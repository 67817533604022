import React, { useContext } from "react";
import { PropTypes } from "prop-types";

import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { UserRoleTypes } from "@/context/UserRoleTypes";
import { UserContext } from "@/context/UserContext";
import DeleteButton from "@/components/common-components/Form/DeleteButton";


export const SalesRepList = ({ salesReps, onDeleteSalesRep }) => {
  const { canEdit } = useContext(UserContext);
  const customCellDeleteSalesRep = ({ dataItem }) => {
    return (
      <td>
        <DeleteButton handleClick={() => onDeleteSalesRep(dataItem)} disabled={!canEdit(UserRoleTypes.PatientPrescribers)}/>
        {/* <button
          disabled={!canEdit(UserRoleTypes.PatientPrescribers)}
          type="button"
          className="k-button"
          onClick={() => onDeleteSalesRep(dataItem)}
        >
          Delete
        </button> */}
      </td>
    );
  };
  customCellDeleteSalesRep.propTypes = {
    dataItem: PropTypes.object,
  };
  return (
    <Grid style={{ height: "300px" }} data={salesReps || []}>
      <Column field="salesRep" title="Sales Rep" />
      <Column field="action" title=" " cell={customCellDeleteSalesRep} />
    </Grid>
  );
};

SalesRepList.propTypes = {
  salesReps: PropTypes.array,
  onDeleteSalesRep: PropTypes.func,
};
