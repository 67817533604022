import React, {
  useContext,
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback
} from 'react';
// kendo
import { Card, CardBody } from '@progress/kendo-react-layout';
import { Field, Form } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import { TextArea } from '@progress/kendo-react-inputs';
import { DropDownList, DropDownTree } from '@progress/kendo-react-dropdowns';
// Context
import {
  WorkItemContext,
  PatientContext,
  UserContext,
  NotifContext,
  LogContext,
  ReferralContext
} from '@/context';
// components
import AwesomeLabel from '@/components/common-components/AwesomeLabel';
import CurrentPatient from '@/components/common-components/CurrentPatient';
// utils
import {
  DatePickerField,
  isDateGreaterThanToday,
  validateInput
} from '@/common/Validation';
import { formatDateToAWSDateTime } from '@/common/DateHelper';
import { generateAttachedFollowData } from '@/components/Header/components/CreateWorkItemMenu/CreateWorkItemMenu.utils';
import {
  queueList,
  userContact
} from '@/components/Header/components/CreateWorkItemMenu/CreateWorkItemMenuHelper';
import { Constants } from '@/constants';

import { groupQueues, workItemTypes } from '@/constants/enum';
import { expandedState, processTreeData } from '@/common/tree-data-operations';
import {
  GENERAL_FOLLOWUP_CALLS_ID,
  checkAudience,
  generalFollowUpCategories,
  getMainGeneralCategory,
  hasMultQueue,
  isCorrectSelection
} from './generalFollowUp.helper';
// gql
import { createWork } from '@/graphql/mutations';
import { connectToGraphqlAPI } from '@/provider';

const selectField = 'selected';
const expandField = 'expanded';
const dataItemKey = 'id';
const textField = 'text';
const subItemsField = 'items';
const fields = {
  selectField,
  expandField,
  dataItemKey,
  subItemsField
};

const GeneralFollowUpMenu = ({ toggleDialog, isParentItem }) => {
  const {
    setRefreshQueueData,
    refreshQueueData,
    deletePendingWorkItem,
    selectedWorkItem
  } = useContext(WorkItemContext);
  const { agentId } = useContext(UserContext);
  const { combinedReferralList } = useContext(ReferralContext);
  const { showError, showSuccess } = useContext(NotifContext);
  const { logApiException } = useContext(LogContext);
  const { selectedPatientInfo } = useContext(PatientContext);
  const [currentContacts, setCurrentContacts] = useState(userContact);
  const [queueSelection, setQueueSelection] = useState(queueList);
  const [showContact, setShowContact] = useState(false);
  const [treeValue, setTreeValue] = useState(null);
  const [expanded, setExpanded] = useState([]);
  const [isValidSelection, setIsValidSelection] = useState(true);

  const onExpandChange = useCallback(
    (event) => setExpanded(expandedState(event.item, dataItemKey, expanded)),
    [expanded]
  );

  const detailsFormRef = useRef(null);
  const itemTypeSelection = useRef();

  useEffect(() => {
    if (detailsFormRef?.current?.onChange) {
      const queueSelectionValue =
        queueSelection?.find((item) => !!item.default) || null;
      detailsFormRef.current.onChange('queueSelection', {
        value: queueSelectionValue
      });
      const contactSelectionValue =
        currentContacts?.find((item) => !!item.default) || null;
      detailsFormRef.current.onChange('assignContact', {
        value: contactSelectionValue
      });
    }
  }, [queueSelection]);

  const createNewFollowupCall = async (requestObject) => {
    try {
      const retData = await connectToGraphqlAPI({
        graphqlQuery: createWork,
        variables: { input: requestObject }
      });
      if (retData) {
        showSuccess('Work Item Created');
        toggleDialog();
        setRefreshQueueData(!refreshQueueData);
        deletePendingWorkItem(requestObject.parentWorkItemId); // remove parent work item after child is created
      }
    } catch (error) {
      console.error('GeneralFollowUpMenu::createNewFollowupCall err:', error);
      showError('Error creating work item', error);
      logApiException(error, {
        view: 'GeneralFollowUpMenu',
        endpoint: 'createWork',
        agentId
      });
    }
  };

  const followUpForm = {
    followUpDate: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          referralId: { ...followUpForm.followUpDate, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        },
        {
          type: 'datePast',
          message: Constants.ErrorMessage.datePast
        }
      ]
    }
  };

  const Item = (props) => {
    return <AwesomeLabel icon={props.item.icon} label={props.item.text} />;
  };

  const ValueHolder = (props) => {
    return props.item ? (
      <AwesomeLabel icon={props.item.icon} label={props.children} />
    ) : (
      props.children
    );
  };

  const treeData = useMemo(
    () =>
      processTreeData(
        generalFollowUpCategories,
        {
          expanded,
          treeValue
        },
        fields
      ),
    [expanded, treeValue]
  );

  const handleSubmit = (e) => {
    const mainItem = { followUpCategory: treeValue, ...e };
    const mainCategory = getMainGeneralCategory(
      mainItem?.followUpCategory?.parentId
    );
    const attachedData = generateAttachedFollowData(mainItem);
    const category =
      mainCategory?.id === GENERAL_FOLLOWUP_CALLS_ID
        ? workItemTypes.OUTBOUND_CALL
        : workItemTypes.FOLLOW_UP;
    const queue = e?.queueSelection?.value;
    const followUpTime = formatDateToAWSDateTime(e?.followUpDate);
    const followUpNotes = e?.followUpNotes;
    const referralId = e?.assignDrug?.referralId;

    const requestObject = {
      createdBy: agentId,
      priority: 6,
      targetTime: followUpTime,
      description: followUpNotes,
      groupQueueId: queue,
      ...(isParentItem && {
        parentWorkItemId: selectedWorkItem?.id
      }),
      assignedTo: 'TBD',
      referralId,
      work: category,
      patientId: selectedPatientInfo?.patientId,
      attachedData
    };
    createNewFollowupCall(requestObject);
  };

  const handleFollowUpTree = (props, type) => {
    setIsValidSelection(isCorrectSelection(type?.value));
    props.onChange('assignContact', {
      value: ''
    });
    props.onChange('queueSelection', {
      value: ''
    });
    const hasAudience = checkAudience(type?.value);

    itemTypeSelection.current = type.value;
    if (hasAudience) {
      const contactsByCategory = getQueueContacts(type?.value);
      setCurrentContacts(contactsByCategory);
      setShowContact(true);
    } else {
      setShowContact(false);
    }
    setTreeValue(type.value);
    const currentSelection = getGeneralQueueAssignment(type?.value);
    setQueueSelection(currentSelection);
  };

  const getQueueContacts = (category) => {
    let result = currentContacts;
    if (!category) return result;

    if (!!category.forPatient) {
      result = [{ value: 'PATIENT', text: 'Patient', default: true }];
    } else if (!!category.forDoctor) {
      result = [{ value: 'DOCTOR', text: 'Doctor', default: true }];
    } else if (!!category.forInsurance) {
      result = [{ value: 'INSURANCE', text: 'Insurance', default: true }];
    } else if (!!category.forPatDoc) {
      result = [
        { value: 'PATIENT', text: 'Patient', default: true },
        { value: 'DOCTOR', text: 'Doctor' }
      ];
    } else if (!!category.forInsDoc) {
      result = [
        { value: 'DOCTOR', text: 'Doctor', default: true },
        { value: 'INSURANCE', text: 'Insurance' }
      ];
    }
    return result;
  };

  const getGeneralQueueAssignment = (category) => {
    if (!category) return queueList;

    let returnValue;
    let returnText;

    if (hasMultQueue(category)) {
      return [
        { value: 'INTAKE', text: 'Intake', default: true },
        { value: 'CLINICIAN', text: 'Clinician' }
      ];
    } else {
      if (category.queueType === groupQueues.INTAKE) {
        returnValue = 'INTAKE';
        returnText = 'Intake';
      } else if (category.queueType === groupQueues.PA) {
        returnValue = 'PA';
        returnText = 'PA';
      } else if (category.queueType === groupQueues.BI) {
        returnValue = 'BI';
        returnText = 'BI';
      } else if (category.queueType === groupQueues.SCHEDULE) {
        returnValue = 'SCHEDULE';
        returnText = 'Scheduling';
      } else if (category.queueType === groupQueues.SALES) {
        returnValue = 'SALES';
        returnText = 'Sales';
      } else if (category.queueType === groupQueues.CLINICIAN) {
        returnValue = 'CLINICIAN';
        returnText = 'Clinician';
      } else {
        returnValue = false;
      }
      return !returnValue
        ? queueList
        : [{ value: returnValue, text: returnText, default: true }];
    }
  };

  return (
    <Card>
      <CardBody>
        <div className='row'>
          <div className='col-md-12' id='tab-strip-gray'>
            <Form
              ref={detailsFormRef}
              onSubmit={handleSubmit}
              validator={isDateGreaterThanToday}
              render={(formRenderProps) => (
                <form
                  onSubmit={formRenderProps.onSubmit}
                  className='k-form pl-3 pr-3 pt-1'
                >
                  <div className='col-md-12 mt-3'>
                    <CurrentPatient />
                  </div>
                  <div className='col-md-12 mt-3'>
                    <AwesomeLabel
                      size='duotone'
                      label={' '}
                      icon='phone-intercom'
                      title='Work Item Type'
                    />
                    <DropDownTree
                      data={treeData}
                      value={treeValue}
                      valid={isValidSelection}
                      name='followUpCategory'
                      onChange={(type) => {
                        handleFollowUpTree(formRenderProps, type);
                      }}
                      placeholder='Select ...'
                      textField={textField}
                      dataItemKey={dataItemKey}
                      selectField={selectField}
                      subItemsField={subItemsField}
                      expandField={expandField}
                      onExpandChange={onExpandChange}
                      item={Item}
                      valueHolder={ValueHolder}
                    />
                  </div>

                  <div className='col-md-12 mt-3'>
                    <AwesomeLabel
                      size='duotone'
                      label={' '}
                      icon='square-check'
                      title='Assign Queue'
                    />
                    <Field
                      component={DropDownList}
                      name='queueSelection'
                      label={' '}
                      style={{ marginTop: '-20px' }}
                      data={queueSelection}
                      textField='text'
                      valueField='value'
                    />
                  </div>
                  {showContact && (
                    <div className='col-md-12 mt-3'>
                      <AwesomeLabel
                        size='duotone'
                        label={' '}
                        icon='user-magnifying-glass'
                        title='Assign Contact'
                      />
                      <Field
                        component={DropDownList}
                        name='assignContact'
                        label={' '}
                        style={{ marginTop: '-20px' }}
                        data={currentContacts}
                        textField='text'
                        valueField='value'
                      />
                    </div>
                  )}
                  {combinedReferralList && combinedReferralList.length > 0 && (
                    <div className='col-md-12 mt-3'>
                      <AwesomeLabel
                        size='duotone'
                        label={' '}
                        icon='user-magnifying-glass'
                        title='Assign Drug'
                      />
                      <Field
                        component={DropDownList}
                        name='assignDrug'
                        label={' '}
                        style={{ marginTop: '-20px' }}
                        data={combinedReferralList}
                        textField='drugName'
                        valueField='drugName'
                        dataItemKey='referralId'
                      />
                    </div>
                  )}
                  <div className='col-md-12 mt-3'>
                    <AwesomeLabel
                      size='duotone'
                      label={' '}
                      icon='calendar-week'
                      title='Follow-Up Date'
                    />
                    <Field
                      component={DatePickerField}
                      name='followUpDate'
                      label=''
                      validator={followUpForm.followUpDate.inputValidator}
                    />
                  </div>
                  <div className='col-md-12 mt-3'>
                    <AwesomeLabel
                      size='duotone'
                      label={' '}
                      icon='note'
                      title='Notes'
                    />
                    <Field
                      component={TextArea}
                      rows={6}
                      name='followUpNotes'
                      label=''
                    />
                  </div>
                  <div className='row p-3'>
                    <div className='col-12' style={{ textAlign: 'center' }}>
                      <Button className='k-button pageButton' type='submit'>
                        Submit
                      </Button>
                    </div>
                  </div>
                </form>
              )}
            />
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default GeneralFollowUpMenu;
