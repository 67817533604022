import React, { useState, useEffect } from "react";

import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Grid } from "@progress/kendo-react-grid";
import { GridColumn as Column } from "@progress/kendo-react-grid/dist/npm/GridColumn";
import { Form, Field } from "@progress/kendo-react-form";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import {
  listLocationAICs,
  getShipmentsByLocation
} from "@/graphql/queries";
import { connectToGraphqlAPI } from "@/provider";
import {
  formatDateToAWSDateTime,
  formatDateToDefault
} from "@/common/DateHelper";
import * as moment from "moment";
import { orderBy } from "@progress/kendo-react-data-tools";

const ShippmentDetails = props => {
  const ShippmentDetailItems = props.dataItem.items;
  return (
    <Grid data={ShippmentDetailItems} className="infusion-grid">
      <Column field={"expirationDate"} title="Expiration Date" />
      <Column field={"lotNumber"} title="Lot Number" />
      <Column field={"ndcNumber"} title="NDC Number" />
      <Column field={"price"} title="Price" />
      <Column field={"productName"} title="Product Name" />
      <Column field={"quantity"} title="Quantity" />
      <Column field={"vendor"} title="Vendor" />
    </Grid>
  );
};

const ShippingHistory = () => {
  const [locations, setLocations] = useState([]);
  const [shipments, setShipments] = useState([]);
  // const [selectedLocation, setSelectedLocation] = useState(null);
  const [sort, setSort] = useState([{ field: "shippedBy", dir: "asc" }]);
  const listLocationAICsCall = async () => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: listLocationAICs
      });

      if (
        data &&
        data.data &&
        data.data.listLocationAICs &&
        data.data.listLocationAICs.items
      ) {
        const locations = data.data.listLocationAICs.items
          .map(item => ({
            ...item,
            text: `${item.locationName}, ${item.state}`,
            value: item.id
          }))
          .sort((a, b) => (a.locationName > b.locationName ? 1 : -1))
          .filter((item) => item.status === "ACTIVE");

        setLocations(locations);
      }
    } catch (err) {
      console.error("ShippingHistory::listLocationAICsCall data err: ", err);
      if (err && err.errors && err.errors.length > 0 && err.errors[0].message) {
        console.error("ShippingHistory::listLocationAICsCall error message: ", err.errors[0].message);
      }
    }
  };

  const getShipmentsByLocationCall = async requestObject => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getShipmentsByLocation,
        variables: requestObject
      });

      if (
        data &&
        data.data &&
        data.data.getShipmentsByLocation &&
        data.data.getShipmentsByLocation.shipments //&&
        // removed Length check because if search returns 0 results, we want to clear the previous values and return empty array
        //data.data.getShipmentsByLocation.shipments.length > 0
      ) {
        const updateShipments = data.data.getShipmentsByLocation.shipments.map(
          item => ({
            ...item,
            shippedDate: formatDateToDefault(item.shippedDate)
          })
        );

        setShipments(updateShipments);
      }
    } catch (err) {
      console.log("ILIA: listLocationAICsCall data err", err);
    }
  };

  const handleAddProductClick = formItems => {
    // search date params use only DATE portion and search midnight to midnight. This means a search for records on 10/11/2021
    // has to be search 10/11/2021 00:00:00 to 10/12/2021 00:00:00. Rather than make the user do this translation,
    // we simply add one day to the toDate in order to get the desired 24hr period.
    // Note: setting the toDate TIME value to 23:59:59 did not work
    // did not work: let toDate = new Date(formItems.toDateRange.setHours(23,59,59));
    let toDate = new Date(formItems.toDateRange);
    toDate.setDate(formItems.toDateRange.getDate() + 1);

    getShipmentsByLocationCall({
      locationId: formItems.location.id,
      period: {
        //startDate: formatDateToAWS(moment(formItems.fromDateRange)),
        //endDate: formatDateToAWS(moment(formItems.toDateRange))
        startDate: formatDateToAWSDateTime(moment(formItems.fromDateRange)),
        endDate: formatDateToAWSDateTime(moment(toDate))
      }
    });
    //setSelectedLocation(formItems.location)
    // listProductOrdersByLocationCall(formItems.location.id);
  };

  useEffect(() => {
    listLocationAICsCall();
  }, []);

  const expandChange = event => {
    let newData = shipments.map(item => {
      if (item.id === event.dataItem.id) {
        item.expanded = !event.dataItem.expanded;
      }
      return item;
    });
    setShipments(newData);
  };

  const deliveryDateCell = ({ dataItem }) => {
    const { expectedDeliveryDate } = dataItem;

    if (!expectedDeliveryDate) return <td>N/A</td>;

    return <td>{formatDateToDefault(expectedDeliveryDate)}</td>;
  };

  return (
    <div className="col-md-12 ml-3 mr-3">
      {/* REPORT SHIPPING HISTORY */}
      <div className="row">
        <div className="col-md-3 mt-12">
          <h3 className="pageTitle">Shipping History Report</h3>
        </div>
      </div>
      <Form
        initialValues={{ fromDateRange: new Date(), toDateRange: new Date() }}
        onSubmit={handleAddProductClick}
        render={formRenderProps => (
          <form
            onSubmit={formRenderProps.onSubmit}
            className={"k-form pl-3 pr-3 pt-1"}
          >
            <div className="row">
              <div className="col-md-12 mt-12 pageTitle">LOCATIONS</div>
            </div>
            <div className="row ">
              <div className="col-md-3 ml-3 mb-5">
                <Field
                  component={DropDownList}
                  name="location"
                  label="LOCATION"
                  data={locations}
                  textField="text"
                  valueField="value"
                />
              </div>
              <div className="col-md-2 mt-0">
                From Date &nbsp;&nbsp;
                <Field component={DatePicker} name="fromDateRange" label="" />
              </div>
              <div className="col-md-2 mt-0">
                To Date: &nbsp;
                <Field component={DatePicker} name="toDateRange" label="" />
              </div>
              <div className="col-md-2 mt-12">
                <button type="submit" className="k-button blue">
                  RUN REPORT
                </button>
              </div>
            </div>
          </form>
        )}
      />
      <div className="row mt-0 py-1">
        <div className="col-md-11 mt-0  mb-2 ">
          <Grid
            className="infusion-grid"
            detail={ShippmentDetails}
            style={{ height: "400px" }}
            data={orderBy(shipments, sort)}
            sort={sort}
            sortable={true}
            onSortChange={e => {
              setSort(e.sort);
            }}
            expandField="expanded"
            onExpandChange={expandChange}
          >
            <Column field="shippedBy" title="Shipped By" />
            <Column field="shippedDate" title="Shipped Date" />
            <Column
              field="expectedDeliveryDate"
              title="Expected Delivery Date"
              cell={deliveryDateCell}
            />
            <Column field="shippingCompany" title="Shipping Company" />
            <Column field="trackingNumber" title="Tracking #" />
            <Column field="shippingStatus" title="Shipping Status" />
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default ShippingHistory;
