import React, { useContext } from 'react';
import styled from 'styled-components';

// kendo
import { Button } from '@progress/kendo-react-buttons';

// context
import { WorkItemContext, PatientContext } from '@/context';

// components
import CreatePreOrderDrawer from './CreatePreOrderDrawer';
import AwesomeLabel from '@/components/common-components/AwesomeLabel';

// utils
import { BLUE, WHITE } from '@/constants/colors';

// styled components
const CreateButton = styled(Button)`
  width: 170px !important;
  background-color: ${BLUE.button};
  color: ${WHITE.plain};
  font-weight: 500;
  background-image: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 15px;
`;

const CreatePreOrder = () => {
  const { setShowPreOrderDrawer, selectedWorkItem } =
    useContext(WorkItemContext);
  const { getPatientBucketCall } = useContext(PatientContext);

  return (
    <>
      <CreateButton
        onClick={() => {
          // load patient info
          getPatientBucketCall(selectedWorkItem.patientId);

          // open drawer
          setShowPreOrderDrawer(true);
        }}
      >
        <AwesomeLabel icon='plus-large' label='Create Pre-Order' />
      </CreateButton>
      <CreatePreOrderDrawer />
    </>
  );
};

CreateButton.displayName = 'CreateButton';

export default CreatePreOrder;
