import React from 'react';
import styled from 'styled-components';

// utils
import { RED } from '@/constants/colors';

const Label = styled.span`
  font-weight: 800;
`;

const Asterisk = styled.span`
  color: ${RED.primary};
`;

Label.displayName = 'Label';
Asterisk.displayName = 'Asterisk';

const RequiredFormLabel = ({ text = 'n/a' }) => {
  return (
    <Label>
      {text}&nbsp;<Asterisk>*</Asterisk>
    </Label>
  );
};

export default RequiredFormLabel;
