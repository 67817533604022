import React, { useState, useEffect } from 'react';
import { Card, CardBody } from '@progress/kendo-react-layout';
import { Document, Page, pdfjs } from 'react-pdf';

// components
import Pagination from '@/components/common-components/Pagination';
import Zoom from '@/components/common-components/Zoom';

const PatientDocument = ({ file }) => {
  const [fileType, setFileType] = useState(''); // image, pdf, doc
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isDocumentLoaded, setIsDocumentLoaded] = useState(false);

  pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const options = {
    cMapUrl: 'cmaps/',
    cMapPacked: true
  };

  function get_url_extension(url) {
    return url.split(/[#?]/)[0].split('.').pop().trim();
  }

  // MAIN INITIATOR
  useEffect(() => {
    if (file) {
      const theFileExt = get_url_extension(file);

      if (
        theFileExt == 'png' ||
        theFileExt == 'PNG' ||
        theFileExt == 'jpg' ||
        theFileExt == 'JPG' ||
        theFileExt == 'jpeg' ||
        theFileExt == 'JPEG' ||
        theFileExt == 'gif' ||
        theFileExt == 'GIF' ||
        theFileExt == 'bmp' ||
        theFileExt == 'BMP'
      ) {
        setFileType('image');
      } else if (theFileExt == 'pdf' || theFileExt == 'PDF') {
        setFileType('pdf');
      } else if (
        theFileExt == 'doc' ||
        theFileExt == 'DOC' ||
        theFileExt == 'docx' ||
        theFileExt == 'DOCX'
      ) {
        setFileType('doc');
      }
    }
  }, [file]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setIsDocumentLoaded(true);
  }

  const onPageChanged = (data) => {
    setPageNumber(data.currentPage);
  };

  return (
    <Card>
      <CardBody style={{ border: '1px solid #ccc' }}>
        {fileType == 'pdf' && (
          <Zoom>
            <Document
              file={file}
              onLoadSuccess={onDocumentLoadSuccess}
              options={options}
              onContextMenu={(e) => e.preventDefault()}
              className="pdf-container"
            >
              <Page className="fax-document" pageNumber={pageNumber} />
            </Document>
            {isDocumentLoaded && (
              <Pagination
                totalRecords={numPages}
                pageLimit={1}
                pageNeighbours={1}
                onPageChanged={onPageChanged}
              />
            )}
          </Zoom>
        )}
        {fileType == 'image' && (
          <Zoom>
            <img src={file} alt="Patient Document Image" width="100%" />
          </Zoom>
        )}
      </CardBody>
    </Card>
  );
};

export default PatientDocument;
