import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

// kendo
import {
  Card,
  CardTitle,
  CardBody,
  CardActions
} from '@progress/kendo-react-layout';

// components
import AwesomeLabel from '@/components/common-components/AwesomeLabel';
import LinkToPatientProfile from '@/components/common-components/LinkToPatientProfile';

// context
import { WorkItemContext } from '@/context';

// styles
import styles from './Widgets.module.scss';

const QueueCenterWidget = () => {
  const history = useHistory();
  const { lastCompletedWorkItem } = useContext(WorkItemContext);
  const lastWorkItem = lastCompletedWorkItem || {
    type: 'Unknown',
    date: 'Unknown'
  };

  return (
    <Card className={styles.card}>
      <CardBody>
        <CardTitle>
          <AwesomeLabel icon='ballot-check' label='Queue Center' />
        </CardTitle>
        <p className={styles.description}>
          Complete work items for{' '}
          <span
            className={styles.link}
            onClick={() => history.push('/work-queue/INTAKE')}
          >
            Intake
          </span>
          ,{' '}
          <span
            className={styles.link}
            onClick={() => history.push('/work-queue/BI')}
          >
            BI
          </span>
          ,{' '}
          <span
            className={styles.link}
            onClick={() => history.push('/work-queue/PA')}
          >
            PA
          </span>
          ,{' '}
          <span
            className={styles.link}
            onClick={() => history.push('/work-queue/SCHEDULE')}
          >
            Schedule
          </span>{' '}
          or{' '}
          <span
            className={styles.link}
            onClick={() => history.push('/work-queue/CLINICIAN')}
          >
            Clinician
          </span>
        </p>
        <p>
          Your last completed work item:
          <br />
          <AwesomeLabel
            icon='calendar-check'
            label={lastWorkItem?.type}
            title='Type'
          />
          <br />
          <AwesomeLabel icon='clock' label={lastWorkItem?.date} title='Date' />
          {lastWorkItem?.patientId && (
            <>
              <br />
              <LinkToPatientProfile
                customData={lastWorkItem}
                customCssClass='red-link'
              />
            </>
          )}
        </p>
      </CardBody>
      <CardActions className={styles.actions}>
        <span
          className='k-button k-button-md k-rounded-md k-button-flat k-button-flat-primary'
          onClick={() => history.push('/work-queue/INTAKE')}
        >
          <AwesomeLabel
            icon='arrow-right'
            label='Intake Queue'
            iconFirst={false}
          />
        </span>
      </CardActions>
    </Card>
  );
};

export default QueueCenterWidget;
