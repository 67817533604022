import React, { useContext } from 'react';

// context
import { WorkItemContext } from '@/context';

// components
import AwesomeLabel from '@/components/common-components/AwesomeLabel';
import { getFileNameFromPath } from '@/common/Utility';

const WorkItemDocLink = () => {
  const { selectedWorkItem } = useContext(WorkItemContext);

  if (!selectedWorkItem) return null;

  const { attachedData } = selectedWorkItem;

  if (!!attachedData?.documentPath) {
    const fileName = getFileNameFromPath(attachedData.documentPath);
    return (
      <div>
        <AwesomeLabel label={fileName} icon='file' title='File Name' />
      </div>
    );
  }

  return null;
};

export default WorkItemDocLink;
