import React from "react";
import { PropTypes } from "prop-types";
import { formatDateToDefault } from "@/common/DateHelper";




export const DeductibleInfo = ({ info }) => {
    const { deductibleType, networkStatus, totalDeductibleAmount, totalOOPAmount } = info;
    const { amount, resetDate } = info.metDeductible;
    const oopAmount = info?.metOOP?.amount
    const oopResetDate = info?.metOOP?.resetDate

    let netStat = networkStatus ? "Yes" : "No";

        const getFormattedDate = (date) => {
        return date ? formatDateToDefault(date) : date;
        };
        const rDate = getFormattedDate(resetDate);
        const oRDate = getFormattedDate(oopResetDate);

    return (
       

            <div className="col-md-6">
                <div className="row" style={{ marginLeft: 1 }}>
                <span className="billing-style">Deductible Type:</span>&nbsp;{deductibleType || "-"}
                </div>

               {/*  <div className="row" className="billing-spacing"><span className="billing-style">Deductible Type:</span>&nbsp;{deductibleType || "-"}</div> */}
                <div className="row" className="billing-spacing"><span className="billing-style">In/Out of Network:</span>&nbsp;{netStat || "-"}</div>
                <div className="row" className="billing-spacing"><span className="billing-style">Deductible Total:</span>&nbsp; {totalDeductibleAmount || "-"}</div>
                <div className="row" className="billing-spacing"><span className="billing-style">Deductible Met:</span>&nbsp;{amount || "-"} </div>
                <div className="row" className="billing-spacing"><span className="billing-style">Deductible Reset Date:</span>&nbsp;{rDate || "-"} </div>
                 
                <div className="row" className="billing-spacing"><span className="billing-style">Out of Pocket Max:</span>&nbsp; {totalOOPAmount || "-"}</div>
                <div className="row" className="billing-spacing"><span className="billing-style">Out of Pocket Max Met:</span>&nbsp;{oopAmount || "-"} </div>
                <div className="row" className="billing-spacing"><span className="billing-style">Out of Pocket Reset Date:</span>&nbsp;{oRDate || "-"} </div>

            </div>

       

    )
}

DeductibleInfo.propTypes = {
    info: PropTypes.object,
};


