import { connectToGraphqlAPI } from '@/provider';
import { listProviderAICs } from '@/graphql/queries';

export const listProviderAICsCall = async (returnRaw = false) => {
  try {
    const data = await connectToGraphqlAPI({
      graphqlQuery: listProviderAICs
    });

    if (!data?.data?.listProviderAICs?.items?.length) {
      return null;
    }

    const sorted = data.data.listProviderAICs.items
      .map((item) => ({
        text: `${item.firstName} ${item.lastName} (NPI: ${item.providerNPI})`,
        value: item.providerNPI // item.locationId, // ??? where is groupId ??? groupId == taxId
      }))
      .sort((first, second) => {
        const firstText = first.text.toUpperCase(); // ignore upper and lowercase
        const secondText = second.text.toUpperCase(); // ignore upper and lowercase
        if (firstText < secondText) {
          return -1;
        }
        if (firstText > secondText) {
          return 1;
        }
        return 0; // names must be equal
      });

    if (returnRaw) {
      return {
        sorted,
        raw: data.data.listProviderAICs.items
      };
    }

    return sorted;
  } catch (err) {
    console.error('listProviderAICsCall data err: ', err);
    return null;
  }
};
