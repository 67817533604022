export const getMinTime = () => {
    return new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 7, 59);
};

export const getMaxTime = () => {
    return new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 18, 1);
};

export const getAdminMinTime = () => {
    return new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 6, 59);
};

export const getAdminMaxTime = () => {
    return new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 18, 1);
};
