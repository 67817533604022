import React, { useState, useContext, useEffect } from 'react';

// kendo
import { Grid } from '@progress/kendo-react-grid';
import { GridColumn as Column } from '@progress/kendo-react-grid/dist/npm/GridColumn';
import { Button } from '@progress/kendo-react-buttons';
import { Form, Field } from '@progress/kendo-react-form';
import { Dialog } from '@progress/kendo-react-dialogs';
import { process } from '@progress/kendo-data-query';

// components
import { ColumnMenuNoGroup } from '@/components/common-components/columnMenu';

// utils
import { InputField, validateInput } from '@/common/Validation';
import { Constants } from '@/constants';
import { formatDateToAWSDateTime } from '@/common/DateHelper';

// gql
import { createCodeInfo } from '@/graphql/mutations';
import { connectToGraphqlAPI } from '@/provider';

// context
import { NotifContext, InfoCodeContext } from '@/context';

const Icd10Admin = () => {
  const { showSuccess, showError } = useContext(NotifContext);
  const { dxCodes, setDxCodes } = useContext(InfoCodeContext);

  const initialDataState = {};
  const [showAddIcd10, setShowAddIcd10] = useState(false);
  const [dataState, setDataState] = useState();
  const [resultState, setResultState] = React.useState(
    process(dxCodes, initialDataState)
  );

  const columns = [
    {
      field: 'code',
      title: 'ICD10 CODE'
    },
    {
      field: 'description',
      title: 'DESCRIPTION'
    }
  ];

  useEffect(() => {
    setDataState(initialDataState);
    setResultState(process(dxCodes, initialDataState));
  }, [dxCodes]);

  const toggleAddNewIcd10 = () => {
    setShowAddIcd10(!showAddIcd10);
  };

  const initialForm = () => {
    const initialObject = {};
    Object.keys(icd10CodeForm).forEach((key) => {
      initialObject[key] = icd10CodeForm[key].value;
    });
    return initialObject;
  };

  const icd10CodeForm = {
    name: {
      value: null,
      inputValidator: (value) => {
        return validateInput({
          name: { ...icd10CodeForm.name, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        },
        {
          type: 'alpha',
          message: Constants.ErrorMessage.Alpha_Required
        },
        {
          type: 'maxLength',
          length: 40,
          message: Constants.ErrorMessage.maxLength_REQUIRED
        }
      ]
    },
    zip: {
      value: null,
      inputValidator: (value) => {
        return validateInput({ zip: { ...icd10CodeForm.zip, value } });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.Zip_REQUIRED
        },
        {
          type: 'minLength',
          length: 5,
          message: Constants.ErrorMessage.minLength_REQUIRED
        },
        {
          type: 'maxLength',
          length: 5,
          message: Constants.ErrorMessage.maxLength_REQUIRED
        }
      ]
    }
  };

  const onDataStateChange = React.useCallback(
    (e) => {
      setDataState(e.dataState); // store for use
      setResultState(process(dxCodes, e.dataState));
    },
    [dxCodes]
  );

  const handleAddNewSubmit = (dataItem) => {
    const date = new Date();
    const requestObject = {
      code: dataItem?.code,
      description: dataItem?.description,
      codeType: 'ICD10',
      createdAt: formatDateToAWSDateTime(date),
      updatedAt: formatDateToAWSDateTime(date)
    };

    addNewICD(requestObject);
  };

  const addNewICD = async (requestObject) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: createCodeInfo,
        variables: { input: requestObject }
      });
      if (data) {
        toggleAddNewIcd10();
        showSuccess('New ICD10 Code has beed created');

        // also updating the list in the context
        setDxCodes([...dxCodes, requestObject]);
      }
    } catch (err) {
      console.error('Icd10Admin::createCodeInfo err: ', err);
      showError(
        `Error in createCodeInfo call, ${err?.errors[0]?.errorType}. This error may be caused by trying to create an existing ICD10 code.`
      );
    }
  };

  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col ml-1 mr-1'>
          <div className='row'>
            <div className='col-md-2 mt-12'>
              <Button
                type='button'
                look='outline'
                icon='plus'
                onClick={toggleAddNewIcd10}
              >
                Add New ICD-10 Code
              </Button>
            </div>
            <div className='col-md-8 mt-16'>
              <b>Note:</b> All new additions should be tested in TEST
              environment before going to Production.
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6 ml-1 mr-1 mt-3 mb-3'>
              <div>
                <div className='a-grid__header'>
                  <div>ICD-10 Codes</div>
                </div>
                <Grid
                  style={{ cursor: 'pointer', minHeight: 250 }}
                  className='queue_grid'
                  data={{ data: resultState.data }}
                  resizable
                  sortable
                  onDataStateChange={onDataStateChange}
                  {...dataState}
                >
                  {columns.map((column, index) => {
                    return (
                      <Column
                        field={column.field}
                        title={column.title}
                        key={index}
                        width={column.minWidth}
                        columnMenu={ColumnMenuNoGroup}
                        cell={column.cell || null}
                      />
                    );
                  })}
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {showAddIcd10 && (
          <Dialog
            title='Add ICD-10 Code'
            width={600}
            height={500}
            initialTop={50}
            showDialog
            onClose={toggleAddNewIcd10}
          >
            <Form
              onSubmit={handleAddNewSubmit}
              initialValues={initialForm()}
              render={(formRenderProps) => (
                <form
                  onSubmit={formRenderProps.onSubmit}
                  className='k-form pl-2 pr-2'
                >
                  <div className='row mt-3'>
                    <div className='col-md-12'>
                      <strong>Example:</strong>&nbsp; K50.90 &nbsp;
                      &nbsp;Crohn's disease, unspecified, without complications
                    </div>
                  </div>
                  <hr />
                  <div className='row'>
                    <div className='col-md-3'>
                      <Field
                        name='code'
                        label='Code'
                        component={InputField}
                        // validator={insurerInfoForm.taxId.inputValidator}
                      />
                    </div>
                  </div>
                  <div className='row mt-3 mb-3'>
                    <div className='col-md-12'>
                      <Field
                        name='description'
                        label='Description'
                        component={InputField}
                        // validator={insurerInfoForm.taxId.inputValidator}
                      />
                    </div>
                  </div>
                  <hr />
                  <div className='row mt-3'>
                    <div className='col-md-6'>
                      <button
                        type='submit'
                        className='k-button pageButton Blue'
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              )}
            />
          </Dialog>
        )}
      </div>
    </div>
  );
};

export default Icd10Admin;
