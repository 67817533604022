import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useHistory } from 'react-router';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { guid } from '@progress/kendo-react-common';
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';

// components
import AICLocationDropdown from '@/components/common-components/AICLocationDropdown';

// context
import {
  PatientContext,
  LocationContext,
  UserContext,
  UserRoleTypes
} from '@/context';
import { NURSING_QUEUE, INFUSION_QUEUE } from '@/context/userMenu';

// helpers
import { formatDateToAWSDateTime } from '@/common/DateHelper';

// partials
import SchedulingCalendarMain from './SchedulingCalendarMain';
import PatientCalendarSearch from './PatientCalendarSearch';
import { displayDate } from '../Scheduling/events-utc';
import { getScheduleEvents } from '../Scheduling/getScheduleEvents';
import { AppointmentListData } from '../Appointments/AppointmentListData';

const Calendar = (props) => {
  const [incomingStartDate, setIncomingStartDate] = useState(null);
  const [incomingId, setIncomingLocationId] = useState(null);
  const [childData, setData] = useState();
  const [, setCalendarToAppointmentWindow] = useState();
  const [schedulerLocationId, setSchedulerLocationId] = useState(null);

  const getDefaultDate = () => {
    return new Date(displayDate);
  };
  const [newDate, setDate] = useState(getDefaultDate()); // instead of using thisDate
  const [locationEvents, setLocationEvents] = useState([]);
  const [, setShowDialog] = useState(false);

  const {
    aicActiveLocationsOnly,
    currentLocation,
    setCurrentLocation,
    getLocation,
    isLocationActive
  } = useContext(LocationContext);
  const { selectedPatientInfo } = useContext(PatientContext);
  const { agent, showInMenu, canEdit } = useContext(UserContext);

  const history = useHistory();

  const calendarLayout = props.calendarLayout ? props.calendarLayout : 1;

  const hours = displayDate.getHours();

  function addHoursToDate(objDate, intHours) {
    const numberOfMlSeconds = objDate.getTime();
    const addMlSeconds = intHours * 60 * 60 * 1000;
    const newDateObj = new Date(numberOfMlSeconds + addMlSeconds);
    return newDateObj;
  }

  const incomingDate = incomingStartDate
    ? addHoursToDate(incomingStartDate, hours)
    : null;
  const finalDate = incomingId || agent?.locationId;

  const date = incomingDate || newDate;

  const isEditable = canEdit(UserRoleTypes.Calendar);
  const showInfusionQ = showInMenu(INFUSION_QUEUE);
  const showNursingQ = showInMenu(NURSING_QUEUE);

  const childToParent = () => {
    setData(!childData);
  };

  const schedulingCalendarToCalendar = (calendarData) => {
    setCalendarToAppointmentWindow(calendarData);
  };

  const view = 'day';

  /// ///All functions needed from Scheduling Calendar

  const handleLocationChange = (event) => {
    const selectedId = event?.target?.value?.selectorValue
      ? event?.target?.value?.selectorValue
      : event;
    if (event?.target?.value?.selectorValue) {
      setIncomingStartDate(null);
      setIncomingLocationId(null);
    }
    let error = false;
    if (aicActiveLocationsOnly?.length) {
      const selected = aicActiveLocationsOnly.find(
        (item) => item.id === selectedId
      );
      if (selected) {
        const zip = selected?.address?.zip;

        // reset and hide the DisplayScheduler by setting its locationId to null
        setSchedulerLocationId(null);
        // reset the date to today
        setDate(getDefaultDate());
        setCurrentLocation(selected);
      } else {
        error = true;
      }
    } else {
      error = true;
    }
    if (error) {
      /* alert(
        "Calendar could not be updated for this location. Please reload and try again"
      ); */
    }
  };

  const handleLocationTreeChange = (selectedItem) => {
    if (selectedItem?.id) {
      setIncomingStartDate(null);
      setIncomingLocationId(null);
      // reset and hide the DisplayScheduler by setting its locationId to null
      setSchedulerLocationId(null);
      // reset the date to today
      setDate(getDefaultDate());
      setCurrentLocation(selectedItem);
    }
  };

  const handleDataChange = useCallback(
    ({ created, updated, deleted }) => {
      if (deleted && deleted.length > 0) {
        setShowDialog(true);
      }
      setLocationEvents((old) =>
        old
          .filter(
            (item) =>
              deleted.find((current) => current.TaskID === item.TaskID) ===
              undefined
          )
          .map(
            (item) =>
              updated.find((current) => current.TaskID === item.TaskID) || item
          )
          .concat(created.map((item) => ({ ...item, TaskID: guid() })))
      );
    },
    [setLocationEvents]
  );

  const getNewDateFromSchedulerSelection = (schedulerDate) => {
    if (!schedulerDate?._localDate) {
      return schedulerDate;
    }
    return new Date(schedulerDate._localDate);
  };

  const handleDateChange = useCallback(
    (event) => {
      setIncomingStartDate(null);
      setDate(getNewDateFromSchedulerSelection(event.value));
    },
    [setDate]
  );
  /// ////////////////////////////////////////////
  /// ///All calls needed from Scheduling Calendar

  const getScheduleEventsByLocationIdCall = async (
    locationIdVal,
    dateVal,
    viewVal
  ) => {
    const result = await getScheduleEvents(locationIdVal, dateVal, viewVal);
    if (schedulerLocationId !== locationIdVal) {
      setSchedulerLocationId(locationIdVal);
    }
    setLocationEvents(result);
  };

  /// ////////////////////////////////////////////////
  const SendToInfusionQueue = () => {
    history.push('/infusion-queue');
  };

  const SendToNursingQueue = () => {
    history.push('/nurse-queue');
  };

  // MAIN INITIATOR
  useEffect(() => {
    if (currentLocation === null) {
      const agentLocation = getLocation(agent?.locationId);
      const isActive = isLocationActive(agentLocation?.id);
      if (isActive) {
        setCurrentLocation(agentLocation);
      }
    }
    setIncomingStartDate(
      props?.location?.state?.searchDate
        ? new Date(props?.location?.state?.searchDate)
        : null
    );
    setIncomingLocationId(
      props?.location?.state?.searchLocationId
        ? props?.location?.state?.searchLocationId
        : null
    );
  }, []);

  useEffect(() => {
    if (currentLocation?.selectorValue && date) {
      getScheduleEventsByLocationIdCall(
        currentLocation.selectorValue,
        formatDateToAWSDateTime(date),
        view
      );
    }
  }, [currentLocation]);

  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-12' />
      </div>
      <div className='row'>
        <div className='col-6'>
          AIC Location: {currentLocation?.id}
          <br />
          {false && (
            <DropDownList
              defaultItem={currentLocation}
              value={currentLocation}
              name='calendarLocation'
              label=''
              data={aicActiveLocationsOnly}
              textField='selectorText'
              valueField='selectorValue'
              onChange={(e) => handleLocationChange(e)}
              style={{ width: '220px' }}
            />
          )}
          <AICLocationDropdown
            data={aicActiveLocationsOnly}
            value={currentLocation}
            style={{ width: '220px' }}
            onChange={handleLocationTreeChange}
          />
        </div>
        {calendarLayout === 1 && (
          <>
            <div className='col-4' style={{ marginBottom: 20 }}>
              {currentLocation?.id && isEditable && (
                <PatientCalendarSearch
                  childToParent={childToParent}
                  searchLayout={2}
                  existingOnly
                />
              )}
            </div>
            {showInfusionQ && (
              <div className='col-md-1 mt-06' style={{ marginBottom: 20 }}>
                <button
                  type='button'
                  className='k-button'
                  onClick={SendToInfusionQueue}
                  style={{ marginTop: 19 }}
                >
                  Infusion Queue
                </button>
              </div>
            )}

            {showNursingQ && (
              <div className='col-md-1 mt-06 ' style={{ marginBottom: 20 }}>
                <button
                  type='button'
                  className='k-button'
                  onClick={SendToNursingQueue}
                  style={{ marginTop: 19 }}
                >
                  Nursing Queue
                </button>
              </div>
            )}
          </>
        )}
      </div>
      <div className='row check-notes'>
        <div className='col-12'>
          {calendarLayout === 1 && selectedPatientInfo?.patientId ? (
            <PanelBar>
              <PanelBarItem
                expanded={false}
                title='Current Patient Appointments'
              >
                <AppointmentListData
                  selectedPatientInfo={selectedPatientInfo}
                  selectedLocationId={finalDate}
                />
              </PanelBarItem>
            </PanelBar>
          ) : (
            ''
          )}
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <SchedulingCalendarMain
            childToParent={childToParent}
            schedulerLocationId={schedulerLocationId}
            firstView={view}
            locationEvent={locationEvents}
            handleDataChange={handleDataChange}
            incomingDate={incomingDate}
            date={date}
            handleDateChange={handleDateChange}
            childData={childData}
            schedulingCalendarToCalendar={schedulingCalendarToCalendar}
            calendarLayout={calendarLayout}
            isEditable={isEditable}
            listLocationAICsData={aicActiveLocationsOnly}
          />
        </div>
      </div>
    </div>
  );
};

export default Calendar;
