import styled from 'styled-components';

export const EventBadgeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PopupTableContainer = styled.div`
  font-size: 12px;
  background-color: #ffffff;
  white-space: nowrap;
  font-weight: normal;
`;

export const PopupTh = styled.th`
  font-size: 11px;
  background-color: '#e2e1e1';
  width: '15%';
`;

export const PopupTr = styled.tr`
  font-size: 11px;
  background-color: '#e2e1e1';
`;

export const PopupTd = styled.td`
  font-size: 12px;
  background-color: '#ffffff';
  white-space: pre-wrap;
  overflow-wrap: break-word;
`;

EventBadgeContainer.displayName = 'EventBadgeContainer';
PopupTableContainer.displayName = 'PopupTableContainer';
PopupTd.displayName = 'PopupTd';
PopupTh.displayName = 'PopupTh';
PopupTr.displayName = 'PopupTr';
