import React, { useState, useEffect, useContext } from 'react';
import { Grid } from '@progress/kendo-react-grid';
import { GridColumn as Column } from '@progress/kendo-react-grid/dist/npm/GridColumn';
import { Input } from '@progress/kendo-react-inputs';
import { Form, Field } from '@progress/kendo-react-form';
import { PDFExport } from '@progress/kendo-react-pdf';
import { Constants } from '@/constants';
import { MessageDialog } from '@/components/common-components/MessageDialog';
import WindowDialog from '@/components/common-components/WindowDialog';
import { formatDateToDefault, getNow } from '@/common/DateHelper';
import generateUUID from '@/common/uuid';
import { connectToGraphqlAPI } from '@/provider';
import { listLocationAICs, listProducts } from '@/graphql/queries';
import { saveOrderReport, saveOrders } from '@/graphql/mutations';
import { UserContext } from '@/context/UserContext';
import {
  InputField,
  validateInput,
  DropDownListField
} from '@/common/Validation';

const OrderProduct = props => {
  const { user } = useContext(UserContext);
  const [visibleCreateOrderDialog, setVisibleCreateOrderDialog] =
    useState(false);
  const [dialogOption, setDialogOption] = useState({});
  const [listLocationAICsData, setListLocationAICsData] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState();
  const [listProductsData, setListProductsData] = useState([]);
  const [listProductsDataFiltered, setListProductsDataFiltered] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();
  const [orderProducts, setOrderProducts] = useState([]);
  const [orderEvents, setOrderEvents] = useState([]);
  const [userEntered, setUserEntered] = useState(false);
  const [orderReportId] = useState(generateUUID());
  const [newEventId] = useState(generateUUID());
  const pdfExportComponent = React.useRef(null);

  const exportPDFWithComponent = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };

  const handleSubmit = () => {
    exportPDFWithComponent(); // print the order report
    handleCreateOrder(); // submit the order itself to the server
  };

  // prodOrders is being sent from handleCreateOrder with UUID's for order id if it is a new order not coming from an appointment.
  const callSaveOrderReport = async prodOrders => {
    try {
      const productOrderIds = prodOrders.map(product => product.orderId);
      await connectToGraphqlAPI({
        graphqlQuery: saveOrderReport,
        variables: {
          agentId: user.username,
          orderReport: { productOrderIds, reportId: orderReportId }
        }
      }).then(() => {
        setDialogOption({
          title: 'Save Order Report',
          message: 'Report Completed',
          showDialog: true
        });
        toggleCreateOrderDialog();
      });
    } catch (err) {
      console.log('dev callSaveOrderReport data err', err);
      setDialogOption({
        title: 'Save Order Report',
        message: 'Error: saveOrderReport',
        showDialog: true
      });
    }
  };

  const toggleCreateOrderDialog = () => {
    setVisibleCreateOrderDialog(!visibleCreateOrderDialog);
  };

  const customCell = props => {
    return (
      <td>
        <Input
          className="w-100"
          name="orderQuantity"
          defaultValue={props.dataItem.orderQuantity}
          onChange={e => {
            const newQty = e.target.value;
            /*
            if (newQty) {
              props.dataItem.shipQty = Number(newQty);
            }
            else{
              props.dataItem.orderQuantity = null;
            }
            */
            if (newQty && Number(newQty) > 0) {
              props.dataItem.orderQuantity = newQty;
            }
          }}
        />
      </td>
    );
  };

  const reportProductCell = props => {
    const { productId, productName, medications } = props.dataItem;
    return (
      <td>
        {productName} {medications} ({productId})
      </td>
    );
  };
  /*
  const customCellDelete = props => {
     return (
       <td>
         <button
           type="button"
           className="k-button"
           onClick={() => handleDeleteClick(props, "record")}
         >
           Delete
         </button>
       </td>
     );
   };
   const handleDeleteClick = (props, object) => {
     console.log("dev handleDeleteClick props", props);
     if (
       window.confirm(
         `Are you sure you want to delete record ${props.dataIndex + 1}?`,
         "Delete Order Product Record?"
       )
     ) {
       if (props.dataIndex > -1) {
         const cloneOrderProducts = [...orderProducts];
         cloneOrderProducts.splice(props.dataIndex, 1);
         setOrderProducts(cloneOrderProducts);
       }
     }
  };
  */
  // MAIN INITIATOR
  useEffect(() => {
    listOrderEvents();
    listLocationAICsCall();
    listProductsCall();
    listProductOrdersCall();
  }, []);

 /*  useEffect(() => {
    console.log("dev orderProducts useEffect", orderProducts);
  }, [orderProducts]); */

  const listProductOrdersCall = async () => {
    const theData =
      props.history?.location?.state?.orders?.map(item => ({
        ...item,
        aicLocation: `${item.locationName} (ID: ${item.locationId})`,
        actionOrderQuantity: item.orderQuantity,
        orderQuantity: item.initialQuantity,
        product: `${item.productName} (${item.productId})`,
        orderDate: formatDateToDefault(item.orderDate),
        medications: `${item.dose} ${item.unitOfMeasure}`
      })) || [];
    setOrderProducts(theData);
  };

  const listOrderEvents = () => {
    const orderEvents = props.history?.location?.state?.eventsForOrder || [];
    setOrderEvents(orderEvents);
  };

  const listLocationAICsCall = async () => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: listLocationAICs
      });

      if (
        data &&
        data.data &&
        data.data.listLocationAICs &&
        data.data.listLocationAICs.items
      ) {
        const theData = data.data.listLocationAICs.items
          .map(item => ({
            ...item,
            text: `${item.locationName}, ${item.state}`,
            value: item.id
          }))
          .sort((a, b) => (a.locationName > b.locationName ? 1 : -1))
          .filter((item) => item.status === 'ACTIVE');
        setListLocationAICsData(theData);
      }
    } catch (err) {
      console.error('OrderProduct::listLocationAICsCall data err: ', err);
      setDialogOption({
        title: 'Order Product: ListLocations',
        message: 'Error: listLocationAICsCall',
        showDialog: true
      });
    }
  };

  // const listProductsCall_old = async () => {
  //   try {
  //     console.log("dev listProductsData useEffect", listProductsData);
  //     const data = await connectToGraphqlAPI({
  //       graphqlQuery: listProducts
  //     });
  //     console.log("dev listProductsCall data", data);

  //     if (
  //       data &&
  //       data.data &&
  //       data.data.listProducts &&
  //       data.data.listProducts.items &&
  //       data.data.listProducts.items.length
  //     ) {
  //       setListProductsData(data.data.listProducts.items);
  //       const filtered = data.data.listProducts.items
  //         .map(item => ({
  //           dosing: item.dosing,
  //           frequency: item.frequency,
  //           price: item.price,
  //           route: item.route,
  //           scheduledAllotment: item.scheduledAllotment,
  //           status: item.status,
  //           strength: item.strength,
  //           unitOfMeas: item.unitOfMeas,
  //           vendor: item.vendor,
  //           productId: item.productId,
  //           productName: item.productName,
  //           productOnSelector: `${item.productName} (${item.strength} ${item.unitOfMeas}) - ${item.packageType}`
  //         }))
  //         .sort((a, b) => (a.productName > b.productName ? 1 : -1));
  //       // @NOTE: the following logic filters out non-unique products
  //       // const unique = Array.from(
  //       //   new Set(filtered.map(a => a.productName))
  //       // ).map(productName => {
  //       //   return filtered.find(a => a.productName === productName);
  //       // });
  //       // setListProductsDataFiltered(unique);
  //       setListProductsDataFiltered(filtered);
  //     }
  //   } catch (err) {
  //     console.log("dev listProductsCall err", err);
  //     setDialogOption({
  //       title: "Order Product: ListProducts",
  //       message: "Error: listProductsCall",
  //       showDialog: true
  //     });
  //   }
  // };
  const listProductsCall = async () => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: listProducts
      });

      if (
        data &&
        data.data &&
        data.data.listProducts &&
        data.data.listProducts.items &&
        data.data.listProducts.items.length
      ) {
        /// /////////////////////
        let list = data.data.listProducts.items;
        let sNextToken = data.data.listProducts.nextToken
          ? data.data.listProducts.nextToken
          : null;
        while (sNextToken != null) {
          try {
            const data = await connectToGraphqlAPI({
              graphqlQuery: listProducts,
              variables: { nextToken: sNextToken }
            });

            if (
              data &&
              data.data &&
              data.data.listProducts &&
              data.data.listProducts.items
            ) {
              sNextToken = data.data.listProducts.nextToken
                ? data.data.listProducts.nextToken
                : null;
              list = list.concat(data.data.listProducts.items);
            }
          } catch (err) {
            console.log('ILIA listProductsCall err', err);
            sNextToken = null; // set to stop iterating
          }
        }
        /// ////////////////
        setListProductsData(list);
        const filtered = list
          .map(item => ({
            dosing: item.dosing,
            frequency: item.frequency,
            price: item.price,
            route: item.route,
            scheduledAllotment: item.scheduledAllotment,
            status: item.status,
            strength: item.strength,
            unitOfMeas: item.unitOfMeas,
            vendor: item.vendor,
            productId: item.productId,
            productName: item.productName,
            productOnSelector: `${item.productName} (${item.strength} ${item.unitOfMeas}) - ${item.packageType}, ${item.packageVolume} ${item.packageVolumeUOM}`
          }))
          .sort((a, b) => (a.productName > b.productName ? 1 : -1));
        // @NOTE: the following logic filters out non-unique products
        // const unique = Array.from(
        //   new Set(filtered.map(a => a.productName))
        // ).map(productName => {
        //   return filtered.find(a => a.productName === productName);
        // });
        // setListProductsDataFiltered(unique);
        setListProductsDataFiltered(filtered);
      }
    } catch (err) {
      console.log('dev listProductsCall err', err);
      setDialogOption({
        title: 'Order Product: ListProducts',
        message: 'Error: listProductsCall',
        showDialog: true
      });
    }
  };

 /*  useEffect(() => {
    console.log("dev setSelectedLocation useEffect", selectedLocation);
  }, [selectedLocation]); */

  const handleLocationChange = event => {
    setSelectedLocation(event.target.value);
  };

 /*  useEffect(() => {
    console.log("dev setSelectedProduct useEffect", selectedProduct);
  }, [selectedProduct]); */

  const handleProductChange = event => {
    setSelectedProduct(event.target.value);
  };

  const handleAddOrderProduct = dataItem => {
    setUserEntered(true);

    const { Product, Location } = dataItem;

    const orderProduct = {
      aicLocation: `${Location.locationName} (ID: ${Location.id})`,
      dose: Product.strength,
      id: newEventId,
      initialQuantity: dataItem.amtOfProduct,
      locationId: Location.id,
      locationName: Location.locationName,
      medications: `${Product.strength} ${Product.unitOfMeas}`,
      orderDate: formatDateToDefault(new Date()),
      orderQuantity: dataItem.amtOfProduct,
      actionOrderQuantity: dataItem.amtOfProduct,
      orderStatus: 'REQUESTED NEW',
      orderedBy: user?.username,
      product: `${Product.productName} (${Product.productId})`,
      productId: Product.productId,
      productName: Product.productName,
      unitOfMeasure: Product.unitOfMeas,
      scheduledAllotment: Product.scheduledAllotment
    };

    try {
      setOrderProducts([...orderProducts, orderProduct]);
      setOrderEvents([...orderEvents, newEventId]);
    } catch (err) {
      console.log('dev handleAddOrderProduct: err', err);
      setDialogOption({
        title: 'OrderProduct: Add Product',
        message: 'Error',
        showDialog: true
      });
    }
  };

  const handleCreateOrder = async () => {
    try {
      const productOrders = orderProducts.map(order => {
        if (order.id === newEventId) {
          const newOrderId = generateUUID(); /// set unique order Ids
          return {
            orderId: newOrderId,
            quantity: order.orderQuantity,
            orderQuantity: order.orderQuantity,
            productId: order.productId,
            productName: order.productName,
            dose: order.dose,
            locationId: order.locationId,
            unitOfMeasure: order.unitOfMeasure,
            userEntered
          };
        }
          return {
            orderId: order.id,
            quantity: order.orderQuantity,
            orderQuantity: order.orderQuantity,
            productId: order.productId,
            productName: order.productName,
            dose: order.dose,
            locationId: order.locationId,
            unitOfMeasure: order.unitOfMeasure
          };
      });

      await connectToGraphqlAPI({
        graphqlQuery: saveOrders,
        variables: {
          agentId: user.username,
          productOrders,
          eventsForOrder: orderEvents
        }
      });

      callSaveOrderReport(productOrders); // send unique order ids to report if userEntered order and not an order from an appointment. Save report on the server
    } catch (error) {
      alert('dev handleCreateOrder error');
      console.log('dev handleCreateOrder error: ', error);
    }

    clearAll();
  };

  const clearAll = () => {
    // need to clear it out of history
    props.history.replace({
      pathname: '/inventory',
      state: { searchType: 'OrderProduct', orders: [], eventsForOrder: [] }
    });

    setOrderProducts([]);
    setOrderEvents([]);
  };

  // Validation
  const receiveProductForm = {
    Location: {
      value: '',
      inputValidator: value => {
        return validateInput({
          referralId: { ...receiveProductForm.Location, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    },
    Product: {
      value: '',
      inputValidator: value => {
        return validateInput({
          referralId: { ...receiveProductForm.Product, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    },
    amtOfProduct: {
      value: '',
      inputValidator: value => {
        return validateInput({
          referralId: { ...receiveProductForm.amtOfProduct, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    }
  };

  return (
    <div className="row">
      <div className="col-11 mt-2 mr-3 ml-3">
        {dialogOption && dialogOption.showDialog && (
          <MessageDialog dialogOption={dialogOption} />
        )}
        <div className="row">
          <div className="col-md-12 mb-4 pageTitle">
            Create Product Manifest
          </div>
        </div>
        <Form
          onSubmit={handleAddOrderProduct}
          render={formRenderProps => (
            <form
              id="formAddProduct"
              onSubmit={formRenderProps.onSubmit}
              className="k-form pl-3 pr-3 pt-1"
            >
              <div className="row mt-02">
                <div className="col-md-2">
                  <Field
                    component={DropDownListField}
                    data={listLocationAICsData}
                    label="AIC Location"
                    name="Location"
                    textField="text"
                    valueField="locationId"
                    /* defaultItem={selectedLocation} */
                    onChange={handleLocationChange}
                    validator={receiveProductForm.Location.inputValidator}
                  />
                </div>
                <div className="col-md-3">
                  <Field
                    component={DropDownListField}
                    data={listProductsDataFiltered}
                    label="Product"
                    name="Product"
                    textField="productOnSelector"
                    valueField="productId"
                    /* defaultItem={selectedProduct} */
                    onChange={handleProductChange}
                    validator={receiveProductForm.Product.inputValidator}
                  />
                </div>
                <div className="col-md-2">
                  <Field
                    component={InputField}
                    name="amtOfProduct"
                    label="Quantity:"
                    validator={receiveProductForm.amtOfProduct.inputValidator}
                  />
                </div>
                <div className="col-md-1 mt-12 mb-3">
                  <button
                    type="submit"
                    className="k-button blue"
                    form="formAddProduct"
                  >
                    Add New
                  </button>
                </div>
                <div className="col-md-1 mt-12 mb-3">
                  <button
                    type="button"
                    onClick={() => {
                      formRenderProps.onFormReset();
                      clearAll();
                    }}
                    className="k-button ml-3"
                  >
                    Clear All
                  </button>
                </div>
              </div>
            </form>
          )}
        />

        <Grid data={orderProducts}>
          <Column field="aicLocation" title="AIC Location" />
          <Column field="orderDate" title="Order Date" />
          <Column field="orderedBy" title="Order By" />
          <Column field="initialQuantity" title="Order Qty" />
          <Column field="product" title="Product" />
          <Column field="medications" title="Strength" />
          <Column
            field="orderQuantity"
            title="Order Amount"
            cell={customCell}
          />
          {/* <Column field="actionDelete" title=" " cell={customCellDelete} /> */}
        </Grid>

        <div className="row mt-3">
          <div className="col-2">
            <button
              type="button"
              primary="true"
              // onClick={handleCreateOrder}
              onClick={toggleCreateOrderDialog}
              className="k-button pageButton mr-1 mt-3"
            >
              Create Order
            </button>
          </div>
        </div>
      </div>
      <WindowDialog
        title="Order Report"
        width={900}
        height={500}
        initialTop={50}
        showDialog={visibleCreateOrderDialog}
        onClose={toggleCreateOrderDialog}
      >
        <Form
            // onSubmit={handleSubmit}
          render={formRenderProps => (
            <form
              onSubmit={formRenderProps.onSubmit}
              className="k-form pl-3 pr-3 pt-1"
            >
              <div style={{ backgroundColor: '#ffffff' }}>
                <PDFExport
                  ref={pdfExportComponent}
                  paperSize="auto"
                  margin={40}
                  fileName={`Order Report - ${orderReportId}`}
                  author="AleraCare"
                >
                  <div
                    className="row col-md-12 mt-1 ml-1"
                    style={{ textAlign: 'center', alignItems: 'center' }}
                  >
                    <div className="col-md-11 mt-12 mb-3">
                      <big>
                        <b>Order Report #: {orderReportId}</b>
                      </big>
                    </div>
                  </div>
                  <div className="row" style={{ textAlign: 'center' }}>
                    <div
                      className="col-md-11"
                      style={{ textAlign: 'center' }}
                    >
                      <b>
                        Date Reported: <i>{getNow()}</i>
                      </b>
                    </div>
                  </div>
                  <div className="row col-md-12 mt-2">
                    <div className="col-md-12 mt-0  mb-2">
                      <Grid className="infusion-grid" data={orderProducts}>
                        <Column
                            field="aicLocation"
                            title="AIC LOCATION"
                            width="200"
                            // cell={reportProductCell}
                          />
                        <Column
                            field="product"
                            title="PRODUCT"
                            width="450"
                            cell={reportProductCell}
                          />
                        <Column
                            field="orderQuantity"
                            title="QTY"
                            width="75"
                          />
                      </Grid>
                    </div>
                  </div>
                  {/* <div className="row mt-3">
                      <div className="col-md-12 ml-3 mt-14">
                        Order Submitted to BlueLink: &nbsp;
                        <Field
                          name="isOrdered"
                          onLabel="Yes"
                          offLabel="No"
                          component={Switch}
                        />
                      </div>
                    </div> */}
                </PDFExport>
                <div className="row p-3 mt-3">
                  <div className="col-12">
                    <button
                      type="submit"
                      className="k-button pageButton Blue"
                      onClick={handleSubmit}
                    >
                      Submit & Print
                    </button>
                  </div>
                </div>
              </div>
            </form>
            )}
        />
      </WindowDialog>
    </div>
  );
};
export default OrderProduct;
