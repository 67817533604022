import React from "react";
import { PropTypes } from "prop-types";

export const CodesInfo = ({ codes }) => {
    return(
        <li ><span className="billing-style">Code:</span>&nbsp;{codes.code || "-"} <span className="billing-style" >Units:</span>&nbsp;{codes.units || "-"}</li>
    )
}
CodesInfo.propTypes = {
    codes: PropTypes.object,
  };