import * as React from 'react';
import { useContext, useRef } from 'react';
import { FollowUpContext } from '../../context/FollowUpContext';
import { connectToGraphqlAPI } from '../../provider';
import FullPageLoader from './FullPageLoader';

// RequestGridData to be used with on onDataStateChange discribed in the kendo documentation at:
// https://www.telerik.com/kendo-react-ui/components/grid/data-operations/local-operations/
export const RequestGridData = (props) => {
  const { followUpReload, setFollowUpReload } = useContext(FollowUpContext);
  const {
    dataState, // is used to compare if data exists
    graphqlQuery, // Query Name
    onDataReceived, // Function to send data in parent component
    childToParent, // controls the dataReloadSwitch from other components.
    // In this example childToParent is a connection between two components,
    // FollowUp to PatientCallWorkInProgress then PatientCallWorkInProgress to RequestGridData
    variables // variables for call
  } = props;
  // useRef can be used like state but it does not cause re-renders //https://www.youtube.com/watch?v=t2ypzz6gJm0
  const lastSuccess = useRef('');
  const pending = useRef('');
  const requestDataIfNeeded = async () => {
    if (followUpReload === true) {
      pending.current = '';
      lastSuccess.current = '';
      setFollowUpReload(!followUpReload);
    }
    if (pending.current || dataState === lastSuccess.current) {
      return;
    }
    pending.current = dataState;
    await connectToGraphqlAPI({
      graphqlQuery,
      variables
    }).then((data) => {
      lastSuccess.current = pending.current;
      pending.current = '';
      if (dataState === lastSuccess.current) {
        onDataReceived.call(undefined, {
          data: data.data
        });
      } else {
        requestDataIfNeeded();
      }
    });
  };
  requestDataIfNeeded();
  return pending.current ? <FullPageLoader /> : null;
};
