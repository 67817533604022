import React from 'react';
import styled from 'styled-components';
import PrescriberAddressCard from './PrescriberAddressCard';

const AddressContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  justify-content: center;
  padding: 15px 0;
  flex-wrap: wrap;
`;

const PrescriberAddresses = ({
  addresses,
  onClickAddUpdateAddress,
  onAddOrUpdate
}) => {
  return (
    <AddressContainer className="row">
      {addresses.map((address, index) => (
        <PrescriberAddressCard
          addressInfo={address}
          key={`address_${index}`}
          index={index}
          onClickAddUpdateAddress={onClickAddUpdateAddress}
          onAddOrUpdate={onAddOrUpdate}
          isPrimary={address?.default || index === 0}
        />
      ))}
    </AddressContainer>
  );
};

export default PrescriberAddresses;
