import React, { useContext } from 'react';

// components
import AwesomeLabel from '@/components/common-components/AwesomeLabel';
import PortalCalls from '@/components/Queue/CallComponent/PortalCalls';
import WorkItemTimeoutModal from '@/components/WorkItemTimeoutModal/WorkItemTimeoutModal';
import { LinkToPatientProfile } from '@/components/common-components/LinkToPatientProfile/LinkToPatientProfile';

// partials
import WorkItemLog from '../WorkItemLog';
import CallHistoryLog from '../CallHistoryLog';
import WorkItemAudience from '../WorkItemAudience';
// context
import { WorkItemContext } from '@/context';

// helpers
import { toProperCase } from '@/common/Converters';

// styles
import styles from '../CurrentWorkItem.module.scss';

const OutboundCall = () => {
  const { selectedWorkItem, numberOfCallAttempts } =
    useContext(WorkItemContext);
  const title = toProperCase(
    selectedWorkItem?.attachedData?.followUpType || selectedWorkItem?.work
  );
  const hasNotes =
    selectedWorkItem.description && selectedWorkItem.description !== '';

  return (
    <>
      <div className='row'>
        <div className='col-4'>
          <h5>
            Complete: {title}
            <WorkItemAudience />
          </h5>
          <div className={styles.itemRow}>
            <LinkToPatientProfile />
          </div>
          <div className={styles.itemRow}>
            <AwesomeLabel
              label={`${numberOfCallAttempts}`}
              icon='phone'
              title='Call Attempts'
            />
          </div>
          <div>
            <CallHistoryLog customClassName='k-iconWhite' />
          </div>
          <div>&nbsp;</div>
          {hasNotes && (
            <div>
              <AwesomeLabel
                label={selectedWorkItem.description}
                icon='notes'
                title='Notes'
              />
            </div>
          )}
          <div className={styles.itemRow}>
            <WorkItemLog />
          </div>
        </div>
        <div className='col-8'>
          <PortalCalls />
        </div>
      </div>
      <WorkItemTimeoutModal />
    </>
  );
};

export default OutboundCall;
