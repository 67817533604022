export const pathToProjectMap = {
  '/': 'Welcome',
  '/patient-portal': 'Patient Management Portal',
  '/scheduling-queue': 'Scheduling Queue',
  '/nurse-queue': 'Nursing Queue',
  '/infusion-portal': 'Patient Infusion Portal',
  '/infusion-queue': 'Infusion Queue',
  '/scheduler': 'Scheduling Calendar',
  '/admin': 'Data Administration',
  '/inventory': 'Inventory',
  '/billing': 'Billing Queue',
  '/treatment-history': 'Treatment History',
  '/clinician': 'Clinician Queue',
  '/pa-queue': 'Prior Auth Queue',
  '/bi-queue': 'BI Queue',
  '/patient-call': 'Patient Call Queue',
  '/releases': 'Release Notes',
  '/failed-fax-queue': 'Failed Fax Queue',
  '/dashboard-queue': 'Dashboard'
};
