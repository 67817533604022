export const returnReasons = {
  BY_TIMEOUT: 'Work item automatically returned to the queue by timeout',
  BY_AGENT: 'Work item was returned by agent "XXX"',
  BY_ADMIN: 'Work item was forcely returned by admin "XXX"',
  UNKNOWN: 'Unknown reason'
};

export const logRecords = {
  CREATED_BY_SYSTEM: 'Work item created by system',
  UPDATED_BY_AGENT: 'Agent "XXX" updated work item'
};

export const inventorySources = {
  BUY_AND_BILL: 'Buy and Bill',
  FREE_DRUG: 'Free Drug'
};

export const orderTypes = {
  NEW_ORDER: 'New Order',
  EXISTING_ORDER: 'Existing Order',
  TRANSFER_ORDER: 'Transfer Order'
};

export const patientStatuses = {
  ACTIVE: 'Active',
  EXPEDITED: 'Expedited',
  INACTIVE: 'Inactive',
  ON_HOLD: 'On Hold',
  SEASONAL: 'Seasonal',
  STAT: 'Stat'
};
