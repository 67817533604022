import { QOL_PROVIDER_FOLLOWING } from '../../surveyDictionary';
import {
  generalPainLevel,
  common1to10Rate,
  commonYesNoField,
  followingInfusionPrompts,
  selfHarmWarning,
  memoryGuideWarning,
  selfCareGuideWarning,
  transportationWarning,
  foodBenefitsWarning,
  startOrSkipSurvey,
  skippingSurveyReasons,
  optOutSurveyReasons
} from '../modelBlocks';
import PHQ9Model from '../PHQ9Model';

export default {
  pages: [
    { ...startOrSkipSurvey },
    { ...skippingSurveyReasons },
    { ...optOutSurveyReasons },
    { ...followingInfusionPrompts },
    {
      elements: [
        // Category 1: Physical Assessment (PA)
        {
          visibleIf: '{survey-start-option} = 1',
          type: 'panel',
          name: 'panel-PA',
          title: 'Category 1: Physical Assessment',
          elements: [
            // Disease state status-Presence of disease effects (not feeling well)
            {
              ...common1to10Rate,
              name: 'PA-general-feel',
              title: 'How do you feel in general on most days?',
              minRateDescription: 'Horrible',
              maxRateDescription: 'Very Well'
            },
            // Disease state status-Activity level
            {
              ...common1to10Rate,
              name: 'PA-activity-level',
              title:
                'How hard is it to do all the activities you want to do because of your health?',
              minRateDescription: 'Extremely difficult',
              maxRateDescription: 'Very easy'
            },
            // Disease state status-Pain level
            {
              name: 'general-pain-scale',
              type: 'radiogroup',
              title:
                'When you have pain how would you rate your pain on a scale of 1 through 10?',
              choices: generalPainLevel,
              isRequired: true
            },
            // Disease state status-Appetite
            {
              ...common1to10Rate,
              name: 'PA-hard-to-eat',
              title: 'How often do you find it difficult to eat?',
              minRateDescription: 'Always',
              maxRateDescription: 'Never'
            }
          ]
        }
      ]
    },
    {
      // Category 2: Emotional well-being
      elements: [
        {
          visibleIf: '{survey-start-option} = 1',
          type: 'panel',
          name: 'panel-EWB',
          title: 'Category 2: Emotional well-being',
          elements: [
            // Anxiety
            {
              ...common1to10Rate,
              name: 'EWB-anxiety',
              title:
                'How often are you experiencing stress, feeling anxious or worried?',
              minRateDescription: 'Always',
              maxRateDescription: 'Never'
            },
            // Depression
            {
              ...common1to10Rate,
              name: 'EWB-depression',
              title:
                'How often are you experiencing feeling sad, blue or depressed and/or experiencing low energy/interest/pleasure in performing day to day tasks?',
              minRateDescription: 'Always',
              maxRateDescription: 'Never'
            },
            // ==================
            // PHQ9 form trigger
            // ==================
            { ...selfHarmWarning },
            { ...PHQ9Model },
            // Sleep
            {
              ...common1to10Rate,
              name: 'EWB-sleep',
              title: 'How often do you have trouble sleeping?',
              minRateDescription: 'Always',
              maxRateDescription: 'Never'
            }
          ]
        }
      ]
    },
    // Category 3: Cognition
    {
      elements: [
        {
          visibleIf: '{survey-start-option} = 1',
          type: 'panel',
          name: 'panel-cognition',
          title: 'Category 3: Cognition',
          elements: [
            // Health literacy level
            {
              ...common1to10Rate,
              name: 'cognition-literacy-level',
              title:
                'How hard is it for you to understand information about your health and your medicines?',
              minRateDescription: 'Extremely difficult',
              maxRateDescription: 'Very easy'
            },
            // Remembering
            {
              ...common1to10Rate,
              name: 'cognition-remembering',
              title:
                'How easy is it for you to remember things? (observe answers to other questions for additional insight)',
              minRateDescription: 'Extremely difficult',
              maxRateDescription: 'Very easy'
            },
            { ...memoryGuideWarning },
            // Ability to problem solve
            {
              ...common1to10Rate,
              name: 'cognition-problem-solving',
              title:
                'When you have a problem (cite examples if needed) how easy is it for you to solve it?',
              minRateDescription: 'Extremely difficult',
              maxRateDescription: 'Very easy'
            },
            { ...selfCareGuideWarning }
          ]
        }
      ]
    },
    // Category 4: Level of Independence
    {
      elements: [
        {
          visibleIf: '{survey-start-option} = 1',
          type: 'panel',
          name: 'panel-personal-development',
          title: 'Category 4: Level of Independence',
          elements: [
            // Self-care
            {
              ...common1to10Rate,
              name: 'LoI-self-care',
              title:
                'How easy is it for you to take care of all of your daily needs yourself?',
              minRateDescription: 'Extremely difficult',
              maxRateDescription: 'Very easy'
            },
            // Access to Caregiver (if needed)
            {
              ...common1to10Rate,
              name: 'LoI-caregiver-access',
              title:
                "Do you have someone to help you when you can't do something for yourself?",
              minRateDescription: 'Never',
              maxRateDescription: 'Always',
              visibleIf: '{LoI-self-care} < 10'
            },
            // Resources-Transportation
            {
              ...commonYesNoField,
              name: 'LoI-transportation',
              title:
                'Do you have someone to take you to your medical appointments when you need them?'
            },
            { ...transportationWarning },
            // Resources-Food
            {
              ...commonYesNoField,
              name: 'LoI-food',
              title: 'Do you have access to the food you need daily?'
            },
            { ...foodBenefitsWarning }
          ]
        }
      ]
    },
    // Category 5: Patient Education
    {
      elements: [
        {
          visibleIf: '{survey-start-option} = 1',
          type: 'panel',
          name: 'panel-patient-education',
          title: 'Category 5: Patient Education',
          elements: [
            // Hospitalizations and/or ER Visits
            {
              ...commonYesNoField,
              name: 'EDU-hospitalizations',
              title:
                'Have you had to be admitted to the hospital or taken to the emergency room recently?'
            }
          ]
        }
      ]
    }
  ],
  showPrevButton: false,
  completedHtml: 'Survey completed, saving data...',
  clearInvisibleValues: 'onHidden',
  questionsOnPageMode: 'singlePage',
  version: 2,
  surveyType: QOL_PROVIDER_FOLLOWING,
  surveyTitle: 'AQCCA Provider Survey - Following Administrations'
};
