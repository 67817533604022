import * as moment from 'moment';

export const getDateString = (value) => {
    if (!value) {
        return null;
    }
    const returnVal = moment(value).format('YYYY-MM-DD');
    if (returnVal === 'Invalid date') {
        return null;
    }
    return returnVal;
};

export const getDateValueToInitialize = (value) => {
    if (!value?.length) {
        return null;
    }
    const date = moment(value);
    if (!date || date === 'Invalid date') {
        return null;
    }
    return new Date(date.add(new Date().getTimezoneOffset(), 'minutes'));
};
