import React, { createContext, useState, useContext, useRef } from 'react';
import LogRocket from 'logrocket';

// context
import { NotifContext } from './NotifContext';
import { UserContext } from './UserContext';

// graphQL
import { connectToGraphqlAPI, getQueryClient } from '@/provider';
import { getWorkItemsByGroupQueue } from '@/graphql/queries';
import { updateWorkItemStatus } from '@/graphql/mutations';
import {
  onUpdateWorkItemStatus,
  onAcquireWork,
  onReleaseWork
} from '@/graphql/subscriptions';

// constants
import { groupQueues, taskStatus } from '@/constants/enum';

// helpers
import { formatDateToAWS } from '@/common/DateHelper';
import { sortByField } from '@/common/SortHelper';
import { prepareWorkItemData } from './InboundContextHelper';

export const InboundContext = createContext();

const InboundContextProvider = ({ children }) => {
  const { showWarning } = useContext(NotifContext);
  const { agentId } = useContext(UserContext);

  const [inboundWorkItems, setInboundWorkItems] = useState([]);
  const [isInboundLoading, setIsInboundLoading] = useState(false);
  const [isInboundDataLoaded, setIsInboundDataLoaded] = useState(false);
  const [showInboundPreview, setShowInboundPreview] = useState(false);
  const [inboundPreviewData, setInboundPreviewData] = useState();
  const [needToBeReleased, setNeedToBeReleased] = useState(true); // true by default, so all work items should be released until we change the value

  const inboundRef = useRef();
  inboundRef.current = inboundWorkItems;

  const logApiException = (err, props) => {
    LogRocket.captureMessage('API call exception', {
      extra: {
        errorMessage: err.errors[0]?.message,
        ...props
      }
    });
  };

  const fetchInboundWorkItems = async () => {
    try {
      setIsInboundLoading(true);

      const data = await getQueryClient({
        query: getWorkItemsByGroupQueue,
        payload: {
          groupQueueId: groupQueues.INBOUND
        },
        path: 'getWorkItemsByGroupQueue'
      });

      if (data?.length > 0) {
        const inboundWorkItems = data
          .map(
            ({
              id,
              agentFirstName,
              agentLastName,
              createdAt,
              source,
              description,
              documentURI,
              workStatus
            }) => {
              const hasAgentName = agentFirstName && agentLastName;
              return {
                id,
                date: formatDateToAWS(createdAt),
                createdAt,
                source,
                description,
                url: documentURI,
                status: workStatus,
                ...(hasAgentName && {
                  prescriberInfo: `${agentFirstName} ${agentLastName}`
                })
              };
            }
          )
          .sort((a, b) => sortByField(a, b, 'createdAt'));

        setIsInboundLoading(false);
        setIsInboundDataLoaded(true);
        setInboundWorkItems(inboundWorkItems);

        // subscribing to all future changes now
        onSubscribe();
      } else {
        showWarning('No faxes found in the queue at this moment');
      }
      setIsInboundLoading(false);
    } catch (err) {
      setIsInboundLoading(false);
      setIsInboundDataLoaded(false);

      logApiException(err, {
        view: 'InboundContext',
        endpoint: 'getWorkItemsByGroupQueue',
        groupQueueId: groupQueues.INBOUND
      });
    }
  };

  // @TODO: not in use anywhere, candidate to be removed in next releases
  const updateInboundWorkItem = async (workItemId, workStatus, reason) => {
    try {
      const input = {
        agentId,
        workItemId,
        workStatus
      };

      if (reason) {
        input.reasonsForStatusChange = reason;
      }

      const res = await getQueryClient({
        query: updateWorkItemStatus,
        payload: {
          input
        },
        path: 'updateWorkItemStatus'
      });
      if (!res?.length) {
        console.error(
          'CreateWorkItemDialog::updateWorkItemStatus - no data returned from the server'
        );
      } else {
        updateStatus(workItemId, workStatus);
      }
    } catch (error) {
      console.error(
        'CreateWorkItemDialog::updateWorkItemStatus error: ',
        error
      );
      logApiException(error, {
        view: 'CreateWorkItemDialog',
        endpoint: 'updateWorkItemStatus',
        agentId,
        workItemId
      });
    }
  };

  // QUEUE UPDATE SUBSCRIPTIONS
  const subscribeStatusUpdate = async () => {
    try {
      await connectToGraphqlAPI({
        graphqlQuery: onUpdateWorkItemStatus
      }).subscribe({
        next: ({ value }) => {
          const { workItem, success } = value.data.onUpdateWorkItemStatus;

          if (success && workItem.groupQueueId === groupQueues.INBOUND) {
            switch (workItem.workStatus) {
              case taskStatus.COMPLETED:
                deleteInboundWorkItem(workItem.id);
                break;
              default:
                break;
            }
          }
        }
      });
    } catch (err) {
      logApiException(err, {
        view: 'InboundContext',
        endpoint: 'onUpdateWorkItemStatus',
        type: 'subscription error'
      });
    }
  };

  const subscribeWorkAcquire = async () => {
    try {
      await connectToGraphqlAPI({
        graphqlQuery: onAcquireWork
      }).subscribe({
        next: ({ value }) => {
          const { workItem, success } = value.data.onAcquireWork;
          if (success && workItem.groupQueueId === groupQueues.INBOUND) {
            deleteInboundWorkItem(workItem.id);
          }
        }
      });
    } catch (err) {
      logApiException(err, {
        view: 'InboundContext',
        endpoint: 'onAcquireWork',
        type: 'subscription error'
      });
    }
  };

  const subscribeWorkRelease = async () => {
    try {
      await connectToGraphqlAPI({
        graphqlQuery: onReleaseWork
      }).subscribe({
        next: ({ value }) => {
          const { workItem, success } = value.data.onReleaseWork;
          if (
            success &&
            workItem.workStatus !== taskStatus.COMPLETED &&
            workItem.groupQueueId === groupQueues.INBOUND
          ) {
            // check if the item is in the queue
            const releasedItem = getInboundWorkItem(workItem.id);

            if (!releasedItem) {
              // if not - we can add it back
              const workItemData = prepareWorkItemData(workItem);
              addInboundWorkItem(workItemData);
            }
          }
        }
      });
    } catch (err) {
      logApiException(err, {
        view: 'InboundContext',
        endpoint: 'onReleaseWork',
        type: 'subscription error'
      });
    }
  };

  // subscribe to all channels
  const onSubscribe = () => {
    subscribeStatusUpdate();
    subscribeWorkAcquire();
    subscribeWorkRelease();
  };

  const getInboundWorkItem = (id) =>
    inboundWorkItems.find((workItem) => workItem.id === id);

  const addInboundWorkItem = (workItem) => {
    const cloned = [...inboundRef.current];
    cloned.push(workItem);
    setInboundWorkItems(cloned);
  };

  const deleteInboundWorkItem = (id) => {
    const cloned = [...inboundRef.current];
    const filtered = cloned.filter((workItem) => workItem.id !== id);
    setInboundWorkItems(filtered);
  };

  const updateStatus = (id, status) => {
    const cloned = [...inboundRef.current];
    const itemForUpdate = cloned.find((item) => id === item.id);

    if (itemForUpdate) {
      itemForUpdate.status = status;

      // update local state
      setInboundWorkItems(cloned);
    }
  };

  return (
    <InboundContext.Provider
      value={{
        inboundWorkItems,
        setInboundWorkItems,
        getInboundWorkItem,
        deleteInboundWorkItem,
        isInboundLoading,
        setIsInboundLoading,
        fetchInboundWorkItems,
        isInboundDataLoaded,
        updateInboundWorkItem,
        showInboundPreview,
        setShowInboundPreview,
        inboundPreviewData,
        setInboundPreviewData,
        setNeedToBeReleased,
        needToBeReleased
      }}
    >
      {children}
    </InboundContext.Provider>
  );
};

export default InboundContextProvider;
