import { Storage } from 'aws-amplify';

function downloadBlob(blob, filename) {
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename || 'download';
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener('click', clickHandler);
    }, 150);
  };
  a.addEventListener('click', clickHandler, false);
  a.click();
  return a;
}

// allows to download files from S3 bucket
export async function downloadFromBucket(filePath, fileName) {
  const result = await Storage.get(filePath, { download: true });
  downloadBlob(result.Body, fileName);
}
