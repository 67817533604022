import React from 'react';
import {
  PopupTableContainer,
  PopupTd,
  PopupTh,
  PopupTr
} from './Calendar.styled';

const CalendarEventPopup = ({ rows }) => {
  return (
    <PopupTableContainer className='row infusionTable'>
      <table width='100%' cellPadding='4' border='0'>
        <tbody>
          {rows.map((row, i) => {
            return (
              <PopupTr key={i}>
                <PopupTh>{row.key}:</PopupTh>
                <PopupTd>{row.value}</PopupTd>
              </PopupTr>
            );
          })}
        </tbody>
      </table>
    </PopupTableContainer>
  );
};

export default CalendarEventPopup;
