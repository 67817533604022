import React, { useEffect, useState } from 'react';
//utils
import { PropTypes } from 'prop-types';
// gql
import { getBillingHistoryInfo } from '@/graphql/queries';
import { connectToGraphqlAPI } from '@/provider';
// components
import { BillingHistoryInfo } from './BillingHistoryInfo';

export const PatientBillingInfoData = ({ id }) => {
  const [billingHistoryInfo, setBillingHistoryInfo] = useState();
  // patient 838997353 has fake billing data in devx for future upgrades
  const getBillingHistoryInfoCall = async () => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getBillingHistoryInfo,
        variables: { id }
      });
      if (data) {
        const billing = data?.data?.getBillingHistoryInfo;
        if (!!billing?.treatmentHistory) {
          billing.treatmentHistory = JSON.parse(
            data?.data?.getBillingHistoryInfo?.treatmentHistory
          );
        }
        setBillingHistoryInfo(billing);
      }
    } catch (err) {
      console.error('err in retrieving getBillingHistoryInfo', err);
      setBillingHistoryInfo(null); //todo: show error dialog
    }
  };

  useEffect(() => {
    if (!id?.length) {
      return;
    }
    setBillingHistoryInfo(null);
    getBillingHistoryInfoCall();
  }, [id]);

  if (!id?.length) {
    return null;
  }
  if (!billingHistoryInfo) {
    return (
      <>
        <p style={{ margin: '20px' }}>Loading...</p>
      </>
    );
  }
  return <BillingHistoryInfo billing={billingHistoryInfo} />;
};

PatientBillingInfoData.propTypes = {
  id: PropTypes.string
};
