import React, { useContext } from "react";
import { SurveyContext } from "@/context/SurveyContext";
import AwesomeLink from "@/components/common-components/AwesomeLink";

const SurveyCloseLink = () => {
  const {
    setActiveSurvey,
    setSurveyResults,
    setSurveyHeader,
    setSurveyFollowup
  } = useContext(SurveyContext);

  return (
    <p className="text-right">
      <AwesomeLink
        onClick={() => {
          setActiveSurvey(null);
          setSurveyResults(null);
          setSurveyFollowup(null);
          setSurveyHeader(null);
        }}
        icon="xmark"
        label="CLOSE"
      />
    </p>
  );
};

export default SurveyCloseLink;
