import React from 'react';

import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Form, Field } from '@progress/kendo-react-form';
import { Input, Switch } from '@progress/kendo-react-inputs';
import { TimePicker } from '@progress/kendo-react-dateinputs';

const RevisitAdmin = (props) => {
  return (
    <div className='infusion-page'>
      {/* VITALS */}
      <Form
        onSubmit={props.handleAddVitals}
        render={(formRenderProps) => (
          <form
            onSubmit={formRenderProps.onSubmit}
            className={'k-form pl-3 pr-3 pt-1'}
          >
            <div
              className='infusion-details col-md-11 mt-2 mb-3'
              style={{ border: '1px solid #afaaaa' }}
            >
              <div className='row'>
                <div className='infusion-HeaderRow col-12 ml-0 pl-2 py-2 mr-0'>
                  <div className='row'>
                    <div className='col-md-2 headerText'>VITALS</div>
                    <div className='col-md-2 headerText'>&nbsp;</div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-2 ml-3'>
                  Time:
                  <br />
                  <Field name='time' label='' component={TimePicker} />
                </div>
                <div className='col-md-1'>
                  <Field name='temp' label='Temp' component={Input} />
                </div>
                <div className='col-md-1'>
                  <Field name='bp' label='BP' component={Input} />
                </div>
                <div className='col-md-1'>
                  <Field name='hr' label='HR' component={Input} />
                </div>
                <div className='col-md-1'>
                  <Field name='r' label='R' component={Input} />
                </div>
                <div className='col-md-1'>
                  <Field name='spo2' label='SPO2' component={Input} />
                </div>
                <div className='col-md-1'>
                  <Field name='initials' label='INITIALS' component={Input} />
                </div>
                <div className='col-md-2 mt-12'>
                  <button type='submit' className='k-button blue'>
                    ADD
                  </button>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12 mt-12 mb-2'>
                  <Grid className='infusion-grid' data={props.itemVitals}>
                    <Column field='time' title='TIME' />
                    <Column field='temp' title='TEMP' />
                    <Column field='bp' title='BP' />
                    <Column field='hr' title='HR' />
                    <Column field='r' title='R' />
                    <Column field='spo2' title='SPO2' />
                    <Column field='initials' title='INITIALS' />
                    <Column
                      field='action'
                      title=' '
                      cell={props.customCellDeleteVitals}
                    />
                  </Grid>
                </div>
              </div>
            </div>
          </form>
        )}
      />
      {/* IV / SUBQ */}
      <div
        className='infusion-details col-md-11 mt-2 mb-3'
        style={{ border: '1px solid #afaaaa' }}
      >
        <div className='row'>
          <div className='infusion-HeaderRow col-12 ml-0 pl-2 py-2 mr-0'>
            <div className='row'>
              <div className='col-md-8 headerText'>
                <strong>IV / IVP</strong>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12 mt-1 mb-2'>
            <Grid className='infusion-grid' data={props.itemIVs}>
              <Column field='time' title='TIME' />
              <Column field='event' title='EVENT' />
              <Column field='rate' title='RATE' />
              <Column field='visualNotes' title='VISUAL NOTES' />
              <Column field='totalInfusion' title='TOTAL INFUSION' />
              <Column field='initials' title='INITIALS' />
            </Grid>
          </div>
        </div>
      </div>
      {/* IM or IQ */}
      <div
        className='infusion-details col-md-11 mt-2 mb-3'
        style={{ border: '1px solid #afaaaa' }}
      >
        <div className='row'>
          <div className='infusion-HeaderRow col-12 ml-0 pl-2 py-2 mr-0'>
            <div className='row'>
              <div className='col-md-8 headerText'>
                <strong>IM / SUBQ</strong>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12 mt-3 mb-2'>
            <Grid className='infusion-grid' data={props.itemIMs}>
              <Column field='time' title='TIME' width='100px' />
              <Column field='location' title='LOCATION' />
              <Column field='amountMG' title='AMOUNT (MG)' />
              <Column field='visualNotes' title='VISUAL NOTES' />
              <Column field='initials' title='INITIALS' />
            </Grid>
          </div>
        </div>
      </div>
      {/* SUBMIT FORM */}
      <Form
        onSubmitClick={props.handleSubmit}
        render={(formRenderProps) => (
          <form
            onSubmit={formRenderProps.onSubmit}
            className={'k-form pl-3 pr-3 pt-1'}
          >
            <div className='row col-md-12 mt-3 mb-3'>
              <div className='col-md-3'>
                Administration Complete &nbsp;
                <Field
                  component={Switch}
                  onLabel='Yes'
                  offLabel='No'
                  name='isAdministrationComplete'
                  defaultChecked={true}
                  disabled={true}
                />
              </div>
            </div>
            <div className='row mt-5 mb-5'>
              <div className='col-md-2'>
                <button type='submit' className='k-button pageButton'>
                  Save
                </button>
              </div>
            </div>
          </form>
        )}
      />
    </div>
  );
};

export default RevisitAdmin;
