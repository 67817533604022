import React, { useContext } from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import AwesomeLabel from "@/components/common-components/AwesomeLabel";
import { formatDateToDefault } from "@/common/DateHelper";
import WarningBlock from "@/components/common-components/WarningBlock";
import { PatientContext } from "@/context/PatientContext";
import { InfusionContext } from "@/context/InfusionContext";
import { NotifContext } from "@/context/NotifContext";
import { updatePatientBucket } from "@/graphql/mutations";
import { connectToGraphqlAPI } from "@/provider";

const PatientMedsLog = props => {
  const { infusion, setInfusion } = useContext(InfusionContext);
  const { showError, showSuccess } = useContext(NotifContext);
  const { selectedPatientInfo, setSelectedPatientInfo } =
    useContext(PatientContext);

  // RENDER HELPERS
  const headerDrugCell = () => {
    return <AwesomeLabel icon="capsules" label="Drug Name" />;
  };

  const headerDosageCell = () => {
    return <AwesomeLabel icon="flask" label="Dosage" />;
  };

  const headerStartDateCell = () => {
    return <AwesomeLabel icon="calendar" label="Start Date" />;
  };

  const headerDiscDateCell = () => {
    return <AwesomeLabel icon="calendar" label="Disc. Date" />;
  };

  const headerFreqCell = () => {
    return <AwesomeLabel icon="hourglass-clock" label="Frequency" />;
  };

  const medCell = dataItem => {
    return renderCellValue(dataItem, "medication");
  };

  const dosageCell = dataItem => {
    return renderCellValue(dataItem, "dosage");
  };

  const freqCell = dataItem => {
    return renderCellValue(dataItem, "frequency");
  };

  const startDateCell = dataItem => {
    return renderCellValue(dataItem, "startDate");
  };

  const discDateCell = dataItem => {
    return renderCellValue(dataItem, "discontinuationDate");
  };

  const renderCellValue = (dataItem, itemField) => {
    return <td>{dataItem?.dataItem?.[itemField] || ""}</td>;
  };

  // ACTION HANDLERS
  const updateMedsCall = async medicationInfo => {
    try {
      const patientProfile = {
        ...selectedPatientInfo.patientProfile,
        medicationInfo
      };
      const patientId = selectedPatientInfo.patientId;

      const data = await connectToGraphqlAPI({
        graphqlQuery: updatePatientBucket,
        variables: {
          input: { patientId, patientProfile }
        }
      });

      if (data.data?.updatePatientBucket) {
        showSuccess("Patient profile successfully updated");

        // additionally need to update infusion context
        // just in case there's an active infusion in progress
        if (infusion && infusion.nursingProcess) {
          const patientBucket = { ...infusion.patientBucket };
          patientBucket.patientProfile.medicationInfo = [...medicationInfo];
          setInfusion({ ...infusion, patientBucket });
        }
      } else {
        showError(
          "Error in Patient Bucket update action. Please, check logs for details"
        );
      }
    } catch (err) {
      console.error("PatientMedsLog::updateMedsCall err:", err);
    }
  };

  const handleDeleteClick = ({ dataIndex }) => {
    // clone existing list to make the mutation next
    const medicationInfo = [
      ...selectedPatientInfo.patientProfile.medicationInfo
    ];

    // remove selected element from the list
    medicationInfo.splice(dataIndex, 1);

    // update patient info (UI)
    setSelectedPatientInfo({
      ...selectedPatientInfo,
      patientProfile: {
        ...selectedPatientInfo.patientProfile,
        medicationInfo
      }
    });

    // update patient bucket (server)
    updateMedsCall(medicationInfo);
  };

  const actionCell = props => {
    return (
      <td>
        <span className="remove-label" onClick={() => handleDeleteClick(props)}>
          <AwesomeLabel label="Remove" icon="xmark" />
        </span>
      </td>
    );
  };

  const data =
    selectedPatientInfo?.patientProfile?.medicationInfo &&
    selectedPatientInfo.patientProfile.medicationInfo.length > 0
      ? selectedPatientInfo.patientProfile.medicationInfo.map(medRecord => {
          const dosage = medRecord.dosage || "—";
          const frequency = medRecord.frequency || "—";
          const startDate = medRecord.startDate
            ? formatDateToDefault(medRecord.startDate)
            : "—";
          const discontinuationDate = medRecord.discontinuationDate
            ? formatDateToDefault(medRecord.discontinuationDate)
            : "—";

          return {
            ...medRecord,
            startDate,
            discontinuationDate,
            dosage,
            frequency
          };
        })
      : [];

  if (!data || data.length === 0)
    return <WarningBlock message="No records available" />;

  return (
    <Grid data={data} className="infusion-grid f10px">
      <Column field="medication" headerCell={headerDrugCell} cell={medCell} />
      <Column field="dosage" headerCell={headerDosageCell} cell={dosageCell} />
      <Column field="frequency" headerCell={headerFreqCell} cell={freqCell} />
      <Column
        field="startDate"
        headerCell={headerStartDateCell}
        cell={startDateCell}
      />
      <Column
        field="discontinuationDate"
        headerCell={headerDiscDateCell}
        cell={discDateCell}
      />
      {props.editable && <Column title="" cell={actionCell} width={75} />}
    </Grid>
  );
};

export default PatientMedsLog;
