import React from 'react';

// components
import WorkStatusBadge from './WorkStatusBadge';
import AwesomeLabel from './AwesomeLabel';

const WorkStatusTransition = ({ logRecord }) => {
  return (
    <>
      <WorkStatusBadge workStatus={logRecord.lastWorkStatus} />
      <AwesomeLabel icon="arrow-right" label=" " />
      <WorkStatusBadge workStatus={logRecord.afterWorkStatus} />
    </>
  );
};

export default WorkStatusTransition;
