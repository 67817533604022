import React, { useContext } from 'react';
import styled from 'styled-components';
import { PatientContext } from '@/context/PatientContext';
import Badge from '../common-components/Badge';

const BadgeWrapper = styled.div`
  text-align: center;
  padding-top: 16px;
`;

const PatientViivBanner = () => {
  const { selectedPatientInfo } = useContext(PatientContext);

  if (selectedPatientInfo.isInViiv) {
    return (
      <BadgeWrapper>
        <Badge text='VIIV STAT Program' type='info' />
      </BadgeWrapper>
    );
  }

  return null;
};

export default PatientViivBanner;

BadgeWrapper.displayName = 'BadgeWrapper';
