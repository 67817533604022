import React, { useContext } from 'react';

// context
import { WorkItemContext } from '@/context/WorkItemContext';
import AwesomeLabel from '@/components/common-components/AwesomeLabel';

const NextItem = () => {
  const { pendingWorkItems, goToNextWorkItem } = useContext(WorkItemContext);

  if (pendingWorkItems.length < 2) return null;

  return (
    <button onClick={goToNextWorkItem}>
      <AwesomeLabel icon="forward" label="Next Work Item" />
    </button>
  );
};

export default NextItem;
