import { isValidDate } from '@/common/DateHelper';

const processBarCode = codeValue => {
  let s01 = ''; // GTIN
  // let s21 = ""; // SN
  // let s70 = ""; // Rate
  let s17 = ''; // Exp Date
  let s10 = ''; // Lot Number
  let lot = '';
  let gtin = '';
  let expDate = '';

  if (codeValue.length < 28) {
    s10 = '';
    console.log('Invalid Barcode Length');
  } else if (codeValue.indexOf('~') >= 0) // MUST be a Tilde to parse
    {
      // pull seg 10 (Lot#) and 17(ExpDate)
      const sSegs = codeValue.split('~');
      s01 = sSegs.find(s => s.startsWith('01'));
      s10 = sSegs.find(s => s.startsWith('10'));
      s17 = sSegs.find(s => s.startsWith('17'));

      if (s17.length === 8) {
        const sYear = s17.substr(2, 2);
        const sMonth = s17.substr(4, 2);
        let sDay = s17.substr(6);
        if (sDay === '00') {
          // get last day of sMonth
          sDay = new Date(parseInt(`20${ sYear}`), parseInt(sMonth), 0).getDate();
        }

        s17 = `20${ sYear }-${ sMonth }-${ sDay } 12:00:00`;
      }
      lot = (s10.length > 2) ? s10.substr(2) : '';
      gtin = (s01.length > 2) ? s01.substr(2) : '';
      expDate = new Date(s17);

      if (!isValidDate(expDate)) {
        expDate = '';
      }
    }

  return { gtin, lot, expDate };
};

export default processBarCode;
