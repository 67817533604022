import React from "react";

// @NOTE: list of supported icons can be found here:
// https://www.telerik.com/kendo-react-ui/components/styling/icons/#toc-list-of-font-icons
const IconLabel = ({ icon = "", label = "", size = 16, iconFirst = true }) => {
  if (!label) {
    console.error("IconLabel component must have label as a property.");
    return "<label>";
  }

  if (!icon) return label;

  const rednerIcon = () => (
    <span className={`k-icon k-i-${icon} k-icon-${size}`}></span>
  );

  if (iconFirst) {
    return (
      <>
        {rednerIcon()}&nbsp; {label}
      </>
    );
  }

  return (
    <>
      {label}&nbsp;{rednerIcon()}
    </>
  );
};
export default IconLabel;
