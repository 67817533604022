import React, { useContext } from 'react';
import styled from 'styled-components';

// Common Components
import AwesomeLabel from '@/components/common-components/AwesomeLabel';

// Context
import {
  InfusionContext,
  SurveyContext,
  NotifContext,
  UserContext,
  LogContext,
  PatientContext
} from '@/context';

// GraphQL
import { connectToGraphqlAPI } from '@/provider';
import { addUpdateNotes, addPatientSurvey } from '@/graphql/mutations';

// Utils
import { formatDateToAWSDateTime } from '@/common/DateHelper';
import { generateSkipSurveyNotes } from '@/components/SurveyEngine/helpers/helperQOL';
import { getSurveyModel } from '@/components/SurveyEngine/models/surveyModelMapper';
import { REASON_TO_SKIP_SURVEY } from '@/components/SurveyEngine/surveyDictionary';

// Styled components
const SkipButton = styled.button`
  margin-top: 10px;
  min-width: 125px;
`;

const PatientSurveySkipButton = () => {
  const { infusion, setInfusion } = useContext(InfusionContext);
  const { setActiveSurvey } = useContext(SurveyContext);
  const { showError } = useContext(NotifContext);
  const { agent } = useContext(UserContext);
  const { logApiException } = useContext(LogContext);
  const { selectedPatientInfo, setSelectedPatientInfo } =
    useContext(PatientContext);

  const { agentId } = agent;
  const { patientId } = infusion.patientBucket;

  const uploadPatientNotes = async (notes) => {
    try {
      const requestObject = {
        patientId,
        agentId,
        notes: [
          {
            date: formatDateToAWSDateTime(),
            note: notes,
            type: 'AQCCA',
            createdBy: agentId,
            modifiedNote: false // send false to add new
          }
        ]
      };

      const data = await connectToGraphqlAPI({
        graphqlQuery: addUpdateNotes,
        variables: { input: requestObject }
      });

      if (data.data?.addUpdateNotes?.notes) {
        // do nothing :-)
      } else {
        showError('Failed to save new Patient Notes (type - AQCCA)');
      }
    } catch (err) {
      logApiException(err, {
        view: 'PatientSurveyActions',
        endpoint: 'addUpdateNotes',
        patientId,
        agentId
      });
    }
  };

  const uploadSurveyCall = async (patientSurvey) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: addPatientSurvey,
        variables: {
          agentId,
          patientId,
          patientSurvey
        }
      });
      if (data.data?.addPatientSurvey) {
        const surveyList =
          selectedPatientInfo.surveys && selectedPatientInfo.surveys.length > 0
            ? selectedPatientInfo.surveys
            : [];
        const surveys = [...surveyList, patientSurvey];
        setSelectedPatientInfo({ ...selectedPatientInfo, surveys });
      } else {
        showError('Failed to add Patient Survey results');
      }
    } catch (err) {
      logApiException(err, {
        view: 'PatientSurveySkipButton',
        endpoint: 'addPatientSurvey',
        patientId,
        agentId,
        surveyType: patientSurvey?.meta?.surveyType,
        version: patientSurvey?.meta?.version
      });
    }
  };

  if (infusion.isSurveyRequired && !infusion.isSurveyComplete) {
    return (
      <SkipButton
        type='button'
        className='k-button k-primary'
        onClick={() => {
          const model = getSurveyModel(REASON_TO_SKIP_SURVEY);
          const surveyStarted = new Date();

          // set the survey model
          setActiveSurvey({
            model,
            onFinish: async (surveyData) => {
              const jsonResults = JSON.parse(surveyData);

              // adding meta info for any future references
              jsonResults.meta = {
                surveyType: model.surveyType,
                version: model.version,
                surveyStarted,
                surveyFinished: new Date(),
                isSurveySkipped: true,
                infusionId: infusion.nursingProcess.id // this will link the infusion with the skipping
              };

              jsonResults.score = null;

              // save a note with reason for skipping survey at patient notes
              const notes = generateSkipSurveyNotes({
                agentId,
                reasonCode: jsonResults['survey-skipping-reason']
              });
              await uploadPatientNotes(notes);

              // add the notes to survey meta data
              jsonResults.notes = notes;

              // add timestamp which is now required
              jsonResults.collectedAt = formatDateToAWSDateTime();

              // prepare the PatientSurveyInput
              const inputBody = {
                survey: JSON.stringify(jsonResults),
                typeOfSurvey: model.surveyType,
                description: model.description,
                version: model.version,
                collectedAt: jsonResults.collectedAt
              };

              // upload results
              await uploadSurveyCall(inputBody);

              // Close the survey modal
              setActiveSurvey(null);

              // update skip flag in the infusion level
              setInfusion({ ...infusion, isSurveySkipped: true });
            }
          });
        }}
      >
        <AwesomeLabel icon='forward' iconFirst={false} label='Skip survey' />
      </SkipButton>
    );
  }

  return null;
};

// Display Names
SkipButton.displayName = 'SkipButton';

export default PatientSurveySkipButton;
