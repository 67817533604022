import React, { useEffect, useState, useContext } from 'react';
import { Storage } from 'aws-amplify';
import { GridColumn as Column } from '@progress/kendo-react-grid/dist/npm/GridColumn';
import { Grid } from '@progress/kendo-react-grid';
import { Card, CardBody, CardHeader } from '@progress/kendo-react-layout';
import { Dialog } from '@progress/kendo-react-dialogs';
import { process } from '@progress/kendo-data-query';

// components
import WarningBlock from '@/components/common-components/WarningBlock';
import { ColumnMenu } from '@/components/common-components/columnMenu';
import Badge from '@/components/common-components/Badge';
import ExpireBadge from '@/components/common-components/ExpireBadge';
import ReferralList from './ReferralList';

// utils
import {
  formatDateTimeToDefault,
  formatDateToDefault
} from '@/common/DateHelper';
import { getDocTypeTitle } from '@/common/Mappers';
import { referralType } from '@/components/Referral/ReferralMaps';

// provider
import { connectToGraphqlAPI } from '@/provider';

// gql
import {
  getLocationsAndProvidersByGroupId,
  getPriorAuthorization,
  GroupAIC
} from '@/graphql/queries';

// context
import { NotifContext, WorkItemContext } from '@/context';

// partials
import PatientDocument from '../PatientDocument';
import PatientWorkItems from './PatientWorkItems';

const getOrderStatus = (clinical) => {
  if (!clinical) return 'Incomplete';
  if (clinical.orderPending) {
    return 'Pending';
  }

  if (clinical.orderApproved) {
    return 'Approved';
  }

  if (clinical.orderDenied) {
    return 'Denied';
  }

  return 'Incomplete';
};

const initialDataState = {};
const initialResultState = { data: [], total: 0 };

const OverView = ({ selectedPatientInfo }) => {
  const [maxDate, setMaxDate] = useState();
  const [referralInformation, setReferralInfo] = useState();
  const [referralIdInfo, setReferralId] = useState();
  const [coPayInfo, setCoPay] = useState();
  const [checkingsInfo, setCheckings] = useState();
  const [paData, setPaData] = useState();
  const [patientDocsDataState, setPatientDocsDataState] =
    useState(initialDataState);
  const [patientDocsResultState, setPatientDocsResultState] =
    useState(initialResultState);

  const [labs, setLabs] = useState();
  const [itemAdministrations, setItemAdministrations] = useState([]);
  const { showError } = useContext(NotifContext);
  const { workItemData, setWorkItemData } = useContext(WorkItemContext);
  const [workItemCheck, setWorkItem] = useState(false);
  const [locationData, setlocationData] = useState(false);
  const [providersInfo, setProviders] = useState(false);
  const [providersName, setProviderName] = useState(false);
  const [selectedDocumentUrl, setSelectedDocumentUrl] = useState('');
  const [showPatientDocDialog, setShowPatientDocDialog] = useState(false);
  const [locationName, setlocationName] = useState(false);
  const [groupAICInfo, setGroupAIC] = useState('');
  const [clinical, setClinical] = useState('');

  const authStatus = paData?.priorAuthChecking?.authStatus;
  const priorAuthNumber =
    paData?.priorAuthChecking?.approvalInfo?.priorAuthNumber;

  const statusCheck = () => {
    if (authStatus === 'APPROVED') {
      const serviceTo = paData?.priorAuthChecking?.approvalInfo?.serviceTo;
      return serviceTo;
    }
  };

  useEffect(() => {
    setWorkItemData(false);
  }, []);

  useEffect(() => {
    if (workItemData) {
      const workItem =
        workItemData[
          workItemData.findIndex((x) => x.work === 'RE_VERIFICATION')
        ];
      if (workItem !== undefined) {
        setWorkItem(true);
      }
    }
  }, [workItemData]);

  const onlyRegularOrders =
    selectedPatientInfo?.referral?.drugReferrals?.filter(
      (order) => order.referralType !== referralType.LAB_DRAW
    );

  const order = onlyRegularOrders?.map((e) => e.referralOrder?.orderDate);

  const orderName = onlyRegularOrders?.map((item, i) => {
    if (item?.referralOrder?.orderDate === maxDate) {
      return <span key={i}> {item?.referralOrder?.orderName || '-'}</span>;
    }

    return null;
  });

  const toggleShowPatientDocDialog = () => {
    setShowPatientDocDialog(!showPatientDocDialog);
  };

  const cellDocType = ({ dataItem }) => {
    return <td>{getDocTypeTitle(dataItem.documentType)}</td>;
  };

  const cellDocDate = ({ dataItem }) => {
    return <td>{formatDateTimeToDefault(dataItem.date)}</td>;
  };
  const cellRecDate = ({ dataItem }) => {
    return dataItem.receivedAt ? (
      <td>{formatDateTimeToDefault(dataItem.receivedAt)}</td>
    ) : (
      <td>Not Available</td>
    );
  };

  const hyperLinkCell = (props) => {
    return (
      <td>
        {props.dataItem.documentPath && (
          <span
            className='blue-link'
            onClick={() => onDocumentRowHandle(props.dataItem)}
          >
            {props.dataItem.documentPath}
          </span>
        )}
      </td>
    );
  };

  const labResultsCell = ({ dataItem }) => {
    const renderResults = () => {
      if (dataItem.results) {
        const jsonResults = JSON.parse(dataItem.results);

        return jsonResults.labTestValue || '';
      }

      return '';
    };

    return <td>{renderResults()}</td>;
  };

  const customCell = ({ dataItem }) => {
    const renderEscalationLabel = () => {
      const curDate = new Date();
      const compareDate = new Date(
        curDate.getFullYear(),
        curDate.getMonth(),
        curDate.getDate() + 30
      );
      const labDate = new Date(dataItem.labExpiration);
      labDate.setDate(labDate.getDate() + 1);

      if (labDate < curDate) {
        return <Badge text='EXPIRED' />;
      }
      if (labDate <= compareDate) {
        return <Badge text='EXPIRES SOON' type='warning' />;
      }
      return null;
    };
    return (
      <td>
        {renderEscalationLabel()}
        {dataItem.labExpiration}
      </td>
    );
  };

  const patientStatusCheck = () => {
    if (selectedPatientInfo.patientStatus === 'INACTIVE')
      return <WarningBlock message='PATIENT IS INACTIVE' />;
  };

  const columns = [
    {
      field: 'creation',
      title: 'TEST DATE'
    },
    {
      field: 'labTest',
      title: 'LAB TEST'
    },
    {
      field: 'value',
      title: 'VALUE',
      cell: labResultsCell
    },
    {
      field: 'labExpiration',
      title: 'LAB EXPIRATION',
      cell: customCell
    }
  ];

  const onDocumentRowHandle = async (data) => {
    const conf = { download: false };
    const s3ImageURL = await Storage.get(data.documentPath, conf);
    setSelectedDocumentUrl(s3ImageURL);
    setShowPatientDocDialog(true);
  };

  let overrideChoice = '';
  if (checkingsInfo?.selectedBillingNPI !== undefined) {
    if (
      checkingsInfo?.selectedBillingNPI === checkingsInfo?.selectedProviderId
    ) {
      overrideChoice = 'PROVIDER';
    } else {
      overrideChoice = 'LOCATION';
    }
  } else {
    overrideChoice = '-';
  }

  /// call to PA
  const handleGetPriorAuthSubmit = () => {
    const requestObject = {
      insuranceKey: checkingsInfo?.insuranceKey,
      referralId: referralIdInfo,
      patientId: selectedPatientInfo?.patientId
    };

    getPriorAuthorizationCall(requestObject);
  };
  const handleLocationAndPaCall = () => {
    const requestObject = {
      groupId: checkingsInfo?.selectedGroupId
    };

    getLocationAndPaCall(requestObject);
  };

  const getPriorAuthorizationCall = async (requestObject) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getPriorAuthorization,
        variables: requestObject
      });

      setPaData(data?.data?.getPriorAuthorization);
    } catch (err) {
      console.log('Overview::getPriorAuthorizationCall err:', err);
      showError(
        `Error: getPriorAuthorizationCall.\nCheck to see if '${requestObject?.dataItem?.insuranceKey}' exists.`
      );
    }
  };

  /// Call get location AIC
  const getLocationAndPaCall = async (requestObject) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getLocationsAndProvidersByGroupId,
        variables: requestObject
      });
      setlocationData(data?.data?.getLocationsAndProvidersByGroupId);
    } catch (err) {
      console.log('Overview::getLocationAndPaCall err:', err);
    }
  };

  useEffect(() => {
    if (order !== undefined) {
      if (order.length !== 0) {
        const maxDates = order.reduce((first, second) =>
          first > second ? first : second
        );
        setMaxDate(maxDates);

        const referral = onlyRegularOrders.find(
          (item) => item.referralOrder?.orderDate === maxDate
        );

        if (referral) {
          setReferralInfo(referral.referralOrder);
          setLabs(referral.orderNotes?.labTests);
          setReferralId(referral.referralId);
          setClinical(referral?.clinical);
        }
      }
    }
  }, [order]);

  const itemsAdmin = () =>
    setItemAdministrations(
      labs?.map((item) => ({
        ...item,
        labExpiration: formatDateToDefault(item.labExpiration),
        creation: formatDateToDefault(item.date),

        note: item.note === 'undefined' ? '-' : item.note
      }))
    );

  useEffect(() => {
    if (locationData) {
      const locationDataFinal = locationData?.find(
        (item) => item.id === checkingsInfo?.selectedLocationId
      );
      if (locationDataFinal) {
        setlocationName(locationDataFinal.locationName);
        setProviders(locationDataFinal.providers);
      }
    }
  }, [locationData]);

  useEffect(() => {
    if (providersInfo) {
      const providersFinal = providersInfo?.find(
        (item) => item.providerNPI === checkingsInfo?.selectedProviderId
      );
      if (providersFinal) {
        setProviderName(
          `${providersFinal.firstName} ${providersFinal.lastName}`
        );
      }
    }
  }, [providersInfo]);

  useEffect(() => {
    itemsAdmin();
  }, [referralInformation]);

  useEffect(() => {
    const copayToFind =
      selectedPatientInfo?.benefitInvestigation?.benefitCheckings.find(
        (item) => item.referralId === referralIdInfo
      );

    if (copayToFind) {
      // 04.26.22 optimized code recommended by Ilia
      const mappedCoPays = copayToFind.checkings.map(
        (checking) => checking?.policy?.coPay || '-'
      );
      const checkingsCoPays = mappedCoPays.join(' / $');

      setCoPay(checkingsCoPays);
      setCheckings(copayToFind?.checkings[0]);
    }
  }, [referralIdInfo]);

  useEffect(() => {
    if (checkingsInfo) {
      handleGetPriorAuthSubmit();
    }
  }, [checkingsInfo]);

  useEffect(() => {
    if (paData) {
      handleLocationAndPaCall();
    }
  }, [paData]);

  useEffect(() => {
    if (checkingsInfo) {
      handleGetBenefitCheckingSubmit(checkingsInfo?.selectedGroupId);
    }
  }, [checkingsInfo]);

  useEffect(() => {
    setPatientDocsResultState(
      process(selectedPatientInfo.patientDocuments || [], patientDocsDataState)
    );
  }, [patientDocsDataState, selectedPatientInfo.patientDocuments]);

  const handleGetBenefitCheckingSubmit = (data) => {
    getGroupAIC(data);
  };

  const getGroupAIC = async (id) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: GroupAIC,
        variables: { id }
      });

      if (data) {
        setGroupAIC(data?.data?.getGroupAIC?.name);
      }
    } catch (err) {
      console.log('BulletingBoard::getBulletinCall err:', err);
    }
  };

  const renderBadge = () => {
    if (clinical?.orderApproved) {
      const checkdate =
        clinical?.expirationDateOfApproval !== null
          ? formatDateToDefault(clinical?.expirationDateOfApproval)
          : '-';

      return <ExpireBadge checkdate={checkdate} includeDIV={false} />;
    }

    return '-';
  };

  const renderExpireBadge = () => {
    return (
      <ExpireBadge
        checkdate={
          referralInformation?.orderExpires &&
          referralInformation.orderExpires !== null
            ? formatDateToDefault(referralInformation?.orderExpires)
            : '-'
        }
        includeDIV={false}
      />
    );
  };

  return (
    <div className='container-fluid'>
      <div className='a-grid__header'>
        <div>Patient Overview</div>
      </div>
      <PatientWorkItems />
      <Card className='mb-3'>
        <CardHeader>
          <div className='row'>
            <div className='col-5'>
              <i className='fa-solid fa-clipboard fa-xl icon-blue'>
                &nbsp;&nbsp;
              </i>
              <strong>
                NOTES: {patientStatusCheck()}&nbsp;{/* {itemCheck()} */}
              </strong>
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <div className='container-fluid'>
            <div className='row my-3'>
              <div className='col-12 headerText notesWrapper mt-1'>
                {selectedPatientInfo?.bulletinBoard || '-'}
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
      <ReferralList />
      <div className='row'>
        <div className='col-6'>
          <Card>
            <CardHeader>
              <div className='row'>
                <div className='col-5'>
                  <i className='fa-solid fa-file-invoice-dollar fa-xl icon-blue'>
                    &nbsp;&nbsp;
                  </i>
                  <strong>BILLING INFO:</strong>
                </div>
              </div>
            </CardHeader>
            <CardBody>
              <div className='row pl-4'>
                <div className='col-12'>
                  <div className='row mt-3'>
                    <b>Co-Pay(s):</b>&nbsp;${coPayInfo || '-'}
                  </div>
                  <div className='row mt-3'>
                    <b>AIC Group:</b>&nbsp; {groupAICInfo || '-'}
                  </div>
                  <div className='row mt-3'>
                    <b>AIC Location:</b>&nbsp; {locationName || '-'}
                  </div>
                  <div className='row mt-3'>
                    <b>AIC Provider:</b>&nbsp; {providersName || '-'}
                  </div>
                  <div className='row mt-3'>
                    <b>Location or Provider NPI: </b> &nbsp;
                    {overrideChoice || '-'}
                  </div>
                  <div className='row mt-3'>
                    <b>PRIOR AUTH INFO: </b> &nbsp;
                    {authStatus || '-'}
                  </div>
                  {statusCheck() && (
                    <>
                      <div className='row mt-2 ml-2'>
                        <b>Prior Auth No.: </b> &nbsp;
                        {priorAuthNumber || '-'}
                      </div>
                      <div className='row mt-2 ml-2'>
                        <b>Service To Date: </b> &nbsp;
                        <ExpireBadge checkdate={statusCheck()} includeDIV />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
        <div className='col-6'>
          <Card>
            <CardHeader>
              <div className='row'>
                <div className='col-5'>
                  <i className='fa-solid fa-clipboard-prescription fa-xl icon-blue'>
                    &nbsp;&nbsp;
                  </i>
                  <strong>REFERRAL INFO:</strong>
                </div>
              </div>
            </CardHeader>
            <CardBody>
              <div className='row pl-4'>
                <div className='col-12'>
                  <div className='row mt-3'>
                    <b>Order Name: </b>&nbsp;
                    {orderName}
                  </div>
                  <div className='row mt-3'>
                    <div className='col-5'>
                      <b>Order Date:</b> &nbsp;
                      {referralInformation
                        ? formatDateToDefault(referralInformation?.orderDate)
                        : '-'}
                    </div>
                    <div className='col-7'>
                      <b>Order Expires: </b>&nbsp;
                      {renderExpireBadge()}
                    </div>
                  </div>
                  <div className='row mt-3'>
                    <strong>DX Codes:</strong>
                  </div>
                  <div className='row'>
                    <div className='col-md-12 headerText mt-1'>
                      <b>
                        {referralInformation?.primaryDX?.primaryDiagnosis ||
                          '-'}
                      </b>
                      {' -  '}
                      {referralInformation?.primaryDX?.description}
                    </div>
                    <br />
                    {referralInformation?.otherDX?.length > 0 && (
                      <div className='col-md-12 headerText'>
                        {referralInformation?.otherDX?.map((dx, i) => (
                          <div className='mt-2' key={i}>
                            <b>{dx.primaryDiagnosis || '-'}</b>
                            {' - '}
                            {dx.description || '-'}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>

                  <div className='row mt-3'>
                    <strong>Referral Order Notes:</strong> &nbsp;
                    {referralInformation?.notes || '-'}
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>

      <Card className='mt-3'>
        <CardHeader>
          <div className='row'>
            <div className='col-5'>
              <i className='fa-solid fa-house-medical-circle-check fa-xl icon-blue'>
                &nbsp;&nbsp;
              </i>
              <strong>CLINICAL INFO</strong>
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <div className='container-fluid'>
            <div className='row my-3'>
              <div className='col-3 headerText mt-1'>
                <strong>Order Name: </strong> {orderName}
              </div>
              <div className='col-2 headerText mt-1'>
                <strong>Clinical Status: </strong> {getOrderStatus(clinical)}
              </div>

              <div className='col-3 headerText mt-1'>
                <strong>Expiration Date: </strong> {renderBadge()}
              </div>
              <div className='col-4 headerText notesWrapper mt-1'>
                <strong>Clinical Notes: </strong>{' '}
                {clinical?.clinicalNotes || '-'}
              </div>
            </div>
          </div>
        </CardBody>
      </Card>

      <Card className='mb-3 mt-3'>
        <CardHeader>
          <div className='row'>
            <div className='col-12 '>
              <i className='fa-solid fa-notes-medical fa-xl icon-blue'>
                &nbsp;&nbsp;
              </i>
              <strong>LABS:</strong>
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <Grid
            style={{ cursor: 'pointer', minHeight: 250 }}
            className='queue_grid'
            data={itemAdministrations}
            resizable
          >
            {columns.map((column, index) => {
              return (
                <Column
                  field={column.field}
                  title={column.title}
                  key={index}
                  width={column.minWidth}
                  columnMenu={ColumnMenu}
                  cell={column.cell || null}
                />
              );
            })}
          </Grid>
        </CardBody>
      </Card>
      <Card className='mb-8 mt-3'>
        <CardHeader>
          <div className='row'>
            <div className='col-5'>
              <i className='fa-solid fa-folder fa-xl icon-blue'>&nbsp;&nbsp;</i>
              <strong>PATIENT DOCUMENTS:</strong>
            </div>
          </div>
        </CardHeader>
        <CardBody className=''>
          <Grid
            className='queue_grid'
            style={{ height: '400px' }}
            data={{ data: patientDocsResultState.data }}
            editField='inEdit'
            selectedField='selected'
            onDataStateChange={(e) => setPatientDocsDataState(e.dataState)}
            sortable
            {...patientDocsDataState}
            total={1} // total set to stop paging error in the browser. Remove if you want to use paging.
          >
            <Column
              field='documentType'
              title='Document Type'
              width='150px'
              columnMenu={ColumnMenu}
              cell={cellDocType}
            />
            <Column
              field='receivedAt'
              title='Date System Received'
              width='195px'
              columnMenu={ColumnMenu}
              cell={cellRecDate}
            />
            <Column
              field='date'
              title='Date Added'
              width='180px'
              columnMenu={ColumnMenu}
              cell={cellDocDate}
            />
            <Column
              field='documentPath'
              title='Document'
              sortable={false}
              columnMenu={ColumnMenu}
              cell={hyperLinkCell}
            />
          </Grid>
        </CardBody>
      </Card>
      {showPatientDocDialog && (
        <Dialog
          title='Patient Document'
          height={1100}
          width={850}
          initialTop={100}
          showDialog
          onClose={toggleShowPatientDocDialog}
        >
          <PatientDocument file={selectedDocumentUrl} />
        </Dialog>
      )}
    </div>
  );
};

export default OverView;
