// AQCCA (QOL) - Nurse-driven surveys
import firstInfusionModelProvider from './provider/firstInfusionModelProvider';
import firstInfusionModelProviderV2 from './provider/firstInfusionModelProviderV2';
import followingInfusionModelProvider from './provider/followingInfusionModelProvider';
import followingInfusionModelProviderV2 from './provider/followingInfusionModelProviderV2';

// AQCCA (QOL) - Nurse-driven surveys
import aqccaModelPatient from './patient/aqccaModelPatient';

// Discontinuation surveys
import discontinuationModel from './discontinuation/discontinuationModel';
import discontinuationModelV2 from './discontinuation/discontinuationModelV2';
import discontinuationModelV3 from './discontinuation/discontinuationModelV3';
import discontinuationModelV4 from './discontinuation/discontinuationModelV4';

// utils
import * as dictionary from '../surveyDictionary';
import skipSurveyReason from './skipSurveyReason';

const surveyModelMapper = {
  // Provider (Nurse-driven) surveys
  [dictionary.QOL_PROVIDER_1ST_INFUSION]: {
    description: 'test description for QOL_PROVIDER_1ST_INFUSION',
    versions: {
      1: firstInfusionModelProvider,
      2: firstInfusionModelProviderV2
    },
    activeVersion: 2
  },
  [dictionary.QOL_PROVIDER_FOLLOWING]: {
    description: 'test description for QOL_PROVIDER_FOLLOWING',
    versions: {
      1: followingInfusionModelProvider,
      2: followingInfusionModelProviderV2
    },
    activeVersion: 2
  },
  // Patient-driven (tablet) surveys
  [dictionary.QOL_PATIENT_TABLET_SURVEY]: {
    description: 'test description for QOL_PATIENT_TABLET_SURVEY',
    versions: {
      1: aqccaModelPatient
    },
    activeVersion: 1
  },
  // Discontinuation surveys
  [dictionary.DISCONTINUATION_SURVEY]: {
    description:
      'Submitting details about the reasons for a referral order discontinuation',
    versions: {
      1: discontinuationModel,
      2: discontinuationModelV2,
      3: discontinuationModelV3,
      4: discontinuationModelV4
    },
    activeVersion: 4
  },
  // Skip survey reason during the infusion
  [dictionary.REASON_TO_SKIP_SURVEY]: {
    description: 'Skip Survey Reason',
    versions: {
      1: skipSurveyReason
    },
    activeVersion: 1
  }
};

// get correct model based on its type and version
//
// @param (required): modelType String
// @param (optional): version String | Number
// @returns Object
export const getSurveyModel = (modelType, version) => {
  const modelFromMapper = surveyModelMapper[modelType];

  // unknown modelType
  if (!modelFromMapper) {
    console.error(
      `Survey model with type '${modelType}' has not been found (surveyModelMapper.js)`
    );
    return null;
  }

  if (Object.hasOwn(modelFromMapper.versions, version)) {
    return modelFromMapper.versions[version];
  }

  // otherwise - version in unsupported or unknown
  console.warn(
    `Passed version '${version}' has not been found for model '${modelType}'. Though, we used the last known version (surveyModelMapper.js)`
  );

  // use the active version for the model
  return modelFromMapper.versions[modelFromMapper.activeVersion];
};

export default surveyModelMapper;
