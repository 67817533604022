import React from 'react';
import WarningBlock from '../common-components/WarningBlock';

// styles
import styles from './WorkItemTimeoutModal.module.scss';

const TextCountdown = ({ timeValue }) => {
  const minutes = Math.floor(timeValue / 60);
  const seconds = minutes === 0 ? timeValue : timeValue % 60;
  const minutesMsg = minutes > 0 ? `${minutes} minutes` : '';
  const secondsMsg = seconds > 0 ? `and ${seconds} seconds` : '';

  return (
    <div className={styles.warning}>
      <WarningBlock
        message={`Work Item will expire in ${minutesMsg} ${secondsMsg}`}
      />
    </div>
  );
};

export { TextCountdown };
