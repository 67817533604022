import React, { useState, useContext } from 'react';

// components
import WindowOverlay from '@/components/common-components/WindowOverlay';
import GeneralFollowUpMenu from './GeneralFollowUpMenu';
// kendo
import { Badge, BadgeContainer } from '@progress/kendo-react-indicators';
// styles
import styles from './GeneralFollowUp.module.scss';
// context
import { WorkItemContext } from '@/context';

const GeneralFollowUp = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { selectedWorkItem } = useContext(WorkItemContext);

  const hasHeaderWorkItem = !!selectedWorkItem?.id;

  const toggleDialog = () => {
    setIsVisible(!isVisible);
  };

  return (
    <>
      <div className='followup'>
        <div className={styles.category}>
          <BadgeContainer>
            <Badge
              themeColor={'success'}
              size='small'
              align={{ horizontal: 'start' }}
              cutoutBorder={false}
            >
              <span
                className={'k-icon k-i-plus'}
                onClick={() => {
                  toggleDialog();
                }}
              />
            </Badge>
            <span className={styles.category} style={{ marginLeft: '1.6rem' }}>
              FOLLOW-UPS &nbsp;&nbsp;
              <button
                type='button'
                onClick={() => toggleDialog()}
                className='k-button followUpButton'
              >
                <span className='k-icon k-i-plus' />
              </button>
            </span>
          </BadgeContainer>
        </div>
      </div>

      {isVisible && (
        <WindowOverlay
          title='Create Work Item'
          onClose={() => toggleDialog()}
          initialTop={hasHeaderWorkItem ? 300 : 100}
          initialHeight={710}
          initialWidth={550}
        >
          <GeneralFollowUpMenu toggleDialog={toggleDialog} />
        </WindowOverlay>
      )}
    </>
  );
};
export default GeneralFollowUp;
