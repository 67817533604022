import React, { createContext, useState, useEffect, useContext } from 'react';

// gql
import { connectToGraphqlAPI, getQueryClient } from '@/provider';
import { listInsurerAICs, listPayers } from '@/graphql/queries';

// context
import { NotifContext } from './NotifContext';
import { LogContext } from './LogContext';

// utils
import { makeFlatArray, sortArrayByField } from '@/common/Utility';

export const PayerContext = createContext();

const PayerContextProvider = ({ children, user }) => {
  const { showError } = useContext(NotifContext);
  const { logApiException } = useContext(LogContext);

  const [payers, setPayers] = useState([]); // Full insurance companies data, including billing address
  const [plans, setPlans] = useState([]); // All plan names we have in the system
  const [payersPlainList, setPayersPlainList] = useState([]); // flat array of payers (array of strings)
  const [plansPlainList, setPlansPlainList] = useState([]); // flat array of insurance plans (array of strings)

  // this API call will init list of all insurance plans
  const listPlans = async () => {
    try {
      const data = await getQueryClient({
        query: listPayers,
        payload: { limit: 1000 },
        path: 'listPayers'
      });

      if (!!data) {
        const cloned = [...data];
        sortArrayByField(cloned, 'planName');
        setPlans(cloned);

        // Make flat list of all plans
        const flatPlans = makeFlatArray(cloned, 'planName');
        setPlansPlainList(flatPlans);
      }
    } catch (err) {
      console.error('PayerContext::listPlans err:', err);
      showError('Error: Failed to get payer plans data.');
      logApiException(err, {
        view: 'PayersContext',
        endpoint: 'listPlans'
      });
    }
  };

  // this API call will init list of all insurance companies
  const listCompanies = async () => {
    try {
      const data = await getQueryClient({
        query: listInsurerAICs,
        payload: { limit: 10000 },
        path: 'listInsurerAICs'
      });

      if (!!data?.length) {
        const payers = [...data];
        sortArrayByField(payers, 'name');
        setPayers(payers);

        // Make flat list of all payers
        const flatPayers = makeFlatArray(payers, 'name');
        setPayersPlainList(flatPayers);
      }
    } catch (err) {
      console.error('PayerContext::listCompanies err:', err);
      showError('Error: Failed to get payers data.');
      logApiException(err, {
        view: 'PayersContext',
        endpoint: 'listCompanies'
      });
    }
  };

  useEffect(() => {
    // action fires only if user logged in
    if (user) {
      listCompanies();
      listPlans();
    }
  }, [user]);

  const getPayerById = (id) => {
    const payer = payers.find((item) => item.id === id);

    if (payer) {
      return payer;
    }

    return null;
  };

  const getPlansByInsurer = (id) => {
    return plans.filter((plan) => plan.insurerId === String(id));
  };

  const getPlansByInsurerName = (name) => {
    return plans.filter((plan) => plan.insurerName === String(name));
  };

  return (
    <PayerContext.Provider
      value={{
        payers,
        setPayers,
        getPayerById,
        plans,
        setPlans,
        payersPlainList,
        setPayersPlainList,
        plansPlainList,
        setPlansPlainList,
        getPlansByInsurer,
        getPlansByInsurerName
      }}
    >
      {children}
    </PayerContext.Provider>
  );
};

export default PayerContextProvider;
