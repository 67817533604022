import React, { createContext, useRef, useState } from 'react';
import LogRocket from 'logrocket';

// gql
import {
  onAcquireWork,
  onReleaseWork,
  onUpdateWorkItemStatus
} from '@/graphql/subscriptions';
import { connectToGraphqlAPI } from '@/provider';
// Helpers
import { patientItemStatusesToExclude } from '@/common/Mappers';
import { taskStatus } from '@/constants/enum';

export const QueueFilterContext = createContext();

const QueueFilterContextProvider = ({ children }) => {
  const [workItemQueueSelection, setWorkItemQueueSelection] = useState();
  const [queueData, setQueueData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [workItemQueueSelectionValue, setWorkItemQueueSelectionValue] =
    useState();
  const [currentSelRow, setCurrentSelRow] = useState({});

  const filterRef = useRef();
  filterRef.current = queueData;

  const logApiException = (err, props) => {
    LogRocket.captureMessage('API call exception', {
      extra: {
        errorMessage: err.errors[0]?.message,
        ...props
      }
    });
  };

  // QUEUE UPDATE SUBSCRIPTIONS
  const subscribeStatusUpdate = async () => {
    try {
      await connectToGraphqlAPI({
        graphqlQuery: onUpdateWorkItemStatus
      }).subscribe({
        next: ({ value }) => {
          const { workItem, success } = value.data.onUpdateWorkItemStatus;
          if (success) {
            updateQueueWorkItem(workItem);
          }
        }
      });
    } catch (err) {
      logApiException(err, {
        view: 'QueueContext',
        endpoint: 'onUpdateWorkItemStatus',
        type: 'subscription error'
      });
    }
  };

  const subscribeWorkAcquire = async () => {
    try {
      await connectToGraphqlAPI({
        graphqlQuery: onAcquireWork
      }).subscribe({
        next: ({ value }) => {
          const { workItem, success } = value.data.onAcquireWork;
          if (success) {
            updateQueueWorkItem(workItem);
          }
        }
      });
    } catch (err) {
      logApiException(err, {
        view: 'QueueContext',
        endpoint: 'onAcquireWork',
        type: 'subscription error'
      });
    }
  };

  const subscribeWorkRelease = async () => {
    try {
      await connectToGraphqlAPI({
        graphqlQuery: onReleaseWork
      }).subscribe({
        next: ({ value }) => {
          const { workItem, success } = value.data.onReleaseWork;
          if (
            success &&
            workItem.workStatus !== taskStatus.COMPLETED &&
            workItem.workStatus !== taskStatus.ARCHIVED &&
            workItem.workStatus !== taskStatus.CANCELED
          ) {
            // check if the item is in the queue
            const releasedItem = getQueueWorkItem(workItem.id);
            if (releasedItem) updateQueueWorkItem(workItem);
          }
        }
      });
    } catch (err) {
      logApiException(err, {
        view: 'QueueContext',
        endpoint: 'onReleaseWork',
        type: 'subscription error'
      });
    }
  };

  const getQueueWorkItem = (id) => {
    const cloned = [...filterRef.current];
    const data = cloned.find((workItem) => workItem.id === id);
    return data;
  };

  const updateQueueWorkItem = (workItem) => {
    const cloned = [...filterRef.current];
    const filtered = cloned.filter((item) => item.id === workItem.id);
    const filteredList = cloned.filter((item) => item.id !== workItem.id);
    if (filtered.length !== 0) {
      filtered[0].assignedTo = workItem?.assignedTo || 'TBD';
      filtered[0].workStatus = workItem?.workStatus;

      const sorted = [...filteredList, filtered[0]]
        .filter(
          (item) => !patientItemStatusesToExclude.includes(item.workStatus)
        )
        .sort((a, b) => {
          return new Date(a.targetTime) - new Date(b.targetTime);
        });
      setQueueData(sorted);
    }
  };

  // subscribe to all channels
  const onSubscribeQueueData = () => {
    subscribeStatusUpdate();
    subscribeWorkAcquire();
    subscribeWorkRelease();
  };

  return (
    <QueueFilterContext.Provider
      value={{
        onSubscribeQueueData,
        workItemQueueSelection,
        setWorkItemQueueSelection,
        workItemQueueSelectionValue,
        setWorkItemQueueSelectionValue,
        setQueueData,
        queueData,
        isLoading,
        setIsLoading,
        currentSelRow,
        setCurrentSelRow
      }}
    >
      {children}
    </QueueFilterContext.Provider>
  );
};

export default QueueFilterContextProvider;
