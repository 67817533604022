import React, { useState, useContext, useEffect } from 'react';
import moment from 'moment';

// kendo
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Input } from '@progress/kendo-react-inputs';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { Grid } from '@progress/kendo-react-grid';
import { GridColumn as Column } from '@progress/kendo-react-grid/dist/npm/GridColumn';
import { Button } from '@progress/kendo-react-buttons';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Typography } from '@progress/kendo-react-common';

// components
import FormButton from '@/components/common-components/Form/FormButton';
import DeleteButton from '@/components/common-components/Form/DeleteButton';
import { MessageDialog } from '@/components/common-components/MessageDialog';
import PrescriberAddresses from '@/components/Prescriber/PrescriberAddresses';
import PrescriberAddressModal from '@/components/Prescriber/PrescriberAddressModal';
import { AddSalesRepButton } from '@/components/Prescriber/sales/AddSalesRepButton';
import { FormRadioGroup } from '@/common/kendo-form-components';

// utils
import { states } from '@/common/states';
import {
  InputField,
  validateInput,
  validateNumeric
} from '@/common/Validation';
import { MaskedPhoneInput } from '@/common/MaskInput';
import {
  convertToE164,
  convertToUS,
  reFormatUSPhoneNumber
} from '@/common/PhoneNumberConverter';
import { Constants } from '@/constants';
import { toProperCase } from '@/common/Converters';

// gql
import { connectToGraphqlAPI } from '@/provider';
import {
  getPatientHcpProfiles,
  getPrescriberInfo,
  getPrescribersByLastName,
  getUsersByRole,
  getNewPrescriberInfoByNPI
} from '@/graphql/queries';
import {
  createPatientToPrescriber,
  updatePrescriberInfo,
  createPrescriberInfo,
  deletePatientToPrescriber
} from '@/graphql/mutations';

// context
import {
  UserContext,
  PatientContext,
  UserRoleTypes,
  StatusContext,
  NotifContext,
  LogContext
} from '@/context';

const additionalOfficesInitialValues = {
  streetName: '',
  city: '',
  state: '',
  zip: ''
};

const notesInitialValues = {
  date: '',
  notes: ''
};

const PrescriberInfo = () => {
  const [salesReps, setSalesReps] = useState([]);
  const [listPatientHcpProfilesData, setListPatientHcpProfilesData] = useState(
    []
  );

  const [dialogOption, setDialogOption] = useState({});
  const [visibleDialog, setVisibleDialog] = useState(false);
  const [searchTableData, setSearchTableData] = useState([]);
  const [mainTableData, setMainTableData] = useState([]);
  const [showPrescriberForm, setShowPrescriberForm] = useState(false);
  const [showAddNewHCPDialog, setShowAddNewHCPDialog] = useState(false);
  const [notesData, setNotesData] = useState(notesInitialValues);
  const [disableNewSubmit, setDisableNewSubmit] = useState(true);
  const [mainFormData, setMainFormData] = useState({});
  const [notesTableData, setNotesTableData] = useState(notesData);
  const [isLoadingPrescriberData, setIsLoadingPrescriberData] = useState(false);
  const [isAddingPrescriber, setIsAddingPrescriber] = useState(false);
  const [showAddressDialog, setShowAddressDialog] = useState(false);

  const { canEdit } = useContext(UserContext);
  const { selectedPatientInfo, setSelectedPatientInfo } =
    useContext(PatientContext);
  const { statusUpdate, setStatusUpdate } = useContext(StatusContext);
  const { showSuccess, showError } = useContext(NotifContext);
  const { logApiException } = useContext(LogContext);

  const formRef = React.useRef(null);

  const communicationPresc = [
    { label: 'phone', value: 'PHONE', className: 'patient-radio blue' },
    { label: 'email', value: 'EMAIL', className: 'patient-radio blue' },
    { label: 'fax', value: 'FAX', className: 'patient-radio blue' }
  ];

  const faxOptOutChoice = [
    {
      label: 'Send Treatment Notes',
      value: 'SENDTREATMENT',
      className: 'patient-radio blue'
    },
    {
      label: 'Do Not Send Treatment Notes',
      value: 'NOTSENDTREATMENT',
      className: 'patient-radio blue'
    },
    {
      label: 'Do not send any System Generated Faxes',
      value: 'NOTSENEDSYSTEMFAX',
      className: 'patient-radio blue'
    }
  ];

  const getFaxOptOutValue = (
    needTreatmentRecords,
    dontSendSystemGeneratedFaxes
  ) => {
    if (dontSendSystemGeneratedFaxes) {
      return 'NOTSENEDSYSTEMFAX';
    }
    if (needTreatmentRecords) {
      return 'SENDTREATMENT';
    }
    return 'NOTSENDTREATMENT';
  };

  const getFaxOptOutOptions = (faxOptOutValue) => {
    switch (faxOptOutValue) {
      case 'NOTSENDTREATMENT':
        return {
          needTreatmentRecords: false,
          dontSendSystemGeneratedFaxes: false
        };
      case 'SENDTREATMENT':
        return {
          needTreatmentRecords: true,
          dontSendSystemGeneratedFaxes: false
        };
      case 'NOTSENEDSYSTEMFAX':
      default:
        return {
          needTreatmentRecords: false,
          dontSendSystemGeneratedFaxes: true
        };
    }
  };
  const [prescriberAddresses, setPrescriberAddresses] = useState([]);
  const [selectedAddressIndex, setSelectedAddressIndex] = useState(
    prescriberAddresses.length - 1
  );
  const handleAddUpdatePrescriberAddress = (index, address) => {
    if (index > -1 && prescriberAddresses.length - 1 >= index) {
      const newAddresses = [...prescriberAddresses];
      newAddresses.splice(index, 1, address);
      setPrescriberAddresses(newAddresses);
    } else {
      const newAddresses = prescriberAddresses.slice(0, -1);
      setPrescriberAddresses([...newAddresses, address, null]);
    }

    if (formRef?.current) {
      formRef.current.onChange('officeAddresses', {
        value: prescriberAddresses
      });
    }
  };

  const handleUpdateAddressTitle = (formRenderProps, value) => {
    formRenderProps.onChange('addressTitle', {
      value
    });
  };

  // [MM] need to be able to refresh this object on form "Edit" click
  const prescriberInfoForm = {
    prescFirstName: {
      value: mainFormData?.prescriberFirstName || '',
      inputValidator: (value) => {
        return validateInput({
          prescFirstName: { ...prescriberInfoForm.prescFirstName, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.prescFirstName_REQUIRED
        },
        {
          type: 'alpha',
          message: Constants.ErrorMessage.Alpha_Required
        },
        {
          type: 'noCommas',
          message: Constants.ErrorMessage.noCommas
        }
      ]
    },
    prescLastName: {
      value: mainFormData?.prescriberLastName || '',
      inputValidator: (value) => {
        return validateInput({
          prescLastName: { ...prescriberInfoForm.prescLastName, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.prescLastName_REQUIRED
        },
        {
          type: 'alpha',
          message: Constants.ErrorMessage.Alpha_Required
        },
        {
          type: 'noCommas',
          message: Constants.ErrorMessage.noCommas
        }
      ]
    },
    prescName: {
      value: mainFormData?.prescriberName || '',
      inputValidator: (value) => {
        return validateInput({
          prescriberName: { ...prescriberInfoForm.prescriberName, value }
        });
      },
      validations: [
        {
          type: 'alpha',
          message: Constants.ErrorMessage.Alpha_Required
        },
        {
          type: 'noCommas',
          message: Constants.ErrorMessage.noCommas
        }
      ]
    },
    officePhone: {
      value: mainFormData?.officePhoneNumber
        ? convertToUS(mainFormData.officePhoneNumber)
        : '',
      inputValidator: (value) => {
        return validateInput({
          officePhone: { ...prescriberInfoForm.officePhone, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.PhoneNumber_REQUIRED
        }
      ]
    },
    officeFax: {
      value: mainFormData?.officeFaxNumber
        ? convertToUS(mainFormData.officeFaxNumber)
        : '',
      inputValidator: (value) => {
        return validateInput({
          officeFax: { ...prescriberInfoForm.officeFax, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.PhoneNumber_REQUIRED
        },
        {
          type: 'phonePattern',
          message: Constants.ErrorMessage.PhoneNumber_REQUIRED
        }
      ]
    },

    updateNeedTreatmentRecords: {
      value: mainFormData?.needTreatmentRecords || false,
      inputValidator: (value) => {
        return validateInput({
          updateNeedTreatmentRecords: {
            ...prescriberInfoForm.updateNeedTreatmentRecords,
            value
          }
        });
      },
      validations: []
    },

    faxOptOut: {
      value: getFaxOptOutValue(
        mainFormData?.needTreatmentRecords,
        mainFormData?.dontSendSystemGeneratedFaxes
      ),
      inputValidator: (value) => {
        return validateInput({
          faxOptOut: {
            ...prescriberInfoForm.faxOptOut,
            value
          }
        });
      },
      validations: []
    },

    siteInstName: {
      value: mainFormData?.siteInstitutionName || '',
      inputValidator: (value) => {
        return validateInput({
          siteInstName: { ...prescriberInfoForm.siteInstName, value }
        });
      },
      validations: [
        {
          type: 'alpha',
          message: Constants.ErrorMessage.Alpha_Required
        },
        {
          type: 'noCommas',
          message: Constants.ErrorMessage.noCommas
        }
      ]
    },
    taxIdNo: {
      value: mainFormData?.taxIDNumber || '',
      inputValidator: (value) => {
        return validateInput({
          taxIdNo: { ...prescriberInfoForm.taxIdNo, value }
        });
      },
      validations: []
    },
    npiNumber: {
      value: mainFormData?.NPINumber || '',
      inputValidator: (value) => {
        return validateInput({
          npiNumber: { ...prescriberInfoForm.npiNumber, value }
        });
      },
      validations: []
    },
    hinNumber: {
      value: mainFormData?.HINNumber || '',
      inputValidator: (value) => {
        return validateInput({
          hinNumber: { ...prescriberInfoForm.hinNumber, value }
        });
      },
      validations: []
    },
    offAddr: {
      value: mainFormData?.officeAddresses?.streetName || '',
      inputValidator: (value) => {
        return validateInput({
          offAddr: { ...prescriberInfoForm.offAddr, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.Address_REQUIRED
        },
        {
          type: 'noCommas',
          message: Constants.ErrorMessage.noCommas
        }
      ]
    },
    offContName: {
      value: mainFormData?.officeContactName || '',
      inputValidator: (value) => {
        return validateInput({
          offContName: { ...prescriberInfoForm.offContName, value }
        });
      },
      validations: [
        {
          type: 'noCommas',
          message: Constants.ErrorMessage.noCommas
        }
        // {
        //     type: "required",
        //     message: Constants.ErrorMessage.Address_REQUIRED,
        // },
      ]
    },
    offCity: {
      value: mainFormData?.officeAddresses?.city || '',
      inputValidator: (value) => {
        return validateInput({
          offCity: { ...prescriberInfoForm.offCity, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.City_REQUIRED
        },
        {
          type: 'alpha',
          message: Constants.ErrorMessage.Alpha_Required
        },
        {
          type: 'noCommas',
          message: Constants.ErrorMessage.noCommas
        }
      ]
    },
    offZip: {
      value: mainFormData?.officeAddresses?.zip || '',
      inputValidator: (value) => {
        return validateInput({
          offZip: { ...prescriberInfoForm.offZip, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.Zip_REQUIRED
        },
        {
          type: 'onlyNumeric',
          message: Constants.ErrorMessage.Numeric_Required
        },
        {
          type: 'zipLength',
          message: Constants.ErrorMessage.zipLength_REQUIRED
        }
      ]
    },
    addOfficeState: {
      value: mainFormData?.additionalOfficeAddresses?.state || '',
      inputValidator: (value) => {
        return validateInput({
          addOfficeState: { ...prescriberInfoForm.addOfficeState, value }
        });
      },
      validations: []
    },
    addOfficeCity: {
      value: mainFormData?.additionalOfficeAddresses?.city || '',
      inputValidator: (value) => {
        return validateInput({
          addOfficeCity: { ...prescriberInfoForm.addOfficeCity, value }
        });
      },
      validations: [
        {
          type: 'noCommas',
          message: Constants.ErrorMessage.noCommas
        }
      ]
    },
    addOfficeZip: {
      value: mainFormData?.additionalOfficeAddresses?.zip || '',
      inputValidator: (value) => {
        return validateInput({
          addOfficeZip: { ...prescriberInfoForm.addOfficeZip, value }
        });
      },
      validations: []
    },
    addOfficeAddr: {
      value: mainFormData?.additionalOfficeAddresses?.street || '',
      inputValidator: (value) => {
        return validateInput({
          addOfficeAddr: { ...prescriberInfoForm.addOfficeAddr, value }
        });
      },
      validations: [
        {
          type: 'noCommas',
          message: Constants.ErrorMessage.noCommas
        }
      ]
    },
    preferredPrescriberContactMethod: {
      value: mainFormData?.preferredPrescriberContactMethod || '',
      inputValidator: (value) => {
        return validateInput({
          preferredPrescriberContactMethod: {
            ...prescriberInfoForm.preferredPrescriberContactMethod,
            value
          }
        });
      },
      validations: []
    },
    taxID: {
      value: mainFormData?.taxID || '',
      inputValidator: (value) => {
        return validateInput({ taxID: { ...prescriberInfoForm.taxID, value } });
      },
      validations: []
    },
    medSpec: {
      value: mainFormData?.medicalSpecialty || '',
      inputValidator: (value) => {
        return validateInput({
          medSpec: { ...prescriberInfoForm.medSpec, value }
        });
      },
      validations: []
    },
    officeEmail: {
      value: mainFormData?.officeEmail || '',
      inputValidator: (value) => {
        return validateInput({
          officeEmail: { ...prescriberInfoForm.officeEmail, value }
        });
      },
      validations: []
    }
  };

  const addHCPForm = {
    prescriberFirstName: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          prescriberFirstName: { ...addHCPForm.prescriberFirstName, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: 'Required First Name'
        },
        {
          type: 'noCommas',
          message: Constants.ErrorMessage.noCommas
        }
      ]
    },
    prescriberLastName: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          prescriberLastName: { ...addHCPForm.prescriberLastName, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: 'Required last Name'
        },
        {
          type: 'noCommas',
          message: Constants.ErrorMessage.noCommas
        }
      ]
    },
    prescriberAddress: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          prescriberAddress: { ...addHCPForm.prescriberAddress, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: 'Required Address'
        },
        {
          type: 'noCommas',
          message: Constants.ErrorMessage.noCommas
        }
      ]
    },
    prescriberCity: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          prescriberCity: { ...addHCPForm.prescriberCity, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: 'Required City'
        },
        {
          type: 'noCommas',
          message: Constants.ErrorMessage.noCommas
        }
      ]
    },
    prescriberState: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          prescriberState: { ...addHCPForm.prescriberState, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: 'Required State'
        }
      ]
    },
    prescriberZip: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          prescriberZip: { ...addHCPForm.prescriberZip, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.Zip_REQUIRED
        },
        {
          type: 'onlyNumeric',
          message: Constants.ErrorMessage.Numeric_Required
        },
        {
          type: 'zipLength',
          message: Constants.ErrorMessage.zipLength_REQUIRED
        }
      ]
    },
    NPINumber: {
      value: '',
      inputValidator: (value) => {
        return validateInput({ NPINumber: { ...addHCPForm.NPINumber, value } });
      },
      validations: [
        {
          type: 'required',
          message: 'Required npi number '
        },
        {
          type: 'onlyNumeric',
          message: Constants.ErrorMessage.Numeric_Required
        },
        {
          type: 'maxLength',
          length: 10,
          message: Constants.ErrorMessage.maxLength_REQUIRED
        },
        {
          type: 'minLength',
          length: 10,
          message: Constants.ErrorMessage.minLength_REQUIRED
        }
      ]
    },
    prescriberPhone: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          prescriberPhone: { ...addHCPForm.prescriberPhone, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: 'Required Office Number '
        },
        {
          type: 'maskedMinlength',
          length: 14,
          message: Constants.ErrorMessage.PhoneNumber_REQUIRED
        }
      ]
    },
    officeFax: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          officeFax: { ...addHCPForm.officeFax, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: 'Required Office Fax Number'
        },
        {
          type: 'maskedMinlength',
          length: 14,
          message: Constants.ErrorMessage.PhoneNumber_REQUIRED
        }
      ]
    },
    faxOptOut: {
      value: 'SENDTREATMENT',
      inputValidator: (value) => {
        return validateInput({
          faxOptOut: { ...addHCPForm.faxOptOut, value }
        });
      },
      validations: []
    },
    taxIDNumber: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          taxIDNumber: { ...addHCPForm.taxIDNumber, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: 'Required taxid number '
        }
      ]
    },
    officeContactName: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          officeContactName: {
            ...addHCPForm.officeContactName,
            value
          }
        });
      }
    },
    addressTitle: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          addressTitle: { ...addHCPForm.addressTitle, value }
        });
      }
    }
  };

  const initialForm = () => {
    const initialObject = {};
    Object.keys(prescriberInfoForm).forEach((key) => {
      initialObject[key] = prescriberInfoForm[key].value;
    });
    console.log({ initialObject });
    return initialObject;
  };

  const initialAddHCPForm = () => {
    const initialObject = {};
    Object.keys(addHCPForm).forEach((key) => {
      initialObject[key] = addHCPForm[key].value;
    });

    return initialObject;
  };

  const getPatientHcpProfilesCall = async () => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getPatientHcpProfiles,
        variables: { patientId: selectedPatientInfo.patientId }
      });
      // Check if the data contains hcpProfile items
      if (data?.data?.getPatientBucket?.hcpProfile?.items?.length) {
        setListPatientHcpProfilesData(
          data.data.getPatientBucket.hcpProfile.items
            .filter((item) => item.prescriber) // Filter out items with null prescriber
            .map((item, index) => ({
              ...item.prescriber,
              id: index,
              selected: true,
              inEdit: false,
              prescriberName: `${item.prescriber.prescriberFirstName} ${item.prescriber.prescriberLastName}`,
              npi: item.prescriber.NPINumber,
              taxID: item.prescriber.taxIDNumber,
              select: ''
            }))
        );
      }
    } catch (err) {
      console.log('marty getPatientHcpProfilesCall err', err);
      setDialogOption({
        title: 'HCP: Prescriber Info',
        message: 'Error', // err.errors[0].message, //'Error',
        showDialog: true
      });
    }
  };

  const handleNPICheck = async (formRenderProps, dataItem) => {
    const searchNPI = dataItem?.value?.replace(/[(_/)\s-]/g, '');
    if (searchNPI.length !== 10) return;
    if (!validateNumeric(searchNPI)) return;

    setDisableNewSubmit(true); // set disabled each time through
    setIsLoadingPrescriberData(true);
    try {
      // not found in system so do NPI Registry lookup
      getNewPrescriberInfoByNPICall(formRenderProps, searchNPI);
      setIsLoadingPrescriberData(false);
    } catch (err) {
      console.log('getPrescriberInfoCall err', err);
      setDialogOption({
        title: 'HCP Prescriber Info',
        message: 'Error: getPrescriberInfoCall ',
        showDialog: true
      });
      setIsLoadingPrescriberData(false);
    }
  };

  const getNewPrescriberInfoByNPICall = async (formRenderProps, requestNPI) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getNewPrescriberInfoByNPI,
        variables: { prescriberNPI: requestNPI }
      });

      if (data?.data?.getNewPrescriberInfoByNPI?.statusCode === '200') {
        const respMessage = JSON.parse(
          data.data.getNewPrescriberInfoByNPI.message
        );
        // now parse response
        if (respMessage?.Errors) {
          // if Errors reported from NPI Registry API
          setDialogOption({
            title: 'HCP Prescriber Info',
            message: `Error In NPI Registry call: ${respMessage?.Errors[0].description}`,
            showDialog: true
          });
        } else if (respMessage?.result_count === 0) {
          // got nothing back
          setDialogOption({
            title: 'HCP Prescriber Info',
            message:
              'NPI Number NOT FOUND In NPI Registry. Please verify NPI number is correct',
            showDialog: true
          });
        } else {
          // if here then SHOULD be only one return, so take first
          if (respMessage?.results?.length > 0) {
            const searchResults = respMessage?.results[0];
            const first_name =
              searchResults.basic?.first_name ||
              searchResults.basic?.authorized_official_first_name;
            const last_name =
              searchResults.basic?.last_name ||
              searchResults.basic?.authorized_official_last_name;
            const addr = searchResults.addresses?.filter(
              (item) => item.address_purpose === 'LOCATION'
            ); // find if LOCATION address is provided
            let addrStreet = '';
            let addrCity = '';
            let addrState = '';
            let addrZip = '';
            let addrPhone = '';
            let addrFax = '';
            if (addr && addr.length > 0) {
              // IF a LOCATION address is specified...
              // always take the first entry: addr[0]
              addrStreet = addr[0].address_1;
              // if the record has more than one address part, concat
              if (addr[0].address_2?.length > 0)
                addrStreet += ` ${addr[0].address_2}`;
              addrCity = addr[0].city;
              // find the state droplist item in the list
              addrState =
                states.find(
                  (st) =>
                    st.abbreviation === addr[0].state ||
                    st.name === addr[0].state
                ) || null;
              addrZip = addr[0].postal_code;
              addrPhone = addr[0].telephone_number
                ? addr[0].telephone_number
                : '';
              addrFax = addr[0].fax_number ? addr[0].fax_number : '';
            }
            // update the controls on the screen
            formRenderProps.onChange('prescriberFirstName', {
              value: first_name.replaceAll(',', ' ')
            });
            formRenderProps.onChange('prescriberLastName', {
              value: last_name.replaceAll(',', ' ')
            });
            formRenderProps.onChange('prescriberAddress', {
              value: addrStreet.replaceAll(',', ' ')
            });
            formRenderProps.onChange('prescriberCity', {
              value: addrCity.replaceAll(',', ' ')
            });
            formRenderProps.onChange('prescriberState', { value: addrState });
            formRenderProps.onChange('prescriberZip', { value: addrZip });
            formRenderProps.onChange('prescriberPhone', {
              value: reFormatUSPhoneNumber(addrPhone)
            });
            formRenderProps.onChange('officeFax', {
              value: reFormatUSPhoneNumber(addrFax)
            });

            const officeAddresses = (searchResults?.officeAddresses || []).map(
              (address) => ({
                ...address,
                phoneNumber: address.phoneNumber
                  ? convertToUS(address.phoneNumber)
                  : null,
                faxNumber: address.faxNumber
                  ? convertToUS(address.faxNumber)
                  : null
              })
            );
            // prescriber addresses
            setPrescriberAddresses([...officeAddresses, null]);
            setDisableNewSubmit(false); // set disabled each time through
          }
        }
      } else {
        setDialogOption({
          title: 'HCP Prescriber Info',
          message: 'Error: In Validating NPI call ',
          showDialog: true
        });
      }
    } catch (err) {
      console.log('"marty getNewPrescriberInfoByNPICall err', err);
      setDialogOption({
        title: 'HCP Prescriber Info',
        message: 'Error: getNewPrescriberInfoByNPICall ',
        showDialog: true
      });
    }
  };

  const createPrescriberInfoCall = async (requestObject) => {
    setIsAddingPrescriber(true);
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: createPrescriberInfo,
        variables: { input: requestObject }
      });

      if (data?.data?.createPrescriberInfo) {
        // now attach to the patient...
        const infoRequest = {
          patientId: selectedPatientInfo.patientId,
          prescriberId: requestObject.NPINumber
        };
        await callCreatePatientToPrescriber(infoRequest);
        const newPrescriberObject = {
          ...requestObject,
          prescriberName: `${requestObject.prescriberFirstName} ${requestObject.prescriberLastName}`,
          npi: requestObject.NPINumber,
          taxID: requestObject.taxIDNumber
        };
        setMainTableData([...mainTableData, newPrescriberObject]);
        showSuccess('HCP created sucessfully');
        toggleAddNewHCPDialog();

        // previously expanded one prescriber in the table?
        if (showPrescriberForm) {
          handleEditClick(newPrescriberObject);
        }
      } else {
        showError('Error: failed to create HCP');
      }
    } catch (err) {
      console.log('"marty createPrescriberInfoCall err', err);
      showError('Error in createPrescriberInfo call');
    } finally {
      setIsAddingPrescriber(false);
    }
  };

  const updatePrescriberInfoCall = async (requestObject) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: updatePrescriberInfo,
        variables: { input: requestObject }
      });
      if (data && data.data && data.data.updatePrescriberInfo) {
        setDialogOption({
          title: 'HCP Prescriber Info',
          message: 'HCP saved sucessfully',
          showDialog: true
        });

        const updatedInfo = data.data.updatePrescriberInfo;
        const npi = updatedInfo.NPINumber;
        // update mainTableData item with new values
        // 1) remove existing dataItem
        const localMainData = mainTableData.filter(
          (item) => item.NPINumber !== npi
        );
        // 2) add in new/updated item
        setMainTableData([
          ...localMainData,
          {
            ...updatedInfo,
            prescriberName: `${updatedInfo.prescriberFirstName} ${updatedInfo.prescriberLastName}`,
            npi,
            taxID: updatedInfo.taxIDNumber
          }
        ]);
        // 3) update Patient Context as well
        const pIdx = selectedPatientInfo?.hcpProfile.items.findIndex(
          (hp) => hp.prescriberId === npi
        );

        if (pIdx > -1) {
          const hcpCloned = { ...selectedPatientInfo.hcpProfile };
          const hcpItemsCloned = [...hcpCloned.items];
          hcpItemsCloned[pIdx].prescriber = { ...updatedInfo };

          setSelectedPatientInfo({
            ...selectedPatientInfo,
            hcpProfile: hcpCloned
          });
        }
      } else {
        setDialogOption({
          title: 'HCP Prescriber Info',
          message: 'Error: failed to save HCP',
          showDialog: true
        });
      }
    } catch (err) {
      console.log('"marty updatePrescriberInfoCall err', err);
      setDialogOption({
        title: 'HCP Prescriber Info',
        message: 'Error: updatePrescriberInfoCall ',
        showDialog: true
      });
    }
  };

  const getPrescribersByLastNameCall = async (searchLastName) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getPrescribersByLastName,
        variables: {
          prescriberLastName: searchLastName
        }
      });
      if (data?.data?.getPrescribersByLastName?.items?.length) {
        setSearchTableData(
          data.data.getPrescribersByLastName.items.map((item, index) => ({
            ...item,
            id: index,
            selected: false,
            inEdit: false,
            prescriberName: `${toProperCase(
              item.prescriberFirstName
            )} ${toProperCase(item.prescriberLastName)}`,
            npi: item.NPINumber,
            taxID: item.taxIDNumber,
            select: ''
          }))
        );
        togglePatientSearchDialog();
      } else {
        setDialogOption({
          title: 'HCP Prescriber Info',
          message: 'Search Returned No Results',
          showDialog: true
        });
      }
    } catch (err) {
      logApiException(err, {
        view: 'PrescriberInfo',
        endpoint: 'getPrescribersByLastName',
        prescriberLastName: searchLastName
      });
      setDialogOption({
        title: 'HCP: Prescriber Info',
        message: 'Error: getPrescribersByLastName',
        showDialog: true
      });
    }
  };

  const getPrescriberInfoCall = async (NPINumber) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getPrescriberInfo,
        variables: {
          NPINumber
        }
      });
      if (data?.data?.getPrescriberInfo) {
        setSearchTableData([
          {
            ...data.data.getPrescriberInfo,
            id: 0,
            selected: false,
            inEdit: false,
            prescriberName: `${toProperCase(
              data.data.getPrescriberInfo?.prescriberFirstName
            )} ${toProperCase(
              data.data.getPrescriberInfo?.prescriberLastName
            )}`,
            npi: data.data.getPrescriberInfo?.NPINumber,
            taxID: data.data.getPrescriberInfo?.taxIDNumber,
            select: ''
          }
        ]);
        togglePatientSearchDialog();
      } else {
        setDialogOption({
          title: 'HCP Prescriber Info',
          message: 'Search Returned No Results',
          showDialog: true
        });
      }
    } catch (err) {
      logApiException(err, {
        view: 'PrescriberInfo',
        endpoint: 'getPrescriberInfo',
        NPINumber
      });
      setDialogOption({
        title: 'HCP: Prescriber Info',
        message: 'Error: getPrescriberInfo',
        showDialog: true
      });
    }
  };

  const callDeletePatientToPresciber = async (requestObject) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: deletePatientToPrescriber,
        variables: { input: requestObject }
      });
      if (data?.data?.deletePatientToPrescriber) {
        setMainTableData(
          mainTableData.filter(
            (item) => item.NPINumber !== requestObject.prescriberId
          )
        );
      }
    } catch (err) {
      console.log('err', err);
      setDialogOption({
        title: 'HCP: Prescriber Info',
        message: 'Error: deletePatientToPrescriber',
        showDialog: true
      });
    }
  };

  const callCreatePatientToPrescriber = async (requestObject) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: createPatientToPrescriber,
        variables: { input: requestObject }
      });
      if (data?.data?.createPatientToPrescriber) {
        setStatusUpdate(!statusUpdate);
      }
    } catch (err) {
      console.log('err', err);
      showError(
        `Error in createPatientToPrescriber call, ${err?.errors[0]?.errorType}`
      );
    }
  };

  const handleAddHCP = () => {
    const uniqueSelectedHCP = searchTableData
      .filter((item) => item.selected)
      .filter(
        (item) =>
          mainTableData.findIndex((row) => row.NPINumber === item.NPINumber) < 0
      );
    if (uniqueSelectedHCP.length > 0) {
      setMainTableData([...mainTableData, ...uniqueSelectedHCP]);
      // togglePatientSearchDialog()
      setVisibleDialog(false);
      const requestObject = {
        patientId: selectedPatientInfo.patientId,
        prescriberId: uniqueSelectedHCP[0].NPINumber
      };
      callCreatePatientToPrescriber(requestObject);
    } else {
      setVisibleDialog(false);
    }
  };

  const deleteHCP = (dataItem) => {
    const requestObject = {
      patientId: selectedPatientInfo.patientId,
      prescriberId: dataItem.NPINumber
    };

    // check if prescriber assigned to any active referral
    const haveActiveReferrals =
      selectedPatientInfo.referral?.drugReferrals?.some(
        (referral) => referral.prescriberId === dataItem.NPINumber
      );

    if (!haveActiveReferrals) {
      callDeletePatientToPresciber(requestObject);
    } else
      showError(
        'Prescriber assigned to some active referral orders and can not be deleted'
      );
  };

  const handleSearchSubmit = (dataItem) => {
    setShowPrescriberForm(false);

    if (dataItem.npiNumber) {
      getPrescriberInfoCall(dataItem.npiNumber);
    }

    if (dataItem.prescName) {
      getPrescribersByLastNameCall(dataItem.prescName.toLowerCase().trim());
    }
  };

  const searchRowItemChange = (event) => {
    const inEditID = event.dataItem.id;
    const data = searchTableData.map((item) =>
      item.id === inEditID ? { ...item, [event.field]: event.value } : item
    );
    setSearchTableData(data);
  };

  const searchSelectionChange = (event) => {
    const data = searchTableData.map((item) => {
      if (event.dataItem.id === item.id) {
        item.selected = !event.dataItem.selected;
      }
      return item;
    });
    setSearchTableData(data);
  };

  const togglePatientSearchDialog = () => {
    setVisibleDialog(!visibleDialog);
  };

  const toggleAddNewHCPDialog = () => {
    setDisableNewSubmit(true); // force initialized to disabled each AddNewDialog Load
    setDialogOption({});
    setShowAddNewHCPDialog(!showAddNewHCPDialog);
  };

  const handleEditClick = (dataObject) => {
    setShowPrescriberForm(false);
    setMainFormData(JSON.parse(JSON.stringify(dataObject)));
    // prescriber addresses
    const officeAddresses = (dataObject?.officeAddresses || []).map(
      (address) => ({
        ...address,
        phoneNumber: address.phoneNumber
          ? convertToUS(address.phoneNumber)
          : null,
        faxNumber: address.faxNumber ? convertToUS(address.faxNumber) : null
      })
    );
    // prescriber addresses
    setPrescriberAddresses([...officeAddresses, null]);

    if (dataObject.notes && dataObject.notes.length > 0) {
      setNotesTableData(
        dataObject.notes.map((item, index) => {
          item.id = index + 1;
          item.selected = false;
          item.date = moment(item.date)
            .add(new Date().getTimezoneOffset(), 'minutes')
            .format(Constants.DATE.DEFAULT_AWS);
          item.inEdit = true;
          item.notes = item.note;
          return item;
        })
      );
    } else {
      setNotesTableData([]);
    }
  };

  // MAIN INITIATOR
  useEffect(() => {
    getPatientHcpProfilesCall(selectedPatientInfo.patientId);

    if (selectedPatientInfo?.hcpProfile?.prescriberInfo?.notes?.length > 0) {
      setNotesTableData(
        selectedPatientInfo?.hcpProfile?.prescriberInfo?.notes.map(
          (item, index) => {
            return {
              id: index + 1,
              date: moment(item.date).add(
                new Date().getTimezoneOffset(),
                'minutes'
              ),
              note: item.note,
              selected: false,
              inEdit: true
            };
          }
        )
      );
    }
  }, []);

  useEffect(async () => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getUsersByRole
      });
      const results = data?.data?.getUsersByRole?.items || [];
      const salesRepDropdownItems = results.map((rep) => ({
        text: `${rep.firstName} ${rep.lastName}`,
        value: rep.userId,
        role: rep.role
      }));
      setSalesReps(salesRepDropdownItems);
    } catch (err) {
      console.log('Error occured in calling get sales reps query', err);
    }
  }, []);

  useEffect(() => {
    if (mainFormData.npi) {
      setShowPrescriberForm(true);
    }
  }, [mainFormData]);

  useEffect(() => {
    setMainTableData(listPatientHcpProfilesData);
  }, [listPatientHcpProfilesData]);

  const PrescriberNameCell = (props) => {
    return (
      <td
        colSpan={props.colSpan}
        style={{ ...props.style, textTransform: 'capitalize' }}
      >
        {props.dataItem?.prescriberName}
      </td>
    );
  };

  const selectAction = (dataItem) => {
    return (
      <td>
        <div className='row'>
          <div className='col-md-3'>
            <Button
              disabled={!canEdit(UserRoleTypes.PatientPrescribers)}
              look='flat'
              icon='edit'
              onClick={() => {
                handleEditClick(dataItem.dataItem);
              }}
            >
              Edit
            </Button>
          </div>
          <div className='col-md-3'>
            <AddSalesRepButton
              prescriberInfo={dataItem.dataItem}
              salesReps={salesReps}
            />
          </div>
          <div className='col-md-3'>
            <DeleteButton
              handleClick={() => deleteHCP(dataItem.dataItem)}
              disabled={!canEdit(UserRoleTypes.PatientPrescribers)}
            />
            {/* <Button
                disabled={!canEdit(UserRoleTypes.PatientPrescribers)}
                type="button"
                title="Delete"
                onClick={() => deleteHCP(dataItem.dataItem)}
              >
                Delete
              </Button> */}
          </div>
        </div>
      </td>
    );
  };

  const handleAddNewHCPSubmit = (dataItem, event) => {
    event.preventDefault();

    let sFaxNum = dataItem.officeFax?.replace('_', ''); // masked value
    sFaxNum = sFaxNum?.length === 14 ? sFaxNum : null; // if undefined/not complete/invalid masked value
    const requestObject = {
      taxIDNumber: dataItem.taxIDNumber,
      NPINumber: dataItem.NPINumber,
      prescriberFirstName: dataItem.prescriberFirstName
        .replace(',', '')
        .toLowerCase(), // aic-2508 remove commas from prescriber name
      prescriberLastName: dataItem.prescriberLastName
        .replace(',', '')
        .toLowerCase(),
      officeAddresses: [
        {
          streetName: dataItem.prescriberAddress?.replaceAll(',', ''),
          city: dataItem.prescriberCity?.replaceAll(',', ''),
          state: dataItem.prescriberState?.abbreviation,
          zip: dataItem.prescriberZip?.replaceAll(',', ''),
          id: 0, // New prescriber will always start with 0
          default: true,
          faxNumber: sFaxNum ? convertToE164(sFaxNum) : null,
          phoneNumber: convertToE164(dataItem.prescriberPhone),
          addressTitle: dataItem.addressTitle,
          officeContactName: dataItem.officeContactName
        }
      ],
      officeFaxNumber: sFaxNum ? convertToE164(sFaxNum) : null,
      officePhoneNumber: convertToE164(dataItem.prescriberPhone),
      ...getFaxOptOutOptions(dataItem.faxOptOut)
    };

    createPrescriberInfoCall(requestObject);
  };

  const showFormInvalidDialog = (title) => {
    setDialogOption({
      title,
      message: Constants.ErrorMessage.allFields_REQUIRED,
      showDialog: true
    });
  };

  const handleAddNotes = (formRenderProps) => {
    if (formRenderProps && formRenderProps.onChange) {
      formRenderProps.onChange('siteInstNameX', {
        value: 'test'
      });
    }
    const { notes } = notesData;

    if (!notes) {
      return showFormInvalidDialog('notes');
    }

    setNotesTableData([...notesTableData, { ...notesData }]);
  };

  const removeNotes = (props) => {
    return (
      <td>
        <DeleteButton
          handleClick={() => {
            if (formRef.current && formRef.current.onChange) {
              formRef.current.onChange('siteInstNameX', {
                value: 'test'
              });
            }
            const copyNotesData = [...notesTableData];
            copyNotesData.splice(props.dataIndex, 1);
            setNotesTableData([...copyNotesData]);
          }}
        />
      </td>
    );
  };

  const handleSubmit = (dataItem) => {
    let sFaxNum = dataItem.officeFax?.replaceAll('_', ''); // masked value
    sFaxNum = sFaxNum.length === 14 ? sFaxNum : null; // if not complete/valid masked value
    const sContactName = dataItem.offContName?.replaceAll(',', ''); // strip any commas aic-2505
    const sSiteInstName = dataItem.siteInstName?.replaceAll(',', ''); // strip any commas aic-2505
    const officeAddresses = prescriberAddresses
      .slice(0, -1)
      .map((address, index) => ({
        id: index,
        default: address?.default || index === 0,
        streetName: address.streetName,
        city: address.city,
        state: address?.state?.abbreviation || address?.state,
        county: address.country,
        zip: address.zip,
        phoneNumber: convertToE164(address.phoneNumber),
        faxNumber: convertToE164(address.faxNumber),
        addressTitle: address?.addressTitle,
        officeContactName: address?.officeContactName
      }));
    const requestObject = {
      // medicalSpecialty: String
      medicalSpecialty: dataItem.medSpec,
      // officeContactFirstName: String
      officeContactFirstName: sContactName,
      // officeContactLastName: String
      officeContactLastName: sContactName,
      // siteInstitutionName: String
      siteInstitutionName: sSiteInstName,
      // taxIDNumber: String
      taxIDNumber: dataItem.taxID, // dataItem.taxIdNo,
      // officeEmail: AWSEmail
      officeEmail: dataItem.officeEmail ? dataItem.officeEmail : null,
      // officeAddresses: [OfficeAddressInput]
      officeAddresses,
      // officePhoneNumber: String
      officePhoneNumber: dataItem.officePhone
        ? convertToE164(dataItem.officePhone)
        : null,
      // officeFaxNumber: String
      officeFaxNumber: sFaxNum ? convertToE164(sFaxNum) : null,
      // preferredPrescriberContactMethod: ContactMethod
      preferredPrescriberContactMethod:
        dataItem.preferredPrescriberContactMethod
          ? dataItem.preferredPrescriberContactMethod
          : null,
      // prescriberFirstName: String
      prescriberFirstName: mainFormData.prescriberFirstName, // dataItem.prescFirstName,
      // prescriberMiddleName: String
      // prescriberLastName: String
      prescriberLastName: mainFormData.prescriberLastName, // dataItem.prescLastName,
      // NPINumber: ID!
      NPINumber: mainFormData.npi, // dataItem.npiNumber,
      // HINNumber: String
      HINNumber: dataItem.hinNumber,
      // officeContactName: String
      // notes: [NoteInput]
      // notes: notesTableData.map((item) => ({
      //   date: formatDateToAWSDateTime(item.date),
      //   // date: moment().format("MM-DD-YYYY"),
      //   note: item.notes,
      //   type: 'PRESCRIBER'
      // })),
      ...getFaxOptOutOptions(dataItem.faxOptOut)
    };
    // addUpdateHCPProfileCall(requestObject)
    updatePrescriberInfoCall(requestObject);
  };

  const handleClickAddUpdateAddress = (index) => {
    setShowAddressDialog(true);
    setSelectedAddressIndex(index);
  };

  return (
    <div className='row pl-3'>
      <div className='col'>
        {!showAddNewHCPDialog && dialogOption && dialogOption.showDialog && (
          <MessageDialog dialogOption={dialogOption} />
        )}
        <Form
          onSubmit={handleSearchSubmit}
          className='k-form pl-3 pr-3 pt-1'
          render={(formRenderProps) => (
            <form onSubmit={formRenderProps.onSubmit}>
              <div className='row'>
                <div className='col-md-3 pageTitle'>Prescriber Info</div>
              </div>

              <div className='row'>
                <div className='col-md-2 mt-16'>Search Prescribers:</div>
                <div className='col-md-3'>
                  <Field
                    name='prescName'
                    label='Last Name'
                    component={InputField}
                    onChange={(e) => {
                      formRenderProps.onChange('prescName', {
                        value: e?.target?.value
                      });

                      formRenderProps.onChange('npiNumber', {
                        value: ''
                      });
                    }}
                  />
                </div>
                <div className='col-md-2'>
                  <Field
                    name='npiNumber'
                    label='NPI'
                    component={InputField}
                    onChange={(e) => {
                      formRenderProps.onChange('npiNumber', {
                        value: e?.target?.value
                      });

                      formRenderProps.onChange('prescName', {
                        value: ''
                      });
                    }}
                  />
                </div>
                <div className='col-md-2 mt-12'>
                  <button type='submit' className='k-button pageButton'>
                    SEARCH
                  </button>
                </div>
                <div className='col-md-2 offset-md-1 mt-12'>
                  <button
                    disabled={!canEdit(UserRoleTypes.PatientPrescribers)}
                    type='button'
                    className='k-button k-primary'
                    onClick={() => {
                      setVisibleDialog(false);
                      toggleAddNewHCPDialog();
                    }}
                  >
                    ADD NEW
                  </button>
                </div>
              </div>

              <hr />

              <div className='row'>
                <div className='col-12 p-0'>
                  <div className='container-fluid'>
                    <div className='row my-2 justify-content-center'>
                      <div className='col-md-12'>
                        <Grid
                          data={mainTableData}
                          className='inbound-existing-patient'
                        >
                          <Column
                            field='prescriberName'
                            title='PRESCRIBER NAME'
                            width='300px'
                            cell={PrescriberNameCell}
                          />
                          <Column field='npi' title='NPI' width='200px' />
                          <Column field='taxID' title='TAX ID' width='200px' />
                          <Column
                            field='select'
                            title='SELECT'
                            cell={selectAction}
                          />
                        </Grid>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}
        />
        {showPrescriberForm && (
          <Form
            onSubmit={handleSubmit}
            initialValues={initialForm()}
            ref={formRef}
            className='k-form pl-3 pr-3 pt-1'
            render={(formRenderProps) => (
              <FormElement>
                <div className='row mt-12 mt-3'>
                  <div className='col-md-3'>
                    <b>PRESCRIBER INFORMATION</b>
                  </div>
                </div>
                <div className='row mt-12 mt-3'>
                  <div className='col-md-3'>
                    PRESC. NAME: &nbsp;
                    <Typography.p textTransform='capitalize' fontWeight='bold'>
                      {`${mainFormData?.prescriberFirstName || ''} ${
                        mainFormData?.prescriberLastName || ''
                      }`}
                    </Typography.p>
                  </div>
                  <div className='col-md-2'>
                    NPI: &nbsp; {mainFormData?.npi}
                  </div>
                  <div className='col-md-1'>TAX ID:</div>
                  <div className='col-md-2'>
                    <Field
                      name='taxID'
                      component={InputField}
                      validator={prescriberInfoForm.taxID.inputValidator}
                    />
                  </div>
                </div>

                <div className='row mt-1'>
                  <div className='col-md-5'>
                    <Field
                      name='medSpec'
                      label='Medical Specialty'
                      component={InputField}
                      validator={prescriberInfoForm.medSpec.inputValidator}
                    />
                  </div>
                  <div className='col-md-4'>
                    <Field
                      name='siteInstName'
                      label='Practice Name'
                      component={InputField}
                      validator={prescriberInfoForm.siteInstName.inputValidator}
                    />
                    <Field name='siteInstNameX' component={InputField} hidden />
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-3'>
                    <Field
                      name='officeEmail'
                      label='Office Email'
                      component={Input}
                    />
                  </div>
                  <div className='col-md-3'>
                    <Field
                      name='hinNumber'
                      label='HIN'
                      component={Input}
                      validator={prescriberInfoForm.hinNumber.inputValidator}
                    />
                  </div>
                  <div className='col-md-6'>
                    <Field
                      name='preferredPrescriberContactMethod'
                      data={communicationPresc}
                      label='Communication Preference:'
                      layout='horizontal'
                      component={FormRadioGroup}
                    />
                  </div>
                </div>

                <div className='col-md-12 mt-06'>
                  <Field
                    name='faxOptOut'
                    data={faxOptOutChoice}
                    label='Fax Opt Out:'
                    layout='horizontal'
                    component={FormRadioGroup}
                    validator={prescriberInfoForm.faxOptOut.inputValidator}
                  />
                </div>

                <div className='row mt-12 mt-3'>
                  <div className='col-md-3'>
                    <b>PRESCRIBER ADDRESSES</b>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-12 mt-06'>
                    <Field
                      name='officeAddresses'
                      component={() => (
                        <PrescriberAddresses
                          addresses={prescriberAddresses}
                          onAddOrUpdate={handleAddUpdatePrescriberAddress}
                          onClickAddUpdateAddress={handleClickAddUpdateAddress}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className='row p-3 mt-16'>
                  <div className='col-2'>
                    <button
                      type='submit'
                      className='k-button pageButton'
                      disabled={!formRenderProps.allowSubmit}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </FormElement>
            )}
          />
        )}
      </div>

      {visibleDialog && (
        <Dialog
          title='Prescriber Selection'
          width={1000}
          onClose={togglePatientSearchDialog}
        >
          <Grid
            data={searchTableData}
            onItemChange={(e) => searchRowItemChange(e)}
            onSelectionChange={(e) => searchSelectionChange(e)}
            selectedField='selected'
          >
            <Column field='prescriberName' title='PRESCRIBER NAME' />
            <Column field='npi' title='NPI' width='200px' />
            <Column field='taxID' title='TAX ID' width='200px' />
            <Column field='selected' editor='boolean' title='SELECT' />
          </Grid>
          <DialogActionsBar>
            <button className='k-button k-primary' onClick={handleAddHCP}>
              ADD PRESCRIBER
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
      {showAddNewHCPDialog && (
        <Dialog
          title='Add New Prescriber'
          width={800}
          minHeight={400}
          onClose={toggleAddNewHCPDialog}
          showDialog={showAddNewHCPDialog}
        >
          {dialogOption && dialogOption.showDialog && (
            <MessageDialog dialogOption={dialogOption} />
          )}
          <Form
            onSubmit={handleAddNewHCPSubmit}
            initialValues={initialAddHCPForm()}
            render={(formRenderProps) => (
              <form
                onSubmit={formRenderProps.onSubmit}
                className='k-form pl-3 pr-3 pt-1'
              >
                {isLoadingPrescriberData && (
                  <div className='row'>
                    <div className='col-md-6'>
                      Loading prescriber data from server…
                    </div>
                  </div>
                )}

                <div className='row'>
                  <div className='col-md-6 mt-8'>
                    <Field
                      name='NPINumber'
                      label='NPI Number'
                      component={InputField}
                      validator={addHCPForm.NPINumber.inputValidator}
                      disabled={isLoadingPrescriberData}
                      onChange={(dataItem) =>
                        handleNPICheck(formRenderProps, dataItem)
                      }
                    />
                  </div>
                  <div className='col-md-6 mt-8'>
                    <Field
                      name='taxIDNumber'
                      label='Tax ID'
                      component={InputField}
                      validator={addHCPForm.taxIDNumber.inputValidator}
                      disabled={isLoadingPrescriberData}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6 mt-8'>
                    <Field
                      name='prescriberFirstName'
                      label='First Name'
                      component={InputField}
                      validator={addHCPForm.prescriberFirstName.inputValidator}
                      disabled={isLoadingPrescriberData}
                    />
                  </div>
                  <div className='col-md-6 mt-8'>
                    <Field
                      name='prescriberLastName'
                      label='Last Name'
                      component={InputField}
                      validator={addHCPForm.prescriberLastName.inputValidator}
                      disabled={isLoadingPrescriberData}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-12 mt-8'>
                    <Field
                      name='prescriberAddress'
                      label='Address'
                      component={InputField}
                      validator={addHCPForm.prescriberAddress.inputValidator}
                      disabled={isLoadingPrescriberData}
                      onChange={(e) => {
                        handleUpdateAddressTitle(
                          formRenderProps,
                          `${formRenderProps.valueGetter('prescriberCity')} - ${
                            e.target.value
                          }`
                        );
                      }}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-4 mt-8'>
                    <Field
                      name='prescriberCity'
                      label='City'
                      component={InputField}
                      validator={addHCPForm.prescriberCity.inputValidator}
                      disabled={isLoadingPrescriberData}
                      onChange={(e) => {
                        handleUpdateAddressTitle(
                          formRenderProps,
                          `${e.target.value} - ${formRenderProps.valueGetter(
                            'prescriberAddress'
                          )}`
                        );
                      }}
                    />
                  </div>
                  <div className='col-md-4 mt-8'>
                    <Field
                      name='prescriberState'
                      data={states}
                      textField='name'
                      valueField='abbreviation'
                      label='State'
                      component={DropDownList}
                      validator={addHCPForm.prescriberState.inputValidator}
                      disabled={isLoadingPrescriberData}
                    />
                  </div>
                  <div className='col-md-4 mt-8'>
                    <Field
                      name='prescriberZip'
                      label='Zip'
                      component={InputField}
                      validator={addHCPForm.prescriberZip.inputValidator}
                      disabled={isLoadingPrescriberData}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6 mt-1'>
                    <Field
                      name='officeContactName'
                      label='Office Contact Name'
                      component={InputField}
                    />
                  </div>
                  <div className='col-md-6 mt-1'>
                    <Field
                      name='addressTitle'
                      label='Address Title'
                      component={InputField}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6 mt-1'>
                    <Field
                      name='prescriberPhone'
                      label='Office Phone'
                      component={MaskedPhoneInput}
                      validator={addHCPForm.prescriberPhone.inputValidator}
                      disabled={isLoadingPrescriberData}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6 mt-1'>
                    <Field
                      name='officeFax'
                      label='Office Fax'
                      component={MaskedPhoneInput}
                      validator={addHCPForm.officeFax.inputValidator}
                      disabled={isLoadingPrescriberData}
                    />
                  </div>
                  <div className='col-md-6 mt-1'>
                    <Field
                      name='faxOptOut'
                      data={faxOptOutChoice}
                      label='Fax Opt Out:'
                      layout='horizontal'
                      component={FormRadioGroup}
                      disabled={isLoadingPrescriberData}
                    />
                  </div>
                </div>
                <div className='row p-3 mt-16'>
                  <div className='col-2'>
                    <FormButton
                      className='k-button pageButton'
                      type='submit'
                      disabled={disableNewSubmit}
                      loading={isAddingPrescriber}
                    >
                      Submit
                    </FormButton>
                  </div>
                </div>
              </form>
            )}
          />
        </Dialog>
      )}
      {showAddressDialog && (
        <PrescriberAddressModal
          onAddUpdateAddress={handleAddUpdatePrescriberAddress}
          address={prescriberAddresses[selectedAddressIndex]}
          onClose={() => setShowAddressDialog(false)}
          index={selectedAddressIndex}
        />
      )}
    </div>
  );
};

export default PrescriberInfo;
