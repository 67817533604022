import React, { useContext } from "react";

import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Switch } from "@progress/kendo-react-inputs";
import { Form, Field } from "@progress/kendo-react-form";

import {
  getPatientWeightFromReferral,
  toProperCase
} from "@/common/Converters";

import { formatDateToDefault } from "@/common/DateHelper";
import { InfusionContext } from "@/context/InfusionContext";

const RevisitOrderReviewV2 = ({ itemAdministrations, itemPreMeds }) => {
  const { infusion } = useContext(InfusionContext);
  const nursingProcess = infusion?.nursingProcess || {};
  const currentReferral = infusion?.currentReferral || {};
  const patientWeightKg = getPatientWeightFromReferral(currentReferral);

  return (
    <div className="infusion-page">
      <div className="col-md-10" style={{ border: "1px solid #afaaaa" }}>
        <div className="row">
          <div className="infusion-HeaderRow col-12 ml-0 pl-3 py-3 mr-0">
            <div className="row">
              <div className="col-md-4 headerText">
                Order Name:
                <br />
                {currentReferral?.orderName}
              </div>
              <div className="col-md-4 headerText">
                Order Type:
                <br />
                {toProperCase(currentReferral?.referralOrder?.orderType)}
              </div>
              <div className="col-md-4 headerText">
                Referral ID:
                <br />
                {nursingProcess?.referralId}
              </div>
            </div>
          </div>
        </div>
        {patientWeightKg > 0 && (
          <div className="row">
            <div className="col-md-12 mt-08">
              Patient Weight on Order: {patientWeightKg} KG
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-md-12 mt-08">
            <Grid data={itemAdministrations} className="infusion-grid">
              <Column field="drugName" title="PRODUCT NAME" />
              <Column field="route" title="ROUTE" />
              <Column field="administer" title="ADMINISTER" />
              <Column field="maxOfTreatments" title="MAX #" />
              <Column field="approvedDosage" title="DOSE" />
              <Column field="unitOfMeas" title="UOM" />
              <Column field="calcDosage" title="CALC DOSE" />
            </Grid>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 mt-08">
            <Grid data={itemPreMeds} className="infusion-grid">
              <Column field="drugName" title="PRE-MEDICATION" />
              <Column field="route" title="ROUTE" />
              <Column field="administer" title="ADMINISTER" />
              <Column field="" title="MAX #" />
              <Column field="approvedDosage" title="DOSE" />
              <Column field="unitOfMeas" title="UOM" />
              <Column field="" title="CALC DOSE" />
            </Grid>
          </div>
        </div>
        <div className="infusion-details col-12 ml-0 pl-3 py-3 mr-0">
          <div>
            <div className="row">
              <div className="col-md-4 headerText">
                {nursingProcess?.prescriberId}
              </div>
              <div className="col-4 headerText">
                {formatDateToDefault(currentReferral?.referralOrder?.orderDate)}
              </div>
              <div className="col-4 headerText">
                {formatDateToDefault(
                  currentReferral?.referralOrder?.orderExpires
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-4 infusion-details-field-name">
                ORDERING PROVIDER
              </div>
              <div className="col-4 infusion-details-field-name">
                ORDER DATE
              </div>
              <div className="col-4 infusion-details-field-name">
                ORDER EXPIRES
              </div>
            </div>
            <div className="row mt-08">
              <div className="col-md-4 headerText">
                {currentReferral?.referralOrder?.primaryDX.primaryDiagnosis}
              </div>
              <div className="col-8 headerText notesWrapper">
                {currentReferral?.referralOrder?.notes}
              </div>
            </div>
            <div className="row">
              <div className="col-4 infusion-details-field-name">
                PRIMARY DX
              </div>
              <div className="col-8 infusion-details-field-name">NOTES</div>
            </div>
          </div>
        </div>
      </div>
      <Form
        render={() => (
          <form className={"k-form pl-3 pr-3 pt-1"}>
            <div className="row mt-3">
              <div className="col-md-3">
                Order is Approved: &nbsp;
                <Field
                  name="orderIsApproved"
                  onLabel="Yes"
                  offLabel="No"
                  component={Switch}
                  disabled={true}
                  defaultChecked={true}
                />
              </div>
              <div className="col-md-5">
                Patient Consent Received: &nbsp;
                <Field
                  name="patientConsentReceived"
                  onLabel="Yes"
                  offLabel="No"
                  component={Switch}
                  defaultChecked={true}
                  disabled={true}
                />
              </div>
            </div>
          </form>
        )}
      />
    </div>
  );
};

export default RevisitOrderReviewV2;
