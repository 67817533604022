import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

// kendo
import { Dialog } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { Grid } from '@progress/kendo-react-grid';
import { GridColumn as Column } from '@progress/kendo-react-grid/dist/npm/GridColumn';

// gql
import { connectToGraphqlAPI } from '@/provider';
import { getSalesCommissionsByPrescriberId } from '@/graphql/queries';

// context
import { PatientContext, ReferralContext } from '@/context';

// utils
import { convertToUS } from '@/common/PhoneNumberConverter';
import { toProperCase } from '@/common/Converters';

// components
import Badge from '@/components/common-components/Badge';
import WindowDialog from '@/components/common-components/WindowDialog';
import PatientDocument from './PatientDocument';
import PatientDocsModal from './PatientDocsModal';
import { Notes } from '@/components/Notes';
import NewPatientBadge from '@/components/common-components/NewPatientBadge';
import PatientStatusBadge from '@/components/common-components/PatientStatusBadge';

const BadgeWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const PatientHeader = (props) => {
  const { selectedPatientInfo } = props;
  const aicLocation = props?.locationName;
  const {
    releasePatientLocker,
    setSelectedLocker,
    isNewPatient,
    setPatientScheduledItems
  } = useContext(PatientContext);
  const {
    setCombinedReferralList,
    setPatientReferralIds,
    setPatientReferralDrugNames
  } = useContext(ReferralContext);

  const [showPatientTaskListDialog, setShowPatientTaskListDialog] =
    useState(false);
  const [selectedDocumentUrl, setSelectedDocumentUrl] = useState('');
  const [showCallScriptDialog, setShowCallScriptDialog] = useState(false);
  const [showPatientNotesDialog, setShowPatientNotesDialog] = useState(false);
  const [showPatientDocsDialog, setShowPatientDocsDialog] = useState(false);
  const [showPatientDocDialog, setShowPatientDocDialog] = useState(false);
  const [prescriberName, setPrescriberName] = useState('');

  const history = useHistory();

  useEffect(() => {
    setSelectedLocker(selectedPatientInfo?.patientId);
    return () => {
      releasePatientLocker();
      // Clear list for follow up modal when leaving patient.
      setCombinedReferralList();
      setPatientReferralIds();
      setPatientReferralDrugNames([]);
      setPatientScheduledItems([]);
    };
  }, []);

  useEffect(async () => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getSalesCommissionsByPrescriberId,
        variables: {
          prescriberId:
            selectedPatientInfo?.hcpProfile?.items?.[0]?.prescriber?.NPINumber
        }
      });
      const results =
        data?.data?.getSalesCommissionsByPrescriberId?.items?.[0]?.userInfo ||
        '';
      if (results) {
        setPrescriberName(
          `${toProperCase(results.firstName)} ${toProperCase(results.lastName)}`
        );
      }
    } catch (err) {
      console.log('Error occured in calling get sales reps query', err);
    }
  }, []);

  const patientTaskLists = [
    {
      Date: '12/11/2022',
      TaskName: 'Labs Needed',
      Notes: 'Waiting on Fax',
      Status: 'In Progress',
      Agent: 'S. Williams',
      Selected: false
    },
    {
      Date: '02/11/2020',
      TaskName: 'Follow Up Needed',
      Notes: 'Labs',
      Status: 'Completed',
      Agent: 'V. Williams',
      Selected: false
    }
  ];

  const toggleCallScriptDialog = () => {
    setShowCallScriptDialog(!showCallScriptDialog);
  };

  const togglePatientTaskListDialog = () => {
    setShowPatientTaskListDialog(!showPatientTaskListDialog);
  };

  const togglePatientNotesDialog = () => {
    setShowPatientNotesDialog(!showPatientNotesDialog);
  };

  const handlePatientNotes = () => {
    togglePatientNotesDialog();
  };

  const handleNewAppointment = async () => {
    await releasePatientLocker();
    history.push({
      pathname: '/scheduler'
    });
  };

  const handlePatientDocs = () => {
    props.sendDataToParent(); // <= need to get updated doc list from bucket
    setShowPatientDocsDialog(true);
  };

  const togglePatientDocsDialog = () => {
    setShowPatientDocsDialog(!showPatientDocsDialog);
  };

  const toggleShowPatientDocDialog = () => {
    setShowPatientDocDialog(!showPatientDocDialog);
  };

  const documentViewCallback = (s3ImageURL) => {
    setSelectedDocumentUrl(s3ImageURL);
    if (showPatientDocsDialog) {
      setShowPatientDocsDialog(false);
    }
    setShowPatientDocDialog(true);
  };

  return (
    <div className='patient-portal-header'>
      <div className='row py-2' style={{ margin: '0' }}>
        <div className='col-12 col-lg-3 right-border'>
          <div className='row'>
            <BadgeWrapper className='col-12'>
              <NewPatientBadge show={isNewPatient} />
              <PatientStatusBadge status={selectedPatientInfo?.patientStatus} />
            </BadgeWrapper>
          </div>
          <div className='row'>
            <div className='col-6 '>
              <big>
                {selectedPatientInfo?.patientFirstName}&nbsp;
                {selectedPatientInfo?.patientLastName}
              </big>
            </div>
            <div className='col-6 patientHeaderText'>
              {selectedPatientInfo?.dob}
            </div>
          </div>
          <div className='row'>
            <div className='col-6 header-details-field-name'>PATIENT NAME</div>
            <div className='col-6 patientFieldName'>PATIENT DOB</div>
          </div>
          <div className='row'>
            <div className='col-6 patientHeaderText' field-name='patientId'>
              {selectedPatientInfo?.patientId}
            </div>
            <div className='col-6 patientHeaderText'>
              {selectedPatientInfo?.patientProfile?.patientInfo?.address?.state}
            </div>
          </div>
          <div className='row'>
            <div className='col-6 patientFieldName'>PATIENT ID</div>
            <div className='col-6 patientFieldName'>PATIENT STATE</div>
          </div>
          <div className='row'>
            <div className='col-6 patientHeaderText'>
              {convertToUS(selectedPatientInfo?.homePhoneNumber)}
            </div>
            <div className='col-6 patientHeaderText'>{aicLocation}</div>
          </div>
          <div className='row'>
            <div className='col-6 patientFieldName'>PATIENT PHONE</div>
            <div className='col-6 patientFieldName'>AIC CENTER</div>
          </div>
        </div>
        <div className='col-12 col-lg-6 right-border'>
          <div className='row'>
            <div className='col-5 patientHeaderText k-text-capitalize'>
              {selectedPatientInfo?.hcpProfile?.items.length
                ? selectedPatientInfo?.hcpProfile?.items[0]?.prescriber
                    ?.prescriberFirstName
                : null}
              &nbsp;
              {selectedPatientInfo?.hcpProfile?.items.length
                ? selectedPatientInfo?.hcpProfile?.items[0]?.prescriber
                    ?.prescriberLastName
                : null}
              &nbsp;
            </div>
            <div className='col-4 patientHeaderText'>
              {
                selectedPatientInfo?.patientProfile?.insuranceInfo?.primary
                  ?.insurerName
              }
              {/* {selectedPatientInfo?.patientProfile?.insuranceInfo?.primary?.planName} */}
              &nbsp;
            </div>
            <div className='col-3 patientHeaderText'>{prescriberName}</div>
          </div>
          <div className='row'>
            <div className='col-5 mt-02 patientFieldName'>PRESCRIBER NAME</div>
            <div className='col-4 patientFieldName'>INSURANCE COMPANY</div>
            <div className='col-3 patientFieldName'>SALES REP</div>
          </div>
          <div className='row'>
            <div className='col-5 patientHeaderText'>
              {selectedPatientInfo?.hcpProfile?.items.length
                ? selectedPatientInfo?.hcpProfile?.items[0]?.prescriber
                    ?.NPINumber
                : null}
              &nbsp;
            </div>
            <div className='col-7 patientHeaderText'>
              {/* {selectedPatientInfo?.hcpProfile?.items.length
                ? selectedPatientInfo?.hcpProfile?.items[0]?.prescriber
                    ?.taxIDNumber
                : null} */}
              {
                selectedPatientInfo?.patientProfile?.insuranceInfo?.primary
                  ?.planName
              }
              &nbsp;
            </div>
          </div>
          <div className='row'>
            <div className='col-5 patientFieldName'>PRESCRIBER NPI</div>
            <div className='col-7 patientFieldName'>INSURANCE PLAN</div>
          </div>
          <div className='row'>
            <div className='col-5 patientHeaderText'>
              {selectedPatientInfo?.hcpProfile?.items.length
                ? convertToUS(
                    selectedPatientInfo?.hcpProfile?.items[0]?.prescriber
                      ?.officeAddresses[0]?.phoneNumber
                  )
                : null}
              &nbsp;
            </div>
            <div className='col-7 patientHeaderText'>
              {selectedPatientInfo?.hcpProfile?.items.length
                ? convertToUS(
                    selectedPatientInfo?.hcpProfile?.items[0]?.prescriber
                      ?.officeAddresses[0]?.faxNumber
                  )
                : null}
              &nbsp;
              {selectedPatientInfo?.hcpProfile?.items[0]?.prescriber
                ?.dontSendSystemGeneratedFaxes ? (
                <Badge type='warning' text='Opt Out Fax' />
              ) : null}
            </div>
          </div>
          <div className='row'>
            <div className='col-5 patientFieldName'>PRESCRIBER PHONE</div>
            <div className='col-7 patientFieldName'>PRESCRIBER FAX</div>
          </div>
        </div>
        <div className='col-12 col-lg-3'>
          <div className='row'>
            <div className='col-12'>
              <button
                type='submit'
                onClick={handlePatientDocs}
                className='k-button k-primary mt-04 button-block'
              >
                <i className='fa-regular fa-folder fa-sm icon-white'>
                  &nbsp;&nbsp;
                </i>
                Patient Docs
              </button>
            </div>
          </div>
          <div className='row mt-04'>
            <div className='col-12'>
              <button
                type='submit'
                onClick={handlePatientNotes}
                className='k-button k-primary mt-04 button-block'
              >
                <i className='fa-regular fa-pen-to-square fa-sm icon-white'>
                  &nbsp;&nbsp;
                </i>
                Patient Notes
              </button>
            </div>
          </div>
          <div className='row mt-04'>
            <div className='col-12'>
              <button
                type='submit'
                onClick={handleNewAppointment}
                className='k-button k-primary mt-04 button-block'
              >
                <i className='fa-regular fa-calendar-days fa-sm icon-white'>
                  &nbsp;&nbsp;
                </i>
                Calendar
              </button>
            </div>
          </div>
        </div>
        {showPatientTaskListDialog && (
          <Dialog
            title='Patient Task List'
            width={1000}
            height={500}
            onClose={togglePatientTaskListDialog}
          >
            <div>
              {/* dialog content */}
              <div className='row justify-content-between mt-12'>
                <div className='col-md-12 text-right'>
                  <Button title='add New' icon='plus'>
                    Add new
                  </Button>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span
                    className='k-icon k-i-delete k-icon-md'
                    title='Remove'
                  />
                </div>
              </div>
              <div className='row'>
                <div
                  className='col-md-12 mt-16'
                  style={{ marginBottom: '1.6rem' }}
                >
                  <Grid
                    editField='inEdit'
                    selectedField='selected'
                    style={{ height: '150px' }}
                    data={patientTaskLists}
                  >
                    <Column field='Date' title='Date' width='200px' />
                    <Column field='TaskName' title='Task Name' width='250px' />
                    <Column field='Notes' title='Notes' width='350px' />
                    <Column field='Status' title='Status' width='200px' />
                    <Column field='Agent' title='Agent' width='350px' />
                    <Column field='selected' title='Select' width='50px' />
                  </Grid>
                </div>
              </div>
            </div>
          </Dialog>
        )}

        <PatientDocsModal
          showDialog={showPatientDocsDialog}
          onClose={togglePatientDocsDialog}
          documentViewCallback={documentViewCallback}
        />

        <WindowDialog
          title='Patient Document'
          height={1100}
          width={850}
          initialTop={100}
          showDialog={showPatientDocDialog}
          onClose={toggleShowPatientDocDialog}
        >
          <div className='col-md-2 text-right mb-4'>
            <Button
              title='Pdf Download'
              onClick={() => {
                const link = document.createElement('a');
                link.href = selectedDocumentUrl;
                link.target = '_blank';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }}
            >
              Pdf Download
            </Button>
          </div>
          <PatientDocument file={selectedDocumentUrl} />
        </WindowDialog>

        {showCallScriptDialog && (
          <Dialog
            title='Agent Call Script'
            initialHeight={750}
            initialTop={100}
            initialLeft={600}
            width={400}
            onClose={toggleCallScriptDialog}
          >
            <div>
              <input className='k-textbox' />
              &nbsp; &nbsp;
              <span className='k-icon k-i-search k-icon-16' />
              <hr />
              <div style={{ fontWeight: 'bold' }}>SCRIPTS</div>
              <div style={{ marginTop: '0.8rem' }}>
                Inbound Call – Patient Enrollment
              </div>
              <div>Inbound Call – Patient Enrollment</div>
              <div>Inbound Call – Patient Enrollment</div>
              <div>Inbound Call – HCP Referral</div>
              <div>Inbound Call – Escalation – Patient/HCP</div>
              <div>Outbound Call – Patient Incomplete Enrollment</div>
              <div>Outbound Call – HCP Incomplete Enrollment</div>
              <div>
                Outbound Call – Patient Welcome (Insurance Verification Outcome
                and review)
              </div>
              <div>
                Outbound Call – HCP Welcome (Referral of patient and Insurance
                outcome review)
              </div>
              <div style={{ fontWeight: 'bold', marginTop: '0.8rem' }}>FAQ</div>
              <div>Agenus Contact Information</div>
              <div>
                Inbound Call – Patient Inquiry (product, medical, insurance,
                financial assistance)
              </div>
              <div>Inbound Call – HCP Inquiry (product, medical, patient)</div>
            </div>
          </Dialog>
        )}
        {showPatientNotesDialog && (
          <Dialog
            title='Notes'
            height={800}
            width={1100}
            initialTop={0}
            initialLeft={0}
            onClose={togglePatientNotesDialog}
          >
            <Notes
              selectedPatientInfo={selectedPatientInfo}
              sendDataToParent={props.sendDataToParent}
            />
          </Dialog>
        )}
      </div>
    </div>
  );
};

export default PatientHeader;
