import React, { useContext } from 'react';
import styled from 'styled-components';

// context
import { TreatmentContext } from '@/context';

// utils
import { generateFaxMeta } from '../infusionHelper';

// styled components
const MetaContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 95%;
`;

// display names
MetaContainer.displayName = 'MetaContainer';

const PanelMeta = ({ selectedPatientInfo, panelTitle }) => {
  const { snapshotInsurance } = useContext(TreatmentContext);
  return (
    <MetaContainer>
      <b>{panelTitle}</b>
      <small>{generateFaxMeta(selectedPatientInfo, snapshotInsurance)}</small>
    </MetaContainer>
  );
};

export default PanelMeta;
