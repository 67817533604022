import React, { useCallback, useEffect, useState } from 'react';
import { Input, Switch, TextArea } from '@progress/kendo-react-inputs';
import { Error, Label } from '@progress/kendo-react-labels';
import * as moment from 'moment';
import { DatePicker, TimePicker } from '@progress/kendo-react-dateinputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';

import { SecureMaskedInput } from 'react-control-library';
import { formatSSN, maskSSN } from './Utility';

const validateRegxEmail = (email) => {
  const regx = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  return regx.test(email);
};

const validateRegxPhoneNo = (phoneNo) => {
  // const regx = /^\d{10}$/;
  const regx = /^\+?\d{10,14}$/;
  return regx.test(phoneNo.replace(/[(_/)\s-]/g, ''));
};

export const validateNumeric = (value) => {
  const regx = /^\d+$/;
  return regx.test(value);
};

export const validateRegxAlphaNumeric = (value) => {
  const regex = /^[A-Za-z0-9]*$/;
  return regex.test(value);
};

export const validateFloat = (value) => {
  const regx = /^[+-]?\d+(\.\d+)?$/;
  return regx.test(value);
};

export const validateAnyNumberGreaterThanZero = (value) => {
  const regx = /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/;
  return regx.test(value);
};

export const validateZipCode = (value) => {
  const regx = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
  return regx.test(value);
};

export const validateNoCommas = (value) => {
  return !value.includes(',');
};
// function validateDate(testdate) {
//   var date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
//   return date_regex.test(testdate);
// }

const validateRegxAlpha = (value) => {
  // const regx = /^[a-zA-Z]+$/;
  // const regx = /^[a-zA-Z -]*$/;
  // const regx = /^[a-zA-Z]([\w -]*[a-zA-Z])?$/;
  const regx = /^[a-zA-Z-_ ]*[a-zA-Z]$/;
  return regx.test(value);
};

export const validateRegxNoExtraSymbols = (value) => {
  const regex = /^[a-zA-Z0-9#-]+$/;
  return regex.test(value);
};

export const SwitchInput = (fieldRenderProps) => {
  const {
    // The meta props of the Field.
    validationMessage,
    touched,
    visited,
    modified,
    valid,
    disabled,
    // The input props of the Field.
    value,
    onChange,
    onFocus,
    onBlur,
    onLabel,
    offLabel,

    // The custom props that you passed to the Field.
    ...others
  } = fieldRenderProps;
  const onValueChange = React.useCallback(() => {
    // onChange callback expects argument with 'value' property
    onChange({
      value: !value
    });
  }, [onChange, value]);
  return (
    <div onFocus={onFocus} onBlur={onBlur}>
      <Switch
        onChange={onValueChange}
        checked={value}
        id={others.id}
        onLabel='Yes'
        disabled={disabled}
        offLabel='No'
      />
      {
        // Display an error message after the "visited" or "touched" field is set to true.
        visited && validationMessage && (
          <div className='k-required'>{validationMessage}</div>
        )
      }
    </div>
  );
};

export const InputField = (fieldRenderProps) => {
  const { validationMessage, visited, ...others } = fieldRenderProps;
  return (
    <div>
      <Input {...others} />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};

export const InputCustomField = (fieldRenderProps) => {
  const { validationMessage, visited, ...others } = fieldRenderProps;
  return (
    <div>
      <Input {...others} />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};

export const MaskedSSNInputFieldOld = (fieldRenderProps) => {
  const { validationMessage, visited, value, onChange, ...others } =
    fieldRenderProps;
  const [rawSSN, setRawSSN] = useState(value || '');

  const handleChangeSSNValue = useCallback(
    (e) => {
      const { value } = e.target;
      if (value.replace(/-/g, '').length < rawSSN.length) {
        const last = rawSSN.length - 1;
        setRawSSN(rawSSN.slice(0, last));
        onChange({ value: rawSSN.slice(0, last) });
        return;
      }

      const numValue = value.replace(/\D/g, '');

      let newSSN = '';
      if (rawSSN.length > 5) {
        newSSN = rawSSN.slice(0, 5) + numValue;
      } else {
        newSSN = rawSSN + numValue;
      }

      if (newSSN.length > 11) {
        return;
      }
      setRawSSN(newSSN);
      onChange({ value: newSSN });
    },
    [rawSSN, onChange]
  );

  return (
    <div>
      <Input
        {...others}
        value={maskSSN(formatSSN(rawSSN))}
        onChange={handleChangeSSNValue}
      />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};

export const MaskedSSNInputField = (fieldRenderProps) => {
  const { validationMessage, visited, value, onChange, ...others } =
    fieldRenderProps;
  const [data, setData] = React.useState(value || '');

  function handleChange(e) {
    onChange({ value: e.target.value });
    setData(e.target.value);
  }

  function handleBlur(e) {
    setData(e.target.value);
  }

  const ssnMask = [
    /^[0-9]*$/,
    /^[0-9]*$/,
    /^[0-9]*$/,
    '-',
    /^[0-9]*$/,
    /^[0-9]*$/,
    '-',
    /^[0-9]*$/,
    /^[0-9]*$/,
    /^[0-9]*$/,
    /^[0-9]*$/
  ];

  // NOTICE: I couldn't use inputTag={Input} as it throws errors
  // Rendering input that looks like other kendo input fields on the form

  return (
    <div>
      <span className='k-textbox-container'>
        <label className='k-label'>SSN</label>
        <SecureMaskedInput
          {...others}
          className='k-textbox'
          mask={ssnMask}
          exactLength={9}
          value={data}
          secure={{
            getValue: (detail, data) => {
              if (!data) return data;
              if (detail.value.length === 9)
                return maskSSN(formatSSN(detail.value));
              return data;
            }
          }}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {visited && validationMessage && <Error>{validationMessage}</Error>}
      </span>
    </div>
  );
};

export const TextAreaField = (fieldRenderProps) => {
  const { validationMessage, visited, ...others } = fieldRenderProps;
  return (
    <div>
      <TextArea rows={4} {...others} />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};

export const TextAreaFieldSmall = (fieldRenderProps) => {
  const { validationMessage, visited, ...others } = fieldRenderProps;
  return (
    <div>
      <TextArea rows={2} {...others} />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};

export const DatePickerField = (fieldRenderProps) => {
  let { validationMessage, visited, ...others } = fieldRenderProps;
  if (
    others.data &&
    others.data.forms &&
    others.data.compareStartDate &&
    others.data.compareEndDate &&
    others.data.forms.valueGetter(others.data.compareStartDate) &&
    others.data.forms.valueGetter(others.data.compareEndDate) &&
    !validateStartDateCompareToEndDate(
      others.data.forms.valueGetter(others.data.compareStartDate),
      others.data.forms.valueGetter(others.data.compareEndDate)
    )
  ) {
    console.log('Error');
    validationMessage = 'Error: End date should be less than to start date';
  }
  return (
    <div>
      <DatePicker {...others} />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};

export const DatePickerFieldWithValidation = ({
  value,
  format,
  onChange,
  min,
  max,
  name,
  label,
  visited,
  validationMessage
}) => {
  const handleChange = (event) => {
    const selectedDate = event.target.value;
    onChange({ value: selectedDate });
  };

  return (
    <div>
      {label && <Label editorId={name}>{label}</Label>}
      <DatePicker
        name={name}
        value={value}
        onChange={handleChange}
        min={min || new Date(1900, 0, 1)}
        max={max || new Date(2099, 11, 31)}
        format={format || 'MM/dd/yyyy'}
      />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};

export const TimePickerField = (fieldRenderProps) => {
  const { validationMessage, visited, ...others } = fieldRenderProps;

  return (
    <div>
      <TimePicker {...others} />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};

export const DropDownListField = (fieldRenderProps) => {
  const { validationMessage, visited, ...others } = fieldRenderProps;

  return (
    <div>
      <DropDownList {...others} />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};

const validateDateRange = (minDate, maxDate, value) => {
  return moment(value).isBetween(minDate, maxDate);
};
const validateDateRangeNull = (minDate, maxDate, value) => {
  // allows the date to return null but still checks an entered date for min and max date's.
  if (value === null) {
    return true;
  }
  return moment(value).isBetween(minDate, maxDate);
};

export const isDateGreaterThanToday = (values) => {
  if (!values?.snoozeUntil) {
    return;
  }
  let givenDate = values?.snoozeUntil;
  const CurrentDate = new Date();
  givenDate = new Date(givenDate);

  if (!(givenDate > CurrentDate)) {
    return {
      snoozeUntil: 'Date should be greater than today.'
    };
  }
};

const validateDateNotInPast = (value) => {
  const date = new Date();
  return moment(value).isSameOrAfter(date.setHours(0, 0, 0, 0));
};
const validateDateTodayOrNotInFuture = (value) => {
  const date = new Date();
  return moment(value).isSameOrAfter(date);
};
const validateDateNotOverOneYearInPast = (value) => {
  const date = new Date();
  const past = moment(date).subtract(1, 'years');
  return moment(value).isSameOrAfter(past);
};

const validateStartDateCompareToEndDate = (startDate, endDate) => {
  return moment(endDate).isSameOrAfter(startDate);
};

const checkFollowUpInput = (value) => {
  if (value?.items?.length) {
    return false;
  }
  return true;
};

export const validateInput = (controls) => {
  const errors = {};
  Object.keys(controls).forEach((controlName) => {
    const { validations, value } = controls[controlName];
    if (validations && Array.isArray(validations)) {
      validations.forEach((validation) => {
        const {
          type,
          message,
          length,
          matchControl,
          minDate,
          maxDate,
          startDate
        } = validation;
        if (
          type === 'required' &&
          (!value || (typeof value === 'string' && value.trim() === '')) &&
          !errors[controlName]
        ) {
          errors[controlName] = message;
        } else if (
          type === 'alpha' &&
          value &&
          !validateRegxAlpha(value) &&
          !errors[controlName]
        ) {
          errors[controlName] = message;
        } else if (
          type === 'alphaNumeric' &&
          value &&
          !validateRegxAlphaNumeric(value) &&
          !errors[controlName]
        ) {
          errors[controlName] = message;
        } else if (
          type === 'maskedMinlength' &&
          value &&
          value.replace(/_/gi, '').length < length &&
          !errors[controlName]
        ) {
          errors[controlName] = message;
        } else if (
          type === 'maskedMaxLength' &&
          value &&
          value.length.replace(/_/gi, '') > length &&
          !errors[controlName]
        ) {
          errors[controlName] = message;
        } else if (
          type === 'minLength' && // changed to match mixedCase naming
          value &&
          value.length < length &&
          !errors[controlName]
        ) {
          errors[controlName] = message.replace('{length}', length.toString());
        } else if (
          type === 'maxLength' &&
          value &&
          value.length > length &&
          !errors[controlName]
        ) {
          errors[controlName] = message.replace('{length}', length.toString());
        } else if (
          type === 'zipLength' &&
          value &&
          value.toString().length !== 5 &&
          value.toString().length !== 9 &&
          !errors[controlName]
        ) {
          errors[controlName] = message;
        } else if (
          type === 'zipFormat' &&
          !validateZipCode(value) &&
          !errors[controlName]
        ) {
          errors[controlName] = message;
        } else if (
          type === 'onlyNumeric' &&
          value &&
          !validateNumeric(value) &&
          !errors[controlName]
        ) {
          errors[controlName] = message;
        } else if (
          type === 'onlyFloat' &&
          value &&
          !validateFloat(value) &&
          !errors[controlName]
        ) {
          errors[controlName] = message;
        } else if (
          type === 'anyNumberGreaterThanZero' &&
          value &&
          !validateAnyNumberGreaterThanZero(value) &&
          !errors[controlName]
        ) {
          errors[controlName] = message;
        } else if (
          type === 'noCommas' &&
          value &&
          !validateNoCommas(value) &&
          !errors[controlName]
        ) {
          errors[controlName] = message;
        } else if (
          type === 'emailPattern' &&
          value &&
          !validateRegxEmail(value) &&
          !errors[controlName]
        ) {
          errors[controlName] = message;
        } else if (
          type === 'phonePattern' &&
          value &&
          !validateRegxPhoneNo(value) &&
          !errors[controlName]
        ) {
          errors[controlName] = message;
        } else if (
          type === 'match' &&
          controls[matchControl] &&
          value !== controls[matchControl].value &&
          !errors[controlName]
        ) {
          errors[controlName] = message;
        } else if (
          type === 'dateRange' &&
          minDate &&
          maxDate &&
          !validateDateRange(minDate, maxDate, value) &&
          !errors[controlName]
        ) {
          errors[controlName] = message;
        } else if (
          type === 'dateRangeNull' &&
          minDate &&
          maxDate &&
          !validateDateRangeNull(minDate, maxDate, value) &&
          !errors[controlName]
        ) {
          errors[controlName] = message;
        } else if (
          type === 'datePast' &&
          value &&
          !validateDateNotInPast(value) &&
          !errors[controlName]
        ) {
          errors[controlName] = message;
        } else if (
          type === 'dateTodayFuture' &&
          value &&
          validateDateTodayOrNotInFuture(value) &&
          !errors[controlName]
        ) {
          errors[controlName] = message;
        } else if (
          type === 'dateFuture' &&
          value &&
          validateDateNotInPast(value) &&
          !errors[controlName]
        ) {
          errors[controlName] = message;
        } else if (
          type === 'dateOneYearPast' &&
          value &&
          !validateDateNotOverOneYearInPast(value) &&
          !errors[controlName]
        ) {
          errors[controlName] = message;
        } else if (
          type === 'isMainCategory' &&
          value &&
          !checkFollowUpInput(value)
        ) {
          errors[controlName] = message;
        } else if (
          type === 'noExtraSymbols' &&
          value &&
          !validateRegxNoExtraSymbols(value)
        ) {
          errors[controlName] = message;
        }
      });
    }
  });
  // return {
  //   errors: Object.keys(errors).length > 0 ? Object.values(errors)[0] : '',
  //   valid: Object.keys(errors).length < 1,
  // };
  return Object.keys(errors).length > 0 ? Object.values(errors)[0] : '';
};
