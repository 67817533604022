import React, { createContext, useState, useEffect, useContext } from 'react';

// gql
import { getQueryClient } from '@/provider';
import { listSalesTerritories } from '@/graphql/queries';

// context
import { LogContext } from './LogContext';

// util
import { capitalizeSalesTerritories } from './SalesContextHelper';
import { sortByField } from '@/common/SortHelper';

export const SalesContext = createContext();

const SalesContextProvider = ({ children, user }) => {
  const { logApiException } = useContext(LogContext);
  const [salesTerritoriesRaw, setSalesTerritoriesRaw] = useState([]);
  const [salesTerritories, setSalesTerritories] = useState([]);
  const getSalesListCall = async () => {
    try {
      const data = await getQueryClient({
        query: listSalesTerritories,
        payload: { limit: 1000 },
        path: 'listSalesTerritories'
      });
      const sortedData = data.sort((a, b) => sortByField(a, b, 'name'));
      const dataCapitalized = sortedData.map((item) => ({
        text: `${capitalizeSalesTerritories(item.name)} (${item.id})`,
        id: item.id
      }));

      setSalesTerritoriesRaw(sortedData); // raw data sorted by name
      setSalesTerritories(dataCapitalized); // sorted and capitalzed name data
    } catch (err) {
      console.error('SalesContext::listSalesTerritories err:', err);
      logApiException(err, {
        view: 'SalesContext',
        endpoint: 'listSalesTerritories'
      });
    }
  };

  useEffect(() => {
    // action fires only if user logged in
    if (user) {
      getSalesListCall();
    }
  }, [user]);
  return (
    <SalesContext.Provider
      value={{
        salesTerritories,
        setSalesTerritories,
        salesTerritoriesRaw,
        setSalesTerritoriesRaw
      }}
    >
      {children}
    </SalesContext.Provider>
  );
};

export default SalesContextProvider;
