export const callSelection = [
  {
    title: 'Reached Patient',
    value: 'REACHED_PATIENT'
  },
  {
    title: 'Reached Agent',
    value: 'REACHED_AGENT'
  },
  {
    title: 'Reached - Decline',
    value: 'REACHED_DECLINE'
  },
  {
    title: 'Reached - D/C Referral',
    value: 'REACHED_REFERRAL'
  },
  {
    title: 'Reached - Intake Monitor',
    value: 'REACHED_INTAKE'
  },
  {
    title: 'Reached - Rph Monitor',
    value: 'REACHED_RPH'
  },
  {
    title: 'Not Reached',
    value: 'NOT_REACHED'
  }
];
export const callSelectionOpen = [
  { title: 'Reached - Call Completed', value: 'CALL_COMPLETED' },
  { title: 'Reached - Not Resolved', value: 'NOT_RESOLVED' },
  { title: 'Reached - Scheduled', value: 'SCHEDULED' },
  { title: 'Reached - Therapy D/C', value: 'THERAPY_DC' },
  { title: 'Reached - Treatment Complete', value: 'TREATMENT_COMPLETE' },
  { title: 'Reached - Surgery/Hospital', value: 'SURGERY_HOSPITAL' },
  {
    title: 'Reached - Decline due to Financial Reasons',
    value: 'DECLINE_FINANCIAL'
  },
  { title: 'Reached - Transition to Home Treatment', value: 'TRANSITION_HOME' },
  { title: 'Reached - Deceased', value: 'DECEASED' },
  { title: 'Reached - Snowbird', value: 'SNOWBIRD' },
  { title: 'Reached - Changed AIC', value: 'CHANGED_AIC' },
  { title: 'Reached - Patient Needs to F/U with MD', value: 'FU_MD' },
  { title: 'Reached - New Referral Received', value: 'NEW_REFERRAL' },
  { title: 'Reached - Obtained Needed Information', value: 'OBTAINED_INFO' },
  {
    title: 'Reached - Refused to provide referral/labs/information',
    value: 'REFUSED_INFO'
  }
];

export const callSelectionNotReached = [
  { title: 'Not Reached - Left VM', value: 'LEFT_VM' },
  { title: 'Not Reached - Busy', value: 'BUSY' },
  { title: 'Not Reached - Wrong Number', value: 'WRONG_NUMBER' },
  { title: 'Not Reached - No VM/No Answer', value: 'NO_VM_NO_ANSWER' },
  {
    title: 'Reached-Will call back',
    value: 'WILL_CALL_BACK'
  }
];
export const callSelectionClose = [
  {
    title: 'Reached Patient',
    value: 'REACHED_PATIENT'
  },
  {
    title: 'Reached Agent',
    value: 'REACHED_AGENT'
  },
  {
    title: 'Reached - Decline',
    value: 'REACHED_DECLINE'
  },
  {
    title: 'Reached - D/C Referral',
    value: 'REACHED_REFERRAL'
  },
  {
    title: 'Reached - Intake Monitor',
    value: 'REACHED_INTAKE'
  },
  {
    title: 'Reached - Rph Monitor',
    value: 'REACHED_RPH'
  },
  {
    title: 'Not Reached - Close Call work item',
    value: 'CLOSE_CALL'
  }
];
