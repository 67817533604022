import { workItemTypes } from '@/constants/enum';

// Takes work item object and scan for the attachedData
// If found - parse it and return back, otherwise return null
export const getAttachedData = (workItem) => {
  if (!workItem || !workItem.attachedData) return null;

  try {
    const parsedData = JSON.parse(workItem.attachedData);
    return parsedData;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getWorkItemMetadata = (dataItem, patientInfo) => {
  const meta = { drugName: null, followUpType: null };

  if (!dataItem || !patientInfo) return meta;

  const aData = getAttachedData(dataItem);

  if (!aData) return meta;

  // get follow type, if any
  if (dataItem.work === workItemTypes.FOLLOW_UP && !!aData.followUpType) {
    meta.followUpType = aData.followUpType;
  }

  // get drug name, if any
  if (aData.referralId) {
    const findInActive = patientInfo.referral.drugReferrals?.find((referral) => referral.referralId === aData.referralId);

    if (findInActive) {
      meta.drugName = findInActive.drugName;
      return meta;
    }

    const findInArchived = patientInfo.referral.archivedDrugReferrals?.find((referral) => referral.archivedReferral.referralId === aData.referralId);

    if (findInArchived) {
      meta.drugName = findInArchived.orderName;
      return meta;
    }
  }

  return meta;
};

export const getAudience = (workItem) => {
  let type = '';

  if (workItem?.attachedData?.contactText) {
    type = workItem?.attachedData?.contactText;
  }
  return type;
};
