import { renderDiscSurveyHeader } from '../../surveyDictionary';

const VERSION = 4;

export default {
  pages: [
    {
      // LEVEL 1 -- Main Reason selector
      elements: [
        {
          name: 'surveyStartPage',
          type: 'html',
          html: renderDiscSurveyHeader(VERSION)
        },
        {
          name: 'main-reason',
          title: 'Reason for Discontinuation?',
          type: 'radiogroup',
          choices: [
            { value: 'SR', text: 'Supplier Reason' },
            { value: 'FN', text: 'Financial Reason' },
            { value: 'MD', text: 'Physician Reason' },
            { value: 'PT', text: 'Patient Reason' },
            { value: 'IN', text: 'Insurance Reason' }
          ],
          isRequired: true
        }
      ]
    },
    {
      // LEVEL 2 -- Questions based on discontinuation answer selected at the prev level
      elements: [
        // Financial Reasons (Code - FN)
        {
          name: 'financial-reason',
          title: "What's the reason?",
          type: 'radiogroup',
          choices: [
            {
              value: '01',
              text: "Patient Doesn't Qualify for Co-Pay Assistance/Can't afford (Preferred Drug)"
            },
            { value: '02', text: "Patient won't provide financial info" },
            { value: '03', text: 'Co-Pay assistance program out of funding' },
            {
              value: '04',
              text: 'Qualifies for CoPay program but costs still too high (Preferred Drug)'
            },
            { value: '05', text: 'No CoPay program available' },
            { value: '07', text: 'White Bagging costs too high' },
            { value: '06', text: 'Other' }
          ],
          isRequired: true,
          visibleIf: "{main-reason} == 'FN'"
        },
        // Physician Reasons (Code - MD)
        {
          name: 'physician-reason',
          title: "What's the reason?",
          type: 'radiogroup',
          choices: [
            {
              value: 20,
              text: 'MD Discontinued treatment - drug not working/ineffective'
            },
            {
              value: 21,
              text: 'MD Discontinued treatment - switched to a different drug (not AIC)'
            },
            { value: 22, text: 'MD Discontinued treatment - side effects' },
            {
              value: 23,
              text: 'MD Discontinued treatment - MD will send in new referral/order'
            },
            {
              value: 24,
              text: 'MD Discontinued treatment - Requests Aleracare to call MD for new Order'
            },
            { value: 25, text: 'MD Discontinued treatment - Needs labs' },
            { value: 26, text: 'MD Discontinued treatment - Lab results' },
            {
              value: 27,
              text: 'MD Discontinued treatment - Clinical Reasons'
            },
            { value: 28, text: 'Referral was sent to another AIC/provider' },
            {
              value: 29,
              text: 'MD Discontinued treatment - patient non-adherent'
            },
            { value: 30, text: 'MD Upset with service' },
            { value: 31, text: 'Treatment Complete' },
            { value: 32, text: 'Need Information - No MD Response' },
            { value: 33, text: 'MD Declines to pursue Appeal/P2P' },
            { value: '06', text: 'Other' }
          ],
          isRequired: true,
          visibleIf: "{main-reason} == 'MD'"
        },
        // Patient Reason (Code - PT)
        {
          name: 'patient-reason',
          title: "What's the reason?",
          type: 'radiogroup',
          choices: [
            {
              value: 40,
              text: 'Patient prescribed non-preferred drug-Requests preferred drug'
            },
            {
              value: 41,
              text: 'Patient prescribed non-preferred drug-Declines preferred drug'
            },
            { value: 42, text: "Patient Doesn't want to start treatment" },
            { value: 43, text: 'Utilizing other provider (ie hospital)' },
            { value: 44, text: 'Patient decided to stop treatment' },
            { value: 45, text: 'Patient deceased' },
            {
              value: 46,
              text: 'Patient needs a physician visit before starting drug'
            },
            { value: 47, text: 'Patient needs labs' },
            { value: 48, text: 'Patient moved (no AIC available)' },
            { value: 49, text: 'Patient Upset with Service' },
            { value: 50, text: 'AIC Days of Week do not work for patient' },
            {
              value: 51,
              text: 'AIC Hours of Operation do not work for patient'
            },
            {
              value: 52,
              text: 'AIC Facility Concerns (ie Privacy, Parking etc)'
            },
            { value: 53, text: 'Data Cleanse' },
            { value: 54, text: 'Patient Hospitalized' },
            { value: 55, text: 'Snowbird - No AIC Location' },
            { value: 56, text: 'Unable to Contact Patient' },
            { value: 57, text: 'Patient switched to home/self treatment' },
            { value: 58, text: 'Snowbird-AleraCare location Available' },
            { value: 59, text: 'AIC Concerns - Too Far to Drive' },
            { value: 60, text: "Doesn't tolerate/allergy" },
            { value: 61, text: 'PT Moved - AIC available' },
            { value: 62, text: 'Test Patient' },
            { value: '06', text: 'Other' }
          ],
          isRequired: true,
          visibleIf: "{main-reason} == 'PT'"
        },
        // Insurance Reason (Code - IN)
        {
          name: 'insurance-reason',
          title: "What's the reason?",
          type: 'radiogroup',
          choices: [
            { value: 60, text: 'No longer eligible/lost insurance' },
            {
              value: 61,
              text: 'Prior Authorization denied - Drug Not Covered'
            },
            {
              value: 62,
              text: 'Prior Authorization denied - Failed to meet clinical criteria'
            },
            { value: 63, text: 'Appeal denied' },
            { value: 64, text: 'Out of network' },
            { value: 65, text: 'Mandated use of alternate provider ie SP' },
            { value: 66, text: 'White Bagging - Out of Network' },
            { value: 67, text: 'White Bagging - PA Denied' },
            { value: 68, text: 'Provider Not Credentialed' },
            { value: 69, text: 'Cheaper at Specialty' },
            { value: 70, text: 'No Buy and Bill Benefit' },
            { value: 71, text: 'Drug not on Fee Schedule' },
            { value: '06', text: 'Other' }
          ],
          isRequired: true,
          visibleIf: "{main-reason} == 'IN'"
        },
        // Supplier Reason (Code - SR)
        {
          name: 'supplier-reason',
          title: "What's the reason?",
          type: 'radiogroup',
          choices: [
            { value: 80, text: 'Drug on Limited Distribution' },
            { value: 81, text: 'Drug Shortage' },
            { value: 82, text: 'Drug not available to AleraCare' }
          ],
          isRequired: true,
          visibleIf: "{main-reason} == 'SR'"
        }
      ]
    }
  ],
  showQuestionNumbers: 'off',
  showPrevButton: false,
  completedHtml: 'Survey completed, saving data...',
  goNextPageAutomatic: true,
  showPreviewBeforeComplete: 'showAllQuestions',
  clearInvisibleValues: 'onHidden',
  questionsOnPageMode: 'singlePage',
  version: VERSION
};
