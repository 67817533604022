import React, { useEffect, useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
// kendo
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { orderBy } from '@progress/kendo-data-query';
// components
import { MessageDialog } from '@/components/common-components/MessageDialog';
import Preloader from '@/components/common-components/Preloader';
import YesNoCell from '../Grid/YesNoCell';
import AICLocationDropdown from '@/components/common-components/AICLocationDropdown';
import { ColumnMenuNoGroup } from '@/components/common-components/columnMenu';
// gql
import { connectToGraphqlAPI } from '@/provider';
import { getChairStateByLocationId } from '@/graphql/queries';
// context
import { LocationContext } from '@/context/LocationContext';
import { NotifContext } from '@/context/NotifContext';
// util
import { productLabNames } from '../Referral/ReferralMaps';
import { getTimeZoneForLocation } from '../Scheduling/getTimezoneForLocation';
import { adjustUTC } from '@/common/DateHelper';

const InfusionQueue = (props) => {
  const { aicLocations, currentLocation, setCurrentLocation } =
    useContext(LocationContext);
  const { showWarning } = useContext(NotifContext);
  const [sort, setSort] = useState([{ field: 'patientId', dir: 'asc' }]);
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(10);
  const [dialogOption, setDialogOption] = useState({});
  const [setMinWidth, setSetMinWidth] = useState(false);
  const [gridCurrent, setGridCurrent] = useState(0);
  const [schedulerTableData, setSchedulerTableData] = useState([]);
  const [selectedOption, setSelectedOption] = useState(currentLocation);
  const [loading, setLoading] = useState(true);

  const yesNoCell = ({ dataItem }) => {
    return <YesNoCell boolVal={dataItem?.occupied} />;
  };

  const checkInCell = ({ dataItem }) => {
    if (dataItem?.checkInTime) {
      const timezone = getTimeZoneForLocation(selectedOption.address.zip);
      const dateString = adjustUTC(dataItem.checkInTime, timezone);
      return <td>{dateString}</td>;
    }

    return <td>&nbsp;</td>;
  };

  const columns = [
    { field: 'chairId', title: 'Chair' },
    { field: 'orderType', title: 'Order Type' },
    { field: 'orderName', title: 'Order Name' },
    { field: 'patientFirstName', title: 'Patient First' },
    { field: 'patientLastName', title: 'Patient Last' },
    { field: 'occupied', title: 'Occupied', cell: yesNoCell },
    {
      field: 'checkInTime',
      title: 'Infusion Started',
      cell: checkInCell
    },
    { field: 'status', title: 'Stage' }
  ];

  const minGridWidth = 0;

  useEffect(() => {
    const storedLocation = localStorage.getItem('LOCATION');
    if (storedLocation) {
      const data = JSON.parse(storedLocation);
      setSelectedOption(data);
      setCurrentLocation(data);
      getChairStateByLocationIdCall(data.selectorValue);
    } else {
      // Handle the case when there is no location data in local storage
      setLoading(false);
    }
  }, []);

  const setWidth = (minWidth) => {
    const width = setMinWidth
      ? minWidth
      : minWidth + (gridCurrent - minGridWidth) / columns.length;
    return width;
  };

  const pageChange = (event) => {
    setSkip(event.page.skip);
    setTake(event.page.take);
  };

  const onRowClickHandle = (e) => {
    const npid = e.dataItem.nursingProcessId;

    if (!npid) {
      return;
    }

    if (currentLocation?.status === 'INACTIVE') {
      showWarning('Location has been deactivated');
      return;
    }

    const isNewInfusion = true;
    console.log('currentLocation: ', currentLocation);

    if (isNewInfusion) {
      props.history.push(`/infusion/${npid}`);
    } else {
      props.history.push({
        pathname: '/infusion-portal',
        state: {
          dataItem: {
            tNPID: {
              id: npid
            }
          }
        }
      });
    }
  };

  const gridEmptyRow = (tableData) => {
    const rows = [];
    if (tableData.length < 10) {
      for (let i = tableData.length, l = 10; i < l; i++) {
        rows.push({
          dateAdded: null,
          patientName: null,
          patientId: null,
          order: null,
          orderType: null,
          drugType: null,
          status: null,
          followupDate: null,
          medicare: null,
          location: null,
          provider: null,
          freeDrug: null
        });
      }
    }
    return rows;
  };

  const handleLocationTreeChange = (selectedItem) => {
    if (selectedItem?.id) {
      // update location context
      setCurrentLocation(selectedItem);

      localStorage.setItem('LOCATION', JSON.stringify(selectedItem));
      setSelectedOption(selectedItem);
      getChairStateByLocationIdCall(selectedItem.selectorValue);
    }
  };

  const getChairStateByLocationIdCall = async (locationId) => {
    setLoading(true);

    try {
      const response = await connectToGraphqlAPI({
        graphqlQuery: getChairStateByLocationId,
        variables: {
          locationId
        }
      });

      if (response?.data?.getChairStateByLocationId?.chairStates) {
        const filteredTableData =
          response.data.getChairStateByLocationId.chairStates.filter(
            (item) => item.orderName !== productLabNames.LAB_DRAW_NAME
          );
        setSchedulerTableData(filteredTableData);
      } else {
        setSchedulerTableData([]);
      }
    } catch (err) {
      console.error('InfusionQueue::getChairStateByLocationIdCall err:', err);
      setDialogOption({
        title: 'Nursing Queue',
        message: 'Error: getChairStateByLocationIdCall',
        showDialog: true
      });
    } finally{
        setLoading(false);
    }
  };

  return (
    <div className='container-fluid'>
      {dialogOption.showDialog && <MessageDialog dialogOption={dialogOption} />}
      <div className='row mt-18'>
        <div className='col-2'>
          AIC CENTER: {selectedOption?.selectorValue}{' '}
          <AICLocationDropdown
            data={aicLocations}
            value={selectedOption}
            style={{ width: '220px' }}
            onChange={handleLocationTreeChange}
          />
        </div>
        <div className='col-1'>&nbsp;</div>
      </div>
      <div className='row my-4'>
        <div className='col-12 col-lg-12 work-in-progress'>
          <div className='a-grid__header'>
            <div>Infusion Queue</div>
          </div>
          <Preloader show={loading}>
            <Grid
              className='queue_grid'
              data={orderBy(
                schedulerTableData.slice(skip, take + skip),
                sort
              ).concat(
                gridEmptyRow(schedulerTableData.slice(skip, take + skip))
              )}
              sortable
              skip={skip}
              take={take}
              total={schedulerTableData.length}
              pageable
              onPageChange={pageChange}
              sort={sort}
              onRowDoubleClick={(e) => {
                onRowClickHandle(e);
              }}
              onSortChange={(e) => {
                setSort(e.sort);
              }}
            >
              {columns.map((column, index) => {
                return (
                  <Column
                    field={column.field}
                    title={column.title}
                    key={index}
                    width={setWidth(column.minWidth)}
                    cell={column.cell || null}
                    columnMenu={ColumnMenuNoGroup}
                  />
                );
              })}
            </Grid>
          </Preloader>
        </div>
      </div>
    </div>
  );
};

export default withRouter(InfusionQueue);
