import React from 'react';
import { Route } from 'react-router-dom';

// components
// import AgentWorkQueue from '@/components/Agent/AgentWorkQueue';
import LandingWidgets from '@/components/LandingPage/LandingPageWidgets';
import PriorAuthQueue from '@/components/Agent/PriorAuthQueue';
import BenInvQueue from '@/components/Agent/BenInvQueue';
import PatientPortal from '@/components/Patient/PatientPortal';
import Administration from '@/components/Administration/Administration';
import SchedulingQueue from '@/components/Scheduling/SchedulingQueue';
import NurseQueue from '@/components/Nurse/NurseQueue';
import InfusionQueue from '@/components/Infusion/InfusionQueue';
import FailedFaxQueue from '@/components/FAX/FailedFaxQueue';
import InfusionPortal from '@/components/Infusion/InfusionPortal';
import Inventory from '@/components/Inventory/Inventory';
import BillingQueue from '@/components/Billing/BillingQueue';
import ClinicianQueue from '@/components/Clinician/ClinicianQueue';
import SurveyBuilder from '@/components/SurveyBuilder';
import Calendar from '@/components/Calendar/Calendar';
import InfusionContainer from '@/components/InfusionV2/InfusionContainer';
import PatientCallQueue from '@/components/PatientCall/PatientCallQueue';
import Releases from '@/components/Releases';
import InboundFaxQueue from '@/components/Queue/InboundFaxQueue/InboundFaxQueue';
import QueueCenterContainer from '@/components/Queue/MainQueue/QueueCenterContainer';
import DashboardLanding from '@/components/Dashboard/DashboardLanding';
import DataHealth from '@/components/DataHealth';

// React Context
import roles from '@/context/enumUserRoles';

// React Router
import PrivateRoute from '@/PrivateRoute';

const App = () => {
  return (
    <article className='content'>
      <Route path='/' exact component={LandingWidgets} />
      {/* <Route path='/' exact component={AgentWorkQueue} /> */}
      <Route path='/widgets' exact component={LandingWidgets} />
      <Route path='/patient-portal' component={PatientPortal} />
      <Route path='/survey-builder' component={SurveyBuilder} />
      <Route path='/data-health' component={DataHealth} />
      <Route path='/releases' component={Releases} />
      <PrivateRoute
        path='/dashboard-queue'
        component={DashboardLanding}
        roles={[
          roles.supervisor,
          roles.admin,
          roles.nursing,
          roles.intake,
          roles.schedule
        ]}
      />
      <PrivateRoute
        path='/scheduler'
        component={Calendar}
        roles={[
          roles.supervisor,
          roles.admin,
          roles.clinician,
          roles.nursing,
          roles.intake,
          roles.schedule,
          roles.inventory
        ]}
      />
      <PrivateRoute
        path='/scheduling-queue'
        component={SchedulingQueue}
        roles={[
          roles.supervisor,
          roles.admin,
          roles.clinician,
          roles.nursing,
          roles.intake,
          roles.schedule,
          roles.inventory
        ]}
      />
      <PrivateRoute
        path='/nurse-queue'
        component={NurseQueue}
        roles={[
          roles.supervisor,
          roles.admin,
          roles.nursing,
          roles.intake,
          roles.schedule
        ]}
      />
      <PrivateRoute
        path='/infusion-queue'
        component={InfusionQueue}
        roles={[
          roles.supervisor,
          roles.admin,
          roles.nursing,
          roles.intake,
          roles.schedule
        ]}
      />
      <Route path='/infusion-portal' component={InfusionPortal} />
      <Route path='/infusion/:id' component={InfusionContainer} />
      <PrivateRoute
        path='/admin'
        component={Administration}
        roles={[roles.supervisor, roles.admin]}
      />
      <PrivateRoute
        path='/inventory'
        component={Inventory}
        roles={[
          roles.supervisor,
          roles.admin,
          roles.intake,
          roles.schedule,
          roles.inventory
        ]}
      />
      <PrivateRoute
        path='/billing'
        component={BillingQueue}
        roles={[roles.supervisor, roles.admin, roles.intake, roles.billing]}
      />
      <PrivateRoute
        path='/clinician'
        component={ClinicianQueue}
        roles={[roles.supervisor, roles.admin, roles.clinician]}
      />
      <PrivateRoute
        path='/pa-queue'
        exact
        component={PriorAuthQueue}
        roles={[roles.supervisor, roles.admin, roles.intake, roles.pa]}
      />
      <PrivateRoute
        path='/bi-queue'
        exact
        component={BenInvQueue}
        roles={[roles.supervisor, roles.admin, roles.intake, roles.bi]}
      />
      <PrivateRoute
        path='/patient-call'
        component={PatientCallQueue}
        roles={[roles.supervisor, roles.admin, roles.intake]}
      />
      <PrivateRoute
        path='/failed-fax-queue'
        exact
        component={FailedFaxQueue}
        roles={[roles.supervisor, roles.admin]}
      />
      <Route path='/new-inbound' component={InboundFaxQueue} />
      <Route path='/work-queue/:type' component={QueueCenterContainer} />
    </article>
  );
};

export default App;
