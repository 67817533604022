import React, { useContext } from 'react';

// kendo
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';

// partials
import DrugWithBadge from './DrugWithBadge';

// context
import { ProductContext } from '@/context';

// utils
import { sortArrayByField } from '@/common/Utility';

const DataHealth = () => {
  const {
    productsRaw,
    premeds,
    diluents,
    mixins,
    prescriptions,
    drugCategories
  } = useContext(ProductContext);

  const inactiveDrugs = productsRaw.filter(
    (item) => item.status === 'INACTIVE'
  );

  const drugsWithoutCategories = sortArrayByField(
    productsRaw.filter(
      (item) => item.category === null || item.category === ''
    ),
    'productName'
  );

  const drugDataItems = [
    {
      title: 'Prescriptions',
      count: prescriptions?.length,
      success: prescriptions?.length > 0,
      list: prescriptions.map((item) => <DrugWithBadge item={item} />)
    },
    {
      title: 'Inactive products',
      count: inactiveDrugs?.length,
      success: inactiveDrugs?.length > 0,
      list: inactiveDrugs.map((item) => item.productName)
    },
    {
      title: 'Pre-meds',
      count: premeds?.length,
      success: premeds?.length > 0,
      list: premeds.map((item) => item.selectorText)
    },
    {
      title: 'Diluents',
      count: diluents?.length,
      success: diluents?.length > 0,
      list: diluents.map((item) => item.productOnSelector)
    },
    {
      title: 'Mixins (isReconstituted)',
      count: mixins?.length,
      success: mixins?.length > 0,
      list: mixins.map((item) => item.productOnSelector)
    },
    {
      title: 'Categories',
      count: drugCategories?.length,
      success: drugCategories?.length > 0,
      list: [...new Set(drugCategories)].sort().map((item) => item)
    },
    {
      title: 'Drugs without categories',
      count: drugsWithoutCategories?.length,
      success: drugsWithoutCategories?.length === 0,
      list: drugsWithoutCategories.map((item) => <DrugWithBadge item={item} />)
    }
  ];

  const statusIcon = (success) => {
    return success ? '✅' : '❌';
  };

  return (
    <>
      <div className='row'>
        <div className='col-md-12 mt-3 offset-1'>
          <h3>Data Health Check</h3>
          <hr />
        </div>
        <div className='col-md-12 mt-3 offset-1'>
          <h5>Products</h5>
        </div>
        <div className='col-md-1' />
        <PanelBar className='col-md-6'>
          {drugDataItems.map((item, index) => {
            return (
              <PanelBarItem
                title={`${statusIcon(item.success)} ${item.title} (${
                  item.count
                } items)`}
                key={index}
              >
                <div>
                  {item.list.map((listItem, index) => {
                    return (
                      <div key={index}>
                        <span>{listItem}</span>
                      </div>
                    );
                  })}
                </div>
              </PanelBarItem>
            );
          })}
        </PanelBar>
      </div>
    </>
  );
};

export default DataHealth;
