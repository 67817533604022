import React, { useContext } from 'react';
import styled from 'styled-components';

// Common Components
import AwesomeLabel from '@/components/common-components/AwesomeLabel';

// Context
import {
  InfusionContext,
  SurveyContext,
  NotifContext,
  UserContext,
  LogContext
} from '@/context';

// GraphQL
import { connectToGraphqlAPI } from '@/provider';
import { addUpdateNotes, updatePatientSurvey } from '@/graphql/mutations';

// Utils
import {
  getLastTabletSurvey,
  getPriorTabletSurvey
} from '@/components/Infusion/infusionHelper';
import {
  calculateSurveyScores,
  generateEducationLinks,
  generateSurveyNotes,
  generateTabletSurveyChartNotes,
  getTabletSurveyScores
} from '@/components/SurveyEngine/helpers/helperQOL';
import surveyModelMapper, {
  getSurveyModel
} from '@/components/SurveyEngine/models/surveyModelMapper';
import {
  formatDateTimeToDefault,
  formatDateToAWSDateTime
} from '@/common/DateHelper';

const ReviewButton = styled.button`
  margin-top: 10px;
  min-width: 125px;
`;

const PatientSurveyReviewButton = () => {
  const { infusion, setInfusion } = useContext(InfusionContext);
  const {
    setActiveSurvey,
    setSurveyResults,
    setSurveyFollowup,
    setSurveyReviewMode
  } = useContext(SurveyContext);
  const { showError } = useContext(NotifContext);
  const { agent } = useContext(UserContext);
  const { logApiException } = useContext(LogContext);

  const { agentId } = agent;
  const { patientId } = infusion.patientBucket;

  const uploadPatientNotes = async (notes) => {
    try {
      const requestObject = {
        patientId,
        agentId,
        notes: [
          {
            date: formatDateToAWSDateTime(),
            note: notes,
            type: 'AQCCA',
            createdBy: agentId,
            modifiedNote: false // send false to add new
          }
        ]
      };

      const data = await connectToGraphqlAPI({
        graphqlQuery: addUpdateNotes,
        variables: { input: requestObject }
      });

      if (data.data?.addUpdateNotes?.notes) {
        // do nothing :-)
      } else {
        showError('Failed to save new Patient Notes (type - AQCCA)');
      }
    } catch (err) {
      logApiException(err, {
        view: 'PatientSurveyActions',
        endpoint: 'addUpdateNotes',
        patientId,
        agentId
      });
    }
  };

  const uploadSurveyCall = async (patientSurvey) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: updatePatientSurvey,
        variables: {
          agentId,
          patientId,
          patientSurvey
        }
      });
      if (data.data?.updatePatientSurvey) {
        // do nothing :-)
      } else {
        showError('Failed to update Patient Survey results');
      }
    } catch (err) {
      logApiException(err, {
        view: 'StartInfusionButton',
        endpoint: 'updatePatientSurvey',
        patientId,
        agentId,
        surveyType: patientSurvey?.meta?.surveyType,
        version: patientSurvey?.meta?.version
      });
    }
  };

  if (infusion.isSurveyComplete && !infusion.isSurveyReviewed) {
    return (
      <ReviewButton
        type="button"
        className="k-button k-primary"
        onClick={() => {
          const lastSurvey = getLastTabletSurvey(infusion.patientBucket.surveys);

          if (lastSurvey) {
            const { survey, collectedAt, collectedBy } = lastSurvey;
            const jsonResults = JSON.parse(survey);

            const priorSurvey = getPriorTabletSurvey(infusion.patientBucket.surveys);
            const priorScore = getTabletSurveyScores(priorSurvey);

            // get model by type
            const model = getSurveyModel(
              jsonResults.meta.surveyType,
              jsonResults.meta.version
            );

            // set the survey model
            setActiveSurvey({
              model,
              onFinish: async (surveyData) => {
                const jsonResults = JSON.parse(surveyData);
                const { surveyType, version, surveyFinished } =
                  jsonResults.meta;
                const { description } = surveyModelMapper[surveyType];

                // ======================
                // Calculate AQCCA & PHQ9 scores
                // ====================
                const score = calculateSurveyScores(jsonResults, true);
                jsonResults.score = score;

                // ======================
                // Generate chart notes based on results
                // ====================
                const notes = generateTabletSurveyChartNotes(jsonResults);
                await uploadPatientNotes(notes);

                // add the notes to survey meta data
                jsonResults.notes = notes;

                // ======================
                // Upload survey results
                // ====================
                const patientSurvey = {
                  survey: JSON.stringify(jsonResults),
                  typeOfSurvey: surveyType,
                  description,
                  version,
                  collectedAt: surveyFinished
                };
                await uploadSurveyCall(patientSurvey);

                // update review flag in the infusion level
                setInfusion({ ...infusion, isSurveyReviewed: true });

                // close survey modals
                setSurveyReviewMode(false);
                setSurveyFollowup(null);
                setSurveyResults(null);
                setActiveSurvey(null);
              }
            });

            setSurveyReviewMode(true);

            // display results in readonly mode
            setSurveyResults(survey);

            const workItems = generateSurveyNotes(jsonResults.notes);
            const education = generateEducationLinks(jsonResults);

            // followup modal
            setSurveyFollowup({
              education,
              workItems,
              score: jsonResults.score || [],
              priorScore: priorScore || [],
              priorCollectedAt:
                formatDateTimeToDefault(priorSurvey?.collectedAt) || null,
              collectedAt: formatDateTimeToDefault(collectedAt),
              collectedBy,
              version: jsonResults.meta?.version || 1,
              surveyType: jsonResults.meta?.surveyType
            });
          } else {
            throw new Error('PatientSurveyActions: Last survey not found');
          }
        }}
      >
        <AwesomeLabel
          icon="arrow-right"
          iconFirst={false}
          label="Review Survey Now"
        />
      </ReviewButton>
    );
  }

  return null;
};

// Display Names
ReviewButton.displayName = 'ReviewButton';

export default PatientSurveyReviewButton;
