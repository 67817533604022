import React from "react";
import pluralize from "pluralize";
import Alert from "@/components/common-components/Alert";

const FishbowlAlert = ({ alertType, itemsCount }) => {
  const pluralizedItems = pluralize("item", itemsCount, true);
  const pluralizedCodes = pluralize("code", itemsCount);
  const haveHas = itemsCount > 1 ? "have" : "has";
  const bodyInfo = `The following ${pluralizedItems} ready to be imported:`;
  const bodyWarn = `The following ${pluralizedItems} ${haveHas} unknown NDC ${pluralizedCodes} and will not be imported:`;

  const alertBody = alertType === "info" ? bodyInfo : bodyWarn;

  return <Alert type={alertType}>{alertBody}</Alert>;
};

export default FishbowlAlert;
