import React, { useEffect } from 'react';
import { Window } from '@progress/kendo-react-dialogs';

const WindowDialog = ({
  children,
  title,
  width = 300,
  initialHeight = 350,
  showDialog = false,
  onClose,
  ...props
}) => {
  useEffect(() => {
    return onClose;
  }, []);

  if (showDialog) {
    return (
      <Window
        title={title}
        width={width}
        initialHeight={initialHeight}
        onClose={onClose}
        {...props}
      >
        {children}
      </Window>
    );
  }
  return null;
};

export default WindowDialog;
