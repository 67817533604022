import React, { createContext, useState } from 'react';
import generateUUID from '@/common/uuid';

const types = {
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
  SUCCESS: 'success',
  DEFAULT: 'none'
};

const TIMEOUT = 5000;

export const NotifContext = createContext();

const NotifContextProvider = ({ children }) => {
  const [queue, setQueue] = useState([]);

  const renderMessage = (type, message) => {
    const id = generateUUID();
    setQueue((prevQueue) => [...prevQueue, { type, message, id }]);

    setTimeout(() => {
      setQueue((prevQueue) =>
        prevQueue.filter((notification) => notification.id !== id)
      );
    }, TIMEOUT);
  };

  const showError = (message) => renderMessage(types.ERROR, message);
  const showSuccess = (message) => renderMessage(types.SUCCESS, message);
  const showInfo = (message) => renderMessage(types.INFO, message);
  const showWarning = (message) => renderMessage(types.WARNING, message);
  const showDefault = (message) => renderMessage(types.DEFAULT, message);

  return (
    <NotifContext.Provider
      value={{
        queue,
        showError,
        showSuccess,
        showInfo,
        showWarning,
        showDefault
      }}
    >
      {children}
    </NotifContext.Provider>
  );
};

export default NotifContextProvider;
