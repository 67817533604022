import React, { useContext, useEffect, useCallback, useState } from 'react';
import * as moment from 'moment';
import styled from 'styled-components';

// kendo
import { Input, Checkbox } from '@progress/kendo-react-inputs';
import { Form, Field } from '@progress/kendo-react-form';
import { Grid } from '@progress/kendo-react-grid';
import { GridColumn as Column } from '@progress/kendo-react-grid/dist/npm/GridColumn';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import {
  PanelBar,
  PanelBarItem,
  TabStrip,
  TabStripTab
} from '@progress/kendo-react-layout';

// components
import { FormRadioGroup } from '@/components/common-components/FormRadioGroup';
import { MessageDialog } from '@/components/common-components/MessageDialog';
import FormButton from '@/components/common-components/Form/FormButton';
import Badge from '@/components/common-components/Badge';
import Preloader from '@/components/common-components/Preloader';
import AwesomeLabel from '@/components/common-components/AwesomeLabel';
import FullPageLoader from '@/components/common-components/FullPageLoader';
import Alert from '@/components/common-components/Alert';
import RequiredFormLabel from '@/components/common-components/RequiredFormLabel';

// context
import {
  UserContext,
  PatientContext,
  UserRoleTypes,
  StatusContext,
  NotifContext
} from '@/context';

// gql
import { connectToGraphqlAPI } from '@/provider';
import {
  getPriorAuthorization,
  getPatientReferralOrders,
  listGroupAICs,
  getLocationsAndProvidersByGroupId,
  getProductInfoByName
} from '@/graphql/queries';
import { addUpdatePriorAuthChecking } from '@/graphql/mutations';

// utils
import { validateInput } from '@/common/Validation';
import { getPatientWeightFromReferral } from '@/common/Converters';
import { Constants } from '@/constants';
import { cleanseString } from '@/common/String-Utils';

const Placeholder = styled.div`
  padding-top: 15px;
`;

const PriorAuthInfo = () => {
  const { showSuccess, showError } = useContext(NotifContext);
  const [loading, setLoading] = useState(true);
  const { user, canEdit } = useContext(UserContext);
  const { selectedPatientInfo } = useContext(PatientContext);
  const [listReferralOrdersData, setListReferralOrdersData] = useState([]);
  const [listInsuranceKey, setListInsuranceKey] = useState([]);
  const [selected, setSelected] = useState(0);
  const [, setListGroupAICsData] = useState([]);
  const [, setListLocationAICsData] = useState([]);
  const [, setListProviderAICsData] = useState([]);
  const [mainFormData, setMainFormData] = useState({});
  const [priorAuthData, setPriorAuthData] = useState({});
  const [showMainForm, setShowMainForm] = useState(true);
  const [dialogOption, setDialogOption] = useState({});
  const [submittedInsurance, setSubmittedInsurance] = useState();
  const [selectedLocation, setSelectedLocation] = useState();
  const [selectedProvider, setSelectedProvider] = useState();
  const [insuranceReferral, setInsuranceReferral] = useState();
  const [tabData, setTabData] = useState([]);
  const [itemAdministrations, setItemAdministrations] = React.useState([]);
  const [itemPreMeds, setItemPreMeds] = React.useState([]);
  const [jCodeData, setJcodeData] = useState();
  const { statusUpdate, setStatusUpdate } = useContext(StatusContext);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [showPlaceholderBI, setShowPlaceholderBI] = useState(false);

  const authStatusChoices = ['PENDING', 'APPROVED', 'DENIED'];

  const Reasons = [
    'Not medically necessary',
    'Not a covered benefit',
    'Plan exclusion',
    'Not on formulary',
    'Out of network'
  ];
  const statChoices = [
    { label: 'Yes', value: true, className: 'patient-radio blue' },
    { label: 'No', value: false, className: 'patient-radio blue' }
  ];
  const [authStatus, setAuthStatus] = useState(true);
  //   const approvalLengths = ['DAY', 'WEEK', 'MONTH', 'YEAR'];

  const priorAuthInfoForm = {
    authStatus: {
      value:
        priorAuthData?.priorAuth?.priorAuthChecking?.authStatus || 'PENDING',
      inputValidator: (value) => {
        return validateInput({
          authStatus: { ...priorAuthInfoForm.authStatus, value }
        });
      },
      validations: []
    },
    denialReason: {
      value: priorAuthData?.priorAuth?.priorAuthChecking?.denialReason,
      inputValidator: (value) => {
        return validateInput({
          authStatus: { ...priorAuthInfoForm.denialReason, value }
        });
      },
      validations: []
    },
    mdoContacted: {
      value: !!priorAuthData?.priorAuth?.priorAuthChecking?.mdoContacted,
      inputValidator: (value) => {
        return validateInput({
          stat: { ...priorAuthInfoForm.mdoContacted, value }
        });
      },
      validations: []
    },
    stat: {
      value: !!priorAuthData?.priorAuth?.priorAuthChecking?.stat,
      inputValidator: (value) => {
        return validateInput({ stat: { ...priorAuthInfoForm.stat, value } });
      },
      validations: []
    },
    insuranceCompanyName: {
      value:
        priorAuthData?.priorAuth?.priorAuthChecking?.requestHistory
          ?.insuranceCompanyName,
      inputValidator: (value) => {
        return validateInput({
          insuranceCompanyName: {
            ...priorAuthInfoForm.insuranceCompanyName,
            value
          }
        });
      },
      validations: []
    },
    personSpokeWith: {
      value:
        priorAuthData?.priorAuth?.priorAuthChecking?.requestHistory
          ?.personSpokeWith,
      inputValidator: (value) => {
        return validateInput({
          personSpokeWith: { ...priorAuthInfoForm.personSpokeWith, value }
        });
      },
      validations: []
    },
    phoneNumber: {
      value:
        priorAuthData?.priorAuth?.priorAuthChecking?.requestHistory
          ?.phoneNumber, // "+14155557777",
      inputValidator: (value) => {
        return validateInput({
          phoneNumber: { ...priorAuthInfoForm.phoneNumber, value }
        });
      },
      validations: []
    },
    callReference: {
      value:
        priorAuthData?.priorAuth?.priorAuthChecking?.requestHistory
          ?.callReference,
      inputValidator: (value) => {
        return validateInput({
          callReference: { ...priorAuthInfoForm.callReference, value }
        });
      },
      validations: []
    },
    submittedDate: {
      value: priorAuthData?.priorAuth?.priorAuthChecking?.requestHistory
        ?.submittedDate
        ? new Date(
            moment(
              priorAuthData?.priorAuth?.priorAuthChecking?.requestHistory
                ?.submittedDate
            ).add(new Date().getTimezoneOffset(), 'minutes')
          )
        : null, // "4/10/2021",
      inputValidator: (value) => {
        return validateInput({
          submittedDate: { ...priorAuthInfoForm.submittedDate, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        },
        {
          type: 'dateRange',
          message: Constants.ErrorMessage.dateOneHundredAndTwentyYearsPast,
          minDate: moment().subtract(120, 'year'),
          maxDate: moment().add(50, 'year')
        }
      ]
    },
    followUpDate: {
      value: priorAuthData?.priorAuth?.priorAuthChecking?.requestHistory
        ?.followUpDate
        ? new Date(
            moment(
              priorAuthData?.priorAuth?.priorAuthChecking?.requestHistory
                ?.followUpDate
            ).add(new Date().getTimezoneOffset(), 'minutes')
          )
        : null, // "4/20/2021",
      inputValidator: (value) => {
        return validateInput({
          followUpDate: { ...priorAuthInfoForm.followUpDate, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        },
        {
          type: 'dateRange',
          message: Constants.ErrorMessage.dateOneHundredAndTwentyYearsPast,
          minDate: moment().subtract(120, 'year'),
          maxDate: moment().add(50, 'year')
        }
      ]
    },
    isSiteSpecific: {
      value:
        !!priorAuthData?.priorAuth?.priorAuthChecking?.approvalInfo
          ?.isSiteSpecific,
      inputValidator: (value) => {
        return validateInput({
          isSiteSpecific: { ...priorAuthInfoForm.isSiteSpecific, value }
        });
      },
      validations: []
    },
    groupId: {
      value:
        priorAuthData?.priorAuth?.priorAuthChecking?.requestHistory?.billings[0]
          .groupId,
      inputValidator: (value) => {
        return validateInput({
          groupId: { ...priorAuthInfoForm.groupId, value }
        });
      },
      validations: []
    },
    locationId: {
      value:
        priorAuthData?.priorAuth?.priorAuthChecking?.requestHistory?.billings[0]
          .locationId,
      inputValidator: (value) => {
        return validateInput({
          locationId: { ...priorAuthInfoForm.locationId, value }
        });
      },
      validations: []
    },
    providerId: {
      value:
        priorAuthData?.priorAuth?.priorAuthChecking?.requestHistory?.billings[0]
          .providerId,
      inputValidator: (value) => {
        return validateInput({
          locationId: { ...priorAuthInfoForm.providerId, value }
        });
      },
      validations: []
    },
    inNetworkTIN: {
      value:
        priorAuthData?.priorAuth?.priorAuthChecking?.requestHistory?.billings[0]
          .inNetworkTIN,
      inputValidator: (value) => {
        return validateInput({
          inNetworkTIN: { ...priorAuthInfoForm.inNetworkTIN, value }
        });
      },
      validations: []
    },
    inNetworkNPI: {
      value:
        priorAuthData?.priorAuth?.priorAuthChecking?.requestHistory?.billings[0]
          .inNetworkNPI,
      inputValidator: (value) => {
        return validateInput({
          inNetworkNPI: { ...priorAuthInfoForm.inNetworkNPI, value }
        });
      },
      validations: []
    },
    outOfNetworkTIN: {
      value:
        priorAuthData?.priorAuth?.priorAuthChecking?.requestHistory?.billings[0]
          .outOfNetworkTIN,
      inputValidator: (value) => {
        return validateInput({
          outOfNetworkTIN: { ...priorAuthInfoForm.outOfNetworkTIN, value }
        });
      },
      validations: []
    },
    outOfNetworkNPI: {
      value:
        priorAuthData?.priorAuth?.priorAuthChecking?.requestHistory?.billings[0]
          .outOfNetworkNPI,
      inputValidator: (value) => {
        return validateInput({
          outOfNetworkNPI: { ...priorAuthInfoForm.outOfNetworkNPI, value }
        });
      },
      validations: []
    },
    jCode1: {
      value:
        priorAuthData?.priorAuth?.priorAuthChecking?.requestHistory?.jCodes
          .length > 0
          ? priorAuthData?.priorAuth?.priorAuthChecking?.requestHistory
              ?.jCodes[0].code
          : null,
      inputValidator: (value) => {
        return validateInput({
          jCode1: { ...priorAuthInfoForm.jCode1, value }
        });
      },
      validations: []
    },
    jCode2: {
      value:
        priorAuthData?.priorAuth?.priorAuthChecking?.requestHistory?.jCodes
          .length > 1
          ? priorAuthData?.priorAuth?.priorAuthChecking?.requestHistory
              ?.jCodes[1].code
          : null,
      inputValidator: (value) => {
        return validateInput({
          jCode2: { ...priorAuthInfoForm.jCode2, value }
        });
      },
      validations: []
    },
    jCode3: {
      value:
        priorAuthData?.priorAuth?.priorAuthChecking?.requestHistory?.jCodes
          .length > 2
          ? priorAuthData?.priorAuth?.priorAuthChecking?.requestHistory
              ?.jCodes[2].code
          : null,
      inputValidator: (value) => {
        return validateInput({
          jCode3: { ...priorAuthInfoForm.jCode3, value }
        });
      },
      validations: []
    },
    jCodeUnits1: {
      value:
        priorAuthData?.priorAuth?.priorAuthChecking?.requestHistory?.jCodes
          .length > 0
          ? priorAuthData?.priorAuth?.priorAuthChecking?.requestHistory
              ?.jCodes[0].units
          : null,
      inputValidator: (value) => {
        return validateInput({
          jCodeUnits1: { ...priorAuthInfoForm.jCodeUnits1, value }
        });
      },
      validations: [
        {
          type: 'onlyNumeric',
          message: Constants.ErrorMessage.Numeric_Required
        }
      ]
    },
    jCodeUnits2: {
      value:
        priorAuthData?.priorAuth?.priorAuthChecking?.requestHistory?.jCodes
          .length > 1
          ? priorAuthData?.priorAuth?.priorAuthChecking?.requestHistory
              ?.jCodes[1].units
          : null,
      inputValidator: (value) => {
        return validateInput({
          jCodeUnits2: { ...priorAuthInfoForm.jCodeUnits2, value }
        });
      },
      validations: [
        {
          type: 'onlyNumeric',
          message: Constants.ErrorMessage.Numeric_Required
        }
      ]
    },
    jCodeUnits3: {
      value:
        priorAuthData?.priorAuth?.priorAuthChecking?.requestHistory?.jCodes
          .length > 2
          ? priorAuthData?.priorAuth?.priorAuthChecking?.requestHistory
              ?.jCodes[2].units
          : null,
      inputValidator: (value) => {
        return validateInput({
          jCodeUnits3: { ...priorAuthInfoForm.jCodeUnits3, value }
        });
      },
      validations: [
        {
          type: 'onlyNumeric',
          message: Constants.ErrorMessage.Numeric_Required
        }
      ]
    },
    adminCode1: {
      value:
        priorAuthData?.priorAuth?.priorAuthChecking?.requestHistory?.adminCodes
          .length > 0
          ? priorAuthData?.priorAuth?.priorAuthChecking?.requestHistory
              ?.adminCodes[0].code
          : null,
      inputValidator: (value) => {
        return validateInput({
          adminCode1: { ...priorAuthInfoForm.adminCode1, value }
        });
      },
      validations: []
    },
    adminCode2: {
      value:
        priorAuthData?.priorAuth?.priorAuthChecking?.requestHistory?.adminCodes
          .length > 1
          ? priorAuthData?.priorAuth?.priorAuthChecking?.requestHistory
              ?.adminCodes[1].code
          : null,
      inputValidator: (value) => {
        return validateInput({
          adminCode2: { ...priorAuthInfoForm.adminCode2, value }
        });
      },
      validations: []
    },
    adminCode3: {
      value:
        priorAuthData?.priorAuth?.priorAuthChecking?.requestHistory?.adminCodes
          .length > 2
          ? priorAuthData?.priorAuth?.priorAuthChecking?.requestHistory
              ?.adminCodes[2].code
          : null,
      inputValidator: (value) => {
        return validateInput({
          adminCode3: { ...priorAuthInfoForm.adminCode3, value }
        });
      },
      validations: []
    },
    adminCodeUnits1: {
      value:
        priorAuthData?.priorAuth?.priorAuthChecking?.requestHistory?.adminCodes
          .length > 0
          ? priorAuthData?.priorAuth?.priorAuthChecking?.requestHistory
              ?.adminCodes[0].units
          : null,
      inputValidator: (value) => {
        return validateInput({
          adminCodeUnits1: { ...priorAuthInfoForm.adminCodeUnits1, value }
        });
      },
      validations: [
        {
          type: 'onlyNumeric',
          message: Constants.ErrorMessage.Numeric_Required
        }
      ]
    },
    adminCodeUnits2: {
      value:
        priorAuthData?.priorAuth?.priorAuthChecking?.requestHistory?.adminCodes
          .length > 1
          ? priorAuthData?.priorAuth?.priorAuthChecking?.requestHistory
              ?.adminCodes[1].units
          : null,
      inputValidator: (value) => {
        return validateInput({
          adminCodeUnits2: { ...priorAuthInfoForm.adminCodeUnits2, value }
        });
      },
      validations: [
        {
          type: 'onlyNumeric',
          message: Constants.ErrorMessage.Numeric_Required
        }
      ]
    },
    adminCodeUnits3: {
      value:
        priorAuthData?.priorAuth?.priorAuthChecking?.requestHistory?.adminCodes
          .length > 2
          ? priorAuthData?.priorAuth?.priorAuthChecking?.requestHistory
              ?.adminCodes[2].units
          : null,
      inputValidator: (value) => {
        return validateInput({
          adminCodeUnits3: { ...priorAuthInfoForm.adminCodeUnits3, value }
        });
      },
      validations: [
        {
          type: 'onlyNumeric',
          message: Constants.ErrorMessage.Numeric_Required
        }
      ]
    },
    priorAuthNumber: {
      value:
        priorAuthData?.priorAuth?.priorAuthChecking?.approvalInfo
          ?.priorAuthNumber, // "5555555",
      inputValidator: (value) => {
        return validateInput({
          priorAuthNumber: { ...priorAuthInfoForm.priorAuthNumber, value }
        });
      },
      validations: [
        {
          type: 'noExtraSymbols',
          message: Constants.ErrorMessage.NO_EXTRA_SYMBOLS
        }
      ]
    },
    serviceFrom: {
      value: priorAuthData?.priorAuth?.priorAuthChecking?.approvalInfo
        ?.serviceFrom
        ? new Date(
            moment(
              priorAuthData?.priorAuth?.priorAuthChecking?.approvalInfo
                ?.serviceFrom
            ).add(new Date().getTimezoneOffset(), 'minutes')
          )
        : null, // "4/20/2021",
      inputValidator: (value) => {
        return validateInput({
          serviceFrom: { ...priorAuthInfoForm.serviceFrom, value }
        });
      },
      validations: [
        {
          type: 'dateRange',
          message: Constants.ErrorMessage.dateOneHundredAndTwentyYearsPast,
          minDate: moment().subtract(120, 'year'),
          maxDate: moment().add(50, 'year')
        }
      ]
    },
    serviceTo: {
      value: priorAuthData?.priorAuth?.priorAuthChecking?.approvalInfo
        ?.serviceTo
        ? new Date(
            moment(
              priorAuthData?.priorAuth?.priorAuthChecking?.approvalInfo
                ?.serviceTo
            ).add(new Date().getTimezoneOffset(), 'minutes')
          )
        : null, // "4/20/2021",
      inputValidator: (value) => {
        return validateInput({
          serviceTo: { ...priorAuthInfoForm.serviceTo, value }
        });
      },
      validations: [
        {
          type: 'dateRange',
          message: Constants.ErrorMessage.dateOneHundredAndTwentyYearsPast,
          minDate: moment().subtract(120, 'year'),
          maxDate: moment().add(50, 'year')
        }
      ]
    },
    priorAuthVisits: {
      value:
        !!priorAuthData?.priorAuth?.priorAuthChecking?.approvalInfo
          ?.numberOfApprovedVisits,
      inputValidator: (value) => {
        return validateInput({
          priorAuthVisits: { ...priorAuthInfoForm.priorAuthVisits, value }
        });
      },
      validations: []
    },
    numberOfApprovedVisits: {
      value:
        priorAuthData?.priorAuth?.priorAuthChecking?.approvalInfo
          ?.numberOfApprovedVisits,
      inputValidator: (value) => {
        return validateInput({
          numberOfApprovedVisits: {
            ...priorAuthInfoForm.numberOfApprovedVisits,
            value
          }
        });
      },
      validations: []
    },
    priorAuthUnits: {
      value:
        !!priorAuthData?.priorAuth?.priorAuthChecking?.approvalInfo
          ?.numberOfApprovedUnits,
      inputValidator: (value) => {
        return validateInput({
          priorAuthUnits: { ...priorAuthInfoForm.priorAuthUnits, value }
        });
      },
      validations: []
    },
    numberOfApprovedUnits: {
      value:
        priorAuthData?.priorAuth?.priorAuthChecking?.approvalInfo
          ?.numberOfApprovedUnits,
      inputValidator: (value) => {
        return validateInput({
          numberOfApprovedUnits: {
            ...priorAuthInfoForm.numberOfApprovedUnits,
            value
          }
        });
      },
      validations: []
    },
    every: {
      value:
        priorAuthData?.priorAuth?.priorAuthChecking?.approvalInfo?.frequency
          ?.every,
      inputValidator: (value) => {
        return validateInput({ every: { ...priorAuthInfoForm.every, value } });
      },
      validations: []
    },
    everyUnit: {
      value:
        priorAuthData?.priorAuth?.priorAuthChecking?.approvalInfo?.frequency
          ?.everyUnit,
      inputValidator: (value) => {
        return validateInput({
          everyUnit: { ...priorAuthInfoForm.everyUnit, value }
        });
      },
      validations: []
    },
    duration: {
      value:
        priorAuthData?.priorAuth?.priorAuthChecking?.approvalInfo?.frequency
          ?.duration,
      inputValidator: (value) => {
        return validateInput({
          duration: { ...priorAuthInfoForm.duration, value }
        });
      },
      validations: []
    },
    durationUnit: {
      value:
        priorAuthData?.priorAuth?.priorAuthChecking?.approvalInfo?.frequency
          ?.durationUnit,
      inputValidator: (value) => {
        return validateInput({
          durationUnit: { ...priorAuthInfoForm.durationUnit, value }
        });
      },
      validations: []
    },
    welcomeCallAttempt1: {
      value:
        priorAuthData?.priorAuth?.priorAuthChecking?.welcomeCalls[0]
          ?.answered || false,
      inputValidator: (value) => {
        return validateInput({
          welcomeCallAttempt1: {
            ...priorAuthInfoForm.welcomeCallAttempt1,
            value
          }
        });
      },
      validations: []
    },
    welcomeCallAttempt2: {
      value:
        priorAuthData?.priorAuth?.priorAuthChecking?.welcomeCalls[1]
          ?.answered || false,
      inputValidator: (value) => {
        return validateInput({
          welcomeCallAttempt2: {
            ...priorAuthInfoForm.welcomeCallAttempt2,
            value
          }
        });
      },
      validations: []
    },
    welcomeCallAttempt3: {
      value:
        priorAuthData?.priorAuth?.priorAuthChecking?.welcomeCalls[2]
          ?.answered || false,
      inputValidator: (value) => {
        return validateInput({
          welcomeCallAttempt3: {
            ...priorAuthInfoForm.welcomeCallAttempt3,
            value
          }
        });
      },
      validations: []
    },
    welcomeCallCompleted: {
      value:
        priorAuthData?.priorAuth?.priorAuthChecking?.welcomeCallCompleted ||
        false,
      inputValidator: (value) => {
        return validateInput({
          welcomeCallCompleted: {
            ...priorAuthInfoForm.welcomeCallCompleted,
            value
          }
        });
      },
      validations: []
    },
    isPACompleted: {
      value: !!priorAuthData?.priorAuth?.priorAuthChecking?.isCompleted,
      inputValidator: (value) => {
        return validateInput({
          isPACompleted: { ...priorAuthInfoForm.isPACompleted, value }
        });
      },
      validations: []
    }
  };

  const initialForm = () => {
    const initialObject = {};
    Object.keys(priorAuthInfoForm).forEach((key) => {
      initialObject[key] = priorAuthInfoForm[key].value;
    });
    return initialObject;
  };

  const renderInsuranceLinks = () => {
    return listInsuranceKey.map((item) => (
      <span
        className='help-link ml-4 mr-4'
        key={item.selectedGroup}
        onClick={() => handleLoadInsuranceOrder(item)}
      >
        <AwesomeLabel icon='clipboard-medical' label={item.insuranceKey} />
      </span>
    ));
  };

  // MAIN INITIATOR
  useEffect(() => {
    listReferralOrdersCall(selectedPatientInfo.patientId);
    listGroupAICsCall(selectedPatientInfo.patientId);
  }, []);

  const listReferralOrdersCall = async (requestObject) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getPatientReferralOrders,
        variables: { patientId: requestObject }
      });
      if (
        data &&
        data.data &&
        data.data.getPatientBucket &&
        data.data.getPatientBucket.referral &&
        data.data.getPatientBucket.referral.drugReferrals &&
        data.data.getPatientBucket.referral.drugReferrals.length
      ) {
        const tabDisplayData = [];
        data.data.getPatientBucket.benefitInvestigation.benefitCheckings.forEach(
          (biChecking) => {
            // find if there's a match in drugReferrals
            const matchedRef = selectedPatientInfo.referral.drugReferrals.find(
              (referral) => referral.referralId === biChecking.referralId
            );
            const hasPA = biChecking.checkings.some(
              (item) => item.paRequired !== false
            );

            if (matchedRef && hasPA) {
              tabDisplayData.push({ ...matchedRef, ...biChecking });
            }
          }
        );
        setListReferralOrdersData(tabDisplayData);
        setTabData(tabDisplayData);
      }
    } catch (err) {
      console.log('getPatientReferralOrders data err', err);
      setDialogOption({
        title: 'PA: Prior Auth Info',
        message: 'Error: getPatientReferralOrders',
        showDialog: true
      });
    }
  };

  const listGroupAICsCall = async () => {
    try {
      const { data } = await connectToGraphqlAPI({
        graphqlQuery: listGroupAICs
      });
      if (data?.listGroupAICs?.items) {
        setListGroupAICsData(
          data.listGroupAICs.items?.map((item) => ({
            ...item,
            text: item.name,
            value: item.id
          }))
        );
      }
    } catch (err) {
      console.log('listGroupAICsCall data err', err);
      setDialogOption({
        title: 'PA: Prior Auth Info',
        message: 'Error: listGroupAICsCall',
        showDialog: true
      });
    }
  };

  const listLocationsAndProvidersCall = async (
    groupId,
    selectedLocationId,
    selectedProviderId
  ) => {
    try {
      if (!groupId) {
        setListLocationAICsData([]);
        return;
      }
      const data = await connectToGraphqlAPI({
        graphqlQuery: getLocationsAndProvidersByGroupId,
        variables: { groupId }
      });
      if (
        data &&
        data.data &&
        data.data.getLocationsAndProvidersByGroupId &&
        data.data.getLocationsAndProvidersByGroupId.length
      ) {
        const groupLocations = data.data.getLocationsAndProvidersByGroupId
          .map((item) => ({
            ...item,
            text: `${item.locationName}, ${item.state} (NPI: ${item.locationNPI})`,
            value: item.id
          }))
          .sort((a, b) => (a.locationName > b.locationName ? 1 : -1));
        setListLocationAICsData(groupLocations);
        let currentLocation;
        // prepopulate selected Location for existing entries
        if (selectedLocationId) {
          currentLocation = groupLocations.find(
            (location) => location.id === selectedLocationId
          );
          if (currentLocation) setSelectedLocation(currentLocation.text);
        }
        // prepopulate providers list for existing entries
        if (
          currentLocation &&
          currentLocation.providers &&
          selectedProviderId
        ) {
          const providers = mapProviders(currentLocation.providers);
          setListProviderAICsData(providers);
          const provider = providers.find(
            ({ providerNPI }) => providerNPI === selectedProviderId
          );
          if (provider) setSelectedProvider(provider.text);
        }
      } else {
        setListLocationAICsData([]);
      }
    } catch (err) {
      console.log('DEV: listLocationsAndProvidersCall data err', err);
      setDialogOption({
        title: 'BI: Prior Authorization',
        message: 'Error: listLocationsAndProvidersCall',
        showDialog: true
      });
    }
  };

  const handleGetPriorAuthSubmit = (dataItem) => {
    const requestObject = {
      dataItem,
      insuranceKey: dataItem.insuranceKey,
      referralId: dataItem.referral.referralId,
      patientId: selectedPatientInfo.patientId
    };
    setLoading(true);
    setShowMainForm(false);
    getPriorAuthorizationCall(requestObject);
  };

  const getPriorAuthorizationCall = async (requestObject) => {
    try {
      const { data } = await connectToGraphqlAPI({
        graphqlQuery: getPriorAuthorization,
        variables: requestObject
      });
      setLoading(false);
      const isAllowed = data?.getPriorAuthorization?.isPARequired;
      if (!isAllowed) {
        setDialogOption({
          title: 'PA: Prior Auth Info',
          message:
            "To use this Insurance type, please set it's 'PA REQUIRED' option to 'Yes' on the BI: Check Benefits screen.",
          showDialog: true
        });
        return;
      }

      setAuthStatus(
        data?.getPriorAuthorization?.priorAuthChecking?.authStatus || 'PENDING'
      );

      if (data?.getPriorAuthorization) {
        let overrideChoice = '';
        if (
          data.getPriorAuthorization?.associatedBIChecking
            ?.selectedBillingNPI ===
          data.getPriorAuthorization.associatedBIChecking?.selectedProviderId
        ) {
          overrideChoice = 'PROVIDER';
        } else {
          overrideChoice = 'LOCATION';
        }
        if (data.getPriorAuthorization?.isBICompleted) {
          setMainFormData({
            selectedOrder: requestObject.dataItem.referral,
            insuranceInfo: selectedPatientInfo.patientProfile.insuranceInfo,
            priorAuth: data.getPriorAuthorization,
            overrideChoice,
            canComplete: requestObject.dataItem.referral.clinical
              ? requestObject.dataItem.referral.clinical.orderApproved
              : false
          });
          setPriorAuthData({ priorAuth: data.getPriorAuthorization });
          if (data.getPriorAuthorization.associatedBIChecking) {
            const { selectedGroupId, selectedLocationId, selectedProviderId } =
              data.getPriorAuthorization.associatedBIChecking;
            listLocationsAndProvidersCall(
              selectedGroupId,
              selectedLocationId,
              selectedProviderId
            );
          }
          setItemAdministrations(
            requestObject.dataItem.referral.referralOrder?.administrations.map(
              (item) => ({
                ...item,
                text: item.orderName,
                value: item.orderName
              })
            )
          );
          setItemPreMeds(
            requestObject.dataItem.referral.referralOrder?.preMedications.map(
              (item) => ({
                ...item,
                text: item.orderName,
                value: item.orderName
              })
            )
          );
          setShowMainForm(true);
        } else {
          setShowPlaceholderBI(true);
        }
      }
    } catch (err) {
      console.error('PriorAuthInfo: getPriorAuthorizationCall err: ', err);
      setDialogOption({
        title: 'PA: Prior Auth Info',
        message: `Error: getPriorAuthorizationCall.\nCheck to see if '${requestObject.dataItem.insuranceKey}' exists.`,
        showDialog: true
      });
    }
  };

  const getPriorAuthorizationInsuranceCall = async (requestObject) => {
    try {
      const { data } = await connectToGraphqlAPI({
        graphqlQuery: getPriorAuthorization,
        variables: requestObject
      });
      const isAllowed = data?.getPriorAuthorization?.isPARequired;
      if (!isAllowed) {
        setDialogOption({
          title: 'PA: Prior Auth Info',
          message:
            "To use this Insurance type, please set it's 'PA REQUIRED' option to 'Yes' on the BI: Check Benefits screen.",
          showDialog: true
        });
        return;
      }

      setAuthStatus(
        data?.getPriorAuthorization?.priorAuthChecking?.authStatus || 'PENDING'
      );

      if (data.getPriorAuthorization) {
        let overrideChoice = '';
        if (
          data.getPriorAuthorization.associatedBIChecking
            ?.selectedBillingNPI ===
          data.getPriorAuthorization.associatedBIChecking?.selectedProviderId
        ) {
          overrideChoice = 'PROVIDER';
        } else {
          overrideChoice = 'LOCATION';
        }
        if (data.getPriorAuthorization?.isBICompleted) {
          setMainFormData({
            selectedOrder: requestObject.dataItem.referral,
            insuranceInfo: selectedPatientInfo.patientProfile.insuranceInfo,
            priorAuth: data.getPriorAuthorization,
            overrideChoice,
            canComplete: requestObject.dataItem.referral.clinical
              ? requestObject.dataItem.referral.clinical.orderApproved
              : false
          });
          setPriorAuthData({ priorAuth: data.getPriorAuthorization });
          if (data.getPriorAuthorization.associatedBIChecking) {
            const { selectedGroupId, selectedLocationId, selectedProviderId } =
              data.getPriorAuthorization.associatedBIChecking;
            listLocationsAndProvidersCall(
              selectedGroupId,
              selectedLocationId,
              selectedProviderId
            );
          }
          setItemAdministrations(
            requestObject.dataItem.referral.referralOrder?.administrations.map(
              (item) => ({
                ...item,
                text: item.orderName,
                value: item.orderName
              })
            )
          );
          setItemPreMeds(
            requestObject.dataItem.referral.referralOrder?.preMedications.map(
              (item) => ({
                ...item,
                text: item.orderName,
                value: item.orderName
              })
            )
          );
          setPriorAuthData({ priorAuth: data.getPriorAuthorization });
          setShowMainForm(true);
        }
      }
    } catch (err) {
      console.log('DEV: getPriorAuthorizationCall err', err);
      setDialogOption({
        title: 'PA: Prior Auth Info',
        message: `Error: getPriorAuthorizationCall.\nCheck to see if '${requestObject.dataItem.insuranceKey}' exists.`,
        showDialog: true
      });
    }
  };

  const mapProviders = (providers) => {
    if (providers && providers.length) {
      return providers
        .map((provider) => ({
          ...provider,
          text: `${provider.firstName} ${provider.lastName}, ${provider.type} (NPI: ${provider.providerNPI})`,
          value: provider.providerNPI
        }))
        .sort((a, b) => (a.firstName > b.firstName ? 1 : -1));
    }
  };

  const onChangeAuthStatus = useCallback(
    (value, onChangeField) => {
      setAuthStatus(value);

      // sync PA complete checkbox
      onChangeField('isPACompleted', {
        value:
          value === 'APPROVED' ? priorAuthInfoForm.isPACompleted.value : false
      });
    },
    [priorAuthInfoForm]
  );

  const handleSubmit = (dataItem) => {
    const priorAuthNumber = cleanseString(dataItem.priorAuthNumber);
    setButtonLoading(true);
    const requestObject = {
      agentId: user.username,
      patientId: selectedPatientInfo.patientId,
      priorAuthInfo: {
        referralId: mainFormData.selectedOrder.referralId,
        insuranceKey: capitalizeFirstLetter(submittedInsurance),
        stat: dataItem.stat,
        authStatus: dataItem.authStatus,
        denialReason: dataItem.denialReason,
        mdoContacted: dataItem.mdoContacted,
        approvalInfo: {
          isSiteSpecific: dataItem.isSiteSpecific,
          priorAuthNumber: priorAuthNumber,
          serviceFrom: dataItem.serviceFrom
            ? moment(dataItem.serviceFrom).format('YYYY-MM-DD')
            : null,
          serviceTo: dataItem.serviceTo
            ? moment(dataItem.serviceTo).format('YYYY-MM-DD')
            : null,
          numberOfApprovedUnits: dataItem.numberOfApprovedUnits
            ? dataItem.numberOfApprovedUnits
            : null,
          numberOfApprovedVisits: dataItem.numberOfApprovedVisits
            ? dataItem.numberOfApprovedVisits
            : null,
          frequency: {
            every: dataItem.every ? dataItem.every : null,
            everyUnit: dataItem.everyUnit,
            duration: dataItem.duration ? dataItem.duration : null,
            durationUnit: dataItem.durationUnit
          }
        },
        requestHistory: {
          billings: [
            {
              groupId: dataItem.groupId,
              locationId: dataItem.locationId,
              providerId: dataItem.providerId,
              inNetworkTIN: dataItem.inNetworkTIN,
              inNetworkNPI: dataItem.inNetworkNPI,
              outOfNetworkTIN: dataItem.outOfNetworkTIN,
              outOfNetworkNPI: dataItem.outOfNetworkNPI
            }
          ],
          insuranceCompanyName: dataItem.insuranceCompanyName,
          phoneNumber: dataItem.phoneNumber,
          personSpokeWith: dataItem.personSpokeWith,
          callReference: dataItem.callReference,
          jCodes: [
            {
              code: dataItem.jCode1,
              units: dataItem.jCodeUnits1
                ? parseInt(dataItem.jCodeUnits1)
                : null
            },
            {
              code: dataItem.jCode2,
              units: dataItem.jCodeUnits2
                ? parseInt(dataItem.jCodeUnits2)
                : null
            },
            {
              code: dataItem.jCode3,
              units: dataItem.jCodeUnits3
                ? parseInt(dataItem.jCodeUnits3)
                : null
            }
          ],
          adminCodes: [
            {
              code: dataItem.adminCode1,
              units: dataItem.adminCodeUnits1
                ? parseInt(dataItem.adminCodeUnits1)
                : null
            },
            {
              code: dataItem.adminCode2,
              units: dataItem.adminCodeUnits2
                ? parseInt(dataItem.adminCodeUnits2)
                : null
            },
            {
              code: dataItem.adminCode3,
              units: dataItem.adminCodeUnits3
                ? parseInt(dataItem.adminCodeUnits3)
                : null
            }
          ],
          submittedDate: moment(dataItem.submittedDate).format('YYYY-MM-DD'),
          followUpDate: moment(dataItem.followUpDate).format('YYYY-MM-DD')
        },
        welcomeCalls: [
          {
            callTime: moment(new Date()).format(),
            agentId: user.username,
            answered: !!dataItem.welcomeCallAttempt1
          },
          {
            callTime: moment(new Date()).format(),
            agentId: user.username,
            answered: !!dataItem.welcomeCallAttempt2
          },
          {
            callTime: moment(new Date()).format(),
            agentId: user.username,
            answered: !!dataItem.welcomeCallAttempt3
          }
        ],
        welcomeCallCompleted: dataItem.welcomeCallCompleted,
        callCompletionTime: dataItem.callCompletionTime,
        isCompleted: !!dataItem.isPACompleted
      }
    };
    addUpdatePriorAuthCheckingData(requestObject);
  };

  const addUpdatePriorAuthCheckingData = async (requestObject) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: addUpdatePriorAuthChecking,
        variables: { input: requestObject }
      });
      if (data.data && data.data.addUpdatePriorAuthChecking) {
        showSuccess('Prior Auth Updated Sucessfully.');
        setStatusUpdate(!statusUpdate);
        setButtonLoading(false);
        // handleLoadInsuranceOrder(requestObject?.priorAuthInfo)
      }
    } catch (err) {
      console.log('DEV: addUpdatePriorAuthCheckingData err', err);
      showError('Error: addUpdatePriorAuthCheckingData');
    }
  };

  const showOtherDX =
    mainFormData.selectedOrder?.referralOrder?.otherDX?.length > 0;
  const patientWeightKg = getPatientWeightFromReferral(
    mainFormData.selectedOrder?.referralOrder
  );

  const productName =
    mainFormData?.selectedOrder?.referralOrder?.administrations[0]?.drugName;

  useEffect(() => {
    // Added if statement to stop a null value from being passed the the api call and creating a console error
    if (productName !== undefined) {
      getProductInfoByNameCall(productName);
    }
  }, [productName]);

  const getProductInfoByNameCall = async () => {
    try {
      const { data } = await connectToGraphqlAPI({
        graphqlQuery: getProductInfoByName,
        variables: {
          productName: productName == null ? undefined : productName
        }
      });
      setJcodeData(data?.getProductInfoByName?.items[0]?.jCode);
    } catch (err) {
      console.log('err in retrieving getProductInfoByName', err);
      setJcodeData(''); // todo: show error dialog
    }
  };

  useEffect(() => {
    if (tabData?.[0]?.checkings[0]?.insuranceKey) {
      const keyData = tabData?.[0]?.checkings
        .sort((a, b) => {
          const textA = a.insuranceKey.toUpperCase();
          const textB = b.insuranceKey.toUpperCase();
          return textA.localeCompare(textB);
        })
        .filter((key) => key.paRequired === true);
      const startData = {
        referral: listReferralOrdersData[0],
        insuranceKey: keyData[0].insuranceKey
      };
      setSubmittedInsurance(keyData[0].insuranceKey.toLowerCase());
      setInsuranceReferral(listReferralOrdersData[0]);
      setListInsuranceKey(keyData);
      handleGetPriorAuthSubmit(startData);
    }
  }, [tabData]);

  const handleLoadInsuranceOrder = (dataItem) => {
    setSubmittedInsurance(dataItem?.insuranceKey.toLowerCase());
    const requestObject = {
      dataItem: { referral: insuranceReferral },
      insuranceKey: dataItem.insuranceKey,
      referralId: insuranceReferral.referralId,
      patientId: selectedPatientInfo.patientId
    };
    setShowMainForm(false);
    getPriorAuthorizationInsuranceCall(requestObject);
  };

  const handleLoadOrder = (dataObject) => {
    const requiredCheckings = dataObject?.checkings.filter(
      (pa) => pa.paRequired === true
    );
    const paInsurance =
      requiredCheckings.length > 0
        ? requiredCheckings[0].insuranceKey.toLowerCase()
        : '';
    setSubmittedInsurance(paInsurance);
    const selectedData = {
      referral: dataObject,
      insuranceKey: paInsurance
    };
    handleGetPriorAuthSubmit(selectedData);
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const handleSelect = (e) => {
    setMainFormData({});
    setItemAdministrations([]);
    setItemPreMeds([]);
    const idx = Number(e.selected);
    const order = listReferralOrdersData[idx];
    const checkings = listReferralOrdersData[idx]?.checkings
      .sort((a, b) => {
        const textA = a.insuranceKey.toUpperCase();
        const textB = b.insuranceKey.toUpperCase();
        return textA.localeCompare(textB);
      })
      .filter((key) => key.paRequired === true);
    setListInsuranceKey(checkings);
    setSelected(idx);
    setInsuranceReferral(order);
    handleLoadOrder(order);
  };

  const PA_COMPLETE_LOCKED = authStatus === 'PENDING';

  const renderPriorAuth = () => {
    return (
      <Preloader show={loading}>
        {showPlaceholderBI ? (
          <Placeholder>
            <Badge
              text='Error: Need to Check BI Complete before starting Prior Auth'
              type='alert'
            />
          </Placeholder>
        ) : (
          <div className='row check-notes'>
            <div className='col'>
              {dialogOption && dialogOption.showDialog && (
                <MessageDialog dialogOption={dialogOption} />
              )}
              {/* MAIN FORM */}
              {/* <div className="row mt-16 ml-1">
              <div className="col-md-2">ORDER NAME:</div>
              <div className="col-md-3">
                <strong>
                  {mainFormData?.selectedOrder?.referralOrder?.orderName}
                </strong>
              </div>
              <div className="col-md-2">PATIENT NAME:</div>
              <div className="col-md-2">
                <strong>
                  {selectedPatientInfo?.patientFirstName}&nbsp;
                  {selectedPatientInfo?.patientLastName}
                </strong>
              </div>
              <div className="col-md-1">DOB:</div>
              <div className="col-md-2">
                <strong>{selectedPatientInfo?.dob}</strong>
              </div>
            </div>
            <hr /> */}
              <div className='row mt-16'>
                <div className='col-4' />
                <div className='col-4'>{renderInsuranceLinks()}</div>
                <div className='col-4' />
              </div>
              <hr />
              {submittedInsurance &&
                mainFormData?.insuranceInfo &&
                mainFormData?.insuranceInfo[submittedInsurance] && (
                  <>
                    <div className='row mt-10 ml-1'>
                      <div className='col-md-2'>INSURANCE TYPE:</div>
                      <div className='col-md-3'>
                        <strong className='bright'>
                          {submittedInsurance.toUpperCase()}
                        </strong>
                      </div>
                      <div className='col-md-1'>POLICY ID:</div>
                      <div className='col-md-2'>
                        <strong>
                          {
                            mainFormData?.insuranceInfo[submittedInsurance]
                              ?.policyId
                          }
                        </strong>
                      </div>
                      <div className='col-md-1'>GROUP ID:</div>
                      <div className='col-md-2'>
                        <strong>
                          {
                            mainFormData?.insuranceInfo[submittedInsurance]
                              ?.groupId
                          }
                        </strong>
                      </div>
                    </div>
                    <div className='row mt-10 ml-1'>
                      <div className='col-md-2'>INSURANCE PLAN:</div>
                      <div className='col-md-3'>
                        <strong>
                          {
                            mainFormData?.insuranceInfo[submittedInsurance]
                              ?.insurerName
                          }
                        </strong>
                        &nbsp;--&nbsp;
                        <strong>
                          {
                            mainFormData?.insuranceInfo[submittedInsurance]
                              ?.planName
                          }
                        </strong>
                      </div>
                      <div className='col-md-1'>PHONE:</div>
                      <div className='col-md-2'>
                        <strong>
                          {
                            mainFormData?.insuranceInfo[submittedInsurance]
                              ?.customerServicePhone
                          }
                        </strong>
                      </div>
                      <div className='col-md-1'>STATE:</div>
                      <div className='col-md-2'>
                        <strong>
                          {
                            mainFormData?.insuranceInfo[submittedInsurance]
                              ?.state
                          }
                        </strong>
                      </div>
                    </div>
                  </>
                )}

              <PanelBar className='mt-4'>
                <PanelBarItem expanded title='REFERRAL ORDER'>
                  <div className='row'>
                    <div className='col mt-08'>
                      <strong>
                        {mainFormData.selectedOrder?.referralOrder?.orderName}
                      </strong>
                    </div>
                  </div>
                  {patientWeightKg > 0 && (
                    <div className='row'>
                      <div className='col mt-08'>
                        Original Order Weight:{' '}
                        <strong>{patientWeightKg} KG</strong>
                      </div>
                    </div>
                  )}

                  <div className='row mt-4'>
                    <div className='col-md-2 mt-08'>ADMINISTRATION:</div>
                    <div className='col-md-10 mt-08'>
                      <Grid data={itemAdministrations} className='a-grid'>
                        <Column
                          field='drugName'
                          title='PRODUCT NAME'
                          width='160px'
                        />
                        <Column field='route' title='ROUTE' width='80px' />
                        <Column
                          field='administer'
                          title='ADMINISTER'
                          width='200px'
                        />
                        <Column
                          field='maxOfTreatments'
                          title='MAX #'
                          width='80px'
                        />
                        <Column
                          field='approvedDosage'
                          title='DOSE'
                          width='80px'
                        />
                        <Column field='unitOfMeas' title='UOM' width='60px' />
                        <Column
                          field='calcDosage'
                          title='CALC DOSE'
                          width='140px'
                        />
                      </Grid>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col mt-3'>PRE-MEDICATION:</div>
                    <div className='col-md-10 mt-08'>
                      <Grid data={itemPreMeds} className='a-grid'>
                        <Column
                          field='drugName'
                          title='PRODUCT NAME'
                          width='160px'
                        />
                        <Column field='route' title='ROUTE' width='80px' />
                        <Column
                          field='administer'
                          title='ADMINISTER'
                          width='200px'
                        />
                        <Column
                          field='maxOfTreatments'
                          title='MAX #'
                          width='80px'
                        />
                        <Column
                          field='approvedDosage'
                          title='DOSE'
                          width='80px'
                        />
                        <Column field='unitOfMeas' title='UOM' width='60px' />
                      </Grid>
                    </div>
                  </div>
                  <div className='row mt-4'>
                    <div className='col'>ORDERING PROVIDER</div>
                    <div className='col'>ORDER DATE</div>
                    <div className='col'>ORDER EXPIRES</div>
                  </div>
                  <div className='row'>
                    <div className='col'>
                      <strong>
                        {mainFormData.selectedOrder?.prescriberId}
                      </strong>
                    </div>
                    <div className='col'>
                      <strong>
                        {mainFormData.selectedOrder?.referralOrder?.orderDate}
                      </strong>
                    </div>
                    <div className='col'>
                      <strong>
                        {
                          mainFormData.selectedOrder?.referralOrder
                            ?.orderExpires
                        }
                      </strong>
                    </div>
                  </div>
                  <div className='row mt-4'>
                    <div className='col-4'>PRIMARY DX</div>
                    {showOtherDX && <div className='col-4'>OTHER DX</div>}
                  </div>
                  <div className='row'>
                    <div className='col-4'>
                      <strong>
                        {
                          mainFormData.selectedOrder?.referralOrder?.primaryDX
                            .primaryDiagnosis
                        }
                      </strong>
                      <br />+{' '}
                      {
                        mainFormData.selectedOrder?.referralOrder?.primaryDX
                          ?.description
                      }
                    </div>
                    {showOtherDX && (
                      <div className='col-4'>
                        {mainFormData.selectedOrder?.referralOrder?.otherDX.map(
                          (dx) => (
                            <>
                              <strong>{dx.primaryDiagnosis}</strong>
                              <br />
                              {dx.description}
                              <br />
                            </>
                          )
                        )}
                      </div>
                    )}
                  </div>
                  <div className='row mt-4'>
                    <div className='col'>NOTES</div>
                  </div>
                  <div className='row'>
                    <div className='col-8'>
                      <strong>
                        {mainFormData?.selectedOrder?.referralOrder?.notes}
                      </strong>
                    </div>
                  </div>
                </PanelBarItem>
              </PanelBar>

              <div className='row mt-4 pl-3'>
                <div className='col-3'>
                  {/* PATIENT HAS STARTED THERAPY:  */}
                  Continuation of Care: &nbsp;
                  <strong>
                    {mainFormData?.selectedOrder?.patientHasStartedTherapy
                      ? 'True'
                      : 'False'}
                  </strong>
                </div>
                <div className='col-2'>
                  # Treatments: &nbsp;
                  <strong>
                    {mainFormData?.selectedOrder?.noOfTreatments ?? '0'}
                  </strong>
                </div>
                <div className='col-3'>
                  First Treatment Date: &nbsp;
                  <strong>
                    {mainFormData?.selectedOrder?.firstTreatmentDate}
                  </strong>
                </div>
                <div className='col-3'>
                  Last Treatment Date: &nbsp;
                  <strong>
                    {mainFormData?.selectedOrder?.lastTreatmentDate}
                  </strong>
                </div>
              </div>

              <hr />
              {showMainForm && (
                <>
                  <Form
                    onSubmit={handleSubmit}
                    initialValues={initialForm()}
                    render={(formRenderProps) => (
                      <form
                        onSubmit={formRenderProps.onSubmit}
                        className='k-form'
                      >
                        <PanelBar className='mt-4'>
                          <PanelBarItem expanded title='INSURANCE INFORMATION'>
                            <div className='row'>
                              <div className='col-md-9' />
                            </div>
                            <div className='row'>
                              <div className='col-md-3'>
                                Agent Name: {user.username}
                              </div>
                              <div className='col-md-6' />
                            </div>
                            <div className='row'>
                              <div className='col-md-2 mt-18'>STAT:</div>
                              <div className='col-md-2 mt-06'>
                                <Field
                                  name='stat'
                                  data={statChoices}
                                  layout='horizontal'
                                  component={FormRadioGroup}
                                  validator={
                                    priorAuthInfoForm.stat.inputValidator
                                  }
                                />
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-3'>
                                <Field
                                  name='insuranceCompanyName'
                                  label='Insurance Company Name'
                                  component={Input}
                                  validator={
                                    priorAuthInfoForm.insuranceCompanyName
                                      .inputValidator
                                  }
                                />
                              </div>
                              <div className='col-md-3'>
                                <Field
                                  name='personSpokeWith'
                                  label='Person Spoke With'
                                  component={Input}
                                  validator={
                                    priorAuthInfoForm.personSpokeWith
                                      .inputValidator
                                  }
                                />
                              </div>
                              <div className='col-md-3'>
                                <Field
                                  name='phoneNumber'
                                  label='Insurance Company Phone'
                                  component={Input}
                                  validator={
                                    priorAuthInfoForm.phoneNumber.inputValidator
                                  }
                                />
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-6'>
                                <Field
                                  name='callReference'
                                  label='Call Reference #'
                                  component={Input}
                                  validator={
                                    priorAuthInfoForm.callReference
                                      .inputValidator
                                  }
                                />
                              </div>
                            </div>
                            <div className='row mt-12'>
                              <div className='col-md-3'>
                                <RequiredFormLabel text='Requested Start Date' />
                                <br />
                                <Field
                                  name='submittedDate'
                                  component={DatePicker}
                                  validator={
                                    priorAuthInfoForm.submittedDate
                                      .inputValidator
                                  }
                                />
                              </div>
                              <div className='col-md-3'>
                                <RequiredFormLabel text='Requested End Date' />
                                <br />
                                <Field
                                  name='followUpDate'
                                  component={DatePicker}
                                  validator={
                                    priorAuthInfoForm.followUpDate
                                      .inputValidator
                                  }
                                />
                              </div>
                            </div>
                          </PanelBarItem>

                          <PanelBarItem expanded title='BILLING INFORMATION:'>
                            <div className='row mt-4'>
                              <div className='col-md-10'>
                                <div className='row mt-12'>
                                  <div className='col-md-2' />
                                  <div className='col-md-4'>AIC GROUP:</div>
                                  <div className='col-md-4'>
                                    {
                                      priorAuthData?.priorAuth
                                        ?.groupAndAssociates?.name
                                    }
                                  </div>
                                </div>
                                <div className='row mt-12'>
                                  <div className='col-md-2' />
                                  <div className='col-md-4'>TAX ID:</div>
                                  <div className='col-md-4'>
                                    {
                                      priorAuthData?.priorAuth
                                        ?.groupAndAssociates?.taxId
                                    }
                                  </div>
                                </div>
                                <div className='row mt-12'>
                                  <div className='col-md-2' />
                                  <div className='col-md-4'>AIC LOCATION:</div>
                                  <div className='col-md-4'>
                                    {selectedLocation}
                                  </div>
                                </div>
                                <div className='row mt-12'>
                                  <div className='col-md-2' />
                                  <div className='col-md-4'>PROVIDER:</div>
                                  <div className='col-md-4'>
                                    {selectedProvider}
                                  </div>
                                </div>
                                <div className='row mt-12'>
                                  <div className='col-md-2' />
                                  <div className='col-md-4'>
                                    LOCATION OR PROVIDER NPI:
                                  </div>
                                  <div className='col-md-4'>
                                    {mainFormData?.overrideChoice}
                                  </div>
                                </div>
                                <div className='row mt-12'>
                                  <div className='col-md-2' />
                                  <div className='col-md-4'>OVERRIDE TIN:</div>
                                  <div className='col-md-4'>
                                    {
                                      priorAuthData?.priorAuth
                                        ?.associatedBIChecking
                                        ?.billingTaxIdForOutOfNetwork
                                    }
                                  </div>
                                </div>
                                <div className='row mt-12'>
                                  <div className='col-md-2' />
                                  <div className='col-md-4'>OVERRIDE NPI:</div>
                                  <div className='col-md-4'>
                                    {
                                      priorAuthData?.priorAuth
                                        ?.associatedBIChecking
                                        ?.billingNPIForOutOfNetwork
                                    }
                                  </div>
                                </div>
                                <div className='row mt-12'>
                                  <div className='col-md-2' />
                                  <div className='col-md-4'>
                                    OVERRIDE REASON:
                                  </div>
                                  <div className='col-md-4'>
                                    {
                                      priorAuthData?.priorAuth
                                        ?.associatedBIChecking
                                        ?.billingOverrideReason
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          </PanelBarItem>

                          <PanelBarItem expanded title='PROCEDURE CODES'>
                            <div className='row mt-08'>
                              <div className='col-md-12'>
                                PROCEDURE CODE: QUANTITIES OR # OF UNITS:
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-6 ml-5'>
                                <div className='row mt-12 '>
                                  <div className='col-md-3 mt-12'>
                                    <strong>J-CODE 1:</strong> &nbsp;{' '}
                                    {jCodeData}
                                  </div>
                                  <div className='col-md-3'>
                                    <Field
                                      name='jCodeUnits1'
                                      layout='horizontal'
                                      label='# Units/Visits'
                                      component={Input}
                                      validator={
                                        priorAuthInfoForm.jCodeUnits1
                                          .inputValidator
                                      }
                                    />
                                  </div>
                                </div>
                                <div className='row'>
                                  <div className='col-md-3 mt-12'>
                                    <strong>J-CODE 2:</strong> &nbsp;{' '}
                                    {jCodeData}
                                  </div>
                                  <div className='col-md-3'>
                                    <Field
                                      name='jCodeUnits2'
                                      layout='horizontal'
                                      label='# Units/Visits'
                                      component={Input}
                                      validator={
                                        priorAuthInfoForm.jCodeUnits2
                                          .inputValidator
                                      }
                                    />
                                  </div>
                                </div>
                                <div className='row'>
                                  <div className='col-md-3 mt-12'>
                                    <strong>J-CODE 3:</strong> &nbsp;{' '}
                                    {jCodeData}
                                  </div>
                                  <div className='col-md-3'>
                                    <Field
                                      name='jCodeUnits3'
                                      layout='horizontal'
                                      label='# Units/Visits'
                                      component={Input}
                                      validator={
                                        priorAuthInfoForm.jCodeUnits3
                                          .inputValidator
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className='col-md-5'>
                                <div className='row mt-16'>
                                  <div className='col-md-4'>
                                    <Field
                                      name='adminCode1'
                                      layout='horizontal'
                                      label='Admin Code 1'
                                      component={Input}
                                      validator={
                                        priorAuthInfoForm.adminCode1
                                          .inputValidator
                                      }
                                    />
                                  </div>
                                  <div className='col-md-4'>
                                    <Field
                                      name='adminCodeUnits1'
                                      layout='horizontal'
                                      label='# Units/Visits'
                                      component={Input}
                                      validator={
                                        priorAuthInfoForm.adminCodeUnits1
                                          .inputValidator
                                      }
                                    />
                                  </div>
                                </div>
                                <div className='row'>
                                  <div className='col-md-4'>
                                    <Field
                                      name='adminCode2'
                                      layout='horizontal'
                                      label='Admin Code 2'
                                      component={Input}
                                      validator={
                                        priorAuthInfoForm.adminCode2
                                          .inputValidator
                                      }
                                    />
                                  </div>
                                  <div className='col-md-4'>
                                    <Field
                                      name='adminCodeUnits2'
                                      layout='horizontal'
                                      label='# Units/Visits'
                                      component={Input}
                                      validator={
                                        priorAuthInfoForm.adminCodeUnits2
                                          .inputValidator
                                      }
                                    />
                                  </div>
                                </div>
                                <div className='row'>
                                  <div className='col-md-4'>
                                    <Field
                                      name='adminCode3'
                                      layout='horizontal'
                                      label='Admin Code 3'
                                      component={Input}
                                      validator={
                                        priorAuthInfoForm.adminCode3
                                          .inputValidator
                                      }
                                    />
                                  </div>
                                  <div className='col-md-4'>
                                    <Field
                                      name='adminCodeUnits3'
                                      layout='horizontal'
                                      label='# Units/Visits'
                                      component={Input}
                                      validator={
                                        priorAuthInfoForm.adminCodeUnits3
                                          .inputValidator
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </PanelBarItem>

                          <PanelBarItem expanded title='PRIOR AUTH APPROVAL'>
                            <div className='row mt-3'>
                              <div className='col-md-2 mt-08'>
                                <RequiredFormLabel text='AUTH STATUS' />
                              </div>
                              <div className='col-md-4'>
                                <Field
                                  name='authStatus'
                                  component={DropDownList}
                                  data={authStatusChoices}
                                  onChange={(e) =>
                                    onChangeAuthStatus(
                                      e.value,
                                      formRenderProps.onChange
                                    )
                                  }
                                  validator={
                                    priorAuthInfoForm.authStatus.inputValidator
                                  }
                                />
                              </div>
                            </div>

                            {authStatus === 'APPROVED' && (
                              <>
                                <div className='row mt-08'>
                                  <div className='col-md-3 offset-md-2'>
                                    <Field
                                      name='priorAuthNumber'
                                      label='Prior Auth Number'
                                      component={Input}
                                      validator={
                                        priorAuthInfoForm.priorAuthNumber
                                          .inputValidator
                                      }
                                    />
                                  </div>
                                  <div className='col-md-3'>
                                    <RequiredFormLabel text='Service From Date' />
                                    <Field
                                      name='serviceFrom'
                                      component={DatePicker}
                                      validator={
                                        priorAuthInfoForm.serviceFrom
                                          .inputValidator
                                      }
                                    />
                                  </div>
                                  <div className='col-md-3'>
                                    <RequiredFormLabel text='Service To Date' />
                                    <Field
                                      name='serviceTo'
                                      component={DatePicker}
                                      validator={
                                        priorAuthInfoForm.serviceTo
                                          .inputValidator
                                      }
                                    />
                                  </div>
                                </div>
                                <div className='row'>
                                  <div className='col-md-4 mt-16'>
                                    VISITS / UNITS APPROVED:
                                  </div>
                                </div>
                                <div className='row'>
                                  <div className='col-md-1 mt-16 offset-md-2'>
                                    <Field
                                      name='priorAuthVisits'
                                      component={Checkbox}
                                      validator={
                                        priorAuthInfoForm.priorAuthVisits
                                          .inputValidator
                                      }
                                    />
                                  </div>
                                  <div className='col-md-2'>
                                    <Field
                                      name='numberOfApprovedVisits'
                                      label='# Visits'
                                      component={Input}
                                      validator={
                                        priorAuthInfoForm.numberOfApprovedVisits
                                          .inputValidator
                                      }
                                    />
                                  </div>
                                </div>
                                <div className='row'>
                                  <div className='col-md-1 mt-16 offset-md-2'>
                                    <Field
                                      name='priorAuthUnits'
                                      component={Checkbox}
                                      validator={
                                        priorAuthInfoForm.priorAuthUnits
                                          .inputValidator
                                      }
                                    />
                                  </div>
                                  <div className='col-md-2'>
                                    <Field
                                      name='numberOfApprovedUnits'
                                      label='Total # of Units Approved'
                                      layout='horizontal'
                                      component={Input}
                                      validator={
                                        priorAuthInfoForm.numberOfApprovedUnits
                                          .inputValidator
                                      }
                                    />
                                  </div>
                                </div>
                                {/*

                                <div className="col-md-1">
                                  <Field
                                    name="every"
                                    label="Every"
                                    layout="horizontal"
                                    component={Input}
                                    validator={priorAuthInfoForm.every.inputValidator}
                                  />
                                </div>
                                <div className="col-md-2 mt-12">
                                  <Field
                                    name="everyUnit"
                                    defaultItem="DAY"
                                    component={DropDownList}
                                    data={approvalLengths}
                                  />
                                </div>
                                <div className="col-md-1">
                                  <Field
                                    name="duration"
                                    label="For"
                                    component={Input}
                                    validator={priorAuthInfoForm.duration.inputValidator}
                                  />
                                </div>
                                <div className="col-md-2 mt-12">
                                  <Field
                                    name="durationUnit"
                                    defaultItem="DAY"
                                    component={DropDownList}
                                    data={approvalLengths}
                                  />
                                </div>
                              </div> */}
                              </>
                            )}
                            {authStatus === 'DENIED' && (
                              <>
                                <div className='row'>
                                  <div className='col-md-2' />
                                  <div className='col-md-4'>
                                    <Field
                                      name='denialReason'
                                      layout='horizontal'
                                      label='Denied Reason'
                                      component={DropDownList}
                                      data={Reasons}
                                    />
                                  </div>
                                </div>
                                <div className='row'>&nbsp;</div>
                                <div className='row'>
                                  <div className='col-md-2' />
                                  <div className='col-md-4'>
                                    <Field
                                      name='mdoContacted'
                                      component={Checkbox}
                                      label='MDO Contacted'
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                            {authStatus === 'PENDING' && (
                              <div className='row'>
                                <div className='col-md-10 offset-md-1 mt-16' />
                              </div>
                            )}
                          </PanelBarItem>

                          <PanelBarItem
                            expanded={false}
                            title='WELCOME CALL TO PATIENT'
                          >
                            <div className='row mt-4'>
                              <div className='col-md-3' />
                              <div className='col-md-3 mt-12'>
                                <Field
                                  name='welcomeCallAttempt1'
                                  label='1st Attempt'
                                  component={Checkbox}
                                  validator={
                                    priorAuthInfoForm.welcomeCallAttempt1
                                      .inputValidator
                                  }
                                />
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-3 mt-12 offset-md-3'>
                                <Field
                                  name='welcomeCallAttempt2'
                                  label='2nd Attempt'
                                  component={Checkbox}
                                  validator={
                                    priorAuthInfoForm.welcomeCallAttempt2
                                      .inputValidator
                                  }
                                />
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-3 mt-12 offset-md-3'>
                                <Field
                                  name='welcomeCallAttempt3'
                                  label='3rd Attempt'
                                  component={Checkbox}
                                  validator={
                                    priorAuthInfoForm.welcomeCallAttempt3
                                      .inputValidator
                                  }
                                />
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-3 mt-12'>
                                <Field
                                  name='welcomeCallCompleted'
                                  label='WELCOME CALL COMPLETED'
                                  component={Checkbox}
                                  validator={
                                    priorAuthInfoForm.isPACompleted
                                      .inputValidator
                                  }
                                />
                              </div>
                            </div>
                          </PanelBarItem>
                        </PanelBar>

                        <div className='row mt-12 pl-3'>
                          <div className='col-md-4'>
                            <Field
                              name='isPACompleted'
                              label='PRIOR AUTHORIZATION COMPLETE'
                              component={Checkbox}
                              validator={
                                priorAuthInfoForm.isPACompleted.inputValidator
                              }
                              disabled={PA_COMPLETE_LOCKED}
                            />
                          </div>
                          {PA_COMPLETE_LOCKED && (
                            <Alert type='warning'>
                              Warning: You can't complete PA while status is
                              PENDING
                            </Alert>
                          )}
                        </div>

                        <div className='row col-md-12 mt-22'>
                          <div className='col-12'>
                            <FormButton
                              disabled={!canEdit(UserRoleTypes.PriorAuth)}
                              type='submit'
                              className='k-button pageButton blue'
                              loading={buttonLoading}
                            >
                              Submit
                            </FormButton>
                          </div>
                        </div>
                      </form>
                    )}
                  />
                </>
              )}
            </div>
          </div>
        )}
      </Preloader>
    );
  };

  return (
    <>
      <div className='row'>
        <div className='col'>
          <div className='k-form pl-3 pr-3 pt-1'>
            <div className='row'>
              <div className='col-md-3 pageTitle'>Prior Authorization</div>
            </div>
          </div>

          <div
            className='col-md-12 mt-3'
            id='tab-strip-gray'
            style={{ minHeight: '800px' }}
          >
            {listReferralOrdersData && listReferralOrdersData.length > 0 ? (
              <TabStrip
                selected={selected}
                onSelect={handleSelect}
                className='tsg-container'
              >
                {listReferralOrdersData.map((item) => (
                  <TabStripTab title={item.drugName} key={item.referralId}>
                    {renderPriorAuth()}
                  </TabStripTab>
                ))}
              </TabStrip>
            ) : (
              <div>
                <Badge
                  text='No active prior authorization orders available'
                  type='warning'
                />
              </div>
            )}
          </div>
          <hr />
        </div>
      </div>
      {buttonLoading && <FullPageLoader />}
    </>
  );
};

export default PriorAuthInfo;
