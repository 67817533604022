import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

// kendo
import { Button } from '@progress/kendo-react-buttons';

// components
import { Notes } from '@/components/Notes';
import PatientDocsModal from '@/components/Patient/PatientDocsModal';
import PatientDocument from '@/components/Patient/PatientDocument';
import InfusionUpdateProviderDialog from './InfusionUpdateProviderDialog';
import WindowDialog from '@/components/common-components/WindowDialog';
import TimeInChair from '@/components/Infusion/TimeInChair';
import AwesomeLabel from '@/components/common-components/AwesomeLabel';

// context
import {
  InfusionContext,
  PatientContext,
  UserContext,
  LogContext
} from '@/context';

// gql
import { connectToGraphqlAPI } from '@/provider';
import {
  addUpdateNotes,
  updateProviderIdInNursingProcess
} from '@/graphql/mutations';

// utils
import {
  formatTimeToDefault,
  adjustUTC,
  formatDateToDefault
} from '@/common/DateHelper';
import {
  listPatientAllergies,
  listOrderPreMeds
} from '@/components/Infusion/infusionHelper';
import { getDosageUOM } from '@/common/DoseHelper';
import { getCabenuvaDosageLabel } from '@/common/viivHelper';

// assets
import logo from '@/assets/images/alrea-new-square-logo.png';
import PatientStatusBadge from '../common-components/PatientStatusBadge';

const InfusionHeaderV2 = () => {
  const history = useHistory();
  const { infusion, setInfusion } = useContext(InfusionContext);
  const { selectedPatientInfo, setSelectedPatientInfo } =
    useContext(PatientContext);
  const { logApiException } = useContext(LogContext);
  const { isNursingAdmin, user } = useContext(UserContext);
  const [showPatientDocsDialog, setShowPatientDocsDialog] = useState(false);
  const [showPatientDocDialog, setShowPatientDocDialog] = useState(false);
  const [showPatientNotesDialog, setShowPatientNotesDialog] = useState(false);
  const [
    showInfusionUpdateProviderDialog,
    setShowInfusionUpdateProviderDialog
  ] = useState(false);
  const [selectedDocumentUrl, setSelectedDocumentUrl] = useState('');
  const np = infusion?.nursingProcess || {};
  const location = infusion?.location || {};
  const patientBucket = infusion?.patientBucket || {};
  const infusionDetails = infusion?.nursingProcess || {};
  const referalDetails = infusion?.currentReferral || {};
  const providerDetails = infusion?.provider || {};
  const locationTimezone = infusion?.location.timeZone || 'America/Los_Angeles';

  const updateProviderIdInNursingProcessCall = async (payload) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: updateProviderIdInNursingProcess,
        variables: {
          input: payload
        }
      });
      if (data?.data?.updateProviderIdInNursingProcess?.statusCode === '200') {
        // add a new patient note
        const note = `Provider was changed for nursing process (id: ${
          infusionDetails.id
        }) started ${formatDateToDefault(
          infusionDetails?.startTime
        )}. Old provider - ${providerDetails?.firstName} ${
          providerDetails?.lastName
        } (id: ${providerDetails?.providerNPI}). New provider - TODO`;

        const requestObject = {
          patientId: selectedPatientInfo.patientId,
          agentId: user.username,
          notes: [
            {
              date: moment(),
              note,
              type: 'NURSING',
              createdBy: user.username,
              modifiedNote: false // send false to add new
            }
          ]
        };
        try {
          await addUpdateNotesCall(requestObject);
          return true;
        } catch (err) {
          logApiException(err, {
            view: 'InfusionHeaderV2',
            endpoint: 'addUpdateNotesCall',
            input: requestObject
          });
        }
      }
      return false;
    } catch (err) {
      logApiException(err, {
        view: 'InfusionHeaderV2',
        endpoint: 'updateProviderIdInNursingProcess',
        input: payload
      });
      return false;
    }
  };

  const addUpdateNotesCall = async (requestObject) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: addUpdateNotes,
        variables: { input: requestObject }
      });
      if (data.data?.addUpdateNotes?.notes) {
        setInfusion({
          ...infusion,
          patientBucket: {
            ...patientBucket,
            notes: data.data?.addUpdateNotes?.notes
          }
        });
        return true;
      }
    } catch (err) {
      logApiException(err, {
        view: 'InfusionHeaderV2',
        endpoint: 'addUpdateNotesCall',
        input: requestObject
      });
    }
  };

  const togglePatientDocsDialog = () => {
    setShowPatientDocsDialog(!showPatientDocsDialog);
  };

  const toggleShowPatientDocDialog = () => {
    setShowPatientDocDialog(!showPatientDocDialog);
  };

  const togglePatientNotesDialog = () => {
    setShowPatientNotesDialog(!showPatientNotesDialog);
  };

  const handlePatientDocs = () => {
    setShowPatientDocsDialog(true);
  };

  const documentViewCallback = (s3ImageURL) => {
    setSelectedDocumentUrl(s3ImageURL);
    if (showPatientDocsDialog) {
      setShowPatientDocsDialog(false);
    }
    setShowPatientDocDialog(true);
  };

  const startTime = formatTimeToDefault(
    adjustUTC(np?.startTime, locationTimezone)
  );
  const isCabenuva = infusion?.currentReferral?.isCabenuva || false;

  const onLogoClick = () => {
    history.push('/');
  };

  const getSequenceBasedData = () => {
    const seqBasedData = {
      calcDosage: 0,
      administer: 'N/A',
      unitOfMeas: 'N/A',
      route: 'N/A',
      dose: 0
    };

    if (!infusion?.scheduleEvent?.adminSequenceNumber) return seqBasedData;

    const seqItem =
      infusion?.currentReferral?.referralOrder?.administrations.find(
        ({ adminSequenceNumber }) =>
          adminSequenceNumber === infusion?.scheduleEvent.adminSequenceNumber
      );
    if (!seqItem) {
      console.error(
        'Error: no administration found for sequence number: ',
        infusion?.scheduleEvent.adminSequenceNumber
      );
      return seqBasedData;
    }
    if (isCabenuva) {
      seqItem.calcDualDosage = getCabenuvaDosageLabel(seqItem.dose);
      seqItem.dualDosage = getCabenuvaDosageLabel(seqItem.approvedDosage);
    }
    return seqItem;
  };

  const {
    calcDosage,
    dualDosage,
    unitOfMeas,
    dose,
    administer,
    route,
    calcDualDosage
  } = getSequenceBasedData();

  const providerName = `${providerDetails?.firstName} ${providerDetails?.lastName}, ${providerDetails?.type}`;

  const headerMapper = [
    {
      data: [
        {
          label: (
            <b>
              {patientBucket?.patientFirstName} {patientBucket?.patientLastName}{' '}
              <PatientStatusBadge status={selectedPatientInfo?.patientStatus} />
            </b>
          ),
          icon: 'user'
        },
        { title: 'DOB', label: patientBucket?.dob, icon: 'cake-candles' },
        {
          title: 'ALLERGIES',
          label: listPatientAllergies(
            patientBucket?.patientProfile?.allergyInfo
          ),
          icon: 'virus',
          duotone: true
        },
        {
          title: 'PRE-MEDS',
          label: listOrderPreMeds(
            referalDetails?.referralOrder?.preMedications
          ),
          icon: 'prescription-bottle',
          duotone: true
        }
      ]
    },
    {
      data: [
        {
          title: 'AIC',
          label: `${location?.locationName}, ${location?.state}`,
          icon: 'map-location-dot',
          duotone: true
        },
        {
          title: 'PROVIDER',
          label: isNursingAdmin ? (
            <span
              className='help-link'
              onClick={() => setShowInfusionUpdateProviderDialog(true)}
            >
              {providerName}
            </span>
          ) : (
            providerName
          ),
          icon: 'user-doctor-hair'
        },
        {
          title: 'ARRIVAL TIME',
          label: startTime,
          icon: 'house-person-return'
        },
        {
          title: 'TIME IN CHAIR',
          label: infusionDetails?.startTime && (
            <TimeInChair
              startTime={infusionDetails?.startTime}
              finishTime={infusionDetails?.endTime}
            />
          ),
          icon: 'bed-pulse',
          duotone: true
        }
      ]
    },
    {
      data: [
        {
          title: 'DRUG (ROUTE)',
          label: `${referalDetails?.drugName} (${route})`,
          icon: 'pills'
        },
        {
          title: 'ORIG ORDER DOSAGE',
          label: isCabenuva ? dualDosage : `${dose} ${unitOfMeas}`,
          icon: 'flask-vial'
        },
        {
          title: 'CALCULATED DOSAGE',
          label: isCabenuva
            ? calcDualDosage
            : `${calcDosage} ${getDosageUOM(unitOfMeas)}`,
          icon: 'flask-vial',
          duotone: true
        },
        {
          title: 'FREQUENCY',
          label: administer,
          icon: 'calendar-days',
          duotone: true
        }
      ]
    }
  ];

  useEffect(() => {
    if (infusion) {
      setSelectedPatientInfo(infusion.patientBucket);
    }
  }, [infusion]);

  return (
    <div className='container-fluid'>
      <div className='row' style={{ background: '#005282' }}>
        <div className='col-md-12 offset-md-0 infusion-portal-header'>
          <div className='row'>
            <div className='col-md-1 mt-0' style={{ background: '#005282' }}>
              <div className='d-flex logo'>
                <img
                  src={logo}
                  className='companyLogoInfusion'
                  onClick={onLogoClick}
                  alt=''
                />
              </div>
            </div>
            <div className='col-md-10 mb-1 mt-10'>
              <div className='row'>
                {headerMapper.map((headerColumn, i) => {
                  return (
                    <div
                      className='col-md-3 headerText'
                      key={`headerColumn-${i}`}
                    >
                      {headerColumn.data.map((columnItem, idx) => {
                        return (
                          <div className='row' key={`columnItem-${idx}`}>
                            <div className='col-md-12 headerText'>
                              <AwesomeLabel
                                label={columnItem.label}
                                icon={columnItem.icon}
                                title={columnItem.title}
                                size={columnItem.duotone ? 'duotone' : null}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
                <div className='col-md-1 mr-1' style={{ margin: '20px' }}>
                  <button
                    type='button'
                    className='k-button k-primary mt-04'
                    onClick={handlePatientDocs}
                    style={{ minWidth: 125 }}
                  >
                    <i className='fa-regular fa-folder fa-sm icon-white'>
                      &nbsp;&nbsp;
                    </i>
                    Patient Docs
                  </button>
                  <br />
                  <button
                    type='button'
                    className='k-button k-primary mt-04'
                    onClick={togglePatientNotesDialog}
                    style={{ minWidth: 125 }}
                  >
                    <i className='fa-regular fa-pen-to-square fa-sm icon-white'>
                      &nbsp;&nbsp;
                    </i>
                    Patient Notes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <PatientDocsModal
        showDialog={showPatientDocsDialog}
        onClose={togglePatientDocsDialog}
        documentViewCallback={documentViewCallback}
      />

      <WindowDialog
        title='Patient Document'
        height={1200}
        width={850}
        initialTop={220}
        initialLeft={700}
        showDialog={showPatientDocDialog}
        onClose={toggleShowPatientDocDialog}
      >
        <div className='col-md-2 text-right mb-4'>
          <Button
            title='Pdf Download'
            onClick={() => {
              const link = document.createElement('a');
              link.href = selectedDocumentUrl;
              link.target = '_blank';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }}
          >
            Pdf Download
          </Button>
        </div>
        <PatientDocument file={selectedDocumentUrl} />
      </WindowDialog>

      <WindowDialog
        title='Patient Notes'
        style={{ backgroundColor: '#fff', minHeight: '300px' }}
        initialHeight={700}
        initialTop={150}
        initialLeft={270}
        width={1100}
        showDialog={showPatientNotesDialog}
        onClose={togglePatientNotesDialog}
      >
        <Notes
          selectedPatientInfo={{
            patientId: patientBucket.patientId,
            notes: patientBucket.notes
          }}
          sendDataToParent={() => {}}
        />
      </WindowDialog>

      {showInfusionUpdateProviderDialog && (
        <InfusionUpdateProviderDialog
          onClose={() => setShowInfusionUpdateProviderDialog(false)}
          onUpdateNursingProvider={updateProviderIdInNursingProcessCall}
          infusionDetails={infusionDetails}
        />
      )}
    </div>
  );
};

export default InfusionHeaderV2;
