import React, { useContext, useEffect, useState } from 'react';
import { Card, CardBody, CardHeader } from '@progress/kendo-react-layout';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';

// components
import Preloader from '@/components/common-components/Preloader';
import AwesomeLabel from '@/components/common-components/AwesomeLabel';
import WorkStatusBadge from '@/components/common-components/WorkStatusBadge';
import SnoozeMenu from '@/components/Queue/SnoozeMenu/SnoozeMenu';

// context
import { NotifContext, PatientContext, WorkItemContext } from '@/context';

// utils
import { formatDateTimeToDefault } from '@/common/DateHelper';
import { cardTypes } from '@/components/Header/components/ActiveWorkItems/components/PendingWorkItems.constants';
import {
  isWorkTypeSupported,
  isWorkTypeSupportedForSnooze,
  patientItemStatusesToExcludeForRetrieve
} from '@/common/Mappers';
import { getWorkItemTitle } from '@/components/Queue/FilterSystem/QueueFilters.utils';

const PatientWorkItems = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [currentSelRow, setCurrentSelRow] = useState({});
  const [visibleSnoozeDateDialog, setVisibleSnoozeDateDialog] = useState(false);
  const {
    patientWorkItemsOpened,
    addPendingWorkItem,
    refreshQueueData,
    updatePatientWorkStatus,
    getPatientWorkItemsCall
  } = useContext(WorkItemContext);
  const { showWarning } = useContext(NotifContext);
  const { selectedPatientInfo, setIsNewPatient, checkIsNewPatientCall } =
    useContext(PatientContext);

  useEffect(async () => {
    if (selectedPatientInfo?.patientId) {
      const data = await getPatientWorkItemsCall(selectedPatientInfo.patientId);

      setIsLoading(false);

      if (!!data?.data?.getWorkItemsByPatient?.length) {
        // check if at least one work item has new patient flag
        if (
          data.data.getWorkItemsByPatient.some(
            (item) => item.attachedData?.isNewPatient === true
          )
        ) {
          setIsNewPatient(true);
        }
      } else {
        // no work items found - but the patient still might be new
        const status = await checkIsNewPatientCall(
          selectedPatientInfo.patientId
        );
        setIsNewPatient(status);
      }
    }
  }, [selectedPatientInfo.patientId, refreshQueueData]);

  const handleAddWorkItemClick = async (dataItem) => {
    if (!isWorkTypeSupported(dataItem.work)) {
      showWarning('Work type not supported at this time');
      return null;
    }

    if (
      // add helper function for this when not under time crunch.
      dataItem.workStatus === 'OPENED' ||
      dataItem.workStatus === 'CREATED' ||
      dataItem.workStatus === 'PENDING' ||
      dataItem.workStatus === 'OVERDUE' ||
      dataItem.workStatus === 'SNOOZED' ||
      dataItem.workStatus === 'REASSIGNED'
    ) {
      const isAcquired = await addPendingWorkItem({
        ...dataItem,
        ...cardTypes[dataItem.work]
      });
      if (isAcquired) {
        updatePatientWorkStatus(dataItem.id, 'IN_PROGRESS');
      }

      return null;
    }
    showWarning('Work item already added to user');
  };

  const handleSnoozeItemClick = (dataObject) => {
    toggleSnoozeDateDialog();
    setCurrentSelRow(dataObject);
  };

  const toggleSnoozeDateDialog = () => {
    setVisibleSnoozeDateDialog(!visibleSnoozeDateDialog);
  };

  const createdAtCell = ({ dataItem }) => {
    return <td>{formatDateTimeToDefault(dataItem.createdAt)}</td>;
  };

  const snoozeCell = ({ dataItem }) => {
    const canUserSnooze = isWorkTypeSupportedForSnooze(dataItem);
    return (
      <td>
        {canUserSnooze && (
          <div className='row'>
            <div className='col-md-2'>
              <Button
                type='button'
                title='Edit Quantity'
                className='k-button clearButton'
                onClick={() => {
                  handleSnoozeItemClick(dataItem);
                }}
              >
                Snooze
              </Button>
            </div>
          </div>
        )}
      </td>
    );
  };

  const actionCell = ({ dataItem }) => {
    if (patientItemStatusesToExcludeForRetrieve.includes(dataItem.workStatus))
      return null;
    return (
      <td>
        <div className='row'>
          <div className='col-md-4'>
            <Button
              type='button'
              title='Retrieve Work Item'
              look='flat'
              icon='file-txt'
              onClick={() => {
                handleAddWorkItemClick(dataItem);
              }}
            >
              Retrieve Work
            </Button>
          </div>
        </div>
      </td>
    );
  };

  const statusCell = ({ dataItem }) => {
    return (
      <td>
        <WorkStatusBadge workStatus={dataItem.workStatus} />
      </td>
    );
  };
  const workTypeCell = ({ dataItem }) => {
    return <td>{getWorkItemTitle(dataItem?.work)}</td>;
  };

  return (
    <>
      <Card className='mb-3'>
        <CardHeader>
          <div className='row'>
            <div className='col-5'>
              <AwesomeLabel
                icon='clipboard icon-blue'
                label={' '}
                size='xl'
                title='PATIENT WORK ITEMS'
              />
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <div className='container-fluid'>
            <div className='row my-3'>
              <Preloader show={isLoading}>
                <div className='col-12 headerText notesWrapper mt-1'>
                  <Grid
                    data={patientWorkItemsOpened}
                    total={1} // total set to stop paging error in the browser. Remove if you want to use paging.
                  >
                    <Column field='groupQueueId' title='Queue Type' />
                    <Column
                      field='createdAt'
                      title='Created At'
                      cell={createdAtCell}
                    />
                    <Column field='description' title='Description' />
                    <Column
                      field='work'
                      title='Work Type'
                      cell={workTypeCell}
                    />
                    <Column
                      field='attachedData.followUpType'
                      title='Follow Up Type'
                    />
                    <Column
                      field='workStatus'
                      title='Status'
                      cell={statusCell}
                    />
                    <Column field='priority' title='Priority' />
                    <Column
                      field='retrieve'
                      title='Retrieve'
                      cell={actionCell}
                    />
                    <Column
                      field='snooze'
                      title='Snooze Work Item'
                      cell={snoozeCell}
                    />
                  </Grid>
                </div>
              </Preloader>
            </div>
          </div>
        </CardBody>
      </Card>
      {visibleSnoozeDateDialog && (
        <SnoozeMenu
          toggleSnoozeDateDialog={toggleSnoozeDateDialog}
          currentSelRow={currentSelRow}
        />
      )}
    </>
  );
};

export default PatientWorkItems;
