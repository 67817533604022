import React from 'react';

/* @NOTE: list of supported icons can be found here: https://fontawesome.com/icons */

const AwesomeLink = ({
  icon = '',
  label = '',
  onClick = null,
  customClassName = ''
}) => {
  return (
    <span className={`help-link ${customClassName}`} onClick={onClick}>
      <i className={`fa-solid fa-${icon} label-icon`} />
      {label}
    </span>
  );
};
export default AwesomeLink;
