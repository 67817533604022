import React from "react";
import { Loader } from "@progress/kendo-react-indicators";

// @NOTE: supported colors, types and animaions listed here:
// https://www.telerik.com/kendo-react-ui/components/indicators/loader/appearance/
const Preloader = ({
  show = true,
  size = "medium",
  label = "Loading...",
  color = "primary",
  type = "infinite-spinner",
  ...props
}) => {
  if (!show) {
    return props.children;
  }

  return (
    <div>
      <div className="wrap">
        <span className="k-d-inline-flex k-flex-col k-align-items-center">
          <span className="title">{label}</span>
          <Loader themeColor={color} type={type} size={size} />
        </span>
      </div>
      <style>
        {`.title {
          color: #6c757d;
          padding: 6px 15px;
        }
        .wrap {
          margin-top: 15px;
          text-align: center;
        }`}
      </style>
    </div>
  );
};
export default Preloader;
