// sorting an array of objects alphabetically using passed `fieldName`
export const sortByField = (a, b, fieldName) => {
  // Convert both strings to lowercase for case-insensitive comparison
  const fieldA = `${a[fieldName]}`.toLowerCase();
  const fieldB = `${b[fieldName]}`.toLowerCase();

  // You can customize the comparison logic based on the data type of the field
  // For simplicity, let's assume the field is a string in this example
  if (fieldA < fieldB) {
    return -1;
  }
  if (fieldA > fieldB) {
    return 1;
  }
  return 0; // Fields are equal
};

// compare dates in the following format: "03/25/2024 07:39 PM"
// which we usually use when displaying in grids (tables)
// in the resulting array the most recent date will be first
export const compareDates = (a, b, fieldName) => {
  // Convert date strings to Date objects
  const dateA = new Date(a[fieldName]);
  const dateB = new Date(b[fieldName]);

  // Compare the dates
  if (dateA > dateB) {
    return -1; // Return a negative value to sort in descending order (most recent first)
  } else if (dateA < dateB) {
    return 1; // Return a positive value to sort in ascending order (oldest first)
  } else {
    return 0; // Dates are equal
  }
};
