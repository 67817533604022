import React, { useContext, useEffect, useState } from 'react';

// kendo
import { GridColumn as Column } from '@progress/kendo-react-grid/dist/npm/GridColumn';
import { Grid } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';

// utils
import { formatDateToDefault } from '@/common/DateHelper';

// context
import { UserContext, PatientContext, NotifContext } from '@/context';

// gql
import { resendClaims } from '@/graphql/mutations';
import { connectToGraphqlAPI } from '@/provider';

const BillingGrid = () => {
  const { user } = useContext(UserContext);
  const { recentBillings } = useContext(PatientContext);
  const { showError, showSuccess } = useContext(NotifContext);
  const [statusCode, setStatusCode] = useState(null);

  const billingBtn = (patientBilling) => {
    return (
      <td>
        <Button
          look='flat'
          icon='reload-sm'
          onClick={() => {
            resubmit(patientBilling?.dataItem?.nursingProcessId);
          }}
        >
          Resubmit to R2
        </Button>
      </td>
    );
  };

  const dateCell = (patientBilling) => {
    return <td>{formatDateToDefault(patientBilling.dataItem.serviceDate)}</td>;
  };

  const resubmit = async (id) => {
    const arrayId = [];
    arrayId.push(id);

    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: resendClaims,
        variables: {
          agentId: user,
          npIds: arrayId
        }
      });
      if (data) {
        setStatusCode(data);
      }
    } catch (err) {
      console.error('BillingGrid::resubmit error: ', err);
      showError(err);
    }
  };

  useEffect(() => {
    if (statusCode !== null) {
      const code = statusCode?.data?.resendClaims?.statusCode;
      if (code === '200') {
        showSuccess('Submission to R2 was successfull');
      } else {
        showError('Submission to R2 was not accepted');
      }
    }
  }, [statusCode]);

  if (!recentBillings) return null;

  return (
    <>
      <div className='row' style={{ marginTop: '20px' }}>
        <div className='col-1' />
        <div className='col-10'>
          <div className='a-grid__header'>
            <div>BILLING SUBMISSION</div>
          </div>
          <Grid
            className='a-grid'
            style={{ height: 600 }}
            selectedField='selected'
            data={recentBillings}
          >
            <Column
              field='serviceDate'
              title='DATE OF SERVICE'
              sortable={false}
              cell={dateCell}
            />
            <Column field='locationName' title='LOCATION' sortable={false} />
            <Column field='orderName' title='ORDER NAME' sortable={false} />
            <Column field='r2Status' title='R2 STATUS' sortable={false} />
            <Column field='resubmit' title='Resubmit' cell={billingBtn} />
          </Grid>
        </div>
      </div>
    </>
  );
};
export default BillingGrid;
