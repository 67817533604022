import React from 'react';
import styled from 'styled-components';

const RowValue = styled.span`
  padding-left: 3px;
`;

const RowField = ({ label = '', value = '', capitalize = false }) => {
  return (
    <p className={capitalize ? 'k-text-capitalize' : ''}>
      <b>{label}:</b>
      <RowValue>{value}</RowValue>
    </p>
  );
};

export default RowField;

RowValue.displayName = 'RowValue';
