import React, { createContext, useState } from 'react';

export const StatusContext = createContext();
export const StatusConsumer = StatusContext.Consumer;

const StatusContextProvider = ({ children }) => {
  const [statusUpdate, setStatusUpdate] = useState('');
  const [noteRefresh, setNoteRefresh] = useState('');
  const [appointmentListRefresh, setAppointmentListRefresh] = useState('');

  return (
    <StatusContext.Provider
      value={{
        statusUpdate,
        setStatusUpdate,
        setNoteRefresh,
        noteRefresh,
        setAppointmentListRefresh,
        appointmentListRefresh
      }}
    >
      {children}
    </StatusContext.Provider>
  );
};

export default StatusContextProvider;
