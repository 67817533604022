// W2W API fetch data and match location names
export const W2wData = async (locationName, date) => {
  const spacesRemoved = locationName.replace(/\s/g, '');
  let location;
  // switch handles all special location names
  switch (spacesRemoved) {
    case 'Ahwatukee2':
      location = 'Ahwatukee';
      break;
    case 'Berkeley2':
      location = 'Berkeley';
      break;
    case 'ColoradoSprings2':
      location = 'ColoradoSprings';
      break;
    case 'Flagstaff3':
      location = 'Flagstaff';
      break;
    case 'LasCruces2':
      location = 'LasCruces';
      break;
    case 'Main/Central':
      location = 'Central';
      break;
    case 'Peoria2':
      location = 'Peoria(II)';
      break;
    case 'PrescottValley2':
      location = 'PrescottValley';
      break;
    case 'ShermanOaks2':
      location = 'ShermanOaks';
      break;
    case 'Tucson':
      location = 'TucsonSE';
      break;
    case 'Tucson3':
      location = 'TucsonNW';
      break;
    default:
      location = spacesRemoved;
  }
  // fetch data from W2W API
  return fetch(
    `https://www5.whentowork.com/cgi-bin/w2wE.dll/api/AssignedShiftList?start_date=${date}&end_date=${date}&key=E01AA88E8-2451758EDB204171A9ECD9DC285DBAC6`
  )
    .then((response) => response.json())
    .then((data) => {
      const arrayData = data?.AssignedShiftList;
      const locationList = arrayData?.map((item) => {
        let position = item.POSITION_NAME;
        const n = 5;
        position = position.substring(n);
        const city = position.split(' ', 2)[0];
        const state = position.split(' ', 2)[1];
        const full = state ? `${city}${state}` : `${city}`;
        const firstLetterFirst = item.FIRST_NAME.charAt(0);
        const firstLetterLast = item.LAST_NAME.charAt(0);
        item.avatar = `${firstLetterFirst}${firstLetterLast}`;
        item.name = `${item.FIRST_NAME} ${item.LAST_NAME}`;
        item.location = full;
        item.time = `${item.START_TIME} - ${item.END_TIME}`;
        return item;
      });
      // conditions for filtering data
      const conditions = {
        ...(location && { location })
      };
      // filter data
      let results = [];
      if (locationList) {
        results = locationList?.filter((item) => {
          return Object.keys(conditions).every((key) => {
            return conditions[key] === item[key];
          });
        });
      }
      return results;
    })
    .catch((err) => {
      // helper file does not use context so log rocket is not avaliable
      console.error(err);
    });
};
