import React from 'react';

// components
import AwesomeLabel from '@/components/common-components/AwesomeLabel';

const DrawerHeaderItem = ({ icon, label, value }) => {
  return (
    <div>
      <small>
        <AwesomeLabel
          icon={icon}
          label={
            <>
              <b>{label}: </b>
              {value}
            </>
          }
        />
      </small>
    </div>
  );
};

export default DrawerHeaderItem;
