import React, { createContext, useState } from 'react';

export const TreatmentContext = createContext();

const TreatmentContextProvider = ({ children }) => {
  const [treatmentSnapshot, setTreatmentSnapshot] = useState();
  const [snapshotInsurance, setSnapshotInsurance] = useState(); // shorthand for primary insurance
  const [snapshotInfusion, setSnapshotInfusion] = useState(); // shorthand for infusion details

  const updateTreatmentSnapshot = (newSnapshot) => {
    if (!!newSnapshot) {
      // Update the treatmentSnapshot as-is
      setTreatmentSnapshot(newSnapshot);

      // Separate things like billing, insurance, etc. into their own states
      setSnapshotInsurance(newSnapshot.billingDetail?.insuranceInfo?.primary);
      setSnapshotInfusion(newSnapshot.treatmentHistory);
    } else {
      // Reset all states
      setTreatmentSnapshot();
      setSnapshotInsurance();
      setSnapshotInfusion();
    }
  };

  return (
    <TreatmentContext.Provider
      value={{
        treatmentSnapshot,
        setTreatmentSnapshot,
        updateTreatmentSnapshot,
        snapshotInsurance,
        snapshotInfusion
      }}
    >
      {children}
    </TreatmentContext.Provider>
  );
};

export default TreatmentContextProvider;
