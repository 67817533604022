import React, { createContext, useState, useEffect, useContext } from 'react';

// gql
import { getQueryClient } from '@/provider';
import { getPrescribers } from '@/graphql/queries';

// util
import { mapAndFilterPrescribers } from './PrescriberContextHelper';

// context
import { NotifContext } from './NotifContext';
import { LogContext } from './LogContext';

export const PrescriberContext = createContext();

const PrescriberContextProvider = ({ children, user }) => {
  const { showError } = useContext(NotifContext);
  const { logApiException } = useContext(LogContext);
  const [prescribers, setPrescribers] = useState([]); // Show raw data
  const [listPrescribersDataFiltered, setListPrescribersDataFiltered] =
    useState([]); // Shows data filtered and sorted by name

  const [listPrescriberOfficeAddresses, setListPrescriberOfficeAddresses] =
    useState([]);
  const [listCardPrescriberAddresses, setListCardPrescriberAddresses] =
    useState([]);
  const [listPatientHcpProfilesData, setListPatientHcpProfilesData] = useState([]);

  // TODO: need to be fixed, there's an error when at leats one of the records has empty addresses (null)
  const getPrescriberListCall = async () => {
    try {
      const data = await getQueryClient({
        query: getPrescribers,
        payload: { limit: 1000 },
        path: 'listPrescriberInfos'
      });

      const unique = mapAndFilterPrescribers(data);

      setPrescribers(data); // raw data
      setListPrescribersDataFiltered(unique); // filtered and sorted data
    } catch (err) {
      console.error('PrescriberContext::getPrescribers err:', err);

      // in some rare cases we still can get data back
      if (err.data) {
        // and in this case we must return it no matter of the error from server
        const unique = mapAndFilterPrescribers(err.data);

        setPrescribers(err.data); // raw data
        setListPrescribersDataFiltered(unique); // filtered and sorted data
      } else {
        // just error and no data - is a bad sign, need to show alert to the user
        showError('Error: Failed to get prescribers data.');
      }

      // but we still log the error though
      logApiException(err, {
        view: 'PrescriberContext',
        endpoint: 'getPrescribers'
      });
    }
  };

  useEffect(() => {
    // action fires only if user logged in
    if (user) {
      getPrescriberListCall();
    }
  }, [user]);

  //   const getPrescriberById = (id) => {
  //     const prescriber = prescribers.find((item) => item.NPINumber === id);

  //     if (prescriber) {
  //       return prescriber;
  //     }

  //     return null;
  //   };

  return (
    <PrescriberContext.Provider
      value={{
        prescribers,
        setPrescribers,
        // getPrescriberById,   Need to test this
        listPrescribersDataFiltered,
        setListPrescribersDataFiltered,
        listPrescriberOfficeAddresses,
        setListPrescriberOfficeAddresses,
        listCardPrescriberAddresses,
        setListCardPrescriberAddresses,
        listPatientHcpProfilesData,
        setListPatientHcpProfilesData
      }}
    >
      {children}
    </PrescriberContext.Provider>
  );
};

export default PrescriberContextProvider;
