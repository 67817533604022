import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
//Kendo
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { orderBy } from '@progress/kendo-data-query';
import { Button } from '@progress/kendo-react-buttons';
// component
import { ColumnMenu } from '../common-components/columnMenu';
// util
import { getDayColumnBackGroundColor } from './dashboardHelper';
import { formatDateTimeToDefault } from '@/common/DateHelper';
import { getWorkItemTitle } from '../Queue/FilterSystem/QueueFilters.utils';
import { toProperCase } from '@/common/Converters';
// context
import { PatientContext, NotifContext } from '@/context';

const DashboardDetails = (props) => {
  const { getPatientBucketCall } = useContext(PatientContext);
  const { showError } = useContext(NotifContext);
  const history = useHistory();
  const initialSort = [
    {
      field: 'days',
      dir: 'dsc'
    }
  ];
  const [sort, setSort] = React.useState(initialSort);
  const itemData = props?.dataItem?.workItems?.fullData;

  const handlePatientDetailsClick = async (dataItem) => {
    try {
      await getPatientBucketCall(dataItem.patientId);
      history.push('/patient-portal');
    } catch (err) {
      showError(err);
    }
  };

  const prescriberNameCell = ({ dataItem }) => {
    if (!dataItem.prescriberLastName) return <td />; // Added to prevent grid from displaying NULL
    const prescriberLastName = toProperCase(dataItem?.prescriberLastName);
    const prescriberFirstName = toProperCase(dataItem?.prescriberFirstName);
    return (
      <td>
        <span>{`${prescriberLastName}, ${prescriberFirstName}`}</span>
      </td>
    );
  };

  const createdDateCell = ({ dataItem }) => {
    return <td>{formatDateTimeToDefault(dataItem?.createdAt)}</td>;
  };
  const targetDateCell = ({ dataItem }) => {
    return <td>{formatDateTimeToDefault(dataItem?.targetTime)}</td>;
  };
  //   const workCell = ({ dataItem }) => {
  //     return <td>{getWorkItemTitle(dataItem?.work)}</td>;
  //   };
  const daysCell = ({ dataItem }) => {
    return getDayColumnBackGroundColor(dataItem?.days);
  };
  const assignedToCell = ({ dataItem }) => {
    return <td>{dataItem?.assignedTo}</td>;
  };
  const infoCell = ({ dataItem }) => {
    return (
      <td>
        <div className='row'>
          <div className='col-md-4'>
            <Button
              look='flat'
              icon='file-txt'
              type='button'
              title='Patient Details'
              onClick={() => handlePatientDetailsClick(dataItem)}
            >
              Patient Details
            </Button>
          </div>
        </div>
      </td>
    );
  };

  const patientNameCell = ({ dataItem }) => {
    return (
      <td>{`${dataItem.patientLastName}, ${dataItem.patientFirstName}`}</td>
    );
  };

  const getItemColumns = () => {
    //These columns are releated to the final level of grids with the work item display
    const cols = [
      { field: 'createdAt', title: 'Created Date', cell: createdDateCell },
      { field: 'days', title: 'Days Old', cell: daysCell },
      {
        field: 'patientLastName',
        title: 'Patient',
        cell: patientNameCell
      },
      { field: 'patientId', title: 'Patient ID' },
      {
        field: 'prescriberLastName',
        title: 'Prescriber',
        cell: prescriberNameCell
      },
      { field: 'drugName', title: 'Drug' },
      /* { field: 'work', title: 'Work Item', cell: workCell }, */
      { field: 'assignedTo', title: 'Assigned To', cell: assignedToCell },
      { field: 'description', title: 'Description' },
      { field: 'attachedData.followUpType', title: 'Follow Up Type' },
      { field: 'targetTime', title: 'Due Date', cell: targetDateCell },
      { field: 'select', title: 'Info', cell: infoCell }
    ];
    return cols;
  };

  const columns = getItemColumns();

  return (
    <Grid
      data={orderBy(itemData, sort)}
      sortable
      sort={sort}
      onSortChange={(e) => {
        setSort(e.sort);
      }}
      total={1} // total set to stop paging error in the browser. Remove if you want to use paging.
    >
      {columns.map(({ minWidth, ...rest }, idx) => (
        <Column key={idx} width={minWidth} columnMenu={ColumnMenu} {...rest} />
      ))}
    </Grid>
  );
};
export { DashboardDetails };
