import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import * as moment from 'moment';

// Kendo Components
import { Input, Switch } from '@progress/kendo-react-inputs';
import { TimePicker } from '@progress/kendo-react-dateinputs';
import { Error } from '@progress/kendo-react-labels';
import { Form, Field } from '@progress/kendo-react-form';
import { Dialog } from '@progress/kendo-react-dialogs';

// Common Components
import Alert from '@/components/common-components/Alert';

// Partials
import RevisitCloseTreatment from '../Infusion/Revisit/RevisitCloseTreatment';
import PatientSurveyActions from './PatientSurveyActions';

// Utils
import { validateInput } from '@/common/Validation';
import { authUser } from '@/common/aws-cognito';
import {
  adjustUTC,
  formatDateToAWSDateTime,
  formatTimeToDefault
} from '@/common/DateHelper';
import { Constants } from '@/constants';
import { getSameHoursAndMinsForTimezone } from '../Scheduling/getUTCTimeString';

// Context
import {
  NotifContext,
  InfusionContext,
  UserContext,
  PatientContext
} from '@/context';

// GraphQL
import { connectToGraphqlAPI } from '@/provider';
import { updateStepCloseTreatment } from '@/graphql/mutations';

const CloseTreatmentV2 = (props) => {
  const history = useHistory();
  const { agent } = useContext(UserContext);
  const { showError, showWarning } = useContext(NotifContext);
  const { infusion } = useContext(InfusionContext);
  const { setSelectedPatientInfo } = useContext(PatientContext);
  const { nursingProcess, location } = infusion;
  const nursingProcessId = nursingProcess?.id;

  const [apiVitals, setApiVitals] = useState([]);
  const [stepAllowed, setStepAllowed] = useState(false);
  const [showCloseTreatmentDialog, setShowCloseTreatmentDialog] =
    useState(false);
  const [allStepsCompleted, setAllStepsCompleted] = useState(false);
  const [pendingSteps, setPendingSteps] = useState([]);
  const [hasNarrativeNoteComplete, setNarrativeNoteComplete] = useState(false);
  const [narrativeNoteTouched, setNarrativeNoteTouched] = useState(false);
  const [departureTime, setDepartureTime] = useState(null);
  const [departureTimeTouched, setDepartureTimeTouched] = useState(false);

  const toggleNarrativeCompletion = useCallback(
    (e) => {
      if (!narrativeNoteTouched) {
        setNarrativeNoteTouched(true);
      }

      setNarrativeNoteComplete(e.value);
    },
    [setNarrativeNoteComplete]
  );

  const onDepartureTimeUpdate = useCallback(
    (e) => {
      if (!departureTimeTouched) {
        setDepartureTimeTouched(true);
      }

      setDepartureTime(e.value);
    },
    [setNarrativeNoteTouched]
  );

  const getOriginaDepTime = () => {
    if (props.infusionCompleted) {
      return nursingProcess.id && location
        ? formatTimeToDefault(
            adjustUTC(
              nursingProcess.stepCloseTreatment.departureTime,
              location.timeZone
            )
          )
        : '';
    }

    return '';
  };

  const originalDepartureTime = getOriginaDepTime();

  const addPendingStep = (newStep) =>
    setPendingSteps((curSteps) =>
      [...curSteps, newStep].filter((v, i, a) => a.indexOf(v) === i)
    );

  useEffect(() => {
    handleLoadInfusion();
  }, [nursingProcess]);

  const updateStepCloseTreatmentCall = async (input) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: updateStepCloseTreatment,
        variables: { input }
      });

      if (data && data.data && data.data.updateStepCloseTreatment) {
        if (data.data.updateStepCloseTreatment.statusCode) {
          if (data.data.updateStepCloseTreatment.statusCode === '200') {
            setTimeout(() => {
              setSelectedPatientInfo(infusion.patientBucket);
              history.push('/infusion-portal', {
                searchType: 'treatmentHistory',
                generateFax: nursingProcess.faxRequired, // need for Faxing
                treatmentId: nursingProcessId // need for Faxing
              });
            }, 500);
          } else if (data.data.updateStepCloseTreatment.statusCode !== '200') {
            showError(data?.data?.updateStepCloseTreatment?.message);
          }
        }
      }
    } catch (err) {
      console.error(
        'CloseTreatmentV2::updateStepCloseTreatmentCall err: ',
        err
      );
    }
  };

  const handleLoadInfusion = () => {
    if (nursingProcess && nursingProcess.stepReview) {
      if (nursingProcess.stepReview.orderApproved) {
        setStepAllowed(true);
      }
    }

    if (
      nursingProcess &&
      nursingProcess.stepReview &&
      nursingProcess.stepReview.orderApproved &&
      nursingProcess.stepAssessment &&
      nursingProcess.stepPreTreatment &&
      nursingProcess.stepPreTreatment.preTreatmentCompleted &&
      nursingProcess.stepPreparation &&
      nursingProcess.stepPreparation.preparationComplete &&
      nursingProcess.stepAdministration &&
      nursingProcess.stepAdministration.administrationComplete
    ) {
      setAllStepsCompleted(true);
    }

    if (nursingProcess && nursingProcess.id) {
      if (
        !nursingProcess.stepReview ||
        !nursingProcess.stepReview.orderApproved
      ) {
        addPendingStep('Order Review');
      }

      if (!nursingProcess.stepAssessment) {
        addPendingStep('Assessment');
      }

      if (
        !nursingProcess.stepPreTreatment ||
        !nursingProcess.stepPreTreatment.preTreatmentCompleted
      ) {
        addPendingStep('Pre-Treatment');
      }

      if (
        !nursingProcess.stepPreparation ||
        !nursingProcess.stepPreparation.preparationComplete
      ) {
        addPendingStep('Preparation');
      }

      if (
        !nursingProcess.stepAdministration ||
        !nursingProcess.stepAdministration.administrationComplete
      ) {
        addPendingStep('Administration');
      }
    }

    if (
      nursingProcess &&
      nursingProcess.stepCloseTreatment &&
      nursingProcess.stepCloseTreatment.departureVital
    ) {
      try {
        const a = [];
        nursingProcess.stepCloseTreatment.departureVital.map((item) => {
          const apiVital = {
            recordNumber: item.recordNumber,
            enteredAt: moment(new Date(item.enteredAt)),
            temperature: item.temperature,
            bloodPressure: item.bloodPressure,
            heartRate: item.heartRate,
            R: item.R,
            SP02: item.SP02,
            initials: item.initials
          };
          a.push(apiVital);
        });
        setApiVitals([...a]);
      } catch (err) {
        console.error('CloseTreatmentV2::nursingProcess vitals err: ', err);
        showError('Error: nursingProcess vitals mapping');
      }
    }
  };

  const infusionForm = {
    departureTime: {
      value: null,
      inputValidator: (value) => {
        return validateInput({
          departureTime: { ...infusionForm.departureTime, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    },
    isNarrativeNoteComplete: {
      value: hasNarrativeNoteComplete,
      inputValidator: (value) => {
        return validateInput({
          isNarrativeNoteComplete: {
            ...infusionForm.isNarrativeNoteComplete,
            value
          }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    }
  };

  const toggleCloseTreatment = () => {
    if (!departureTime) {
      showWarning(
        "Please set the 'Departure Time' in order to close the treatment"
      );
    } else if (!hasNarrativeNoteComplete) {
      showWarning(
        "Please set the 'Narrative Note Complete' in order to close the treatment"
      );
    } else if (infusion.isSurveyRequired && !infusion.isSurveySkipped) {
      if (!infusion.isSurveyComplete) {
        showWarning(
          'Patient survey must be completed in order to close the treatment'
        );
      } else if (!infusion.isSurveyReviewed) {
        showWarning('Survey must be reviewed prior to closing treatment');
      } else {
        setShowCloseTreatmentDialog(!showCloseTreatmentDialog);
      }
    } else {
      setShowCloseTreatmentDialog(!showCloseTreatmentDialog);
    }
  };

  const updatStepHandler = (dataItem) => {
    const requestObject = {
      nursingProcessId,
      agentId: agent.agentId,
      departureVital: apiVitals,
      departureTime: formatDateToAWSDateTime(
        getSameHoursAndMinsForTimezone(
          dataItem.departureTime,
          location?.timeZone
        )
      ),
      // AIC-3084: need to display in TH the person who actually closed the treatment, not started it
      signature: agent.agentId
    };

    updateStepCloseTreatmentCall(requestObject);
    toggleCloseTreatment();
  };

  const handleSubmit = (dataItem) => {
    const cbSuccess = () => {
      return updatStepHandler(dataItem);
    };

    const cbFailure = (errMessage) => showError(errMessage);

    authUser(dataItem.signature, dataItem.password, cbSuccess, cbFailure);
  };

  const handleRevisitSubmit = () => {
    const requestObject = {
      nursingProcessId,
      agentId: agent.agentId,
      departureVital: apiVitals,
      departureTime: departureTime
        ? formatDateToAWSDateTime(
            getSameHoursAndMinsForTimezone(
              departureTime.value,
              location?.timeZone
            )
          )
        : nursingProcess.stepCloseTreatment.departureTime,
      closeTreatmentNote: nursingProcess.stepCloseTreatment.closeTreatmentNote,
      signature: nursingProcess.stepCloseTreatment.signature,
      password: nursingProcess.stepCloseTreatment.password
    };

    updateStepCloseTreatmentCall(requestObject);
  };

  return (
    <div className='infusion-page'>
      {stepAllowed && (
        <>
          {props.infusionCompleted ? (
            <RevisitCloseTreatment
              updateStepHandler={handleRevisitSubmit}
              updateDepartureTimeHandler={setDepartureTime}
              originalTime={originalDepartureTime}
            />
          ) : (
            <Form
              onSubmit={handleSubmit}
              render={(formRenderProps) => (
                <form
                  onSubmit={formRenderProps.onSubmit}
                  className='k-form pl-3 pr-3 pt-1'
                >
                  <div className='row mt-3'>
                    <div className='col-md-2'>DEPARTURE TIME:</div>
                    <div className='col-md-2'>
                      <Field
                        component={TimePicker}
                        name='departureTime'
                        onChange={onDepartureTimeUpdate}
                        validator={infusionForm.departureTime.inputValidator}
                        format='h:mm a'
                      />
                      {!departureTime && departureTimeTouched && (
                        <Error>{Constants.ErrorMessage.is_REQUIRED}</Error>
                      )}
                    </div>
                  </div>
                  <div className='row mt-3'>
                    <div className='col-md-2'>Narrative Note Complete</div>
                    <div className='col-md-2'>
                      <Field
                        component={Switch}
                        onLabel='Yes'
                        offLabel='No'
                        name='isNarrativeNoteComplete'
                        onChange={toggleNarrativeCompletion}
                        defaultChecked={hasNarrativeNoteComplete}
                        validator={
                          infusionForm.isNarrativeNoteComplete.inputValidator
                        }
                      />
                      {!hasNarrativeNoteComplete && narrativeNoteTouched && (
                        <Error>{Constants.ErrorMessage.is_REQUIRED}</Error>
                      )}
                    </div>
                  </div>

                  <div className='row mt-5 mb-5'>
                    <div className='col-2'>
                      <button
                        type='button'
                        onClick={toggleCloseTreatment}
                        className='k-button pageButton'
                        disabled={!allStepsCompleted}
                      >
                        Close Treatment
                      </button>
                    </div>
                  </div>
                  {!allStepsCompleted && (
                    <div className='row'>
                      <div className='col-md-12'>
                        <Alert>
                          <>
                            Please make sure all steps in the infusion are
                            &quot;Complete&quot; before attempting to close
                            treatment.
                            <br />
                            <br />
                            Steps not completed:
                            <br />
                            <ul>
                              {pendingSteps.map((step, i) => (
                                <li key={i}>{step}</li>
                              ))}
                            </ul>
                          </>
                        </Alert>
                      </div>
                    </div>
                  )}
                  <PatientSurveyActions />
                  {showCloseTreatmentDialog && (
                    <Dialog
                      title='Close Treatment'
                      initialTop={0}
                      width={450}
                      height='auto'
                      showDialog
                      onClose={toggleCloseTreatment}
                    >
                      <>
                        <div className='row py-1'>
                          <div className='col-md-4 mt-16 ml-3'>USER NAME:</div>
                          <div className='col-md-7'>
                            <Field
                              component={Input}
                              name='signature'
                              label='User Name'
                            />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-md-4 mt-16 ml-3'>PASSWORD:</div>
                          <div className='col-md-7'>
                            <Field
                              component={Input}
                              name='password'
                              label='Password'
                              type='password'
                            />
                          </div>
                        </div>
                        <div className='row mt-3'>
                          <div className='col text-center'>
                            <Alert>
                              Reminder: Please add Close Treatment Notes
                            </Alert>
                          </div>
                        </div>
                        <div className='row'>
                          <div
                            className='col-md-12 mt-1 pl-0 py-3 mr-0'
                            style={{ textAlign: 'center' }}
                          >
                            <button
                              type='submit'
                              className='k-button pageButton'
                            >
                              Close Treatment
                            </button>
                          </div>
                        </div>
                      </>
                    </Dialog>
                  )}
                </form>
              )}
            />
          )}
        </>
      )}
    </div>
  );
};

export default CloseTreatmentV2;
