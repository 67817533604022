import React from 'react'
import { useContext } from 'react';
import { PatientContext } from '../../context/PatientContext';
import AwesomeLabel from './AwesomeLabel';


///Component can be used where ever there is a selected patient
const CurrentPatient = () => {
    const { selectedPatientInfo } = useContext(PatientContext);
  return (
    <>
        <span style={{ color: "#ff9900", fontSize: "16px" }}>
          <AwesomeLabel
                    size={"duotone"}
                    label={" "}
                    icon={"user"}
                    title={"Current Patient"}
                  />
        </span>{" "}
        {`${selectedPatientInfo?.patientFirstName} ${selectedPatientInfo?.patientLastName} (ID:${selectedPatientInfo?.patientId})`}
     </> 
  )
}

export default CurrentPatient