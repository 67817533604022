import React, { useState, useContext } from 'react';

import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';

import InsurerAdmin from '../Insurer/InsurerAdmin';
import PayerAdmin from '../Payer/PayerAdmin';
import ProductAdmin from '../Product/ProductAdmin';

import { UserContext } from '@/context/UserContext';
import BillingAdmin from './billing-admin/BillingAdmin';
import WorkItemsAdmin from '../Workitems/WorkItemsAdmin';
import Icd10Admin from './Icd10Codes-admin/Icd10Admin';
import ReleaseLock from './lock-admin/ReleaseLock';
import DataHealth from '../DataHealth';

const Administration = (props) => {
  const { user, agentId } = useContext(UserContext);

  // REDIRECT TO HOME IF NO USER EXISTS
  if (!user?.username) {
    props.history.push('/');
  }

  const { searchType = 'HCP' } = props.location.state || { searchType: null };

  // CHOOSE/NAVIGATE TO SUB-TAB
  let activeTab = 0;

  if (searchType === 'PATIENT') {
    activeTab = 3;
  } else if (searchType === 'HCP') {
    activeTab = 0;
  } else if (searchType === 'INSURER') {
    activeTab = 1;
  } else if (searchType === 'PAYER') {
    activeTab = 2;
  }

  const [selectedTab, setSelectedTab] = useState(activeTab);

  const handleSelect = (e) => {
    setSelectedTab(e.selected);
  };

  return (
    <div className='portal-container'>
      <div className='row' style={{ margin: '0' }}>
        <div className='col-12 main-content p-0'>
          <div className='container-fluid navBar1'>
            <TabStrip selected={selectedTab} onSelect={handleSelect}>
              {/* <TabStripTab contentClassName="navBar2" title="Patients">
								<div className="tabText">
									<PatientAdmin />
								</div>
							</TabStripTab> 
							<TabStripTab contentClassName="navBar2" title="Prescriber Profiles">
								<div className="tabText">
									<PrescriberAdmin />
								</div>
							</TabStripTab>
							*/}
              <TabStripTab contentClassName='navBar2' title='Billing'>
                <div className='tabText'>
                  <BillingAdmin />
                </div>
              </TabStripTab>
              <TabStripTab contentClassName='navBar2' title='Insurers'>
                <div className='tabText'>
                  <InsurerAdmin />
                </div>
              </TabStripTab>
              <TabStripTab contentClassName='navBar2' title='Payers'>
                <div className='tabText'>
                  <PayerAdmin />
                </div>
              </TabStripTab>
              <TabStripTab contentClassName='navBar2' title='Products'>
                <div className='tabText'>
                  <ProductAdmin />
                </div>
              </TabStripTab>
              <TabStripTab contentClassName='navBar2' title='Work Items'>
                <div className='tabText'>
                  <WorkItemsAdmin />
                </div>
              </TabStripTab>
              <TabStripTab contentClassName='navBar2' title='ICD-10 Codes'>
                <div className='tabText'>
                  <Icd10Admin />
                </div>
              </TabStripTab>
              <TabStripTab
                contentClassName='navBar2'
                title='Release Patient Lock'
              >
                <div className='tabText'>
                  <ReleaseLock agentId={agentId} />
                </div>
              </TabStripTab>
              <TabStripTab contentClassName='navBar2' title='Data Health Check'>
                <div className='tabText'>
                  <DataHealth />
                </div>
              </TabStripTab>
            </TabStrip>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Administration;
