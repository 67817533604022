import { taskStatus, taskStatusesMapped } from '@/constants/enum';

export const getBadgeType = (workStatus) => {
  // blue badge (INFO):
  if (workStatus === taskStatus.IN_PROGRESS) return 'info';

  // red badge (ALERT):
  if (
    [
      taskStatus.ARCHIVED,
      taskStatus.CANCELED,
      taskStatus.OVERDUE,
      taskStatus.ALERTED
    ].includes(workStatus)
  )
    return 'alert';

  // orange badge (WARNING):
  if ([taskStatus.PENDING, taskStatus.RESCHEDULED].includes(workStatus))
    return 'warning';

  // green badge (SUCCESS):
  if ([taskStatus.COMPLETED].includes(workStatus)) return 'success';

  // purple badge (SNOOZE):
  if ([taskStatus.SNOOZED, taskStatus.ON_HOLD].includes(workStatus))
    return 'snooze';

  // for all other types return gray badge (DEFAULT):
  return 'default';
};

// check if we have a mapping for the status type
export const hasMapping = (workStatus) =>
  Object.prototype.hasOwnProperty.call(taskStatusesMapped, workStatus);
