import React, { useContext } from 'react';

// context
import { PatientContext } from '@/context';

// utils
import { formatDateTimeToDefault } from '@optml/alera-share';

const TreatmentHistoryRevisions = ({ npid, handleModal }) => {
  const { findSnapshotsByNursingProcessId } = useContext(PatientContext);

  const revisions = findSnapshotsByNursingProcessId(npid);

  if (!revisions?.length) return null;

  revisions.sort(
    (a, b) =>
      new Date(b.treatmentHistory?.infusionDetail?.updatedAt) -
      new Date(a.treatmentHistory?.infusionDetail?.updatedAt)
  );

  return (
    <div>
      <small>
        <b>Historical updates:</b>
      </small>
      {revisions.map((revision) => {
        return (
          <div key={revision.id}>
            <small onClick={() => handleModal(revision)} className='help-link'>
              {formatDateTimeToDefault(
                revision.treatmentHistory?.infusionDetail?.updatedAt
              )}
            </small>
          </div>
        );
      })}
    </div>
  );
};

export default TreatmentHistoryRevisions;
