import React from 'react';

//Styles
import styled from 'styled-components';
import { GRAY } from '@/constants/colors';

/** Styled Components */
const IdStyles = styled.p`
  font-size: 9px;
  color: ${GRAY.text};
  margin-top: 10px;
  margin-bottom: 0;
`;

export const ReferralIdsDisplay = ({
  referralId,
  referralUUID,
  grid,
  therapyId,
  hideTherapyId
}) => {
  return (
    <>
      {!grid && (
        <div className='row'>
          <div className='col-md-1'>
            <IdStyles>Therapy ID: </IdStyles>
          </div>
          <div className='col-md-3'>
            <IdStyles>{therapyId}</IdStyles>
          </div>
          <div className='col-md-1'>
            <IdStyles>ID: </IdStyles>
          </div>
          <div className='col-md-3'>
            <IdStyles>{referralId}</IdStyles>
          </div>
          <div className='col-md-1'>
            <IdStyles>UUID: </IdStyles>
          </div>
          <div className='col-md-3'>
            <IdStyles>{referralUUID}</IdStyles>
          </div>
        </div>
      )}
      {grid && (
        <>
          <div className='row'>
            <div className='col'>
              <IdStyles>ID: {referralId}</IdStyles>
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <IdStyles>UUID: {referralUUID}</IdStyles>
            </div>
          </div>
          {!hideTherapyId && (
            <div className='row'>
              <div className='col'>
                <IdStyles>Therapy ID: {therapyId}</IdStyles>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
