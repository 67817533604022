import React, { useState, useEffect, useCallback } from 'react';
import { Auth } from 'aws-amplify';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import cx from 'clsx';

import { IDLE_LIMIT_TIME, COUNTDOWN_DURATION } from './IdleAutoLogoffModal.constants';
import styles from './IdleAutoLogoffModal.module.scss';

let idleTimer = null;
let countdownTimer = null;

const IdleAutoLogoffModal = ({ isLoggedIn = false }) => {
  const [showIdleDialog, setShowIdleDialog] = useState(false);
  const [countdown, setCountdown] = useState(COUNTDOWN_DURATION);

  const showSessionModal = () => {
    setShowIdleDialog(true);

    let remainingSeconds = COUNTDOWN_DURATION;
    countdownTimer = setInterval(async () => {
      remainingSeconds -= 1;
      if (remainingSeconds === 0) {
        // refresh timer and dialog
        clearInterval(countdownTimer);
        setShowIdleDialog(false);

        // logout
        await Auth.signOut()
          .then(() => window.location.replace('/'))
          .catch((err) => console.log(err));
      }
      setCountdown(remainingSeconds);
    }, 1000);
  };

  const onContinueSession = () => {
    resetTimer();
    clearInterval(countdownTimer);
    setShowIdleDialog(false);
    setCountdown(COUNTDOWN_DURATION);
  };

  const resetTimer = useCallback(() => {
    if (isLoggedIn) {
      clearTimeout(idleTimer);
      idleTimer = setTimeout(showSessionModal, IDLE_LIMIT_TIME);
    }
  }, [isLoggedIn, showSessionModal]);

  useEffect(() => {
    window.onload = resetTimer;
    window.onmousemove = resetTimer;
    window.onmousedown = resetTimer;
    window.ontouchstart = resetTimer;
    window.onclick = resetTimer;
    window.onkeypress = resetTimer;
    window.addEventListener('scroll', resetTimer, true);
    resetTimer();
  }, [isLoggedIn]);

  if (showIdleDialog) {
    return (
      <Dialog
        className={styles.idleLogoffDialog}
        title="Session Expiring"
        onClose={() => setShowIdleDialog(false)}
      >
        <p className={styles.sessionDesc}>
          Your current session is expiring, would you like to continue?
        </p>
        <Button
          className={cx('btn blue', styles.btnContinue)}
          onClick={onContinueSession}
        >
          Continue ({countdown})
        </Button>
      </Dialog>
    );
  }
  return null;
};

export { IdleAutoLogoffModal };
