import React, { useContext } from 'react';

// context
import { WorkItemContext } from '@/context';

// components
import AwesomeLabel from '@/components/common-components/AwesomeLabel';

const WorkItemId = () => {
  const { selectedWorkItem } = useContext(WorkItemContext);

  if (!selectedWorkItem) return null;

  return (
    <AwesomeLabel
      label={selectedWorkItem.id.substring(0, 8)}
      icon='hashtag'
      title='Work Item ID'
    />
  );
};

export default WorkItemId;
