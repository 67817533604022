import { workItemTypes } from '@/constants/enum';

const getFollowUpType = (formData) => {
  let returnText = formData.followUpCategory.text;
  if (
    formData.followUpCategory.value === workItemTypes.FOLLOW_UP ||
    formData.followUpCategory.value === workItemTypes.OUTBOUND_CALL
  ) {
    returnText = formData.assignContact.text; // Add the contact name as follow up type
  }
  return returnText;
};

const checkIfEndsWithParentheses = (str) => {
  if (str.endsWith(')')) {
    return true;
  }
};

export const generateAttachedFollowData = (formData) => {
  const res = {};
  if ('assignContact' in formData) {
    res.contactText = formData.assignContact?.text;
    res.contactValue = formData.assignContact?.value;
  }

  if ('followUpCategory' in formData) {
    res.followUpType = getFollowUpType(formData);
    res.followUpCategory = formData.followUpCategory?.value;
    const checkString = checkIfEndsWithParentheses(res.followUpType);
    if (!checkString) {
      res.followUpCategoryText = res.contactText
        ? `${res.followUpType}(${res.contactText})`
        : res.followUpType;
    } else {
      res.followUpCategoryText = res.followUpType;
    }

    return JSON.stringify(res);
  }
};
