import React, { useState, useContext, useEffect } from 'react';

// kendo
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';

// components
import OrderHistoryArchivedTab from './OrderHistoryArchivedTab';
import OrderHistoryDiscTab from './OrderHistoryDiscTab';
import Badge from '@/components/common-components/Badge';

// gql
import { connectToGraphqlAPI } from '@/provider';
import { getArchivedReferralOrders } from '@/graphql/queries';

// utils
import { formatDateToDefault } from '@/common/DateHelper';

// context
import { PatientContext } from '@/context';

const OrderHistory = () => {
  const { selectedPatientInfo } = useContext(PatientContext);
  const [selected, setSelected] = useState(0);
  const [archivedReferrals, setArchivedReferrals] = useState([]);
  const [onlyArchived, setOnlyArchived] = useState([]);
  const [onlyDiscontinued, setOnlyDiscontinued] = useState([]);

  const handleSelect = (e) => {
    setSelected(e.selected);
  };

  const getArchivedReferrals = async () => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getArchivedReferralOrders,
        variables: { patientId: selectedPatientInfo.patientId }
      });
      if (
        !!data?.data?.getPatientBucket?.referral?.archivedDrugReferrals?.length
      ) {
        setArchivedReferrals(
          data.data.getPatientBucket.referral.archivedDrugReferrals.map(
            (item) => ({
              ...item,
              text: item.orderName,
              value: item.orderName,
              orderName: item.orderName,
              dosage:
                item.archivedReferral.referralOrder?.administrations[0]
                  ?.approvedDosage,
              calculated:
                item.archivedReferral.referralOrder?.administrations[0]
                  ?.calcDosage,
              unitOfMeas:
                item.archivedReferral.referralOrder?.administrations[0]
                  ?.unitOfMeas,
              orderDate: formatDateToDefault(
                item.archivedReferral.referralOrder?.orderDate
              ),
              archivedDate: formatDateToDefault(item.archivedDate),
              expirationDate: formatDateToDefault(
                item.archivedReferral.referralOrder?.orderExpires
              ),
              agentId: item.agentId
            })
          )
        );
      }
    } catch (err) {
      console.error('OrderHistory::getArchivedReferrals err: ', err);
    }
  };

  useEffect(() => {
    getArchivedReferrals();
  }, []);

  useEffect(() => {
    // we used the following reasons in the old approach for discontinuation
    const legacyReasons = [
      'PATIENT_DISCONTINUED_TREATMENT',
      'DOCTOR_DISCONTINUED_THERAPY',
      'ORDER_DISCONTINUED'
    ];

    const onlyArchived = archivedReferrals.filter((referral) => {
      const isArchived = referral.archivedReferral?.archiveOrder === true;
      const hasLegacyOrderDiscontinued = legacyReasons.includes(
        referral.archivedReferral?.reasonForArchiving
      );

      return hasLegacyOrderDiscontinued ? false : isArchived;
    });

    const onlyDiscontinued = archivedReferrals.filter((referral) => {
      const isDiscontinued = referral.archivedReferral?.discontinued === true;
      const hasLegacyOrderDiscontinued = legacyReasons.includes(
        referral.archivedReferral?.reasonForArchiving
      );

      return hasLegacyOrderDiscontinued ? true : isDiscontinued;
    });

    setOnlyArchived(onlyArchived);
    setOnlyDiscontinued(onlyDiscontinued);
  }, [archivedReferrals]);

  const displayCounterTitle = (title, counter) => {
    if (counter < 1) return title;

    return (
      <>
        <span className='label-icon'>{title}</span>
        <Badge type='info' text={counter} />
      </>
    );
  };

  return (
    <div className='row'>
      <div className='col'>
        <div className={'k-form pl-3 pr-3 pt-1'}>
          <div className='row'>
            <div className='col-md-3 pageTitle'>Referral Order History</div>
          </div>
        </div>

        <div className='col-md-12 mt-3' id='tab-strip-gray'>
          <TabStrip
            selected={selected}
            onSelect={handleSelect}
            className='tsg-container'
          >
            <TabStripTab
              title={displayCounterTitle(
                'Archived Orders',
                onlyArchived.length
              )}
            >
              {onlyArchived.length && (
                <OrderHistoryArchivedTab orders={onlyArchived} />
              )}
            </TabStripTab>
            <TabStripTab
              title={displayCounterTitle(
                'Discontinued Orders',
                onlyDiscontinued.length
              )}
            >
              {onlyDiscontinued.length && (
                <OrderHistoryDiscTab orders={onlyDiscontinued} />
              )}
            </TabStripTab>
          </TabStrip>
        </div>
      </div>
    </div>
  );
};

export default OrderHistory;
