import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const usePath = () => {
  const [showAsInfusion, setShowAsInfusion] = useState(false);

  const history = useHistory();

  const { pathname } = history.location;

  const isInfusionPath = path => {
    return path === '/infusion-portal' || path.startsWith('/infusion/');
  };

  useEffect(() => {
    if (pathname) {
      setShowAsInfusion(isInfusionPath(pathname));
    }
  }, [pathname]);

  return { showAsInfusion };
};

export { usePath };
