import React from 'react';
import styled from 'styled-components';
import Badge from './Badge';

const Spacer = styled.span`
  display: inline-block;
  width: ${(props) => `${props.gap}px`};
`;

const BadgeInline = ({ inlineText = '', gap = 10, ...props }) => {
  return (
    <span>
      {inlineText}
      <Spacer gap={gap} />
      <Badge {...props} />
    </span>
  );
};

export default BadgeInline;
