import React, { useState, useContext, useEffect } from 'react';
import * as moment from 'moment';

// kendo
import { Form, Field } from '@progress/kendo-react-form';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { RadioGroup } from '@progress/kendo-react-inputs';

// components
import { FormRadioGroup } from '@/components/common-components/FormRadioGroup';
import WarningBlock from '@/components/common-components/WarningBlock';
import Badge from '@/components/common-components/Badge';
import FormButton from '@/components/common-components/Form/FormButton';

// utils
import {
  DatePickerField,
  TextAreaField,
  validateInput
} from '@/common/Validation';
import { formatDateToAWS, isDateFromPast } from '@/common/DateHelper';

// gql
import { connectToGraphqlAPI } from '@/provider';
import { getPatientReferralOrders } from '@/graphql/queries';
import { addUpdateNotes, updateReferralOrder } from '@/graphql/mutations';

// context
import {
  UserContext,
  PatientContext,
  StatusContext,
  NotifContext,
  UserRoleTypes
} from '@/context';

const Clinical = () => {
  const { user, canEdit, agent } = useContext(UserContext);
  const { selectedPatientInfo } = useContext(PatientContext);
  const { showError, showSuccess } = useContext(NotifContext);
  const { statusUpdate, setStatusUpdate, setNoteRefresh, noteRefresh } =
    useContext(StatusContext);

  const [listReferralOrdersData, setListReferralOrdersData] = useState([]);
  const [allReferralOrdersData, setAllReferralOrdersData] = useState([]);
  const [otherOrdersThatNeedSaving, setOtherOrdersThatNeedSaving] = useState(
    []
  );
  const [orderFormData, setOrderFormData] = useState({});
  const [isReferralApprovedClinical, setIsReferralApprovedClinical] =
    useState(false);
  const [isReferralDeniedClinical, setIsReferralDeniedClinical] =
    useState(false);
  const [isReferralPending, setIsReferralPending] = useState(false);
  const [buttonToggle, setButtonToggle] = useState(false);

  const [isLoading, setLoading] = useState(false);
  const [checkedAgain, setCheckedAgain] = useState(null);
  const [currentReferral, setCurrentReferral] = useState(null);
  const [currentNote, setCurrentNote] = useState('');
  const [selected, setSelected] = useState(0);

  const handleSelect = (e) => {
    const idx = Number(e.selected);
    const order = listReferralOrdersData[idx];
    setSelected(idx);
    handleLoadOrder(order);
  };

  const clinicalOrderStatuses = [
    { label: 'Approved', value: 'Approved', className: 'patient-radio blue' },
    { label: 'Pending', value: 'Pending', className: 'patient-radio blue' },
    { label: 'Denied', value: 'Denied', className: 'patient-radio blue' }
  ];

  const currentExpiration = new Date(
    formatDateToAWS(currentReferral?.[0]?.clinical?.expirationDateOfApproval)
      ? formatDateToAWS(
          currentReferral?.[0]?.clinical?.expirationDateOfApproval
        )
      : null
  );

  const getStatus = () => {
    if (orderFormData?.clinical?.orderApproved) return 'Approved';
    if (orderFormData?.clinical?.orderDenied) return 'Denied';
    if (orderFormData?.clinical?.orderPending) return 'Pending';

    return null;
  };

  const referralForm = {
    clinicalOrderStatus: {
      value: getStatus(),
      inputValidator: (value) => {
        return validateInput({
          clinicalOrderStatus: { ...referralForm.clinicalOrderStatus, value }
        });
      },
      validations: []
    },
    clinicalDeniedReason: {
      value: orderFormData.clinical?.reason || '',
      inputValidator: (value) => {
        return validateInput({
          clinicalDeniedReason: { ...referralForm.clinicalDeniedReason, value }
        });
      },
      validations: []
    },
    clinicalApprovalExpDate: {
      value: orderFormData.clinical?.expirationDateOfApproval
        ? new Date(
            moment(orderFormData.clinical.expirationDateOfApproval).add(
              new Date().getTimezoneOffset(),
              'minutes'
            )
          )
        : null,
      inputValidator: (value) => {
        return validateInput({
          clinicalApprovalExpDate: {
            ...referralForm.clinicalApprovalExpDate,
            value
          }
        });
      },
      validations: []
    },
    isReferralApproved: {
      value: orderFormData.referralApproved || false,
      inputValidator: (value) => {
        return validateInput({
          isReferralApproved: { ...referralForm.isReferralApproved, value }
        });
      },
      validations: []
    },
    isReferralArchived: {
      value: orderFormData.archiveOrder || false,
      inputValidator: (value) => {
        return validateInput({
          isReferralArchived: { ...referralForm.isReferralArchived, value }
        });
      },
      validations: []
    }
  };

  const initialForm = () => {
    const initialObject = {};

    Object.keys(referralForm).forEach((key) => {
      initialObject[key] = referralForm[key].value;
    });
    return initialObject;
  };

  const handleChange = (e) => {
    handleClinicalOrderStatus(e.value);
    setButtonToggle(true);
  };

  const handleButtonState = () => {
    setButtonToggle(true);
  };
  const handleNoteState = (dataItem) => {
    setButtonToggle(true);
    setCurrentNote(dataItem.value);
  };
  const handleClinicalOrderStatus = (status) => {
    setIsReferralApprovedClinical(status === 'Approved');
    setIsReferralDeniedClinical(status === 'Denied');
    setIsReferralPending(status === 'Pending');

    setCheckedAgain(status);
  };

  // MAIN INITIATOR
  useEffect(() => {
    getPatientReferralOrdersCall(selectedPatientInfo.patientId);
  }, []);

  const getPatientReferralOrdersCall = async (requestObject) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getPatientReferralOrders,
        variables: { patientId: requestObject } // patientId
      });

      if (
        data &&
        data.data &&
        data.data.getPatientBucket &&
        data.data.getPatientBucket.referral &&
        data.data.getPatientBucket.referral.drugReferrals &&
        data.data.getPatientBucket.referral.drugReferrals.length
      ) {
        setAllReferralOrdersData(
          data.data.getPatientBucket.referral.drugReferrals
        );

        const referrals = data.data.getPatientBucket.referral.drugReferrals
          .map((item) => ({
            ...item,
            text: item.referralOrder.orderName,
            value: item.referralOrder.orderName
          }))
          .sort((a, b) => (b.drugName < a.drugName ? 1 : -1));
        setListReferralOrdersData(referrals);

        // populate first order by default
        const defaultReferral = referrals[selected];
        handleLoadOrder(defaultReferral);
      }
    } catch (err) {
      console.log('marty getPatientReferralOrders data err', err);
      showError('Referral: Clinical -- Error: getPatientReferralOrders');
    }
  };

  useEffect(() => {
    // OOTNS -- OTHER ORDERS THAT NEED SAVING
    const ootns = allReferralOrdersData.filter(
      (referral) => referral.referralId != orderFormData.referralId
    );
    const currentReferral = allReferralOrdersData.filter(
      (referral) => referral.referralId === orderFormData.referralId
    );

    setOtherOrdersThatNeedSaving([...ootns]);
    setCurrentReferral(currentReferral);
    if (currentReferral[0]?.clinical?.orderApproved === true) {
      setIsReferralApprovedClinical(true);
    }
  }, [orderFormData]);

  const handleLoadOrder = (dataObject) => {
    setIsReferralApprovedClinical(
      dataObject?.clinical?.orderApproved === 'Approved'
    );
    setIsReferralPending(dataObject?.clinical?.orderPending === 'Pending');
    setIsReferralDeniedClinical(dataObject?.clinical?.orderDenied === 'Denied');

    setOrderFormData(dataObject);
    setCurrentNote(dataObject?.clinical?.clinicalNotes);
  };

  const addUpdateNotesCall = async (requestObject) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: addUpdateNotes,
        variables: { input: requestObject }
      });
      if (
        data &&
        data.data &&
        data.data.addUpdateNotes &&
        data.data.addUpdateNotes.notes
      ) {
        setNoteRefresh(!noteRefresh);
      }
    } catch (err) {
      console.log('Clinical::addUpdateNotesCall err: ', err);
    }
  };

  const handleSubmitOrder = (dataItem) => {
    const checkDate = isDateFromPast(dataItem?.values?.clinicalApprovalExpDate);
    if (checkDate && isReferralApprovedClinical) {
      showError('Expiration date must be in the future');
    } else {
      setButtonToggle(false);
      if (!dataItem || !user) {
        return;
      }

      const requestObject = {
        agentId: user.username,
        patientId: selectedPatientInfo.patientId,
        referral: {
          drugReferrals: [
            ...otherOrdersThatNeedSaving,
            {
              referralType: orderFormData.referralType,
              referralUUID: orderFormData.referralUUID,
              referralId: orderFormData.referralId,
              originalReceivedDate: orderFormData?.originalReceivedDate,
              rxOrderFile: orderFormData.rxOrderFile,
              drugId: orderFormData.drugId,
              drugName: orderFormData.drugName,
              isCompleted: !!orderFormData.referralApproved,
              prescriberId: orderFormData.prescriberId,
              prescriberOfficeAddressId:
                orderFormData.prescriberOfficeAddressId || 0,
              drugType: orderFormData.drugType,
              patientHasStartedTherapy: orderFormData.patientHasStartedTherapy,
              noOfTreatments: orderFormData.noOfTreatments,
              firstTreatmentDate: orderFormData.firstTreatmentDate,
              lastTreatmentDate: orderFormData.lastTreatmentDate,
              inventorySource: orderFormData.inventorySource,
              specialPharmName: orderFormData.specialPharmName,
              specialPharmPhoneNumber: orderFormData.specialPharmPhoneNumber,
              referralApproved: !!orderFormData.referralApproved,
              scheduling: orderFormData.scheduling,
              archiveOrder: !!orderFormData.archiveOrder,
              reasonForArchiving: orderFormData.reasonForArchiving,
              orderNotes: orderFormData.orderNotes,
              clinical: {
                orderApproved: isReferralApprovedClinical,
                orderDenied: isReferralDeniedClinical,
                orderPending: isReferralPending,
                reason: dataItem.clinicalDeniedReason,
                expirationDateOfApproval: dataItem?.values
                  ?.clinicalApprovalExpDate
                  ? formatDateToAWS(dataItem?.values?.clinicalApprovalExpDate)
                  : null,
                clinicalNotes: dataItem?.values?.clinicalNotes
                  ? dataItem?.values?.clinicalNotes
                  : currentNote
              },
              adverseEvent: orderFormData.adverseEvent,
              discontinuation: orderFormData.discontinuation,
              referralOrder: orderFormData.referralOrder
            }
          ]
        }
      };
      const noteObject = {
        patientId: selectedPatientInfo.patientId,
        agentId: agent.agentId,
        notes: [
          {
            date: moment(new Date()),
            note: checkedAgain
              ? `Notes: ${currentNote} Status: ${checkedAgain}`
              : `Notes: ${dataItem?.values?.clinicalNotes} Status: ${referralForm.clinicalOrderStatus.value}`,
            type: 'CLINICAL'
          }
        ]
      };

      updateReferralOrderCall(requestObject);
      addUpdateNotesCall(noteObject);
    }
  };

  const updateReferralOrderCall = async (requestObject) => {
    try {
      setLoading(true);
      const data = await connectToGraphqlAPI({
        graphqlQuery: updateReferralOrder,
        variables: { input: requestObject }
      });

      if (data && data.data && data.data.updateReferral) {
        showSuccess('Referral updated sucessfully');
        setLoading(false);
        setStatusUpdate(!statusUpdate);

        // refresh the ReferralOrders 11.08.21
        getPatientReferralOrdersCall(selectedPatientInfo.patientId);
      }
    } catch (err) {
      console.log('Clinical::updateReferralOrderCall err: ', err);
      showError('Referral: Clinical -- Error: updateReferralOrderCall');
      setLoading(false);
    }
  };

  const initValues = initialForm();

  const renderReferral = () => {
    return (
      <Form
        onSubmitClick={handleSubmitOrder}
        initialValues={initValues}
        key={JSON.stringify(initValues)}
        render={(formRenderProps) => (
          <form
            onSubmit={formRenderProps.onSubmit}
            className='k-form pl-3 pr-3 pt-1'
          >
            <article>
              <div className='row'>
                <div className='col-md-2 mt-08'>ORDER:</div>
                <div className='col-md-8 mt-08'>
                  <RadioGroup
                    name='clinicalOrderStatus'
                    data={clinicalOrderStatuses}
                    layout='horizontal'
                    component={FormRadioGroup}
                    onChange={handleChange}
                    defaultValue={referralForm.clinicalOrderStatus.value}
                  />
                </div>
              </div>
              <div className='row mt-12'>
                {isReferralApprovedClinical && (
                  <div className='col-md-3 offset-2'>
                    Expiration Date for Approval:
                    <br />
                    <Field
                      name='clinicalApprovalExpDate'
                      label=''
                      component={DatePickerField}
                      defaultValue={currentExpiration}
                      onChange={handleButtonState}
                    />
                  </div>
                )}
              </div>
              <div className='row'>
                <div className='col-2 mt-10' />
                <div className='col-4 mt-10'>
                  <Field
                    component={TextAreaField}
                    name='clinicalNotes'
                    defaultValue={currentNote}
                    onChange={handleNoteState}
                  />
                </div>
              </div>
              <div className='row mt-4'>
                {canEdit(UserRoleTypes.Clinical) ? (
                  <>
                    <div className='col-md-2'>&nbsp;</div>
                    <div className='col-md-3 mb-3'>
                      <FormButton
                        label='Update'
                        type='submit'
                        className='k-button pageButton blue'
                        loading={isLoading}
                        disabled={!buttonToggle}
                      />
                    </div>
                  </>
                ) : (
                  <WarningBlock message='You do not have the necessary permissions to add clinical oversight' />
                )}
              </div>
            </article>
          </form>
        )}
      />
    );
  };

  return (
    <div className='row pl-3 pr-3 pt-1'>
      <div className='col'>
        <div className='row'>
          <div className='col-md-3 pageTitle'>Clinical Oversight</div>
        </div>
        <div className='row'>
          <div className='col-md-12 mt-3' id='tab-strip-gray'>
            {listReferralOrdersData && listReferralOrdersData.length > 0 ? (
              <TabStrip
                selected={selected}
                onSelect={handleSelect}
                className='tsg-container'
              >
                {listReferralOrdersData.map((item) => (
                  <TabStripTab title={item.drugName} key={item.referralId}>
                    {renderReferral()}
                  </TabStripTab>
                ))}
              </TabStrip>
            ) : (
              <div className=''>
                <Badge text='No active orders available' type='warning' />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Clinical;
