// util
import { toProperCase } from '@/common/Converters';

export const mapAndFilterPrescribers = (dataArray) => {
  const filtered = dataArray
    .map((item) => ({
      ...item,
      prescriberName: toProperCase(
        `${item.prescriberFirstName} ${item.prescriberLastName}`
      )
    }))
    .sort((a, b) => (a.prescriberName > b.prescriberName ? 1 : -1));
  const unique = Array.from(new Set(filtered.map((a) => a.prescriberName))).map(
    (prescriberName) => {
      return filtered.find((a) => a.prescriberName === prescriberName);
    }
  );

  return unique;
};
