/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateScheduleEvent = /* GraphQL */ `
  subscription onCreateEvent {
    onCreateEvent {
      message
      statusCode
    }
  }
`;
export const onCancelScheduleEvent = /* GraphQL */ `
  subscription onDeleteEvent {
    onDeleteEvent {
      message
      statusCode
    }
  }
`;
export const onUpdateScheduleEvent = /* GraphQL */ `
  subscription onUpdateEvent {
    onUpdateEvent {
      message
      statusCode
    }
  }
`;
export const onCreatePendingEvent = /* GraphQL */ `
  subscription onCreatePendingEvent {
    onCreatePendingEvent {
      message
      statusCode
    }
  }
`;
export const onCancelPendingEvent = /* GraphQL */ `
  subscription onDeletePendingEvent {
    onDeletePendingEvent {
      message
      statusCode
    }
  }
`;
export const onUpdatePendingEvent = /* GraphQL */ `
  subscription onUpdatePendingEvent {
    onUpdatePendingEvent {
      message
      statusCode
    }
  }
`;
export const onStepCheckInEvent = /* GraphQL */ `
  subscription onStepCheckInEvent {
    onStepCheckInEvent {
      message
      statusCode
    }
  }
`;

export const onUpdateWorkItemStatus = /* GraphQL */ `
  subscription onUpdateWorkItemStatus {
    onUpdateWorkItemStatus {
      details
      success
      workItem {
        attachedData
        createdAt
        description
        groupQueueId
        id
        previousWorkStatus
        workItemType
        workStatus
      }
    }
  }
`;

export const onAcquireWork = /* GraphQL */ `
  subscription onAcquireWork {
    onAcquireWork {
      details
      success
      workItem {
        assignedTo
        attachedData
        groupQueueId
        id
        parentWorkItemId
        previousWorkStatus
        workItemType
        workStatus
      }
    }
  }
`;

export const onReleaseWork = /* GraphQL */ `
  subscription onReleaseWork {
    onReleaseWork {
      details
      success
      workItem {
        attachedData
        createdAt
        groupQueueId
        id
        workItemType
        workStatus
      }
    }
  }
`;

export const onCloseLabDraw = /* GraphQL */ `
  subscription onCloseLabDraw {
    onCloseLabDraw {
      message
      statusCode
    }
  }
`;
