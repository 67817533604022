import React, { useEffect, useState } from 'react';

import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';

import Ordering from './Ordering/Ordering';
import Shipping from './Shipping/Shipping';

import { connectToGraphqlAPI } from '@/provider';
import { listProductInventories } from '@/graphql/queries';

import SiteInventory from './SiteInventory';

const Inventory = (props) => {
  const [inventoryData, setInventoryData] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);

  const handleSelect = (e) => {
    setSelectedTab(e.selected);
  };

  // MAIN INITIATOR
  useEffect(() => {
    listProductInventoriesCall();
  }, []);

  const listProductInventoriesCall = async () => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: listProductInventories
      });

      // STEP 1: data collection from existing record
      if (data && data.data && data.data.listProductInventories) {
        setInventoryData(data.data.listProductInventories);
      } else {
        alert('NO INVENTORY DATA AVAILABLE.');
      }
    } catch (err) {
      console.log('marty listProductInventoriesCall err', err);
    }
  };

  return (
    <div className='inventory-tab inventory-portal'>
      <TabStrip selected={selectedTab} onSelect={handleSelect}>
        <TabStripTab contentClassName='navBar2' title='SITE INVENTORY'>
          <div className='tabText'>
            <SiteInventory />
          </div>
        </TabStripTab>
        <TabStripTab contentClassName='navBar2' title='ORDERING'>
          <div className='tabText'>
            <Ordering inventoryData={inventoryData} history={props.history} />
          </div>
        </TabStripTab>
        <TabStripTab contentClassName='navBar2' title='SHIPPING'>
          <div className='tabText'>
            <Shipping />
          </div>
        </TabStripTab>
      </TabStrip>
    </div>
  );
};

export default Inventory;
