import React, { createContext, useState } from 'react';

export const SurveyContext = createContext();

const SurveyContextProvider = ({ children }) => {
  const [activeSurvey, setActiveSurvey] = useState(null);
  const [surveyHeader, setSurveyHeader] = useState(null);
  const [surveyResults, setSurveyResults] = useState(null);
  const [surveyFollowup, setSurveyFollowup] = useState(null);
  const [surveyReviewMode, setSurveyReviewMode] = useState(false);

  return (
    <SurveyContext.Provider
      value={{
        activeSurvey,
        setActiveSurvey,
        surveyHeader,
        setSurveyHeader,
        surveyResults,
        setSurveyResults,
        surveyFollowup,
        setSurveyFollowup,
        surveyReviewMode,
        setSurveyReviewMode
      }}
    >
      {children}
    </SurveyContext.Provider>
  );
};

export default SurveyContextProvider;
