import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

// context awared.
import ServiceWorkerContextProvider from '@/context/ServiceWorkerContext';

ReactDOM.render(
  <ServiceWorkerContextProvider>
    <App />
  </ServiceWorkerContextProvider>,
   document.getElementById('root')
);
