import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { orderBy } from '@progress/kendo-data-query';
import { Input } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Form, Field } from '@progress/kendo-react-form';
import { PDFExport } from '@progress/kendo-react-pdf';

import {
  InputField,
  validateInput,
  DatePickerField
} from '@/common/Validation';
import WindowDialog from '@/components/common-components/WindowDialog';
import { MessageDialog } from '@/components/common-components/MessageDialog';
import { formatDateToAWS, formatDateToDefault, getNow } from '@/common/DateHelper';
import { Constants } from '@/constants';

import { connectToGraphqlAPI } from '@/provider';
import {
  // listLocationAICs,
  getLocationsWithActiveOrders,
  getProductOrdersByLocation,
  getInventoryByNDC
} from '@/graphql/queries';
import { shippingProducts } from '@/graphql/mutations';

import { UserContext } from '@/context/UserContext';

const initialSort = [
  {
    field: 'productName',
    dir: 'asc'
  }
];

const ShippingReport = () => {
  const { user } = useContext(UserContext);
  const agentId = user.username;
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedShippingCompany, setSelectedShippingCompany] = useState(null);
  const [selectedTrackingNumber, setSelectedTrackingNumber] = useState(null);
  const [customShippinngCompany, setCustomShippinngCompany] = useState(null);
  const [expectedDeliveryDate, setExpectedDeliveryDate] = useState(null);
  const [selectedShippingDate, setSelectedShippingDate] = useState(new Date());
  const [locationOrders, setLocationOrders] = useState([]);
  const [locationInventory, setLocationInventory] = useState([]);
  const [shipmentOrder, setShipmentOrder] = useState([]);
  const [selectedDrug, setSelectedDrug] = useState(null);
  const [drugLots, setDrugLots] = useState([]);
  const [drugOrders, setDrugOrders] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [showShipmentDialog, setShowShipmentDialog] = useState(false);
  const [dialogOption, setDialogOption] = useState({});
  const [sort, setSort] = React.useState(initialSort);
  const pdfExportComponent = React.useRef(null);
  const [shippingCompanies] = useState([
    {
      text: 'Above & Beyond',
      value: 'Above & Beyond'
    },
    {
      text: 'FedEx',
      value: 'FedEx'
    },
    {
      text: 'UPS',
      value: 'UPS'
    },
    {
      text: 'XPO',
      value: 'XPO'
    },
    {
      text: 'ZipScript',
      value: 'ZipScript'
    },
    {
      text: 'Other',
      value: 'Other'
    }
  ]);
  const [showCustomShipping, setShowCustomShipping] = useState(false);
  const formInvalid = Boolean(!selectedShippingCompany ||
      !selectedTrackingNumber ||
      (selectedShippingCompany === 'Other' && !customShippinngCompany));
  const [reloadInventoryGrid, setReloadInventoryGrid] = useState(0);

  function handleShippingCompanyChange(event) {
    const val = event.target.value.value;

    setSelectedShippingCompany(val);
    setShowCustomShipping(val === 'Other');
  }

  function handleTrackingNumberChange(event) {
    setSelectedTrackingNumber(event.target.value);
  }

  function handleCustomShippingCompanyChange(event) {
    setCustomShippinngCompany(event.target.value);
  }

  function handleDeliveryDateChange(event) {
    setExpectedDeliveryDate(event.target.value);
  }

  const getLocationsWithActiveOrdersCall = async () => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getLocationsWithActiveOrders
      });

      if (data && data.data && data.data.getLocationsWithActiveOrders) {
        const locations = data.data.getLocationsWithActiveOrders
          .map(item => ({
            ...item,
            text: `${item.locationName}, ${item.state}`,
            value: item.id
          }))
          .sort((a, b) => (a.locationName > b.locationName ? 1 : -1));

        setLocations(locations);
      }
    } catch (err) {
      console.log('ILIA: getLocationsWithActiveOrdersCall data err', err);
      if (err && err.errors && err.errors.length > 0 && err.errors[0].message) {
        console.log('ILIA: error message: ', err.errors[0].message);
      }
    }
  };

  const getProductOrdersByLocationCall = async locationId => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getProductOrdersByLocation,
        variables: { locationId }
      });

      if (
        data &&
        data.data &&
        data.data.getProductOrdersByLocation &&
        data.data.getProductOrdersByLocation.orders &&
        data.data.getProductOrdersByLocation.orders.length
      ) {
        const orderItems = data.data.getProductOrdersByLocation.orders;

        setLocationOrders(orderItems.map(order => ({
            orderId: order.id,
            orderDate: order.orderDate,
            productName: order.productName,
            productId: order.productId,
            dose: order.dose,
            unitOfMeasure: order.unitOfMeasure,
            qty: order.initialQuantity,
            qtyShipped: order.orderQuantity,
            orderStatus: order.orderStatus
          })));

        // filter out non-unique NDC
        const ndcList = [...new Set(orderItems.map(order => order.productId))];

        getInventoryByNDCCall(ndcList);
      } else {
        setDialogOption({
          title: 'Find Orders',
          message: 'No orders found for selected location.', // err.errors[0].message, //'Error',
          showDialog: true
        });
      }

      return data;
    } catch (err) {
      console.log('MCN: getProductOrdersByLocation request error', err);
      if (err && err.errors && err.errors.length > 0 && err.errors[0].message) {
        //
      }
    }
  };

  const getInventoryByNDCCall = async (ndcList = []) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getInventoryByNDC,
        variables: { agentId, ndcList }
      });

      if (
        data &&
        data.data &&
        data.data.getInventoryByNDC &&
        data.data.getInventoryByNDC.inventory
      ) {
        setLocationInventory(data.data.getInventoryByNDC.inventory
            .map(invItem => ({
              ...invItem,
              drug: getDrugNameByNDC(invItem.ndc),
              qty: invItem.quantity
            }))
            .sort((a, b) => (a.qty < b.qty ? 1 : -1)));
      }
    } catch (err) {
      console.log('ILIA: getInventoryByNDCCall request error', err);
      if (err && err.errors && err.errors.length > 0 && err.errors[0].message) {
        //
      }
    }
  };

  /* const showNeedTrackingDetailsMsg = () => {
    setDialogOption({
      title: "Create Shipment Order",
      message: "Please provide shipping company and tracking number.",
      showDialog: true
    });
  }; */

  const createShipmentHandler = async () => {
    if (formInvalid) {
      /* return showNeedTrackingDetailsMsg(); */
    }

    try {
      const items = [];
      shipmentOrder.forEach(order => {
        if (order.shipQty > 0) {
          items.push({
            expirationDate: order.expirationDate,
            fromInventoryId: order.id,
            lotNumber: order.lotNumber,
            ndcNumber: order.productId,
            price: order.price,
            productName: order.productName,
            quantity: order.shipQty,
            vendor: order.vendor
          });
        }
      });

      const productOrders = [];
      shipmentOrder.forEach(order => {
        if (order.shipQty > 0) {
          order.drugOrders.forEach(drugOrder => {
            if (drugOrder.shipQty > 0) {
              let status = 'PARTIALLY_FULFILLED';
              if (drugOrder.shipQty == drugOrder.qty) {
                status = 'FULFILLED';
              }
              productOrders.push({
                orderId: drugOrder.orderId,
                orderStatus: status
              });
            }
          });
        }
      });

      const shippingCompany =
        selectedShippingCompany === 'Other'
          ? customShippinngCompany
          : selectedShippingCompany;
      const shipDate = new Date();
      const productShipment = {
        items,
        shippedDate: selectedShippingDate,
        trackingNumber: selectedTrackingNumber || '00000000',
        shippingCompany: shippingCompany || 'No company selected',
        shippingToLocationId: selectedLocation.id,
        productOrders,
        expectedDeliveryDate: formatDateToAWS(expectedDeliveryDate || shipDate.setDate(shipDate.getDate() + 1))
      };

      const data = await connectToGraphqlAPI({
        graphqlQuery: shippingProducts,
        variables: { agentId, productShipment }
      });

      if (
        data &&
        data.data &&
        data.data.shippingProducts &&
        data.data.shippingProducts.message
      ) {
        alert(`Error occurred saving order data with:\nstatusCode: ${
            data.data.shippingProducts.statusCode
            }\nand message: ${
            data.data.shippingProducts.message}`);
      } else {
        alert('Shipment created!');
        exportPDFWithComponent();
        resetPage();
      }
    } catch (err) {
      console.log('ILIA: saveOrders data err', err);
      if (err && err.errors && err.errors.length > 0 && err.errors[0].message) {
        console.log('ILIA: saveOrders error message: ', err.errors[0].message);
      }
    }
  };

  // Form Handlers and Togglers
  const toggleDialog = () => {
    setShowDialog(!showDialog);
  };

  const resetPage = () => {
    setShipmentOrder(null);
    setLocationOrders([]);
    setLocationInventory([]);
    setShowShipmentDialog(false);
    setShowDialog(false);
    getLocationsWithActiveOrdersCall();
    /* getProductOrdersByLocationCall(selectedLocation.id); */
  };

  const handleFindOrders = formItems => {
    setLocationOrders([]);
    setLocationInventory([]);
    setSelectedLocation(formItems.location);
    getProductOrdersByLocationCall(formItems.location.id);
  };

  const submitShipmentItems = () => {
    const shipOrder = drugLots
      .filter(lot => lot.shipQty > 0)
      .map(lot => {
        const shipVendor =
          selectedShippingCompany === 'Other'
            ? customShippinngCompany
            : selectedShippingCompany;
        return {
          ...lot,
          drug: `${lot.productName} (${lot.strengthPerVial} ${lot.unitOfMeasure})`,
          shipVendor,
          shipTracking: selectedTrackingNumber,
          drugOrders: drugOrders
            .filter(order => order.shipQty > 0)
            .map(order => {
              return {
                ...order,
                drug: `${order.productName} (${order.strengthPerVial} ${order.unitOfMeasure})`
              };
            })
        };
      });
    // clone newly created shipOrder.
    const clonedItems = [...shipOrder];

    // log out existing shipment Order

    // now enumerate existing. we are going to add what was here before to new map list.
    if (shipmentOrder && shipmentOrder.length > 0) {
      shipmentOrder.forEach(existingShipmentOrder => {
        const existingId = existingShipmentOrder.id;
        let foundMatch = false;
        shipOrder.forEach(newShipmentOrder => {
          if (!foundMatch) {
            const newId = newShipmentOrder.id;
            if (newId == existingId) {
              foundMatch = true;
            }
          }
        });
        if (!foundMatch) {
          clonedItems.push(existingShipmentOrder);
        }
      });
    }
    setShipmentOrder(clonedItems);
    toggleDialog();
  };

  const exportPDFWithComponent = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };

  // Custom Component Renderers
  const customDrugName = props => {
    const { productName, dose, unitOfMeasure } = props.dataItem;
    return (
      <td>
        {productName} ({dose} {unitOfMeasure})
      </td>
    );
  };

  const customInvDrug = props => {
    const drugName = getDrugNameByNDC(props.dataItem.ndc);
    return <td>{drugName}</td>;
  };

  const customDate = props => {
    return <td>{formatDateToDefault(props.dataItem.orderDate)}</td>;
  };

  const inventoryShipQty = props => {
    if (props.dataItem.qty === 0) {
      return <td>&mdash;</td>;
    }

    const shipQty = props.dataItem.shipQty ? props.dataItem.shipQty : 0;
    const style = props.dataItem.qty - shipQty <= 0 ? { color: 'red' } : null;

    return (
      <td>
        <Input
          className="w-100"
          name="shipQty"
          defaultValue={props.dataItem.shipQty}
          style={style}
          onBlur={e => {
            setReloadInventoryGrid(reloadInventoryGrid + 1);
          }}
          onChange={e => {
            const newQty = e.target.value;

            if (newQty) {
              props.dataItem.shipQty = Number(newQty);
            } else {
              props.dataItem.shipQty = null;
            }
          }}
        />
      </td>
    );
  };

  const calcRequestedQuantity = productId => {
    let qtyCount = 0;

    locationOrders.forEach(order => {
      if (productId === order.productId) {
        qtyCount += order.qty;
      }
    });

    return qtyCount;
  };

  const inventoryRequestedQty = props => (
    <td>{calcRequestedQuantity(props.dataItem.ndc)}</td>
  );

  const inventoryRemainingQty = props => {
    const reqQty = calcRequestedQuantity(props.dataItem.ndc);
    const remQty = props.dataItem.qty - reqQty;
    const style = remQty < 0 ? { color: 'red' } : null;

    return <td style={style}>{remQty}</td>;
  };

  const inventoryShipAdd = props => {
    if (props.dataItem.qty === 0) {
      return <td>&nbsp;</td>;
    }
    let sDisable = '';
    const shipQty = props.dataItem.shipQty ? props.dataItem.shipQty : 0;
    sDisable = props.dataItem.qty - shipQty >= 0 ? '' : 'disabled';
    return (
      <td key={props.dataItem}>
        <button
          // key={props.dataItem}
          type="button"
          primary="true"
          className="k-button mr-1"
          // disabled={"disabled"}
          disabled={sDisable}
          onClick={() => {
            setSelectedDrug(props.dataItem);
            toggleDialog();

            // pre-populate ship qty for Lots
            const orderLots = populateLotsQty(
              props.dataItem.items,
              props.dataItem.shipQty
            );
            setDrugLots(orderLots);

            // pre-populate ship qty for Orders
            const ordersByNDC = locationOrders.filter(order => order.productId === props.dataItem.ndc);
            const shipOrders = populateShipQty(
              ordersByNDC,
              props.dataItem.shipQty
            );
            setDrugOrders(shipOrders);
          }}
        >
          ADD
        </button>
      </td>
    );
  };

  // Helper Methods
  const populateLotsQty = (orderLots, totalQty) => {
    const lots = [...orderLots];
    let counter = totalQty;

    lots.forEach(lot => {
      const qty = lot.currentQuantity;

      if (qty > 0 && counter > 0) {
        // put all at this lot, if possible
        if (qty <= counter) {
          lot.shipQty = qty;
          counter -= qty;
        } else {
          // otherwise - fulfill partially using this lot
          lot.shipQty = counter;
          counter = 0;
        }
      } else {
        lot.shipQty = null;
      }
    });

    return lots;
  };

  const populateShipQty = (orders, totalQty) => {
    const clonedOrders = [...orders];
    let counter = totalQty;

    clonedOrders.forEach(order => {
      if (order.qty > 0 && counter > 0) {
        // put all at this order, if possible
        if (order.qty <= counter) {
          order.shipQty = order.qty;
          counter -= order.qty;
        } else {
          // otherwise - fulfill partially using this order
          order.shipQty = counter;
          counter = 0;
        }
      } else {
        order.shipQty = null;
      }
    });

    return clonedOrders;
  };
  const handleDeleteClick = (props, object) => {
    if (props.dataIndex > -1) {
      if (object === 'OrderToShip') {
        // alert(`DELETE ${object}: ${props.dataIndex}`)
        if (props.dataIndex > -1) {
          const cloneShipmentOrder = [...shipmentOrder];
          cloneShipmentOrder.splice(props.dataIndex, 1);
          setShipmentOrder(cloneShipmentOrder);
        }
      }
    }
  };

  const customCellDeleteOrderToShip = props => {
    return (
      <td>
        <button
          type="button"
          className="k-button"
          onClick={() => handleDeleteClick(props, 'OrderToShip')}
        >
          Delete
        </button>
      </td>
    );
  };
  const getDrugNameByNDC = ndc => {
    const drug = locationOrders.find(order => order.productId === ndc);

    if (drug) {
      return `${drug.productName} (${drug.dose} ${drug.unitOfMeasure})`;
    }

    return '';
  };

  const OrderToShip = (hideActions = false) => {
    return (
      <div className="row">
        <div className="col-md-11 mb-2">
          <Grid className="infusion-grid" data={shipmentOrder}>
            <Column field="id" title="Inventory ID" />
            <Column field="drug" title="Drug" />
            <Column field="shipQty" title="Ship Qty" />
            {!hideActions && (
              <Column
                field="action"
                title=" "
                cell={customCellDeleteOrderToShip}
              />
            )}
          </Grid>
        </div>
      </div>
    );
  };

  const shippingReportForm = {
    packageDeliveryCompany: {
      value: selectedShippingCompany,
      inputValidator: value => {
        return validateInput({
          packageDeliveryCompany: {
            ...shippingReportForm.packageDeliveryCompany,
            value
          }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    },
    trackingNumber: {
      value: selectedShippingCompany,
      inputValidator: value => {
        return validateInput({
          trackingNumber: {
            ...shippingReportForm.trackingNumber,
            value
          }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    }
  };

  // MAIN INITIATOR
  useEffect(() => {
    getLocationsWithActiveOrdersCall();
  }, []);

  const handleCloseClick = useCallback(() => {
    setShowShipmentDialog(false);
  }, []);

  return (
    <div className="col-12 mt-2 ml-1 mr-3">
      {dialogOption && dialogOption.showDialog && (
        <MessageDialog dialogOption={dialogOption} />
      )}
      <Form
        onSubmit={handleFindOrders}
        render={formRenderProps => (
          <form
            onSubmit={formRenderProps.onSubmit}
            className="k-form pl-3 pr-3 pt-1"
          >
            <div className="row">
              <div className="col-md-12 mt-12 pageTitle">
                <big>PENDING ORDERS</big>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 ml-3">
                <Field
                  component={DropDownList}
                  name="location"
                  label="LOCATION"
                  data={locations}
                  textField="text"
                  valueField="value"
                />
              </div>
              {/* <div className="col-md-3">
                From Date &nbsp;&nbsp;
                <Field
                  name={"serviceFrom"}
                  label={"Service From"}
                  component={DatePickerField}
                />
              </div>
              <div className="col-md-3">
                To Date &nbsp;&nbsp;
                <Field
                  name={"serviceTo"}
                  label={"Service To"}
                  component={DatePickerField}
                />
              </div> */}
              <div className="col-md-1 mt-3">
                <button type="submit" className="k-button blue">
                  Find Orders
                </button>
              </div>
            </div>
          </form>
        )}
      />

      {/* DISPLAY ORDERS PER SELECTED LOCATION */}
      {locationOrders && locationOrders.length > 0 && (
        <>
          <div className="row">
            <div className="col-md-12 mt-12 pageTitle">
              <big>Orders ({selectedLocation.text})</big>
            </div>
          </div>
          <div className="row">
            <div className="col-md-11 mb-2">
              <Grid
                className="infusion-grid"
                data={orderBy(locationOrders, sort)}
                sortable
                sort={sort}
                onSortChange={e => {
                  setSort(e.sort);
                }}
                style={{
                  height: '300px'
                }}
              >
                <Column field="orderId" title="Order Id" />
                <Column
                  field="orderDate"
                  title="Order Date"
                  cell={customDate}
                />
                <Column field="product" title="Drug" cell={customDrugName} />
                <Column field="qty" title="Order Qty" />
                <Column field="qtyShipped" title="Ship Qty" />
                <Column field="orderStatus" title="Order Status" />
              </Grid>
            </div>
          </div>
        </>
      )}

      {/* DISPLAY CURRENT INVENTORY PER SELECTED LOCATION */}
      {locationInventory && locationInventory.length > 0 && (
        <>
          <div className="row">
            <div className="col-md-12 mt-12 pageTitle">
              <big>Inventory</big>
            </div>
          </div>
          <div className="row">
            <div key={reloadInventoryGrid} className="col-md-11 mb-2">
              <Grid className="infusion-grid" data={locationInventory}>
                <Column field="drug" title="Drug" cell={customInvDrug} />
                <Column field="qty" title="Inventory Qty" />
                <Column
                  field="qtyShip"
                  title="Requested Qty"
                  cell={inventoryRequestedQty}
                />
                <Column
                  field="qtyShip"
                  title="Ship Qty"
                  cell={inventoryShipQty}
                />
                <Column
                  field="qtyShip"
                  title="Remaining Qty"
                  cell={inventoryRemainingQty}
                />
                <Column
                  field="addAction"
                  title="Action"
                  cell={inventoryShipAdd}
                />
              </Grid>
            </div>
          </div>
        </>
      )}

      {/* WHAT WILL BE INCLUDED TO THE SHIPMENT */}
      {shipmentOrder && shipmentOrder.length > 0 && (
        <>
          <div className="row">
            <div className="col-md-12 mt-12 pageTitle">
              <big>Order to Ship</big>
            </div>
          </div>
          <OrderToShip />
          {/* <div className="row">
            <div className="col-md-11 mb-2">
              <Grid className="infusion-grid" data={shipmentOrder}>
                <Column field="id" title="Inventory ID" />
                <Column field="drug" title="Drug" />
                <Column field="shipQty" title="Ship Qty" />
                <Column
                  field="action"
                  title=" "
                  cell={customCellDeleteOrderToShip}
                />
              </Grid>
            </div>
          </div> */}
          {/* <div className="row">
            <div className="col-md-12 mt-12 pageTitle">
              <big>Shipping Information</big>
            </div>
          </div>
          <div className="row">
            <div className="col-2">
              Order will be shipped by: {selectedShippingCompany}
            </div>
            <div className="col-2">
              Tracking Number: {selectedTrackingNumber}
            </div>
          </div> */}
          <div className="row mt-3">
            <div className="col-2">
              <button
                type="button"
                primary="true"
                onClick={() => setShowShipmentDialog(true)}
                className="k-button k-button pageButton mr-1 mt-3"
              >
                Create Shipment
              </button>
            </div>
          </div>
        </>
      )}

      {/* ADD TO ORDER MODAL WINDOW */}
      <WindowDialog
        title="Add Shipment Item"
        width={900}
        height={535}
        initialTop={10}
        showDialog={showDialog}
        onClose={toggleDialog}
      >
        <Form
          onSubmit={submitShipmentItems}
          render={formRenderProps => (
            <form
              onSubmit={formRenderProps.onSubmit}
              className="k-form pl-3 pr-3 pt-1"
            >
              <div style={{ backgroundColor: '#ffffff' }}>
                <div className="infusion-details col-12 ml-0 pl-3 py-3 mr-0">
                  <div className="row">
                    <div className="col-md-3 headerText">Phoenix</div>
                    <div className="col-3 headerText">
                      {selectedLocation.text}
                    </div>
                    <div className="col-md-3 headerText">
                      {getDrugNameByNDC(selectedDrug.ndc)}
                    </div>
                    <div className="col-3 headerText">{selectedDrug.ndc}</div>
                  </div>
                  <div className="row">
                    <div className="col-3 infusion-details-field-name">
                      Ship From Location
                    </div>
                    <div className="col-3 infusion-details-field-name">
                      Ship To Location
                    </div>
                    <div className="col-3 infusion-details-field-name">
                      Drug
                    </div>
                    <div className="col-3 infusion-details-field-name">
                      NDC Package Code
                    </div>
                  </div>
                  <div className="row">&nbsp;</div>
                  <div className="row">
                    <div className="col-md-9">&nbsp;</div>
                    <div className="col-3 infusion-details-field-name">
                      Total Ship Quantity:{' '}
                      <span className="headerText">
                        {selectedDrug.shipQty}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row col-md-12 mt-2">
                  <div className="col-md-12 mt-0  mb-2">
                    <Grid className="infusion-grid" data={drugLots}>
                      <Column field="lotNumber" title="Lot #" />
                      <Column
                        field="expirationDate"
                        title="Expiration Date"
                      />
                      <Column field="currentQuantity" title="Available Qty" />
                      <Column
                        field="shipQty"
                        title="Ship Qty"
                        cell={inventoryShipQty}
                      />
                    </Grid>
                  </div>
                </div>
                <div className="row col-md-12 mt-2">
                  <div className="col-md-12 mt-0  mb-2">
                    <Grid className="infusion-grid" data={drugOrders}>
                      <Column field="orderId" title="Order ID" />
                      <Column field="qty" title="Order Qty" />
                      <Column
                        field="shipQty"
                        title="Ship Qty"
                        cell={inventoryShipQty}
                      />
                    </Grid>
                  </div>
                </div>
                {/* <div className="row">
                    <div className="col-md-3 ml-3">
                      <Field
                        component={DropDownList}
                        name="shippingCompany"
                        label="Shipping Company"
                        data={shippingCompanies}
                        textField="text"
                        valueField="value"
                        onChange={handleShippingCompanyChange}
                        //validator={shippingReportForm.packageDeliveryCompany.inputValidator}
                      />
                    </div>
                    {showCustomShipping && (
                      <div className="col-md-3 ml-3">
                        <Field
                          name="customShippingCompany"
                          label="Enter Shipping Company"
                          component={InputField}
                          onChange={handleCustomShippingCompanyChange}
                          //validator={shippingReportForm.trackingNumber.inputValidator}
                        />
                      </div>
                    )}
                    <div className="col-md-3 ml-3">
                      <Field
                        name="trackingNumber"
                        label="Tracking Number"
                        component={InputField}
                        onChange={handleTrackingNumberChange}
                        //validator={shippingReportForm.trackingNumber.inputValidator}
                      />
                    </div>
                  </div> */}
                <div className="row p-3 mt-3">
                  <div className="col-12">
                    <button
                      className="k-button pageButton Blue"
                      onClick={submitShipmentItems}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
            )}
        />
      </WindowDialog>

      {/* CREATE SHIPMENT AND TRACKING */}
      <WindowDialog
        title="Create Shipment and Tracking"
        width={900}
        height={535}
        initialTop={10}
        showDialog={showShipmentDialog}
        onClose={handleCloseClick}
      >
        <Form
          onSubmit={submitShipmentItems}
          render={formRenderProps => (
            <form
              onSubmit={formRenderProps.onSubmit}
              className="k-form pl-3 pr-3 pt-1"
            >
              <div style={{ backgroundColor: '#ffffff' }}>
                <PDFExport
                  ref={pdfExportComponent}
                  paperSize="auto"
                  margin={40}
                  fileName="Shipping Order (AleraCare)"
                  author="AleraCare"
                >
                  <div className="pl-4 pt-4 row">
                    <div className="col-4 infusion-details headerText">
                      Phoenix{' '}
                    </div>
                    <div className="col-4  infusion-details headerText">
                      {selectedLocation.text}{' '}
                    </div>
                    <div className="col-4 infusion-details headerText">
                      <i>{getNow()}</i>{' '}
                    </div>
                  </div>
                  <div className="pl-4 pt-1 row">
                    <div className="col-4">Ship From location</div>
                    <div className="col-4">Ship to Location</div>
                    <div className="col-4">Date</div>
                  </div>
                  <div className="ml-3">
                    <div className="row pageTitle mb-3 mt-4 ml-1">
                      <big>Order to Ship</big>
                    </div>
                    <OrderToShip hideActions />
                  </div>
                  <div className="row">
                    <div className="col-md-3 ml-3">
                      <Field
                        component={DropDownList}
                        name="shippingCompany"
                        label="Shipping Company"
                        data={shippingCompanies}
                        textField="text"
                        valueField="value"
                        onChange={handleShippingCompanyChange}
                      />
                    </div>
                    {showCustomShipping && (
                    <div className="col-md-3 ml-3">
                      <Field
                        name="customShippingCompany"
                        label="Enter Shipping Company"
                        component={InputField}
                        onChange={handleCustomShippingCompanyChange}
                      />
                    </div>
                      )}
                    <div className="col-md-3 ml-3">
                      <Field
                        name="trackingNumber"
                        label="Tracking Number"
                        component={InputField}
                        onChange={handleTrackingNumberChange}
                      />
                    </div>
                    <div className="col-md-3 ml-3">
                      <Field
                        name="deliveryDate"
                        label="Expected Delivery Date"
                        component={DatePickerField}
                        onChange={handleDeliveryDateChange}
                      />
                    </div>
                  </div>
                </PDFExport>
                <div className="row p-3 mt-3">
                  <div className="col-3">
                    <button
                      className="k-button pageButton Blue"
                      onClick={createShipmentHandler}
                    >
                      Submit & Print
                    </button>
                  </div>
                </div>
              </div>
            </form>
            )}
        />
      </WindowDialog>
    </div>
  );
};

export default ShippingReport;
