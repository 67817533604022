import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';

import Preloader from '@/components/common-components/Preloader';
import Badge from '@/components/common-components/Badge';

import { connectToGraphqlAPI } from '@/provider';
import { getWorkItemsByGroupQueue, getPatientBucket } from '@/graphql/queries';

import { UserContext } from '@/context/UserContext';
import { PatientContext } from '@/context/PatientContext';
import { NotifContext } from '@/context/NotifContext';

import { formatDateToDefault } from '@/common/DateHelper';
import { ColumnMenu } from '@/components/common-components/columnMenu';

const BenInvQueue = () => {
  const history = useHistory();
  const { user } = useContext(UserContext);
  const { setSelectedPatientInfo } = useContext(PatientContext);
  const { showError } = useContext(NotifContext);

  const [loading, setLoading] = useState(true);
  const [listWorkItems, setListWorkItems] = useState([]);

  const initialDataState = {};

  const [dataState, setDataState] = useState();
  const [resultState, setResultState] = useState(process(listWorkItems, initialDataState));

  const onDataStateChange = useCallback(
    (e) => {
      setDataState(e.dataState);
      setResultState(process(listWorkItems, e.dataState));
    },
    [listWorkItems]
  );

  const render = {
    patientNameCell: ({ dataItem: { inProgram, patientName } }) => (
      <td>
        {inProgram && (
          <Badge type="info" text="STAT ViiV" />
        )}
        {patientName}
      </td>
    ),
    customCell: ({ dataItem: { workStatus, description } }) => (
      <td>
        {workStatus === 'OVERDUE' && (
          <Badge text="ESCALATED" />
        )}
        {description}
      </td>
    )
  };

  const columns = [
    { field: 'agentName', title: 'Agent Name' },
    { field: 'patientName', title: 'Patient Name', cell: render.patientNameCell },
    { field: 'work', title: 'Type' },
    { field: 'description', title: 'Description', cell: render.customCell },
    { field: 'expDate', title: 'Exp Date' },
  ];

  // MAIN INITIATOR
  useEffect(() => {
    if (user) {
      fetchWorkInProgressData();
    }
  }, []);

  useEffect(() => {
    if (!loading) {
      setDataState(initialDataState);
      setResultState(process(listWorkItems, initialDataState));
    }
  }, [listWorkItems]);

  const fetchWorkInProgressData = async () => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getWorkItemsByGroupQueue,
        variables: { groupQueueId: 'BI' },
      });

      if (data?.data?.getWorkItemsByGroupQueue?.length > 0) {
        setLoading(false);

        const listWorkItemsData = data.data.getWorkItemsByGroupQueue
          .map((item) => {
            item.patientName = `${item.patientLastName}, ${item.patientFirstName}`;
            item.agentName = `${item.agentLastName}, ${item.agentFirstName}`;
            item.expDate = formatDateToDefault(item.targetTime);
            return item;
          })
          .sort((a, b) => (b.startTime > a.startTime ? 1 : -1));

        setListWorkItems(listWorkItemsData);
      }
      setLoading(false);
    } catch (err) {
      console.log('BenInvQueue::getWorkItemsByGroupQueue error:', err);
      setLoading(false);
    }
  };

  const getPatientBucketData = async (patientId, currentStage) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getPatientBucket,
        variables: { patientId },
      });

      if (data && data.data && data.data.getPatientBucket) {
        setSelectedPatientInfo(data.data.getPatientBucket);
        history.push('/patient-portal', {
          searchType: currentStage,
          redirect2BI: true,
        });
      } else {
        showError('No Patient Record Found');
      }
    } catch (err) {
      console.log('BenInvQueue::getPatientBucketData err:', err);
      showError('Error at patient bucket method. Check console for details');
    }
  };

  const onRowClickHandle = (e) => {
    if (e?.dataItem?.work) {
      return getPatientBucketData(e.dataItem.patientId, 'benInv');
    }

    console.error("BenInvQueue error: work item doesn't have expected values");
  };

  return (
    <div className="container-fluid agent-work-queue">
      {user?.username && (
        <div className="row my-3">
          <div
            className="col-12 col-lg-12 work-in-progress"
            style={{ marginTop: '1.9rem' }}
          >
            <div className="a-grid__header">
              <div>Benefits Investigation Queue</div>
            </div>
            <Preloader show={loading}>
              <Grid
                className="queue_grid"
                onRowClick={onRowClickHandle}
                style={{ cursor: 'pointer' }}
                data={{ data: resultState.data }}
                sortable
                onDataStateChange={onDataStateChange}
                {...dataState}
              >
                {columns.map((column, index) => {
                  return (
                    <Column
                      field={column.field}
                      title={column.title}
                      key={index}
                      width={column.minWidth}
                      columnMenu={ColumnMenu}
                      cell={column.cell}
                    />
                  );
                })}
              </Grid>
            </Preloader>
          </div>
        </div>
      )}
    </div>
  );
};

export default BenInvQueue;
