import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Stepper } from '@progress/kendo-react-layout';
import { TextArea } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Form, Field } from '@progress/kendo-react-form';

import { Dialog } from '@progress/kendo-react-dialogs';
import { Storage } from 'aws-amplify';
import WindowDialog from '@/components/common-components/WindowDialog';
import { MessageDialog } from '@/components/common-components/MessageDialog';
import {
  initStepper,
  getStepStatus,
  infusionSteps,
  initCurrentStep,
  isInfusionRestricted
} from '@/components/Infusion/infusionHelper';

import { connectToGraphqlAPI } from '@/provider';
// import { getPatientReferralOrders } from "@/graphql/queries";
import {
  updateStepOrderReview,
  updateStepAssessment,
  updateStepPreTreatment,
  updateStepPreparation,
  updateStepAdministration,
  updateStepCloseTreatment,
  // updateReferralOrder,
  cancelTreatment,
  updateNarrativeNotes
} from '@/graphql/mutations';

import Assessment from './Assessment';
import OrderReview from './OrderReview';
import PreTreatment from './PreTreatment';
import Prep from './Prep';
import Admin from './Admin';
import CloseTreatment from './CloseTreatment';

import { NotifContext } from '@/context/NotifContext';
import PatientDocument from '../Patient/PatientDocument';
import Alert from '@/components/common-components/Alert';

const Infusion = props => {
  const { agent } = props;
  const { showError } = useContext(NotifContext);
  const history = useHistory();

  const [infusion, setInfusion] = useState(props.infusion);
  const infusionCompleted = props.nursingProcess?.status === 'CLOSED';
  const infusionRestricted = infusionCompleted
    ? isInfusionRestricted(props)
    : false;
  const [steps, setSteps] = useState(initStepper({ ...infusion, infusionCompleted }));

  const { selectedPatientInfo } = props;
  const { nursingProcessId } = props;
  const { headerDetailsData } = props;
  const listProductsByNameData = props.listProductsByNameData.filter(item => item.productName === props.listProductsByName);
  const adminNumber = props?.adminNumber;

  const { showInfusionStepper } = props;
  const { showInfusionForm } = props;
  const { infusionFormData } = props;
  const rxOrderFile = props?.infusionFormData?.rxOrderFile;
  const [narrativeNotes, setNarrativeNotes] = useState('');

  const calcStep = initCurrentStep(infusion);
  const [currentStep, setCurrentStep] = useState(calcStep);
  const [stepOrder, setStepOrder] = useState(calcStep - 1);

  const [dialogOption, setDialogOption] = useState({});
  const [showNarrativeDialog, setShowNarrativeDialog] = useState(false);
  const [showCancelInfusionDialog, setShowCancelInfusionDialog] =
    useState(false);
  const [showPatientDocDialog, setShowPatientDocDialog] = useState(false);
  const [selectedDocumentUrl, setSelectedDocumentUrl] = useState('');

  const updateStepper = isValid => {
    const updatedSteps = steps.map((step, index) => ({
      ...step,
      isValid: index === stepOrder ? isValid : step.isValid
    }));

    setSteps(updatedSteps);
  };
  useEffect(() => {
    localStorage.setItem('narrativeNotes', JSON.stringify(narrativeNotes));
  }, [narrativeNotes]);

  useEffect(() => {
    if (props.nursingProcess?.notes && props.nursingProcess?.notes.length > 0) {
      setNarrativeNotes(props.nursingProcess.notes[0]);
    }
    // reset prev infusion data
  }, [props.nursingProcess]);

  // the callback from child to parent
  const sendDataToParent = payload => {
    if (currentStep === 1 && payload.request === 'pleaseUpdateDocs') {
      props.sendDataToParent();
    }
    if (currentStep === 1 && payload.nursingProcessId) {
      const infusionNewData = infusion;
      infusionNewData.updateStepOrderReviewInput.nursingProcessId =
        payload.nursingProcessId;
      infusionNewData.updateStepOrderReviewInput.agentId = payload.agentId;
      infusionNewData.updateStepOrderReviewInput.addendumOrderFilePath =
        payload.addendumOrderFilePath;
      infusionNewData.updateStepOrderReviewInput.orderIsApproved =
        payload.orderIsApproved;
      infusionNewData.updateStepOrderReviewInput.patientConsentReceived =
        payload.patientConsentReceived;

      // @NOTE: notes are not a part of the step anymore and has to be removed
      // infusionNewData.updateStepOrderReviewInput.notes = narrativeNotes;

      localStorage.setItem('infusion', JSON.stringify(infusionNewData));
      setInfusion(infusionNewData);
      updateStepOrderReviewCall(infusionNewData.updateStepOrderReviewInput);

      // update stepper progress checkmark
      const stepIsValid = getStepStatus(infusionSteps.ORDER_REVIEW, payload);
      updateStepper(stepIsValid);
    }
    if (currentStep === 2 && payload.nursingProcessId) {
      // const infusionNewData = {
      //   ...infusion,
      //   currentStep: 3,
      //   updateStepAssessmentInput: { ...payload, notes: narrativeNotes }
      // };
      // localStorage.setItem("infusion", JSON.stringify(infusionNewData));
      // setInfusion(infusionNewData);
      // setCurrentStep(3);
      // setStepOrder(3);
      // updateStepAssessmentCall(infusionNewData.updateStepAssessmentInput);

      // NOTE: all logic above went to the following API callback
      // It allows to fix the critical issue with the infusion logic
      // updateStepAssessmentCall({ ...payload, notes: narrativeNotes });
      updateStepAssessmentCall(payload);

      // update allergies on patient bucket as well
      // updateBucketAllergiesCall({
      //   referralId: props.infusionFormData.referralId,
      //   allergies: payload.allergies
      // });

      // NOTE: moved to the updateStepAssessmentCall callback
      // update stepper progress checkmark
      // const stepIsValid = getStepStatus(infusionSteps.ASSESSMENT, payload);
      // updateStepper(stepIsValid);
    }
    if (currentStep === 3 && payload.nursingProcessId) {
      // const infusionNewData = {
      //   ...infusion,
      //   currentStep: 4,
      //   updateStepPreTreatmentInput: { ...payload, notes: narrativeNotes }
      // };

      // localStorage.setItem("infusion", JSON.stringify(infusionNewData));
      // setInfusion(infusionNewData);
      // setCurrentStep(4);
      // setStepOrder(4);

      // NOTE: all logic above went to the following API callback
      // It allows to fix the critical issue with the infusion logic
      updateStepPreTreatmentCall(payload);

      // NOTE: moved to the updateStepAssessmentCall callback
      // // update stepper progress checkmark
      // const stepIsValid = getStepStatus(infusionSteps.PRE_TREATMENT, payload);
      // updateStepper(stepIsValid);
    }
    if (currentStep === 4 && payload.nursingProcessId) {
      // const infusionNewData = {
      //   ...infusion,
      //   currentStep: 5,
      //   updateStepPreparationInput: { ...payload, notes: narrativeNotes }
      // };
      // localStorage.setItem("infusion", JSON.stringify(infusionNewData));
      // setInfusion(infusionNewData);
      // setCurrentStep(5);
      // setStepOrder(5);

      // NOTE: all logic above went to the following API callback
      // It allows to fix the critical issue with the infusion logic
      updateStepPreparationCall(payload);

      // NOTE: moved to the updateStepAssessmentCall callback
      // update stepper progress checkmark
      // const stepIsValid = getStepStatus(infusionSteps.PREP, payload);
      // updateStepper(stepIsValid);
    }
    if (currentStep === 5 && payload.nursingProcessId) {
      // const infusionNewData = {
      //   ...infusion,
      //   currentStep: 6,
      //   updateStepAdministrationInput: { ...payload, notes: narrativeNotes }
      // };
      // localStorage.setItem("infusion", JSON.stringify(infusionNewData));
      // setInfusion(infusionNewData);
      // setCurrentStep(6);
      // setStepOrder(6);

      // NOTE: all logic above went to the following API callback
      // It allows to fix the critical issue with the infusion logic
      updateStepAdministrationCall(payload);

      // NOTE: moved to the updateStepAssessmentCall callback
      // update stepper progress checkmark
      // const stepIsValid = getStepStatus(infusionSteps.ADMIN, payload);
      // updateStepper(stepIsValid);
    }
    if (currentStep === 6 && payload.nursingProcessId) {
      // alert(`PAYLOAD RECEIVED: currentStep ${currentStep}`)
      // updateStepCloseTreatmentCall(payload)
      const infusionNewData = {
        ...infusion,
        currentStep: 7,
        updateStepCloseTreatmentInput: { ...payload }
      };
      // const infusionNewData = infusion;
      // infusionNewData.updateStepCloseTreatmentInput.nursingProcessId =
      //   payload.nursingProcessId;
      // infusionNewData.updateStepCloseTreatmentInput.agentId = payload.agentId;
      // infusionNewData.updateStepCloseTreatmentInput.notes = payload.notes;
      // infusionNewData.updateStepCloseTreatmentInput.departureVital =
      //   payload.departureVital;
      // infusionNewData.updateStepCloseTreatmentInput.departureTime =
      //   payload.departureTime;
      // infusionNewData.updateStepCloseTreatmentInput.closeTreatmentNote =
      //   payload.closeTreatmentNote;
      // infusionNewData.updateStepCloseTreatmentInput.signature =
      //   payload.signature;
      // infusionNewData.updateStepCloseTreatmentInput.password = payload.password;
      // infusionNewData.currentStep = 6;
      localStorage.setItem('infusion', JSON.stringify(infusionNewData));
      setInfusion(infusionNewData);
      setCurrentStep(7);
      // setStepOrder(7);

      // NOTE: all logic above went to the following API callback
      // It allows to fix the critical issue with the infusion logic
      updateStepCloseTreatmentCall(payload);

      // NOTE: moved to the updateStepAssessmentCall callback
      // update stepper progress checkmark
      // const stepIsValid = getStepStatus(infusionSteps.CLOSE_TREATMENT, payload);
      // updateStepper(stepIsValid);
    }
    if (currentStep === 7) {
      alert('INFUSION COMPLETE!');
    }
  };

  const updateStepOrderReviewCall = async requestObject => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: updateStepOrderReview,
        variables: { input: requestObject }
      });
      if (data && data.data && data.data.updateStepOrderReview) {
        setStepOrder(1);
        setCurrentStep(2);

        const infusionNewData = infusion;
        infusionNewData.updateStepOrderReviewResponse =
          data.data.updateStepOrderReview;
        infusionNewData.updateStepAssessmentInput.nursingProcessId =
          data.data.updateStepOrderReview.nursingProcessId;
        infusionNewData.currentStep = 2;

        setInfusion(infusionNewData);
        localStorage.setItem('infusion', JSON.stringify(infusionNewData));
      }
    } catch (err) {
      console.log('marty updateStepOrderReviewCall err', err);
      alert('marty updateStepOrderReviewCall err');
    }
  };

  const updateStepAssessmentCall = async requestObject => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: updateStepAssessment,
        variables: { input: requestObject }
      });

      if (data && data.data && data.data.updateStepAssessment) {
        setStepOrder(2);
        setCurrentStep(3);

        const infusionNewData = infusion;
        infusionNewData.updateStepAssessmentResponse =
          data.data.updateStepAssessment;
        infusionNewData.currentStep = 3;
        infusionNewData.updateStepAssessmentInput = requestObject;

        setInfusion(infusionNewData);
        localStorage.setItem('infusion', JSON.stringify(infusionNewData));

        // update stepper progress checkmark
        const stepIsValid = getStepStatus(
          infusionSteps.ASSESSMENT,
          requestObject
        );
        updateStepper(stepIsValid);
      }
    } catch (err) {
      console.log('marty updateStepAssessmentCall err', err);
    }
  };

  const updateStepPreTreatmentCall = async requestObject => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: updateStepPreTreatment,
        variables: { input: requestObject }
      });
      if (data && data.data && data.data.updateStepPreTreatment) {
        setStepOrder(3);
        setCurrentStep(4);

        const infusionNewData = infusion;
        infusionNewData.updateStepPreTreatmentResponse =
          data.data.updateStepPreTreatment;
        infusionNewData.currentStep = 4;
        infusionNewData.updateStepPreTreatmentInput = requestObject;

        setInfusion(infusionNewData);
        localStorage.setItem('infusion', JSON.stringify(infusionNewData));

        // update stepper progress checkmark
        const stepIsValid = getStepStatus(
          infusionSteps.PRE_TREATMENT,
          requestObject
        );
        updateStepper(stepIsValid);
      }
    } catch (err) {
      console.log('marty updateStepPreTreatmentCall err', err);
    }
  };

  const updateStepPreparationCall = async requestObject => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: updateStepPreparation,
        variables: { input: requestObject }
      });
      if (data && data.data && data.data.updateStepPreparation) {
        setStepOrder(4);
        setCurrentStep(5);

        const infusionNewData = infusion;
        infusionNewData.updateStepPreparationResponse =
          data.data.updateStepPreparation;
        infusionNewData.currentStep = 5;
        infusionNewData.updateStepPreparationInput = requestObject;

        setInfusion(infusionNewData);
        localStorage.setItem('infusion', JSON.stringify(infusionNewData));

        // update stepper progress checkmark
        const stepIsValid = getStepStatus(infusionSteps.PREP, requestObject);
        updateStepper(stepIsValid);
      }
    } catch (err) {
      console.log('marty updateStepPreparationCall err', err);
    }
  };

  const updateStepAdministrationCall = async requestObject => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: updateStepAdministration,
        variables: { input: requestObject }
      });
      if (data && data.data && data.data.updateStepAdministration) {
        setStepOrder(5);
        setCurrentStep(6);

        const infusionNewData = infusion;
        infusionNewData.updateStepAdministrationResponse =
          data.data.updateStepAdministration;
        infusionNewData.currentStep = 6;
        infusionNewData.updateStepAdministrationInput = requestObject;

        setInfusion(infusionNewData);
        localStorage.setItem('infusion', JSON.stringify(infusionNewData));

        // update stepper progress checkmark
        const stepIsValid = getStepStatus(infusionSteps.ADMIN, requestObject);
        updateStepper(stepIsValid);
      }
    } catch (err) {
      console.log('marty updateStepAdministrationCall err', err);
    }
  };

  const updateStepCloseTreatmentCall = async requestObject => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: updateStepCloseTreatment,
        variables: { input: requestObject }
      });

      if (data && data.data && data.data.updateStepCloseTreatment) {
        setStepOrder(6);
        setCurrentStep(7);

        const infusionNewData = infusion;
        infusionNewData.updateStepCloseTreatmentResponse =
          data.data.updateStepCloseTreatment;
        infusionNewData.currentStep = 7;
        infusionNewData.updateStepCloseTreatmentInput = requestObject;

        setInfusion(infusionNewData);
        localStorage.setItem('infusion', JSON.stringify(infusionNewData));

        // update stepper progress checkmark
        const stepIsValid = getStepStatus(
          infusionSteps.CLOSE_TREATMENT,
          requestObject
        );
        updateStepper(stepIsValid);

        if (data.data.updateStepCloseTreatment.statusCode) {
          if (data.data.updateStepCloseTreatment.statusCode === '200') {
            localStorage.removeItem('narrativeNotes');

            // redirect
            setTimeout(() => {
              history.push('/infusion-portal', {
                searchType: 'treatmentHistory',
                generateFax: props.nursingProcess.faxRequired, // need for Faxing
                treatmentId: props.nursingProcess.id // need for Faxing
              });
            }, 500);
          } else if (data.data.updateStepCloseTreatment.statusCode !== '200') {
            showError(data?.data?.updateStepCloseTreatment?.message);
          }
        }
      }
    } catch (err) {
      alert('Infusion::updateStepCloseTreatmentCall err');
    }
  };

  // const updateBucketAllergiesCall = async requestObject => {
  //   try {
  //     // get current refOrders
  //     const data = await connectToGraphqlAPI({
  //       graphqlQuery: getPatientReferralOrders,
  //       variables: { patientId: selectedPatientInfo.patientId }
  //     });

  //     if (
  //       data &&
  //       data.data &&
  //       data.data.getPatientBucket &&
  //       data.data.getPatientBucket.referral &&
  //       data.data.getPatientBucket.referral.drugReferrals &&
  //       data.data.getPatientBucket.referral.drugReferrals.length
  //     ) {
  //       const { allergies, referralId } = requestObject;

  //       // synch allergies on selected referral
  //       const selectedReferral =
  //         data.data.getPatientBucket.referral.drugReferrals.find(
  //           referral => referral.referralId === referralId
  //         );

  //       if (selectedReferral) {
  //         selectedReferral.orderNotes = {
  //           ...selectedReferral.orderNotes,
  //           allergies
  //         };

  //         // update bucket referral by refId
  //         const reqBody = {
  //           agentId: user.username,
  //           patientId: selectedPatientInfo.patientId,
  //           referral: {
  //             drugReferrals: data.data.getPatientBucket.referral.drugReferrals
  //           }
  //         };

  //         await connectToGraphqlAPI({
  //           graphqlQuery: updateReferralOrder,
  //           variables: { input: reqBody }
  //         });
  //       }
  //     }
  //   } catch (err) {
  //     console.log("ilia updateBucketAllergiesCall err", err);
  //   }
  // };

  const REASONS = [
    { value: 'Abnormal Vital Signs' },
    { value: 'Damaged Medication' },
    { value: 'Home Medication Contraindication' },
    { value: 'No Meds Administered' },
    { value: 'Order Not Approved' },
    { value: 'Patient Changed Mind' },
    { value: 'Unable to Obtain IV Access' }
  ];

  const handleChange = e => {
    setStepOrder(e.value);
    setCurrentStep(Math.round(e.value + 1));
  };

  const toggleNarrativeDialog = () => {
    setShowNarrativeDialog(!showNarrativeDialog);
  };

  const toggleCancelInfusionDialog = () => {
    setShowCancelInfusionDialog(!showCancelInfusionDialog);
  };

  const handleCancel = dataItem => {
    const requestObject = {
      // id: ID!
      nursingProcessId:
        headerDetailsData?.eventInfo?.tNPID?.id ||
        headerDetailsData?.nursingProcessInfo,
      // agentId: ID!
      agentId: agent?.agentId,
      // reason: String!
      reason: dataItem.reasonCancelled.value,
      // notes: String
      notes: dataItem.cancelNotes ? dataItem.cancelNotes : ''
    };

    cancelTreatmentCall(requestObject);
  };

  const cancelTreatmentCall = async requestObject => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: cancelTreatment,
        variables: { input: requestObject }
      });
      if (
        data &&
        data.data &&
        data.data.cancelTreatment &&
        data.data.cancelTreatment.statusCode
      ) {
        if (data.data.cancelTreatment.statusCode === '200') {
          toggleCancelInfusionDialog();
          localStorage.removeItem('narrativeNotes');

          setDialogOption({
            title: 'Infusion: Cancel Infusion',
            message: 'Infusion was cancelled',
            showDialog: true
          });
          history.push('/infusion-portal', { searchType: 'treatmentHistory' });
        } else {
          setDialogOption({
            title: 'Infusion: Cancel Infusion',
            message: 'Infusion can not be cancelled (bad statusCode returned)',
            showDialog: true
          });
        }
      } else {
        setDialogOption({
          title: 'Infusion: Cancel Infusion',
          message: 'Infusion can not be cancelled (no statusCode provided)',
          showDialog: true
        });
      }
    } catch (err) {
      console.log('cancelTreatmentCall err', err);
      setDialogOption({
        title: 'Infusion: Cancel Infusion',
        message: 'Error: cancelTreatmentCall',
        showDialog: true
      });
    }
  };

  const updateNotesCall = async () => {
    const title = 'Infusion: Narrative Notes';
    const showDialog = true;

    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: updateNarrativeNotes,
        variables: {
          agentId: agent?.agentId,
          notes: narrativeNotes,
          nursingProcessId
        }
      });

      if (
        data &&
        data.data &&
        data.data.addUpdateNursingNotes &&
        data.data.addUpdateNursingNotes.statusCode
      ) {
        if (data.data.addUpdateNursingNotes.statusCode === '200') {
          toggleNarrativeDialog();
          setDialogOption({
            title,
            message: 'Narrative Notes updated successfully.',
            showDialog
          });
          // push to local cache so reloads correctly when navigating infusion tabs
          const notes = new Array();
          notes.push(narrativeNotes);
          props.setNursingProcess({ ...props.nursingProcess, notes });
        } else {
          setDialogOption({
            title,
            message: 'Narrative Notes can not be updated now.',
            showDialog
          });
        }
      } else {
        setDialogOption({
          title,
          message:
            'Narrative Notes can not be updated now (no statusCode provided).',
          showDialog
        });
      }
    } catch (err) {
      console.log('updateNotesCall err', err);
      setDialogOption({
        title,
        message: 'Error: updateNotesCall failed',
        showDialog
      });
    }
  };

  const toggleShowPatientDocDialog = () => {
    setShowPatientDocDialog(!showPatientDocDialog);
  };

  const onDocumentClick = async () => {
    const conf = { download: false };
    const s3ImageURL = await Storage.get(rxOrderFile, conf);
    setSelectedDocumentUrl(s3ImageURL);
    setShowPatientDocDialog(true);
  };

  const renderStep = () => {
    const generalProps = {
      nursingProcessId,
      infusion,
      infusionFormData,
      showInfusionForm,
      selectedPatientInfo,
      listProductsByNameData,
      sendDataToParent,
      infusionCompleted,
      infusionRestricted
    };

    if (stepOrder === 0) {
      return <OrderReview {...generalProps} />;
    } if (stepOrder === 1) {
      return <Assessment {...generalProps} />;
    } if (stepOrder === 2) {
      return <PreTreatment {...generalProps} />;
    } if (stepOrder === 3) {
      return (
        <Prep
          {...generalProps}
          headerDetailsData={headerDetailsData}
          adminNumber={adminNumber}
        />
      );
    } if (stepOrder === 4) {
      return <Admin {...generalProps} />;
    } if (stepOrder === 5) {
      return <CloseTreatment {...generalProps} />;
    }
  };

  const SendToInfusionQueue = () => {
    history.push('/infusion-queue');
  };

  return (
    <>
      {dialogOption.showDialog && <MessageDialog dialogOption={dialogOption} />}

      {/* {infusionCompleted && (
        <div style={{ color: "red" }}>
          You&apos;re revisiting already completed infusion. Some fields might
          be non-editable.
        </div>
      )} */}

      {showInfusionStepper && (
        <>
          <div className="row">
            <div className="col-9 mt-2 py-3">
              <Stepper
                value={stepOrder}
                onChange={handleChange}
                items={steps}
                successIcon="k-icon k-iconWhite k-i-check-circle"
                errorIcon="k-icon k-iconWhite k-i-close-outline"
              />
            </div>

            <div className="col-3 mt-2 " style={{ marginLeft: '-40px' }}>
              <div className="row">
                <div
                  className="col-12 mt-06" /* style={{ marginLeft: "-16px" }} */
                >
                  <button
                    type="button"
                    className="k-button"
                    style={{ minWidth: 140 }}
                    onClick={SendToInfusionQueue}
                  >
                    <i className="fa-solid fa-display-medical icon-blue">
                      &nbsp;&nbsp;
                    </i>
                    Infusion Queue
                  </button>
                  <button
                    type="button"
                    className="k-button  ml-3 "
                    style={{ minWidth: 140 }}
                    onClick={toggleNarrativeDialog}
                  >
                    <i className="fa-solid fa-notes-medical icon-blue">
                      &nbsp;&nbsp;
                    </i>
                    Narrative Notes
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-12 mt-06">
                  {rxOrderFile && (
                    <>
                      <button
                        type="button"
                        className="k-button "
                        style={{ minWidth: 140 }}
                        onClick={onDocumentClick}
                      >
                        <i
                          style={{ marginLeft: '-17px' }}
                          className=" fa-solid fa-file-prescription icon-blue"
                        >
                          &nbsp;&nbsp;
                        </i>
                        RX Order File
                      </button>
                    </>
                  )}
                  {!infusionCompleted && rxOrderFile && (
                    <>
                      <button
                        type="button"
                        className="k-button ml-3"
                        style={{ minWidth: 140 }}
                        onClick={toggleCancelInfusionDialog}
                      >
                        <i className="fa-solid fa-xmark-large icon-blue">
                          &nbsp;&nbsp;
                        </i>
                        Cancel Infusion
                      </button>
                    </>
                  )}
                  {!infusionCompleted && !rxOrderFile && (
                    <>
                      <button
                        type="button"
                        className="k-button"
                        style={{ minWidth: 140 }}
                        onClick={toggleCancelInfusionDialog}
                      >
                        <i className="fa-solid fa-xmark-large icon-blue">
                          &nbsp;&nbsp;
                        </i>
                        Cancel Infusion
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          {showInfusionForm && (
            <div className="row">
              <div className="col-12 pt-3 ">{renderStep()}</div>
            </div>
          )}

          <WindowDialog
            title="Cancel Infusion"
            width={550}
            initialTop={50}
            height={460}
            onClose={toggleCancelInfusionDialog}
            showDialog={showCancelInfusionDialog}
          >
            <Form
              onSubmit={handleCancel}
              render={formRenderProps => (
                <form
                  onSubmit={formRenderProps.onSubmit}
                  className="k-form pl-3 pr-3 pt-1"
                >
                  <div className="row">
                    <div className="col-md-3 mt-2 pl-4">Reason:</div>
                    <div className="col-8">
                      <Field
                        data={REASONS}
                        component={DropDownList}
                        valueField="value"
                        textField="value"
                        style={{ width: '100%' }}
                        name="reasonCancelled"
                      />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col">
                      <Alert>
                        Reminder: Please add Close Treatment Notes
                      </Alert>
                    </div>
                  </div>
                  {/*  <div className="row">
                      <div className="col-md-11 mt-16">
                        <Field
                          component={TextArea}
                          style={{ width: "100%", height: "250px" }}
                          autoSize={true}
                          defaultValue="Cancel Treatment Notes"
                          name={"cancelNotes"}
                        />
                      </div>
                    </div> */}
                  <div className="row">
                    <div className="col-md-12 mt-16">
                      <button
                        type="submit"
                        className="k-button k-primary mr-3 p-1"
                      >
                        Submit Cancellation
                      </button>
                      <button
                        onClick={toggleCancelInfusionDialog}
                        className="k-button pageButton"
                      >
                        Do Not Cancel
                      </button>
                    </div>
                  </div>
                </form>
                )}
            />
          </WindowDialog>
          <WindowDialog
            title="Narrative Notes"
            initialTop={50}
            width={950}
            height={550}
            showDialog={showNarrativeDialog}
            onClose={toggleNarrativeDialog}
          >
            <div className="row">
              <div className="col-md-11 mt-16">
                <TextArea
                  style={{ width: '100%', overflow: 'auto', height: '350px' }}
                  value={narrativeNotes}
                  onChange={e => setNarrativeNotes(e.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mt-16">
                <button
                  type="submit"
                  onClick={updateNotesCall}
                  className="k-button pageButton mr-3"
                >
                  Update
                </button>
              </div>
            </div>
          </WindowDialog>
          {showPatientDocDialog && (
            <Dialog
              title="Patient Document"
              height={1100}
              width={850}
              initialTop={100}
              showDialog
              onClose={toggleShowPatientDocDialog}
            >
              <PatientDocument file={selectedDocumentUrl} />
            </Dialog>
          )}
        </>
      )}
    </>
  );
};

export default Infusion;
