import React from 'react';
import { MapInteractionCSS } from 'react-map-interaction';

const Zoom = ({ children }) => {
  return (
    <MapInteractionCSS
      showControls
      defaultValue={{
        scale: 0.95,
        translation: { x: 0, y: 20 }
      }}
      minScale={0.5}
      maxScale={3}
      translationBounds={{
        xMax: 400,
        yMax: 200
      }}
      plusBtnContents={<i className="fa-solid fa-plus" />}
      minusBtnContents={<i className="fa-solid fa-minus" />}
    >
      {children}
    </MapInteractionCSS>
  );
};

export default Zoom;
