import React, { useContext, useState, useEffect } from 'react';

import { Form, Field } from '@progress/kendo-react-form';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { TextArea } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';

import { GridColumn as Column } from '@progress/kendo-react-grid/dist/npm/GridColumn';
import { Grid } from '@progress/kendo-react-grid';
import * as moment from 'moment';
import { orderBy } from '@progress/kendo-react-data-tools';
import { process } from '@progress/kendo-data-query';
import DeleteButton from '@/components/common-components/Form/DeleteButton';
import EditButton from '@/components/common-components/Form/EditButton';
import { isDateFromPast, toTimestamp } from '@/common/DateHelper';

import { connectToGraphqlAPI } from '@/provider';
import { addUpdateNotes } from '@/graphql/mutations';

import { UserContext } from '@/context/UserContext';

import { Constants } from '@/constants';
import { validateInput } from '@/common/Validation';
import { ColumnMenu } from '@/components/common-components/columnMenu';
import { noteTypes } from '@/constants/enum';
import { getNoteTypeTitle } from '@/common/Mappers';

const Notes = (props) => {
  const { selectedPatientInfo } = props;
  const initialDataState = {};
  const [sort, setSort] = useState([{ field: 'date', dir: 'dsc' }]);

  const { user } = useContext(UserContext);

  let theData = [];

  if (selectedPatientInfo.notes) {
    theData = selectedPatientInfo.notes
      .map((item) => ({
        ...item,
        displayDate: moment(item.date).format('MM/DD/YYYY @ hh:mm A'),
        date: new Date(item.date),
        timeStamp: toTimestamp(item.date)
      }))
      .sort((a, b) => (b.date - a.date ? 1 : -1));
  }
  // this method determines if any of the notes items are "today" so they can be edited or deleted
  const canEditDeleteItems = (dataArray) => {
    const canEditCount = dataArray.filter((item) => !isDateFromPast(item.date));
    return canEditCount.length > 0;
  };

  const [patientNotes, setPatientNotes] = useState(theData || []);

  const [showAction, setShowAction] = useState(canEditDeleteItems(theData)); // this state var determines of we need to show the "action" column in the popup

  const [showAddNewDialog, setShowAddNewDialog] = useState(false);

  const [showUpdateDialog, setShowUpdateDialog] = useState(false);

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const [selectedNoteItem, setSelectedNoteItem] = useState({});

  const [updateNoteType, setUpdateNoteType] = useState('');
  const [dataState, setDataState] = useState();
  const [resultState, setResultState] = useState(process(patientNotes, initialDataState));

  useEffect(() => {
    setResultState(process(patientNotes, initialDataState));
  }, [patientNotes]);

  const objUpdated =
    theData.find((obj) => obj.timeStamp === selectedNoteItem.timeStamp) ||
    patientNotes.find((obj) => obj.timeStamp === selectedNoteItem.timeStamp);

  const selectAction = (dataItem) => {
    if (
      !isDateFromPast(dataItem.dataItem.date) &&
      dataItem.dataItem.type !== 'OUTBOUND_CALL'
    ) {
      // if from today (not past days)
      return (
        <td>
          <div className="row">
            <div className="col-md-3">
              <EditButton
                handleClick={() => toggleUpdateNoteDialog(dataItem.dataItem)}
              />
            </div>
            <div>&nbsp;</div>
            <div className="col-md-3">
              <DeleteButton
                handleClick={() => toggleDeleteNoteDialog(dataItem.dataItem)}
              />
            </div>
          </div>
        </td>
      );
    }
    return <td>&nbsp;</td>;
  };

  const noteTypeCell = ({ dataItem }) => {
    return <td>{getNoteTypeTitle(dataItem?.type)}</td>;
  };

  const columns = [
    { field: 'displayDate', title: 'Date', width: '190px' },
    { field: '', title: 'Type/Category', cell: noteTypeCell, width: '200px' },
    { field: 'createdBy', title: 'Created By', width: '190px' },
    { field: 'note', title: 'Note', cssClass: 'notesWrapper', width: '300px' },
    showAction
      ? { field: '', title: 'Action', cell: selectAction, width: '150px' }
      : null // only show this column if there are items that can be edited/deleted
  ].filter(Boolean); // this filters out the "null" column if showAction is false

  const noteForm = {
    noteType: {
      value: updateNoteType,
      inputValidator: (value) => {
        return validateInput({
          referralId: { ...noteForm.noteType, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    },
    note: {
      value: selectedNoteItem?.note ? selectedNoteItem.note : '',
      inputValidator: (value) => {
        return validateInput({
          referralId: { ...noteForm.note, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    }
  };

  // const handleAddNote = dataItem => {
  //   const requestObject = {
  //     patientId: selectedPatientInfo.patientId,
  //     agentId: user.username,
  //     //notes: [NoteInput!]!
  //     notes: [
  //       {
  //         // date: AWSDateTime
  //         date: moment(new Date()), //.format("YYYY-MM-DDTHH:mm:ss.SSS"),
  //         // note: String!
  //         note: dataItem.note,
  //         // type: NoteType
  //         type: dataItem.noteType.value
  //         // drugName: String
  //         // labTest: String
  //         // labExpiration: AWSDate
  //       }
  //     ]
  //   };

  //   addUpdateNotesCall(requestObject);
  // };

  // const addUpdateNotesCall = async requestObject => {
  //   try {
  //     const data = await connectToGraphqlAPI({
  //       graphqlQuery: addUpdateNotes,
  //       variables: { input: requestObject }
  //     });

  //     if (data.data?.addUpdateNotes?.notes) {
  //       const theData = data.data.addUpdateNotes.notes
  //         .sort((a, b) => (b.date > a.date ? 1 : -1))
  //         .map(item => {
  //           return {
  //             ...item,
  //             displayDate: moment(new Date(item.date)).format("MM/DD/YYYY @ hh:mm A"),
  //             text: item.subject,
  //             value: item.id
  //           };
  //         });
  //       setPatientNotes(theData);
  //       toggleNewNoteDialog();
  //       props.sendDataToParent({});
  //     }
  //   } catch (err) {
  //     console.log("marty addUpdateNotesCall err", err);
  //   }
  // };

  const handleAddNote = (dataItem) => {
    const requestObject = {
      patientId: selectedPatientInfo.patientId,
      agentId: user.username,
      // notes: [NoteInput!]!
      notes: [
        // add NEW note
        {
          // date: AWSDateTime
          date: moment(new Date()), // .format("YYYY-MM-DDTHH:mm:ss.SSS"),
          // note: String!
          note: dataItem.note,
          // type: NoteType
          type: dataItem.noteType.value,
          // drugName: String
          // labTest: String
          // labExpiration: AWSDate
          createdBy: user.username,
          modifiedNote: false // send false to add new
        }
      ]
    };

    addUpdateNotesCall(requestObject);
  };

  const handleUpdateNote = (dataItem) => {
    if (objUpdated) {
      const requestObject = {
        patientId: selectedPatientInfo.patientId,
        agentId: user.username,
        // notes: [NoteInput!]!
        notes: [
          {
            // date: AWSDateTime
            date: objUpdated.date, // keep existing date
            // note: String!
            note: dataItem.note, // enter updated note value
            // type: NoteType
            type: objUpdated.type, // keep existing type
            // drugName: String
            // labTest: String
            // labExpiration: AWSDate
            createdBy: objUpdated.createdBy, // keep existing createdBy
            modifiedNote: true // send true with updated note text to update
          }
        ]
      };
      addUpdateNotesCall(requestObject);
    }
  };

  const handleDeleteNote = () => {
    if (objUpdated) {
      const requestObject = {
        patientId: selectedPatientInfo.patientId,
        agentId: user.username,
        // notes: [NoteInput!]!
        notes: [
          {
            // date: AWSDateTime
            date: objUpdated.date, // keep existing date
            // note: String!
            note: '', // enter empty string to signal delete value
            // type: NoteType
            type: objUpdated.type, // keep existing type
            // drugName: String
            // labTest: String
            // labExpiration: AWSDate
            createdBy: objUpdated.createdBy, // keep existing createdBy
            modifiedNote: true // send true with empty text to delete
          }
        ]
      };
      addUpdateNotesCall(requestObject);
    }
  };

  const addUpdateNotesCall = async (requestObject) => {
    try {
      const data = await connectToGraphqlAPI({
        // graphqlQuery: updatePatientBucketNotes,
        graphqlQuery: addUpdateNotes,
        variables: { input: requestObject }
      });

      if (data.data?.addUpdateNotes?.notes) {
        const theData = data.data.addUpdateNotes.notes
          .map((item) => {
            return {
              ...item,
              displayDate: moment(item.date).format('MM/DD/YYYY @ hh:mm A'),
              text: item.subject,
              value: item.id,
              date: new Date(item.date),
              timeStamp: toTimestamp(item.date)
            };
          })
          .sort((a, b) => (b.date - a.date ? 1 : -1));
        setPatientNotes(theData);
        setShowAction(canEditDeleteItems(theData)); // update if Action column is visible
        if (showAddNewDialog) {
          toggleNewNoteDialog();
        } else if (showUpdateDialog) {
          toggleUpdateNoteDialog();
        } else if (showDeleteDialog) {
          toggleDeleteNoteDialog();
        }
        props.sendDataToParent({});
      }
    } catch (err) {
      console.error('Notes::addUpdateNotesCall err: ', err);
    }
  };

  const toggleNewNoteDialog = () => {
    setShowAddNewDialog(!showAddNewDialog);
  };

  const toggleUpdateNoteDialog = (dataItem) => {
    if (!showUpdateDialog) {
      // set cuz opening dialog
      setSelectedNoteItem(dataItem);

      // locate assignTo in listItem
      const updateTypeItem = noteTypes.find((itm) => itm.value === dataItem.type);
      setUpdateNoteType(updateTypeItem);
    } else {
      // else clear cuz closing dialog
      setSelectedNoteItem({});
      setUpdateNoteType('');
    }
    setShowUpdateDialog(!showUpdateDialog);
  };

  const toggleDeleteNoteDialog = (dataItem) => {
    if (!showDeleteDialog) {
      // set cuz opening dialog
      setSelectedNoteItem(dataItem);

      // locate assignTo in listItem
      const updateTypeItem = noteTypes.find((itm) => itm.value === dataItem.type);
      setUpdateNoteType(updateTypeItem);
    } else {
      // else clear cuz closing dialog
      setSelectedNoteItem({});
      setUpdateNoteType('');
    }
    setShowDeleteDialog(!showDeleteDialog);
  };

  const initialForm = () => {
    const initialObject = {};
    Object.keys(noteForm).forEach((key) => {
      initialObject[key] = noteForm[key].value;
    });
    return initialObject;
  };
  const onDataStateChange = React.useCallback(
    (e) => {
      setDataState(e.dataState); // store for use
      setResultState(process(patientNotes, e.dataState));
    },
    [patientNotes]
  );

  return (
    <>
      <div className="row">
        <div className="col">
          <div className="row">
            <div className="col-12">
              <div className="row justify-content-end grid-heading mb-2 pr-5">
                <button
                  type="button"
                  className="k-button"
                  onClick={toggleNewNoteDialog}
                >
                  New Note
                </button>
              </div>
              <div className="container-fluid">
                <div className="row my-2 justify-content-center">
                  <div className="col-md-12">
                    <Grid
                      className="a-grid"
                      data={orderBy(resultState.data, sort)}
                      sort={sort}
                      sortable
                      onSortChange={(e) => {
                        const thisSort = [
                          {
                            dir: e.sort[0].dir,
                            field:
                              e.sort[0].field === 'displayDate'
                                ? 'date'
                                : 'displayDate'
                          }
                        ];
                        setSort(thisSort);
                      }}
                      onDataStateChange={onDataStateChange}
                      {...dataState}
                    >
                      {columns.map((column) => {
                        return (
                          <Column
                            key={`notes-column-${column.field}`}
                            field={column.field}
                            title={column.title}
                            width={column.width}
                            cell={column.cell}
                            className={column.cssClass}
                            columnMenu={ColumnMenu}
                          />
                        );
                      })}
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showAddNewDialog && (
        <Dialog
          title="Add New Note"
          width={1000}
          onClose={toggleNewNoteDialog}
          showDialog
        >
          <Form
            onSubmit={handleAddNote}
            render={(formRenderProps) => (
              <form
                onSubmit={formRenderProps.onSubmit}
                className="k-form pl-3 pr-3 pt-1"
              >
                <div className="row">
                  <div className="col-md-3 ">Type/Category:</div>
                  <div className="col-md-9">
                    <Field
                      name="noteType"
                      component={DropDownList}
                      data={noteTypes}
                      textField="text"
                      valueField="value"
                      defaultValue="Intake"
                      validator={noteForm.noteType.inputValidator}
                    />
                  </div>
                </div>
                <div className="row" style={{ marginTop: '1.0rem' }}>
                  <div className="col-md-12 ">
                    <Field
                      component={TextArea}
                      name="note"
                      style={{ width: '100%', minHeight: '250px' }}
                      autoSize
                    />
                  </div>
                </div>
                <div className="row" style={{ marginTop: '1.0rem' }}>
                  <div className="col my-3 offset-5">
                    <Button type="submit" title="Add Note">
                      Add Note
                    </Button>
                  </div>
                </div>
              </form>
            )}
          />
        </Dialog>
      )}
      {showUpdateDialog && (
        <Dialog
          title="Update Note"
          width={1000}
          onClose={toggleUpdateNoteDialog}
          showDialog
        >
          <Form
            initialValues={initialForm()}
            onSubmit={handleUpdateNote}
            render={(formRenderProps) => (
              <form
                onSubmit={formRenderProps.onSubmit}
                className="k-form pl-3 pr-3 pt-1"
              >
                <div className="row">
                  <div className="col-md-3 ">Type/Category:</div>
                  <div className="col-md-9">
                    <Field
                      name="noteType"
                      component={DropDownList}
                      data={noteTypes}
                      textField="text"
                      valueField="value"
                      defaultValue="Intake"
                      validator={noteForm.noteType.inputValidator}
                      disabled // disabled on update
                    />
                  </div>
                </div>
                <div className="row" style={{ marginTop: '1.0rem' }}>
                  <div className="col-md-12 ">
                    <Field
                      component={TextArea}
                      name="note"
                      style={{ width: '100%', minHeight: '250px' }}
                      autoSize
                    />
                  </div>
                </div>
                <div className="row" style={{ marginTop: '1.0rem' }}>
                  <div className="col my-3 offset-5">
                    <Button type="submit" title="Add Note">
                      Update Note
                    </Button>
                  </div>
                </div>
              </form>
            )}
          />
        </Dialog>
      )}
      {showDeleteDialog && (
        <Dialog
          title="Delete Note"
          width={700}
          onClose={toggleDeleteNoteDialog}
          showDialog
        >
          <Form
            initialValues={initialForm()}
            onSubmit={handleDeleteNote}
            render={(formRenderProps) => (
              <form
                onSubmit={formRenderProps.onSubmit}
                className="k-form pl-3 pr-3 pt-1"
              >
                <div className="row">
                  <div className="col-md-3 ">Type/Category:</div>
                  <div className="col-md-9">
                    <Field
                      name="noteType"
                      component={DropDownList}
                      data={noteTypes}
                      textField="text"
                      valueField="value"
                      defaultValue="Intake"
                      validator={noteForm.noteType.inputValidator}
                      disabled
                    />
                  </div>
                </div>
                <div className="row" style={{ marginTop: '1.0rem' }}>
                  <div className="col-md-12 ">
                    <Field
                      component={TextArea}
                      name="note"
                      style={{ width: '100%', minHeight: '250px' }}
                      autoSize
                      disabled
                    />
                  </div>
                </div>
                <div className="row" style={{ marginTop: '1.0rem' }}>
                  <div className="col my-3 offset-5">
                    <Button
                      // type="submit"
                      title="Delete Note"
                      onClick={handleDeleteNote}
                    >
                      Delete Note
                    </Button>
                  </div>
                </div>
              </form>
            )}
          />
        </Dialog>
      )}
    </>
  );
};

export default Notes;
