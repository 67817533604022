// Util
import { capitalizeFirstWord } from '@/common/Converters';
import { stateAbbreviations } from '@/common/states';

export const capitalizeSalesTerritories = (str) => {
  return str
    .split(' ')
    .map((word) => {
      // If the word is a state abbreviation, return it in uppercase
      if (stateAbbreviations.includes(word.toUpperCase())) {
        return word.toUpperCase();
      }
      // Otherwise, capitalize the first letter
      return capitalizeFirstWord(word);
    })
    .join(' ');
};
