import React, { useContext } from 'react';
import Drawer from 'rc-drawer';
import styled from 'styled-components';

// context
import { LocationContext } from '@/context';

// components
import Preloader from '@/components/common-components/Preloader';
import AwesomeLabel from '@/components/common-components/AwesomeLabel';
import DrawerHeaderItem from '@/components/WorkItemLogDrawer/DrawerHeaderItem';

// utils
import { utc2local } from '@/common/DateHelper';

const DrawerContent = styled.div`
  padding: 15px;
`;

const LogRecord = styled.li`
  padding: 10px 0;
`;

const LogList = styled.ul`
  padding-inline-start: 20px;

  div {
    padding-top: 10px;
  }
`;

const LocationHistoryLogDrawer = () => {
  const { showLocationHistory, setShowLocationHistory, loading, inventoryLog } =
    useContext(LocationContext);

  const renderLogRecord = (logRecord, index) => {
    const elKey = `log-item-${index}`;
    const hasNotes = logRecord.notes && logRecord.notes !== '';
    const when = utc2local(logRecord.updatedAt);

    const renderNotes = (notes) => {
      const [mainCat, subCat, subNote] = notes.split(';');

      return (
        <>
          {mainCat}
          {subCat && <> &rarr; {subCat}</>}
          {subNote && <> &rarr; {subNote}</>}
        </>
      );
    };

    return (
      <LogRecord key={elKey}>
        <div>
          <small>
            <AwesomeLabel
              icon='box-circle-check'
              label={
                <>
                  <b>Qty change: </b>
                  {logRecord.beforeQuantity} &rarr; {logRecord.afterQuantity}
                </>
              }
            />
          </small>
        </div>
        {hasNotes && (
          <div>
            <small>
              <AwesomeLabel
                icon='notes'
                label={
                  <>
                    <b>Reason: </b>
                    {renderNotes(logRecord.notes)}
                  </>
                }
              />
            </small>
          </div>
        )}
        <div>
          <small>
            <AwesomeLabel icon='calendar' label={when} />
          </small>
        </div>

        <DrawerHeaderItem
          icon='user'
          label='Updated By'
          value={logRecord.updatedBy}
        />
      </LogRecord>
    );
  };

  return (
    <Drawer
      open={showLocationHistory}
      onClose={() => setShowLocationHistory(false)}
    >
      <DrawerContent>
        <h5>Inventory Item Log</h5>
        <Preloader show={loading}>
          <LogList>
            {inventoryLog?.map((logRecord, i) => renderLogRecord(logRecord, i))}
          </LogList>
          {inventoryLog?.length === 0 && <p>No history records found.</p>}
        </Preloader>
      </DrawerContent>
    </Drawer>
  );
};

export default LocationHistoryLogDrawer;

DrawerContent.displayName = 'DrawerContent';
LogRecord.displayName = 'LogRecord';
LogList.displayName = 'LogList';
