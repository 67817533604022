import React, { useContext } from 'react';

// components
import ReturnItem from './ItemActions/ReturnItem';
import ArchiveItem from './ItemActions/ArchiveItem';
import ReturnToQueue from './ItemActions/ReturnToQueue';
import CreateWorkItem from './ItemActions/CreateWorkItem';
import CompleteWorkItem from './ItemActions/CompleteWorkItem';
import CancelItem from './ItemActions/CancelItem';
// context
import { WorkItemContext } from '@/context/WorkItemContext';
// utils
import { isWorkTypeSupported } from '@/common/Mappers';

const CurrentWorkItemActions = () => {
  const { selectedWorkItem, isParentWorkItem, patientWorkItems } =
    useContext(WorkItemContext);

  if (!selectedWorkItem || !selectedWorkItem.id) return null;
  const isParent = isParentWorkItem(selectedWorkItem.id);
  const buttonItems = [ReturnItem, CreateWorkItem, ReturnToQueue];
  if (!isParent) {
    buttonItems.push(ArchiveItem, CancelItem, CompleteWorkItem);
  }
  const setActionsList = () => {
    let items = [];
    if (isWorkTypeSupported(selectedWorkItem.work)) {
      items = buttonItems;
    }
    return items;
  };

  const actionsList = setActionsList();

  return (
    <>
      {patientWorkItems && (
        <>
          {actionsList.map((ActionComponent) => (
            // key added to fix red console error
            <ActionComponent key={ActionComponent.name} />
          ))}
        </>
      )}
    </>
  );
};

export default CurrentWorkItemActions;
