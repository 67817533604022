import React, { useState, useContext, useCallback } from 'react';
import pluralize from 'pluralize';
import Papa from 'papaparse';

// kendo
import { Dialog } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';

// utils
import { formatDateToAWS, formatDateToAWSDateTime } from '@/common/DateHelper';

// context
import { UserContext, NotifContext, ProductContext } from '@/context';

// components
import FishbowlPreviewTable from './FishbowlPreviewTable';
import AwesomeLabel from '@/components/common-components/AwesomeLabel';
import Alert from '@/components/common-components/Alert';

// gql
import { connectToGraphqlAPI } from '@/provider';
import { addProductsToInventory } from '@/graphql/mutations';
import { parseSerialNumbers } from './FishbowlImport.utils';

const FishbowlImport = (props) => {
  const { user } = useContext(UserContext);
  const { getProductById } = useContext(ProductContext);
  const [importData, setImportData] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [unknownProducts, setUnknownProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const { showSuccess, showError } = useContext(NotifContext);

  const onCloseHandler = useCallback(() => {
    if (!loading) {
      setImportData([]);
      setUnknownProducts([]);
      setShowAlert(false);
      props.onToggle();
    }
  });

  const changeHandler = (event) => {
    setShowAlert(false);

    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        const parsedData = parseSerialNumbers(results.data);

        const hasValidFormat = parsedData.every((item) =>
          Object.prototype.hasOwnProperty.call(item, 'Tracking-Expiration Date')
        );

        if (!hasValidFormat) {
          return setShowAlert(true);
        }

        const productsForImport = [];
        const badProducts = [];

        parsedData.forEach((item) => {
          const dbProduct = getProductById(item.PartNumber);
          const knownProduct = dbProduct ? true : false;
          const productName = knownProduct
            ? dbProduct.productName
            : item.PartDescription;
          const productNameDetailed = knownProduct
            ? dbProduct.selectorText
            : item.PartDescription;
          const strength = knownProduct ? dbProduct.strength : 0;
          const unitOfMeas = knownProduct
            ? dbProduct.unitOfMeas
            : item.ShipItemUom;
          const vendor = knownProduct ? dbProduct.vendor : 'n/a';
          const price = knownProduct ? dbProduct.price : 0;
          const expirationDate = item['Tracking-Expiration Date'].trim();
          const serial = item.SerialNumber;

          const productItem = {
            lotNumber: item['Tracking-Lot Number'].trim(),
            ndcNumber: item.PartNumber,
            quantity: item.ShipItemQty,
            receivedTime: formatDateToAWSDateTime(),
            strength,
            unitOfMeas,
            expirationDate: formatDateToAWS(expirationDate),
            price,
            productName,
            productNameDetailed,
            vendor,
            freeDrug: false,
            serial
          };

          if (knownProduct) {
            productsForImport.push(productItem);
          } else {
            badProducts.push(productItem);
          }
        });

        setImportData(productsForImport);
        setUnknownProducts(badProducts);
      }
    });
  };

  const onSubmit = async () => {
    try {
      setLoading(true);

      const mappedProducts = importData.map((product) => {
        delete product.productNameDetailed;

        return product;
      });

      const pluralizedItems = pluralize('product', mappedProducts.length, true);

      await connectToGraphqlAPI({
        graphqlQuery: addProductsToInventory,
        variables: {
          agentId: user.username,
          locationId: props.locationId,
          products: [...mappedProducts]
        }
      }).then(() => {
        setLoading(false);

        showSuccess(
          `${pluralizedItems} successfully added to the inventory at ${props.location}`
        );

        props.addProductCallback(); // re-fetch data from inventory

        onCloseHandler(); // close modal and clean up local state
      });
    } catch (err) {
      showError('Error: check add products method at FishbowlImport component');
      console.error('FishbowlImport::onSubmit err: ', err);

      setLoading(false);
    }
  };

  if (!props.show) return null;

  const hasDataForImport = Boolean(importData && importData.length > 0);
  const hasUnknownProducts = Boolean(
    unknownProducts && unknownProducts.length > 0
  );

  const pluralizedItems = pluralize('item', importData.length, true);

  return (
    <Dialog
      title={`Import Products from Fishbowl —> ${props.location}`}
      width='auto'
      height='auto'
      initialTop={50}
      showDialog={true}
      onClose={onCloseHandler}
    >
      <>
        {showAlert ? (
          <div style={{ textAlign: 'center' }}>
            <Alert type='error'>
              Bad import format. Make sure you exported your data directly from
              the Fishbowl.
            </Alert>
          </div>
        ) : null}
        <input
          type='file'
          name='file'
          accept='.csv'
          onChange={changeHandler}
          style={{ display: 'block', margin: '10px auto' }}
        />
        {hasUnknownProducts ? (
          <>
            <hr />
            <FishbowlPreviewTable
              alertType='warning'
              tableData={unknownProducts}
            />
          </>
        ) : null}
        {hasDataForImport ? (
          <>
            <hr />
            <FishbowlPreviewTable alertType='info' tableData={importData} />
            <hr />
            <div>
              <Button
                onClick={onSubmit}
                className='blue mr-4'
                disabled={loading}
              >
                <AwesomeLabel
                  icon='file-import'
                  iconStyle='duotone'
                  label={`Import ${pluralizedItems}`}
                />
              </Button>
              <Button
                onClick={onCloseHandler}
                className='red'
                disabled={loading}
              >
                <AwesomeLabel icon='xmark' iconStyle='regular' label='Cancel' />
              </Button>
            </div>
          </>
        ) : null}
      </>
    </Dialog>
  );
};

export default FishbowlImport;
