import React, { useContext, useState } from 'react';

import { Form, FormElement, Field } from '@progress/kendo-react-form';
import { InputField, DatePickerFieldWithValidation } from '@/common/Validation';
import { formatDateToAWS } from '@/common/DateHelper';
import { Constants } from '@/constants';

import { PatientContext } from '@/context/PatientContext';
import { NotifContext } from '@/context/NotifContext';
import { InfusionContext } from '@/context/InfusionContext';

import FormButton from '@/components/common-components/Form/FormButton';

import { updatePatientBucket } from '@/graphql/mutations';
import { connectToGraphqlAPI } from '@/provider';

const PatientAllergiesAdd = () => {
  const { infusion, setInfusion } = useContext(InfusionContext);
  const { showError, showSuccess } = useContext(NotifContext);
  const { selectedPatientInfo, setSelectedPatientInfo } =
    useContext(PatientContext);

  const [isLoading, setLoading] = useState(false);

  const updateAllergiesCall = async (allergyInfo) => {
    try {
      setLoading(true);

      const patientProfile = {
        ...selectedPatientInfo.patientProfile,
        allergyInfo
      };
      const { patientId } = selectedPatientInfo;

      const data = await connectToGraphqlAPI({
        graphqlQuery: updatePatientBucket,
        variables: {
          input: { patientId, patientProfile }
        }
      });

      if (data.data?.updatePatientBucket) {
        showSuccess('Patient profile successfully updated');

        // additionally need to update infusion context
        // just in case there's an active infusion in progress
        if (infusion && infusion.nursingProcess) {
          const patientBucket = { ...infusion.patientBucket };
          patientBucket.patientProfile.allergyInfo = [...allergyInfo];
          setInfusion({ ...infusion, patientBucket });
        }
      } else {
        showError('Error in Patient Bucket update action. Please, check logs for details');
      }

      setLoading(false);
    } catch (err) {
      console.error('PatientAllergiesAdd::updateAllergiesCall err:', err);
      setLoading(false);
    }
  };

  const handleAddAllergies = (dataItem) => {
    const { allergyDate, allergyDrugName, allergyReaction } = dataItem;
    if (!allergyDate || !allergyDrugName || !allergyReaction) {
      return showError(Constants.ErrorMessage.allFields_REQUIRED);
    }

    const apiAllergy = {
      recordNumber: 1,
      entered: formatDateToAWS(dataItem.allergyDate),
      allergen: dataItem.allergyDrugName,
      reaction: dataItem.allergyReaction
    };

    try {
      const allergyInfo = selectedPatientInfo.patientProfile?.allergyInfo
        ? [...selectedPatientInfo.patientProfile.allergyInfo, apiAllergy]
        : [apiAllergy];

      // update patient info (UI)
      setSelectedPatientInfo({
        ...selectedPatientInfo,
        patientProfile: {
          ...selectedPatientInfo.patientProfile,
          allergyInfo
        }
      });

      // update patient info (API)
      updateAllergiesCall(allergyInfo);
    } catch (err) {
      console.error('PatientAllergiesAdd::handleAddAllergies err: ', err);
      showError('Error: handleAddAllergiesAdd');
    }
  };

  return (
    <Form
      onSubmit={handleAddAllergies}
      render={(formRenderProps) => (
        <form onSubmit={formRenderProps.onSubmit}>
          <div className="mb-3">
            <div className="row">
              <div className="col-md-6">
                <Field
                  name="allergyDrugName"
                  // label="Drug Name"
                  label="Allergy"
                  component={InputField}
                />
              </div>
              <div className="col-md-6">
                <Field
                  name="allergyDate"
                  label="Allergy Date"
                  component={DatePickerFieldWithValidation}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Field
                  name="allergyReaction"
                  label="Reaction"
                  component={InputField}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-1 mt-12">
                <FormButton
                  type="submit"
                  className="k-button blue"
                  loading={isLoading}
                  label="ADD"
                  disabled={!formRenderProps.allowSubmit}
                />
              </div>
            </div>
          </div>
        </form>
      )}
    />
  );
};

export default PatientAllergiesAdd;
