import React from "react";
import { PropTypes } from "prop-types";


export const InsuranceDetails = ({ insuranceTypeName, details }) => {
    if (!details) {
        return null;
    }


    const {
        groupId,
        insurerId,
        insurerName,
        binNumber,
        policyId,
        planName,
        coveredBy,
        customerServicePhone,
        email,
        firstName,
        hasPharmacyBenefits,
        insuranceExpireDate,
        lastName,
        payerId,
        pcnNumber,
        pharmacyPhone,
        relationship,
        rxGroupNumber,
        rxPlanName,
        state,
    } = details;

    return (
        <>
            <div className="row mb-4" style={{ backgroundColor: "#e2e1e1" }}>
                <b className="ml-3">{insuranceTypeName}-Plan Information:</b>
            </div>
            {/* <div className="row" className="billing-spacing"><span className="billing-style">Name:</span>&nbsp;{firstName || "-"} {lastName || "-"} </div> */}
            <div className="row" className="billing-spacing"><span className="billing-style">Ins. Plan Name:</span>&nbsp;{planName || "-"} </div>
            <div className="row" className="billing-spacing"><span className="billing-style">Policy ID:</span>&nbsp; {policyId || "-"}</div>
            <div className="row" className="billing-spacing"><span className="billing-style">Grp ID::</span>&nbsp; {groupId || "-"} </div>
            <div className="row" className="billing-spacing"><span className="billing-style">Insurer Name:</span>&nbsp;{insurerName || "-"} </div>
            <div className="row" className="billing-spacing"><span className="billing-style">Customer Service:</span>&nbsp;{customerServicePhone || "-"} </div>
        </>
    );
};


InsuranceDetails.propTypes = {
    insuranceTypeName: PropTypes.string,
    details: PropTypes.object,
};
