import React from "react";

const GridRecordWithFooter = ({ mainText, footerText }) => {
  if (!mainText || !footerText) {
    return null;
  }

  return (
    <>
      {mainText}
      <br />
      <span className="location-time-label">{footerText}</span>
    </>
  );
};
export default GridRecordWithFooter;
