import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';

// Context
import { NotifContext, UserContext } from '@/context';

const PrivateRoute = ({ component: Component, roles, ...rest }) => {
  const { agent } = useContext(UserContext);
  const { showWarning } = useContext(NotifContext);

  // check if roles in both arrays have any intersections
  const hasMatch = (arr1, arr2) => arr1.some((r) => arr2.includes(r));

  const agentRoles = agent.roles || [];

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!agent && !agentRoles.length) {
          showWarning("You're not authorized in the application");

          // not logged in so redirect to login page with the return url
          return <Redirect to={{ pathname: '/' }} />;
        }

        // check if route restricted by role and agent has that role
        if (roles && !hasMatch(roles, agentRoles)) {
          showWarning("You're not authorized to use this app section");

          // role not authorised so redirect to home page
          return <Redirect to={{ pathname: '/' }} />;
        }

        // authorised so return component
        return <Component {...props} />;
      }}
    />
  );
};

export default PrivateRoute;
