import React, { useState, useEffect, useContext } from 'react';
import pluralize from 'pluralize';
import cx from 'clsx';

// kendo
import {
  Grid,
  GridColumn as Column,
  GridToolbar
} from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import { Button } from '@progress/kendo-react-buttons';
import {
  ExcelExport,
  ExcelExportColumn,
  ExcelExportColumnGroup
} from '@progress/kendo-react-excel-export';

// components
import { ColumnMenu } from '@/components/common-components/columnMenu';
import AwesomeLabel from '@/components/common-components/AwesomeLabel';
import Preloader from '@/components/common-components/Preloader';
import WorkStatusBadge from '@/components/common-components/WorkStatusBadge';
import SnoozeMenu from '@/components/Queue/SnoozeMenu/SnoozeMenu';
import PatientStatusBadge from '@/components/common-components/PatientStatusBadge';
import NewPatientBadge from '@/components/common-components/NewPatientBadge';
// context
import {
  QueueFilterContext,
  PatientContext,
  UserContext,
  WorkItemContext
} from '@/context';

// utils
import { formatDateTimeToDefault } from '@/common/DateHelper';
import { returnReasons } from '@/constants/dict';
import { isWorkTypeSupportedForSnooze } from '@/common/Mappers';
import { toProperCase } from '@/common/Converters';
import { getWorkItemTitle } from '@/components/Queue/FilterSystem/QueueFilters.utils';
import {
  convertToUS,
  reFormatUSPhoneNumber
} from '@/common/PhoneNumberConverter';
import { getExportLineStyleSave } from '@/common/exportHelper';
// styles
import styles from './MainQueue.module.scss';

const initialResultState = { data: [], total: 0 };

const MainQueue = ({ name }) => {
  const initialDataState = {
    skip: 0,
    take: 25
  };
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [dataState, setDataState] = useState(initialDataState);
  const [resultState, setResultState] = useState(initialResultState);
  const [visibleSnoozeDateDialog, setVisibleSnoozeDateDialog] = useState(false);
  const toggleSnoozeDateDialog = () => {
    setVisibleSnoozeDateDialog(!visibleSnoozeDateDialog);
  };
  const [currentSelRow, setCurrentSelRow] = useState({});
  // context
  const { queueData, setQueueData, workItemQueueSelection, isLoading } =
    useContext(QueueFilterContext);
  const { agentId, isAdmin } = useContext(UserContext);
  const { returnWorkItem } = useContext(WorkItemContext);
  const { goToProfile } = useContext(PatientContext);

  useEffect(() => {
    if (workItemQueueSelection) {
      setQueueData([]);
    }
  }, []);

  useEffect(() => {
    setResultState(process(queueData, dataState));
    if (queueData?.length > 0) {
      setIsDataLoaded(true);
    }
  }, [dataState, queueData]);

  const handleReleaseWork = (props) => {
    setQueueData([]);
    returnWorkItem(props, returnReasons.BY_ADMIN);
  };

  const handleSnoozeItemClick = (dataObject) => {
    toggleSnoozeDateDialog();
    setCurrentSelRow(dataObject);
  };

  const _export = React.useRef(null);

  const excelExport = () => {
    if (_export.current !== null) {
      getExportLineStyleSave(_export);
    }
  };

  const finalData = resultState.data.map((item) => {
    const prescriberLastName = toProperCase(item.prescriberLastName);
    const prescriberFirstName = toProperCase(item.prescriberFirstName);
    item.createdAt = formatDateTimeToDefault(item.createdAt);
    item.targetTime = formatDateTimeToDefault(item.targetTime);
    item.prescriber = prescriberLastName
      ? `${prescriberLastName}, ${prescriberFirstName}`
      : '';
    if (item?.attachedData?.patientHomePhoneNumber) {
      item.attachedData.patientHomePhoneNumber = reFormatUSPhoneNumber(
        convertToUS(item.attachedData.patientHomePhoneNumber)
      );
    }
    return item;
  });

  const patientNameCell = ({ dataItem }) => {
    const homePhone = dataItem?.attachedData?.patientHomePhoneNumber;
    const isNewPatient = !!dataItem?.attachedData?.isNewPatient;
    const state = dataItem?.patientState;
    return (
      <>
        <td>
          <NewPatientBadge show={isNewPatient} />
          <span
            className={styles.link}
            onClick={() => goToProfile(dataItem.patientId)}
          >
            {`${dataItem.patientLastName}, ${dataItem.patientFirstName}`}
          </span>
          {!!homePhone && (
            <>
              <br />
              <span>{homePhone}</span>
            </>
          )}
          {!!state && (
            <>
              <br />
              <span>{state}</span>
            </>
          )}
        </td>
      </>
    );
  };
  const patientIdCell = ({ dataItem }) => {
    const patientId = dataItem?.patientId;
    const patientStatus = dataItem?.patientStatus;
    return (
      <>
        <td>
          <PatientStatusBadge status={patientStatus} />
          <span>{patientId}</span>
        </td>
      </>
    );
  };

  const drugNameCell = ({ dataItem }) => {
    const productCategory = dataItem?.productCategory;
    return (
      <>
        <td>
          <span>{`${dataItem?.drugName || ''}`}</span>
          {!!productCategory && (
            <>
              <br />
              <span>{productCategory}</span>
            </>
          )}
        </td>
      </>
    );
  };

  const workCell = ({ dataItem }) => {
    const priority = dataItem?.priority;
    return (
      <td>
        <span>{getWorkItemTitle(dataItem?.work)}</span>
        {priority && (
          <>
            <br />
            <span>{`Priority: ${priority}`}</span>
          </>
        )}
      </td>
    );
  };

  const workStatusCell = ({ dataItem }) => {
    return (
      <td>
        <WorkStatusBadge workStatus={dataItem.workStatus} />
      </td>
    );
  };

  const releaseCell = ({ dataItem }) => {
    const canUserRelease =
      agentId === dataItem?.assignedTo ||
      (isAdmin && dataItem?.assignedTo !== 'TBD');
    return (
      <td>
        {/* If the work item is assigned to the current agent, or if the current agent is an admin,
        then display the release button  */}
        {canUserRelease && (
          <span
            className='remove-label'
            onClick={() => handleReleaseWork(dataItem)}
          >
            <AwesomeLabel label='Return' icon='xmark' />
          </span>
        )}
      </td>
    );
  };

  const snoozeCell = ({ dataItem }) => {
    const canUserSnooze = isWorkTypeSupportedForSnooze(dataItem);
    return (
      <td>
        {canUserSnooze && (
          <div className='row'>
            <div className='col-md-2'>
              <Button
                type='button'
                title='Edit Quantity'
                className='k-button clearButton'
                onClick={() => {
                  handleSnoozeItemClick(dataItem);
                }}
              >
                Snooze
              </Button>
            </div>
          </div>
        )}
      </td>
    );
  };

  const getColumns = () => {
    const cols = [
      { field: 'createdAt', title: 'Created Date' },
      {
        field: 'patientLastName',
        title: 'Patient',
        cell: patientNameCell,
        minWidth: 140
      },
      { field: 'patientId', title: 'Patient ID', cell: patientIdCell },
      {
        field: 'prescriber',
        title: 'Prescriber'
      },
      { field: 'drugName', title: 'Drug', cell: drugNameCell },
      { field: 'work', title: 'Work Item', cell: workCell },
      { field: 'workStatus', title: 'Status', cell: workStatusCell },
      { field: 'assignedTo', title: 'Assigned To' },
      { field: 'description', title: 'Description' },
      {
        field: 'attachedData.followUpCategoryText',
        title: 'Follow Up Type'
      },
      { field: 'targetTime', title: 'Due Date' },
      { field: 'assignedTo', title: 'Release Work Item', cell: releaseCell },
      { field: 'snooze', title: 'Snooze Work Item', cell: snoozeCell }
    ];
    return cols;
  };

  const columns = getColumns();
  const render = {
    pendingItemsCounter: () => {
      const pluralizedItems = pluralize('work item', resultState.total, true);
      return resultState.data.length > 0 ? `(${pluralizedItems})` : '';
    }
  };
  const exportFileName = `${name}.xlsx`;
  return (
    <>
      {isDataLoaded && (
        <Preloader show={isLoading}>
          <ExcelExport data={finalData} ref={_export} fileName={exportFileName}>
            <ExcelExportColumnGroup>
              <ExcelExportColumn field='createdAt' title='Created Date' />
              <ExcelExportColumn
                field='patientLastName'
                title='Patient Last Name'
              />
              <ExcelExportColumn
                field='patientFirstName'
                title='Patient First Name'
              />
              <ExcelExportColumn
                field='attachedData.patientHomePhoneNumber'
                title='Home Phone'
              />
              <ExcelExportColumn field='patientId' title='Patient ID' />
              <ExcelExportColumn field='patientState' title='State' />
              <ExcelExportColumn field='patientStatus' title='Patient Status' />
              <ExcelExportColumn field='priority' title='Priority' />
              <ExcelExportColumn field='prescriber' title='Prescriber' />
              <ExcelExportColumn field='insurerName' title='Insurer' />
              <ExcelExportColumn field='drugName' title='Drug' />
              <ExcelExportColumn
                field='attachedData.programs[0].programId'
                title='Program'
              />
              <ExcelExportColumn field='productCategory' title='Category' />
              <ExcelExportColumn field='work' title='Work Item' />
              <ExcelExportColumn field='workStatus' title='Status' />
              <ExcelExportColumn field='assignedTo' title='Assigned To' />
              <ExcelExportColumn field='description' title='Description' />
              <ExcelExportColumn
                field='attachedData.followUpType'
                title='Follow Up Type'
              />
              <ExcelExportColumn field='targetTime' title='Due Date' />
            </ExcelExportColumnGroup>
          </ExcelExport>
          <Grid
            className={cx(styles.dataGrid, 'queue_grid')}
            data={{
              data: finalData
            }}
            pageable={{
              buttonCount: 4,
              pageSizes: [10, 25, 100, 200, 1000, 10000]
            }}
            onDataStateChange={(e) => {
              setDataState(e.dataState);
            }}
            sortable
            {...dataState}
            total={queueData.length || 1}
          >
            <GridToolbar>
              <div className='a-grid__header'>
                <div>
                  {name} {render.pendingItemsCounter()}
                </div>
              </div>
              <div className='float-right mt-1 mr-1'>
                <Button
                  title='Export Excel'
                  className='k-button'
                  onClick={excelExport}
                >
                  <AwesomeLabel
                    label='Excel Export'
                    icon='file-export icon-blue'
                  />
                </Button>
              </div>
            </GridToolbar>
            {columns.map(({ minWidth, ...rest }, idx) => (
              <Column
                key={idx}
                width={minWidth}
                columnMenu={ColumnMenu}
                {...rest}
              />
            ))}
          </Grid>
        </Preloader>
      )}
      {visibleSnoozeDateDialog && (
        <SnoozeMenu
          toggleSnoozeDateDialog={toggleSnoozeDateDialog}
          currentSelRow={currentSelRow}
        />
      )}
    </>
  );
};

export default MainQueue;
