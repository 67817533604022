import React from 'react';

const PatientStatusBadge = ({ status }) => {
  let badgeColor;
  let title = status;

  switch (status) {
    case 'ON_HOLD':
      badgeColor = 'info';
      title = 'ON HOLD';
      break;
    case 'EXPEDITED':
      badgeColor = 'primary';
      title = 'Expedited';
      break;
    case 'STAT':
      badgeColor = 'secondary';
      title = 'Stat';
      break;
    case 'SEASONAL':
      badgeColor = 'warning';
      break;
    case 'INACTIVE':
      badgeColor = 'danger';
      break;
    default: // ACTIVE or NULL (By default backend sets status to 'NULL', which is equivalent to 'ACTIVE')
      badgeColor = 'success';
      title = 'ACTIVE';
  }

  return (
    <span className={`badge badge-pill badge-${badgeColor}`}>{title}</span>
  );
};

export default PatientStatusBadge;
