import roles from '@/context/enumUserRoles';
import { isObjectEmpty } from '@/common/Utility';
import { getFullAccess } from './UserRoleTypes';

export const getIsUserAdmin = (getAgentResponse) => {
  const userRoles =
    getAgentResponse?.data?.getAgent?.roles || getAgentResponse?.roles;

  const allowedRoles = [roles.admin];

  return userRoles.some((role) => allowedRoles.includes(role));
};

export const getIsUserSupervisor = (getAgentResponse) => {
  const userRoles =
    getAgentResponse?.data?.getAgent?.roles || getAgentResponse?.roles;

  const allowedRoles = [roles.supervisor];

  return userRoles.some((role) => allowedRoles.includes(role));
};

export const getUserRoles = (getAgentResponse) => {
  const aclRulesString = getAgentResponse?.data?.getAgent?.consolidatedACLRule;
  const userRoles = {};
  try {
    if (aclRulesString?.length) {
      const roles = JSON.parse(JSON.parse(aclRulesString));

      return isObjectEmpty(roles) ? getFullAccess() : roles;
    }
  } catch (err) {
    console.error(
      'Following error occured while parsing consolidatedACLRule',
      err
    );
  }
  return userRoles;
};

export const getHasAccess = (userRoles, userRoleType) => {
  if (!userRoles || !userRoleType?.length) {
    return false;
  }
  const accessLevel = userRoles[userRoleType];
  return !!accessLevel?.length;
};

export const getHasEditAccess = (userRoles, userRoleType) => {
  if (!userRoles || !userRoleType?.length) {
    return false;
  }
  const accessLevel = userRoles[userRoleType];
  return accessLevel === 'Edit Access';
};
