import React, { useState, useContext } from 'react';

// components
import AwesomeLabel from '@/components/common-components/AwesomeLabel';
import { ConfirmationModal } from '@/components/common-components/ConfirmationModal/ConfirmationModal';

// context
import { WorkItemContext, UserContext, NotifContext } from '@/context';

// utils
import { callWorkTypes } from '@/constants/enum';

// styles
import styles from './ItemActions.module.scss';

const CompleteWorkItem = () => {
  const {
    selectedWorkItem,
    releaseWorkItem,
    deletePendingWorkItem,
    setRefreshQueueData,
    refreshQueueData,
    updateLastCompleted,
    numberOfCallAttempts
  } = useContext(WorkItemContext);
  const { agentId, isSupervisor, isAdmin } = useContext(UserContext);
  const { showError, showSuccess } = useContext(NotifContext);
  const [isVisible, setIsVisible] = useState(false);

  const toggleDialog = () => {
    setIsVisible(!isVisible);
  };

  const onReleaseWorkItem = async () => {
    const data = await releaseWorkItem({
      agentId,
      workItemId: selectedWorkItem.id,
      workStatus: 'COMPLETED'
    });
    if (data[0]?.success) {
      toggleDialog();
      deletePendingWorkItem(selectedWorkItem.id);
      showSuccess('Work Item Completed');
      setRefreshQueueData(!refreshQueueData);

      // last completed WI logic
      updateLastCompleted(selectedWorkItem);
    } else if (!data[0]?.success) {
      showError(data[0].details);
      toggleDialog();
    }
  };

  // OPTML-1680: Hide Close Work Item on all Call Work items until after 3 attempts
  // Exception:  Close Work Item button should Always viewable for Admin/Supervisor Roles
  const hasCallType = callWorkTypes.includes(selectedWorkItem?.work);
  if (hasCallType && numberOfCallAttempts < 3 && !isSupervisor && !isAdmin)
    return null;

  return (
    <>
      <button className={styles.minButtonWidth} onClick={() => toggleDialog()}>
        <AwesomeLabel icon='circle-xmark' label='Close Work Item' />
      </button>
      <ConfirmationModal
        isVisible={isVisible}
        dialogDescription='Has work been completed?'
        dialogTitle='Complete Work Item'
        onClose={() => setIsVisible(false)}
        onReject={() => setIsVisible(false)}
        onConfirm={() => onReleaseWorkItem()}
      />
    </>
  );
};

export default CompleteWorkItem;
