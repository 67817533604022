import React, { useEffect, useState, useContext } from 'react';
import {
  getPatientReferralOrders,
  getScheduleEventsByPatient
} from '@/graphql/queries';
import { connectToGraphqlAPI } from '@/provider';
import AppointmentsList from '@/components/Appointments/AppointmentsList/index';
import Preloader from '@/components/common-components/Preloader';
import { StatusContext } from '@/context/StatusContext';
import { NotifContext } from '@/context/NotifContext';

export const AppointmentListData = (props) => {
  const selectedLocationId = props?.selectedLocationId;
  const { appointmentListRefresh } = useContext(StatusContext);
  const patientId = props?.selectedPatientInfo?.patientId;
  const [patientAppointments, setPatientAppointments] = useState();
  const [loading, setLoading] = useState(true);
  const [listReferralOrdersData, setListReferralOrdersData] = useState([]);
  const [patientData, setPatientData] = useState([]);
  const { showError } = useContext(NotifContext);
  const [reload, setReload] = useState('');

  const childToParent = (childdata) => {
    setReload(childdata);
  };

  const fetchAppointmentData = async () => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getScheduleEventsByPatient,
        variables: { patientId }
      });

      setPatientAppointments(data?.data?.getScheduleEventsByPatient?.events);
    } catch (err) {
      console.error(
        'AppointmentListData - err in retrieving Patient Appointments: ',
        err
      );
      // todo: show error dialog
      setPatientAppointments([]);
    }
    setLoading(false);
  };

  const getPatientReferralOrdersCall = async () => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getPatientReferralOrders,
        variables: { patientId }
      });

      if (data?.data?.getPatientBucket?.referral?.drugReferrals?.length) {
        setListReferralOrdersData(data.data.getPatientBucket.referral.drugReferrals.map((item) => ({
            ...item,
            text: item.referralOrder.orderName,
            value: item.referralId
          })));
        setPatientData(data?.data?.getPatientBucket);
      }
    } catch (err) {
      console.error(
        'AppointmentListData::getPatientReferralOrders data err: ',
        err
      );
      showError('Error: getPatientReferralOrders');
    }
  };

  useEffect(() => {
    fetchAppointmentData();
    getPatientReferralOrdersCall();
  }, [appointmentListRefresh, selectedLocationId, reload, patientId]);

  return (
    <div className="container-fluid mb-5">
      <div className="row">
        <div className="col-md-3 pageTitle">Appointment Information</div>
      </div>
      <Preloader show={loading}>
        <AppointmentsList
          patientData={patientData}
          childToParent={childToParent}
          patientAppointments={patientAppointments}
          listReferralOrdersData={listReferralOrdersData}
          selectedLocationId={selectedLocationId}
        />
      </Preloader>
    </div>
  );
};
