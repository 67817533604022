// React
import React, { useContext } from 'react';
// Kendo
import { Button } from '@progress/kendo-react-buttons';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Field, Form } from '@progress/kendo-react-form';
import { FormDateTimePicker } from '@/common/kendo-form-components';
// gql
import { connectToGraphqlAPI } from '@/provider';
import { snoozeWorkItem } from '@/graphql/mutations';
// context
import { LogContext, UserContext, WorkItemContext } from '@/context';
// util
import { Constants } from '@/constants';
import { isDateGreaterThanToday, validateInput } from '@/common/Validation';
import {
  formatDateToAWSDateTime,
  getDateTimePlusOneDay
} from '@/common/DateHelper';
// components
import AwesomeLabel from '@/components/common-components/AwesomeLabel';

const SnoozeMenu = ({ toggleSnoozeDateDialog, currentSelRow }) => {
  const { agentId } = useContext(UserContext);
  const { setRefreshQueueData, refreshQueueData } = useContext(WorkItemContext);
  const { logApiException } = useContext(LogContext);

  const patientName = `${currentSelRow.patientFirstName} ${currentSelRow.patientLastName}`;

  const handleSnoozeItemDefault = () => {
    const defaultValue = getDateTimePlusOneDay().setHours(0, 0, 0, 0);
    const formatedDate = formatDateToAWSDateTime(defaultValue);
    handleSnoozeItem(formatedDate);
  };

  const handleSnoozeItemSchedule = (dataItem) => {
    const formatedDate = formatDateToAWSDateTime(dataItem.snoozeUntil);
    handleSnoozeItem(formatedDate);
  };

  const handleSnoozeItem = async (formatedDate) => {
    try {
      await connectToGraphqlAPI({
        graphqlQuery: snoozeWorkItem,
        variables: {
          agentId,
          targetDate: formatedDate,
          workItemId: currentSelRow.id
        }
      }).then(() => {
        toggleSnoozeDateDialog();
        setRefreshQueueData(!refreshQueueData);
      });
    } catch (err) {
      console.error(err);
      logApiException(err, {
        view: 'SnoozeMenu',
        endpoint: 'snoozeWorkItem',
        agentId
      });
    }
  };

  const snoozeForm = {
    snoozeUntil: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          snoozeUntil: { ...snoozeForm.snoozeUntil, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    }
  };

  return (
    <Dialog
      title='Snooze Work Item'
      width={500}
      height={250}
      onClose={toggleSnoozeDateDialog}
      showDialog
    >
      <Form
        onSubmit={handleSnoozeItemSchedule}
        validator={isDateGreaterThanToday}
        render={(formRenderProps) => (
          <form
            onSubmit={formRenderProps.onSubmit}
            className='k-form pl-2 pr-3 pt-1'
          >
            <div className='row mt-2'>
              <div className='col-7'>
                <b>Patient Name:</b> {patientName}
              </div>
              <div className='col-4'>
                <b>ID:</b> {currentSelRow.patientId}
              </div>
            </div>
            <div className='row mt-2'>
              <div className='col-12'>
                <Field
                  component={FormDateTimePicker}
                  name='snoozeUntil'
                  label={<b>Snooze Until:</b>}
                  validator={snoozeForm.snoozeUntil.inputValidator}
                />
              </div>
            </div>
            <div className='row p-3'>
              <div className='col-6 text-center'>
                <Button
                  type='button'
                  className='k-button snoozeButton'
                  onClick={() => handleSnoozeItemDefault()}
                >
                  <AwesomeLabel icon='snooze' label='Default Snooze' />
                </Button>
              </div>
              <div className='col-6 text-center'>
                <Button type='submit' className='k-button snoozeButton'>
                  <AwesomeLabel icon='alarm-snooze' label='Scheduled Snooze' />
                </Button>
              </div>
            </div>
          </form>
        )}
      />
    </Dialog>
  );
};

export default SnoozeMenu;
