import { Dialog } from '@progress/kendo-react-dialogs';
import { Field, Form } from '@progress/kendo-react-form';
import { Badge, BadgeContainer } from '@progress/kendo-react-indicators';
import { Checkbox, TextArea } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import moment from 'moment/moment';
import React, { useMemo, useEffect, useContext, useState } from 'react';
import { DropDownTree } from '@progress/kendo-react-dropdowns';
import { formatDateToAWSDateTime } from '../../common/DateHelper';
import {
  DatePickerField,
  DropDownListField,
  validateInput
} from '../../common/Validation';
import { Constants } from '../../constants';
import { NotifContext } from '../../context/NotifContext';
import { PatientContext } from '../../context/PatientContext';
import { UserContext } from '../../context/UserContext';
import {
  addUpdateNotes,
  createWorkItem,
  updateWorkItem
} from '../../graphql/mutations';
import {
  getCaseByPatientId,
  getWorkItemsByPatient
} from '../../graphql/queries';
import { connectToGraphqlAPI } from '../../provider';
import styles from '../../styles/StatusBar.module.scss';
import AwesomeLabel from '../common-components/AwesomeLabel';
import CurrentPatient from '../common-components/CurrentPatient';
import {
  followUpAssignToGroups,
  followUpCallCategories,
  getCategoryNameFromJSON,
  getMainCategory,
  getWorkItemType
} from './followUpData.js';
import { FollowUpContext } from '../../context/FollowUpContext';
import { WorkItemContext } from '../../context/WorkItemContext';
import { FollowupItem } from './FollowUpItem';
import {
  expandedState,
  processTreeData
} from '../../common/tree-data-operations';
import { StatusContext } from '../../context/StatusContext';

const selectField = 'selected';
const expandField = 'expanded';
const dataItemKey = 'id';
const textField = 'text';
const subItemsField = 'items';
const fields = {
  selectField,
  expandField,
  dataItemKey,
  subItemsField
};

const FollowUp = ({ returnStyle, followUpListLoad }) => {
  const { selectedPatientInfo } = useContext(PatientContext);
  const { setWorkItemData, setOverDueWorkItemsData } =
    useContext(WorkItemContext);
  const { user, agentUsers } = useContext(UserContext);
  const { followUpEvent, setFollowUpEvent, setFollowUpReload, followUpReload } =
    useContext(FollowUpContext);
  const { setNoteRefresh, noteRefresh } = useContext(StatusContext);
  const { showError } = useContext(NotifContext);
  const [visibleFollowUpDialog, setVisibleFollowUpDialog] = useState(false);
  const [visibleAddNewFollowUpDialog, setVisibleAddNewFollowUpDialog] =
    useState(false);
  const [followUpPopupId, setFollowUpPopupId] = useState('');
  const [followUpPopupDate, setFollowUpPopupDate] = useState('');
  const [followUpPopupNote, setFollowUpPopupNote] = useState('');
  const [followUpCategory, setFollowUpCategory] = useState('');
  const [followUpCreatedDate, setFollowUpCreatedDate] = useState('');
  const [caseData, setCaseData] = useState('');
  const [workType, setWorkType] = useState('');
  const [followUpPopupAssignedTo, setFollowUpPopupAssignedTo] = useState('');
  const [followUpItems, setFollowUpItems] = useState([]);
  const [workItems, setWorkItems] = useState([]);
  const [noteData, setNoteData] = useState([]);
  const [followUpAssignToList, setFollowUpAssignToList] = useState([]);
  const [showStatusBarFollowUp, setShowStatusBarFollowUp] = useState('success'); // always show green "+"
  const [expandStatusBarFollowUp, setExpandStatusBarFollowUp] = useState(returnStyle === 'followUpLayout');
  const [value, setValue] = React.useState(null);
  const [expanded, setExpanded] = React.useState([
    /* 1, 6, 12, 20, 29 */
  ]);
  const onChange = (event) => setValue(event.value);
  const onExpandChange = React.useCallback(
    (event) => setExpanded(expandedState(event.item, dataItemKey, expanded)),
    [expanded]
  );
  const patientId = selectedPatientInfo?.patientId;
  const dropDownData = followUpCallCategories.filter((item) => item.id < '35');
  const finalDropData =
    returnStyle === 'followUpLayout' ? dropDownData : followUpCallCategories;

  const Item = (props) => {
    return (
      <React.Fragment>
        <AwesomeLabel icon={props.item.icon} label={props.item.text} />
      </React.Fragment>
    );
  };
  const ValueHolder = (props) => {
    return props.item ? (
      <React.Fragment>
        <AwesomeLabel icon={props.item.icon} label={props.children} />
      </React.Fragment>
    ) : (
      props.children
    );
  };

  const treeData = useMemo(
    () =>
      processTreeData(
        finalDropData,
        {
          expanded,
          value
        },
        fields
      ),
    [expanded, value]
  );

  const getNoteData = () => {
    let theData = [];
    if (selectedPatientInfo.notes === Array) {
      theData = selectedPatientInfo.notes
        .map((item) => ({
          ...item,
          displayDate: moment(item.date).format('MM/DD/YYYY @ hh:mm A'),
          date: item.date
        }))
        .sort((a, b) => (b.date - a.date ? 1 : -1));
    }
    setNoteData(theData);
  };

  useEffect(() => {
    // enhancements to update the colored +/- based on retrieved data
    if (selectedPatientInfo?.patientId) {
      InitWorkItemsFollowupsListsCall();
      getNoteData();
    }
    // handle this call to load data
  }, [selectedPatientInfo, agentUsers]);
  useEffect(() => {
    if (selectedPatientInfo) {
      getCaseData(patientId);
    }
  }, [selectedPatientInfo]);

  useEffect(() => {
    if (followUpEvent) {
      toggleFollowUp(followUpEvent);
    }
    return () => {
      setFollowUpEvent('');
    };
  }, [followUpEvent]);

  const getCaseData = async (patientId) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getCaseByPatientId,
        variables: { patientId }
      });

      if (data && data.data && data.data.getCaseByPatientId) {
        setCaseData(data.data.getCaseByPatientId.caseDetail);
      }
    } catch (err) {
      console.log('FollowUp::getCaseByPatientId err:', err);
      showError('FollowUp::getCaseByPatientId err:', err);
    }
  };

  const toggleFollowUp = (event) => {
    let eventData = '';
    if (event?.target?.props) {
      eventData = event.target.props;
    } else {
      eventData = event;
    }
    const followUpAddDate = new Date(eventData.followupdate);
    if (!visibleAddNewFollowUpDialog) {
      // if TURNING ON because "Not false"
      // grab values from followup chip clicked
      setFollowUpPopupId(eventData.followupid);
      setFollowUpPopupDate(new Date(followUpAddDate.setDate(followUpAddDate.getDate() + 1)));
      setFollowUpCreatedDate(eventData.followUpCreated);

      // locate assignTo in listItem
      const followUpAssignToItem = followUpAssignToList.find((itm) => itm.value === eventData.followupassignedto);
      setFollowUpPopupAssignedTo(followUpAssignToItem);
      setFollowUpPopupNote(eventData.followupnote);
      setFollowUpCategory(eventData.followupcategory);
      setWorkType(eventData.followUpWorkType);
    } else {
      // if TURNING OFF because "Not true"
      // init states to empty for next use
      setFollowUpPopupId('');
      setFollowUpPopupDate(new Date('2021-01-01'));
      setFollowUpPopupAssignedTo('');
      setFollowUpPopupNote('');
    }
    setVisibleFollowUpDialog(!visibleFollowUpDialog);
  };
  const toggleAddNewFollowUp = () => {
    setVisibleAddNewFollowUpDialog(!visibleAddNewFollowUpDialog);
    setExpanded([]);
  };

  const loadAgents = async () => {
    const assignToGroups = followUpAssignToGroups.map((r) => ({
      text: `- ${r}`,
      value: r
    }));

    const newArray = [...assignToGroups, ...agentUsers];
    // sort combined list in ascending
    setFollowUpAssignToList(newArray.sort((a, b) => a.text.localeCompare(b.text)));
    if (returnStyle === 'followUpLayout') {
      followUpListLoad();
    }
  };

  const CheckWorkItemsInit = (expanding) => {
    if (
      expanding === true &&
      followUpItems.length === 0 &&
      workItems.length === 0
    ) {
      InitWorkItemsFollowupsListsCall();
    }
  };

  const InitWorkItemsFollowupsListsCall = async () => {
    try {
      // set date range +/- 1 year... API already filters on workstatus != COMPLETE
      const curDate = new Date();
      const startDate = new Date(
        curDate.getFullYear() - 1,
        curDate.getMonth(),
        curDate.getDate()
      );
      const endDate = new Date(
        curDate.getFullYear() + 1,
        curDate.getMonth(),
        curDate.getDate()
      );
      const sRangeStartDate = `${moment(startDate).format('YYYY-MM-DD')}`;
      const sRangeEndDate = `${moment(endDate).format('YYYY-MM-DD')}`;
      const sCurDate = `${moment(curDate).format('YYYY-MM-DD')}`;

      // getWorkItemsByPatient is being called in context and follow up needs to be refactored to prevent multiple calls.
      const retData = await connectToGraphqlAPI({
        graphqlQuery: getWorkItemsByPatient,
        variables: {
          patientId: selectedPatientInfo.patientId,
          startDate: formatDateToAWSDateTime(sRangeStartDate),
          endDate: formatDateToAWSDateTime(sRangeEndDate)
        }
      });
      // now add to the followUpItems
      if (retData && retData.data && retData.data.getWorkItemsByPatient) {
        let sWIScheduleSummary = '';
        const retFollowUpItems = retData.data.getWorkItemsByPatient.filter((itm) =>
            itm.work === 'FOLLOW_UP' ||
            ('OUTBOUND_CALL' && itm.followupDate !== null));
        const retWIScheduleItems = retData.data.getWorkItemsByPatient.filter((itm) =>
            itm.work !== 'FOLLOW_UP' ||
            ('OUTBOUND_CALL' && itm.work == 'SCHEDULE'));
        const retOtherItems = retData.data.getWorkItemsByPatient.filter((itm) =>
            itm.work !== 'FOLLOW_UP' ||
            ('OUTBOUND_CALL' && itm.work !== 'SCHEDULE'));
        const overDueFollowUp = retFollowUpItems.filter((itm) => itm.followupDate < sCurDate || itm.workStatus === 'OVERDUE');
        const overDueWorkItems = retOtherItems.filter((itm) => itm.targetTime < sCurDate || itm.workStatus === 'OVERDUE');
        const retDisplayFollowUpItems = retFollowUpItems
          .map((itm) => ({
            id: itm.id,
            category: getCategoryNameFromJSON(itm.attachedData),
            date: itm.followupDate,
            assignedTo: itm.assignedTo,
            note: itm.description,
            workType: itm.work
          }))
          .sort((a, b) => {
            return new Date(a.date) - new Date(b.date);
          });
        let retWorkItems = retOtherItems.map((itm) => ({
          id: itm.id,
          description: itm.description,
          work: itm.work,
          workStatus: itm.workStatus
        }));
        if (retWIScheduleItems.length > 0) {
          sWIScheduleSummary = `Patient has ${retWIScheduleItems.length.toString()} schedule Work Item`;
          if (retWIScheduleItems.length > 1) {
            sWIScheduleSummary += 's';
          }
          // add in "schedule summary" item
          retWorkItems = [
            ...retWorkItems,
            { id: '123', description: sWIScheduleSummary, work: 'SCHEDULE' }
          ];
        }

        if (overDueFollowUp.length > 0) {
          setShowStatusBarFollowUp('error');
        } else if (retFollowUpItems.length > 0) {
          setShowStatusBarFollowUp('warning');
        } else {
          setShowStatusBarFollowUp('success');
        }
        setOverDueWorkItemsData(overDueWorkItems);
        setFollowUpItems(retDisplayFollowUpItems);
        setWorkItems(retWorkItems);
        setWorkItemData(retWorkItems);
        loadAgents();
      }
    } catch (err) {
      console.log('FollowUp::InitListOfFollowupsCall err:', err);
      showError('FollewUp::InitListOfFollowupsCall err:', err);
    }
  };

  const handleSubmitNewFollowup = (dataItem) => {
    setVisibleAddNewFollowUpDialog(false); // close dialog
    const mainCategory = getMainCategory(dataItem?.followUpCategory?.id);
    if (dataItem.followUpDate) {
      const thisNotes = dataItem.followUpNotes ? dataItem.followUpNotes : '';
      const thisAssignedTo = dataItem.followUpAssignTo
        ? dataItem.followUpAssignTo.value
        : user.username; // clear the leading char that lets groups sort first
      const thisStartDateTime = `${moment(Date()).format('YYYY-MM-DD')}T00:00:00.000Z`; // grab a NOW date

      const thisTargetDateTime = formatDateToAWSDateTime(dataItem.followUpDate); // grab a NOW date
      const thisFollowUpDate = `${moment(dataItem.followUpDate).format('YYYY-MM-DD')}`;
      const sAttachedDataJSON = `{"category": "${mainCategory.text}${
        mainCategory.text === dataItem.followUpCategory.text
          ? ''
          : `/${dataItem.followUpCategory.text}`
      }"}`;
      const addPatientNote = dataItem?.addPatientNote;

      const requestObject = {
        assignedTo: thisAssignedTo, // statusBar?.caseDetail?.agentId,
        groupQueueId: 'INTAKE',
        work: getWorkItemType(dataItem.followUpCategory.id),
        attachedData: sAttachedDataJSON, // AWSJSON field
        startTime: thisStartDateTime,
        targetTime: thisTargetDateTime,
        createdBy: user.username,
        followupDate: thisFollowUpDate,
        updatedBy: user.username,
        priority: 10,
        workStatus: 'CREATED',
        patientId,
        caseId: caseData?.caseId, // NOTE: caseId seems to be patientId in several places like patientportal/nursequeue/loginfo
        description: thisNotes // dataItem.followUpNotes
      };

      createNewFollowupEventCall(requestObject, addPatientNote);
    }
  };

  const createNewFollowupEventCall = async (requestObject, addPatientNote) => {
    try {
      // get followupDate back into MM-DD-YYYY so new Date does not intepret as international date and adjust for timezone...
      const sFollowUpdate = `${moment(requestObject.followupDate).format('MM-DD-YYYY')}`;
      const sCategory = getCategoryNameFromJSON(requestObject.attachedData); // parse the category from the requestObject.attachedData JSON string
      const retData = await connectToGraphqlAPI({
        graphqlQuery: createWorkItem,
        variables: { input: requestObject }
      });
      const note = requestObject?.description;

      // now add to the followUpItems
      const newFollowup = {
        id: retData.data.createWorkItem.id,
        category: sCategory, // this is temporary until i can find out where to put hte category on the workItem FOLLOWUP
        date: new Date(sFollowUpdate),
        note
      };
      if (retData && retData.data && retData.data.createWorkItem) {
        setFollowUpItems((prevItems) => {
          return [newFollowup, ...prevItems].sort((a, b) => {
            return new Date(a.date) - new Date(b.date);
          });
        });
        if (returnStyle === 'followUpLayout') {
          setFollowUpReload(!followUpReload);
        }
      }
      const requestObjNote = {
        patientId: selectedPatientInfo.patientId,
        agentId: user.username,
        notes: [
          {
            date: moment(new Date()),
            note,
            type: requestObject.work
          }
        ]
      };
      if (addPatientNote) {
        addUpdateNotesCall(requestObjNote);
      }
      InitWorkItemsFollowupsListsCall();
      setExpanded([]);
    } catch (err) {
      console.log('FollowUp::createNewFollowupEventCall err:', err);
      showError('FollowUp::createNewFollowupEventCall err:', err);
    }
  };

  const handleSubmitUpdateFollowup = (dataItem) => {
    const bIsCompleted = !!dataItem.followUpComplete;
    const bHasDate = !!dataItem.followUpDate;
    const thisAssignedTo = dataItem.followUpAssignTo
      ? dataItem.followUpAssignTo.value
      : user.username; // clear the leading char that lets groups sort first
    const thisNotes = dataItem.followUpNotes
      ? dataItem.followUpNotes
      : followUpPopupNote; // if not changed, use previous value

    if (!bHasDate && !bIsCompleted) {
      // must either have FollowUpDate OR be marked as Complete
      // error and return with warning
      alert('Either IsComplete or Followup Date must be specified!');
      return;
    }

    setVisibleFollowUpDialog(false); // close dialog

    // let thisStartDateTime = `${moment(Date()).format(
    //   "YYYY-MM-DD"
    // )}T00:00:00.000Z`; // grab a NOW date
    const thisTargetDateTime = formatDateToAWSDateTime(dataItem.followUpDate); // grab a NOW date
    let thisFollowUpDate = `${moment(followUpPopupDate).format('YYYY-MM-DD')}`; // initialize to previous values
    if (dataItem.followUpDate) {
      thisFollowUpDate = `${moment(dataItem.followUpDate).format('YYYY-MM-DD')}`;
    }
    const { addPatientNote } = dataItem;

    const requestObject = {
      id: followUpPopupId, // use the value from the clicked followup Chip stored when the popup started
      assignedTo: thisAssignedTo, // statusBar?.caseDetail?.agentId,
      groupQueueId: 'INTAKE',
      work: workType,
      createdBy: user.username,
      targetTime: thisTargetDateTime,
      followupDate: thisFollowUpDate,
      updatedBy: user.username,
      priority: 10,
      workStatus: 'IN_PROGRESS',
      patientId,
      caseId: caseData?.caseId, // NOTE: caseId seems to be patientId in several places like patientportal/nursequeue/loginfo
      description: thisNotes // dataItem.followUpNotes
    };

    if (bIsCompleted) {
      // now remove from display list
      const arFollowupItems = [...followUpItems]; // make a separate copy of the array
      const fItemIndex = arFollowupItems.findIndex((itm) => itm.id === requestObject.id);
      if (fItemIndex !== -1) {
        arFollowupItems.splice(fItemIndex, 1);
        setFollowUpItems(arFollowupItems);
      }

      // set workStatus so Update will tag and remove from next call to getWorkItemsByPatien
      requestObject.workStatus = 'COMPLETED';
    }
    // temp comment for debug... enable for storage
    if (addPatientNote) {
      handleUpdateNote(requestObject);
    }
    updateFollowupEventCall(requestObject);
  };

  const handleUpdateNote = (dataItem) => {
    // Find specific object being updated.
    const objUpdated = noteData.find((obj) =>
        Date.now(new Date(obj.date).setMilliseconds(0)) ===
        Date.now(new Date(followUpCreatedDate).setMilliseconds(0)));
    const requestObject = {
      patientId: selectedPatientInfo.patientId,
      agentId: user.username,
      // notes: [NoteInput!]!
      notes: [
        {
          // date: AWSDateTime
          date: objUpdated?.date ? new Date(objUpdated.date) : new Date(), // keep existing date
          // note: String!
          note: dataItem.description ? dataItem.description : objUpdated.note, // enter updated note value
          // type: NoteType
          type: objUpdated?.type ? objUpdated.type : dataItem.work, // keep existing type
          // drugName: String
          // labTest: String
          // labExpiration: AWSDate
          createdBy: objUpdated?.createdBy
            ? objUpdated.createdBy
            : dataItem.createdBy, // keep existing createdBy
          modifiedNote: true // send true with updated note text to update
        }
      ]
    };
    addUpdateNotesCall(requestObject);

    //
  };

  const addUpdateNotesCall = async (requestObject) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: addUpdateNotes,
        variables: { input: requestObject }
      });
      if (data) {
        getNoteData();
        setNoteRefresh(!noteRefresh);
      }
    } catch (err) {
      console.log('FollowUp::addUpdateNotesCall err:', err);
    }
  };

  const updateFollowupEventCall = async (requestObject) => {
    try {
      const sFollowUpdate = `${moment(requestObject.followupDate).format('MM-DD-YYYY')}`;
      const retData = await connectToGraphqlAPI({
        graphqlQuery: updateWorkItem,
        variables: { input: requestObject }
      });

      if (retData && retData.data && retData.data.updateWorkItem) {
        // now update followUpItems
        const fItem = followUpItems.find((itm) => itm.id === retData.data.updateWorkItem.id);

        if (fItem) {
          fItem.date = new Date(sFollowUpdate);
          fItem.note = requestObject.description;
          fItem.assignedTo = requestObject.assignedTo;

          // update the existing list with the modified existing list
          setFollowUpItems((prevItems) => {
            return [...prevItems];
          });
        }
        if (returnStyle === 'followUpLayout') {
          setFollowUpReload(!followUpReload);
        }
      }
      InitWorkItemsFollowupsListsCall();
    } catch (err) {
      console.log('FollowUp::updateFollowupEventCall err:', err);
      showError('FollowUp::updateFollowupEventCall err:', err);
    }
  };

  /// //Validations/////
  const initialForm = () => {
    const initialObject = {};
    Object.keys(followUpForm).forEach((key) => {
      initialObject[key] = followUpForm[key].value;
    });
    return initialObject;
  };

  const followUpForm = {
    followUpCategory: {
      value: followUpCategory,
      inputValidator: (value) => {
        return validateInput({
          referralId: { ...followUpForm.followUpCategory, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        },
        {
          type: 'isMainCategory',
          message: Constants.ErrorMessage.notMainCategory
        }
      ]
    },
    followUpAssignTo: {
      value: followUpPopupAssignedTo,
      inputValidator: (value) => {
        return validateInput({
          referralId: { ...followUpForm.followUpAssignTo, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    },
    followUpNotes: {
      value: followUpPopupNote,
      inputValidator: (value) => {
        return validateInput({
          referralId: { ...followUpForm.followUpNotes, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    },
    followUpDate: {
      // value: "",
      value: followUpPopupDate,
      inputValidator: (value) => {
        return validateInput({
          referralId: { ...followUpForm.followUpDate, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        },
        {
          type: 'datePast',
          message: Constants.ErrorMessage.datePast
        }
      ]
    }
  };

  return (
    <>
      {returnStyle === 'statusBarLayout' && (
        <div className="followup">
          <div className={styles.category}>
            <BadgeContainer>
              <Badge
                themeColor={showStatusBarFollowUp}
                size="small"
                align={{ horizontal: 'start' }}
                cutoutBorder={false}
              >
                <span
                  className={
                    expandStatusBarFollowUp
                      ? 'k-icon k-i-minus'
                      : 'k-icon k-i-plus'
                  }
                  onClick={() => {
                    CheckWorkItemsInit(!expandStatusBarFollowUp);
                    setExpandStatusBarFollowUp(!expandStatusBarFollowUp);
                  }}
                />
              </Badge>
              <span
                className={styles.category}
                style={{ marginLeft: '1.6rem' }}
              >
                FOLLOW-UPS &nbsp;&nbsp;
                <button
                  type="button"
                  onClick={toggleAddNewFollowUp}
                  className="k-button followUpButton"
                >
                  <span className="k-icon k-i-plus" />
                </button>
              </span>
              {expandStatusBarFollowUp && (
                <>
                  {followUpItems.map((itm) => (
                    <FollowupItem
                      key={itm.id}
                      id={itm.id}
                      assignedTo={itm.assignedTo}
                      category={itm.category}
                      date={itm.date}
                      note={itm.note}
                      workType={itm.workType}
                      clickEvent={toggleFollowUp}
                    />
                  ))}
                </>
              )}
            </BadgeContainer>
          </div>
        </div>
      )}
      {returnStyle === 'followUpLayout' && (
        <Button
          style={{ color: '#005282', float: 'right' }}
          look="flat"
          icon="plus"
          onClick={toggleAddNewFollowUp}
        >
          New Follow Up
        </Button>
      )}
      {visibleAddNewFollowUpDialog && (
        <Dialog
          title="🡹 Follow Up"
          width={500}
          height={530}
          onClose={toggleAddNewFollowUp}
          showDialog
        >
          <Form
            // initialValues={initialForm()}
            onSubmit={handleSubmitNewFollowup}
            render={(formRenderProps) => (
              <form
                onSubmit={formRenderProps.onSubmit}
                className="k-form pl-3 pr-3 pt-1"
              >
                <div className="col-md-12 mt-3">
                  <CurrentPatient />
                </div>
                <div className="col-md-12 mt-3">
                  <AwesomeLabel
                    size="duotone"
                    label={' '}
                    icon="phone-intercom"
                    title="Call Types"
                  />
                  <Field
                    component={DropDownTree}
                    /* data={returnStyle === "followUpLayout" ? dropDownData : followUpCallCategories}
                    name={"followUpCategory"}
                    label={"CATEGORY"}
                    style={{ width: 400 }}
                    textField={"text"}
                    dataItemKey={"id"}
                    selectField={"selected"}
                    expandField={expanded}
                    validator={followUpForm.followUpCategory.inputValidator} */
                    data={treeData}
                    value={value}
                    name="followUpCategory"
                    onChange={onChange}
                    placeholder="Select ..."
                    textField={textField}
                    dataItemKey={dataItemKey}
                    selectField={selectField}
                    subItemsField={subItemsField}
                    expandField={expandField}
                    onExpandChange={onExpandChange}
                    item={Item}
                    valueHolder={ValueHolder}
                    validator={followUpForm.followUpCategory.inputValidator}
                  />
                </div>
                <div className="col-md-12 mt-3">
                  <AwesomeLabel
                    size="duotone"
                    label={' '}
                    icon="calendar-week"
                    title="Follow-Up Date"
                  />
                  <Field
                    component={DatePickerField}
                    name="followUpDate"
                    label=""
                    validator={followUpForm.followUpDate.inputValidator}
                  />
                </div>
                <div className="col-md-12 mt-3">
                  <AwesomeLabel
                    size="duotone"
                    label={' '}
                    icon="user-magnifying-glass"
                    title="Assign To"
                  />
                  <Field
                    component={DropDownListField}
                    name="followUpAssignTo"
                    label={' '}
                    style={{ marginTop: '-20px' }}
                    data={followUpAssignToList}
                    textField="text"
                    valueField="value"
                    validator={followUpForm.followUpAssignTo.inputValidator}
                  />
                </div>
                <div className="col-md-12 mt-3">
                  <AwesomeLabel
                    size="duotone"
                    label={' '}
                    icon="note"
                    title="Notes"
                  />
                  <Field component={TextArea} name="followUpNotes" label="" />
                </div>
                <div className="row mt-3 ml-1">
                  <div className="col-12">
                    {/* </div>
                  <div className="col-11"> */}
                    <AwesomeLabel
                      size="duotone"
                      label={' '}
                      icon="note"
                      title="Add To Patient Notes"
                    />
                    <Field
                      component={Checkbox}
                      name="addPatientNote"
                      label={' '}
                    />
                  </div>
                </div>
                <div className="row p-3">
                  <div className="col-12" style={{ textAlign: 'center' }}>
                    {/* <button type="submit" className="k-button pageButton"> */}
                    <Button
                      /// style={{ color: "#005282" }}
                      /* look="flat" */
                      className="k-button pageButton"
                      type="submit"
                    >
                      Submit
                    </Button>
                    {/* </button> */}
                  </div>
                </div>
              </form>
            )}
          />
        </Dialog>
      )}

      {visibleFollowUpDialog && (
        <Dialog
          title="🡹 Follow Up"
          width={500}
          height={530}
          onClose={toggleFollowUp}
          showDialog
        >
          <Form
            initialValues={initialForm()}
            onSubmit={handleSubmitUpdateFollowup}
            render={(formRenderProps) => (
              <form
                onSubmit={formRenderProps.onSubmit}
                className="k-form pl-3 pr-3 pt-1"
              >
                <div className="col-md-12">
                  <CurrentPatient />
                </div>
                <div className="col-md-12 mt-3" style={{ fontSize: '16px' }}>
                  <AwesomeLabel icon="memo icon-blue" label="Category:" />
                  &nbsp;
                  {followUpCategory}
                </div>
                <div className="col-md-12 mt-3">
                  <Field
                    component={TextArea}
                    name="followUpCategory"
                    label=""
                    style={{ display: 'none' }}
                    validator={followUpForm.followUpCategory.inputValidator}
                    required
                  />
                </div>
                {/* <div className="col-md-12 mt-3">
                  Change Follow-Up Date: &nbsp;
                  <Field
                    component={DatePickerField}
                    name={"followUpDate"}
                    label={""}
                    defaultValue = {followUpPopupDate}
                    validator={followUpForm.followUpDate.inputValidator}
                    required={true}
                  />
                </div> */}
                <div className="col-md-12 mt-3">
                  <AwesomeLabel
                    size="duotone"
                    label={' '}
                    icon="calendar-week"
                    title="Change Follow-Up Date"
                  />
                  <Field
                    component={DatePickerField}
                    name="followUpDate"
                    label=""
                    defaultValue={followUpPopupDate}
                    validator={followUpForm.followUpDate.inputValidator}
                  />
                </div>

                {/* <div className="col-md-12">
                  <Field
                    component={DropDownListField}
                    data={followUpAssignToList}
                    name={"followUpAssignTo"}
                    textField="text"
                    dataItemKey="text"
                    valueField="value"
                    defaultItem={followUpPopupAssignedTo} // from OrderProduct.jsx
                    label={"ASSIGN TO:"}
                    style={{ width: 400 }}
                    validator={followUpForm.followUpCategory.inputValidator}
                  />
                </div> */}
                <div className="col-md-12 mt-3">
                  <AwesomeLabel
                    size="duotone"
                    label={' '}
                    icon="user-magnifying-glass"
                    title="Assign To"
                  />
                  <Field
                    component={DropDownListField}
                    name="followUpAssignTo"
                    label={' '}
                    style={{ marginTop: '-20px' }}
                    data={followUpAssignToList}
                    textField="text"
                    valueField="value"
                    validator={followUpForm.followUpAssignTo.inputValidator}
                  />
                </div>
                {/*  <div className="col-md-12 mt-3">
                  <Field
                    component={TextArea}
                    name={"followUpNotes"}
                    text={"hello"}
                    value={"hello"}
                    defaultValue={followUpPopupNote} // preloads the control, does not pass value through to submit dataItem
                    validator={followUpForm.followUpNotes.inputValidator}
                  />
                </div>
                <div className="col-md-12 mt-3">
                  <Field
                    component={Checkbox}
                    name={"addPatientNote"}
                    label={"Add/Update Patient Note"}

                    // removes followup button from sidebar tab & notes go into Patient Notes
                  />
                </div> */}
                <div className="col-md-12 mt-3">
                  <AwesomeLabel
                    size="duotone"
                    label={' '}
                    icon="note"
                    title="Notes"
                  />
                  <Field component={TextArea} name="followUpNotes" label="" />
                </div>
                <div className="row mt-3 ml-1">
                  <div className="col-12">
                    {/* </div>
                  <div className="col-11"> */}
                    <AwesomeLabel
                      size="duotone"
                      label={' '}
                      icon="note"
                      title="Add To Patient Notes"
                    />
                    &nbsp;
                    <Field
                      component={Checkbox}
                      name="addPatientNote"
                      label={' '}
                    />
                  </div>
                </div>
                <div className="row mt-3 ml-1">
                  <div className="col-12">
                    {/* </div>
                  <div className="col-11"> */}
                    <AwesomeLabel
                      size="duotone"
                      label={' '}
                      icon="check"
                      title="Follow-Up Completed"
                    />
                    <Field
                      component={Checkbox}
                      name="followUpComplete"
                      label={' '}
                    />
                  </div>
                </div>
                {/* <div className="col-md-12 mt-3">
                  <Field
                    component={Checkbox}
                    name={"followUpComplete"}
                    label={"Follow-Up Completed"}

                    // removes followup button from sidebar tab & notes go into Patient Notes
                  />
                </div> */}
                <div className="row p-3">
                  <div className="col-12" style={{ textAlign: 'center' }}>
                    <button type="submit" className="k-button pageButton">
                      Update
                    </button>
                  </div>
                </div>
              </form>
            )}
          />
        </Dialog>
      )}
    </>
  );
};

export default FollowUp;
