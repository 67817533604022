// COMMON MEASUREMENTS
export const MCG = 'mcg';
export const MG = 'mg';
export const ML = 'ml';
export const LITER = 'l';
export const GRAMM = 'g';
export const KG = 'kg';
export const IU = 'iu';
export const G_PER_KG = 'g_per_kg';
export const MG_PER_KG = 'mg_per_kg';
export const MCG_PER_KG = 'mcg_per_kg';

// RARE MEASUREMENTS
export const EACH = 'each';
export const PCT = 'pct';
export const UNITS = 'units';

// DEFAULT LIST OF SUPPORTED UOMS
export const defaultUOMs = [
  ML,
  MCG,
  MG,
  GRAMM,
  KG,
  IU,
  G_PER_KG,
  MG_PER_KG,
  MCG_PER_KG
];

// MAPPING RULES TO SUPPORT
export const mapUOMs = uomList => {
  if (uomList && uomList.length) {
    const mappedUOMs = [];
    const uomArr = uomList.split(',');

    uomArr.forEach(unitOfMeas => {
      if (unitOfMeas === GRAMM) {
        mappedUOMs.push(GRAMM);
        mappedUOMs.push(G_PER_KG);
      } else if (unitOfMeas === MG) {
        mappedUOMs.push(MG);
        mappedUOMs.push(MG_PER_KG);
      } else if (unitOfMeas === MCG) {
        mappedUOMs.push(MCG);
        mappedUOMs.push(MCG_PER_KG);
      } else {
        mappedUOMs.push(unitOfMeas);
      }
    });

    return mappedUOMs;
  }

  return defaultUOMs;
};
