import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

// kendo
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';

// components
import Footer from '@/components/Footer';
import Notifier from '@/components/Notifier';
import SurveyEngine from '@/components/SurveyEngine';
import PatientHeader from './PatientHeader';
import StatusBar from '../StatusBar/StatusBar';
import { Patient } from './index';
import { Prescriber } from '../Prescriber';
import { Referral } from '../Referral';
import { CheckBenefits } from '../BenInvest';
import { PriorAuth } from '../PriorAuth';
// import { Payer } from '../Payer';
import { Fax } from '../FAX';
import { PatientPortalHeader } from './PatientPortalHeader';
import { AppointmentListData } from '../Appointments/AppointmentListData';
import PatientWorkLog from './PatientWorkLog';
import Alert from '@/components/common-components/Alert';

// gql
import { connectToGraphqlAPI } from '@/provider';
import { getPatientBucket, getLocationAic } from '@/graphql/queries';
import { getCase } from '@/graphql_case/queries';

// context
import {
  LogContext,
  PatientContext,
  StatusContext,
  UserContext
} from '@/context';

const PatientPortal = (props) => {
  const { noteRefresh, appointmentListRefresh } = useContext(StatusContext);
  const { logApiException } = useContext(LogContext);
  const [maxDate, setMaxDate] = useState();
  const history = useHistory();
  const [referralIdInfo, setReferralId] = useState();

  const {
    selectedPatientInfo,
    setSelectedPatientInfo,
    showPatientFaxDocument,
    getPatientProgrammsCall,
    selectedTab,
    setSelectedTab
  } = useContext(PatientContext);

  const { agent } = useContext(UserContext);

  const [refreshPatientDocs, setRefreshPatientDocs] = useState(false);
  const [locationName, setlocationName] = useState(false);

  // REDIRECT TO HOME IF NO PATIENT ID EXISTS
  if (!selectedPatientInfo?.patientId) {
    history.push('/');
  }

  const [getCaseData, setGetCaseData] = useState({});

  const { searchType = 'Patient' } = props.location.state || {
    searchType: null
  };

  // CHOOSE/NAVIGATE TO SUB-TAB
  let activeTab = 0;

  if (searchType === 'PATIENT') {
    activeTab = 0;
  } else if (searchType === 'PRESCRIBER') {
    activeTab = 1;
  } else if (searchType === 'REFERRAL') {
    activeTab = 2;
  } else if (searchType === 'BEN_INV') {
    activeTab = 3;
  } else if (searchType === 'PRIOR_AUTH') {
    activeTab = 4;
  } else if (searchType === 'PAYOR') {
    activeTab = 5;
  } else if (searchType === 'FAX') {
    activeTab = 6;
  } else if (searchType === 'CALENDAR') {
    activeTab = 7;
  }

  useEffect(() => {
    if (activeTab !== 0) {
      setSelectedTab(activeTab);
    } else {
      setSelectedTab(0);
    }
  }, []);

  const handleSelect = (e) => {
    setSelectedTab(e.selected);
  };

  const fetchInboundData = async () => {
    const data = await connectToGraphqlAPI({
      graphqlQuery: getCase,
      variables: { caseId: selectedPatientInfo.patientId }
    });

    if (data && data.data && data.data.getCase) {
      setGetCaseData(data.data.getCase);
    }
  };

  useEffect(() => {
    getPatientBucketCall();
  }, [noteRefresh, appointmentListRefresh]);

  useEffect(() => {
    if (selectedPatientInfo?.patientId) {
      fetchInboundData();
    }
  }, [selectedPatientInfo]);

  useEffect(() => {
    if (history?.location?.state?.redirect2PatientInfo) {
      setSelectedTab(0);
    }
    if (history?.location?.state?.redirect2NewOrder) {
      setSelectedTab(2);
    }
    if (history?.location?.state?.redirect2CurrentOrders) {
      setSelectedTab(2);
    }
    if (history?.location?.state?.redirect2BI) {
      setSelectedTab(3);
    }
    if (history?.location?.state?.redirect2PA) {
      setSelectedTab(4);
    }
  }, [history]);

  // the callback from child to parent
  const sendDataToParent = () => {
    setRefreshPatientDocs(true);
    getPatientBucketCall();
  };
  // TODO: get rid of this call, can use data from LocationContext
  const getPatientHeaderDetailsCall = async (locationId) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getLocationAic,
        variables: { id: locationId }
      });

      if (data && data.data && data.data.getLocationAIC) {
        const headerDetails = {
          locationInfo: data.data.getLocationAIC
        };
        setlocationName(headerDetails?.locationInfo?.locationName);
      }
    } catch (error) {
      console.error(
        'PatientPortal::getInfusionHeaderDetailsCall error:',
        error
      );
      logApiException(error, {
        view: 'PatienPortal',
        endpoint: 'getLocationAic'
      });
    }
  };

  const getPatientBucketCall = async () => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getPatientBucket,
        variables: { patientId: selectedPatientInfo.patientId }
      });
      const order = data.data.getPatientBucket?.referral?.drugReferrals?.map(
        (e) => e.referralOrder?.orderDate
      );

      if (data?.data?.getPatientBucket) {
        const pBucket = data.data.getPatientBucket;
        setSelectedPatientInfo(pBucket);

        await getPatientProgrammsCall(
          agent.agentId,
          selectedPatientInfo.patientId
        );
      }

      if (order !== undefined) {
        if (order.length !== 0) {
          const maxDates = order.reduce((first, second) =>
            first > second ? first : second
          );
          setMaxDate(maxDates);
        }
      }
    } catch (error) {
      console.error('PatientPortal::getPatientBucketCall error:', error);
      logApiException(error, {
        view: 'PatienPortal',
        endpoint: 'getPatientBucket'
      });
    }
  };

  // TODO: refactor this part, using map is redundant
  useEffect(() => {
    return selectedPatientInfo?.referral?.drugReferrals?.map((item) => {
      if (item?.referralOrder?.orderDate === maxDate) {
        setReferralId(item?.referralId);
      }
      return item;
    });
  }, [maxDate, selectedPatientInfo]);

  // TODO: refactor this part, using map is redundant
  useEffect(() => {
    return selectedPatientInfo?.benefitInvestigation?.benefitCheckings.forEach(
      (item) => {
        if (referralIdInfo !== undefined) {
          if (item?.referralId === referralIdInfo) {
            getPatientHeaderDetailsCall(item?.checkings[0].selectedLocationId);
          }
        }
      }
    );
  }, [referralIdInfo, selectedPatientInfo]);

  return (
    <div className='portal-container'>
      <div className='flex-container'>
        <div className='side-bar'>
          <StatusBar
            getCaseData={getCaseData}
            selectedPatientInfo={selectedPatientInfo}
            sendDataToParent={sendDataToParent}
          />
        </div>
        <div className='main-content p-0'>
          <PatientPortalHeader />
          <PatientHeader
            getCaseData={getCaseData}
            selectedPatientInfo={selectedPatientInfo}
            sendDataToParent={sendDataToParent}
            refreshPatientDocs={refreshPatientDocs}
            locationName={locationName}
          />

          <div className='patient-portal navBar1'>
            <TabStrip selected={selectedTab} onSelect={handleSelect}>
              <TabStripTab contentClassName='navBar2' title='PATIENT PROFILE'>
                <div className='tabText'>
                  <Patient
                    selectedPatientInfo={selectedPatientInfo}
                    sendDataToParent={sendDataToParent}
                  />
                </div>
              </TabStripTab>
              <TabStripTab
                contentClassName='navBar2'
                title='PRESCRIBER'
                disabled={!selectedPatientInfo}
              >
                <div className='tabText'>
                  <Prescriber
                    selectedPatientInfo={selectedPatientInfo}
                    sendDataToParent={sendDataToParent}
                  />
                </div>
              </TabStripTab>
              <TabStripTab
                contentClassName='navBar2'
                title='REFERRAL'
                disabled={!selectedPatientInfo}
              >
                <div className='tabText'>
                  <Referral
                    selectedPatientInfo={selectedPatientInfo}
                    sendDataToParent={sendDataToParent}
                  />
                </div>
              </TabStripTab>
              <TabStripTab
                contentClassName='navBar2'
                title='BENEFITS INV'
                disabled={!selectedPatientInfo}
              >
                <div className='tabText'>
                  <CheckBenefits
                    selectedPatientInfo={selectedPatientInfo}
                    sendDataToParent={sendDataToParent}
                  />
                </div>
              </TabStripTab>
              <TabStripTab
                contentClassName='navBar2'
                title='PRIOR AUTH'
                disabled={!selectedPatientInfo}
              >
                <div className='tabText'>
                  <PriorAuth
                    selectedPatientInfo={selectedPatientInfo}
                    sendDataToParent={sendDataToParent}
                  />
                </div>
              </TabStripTab>
              <TabStripTab
                contentClassName='navBar2'
                title='PAYOR'
                disabled={!selectedPatientInfo}
              >
                <div className='tabText'>
                  {/* <Payer
                    selectedPatientInfo={selectedPatientInfo}
                    sendDataToParent={sendDataToParent}
                  /> */}
                  <div style={{ padding: '30px 0', textAlign: 'center' }}>
                    <Alert>This functionality is currently disabled</Alert>
                  </div>
                </div>
              </TabStripTab>
              <TabStripTab
                contentClassName='navBar2'
                title='FAX'
                disabled={!selectedPatientInfo}
              >
                <div className='tabText'>
                  <Fax
                    selectedPatientInfo={selectedPatientInfo}
                    sendDataToParent={sendDataToParent}
                  />
                </div>
              </TabStripTab>
              <TabStripTab
                contentClassName='navBar2'
                title='APPOINTMENTS'
                disabled={!selectedPatientInfo}
              >
                <div className='tabText'>
                  <AppointmentListData
                    selectedPatientInfo={selectedPatientInfo}
                    sendDataToParent={sendDataToParent}
                  />
                </div>
              </TabStripTab>
              <TabStripTab
                contentClassName='navBar2'
                title='LOG'
                disabled={!selectedPatientInfo}
              >
                <div className='tabText'>
                  <PatientWorkLog />
                </div>
              </TabStripTab>
            </TabStrip>
          </div>
        </div>
      </div>
      {selectedPatientInfo && selectedPatientInfo.documentURI && (
        <button
          type='submit'
          onClick={showPatientFaxDocument}
          className='k-button pageTab doc-fixed-right'
        >
          Patient Document
        </button>
      )}
      <footer className='footer'>
        <Footer />
        <Notifier />
        <SurveyEngine />
      </footer>
    </div>
  );
};

export default PatientPortal;
