import {
  INTAKE_QUEUE,
  INFUSION_QUEUE,
  SCHEDULING_QUEUE,
  NURSING_QUEUE,
  CALENDAR,
  INVENTORY,
  DATA_ADMIN,
  BILLING_QUEUE,
  CLINICIAN_QUEUE,
  LOG_OUT,
  BI_QUEUE,
  PA_QUEUE,
  RELEASES,
  PATIENT_CALL,
  FAILED_FAX_QUEUE,
  DASHBOARD,
  INTAKE_QUEUE_NEW,
  BI_QUEUE_NEW,
  PA_QUEUE_NEW,
  MAIN_QUEUE_NEW,
  SALES_QUEUE_NEW,
  INBOUND_FAX_QUEUE_NEW,
  WIDGETS
} from './userMenu';
import roles from './enumUserRoles';

const fullAccessMenu = [
  INTAKE_QUEUE,
  INFUSION_QUEUE,
  SCHEDULING_QUEUE,
  NURSING_QUEUE,
  CALENDAR,
  INVENTORY,
  DATA_ADMIN,
  BILLING_QUEUE,
  CLINICIAN_QUEUE,
  BI_QUEUE,
  PA_QUEUE,
  PATIENT_CALL,
  RELEASES,
  FAILED_FAX_QUEUE,
  LOG_OUT,
  DASHBOARD,
  INTAKE_QUEUE_NEW,
  BI_QUEUE_NEW,
  PA_QUEUE_NEW,
  MAIN_QUEUE_NEW,
  SALES_QUEUE_NEW,
  INBOUND_FAX_QUEUE_NEW,
  WIDGETS,
  LOG_OUT
];

const getUserMenuAccess = (getAgentResponse) => {
  const userRoles = getAgentResponse?.data?.getAgent?.roles;
  const hasRole = (role) => userRoles.includes(role);

  if (hasRole(roles.supervisor) || hasRole(roles.admin)) {
    return fullAccessMenu;
  }

  if (hasRole(roles.intake)) {
    return [
      INTAKE_QUEUE,
      INTAKE_QUEUE_NEW,
      BI_QUEUE_NEW,
      PA_QUEUE_NEW,
      MAIN_QUEUE_NEW,
      INFUSION_QUEUE,
      SCHEDULING_QUEUE,
      NURSING_QUEUE,
      INVENTORY,
      BILLING_QUEUE,
      BI_QUEUE,
      PA_QUEUE,
      CALENDAR,
      RELEASES,
      INBOUND_FAX_QUEUE_NEW,
      WIDGETS,
      LOG_OUT
    ];
  }

  if (hasRole(roles.schedule)) {
    return [
      INTAKE_QUEUE,
      INTAKE_QUEUE_NEW,
      BI_QUEUE_NEW,
      PA_QUEUE_NEW,
      MAIN_QUEUE_NEW,
      INFUSION_QUEUE,
      SCHEDULING_QUEUE,
      NURSING_QUEUE,
      INVENTORY,
      CALENDAR,
      RELEASES,
      LOG_OUT
    ];
  }

  if (hasRole(roles.nursing)) {
    return [
      INTAKE_QUEUE,
      INTAKE_QUEUE_NEW,
      BI_QUEUE_NEW,
      PA_QUEUE_NEW,
      MAIN_QUEUE_NEW,
      INFUSION_QUEUE,
      SCHEDULING_QUEUE,
      NURSING_QUEUE,
      CALENDAR,
      RELEASES,
      LOG_OUT
    ];
  }

  if (hasRole(roles.clinician)) {
    return [
      INTAKE_QUEUE,
      INTAKE_QUEUE_NEW,
      BI_QUEUE_NEW,
      PA_QUEUE_NEW,
      MAIN_QUEUE_NEW,
      SCHEDULING_QUEUE,
      CLINICIAN_QUEUE,
      CALENDAR,
      RELEASES,
      LOG_OUT
    ];
  }

  if (hasRole(roles.inventory)) {
    return [
      INTAKE_QUEUE,
      INTAKE_QUEUE_NEW,
      BI_QUEUE_NEW,
      PA_QUEUE_NEW,
      MAIN_QUEUE_NEW,
      SCHEDULING_QUEUE,
      INVENTORY,
      CALENDAR,
      RELEASES,
      LOG_OUT
    ];
  }

  if (hasRole(roles.billing)) {
    return [INTAKE_QUEUE, INTAKE_QUEUE_NEW, BILLING_QUEUE, RELEASES, LOG_OUT];
  }

  if (hasRole(roles.sales)) {
    return [INTAKE_QUEUE, INTAKE_QUEUE_NEW, SALES_QUEUE_NEW, RELEASES, LOG_OUT];
  }

  if (hasRole(roles.bi)) {
    return [
      BI_QUEUE,
      BI_QUEUE_NEW,
      PA_QUEUE_NEW,
      MAIN_QUEUE_NEW,
      RELEASES,
      LOG_OUT
    ];
  }

  if (hasRole(roles.pa)) {
    return [
      PA_QUEUE,
      BI_QUEUE_NEW,
      PA_QUEUE_NEW,
      MAIN_QUEUE_NEW,
      RELEASES,
      LOG_OUT
    ];
  }

  // DEFAULT - access to EVERYTHING
  return fullAccessMenu;
};

export default getUserMenuAccess;
