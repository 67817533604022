import React, { useEffect, useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';

import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';

import { Form, Field } from '@progress/kendo-react-form';
import { DatePicker } from '@progress/kendo-react-dateinputs';

import {
  ExcelExport,
  ExcelExportColumn,
  ExcelExportColumnGroup
} from '@progress/kendo-react-excel-export';
import * as moment from 'moment';
import { process } from '@progress/kendo-data-query';
import { Button } from '@progress/kendo-react-buttons';
import { MessageDialog } from '@/components/common-components/MessageDialog';

import { connectToGraphqlAPI } from '@/provider';
import {
  getWorkItemsFromNursingQueue,
  getPatientBucket
} from '@/graphql/queries';

// components
import AICLocationDropdown from '@/components/common-components/AICLocationDropdown';

// context
import { LocationContext, UserContext, PatientContext } from '@/context';

import PatientManualSearch from '../Agent/PatientManualSearch';

import {
  formatDateToAWS,
  formatDateToAWSDateTime,
  formatDateToDefault,
  formatTimeToDefault,
  adjustUTC
} from '@/common/DateHelper';
import { getTimeZoneForLocation } from '../Scheduling/getTimezoneForLocation';

import { getWorkItemStatusText } from '@/common/Mappers';

// filtering
import Preloader from '@/components/common-components/Preloader';
import { getDosageUOM } from '@/common/DoseHelper';
import { ColumnMenu } from '@/components/common-components/columnMenu';

const NurseQueue = (props) => {
  const { agent } = useContext(UserContext);
  const { setSelectedPatientInfo } = useContext(PatientContext);

  const _exporter = React.createRef();
  const exportExcel = () => {
    if (_exporter.current) {
      _exporter.current.save();
    }
  };

  const { aicActiveLocationsOnly, currentLocation, setCurrentLocation } =
    useContext(LocationContext);

  const [selectedLocation, setSelectedLocation] = useState(currentLocation);
  const [selectedDefaultLocation, setSelectedDefaultLocation] = useState(
    JSON.parse(localStorage.getItem('LOCATIONDATA')) || currentLocation
  );
  const [onChangeTrigger, setOnChangeTrigger] = useState();

  const [queueTableData, setQueueTableData] = useState([]);

  const [sort, setSort] = useState([{ field: 'patientId', dir: 'asc' }]);
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(100);
  const [dialogOption, setDialogOption] = useState({});

  const initialStartNewDate = new Date();
  const initialEndNewDate = new Date();
  initialEndNewDate.setDate(initialStartNewDate.getDate() + 14);

  const fromData = localStorage.getItem('FROM');
  const toData = localStorage.getItem('TO');
  const fromDate = fromData ? new Date(fromData) : initialStartNewDate;
  const toDate = toData ? new Date(toData) : initialEndNewDate;

  const initialStartDate = fromDate || initialStartNewDate;
  const initialEndDate = toDate || initialEndNewDate;

  /* const initialStartDate = new Date();
	const initialEndDate = new Date();
	initialEndDate.setDate(initialStartDate.getDate() + 14); */

  // filtering start
  const initialDataState = {};

  const [dataState, setDataState] = useState();
  const [loading, setLoading] = useState(false);
  const [orderingQueues, setOrderingQueues] = useState([]);
  const [resultState, setResultState] = React.useState(
    process(queueTableData, initialDataState)
  );

  const onDataStateChange = React.useCallback(
    (e) => {
      setDataState(e.dataState); // store for use
      setResultState(process(queueTableData, e.dataState));
    },
    [queueTableData]
  );

  useEffect(() => {
    // if (!loading) {
    setDataState(initialDataState);
    setResultState(process(queueTableData, initialDataState));
    // }
  }, [queueTableData]);

  // filtering end

  // Row expanding pieces
  const NursingDetails = (props) => {
    const NursingDetailItems = props.dataItem;

    return (
      <>
        <div>
          <strong>Admin Seq No. :</strong>{' '}
          {NursingDetailItems?.adminSequenceNumber}{' '}
        </div>
        <div>
          <strong>Cal.Dos:</strong> {NursingDetailItems?.adminStage?.calcDosage}{' '}
          <strong>UOM:</strong>{' '}
          {getDosageUOM(NursingDetailItems?.adminStage?.unitOfMeas)}{' '}
        </div>
        <div>
          <strong>Admin:</strong> {NursingDetailItems?.adminStage?.administer}{' '}
        </div>
        <div>
          <strong>Co-Pay(s):</strong> ${NursingDetailItems?.coPay}{' '}
        </div>
        <div>
          <strong>Event ID:</strong> {NursingDetailItems?.id}{' '}
        </div>
      </>
    );
  };

  const expandChange = (event) => {
    const newData = resultState.data.map((item) => {
      if (item.id === event.dataItem.id) {
        item.expanded = !event.dataItem.expanded;
      }
      return item;
    });
    setResultState(process(newData, dataState));
  };

  useEffect(() => {
    if (selectedDefaultLocation) {
      setLoading(true);
      const locationData = JSON.parse(localStorage.getItem('LOCATIONDATA'));
      const fromData = localStorage.getItem('FROM');
      const toData = localStorage.getItem('TO');
      getWorkItemsFromNursingQueueCall(locationData, fromData, toData);
    }
  }, []);

  const handleLocationChange = (event) => {
    setLoading(true);

    const selectedLocation = { ...event.location };
    // update in location context
    setCurrentLocation(selectedLocation);
    // additionally set the timezone
    selectedLocation.timezone = getTimeZoneForLocation(
      selectedLocation?.address?.zip
    );
    setSelectedLocation(selectedLocation);
    localStorage.setItem('FROM', event.fromDateRange);
    localStorage.setItem('TO', event.toDateRange);

    setSelectedDefaultLocation(selectedLocation);
    setOnChangeTrigger(!onChangeTrigger);
    getWorkItemsFromNursingQueueCall(
      selectedLocation,
      event.fromDateRange,
      event.toDateRange
    );
  };

  const handleLocationTreeChange = (selectedItem, formRenderProps) => {
    if (selectedItem?.id) {
      const selectedDefaultLocation = { ...selectedItem };
      // update in location context
      setCurrentLocation(selectedItem);
      formRenderProps.onChange('location', { value: selectedItem });

      selectedDefaultLocation.timezone = getTimeZoneForLocation(
        selectedDefaultLocation?.address?.zip
      );
      localStorage.setItem(
        'LOCATIONDATA',
        JSON.stringify(selectedDefaultLocation)
      );
      setSelectedDefaultLocation(selectedDefaultLocation);
      setOnChangeTrigger(!onChangeTrigger);
    }
  };

  const getWorkItemsFromNursingQueueCall = async (
    location,
    searchStartDate,
    searchEndDate
  ) => {
    try {
      if (agent.agentId) {
        // add one day to be INCLUSIVE of the search range, otherwise cuts off at MIDNIGHT (00:00:00) of searchEndDate and leaves off that days results
        const tempEndDate = new Date(searchEndDate);
        tempEndDate.setDate(tempEndDate.getDate() + 1);
        const response = await connectToGraphqlAPI({
          graphqlQuery: getWorkItemsFromNursingQueue,
          variables: {
            agentId: agent.agentId,
            locationId: location.id /* ? location.id : location,  */,
            // need to implement rule of displaying scheduled patients from current date to 14 days out
            period: {
              startDate: formatDateToAWSDateTime(
                formatDateToAWS(searchStartDate)
              ), // e.g. "2021-07-01T00:00:00.000Z",
              endDate: formatDateToAWSDateTime(formatDateToAWS(tempEndDate)) // e.g. "2021-09-30T00:00:00.000Z"
              // startDate: formatDateToAWSDateTime(searchStartDate), // e.g. "2021-07-01",
              // endDate: formatDateToAWSDateTime(tempEndDate) // e.g. "2021-09-30"
              // startDate: formatDateToAWS(), // e.g. "2021-07-01",
              // endDate: formatDateToAWS(moment().add(14, 'days')) // e.g. "2021-09-30"
            }
          }
        });
        setLoading(false);
        if (response?.data?.getWorkItemsFromNursingQueue?.workItems) {
          /// /////////////////////
          const list = response.data.getWorkItemsFromNursingQueue.workItems;
          // getWorkItemsFromNursingQueue API does NOT paginate (no nextToken), so leaving untested code here for day when API may be updated
          // let sNextToken = response.data.getWorkItemsFromNursingQueue.nextToken ? response.data.getWorkItemsFromNursingQueue.nextToken : null;
          // while (sNextToken != null)
          // {
          // 	try {
          // 		const data = await connectToGraphqlAPI({
          // graphqlQuery: getWorkItemsFromNursingQueue,
          // variables: {
          // 	agentId: agent.agentId,
          // 	locationId: location.id,
          // 	// need to implement rule of displaying scheduled patients from current date to 14 days out
          // 	period: {
          // 		startDate: formatDateToAWSDateTime(searchStartDate), // e.g. "2021-07-01",
          // 		endDate: formatDateToAWSDateTime(tempEndDate), // e.g. "2021-09-30"
          //		nextToken : sNextToken
          // 	}
          // },
          // 		});
          // 		console.log("ILIA getWorkItemsFromNursingQueue data", data);

          // 		if (
          //			response &&
          //			response.data &&
          //			response.data.getWorkItemsFromNursingQueue &&
          //			response.data.getWorkItemsFromNursingQueue.workItems
          // 		) {
          // 			sNextToken = response.data.getWorkItemsFromNursingQueue.nextToken ? response.data.getWorkItemsFromNursingQueue.nextToken : null;
          // 			list = list.concat(response.data.getWorkItemsFromNursingQueue.workItems);
          // 			console.log("ILIA: GLIST", list);
          // 		}
          // 	} catch (err) {
          // 		console.log("ILIA getWorkItemsFromNursingQueue err", err);
          // 		sNextToken = null; // set to stop iteratinggit push
          // 	}
          // }
          /// ////////////////

          const filteredQueueData = list.filter((item) => {
            if (item.chairId === 'c000') {
              return false;
            }
            if (!item.orderName) {
              return false;
            }
            return true;
          });
          const tempQueue = filteredQueueData
            .filter((item) => {
              // instead of comparing EXACT date and TIME, we now just show all appt that match the DATE
              // let newDate = new Date();
              // return moment(formatDateToDefault(newDate.toString())).isSameOrBefore(formatDateToDefault(item.scheduledStartTime));
              return moment(
                formatDateToDefault(searchStartDate.toString())
              ).isSameOrBefore(formatDateToDefault(item.scheduledStartTime));
            })
            .map((item) => {
              item.scheduledStartDate = formatDateToDefault(
                item.scheduledStartTime
              );
              item.scheduledStartTime = formatTimeToDefault(
                adjustUTC(item.scheduledStartTime, location?.timezone)
              );
              item.scheduledEndTime = formatTimeToDefault(
                adjustUTC(item.scheduledEndTime, location?.timezone)
              );
              item.patientName = `${item.patientFirstName} ${item.patientLastName}`;
              item.dob = formatDateToDefault(item.dob);
              item.displayStatus = getWorkItemStatusText(item.status);
              return item;
            });
          setQueueTableData(tempQueue);
        }
      }
    } catch (err) {
      console.log('marty getWorkItemsFromNursingQueueCall err', err);
      setLoading(false);
      setDialogOption({
        title: 'Nursing Queue',
        message: 'Error: getWorkItemsFromNursingQueueCall',
        showDialog: true
      });
    }
  };

  const pageChange = (event) => {
    setSkip(event.page.skip);
    setTake(event.page.take);
  };

  const onRowClickHandle = (e) => {
    // alert(`SEND AGENT TO ID: ${e.dataItem.id}`)
    if (agent.agentId) {
      // acquireWorkApiCall(
      // 	{
      // 		agentId: agent.agentId, //user.username,
      // 		caseId: e.dataItem.patientId,
      // 		workItemId: e.dataItem.id,
      // 		actionToTake: 'EDIT'
      // 	},
      // 	e.dataItem.patientId,
      // 	e.dataItem.currentStage,
      // )
      getPatientBucketData(
        e.dataItem.patientId,
        'CALENDAR', // e.dataItem.currentStage
        e.dataItem.scheduledStartDate,
        selectedDefaultLocation.id
          ? selectedDefaultLocation.id
          : selectedLocation.id
      );
    }
  };

  const getPatientBucketData = async (
    patientId,
    currentStage,
    searchDate,
    searchLocationId
  ) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getPatientBucket,
        variables: { patientId }
      });
      if (data && data.data && data.data.getPatientBucket) {
        setSelectedPatientInfo(data.data.getPatientBucket);
        props.history.push('/scheduler', {
          searchType: currentStage,
          searchDate,
          searchLocationId,
          locationName: selectedDefaultLocation?.locationName,
          state: selectedDefaultLocation?.state,
          chairData: selectedDefaultLocation?.chairs
        });
      } else {
        setDialogOption({
          title: 'Nursing Queue: Get Patient',
          message: 'No Patient Record Found',
          showDialog: true
        });
      }
    } catch (err) {
      console.log('marty getPatientBucketData err', err);
      // setDialogOption({
      // 	title: 'Search Patient',
      // 	message: err.errors[0].message,
      // 	showDialog: true,
      // })
      if (err && err.errors && err.errors.length > 0 && err.errors[0].message) {
      }
    }
  };

  const gridEmptyRow = (tableData) => {
    const rows = [];
    if (tableData.length < 100) {
      for (let i = tableData.length, l = 100; i < l; i++) {
        rows.push({
          scheduledStartDate: null,
          scheduledStartTime: null,
          scheduledEndTime: null,
          patientName: null,
          patientId: null,
          orderName: null,
          orderType: null,
          route: null,
          chairId: null,
          orderStatus: null,
          status: null,
          id: null
        });
      }
    }
    return rows;
  };

  const RequiredFieldsValidator = (values) => {
    if (!values?.location) {
      return {
        location: 'location blah blah.'
      };
    }
    if (!values?.fromDateRange || !values?.toDateRange) {
      return;
    }
    if (values.toDateRange < values.fromDateRange) {
      return {
        toDateRange: 'End Date should be greater than the Start Date.'
      };
    }
  };

  const itemStatus = ({ dataItem }) => {
    const { displayStatus, reason } = dataItem;

    const isCancelled = displayStatus === 'Appointment Cancelled';
    const isInvalid = displayStatus === 'Invalid';

    const renderReasonHint = () => {
      return isCancelled || isInvalid ? (
        <>
          <br />
          <small>({reason})</small>
        </>
      ) : (
        <div />
      );
    };

    return (
      <td>
        {displayStatus}
        {renderReasonHint()}
      </td>
    );
  };

  return (
    <div className='container-fluid'>
      {dialogOption.showDialog && <MessageDialog dialogOption={dialogOption} />}
      <div className='row mt-2'>
        {/* <div className="col-md-1 mt-1">
					&nbsp;
				</div>
				<div className="col-md-2 mt-1  pageTitle">
					&nbsp;
				</div>
				<div className="col-md-2 mt-2  pageTitle">
				Today's Date: {getNow()}
				</div> */}
      </div>
      <div className='row'>
        <div className='col-8'>
          <Form
            initialValues={{
              fromDateRange: initialStartDate,
              toDateRange: initialEndDate,
              location: selectedDefaultLocation
            }}
            onSubmit={handleLocationChange}
            validator={RequiredFieldsValidator}
            render={(formRenderProps) => (
              <form
                onSubmit={formRenderProps.onSubmit}
                className='k-form pl-3 pr-3 pt-1'
              >
                <div className='row'>
                  {/* <div className="col-md-1 mt-3 pageTitle">SEARCH:</div> */}
                  <div className='col-md-2 mt-22'>
                    <Field
                      component={() => (
                        <AICLocationDropdown
                          data={aicActiveLocationsOnly}
                          value={currentLocation}
                          onChange={(selected) =>
                            handleLocationTreeChange(selected, formRenderProps)
                          }
                        />
                      )}
                      name='location'
                      label='LOCATION'
                    />
                  </div>
                  <div className='col-md-2 mt-10'>
                    From Date &nbsp;&nbsp;
                    <Field
                      component={DatePicker}
                      name='fromDateRange'
                      label=''
                    />
                  </div>
                  <div className='col-md-2 mt-10'>
                    To Date: &nbsp;
                    <Field component={DatePicker} name='toDateRange' label='' />
                  </div>
                  <div className='col-md-1 mt-22'>
                    <button type='submit' className='k-button blue'>
                      SEARCH
                    </button>
                  </div>
                </div>
              </form>
            )}
          />
        </div>
        <div className='col-md-3 mt-2 '>
          <PatientManualSearch
            searchLayout={2}
            searchEndPoint='/patient-portal'
            existingOnly
          />
        </div>
      </div>
      <div className='row ml-1 mr-1 mt-0'>
        <div className='col-12 col-lg-12 work-in-progress'>
          <div className='row'>
            <div className='col-11' />
            <div className='col-1 bright'>
              <Button
                style={{ marginLeft: '-18px' }}
                look='flat'
                onClick={exportExcel}
              >
                <i className='fa-solid fa-file-excel '>&nbsp;&nbsp;</i>
                Export to Excel
              </Button>
            </div>
          </div>
          <ExcelExport
            ref={_exporter}
            fileName='nursing-queue.xlsx'
            data={resultState.data}
          >
            <ExcelExportColumnGroup>
              <ExcelExportColumn
                field='locationName'
                title='Location'
                width={120}
              />
              <ExcelExportColumn
                field='scheduledStartDate'
                title='Appointment Date'
                width={180}
              />
              <ExcelExportColumn
                field='scheduledStartTime'
                title='Start Time'
                width={120}
              />
              <ExcelExportColumn
                field='scheduledEndTime'
                title='End Time'
                width={120}
              />
              <ExcelExportColumn
                field='patientName'
                title='Patient Name'
                width={180}
              />
              <ExcelExportColumn field='patientId' title='ID' width={150} />
              <ExcelExportColumn
                field='orderName'
                title='Order Name'
                width={150}
              />
              <ExcelExportColumn field='route' title='Route' width={100} />
              <ExcelExportColumn field='chairId' title='Chair' width={100} />
              <ExcelExportColumn
                field='orderStatus'
                title='Order Status'
                width={150}
              />
              <ExcelExportColumn
                field='displayStatus'
                title='Item Status'
                width={150}
                cell={itemStatus}
              />
              <ExcelExportColumn field='dob' title='DOB' width={120} />
              <ExcelExportColumn
                field='adminSequenceNumber'
                title='Admin Seq No'
                width={120}
              />
              <ExcelExportColumn
                field='adminStage.calcDosage'
                title='Cal. Dos'
                width={120}
              />
              <ExcelExportColumn
                field='adminStage.unitOfMeas'
                title='UOM'
                width={120}
              />
              <ExcelExportColumn
                field='adminStage.administer'
                title='Admin'
                width={160}
              />
              <ExcelExportColumn field='coPay' title='Co-Pay(s)' width={120} />
              <ExcelExportColumn field='id' title='Event ID' width={180} />
            </ExcelExportColumnGroup>
          </ExcelExport>
          <div className='a-grid__header'>
            <div>Nursing Queue</div>
          </div>

          <Preloader show={loading}>
            <Grid
              className='nurse-queue_grid'
              data={{ data: resultState.data }}
              onDataStateChange={onDataStateChange}
              {...dataState}
              /* groupable={true} */
              /* sortable={true} */
              /* pageable={true}
						pageSize={10}  */

              // data={orderBy(
              // 	queueTableData.slice(
              // 		skip,
              // 		take + skip
              // 	),
              // 	sort
              // ).concat(
              // 	gridEmptyRow(
              // 		queueTableData.slice(
              // 			skip,
              // 			take + skip
              // 		)
              // 	)
              // )}

              // skip={skip}
              // take={take}
              // total={queueTableData.length}
              // pageable={true}
              // onPageChange={pageChange}
              // sort={sort}
              onRowDoubleClick={(e) => {
                onRowClickHandle(e);
              }}
              // onSortChange={(e) => {setSort(e.sort)}}

              // expando row specifics
              expandField='expanded'
              onExpandChange={expandChange}
              detail={NursingDetails}
            >
              {/* Ask Ward about process then map to location name */}
              <Column
                field='locationName'
                title='Location'
                width={120}
                columnMenu={ColumnMenu}
              />
              <Column
                field='scheduledStartDate'
                title='Appointment Date'
                width={180}
                columnMenu={ColumnMenu}
              />
              <Column
                field='scheduledStartTime'
                title='Start Time'
                width={120}
                columnMenu={ColumnMenu}
              />
              <Column
                field='scheduledEndTime'
                title='End Time'
                width={120}
                columnMenu={ColumnMenu}
              />
              <Column
                field='patientName'
                title='Patient Name'
                width={180}
                columnMenu={ColumnMenu}
              />
              <Column
                field='patientId'
                title='ID'
                width={150}
                columnMenu={ColumnMenu}
              />
              <Column
                field='orderName'
                title='Order Name'
                width={150}
                columnMenu={ColumnMenu}
              />
              <Column
                field='route'
                title='Route'
                width={100}
                columnMenu={ColumnMenu}
              />
              <Column
                field='chairId'
                title='Chair'
                width={100}
                columnMenu={ColumnMenu}
              />
              <Column
                field='orderStatus'
                title='Order Status'
                width={150}
                columnMenu={ColumnMenu}
              />
              <Column
                field='displayStatus'
                title='Item Status'
                width={150}
                cell={itemStatus}
                columnMenu={ColumnMenu}
              />

              <Column
                field='dob'
                title='DOB'
                width={120}
                columnMenu={ColumnMenu}
              />
              {/* <Column field="id" title="Event ID" width={300} /> */}
            </Grid>
          </Preloader>
        </div>
      </div>
    </div>
  );
};

export default withRouter(NurseQueue);
