import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Drawer, DrawerItem } from '@progress/kendo-react-layout';
import cx from 'clsx';

// context
import { UserContext } from '@/context/UserContext';

// constants
import { menuItems } from './DrawerMenu.constants';

// styles
import styles from './DrawerMenu.module.scss';

const DrawerMenu = ({ expanded, onClose }) => {
  const [items, setItems] = useState(menuItems);

  const history = useHistory();

  const { showInMenu } = useContext(UserContext);

  const onSelect = (ev) => {
    const { id: itemId, ...restProps } = ev.itemTarget.props;
    const menuItem = items.find((item) => item.id === itemId);
    const currentItem = { ...restProps, ...menuItem };
    const isParent = currentItem['data-expanded'] !== undefined;
    const nextExpanded = !currentItem['data-expanded'];
    const newData = items.map((item) => {
      const { id, selected, 'data-expanded': currentExpanded, ...rest } = item;
      const isCurrentItem = currentItem.id === id;
      return {
        id,
        selected: isCurrentItem,
        'data-expanded':
          isCurrentItem && isParent ? nextExpanded : currentExpanded,
        ...rest
      };
    });

    if (!currentItem.isParent) {
      onClose();
    }
    if (currentItem.callback) {
      currentItem.callback();
    }

    history.push(currentItem.route);
    setItems(newData);
  };

  const generateDrawerItems = () => {
    const drawerItems = items.map((item) => {
      const { parentId, ...rest } = item;
      if (parentId !== undefined) {
        const parent = items.find(({ id }) => id === parentId);
        return {
          ...rest,
          visible: parent['data-expanded']
        };
      }
      return item;
    });
    return drawerItems.filter(({ key }) => (key ? showInMenu(key) : true));
  };

  const render = {
    drawerItem: ({ visible, icon, text, isParent, callback, ...props }) => {
      const isExpanded = props['data-expanded'];
      if (visible === false) return null;
      return (
        <DrawerItem className={styles.drawerItem} {...props}>
          <span className={`k-icon ${icon}`} />
          <span className="k-item-text">{text}</span>
          {isExpanded && (
            <span
              className={cx(
                styles.drawerItemIcon,
                'k-icon',
                `k-i-arrow-chevron-${isExpanded ? 'down' : 'right'}`
              )}
            />
          )}
        </DrawerItem>
      );
    }
  };

  return (
    <Drawer
      className={styles.drawerMenu}
      expanded={expanded}
      position="end"
      mode="overlay"
      items={generateDrawerItems()}
      item={render.drawerItem}
      onSelect={onSelect}
      onOverlayClick={onClose}
    />
  );
};

export { DrawerMenu };
