import { SCHEDULE_EVENTS } from '@/constants/enum';

/* Sets custom incrementes for timepicker component  */
export const steps = {
  /* hour: 1, */
  minute: 15
  /* second: 30, */
};

export const endTimeNotFifteenPastStartTimeValidator = (values) => {
  if (!values?.startTime || !values?.endTime) {
    return;
  }
  const diffInMinutes = Math.round(
    (values.endTime.getTime() - values.startTime.getTime()) / 60000
  );
  if (diffInMinutes < 15) {
    return {
      endTime: 'End Time should be 15 mintutes or more than the Start Time.'
    };
  }
};

export const CHAIR_TELEHEALTH = 'cTelehealth';

const reasonsPatientNotSeen = [
  'Archived Referral',
  'Auth Denied',
  'Auth Pending',
  'Billing Issue',
  'Clinical Reasons',
  'Cost Prohibitive',
  'Deceased',
  'Home Treatment',
  'Labs Needed',
  'MD Did Not Renew',
  'Moved Out of State',
  'New Insurance',
  'No Inventory',
  'No Reason Given',
  'No Show',
  'Pt follow-up w/ MDO',
  'Rx or PA Expired',
  'Schedule Optimization',
  'Sick',
  'Snowbird',
  'Surgery/Hospital',
  "Therapy DC'd",
  'Treatment from MDO',
  'Wrong Day'
];

export const getCancelReasons = (eventType) => {
  if (eventType === SCHEDULE_EVENTS.APPOINTMENT_TELEHEALTH) {
    const cloned = [...reasonsPatientNotSeen];
    cloned.push('Patient did not answer');
    return cloned.sort();
  }

  return reasonsPatientNotSeen;
};
