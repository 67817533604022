import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { process } from '@progress/kendo-data-query';
import { Field, Form } from '@progress/kendo-react-form';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { GridRowSelection } from '@/components/common-components/GridRowSelection';
import Preloader from '@/components/common-components/Preloader';
import YesNoCell from '../../Grid/YesNoCell';

import { connectToGraphqlAPI } from '@/provider';
import {
  getScheduledEventsForOrder,
  getScheduledEventsForOrderByDate
} from '@/graphql/queries';
import { requestForOrder } from '@/graphql/mutations';
import { UserContext } from '@/context/UserContext';

import {
  formatDateToAWSDateTime,
  formatDateToDefault
} from '@/common/DateHelper';

const OrderingQueue = () => {
  const { user } = useContext(UserContext);
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [orderingQueues, setOrderingQueues] = useState([]);

  const initialDataState = {};

  const [dataState, setDataState] = React.useState();
  const [resultState, setResultState] = React.useState(process(orderingQueues, initialDataState));

  const onDataStateChange = React.useCallback(
    (e) => {
      setDataState(e.dataState);
      setResultState(process(orderingQueues, e.dataState));
    },
    [orderingQueues]
  );

  const freeDrugCell = ({ dataItem }) => {
    return <YesNoCell boolVal={dataItem?.freeDrug} />;
  };

  const columns = [
    { field: 'appointmentDate', title: 'Scheduled Date', filterable: false },
    { field: 'aicLocation', title: 'AIC Location' },
    { field: 'patientName', title: 'Patient Name' },
    { field: 'orderName', title: 'Order Name' },
    { field: 'medications', title: 'Medication', filterable: false },
    {
      field: 'freeDrug',
      title: 'Free Drug',
      filterable: false,
      customCell: freeDrugCell
    },
    {
      field: 'appointmentStatus',
      title: 'Appointment Status',
      filterable: false
    },
    { field: 'orderStatus', title: 'Order Status', filterable: false },
    { field: 'isSelected', title: ' ', filterable: false }
  ];

  // MAIN INITIATOR
  useEffect(() => {
    getScheduledEventsForOrderCall();
  }, []);

  useEffect(() => {
    if (!loading) {
      setDataState(initialDataState);
      setResultState(process(orderingQueues, initialDataState));
    }
  }, [orderingQueues]);

  const handleRunReport = async (props) => {
    getScheduledEventsForOrderByDateCall(
      props.fromDateRange,
      props.toDateRange
    );
  };

  const getScheduledEventsForOrderByDateCall = async (
    fromDateRange,
    toDateRange
  ) => {
    setLoading(true);
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getScheduledEventsForOrderByDate,
        variables: {
          startDate: formatDateToAWSDateTime(fromDateRange),
          endDate: formatDateToAWSDateTime(toDateRange)
        }
      });

      setLoading(false);

      if (
        data &&
        data.data &&
        data.data.getScheduledEventsForOrder &&
        data.data.getScheduledEventsForOrder.scheduledEvents &&
        data.data.getScheduledEventsForOrder.scheduledEvents.length
      ) {
        setOrderingQueues(data.data.getScheduledEventsForOrder.scheduledEvents.map((item) => {
            item.appointmentDate = formatDateToDefault(item.appointmentDate);
            item.aicLocation = `${item.locationName} (ID: ${item.locationId})`;
            item.patientName = `${item.patientFirstName} ${item.patientLastName}`;
            item.medications = `${item.calcDose} ${item.unitOfMeasure}`;
            return item;
          }));
      }
    } catch (err) {
      console.log('marty getScheduledEventsForOrderCall err', err);
      setLoading(false);
    }
  };
  const getScheduledEventsForOrderCall = async () => {
    setLoading(true);
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getScheduledEventsForOrder
      });

      setLoading(false);

      if (
        data &&
        data.data &&
        data.data.getScheduledEventsForOrder &&
        data.data.getScheduledEventsForOrder.scheduledEvents &&
        data.data.getScheduledEventsForOrder.scheduledEvents.length
      ) {
        setOrderingQueues(data.data.getScheduledEventsForOrder.scheduledEvents.map((item) => {
            item.appointmentDate = formatDateToDefault(item.appointmentDate);
            item.aicLocation = `${item.locationName} (ID: ${item.locationId})`;
            item.patientName = `${item.patientFirstName} ${item.patientLastName}`;
            item.medications = `${item.calcDose} ${item.unitOfMeasure}`;
            return item;
          }));
      }
    } catch (err) {
      console.log('marty getScheduledEventsForOrderCall err', err);
      setLoading(false);
    }
  };

  const selectOrdersHandler = useCallback(async () => {
    // filter out only selected + provide only fields that required by the schema
    const orderRequests = orderingQueues
      .filter((row) => row.isSelected)
      .map((order) => {
        return {
          eventId: order.eventId,
          locationId: order.locationId,
          locationName: order.locationName,
          patientId: order.patientId,
          patientLastName: order.patientLastName,
          patientFirstName: order.patientFirstName,
          referralId: order.referralId,
          orderName: order.orderName,
          calcDose: order.calcDose,
          unitOfMeasure: order.unitOfMeasure,
          freeDrug: order.freeDrug
        };
      });

    // sending selected orders to server
    const res = await connectToGraphqlAPI({
      graphqlQuery: requestForOrder,
      variables: {
        agentId: user.username,
        orderRequests
      }
    });

    // redirect to ORDER PRODUCT inventory tab
    history.push({
      pathname: '/inventory',
      state: {
        searchType: 'OrderProduct',
        orders: res.data?.requestForOrder?.orders || [],
        eventsForOrder: res.data?.requestForOrder?.eventsForOrder || []
      }
    });
  });

  return (
    <div className="container-fluid">
      <Form
        onSubmit={handleRunReport}
        render={(formRenderProps) => (
          <form onSubmit={formRenderProps.onSubmit}>
            <div className="row">
              <div
                className="col-2 col-md-3 pageTitle"
                style={{
                  marginTop: '30px',
                  marginLeft: '16px',
                  marginRight: '-50px'
                }}
              >
                ORDER SELECTION
              </div>
              <div
                className="col-2 col-md-4 mt-06"
                style={{ marginRight: '-50px', marginLeft: '-50px' }}
              >
                <Field
                  component={DatePicker}
                  name="fromDateRange"
                  label="From:"
                />
              </div>
              <div className="col-2 col-md-4 mt-06">
                <Field
                  component={DatePicker}
                  name="toDateRange"
                  label="To:"
                />
              </div>
              <div className="col-2 col-md-3" style={{ marginTop: '1.8rem' }}>
                <button type="submit" className="k-button blue">
                  Run Report
                </button>
              </div>
            </div>
          </form>
        )}
      />

      <div className="row">
        <div className="col-md-11 mt-2 ml-3">
          {/* <Form
          render={formRenderProps => (
            <form onSubmit={formRenderProps.onSubmit}>
              <div className="row col-md-11 mt-0 mb-3">
                <div className="col-md-2 mt-0">
                  FROM: &nbsp;
                  <Field
                    component={DatePicker}
                    name={"fromDateRange"}
                    label={""}
                  />
                </div>
                <div className="col-md-2 mt-0">
                  TO: &nbsp;
                  <Field
                    component={DatePicker}
                    name={"toDateRange"}
                    label={""}
                  />
                </div>
                <div className="col-md-2 mt-12">
                  <button type="submit" className="k-button blue">
                    RUN REPORT
                  </button>
                </div>
              </div>
            </form>
          )}
        /> */}
          <Preloader show={loading}>
            <GridRowSelection
              columns={columns}
              data={{ data: resultState.data }}
              // filterable={true}
              sortable
              onDataStateChange={onDataStateChange}
              title="SCHEDULED PATIENTS"
              updateTableData={setOrderingQueues}
              {...dataState}
            />
            <div className="row">
              <div className="col">
                <button
                  type="button"
                  primary="true"
                  onClick={selectOrdersHandler}
                  className="k-button k-button pageButton mr-1 mt-3"
                >
                  Select Orders
                </button>
              </div>
            </div>
          </Preloader>
        </div>
      </div>
    </div>
  );
};

export default OrderingQueue;
