import {
  workItemPriorityMap,
  workStatusMap,
  workItemStatusMap,
  workItemCodeStatusMap,
  dashboardWorkStatusMap
} from './filterHelper';

export const getWorkItemTitle = (work) => {
  if (work in workItemStatusMap) {
    return workItemStatusMap[work];
  }
  return work;
};

export const getStatusTitle = (workStatus) => {
  if (workStatus in workStatusMap) {
    return workStatusMap[workStatus];
  }
  return workStatus;
};
export const getDashStatusTitle = (workStatus) => {
  if (workStatus in dashboardWorkStatusMap) {
    return dashboardWorkStatusMap[workStatus];
  }
  return workStatus;
};
export const getPriorityTitle = (priority) => {
  if (priority in workItemPriorityMap) {
    return workItemPriorityMap[priority];
  }
  return priority;
};
export const getWorkItemCodeTitle = (codeName) => {
  if (codeName in workItemCodeStatusMap) {
    return workItemCodeStatusMap[codeName];
  }
  return codeName;
};
/* Current reditection options for getPushObj
   redirect2PatientInfo
   redirect2NewOrder
   redirect2CurrentOrders
   redirect2BI
   redirect2PA */

export const getPushObj = (work) => {
  const pushObj = {
    searchType: 'Patient'
  };
  switch (work) {
    case 'BI_NETWORK':
    case 'BENEFIT_INVESTIGATION':
    case 'NETWORK_CHECKING':
      pushObj.redirect2BI = true;
      break;
    case 'PRIOR_AUTHORIZATION':
    case 'PA_DENIED':
    case 'PENDING_PA_APPROVAL':
    case 'EXTEND_PA_UNITS':
    case 'EXTEND_PA_VISITS':
    case 'EXTEND_PA':
      pushObj.redirect2PA = true;
      break;
    case 'NEW_REFERRAL':
    case 'PRE_ORDER':
      pushObj.redirect2NewOrder = true;
      break;
    case 'REFILL':
    case 'UPDATE_LAB_TESTS':
      pushObj.redirect2CurrentOrders = true;
      break;
    default:
      break;
  }
  return pushObj;
};
