import React, { createContext, useState, useEffect } from 'react';

import { connectToGraphqlAPI, getQueryClient } from '@/provider';
import { getInventoryByLocation, listLocationAICs } from '@/graphql/queries';

import {
  formatDateToDefault,
  formatDateTimeToDefault,
  adjustUTC,
  formatTimeToDefault
} from '@/common/DateHelper';

export const LocationContext = createContext();

const LocationContextProvider = ({ children, user }) => {
  const [aicLocations, setAicLocations] = useState([]); // the full list of ALL locations no matter of their status
  const [aicActiveLocationsOnly, setAicActiveLocationsOnly] = useState([]); // filtered AIC locations that have ACTIVE status only
  const [currentLocation, setCurrentLocation] = useState(null);
  const [locationInventory, setLocationInventory] = useState([]); // excludes items with QTY = 0
  const [locationFullInventory, setLocationFullInventory] = useState([]); // includes even items with QTY = 0
  const [showLocationHistory, setShowLocationHistory] = useState(false); // Ensure setShowLocationHistory is initialized
  const [inventoryLog, setInventoryLog] = useState(null);
  const [lastVisitedLocation, setLastVisitedLocation] = useState(
    localStorage.getItem('lastVisitedLocation') || 'Unknown'
  );
  const [loading, setLoading] = useState(false); // New loading state for get inventory button

  const listLocationCall = async () => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: listLocationAICs
      });

      if (data?.data?.listLocationAICs?.items) {
        const theData = data.data.listLocationAICs.items
          .map((location) => {
            location.selectorText = `${location.locationName}, ${location.state}`;
            location.selectorValue = location.id;

            return location;
          })
          .sort((a, b) => (a.locationName > b.locationName ? 1 : -1));

        setAicLocations(theData);

        // filter out INCATIVE locations
        const onlyActiveAICs = theData.filter((aic) => aic.status === 'ACTIVE');
        setAicActiveLocationsOnly(onlyActiveAICs);
      }
    } catch (err) {
      alert('Failed to get AIC location list from the server.');
    }
  };

  const getLocationInventory = async (locationId) => {
    try {
      setLoading(true);
      const data = await getQueryClient({
        query: getInventoryByLocation,
        payload: { locationId },
        path: 'getInventoryByLocation'
      });

      if (data?.length > 0) {
        const invItems = data.map((item) => ({
          ...item,
          shippedDate: formatDateToDefault(item.shippedDate),
          displayStrengthUoM: `${item.strengthPerVial} ${item.unitOfMeasure}`,
          received: formatDateTimeToDefault(item.receivedTimestamp)
        }));

        updateLocationInventory(invItems);
      } else {
        updateLocationInventory([]);
      }
    } catch (err) {
      console.error('LocationContext::getLocationInventory err:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // action fires only if user logged in
    if (user) {
      listLocationCall();
    }
  }, [user]);

  useEffect(() => {
    if (currentLocation) {
      localStorage.setItem('lastVisitedLocation', currentLocation.selectorText);
      setLastVisitedLocation(currentLocation.selectorText);
    }
  }, [currentLocation]);

  const getLocation = (locationId) =>
    aicLocations.find(({ id }) => id === locationId);

  // get date in UTC and adjust it by passed location timezone
  const getDateByLocation = (locationId, date) => {
    const location = getLocation(locationId);
    if (location) {
      return formatDateToDefault(adjustUTC(date, location.timeZone));
    }
    return 'N/A';
  };

  // get dateTime in UTC and adjust it by passed location timezone
  const getDateTimeByLocation = (id, dateTime) => {
    const location = getLocation(id);
    if (location) {
      return formatDateTimeToDefault(adjustUTC(dateTime, location.timeZone));
    }
    return 'N/A';
  };

  // get dateTime in UTC and adjust it by passed location timezone
  const getTimeByLocation = (locationId, dateTime) => {
    const location = getLocation(locationId);
    if (location) {
      return formatTimeToDefault(adjustUTC(dateTime, location.timeZone));
    }
    return 'N/A';
  };

  // get chairs by location id
  const getChairsByLocation = (id) => {
    const location = getLocation(id);

    return location.chairs;
  };

  // check if location is active
  const isLocationActive = (id) => {
    const location = getLocation(id);

    if (!location) return false;

    return location.status === 'ACTIVE';
  };

  const updateLocationInventory = (newInventory) => {
    // includes even items with QTY = 0
    setLocationFullInventory(newInventory);

    // excludes items with QTY = 0
    setLocationInventory(
      newInventory.filter((item) => item.currentQuantity > 0)
    );
  };

  return (
    <LocationContext.Provider
      value={{
        aicLocations,
        getLocation,
        getDateByLocation,
        getTimeByLocation,
        getChairsByLocation,
        getDateTimeByLocation,
        aicActiveLocationsOnly,
        currentLocation,
        setCurrentLocation,
        isLocationActive,
        locationInventory,
        getLocationInventory,
        locationFullInventory,
        lastVisitedLocation,
        setLastVisitedLocation,
        getLocation,
        loading,
        setLoading,
        showLocationHistory,
        setShowLocationHistory,
        aicActiveLocationsOnly,
        setInventoryLog,
        inventoryLog,
        updateLocationInventory
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};

export default LocationContextProvider;
