import React, { useContext } from 'react';

// kendo
import { Button } from '@progress/kendo-react-buttons';

// context
import { LocationContext } from '@/context';

// utils
import { compareDates } from '@/common/SortHelper';

const InventoryLocationHistoryLog = ({ log = [] }) => {
  const { setShowLocationHistory, setInventoryLog } =
    useContext(LocationContext);

  return (
    <Button
      look='flat'
      icon='book'
      onClick={() => {
        setInventoryLog(log.sort((a, b) => compareDates(a, b, 'updatedAt')));
        setShowLocationHistory(true);
      }}
    >
      Show History
    </Button>
  );
};

export default InventoryLocationHistoryLog;
