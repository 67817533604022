import React from "react";
import { PropTypes } from "prop-types";
import { DeductibleInfo } from "./DeductibleInfo";
import { formatDateToDefault } from "@/common/DateHelper";

const getFormattedDate = (date) => {
    return date ? formatDateToDefault(date) : date;
};



export const BenefitCheckings = ({ details }) => {


    


    const { results, timelyFilling } = details?.claims;

    const { city, county, state, streetName, zip } = details?.claims?.claimAddress;

    const {
        groupId,
        insuranceKey,
        isCompleted,
        paRequired,
        billingNPIForOutOfNetwork,
        billingNPINumber,
        billingOverrideReason,
        billingTaxId,
        billingTaxIdForOutOfNetwork,
        callCompletionTime,
        callReferenceNumber,
        policyId,
        predeterminationNeeded,
        representativeName,
        verifiedDate,
        selectedProviderId,
        selectedLocationId,
        selectedGroupId,
        selectedBillingNPI
    } = details;

    const {
        coPay,
        coveragePercentage,
        dedType,
        deductibleNotes,
        effectiveDate,
        loa,
        networkStatus,
        outOfNetworkBenefits,
        payerPhoneNumber,
        planName,
        planType,
        termDate,
        verificationMethod,
    } = details?.policy

    let overrideChoice = "";
    if (selectedBillingNPI !== undefined) {
      if (
        selectedBillingNPI == selectedProviderId
      ) {
        overrideChoice = "PROVIDER";
      } else {
        overrideChoice = "LOCATION";
      }
    } else {
      overrideChoice = "-";
    }
    
    const { paPhone, paSite, submitMethod } = details?.predetermination;

    let paRequiredTest = paRequired ? "Yes" : "No";
    let pdNeeded = predeterminationNeeded ? "Yes" : "No";
    let loaNeeded = loa ? "Yes" : "No";

    const verified = getFormattedDate(verifiedDate);
    const eDate = getFormattedDate(effectiveDate);
    const tDate = getFormattedDate(termDate);

    const insuranceName = insuranceKey.toUpperCase();

    return (
        <>
            <div className="container-fluid" style={{ backgroundColor: "white" }} >
                <div className="row" style={{ backgroundColor: "#e2e1e1" }}>
                    <div className="col-md-12 ml-1 page-break">
                        <b>BENEFITS-{insuranceName}</b>
                    </div>
                </div>

                <div className="row mt-3 ml-3" style={{ padding: 10 }} >
                    <div className="col-md-6">
                        <div className="row mb-1">
                            <b>Benefits Information:</b>
                        </div>
                        <div className="row billing-spacing" ><span className="billing-style">AIC Group:</span>&nbsp;  { billingTaxId || "-"}</div>
                        {/* <div className="row billing-spacing" ><span className="billing-style">AIC Location NPI ????:</span>&nbsp;{selectedLocationId || "-"} </div> */}
                        <div className="row billing-spacing" ><span className="billing-style">Provider NPI:</span>&nbsp; {selectedProviderId || "-"}</div>
                    </div>
                    <div className="col-md-6">
                        <div className="row mb-1">
                            <b>Override:</b>
                        </div>
                        <div className="row billing-spacing" ><span className="billing-style">Override TIN:</span>&nbsp;  {billingTaxIdForOutOfNetwork || "-"} </div>
                        <div className="row billing-spacing" ><span className="billing-style">Override NPI:</span>&nbsp; {billingNPIForOutOfNetwork || "-"} </div>
                        <div className="row billing-spacing" ><span className="billing-style">Override Reason:</span>&nbsp; {billingOverrideReason || "-"} </div>
                    </div>

                    
                </div>
                <div className="line">
                    <hr></hr>
                </div>
                <div className="row mt-3 ml-3" style={{ padding: 10 }} >
                    <div className="col-md-4">
                        <div className="row mb-1">
                            <b>Billing Information:</b>
                        </div>

                        <div className="row billing-spacing" ><span className="billing-style">Date Verified:</span>&nbsp;{verified || "-"} </div>
                        <div className="row billing-spacing" ><span className="billing-style">Call Reference:</span>&nbsp; {callReferenceNumber || "-"}</div>
                        <div className="row billing-spacing" ><span className="billing-style">Representative Name:</span>&nbsp;{representativeName || "-"} </div>
                        <div className="row billing-spacing" ><span className="billing-style">Location or Provider NPI: </span>&nbsp;{overrideChoice || "-"} </div>
                        <div className="row billing-spacing" ><span className="billing-style">Billing NPI: </span>&nbsp;{selectedBillingNPI || "-"} </div>
                        

                    </div>
                    <div className="col-md-4">
                        <div className="row mb-1">
                        <b style={{marginLeft: 14}}>Payer Information:</b>
                        </div>
                        <div className="row" className="billing-spacing"><span className="billing-style">Verified by:</span>&nbsp;{verificationMethod || "-"}</div>
                        <div className="row" className="billing-spacing"><span className="billing-style">Phone:</span>&nbsp;{payerPhoneNumber || "-"}</div>
                        <div className="row" className="billing-spacing"><span className="billing-style">Network Status:</span>&nbsp;{networkStatus || "-"} </div>
                        <div className="row" className="billing-spacing"><span className="billing-style">Out of Network Benefits:</span>&nbsp;{outOfNetworkBenefits || "-"} </div>
                        <div className="row" className="billing-spacing"><span className="billing-style">Plan Type</span>:&nbsp;{planType || "-"}</div>
                        <div className="row" className="billing-spacing"><span className="billing-style">Deductible Type:</span>&nbsp;{dedType || "-"}</div>
                        <div className="row" className="billing-spacing"><span className="billing-style">Effective Date:</span>&nbsp;{eDate || "-"}</div>
                        <div className="row" className="billing-spacing"><span className="billing-style">Term Date:</span>&nbsp;{tDate || "-"}</div>
                        <div className="row" className="billing-spacing"><span className="billing-style">Coverage:</span>&nbsp;{coveragePercentage || "-"}</div>
                        <div className="row" className="billing-spacing"><span className="billing-style">Co-pay:</span>&nbsp;{coPay || "-"}</div>
                    </div>

                    <div className="col-md-4">
                        <div className="row mb-1">
                            <b style={{marginLeft: 14}}>Prior Authorization:</b>
                        </div>
                        <div className="row" className="billing-spacing"><span className="billing-style">PA Required:</span>&nbsp;{paRequiredTest || "-"} </div> {/*  */}
                        <div className="row" className="billing-spacing"><span className="billing-style">Pre-Determination Needed:</span>&nbsp;{pdNeeded || "-"}</div>
                        <div className="row" className="billing-spacing"><span className="billing-style">LOA:</span>&nbsp;{loaNeeded || "-"}</div>
                        {/* <div className="row" className="billing-spacing"><span className="billing-style">LOA Number:</span>&nbsp;{"" || "-"}</div> */}


                        <div className="row mb-1 mt-20">
                            <b style={{marginLeft: 14}}>Claims Information:</b>
                        </div>
                        <div className="row" className="billing-spacing"><span className="billing-style">Address:</span>&nbsp;{streetName || "-"}</div>
                        {/* <div className="row" className="billing-spacing"><span className="billing-style">Address 2: ?? is not in API</span>&nbsp; </div> */}
                        <div className="row" className="billing-spacing"><span className="billing-style">City:</span>&nbsp;{city || "-"}</div>
                        <div className="row" className="billing-spacing"><span className="billing-style">State:</span>&nbsp; {state || "-"}</div>
                        <div className="row" className="billing-spacing"><span className="billing-style">Zip:</span>&nbsp;{zip || "-"} </div>
                        <div className="row" className="billing-spacing"><span className="billing-style">County:</span>&nbsp;{county || "-"} </div>
                        <div className="row" className="billing-spacing"><span className="billing-style">Timely Filing:</span>&nbsp;{timelyFilling || "-"} </div>
                        {/* <div className="row" className="billing-spacing"><span className="billing-style">Who is at risk for claims:???</span>&nbsp; </div> */}

                    </div>
                </div>
                <div className="line">
                    <hr></hr>
                </div>
                {/* <div className="row mb-20 ml-10" >
                    <b>Deductible Information:</b>
                </div> */}
                

                <div className="row mt-3 ml-3" >


                    {!details?.policy.deductibleInfo[0].deductibleType ? <p>Deductible information unavailable</p> :

                        !details?.policy?.deductibleInfo?.length
                            ? null
                            : details?.policy?.deductibleInfo?.map(
                                (info, index) => (
                                    <DeductibleInfo key={index} info={info} />
                                )
                            )

                    }

                </div>
                <div className="row mt-3 ml-3" style={{ padding: 10 }}>


                    <div className="col-md-4">



                    </div>


                </div>

                <div className="line">
                    <hr></hr>
                </div>


            </div>
        </>
    );
};
    

BenefitCheckings.propTypes = {
    details: PropTypes.object,
};
