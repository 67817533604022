import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

// kendo
import {
  Card,
  CardTitle,
  CardBody,
  CardActions
} from '@progress/kendo-react-layout';

// components
import AwesomeLabel from '@/components/common-components/AwesomeLabel';

// context
import { LocationContext } from '@/context';

// styles
import styles from './Widgets.module.scss';

const CalendarWidget = () => {
  const history = useHistory();

  const { aicActiveLocationsOnly, lastVisitedLocation } =
    useContext(LocationContext);

  return (
    <Card className={styles.card}>
      <CardBody>
        <CardTitle>
          <AwesomeLabel icon='calendar' label='Scheduling' />
        </CardTitle>
        <p className={styles.description}>Schedule patient appointments</p>
        <p>
          We have &#127973; {aicActiveLocationsOnly.length} active locations as
          of today
        </p>
        <p>
          Last AIC location you worked with:
          <br />
          <AwesomeLabel icon='location-dot' label={lastVisitedLocation} />
        </p>
      </CardBody>
      <CardActions className={styles.actions}>
        <span
          className='k-button k-button-md k-rounded-md k-button-flat k-button-flat-primary'
          onClick={() => history.push('/scheduler')}
        >
          <AwesomeLabel
            icon='arrow-right'
            label='Check Calendar'
            iconFirst={false}
          />
        </span>
      </CardActions>
    </Card>
  );
};

export default CalendarWidget;
