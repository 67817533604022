// converts LB to KG and rounds up the resulting value
export const lb2kg = (pounds) => {
  if (!pounds) return 0;

  return Math.round(pounds / 2.2);
};

// converts KG to LB and rounds up the resulting value
export const kg2lb = (kilos) => {
  if (!kilos) return 0;

  return Math.round(kilos * 2.2);
};

// take referral and return patient weight in KG
export const getPatientWeightFromReferral = (referralOrder) => {
  if (!referralOrder) return 0;

  const { patientWeight, unitOfWeight } = referralOrder;

  if (unitOfWeight === 'KG' || !unitOfWeight) {
    return patientWeight;
  } if (unitOfWeight === 'LB') {
    return lb2kg(patientWeight);
  }
};

// take referral and return patient weight in LB
export const getPatientWeightFromReferralLB = (referralOrder) => {
  if (!referralOrder) return 0;

  const { patientWeight, unitOfWeight } = referralOrder;

  if (unitOfWeight === 'LB' || !unitOfWeight) {
    return patientWeight;
  } if (unitOfWeight === 'KG') {
    return kg2lb(patientWeight);
  }
};

export const yesNoBoolean = (boolVal) => (boolVal ? 'Yes' : 'No');

export const toProperCase = (strVal) => {
  if (!strVal) return '';

  return strVal
    .replace(/([^\W_]+[^\s_]*) */g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    })
    .replace(/_/g, ' ');
};

export function capitalizeFirstWord(sentence) {
  // Capitalize the first letter of the sentence
  return sentence.charAt(0).toUpperCase() + sentence.slice(1);
}

export const formatZipCode = (zip) => {
  // Check if the input is a valid ZIP code (5 or 9 digits)
  if (/^\d{5}$/.test(zip)) {
    // Format as "XXXXX"
    return zip;
  }
  if (/^\d{9}$/.test(zip)) {
    // Format as "XXXXX-XXXX"
    return `${zip.slice(0, 5)}-${zip.slice(5)}`;
  }
  // Invalid ZIP code format, return the original input
  return zip;
};
