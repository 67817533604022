import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

// context
import { QueueFilterContext, PatientContext } from '@/context';

// components
import AwesomeLabel from '@/components/common-components/AwesomeLabel';

// styles
import styles from './ItemActions.module.scss';

const ReturnToQueue = () => {
  const { workItemQueueSelection } = useContext(QueueFilterContext);
  const { releasePatientLocker } = useContext(PatientContext);
  const history = useHistory();

  const handleSubmit = async () => {
    await releasePatientLocker();

    const type = workItemQueueSelection?.queueType || 'INTAKE';
    const path = `/work-queue/${type}`;
    history.push(path);
  };

  return (
    <button className={styles.minButtonWidth} onClick={handleSubmit}>
      <AwesomeLabel icon='backward' label='Go Back to Queue' />
    </button>
  );
};

export default ReturnToQueue;
