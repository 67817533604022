import React, { useContext } from 'react';

// context
import { WorkItemContext } from '@/context';

// components
import AwesomeLabel from '@/components/common-components/AwesomeLabel';

const WorkItemInsurance = () => {
  const { selectedWorkItem } = useContext(WorkItemContext);

  if (!selectedWorkItem) return null;

  const { attachedData } = selectedWorkItem;

  if (
    !!attachedData?.attachedData?.insurerName &&
    !!attachedData?.attachedData?.insurancePlanName
  ) {
    const { insurerName, insurancePlanName } = attachedData.attachedData;

    return (
      <div>
        <AwesomeLabel
          label={`${insurerName}, plan: ${insurancePlanName}`}
          icon='file-contract'
          title='Insurance'
        />
      </div>
    );
  }

  return null;
};

export default WorkItemInsurance;
