import React, { useContext, useState } from 'react';

// kendo
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { Dialog } from '@progress/kendo-react-dialogs';

// components
import FormButton from '@/components/common-components/Form/FormButton';

// utils
import AutoFilter from '@/common/AutoFilter';

// context
import { UserContext, InfoCodeContext, UserRoleTypes } from '@/context';

const generateDxLabel = (dx) => {
  if (dx?.description && dx?.primaryDiagnosis) {
    return `${dx.primaryDiagnosis} - ${dx.description}`;
  }

  return '';
};

const DxUpdateForm = ({ primaryDx, otherDx, toggleDialog, handleSubmit }) => {
  const { canEdit } = useContext(UserContext);
  const { dxCodes } = useContext(InfoCodeContext);

  const [loading, setLoading] = useState(false);
  const [touched, setTouched] = useState(false);

  const initialForm = () => {
    const initialObject = {};
    if (primaryDx) {
      initialObject['primaryDx'] = dxCodes.find(
        (listCodeInfo) => listCodeInfo.description === primaryDx.description
      );
    }

    if (otherDx) {
      if (otherDx[0]) {
        initialObject['otherDx1'] = dxCodes.find(
          (listCodeInfo) => listCodeInfo.description === otherDx[0].description
        );
      }

      if (otherDx[1]) {
        initialObject['otherDx2'] = dxCodes.find(
          (listCodeInfo) => listCodeInfo.description === otherDx[1].description
        );
      }
    }

    return initialObject;
  };

  const [selectedCodeDescription, setSelectedCodeDescription] = useState(
    initialForm()
  );

  const submitForm = () => {
    setLoading(true);

    const primaryDx = selectedCodeDescription['primaryDx'];
    const otherDX = [];

    const otherDx1 = selectedCodeDescription['otherDx1'];
    if (otherDx1) {
      otherDX.push({
        diagnosedBy: '',
        primaryDiagnosis: otherDx1?.code || '',
        description: otherDx1?.description || ''
      });
    }
    const otherDx2 = selectedCodeDescription['otherDx2'];
    if (otherDx2) {
      otherDX.push({
        primaryDiagnosis: otherDx2?.code || '',
        description: otherDx2?.description || '',
        diagnosedBy: ''
      });
    }

    handleSubmit({
      primaryDX: {
        primaryDiagnosis: primaryDx?.code || '',
        description: primaryDx?.description || '',
        diagnosedBy: ''
      },
      otherDX
    });
  };

  // only admins can see this
  if (!canEdit(UserRoleTypes.CurrentOrders)) return null;

  const isFormValid = () => {
    if (!touched) return false;
    // primary
    if (
      !selectedCodeDescription['primaryDx'] ||
      selectedCodeDescription['primaryDx'].code === ''
    )
      return false;
    // other
    if (
      selectedCodeDescription['otherDx1'] &&
      selectedCodeDescription['otherDx1'].code === ''
    )
      return false;
    if (
      selectedCodeDescription['otherDx2'] &&
      selectedCodeDescription['otherDx2'].code === ''
    )
      return false;

    return true;
  };

  const codeDescriptionChange = (value, name, formRenderProps) => {
    setTouched(true);
    let copySelectedCodeDescription = { ...selectedCodeDescription };
    const matchedDxCode = dxCodes.find(
      (listCodeInfo) => listCodeInfo.codeDescription === value
    );
    if (value && matchedDxCode) {
      if (copySelectedCodeDescription[name]) {
        copySelectedCodeDescription = {
          ...copySelectedCodeDescription,
          [name]: {
            ...copySelectedCodeDescription[name],
            code: matchedDxCode?.code || '',
            description: matchedDxCode?.description || ''
          }
        };
      } else {
        copySelectedCodeDescription = {
          ...copySelectedCodeDescription,
          [name]: {
            ...matchedDxCode,
            name
          }
        };
      }
      setSelectedCodeDescription(copySelectedCodeDescription);
      formRenderProps.onChange(name, {
        value
      });
    } else if (!value) {
      delete copySelectedCodeDescription[name];
      setSelectedCodeDescription(copySelectedCodeDescription);
      formRenderProps.onChange(name, {
        value: null
      });
    }
  };

  return (
    <Form
      onSubmit={submitForm}
      initialValues={initialForm()}
      render={(formRenderProps) => (
        <Dialog title='Update DX codes' onClose={toggleDialog} width={500}>
          <FormElement className='k-form pl-3 pr-3 pt-1'>
            <div className='row mt-12'>
              <h4>
                Primary DX <span style={{ color: 'red' }}>*</span>
              </h4>
              <div className='col-md-12'>
                <Field
                  name='primaryDx'
                  label='ICD10 Code'
                  placeholder='ICD10 Code'
                  component={AutoFilter}
                  data={dxCodes}
                  textField='codeDescription'
                  valueField='code'
                  savedValue={generateDxLabel(primaryDx)}
                  onChangeCallback={(value, name) =>
                    codeDescriptionChange(value, name, formRenderProps)
                  }
                />
              </div>
            </div>

            <div className='row mt-12'>
              <h4>Other DX</h4>
              <div className='col-md-12'>
                <Field
                  name='otherDx1'
                  label='ICD10 Code'
                  placeholder='ICD10 Code'
                  component={AutoFilter}
                  data={dxCodes}
                  textField='codeDescription'
                  valueField='code'
                  savedValue={generateDxLabel(otherDx[0])}
                  onChangeCallback={(value, name) =>
                    codeDescriptionChange(value, name, formRenderProps)
                  }
                />
              </div>
            </div>

            <div className='row mt-12'>
              <div className='col-md-12'>
                <Field
                  name='otherDx2'
                  label='ICD10 Code'
                  placeholder='ICD10 Code'
                  component={AutoFilter}
                  data={dxCodes}
                  textField='codeDescription'
                  valueField='code'
                  savedValue={generateDxLabel(otherDx[1])}
                  onChangeCallback={(value, name) =>
                    codeDescriptionChange(value, name, formRenderProps)
                  }
                />
              </div>
            </div>
            <hr />
            <div className='row pt-1'>
              <div className='col-12'>
                <FormButton
                  type='submit'
                  loading={loading}
                  label='Update'
                  className='k-button pageButton'
                  disabled={!isFormValid()}
                />
              </div>
            </div>
          </FormElement>
        </Dialog>
      )}
    />
  );
};

export default DxUpdateForm;
