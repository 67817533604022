export const BLUE = {
  primary: '#005282',
  button: '#4696e7'
};

export const RED = {
  primary: 'red',
  dark: 'darkred'
};

export const WHITE = {
  highGrad: '#f6f6f6',
  lowGrad: '#f1f1f1',
  plain: '#fff'
};

export const GRAY = {
  text: '#656565'
};

const COLORS = { BLUE, RED, WHITE, GRAY };

export default COLORS;
