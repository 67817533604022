import React, { useState, useContext, useMemo } from 'react';
import styled from 'styled-components';

// kendo
import { Grid } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { orderBy } from '@progress/kendo-data-query';
import { GridColumn as Column } from '@progress/kendo-react-grid/dist/npm/GridColumn';
import { Form, Field } from '@progress/kendo-react-form';
import { Input, Checkbox } from '@progress/kendo-react-inputs';
import { Dialog } from '@progress/kendo-react-dialogs';
import {
  ExcelExport,
  ExcelExportColumn,
  ExcelExportColumnGroup
} from '@progress/kendo-react-excel-export';

// common components
import AICLocationDropdown from '@/components/common-components/AICLocationDropdown';
import AwesomeLabel from '@/components/common-components/AwesomeLabel';
import Badge from '@/components/common-components/Badge';
import Preloader from '@/components/common-components/Preloader';
import InventoryLocationHistoryLog from '@/components/Header/components/ActiveWorkItems/components/InventoryLocationHistoryLog';

// partials
import AddProductToInventory from './SiteInventory/AddProductToInventory';
import FishbowlImport from './SiteInventory/FishbowlImport';
import BulkAdjustmentModal from './BulkAdjustmentModal';

// gql
import { reconcileInventory } from '@/graphql/mutations';
import { connectToGraphqlAPI } from '@/provider';

// context
import { LocationContext, UserContext } from '@/context';

// styled components
const TogglerWrapper = styled.div`
  margin: 10px 0 15px 0;
`;

const SiteInventory = () => {
  const { user } = useContext(UserContext);
  const {
    aicActiveLocationsOnly,
    currentLocation,
    setCurrentLocation,
    getLocationInventory,
    locationInventory,
    locationFullInventory,
    updateLocationInventory,
    loading
  } = useContext(LocationContext);

  const locations = useMemo(() => {
    let result = [];
    // get central.
    const centralLocation = aicActiveLocationsOnly?.find(
      (element) => element.id === '1'
    );
    if (centralLocation) {
      // create copy
      const centralCopy = { ...centralLocation };
      // modify copy and put back.
      centralCopy.id = '9999999';
      centralCopy.locationName = '(Main Inventory)';
      result = [...aicActiveLocationsOnly, centralCopy];
    }
    return result;
  }, [aicActiveLocationsOnly]);

  const [sort, setSort] = useState([{ field: 'productName', dir: 'asc' }]);
  const [showAddProduct, setShowAddProduct] = useState(false);
  const [showFishbowl, setShowFishbowl] = useState(false);
  const [visibleEditQtyDialog, setVisibleEditQtyDialog] = useState(false);
  const [currentSelRow, setCurrentSelRow] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [isRemoving, setIsRemoving] = useState(false);
  const [showHidden, setShowHidden] = useState(false);

  // use full or filtered inventory based on showHidden
  const inventoryItems = showHidden ? locationFullInventory : locationInventory;

  const onToggle = () => setShowAddProduct(!showAddProduct);
  const onToggleFishbowl = () => setShowFishbowl(!showFishbowl);

  // TODO: create a wrapper component instead of copy-pasting this logic everywhere
  const _exporter = React.createRef();
  const exportExcel = () => {
    if (_exporter.current) {
      _exporter.current.save();
    }
  };

  const toggleEditQtyDialog = () => {
    setVisibleEditQtyDialog(!visibleEditQtyDialog);
  };

  const handleEditQtyClick = (dataObject) => {
    toggleEditQtyDialog();
    setCurrentSelRow(dataObject);
  };

  const handleEditQtySubmit = async (dataItem) => {
    try {
      const reconcileRecords = [
        {
          afterQuantity: dataItem.newQuantity, // provided as string... how get to int?
          beforeQuantity: currentSelRow.currentQuantity,
          inventoryId: currentSelRow.id,
          reason: 'RECONCILE',
          updatedBy: user.username
        }
      ];

      await connectToGraphqlAPI({
        graphqlQuery: reconcileInventory,
        variables: {
          agentId: user.username,
          reconcileRecords
        }
      }).then(() => {
        toggleEditQtyDialog();

        const clonedFull = [...locationFullInventory];
        const currentItemFull = clonedFull.find(
          (item) => item.id === currentSelRow.id
        );

        if (currentItemFull) {
          currentItemFull.currentQuantity = dataItem.newQuantity;
          updateLocationInventory(clonedFull);
        }
      });
    } catch (err) {
      if (err && err.errors && err.errors.length > 0) {
        console.error('SiteInventory::handleEditQtySubmit error: ', err);
      }
    }
  };

  const selectAction = (dataItem) => {
    return (
      <td>
        {
          <div className='row'>
            <div className='col-md-4'>
              <Button
                look='flat'
                icon='edit'
                onClick={() => {
                  handleEditQtyClick(dataItem.dataItem);
                }}
              >
                Edit Qty
              </Button>
              {dataItem.dataItem.updateHistory && (
                <InventoryLocationHistoryLog
                  log={dataItem.dataItem.updateHistory}
                />
              )}
            </div>
          </div>
        }
      </td>
    );
  };

  const handleGetLocationInventoryClick = (formItems) => {
    getLocationInventory(formItems.location.id);
    setCurrentLocation(formItems.location);
  };

  const addProductCallback = () => {
    getLocationInventory(currentLocation?.id);
  };

  const handleLocationTreeChange = (selectedItem, formRenderProps) => {
    if (selectedItem?.id) {
      setCurrentLocation(selectedItem);
      formRenderProps.onChange('location', {
        value: selectedItem
      });
    }
  };

  const priceCell = ({ dataItem }) => {
    return <td>{dataItem.freeDrug ? 0 : dataItem.price}</td>;
  };

  const freeDrugCell = ({ dataItem }) => {
    if (dataItem.freeDrug) {
      return (
        <td>
          <Badge type='info' text='FREE DRUG' />
        </td>
      );
    }

    return <td>&nbsp;</td>;
  };

  const handleSelectionChange = (event) => {
    const selectedDataItems = event.selectedRows.map((row) => row.dataItem);
    setSelectedRows(selectedDataItems);
  };
  // Product Bulk Delete
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const toggleDeleteConfirm = () => {
    setShowDeleteConfirm(!showDeleteConfirm);
  };
  //   const [deleteReason, setDeleteReason] = useState('');
  //   const [isDeleteReasonSelected, setIsDeleteReasonSelected] = useState(false);
  //   const handleDeleteReasonChange = (e) => {
  //     const reason = e.target.value;
  //     setDeleteReason(reason);
  //     setIsDeleteReasonSelected(reason !== ''); // Enable Confirm button if reason is not empty
  //   };

  const handleConfirmRemoveSelectedClick = async (reason) => {
    setIsRemoving(true);
    try {
      if (!reason) {
        console.error('Reason for inventory bulk adjustment is required.');
        return;
      }
      const reconcileRecords = selectedRows.map((row) => ({
        afterQuantity: 0,
        beforeQuantity: row.currentQuantity,
        inventoryId: row.id,
        reason: 'RECONCILE',
        updatedBy: user.username,
        notes: reason
      }));
      await connectToGraphqlAPI({
        graphqlQuery: reconcileInventory,
        variables: {
          agentId: user.username,
          reconcileRecords
        }
      }).then(() => {
        const cloned = [...locationFullInventory];
        cloned.forEach((item) => {
          const selectedRow = selectedRows.find(
            (selected) => selected.id === item.id
          );
          if (selectedRow) {
            item.currentQuantity = 0;
          }
        });

        setSelectedRows([]);
        updateLocationInventory(cloned);
      });
    } catch (err) {
      if (err && err.errors && err.errors.length > 0) {
        console.error('SiteInventory::handleRemoveSelectedClick error: ', err);
      }
    } finally {
      setIsRemoving(false);
      toggleDeleteConfirm();
    }
  };

  return (
    <div className='col-md-12 ml-3 mr-3'>
      <div className='row'>
        <div className='col-md-6 mt-08'>
          <h3 className='pageTitle'>SITE INVENTORY REPORT</h3>
        </div>
      </div>
      <Form
        onSubmit={handleGetLocationInventoryClick}
        render={(formRenderProps) => (
          <form
            onSubmit={formRenderProps.onSubmit}
            className='k-form pl-1 pr-1 pt-1'
          >
            <div className='row'>
              <div className='col-md-3 ml-3 mb-4 mt-12'>
                <Field
                  component={() => (
                    <AICLocationDropdown
                      data={locations}
                      value={currentLocation}
                      onChange={(selected) => {
                        handleLocationTreeChange(selected, formRenderProps);
                        setSelectedRows([]);
                      }}
                    />
                  )}
                  name='location'
                  label='LOCATION'
                />
              </div>
              <div className='col-md-2 mt-12'>
                <button
                  type='submit'
                  className='k-button blue'
                  disabled={loading}
                >
                  GET INVENTORY
                </button>
                <TogglerWrapper>
                  <Field
                    name='qtyToggler'
                    label='Show Hidden Items'
                    component={Checkbox}
                    onChange={(e) => setShowHidden(e.value)}
                  />
                </TogglerWrapper>
              </div>
            </div>
          </form>
        )}
      />
      <div className='row'>
        <div className='col-md-12 mt-1 mb-2'>
          <Button
            look='flat'
            icon='plus'
            onClick={onToggle}
            disabled={!currentLocation?.id}
          >
            Add Product
          </Button>
          <Button
            look='flat'
            onClick={onToggleFishbowl}
            disabled={!currentLocation?.id}
          >
            <AwesomeLabel
              icon='fish'
              iconStyle='regular'
              label='Import from Fishbowl'
            />
          </Button>
          <Button
            look='flat'
            disabled={!currentLocation?.id}
            onClick={exportExcel}
          >
            <AwesomeLabel icon='file-excel' label='Export to Excel' />
          </Button>
          <Button
            look='flat'
            disabled={selectedRows.length === 0 || isRemoving}
            onClick={toggleDeleteConfirm}
          >
            <AwesomeLabel
              icon='trash-can-list'
              label='Product Bulk Adjustment'
            />
          </Button>
        </div>
      </div>
      <BulkAdjustmentModal
        isVisible={showDeleteConfirm}
        numSelected={selectedRows.length}
        handleClose={toggleDeleteConfirm}
        handleConfirm={handleConfirmRemoveSelectedClick}
      />
      <div className='row'>
        <div className='mb-2' style={{ paddingRight: 35 }}>
          <ExcelExport
            ref={_exporter}
            fileName='inventory.xlsx'
            data={orderBy(inventoryItems, sort)}
          >
            <ExcelExportColumnGroup>
              <ExcelExportColumn field='productName' title='PRODUCT' />
              <ExcelExportColumn field='productId' title='NDC' />
              <ExcelExportColumn field='currentQuantity' title='QTY' />
              <ExcelExportColumn field='displayStrengthUoM' title='VIAL' />
              <ExcelExportColumn field='lotNumber' title='LOT' />
              <ExcelExportColumn field='serial' title='SERIAL NUMBER' />
              <ExcelExportColumn field='vendor' title='VENDOR' />
              <ExcelExportColumn field='expirationDate' title='EXP' />
              <ExcelExportColumn
                field='received'
                title='RECEIVED'
                width={200}
              />
              <ExcelExportColumn field='price' title='PRICE' />
              <ExcelExportColumn field='freeDrug' title='FREE DRUG' />
              <ExcelExportColumn field='notes' title='NOTES' />
            </ExcelExportColumnGroup>
          </ExcelExport>
          <Preloader show={loading}>
            <Grid
              className='a-grid'
              data={orderBy(inventoryItems, sort)}
              sortable
              sort={sort}
              onSortChange={(e) => {
                setSort(e.sort);
              }}
              style={{ minHeight: 450 }}
              selectable={{
                enabled: true,
                mode: 'multiple'
              }}
              onSelectionChange={handleSelectionChange}
            >
              <Column
                field='checkbox'
                width='50px'
                headerSelectionValue={
                  selectedRows.length === inventoryItems.length
                }
                headerCell={() => (
                  <input
                    type='checkbox'
                    checked={selectedRows.length === inventoryItems.length}
                    onChange={(event) => {
                      if (event.target.checked) {
                        setSelectedRows(inventoryItems);
                      } else {
                        setSelectedRows([]);
                      }
                    }}
                  />
                )}
                cell={(props) => (
                  <td>
                    <input
                      type='checkbox'
                      checked={selectedRows.some(
                        (row) => row.id === props.dataItem.id
                      )}
                      onChange={(event) => {
                        if (event.target.checked) {
                          setSelectedRows([...selectedRows, props.dataItem]);
                        } else {
                          setSelectedRows(
                            selectedRows.filter(
                              (row) => row.id !== props.dataItem.id
                            )
                          );
                        }
                      }}
                    />
                  </td>
                )}
              />
              <Column field='productName' title='PRODUCT' />
              <Column field='productId' title='NDC' />
              <Column field='currentQuantity' title='QTY' />
              <Column field='displayStrengthUoM' title='VIAL' />
              <Column field='lotNumber' title='LOT' />
              <Column field='serial' title='SERIAL #' />
              <Column field='vendor' title='VENDOR' />
              <Column field='expirationDate' title='EXP' />
              <Column field='received' title='RECEIVED' />
              <Column field='price' title='PRICE' cell={priceCell} />
              <Column title='FREE DRUG' cell={freeDrugCell} />
              <Column field='notes' title='NOTES' />
              <Column field='select' title='ACTION' cell={selectAction} />
            </Grid>
          </Preloader>
        </div>
      </div>
      <AddProductToInventory
        location={currentLocation?.selectorText}
        locationId={currentLocation?.id}
        onToggle={onToggle}
        show={showAddProduct}
      />
      <FishbowlImport
        location={currentLocation?.selectorText}
        locationId={currentLocation?.id}
        onToggle={onToggleFishbowl}
        show={showFishbowl}
        addProductCallback={addProductCallback}
      />
      {visibleEditQtyDialog && (
        <Dialog
          title='Edit Quantity'
          width={500}
          height='auto'
          top={0}
          onClose={toggleEditQtyDialog}
          showDialog
        >
          <Form
            onSubmit={handleEditQtySubmit}
            render={(formRenderProps) => (
              <form
                onSubmit={formRenderProps.onSubmit}
                className='k-form pl-3 pr-3 pt-1'
              >
                <div className='row mt-2'>
                  <div className='col-6'>
                    <b>Product:</b> {currentSelRow.productName}
                  </div>
                  <div className='col-6'>
                    <b>NDC:</b> {currentSelRow.productId}
                  </div>
                </div>
                <div className='row mt-2'>
                  <div className='col-6'>
                    <b>Lot:</b> {currentSelRow.lotNumber}
                  </div>
                  <div className='col-6'>
                    <b>Qty:</b> {currentSelRow.currentQuantity}
                  </div>
                </div>
                <div className='row mt-2'>
                  <div className='col-12'>
                    <Field
                      component={Input}
                      label='New Quantity'
                      name='newQuantity'
                      disabled={false}
                    />
                  </div>
                </div>
                <div className='row p-3'>
                  <div className='col-12' style={{ textAlign: 'center' }}>
                    <button type='submit' className='k-button pageButton'>
                      Update Quantity
                    </button>
                  </div>
                </div>
              </form>
            )}
          />
        </Dialog>
      )}
    </div>
  );
};

export default SiteInventory;

TogglerWrapper.displayName = 'TogglerWrapper';
