import React, { useState, useContext } from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';

// components
import WarningBlock from '@/components/common-components/WarningBlock';

// context
import { UserRoleTypes, UserContext } from '@/context';

// utils
import { BLUE, WHITE, GRAY } from '@/constants/colors';

const Selector = styled.select`
  border-color: ${BLUE.primary};
  background-clip: padding-box;
  border-radius: 2px;
  padding: 0;
  width: 300px;
  display: flex;
  flex-direction: row;
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  position: relative;
  transition: all 0.1s ease;
  cursor: pointer;
  outline: 0;
  color: ${GRAY.text};
  background-color: ${WHITE.highGrad};
  background-image: linear-gradient(${WHITE.highGrad}, ${WHITE.lowGrad});
  font-family: inherit;
  font-size: 14px;
  line-height: 1.42857;
  text-align: left;
  white-space: nowrap;
  height: 30px;
`;

export const AddSalesRep = ({ salesReps, onAddSalesRep }) => {
  const { canEdit } = useContext(UserContext);
  const [selectedRep, setSelectedRep] = useState(null);
  const [validationError, setValidationError] = useState(null);
  const addSalesRep = () => {
    if (!selectedRep?.length) {
      setValidationError('Please select a Sales Rep');
      return;
    }
    onAddSalesRep(selectedRep);
  };
  const onChangeSalesRepSelection = (event) => {
    if (event.target.value?.length) {
      setValidationError(null);
    }
    setSelectedRep(event.target.value);
  };
  if (!salesReps?.length) {
    return <div>No Sales Reps available...</div>;
  }
  return (
    <div className='row my-3'>
      <div className='col-md-6'>
        <Selector onChange={onChangeSalesRepSelection}>
          <option>Sales Rep</option>
          {salesReps.map((rep) => (
            <option key={rep.value} value={rep.value}>
              {rep.text}
            </option>
          ))}
        </Selector>
        <WarningBlock message={validationError} />
      </div>
      <div className='col-md-6 text-left'>
        <button
          disabled={!canEdit(UserRoleTypes.PatientPrescribers)}
          type='button'
          look='outline'
          className='k-button blue large-btn-width'
          onClick={addSalesRep}
        >
          ADD
        </button>
      </div>
    </div>
  );
};

AddSalesRep.propTypes = {
  salesReps: PropTypes.array,
  onAddSalesRep: PropTypes.func
};
