import React, { createContext, useState } from 'react';

export const CalendarContext = createContext();

const CalendarContextProvider = ({ children }) => {
  const [showNewTelehealthModal, setShowNewTelehealthModal] = useState(false);
  const [showOfficeVisitModal, setShowOfficeVisitModal] = useState(false);

  return (
    <CalendarContext.Provider
      value={{
        showNewTelehealthModal,
        setShowNewTelehealthModal,
        showOfficeVisitModal,
        setShowOfficeVisitModal
      }}
    >
      {children}
    </CalendarContext.Provider>
  );
};

export default CalendarContextProvider;
