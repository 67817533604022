import React from 'react';
import styled from 'styled-components';
import PrescriberAddressCardCurrent from './PrescriberAddressCardCurrent';

const AddressContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  justify-content: left;
  padding: 15px 0;
  flex-wrap: wrap;
`;

const PrescriberAddressesCurrent = ({ addresses }) => {
  return (
    <AddressContainer className="row">
      {addresses?.map((address, index) => (
        <PrescriberAddressCardCurrent
          addressInfo={address}
          key={`address_${index}`}
          isPrimary={address?.default || index === 0}
        />
      ))}
    </AddressContainer>
  );
};

export default PrescriberAddressesCurrent;
