import React, { createContext, useContext, useState } from 'react';

export const ServiceWorkerContext = createContext();

export const useServiceWorker = () => useContext(ServiceWorkerContext);

const ServiceWorkerContextProvider = ({ children }) => {
  const [isServiceWorkerInitialized, setIsServiceWorkerInitialized] =
    useState(false);
  const [isServiceWorkerUpdated, setIsServiceWorkerUpdated] = useState(false);
  const [serviceWorkerRegistration, setServiceWorkerRegistration] =
    useState(null);

  const onSWInit = () => {
    setIsServiceWorkerInitialized(true);
  };

  const onSWActivate = (registration) => {
    const registrationWaiting = registration.waiting;
    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
      registrationWaiting.addEventListener('statechange', (e) => {
        if (e.target.state === 'activated') {
          window.location.reload();
        }
      });
    }
  };

  const onSWUpdate = (registration) => {
    setIsServiceWorkerUpdated(true);
    setServiceWorkerRegistration(registration);
  };

  const contextValue = {
    isServiceWorkerInitialized,
    isServiceWorkerUpdated,
    serviceWorkerRegistration,
    setIsServiceWorkerUpdated,
    onSWInit,
    onSWUpdate,
    onSWActivate
  };

  return (
    <ServiceWorkerContext.Provider value={contextValue}>
      {children}
    </ServiceWorkerContext.Provider>
  );
};

export default ServiceWorkerContextProvider;
