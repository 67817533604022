export const getWorkItemType = (id) => {
  const workItemSwitch = 132;
  return Number(id) >= workItemSwitch ? 'FOLLOW_UP' : 'OUTBOUND_CALL';
};

export const getCategoryNameFromJSON = (sJSON) => {
  let sCat = 'unknown';
  if (sJSON !== null) {
    sCat = 'CAT';
    const oJSON = JSON.parse(sJSON);
    sCat = oJSON?.category ? oJSON.category : 'Other';
  } else {
    sCat = 'Other';
  }
  return sCat;
};

export const followUpAssignToGroups = [
  'INTAKE',
  'BI_PA',
  'SCHEDULE',
  'CLINICIAN',
  'INVENTORY',
  'INBOUND',
  'UNASSIGNED',
  'NURSING',
  'SUPERVISOR',
  'ADMIN',
  'BILLING',
  'SALES'
];

//

const phoneIcon = 'phone icon-blue';
const officeIcon = 'phone-office';
// Follow up is set to switch on the id if it is less than 132. If adding to the call queue only add id's less then 132
// if it is for a standard follow up start at 142 and go up.
export const followUpCallCategories = [
  {
    text: 'Standard Follow Up',
    id: 141,
    items: [
      {
        text: 'Patient Profile',
        id: 132,
        icon: phoneIcon
      },
      {
        text: 'HCP',
        id: 133,
        icon: phoneIcon
      },
      {
        text: 'Referral',
        id: 134,
        icon: phoneIcon
      },
      {
        text: 'Clinical',
        id: 135,
        icon: phoneIcon
      },
      {
        text: 'Benefits Inv',
        id: 136,
        icon: phoneIcon
      },
      {
        text: 'Prior Auth',
        id: 137,
        icon: phoneIcon
      },
      {
        text: 'Fax',
        id: 138,
        icon: phoneIcon
      },
      {
        text: 'Scheduling',
        id: 139,
        icon: phoneIcon
      },
      {
        text: 'Other',
        id: 140,
        icon: phoneIcon
      }
    ]
  },
  {
    text: 'Appointment Reminder Call',
    id: 1,
    // icon: officeIcon,
    items: [
      {
        text: 'Unable to Process Referral',
        id: 2,
        icon: phoneIcon
      },
      {
        text: 'Patient Non-Adherence Call',
        id: 3,
        icon: phoneIcon
      },
      {
        text: 'Other/Ad Hoc Call',
        id: 4,
        icon: phoneIcon
      },
      {
        text: 'Doctor Calls',
        id: 5,
        icon: phoneIcon
      }
    ]
  },
  {
    text: 'Labs Coordination Call',
    id: 6,
    // icon: officeIcon,
    items: [
      {
        text: 'Patient Call-Inform/Plan',
        id: 7,
        icon: phoneIcon
      },
      {
        text: 'MD Call-Obtain Lab Order/Results',
        id: 8,
        icon: phoneIcon
      },
      {
        text: 'Lab Call-Make Appointment',
        id: 9,
        icon: phoneIcon
      },
      {
        text: 'Patient Call-Appointment Details',
        id: 10,
        icon: phoneIcon
      },
      {
        text: 'Patient Calls',
        id: 11,
        icon: phoneIcon
      }
    ]
  },
  {
    text: 'Pharmacist Doctor Call',
    id: 12,
    // icon: officeIcon,
    items: [
      {
        text: 'Labs',
        id: 13,
        icon: phoneIcon
      },
      {
        text: 'Referral',
        id: 14,
        icon: phoneIcon
      },
      {
        text: 'Other',
        id: 15,
        icon: phoneIcon
      }
    ]
  },
  {
    text: 'Pharmacist Patient Call',
    id: 16,
    // icon: officeIcon,
    items: [
      {
        text: 'Labs',
        id: 17,
        icon: phoneIcon
      },
      {
        text: 'Adherence',
        id: 18,
        icon: phoneIcon
      },
      {
        text: 'Other',
        id: 19,
        icon: phoneIcon
      }
    ]
  },
  {
    text: 'Request For Information',
    id: 20,
    // icon: officeIcon,
    items: [
      {
        text: 'Patient Declined to Start Medication',
        id: 21,
        icon: phoneIcon
      },
      {
        text: 'Patient Discontinued Medication',
        id: 22,
        icon: phoneIcon
      },
      {
        text: 'Patient Non-Adherent to Medication',
        id: 23,
        icon: phoneIcon
      },
      {
        text: 'Expired Referral',
        id: 24,
        icon: phoneIcon
      },
      {
        text: 'Patient Labs',
        id: 25,
        icon: phoneIcon
      },
      {
        text: 'Fax Failed',
        id: 26,
        icon: phoneIcon
      },
      {
        text: 'Faxback Not Received',
        id: 27,
        icon: phoneIcon
      },
      {
        text: 'Other/Ad Hoc',
        id: 28,
        icon: phoneIcon
      }
    ]
  },
  {
    text: 'Welcome Call',
    id: 29,
    // icon: officeIcon,
    items: [
      {
        text: 'BI Review',
        id: 30,
        icon: phoneIcon
      },
      {
        text: 'Pap Program',
        id: 31,
        icon: phoneIcon
      }
    ]
  }
];

export const getMainCategory = (id) => {
  if (id >= 1 && id <= 5) {
    const mainCategory = {
      text: 'Appointment Reminder Call',
      id: 1
    };
    return mainCategory;
  }
  if (id >= 6 && id <= 11) {
    const mainCategory = {
      text: 'Labs Coordination Call',
      id: 6
    };
    return mainCategory;
  }
  if (id >= 12 && id <= 15) {
    const mainCategory = {
      text: 'Pharmacist Doctor Call',
      id: 12
    };
    return mainCategory;
  }
  if (id >= 16 && id <= 19) {
    const mainCategory = {
      text: 'Pharmacist Patient Call',
      id: 16
    };
    return mainCategory;
  }
  if (id >= 20 && id <= 28) {
    const mainCategory = {
      text: 'Request For Information',
      id: 20
    };
    return mainCategory;
  }
  if (id >= 29 && id <= 31) {
    const mainCategory = {
      text: 'Welcome Call',
      id: 29
    };
    return mainCategory;
  }
  if (id >= 132 && id <= 141) {
    const mainCategory = {
      text: 'Standard Follow Up',
      id: 141
    };
    return mainCategory;
  }
};
