import React, { useState, useEffect } from 'react';
import { Field } from '@progress/kendo-react-form';
import { Switch } from '@progress/kendo-react-inputs';
import { DatePickerField } from '@/common/Validation';

const OralLeadIn = ({ formRenderProps }) => {
  const [hasTherapy, setHasTherapy] = useState(false);

  const toggleTherapy = e => setHasTherapy(e.value);

  useEffect(() => {
    const oralLeadInDate = formRenderProps.valueGetter('oralLeadInDate');
    if (oralLeadInDate) {
      setHasTherapy(true);
    }
  }, []);

  const renderCalendarInput = () => {
    return (
      <>
        <div className="col-md-3">
          {hasTherapy ? 'Last Dose of Oral Lead in date' : 'Date of First Injection'}:
        </div>
        <div className="col-md-3">
          <Field
            name={hasTherapy ? 'oralLeadInDate' : 'firstInjectionDate'}
            component={DatePickerField}
          />
        </div>
      </>
    );
  };

  return (
    <div className="row pt-1">
      <div className="col-md-2">ORAL LEAD-IN THERAPY:</div>
      <div className="col-10">
        <div className="row">
          <div className="col-md-2">
            <Switch
              onLabel="Yes"
              offLabel="No"
              checked={hasTherapy}
              onChange={e => {
                toggleTherapy(e);

                formRenderProps.onChange('oralLeadInDate', {
                  value: null
                });
                formRenderProps.onChange('firstInjectionDate', {
                  value: null
                });
              }}
            />
          </div>
          {renderCalendarInput()}
        </div>
      </div>
    </div>
  );
};

export default OralLeadIn;
