import React, { useContext, useEffect, useState } from "react";

import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Switch } from "@progress/kendo-react-inputs";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, Field } from "@progress/kendo-react-form";

import { validateInput } from "@/common/Validation";
import { getPatientWeightFromReferral } from "@/common/Converters";
import RevisitOrderReview from "@/components/Infusion/Revisit/RevisitOrderReview";

import { MessageDialog } from "@/components/common-components/MessageDialog";
// import { restoreNotes } from "@/components/Infusion/infusionHelper";

import { connectToGraphqlAPI } from "@/provider";
import { getNursingProcess } from "@/graphql/queries";
import { Storage } from "aws-amplify";
import { addUpdatePatientDocs } from "@/graphql/mutations";
import { UserContext } from "@/context/UserContext";

import * as moment from "moment";
import { formatDateToDefault } from "@/common/DateHelper";

const OrderReview = props => {
  const { user } = useContext(UserContext);

  const infusion = props.infusion;
  const selectedPatientInfo = props.selectedPatientInfo;
  const nursingProcessId = props.nursingProcessId;
  const [nursingProcess, setNursingProcess] = useState({});

  const infusionFormData = props.infusionFormData;
  const showInfusionForm = props.showInfusionForm;
  const [itemAdministrations, setItemAdministrations] = useState([]);
  const [itemPreMeds, setItemPreMeds] = useState([]);

  const [showForm, setShowForm] = useState(true);
  const [isOrderApproved, setIsOrderApproved] = useState(false);
  const [isPatientConsentReceived, setIsPatientConsentReceived] =
    useState(false);

  const [dialogOption, setDialogOption] = useState({});
  const [showAddendumDialog, setShowAddendumDialog] = useState(false);

  // MAIN INITIATOR
  useEffect(() => {
    getNursingProcessCall(nursingProcessId);
  }, []);

  useEffect(() => {
    handleLoadInfusion(infusionFormData);
  }, [nursingProcess]);

  const getNursingProcessCall = async id => {
    // let npid = id;
    // if (!npid) {
    //   npid = "";
    // }
    try {
      if (id && id !== "") {
        const data = await connectToGraphqlAPI({
          graphqlQuery: getNursingProcess,
          variables: { id }
        });

        // STEP 1: data collection from existing record
        if (data && data.data && data.data.getNursingProcess) {
          await Promise.allSettled([
            setShowForm(false),
            setNursingProcess(data.data.getNursingProcess),
            setIsOrderApproved(
              data.data.getNursingProcess.stepReview?.orderApproved
            ),
            setIsPatientConsentReceived(
              data.data.getNursingProcess.stepReview?.patientConsentReceived
            ),
            setShowForm(true)
          ]);
        } else {
          handleLoadInfusion(infusionFormData);
        }
      }
    } catch (err) {
      alert("ERROR: getNursingProcessCall");
    }
  };

  const handleLoadInfusion = dataObject => {
    if (dataObject && dataObject.referralOrder) {
      const selectedOrder = dataObject;

      setItemAdministrations(
        selectedOrder.referralOrder.administrations.map(item => ({
          ...item,
          text: item.orderName,
          value: item.orderName
        }))
      );

      setItemPreMeds(
        selectedOrder.referralOrder.preMedications.map(item => ({
          ...item,
          text: item.orderName,
          value: item.orderName
        }))
      );
    }
  };

  const infusionForm = {
    orderIsApproved: {
      value: nursingProcess.stepReview?.orderApproved ? true : false,
      inputValidator: value => {
        return validateInput({
          orderIsApproved: { ...infusionForm.orderIsApproved, value }
        });
      },
      validations: []
    },
    patientConsentReceived: {
      value: nursingProcess.stepReview?.patientConsentReceived ? true : false,
      inputValidator: value => {
        return validateInput({
          patientConsentReceived: {
            ...infusionForm.patientConsentReceived,
            value
          }
        });
      },
      validations: []
    }
  };

  const toggleAddendum = () => {
    setShowAddendumDialog(!showAddendumDialog);
  };

  const handleFileUpload = async e => {
    const file = e.target.files[0];
    try {
      // Upload the file to s3 with public (internally private) access level.
      //await Storage.put('picture.jpg', file, {
      await Storage.put(
        `patientDocs/${selectedPatientInfo.patientId}/${file.name}`,
        file,
        {
          level: "public"
          //contentType: 'image/jpg'
        }
      );
      // Retrieve the uploaded file to display
      // const url = await Storage.get(
      //   `patientDocs/${selectedPatientInfo.patientId}/${file.name}`,
      //   {
      //     level: "public"
      //   }
      // );

      const requestObject = {
        patientId: selectedPatientInfo.patientId,
        agentId: user.username,
        patientDocuments: {
          documentType: "ReferralAddendum",
          documentPath: `patientDocs/${selectedPatientInfo.patientId}/${file.name}`,
          date: moment().utc().format()
        }
      };

      callAddUpdatePatientDocs(requestObject);
    } catch (err) {
      console.log(err);
    }
  };

  const callAddUpdatePatientDocs = async requestObject => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: addUpdatePatientDocs,
        variables: {
          input: requestObject
        }
      });

      let payload = {
        message: "Please Update Docs Component",
        request: "pleaseUpdateDocs"
      };
      toggleAddendum();
      if (data && data.data && data.data.addUpdatePatientDocs) {
        setDialogOption({
          title: "Referral Addendum",
          message: "Referral Addendum uploaded sucessfully.",
          showDialog: true
        });
      }
      props.sendDataToParent(payload);
    } catch (err) {
      console.log("marty callAddUpdatePatientDocs err", err);
    }
  };

  const handleSubmit = dataItem => {
    const { orderIsApproved, patientConsentReceived } = dataItem;

    if (!orderIsApproved || !patientConsentReceived) {
      setDialogOption({
        title: "Order Review",
        message:
          "You must approve the order and confirm that Patient Consent received.",
        showDialog: true
      });

      return;
    }

    const requestObject = {
      // STEP 1
      // input UpdateStepOrderReviewInput {
      // updateStepOrderReviewInput: {
      // nursingProcessId: ID!
      nursingProcessId:
        nursingProcessId ||
        infusion.updateStepOrderReviewInput.nursingProcessId ||
        "",
      // agentId: ID!
      agentId: infusion.stepCheckInInput.agentId,
      // addendumOrderFilePath: String
      addendumOrderFilePath: null,
      // orderIsApproved: Boolean!
      orderIsApproved,
      // patientConsentReceived: Boolean!
      patientConsentReceived
      // @NOTE: removed as part of updating step, now the notes live alone
      // notes: [String]
      // notes: restoreNotes()
      // },
    };

    props.sendDataToParent(requestObject);
  };

  String.prototype.toProperCase = function () {
    return this.replace(/([^\W_]+[^\s_]*) */g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }).replace(/_/g, " ");
  };

  const patientWeightKg = getPatientWeightFromReferral(
    infusionFormData.referralOrder
  );

  const toggleOrderApproval = () => {
    setIsOrderApproved(!isOrderApproved);
  };

  const togglePatientConsent = () => {
    setIsPatientConsentReceived(!isPatientConsentReceived);
  };

  if (props.infusionRestricted) {
    return (
      <RevisitOrderReview
        infusionFormData={infusionFormData}
        itemAdministrations={itemAdministrations}
        itemPreMeds={itemPreMeds}
      />
    );
  }

  return (
    <div className="infusion-page">
      {dialogOption && dialogOption.showDialog && (
        <MessageDialog dialogOption={dialogOption} />
      )}

      {showInfusionForm && (
        <>
          <div className="col-md-10" style={{ border: "1px solid #afaaaa" }}>
            <div className="row">
              <div className="infusion-HeaderRow col-12 ml-0 pl-3 py-3 mr-0">
                <div className="row">
                  <div className="col-md-4 headerText">
                    Order Name:
                    <br />
                    {infusionFormData.referralOrder?.orderName}
                  </div>
                  <div className="col-md-4 headerText">
                    Order Type:
                    <br />
                    {infusionFormData.referralOrder?.orderType.toProperCase()}
                  </div>
                  <div className="col-md-4 headerText">
                    Referral ID:
                    <br />
                    {infusionFormData.referralId}
                  </div>
                </div>
              </div>
            </div>
            {patientWeightKg > 0 && (
              <div className="row">
                <div className="col-md-12 mt-08">
                  Patient Weight on Order: {patientWeightKg} KG
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-md-12 mt-08">
                <Grid data={itemAdministrations} className="infusion-grid">
                  <Column field="drugName" title="PRODUCT NAME" width="150px" />
                  <Column field="route" title="ROUTE" width="80px" />
                  <Column field="administer" title="ADMINISTER" width="240px" />
                  <Column field="maxOfTreatments" title="MAX #" width="80px" />
                  <Column field="approvedDosage" title="DOSE" width="80px" />
                  <Column field="unitOfMeas" title="UOM" width="120px" />
                  <Column field="calcDosage" title="CALC DOSE" width="130px" />
                </Grid>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 mt-08">
                <Grid data={itemPreMeds} className="infusion-grid">
                  <Column
                    field="drugName"
                    title="PRE-MEDICATION"
                    width="150px"
                  />
                  <Column field="route" title="ROUTE" width="80px" />
                  <Column field="administer" title="ADMINISTER" width="240px" />
                  <Column field="" title="MAX #" width="80px" />
                  <Column field="approvedDosage" title="DOSE" width="80px" />
                  <Column field="unitOfMeas" title="UOM" width="100px" />
                  <Column field="" title="CALC DOSE" width="130px" />
                </Grid>
              </div>
            </div>
            <div className="infusion-details col-12 ml-0 pl-3 py-3 mr-0">
              <div>
                <div className="row">
                  <div className="col-md-4 headerText">
                    {infusionFormData.prescriberId}
                  </div>
                  <div className="col-4 headerText">
                    {formatDateToDefault(
                      infusionFormData.referralOrder?.orderDate
                    )}
                  </div>
                  <div className="col-4 headerText">
                    {formatDateToDefault(
                      infusionFormData.referralOrder?.orderExpires
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 infusion-details-field-name">
                    ORDERING PROVIDER
                  </div>
                  <div className="col-4 infusion-details-field-name">
                    ORDER DATE
                  </div>
                  <div className="col-4 infusion-details-field-name">
                    ORDER EXPIRES
                  </div>
                </div>
                <div className="row mt-08">
                  <div className="col-md-4 headerText">
                    {/* Crohn's disease, unspecified, without complications<br/> */}
                    <b>
                      {
                        infusionFormData.referralOrder?.primaryDX
                          .primaryDiagnosis
                      }
                    </b>
                    <br />
                    {infusionFormData.referralOrder?.primaryDX.description}
                  </div>
                  {infusionFormData?.referralOrder?.otherDX?.length > 0 && (
                    <>
                      <div className="col-4 headerText">
                        {infusionFormData?.referralOrder?.otherDX?.map(
                          (dx, i) => (
                            <div key={i}>
                              <strong>{dx.primaryDiagnosis}</strong>
                              {"  "}
                              {dx.description && <div>{dx.description}</div>}
                            </div>
                          )
                        )}
                      </div>
                    </>
                  )}
                  <div className="col-4 headerText notesWrapper">
                    {/* PREMEDS:  NONE/// LABS: NEGATIVE TB AND HEP B COLLECTED 5/15/20, YEARLNOT REQUIRED///
                  RATE: TOTAL INFUSION TIME NO LESS THAN 30 MINUTES, 500ML/HR (0.2 MICRON FILTER)- LO 9/14/20 */}
                    {infusionFormData.referralOrder?.notes}
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 infusion-details-field-name">
                    PRIMARY DX
                  </div>
                  {infusionFormData?.referralOrder?.otherDX?.length > 0 && (
                    <div className="col-4 infusion-details-field-name">
                      OTHER DX
                    </div>
                  )}
                  <div className="col-4 infusion-details-field-name">NOTES</div>
                </div>
              </div>
            </div>
          </div>

          {showForm && (
            <Form
              onSubmit={handleSubmit}
              initialValues={{
                orderIsApproved: isOrderApproved,
                patientConsentReceived: isPatientConsentReceived
              }}
              render={formRenderProps => (
                <form
                  onSubmit={formRenderProps.onSubmit}
                  className={"k-form pl-3 pr-3 pt-1"}
                >
                  <div className="row mt-3">
                    <div className="col-md-3">
                      Order is Approved: &nbsp;
                      <Field
                        name="orderIsApproved"
                        onLabel="Yes"
                        offLabel="No"
                        component={Switch}
                        value={isOrderApproved}
                        defaultChecked={isOrderApproved}
                        onChange={toggleOrderApproval}
                      />
                    </div>
                    <div className="col-md-5">
                      Patient Consent Received: &nbsp;
                      <Field
                        name="patientConsentReceived"
                        onLabel="Yes"
                        offLabel="No"
                        component={Switch}
                        value={isPatientConsentReceived}
                        defaultChecked={isPatientConsentReceived}
                        onChange={togglePatientConsent}
                      />
                    </div>
                    <div className="col-md-3">
                      <button className="k-button" onClick={toggleAddendum}>
                        Add Addendum
                      </button>
                      <span
                        className={"k-icon k-i-file-txt k-icon-32"}
                        style={{ color: "blue" }}
                      ></span>
                    </div>
                  </div>
                  <div className="row mt-3 mb-5">
                    <div className="col-12">
                      <button type="submit" className="k-button pageButton">
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              )}
            />
          )}

          <div className="row mt-5 mb-5"></div>

          {showAddendumDialog && (
            <Dialog
              title={"Referral Addendum"}
              width={450}
              height={400}
              onClose={toggleAddendum}
            >
              <div className="row mt-1 ml-1">
                <div>Select File to Upload:</div>
              </div>
              <div className="row mt-3 ml-1">
                <div col-12>
                  <input
                    type="file"
                    accept="image/jpg,
                            image/jpeg,
                            image/gif,
                            image/png,
                            image/svg+xml,
                            application/pdf,
                            application/msword,
                            application/vnd.openxmlformats-officedocument.wordprocessingml.document
                        "
                    onChange={evt => handleFileUpload(evt)}
                  />
                </div>
              </div>
            </Dialog>
          )}
        </>
      )}
    </div>
  );
};

export default OrderReview;
