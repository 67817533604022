import React from 'react';
import { Grid } from '@progress/kendo-react-grid';
import styled from 'styled-components';
import { GridColumn as Column } from '@progress/kendo-react-grid/dist/npm/GridColumn';
import AwesomeLink from '@/components/common-components/AwesomeLink';
import { SurveyContext } from '@/context/SurveyContext';
import AwesomeLabel from '@/components/common-components/AwesomeLabel';
import GridRecord from '@/components/common-components/GridRecordWithFooter';
import {
  calculateSurveyScores,
  generateEducationLinks,
  generateSurveyNotes
} from '../SurveyEngine/helpers/helperQOL';
import { GRAY } from '@/constants/colors';
import { localizeTimeDiff, timeDiff } from '@/common/DateHelper';
import Badge from '../common-components/Badge';

/** Styled Components */
const SurveyTime = styled.p`
  font-size: 10px;
  color: ${GRAY.text};
  margin-top: 10px;
  margin-bottom: 0;
`;

const PatientSurveyLog = ({ surveyInfo }) => {
  const { setActiveSurvey, setSurveyResults, setSurveyFollowup } =
    React.useContext(SurveyContext);

  const headerTypeCell = () => {
    return <AwesomeLabel icon="square-poll-horizontal" label="Type/Version" />;
  };
  const headerDateCell = () => {
    return <AwesomeLabel icon="calendar" label="Survey Date" />;
  };
  const headerSurveyCell = () => {
    return <AwesomeLabel icon="memo-circle-check" label="Survey Results" />;
  };
  const headerScoreCell = () => {
    return <AwesomeLabel icon="bullseye-arrow" label="Total Score" />;
  };

  const findPriorSurvey = (currentSurveyIndex) => {
    if (currentSurveyIndex === 0) return null;

    // need to add unique ID (index) temporarily so we can apply filters later
    const mappedSurveys = surveyInfo.map((survey, index) => ({
      ...survey,
      index
    }));

    // find selected element so we will know its unique ID (index)
    const currentSurvey = mappedSurveys[currentSurveyIndex];

    // filter out skipped surveys - we do not include skipped surveys
    const noSkippedSurveys = mappedSurveys.filter((surveyData) => {
      const { meta, score } = JSON.parse(surveyData.survey);
      if (!score) return false;
      return meta.isSurveySkipped !== true;
    });

    const filteredIndex = noSkippedSurveys.findIndex((survey) => survey.index === currentSurvey.index);
    if (filteredIndex <= 0) return null;

    // we only compare results with same model version
    const priorSurvey = noSkippedSurveys[filteredIndex - 1];

    if (currentSurvey.version === priorSurvey.version) {
      return priorSurvey;
    }

    // otherwise we do not show comparison at all
    return null;
  };

  const getSurveyScore = (surveyData) => {
    if (!surveyData) return null;

    const jsonSurveyResults = JSON.parse(surveyData.survey);
    return calculateSurveyScores(jsonSurveyResults);
  };

  const typeVersionCell = ({ dataItem }) => {
    if (!dataItem.surveyModel) {
      return <td>Unknown Survey</td>;
    }

    const { surveyTitle, version } = dataItem.surveyModel;
    const footerText = `Survey Version: ${version}`;

    return (
      <td>
        <GridRecord mainText={surveyTitle} footerText={footerText} />
      </td>
    );
  };

  const collectedCell = ({ dataItem }) => {
    const getFooterText = () => (
      <>
        Collected by Agent: <b className="icon-blue">{dataItem.collectedBy}</b>
      </>
    );

    return (
      <td>
        <GridRecord
          mainText={dataItem.collectedAt}
          footerText={getFooterText()}
        />
      </td>
    );
  };

  const scoreCell = ({ dataItem }) => {
    try {
      let scoreArr = [];
      const { score } = JSON.parse(dataItem.survey);

      if (score && score.length > 0) {
        scoreArr = [...score];

        return (
          <td>
            {scoreArr.map((record, index) => {
              return (
                <span key={`score-cell-${index}`}>
                  <b className="icon-blue">{record.score}</b> ({record.type})
                  <br />
                </span>
              );
            })}
          </td>
        );
      }

      return (
        <td>
          <Badge type="alert" text="skipped" />
        </td>
      );
    } catch (error) {
      return <td>&nbsp;</td>;
    }
  };

  const surveyResultsCell = ({ dataItem, dataIndex }) => {
    const priorSurvey = findPriorSurvey(dataIndex);
    const priorScore = getSurveyScore(priorSurvey);
    const { survey, surveyModel, collectedAt, collectedBy } = dataItem;
    if (survey) {
      const renderTiming = () => {
        const { meta } = JSON.parse(survey);

        if (meta.surveyFinished !== undefined) {
          const startedDate = new Date(meta.surveyStarted);
          const finishedDate = new Date(meta.surveyFinished);
          const diffStr = localizeTimeDiff(...timeDiff(finishedDate, startedDate));

          return <SurveyTime>Survey Completed In {diffStr}</SurveyTime>;
        }

        return null;
      };

      return (
        <td>
          <AwesomeLink
            onClick={() => {
              // set the survey model
              setActiveSurvey({
                model: surveyModel
              });

              // display results in readonly mode
              setSurveyResults(survey);

              const jsonResults = JSON.parse(survey);
              const workItems = generateSurveyNotes(jsonResults.notes);
              const education = generateEducationLinks(jsonResults);

              // followup modal
              setSurveyFollowup({
                education,
                workItems,
                score: jsonResults.score || [],
                priorScore: priorScore || [],
                priorCollectedAt: priorSurvey?.collectedAt || null,
                collectedAt,
                collectedBy,
                version: jsonResults.meta?.version || 1,
                surveyType: jsonResults.meta?.surveyType
              });
            }}
            icon="memo-circle-check"
            label="Survey Results"
          />
          {renderTiming()}
        </td>
      );
    }

    return <td>No survey results</td>;
  };

  return (
    <>
      <Grid className="a-grid" data={surveyInfo}>
        <Column headerCell={headerTypeCell} cell={typeVersionCell} />
        <Column headerCell={headerDateCell} cell={collectedCell} />
        <Column headerCell={headerScoreCell} cell={scoreCell} />
        <Column headerCell={headerSurveyCell} cell={surveyResultsCell} />
      </Grid>
    </>
  );
};

export default PatientSurveyLog;
