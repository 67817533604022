import React from "react";
import WorkInProgress from "../Agent/WorkInProgress";

const WorkItemsAdmin = () => {
  return (
    <div className="row">
      <div className="col">
        <div className="row">
          <WorkInProgress workItemSelect={1} />
        </div>
      </div>
    </div>
  );
};

export default WorkItemsAdmin;
