import React, { useEffect, useRef } from 'react';
import { savePDF } from '@progress/kendo-react-pdf';
import Sanitize from '@/common/Sanitize';
import { generateLabelHTML } from './infusionHelper';

const IVLabel = ({ labelData }) => {
  const container = useRef(null);

  useEffect(() => {
    const element = container.current || document.getElementById('IVLabel');
    savePDF(element, {
      paperSize: [300, 450],
      margin: 20,
      fileName: `IV${labelData.patientId}`
    });
  }, []);

  const labelHtml = generateLabelHTML(labelData);

  return (
    <div
      id="IVLabel"
      ref={container}
      style={{
        width: '260px',
        fontSize: '50%'
      }}
    >
      <Sanitize html={labelHtml} allowedTags={['h3', 'hr', 'p', 'b']} />
    </div>
  );
};

export default IVLabel;
