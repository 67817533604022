import React, { useContext } from 'react';

// components
import WorkItemTimeoutModal from '@/components/WorkItemTimeoutModal/WorkItemTimeoutModal';
import LinkToPatientProfile from '@/components/common-components/LinkToPatientProfile';
import AwesomeLabel from '@/components/common-components/AwesomeLabel';
import WorkItemAudience from '../WorkItemAudience';

// partials
import WorkItemId from '../WorkItemId';
import PreviewDocument from '../PreviewDocument';
import WorkItemLog from '../WorkItemLog';
import WorkItemParent from '../WorkItemParent';
import WorkItemICD10 from '../WorkItemICD10';
import WorkItemInsurance from '../WorkItemInsurance';
import WorkItemDocLink from '../WorkItemDocLink';
import CreatePreOrder from '../CreatePreOrder';

// context
import { WorkItemContext } from '@/context';

// utils
import { toProperCase } from '@/common/Converters';
import { workItemTypes } from '@/constants/enum';

// styles
import styles from '../CurrentWorkItem.module.scss';

const CommonWorkItem = ({ hasDocument }) => {
  const { selectedWorkItem } = useContext(WorkItemContext);
  const drugName =
    selectedWorkItem.attachedData?.attachedData?.productName ||
    selectedWorkItem?.drugName ||
    'n/a';
  const title = toProperCase(
    selectedWorkItem?.attachedData?.followUpType || selectedWorkItem?.work
  );

  return (
    <>
      <div className='row'>
        <div className='col-5'>
          <h5>
            Complete: {title}
            <WorkItemAudience />
          </h5>
          <div>
            <LinkToPatientProfile />
          </div>
          <div>
            <AwesomeLabel label={drugName} icon='pills' title='Drug Name' />
          </div>
          <div>
            <WorkItemId />
          </div>
          <div className={styles.itemRow}>
            <WorkItemLog />
          </div>
        </div>
        <div className='col-1' />
        <div className='col-6'>
          <div>
            <WorkItemParent />
            <p>
              <b>Description:</b> {selectedWorkItem.description}
            </p>
            <WorkItemICD10 />
            <WorkItemInsurance />
            <WorkItemDocLink />
            {hasDocument && <PreviewDocument />}
            {selectedWorkItem.work === workItemTypes.PRE_ORDER && (
              <CreatePreOrder />
            )}
          </div>
        </div>
      </div>
      <WorkItemTimeoutModal />
    </>
  );
};

export default CommonWorkItem;
