export const createTypes = [
  { value: 'PLAN_OON', text: 'Discontinuation Review' },
  { value: 'FOLLOW_UP', text: 'Follow up' },
  /*  { value: 'FOLLOW_UP', text: 'Follow up: Intake' },// Temporary code or comment until more queues are released
  { value: 'FOLLOW_UP', text: 'Follow up: Nursing' },
  { value: 'FOLLOW_UP', text: 'Follow Up: Pharmacist' },
  { value: 'FOLLOW_UP', text: 'Follow up: Scheduling' }, */
  { value: 'OUTBOUND_CALL', text: 'Outbound Call' }
  /* { value: 'SEND_EMAIL', text: 'Outbound Email' },
  { value: 'SEND_FAX', text: 'Outbound Fax' },
  { value: 'OUTBOUND_TEXT', text: 'Outbound Text(Phase 2)' } */
];
export const userContact = [
  { value: 'DOCTOR', text: 'Doctor' },
  { value: 'INSURANCE', text: 'Insurance' },
  { value: 'PATIENT', text: 'Patient' },
  { value: 'SALES', text: 'Sales' }
  /* { value: 'INSURANCE_NO_SELECTION', text: 'Insurance (no selection)' } */
];
export const queueList = [
  { value: 'BI', text: 'BI' },
  { value: 'INTAKE', text: 'Intake' },
  { value: 'PA', text: 'PA' },
  { value: 'SALES', text: 'Sales' },
  { value: 'SCHEDULED', text: 'Scheduling' }
  /* { value: 'NURSING', text: 'Nursing' },// Temporary code or comment until more queues are released
  { value: 'PHARMACIST', text: 'Pharmacist' },
   */
];
