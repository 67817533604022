import React from "react";

const PatientLogInfo = () => {
  return (
    <p style={{ fontStyle: "italic" }}>
      <span style={{ color: "darkred" }}>* </span>You can manage allergies and
      meds in the <b>Patient Profile</b> (Patient Info ---&gt; Patient)
    </p>
  );
};

export default PatientLogInfo;
