import { formatZipCode } from '@/common/Converters';

export const formatPrescriberAddress = (addressInfo) => {
  const state = addressInfo?.state?.abbreviation || addressInfo?.state;

  return `${addressInfo?.streetName} - ${
    addressInfo?.city
  }, ${state} -  ${formatZipCode(addressInfo?.zip)}`;
};

export const formatPrescriberAddressTitle = (addressInfo) => {
  if (addressInfo?.addressTitle) return addressInfo.addressTitle;

  const state = addressInfo?.state?.abbreviation || addressInfo?.state;

  return `${addressInfo?.city}, ${state} - ${
    addressInfo?.streetName
  } - ${formatZipCode(addressInfo?.zip)}`;
};

export const formatPrescriberAddressHistory = (addressInfo) => {
  return `${addressInfo?.streetName} - ${addressInfo?.city}, ${
    addressInfo?.state
  } ${formatZipCode(addressInfo?.zip)} Phone:${
    addressInfo?.phoneNumber
  } - Fax:${addressInfo?.faxNumber}`;
};
