import { Auth } from 'aws-amplify';

import * as menuKeys from '@/context/userMenu';

export const menuItems = [
  {
    id: 55,
    key: menuKeys.CALENDAR,
    text: 'Calendar',
    icon: 'k-i-calendar',
    route: '/scheduler'
  },
  {
    id: 1,
    key: menuKeys.DATA_ADMIN,
    text: 'Data Admin',
    icon: 'k-i-cogs',
    route: '/admin'
  },
  {
    id: 33,
    key: menuKeys.INVENTORY,
    text: 'Inventory',
    icon: 'k-i-inbox',
    route: '/inventory'
  },
  {
    id: 77,
    key: menuKeys.RELEASES,
    text: 'Release Notes',
    icon: 'k-i-js',
    route: '/releases'
  },
  {
    id: 2,
    isParent: true,
    text: 'Working Queues',
    icon: 'k-i-aggregate-fields',
    'data-expanded': false
  },
  {
    id: 11,
    key: menuKeys.DASHBOARD,
    parentId: 2,
    text: 'Dashboard',
    icon: 'k-i-minus',
    route: '/dashboard-queue'
  },
  {
    id: 1007,
    parentId: 2,
    key: menuKeys.MAIN_QUEUE_NEW,
    text: 'Queue Center (NEW)',
    icon: 'k-i-minus',
    route: '/work-queue/INTAKE'
  },
  {
    id: 1012,
    parentId: 2,
    key: menuKeys.INBOUND_FAX_QUEUE_NEW,
    text: 'Inbound Fax Queue (NEW)',
    icon: 'k-i-minus',
    route: '/new-inbound'
  },
  {
    id: 3,
    key: menuKeys.INFUSION_QUEUE,
    parentId: 2,
    text: 'Infusion Queue',
    icon: 'k-i-minus',
    route: '/infusion-queue'
  },
  {
    id: 4,
    parentId: 2,
    key: menuKeys.NURSING_QUEUE,
    text: 'Nursing Queue',
    icon: 'k-i-minus',
    route: '/nurse-queue'
  },
  {
    id: 5,
    parentId: 2,
    key: menuKeys.BILLING_QUEUE,
    text: 'Billing Queue',
    icon: 'k-i-minus',
    route: '/billing'
  },
  /* {
    id: 7,
    parentId: 2,
    key: menuKeys.CLINICIAN_QUEUE,
    text: 'Clinician Queue',
    icon: 'k-i-minus',
    route: '/clinician'
  }, */
  {
    id: 10,
    parentId: 2,
    key: menuKeys.FAILED_FAX_QUEUE,
    text: 'Failed Fax Queue',
    icon: 'k-i-minus',
    route: '/failed-fax-queue'
  },
  {
    id: 66,
    key: menuKeys.LOG_OUT,
    text: 'Log Out',
    icon: 'k-i-login',
    route: '/',
    callback: async () => {
      await Auth.signOut()
        .then((data) => console.log(data))
        .catch((err) => console.log(err));
    }
  }
];
