import React, { useContext, useState } from 'react';

// components
import { Dialog } from '@progress/kendo-react-dialogs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import AwesomeLabel from '@/components/common-components/AwesomeLabel';

// context
import { WorkItemContext } from '@/context/WorkItemContext';

// constants
import { workItemArchiveReasons } from '@/constants/enum';

// styles
import styles from './ItemActions.module.scss';

const ArchiveItemReason = ({ isVisible, onClose }) => {
  const [reason, setReason] = useState({ value: null });

  const { archiveWorkItem } = useContext(WorkItemContext);

  const onReasonChange = (event) => {
    setReason({ value: event.target.value });
  };

  const onArchiveSubmit = async () => {
    const data = await archiveWorkItem(reason);
    if (data?.data?.updateWorkItemStatus?.success) {
      onClose();
    }
  };

  if (!isVisible) return null;

  return (
    <Dialog title="Archive Work Item" onClose={onClose}>
      <div className={styles.modalBody}>
        <div>Select a reason for archiving:</div>
        <DropDownList
          data={workItemArchiveReasons}
          textField="text"
          dataItemKey="id"
          value={reason.value}
          onChange={onReasonChange}
          style={{
            width: '350px'
          }}
        />
      </div>
      <div className={styles.actions}>
        <button className={styles.actionButton} onClick={onClose}>
          <AwesomeLabel icon="xmark" label="Cancel" />
        </button>
        <button
          className={styles.actionButton}
          onClick={onArchiveSubmit}
          disabled={!reason.value}
        >
          <AwesomeLabel icon="box-archive" label="Archive Work Item" />
        </button>
      </div>
    </Dialog>
  );
};

export default ArchiveItemReason;
