import React, { useContext } from 'react';
import Drawer from 'rc-drawer';
import styled from 'styled-components';

// components
import Preloader from '@/components/common-components/Preloader';
import AwesomeLabel from '@/components/common-components/AwesomeLabel';
import Badge from '@/components/common-components/Badge';

// utils
import { utc2local } from '@/common/DateHelper';
import { getLogTitle } from '@/components/WorkItemLogDrawer/WorkItemLog.utils';
import { taskStatus } from '@/constants/enum';

// context
import { WorkItemContext } from '@/context';

const DrawerContent = styled.div`
  padding: 15px;
`;

const LogRecord = styled.li`
  padding: 10px 0;
`;

const LogList = styled.ul`
  padding-inline-start: 20px;

  div {
    padding-top: 10px;
  }
`;

const CallHistoryDrawer = () => {
  const {
    isLogLoading,
    showCallHistoryLog,
    setShowCallHistoryLog,
    callHistoryLog
  } = useContext(WorkItemContext);

  if (!showCallHistoryLog) return null;

  const renderLogRecord = (logRecord, index) => {
    const elKey = `log-item-${index}`;
    const when = utc2local(logRecord.recordTime);
    const hasNotes = logRecord.notes && logRecord.notes !== '';
    const attemptCount = callHistoryLog.length - index;
    const isFullyResolved = logRecord.afterWorkStatus === taskStatus.COMPLETED;

    return (
      <LogRecord key={elKey}>
        <Badge text={`Attempt #${attemptCount}`} type='success' />
        {isFullyResolved && <Badge text='Fully Resolved' type='info' />}
        <AwesomeLabel icon='user' label={logRecord.agentId} />
        <div>{getLogTitle(logRecord)}</div>
        <div>
          <small>
            <AwesomeLabel icon='calendar' label={when} />
          </small>
        </div>
        {hasNotes && (
          <div>
            <small>
              <AwesomeLabel
                icon='notes'
                label={
                  <>
                    <b>Notes: </b>
                    {logRecord.notes}
                  </>
                }
              />
            </small>
          </div>
        )}
      </LogRecord>
    );
  };

  return (
    <Drawer
      open={showCallHistoryLog}
      onClose={() => {
        setShowCallHistoryLog(false);
      }}
    >
      <DrawerContent>
        <h5>Call History</h5>
        <Preloader show={isLogLoading}>
          <LogList>
            {callHistoryLog?.map((logRecord, i) =>
              renderLogRecord(logRecord, i)
            )}
          </LogList>
          {callHistoryLog?.length === 0 && (
            <p>No call history records found.</p>
          )}
        </Preloader>
      </DrawerContent>
    </Drawer>
  );
};

export { CallHistoryDrawer };
