import moment from 'moment';
import { Constants } from '@/constants';

// format date to 'MM/DD/YYYY' default format
export const formatDateToDefault = (origDate = moment()) => {
  return moment(origDate).format(Constants.DATE.DEFAULT);
};

// format date and time to 'MM/DD/YYYY HH:mm' format
export const formatDateTimeToDefault = (origDate = moment()) => {
  return moment(origDate).format(Constants.DATE.DEFAULT_DATETIME);
};

// format time to 'hh:mm A' format (07:18 PM)
export const formatTimeToDefault = (origDate = moment(), timeZone) => {
  if (timeZone?.length) {
    const dateTime = new Date(origDate);
    const dateTimeForTimeZone = dateTime.toLocaleString('en-US', { timeZone });
    return moment(dateTimeForTimeZone).format(Constants.DATE.DEFAULT_TIME);
  }
  return moment(origDate).format(Constants.DATE.DEFAULT_TIME);
};
export const toTimestamp = (strDate) => {
  const dateTime = new Date(strDate).getTime();
  return dateTime / 1000;
};
export const toTenDigitTimestamp = (strDate) => {
  const dateTime = Math.floor(strDate / 1000);
  return dateTime;
};

// returns current timestamp as formatted date (or date + time, if `withTime` set to `true`)
export const getNow = (withTime = false) => {
  const format = withTime
    ? Constants.DATE.DEFAULT_DATETIME
    : Constants.DATE.DEFAULT;

  return moment().format(format);
};

// convert time in seconds to user-friendly format like - `2 hrs 9 min`
export const formatSecondsGently = (seconds) => {
  return moment.utc(seconds * 1000).format('H [hrs], m [min]');
};

// format date to AWSDate - 'YYYY-MM-DD' default format
export const formatDateToAWS = (origDate = moment()) => {
  return moment(origDate).format(Constants.DATE.DEFAULT_AWS);
};

export const formatDateToAWSDateTime = (origDate = moment()) => {
  // return moment(momentDate).format(Constants.DATE.DEFAULT_AWS_DATETIME);
  return new Date(origDate).toISOString();
};

export const isoStringToDate = (date) => {
  if (!date) return null;
  const offsetInMins = new Date().getTimezoneOffset();
  return new Date(moment(date).add(offsetInMins, 'minutes'));
};

// Adjust date passed in UTC format with a custom timezone
// E.g. We creating an infusion in California for 9 am
// It saves on the server side as - "2021-09-22T17:00:00.000Z"
// Which means 5 pm in UTC.
// ***
// Then when we come back and select that date under location with custom timezone
// We'd like to see that exact time as 9 am, not as 5 pm.
// ***
// This converter helps to do that, just provide the original value from server
// As well as custom timezone in text format
// (can be received by location's zip code using another helper - `getTimeZoneForLocation`)
export const adjustUTC = (
  dateUTC = formatDateTimeToDefault(),
  timeZone = 'America/Los_Angeles'
) => {
  return new Date(dateUTC).toLocaleString(undefined, { timeZone });
};

export const utc2local = (dateUTC = new Date()) => {
  return new Date(dateUTC).toLocaleString('en-US');
  //   const myTime = new Date(myDateTime).toLocaleTimeString('en-US');
};

export const isIsoDate = (dateStr) => {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(dateStr)) return false;
  const d = new Date(dateStr);
  return d.toISOString() === dateStr;
};

// check if passed date is from the past, not today
export const isDateFromPast = (date) => {
  const incomingDate = new Date(date);
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return incomingDate < today;
};

// Check if date is todays date
export const isToday = (someDate) => {
  const today = new Date();
  return (
    someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
  );
};

// Rounds time to 15 minutes
export const calculateToFifteen = (date) => {
  const minutes = 15;
  const ms = 1000 * 60 * minutes;
  const newTime = new Date(Math.round(date.getTime() / ms) * ms);
  return newTime;
};

// Calculate age from birthday
export const calculateAge = (birthday) => {
  const birth = new Date(birthday);
  const ageDifMs = Date.now() - birth.getTime();
  const ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};
export const calculateWorkItemAge = (created) => {
  const date1 = new Date(created);
  const date2 = new Date();

  // To calculate the time difference of two dates
  const differenceInTime = date2.getTime() - date1.getTime();

  // To calculate the no. of days between two dates
  const differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));

  // To display the final no. of days (result)
  return differenceInDays;
};
export const isValidDate = (d) => {
  return d instanceof Date && !Number.isNaN(d);
};

// Remove leading zeroes from months/days
// E.g. 2022-04-09 ---> 2022-4-9
export const removeLeadingZeroes = (dateString = formatDateToDefault()) =>
  dateString.replace(/(^|-)0+/g, '$1');

export const dateDiffInDays = (a, b) => {
  const MS_PER_DAY = 1000 * 60 * 60 * 24;

  // Discard the time and time-zone information
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / MS_PER_DAY);
};

/**
 * Calculate the difference between two dates.
 * @param {Date} date1 first date
 * @param {Date} date2 second date
 * @return {[number, string]} array containing the difference and the time unit of measure
 */
export const timeDiff = (date1, date2) => {
  if (!(date1 instanceof Date && date2 instanceof Date)) {
    throw new RangeError('DateHelper::timeDiff - Invalid date arguments');
  }

  const timeIntervals = [31536000, 2628000, 604800, 86400, 3600, 60, 1];
  const intervalNames = [
    'year',
    'month',
    'week',
    'day',
    'hour',
    'minute',
    'second'
  ];
  const diff = Math.abs(date2.getTime() - date1.getTime()) / 1000;
  const index = timeIntervals.findIndex((i) => diff / i >= 1);
  const n = Math.floor(diff / timeIntervals[index]);
  const interval = intervalNames[index];
  return [n, interval];
};
/**
 * Format a date difference into a string.
 * @param {number} value numeric value
 * @param {string} str time unit
 * @return {string} value and unit, taking plurals into account
 */
export const localizeTimeDiff = (value, str) => {
  if (value !== 1) {
    str += 's';
  }

  return `${value} ${str}`;
};

export const getOneYearAgoDate = () => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - 1);
  return date;
};

export const getDateTimePlusOneDay = (incomingDate) => {
  const date = incomingDate ? new Date(incomingDate) : new Date();

  // add a day
  date.setDate(date.getDate() + 1);
  return date;
};

export const isDateTimeLessThenNow = (date) => {
  const targetDate = new Date(date);
  const today = new Date();
  return targetDate < today;
};
