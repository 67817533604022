import React, { useContext, useEffect, useState } from 'react';
import { Storage } from 'aws-amplify';
import * as moment from 'moment';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Switch } from '@progress/kendo-react-inputs';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Form, Field } from '@progress/kendo-react-form';

// common
import { validateInput } from '@/common/Validation';
import {
  getPatientWeightFromReferral,
  toProperCase
} from '@/common/Converters';
import { formatDateToDefault } from '@/common/DateHelper';
import { getCabenuvaDosageLabel } from '@/common/viivHelper';

// gql
import { connectToGraphqlAPI } from '@/provider';
import {
  addUpdatePatientDocs,
  updateStepOrderReview
} from '@/graphql/mutations';
import { UserContext } from '@/context/UserContext';
import { InfusionContext } from '@/context/InfusionContext';
import { NotifContext } from '@/context/NotifContext';

import RevisitOrderReviewV2 from './RevisitOrderReviewV2';
import {
  getStepStatusV2,
  infusionStepNumbersV2,
  infusionSteps
} from '../Infusion/infusionHelper';
import { getDosageUOM } from '@/common/DoseHelper';

const OrderReviewV2 = (props) => {
  const { user, agent } = useContext(UserContext);
  const { infusion, setInfusion, setCurrentStep, updateStepper } =
    useContext(InfusionContext);
  const { showSuccess, showWarning, showError } = useContext(NotifContext);

  const { nursingProcess, currentReferral } = infusion;

  const [itemAdministrations, setItemAdministrations] = useState([]);
  const [itemPreMeds, setItemPreMeds] = useState([]);

  const [isOrderApproved, setIsOrderApproved] = useState(false);
  const [isPatientConsentReceived, setIsPatientConsentReceived] =
    useState(false);

  const [showAddendumDialog, setShowAddendumDialog] = useState(false);

  useEffect(() => {
    handleLoadInfusion(infusion?.currentReferral);
  }, [infusion]);

  const updateStepOrderReviewCall = async (input) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: updateStepOrderReview,
        variables: { input }
      });
      if (data?.data?.updateStepOrderReview) {
        setCurrentStep(infusionStepNumbersV2.ASSESSMENT);

        const orderApproved = input.orderIsApproved;

        const infusionData = { ...infusion };
        infusionData.nursingProcess.stepReview = {
          ...infusionData.nursingProcess.stepReview,
          ...data.data.updateStepOrderReview,
          orderApproved, // hack due to the field naming inconsitency
          ...input
        };

        setInfusion({ ...infusionData });

        // update stepper progress checkmark
        const stepInput = { ...input, orderApproved };
        const stepIsValid = getStepStatusV2(
          infusionSteps.ORDER_REVIEW,
          stepInput
        );
        updateStepper(stepIsValid);
      }
    } catch (err) {
      console.error('OrderReviewV2::updateStepOrderReviewCall err: ', err);
      showError('Error: check logs for OrderReviewV2::updateStepOrderReviewCall module');
    }
  };

  const handleLoadInfusion = (dataObject) => {
    if (dataObject && dataObject.referralOrder) {
      const selectedOrder = dataObject;

      setItemAdministrations(selectedOrder.referralOrder.administrations.map((item) => ({
          ...item,
          text: item.orderName,
          value: item.orderName
        })));

      setItemPreMeds(selectedOrder.referralOrder.preMedications.map((item) => ({
          ...item,
          text: item.orderName,
          value: item.orderName
        })));
    }

    setIsOrderApproved(nursingProcess.stepReview?.orderApproved);
    setIsPatientConsentReceived(nursingProcess.stepReview?.patientConsentReceived);
  };

  const infusionForm = {
    orderIsApproved: {
      value: !!nursingProcess.stepReview?.orderApproved,
      inputValidator: (value) => {
        return validateInput({
          orderIsApproved: { ...infusionForm.orderIsApproved, value }
        });
      },
      validations: []
    },
    patientConsentReceived: {
      value: !!nursingProcess.stepReview?.patientConsentReceived,
      inputValidator: (value) => {
        return validateInput({
          patientConsentReceived: {
            ...infusionForm.patientConsentReceived,
            value
          }
        });
      },
      validations: []
    }
  };

  const toggleAddendum = () => {
    setShowAddendumDialog(!showAddendumDialog);
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const { patientId } = nursingProcess;
    try {
      // Upload the file to s3 with public (internally private) access level.
      await Storage.put(`patientDocs/${patientId}/${file.name}`, file, {
        level: 'public'
      });

      const requestObject = {
        patientId,
        agentId: user.username,
        patientDocuments: {
          documentType: 'ReferralAddendum',
          documentPath: `patientDocs/${patientId}/${file.name}`,
          date: moment().utc().format()
        }
      };

      callAddUpdatePatientDocs(requestObject);
    } catch (err) {
      console.error(err);
    }
  };

  // @TODO: review and update the logic
  const callAddUpdatePatientDocs = async (requestObject) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: addUpdatePatientDocs,
        variables: {
          input: requestObject
        }
      });

      // close modal
      toggleAddendum();

      if (data && data.data && data.data.addUpdatePatientDocs) {
        showSuccess('Referral Addendum uploaded sucessfully.');
      }

      // update patient docs with the new addendum record
      const pDocs = infusion.patientBucket.patientDocuments
        ? [...infusion.patientBucket.patientDocuments]
        : [];
      const { documentType, documentPath, date } =
        requestObject.patientDocuments;

      pDocs.push({ documentType, documentPath, date });
      setInfusion({
        ...infusion,
        patientBucket: { ...infusion.patientBucket, patientDocuments: pDocs }
      });
    } catch (err) {
      console.error('OrderReviewV2::callAddUpdatePatientDocs err: ', err);
    }
  };

  const handleSubmit = (dataItem) => {
    const { orderIsApproved, patientConsentReceived } = dataItem.values;

    if (!orderIsApproved || !patientConsentReceived) {
      showWarning('You must approve the order and confirm that Patient Consent received.');
      return;
    }

    const requestObject = {
      // STEP 1
      // input UpdateStepOrderReviewInput {
      // updateStepOrderReviewInput: {
      // nursingProcessId: ID!
      nursingProcessId: nursingProcess.id,
      // agentId: ID!
      agentId: agent.agentId,
      // addendumOrderFilePath: String
      addendumOrderFilePath: null,
      // orderIsApproved: Boolean!
      orderIsApproved,
      // patientConsentReceived: Boolean!
      patientConsentReceived
    };

    updateStepOrderReviewCall(requestObject);
  };

  const patientWeightKg = getPatientWeightFromReferral(infusion.currentReferral);

  const toggleOrderApproval = () => {
    setIsOrderApproved(!isOrderApproved);
  };

  const togglePatientConsent = () => {
    setIsPatientConsentReceived(!isPatientConsentReceived);
  };

  if (props.infusionRestricted) {
    return (
      <RevisitOrderReviewV2
        itemAdministrations={itemAdministrations}
        itemPreMeds={itemPreMeds}
      />
    );
  }

  const initialFormValues = {
    orderIsApproved: isOrderApproved,
    patientConsentReceived: isPatientConsentReceived
  };

  const { referralOrder } = infusion.currentReferral;

  const render = {
    doseCell: ({ dataItem }) => {
      if (dataItem.drugName === 'Cabenuva') {
        return <td>{getCabenuvaDosageLabel(dataItem.approvedDosage)}</td>;
      }
      return (
        <td>
          {dataItem.approvedDosage} {dataItem.unitOfMeas}
        </td>
      );
    },
    calcDoseCell: ({ dataItem }) => {
      if (dataItem.drugName === 'Cabenuva') {
        return <td>{getCabenuvaDosageLabel(dataItem.dose)}</td>;
      }
      return (
        <td>
          {dataItem.calcDosage} {getDosageUOM(dataItem.unitOfMeas)}
        </td>
      );
    }
  };

  return (
    <div className="infusion-page">
      <div className="col-md-10" style={{ border: '1px solid #afaaaa' }}>
        <div className="row">
          <div className="infusion-HeaderRow col-12 ml-0 pl-3 py-3 mr-0">
            <div className="row">
              <div className="col-md-4 headerText">
                Order Name:
                <br />
                {referralOrder?.orderName}
              </div>
              <div className="col-md-4 headerText">
                Order Type:
                <br />
                {toProperCase(referralOrder?.orderType)}
              </div>
              <div className="col-md-4 headerText">
                Referral ID:
                <br />
                {nursingProcess?.referralId}
              </div>
            </div>
          </div>
        </div>
        {patientWeightKg > 0 && (
          <div className="row">
            <div className="col-md-12 mt-08">
              Patient Weight on Order: {patientWeightKg} KG
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-md-12 mt-08">
            <Grid data={itemAdministrations} className="infusion-grid">
              <Column field="drugName" title="PRODUCT NAME" width="150px" />
              <Column field="route" title="ROUTE" width="80px" />
              <Column field="administer" title="ADMINISTER" width="240px" />
              <Column field="maxOfTreatments" title="MAX #" width="80px" />
              <Column title="DOSE" width="130px" cell={render.doseCell} />
              <Column
                title="CALC DOSE"
                width="130px"
                cell={render.calcDoseCell}
              />
            </Grid>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 mt-08">
            <Grid data={itemPreMeds} className="infusion-grid">
              <Column field="drugName" title="PRE-MEDICATION" width="150px" />
              <Column field="route" title="ROUTE" width="80px" />
              <Column field="administer" title="ADMINISTER" width="240px" />
              <Column field="" title="MAX #" width="80px" />
              <Column title="DOSE" width="130px" cell={render.doseCell} />
              <Column
                title="CALC DOSE"
                width="130px"
                cell={render.calcDoseCell}
              />
            </Grid>
          </div>
        </div>
        <div className="infusion-details col-12 ml-0 pl-3 py-3 mr-0">
          <div>
            <div className="row">
              <div className="col-md-4 headerText">
                {currentReferral?.prescriberId}
              </div>
              <div className="col-4 headerText">
                {formatDateToDefault(referralOrder?.orderDate)}
              </div>
              <div className="col-4 headerText">
                {formatDateToDefault(referralOrder?.orderExpires)}
              </div>
            </div>
            <div className="row">
              <div className="col-4 infusion-details-field-name">
                ORDERING PROVIDER
              </div>
              <div className="col-4 infusion-details-field-name">
                ORDER DATE
              </div>
              <div className="col-4 infusion-details-field-name">
                ORDER EXPIRES
              </div>
            </div>
            <div className="row mt-08">
              <div className="col-md-4 headerText">
                <b>{referralOrder?.primaryDX?.primaryDiagnosis}</b>
                <br />
                {referralOrder?.primaryDX?.description}
              </div>
              {referralOrder?.otherDX?.length > 0 && (
                <>
                  <div className="col-4 headerText">
                    {referralOrder?.otherDX?.map((dx, i) => (
                      <div key={i}>
                        <strong>{dx.primaryDiagnosis}</strong>
                        {'  '}
                        {dx.description && <div>{dx.description}</div>}
                      </div>
                    ))}
                  </div>
                </>
              )}
              <div className="col-4 headerText notesWrapper">
                {referralOrder?.notes}
              </div>
            </div>
            <div className="row">
              <div className="col-4 infusion-details-field-name">
                PRIMARY DX
              </div>
              {referralOrder?.otherDX?.length > 0 && (
                <div className="col-4 infusion-details-field-name">
                  OTHER DX
                </div>
              )}
              <div className="col-4 infusion-details-field-name">NOTES</div>
            </div>
          </div>
        </div>
      </div>

      <Form
        onSubmitClick={handleSubmit}
        initialValues={initialFormValues}
        key={JSON.stringify(initialFormValues)}
        render={(formRenderProps) => (
          <form
            onSubmit={formRenderProps.onSubmit}
            className="k-form pl-3 pr-3 pt-1"
          >
            <div className="row mt-3">
              <div className="col-md-3">
                Order is Approved: &nbsp;
                <Field
                  name="orderIsApproved"
                  onLabel="Yes"
                  offLabel="No"
                  component={Switch}
                  value={isOrderApproved}
                  defaultChecked={isOrderApproved}
                  onChange={toggleOrderApproval}
                />
              </div>
              <div className="col-md-5">
                Patient Consent Received: &nbsp;
                <Field
                  name="patientConsentReceived"
                  onLabel="Yes"
                  offLabel="No"
                  component={Switch}
                  value={isPatientConsentReceived}
                  defaultChecked={isPatientConsentReceived}
                  onChange={togglePatientConsent}
                />
              </div>
              <div className="col-md-3">
                <button
                  className="k-button"
                  onClick={(e) => {
                    e.preventDefault();
                    toggleAddendum();
                  }}
                >
                  Add Addendum
                </button>
                <span
                  className="k-icon k-i-file-txt k-icon-32"
                  style={{ color: 'blue' }}
                />
              </div>
            </div>
            <div className="row mt-3 mb-5">
              <div className="col-12">
                <button type="submit" className="k-button pageButton">
                  Save
                </button>
              </div>
            </div>
          </form>
        )}
      />

      <div className="row mt-5 mb-5" />

      {showAddendumDialog && (
        <Dialog
          title="Referral Addendum"
          width={450}
          height={400}
          onClose={toggleAddendum}
        >
          <div className="row mt-1 ml-1">
            <div>Select File to Upload:</div>
          </div>
          <div className="row mt-3 ml-1">
            <div className="col-12">
              <input
                type="file"
                accept="image/jpg,
                            image/jpeg,
                            image/gif,
                            image/png,
                            image/svg+xml,
                            application/pdf,
                            application/msword,
                            application/vnd.openxmlformats-officedocument.wordprocessingml.document
                        "
                onChange={(evt) => handleFileUpload(evt)}
              />
            </div>
          </div>
        </Dialog>
      )}
    </div>
  );
};

export default OrderReviewV2;
