import React from "react"
import { Chip } from "@progress/kendo-react-buttons";
import { formatDateToDefault } from "../../common/DateHelper";
import styles from "../../styles/StatusBar.module.scss";

export const FollowupItem = (props) => {
    const sDate = formatDateToDefault(props.date);
    const sText = sDate + " " + props.category + ":";
    const sCategory = props.category;
    

    return (
      <div
        className={styles.categoryChip}
        style={{ marginLeft: "0.0rem", marginTop: "0.4rem" }}
      >
        <Chip
          selection="single"
          text={sText}
          value="info"
          followupid={props.id}
          followupcategory={sCategory}
          followupdate={sDate}
          followupnote={props.note}
          followupassignedto={props.assignedTo}
          followUpWorkType={props.workType}
          type="info"
          style={{ backgroundColor: "#bedafa", fontSize: "11px", maxWidth: 200  }}
          look="outlined"
          removeIcon="k-i-edit"
          removable={true}
          onClick={props.clickEvent}
        />
      </div>
    );
  };
    
