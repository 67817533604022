import React from 'react';

// components
import PatientStatusBadge from './PatientStatusBadge';
import AwesomeLabel from './AwesomeLabel';

const PatientStatusTransition = ({ logRecord }) => {
  return (
    <>
      <PatientStatusBadge status={logRecord.beforeStatus} />
      &nbsp;&nbsp;
      <AwesomeLabel icon='arrow-right' label=' ' />
      <PatientStatusBadge status={logRecord.afterStatus} />
    </>
  );
};

export default PatientStatusTransition;
