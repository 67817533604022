import React, { useEffect, useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import * as moment from 'moment';
import { Button } from '@progress/kendo-react-buttons';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { orderBy, process } from '@progress/kendo-data-query';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { Field, Form } from '@progress/kendo-react-form';
import { Dialog } from '@progress/kendo-react-dialogs';

// components
import Badge from '@/components/common-components/Badge';
import { MessageDialog } from '@/components/common-components/MessageDialog';
import { ColumnMenu } from '@/components/common-components/columnMenu';
import Preloader from '@/components/common-components/Preloader';

// gql
import { connectToGraphqlAPI } from '@/provider';
import {
  getWorkItemsFromScheduleQueue,
  getPatientBucket,
} from '@/graphql/queries';
import { snoozeWorkItem } from '@/graphql/mutations.js';

// context
import { UserContext } from '@/context/UserContext';
import { PatientContext } from '@/context/PatientContext';

// helpers
import { formatDateToAWSDateTime, } from '@/common/DateHelper';
import {
  DatePickerField,
  isDateGreaterThanToday,
  validateInput,
} from '@/common/Validation';

// constants
import { Constants } from '@/constants';

// partials
import PatientManualSearch from '../Agent/PatientManualSearch';

const Scheduler = (props) => {
  const [sort, setSort] = useState([{ field: 'patientId', dir: 'asc' }]);
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(100);
  const [setMinWidth, setSetMinWidth] = useState(false);
  const [, setGridCurrent] = useState(0);
  const [dialogOption, setDialogOption] = useState({});
  const [queueTableData, setQueueTableData] = useState([]);
  const initialDataState = {};
  const [dataState, setDataState] = useState();
  const [resultState, setResultState] = useState(process(queueTableData, initialDataState));
  const [visibleSnoozeDateDialog, setVisibleSnoozeDateDialog] = useState(false);
  const [currentSelRow, setCurrentSelRow] = useState({});
  const [loading, setLoading] = useState(true);

  const { user, agent } = useContext(UserContext);
  const { setSelectedPatientInfo } = useContext(PatientContext);

  const _exporter = React.createRef();

  const exportExcel = () => {
    if (_exporter.current) {
      _exporter.current.save();
    }
  };

  const schedulingTypes = [
    { label: 'Standard', value: 'STANDARD', index: 1 }, // per weiping used by earlier versions
    { label: 'Standard', value: 'STANDARD', index: 5 },
    { label: 'Priority', value: 'PRIORITY', index: 10 },
    { label: 'Priority Antibiotic', value: 'PRIORITY_ANTIBIOTIC', index: 9 },
  ];

  const initialStartNewDate = new Date();
  const initialEndNewDate = new Date();
  initialStartNewDate.setDate(initialEndNewDate.getDate() - 14); // go 14 days PRIOR

  const fromData = localStorage.getItem('FROM');
  const toData = localStorage.getItem('TO');
  const fromDate = fromData ? new Date(fromData) : initialStartNewDate;
  const toDate = toData ? new Date(toData) : initialEndNewDate;

  const initialStartDate = fromDate || initialStartNewDate;
  const initialEndDate = toDate || initialEndNewDate;

  const minGridWidth = 0;

  const handleSnoozeItemClick = (dataObject) => {
    toggleSnoozeDateDialog();
    setCurrentSelRow(dataObject);
  };

  const toggleSnoozeDateDialog = () => {
    setVisibleSnoozeDateDialog(!visibleSnoozeDateDialog);
  };

  const handleSnoozeItemSubmit = async (dataItem) => {
    try {
      const reconcileRecords = [
        {
          afterQuantity: dataItem.newQuantity, // provided as string... how get to int?
          beforeQuantity: currentSelRow.currentQuantity,
          inventoryId: currentSelRow.id,
          reason: 'RECONCILE',
          updatedBy: user.username,
        },
      ];

      await connectToGraphqlAPI({
        graphqlQuery: snoozeWorkItem,
        variables: {
          agentId: user.username,
          targetDate: formatDateToAWSDateTime(dataItem.snoozeUntil),
          workItemId: currentSelRow.id,
        },
      }).then(() => {
        toggleSnoozeDateDialog();
        getWorkItemsFromScheduleQueueCall(initialStartDate, initialEndDate);
        setDialogOption({
          title: 'Snoozing Schedule Item',
          message: 'Item Snooze Date was succesfully updated.',
          showDialog: true,
        });
      });
    } catch (err) {
      if (err && err.errors && err.errors.length > 0) {
        //
      }
    }
  };

  /// /////////////////////  End Snoozing section

  const onDataStateChange = React.useCallback(
    (e) => {
      setDataState(e.dataState); // store for use
      setResultState(process(queueTableData, e.dataState));
    },
    [queueTableData]
  );

  useEffect(() => {
    // if (!loading) {
    setDataState(initialDataState);
    setResultState(process(queueTableData, initialDataState));
    // }
  }, [queueTableData]);

  // MAIN INITIATOR
  useEffect(() => {
    getWorkItemsFromScheduleQueueCall(initialStartDate, initialEndDate);
  }, [agent]);

  const getWorkItemsFromScheduleQueueCall = async (dateFrom, dateTo) => {
    try {
      if (agent.agentId) {
        const localFromDate = formatDateToAWSDateTime(dateFrom);
        const localToDate = formatDateToAWSDateTime(dateTo);
        const response = await connectToGraphqlAPI({
          graphqlQuery: getWorkItemsFromScheduleQueue,
          variables: {
            agentId: agent.agentId,
            period: {
              startDate: localFromDate,
              endDate: localToDate,
            },
          },
        });
        setLoading(false);

        if (
          response &&
          response.data &&
          response.data.getWorkItemsFromScheduleQueue &&
          response.data.getWorkItemsFromScheduleQueue.length > 0
        ) {
          setQueueTableData(response.data.getWorkItemsFromScheduleQueue.map((item) => {
              item.dateAdded = moment(new Date(item.dateAdded)).format(Constants.DATE.SHORTDATE);
              item.patientName = `${item.patientFirstName} ${item.patientLastName}`;
              item.location =
                item?.locationAndProviders &&
                item?.locationAndProviders.length > 0
                  ? item?.locationAndProviders[0]?.locationName
                  : '';
              item.locationId =
                item?.locationAndProviders &&
                item?.locationAndProviders.length > 0
                  ? item?.locationAndProviders[0]?.locationId
                  : '';
              item.provider =
                item?.locationAndProviders &&
                item?.locationAndProviders.length > 0
                  ? `${item?.locationAndProviders[0]?.providerFirstName} ${item.locationAndProviders[0].providerLastName}`
                  : '';
              item.freeDrug = item.freeDrug ? 'Yes' : 'No';
              item.medicare = item.medicare ? 'Yes' : 'No';
              item.displayPriority = schedulingTypes.find((x) => x.index === item.priority)?.label;
              return item;
            }));
        }
      }
    } catch (err) {
      console.log('marty getWorkItemsFromScheduleQueueCall err', err);
      setDialogOption({
        title: 'Scheduling Queue',
        message: 'Error: getWorkItemsFromScheduleQueueCall',
        showDialog: true,
      });
      setLoading(false);
    }
  };

  const handleResize = () => {
    const grid = document.querySelector('.k-grid');
    if (grid.offsetWidth < minGridWidth && !setMinWidth) {
      setSetMinWidth(true);
    } else if (grid.offsetWidth > minGridWidth) {
      setGridCurrent(grid.offsetWidth);
      setSetMinWidth(false);
    }
  };

  const pageChange = (event) => {
    setSkip(event.page.skip);
    setTake(event.page.take);
  };

  const onRowClickHandle = (e) => {
    if (agent.agentId) {
      getPatientBucketData(
        e.dataItem.patientId,
        'CALENDAR', // e.dataItem.currentStage
        moment(new Date()).format('MM/DD/YYYY'),
        e.dataItem.locationId
      );
    }
  };

  const getPatientBucketData = async (
    patientId,
    currentStage,
    searchDate,
    searchLocationId
  ) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getPatientBucket,
        variables: { patientId },
      });
      if (data && data.data && data.data.getPatientBucket) {
        setSelectedPatientInfo(data.data.getPatientBucket);
        props.history.push('/patient-portal', {
          searchType: currentStage,
          searchDate,
          searchLocationId,
        });
      } else {
        setDialogOption({
          title: 'Scheduling Queue: Get Patient',
          message: 'No Patient Record Found',
          showDialog: true,
        });
      }
    } catch (err) {
      console.log('marty getPatientBucketData err', err);
    }
  };

  const gridEmptyRow = (tableData) => {
    const rows = [];
    if (tableData.length < 100) {
      for (let i = tableData.length, l = 100; i < l; i++) {
        rows.push({
          dateAdded: null,
          patientName: null,
          patientId: null,
          orderName: null,
          orderType: null,
          drugType: null,
          displayPriority: null,
          followupDate: null,
          medicare: null,
          location: null,
          locationId: null,
          provider: null,
          freeDrug: null,
          id: null,
        });
      }
      // console.log(rows)
    }
    return rows;
  };

  const handleDateRangeSubmit = (event) => {
    setLoading(true);

    localStorage.setItem('FROM', event.fromDateRange);
    localStorage.setItem('TO', event.toDateRange);
    const dateFrom = localStorage.getItem('FROM');
    const dateTo = localStorage.getItem('TO');
    getWorkItemsFromScheduleQueueCall(dateFrom, dateTo);
  };

  const snoozeForm = {
    snoozeUntil: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          snoozeUntil: { ...snoozeForm.snoozeUntil, value },
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED,
        },
      ],
    },
  };

  const render = {
    patientNameCell: ({ dataItem: { inProgram, patientName } }) => (
      <td>
        {inProgram && (
          <Badge type="info" text="STAT ViiV" />
        )}
        {patientName}
      </td>
    ),
    actionCell: ({ dataItem }) => (
      <td>
        <div className="row">
          <div className="col-md-4">
            <Button
              type="button"
              title="Edit Quantity"
              onClick={() => handleSnoozeItemClick(dataItem)}
            >
              Snooze Item
            </Button>
          </div>
        </div>
      </td>
    )
  };

  const columns = [
    {
      field: 'dateAdded',
      title: 'Date Added',
      width: 120,
      columnMenu: ColumnMenu
    },
    {
      field: 'patientName',
      title: 'Patient Name',
      columnMenu: ColumnMenu,
      cell: render.patientNameCell
    },
    {
      field: 'patientId',
      title: 'Patient ID',
      width: 120,
      columnMenu: ColumnMenu
    },
    {
      field: 'orderName',
      title: 'Order Name',
      width: 120,
      columnMenu: ColumnMenu
    },
    {
      field: 'orderType',
      title: 'Order Type',
      width: 120,
      columnMenu: ColumnMenu
    },
    {
      field: 'drugType',
      title: 'Drug Type',
      width: 120,
      columnMenu: ColumnMenu
    },
    {
      field: 'displayPriority',
      title: 'Priority',
      width: 120,
      columnMenu: ColumnMenu
    },
    {
      field: 'planName',
      title: 'Plan Name',
      width: 200,
      columnMenu: ColumnMenu
    },
    {
      field: 'location',
      title: 'Location',
      width: 120,
      columnMenu: ColumnMenu
    },
    {
      field: 'provider',
      title: 'Provider',
      width: 120,
      columnMenu: ColumnMenu
    },
    {
      field: 'freeDrug',
      title: 'Free Drug',
      width: 120,
      columnMenu: ColumnMenu
    },
    {
      field: 'select',
      title: 'SELECT',
      cell: render.actionCell
    },
  ];

  return (
    <div className="container-fluid">
      {dialogOption.showDialog && <MessageDialog dialogOption={dialogOption} />}
      <div className="row mt-10">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-5">
              <Form
                initialValues={{
                  fromDateRange: initialStartDate,
                  toDateRange: initialEndDate,
                }}
                onSubmit={handleDateRangeSubmit}
                render={(formRenderProps) => (
                  <form onSubmit={formRenderProps.onSubmit}>
                    <div className="row col-md-12 mt-0 mb-3">
                      <div className="col-md-4 mt-06">
                        <Field
                          component={DatePicker}
                          name="fromDateRange"
                          label="From:"
                        />
                      </div>
                      <div className="col-md-4 mt-06">
                        <Field
                          component={DatePicker}
                          name="toDateRange"
                          label="To:"
                        />
                      </div>
                      <div className="col-md-4" style={{ marginTop: '1.8rem' }}>
                        <button type="submit" className="k-button blue">
                          Run Report
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              />
            </div>

            <div className="col-md-5 offset-2 mt-0">
              <PatientManualSearch
                searchLayout={2}
                searchEndPoint="/patient-portal"
                existingOnly
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row my-4">
        <div className="col-12 work-in-progress ">
          <div className="row">
            <div className="col-11" />
            <div className="col-1 bright">
              <Button style={{ marginLeft: '-18px' }} look="flat" onClick={exportExcel}>
                <i className="fa-solid fa-file-excel ">&nbsp;&nbsp;</i>
                Export to Excel
              </Button>
            </div>
          </div>

          <div className="a-grid__header">
            <div>Scheduling Queue</div>
          </div>
          <Preloader show={loading}>
            <ExcelExport
              data={orderBy(resultState.data, sort).slice(skip, take + skip)}
              ref={_exporter}
            >
              <Grid
                className="nurse-queue_grid"
                sortable
                pageable
                sort={sort}
                skip={skip}
                take={take}
                total={queueTableData.length}
                data={orderBy(resultState.data, sort).slice(skip, take + skip)}
                onPageChange={pageChange}
                onRowDoubleClick={(e) => onRowClickHandle(e)}
                onSortChange={(e) => setSort(e.sort)}
                onDataStateChange={onDataStateChange}
                {...dataState}
              >
                {columns.map((colProps, idx) => (
                  <Column key={idx} {...colProps} />
                ))}
              </Grid>
            </ExcelExport>
          </Preloader>
        </div>
      </div>

      {visibleSnoozeDateDialog && (
        <Dialog
          title="Scheduling Item"
          width={500}
          height={350}
          top={25}
          onClose={toggleSnoozeDateDialog}
          showDialog
        >
          <Form
            // initialValues={initialForm()}
            onSubmit={handleSnoozeItemSubmit}
            validator={isDateGreaterThanToday}
            render={(formRenderProps) => (
              <form
                onSubmit={formRenderProps.onSubmit}
                className="k-form pl-2 pr-3 pt-1"
              >
                <div className="row mt-2">
                  <div className="col-7">
                    <b>Patient Name:</b> {currentSelRow.patientName}
                  </div>
                  <div className="col-4">
                    <b>ID:</b> {currentSelRow.patientId}
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-12">
                    <b>Order Name:</b> {currentSelRow.orderName}
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-12">
                    <Field
                      component={DatePickerField}
                      name="snoozeUntil"
                      label="Snooze Until"
                      validator={snoozeForm.snoozeUntil.inputValidator}
                    />
                  </div>
                </div>
                <div className="row p-3">
                  <div className="col-12" style={{ textAlign: 'center' }}>
                    <button type="submit" className="k-button pageButton">
                      Snooze
                    </button>
                  </div>
                </div>
              </form>
            )}
          />
        </Dialog>
      )}
    </div>
  );
};

export default withRouter(Scheduler);
