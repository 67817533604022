import React, { useState, useEffect } from 'react';
import { Button } from '@progress/kendo-react-buttons';

import { useHistory } from 'react-router-dom';
import { Dialog } from '@progress/kendo-react-dialogs';
import WindowDialog from '@/components/common-components/WindowDialog';

import { Notes } from '../Notes';
import PatientDocsModal from '../Patient/PatientDocsModal';
import PatientDocument from '../Patient/PatientDocument';
import logo from '@/assets/images/ALERACARElogo_square.png';

import { connectToGraphqlAPI } from '@/provider';
import { getEvent, getNursingProcess, getReferral } from '@/graphql/queries';

import { getTimeZoneForLocation } from '../Scheduling/getTimezoneForLocation';
import { formatTimeToDefault, adjustUTC } from '@/common/DateHelper';
import {
  listPatientAllergies,
  listOrderPreMeds
} from '@/components/Infusion/infusionHelper';

import TimeInChair from './TimeInChair';

const InfusionHeader = (props) => {
  const history = useHistory();

  const [showPatientDocsDialog, setShowPatientDocsDialog] = useState(false);
  const [showPatientDocDialog, setShowPatientDocDialog] = useState(false);
  const [showPatientNotesDialog, setShowPatientNotesDialog] = useState(false);
  const [selectedDocumentUrl, setSelectedDocumentUrl] = useState('');
  const [infusionDetails, setInfusionDetails] = useState(null);
  const [referalDetails, setReferalDetails] = useState(null);
  const [locationTimezone, setLocationTimezone] = useState();
  const [eventDataAdmin, setEventData] = useState();

  const togglePatientDocsDialog = () => {
    setShowPatientDocsDialog(!showPatientDocsDialog);
  };

  const toggleShowPatientDocDialog = () => {
    setShowPatientDocDialog(!showPatientDocDialog);
  };

  const togglePatientNotesDialog = () => {
    setShowPatientNotesDialog(!showPatientNotesDialog);
  };

  const handlePatientDocs = () => {
    setShowPatientDocsDialog(true);
  };

  const documentViewCallback = (s3ImageURL) => {
    setSelectedDocumentUrl(s3ImageURL);
    setShowPatientDocDialog(true);
  };

  useEffect(() => {
    setEventData('');
  }, []);

  // MAIN INITIATOR
  useEffect(async () => {
    let NPID = '';

    if (props.headerDetailsData?.nursingProcessInfo) {
      NPID = props.headerDetailsData.nursingProcessInfo;
    } else if (props.headerDetailsData?.eventInfo?.tNPID?.id) {
      NPID = props.headerDetailsData.eventInfo.tNPID.id;
    }

    if (NPID !== '') {
      // get NursingProcess details
      const data = await connectToGraphqlAPI({
        graphqlQuery: getNursingProcess,
        variables: { id: NPID }
      });

      if (data && data.data && data.data.getNursingProcess) {
        setInfusionDetails(data.data.getNursingProcess);
      }
      // get referral details
      const referralData = await connectToGraphqlAPI({
        graphqlQuery: getReferral,
        variables: {
          patientId: data.data.getNursingProcess.patientId,
          referralId: data.data.getNursingProcess.referralId
        }
      });
      if (
        referralData &&
        referralData.data &&
        referralData.data.getReferral &&
        referralData.data.getReferral.referralDetail
      ) {
        setReferalDetails(referralData.data.getReferral.referralDetail);
      }
      /// get event admin number
      const eventData = await connectToGraphqlAPI({
        graphqlQuery: getEvent,
        variables: {
          id: data.data.getNursingProcess.scheduleEventId
        }
      });
      if (eventData) {
        setEventData(eventData?.data?.getEvent?.adminSequenceNumber);
        props.childToParent(eventData?.data?.getEvent?.adminSequenceNumber);
      }
    }

    setLocationTimezone(getTimeZoneForLocation(props.headerDetailsData?.locationInfo?.address?.zip));
  }, []);

  // let startTime = moment(new Date(moment(props.headerDetailsData.eventInfo?.startTime).add(new Date().getTimezoneOffset(), 'minutes'))).format("hh:mm A").toString()
  const startTime = formatTimeToDefault(adjustUTC(infusionDetails?.startTime, locationTimezone));

  const onLogoClick = () => {
    history.push('/');
  };

  const getSequenceBasedData = () => {
    const seqBasedData = {
      calcDosage: 0,
      administer: 'N/A',
      unitOfMeas: 'N/A',
      route: 'N/A',
      dose: 0
    };

    if (!eventDataAdmin) return seqBasedData;

    const seqItem = referalDetails?.referralOrder?.administrations.find((item) => item.adminSequenceNumber === eventDataAdmin);

    if (seqItem) {
      return seqItem;
    }
    console.error(
      'Error: no administration found for sequence number: ',
      eventDataAdmin
    );

    return seqBasedData;
  };

  const { calcDosage, unitOfMeas, dose, administer, route } =
    getSequenceBasedData();

  return (
    <div className="container-fluid">
      <div className="row" style={{ background: '#005282' }}>
        <div className="col-md-12 offset-md-0 infusion-portal-header">
          <div className="row">
            <div className="col-md-1 mt-0" style={{ background: '#005282' }}>
              <div className="d-flex logo">
                <img
                  className="companyLogoInfusion"
                  src={logo}
                  alt="logo"
                  onClick={onLogoClick}
                />
              </div>
            </div>
            <div className="col-md-10  mb-1">
              <div className="row">
                <div className="col-md-10 mt-10">
                  <div className="row">
                    <div className="col-md-3 headerText">
                      {/* PATIENT:&nbsp;  */}
                      <b>
                        <big>
                          {
                            props.headerDetailsData.patientInfo
                              ?.patientFirstName
                          }{' '}
                          {props.headerDetailsData.patientInfo?.patientLastName}
                        </big>
                      </b>
                    </div>
                    <div className="col-md-3 headerText">
                      {/* PATIENT:&nbsp; */}
                      <b>DOB:</b> {props.headerDetailsData.patientInfo?.dob}
                    </div>
                    <div className="col-md-3 headerText">
                      <b>AIC:&nbsp;</b>
                      {props.headerDetailsData.locationInfo?.locationName},{' '}
                      {props.headerDetailsData.locationInfo?.state}
                    </div>
                    <div className="col-md-3 headerText">
                      <b>ARRIVAL TIME:</b>&nbsp;
                      {/* {moment(props.headerDetailsData.eventInfo?.startTime).format("HH:mm")} {props.headerDetailsData.eventInfo?.startTimeZone} */}
                      {startTime}
                      {/* {formatTimeToDefault(props.headerDetailsData.eventInfo?.startTime)} */}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-9 headerText">
                      <b>ORDER:&nbsp;</b>
                      {referalDetails?.referralOrder?.orderName}
                      &nbsp;&nbsp;&nbsp; dose:&nbsp;
                      {/* {referalDetails?.referralOrder?.administrations[0]?.dose} */}
                      {dose}
                      &nbsp;&nbsp;&nbsp; UOM:&nbsp;
                      {/* {
                        referalDetails?.referralOrder?.administrations[0]
                          ?.unitOfMeas
                      } */}
                      {unitOfMeas}
                      &nbsp;&nbsp;&nbsp; Freq:&nbsp;
                      {/* {
                        referalDetails?.referralOrder?.administrations[0]
                          ?.administer
                      } */}
                      {administer}
                      &nbsp;&nbsp;&nbsp; route:&nbsp;
                      {/* {referalDetails?.referralOrder?.administrations[0]?.route} */}
                      {route}
                      &nbsp;&nbsp;&nbsp; CalcDose:&nbsp;
                      {/* {
                        referalDetails?.referralOrder?.administrations[0]
                          ?.calcDosage
                      } */}
                      {/* {referalDetails?.referralOrder?.administrations.map(
                        index => {
                          if (index.adminSequenceNumber === eventDataAdmin) {
                            return index?.calcDosage;
                          }
                        }
                      )} */}
                      {calcDosage}
                    </div>
                    <div className="col-md-3 headerText">
                      <b>TIME IN CHAIR:</b>&nbsp;
                      {infusionDetails?.startTime && (
                        <TimeInChair
                          startTime={infusionDetails?.startTime}
                          finishTime={infusionDetails?.endTime}
                        />
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 headerText">
                      <b>PROVIDER:&nbsp;</b>
                      {props.headerDetailsData.providerInfo?.firstName}{' '}
                      {props.headerDetailsData.providerInfo?.lastName},{' '}
                      {props.headerDetailsData.providerInfo?.type}
                    </div>
                    <div className="col-md-3 headerText">
                      <b>ALLERGIES:</b>&nbsp;
                      {listPatientAllergies(props.headerDetailsData?.patientInfo?.patientProfile
                          ?.allergyInfo)}
                    </div>
                    <div className="col-md-3 headerText">
                      <b>PRE-MEDS:</b>&nbsp;
                      {listOrderPreMeds(referalDetails?.referralOrder?.preMedications)}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 headerText">
                      {/* <b>EVENT ID:</b>&nbsp;
                                            {props.headerDetailsData.eventInfo?.eventId}
                                            <br/> */}
                      <b>NURS. PROC. ID:&nbsp;</b>
                      {props.headerDetailsData.nursingProcessInfo}
                    </div>
                  </div>
                </div>
                <div className="col-md-1 mr-1" style={{ margin: '20px' }}>
                  <button
                    type="button"
                    className="k-button k-primary mt-04"
                    onClick={handlePatientDocs}
                    style={{ minWidth: 125 }}
                  >
                    <i className="fa-regular fa-folder fa-sm icon-white">
                      &nbsp;&nbsp;
                    </i>
                    Patient Docs
                  </button>
                  <br />
                  <button
                    type="button"
                    className="k-button k-primary mt-04"
                    onClick={togglePatientNotesDialog}
                    style={{ minWidth: 125 }}
                  >
                    <i className="fa-regular fa-pen-to-square fa-sm icon-white">
                      &nbsp;&nbsp;
                    </i>
                    Patient Notes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <PatientDocsModal
        showDialog={showPatientDocsDialog}
        onClose={togglePatientDocsDialog}
        documentViewCallback={documentViewCallback}
      />

      {showPatientDocDialog && (
        <Dialog
          title="Patient Document"
          height={1200}
          width={850}
          initialTop={220}
          initialLeft={700}
          onClose={toggleShowPatientDocDialog}
        >
          <div className="col-md-2 text-right mb-4">
            <Button
              title="Pdf Download"
              onClick={() => {
                const link = document.createElement('a');
                link.href = selectedDocumentUrl;
                link.target = '_blank';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }}
            >
              Pdf Download
            </Button>
          </div>
          <PatientDocument file={selectedDocumentUrl} />
        </Dialog>
      )}
      {showPatientNotesDialog && (
        <Dialog
          title="Patient Notes"
          style={{ backgroundColor: '#fff', minHeight: '300px' }}
          initialHeight={700}
          initialTop={220}
          initialLeft={20}
          width={1100}
          showDialog={showPatientNotesDialog}
          onClose={togglePatientNotesDialog}
        >
          <Notes
            selectedPatientInfo={
              (props.headerDetailsData.patientInfo, props.notes)
            }
            sendDataToParent={props.sendDataToParent}
          />
        </Dialog>
      )}
    </div>
  );
};

export default InfusionHeader;
