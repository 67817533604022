import React, { useContext } from 'react';
import styled from 'styled-components';

// kendo
import { Tooltip } from '@progress/kendo-react-tooltip';

// context
import { WorkItemContext } from '@/context';

// components
import Alert from '@/components/common-components/Alert';

const AlertWrapper = styled.div`
  padding-bottom: 10px;
  cursor: pointer;
  font-weight: 500;
`;

const WorkItemParent = () => {
  const { selectedWorkItem, isParentWorkItem } = useContext(WorkItemContext);
  const isParent = isParentWorkItem(selectedWorkItem.id);

  if (!isParent) return null;
  return (
    <Tooltip anchorElement='target' position='bottom' parentTitle={true}>
      <AlertWrapper title='Cannot close this work item until all associated tasks or calls are completed'>
        <Alert>Associated work not completed</Alert>
      </AlertWrapper>
    </Tooltip>
  );
};

AlertWrapper.displayName = 'AlertWrapper';

export default WorkItemParent;
