import React, { useState, useContext, useEffect } from 'react';
import { Storage } from 'aws-amplify';

// kendo
import { Form, Field } from '@progress/kendo-react-form';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Grid } from '@progress/kendo-react-grid';
import { GridColumn as Column } from '@progress/kendo-react-grid/dist/npm/GridColumn';
import { DateTimePicker } from '@progress/kendo-react-dateinputs';
import { Dialog } from '@progress/kendo-react-dialogs';
import { orderBy, process } from '@progress/kendo-data-query';

// components
import WindowDialog from '@/components/common-components/WindowDialog';
import FormButton from '@/components/common-components/Form/FormButton';
import PatientDocument from '@/components/Patient/PatientDocument';
import ConfirmationModal from '@/components/common-components/ConfirmationModal';

// gql
import { connectToGraphqlAPI } from '@/provider';
import { addUpdatePatientDocs } from '@/graphql/mutations';

// context
import { UserContext, UserRoleTypes, PatientContext } from '@/context';

// helpers
import { getDocTypeTitle } from '@/common/Mappers';
import {
  formatDateTimeToDefault,
  formatDateToAWSDateTime,
  getNow
} from '@/common/DateHelper';

// constants
import {
  patientDocumentTypes,
  patientDocTypesWithWorkItems
} from '@/constants/enum';

// styles
import styles from './PatientUpload.module.scss';

const acceptFileTypes = [
  'image/jpg',
  'image/jpeg',
  'image/gif',
  'image/png',
  'image/svg+xml',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
];

export const initialSort = [
  {
    field: 'receivedAt',
    dir: 'dsc'
  }
];

const PatientUpload = (props) => {
  const initialDataState = {};

  const { user, canEdit, isAdmin } = useContext(UserContext);
  const { selectedPatientInfo } = useContext(PatientContext);

  const [isUploading, setIsUploadLoading] = useState(false);
  const [documentType, setDocumentType] = useState('');
  const [selectedDocumentUrl, setSelectedDocumentUrl] = useState('');
  const [showDateModal, setShowDateModal] = useState(false);
  const [showPatientDocs, setShowPatientDocs] = useState(true);
  const [showPatientDocDialog, setShowPatientDocDialog] = useState(false);
  const [recDataItem, setRecDataItem] = useState('');
  const [loading, setLoading] = useState(false);
  const [dateTime, setDateTime] = useState(new Date(getNow(true)));
  const [recDateTime, setRecDateTime] = useState(new Date(getNow(true)));
  const [sort, setSort] = useState(initialSort);
  const [dataState, setDataState] = useState();
  const [resultState, setResultState] = useState(
    selectedPatientInfo?.patientDocuments
      ? process(selectedPatientInfo?.patientDocuments, initialDataState)
      : []
  );
  const [showConfirm, setShowConfirm] = useState(false);
  const [lockConfirm, setLockConfirm] = useState(false);
  const [eventData, setEventData] = useState();

  const handleChange = (event) => {
    setDateTime(event.value);
  };
  const handleRecChange = (event) => {
    setRecDateTime(event.value);
  };

  useEffect(() => {
    setResultState(
      selectedPatientInfo.patientDocuments
        ? process(selectedPatientInfo.patientDocuments, initialDataState)
        : []
    );
  }, [selectedPatientInfo.patientDocuments]);

  const onDataStateChange = React.useCallback(
    (e) => {
      setDataState(e.dataState); // store for use
      setResultState(
        selectedPatientInfo?.patientDocuments
          ? process(selectedPatientInfo?.patientDocuments, initialDataState)
          : []
      );
    },
    [selectedPatientInfo?.patientDocuments]
  );

  const generateDocumentPath = (fileName) => {
    const dateNow = new Date().toISOString();
    const documentPath = `patientDocs/${selectedPatientInfo.patientId}/${dateNow}_${fileName}`;
    return documentPath;
  };

  const onFileUpload = async (createWorkItem = true, rawEvent) => {
    const eData = rawEvent || eventData;
    const file = eData.target.files[0];
    const documentPath = generateDocumentPath(file.name);

    setIsUploadLoading(true);
    setLockConfirm(true);
    try {
      setShowPatientDocs(false);

      // Upload the file to s3 with public (internally private) access level.
      await Storage.put(documentPath, file, { level: 'public' });

      // Retrieve upload url & Show preview dialog
      await onPreviewDocument(documentPath);

      // Update Association
      const requestObject = {
        patientId: selectedPatientInfo.patientId,
        agentId: user.username,
        patientDocuments: {
          documentType,
          documentPath,
          date: formatDateToAWSDateTime(dateTime),
          receivedAt: formatDateToAWSDateTime(
            dateTime || new Date(getNow(true))
          )
        },
        createWorkItem // flag that tells backend if we also need to create a work item for the uploaded document
      };
      await callAddUpdatePatientDocs(requestObject);
    } catch (err) {
      console.error('Patient Upload error: ', err);
      setLockConfirm(false);
    }
    setIsUploadLoading(false);
    setShowConfirm(false);
    setLockConfirm(false);
  };

  const onRecDateUpdate = async (e) => {
    setLoading(true);
    try {
      // Update Asstrueociation
      const requestObject = {
        patientId: selectedPatientInfo.patientId,
        agentId: user.username,
        patientDocuments: {
          documentType: recDataItem.documentType,
          documentPath: recDataItem.documentPath,
          date: recDataItem.date,
          receivedAt: formatDateToAWSDateTime(recDateTime)
        }
      };
      await callAddUpdatePatientDocs(requestObject);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
    setIsUploadLoading(false);
    setLoading(false);
  };

  const callAddUpdatePatientDocs = async (requestObject) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: addUpdatePatientDocs,
        variables: {
          input: requestObject
        }
      });

      const payload = {
        message: 'Please Update Docs Component',
        request: 'pleaseUpdateDocs'
      };
      props.sendDataToParent(payload);
      setShowPatientDocs(true);
      setShowDateModal(false);
    } catch (err) {
      console.log('marty callAddUpdatePatientDocs err', err);
    }
  };

  const onPreviewDocument = async (documentPath) => {
    const s3ImageURL = await Storage.get(documentPath, { download: false });
    setSelectedDocumentUrl(s3ImageURL);
    setShowPatientDocDialog(true);
  };

  const customCell = (dataItem) => {
    return (
      <span
        className='help-link'
        onClick={() => {
          setRecDataItem(dataItem);
          setShowDateModal(!showDateModal);
        }}
      >
        Change System Date
      </span>
    );
  };
  const dateButton = (dataItem) => {
    return (
      <span
        className='help-link'
        onClick={() => {
          setRecDataItem(dataItem);
          setShowDateModal(!showDateModal);
        }}
      >
        {formatDateTimeToDefault(dataItem.receivedAt)}
      </span>
    );
  };

  const render = {
    docType: ({ dataItem }) => (
      <td>{getDocTypeTitle(dataItem.documentType)}</td>
    ),
    docDate: ({ dataItem }) => (
      <td>{formatDateTimeToDefault(dataItem.date)}</td>
    ),
    docRecDate: ({ dataItem }) => (
      <>
        {dataItem.receivedAt ? (
          <td>{formatDateTimeToDefault(dataItem.receivedAt)}</td>
        ) : (
          <td style={{ marginleft: '-50px' }}>Not Available</td>
        )}
      </>
    ),
    docRecDateLink: ({ dataItem }) => (
      <>
        {dataItem.receivedAt ? (
          <td>{dateButton(dataItem)}</td>
        ) : (
          <td style={{ marginleft: '-50px' }}>{customCell(dataItem)}</td>
        )}
      </>
    ),
    hyperLink: ({ dataItem: { documentPath } }) => (
      <td>
        {documentPath && (
          <span
            className='blue-link'
            onClick={() => onPreviewDocument(documentPath)}
          >
            {documentPath}
          </span>
        )}
      </td>
    ),
    fileUploader: () => {
      if (isUploading) {
        return <h3>Uploading...</h3>;
      }
      if (editable) {
        return (
          <div className='row'>
            <div className='col-md-2 mt-12'>RECEIVED DATE:</div>
            <div className='col-md-2 mt-12'>
              <DateTimePicker
                width='100%'
                name='dateTime'
                required
                value={dateTime}
                onChange={handleChange}
              />
            </div>
            <div className='col-md-2 mt-12'>UPLOAD FILE:</div>
            <div className='col-md-3 mt-12'>
              <input
                type='file'
                accept={acceptFileTypes.join(',')}
                disabled={!documentType}
                onChange={(e) => {
                  setEventData(e);

                  // we have to exclude some types since they do not have related work items (OPTML-1720)
                  if (patientDocTypesWithWorkItems.includes(documentType)) {
                    setShowConfirm(true);
                  } else {
                    onFileUpload(false, e);
                  }
                }}
              />
            </div>
          </div>
        );
      }
      return null;
    }
  };

  const editable = canEdit(UserRoleTypes.Upload);

  return (
    <div className='row' style={{ height: '100vh' }}>
      <div className='col'>
        <Form
          render={({ onSubmit }) => (
            <form onSubmit={onSubmit} className='k-form pl-3 pr-3 pt-1'>
              <div className='row'>
                <div className='col-md-4 pageTitle'>Patient Upload</div>
              </div>
              {editable && (
                <div className='row'>
                  <div className='col-md-2 mt-12'>DOCUMENT TYPE:</div>
                  <div className='col-md-3 mt-12'>
                    <Field
                      name='documentType'
                      onChange={(e) => setDocumentType(e.value.value)}
                      data={patientDocumentTypes}
                      label=''
                      textField='title'
                      valueField='value'
                      component={DropDownList}
                    />
                  </div>
                </div>
              )}
              {render.fileUploader()}
            </form>
          )}
        />

        {showPatientDocs && (
          <div className='row mt-16'>
            <div className='col-md-8 patient-document ml-3'>
              <Grid
                // data={selectedPatientInfo.patientDocuments || []}
                data={{
                  data:
                    selectedPatientInfo?.patientDocuments && resultState.data
                      ? orderBy(resultState.data, sort, sort)
                      : []
                }}
                editField='inEdit'
                selectedField='selected'
                style={{ height: '400px' }}
                onDataStateChange={onDataStateChange}
                {...dataState}
                sortable
                sort={sort}
                onSortChange={(e) => {
                  setSort(e.sort);
                }}
              >
                <Column
                  field='documentType'
                  title='Document Type'
                  width='150px'
                  cell={render.docType}
                />
                <Column
                  field='receivedAt'
                  title='Date System Received'
                  width='195px'
                  cell={isAdmin ? render.docRecDateLink : render.docRecDate}
                />
                <Column
                  field='date'
                  title='Date Added To Patient'
                  width='180px'
                  cell={render.docDate}
                />
                <Column
                  field='documentPath'
                  title='Document'
                  sortable={false}
                  cell={render.hyperLink}
                />
              </Grid>
            </div>
          </div>
        )}
      </div>

      {/* Patient Document */}
      <WindowDialog
        className={styles.patientDocDialog}
        title='Patient Document'
        width={850}
        initialTop={0}
        initialLeft={300}
        showDialog={showPatientDocDialog}
        onClose={() => setShowPatientDocDialog(false)}
      >
        <PatientDocument file={selectedDocumentUrl} />
      </WindowDialog>
      <div>
        {showDateModal && (
          <Dialog
            title='Update System Recevied Date'
            initialHeight={250}
            initialTop={50}
            initialLeft={100}
            width={400}
            onClose={() => setShowDateModal(false)}
          >
            <div className='row'>
              <div className='col-md-1' />
              <div className='col-md-7'>
                <DateTimePicker
                  width='100%'
                  name='dateTime'
                  required
                  value={recDateTime}
                  onChange={handleRecChange}
                />
              </div>
              <div className='col-md-4'>
                <FormButton loading={loading} onClick={() => onRecDateUpdate()}>
                  Submit
                </FormButton>
              </div>
            </div>
          </Dialog>
        )}
      </div>
      <ConfirmationModal
        isVisible={showConfirm}
        dialogDescription='Create a work item for this document?'
        dialogTitle='Work Item Creation'
        onClose={() => setShowConfirm(false)}
        onReject={() => onFileUpload(false)}
        onConfirm={() => onFileUpload()}
        disabled={lockConfirm}
      />
    </div>
  );
};

export { PatientUpload };
