import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Storage } from 'aws-amplify';

// Kendo Components
import { Stepper } from '@progress/kendo-react-layout';
import { TextArea } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Form, Field } from '@progress/kendo-react-form';
import { Dialog } from '@progress/kendo-react-dialogs';

// Common components
import WindowDialog from '@/components/common-components/WindowDialog';
import { MessageDialog } from '@/components/common-components/MessageDialog';
import Alert from '@/components/common-components/Alert';

// GraphQL
import { connectToGraphqlAPI } from '@/provider';
import { cancelTreatment, updateNarrativeNotes } from '@/graphql/mutations';

// Partials
import OrderReviewV2 from './OrderReviewV2';
import AssessmentV2 from './AssessmentV2';
import PreTreatmentV2 from './PreTreatmentV2';
import PrepV2 from './PrepV2';
import AdminV2 from './AdminV2';
import CloseTreatmentV2 from './CloseTreatmentV2';
import PatientDocument from '../Patient/PatientDocument';
import PatientSurveyBanner from './PatientSurveyBanner';

// Context
import { NotifContext } from '@/context/NotifContext';
import { InfusionContext } from '@/context/InfusionContext';

// Utils
import { isInfusionRestricted } from '@/components/Infusion/infusionHelper';

const InfusionMainV2 = () => {
  const history = useHistory();
  const { showError, showSuccess } = useContext(NotifContext);
  const { infusion, setInfusion, steps, currentStep, onStepChange } =
    useContext(InfusionContext);

  const infusionCompleted = infusion.nursingProcess?.status === 'CLOSED';
  const infusionRestricted = infusionCompleted
    ? isInfusionRestricted(infusion)
    : false;
  const nursingProcess = infusion.nursingProcess || {};
  const infusionFormData = infusion.currentReferral || {};
  const { rxOrderFile } = infusionFormData;
  const [narrativeNotes, setNarrativeNotes] = useState('');

  const [dialogOption, setDialogOption] = useState({});
  const [showNarrativeDialog, setShowNarrativeDialog] = useState(false);
  const [showCancelInfusionDialog, setShowCancelInfusionDialog] =
    useState(false);
  const [showPatientDocDialog, setShowPatientDocDialog] = useState(false);
  const [selectedDocumentUrl, setSelectedDocumentUrl] = useState('');

  useEffect(() => {
    localStorage.setItem('narrativeNotes', JSON.stringify(narrativeNotes));
  }, [narrativeNotes]);

  useEffect(() => {
    if (nursingProcess?.notes && nursingProcess?.notes.length > 0) {
      setNarrativeNotes(nursingProcess.notes);
    }
    // reset prev infusion data
  }, [nursingProcess]);

  const REASONS = [
    { value: 'Abnormal Vital Signs' },
    { value: 'Damaged Medication' },
    { value: 'Home Medication Contraindication' },
    { value: 'No Meds Administered' },
    { value: 'Order Not Approved' },
    { value: 'Patient Changed Mind' },
    { value: 'Unable to Obtain IV Access' }
  ];

  const toggleNarrativeDialog = () => {
    setShowNarrativeDialog(!showNarrativeDialog);
  };

  const toggleCancelInfusionDialog = () => {
    setShowCancelInfusionDialog(!showCancelInfusionDialog);
  };

  const handleCancel = (dataItem) => {
    const requestObject = {
      // id: ID!
      nursingProcessId: nursingProcess.id,
      // agentId: ID!
      agentId: nursingProcess.startedBy,
      // reason: String!
      reason: dataItem.reasonCancelled.value,
      // notes: String
      notes: dataItem.cancelNotes ? dataItem.cancelNotes : ''
    };

    cancelTreatmentCall(requestObject);
  };

  const cancelTreatmentCall = async (requestObject) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: cancelTreatment,
        variables: { input: requestObject }
      });
      if (data.data?.cancelTreatment?.statusCode) {
        if (data.data.cancelTreatment.statusCode === '200') {
          toggleCancelInfusionDialog();
          localStorage.removeItem('narrativeNotes');

          setDialogOption({
            title: 'Infusion: Cancel Infusion',
            message: 'Infusion was cancelled',
            showDialog: true
          });
          history.push('/infusion-portal', { searchType: 'treatmentHistory' });
        } else {
          setDialogOption({
            title: 'Infusion: Cancel Infusion',
            message: 'Infusion can not be cancelled (bad statusCode returned)',
            showDialog: true
          });
        }
      } else {
        setDialogOption({
          title: 'Infusion: Cancel Infusion',
          message: 'Infusion can not be cancelled (no statusCode provided)',
          showDialog: true
        });
      }
    } catch (err) {
      console.error('cancelTreatmentCall err: ', err);
      setDialogOption({
        title: 'Infusion: Cancel Infusion',
        message: 'Error: cancelTreatmentCall',
        showDialog: true
      });
    }
  };

  const updateNotesCall = async () => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: updateNarrativeNotes,
        variables: {
          agentId: nursingProcess.startedBy,
          notes: narrativeNotes,
          nursingProcessId: nursingProcess.id
        }
      });

      if (data.data?.addUpdateNursingNotes?.statusCode) {
        if (data.data.addUpdateNursingNotes.statusCode === '200') {
          toggleNarrativeDialog();
          showSuccess('Narrative Notes updated successfully.');
          setInfusion({
            ...infusion,
            nursingProcess: {
              ...infusion.nursingProcess,
              notes: narrativeNotes
            }
          });
        } else {
          showError('Narrative Notes can not be updated now.');
        }
      } else {
        showError('Narrative Notes can not be updated now (no statusCode provided).');
      }
    } catch (err) {
      console.error('InfusionMainV2::updateNotesCall err: ', err);
      showError('Error: updateNotesCall failed');
    }
  };

  const toggleShowPatientDocDialog = () => {
    setShowPatientDocDialog(!showPatientDocDialog);
  };

  const onDocumentClick = async () => {
    const conf = { download: false };
    const s3ImageURL = await Storage.get(rxOrderFile, conf);
    setSelectedDocumentUrl(s3ImageURL);
    setShowPatientDocDialog(true);
  };

  const renderStep = () => {
    const generalProps = {
      infusionCompleted,
      infusionRestricted
    };

    if (currentStep === 0) {
      return <OrderReviewV2 {...generalProps} />;
    }
    if (currentStep === 1) {
      return <AssessmentV2 {...generalProps} />;
    }
    if (currentStep === 2) {
      return <PreTreatmentV2 {...generalProps} />;
    }
    if (currentStep === 3) {
      return <PrepV2 {...generalProps} />;
    }
    if (currentStep === 4) {
      return <AdminV2 {...generalProps} />;
    }
    if (currentStep === 5) {
      return <CloseTreatmentV2 {...generalProps} />;
    }
  };

  const SendToInfusionQueue = () => {
    history.push('/infusion-queue');
  };

  return (
    <>
      {dialogOption.showDialog && <MessageDialog dialogOption={dialogOption} />}
      <>
        <PatientSurveyBanner />
        <div className="row">
          <div className="col-9 mt-2 py-3">
            <Stepper
              value={currentStep}
              onChange={onStepChange}
              items={steps}
              successIcon="k-icon k-iconWhite k-i-check-circle"
              errorIcon="k-icon k-iconWhite k-i-close-outline"
            />
          </div>

          <div className="col-3 mt-2 " style={{ marginLeft: '-40px' }}>
            <div className="row">
              <div className="col-12 mt-06">
                <button
                  type="button"
                  className="k-button"
                  style={{ minWidth: 140 }}
                  onClick={SendToInfusionQueue}
                >
                  <i className="fa-solid fa-display-medical icon-blue">
                    &nbsp;&nbsp;
                  </i>
                  Infusion Queue
                </button>
                <button
                  type="button"
                  className="k-button  ml-3"
                  style={{ minWidth: 140 }}
                  onClick={toggleNarrativeDialog}
                >
                  <i className="fa-solid fa-notes-medical icon-blue">
                    &nbsp;&nbsp;
                  </i>
                  Narrative Notes
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-12 mt-06">
                {rxOrderFile && (
                  <>
                    <button
                      type="button"
                      className="k-button"
                      style={{ minWidth: 140 }}
                      onClick={onDocumentClick}
                    >
                      <i
                        style={{ marginLeft: '-17px' }}
                        className=" fa-solid fa-file-prescription icon-blue"
                      >
                        &nbsp;&nbsp;
                      </i>
                      RX Order File
                    </button>
                  </>
                )}
                {!infusionCompleted && rxOrderFile && (
                  <>
                    <button
                      type="button"
                      className="k-button ml-3"
                      style={{ minWidth: 140 }}
                      onClick={toggleCancelInfusionDialog}
                    >
                      <i className="fa-solid fa-xmark-large icon-blue">
                        &nbsp;&nbsp;
                      </i>
                      Cancel Infusion
                    </button>
                  </>
                )}
                {!infusionCompleted && !rxOrderFile && (
                  <>
                    <button
                      type="button"
                      className="k-button"
                      style={{ minWidth: 140 }}
                      onClick={toggleCancelInfusionDialog}
                    >
                      <i className="fa-solid fa-xmark-large icon-blue">
                        &nbsp;&nbsp;
                      </i>
                      Cancel Infusion
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 pt-3 ">{renderStep()}</div>
        </div>

        {showCancelInfusionDialog && (
          <Dialog
            title="Cancel Infusion"
            width={550}
            initialTop={50}
            height="auto"
            onClose={toggleCancelInfusionDialog}
          >
            <Form
              onSubmit={handleCancel}
              render={(formRenderProps) => (
                <form
                  onSubmit={formRenderProps.onSubmit}
                  className="k-form pl-3 pr-3 pt-1"
                >
                  <div className="row">
                    <div className="col-md-3 mt-2 pl-4">Reason:</div>
                    <div className="col-8">
                      <Field
                        data={REASONS}
                        component={DropDownList}
                        valueField="value"
                        textField="value"
                        style={{ width: '100%' }}
                        name="reasonCancelled"
                      />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col">
                      <Alert>Reminder: Please add Close Treatment Notes</Alert>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 mt-16">
                      <button
                        type="submit"
                        className="k-button k-primary mr-3 p-1"
                      >
                        Submit Cancellation
                      </button>
                      <button
                        onClick={toggleCancelInfusionDialog}
                        className="k-button pageButton"
                      >
                        Do Not Cancel
                      </button>
                    </div>
                  </div>
                </form>
              )}
            />
          </Dialog>
        )}

        <WindowDialog
          title="Narrative Notes"
          initialTop={50}
          width={950}
          height={550}
          showDialog={showNarrativeDialog}
          onClose={toggleNarrativeDialog}
        >
          <div className="row">
            <div className="col-md-11 mt-16">
              <TextArea
                style={{ width: '100%', overflow: 'auto', height: '350px' }}
                value={narrativeNotes}
                onChange={(e) => setNarrativeNotes(e.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mt-16">
              <button
                type="submit"
                onClick={updateNotesCall}
                className="k-button pageButton mr-3"
              >
                Update
              </button>
            </div>
          </div>
        </WindowDialog>
        {showPatientDocDialog && (
          <Dialog
            title="Patient Document"
            height={1100}
            width={850}
            initialTop={100}
            showDialog
            onClose={toggleShowPatientDocDialog}
          >
            <PatientDocument file={selectedDocumentUrl} />
          </Dialog>
        )}
      </>
    </>
  );
};

export default InfusionMainV2;
