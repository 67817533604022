import React, { useContext } from 'react';

// kendo
import { Button } from '@progress/kendo-react-buttons';

// gql
import { connectToGraphqlAPI } from '@/provider';
import {
  getPatientHeaderDetailsForSurvey,
  listPatientSurveys,
  getPatientBucket
} from '@/graphql/queries';
import {
  addPatientSurvey,
  addUpdateNotes,
  addUpdatePatientInfo
} from '@/graphql/mutations';

// context
import {
  UserContext,
  PatientContext,
  SurveyContext,
  LogContext,
  NotifContext
} from '@/context';

// utils
import {
  getSurveySettingsQOL,
  generateChartNotesQOL,
  generateSurveyNotes,
  calculateSurveyScores,
  generateEducationLinks,
  generateSkipSurveyNotes,
  generateSurveyOptOutNote
} from '@/components/SurveyEngine/helpers/helperQOL';
import surveyModelMapper, {
  getSurveyModel
} from '@/components/SurveyEngine/models/surveyModelMapper';
import buildHeaderModelProvider from '@/components/SurveyEngine/models/provider/patientHeaderModelProvider';
import { formatDateToAWSDateTime } from '@/common/DateHelper';
import {
  getEventDetailsForSurvey,
  shouldDisplayQOL
} from '../Infusion/infusionHelper';

const StartInfusionButton = (props) => {
  const { agent } = useContext(UserContext);
  const { showError, showSuccess } = useContext(NotifContext);
  const { setActiveSurvey, setSurveyHeader, setSurveyFollowup } =
    useContext(SurveyContext);
  const {
    selectedPatientInfo,
    setSelectedPatientInfo,
    definePatientPreferences
  } = useContext(PatientContext);
  const { logApiException } = useContext(LogContext);

  // API calls
  const listPatientSurveysCall = async (patientId) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: listPatientSurveys,
        variables: { patientId }
      });
      if (data.data?.getPatientBucket) {
        return data.data.getPatientBucket.surveys;
      }
      return null;
    } catch (err) {
      logApiException(err, {
        view: 'StartInfusionButton',
        endpoint: 'listPatientSurveys',
        patientId
      });
    }
  };

  // get header details (patient bucket + referral combo)
  const getDetailsCall = async () => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getPatientHeaderDetailsForSurvey,
        variables: {
          patientId: selectedPatientInfo.patientId
        }
      });
      if (data.data?.getPatientBucket) {
        return data.data.getPatientBucket;
      }
      return null;
    } catch (err) {
      logApiException(err, {
        view: 'StartInfusionButton',
        endpoint: 'getPatientHeaderDetailsForSurvey'
      });
    }
  };

  const uploadPatientNotes = async (notes) => {
    try {
      const requestObject = {
        patientId: selectedPatientInfo.patientId,
        agentId: agent.agentId,
        notes: [
          {
            date: formatDateToAWSDateTime(),
            note: notes,
            type: 'AQCCA',
            createdBy: agent.agentId,
            modifiedNote: false // send false to add new
          }
        ]
      };

      const data = await connectToGraphqlAPI({
        graphqlQuery: addUpdateNotes,
        variables: { input: requestObject }
      });

      if (data.data?.addUpdateNotes?.notes) {
        // do nothing :-)
      } else {
        showError('Failed to save new Patient Notes (type - AQCCA)');
      }
    } catch (err) {
      logApiException(err, {
        view: 'StartInfusionButton',
        endpoint: 'addUpdateNotes',
        patientId: selectedPatientInfo.patientId,
        agentId: agent.agentId
      });
    }
  };

  const uploadOptOutNotes = async (reason) => {
    const note = generateSurveyOptOutNote(agent.agentId, reason);
    try {
      const requestObject = {
        patientId: selectedPatientInfo.patientId,
        agentId: agent.agentId,
        notes: [
          {
            note,
            createdBy: agent.agentId,
            date: formatDateToAWSDateTime(),
            type: 'PATIENT_COMMUNICATIONS',
            modifiedNote: false // send false to add new
          }
        ]
      };

      const data = await connectToGraphqlAPI({
        graphqlQuery: addUpdateNotes,
        variables: { input: requestObject }
      });

      if (!data.data?.addUpdateNotes?.notes) {
        showError(
          'Failed to save new Patient Notes (type - PATIENT_COMMUNICATIONS)'
        );
      }
    } catch (err) {
      logApiException(err, {
        view: 'StartInfusionButton',
        endpoint: 'uploadOptOutNotes',
        patientId: selectedPatientInfo.patientId,
        agentId: agent.agentId,
        note
      });
    }
  };

  const uploadSurveyCall = async (surveyData) => {
    try {
      const { patientId } = selectedPatientInfo;
      const { surveyType, version } = surveyData.meta;
      const { description } = surveyModelMapper[surveyType];

      const patientSurvey = {
        survey: JSON.stringify(surveyData),
        typeOfSurvey: surveyType,
        description,
        version,
        collectedAt: formatDateToAWSDateTime()
      };

      const surveyList =
        selectedPatientInfo.surveys && selectedPatientInfo.surveys.length > 0
          ? selectedPatientInfo.surveys
          : [];
      const surveys = [...surveyList, patientSurvey];

      const data = await connectToGraphqlAPI({
        graphqlQuery: addPatientSurvey,
        variables: { agentId: agent.agentId, patientId, patientSurvey }
      });

      if (data.data?.addPatientSurvey) {
        setSelectedPatientInfo({ ...selectedPatientInfo, surveys });
      } else {
        showError(
          'Error in Patient Survey update action. Please, check logs for details'
        );
      }
    } catch (err) {
      logApiException(err, {
        view: 'StartInfusionButton',
        endpoint: 'addPatientSurvey',
        patientId: selectedPatientInfo?.patientId,
        agentId: agent.agentId,
        surveyType: surveyData?.meta?.surveyType,
        version: surveyData?.meta?.version
      });
    }
  };

  const optSurveysOutCall = async (reason) => {
    try {
      let pInfo = { ...selectedPatientInfo };
      if (!pInfo.patientProfile) {
        const data = await connectToGraphqlAPI({
          graphqlQuery: getPatientBucket,
          variables: {
            patientId: pInfo.patientId
          }
        });
        if (data.data?.getPatientBucket) {
          pInfo = data.data.getPatientBucket;
        }
      }

      const input = {
        agentId: agent.agentId,
        patientId: pInfo.patientId,
        patientInfo: {
          ...pInfo.patientProfile.patientInfo,
          preference: {
            surveys: {
              preferred: false,
              updatedAt: formatDateToAWSDateTime(),
              reasons: reason,
              updatedBy: agent.agentId
            }
          }
        }
      };

      const data = await connectToGraphqlAPI({
        graphqlQuery: addUpdatePatientInfo,
        variables: { input }
      });

      if (data?.data?.addUpdatePatientInfo?.patientId) {
        showSuccess('Patient has been opted-out from any following surveys');
      } else {
        showError(
          'Error in Patient Survey opt-out action. Please, check logs for details'
        );
      }
    } catch (err) {
      logApiException(err, {
        view: 'StartInfusionButton',
        endpoint: 'addUpdatePatientInfo',
        patientId: selectedPatientInfo?.patientId,
        agentId: agent.agentId
      });
    }
  };

  const displaySurvey = async (surveyType) => {
    // get referral details as a first thing
    const headerData = await getDetailsCall();
    const referralData = headerData.referral?.drugReferrals?.find(
      (referral) => referral.referralId === selectedPatientInfo.referralId
    );

    // get model by type
    const model = getSurveyModel(surveyType);
    const patientHeaderDetails = buildHeaderModelProvider({
      ...headerData,
      referralData,
      surveyType
    });

    const surveyStarted = new Date();

    setSurveyHeader(patientHeaderDetails);
    setActiveSurvey({
      model,
      onFinish: async (surveyData) => {
        const jsonResults = JSON.parse(surveyData);

        // adding meta info for any future references
        jsonResults.meta = {
          surveyType,
          version: model.version,
          surveyStarted,
          surveyFinished: new Date(),
          eventDetails: getEventDetailsForSurvey(props.eventDetails)
        };

        // separate the logic for survey skipping
        if (jsonResults['survey-start-option'] === 0) {
          jsonResults.score = null;
          jsonResults.meta.isSurveySkipped = true;

          // save a note with reason for skipping survey at patient notes
          const notes = generateSkipSurveyNotes({
            ...props.eventDetails,
            reasonCode: jsonResults['survey-skipping-reason']
          });
          await uploadPatientNotes(notes);

          // add the notes to survey meta data
          jsonResults.notes = notes;

          // upload results
          await uploadSurveyCall(jsonResults);

          // Close the survey modal
          setActiveSurvey(null);
        }
        // separate the logic for survey opt-out
        else if (jsonResults['survey-start-option'] === 2) {
          // opt patient out on patient level
          const reason = jsonResults['survey-opt-out-reason'];
          await optSurveysOutCall(reason);

          // generate patient note with a reason
          await uploadOptOutNotes(reason);

          // Close the survey modal
          setActiveSurvey(null);
        } else {
          // ======================
          // Calculate AQCCA & PHQ9 scores
          // ====================
          const score = calculateSurveyScores(jsonResults);
          jsonResults.score = score;

          // ======================
          // Generate chart notes based on results
          // ====================
          const notes = generateChartNotesQOL(jsonResults);
          await uploadPatientNotes(notes);

          // add the notes to survey meta data
          jsonResults.notes = notes;

          // ======================
          // Upload survey results
          // ====================
          await uploadSurveyCall(jsonResults);

          // Close the survey modal
          setActiveSurvey(null);

          // followup modal - results preview
          const workItems = generateSurveyNotes(notes);
          const education = generateEducationLinks(jsonResults);
          setSurveyFollowup({
            education,
            workItems,
            score: jsonResults.score || [],
            version: model.version
          });
        }

        // start the infusion
        props.superInfusionCallback();
      }
    });
  };

  return (
    <Button
      type='button'
      className='btn blue'
      onClick={async (event) => {
        event.currentTarget.disabled = true;
        const isQOL = shouldDisplayQOL(selectedPatientInfo.locationId);
        const { isSurveyOptedOut } = await definePatientPreferences(
          selectedPatientInfo
        );

        if (!isQOL || isSurveyOptedOut) return props.superInfusionCallback();

        // QOL survey check in action
        const surveys = await listPatientSurveysCall(
          selectedPatientInfo.patientId
        );
        const { forceDisplay, surveyType } = getSurveySettingsQOL(surveys);

        if (forceDisplay) {
          // display QOL survey
          await displaySurvey(surveyType);
        } else {
          // start the infusion
          props.superInfusionCallback();
        }
      }}
    >
      Start Infusion
    </Button>
  );
};

export default StartInfusionButton;
