import React, { useContext, useMemo, useState } from 'react';
import { Storage } from 'aws-amplify';
// kendo
import { GridColumn as Column } from '@progress/kendo-react-grid/dist/npm/GridColumn';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Grid } from '@progress/kendo-react-grid';
// components
import PatientDocument from '../Patient/PatientDocument';
import AwesomeLink from '@/components/common-components/AwesomeLink';
// context
import { PatientContext } from '@/context/PatientContext';
//util
import { formatPrescriberAddressHistory } from '../Prescriber/prescriberHelper';
import { yesNoBoolean } from '@/common/Converters';
import { Constants } from '@/constants';
import * as moment from 'moment';

const OrderHistoryOrderDetails = ({ selectedOrderInfo, onClose }) => {
  const { selectedPatientInfo } = useContext(PatientContext);
  const prescriber = selectedPatientInfo?.hcpProfile.items.find(
    (hp) =>
      hp.prescriberId === selectedOrderInfo?.archivedReferral?.prescriberId
  );
  const presName = `${prescriber?.prescriber?.prescriberFirstName} ${prescriber?.prescriber?.prescriberLastName}`;
  const originalReceivedDate =
    moment(selectedOrderInfo?.archivedReferral?.originalReceivedDate).format(
      Constants.DATE.DEFAULT_AWS
    ) || '';
  const prescriberOfficeAddress = useMemo(() => {
    const officeAddress = (prescriber?.prescriber?.officeAddresses || []).find(
      (address) =>
        selectedOrderInfo?.archivedReferral?.prescriberOfficeAddressId ===
        address.id
    );
    return officeAddress ? formatPrescriberAddressHistory(officeAddress) : null;
  }, [prescriber]);

  const [showPatientDocDialog, setShowPatientDocDialog] = useState(false);
  const [showReferralOrderDetailsDialog, setShowReferralOrderDetailsDialog] =
    useState(true);
  const [selectedDocumentUrl, setSelectedDocumentUrl] = useState('');

  const file = selectedOrderInfo?.archivedReferral?.rxOrderFile;
  const isDiscontinued = selectedOrderInfo?.archivedReferral?.discontinued;
  const orderType = isDiscontinued
    ? 'Discontinued Referral Order Details'
    : 'Archived Referral Order Details';

  const onDocumentClick = async () => {
    const conf = { download: false };
    const s3ImageURL = await Storage.get(/* docPath ? docPath : */ file, conf);
    setSelectedDocumentUrl(s3ImageURL);
    setShowPatientDocDialog(true);
  };

  const toggleShowPatientDocDialog = () => {
    setShowPatientDocDialog(!showPatientDocDialog);
  };

  const toggleShowReferralOrderDetailsDialog = () => {
    setShowReferralOrderDetailsDialog(false);
    onClose();
  };

  if (!showReferralOrderDetailsDialog) return null;

  return (
    <Dialog
      title={`${orderType} - ${selectedOrderInfo.orderName}`}
      width={1000}
      initialHeight={600}
      onClose={toggleShowReferralOrderDetailsDialog}
    >
      <article>
        <div className='row'>
          <div className='col-md-2'>ORDER NAME:</div>
          <div className='col-md-3'>
            <strong>{selectedOrderInfo.orderName}</strong>
          </div>
          <div className='col-md-2'>ORDER TYPE:</div>
          <div className='col-md-3'>
            <strong>
              {selectedOrderInfo.archivedReferral?.referralOrder?.orderType}
            </strong>
          </div>
        </div>

        <div className='row mt-14'>
          <div className='col-md-2'>PRESCRIBING HCP:</div>
          <div className='col-md-3'>
            <strong>
              {presName}
              (#{selectedOrderInfo.archivedReferral?.prescriberId})
            </strong>
          </div>
          <div className='col-md-2'>DRUG TYPE:</div>
          <div className='col-md-3'>
            <strong>{selectedOrderInfo.archivedReferral?.drugType}</strong>
          </div>
        </div>
        <div className='row mt-14'>
          <div className='col-md-2'>PRESCRIBING HCP OFFICE ADDRESS:</div>
          <div className='col-md-3'>
            <strong>{prescriberOfficeAddress}</strong>
          </div>
          <div className='col-md-2'>ORDER RECEIVED DATE:</div>
          <div className='col-md-3'>
            <strong>{originalReceivedDate}</strong>
          </div>
        </div>
        <div className='row mt-14'>
          <div className='col-md-2'>ORDER DATE:</div>
          <div className='col-md-3'>
            <strong>{selectedOrderInfo.orderDate}</strong>
          </div>
          <div className='col-md-2'>PRIMARY DX:</div>
          <div className='col-md-5'>
            <strong>
              {
                selectedOrderInfo.archivedReferral?.referralOrder?.primaryDX
                  ?.primaryDiagnosis
              }
              {selectedOrderInfo.archivedReferral?.referralOrder?.otherDX
                ?.length > 0 && (
                <>
                  {selectedOrderInfo.archivedReferral?.referralOrder?.otherDX?.map(
                    (dx, i) => (
                      <div key={i}>{dx.primaryDiagnosis}</div>
                    )
                  )}
                </>
              )}
            </strong>
          </div>
        </div>
        <div className='row mt-14'>
          <div className='col-md-2'>ORDER EXP DATE:</div>
          <div className='col-md-3'>
            <strong>
              {selectedOrderInfo.archivedReferral?.referralOrder?.orderExpires}
            </strong>
          </div>
          {file && (
            <>
              <div className='col-md-2'>RX Order:</div>
              <div className='col-md-3'>
                <AwesomeLink
                  onClick={() => onDocumentClick()}
                  icon='magnifying-glass'
                  label='View Details'
                />
              </div>
            </>
          )}
        </div>

        <hr />

        {/* ADMINISTRATION */}

        <div className='row'>
          <div className='col-md-2 mt-08'>ADMINISTRATION:</div>
          <div className='col-md-10 mt-08'>
            <Grid
              data={selectedOrderInfo.itemAdministrations}
              className='a-grid'
            >
              <Column field='drugName' title='PRODUCT NAME' width='160px' />
              <Column field='route' title='ROUTE' width='80px' />
              <Column field='administer' title='ADMINISTER' width='200px' />
              <Column field='maxOfTreatments' title='MAX #' width='80px' />
              <Column field='approvedDosage' title='DOSE' width='80px' />
              <Column field='unitOfMeas' title='UOM' width='60px' />
              <Column field='calcDosage' title='CALC DOSE' width='140px' />
            </Grid>
          </div>
        </div>

        <hr />

        {/* PRE-MEDICATIONS */}

        <div className='row'>
          <div className='col-md-2 mt-08'>PRE-MEDICATION:</div>
          <div className='col-md-10 mt-08'>
            <Grid data={selectedOrderInfo.itemPreMeds} className='a-grid'>
              <Column field='drugName' title='PRODUCT NAME' width='160px' />
              <Column field='route' title='ROUTE' width='80px' />
              <Column field='administer' title='ADMINISTER' width='200px' />
              <Column field='maxOfTreatments' title='MAX #' width='80px' />
              <Column field='approvedDosage' title='DOSE' width='80px' />
              <Column field='unitOfMeas' title='UOM' width='60px' />
            </Grid>
          </div>
        </div>

        <hr />

        <div className='row'>
          <div className='col-md-2 mt-16'>ORDER NOTES:</div>
          <div className='col-10'>
            <strong>
              {selectedOrderInfo.archivedReferral?.referralOrder?.notes}
            </strong>
          </div>
        </div>

        <hr />

        <div className='row mt-12'>
          <div className='col-md-2 mt-16'>PROGRESS NOTES:</div>
        </div>

        <hr />

        <div className='row'>
          <div className='col-md-2 mt-08'>
            Continuation of Care:
            <br />
            <strong>
              {yesNoBoolean(
                selectedOrderInfo.archivedReferral?.patientHasStartedTherapy
              )}
            </strong>
          </div>
          <div className='col-md-2'>
            # Treatments
            <br />
            <strong>
              {selectedOrderInfo.archivedReferral?.noOfTreatments}
            </strong>
          </div>
          <div className='col-md-3'>
            First Treatment Date
            <br />
            <strong>
              {selectedOrderInfo.archivedReferral?.firstTreatmentDate}
            </strong>
          </div>
          <div className='col-md-3'>
            Last Treatment Date
            <br />
            <strong>
              {selectedOrderInfo.archivedReferral?.lastTreatmentDate}
            </strong>
          </div>
        </div>

        <div className='row mt-22'>
          <div className='col-md-2'>INVENTORY SOURCE:</div>
          <div className='col-md-3'>
            <strong>
              {selectedOrderInfo.archivedReferral?.inventorySource}
            </strong>
          </div>
          <div className='col-md-3' />
          <div className='col-md-3' />
        </div>

        <div className='row mt-08'>
          <div className='col-md-2 mt-08'>SCHEDULING:</div>
          <div className='col-md-6'>
            <strong>{selectedOrderInfo.archivedReferral?.scheduling}</strong>
          </div>
        </div>

        <div className='row mt-08'>
          <div className='col-md-2 mt-16'>PREFERRED SPECIALTY PHARMACY:</div>
          <div className='col-md-3 mt-06'>
            <strong>
              {selectedOrderInfo.archivedReferral?.specialPharmName}
            </strong>
          </div>
          <div className='col-md-3 mt-06'>
            <strong>
              {selectedOrderInfo.archivedReferral?.specialPharmPhoneNumber}
            </strong>
          </div>
        </div>

        <hr />

        <div className='row'>
          <div className='col-md-3 mt-08'>
            REFERRAL APPROVED/COMPLETE:
            <br />
            <strong>
              {yesNoBoolean(
                selectedOrderInfo.archivedReferral?.referralApproved
              )}
            </strong>
          </div>

          <div className='col-md-1 mt-06' />

          <div className='col-md-2 mt-08'>
            REFERRAL ARCHIVED:
            <br />
            <strong>YES</strong>
          </div>

          <div className='col-md-4 mt-06'>
            REASON ARCHIVED:
            <br />
            <strong>
              {selectedOrderInfo.archivedReferral?.reasonForArchiving}
            </strong>
          </div>
        </div>

        <hr />
      </article>
      {showPatientDocDialog && (
        <Dialog
          title='Patient Document'
          height={1100}
          width={850}
          initialTop={100}
          showDialog
          onClose={toggleShowPatientDocDialog}
        >
          <PatientDocument file={selectedDocumentUrl} />
        </Dialog>
      )}
    </Dialog>
  );
};

export default OrderHistoryOrderDetails;
