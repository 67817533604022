import React, { useState, useContext, useEffect } from "react";
import { Grid } from "@progress/kendo-react-grid";
import { GridColumn as Column } from "@progress/kendo-react-grid/dist/npm/GridColumn";
import { Form, Field } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { MessageDialog } from "@/components/common-components/MessageDialog";
import ScanBarcode from "@/components/common-components/ScanBarcode";
import {
  InputField,
  DatePickerField,
  validateInput,
  DropDownListField,
  validateFloat
} from "@/common/Validation";
import {
  formatDateToDefault,
  formatDateToAWS
} from "@/common/DateHelper";
import { UserContext } from "@/context/UserContext";
import { connectToGraphqlAPI } from "@/provider";
import { addProductsToInventory } from "@/graphql/mutations";
import {
  listProducts,
  getLatestPriceOfDrugByNDC
} from "@/graphql/queries";
import { Constants } from "@/constants";

import vendorData from "../vendors";

const ReceiveProduct = () => {
  const { user } = useContext(UserContext);
  const [products, setProducts] = useState([]);
  const [productList, setProductList] = useState([]);
  const [dialogOption, setDialogOption] = useState({});

  // form fields
  const [receivedDate, setReceivedDate] = useState(new Date());
  const [orderQty, setOrderQty] = useState("");
  const [selDrug, setSelDrug] = useState();
  const [selVendor, setSelVendor] = useState("");
  const [lastDrugPrice, setLastDrugPrice] = useState("");
  const [lotNumber, setLotNumber] = useState("");
  const [expDate, setExpDate] = useState("");

  let FormVals = {
    recDate: receivedDate,
    qty: orderQty,
    drug: selDrug,
    vendor: selVendor,
    lot: lotNumber,
    expDate: expDate,
    price: lastDrugPrice
  };
  const [formValues, setFormValues] = useState(FormVals);
  const loadFormValues = () => {
    setFormValues(FormVals);
  };

  const resetFormVals = () => {
    // reset state vars
    setReceivedDate(new Date());
    setOrderQty("");
    setSelDrug();
    setSelVendor("");
    setLotNumber("");
    setExpDate("");
    setLastDrugPrice("");

    // since states are "scheduled" force reset on structure
    FormVals.recDate = new Date();
    FormVals.qty = "";
    FormVals.drug = null;
    FormVals.vendor = "";
    FormVals.lot = "";
    FormVals.expDate = "";
    FormVals.price = "";
    loadFormValues();
  };
  const onCellRemove = cellIndex => {
    // remove element by its index
    const updatedList = [...productList];
    updatedList.splice(cellIndex, 1);

    setProductList(updatedList);
  };

  const customCell = props => {
    if (props.field === "action") {
      return (
        <td>
          <button
            type="button"
            primary="true"
            className="k-button mr-1"
            onClick={() => onCellRemove(props.dataIndex)}
          >
            Delete
          </button>
        </td>
      );
    }
  };

  ///Validation
  const receiveProductForm = {
    drug: {
      value: {},
      inputValidator: value => {
        return validateInput({
          referralId: { ...receiveProductForm.drug, value }
        });
      },
      validations: [
        {
          type: "required",
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    },
    qty: {
      value: "",
      inputValidator: value => {
        return validateInput({
          referralId: { ...receiveProductForm.qty, value }
        });
      },
      validations: [
        {
          type: "required",
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    },
    vender: {
      value: "",
      inputValidator: value => {
        return validateInput({
          referralId: { ...receiveProductForm.vender, value }
        });
      },
      validations: [
        {
          type: "required",
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    },
    price: {
      value: "",
      inputValidator: value => {
        return validateInput({
          referralId: { ...receiveProductForm.price, value }
        });
      },
      validations: [
        {
          type: "required",
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    },
    lot: {
      value: lotNumber,
      inputValidator: value => {
        return validateInput({
          startDate: { ...receiveProductForm.lot, value }
        });
      },
      validations: [
        {
          type: "required",
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
        /* {
          type: "dateRange",
          message: Constants.ErrorMessage.startDate_REQUIRED,
          minDate: moment().subtract(120, "year"),
          maxDate: moment().subtract(5, "year")
        } */
      ]
    },
    recDate: {
      //value: "",
      value: expDate,
      inputValidator: value => {
        return validateInput({
          startTime: { ...receiveProductForm.recDate, value }
        });
      },
      validations: [
        {
          type: "required",
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    },
    expDate: {
      //value: "",
      value: new Date(),
      inputValidator: value => {
        return validateInput({
          endTime: { ...receiveProductForm.expDate, value }
        });
      },
      validations: [
        {
          type: "required",
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    }
  };

  const validateEmptyFloat = value => {
    if (!value || value === "") return "";
    return validateFloat(value) ? "" : Constants.ErrorMessage.Numeric_Required;
  };

  const dateCell = props => {
    return <td>{formatDateToDefault(props.dataItem[props.field])}</td>;
  };

  const handleAddProductClick = formData => {
    const clonedList = [...productList];
    //const { drug } = formData.values; // use this version when using Form:onSubmitClick event
    const { drug } = formData;
    clonedList.push({
      vial: drug.text,
      lotNumber: formData.lot ? formData.lot : lotNumber,
      ndcNumber: drug.productId,
      quantity: formData.qty,
      receivedTime: formData.recDate,
      strength: drug.strength,
      unitOfMeas: drug.unitOfMeas,
      expirationDate: formData.expDate ? formData.expDate : expDate,
      price: formData.price,
      productName: drug.productName,
      vendor: formData.vendor
    });

    setProductList(clonedList);
    resetForm();
  };

  const handleAddProducts = async () => {
    try {
      const products = productList.map(product => {
        return {
          freeDrug: false,
          lotNumber: product.lotNumber,
          ndcNumber: product.ndcNumber,
          quantity: product.quantity,
          receivedTime: new Date(product.receivedTime).toISOString(),
          strength: product.strength,
          unitOfMeas: product.unitOfMeas,
          expirationDate: formatDateToAWS(product.expirationDate),
          price: product.price,
          productName: product.productName,
          vendor: product.vendor
        };
      });

      await connectToGraphqlAPI({
        graphqlQuery: addProductsToInventory,
        variables: {
          agentId: user.username,
          products
        }
      }).then(() => {
        setDialogOption({
          title: "Inventory Status",
          message: "Inventory was succesfully updated.",
          showDialog: true
        });

        setProductList([]);
      });
    } catch (err) {
      if (err && err.errors && err.errors.length > 0) {
        //
      }
    }
  };

  const listProductsCall = async () => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: listProducts
      });

      if (
        data &&
        data.data &&
        data.data.listProducts &&
        data.data.listProducts.items
      ) {
        // let list = data.data.listProducts.items
        //   .map(item => ({
        //     ...item,
        //     text: `${item.productName} ${item.strength} ${item.unitOfMeas} (${item.route})`,
        //     value: item.productId,
        //     productOnSelector: `${item.productName} (${item.strength} ${item.unitOfMeas}) - ${item.packageType}`
        //   }));
        //   // will sort later after getting all the records
        //   //.sort((a, b) => (a.productName > b.productName ? 1 : -1));
        // console.log("ILIA: GLIST", list);

        // if MORE results, then must fetch and add to list
        ////////////////////////
        let list = data.data.listProducts.items;
        let sNextToken = data.data.listProducts.nextToken
          ? data.data.listProducts.nextToken
          : null;
        while (sNextToken != null) {
          try {
            const data = await connectToGraphqlAPI({
              graphqlQuery: listProducts,
              variables: { nextToken: sNextToken }
            });

            if (
              data &&
              data.data &&
              data.data.listProducts &&
              data.data.listProducts.items
            ) {
              sNextToken = data.data.listProducts.nextToken
                ? data.data.listProducts.nextToken
                : null;
              list = list.concat(data.data.listProducts.items);
            }
          } catch (err) {
            console.log("ILIA listProductsCall err", err);
            sNextToken = null; // set to stop iterating
          }
        }
        ///////////////////
        // sort the collection for display
        list = list
          .map(item => ({
            ...item,
            text: `${item.productName} ${item.strength} ${item.unitOfMeas} (${item.route})`,
            value: item.productId,
            productOnSelector: `${item.productName} (${item.strength} ${item.unitOfMeas}) - ${item.packageType}, ${item.packageVolume} ${item.packageVolumeUOM}`
          }))
          .sort((a, b) => (a.productName > b.productName ? 1 : -1));
        setProducts(list);
      }
    } catch (err) {
      console.log("ILIA listProductsCall err", err);
    }
  };

  const resetForm = () => {
    document.getElementById("resetButton").click();
  };

  // MAIN INITIATOR
  useEffect(() => {
    listProductsCall();
  }, []);

  // useEffect(() => {
  //   loadFormValues();
  // }, []);

  // useEffect(() => {
  // 	initialForm()
  // },[])

  // const initialForm = () => {
  // 	let initialObject = {}
  // 	Object.keys(receiveProductForm).forEach(key => {
  // 			initialObject[key] = receiveProductForm[key].value
  // 	})
  // 	//console.log('initialObject', initialObject)
  // 	return initialObject
  // }

  const handleProductOnChange = async item => {
    let sPrice = "";
    // // retrieve lastentered price
    try {
      // const retData = await connectToGraphqlAPI({
      //   graphqlQuery: getLatestPriceOfDrugByNDC,
      //   variables: { ndc: item.value.productId }
      // });
      // if (
      //   retData &&
      //   retData.data &&
      //   retData.data.getLatestPriceOfDrugByNDC &&
      //   retData.data.getLatestPriceOfDrugByNDC.latestPrice
      // ) {
      //   let fNum = parseFloat(
      //     retData.data.getLatestPriceOfDrugByNDC.latestPrice
      //   );
      //   sPrice = fNum.toFixed(2);
      // }

        let fNum = parseFloat(item.value.price);
        sPrice = fNum.toFixed(2);

      // store for later use
      setLastDrugPrice(sPrice);
      setSelDrug(item.value);

      // store for IMMEDIATE use since state values are "scheduled" for update
      FormVals.price = sPrice;
      FormVals.drug = item.value;
      loadFormValues();
    } catch (err) {
      console.log("getLatestPriceOfDrugByNDC err", err);
    }
  };
  return (
    <div className="row">
      <div className="col-12 mt-2 mr-3 ml-3">
        {dialogOption && dialogOption.showDialog && (
          <MessageDialog dialogOption={dialogOption} />
        )}
        <div className="row">
          <div className="col-md-12 mb-4 pageTitle">Orders To Receive</div>
        </div>
        <Form
          initialValues={formValues}
          key={JSON.stringify(formValues)}
          onSubmit={handleAddProductClick}
          ignoreModified={true}
          render={formRenderProps => (
            <form
              id="formReceiveProduct"
              onSubmit={formRenderProps.onSubmit}
              className={"k-form pl-3 pr-3 pt-1"}
            >
              <div style={{ textAlign: "center" }}>
                <ScanBarcode
                  callback={res => {
                    formRenderProps.onChange("lot", {
                      value: res.lot
                    });

                    formRenderProps.onChange("expDate", {
                      value: res.expDate
                    });
                  }}
                />
                <hr />
              </div>
              <div className="row mt-02">
                <div className="col-md-2">
                  <Field
                    name="recDate"
                    label="Date Rec'd"
                    component={DatePickerField}
                    validator={receiveProductForm.recDate.inputValidator}
                    onChange={e => setReceivedDate(e.value)}
                  />
                </div>
                <div className="col-md-2">
                  <Field
                    component={DropDownListField}
                    label="VENDOR #"
                    data={vendorData}
                    name="vendor"
                    validator={receiveProductForm.drug.inputValidator}
                    onChange={e => {
                      setSelVendor(e.value);
                      FormVals.vendor = e.value;
                    }}
                    // 10.19.21 wat NOTE: we added the FormsVals.vendor update because the setSelVendor() was not updating the state until some other event
                    // would cause the scheduled update to occur. this meant that the state was not current. By updating the FormsVals structure directly the
                    // values flow through correctly and update the screen as desired. notice that this manual process is also accomplished for the other DropDownList box "drug"
                    // and the programming is accomplished within the handleProductOnChange() method.
                  />
                </div>
              </div>
              <div className="row mt-02">
                <div className="col-md-3">
                  <Field
                    component={DropDownListField}
                    label="Product"
                    data={products}
                    name="drug"
                    textField={"productOnSelector"}
                    valueField={"productId"}
                    validator={receiveProductForm.drug.inputValidator}
                    onChange={handleProductOnChange}
                  />
                </div>
                <div className="col-md-2" style={{ marginLeft: "25px" }}>
                  <Field
                    name="lot"
                    label="LOT #"
                    component={InputField}
                    validator={receiveProductForm.lot.inputValidator}
                    onChange={e => setLotNumber(e.value)}
                  />
                </div>
                <div className="col-md-2">
                  <Field
                    name="expDate"
                    label="Exp. Date"
                    component={DatePickerField}
                    defaultValue={expDate}
                    validator={receiveProductForm.expDate.inputValidator}
                    onChange={e => setExpDate(e.value)}
                  />
                </div>
              </div>
              <div className="row mt-02">
                <div className="col-md-1">
                  <Field
                    name="qty"
                    label="QTY"
                    component={InputField}
                    validator={receiveProductForm.qty.inputValidator}
                    onChange={e => {
                      setOrderQty(e.value);
                      FormVals.qty = e.value;
                    }}
                  />
                </div>
                <div className="col-md-2">
                  <Field
                    name="price"
                    label="PRICE PER UNIT"
                    component={InputField}
                    validator={validateEmptyFloat}
                    onChange={e => setLastDrugPrice(e.value)}
                    disabled={true}

                  />
                </div>
              </div>
              <div className="row mt-02"></div>
              <div className="row mt-12">
                <div className="col-md-1 mt-3 py-1">
                  <Button className="blue" type="submit" disabled={!products}>
                    ADD PRODUCT
                  </Button>
                  <div className="col-md-1 mt-12">
                    <button
                      className="k-button k-button pageButton"
                      onClick={() => {
                        resetFormVals();
                        formRenderProps.onFormReset();
                      }}
                      id="resetButton"
                      style={{ display: "none" }}
                    >
                      Reset Form
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )}
        />
        {productList && productList.length > 0 && (
          <>
            <div className="row p-3 mt-3">
              <Grid data={productList}>
                <Column
                  field="receivedTime"
                  title="DATE REC'D"
                  cell={dateCell}
                />
                <Column field="quantity" title="QTY" />
                <Column field="vial" title="VIAL" />
                <Column field="lotNumber" title="LOT" />
                <Column field="vendor" title="VENDOR" />
                <Column field="expirationDate" title="EXP" cell={dateCell} />
                <Column field="price" title="PRICE PER UNIT " />
                <Column field="action" cell={customCell} />
              </Grid>
              <div className="col-2">
                <button
                  type="button"
                  primary="true"
                  onClick={handleAddProducts}
                  className="k-button k-button pageButton mr-1 mt-3"
                >
                  Add to Inventory
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ReceiveProduct;
