import React, { useContext } from 'react';
// components
import AwesomeLabel from '@/components/common-components/AwesomeLabel';
import { ColumnMenu } from '@/components/common-components/columnMenu';
//context
import { ReferralContext } from '@/context';
//kendo
import { Grid } from '@progress/kendo-react-grid';
import { GridColumn as Column } from '@progress/kendo-react-grid/dist/npm/GridColumn';
import { Card, CardBody, CardHeader } from '@progress/kendo-react-layout';

const ReferralList = () => {
  const { combinedReferralList } = useContext(ReferralContext);
  const columns = [
    {
      field: 'drugName',
      title: 'DRUG NAME'
    },
    {
      field: 'referralId',
      title: 'REFERRAL ID'
    }
  ];
  return (
    <Card className='mb-3 mt-3'>
      <CardHeader>
        <div className='row'>
          <div className='col-12 '>
            <AwesomeLabel
              label={' '}
              size='xl'
              icon={'memo icon-blue'}
              title={'CURRENT REFERRALS'}
            />
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <Grid className='queue_grid' data={combinedReferralList} resizable>
          {columns.map((column, index) => {
            return (
              <Column
                field={column.field}
                title={column.title}
                key={index}
                width={column.minWidth}
                columnMenu={ColumnMenu}
                cell={column.cell || null}
              />
            );
          })}
        </Grid>
      </CardBody>
    </Card>
  );
};

export default ReferralList;
