import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';

// common
import { patientProfileTabs } from '@/common/NavHelper';

// partials
import OverViewPage from './overview/OverviewPage';
import PatientInfo from './PatientInfo';
import PatientInsurance from './PatientInsurance';
import PatientBilling from './PatientBilling';
import PatientUpload from './PatientUpload';
import TreatmentHistory from './treatment-history/TreatmentHistory';

const Patient = (props) => {
  const history = useHistory();

  const { selectedPatientInfo } = props;

  const [selectedTab, setSelectedTab] = useState(0);

  const handleSelect = (e) => {
    setSelectedTab(e.selected);
  };

  useEffect(() => {
    if (history?.location?.state?.redirectPatienTreatHistory) {
      setSelectedTab(patientProfileTabs.TREATMENT_HISTORY);
    }
    if (history?.location?.state?.redirectPatientBilling) {
      setSelectedTab(patientProfileTabs.BILLING);
    }
    if (history?.location?.state?.redirectToUpload) {
      setSelectedTab(patientProfileTabs.UPLOAD);
    }
    if (history?.location?.state?.redirect2PatientInfo) {
      setSelectedTab(patientProfileTabs.PATIENT_PROFILE);
    }
  }, [history]);

  // the callback from child to parent
  const sendDataToParent = (payload) => {
    props.sendDataToParent(payload); // -||-
  };

  return (
    <div className="patient-portal bottom-tabstrip-container">
      <TabStrip selected={selectedTab} onSelect={handleSelect}>
        <TabStripTab contentClassName="navBar2" title="Patient Overview">
          <div className="tabText">
            <OverViewPage />
          </div>
        </TabStripTab>
        <TabStripTab title="Patient">
          <PatientInfo />
        </TabStripTab>
        <TabStripTab title="Insurance">
          <PatientInsurance
            selectedPatientInfo={selectedPatientInfo}
            sendDataToParent={sendDataToParent}
          />
        </TabStripTab>
        <TabStripTab title="Upload">
          <PatientUpload
            selectedPatientInfo={selectedPatientInfo}
            sendDataToParent={sendDataToParent}
          />
        </TabStripTab>
        <TabStripTab title="Billing">
          <PatientBilling
            selectedPatientInfo={selectedPatientInfo}
            sendDataToParent={sendDataToParent}
          />
        </TabStripTab>
        <TabStripTab title="Treatment History">
          <TreatmentHistory
            selectedPatientInfo={selectedPatientInfo}
            sendDataToParent={sendDataToParent}
          />
        </TabStripTab>
      </TabStrip>
    </div>
  );
};

export default Patient;
