import * as React from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import AwesomeLabel from '../AwesomeLabel';

// styles
import styles from './ConfirmationModal.module.scss';

const ConfirmationModal = ({
  isVisible,
  onClose,
  onConfirm,
  onReject,
  dialogTitle = 'Please confirm',
  dialogDescription = 'Are you sure you want to continue?',
  dialogBody,
  yesLabel = 'Yes',
  yesIcon = 'check',
  noLabel = 'No',
  noIcon = 'ban',
  customClassName,
  customBodyClassName,
  disabled = false
}) => {
  if (!isVisible) return null;

  return (
    <Dialog title={dialogTitle} onClose={onClose}>
      {!dialogBody && (
        <p className={customClassName || styles.description}>
          {dialogDescription}
        </p>
      )}
      {dialogBody && <div className={customBodyClassName}>{dialogBody}</div>}
      <div className={styles.actions}>
        <button
          className={styles.actionButton}
          onClick={onReject}
          disabled={disabled}
        >
          <AwesomeLabel icon={noIcon} label={noLabel} />
        </button>
        <button
          className={styles.actionButton}
          onClick={onConfirm}
          disabled={disabled}
        >
          <AwesomeLabel icon={yesIcon} label={yesLabel} />
        </button>
      </div>
    </Dialog>
  );
};

export { ConfirmationModal };
