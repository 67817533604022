import React, { useState } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { PropTypes } from 'prop-types';
import { PatientBillingListData } from './patient-billing/PatientBillingListData';
import { PatientBillingInfoData } from './patient-billing/PatientBillingInfoData';
import WindowDialog from '@/components/common-components/WindowDialog';

const PatientBilling = () => {
  const [selectedBillingInfoId, setSelectedBillingInfoId] = useState();
  const [showBillingDetails, setShowBillingDetails] = useState(false);
  const SelectBillingItemAction = ({ dataItem }) => {
    return (
      <td>
        <div className="row">
          <div className="col-md-6">
            <Button
              type="button"
              title="Billing Detail"
              onClick={() => {
                setSelectedBillingInfoId(dataItem?.id);
                setShowBillingDetails(true);
              }}
            >
              Billing Detail
            </Button>
          </div>
        </div>
      </td>
    );
  };

  SelectBillingItemAction.propTypes = {
    dataItem: PropTypes.object
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <PatientBillingListData selectAction={SelectBillingItemAction} />
        </div>
      </div>

      <WindowDialog
        title="Billing History"
        style={{ backgroundColor: '#fff', minHeight: '300px' }}
        initialHeight={900}
        initialTop={1}
        initialLeft={1}
        width={1000}
        showDialog={showBillingDetails}
        onClose={() => setShowBillingDetails(false)}
      >
        <>
          <PatientBillingInfoData id={selectedBillingInfoId} />
        </>
      </WindowDialog>
    </>
  );
};

export default PatientBilling;
