/*
 * Gets a date object from the provided string in the format 2021-09-13T15:00:00.000Z
 * Makes sure that timezone offsets are not applied. So the returned date hour and minutes stay same
 *
 */
// If all timezones are needed, use import '@progress/kendo-date-math/tz/all' which is 600kb
import '@progress/kendo-date-math/tz/all';
// todo: if we know what specific timezones we need, we can just import specific ones to decrease the application bundle size
// import "@progress/kendo-date-math/tz/Etc/UTC";
// import "@progress/kendo-date-math/tz/America/New_York";
// import "@progress/kendo-date-math/tz/America/Chicago";
// import "@progress/kendo-date-math/tz/America/Phoenix";
// import "@progress/kendo-date-math/tz/America/Los_Angeles";

import { ZonedDate } from '@progress/kendo-date-math';
import * as moment from 'moment';

/*
 * 1. User selects 4 pm in current timezone
 * 2. Find out how many minutes we need to add or subtract to create a new date time in current timezone that is actually 4 pm in our intended timezone
 * 3. Take that time and use JSON.stringify(date) to get the UTC value for that timezone
 * 4. When loading back from UTC for line number 462
 *     Start: ZonedDate.fromUTCDate(item.startTime, timezone),
 *     End: ZonedDate.fromUTCDate(item.endTime, timezone),
 *  5. The local date created from above represents the same moment in time as the ZonedDate
 */

const getOffsetOfATimezoneFromCurrent = (dateTime, timezone) => {
  const dateInBrowserTimezone = dateTime;
  const browserTimezoneOffset = dateInBrowserTimezone.getTimezoneOffset();

  const tzDate = ZonedDate.fromLocalDate(dateInBrowserTimezone, timezone);
  const userSelectedTimezoneOffset = tzDate.getTimezoneOffset();

  return userSelectedTimezoneOffset - browserTimezoneOffset;
};

export const getSameHoursAndMinsForTimezone = (dateTime, timezone) => {
  const offset = getOffsetOfATimezoneFromCurrent(dateTime, timezone);
  const offsetInMilliseconds = offset * 60000;
  const milliseconds = dateTime.getTime();
  const newTimeInMilliseconds = milliseconds + offsetInMilliseconds;
  const dateTimeForTimezone = new Date(newTimeInMilliseconds);
  return dateTimeForTimezone;
};

const getDateTimeValue = (datePart, timePart) => {
  const dateTimeStrValue = `${moment(datePart).format('YYYY-MM-DD')}T${moment(timePart).format('HH:mm')}`;
  return new Date(dateTimeStrValue);
};
/*
 * 1. User selects 4 pm in current timezone
 * 2. Find out how many minutes we need to add or subtract to create a new date time in current timezone that is actually 4 pm in our intended timezone
 * 3. Take that time and use JSON.stringify(date) to get the UTC value for that timezone
 */
export const getUTCTimeString = (datePart, timePart, intendedTimezone) => {
  const dateTime = getDateTimeValue(datePart, timePart);
  const strInUTC = JSON.stringify(getSameHoursAndMinsForTimezone(dateTime, intendedTimezone));
  return strInUTC.replace(/"/g, '');
};
