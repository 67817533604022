import React, { useEffect, useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import * as moment from 'moment';

// kendo
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Form, Field } from '@progress/kendo-react-form';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { process } from '@progress/kendo-data-query';

// components
import { MessageDialog } from '@/components/common-components/MessageDialog';
import PatientManualSearch from '@/components/Agent/PatientManualSearch';
import { ColumnMenu } from '@/components/common-components/columnMenu';

// gql
import { connectToGraphqlAPI } from '@/provider';
import { getBillingHistoryByDates, getPatientBucket } from '@/graphql/queries';

// context
import { UserContext, PatientContext } from '@/context';

// utils
import { formatDateToAWSDateTime } from '@/common/DateHelper';

const BillingQueue = (props) => {
  const { agent } = useContext(UserContext);
  const { setSelectedPatientInfo } = useContext(PatientContext);

  const [billingTableData, setBillingTableData] = useState([]);

  const [dialogOption, setDialogOption] = useState({});

  //filtering start
  const initialDataState = {};

  const [dataState, setDataState] = useState();
  const [resultState, setResultState] = React.useState(
    process(billingTableData, initialDataState)
  );

  const onDataStateChange = React.useCallback(
    (e) => {
      setDataState(e.dataState); // store for use
      setResultState(process(billingTableData, e.dataState));
    },
    [billingTableData]
  );

  useEffect(() => {
    //if (!loading) {
    setDataState(initialDataState);
    setResultState(process(billingTableData, initialDataState));
    //}
  }, [billingTableData]);

  //filtering end

  // const gridEmptyRow = (tableData) => {
  // 	const rows = []
  // 	if (tableData.length < 100) {
  // 		for (var i = tableData.length, l = 100; i < l; i++) {
  // 			rows.push({
  // 				scheduledStartDate: null,
  // 				scheduledStartTime: null,
  // 				scheduledEndTime: null,
  // 				followupDate: null,
  // 				patientName: null,
  // 				patientId: null,
  // 				orderName: null,
  // 				//orderType: null,
  // 				route: null,
  // 				chairId: null,
  // 				orderStatus: null,
  // 				id: null,
  // 			})
  // 		}
  // 		// console.log(rows)
  // 	}
  // 	return rows
  // }

  const handleRunReport = async (props) => {
    // try {

    // 	const data = await connectToGraphqlAPI({
    // 	graphqlQuery: getBillingHistoryByDates,
    // 	variables: {
    // 	  startDate: formatDateToAWS(props.fromDateRange),
    // 	  endDate: formatDateToAWS(props.toDateRange)
    // 	}
    // });
    // const listBillingItemsData = data.data.getBillingHistoryByDates.map(item => {
    // 	item.patientName = `${item.patientLastName}, ${item.patientFirstName}`;
    // 	item.providerName = `${item.providerFirstName} ${item.providerLastName}`;
    // 	item.itemDate = moment(item.serviceDate).format("MM-DD-YYYY");
    // 	return item;
    //   	}
    //   ).filter(x => x.patientName != 'null, null').sort((a, b) => (a.serviceDate > b.serviceDate ? 1 : -1));

    //   setBillingTableData(listBillingItemsData);

    // } catch (err) {
    // 	if (err && err.errors && err.errors.length > 0) {
    // 		//
    // 	}
    // }
    fetchBillingHistory(props.fromDateRange, props.toDateRange);
  };

  // MAIN INITIATOR
  useEffect(() => {
    // set date range +/- 1 year... API already filters on workstatus != COMPLETE
    const curDate = new Date();
    const startDate = new Date(
      curDate.getFullYear(),
      curDate.getMonth(),
      curDate.getDate() - 14 // default to PREVIOUS 2 weeks, can set to any number days +/-
    );
    //   const endDate = new Date(
    //     curDate.getFullYear() + 1,
    //     curDate.getMonth(),
    //     curDate.getDate()
    //   );
    fetchBillingHistory(startDate, curDate);
  }, []);

  const fetchBillingHistory = async (fromDateRange, toDateRange) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getBillingHistoryByDates,
        variables: {
          startDate: formatDateToAWSDateTime(fromDateRange),
          endDate: formatDateToAWSDateTime(toDateRange)
        }
      });
      ////////////////////////
      let list = data.data.getBillingHistoryByDates;
      // getBillingHistoryByDates API does NOT paginate (no nextToken), so leaving untested code here for day when API may be updated
      // let sNextToken = data.data.getBillingHistoryByDates.nextToken ? data.data.getBillingHistoryByDates.nextToken : null;
      // while (sNextToken != null)
      // {
      // 	try {
      // 		const data = await connectToGraphqlAPI({
      // 				graphqlQuery: getBillingHistoryByDates,
      // 				variables: {
      // 							startDate: formatDateToAWSDateTime(fromDateRange),
      // 							endDate: formatDateToAWSDateTime(toDateRange),
      // 						    nextToken : sNextToken}
      // 		});
      // 		console.log("ILIA getBillingHistoryByDates data", data);

      // 		if (
      // 			data &&
      // 			data.data &&
      // 			data.data.getBillingHistoryByDates &&
      // 			data.data.getBillingHistoryByDates.items
      // 		) {
      // 			sNextToken = data.data.getBillingHistoryByDates.nextToken ? data.data.getBillingHistoryByDates.nextToken : null;
      // 			list = list.concat(data.data.getBillingHistoryByDates);
      // 			console.log("ILIA: GLIST", list);
      // 		}
      // 	} catch (err) {
      // 		console.log("ILIA getBillingHistoryByDates err", err);
      // 		sNextToken = null; // set to stop iterating
      // 	}
      // }
      ///////////////////
      const listBillingItemsData = list
        .map((item) => {
          item.patientName = `${item.patientLastName}, ${item.patientFirstName}`;
          item.providerName = `${item.providerFirstName} ${item.providerLastName}`;
          item.itemDate = moment(item.serviceDate).format('MM-DD-YYYY');
          return item;
        })
        .filter((x) => x.patientName != 'null, null')
        .sort((a, b) => (a.serviceDate > b.serviceDate ? 1 : -1));

      setBillingTableData(listBillingItemsData);
    } catch (err) {
      if (err && err.errors && err.errors.length > 0) {
        //
      }
    }
  };

  const onRowClickHandle = (e) => {
    if (agent.agentId) {
      getPatientBucketData(
        e.dataItem.patientId,
        'PATIENT' //, // would prefer the Billing sub-tab of the Patient Profile tab, but cant seem to get the last selection
        //e.dataItem.scheduledStartDate,
        //e.dataItem.locationId
      );
    }
  };

  const getPatientBucketData = async (patientId, currentStage) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getPatientBucket,
        variables: { patientId: patientId }
      });

      if (data && data.data && data.data.getPatientBucket) {
        setSelectedPatientInfo(data.data.getPatientBucket);
        props.history.push('/patient-portal', {
          searchType: currentStage,
          redirectPatientBilling: true
        });
      } else {
        setDialogOption({
          title: 'Nursing Queue: Get Patient',
          message: 'No Patient Record Found',
          showDialog: true
        });
      }
    } catch (err) {
      console.log('BillingQueue::getPatientBucketData err:', err);
    }
  };

  return (
    <div className='container-fluid'>
      {dialogOption.showDialog && <MessageDialog dialogOption={dialogOption} />}

      <div className='row mt-10'>
        <div className='col-md-12'>
          <div className='row'>
            <div className='col-md-5'>
              <Form
                onSubmit={handleRunReport}
                render={(formRenderProps) => (
                  <form onSubmit={formRenderProps.onSubmit}>
                    <div className='row col-md-12 mt-0 mb-3'>
                      <div className='col-md-4 mt-06'>
                        <Field
                          component={DatePicker}
                          name={'fromDateRange'}
                          label={'From:'}
                        />
                      </div>
                      <div className='col-md-4 mt-06'>
                        <Field
                          component={DatePicker}
                          name={'toDateRange'}
                          label={'To:'}
                        />
                      </div>
                      <div className='col-md-4' style={{ marginTop: '1.8rem' }}>
                        <button type='submit' className='k-button blue'>
                          Run Report
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              />
            </div>
            <div className='col-md-5 offset-2 mt-0'>
              <PatientManualSearch
                searchLayout={2}
                searchEndPoint={'/patient-portal'}
                existingOnly={true}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='row my-4'>
        <div className='col-12 col-lg-12 work-in-progress'>
          <div className='a-grid__header'>
            <div>Billing Queue</div>
          </div>

          <Grid
            className='nurse-queue_grid'
            data={{ data: resultState.data }}
            onDataStateChange={onDataStateChange}
            {...dataState}
            sortable
            onRowDoubleClick={(e) => {
              onRowClickHandle(e);
            }}
          >
            <Column
              field='itemDate'
              title='Date'
              width={100}
              columnMenu={ColumnMenu}
            />
            <Column
              field='locationName'
              title='Location'
              width={125}
              columnMenu={ColumnMenu}
            />
            <Column
              field='patientId'
              title='ID'
              width={125}
              columnMenu={ColumnMenu}
            />
            <Column
              field='patientName'
              title='Patient Name'
              width={180}
              columnMenu={ColumnMenu}
            />
            <Column
              field='providerName'
              title='Provider'
              width={150}
              columnMenu={ColumnMenu}
            />
            <Column
              field='insurerName'
              title='Insurance'
              width={150}
              columnMenu={ColumnMenu}
            />
            <Column
              field='orderName'
              title='Medication'
              width={200}
              columnMenu={ColumnMenu}
            />
            <Column
              field='infusionStatus'
              title='Infusion Status'
              width={125}
              columnMenu={ColumnMenu}
            />
            <Column
              field='billingStatus'
              title='Billed Status'
              width={125}
              columnMenu={ColumnMenu}
            />
            <Column
              field='r2Status'
              title='RCM Status'
              width={150}
              columnMenu={ColumnMenu}
            />
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default withRouter(BillingQueue);
