import React, { useState, useContext } from 'react';

// components
import AwesomeLabel from '@/components/common-components/AwesomeLabel';
import WindowOverlay from '@/components/common-components/WindowOverlay';
import GeneralFollowUpMenu from '@/components/Queue/GeneralFollowUp/GeneralFollowUpMenu';
// styles
import styles from './ItemActions.module.scss';
// context
import { WorkItemContext } from '@/context/WorkItemContext';

const CreateWorkItem = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { selectedWorkItem } = useContext(WorkItemContext);
  const toggleDialog = () => {
    setIsVisible(!isVisible);
  };

  return (
    <>
      {selectedWorkItem.createdBy === 'SYSTEM' && (
        <button
          className={styles.minButtonWidth}
          onClick={() => toggleDialog()}
        >
          <AwesomeLabel icon='user-helmet-safety' label='Create Work Item' />
        </button>
      )}
      {isVisible && (
        <WindowOverlay
          title='Create Work Item'
          onClose={() => toggleDialog()}
          initialTop={50}
          initialHeight={660}
          initialWidth={550}
        >
          <GeneralFollowUpMenu isParentItem toggleDialog={toggleDialog} />
        </WindowOverlay>
      )}
    </>
  );
};

export default CreateWorkItem;
