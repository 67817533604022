import React, { useEffect, useState, useContext } from 'react';
import { PropTypes } from 'prop-types';

// components
import { InsuranceDetails } from './InsuranceDetails';
import { AuthorizationInfo } from './AuthorizationInfo';
import { BenefitCheckingInfo } from './BenefitCheckingInfo';
import PdfContainer from '@/common/HtmlToPdf/PdfContainerBilling';
import Doc from '@/common/HtmlToPdf/DocServiceBilling';

// gql
import { getNursingProcess } from '@/graphql/queries';
import { connectToGraphqlAPI } from '@/provider';

// context
import { LocationContext } from '@/context';

export const BillingHistoryInfo = ({ billing }) => {
  const { getDateByLocation } = useContext(LocationContext);
  const id = billing?.nursingProcessId;
  const inventorySource = billing?.treatmentHistory?.inventorySource
    ? billing?.treatmentHistory?.inventorySource
    : 'Not Available';
  let source =
    inventorySource.replace('BUY_AND_BILL', 'Buy and Bill') ||
    inventorySource.replace('FREE_DRUG', 'Free Drug');
  const [adminCode, setAdminCode] = useState(null);

  const getNursingProcessCall = async () => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getNursingProcess,
        variables: { id }
      });
      setAdminCode(data?.data?.getNursingProcess?.claim?.claimDetail);
    } catch (err) {
      console.error('BillingHistoryInfo::getNursingProcessCall err: ', err);
      setAdminCode(null);
    }
  };

  useEffect(() => {
    if (!id?.length) {
      return;
    }
    setAdminCode(null);
    getNursingProcessCall();
  }, [id]);

  let codeData = adminCode ? JSON.parse(adminCode) : null;

  const data = codeData ? JSON.parse(codeData) : null;

  if (!billing) {
    return null;
  }

  const serviceDate = getDateByLocation(
    billing.locationId,
    billing.serviceDateTime
  );
  const createPdf = (html) => Doc.createPdf(html);

  return (
    <>
      <PdfContainer createPdf={createPdf}>
        <div className='p-1 ml-3 mr-3'>
          <h4>
            <div
              className='row'
              id='billStart'
              style={{ paddingTop: 50, marginBottom: 10 }}
            >
              <div className='col-md-12 ml-2'>
                <span>BILLING DETAIL - </span>
                &nbsp;&nbsp;
                <span>Order: </span>
                {billing?.orderName} &nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;&nbsp;
                {'   '}
                <span> DOS: </span> {serviceDate} &nbsp;&nbsp;&nbsp;&nbsp; |
                &nbsp;&nbsp;&nbsp;&nbsp;{'   '}
                <span>Location: </span>
                {billing?.locationName}&nbsp;&nbsp;&nbsp;&nbsp; |
                &nbsp;&nbsp;&nbsp;&nbsp;{'   '}
                <span>Inventory: </span>
                {source || '-'}&nbsp;&nbsp;&nbsp;&nbsp; |
                &nbsp;&nbsp;&nbsp;&nbsp;{'   '}
                {/* <dev
                  className="row"
                  style={{ paddingTop: 10, marginBottom: 10, marginLeft: 2 }}
                >
                  <span>Admin Codes: </span> &nbsp;
                  {data?.encounter?.items.map((data, index) => {
                    let dataCode = data.detail.hcpcs;
                    return <div key={index}>{dataCode}</div>;
                  })}
                  &nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;&nbsp;
                  {"   "}
                </dev>
                */}
              </div>
            </div>
          </h4>
          <div className='container-fluid'>
            <div className='row mt-3' id='primary'>
              <div
                className='col-12 ml-1'
                style={{ marginBottom: 20, backgroundColor: 'white' }}
              >
                <div
                  className='row mb-4'
                  style={{ backgroundColor: '#e2e1e1' }}
                >
                  <b className='ml-3'>PROCEDURE CODES</b>
                </div>
                <div className='col-md-12 mb-12'>
                  <div className='row'>
                    <div className='col-3'>Procedure Billing Codes:</div>
                    <div className='col-7'>
                      {data?.encounter?.items.map((data, index) => {
                        let dataCode = data.detail.hcpcs;
                        return dataCode != null ? (
                          <div key={index} style={{ display: 'inline-block' }}>
                            {dataCode},&nbsp;&nbsp;
                          </div>
                        ) : (
                          <div></div>
                        );

                        /* let dataCode = data.detail.hcpcs;
                        return <div key={index} style={{display: 'inline-block'}}>{dataCode}&nbsp;&nbsp;</div>; */
                      })}
                    </div>
                  </div>
                  <div className='row mt-6'>
                    <div className='col-3'>Medication Codes:</div>
                    <div className='col-9'>
                      {billing?.billingDetail?.benefitCheckingInfo[0]?.checkings[0]?.priorAuthorization?.jCode.map(
                        (data, index) => {
                          let dataCode = data;
                          return dataCode != null ? (
                            <div
                              key={index}
                              style={{ display: 'inline-block' }}
                            >
                              {dataCode},&nbsp;&nbsp;
                            </div>
                          ) : (
                            <div></div>
                          );
                        }
                      )}
                    </div>
                  </div>
                  <div className='row mt-6'>
                    <div className='col-3'>&nbsp;</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='container-fluid'>
            <div className='row mt-3 ' id='primary'>
              <div
                className='col-4 '
                style={{ marginBottom: 20, backgroundColor: 'white' }}
              >
                <InsuranceDetails
                  insuranceTypeName='PRIMARY'
                  details={billing?.billingDetail?.insuranceInfo?.primary}
                />
              </div>
              {!billing?.billingDetail?.insuranceInfo?.secondary ? (
                <div className='col-4'></div>
              ) : (
                <div
                  className='col-4'
                  style={{ marginBottom: 20, backgroundColor: 'white' }}
                >
                  <InsuranceDetails
                    insuranceTypeName='SECONDARY'
                    details={billing?.billingDetail?.insuranceInfo?.secondary}
                  />
                </div>
              )}
              {!billing?.billingDetail?.insuranceInfo?.tertiary ? (
                <div className='col-4'></div>
              ) : (
                <div
                  className='col-4'
                  style={{ marginBottom: 20, backgroundColor: 'white' }}
                >
                  <InsuranceDetails
                    insuranceTypeName='TERTIARY'
                    details={billing?.billingDetail?.insuranceInfo?.tertiary}
                  />
                </div>
              )}
            </div>
          </div>
          <div className='line pt--5'>
            <hr></hr>
          </div>
          <div className='row' style={{ marginBottom: 10 }}>
            <div className='col-md-12 ml-2'>
              {billing?.billingDetail?.benefitCheckingInfo?.length ? (
                <h4>BENEFITS INV</h4>
              ) : (
                <div></div>
              )}
            </div>
          </div>

          {!billing?.billingDetail?.benefitCheckingInfo?.length
            ? null
            : billing?.billingDetail?.benefitCheckingInfo.map(
                (details, index) => (
                  <BenefitCheckingInfo key={index} details={details} />
                )
              )}

          {billing?.billingDetail?.benefitCheckingInfo?.length ? (
            <div className='line'>
              <hr></hr>
            </div>
          ) : (
            <div></div>
          )}

          <div className='row' style={{ marginBottom: 10 }}>
            <div className='col-md-12 ml-2  '>
              {billing?.billingDetail?.authorizationInfo?.length ? (
                <h4>PRIOR AUTH</h4>
              ) : (
                <div></div>
              )}
            </div>
          </div>
          {!billing?.billingDetail?.authorizationInfo?.length
            ? null
            : billing?.billingDetail?.authorizationInfo.map(
                (details, index) => (
                  <AuthorizationInfo key={index} details={details} />
                )
              )}
        </div>
      </PdfContainer>
      {billing?.billingDetail?.authorizationInfo?.length ? (
        <div className='line'>
          <hr></hr>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

BillingHistoryInfo.propTypes = {
  billing: PropTypes.object
};
