import React, { useState, useEffect } from 'react';
import pluralize from 'pluralize';
import styled from 'styled-components';

// common components
import ConfirmationModal from '@/components/common-components/ConfirmationModal';
import Alert from '@/components/common-components/Alert';

// partials
import BulkAdjustmentSelector from './BulkAdjustmentSelector';

// styled components
const ModalBody = styled.div`
  padding: 5px 5px 20px 5px;
  font-size: 16px;
`;

const ModalSelector = styled.div`
  margin-top: 15px;
`;

const BulkAdjustmentModal = ({
  isVisible,
  numSelected,
  handleClose,
  handleConfirm
}) => {
  const [reason, setReason] = useState(null);

  useEffect(() => {
    setReason(null);
  }, [isVisible]);

  const pluralizedItems = pluralize('selected item', numSelected, true);

  return (
    <ConfirmationModal
      isVisible={isVisible}
      dialogBody={
        <ModalBody>
          <Alert>Are you sure you want to adjust {pluralizedItems}?</Alert>
          <small>Once done their quantity will be set to 0.</small>
          <ModalSelector>
            <BulkAdjustmentSelector
              onSelectChange={(selected) => {
                if (selected && selected.mainCategory) {
                  setReason(
                    `${selected.mainCategory};${selected.text};${selected.notes}`
                  );
                } else if (selected && selected.text) {
                  setReason(selected.text);
                } else setReason(null);
              }}
              placeholder='Select Reason for Adjustment'
            />
          </ModalSelector>
        </ModalBody>
      }
      dialogTitle='Inventory Bulk Adjustment'
      onClose={handleClose}
      onReject={handleClose}
      onConfirm={() => handleConfirm(reason)}
      disabled={!reason}
    />
  );
};

export default BulkAdjustmentModal;

ModalBody.displayName = 'ModalBody';
ModalSelector.displayName = 'ModalSelector';
