// THIS WILL LATER BE IN IN THE Products TABLE

export default [
  {
    drugName: 'Acetaminophen',
    drugPreMed: true,
    dose: '325',
    route: 'PO',
    uom: 'mg',
    title: 'Acetaminophen 325 mg (PO)'
  },
  {
    drugName: 'Acetaminophen',
    drugPreMed: true,
    dose: '500',
    route: 'PO',
    uom: 'mg',
    title: 'Acetaminophen 500 mg (PO)'
  },
  {
    drugName: 'Acetaminophen',
    drugPreMed: true,
    dose: '650',
    route: 'PO',
    uom: 'mg',
    title: 'Acetaminophen 650 mg (PO)'
  },
  {
    drugName: 'Acetaminophen',
    drugPreMed: true,
    dose: '1000',
    route: 'PO',
    uom: 'mg',
    title: 'Acetaminophen 1000 mg (PO)'
  },
  {
    drugName: 'Benadryl',
    drugPreMed: true,
    dose: '25',
    route: 'PO',
    uom: 'mg',
    title: 'Benadryl 25 mg (PO)'
  },
  {
    drugName: 'Benadryl',
    drugPreMed: true,
    dose: '25',
    route: 'IV',
    uom: 'mg',
    title: 'Benadryl 25 mg (IV)'
  },
  {
    drugName: 'Benadryl',
    drugPreMed: true,
    dose: '50',
    route: 'IV',
    uom: 'mg',
    title: 'Benadryl 50 mg (IV)'
  },
  {
    drugName: 'Cetirizine',
    drugPreMed: true,
    dose: '10',
    route: 'PO',
    uom: 'mg',
    title: 'Cetirizine 10 mg (PO)'
  },
  {
    drugName: 'Claritin',
    drugPreMed: true,
    dose: '10',
    route: 'PO',
    uom: 'mg',
    title: 'Claritin 10 mg (PO)'
  },
  {
    drugName: 'Dexamethasone',
    drugPreMed: true,
    dose: '4',
    route: 'IV',
    uom: 'mg',
    title: 'Dexamethasone 4 mg (IV)'
  },
  {
    drugName: 'Dexamethasone',
    drugPreMed: true,
    dose: '8',
    route: 'IV',
    uom: 'mg',
    title: 'Dexamethasone 8 mg (IV)'
  },
  {
    drugName: 'Famotidine',
    drugPreMed: true,
    dose: '40',
    route: 'PO',
    uom: 'mg',
    title: 'Famotidine 40 mg (PO)'
  },
  {
    drugName: 'Famotidine',
    drugPreMed: true,
    dose: '40',
    route: 'IV',
    uom: 'mg',
    title: 'Famotidine 40 mg (IV)'
  },
  {
    drugName: 'Hydrocortisone',
    drugPreMed: true,
    dose: 'any up to 100mg',
    route: 'IV',
    uom: 'mg',
    title: 'Hydrocortisone < 100 mg (IV)'
  },
  {
    drugName: 'Ibuprofen',
    drugPreMed: true,
    dose: '200',
    route: 'PO',
    uom: 'mg',
    title: 'Ibuprofen 200 mg (PO)'
  },
  {
    drugName: 'Ibuprofen',
    drugPreMed: true,
    dose: '400',
    route: 'PO',
    uom: 'mg',
    title: 'Ibuprofen 400 mg (PO)'
  },
  {
    drugName: 'Ibuprofen',
    drugPreMed: true,
    dose: '600',
    route: 'PO',
    uom: 'mg',
    title: 'Ibuprofen 600 mg (PO)'
  },
  {
    drugName: 'Ibuprofen',
    drugPreMed: true,
    dose: '800',
    route: 'PO',
    uom: 'mg',
    title: 'Ibuprofen 800 mg (PO)'
  },
  {
    drugName: 'Palonosetron',
    drugPreMed: true,
    dose: '0.25',
    route: 'IV',
    uom: 'mg',
    title: 'Palonosetron 0.25 mg (IV)'
  },
  {
    drugName: 'Prednisone',
    drugPreMed: true,
    dose: '5',
    route: 'PO',
    uom: 'mg',
    title: 'Prednisone 5 mg (PO)'
  },
  {
    drugName: 'Solumedrol',
    drugPreMed: true,
    dose: '40',
    route: 'IV',
    uom: 'mg',
    title: 'Solumedrol 40 mg (IV)'
  },
  {
    drugName: 'Solumedrol',
    drugPreMed: true,
    dose: '100',
    route: 'IV',
    uom: 'mg',
    title: 'Solumedrol 100 mg (IV)'
  },
  {
    drugName: 'Solumedrol',
    drugPreMed: true,
    dose: '125',
    route: 'IV',
    uom: 'mg',
    title: 'Solumedrol 125 mg (IV)'
  },
  {
    drugName: 'Solumedrol',
    drugPreMed: true,
    dose: '40',
    route: 'other',
    uom: 'mg',
    title: 'Solumedrol 40 mg (other)'
  },
  {
    drugName: 'Solumedrol',
    drugPreMed: true,
    dose: '500',
    route: 'IV',
    uom: 'mg',
    title: 'Solumedrol 500 mg (IV)'
  },
  {
    drugName: 'Solumedrol',
    drugPreMed: true,
    dose: '1000',
    route: 'IV',
    uom: 'mg',
    title: 'Solumedrol 1000 mg (IV)'
  },
  {
    drugName: 'Zofran',
    drugPreMed: true,
    dose: '4',
    route: 'PO',
    uom: 'mg',
    title: 'Zofran 4 mg (PO)'
  },
  {
    drugName: 'Zofran',
    drugPreMed: true,
    dose: '8',
    route: 'PO',
    uom: 'mg',
    title: 'Zofran 8 mg (PO)'
  },
  {
    drugName: 'Zofran',
    drugPreMed: true,
    dose: '4',
    route: 'IV',
    uom: 'mg',
    title: 'Zofran 4 mg (IV)'
  },
  {
    drugName: 'Zofran',
    drugPreMed: true,
    dose: '8',
    route: 'IV',
    uom: 'mg',
    title: 'Zofran 8 mg (IV)'
  }
];
