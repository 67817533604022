import React, { useContext } from 'react';

// components
import ReturnToQueue from './components/ItemActions/ReturnToQueue';

// context
import { WorkItemContext } from '@/context';

// styles
import styles from './ActiveWorkItems.module.scss';

const ActiveWorkItemsMini = () => {
  const { showMinimizedPanel, setShowMinimizedPanel } =
    useContext(WorkItemContext);

  if (!showMinimizedPanel) return null;

  return (
    <div className={styles.stickyMini}>
      <div className={styles.miniContainer}>
        <div>Work item queue is cleared</div>
        <ReturnToQueue />
      </div>
      <div
        className={styles.close}
        onClick={() => setShowMinimizedPanel(false)}
      >
        x
      </div>
    </div>
  );
};

export { ActiveWorkItemsMini };
