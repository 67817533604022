import React, { createContext, useState, useEffect } from 'react';

// gql
import { getQueryClient } from '@/provider';
import { listCodeInfos } from '@/graphql/queries';

// utils
import {
  CODE_TYPES,
  filterCodesByType,
  mapICD10Codes
} from './InfoCodeContextHelper';

export const InfoCodeContext = createContext();
export const InfoCodeConsumer = InfoCodeContext.Consumer;

const InfoCodeContextProvider = ({ children, user }) => {
  const [dxCodes, setDxCodes] = useState([]);
  const [BICodes, setBICodes] = useState([]);
  const [PACodes, setPACodes] = useState([]);
  const [inboundCodes, setInboundCodes] = useState([]);
  const [intakeCodes, setIntakeCodes] = useState([]);
  const [scheduleCodes, setScheduleCodes] = useState([]);

  const fetchListCodeInfos = async () => {
    try {
      const data = await getQueryClient({
        query: listCodeInfos,
        payload: {
          limit: 1000
        },
        path: 'listCodeInfos'
      });

      if (data?.length > 0) {
        // filter and map ICD10 codes
        const icd10Codes = mapICD10Codes(data);
        setDxCodes(icd10Codes);

        // set other code types
        const cBI = filterCodesByType(data, CODE_TYPES.BI);
        const cPA = filterCodesByType(data, CODE_TYPES.PA);
        const cInbound = filterCodesByType(data, CODE_TYPES.INBOUND);
        const cIntake = filterCodesByType(data, CODE_TYPES.INTAKE);
        const cSchedule = filterCodesByType(data, CODE_TYPES.SCHEDULE);

        setBICodes(cBI);
        setPACodes(cPA);
        setInboundCodes(cInbound);
        setIntakeCodes(cIntake);
        setScheduleCodes(cSchedule);
      }
    } catch (err) {
      console.error('InfoCodeContext::fetchListCodeInfos err:', err);
    }
  };

  const isValidDxCode = (dxCode) => {
    if (!dxCode) return false;

    return (
      dxCodes.findIndex((codeValue) => codeValue.codeDescription === dxCode) >
      -1
    );
  };

  useEffect(() => {
    // action fires only if user logged in
    if (user) {
      fetchListCodeInfos();
    }
  }, [user]);

  return (
    <InfoCodeContext.Provider
      value={{
        dxCodes,
        setDxCodes,
        isValidDxCode,
        BICodes,
        PACodes,
        inboundCodes,
        intakeCodes,
        scheduleCodes
      }}
    >
      {children}
    </InfoCodeContext.Provider>
  );
};

export default InfoCodeContextProvider;
