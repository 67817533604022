import { savePDF } from '@progress/kendo-react-pdf';

class DocServiceBilling {
  createPdf = (html) => {
    savePDF(html, {
      paperSize: 'Letter',
      fileName: 'BillingHistory.pdf',
      scale: 0.5,
      margin: 3
    }, () => {
        console.log('pdf save successfully.');
    });
  }
}

const Doc = new DocServiceBilling();
export default Doc;
