import {
  formatDateToDefault,
  calculateAge
} from '@/common/DateHelper';
import { buildDXString, renderWeightInHeader } from '../../helpers/helperQOL';
import { QOL_PROVIDER_FOLLOWING } from '../../surveyDictionary';

const buildHeaderModelProvider = userData => {
  const weightInfo = renderWeightInHeader(userData.patientProfile.weightInfo);
  const dxString = buildDXString(userData.referralData?.referralOrder);
  const title =
    userData.surveyType === QOL_PROVIDER_FOLLOWING
      ? 'Follow-up assessment'
      : 'First Assessment';

  return {
    pages: [
      {
        name: 'patientInfo',
        elements: [
          {
            name: 'patientHeader',
            type: 'html',
            html: `<table class='survey-header-table'>
            <tr>
              <td><i class="fa-solid fa-user"></i>&nbsp;${
                userData?.patientFirstName
              } ${userData?.patientLastName}</td>
              <td><i class="fa-solid fa-calendar"></i>&nbsp;<b>DOB:</b> ${
                formatDateToDefault(userData.dob) || 'n/a'
              } (${calculateAge(userData.dob)} years)</td>
              <td><i class="fa-solid fa-map-location-dot"></i>&nbsp;<b>Location:</b> ${
                userData.patientProfile?.patientInfo?.address?.city
              }, ${userData.patientProfile?.patientInfo?.address?.state}</td>
            </tr>
            <tr>
              <td colspan="2"><i class="fa-solid fa-pills"></i>&nbsp;<b>Drug for the infusion:</b> ${
                userData?.referralData?.drugName
              }</td>
              <td><i class="fa-solid fa-weight-scale"></i>&nbsp;<b>Patient Weight:</b> ${weightInfo}</td>
            </tr>
            <tr>
            <td colspan="3">
              <i class="fa-solid fa-stethoscope"></i>&nbsp;<b>Diagnosis (DX):</b> ${dxString}
            </td>
            </tr>
          </table>
          <br />
          <p>
            <b>Directions to the Provider:</b> Both through direct observation and through patient responses to direct questions (open-ended and through use of a teach-back) select one value for each question that best represents the patients current state.
          </p>
          <hr />`
          }
        ],
        title
      }
    ],
    showQuestionNumbers: 'off',
    // showPrevButton: false,
    showNavigationButtons: false,
    mode: 'display'
  };
};

export default buildHeaderModelProvider;
