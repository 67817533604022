import React, { useState, useEffect } from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';

import { RELEASE_NOTES_KEY, RELEASE_NOTES_STATUS } from './ReleaseNotesModal.constants';
import styles from './ReleaseNotesModal.module.scss';

const ReleaseNotesModal = ({ isLoggedIn, isServiceWorkerUpdated, onUpdate, onClose }) => {
  const [showReleasesDialog, setShowReleasesDialog] = useState(false);

  const releaseNotesStatus = localStorage.getItem(RELEASE_NOTES_KEY);

  const onCloseModal = () => {
    localStorage.setItem(RELEASE_NOTES_KEY, RELEASE_NOTES_STATUS.DONE);
    setShowReleasesDialog(false);
    onClose();
  };

  const onUpdateNow = () => {
    onUpdate();
    onCloseModal();
  };

  useEffect(() => {
    if (!isServiceWorkerUpdated) return;
    if (isLoggedIn) {
      setShowReleasesDialog(true);
    } else {
      localStorage.setItem(RELEASE_NOTES_KEY, RELEASE_NOTES_STATUS.PENDING);
    }
  }, [isServiceWorkerUpdated]);

  useEffect(() => {
    if (isLoggedIn && releaseNotesStatus === RELEASE_NOTES_STATUS.PENDING) {
      setShowReleasesDialog(true);
    }
  }, [isLoggedIn, releaseNotesStatus]);

  if (showReleasesDialog) {
    return (
      <Dialog
        className={styles.releaseNotesDialog}
        title="A new version was deployed"
        width={500}
        onClose={onCloseModal}
      >
        <div>In order to make sure all updates are in application clear your browser's cache and refresh the page.</div>
        {releaseNotesStatus === RELEASE_NOTES_STATUS.PENDING ? (
          <Button
            className="btn blue"
            onClick={onCloseModal}
          >
            Confirm
          </Button>
        ) : (
          <Button
            className="btn blue"
            onClick={onUpdateNow}
          >
            Update now
          </Button>
        )}
      </Dialog>
    );
  }
  return null;
};

export { ReleaseNotesModal };
