import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import styled from 'styled-components';
import { AmplifyAuthenticator } from '@aws-amplify/ui-react';
import Amplify, { I18n } from 'aws-amplify';
import LogRocket from 'logrocket';
import * as serviceWorker from './serviceWorker';

// constants
import volcabularies from '@/constants/volcabularies';
import config from '@/config';

// components
import Header from '@/components/Header/index';
import Footer from '@/components/Footer';
import SurveyEngine from '@/components/SurveyEngine';
import Fallback from '@/components/Fallback/Fallback';
import Notifier from '@/components/Notifier';
import IdleAutoLogoffModal from '@/components/IdleAutoLogoffModal';
import ReleaseNotesModal from '@/components/ReleaseNotesModal';
import {
  ActiveWorkItemsSticky,
  ActiveWorkItemsMini
} from './components/Header/components/ActiveWorkItems';
import ScrollToTop from './components/ScrollToTop';
import WorkItemLogDrawer from './components/WorkItemLogDrawer';
import CallHistoryDrawer from './components/CallHistoryDrawer';
import PatientStatusLogDrawer from './components/Patient/PatientStatusLogDrawer';
import LocationHistoryLogDrawer from './components/Inventory/InventoryLocationItemLogDrawer';

// React Context
import Compose from '@/Compose';
import ContextProviders from './ContextProviders';
import { useServiceWorker } from '@/context/ServiceWorkerContext';

// React Router
import AppRoutes from './AppRoutes';

// assets
import './styles/App.scss';
import svgLogo from '@/assets/images/aleracare.svg';

Amplify.configure(config.aws);

I18n.putVocabularies(volcabularies);

const AppWrapper = styled.div`
  background-image: linear-gradient(
    rgba(0, 82, 130, 1),
    rgba(240, 248, 253, 1)
  );
  display: ${(props) => (props.user ? 'block' : 'flex')};
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const isProduction = process.env.REACT_APP_ENV === 'prod';

const App = () => {
  const [user, setUser] = useState();

  const {
    isServiceWorkerUpdated,
    serviceWorkerRegistration,
    setIsServiceWorkerUpdated,
    onSWInit,
    onSWUpdate,
    onSWActivate
  } = useServiceWorker();

  const isLoggedIn = Boolean(user);

  if (isProduction) {
    // register service worker
    serviceWorker.register({
      onSuccess: onSWInit,
      onUpdate: onSWUpdate
    });

    // init LogRocket
    LogRocket.init('izpx5h/dev');
    LogRocket.identify(user?.username, {
      name: `${user?.attributes?.given_name} ${user?.attributes?.family_name}`,
      email: user?.attributes?.email
    });
  }

  const onAuthStateChange = (__, authData) => {
    setUser(authData);
  };

  const onCloseReleaseDialog = () => {
    setIsServiceWorkerUpdated(false);
  };

  useEffect(() => {
    if (isLoggedIn) return;
    if (isServiceWorkerUpdated) {
      onSWActivate(serviceWorkerRegistration);
    }
  }, [isServiceWorkerUpdated]);

  console.info('App version: ', process.env.REACT_APP_VERSION);

  return (
    <AppWrapper user={user}>
      <div className={isLoggedIn ? 'loggedin_container' : 'branding'}>
        {!isLoggedIn && (
          <img className="branding__logo" src={svgLogo} alt="logo" />
        )}
        <AmplifyAuthenticator
          handleAuthStateChange={onAuthStateChange}
          className="aws-amplify-authenticator"
        >
          <Router>
            <ScrollToTop />
            <Compose components={ContextProviders} user={user}>
              <header className="header">
                <PatientStatusLogDrawer />
                <WorkItemLogDrawer />
                <CallHistoryDrawer />
                <ActiveWorkItemsSticky />
                <ActiveWorkItemsMini />
                <LocationHistoryLogDrawer />
                <Header />
              </header>
              <ErrorBoundary FallbackComponent={Fallback}>
                <AppRoutes />
                <footer className="footer">
                  <Footer />
                  <Notifier />
                  <SurveyEngine />
                </footer>
              </ErrorBoundary>
            </Compose>
          </Router>
        </AmplifyAuthenticator>
        {!isLoggedIn && (
          <footer className="footer login">
            <Footer />
          </footer>
        )}
      </div>

      {/* Release Notes Modal */}
      <ReleaseNotesModal
        isLoggedIn={isLoggedIn}
        isServiceWorkerUpdated={isServiceWorkerUpdated}
        onUpdate={() => onSWActivate(serviceWorkerRegistration)}
        onClose={onCloseReleaseDialog}
      />

      {/* Idle Countdown Modals */}
      <IdleAutoLogoffModal isLoggedIn={isLoggedIn} />
    </AppWrapper>
  );
};

export default App;
