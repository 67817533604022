export const Constants = {
  ErrorMessage: {
    // selectDrug_REQUIRED: "Drug Selection Must not be Empty",
    // gender_REQUIRED: "Gender Selection Must not be Empty",
    FirstName_REQUIRED: 'required',
    lastname_REQUIRED: 'required',
    dateOfBirth_REQUIRED: 'required',
    Address_REQUIRED: 'Address Must not be empty',
    State_REQUIRED: 'State Must not be empty',
    // SSN_REQUIRED: "SSN Must not be Empty",
    PhoneNumber_REQUIRED: 'required',
    PhoneNumber_FORMAT: 'Phone number not formatted correctly',
    // EMAIL_REQUIRED: "Email Must not be empty",
    EMAIL_REGX: 'Email Must be a valid address',
    PASSWROD_REQUIRD: 'Email Must not be empty',
    Zip_REQUIRED: 'Zip Must not be empty',
    City_REQUIRED: 'City Must not be empty',
    // altContactFirstName_REQUIRED: "Alternate First Name Must not be empty",
    // altContactLastName_REQUIRED: "Alternate Last Name Must not be empty",
    // altContactPhone_REQUIRED: "Alternate First Name Must not be empty",
    Alpha_Required: 'Only Alphabets are allowed',
    AlphaNumeric_Required: 'Only Alphabets and Numerals are allowed',
    Numeric_Required: 'Only Numerals are allowed',
    NO_EXTRA_SYMBOLS: 'No special characters or spaces allowed',
    PosNumeric_Required: 'Only Positive Numerals are allowed',
    Admin_Timer_Range: 'Alert: Time is not within Business Hours',
    // preferredLanguage_REQUIRED: "please enter Preferred Language",
    // planName_REQUIRED: "Plan Name is Required",
    // Name_REQUIRED: "Name is Required",
    state_REQUIRED: 'please select a state',
    insuranceExpireDate_REQUIRED: 'Insurance Exp. Date Must not be Empty',
    prescFirstName_REQUIRED: 'First Name Must not be empty',
    prescLastName_REQUIRED: 'Last Name Must not be empty',
    // officePhNo_REQUIRED: "Phone No. Must not be empty",
    FaxNo_REQUIRED: 'Fax No. Must not be empty',
    siteInstName_REQUIRED: 'Site Name Must not be empty',
    taxIdNo_REQUIRED: 'Tax. ID Must not be empty',
    npiNo_REQUIRED: 'NPI No. Must not be empty',
    orderDate_REQUIRED: 'Order Exp. Date Must not be empty',
    primaryDiag_REQUIRED: 'Primary Diagnosis is Required',
    specPharmName_REQUIRED: 'Specialty Pharmacy Name is Required',
    SSN_No_REQUIRED: 'SSN No Must not be empty',
    reasNewTherapy_REQUIRED: 'Reason is Required',
    primaryDiagnosisInfo_REQUIRED: 'Primary Diagnosis is Required',
    infusionProvName_REQUIRED: 'Name is Required',
    drug_REQUIRED: 'Drug Name is Required',
    dosage_REQUIRED: 'Dosage is Required',
    frequency_REQUIRED: 'Frequency is Required',
    doctorName_Required: 'Physician Name is Required',
    orderDates_REQUIRED: 'Order Date is Required',
    npiNumber_REQUIRED: 'NPI is Required',
    is_REQUIRED: 'Required',
    polIdNum_REQUIRED: 'Required',
    insName_REQUIRED: 'Required',
    packageDeliveryCompany_REQUIRED: 'Required',
    trackingNumber_REQUIRED: 'Required',
    allFields_REQUIRED: 'All fields are required, please provide more details',
    checkInStartTime_WITHIN_OFFICE_HOURS:
      'Checkin time should be between 8 am and 6 pm',
    appointmentStartTime_WITHIN_OFFICE_HOURS:
      'Appointment start time should be between 8 am and 6 pm',
    appointmentEndTime_WITHIN_OFFICE_HOURS:
      'Appointment end time should be between 8 am and 6 pm',
    Gender_REQUIRED: 'Required',
    effDate_REQUIRED: 'Required',
    temp_Value: 'please check the temperature',
    heartrate_Value: ' please check the heartrate',
    spo2_Value: ' please check the SP02',
    r_Value: 'please check the R value',
    planName_REQUIRED: 'required',
    datePast: 'Date cannot be in the past',
    dateFuture: 'Date cannot be in the future',
    dateOneYearPast: 'Date cannot be over one year in the past',
    dateOneHundredAndTwentyYearsPast:
      'Date cannot be over one hundred and twenty years in the past',
    dateFormat: 'Date must be in a (MM/DD/YYYY)',
    notEnoughRights: 'You are not authorized to perform this action',
    minLength_REQUIRED: 'Length must be minimum {length} character(s)',
    maxLength_REQUIRED: 'Length must be maximum {length} character(s)',
    zipLength_REQUIRED: 'Length must be 5 or 9 characters',
    zipCode_FORMAT: 'Invalid zipcode',
    noCommas: 'Commas are not allowed',
    notMainCategory: 'Please select sub-category'
  },
  Label: {
    PRESCRIPTION_FOR: 'PRESCRIPTION FOR'
  },
  DATE: {
    SHORTDATE: 'MM-DD-YYYY',
    LONGFORMAT: 'YYYY-DD-MM',
    DEFAULT: 'MM/DD/YYYY',
    DEFAULT_TIME: 'hh:mm A',
    DEFAULT_DATETIME: 'MM/DD/YYYY hh:mm A',
    DEFAULT_AWS: 'YYYY-MM-DD',
    DEFAULT_AWS_DATETIME: "yyyy-MM-dd'T'HH:mm:ss.sss'Z'"
  }
};
