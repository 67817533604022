import React, { useContext, useEffect, useState } from 'react';
import { Storage } from 'aws-amplify';
import * as moment from 'moment';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';

// components
import WindowDialog from '@/components/common-components/WindowDialog';

// gql
import { connectToGraphqlAPI } from '@/provider';
import { GetCommunicationHistoryByPatientId } from '@/graphql/queries';

// context
import { PatientContext } from '@/context/PatientContext';

import PatientDocument from '../Patient/PatientDocument';

const FaxHistory = () => {
  const { selectedPatientInfo } = useContext(PatientContext);

  const [listCommunicationHistoryData, setListCommunicationHistoryData] =
    useState();
  const [generatedPdfPath, setGeneratedPdfPath] = useState('');
  const [showPatientFaxDocumentDialog, setShowPatientFaxDocumentDialog] =
    useState(false);

  const listCommunicationHistoryCall = async requestObject => {
    // Call has been updated to use GetCommunicationHistoryByPatientId but list names were not changed,
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: GetCommunicationHistoryByPatientId,
        variables: requestObject
      });
      if (
        data &&
        data.data &&
        data.data.getCommunicationHistoryByPatientId &&
        data.data.getCommunicationHistoryByPatientId.items
      ) {
        /// /////////////////////
        let list = data.data.getCommunicationHistoryByPatientId.items;
        let sNextToken = data.data.getCommunicationHistoryByPatientId.nextToken
          ? data.data.getCommunicationHistoryByPatientId.nextToken
          : null;
        while (sNextToken != null) {
          try {
            const patientData = await connectToGraphqlAPI({
              graphqlQuery: GetCommunicationHistoryByPatientId,
              variables: requestObject
            });

            if (
              patientData &&
              patientData.data &&
              patientData.data.getCommunicationHistoryByPatientId &&
              patientData.data.getCommunicationHistoryByPatientId.items
            ) {
              sNextToken = patientData.data.getCommunicationHistoryByPatientId.nextToken
                ? patientData.data.getCommunicationHistoryByPatientId.nextToken
                : null;
              list = list.concat(patientData.data.getCommunicationHistoryByPatientId.items);
            }
          } catch (err) {
            console.log('SiteInventory::listProductsCall err: ', err);
            sNextToken = null; // set to stop iterating
          }
        }
        /// ////////////////

        const theData = list
          .map(item => ({
            ...item,
            sortTime: item.eventTime,
            eventTime: moment(new Date(item.eventTime)).format('MM/DD/YYYY @ hh:mm A'),
            text: `${item.subject}`,
            value: item.id
          }))
          .sort((a, b) => (b.sortTime > a.sortTime ? 1 : -1));
          // .sort((a, b) => (b.eventTime > a.eventTime ? 1 : -1));

        setListCommunicationHistoryData(theData);
      }
    } catch (err) {
      console.log('marty listCommunicationHistoryCall err', err);
      alert('marty listCommunicationHistoryCall err');
    }
  };

  // MAIN INITIATOR
  useEffect(() => {
    const curDate = new Date();
    const startDate = new Date(
      curDate.getFullYear() - 1,
      curDate.getMonth(),
      curDate.getDate()
    );

    const requestObj = {
      patientId: selectedPatientInfo.patientId,
      filter: {
        eventTime: { gt: moment(startDate).format('YYYY-MM-DD') }, // get up to one years worth of faxes
        // NOTE: currently records are small enough that returned values are sorted client side, however if records increase and get "paged" it is possible
        // that NEWER faxes may not be in the first return set... when i try to specify sortDirection i get error msg: 'The variables input contains a
        // field name 'sortDirection' that is not defined for input object type 'ModelCommunicationHistoryFilterInput' '
        // so leaving out for now cuz working...
        // sortDirection: "DESC"
      }
    };
    listCommunicationHistoryCall(requestObj);
  }, []);

  /* useEffect(() => {
    console.log(
      "marty listCommunicationHistoryData useEffect",
      listCommunicationHistoryData
    );
    // setShowCommunicationDetails(true)
  }, [listCommunicationHistoryData]); */

  const showPatientFaxDocument = () => {
    setShowPatientFaxDocumentDialog(true);
  };

  const handleOnDialogClose = () => {
    setShowPatientFaxDocumentDialog(false);
  };

  const handlePerviewFax = async props => {
    const conf = { download: false };
    const s3ImageURL = await Storage.get(props.dataItem.reference, conf);
    setGeneratedPdfPath(s3ImageURL);
    showPatientFaxDocument();
  };

  const perviewFaxDocumentCell = props => {
    return (
      <td>
        <span className="blue-link" onClick={() => handlePerviewFax(props)}>
          {props.dataItem.reference}
        </span>
      </td>
    );
  };

  return (
    <div className="col-md-12" style={{ minHeight: '750px' }}>
      <div className="row">
        <div className="col-md-3 mb-3 pageTitle">Fax Communication History</div>
      </div>
      <div className="row">
        <div className="col">
          <Grid
            data={listCommunicationHistoryData}
            // customCell={customCell}
            // title="FAX HISTORY"
          >
            <Column field="eventTime" title="Date Sent" width={200} />
            <Column field="subject" title="Subject (Referral)" width={180} />
            {/* <Column field="docType" title="Document Type" width={120} /> */}
            <Column field="toEntity" title="Outgoing Fax" width={140} />
            <Column field="fromEntity" title="Sender" width={180} />
            <Column field="status" title="Status" width={120} />
            <Column
              field="reference"
              title="Preview"
              width={220}
              cell={perviewFaxDocumentCell}
            />
            <Column field="id" title="Communication ID" width={300} />
          </Grid>
        </div>
      </div>

      <WindowDialog
        title="Patient Document"
        style={{ backgroundColor: '#e9ecef', minHeight: '300px' }}
        initialHeight={800}
        initialTop={150}
        initialLeft={550}
        width={800}
        showDialog={showPatientFaxDocumentDialog}
        onClose={handleOnDialogClose}
      >
        <PatientDocument file={generatedPdfPath} />
      </WindowDialog>
    </div>
  );
};

export default FaxHistory;
