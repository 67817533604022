import React, { useContext } from 'react';

// components
import PatientAddSearch from '@/components/Agent/PatientAddSearch';
import InboundFaxWidget from './InboundFaxWidget';
import QueueCenterWidget from './QueueCenterWidget';

// context
import { UserContext } from '@/context';
import CalendarWidget from './CalendarWidget';

const LandingWidgets = () => {
  const { user } = useContext(UserContext);

  return (
    <div className='container-fluid agent-work-queue'>
      {user?.username && (
        <>
          <div className='row pt-3'>
            <div className='col-3'>
              <PatientAddSearch
                searchEndPoint='/patient-portal'
                existingOnly={false}
              />
            </div>
            <div className='col-9'>
              <div className='k-card-deck'>
                <QueueCenterWidget />
                <CalendarWidget />
                <InboundFaxWidget />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default LandingWidgets;
