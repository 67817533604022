import React, { useEffect } from 'react';

// app components
import WindowOverlay from '@/components/common-components/WindowOverlay';

const CreateWorkItemDialogOverlay = (props) => {
  useEffect(() => {
    return () => {
      // on dialog unmount we have to release work item
      props.onUnmount();
    };
  }, []);

  return <WindowOverlay {...props}>{props.children}</WindowOverlay>;
};

export default CreateWorkItemDialogOverlay;
