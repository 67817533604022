import React, { useContext } from 'react';

// components
import WorkItemTimeoutModal from '@/components/WorkItemTimeoutModal/WorkItemTimeoutModal';
import PayerOON from './ItemsByType/PayerOON';
import OutboundCall from './ItemsByType/OutboundCall';
import CommonWorkItem from './ItemsByType/CommonWorkItem';

// context
import { WorkItemContext } from '@/context';

// utils
import { workItemTypes } from '@/constants/enum';

const CurrentWorkItem = () => {
  const { selectedWorkItem } = useContext(WorkItemContext);

  if (!selectedWorkItem || !selectedWorkItem.id) return null;

  const WorkItemComponent = () => {
    switch (selectedWorkItem.work) {
      case workItemTypes.NEW_REFERRAL:
      case workItemTypes.CHART_NOTES:
      case workItemTypes.REFILL:
      case workItemTypes.MISCELLANEOUS:
      case workItemTypes.UPDATE_LAB_TESTS:
      case workItemTypes.PRIOR_AUTH_INFO:
      case workItemTypes.PRE_ORDER:
        return <CommonWorkItem hasDocument={true} />; // Has the preview document button
      case workItemTypes.EXTEND_ADMINS:
      case workItemTypes.EXTEND_INSURANCE:
      case workItemTypes.EXTEND_LAB_TESTS:
      case workItemTypes.EXPIRED_REFERRAL:
      case workItemTypes.EXTEND_REFERRAL:
      case workItemTypes.BENEFIT_INVESTIGATION:
      case workItemTypes.FOLLOW_UP:
      case workItemTypes.EXTEND_PA:
      case workItemTypes.EXTEND_PA_VISITS:
      case workItemTypes.EXTEND_PA_UNITS:
      case workItemTypes.PRIOR_AUTHORIZATION:
      case workItemTypes.BI_NETWORK:
      case workItemTypes.NETWORK_CHECKING:
      case workItemTypes.PENDING_PA_APPROVAL:
      case workItemTypes.PA_DENIED:
      case workItemTypes.CLINICIAN_REVIEW:
      case workItemTypes.RE_VERIFICATION:
      case workItemTypes.PATIENT_REACTIVATION_REVIEW:
        return <CommonWorkItem />; // Does not have the preview document button
      case workItemTypes.OUTBOUND_CALL:
      case workItemTypes.WELCOME_CALL:
      case workItemTypes.FINANCIAL_ASSIST_CALL:
      case workItemTypes.PENDING_APPT:
      case workItemTypes.EXTEND_SCHEDULE:
      case workItemTypes.SCHEDULE:
        return <OutboundCall />;
      case workItemTypes.DISCONTINUATION_REVIEW:
      case workItemTypes.PLAN_OON:
        return <PayerOON />;
      default:
        return <div>Unsupported Work Item Type</div>;
    }
  };

  return (
    <>
      <WorkItemComponent />
      <WorkItemTimeoutModal />
    </>
  );
};

export default CurrentWorkItem;
