import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback
} from 'react';
import LogRocket from 'logrocket';

// gql
import { connectToGraphqlAPI, getQueryClient } from '@/provider';
import {
  acquireWork,
  reassignWork,
  releaseWork,
  updateWorkItemStatus,
  disposeCall
} from '@/graphql/mutations';
import {
  getWorkItem,
  getWorkItemsByPatient,
  getWorkItemsAssignedToAgent
} from '@/graphql/queries';
import { onUpdateWorkItemStatus } from '@/graphql/subscriptions';

// context
import { UserContext, NotifContext, PatientContext } from './index';

// utils
import {
  mapAttachedData,
  patientItemStatusesToExclude
} from '@/common/Mappers';
import { returnReasons } from '@/constants/dict';
import {
  callWorkTypes,
  groupQueues,
  taskStatus,
  workItemTypes
} from '@/constants/enum';
import {
  getNow,
  getOneYearAgoDate,
  isDateTimeLessThenNow
} from '@/common/DateHelper';
import { workItemStatusMap } from '@/components/Queue/FilterSystem/filterHelper';

export const WorkItemContext = createContext();

const initialFilters = { assignee: [], patientLastName: '' };
const emptySoul = { id: null };

const WorkItemContextProvider = ({ children }) => {
  const { agentId } = useContext(UserContext);
  const { showError, showSuccess, showWarning } = useContext(NotifContext);
  const { goToProfile } = useContext(PatientContext);
  const [selectedWorkItem, setSelectedWorkItem] = useState({ ...emptySoul });
  const [patientWorkItems, setPatientWorkItems] = useState([]);
  const [patientWorkItemsOpened, setPatientWorkItemsOpened] = useState([]);
  const [patientWorkItemsClosed, setPatientWorkItemsClosed] = useState([]);
  const [workItemData, setWorkItemData] = useState();
  const [overDueWorkItemsData, setOverDueWorkItemsData] = useState();
  const [initialPatientId, setInitialPatientId] = useState();
  const [filters, setFilters] = useState(initialFilters);
  const [pendingWorkItems, setPendingWorkItems] = useState([]);
  const [refreshQueueData, setRefreshQueueData] = useState(false);
  const [showLog, setShowLog] = useState(false);
  const [isLogLoading, setIsLogLoading] = useState(false);
  const [workItemLog, setWorkItemLog] = useState(null);
  const [numberOfCallAttempts, setNumberOfCallAttempts] = useState(0);
  const [callHistoryLog, setCallHistoryLog] = useState(null);
  const [showCallHistoryLog, setShowCallHistoryLog] = useState(false);
  const [selectedWorkItem4Log, setSelectedWorkItem4Log] = useState({
    ...emptySoul
  });
  const [showPreOrderDrawer, setShowPreOrderDrawer] = useState(false);
  const [lastCompletedWorkItem, setLastCompletedWorkItem] = useState(
    JSON.parse(localStorage.getItem('lastCompletedWorkItem')) || null
  );
  const [showMinimizedPanel, setShowMinimizedPanel] = useState(false);

  useEffect(() => {
    if (agentId) {
      // get all pedning work items for current agent
      fetchAgentWorkItems();
    }
  }, [agentId]);

  // subscribe to update events to handle some tricky logic
  useEffect(() => {
    const subscription = connectToGraphqlAPI({
      graphqlQuery: onUpdateWorkItemStatus
    }).subscribe({
      next: ({ value }) => {
        const { workItem, success } = value.data.onUpdateWorkItemStatus;
        const workGroups = [groupQueues.INTAKE, groupQueues.BI, groupQueues.PA];

        if (success && workGroups.includes(workItem.groupQueueId)) {
          switch (workItem.workStatus) {
            case taskStatus.COMPLETED:
              // remove items from the top panel if they're done by system behind the scenes
              const updatedPendingItems = pendingWorkItems.filter(
                (item) => item.id !== workItem.id
              );

              // update top panel state
              setPendingWorkItems(updatedPendingItems);

              // update minimized flag, if needed
              if (updatedPendingItems.length === 0) {
                setShowMinimizedPanel(true);
              }
              break;
            default:
              break;
          }
        }
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [pendingWorkItems]);

  // patientId - required
  const getPatientWorkItemsCall = useCallback(
    async (patientId, startDate, endDate) => {
      const variables = {
        patientId,
        startDate: startDate ? startDate : getOneYearAgoDate().toISOString(),
        endDate: endDate ? endDate : new Date().toISOString()
      };
      try {
        const data = await connectToGraphqlAPI({
          graphqlQuery: getWorkItemsByPatient,
          variables
        });
        if (data?.data?.getWorkItemsByPatient.length !== 0) {
          const mappedData = mapAttachedData(data.data.getWorkItemsByPatient);

          // all work items no matter of their status
          setPatientWorkItems(mappedData);

          // only opened work items (that CAN be worked on)
          const openedItems = mappedData.filter(
            (item) => !patientItemStatusesToExclude.includes(item.workStatus)
          );
          setPatientWorkItemsOpened(openedItems);

          // only closed work items (that CAN NOT be worked on)
          const closedItems = mappedData.filter((item) =>
            patientItemStatusesToExclude.includes(item.workStatus)
          );
          setPatientWorkItemsClosed(closedItems);
        } else {
          setPatientWorkItems([]);
          setPatientWorkItemsOpened([]);
          setPatientWorkItemsClosed([]);
        }
        return data;
      } catch (error) {
        console.error('WorkItemContext::getPatientWorkItemsCall err:', error);
        logApiException(error, {
          view: 'WorkItemContext',
          endpoint: 'getWorkItemsByPatient',
          patientId,
          agentId
        });
      }
    },
    [selectedWorkItem?.patientId, refreshQueueData]
  );

  useEffect(() => {
    if (selectedWorkItem?.patientId) {
      const inputObject = {
        patientId: selectedWorkItem?.patientId,
        startDate: getOneYearAgoDate().toISOString(),
        endDate: new Date().toISOString()
      };
      getPatientWorkItemsCall(inputObject);
    }
  }, [initialPatientId]);

  const logApiException = (err, props) => {
    LogRocket.captureMessage('API call exception', {
      extra: {
        errorMessage: err.errors[0]?.message,
        ...props
      }
    });
  };

  const logInfo = (message, props) => {
    LogRocket.captureMessage(message, {
      extra: {
        ...props
      }
    });
  };

  const getPendingWorkItem = (id) =>
    pendingWorkItems.find((workItem) => workItem.id === id);

  const addPendingWorkItem = async (workItem) => {
    const isAcquired = await acquireWorkItem(workItem.id);

    if (isAcquired) {
      const cloned = [...pendingWorkItems];
      const combinedData = {
        ...workItem,
        workHistory: isAcquired[0].workItem.workHistory
      };
      cloned.push(combinedData);
      setPendingWorkItems(cloned);

      // additionally set this item as current (active) work item
      setSelectedWorkItem(combinedData);

      // update call number of attempts
      setNumberOfCallAttempts(countCallAttempts(combinedData));

      // reset minimized flag, if needed
      if (cloned.length > 0) {
        setShowMinimizedPanel(false);
      }
    }
    return isAcquired;
  };

  const deletePendingWorkItem = (id) => {
    const cloned = [...pendingWorkItems];
    const filtered = cloned.filter((workItem) => workItem.id !== id);
    setPendingWorkItems(filtered);

    // set currently active work item
    const newWorkItem =
      filtered.length === 0 ? { ...emptySoul } : filtered[filtered.length - 1];

    // update active work item by index
    setSelectedWorkItem(newWorkItem);

    // this needed to populate workHistory and update number of call attempts
    if (newWorkItem.work === workItemTypes.OUTBOUND_CALL) {
      getWorkItemDetails(newWorkItem.id);
    }

    // update minimized flag value
    setShowMinimizedPanel(filtered.length === 0);
  };

  const goToNextWorkItem = () => {
    const currentIndex = pendingWorkItems.findIndex(
      (workItem) => workItem.id === selectedWorkItem.id
    );

    if (currentIndex > -1) {
      let nextIndex = 0;
      const maxIndex = pendingWorkItems.length - 1;

      if (currentIndex < maxIndex) {
        nextIndex = currentIndex + 1;
      }

      const nextItem = pendingWorkItems[nextIndex];

      setSelectedWorkItem(nextItem);
      goToProfile(nextItem.patientId);

      // this needed to populate workHistory and update number of call attempts
      if (nextItem.work === workItemTypes.OUTBOUND_CALL) {
        getWorkItemDetails(nextItem.id);
      }
    }
  };

  const acquireWorkItem = async (workItemId) => {
    try {
      const data = await getQueryClient({
        query: acquireWork,
        path: 'acquireWork',
        payload: { input: { agentId, workItemId } }
      });

      if (!data?.[0].success) {
        showWarning(data?.[0].details);
        return null;
      }
      return data;
    } catch (error) {
      console.error('WorkItemContext::acquireWorkItem error: ', error);
      logApiException(error, {
        view: 'WorkItemContext',
        endpoint: 'acquireWork',
        id: workItemId,
        agentId
      });
    }
  };

  const reassignWorkItem = async (requestObject) => {
    try {
      const retData = await connectToGraphqlAPI({
        graphqlQuery: reassignWork,
        variables: { input: requestObject }
      });

      if (retData && retData.data && retData.data.reassignWork) {
        logInfo('Work Item status updated successfully', {
          view: 'WorkItemContext',
          endpoint: 'reassignWork',
          agentId: requestObject.agentId,
          workItemId: requestObject.workItemId,
          workStatus: requestObject.status
        });
      }
      return retData;
    } catch (err) {
      logApiException(err, {
        view: 'WorkItemContext',
        endpoint: 'reassignWork',
        agentId: requestObject.agentId,
        workItemId: requestObject.workItemId,
        workStatus: requestObject.status
      });
    }
  };

  const releaseWorkItem = async (input) => {
    try {
      if (input) {
        const data = await getQueryClient({
          query: releaseWork,
          path: 'releaseWork',
          payload: {
            input
          }
        });
        return data;
      }
    } catch (error) {
      console.error('WorkItemContext::releaseWorkItem error: ', error);
      logApiException(error, {
        view: 'WorkItemContext',
        endpoint: 'releaseWork',
        id: input.id,
        agentId,
        workStatus: input.workStatus
      });
    }
  };

  const getWorkStatus = (workItem) => {
    let workStatus = workItem?.workStatus;
    const isParent = isParentWorkItem(workItem.id);
    const previousWorkStatus = workItem?.previousWorkStatus;
    const isWorkItemOverdue = isDateTimeLessThenNow(workItem?.targetTime);

    if (
      isWorkItemOverdue &&
      workStatus !== taskStatus.PENDING &&
      previousWorkStatus !== taskStatus.PENDING
    )
      return (workStatus = taskStatus.OVERDUE);
    if (
      (isParent && workStatus === taskStatus.IN_PROGRESS) ||
      (isParent && workStatus === taskStatus.PENDING)
    ) {
      workStatus = taskStatus.PENDING;
    } else {
      workStatus = taskStatus.OPENED;
    }
    return workStatus;
  };

  const returnWorkItem = async (
    workItem,
    reason = returnReasons.UNKNOWN,
    notes
  ) => {
    const agentName =
      agentId === workItem?.assignedTo ? agentId : workItem?.assignedTo;
    const workStatus = getWorkStatus(workItem);
    const release = await releaseWorkItem({
      agentId: agentName,
      workStatus,
      workItemId: workItem.id,
      reasonsForStatusChange: reason,
      notes
    });

    if (release?.[0]?.success) {
      deletePendingWorkItem(workItem?.id);
      setRefreshQueueData(!refreshQueueData);
    }
  };

  const updateWorkItem = async (requestInput) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: updateWorkItemStatus,
        variables: { input: requestInput }
      });

      if (data?.data?.updateWorkItemStatus) {
        logInfo('Work Item status updated successfully', {
          view: 'WorkItemContext',
          endpoint: 'updateWorkItemStatus',
          agentId: requestInput.agentId,
          workItemId: requestInput.workItemId,
          workStatus: requestInput.status
        });
        return data;
      }
    } catch (err) {
      logApiException(err, {
        view: 'WorkItemContext',
        endpoint: 'updateWorkItemStatus',
        agentId: requestInput.agentId,
        workItemId: requestInput.workItemId,
        workStatus: requestInput.status
      });
    }
  };

  const archiveWorkItem = async (reason) => {
    const requestObject = {
      workItemId: selectedWorkItem.id,
      agentId,
      workStatus: taskStatus.ARCHIVED,
      reasonsForStatusChange: reason?.value?.text
    };
    const data = await updateWorkItem(requestObject);
    if (data?.data?.updateWorkItemStatus?.success) {
      deletePendingWorkItem(selectedWorkItem.id);
      showSuccess('Work Item archived successfully');
      setRefreshQueueData(!refreshQueueData);
      return data;
    }
    showError('Work Item could not be archived');
  };

  /// When rebuilding the button to combined the closing buttons into one also combined the three functions into one.
  const cancelWorkItem = async (reason) => {
    const requestObject = {
      workItemId: selectedWorkItem.id,
      agentId,
      workStatus: taskStatus.CANCELED,
      reasonsForStatusChange: reason?.value?.text
    };
    const data = await updateWorkItem(requestObject);
    if (data?.data?.updateWorkItemStatus?.success) {
      deletePendingWorkItem(selectedWorkItem.id);
      showSuccess('Work Item canceled successfully');
      setRefreshQueueData(!refreshQueueData);
      return data;
    }
    showError('Work Item could not be canceled');
  };

  const fetchAgentWorkItems = async () => {
    try {
      const data = await getQueryClient({
        query: getWorkItemsAssignedToAgent,
        path: 'getWorkItemsAssignedToAgent',
        payload: { agentId }
      });
      // map only PENDING items (items marked as in progress on server side)
      if (data?.length > 0) {
        const pendingItems = mapAttachedData(data);

        if (!!pendingItems.length) {
          const [firstPendingItem] = pendingItems;

          // set initial list at the context level
          setPendingWorkItems(pendingItems);
          setSelectedWorkItem(firstPendingItem);
          setInitialPatientId(firstPendingItem?.patientId);

          // for Outbound Calls we'll prepopulate work history in order to show # of call attempts
          if (firstPendingItem.work === workItemTypes.OUTBOUND_CALL) {
            await getWorkItemDetails(firstPendingItem.id);
          }
        }
      }
    } catch (error) {
      console.error('WorkItemContext::fetchAgentWorkItems error: ', error);
      logApiException(error, {
        view: 'WorkItemContext',
        endpoint: 'getWorkItemsAssignedToAgent',
        agentId
      });
    }
  };

  const getWorkItemDetails = async (id) => {
    const workItemId = id || selectedWorkItem?.id;
    try {
      setIsLogLoading(true);

      const data = await connectToGraphqlAPI({
        graphqlQuery: getWorkItem,
        variables: { workItemId }
      });

      setIsLogLoading(false);

      if (data?.data?.getWorkItem) {
        const workItemData = data.data.getWorkItem;
        setSelectedWorkItem4Log(workItemData);

        const workHistory = workItemData.workHistory;
        const hasHistory = !!workHistory;
        setWorkItemLog(hasHistory ? workHistory : []);
        setCallHistoryLog(
          hasHistory
            ? workHistory.filter((record) => !!record.callAttempted).reverse()
            : []
        );
        setNumberOfCallAttempts(countCallAttempts(workItemData));
      } else setWorkItemLog([]);
    } catch (error) {
      setIsLogLoading(false);
      console.error('WorkItemContext::getWorkItemDetails error: ', error);
      logApiException(error, {
        view: 'WorkItemContext',
        endpoint: 'getWorkItem',
        workItemId
      });
    }
  };

  const updatePhoneCallWorkItem = async (input) => {
    try {
      if (input) {
        const data = await getQueryClient({
          query: disposeCall,
          path: 'disposeCall',
          payload: input
        });
        return data;
      }
    } catch (error) {
      console.error('WorkItemContext::updatePhoneCallWorkItem error: ', error);
      logApiException(error, {
        view: 'WorkItemContext',
        endpoint: 'disposeCall',
        id: input.id,
        agentId,
        workStatus: input.workStatus
      });
    }
  };

  const updatePatientWorkStatus = (id, status) => {
    const cloned = [...patientWorkItems];
    const itemForUpdate = cloned.find((item) => id === item.id);

    if (itemForUpdate) {
      itemForUpdate.workStatus = status;
      // update local state
      setPatientWorkItems(cloned);
    }
  };

  const isParentWorkItem = (workItemId) => {
    const isParent = patientWorkItems.some(
      (workItem) => workItem?.parentWorkItemId === workItemId
    );
    return isParent;
  };

  // this will help to show call attempts w/o need to make extra API calls
  const countCallAttempts = (workItem) => {
    let count = 0;

    if (callWorkTypes.includes(workItem.work)) {
      const workHistory = workItem.workHistory;
      const hasHistory = !!(workHistory && workHistory.length > 0);

      if (hasHistory) {
        const callAttempts = workHistory.filter(
          (item) => item.callAttempted === true
        );
        if (callAttempts.length > 0) {
          return callAttempts.length;
        }
      }
    }

    return count;
  };

  // wrapper around context state so we can re-use this instead of copy-pasta same logic
  const updateLastCompleted = (workItem) => {
    const lastWI = {
      type: workItemStatusMap[workItem.work] || 'Unknown',
      date: getNow(true),
      patientName: `${workItem.patientFirstName} ${workItem.patientLastName}`,
      patientId: workItem.patientId
    };
    localStorage.setItem('lastCompletedWorkItem', JSON.stringify(lastWI));
    setLastCompletedWorkItem(lastWI);
  };

  return (
    <WorkItemContext.Provider
      value={{
        cancelWorkItem,
        updatePatientWorkStatus,
        setWorkItemData,
        workItemData,
        selectedWorkItem,
        setSelectedWorkItem,
        getPatientWorkItemsCall,
        isParentWorkItem,
        setOverDueWorkItemsData,
        overDueWorkItemsData,
        filters,
        setFilters,
        pendingWorkItems,
        setPendingWorkItems,
        getPendingWorkItem,
        addPendingWorkItem,
        deletePendingWorkItem,
        goToNextWorkItem,
        acquireWorkItem,
        returnWorkItem,
        updateWorkItem,
        releaseWorkItem,
        patientWorkItems,
        setPatientWorkItems,
        patientWorkItemsOpened,
        patientWorkItemsClosed,
        reassignWorkItem,
        refreshQueueData,
        setRefreshQueueData,
        archiveWorkItem,
        showLog,
        setShowLog,
        isLogLoading,
        workItemLog,
        getWorkItemDetails,
        numberOfCallAttempts,
        updatePhoneCallWorkItem,
        callHistoryLog,
        showCallHistoryLog,
        setShowCallHistoryLog,
        selectedWorkItem4Log,
        showPreOrderDrawer,
        setShowPreOrderDrawer,
        lastCompletedWorkItem,
        setLastCompletedWorkItem,
        showMinimizedPanel,
        setShowMinimizedPanel,
        updateLastCompleted
      }}
    >
      {children}
    </WorkItemContext.Provider>
  );
};

export default WorkItemContextProvider;
