import React, { useState, useCallback, useEffect, useMemo } from "react";
import { DropDownTree } from "@progress/kendo-react-dropdowns";
import { processTreeData, expandedState, groupAICLocationData } from "./utils";
import "./styles.scss";

const ValueHolder = (props) => {
  return props.item ? (
    <React.Fragment>
      {props.children}
      <span>,&nbsp;{props.item.state}</span>
    </React.Fragment>
  ) : (
    props.children
  );
};

const ListNoData = (props) => (
  <div className="k-nodata">
    <div>
      <h4
        style={{
          fontSize: "1em",
        }}
      >
        <span
          className="k-icon k-i-warning"
          style={{
            fontSize: "2.5em",
          }}
        />
        <br />
        <br />
        {props.children}
      </h4>
    </div>
  </div>
);

const AICLocationDropdown = ({
  data = data,
  selectField = "selected",
  expandField = "expanded",
  dataItemKey = "id",
  textField = "locationName",
  subItemsField = "items",
  itemExpandKey = "state",
  value,
  onChange,
  ...otherProps
}) => {
  const fields = {
    selectField,
    expandField,
    dataItemKey,
    subItemsField,
    itemExpandKey,
  };

  const groupedData = groupAICLocationData(data);
  const [expanded, setExpanded] = useState([
    value?.state ? value.state : groupedData[0] ? groupedData[0].state : [],
  ]);
  const treeData = useMemo(
    () =>
      processTreeData(
        groupedData,
        {
          expanded,
          value,
        },
        fields
      ),
    [expanded, value]
  );

  useEffect(() => {
    if (value && expanded.indexOf(value.state) < 0) {
      setExpanded(expandedState(value, itemExpandKey, expanded));
    }
  }, [value]);

  const onExpandChange = useCallback(
    (event) => {
      setExpanded(expandedState(event.item, itemExpandKey, expanded));
    },
    [expanded]
  );

  const Item = (props) => {
    return (
      <div
        onClick={(e) => handleCustomChange(e, props)}
        onDoubleClick={(e) => e.stopPropagation()}
      >
        {`${props.item.locationName}`}
      </div>
    );
  };

  const handleCustomChange = (e, props) => {
    
    let error = false;
    if (props.item) {
      if (props.item.id) {
        onChange(props.item);
      } else {
        // clicked sub root item
        setExpanded(expandedState(props.item, itemExpandKey, expanded));
        e.stopPropagation();
        return;
      }
    } else {
      error = true;
    }
    // TODO: handle if error is true
  };

  return (
    <DropDownTree
      {...otherProps}
      data={treeData}
      value={value}
      placeholder="Select ..."
      textField={textField}
      dataItemKey={dataItemKey}
      selectField={selectField}
      expandField={expandField}
      onExpandChange={onExpandChange}
      valueHolder={ValueHolder}
      listNoData={ListNoData}
      popupSettings={{
        className: "aic-location",
      }}
      className="aic-location"
      item={Item}
    />
  );
};

export default AICLocationDropdown;
