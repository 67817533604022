import React, { useEffect, useState, useContext } from 'react';

// context
import { TreatmentContext } from '@/context';

// utils
import { parseQuestionaire, showAnswers } from './Assessments.utils';

const Assessments = () => {
  const { snapshotInfusion } = useContext(TreatmentContext);
  const questionnaire = parseQuestionaire(
    snapshotInfusion?.infusionDetail?.stepAssessment?.questionnaire
  );

  const [showQuestionSet1, setShowQuestionSet1] = useState(false);
  const [showQuestionSet2, setShowQuestionSet2] = useState(false);
  const [showQuestionSet3, setShowQuestionSet3] = useState(false);
  const [showQuestionSet4, setShowQuestionSet4] = useState(false);
  const [showQuestionSet5, setShowQuestionSet5] = useState(false);
  const [showQuestionSet6, setShowQuestionSet6] = useState(false);
  const [showQuestionSet7, setShowQuestionSet7] = useState(false);
  const [showQuestionSet8, setShowQuestionSet8] = useState(false);
  const [showQuestionSet9, setShowQuestionSet9] = useState(false);
  const [showQuestionSet10, setShowQuestionSet10] = useState(false);
  const [showQuestionSet11, setShowQuestionSet11] = useState(false);
  const [showQuestionSet12, setShowQuestionSet12] = useState(false);

  useEffect(() => {
    if (
      questionnaire?.question1_1 != null ||
      questionnaire?.question1_2 != null ||
      questionnaire?.question1_3 != null ||
      questionnaire?.question1_4 != null ||
      questionnaire?.question1_5 != null ||
      questionnaire?.question1_6 != null
    ) {
      setShowQuestionSet1(true);
    }
    if (
      questionnaire?.question2_1 != null ||
      questionnaire?.question2_2 != null ||
      questionnaire?.question2_3 != null ||
      questionnaire?.question2_4 != null ||
      questionnaire?.question2_5 != null ||
      questionnaire?.question2_6 != null ||
      questionnaire?.question2_7 != null ||
      questionnaire?.question2_8 != null
    ) {
      setShowQuestionSet2(true);
    }
    if (
      questionnaire?.question3_1 != null ||
      questionnaire?.question3_2 != null ||
      questionnaire?.question3_3 != null ||
      questionnaire?.question3_4 != null ||
      questionnaire?.question3_5 != null ||
      questionnaire?.question3_6 != null ||
      questionnaire?.question3_7 != null ||
      questionnaire?.question3_8 != null ||
      questionnaire?.question3_9 != null ||
      questionnaire?.question3_10 != null ||
      questionnaire?.question3_11 != null ||
      questionnaire?.question3_12 != null
    ) {
      setShowQuestionSet3(true);
    }
    if (
      questionnaire?.question4_1 != null ||
      questionnaire?.question4_2 != null ||
      questionnaire?.question4_3 != null ||
      questionnaire?.question4_4 != null
    ) {
      setShowQuestionSet4(true);
    }
    if (
      questionnaire?.question5_1 != null ||
      questionnaire?.question5_2 != null ||
      questionnaire?.question5_3 != null ||
      questionnaire?.question5_4 != null ||
      questionnaire?.question5_5 != null ||
      questionnaire?.question5_6 != null ||
      questionnaire?.question5_7 != null ||
      questionnaire?.question5_8 != null
    ) {
      setShowQuestionSet5(true);
    }
    if (
      questionnaire?.question6_1 != null ||
      questionnaire?.question6_2 != null ||
      questionnaire?.question6_3 != null ||
      questionnaire?.question6_4 != null ||
      questionnaire?.question6_5 != null ||
      questionnaire?.question6_6 != null ||
      questionnaire?.question6_7 != null ||
      questionnaire?.question6_8 != null ||
      questionnaire?.question6_9 != null ||
      questionnaire?.question6_10 != null
    ) {
      setShowQuestionSet6(true);
    }
    if (
      questionnaire?.question7_1 != null ||
      questionnaire?.question7_2 != null ||
      questionnaire?.question7_3 != null ||
      questionnaire?.question7_4 != null ||
      questionnaire?.question7_5 != null ||
      questionnaire?.question7_6 != null ||
      questionnaire?.question7_7 != null ||
      questionnaire?.question7_8 != null
    ) {
      setShowQuestionSet7(true);
    }
    if (
      questionnaire?.question8_1 != null ||
      questionnaire?.question8_2 != null ||
      questionnaire?.question8_3 != null ||
      questionnaire?.question8_4 != null ||
      questionnaire?.question8_5 != null ||
      questionnaire?.question8_6 != null
    ) {
      setShowQuestionSet8(true);
    }
    if (
      questionnaire?.question9_1 != null ||
      questionnaire?.question9_2 != null ||
      questionnaire?.question9_3 != null ||
      questionnaire?.question9_4 != null ||
      questionnaire?.question9_5 != null ||
      questionnaire?.question9_6 != null ||
      questionnaire?.question9_7 != null ||
      questionnaire?.question9_8 != null
    ) {
      setShowQuestionSet9(true);
    }
    if (
      questionnaire?.question10_1 != null ||
      questionnaire?.question10_2 != null ||
      questionnaire?.question10_3 != null ||
      questionnaire?.question10_4 != null ||
      questionnaire?.question10_5 != null ||
      questionnaire?.question10_6 != null ||
      questionnaire?.question10_7 != null ||
      questionnaire?.question10_8 != null
    ) {
      setShowQuestionSet10(true);
    }
    if (
      questionnaire?.question11_1 != null ||
      questionnaire?.question11_2 != null ||
      questionnaire?.question11_3 != null ||
      questionnaire?.question11_4 != null ||
      questionnaire?.question11_5 != null ||
      questionnaire?.question11_6 != null ||
      questionnaire?.question11_7 != null ||
      questionnaire?.question11_8 != null
    ) {
      setShowQuestionSet11(true);
    }
    if (
      questionnaire?.question12_1 != null ||
      questionnaire?.question12_2 != null ||
      questionnaire?.question12_3 != null ||
      questionnaire?.question12_4 != null
    ) {
      setShowQuestionSet12(true);
    }
  }, [questionnaire]);

  return (
    <>
      <div className='mt-3 ml-0'>
        <b>Assessment Questions:&nbsp;</b>
      </div>
      <div className='infusion-questions mt-3 ml-0'>
        {!!questionnaire && Object.keys(questionnaire).length === 0 ? (
          <span>No Assessments Completed Today</span>
        ) : (
          <div>
            <div>
              {showQuestionSet1 && (
                <>
                  <div className='category'>
                    <b>CONSTITUTIONAL:</b>
                  </div>
                  <div className='question'>Unexplained Fatigue</div>
                  <div className='answer'>
                    {showAnswers(
                      questionnaire.question1_1,
                      questionnaire.question1_2
                    )}
                  </div>
                  <div className='question'>Fever, Chills, Sweats</div>
                  <div className='answer'>
                    {showAnswers(
                      questionnaire.question1_3,
                      questionnaire.question1_4
                    )}
                  </div>
                  <div className='question'>Other</div>
                  <div className='answer'>
                    {showAnswers(
                      questionnaire.question1_5,
                      questionnaire.question1_6
                    )}
                  </div>
                </>
              )}
            </div>
            <div>
              {showQuestionSet2 && (
                <>
                  <div className='category'>
                    <b>CARDIOVASCULAR</b>
                  </div>
                  <div className='question'>High blood pressure</div>
                  <div className='answer'>
                    {showAnswers(
                      questionnaire.question2_1,
                      questionnaire.question2_2
                    )}
                  </div>
                  <div className='question'>Chest pain over heart</div>
                  <div className='answer'>
                    {showAnswers(
                      questionnaire.question2_3,
                      questionnaire.question2_4
                    )}
                  </div>
                  <div className='question'>Previous cardiac issues</div>
                  <div className='answer'>
                    {showAnswers(
                      questionnaire.question2_5,
                      questionnaire.question2_6
                    )}
                  </div>
                  <div className='question'>Other</div>
                  <div className='answer'>
                    {showAnswers(
                      questionnaire.question2_7,
                      questionnaire.question2_8
                    )}
                  </div>
                </>
              )}
            </div>
            <div>
              {showQuestionSet3 && (
                <>
                  <div className='category'>
                    <b>NEUROLOGICAL</b>
                  </div>
                  <div className='question'>Headaches</div>
                  <div className='answer'>
                    {showAnswers(
                      questionnaire.question3_1,
                      questionnaire.question3_2
                    )}
                  </div>
                  <div className='question'>Double Vision</div>
                  <div className='answer'>
                    {showAnswers(
                      questionnaire.question3_3,
                      questionnaire.question3_4
                    )}
                  </div>
                  <div className='question'>Seizure</div>
                  <div className='answer'>
                    {showAnswers(
                      questionnaire.question3_5,
                      questionnaire.question3_6
                    )}
                  </div>

                  <div className='question'>Numbness, Tingling, Tremors</div>
                  <div className='answer'>
                    {showAnswers(
                      questionnaire.question3_7,
                      questionnaire.question3_8
                    )}
                  </div>
                  <div className='question'>Memory loss</div>
                  <div className='answer'>
                    {showAnswers(
                      questionnaire.question3_9,
                      questionnaire.question3_10
                    )}
                  </div>
                  <div className='question'>Other</div>
                  <div className='answer'>
                    {showAnswers(
                      questionnaire.question3_11,
                      questionnaire.question3_12
                    )}
                  </div>
                </>
              )}
            </div>
            <div>
              {showQuestionSet4 && (
                <>
                  <div className='category'>
                    <b>SKIN</b>
                  </div>
                  <div className='question'>Wounds</div>
                  <div className='answer'>
                    {showAnswers(
                      questionnaire.question4_1,
                      questionnaire.question4_2
                    )}
                  </div>
                  <div className='question'>Other</div>
                  <div className='answer'>
                    {showAnswers(
                      questionnaire.question4_3,
                      questionnaire.question4_4
                    )}
                  </div>
                </>
              )}
            </div>
            <div>
              {showQuestionSet5 && (
                <>
                  <div className='category'>
                    <b>EYES/EARS/NOSE/THROAT</b>
                  </div>
                  <div className='question'>Dizziness</div>
                  <div className='answer'>
                    {showAnswers(
                      questionnaire.question5_1,
                      questionnaire.question5_2
                    )}
                  </div>
                  <div className='question'>Sore Throat</div>
                  <div className='answer'>
                    {showAnswers(
                      questionnaire.question5_3,
                      questionnaire.question5_4
                    )}
                  </div>
                  <div className='question'>Vision Problems</div>
                  <div className='answer'>
                    {showAnswers(
                      questionnaire.question5_5,
                      questionnaire.question5_6
                    )}
                  </div>
                  <div className='question'>Other</div>
                  <div className='answer'>
                    {showAnswers(
                      questionnaire.question5_7,
                      questionnaire.question5_8
                    )}
                  </div>
                </>
              )}
            </div>
            <div>
              {showQuestionSet6 && (
                <>
                  <div className='category'>
                    <b>RESPIRATORY</b>
                  </div>
                  <div className='question'>Cough</div>
                  <div className='answer'>
                    {showAnswers(
                      questionnaire.question6_1,
                      questionnaire.question6_2
                    )}
                  </div>
                  <div className='question'>Shortness of breath</div>
                  <div className='answer'>
                    {showAnswers(
                      questionnaire.question6_3,
                      questionnaire.question6_4
                    )}
                  </div>
                  <div className='question'>Wheezing</div>
                  <div className='answer'>
                    {showAnswers(
                      questionnaire.question6_5,
                      questionnaire.question6_6
                    )}
                  </div>
                  <div className='question'>Chest pain, lungs</div>
                  <div className='answer'>
                    {showAnswers(
                      questionnaire.question6_7,
                      questionnaire.question6_8
                    )}
                  </div>
                  <div className='question'>Other</div>
                  <div className='answer'>
                    {showAnswers(
                      questionnaire.question6_9,
                      questionnaire.question6_10
                    )}
                  </div>
                </>
              )}
            </div>
            <div>
              {showQuestionSet7 && (
                <>
                  <div className='category'>
                    <b>GENITAL/URINARY</b>
                  </div>
                  <div className='question'>Pain with urination</div>
                  <div className='answer'>
                    {showAnswers(
                      questionnaire.question7_1,
                      questionnaire.question7_2
                    )}
                  </div>
                  <div className='question'>Problems with frequency</div>
                  <div className='answer'>
                    {showAnswers(
                      questionnaire.question7_3,
                      questionnaire.question7_4
                    )}
                  </div>
                  <div className='question'>Catheter in use</div>
                  <div className='answer'>
                    {showAnswers(
                      questionnaire.question7_5,
                      questionnaire.question7_6
                    )}
                  </div>
                  <div className='question'>Other</div>
                  <div className='answer'>
                    {showAnswers(
                      questionnaire.question7_7,
                      questionnaire.question7_8
                    )}
                  </div>
                </>
              )}
            </div>
            <div>
              {showQuestionSet8 && (
                <>
                  <div className='category'>
                    <b>MUSCULOSKELETAL</b>
                  </div>
                  <div className='question'>Joint Pain</div>
                  <div className='answer'>
                    {showAnswers(
                      questionnaire.question8_1,
                      questionnaire.question8_2
                    )}
                  </div>
                  <div className='question'>Muscle weakness</div>
                  <div className='answer'>
                    {showAnswers(
                      questionnaire.question8_3,
                      questionnaire.question8_4
                    )}
                  </div>
                  <div className='question'>Other</div>
                  <div className='answer'>
                    {showAnswers(
                      questionnaire.question8_5,
                      questionnaire.question8_6
                    )}
                  </div>
                </>
              )}
            </div>
            <div>
              {showQuestionSet9 && (
                <>
                  <div className='category'>
                    <b>GASTROINTESTINAL</b>
                  </div>
                  <div className='question'>Nausea or vomiting</div>
                  <div className='answer'>
                    {showAnswers(
                      questionnaire.question9_1,
                      questionnaire.question9_2
                    )}
                  </div>
                  <div className='question'>Diarrhea</div>
                  <div className='answer'>
                    {showAnswers(
                      questionnaire.question9_3,
                      questionnaire.question9_4
                    )}
                  </div>
                  <div className='question'>Constipation</div>
                  <div className='answer'>
                    {showAnswers(
                      questionnaire.question9_5,
                      questionnaire.question9_6
                    )}
                  </div>
                  <div className='question'>Other</div>
                  <div className='answer'>
                    {showAnswers(
                      questionnaire.question9_7,
                      questionnaire.question9_8
                    )}
                  </div>
                </>
              )}
            </div>
            <div>
              {showQuestionSet10 && (
                <>
                  <div className='category'>
                    <b>PSYCHOLOGICAL</b>
                  </div>
                  <div className='question'>Depression or Anxiety</div>
                  <div className='answer'>
                    {showAnswers(
                      questionnaire.question10_1,
                      questionnaire.question10_2
                    )}
                  </div>
                  <div className='question'>Insomnia</div>
                  <div className='answer'>
                    {showAnswers(
                      questionnaire.question10_3,
                      questionnaire.question10_4
                    )}
                  </div>
                  <div className='question'>Poor appetite</div>
                  <div className='answer'>
                    {showAnswers(
                      questionnaire.question10_5,
                      questionnaire.question10_6
                    )}
                  </div>
                  <div className='question'>Other</div>
                  <div className='answer'>
                    {showAnswers(
                      questionnaire.question10_7,
                      questionnaire.question10_8
                    )}
                  </div>
                </>
              )}
            </div>
            <div>
              {showQuestionSet11 && (
                <>
                  <div className='category'>
                    <b>FEMALE</b>
                  </div>
                  <div className='question'>Currently pregnant</div>
                  <div className='answer'>
                    {showAnswers(
                      questionnaire.question11_1,
                      questionnaire.question11_2
                    )}
                  </div>
                  <div className='question'>Menopause</div>
                  <div className='answer'>
                    {showAnswers(
                      questionnaire.question11_3,
                      questionnaire.question11_4
                    )}
                  </div>
                  <div className='question'>Hysterectomy</div>
                  <div className='answer'>
                    {showAnswers(
                      questionnaire.question11_5,
                      questionnaire.question11_6
                    )}
                  </div>
                  <div className='question'>Other</div>
                  <div className='answer'>
                    {showAnswers(
                      questionnaire.question11_7,
                      questionnaire.question11_8
                    )}
                  </div>
                </>
              )}
            </div>
            <div>
              {showQuestionSet12 && (
                <>
                  <div className='category'>
                    <b>TYSABRI:</b>
                  </div>
                  <div className='question'>
                    The Tysabri TOUCH pre-Infusion checklist has been completed
                    with the patient?
                  </div>
                  <div className='answer'>
                    {showAnswers(
                      questionnaire.question12_1,
                      questionnaire.question12_2
                    )}
                  </div>
                  <div className='question'>
                    The TOUCH Biogen authorization checklist has been verified
                    and confirmed for this patient and treatment date? q
                  </div>
                  <div className='answer'>
                    {showAnswers(
                      questionnaire.question12_3,
                      questionnaire.question12_4
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Assessments;
