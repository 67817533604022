import React, { useContext } from 'react';

// kendo
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { Input } from '@progress/kendo-react-inputs';

// context
import { PatientContext } from '@/context';

// components
import BillingGrid from './BillingGrid';

const BillingSubmit = () => {
  const { getPatientBillingHistory } = useContext(PatientContext);

  const handleSubmit = (dataItem) => {
    if (dataItem?.patientId !== undefined) {
      getPatientBillingHistory(dataItem?.patientId);
    }
  };
  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-4'></div>
        <div className='col-4'>
          <Form
            onSubmit={handleSubmit}
            render={() => (
              <FormElement
                style={{
                  maxWidth: 650
                }}
              >
                <fieldset className={'k-form-fieldset'}>
                  <legend className={'k-form-legend'}>Enter Patient Id:</legend>
                  <div className='mb-1'>
                    <Field
                      name={'patientId'}
                      component={Input}
                      label={'Patient Id'}
                    />
                  </div>
                  <div className='mb-1'></div>
                </fieldset>
                <div className='k-form-buttons'>
                  <button type={'submit'} className='k-button'>
                    Submit
                  </button>
                </div>
              </FormElement>
            )}
          />
        </div>
      </div>
      <BillingGrid />
    </div>
  );
};

export default BillingSubmit;
