export const getAdministerInstructions = adminData => {
  let instructions = "As directed";

  switch (adminData.dosageFrequencyType) {
    case "OVER":
      instructions = `Over ${adminData.dosageDayRange} consecutive days`;
      break;
    case "EVERY":
      instructions = `Every ${adminData.dosageEvery} ${adminData.dosageDateTimeFrameEvery}s for ${adminData.dosageFor} ${adminData.dosageDateTimeFrameFor}s`;
      break;
    case "BOTH":
      instructions = `Over ${adminData.dosageDayRange} days; Every ${adminData.dosageEvery} ${adminData.dosageDateTimeFrameEvery}s for ${adminData.dosageFor} ${adminData.dosageDateTimeFrameFor}s`;
      break;
  }

  return instructions;
};
