import React from 'react';
import cx from 'clsx';

// hooks
import { usePath } from './UserInfo.hooks';

// helper
import { getNow } from '@/common/DateHelper';

// styles
import styles from './UserInfo.module.scss';

const UserInfo = ({ user }) => {
  const { showAsInfusion } = usePath();

  if (showAsInfusion) {
    return (
      <div className={styles.infusionInfo}>
        <span className="dark-label">
          <span
            className={cx(styles.userIcon, 'k-icon', 'k-i-user', 'dark-label')}
          />
          <span>{user?.username}</span>
        </span>
      </div>
    );
  }
  return (
    <div className={styles.generalInfo}>
      <span className={styles.date}>Today's Date: {getNow()}</span>
      <span className="white-label">
        <span
          className={cx(styles.userIcon, 'k-icon', 'k-i-user', 'white-label')}
        />
        <span>{user?.username}</span>
      </span>
    </div>
  );
};

export { UserInfo };
