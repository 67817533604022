import React, { useContext, useState } from "react";

import { Form, Field } from "@progress/kendo-react-form";
import { DatePicker } from "@progress/kendo-react-dateinputs";

import { InputField } from "@/common/Validation";
import { formatDateToAWS } from "@/common/DateHelper";

import { PatientContext } from "@/context/PatientContext";
import { NotifContext } from "@/context/NotifContext";
import { InfusionContext } from "@/context/InfusionContext";

import FormButton from "@/components/common-components/Form/FormButton";

import { updatePatientBucket } from "@/graphql/mutations";
import { connectToGraphqlAPI } from "@/provider";

const PatientMedsAdd = () => {
  const { infusion, setInfusion } = useContext(InfusionContext);
  const { showError, showSuccess } = useContext(NotifContext);
  const { selectedPatientInfo, setSelectedPatientInfo } =
    useContext(PatientContext);

  const [isLoading, setLoading] = useState(false);

  const updateMedsCall = async medicationInfo => {
    try {
      setLoading(true);

      const patientProfile = {
        ...selectedPatientInfo.patientProfile,
        medicationInfo
      };
      const patientId = selectedPatientInfo.patientId;

      const data = await connectToGraphqlAPI({
        graphqlQuery: updatePatientBucket,
        variables: {
          input: { patientId, patientProfile }
        }
      });

      if (data.data?.updatePatientBucket) {
        showSuccess("Patient profile successfully updated");

        // additionally need to update infusion context
        // just in case there's an active infusion in progress
        if (infusion && infusion.nursingProcess) {
          const patientBucket = { ...infusion.patientBucket };
          patientBucket.patientProfile.medicationInfo = [...medicationInfo];
          setInfusion({ ...infusion, patientBucket });
        }
      } else {
        showError(
          "Error in Patient Bucket update action. Please, check logs for details"
        );
      }

      setLoading(false);
    } catch (err) {
      console.error("PatientMedsAdd::updateMedsCall err:", err);
      setLoading(false);
    }
  };

  const handleAddMed = dataItem => {
    const { medication, startDate, discontinuationDate } = dataItem;

    if (!medication) {
      return showError("Medication field is required");
    }

    const medRecord = {
      recordNumber: 1,
      ...dataItem
    };

    if (startDate) {
      medRecord.startDate = formatDateToAWS(startDate);
    }

    if (discontinuationDate) {
      medRecord.discontinuationDate = formatDateToAWS(discontinuationDate);
    }

    try {
      const medicationInfo = selectedPatientInfo.patientProfile?.medicationInfo
        ? [...selectedPatientInfo.patientProfile.medicationInfo, medRecord]
        : [medRecord];

      setSelectedPatientInfo({
        ...selectedPatientInfo,
        patientProfile: {
          ...selectedPatientInfo.patientProfile,
          medicationInfo
        }
      });

      updateMedsCall(medicationInfo);
    } catch (err) {
      console.error("PatientMedsAdd::handleAddMed err: ", err);
      showError("Error: handleAddMed");
    }
  };

  return (
    <Form
      onSubmit={handleAddMed}
      render={formRenderProps => (
        <form onSubmit={formRenderProps.onSubmit}>
          <div className="mb-3">
            <div className="row">
              <div className="col-md-6">
                <Field
                  name="medication"
                  label="Medication"
                  component={InputField}
                />
              </div>
              <div className="col-md-6">
                <Field name="dosage" label="Dosage" component={InputField} />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Field
                  name="frequency"
                  label="Frequency"
                  component={InputField}
                />
              </div>
            </div>
            <div className="row mt-12">
              <div className="col-md-6">
                Start Date:
                <Field name="startDate" component={DatePicker} />
              </div>
              <div className="col-md-6">
                Disc. Date:
                <Field name="discontinuationDate" component={DatePicker} />
              </div>
            </div>
            <div className="row">
              <div className="col-md-1 mt-12">
                <FormButton
                  type="submit"
                  className="k-button blue"
                  loading={isLoading}
                  label="ADD"
                  disabled={!formRenderProps.allowSubmit}
                />
              </div>
            </div>
          </div>
        </form>
      )}
    />
  );
};

export default PatientMedsAdd;
