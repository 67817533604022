import React, { useContext } from 'react';
import pluralize from 'pluralize';

// components
import AwesomeLabel from '@/components/common-components/AwesomeLabel';
import NextItem from './components/ItemActions/NextItem';
import CurrentWorkItem from './components/CurrentWorkItem';
import CurrentWorkItemActions from './components/CurrentWorkItemActions';

// context
import { WorkItemContext } from '@/context';

// styles
import styles from './ActiveWorkItems.module.scss';

const ActiveWorkItemsSticky = () => {
  const { pendingWorkItems } = useContext(WorkItemContext);

  if (!pendingWorkItems || pendingWorkItems.length === 0) return null;

  const pluralizedItems = pluralize(
    'pending work item',
    pendingWorkItems.length,
    true
  );

  return (
    <div className={styles.sticky}>
      <div className={styles.column1}>
        <AwesomeLabel
          label={`You have ${pluralizedItems}`}
          icon='hourglass-clock'
        />
        <div className={styles.nextItem}>
          <NextItem />
        </div>
      </div>
      <div className={styles.column2}>
        <CurrentWorkItem />
      </div>
      <div className={styles.column3}>
        <CurrentWorkItemActions />
      </div>
    </div>
  );
};

export { ActiveWorkItemsSticky };
