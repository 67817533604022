import React, { useState, useContext } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import AwesomeLabel from '@/components/common-components/AwesomeLabel';
// context
import { NotifContext } from '@/context/NotifContext';

const ArchiveOrder = ({ patientId, referralId, agentId, onArchiveClick }) => {
  const { showError } = useContext(NotifContext);

  if (!patientId || !referralId || !agentId) {
    showError('No patientId/referralId/agentId passed');
    return null;
  }

  return (
    <>
      <Button onClick={onArchiveClick} className="k-button red withPadding">
        <AwesomeLabel icon="archive" label="Archive" />
      </Button>
    </>
  );
};

export default ArchiveOrder;
