export const patientProfileTabs = {
  OVERVIEW: 0,
  PATIENT_PROFILE: 1,
  INSURANCE: 2,
  UPLOAD: 3,
  BILLING: 4,
  TREATMENT_HISTORY: 5,
  APPOINTMENTS: 6
};

export const infusionPortalTabs = {
  OVERVIEW: 0,
  PATIENT_INFO: 1,
  PRESCRIBER: 2,
  TREATMENT_HISTORY: 3,
  FAX: 4,
  APPOINTMENTS: 5
};
