import React from "react";
import { PropTypes } from "prop-types";
import { formatDateToDefault } from "@/common/DateHelper";
import { BenefitCheckings } from "./BenefitCheckings";

/* const getFormattedDate = (date) => {
  return date ? formatDateToDefault(date) : date;
};

const getVisitsPerUnit = (details) => {
  if (
    !details?.approvalInfo?.numberOfApprovedUnits ||
    !details?.approvalInfo?.numberOfApprovedVisits
  ) {
    return null;
  }
  return Math.round(
    details?.approvalInfo?.numberOfApprovedVisits /
      details?.approvalInfo?.numberOfApprovedUnits
  );
}; */

export const BenefitCheckingInfo = ({ details }) => {
const{ callCompletionTime, referralId, welcomeCallCompleted } = details;

  return (
    <>
    {!details?.checkings.length
            ? null
            : details?.checkings.map(
                (details, index) => (
                  <BenefitCheckings key={index} details={details} />
                )
              )}
    </>
  );
};

BenefitCheckingInfo.propTypes = {
  details: PropTypes.object,
};
