export const UserRoleTypes = {
  BenefitsInvest: 'Benefits_Invest',
  Billing: 'Billing',
  Calendar: 'Calendar',
  Clinical: 'Clinical',
  ClinicianQueue: 'Clinician Queue',
  CurrentOrders: 'Current Orders',
  Discontinuation: 'Discontinuation',
  Fax: 'FAX',
  FreeDrug: 'Free Drug',
  // Info: "Info",
  Infusion: 'Infusion',
  Insurance: 'Insurance',
  Inventory: 'Inventory',
  Log: 'Log',
  NewOrder: 'New Order',
  NursingQueue: 'Nursing Queue',
  OrderHistory: 'Order History',
  Ordering: 'Ordering',
  // Patient: "Patient",
  // PatientInfo: generally allows to edit patient info and save updates on Patient profile,
  // excluding some critical info - we use "PatientKeyInfo" for that purpose
  PatientInfo: 'PatientInfo',
  // PatientKeyInfo: allows to edit some critical info like DOB etc.
  PatientKeyInfo: 'PatientKeyInfo',
  PatientPrescribers: 'Patient Prescribers',
  PatientWorkQueue: 'Patient Work Queue',
  Payor: 'Payor',
  Prescriber: 'Prescriber',
  PriorAuth: 'Prior_Auth',
  Referral: 'Referral',
  SchedulingQueue: 'Scheduling Queue',
  Shipping: 'Shipping',
  SiteInventory: 'Site Inventory',
  TreatmentHistory: 'Treatment_History',
  Upload: 'Upload'
};

export const getFullAccess = () => {
  const roles = {};

  for (const property in UserRoleTypes) {
    roles[property] = 'Edit Access';
  }

  return roles;
};
