import React, { useState, useEffect, useContext, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import pluralize from 'pluralize';

// kendo
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import { Card, CardBody } from '@progress/kendo-react-layout';
import { Field, Form } from '@progress/kendo-react-form';
import { Input } from '@progress/kendo-react-inputs';

// components
import { MessageDialog } from '@/components/common-components/MessageDialog';
import Preloader from '@/components/common-components/Preloader';
import Badge from '@/components/common-components/Badge';
import { ColumnMenu } from '@/components/common-components/columnMenu';
import WindowDialog from '@/components/common-components/WindowDialog';

// gql
import { connectToGraphqlAPI } from '@/provider';
import {
  getWorkItemsByGroupQueueLite,
  getPatientBucket
} from '@/graphql/queries';
import { acquireWork, reassignWork } from '@/graphql/mutations';

// context
import { UserContext, PatientContext, WorkItemContext } from '@/context';

// utils
import { formatDateToDefault } from '@/common/DateHelper';
import { Constants } from '@/constants';
import { validateInput, DropDownListField } from '@/common/Validation';

const WorkInProgress = (props) => {
  const { user, agentUsers: fullAssignToList } = useContext(UserContext);
  const { setSelectedPatientInfo, setSelectedLocker } =
    useContext(PatientContext);
  const { setSelectedWorkItem } = useContext(WorkItemContext);

  const [loading, setLoading] = useState(true);
  const [listWorkItems, setListWorkItems] = useState([]);
  const [currentAssignTo, setCurrentAssignTo] = useState('');
  const [currentSelectedWorkItem, setCurrentSelectedWorkItem] = useState({});

  const initialDataState = {};

  const [dataState, setDataState] = useState();
  const [resultState, setResultState] = useState(
    process(listWorkItems, initialDataState)
  );

  const [columns, setColumns] = useState([]);

  const [visibleReassignAgentDialog, setVisibleReassignAgentDialog] =
    useState(false);

  const pluralizedItems = pluralize(
    'pending item',
    resultState.data.length,
    true
  );
  const pendingItemsCounter =
    resultState.data.length > 0 ? `(${pluralizedItems})` : '';

  const onDataStateChange = useCallback(
    (e) => {
      setDataState(e.dataState);
      setResultState(process(listWorkItems, e.dataState));
    },
    [listWorkItems]
  );

  const [dialogOption, setDialogOption] = useState({});

  const followUpAssignToGroups = [
    'INTAKE',
    'BI_PA',
    'SCHEDULE',
    'CLINICIAN',
    'INVENTORY',
    'INBOUND',
    'UNASSIGNED',
    'NURSING',
    'SUPERVISOR',
    'ADMIN',
    'BILLING',
    'SALES'
  ];

  const handleReassignClick = (dataItem) => {
    toggleReassignAgentDialog(); // open dialog
    setCurrentSelectedWorkItem(dataItem);
    setCurrentAssignTo(dataItem.assignedTo);
  };
  const handleSubmitUpdateReassign = (dataItem) => {
    const thisAssignedTo = dataItem.newAssignTo
      ? dataItem.newAssignTo.value
      : user.username; // clear the leading char that lets groups sort first

    setVisibleReassignAgentDialog(false); // close dialog

    // input ReassignWorkInput {
    //   currentAgentId: String!
    //   reassignedToAgentId: String
    //   workItemId: String!
    //   reassignedToQueueId: GroupQueue
    // }
    const requestObject = {
      currentAgentId: currentSelectedWorkItem.assignedTo,
      reassignedToAgentId: thisAssignedTo,
      workItemId: currentSelectedWorkItem.id
    };

    updateWorkItemCall(requestObject);
  };
  const updateWorkItemCall = async (requestObject) => {
    try {
      const retData = await connectToGraphqlAPI({
        graphqlQuery: reassignWork,
        variables: { input: requestObject }
      });

      if (retData && retData.data && retData.data.reassignWork) {
        // popup notice completed successfully
        setDialogOption({
          title: 'Update Agent Assignment',
          message: 'WorkItem saved sucessfully',
          showDialog: true
        });

        fetchWorkInProgressData(); // reloads the grid
      }
    } catch (err) {
      console.log('StatusBar::updateFollowupEventCall err:', err);
      setDialogOption({
        title: 'Followup: Update Followup',
        message: 'Error: updateFollowupEventCall', // err.errors[0].message, //'Error',
        showDialog: true
      });
    }
  };

  /// //Validations/////
  const initialForm = () => {
    const initialObject = {};
    Object.keys(reassignAgentForm).forEach((key) => {
      initialObject[key] = reassignAgentForm[key].value;
    });
    return initialObject;
  };

  const reassignAgentForm = {
    currentAssignTo: {
      value: currentAssignTo
    },
    newAssignTo: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          referralId: { ...reassignAgentForm.newAssignTo, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    }
  };

  const workItemPriority = [
    { label: '01-Standard', value: 1 },
    { label: '02-Standard', value: 2 },
    { label: '03-Standard', value: 3 },
    { label: '04-High', value: 4 },
    { label: '05-High', value: 5 },
    { label: '06-High', value: 6 },
    { label: '07-High', value: 7 },
    { label: '08-Critical', value: 8 },
    { label: '09-Critical', value: 9 },
    { label: '10-Critical', value: 10 }
  ];

  const getColumns = () => {
    const cols = [
      { field: 'agentName', title: 'Created By' },
      { field: 'assignedTo', title: 'Assigned To Id' },
      {
        field: 'patientName',
        title: 'Patient Name',
        cell: render.patientNameCell
      },
      { field: 'priorityDisplay', title: 'Priority' },
      { field: 'description', title: 'Description', cell: render.customCell },
      { field: 'expDate', title: 'Exp Date' }
    ];
    if (props?.workItemSelect === 1) {
      cols.push({
        field: '',
        title: 'Reassign Agent',
        cell: render.customCellReassignAgent
      });
    }

    return cols;
  };

  // MAIN INITIATOR
  useEffect(() => {
    if (user) {
      fetchWorkInProgressData();
    }
    setColumns(getColumns());
  }, []);

  useEffect(() => {
    if (!loading) {
      setDataState(initialDataState);
      setResultState(process(listWorkItems, initialDataState));
    }
  }, [listWorkItems]);

  const fetchWorkInProgressData = async () => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getWorkItemsByGroupQueueLite,
        variables: { groupQueueId: 'INTAKE', lightWeight: true }
      });

      // const data = await connectToGraphqlAPI({
      //   graphqlQuery: getWorkItemsByGroupQueue,
      //   variables: { groupQueueId: "INTAKE", lightWeight: false }
      // });

      if (
        data &&
        data.data &&
        data.data.getWorkItemsByGroupQueue &&
        data.data.getWorkItemsByGroupQueue.length > 0
      ) {
        setLoading(false);

        const listWorkItemsData = data.data.getWorkItemsByGroupQueue
          .map((item) => {
            item.patientName = `${item.patientLastName}, ${item.patientFirstName}`;
            item.agentName = `${item.agentLastName}, ${item.agentFirstName}`;
            item.expDate = formatDateToDefault(item.targetTime);
            item.priorityDisplay = item.priority
              ? workItemPriority.find((x) => x.value === item.priority)?.label
              : '00-No Priority';
            return item;
          })
          .sort((a, b) => (b.priority > a.priority ? 1 : -1)); // sort by priority DESC
        setListWorkItems(listWorkItemsData);
      }
      setLoading(false);
    } catch (err) {
      console.log('WorkInProgress::getWorkItemsByGroupQueue error:', err);
      setLoading(false);
    }
  };

  const onRowClickHandle = (e) => {
    setSelectedWorkItem(e.dataItem.workItems);
    if (e.dataItem.assignedTo) {
      acquireWorkApiCall(
        {
          agentId: e.dataItem.assignedTo,
          workItemId: e.dataItem.id
        },
        e.dataItem.patientId,
        e.dataItem.currentStage
      );
    }
  };

  const acquireWorkApiCall = async (requestObject, patientId, currentStage) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: acquireWork,
        variables: { input: requestObject }
      });

      if (data && data.data && data.data.acquireWork) {
        if (
          data.data.acquireWork.success // &&
          // data.data.acquireLocker.lockerId
        ) {
          setSelectedLocker(data.data.acquireWork.lockerId);
          getPatientBucketData(patientId, currentStage);
        } else if (data.data.acquireWork.details) {
          setDialogOption({
            title: 'Acquire Work: Error 1',
            message: data.data.acquireWork.details,
            showDialog: true
          });
        } else {
          setDialogOption({
            title: 'Acquire Work: Error 2',
            message: 'Error acquireWorkApiCall with no {details} available',
            showDialog: true
          });
        }
      } else if (data.data.acquireWork.details) {
        setDialogOption({
          title: 'Acquire Work: Error 3',
          message: data.data.acquireWork.details,
          showDialog: true
        });
      }
    } catch (err) {
      console.log('WorkInProgress::acquireWorkApiCall err:', err);
      setDialogOption({
        title: 'Work In Progress',
        message: 'Fatal Error',
        showDialog: true
      });
    }
  };

  const getPatientBucketData = async (patientId, currentStage) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getPatientBucket,
        variables: { patientId }
      });

      if (data && data.data && data.data.getPatientBucket) {
        setSelectedPatientInfo(data.data.getPatientBucket);
        props.history.push('/patient-portal', { searchType: currentStage });
      } else {
        setDialogOption({
          title: 'Work In Progress: Get Patient',
          message: 'No Patient Record Found',
          showDialog: true
        });
      }
    } catch (err) {
      console.log('WorkInProgress::getPatientBucketData err:', err);
    }
  };

  const toggleReassignAgentDialog = () => {
    setVisibleReassignAgentDialog(!visibleReassignAgentDialog);
  };

  const render = {
    patientNameCell: ({ dataItem: { inProgram, patientName } }) => (
      <td>
        {inProgram && <Badge type='info' text='STAT ViiV' />}
        {patientName}
      </td>
    ),
    customCell: ({ dataItem: { workStatus, description } }) => (
      <td>
        {workStatus === 'OVERDUE' && <Badge text='ESCALATED' />}
        {description}
      </td>
    ),
    customCellReassignAgent: ({ dataItem }) => (
      <td>
        <button
          type='button'
          title='Reassign Agent'
          onClick={() => {
            handleReassignClick(dataItem);
          }}
        >
          Reassign Agent
        </button>
      </td>
    )
  };

  return (
    <div>
      <Card>
        <CardBody>
          <div className='container-fluid'>
            {dialogOption.showDialog && (
              <MessageDialog dialogOption={dialogOption} />
            )}
            <div className='row my-3'>
              <div className='col-12 col-lg-12 work-in-progress'>
                <div className='a-grid__header'>
                  <div>Work In Progress {pendingItemsCounter}</div>
                </div>
                <Preloader show={loading}>
                  <Grid
                    onRowClick={(e) => {
                      onRowClickHandle(e);
                    }}
                    style={{ cursor: 'pointer', minHeight: 250 }}
                    className='queue_grid'
                    data={{ data: resultState.data }}
                    sortable
                    onDataStateChange={onDataStateChange}
                    {...dataState}
                  >
                    {columns.map((column, index) => {
                      return (
                        <Column
                          field={column.field}
                          title={column.title}
                          key={index}
                          width={column.minWidth}
                          columnMenu={ColumnMenu}
                          cell={column.cell || null}
                        />
                      );
                    })}
                  </Grid>
                </Preloader>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
      <WindowDialog
        title='Reassign Work Item'
        width={500}
        height={400}
        top={0}
        showDialog={visibleReassignAgentDialog}
        onClose={toggleReassignAgentDialog}
      >
        <Form
          initialValues={initialForm()}
          onSubmit={handleSubmitUpdateReassign}
          render={(formRenderProps) => (
            <form
              onSubmit={formRenderProps.onSubmit}
              className='k-form pl-3 pr-3 pt-1'
            >
              <div className='col-md-12 mt-3'>
                <Field component={Input} name='currentAssignTo' disabled />
              </div>
              <div className='col-md-12'>
                <Field
                  component={DropDownListField}
                  data={fullAssignToList}
                  name='newAssignTo'
                  textField='text'
                  dataItemKey='text'
                  valueField='value'
                  label='ASSIGN TO:'
                  style={{ width: 400 }}
                  validator={reassignAgentForm.newAssignTo.inputValidator}
                />
              </div>
              <div className='row p-3'>
                <div className='col-12' style={{ textAlign: 'center' }}>
                  <button type='submit' className='k-button pageButton'>
                    Update
                  </button>
                </div>
              </div>
            </form>
          )}
        />
      </WindowDialog>
    </div>
  );
};

export default withRouter(WorkInProgress);
