import React, { useContext } from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import AwesomeLabel from "@/components/common-components/AwesomeLabel";
import WarningBlock from "@/components/common-components/WarningBlock";
import { formatDateToDefault } from "@/common/DateHelper";
import { PatientContext } from "@/context/PatientContext";
import { InfusionContext } from "@/context/InfusionContext";
import { NotifContext } from "@/context/NotifContext";
import { updatePatientBucket } from "@/graphql/mutations";
import { connectToGraphqlAPI } from "@/provider";

const PatientAllergiesLog = props => {
  const { infusion, setInfusion } = useContext(InfusionContext);
  const { showError, showSuccess } = useContext(NotifContext);
  const { selectedPatientInfo, setSelectedPatientInfo } =
    useContext(PatientContext);

  // RENDER HELPERS
  const headerDrugCell = () => {
    return <AwesomeLabel icon="capsules" label="Drug Name" />;
  };

  const headerReactionCell = () => {
    return <AwesomeLabel icon="thermometer" label="Reaction" />;
  };

  const headerDateCell = () => {
    return <AwesomeLabel icon="calendar" label="Entered Date" />;
  };

  const allergenCell = dataItem => {
    return renderCellValue(dataItem, "allergen");
  };

  const reactionCell = dataItem => {
    return renderCellValue(dataItem, "reaction");
  };

  const dateCell = dataItem => {
    return renderCellValue(dataItem, "entered");
  };

  const renderCellValue = (dataItem, itemField) => {
    return <td>{dataItem?.dataItem?.[itemField] || 0}</td>;
  };

  // ACTION HANDLERS
  const updateAllergiesCall = async allergyInfo => {
    try {
      const patientProfile = {
        ...selectedPatientInfo.patientProfile,
        allergyInfo
      };
      const patientId = selectedPatientInfo.patientId;

      const data = await connectToGraphqlAPI({
        graphqlQuery: updatePatientBucket,
        variables: {
          input: { patientId, patientProfile }
        }
      });

      if (data.data?.updatePatientBucket) {
        showSuccess("Patient profile successfully updated");

        // if there's an active infusion - we need to update allergies there as well
        if (infusion && infusion.nursingProcess) {
          const patientBucket = { ...infusion.patientBucket };
          patientBucket.patientProfile.allergyInfo = [...allergyInfo];
          setInfusion({ ...infusion, patientBucket });
        }
      } else {
        showError(
          "Error in Patient Bucket update action. Please, check logs for details"
        );
      }
    } catch (err) {
      console.error("PatientAllergiesLog::updateAllergiesCall err:", err);
    }
  };

  const handleDeleteClick = ({ dataIndex }) => {
    // clone existing list to make the mutation next
    const allergyInfo = [...selectedPatientInfo.patientProfile.allergyInfo];

    // remove selected element from the list
    allergyInfo.splice(dataIndex, 1);

    // update patient info (UI)
    setSelectedPatientInfo({
      ...selectedPatientInfo,
      patientProfile: {
        ...selectedPatientInfo.patientProfile,
        allergyInfo
      }
    });

    // update patient bucket (server)
    updateAllergiesCall(allergyInfo);
  };

  const actionCell = props => {
    return (
      <td>
        <span className="remove-label" onClick={() => handleDeleteClick(props)}>
          <AwesomeLabel label="Remove" icon="xmark" />
        </span>
      </td>
    );
  };

  const data =
    selectedPatientInfo?.patientProfile?.allergyInfo &&
    selectedPatientInfo.patientProfile.allergyInfo.length > 0
      ? selectedPatientInfo.patientProfile.allergyInfo.map(allergyRecord => {
          return {
            ...allergyRecord,
            entered: formatDateToDefault(allergyRecord.entered)
          };
        })
      : [];

  if (!data || data.length === 0)
    return <WarningBlock message="No records available" />;

  return (
    <Grid data={data} className="infusion-grid f10px">
      <Column
        field="allergen"
        title="ALLERGEN"
        headerCell={headerDrugCell}
        cell={allergenCell}
      />
      <Column
        field="reaction"
        title="REACTION"
        headerCell={headerReactionCell}
        cell={reactionCell}
      />
      <Column
        field="entered"
        title="ENTERED DATE"
        headerCell={headerDateCell}
        cell={dateCell}
      />
      {props.editable && <Column title="" cell={actionCell} width={75} />}
    </Grid>
  );
};

export default PatientAllergiesLog;
