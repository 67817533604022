import React from 'react';
import AwesomeLabel from './AwesomeLabel';

const typeCSS = {
  alert: ['darkred', 'brake-warning'],
  warning: ['#fe6a00', 'hexagon-exclamation'],
  success: ['green', 'badge-check'],
  info: ['#005282', 'square-info']
};

const Alert = ({ type = 'alert', children }) => {
  if (!children) {
    console.error('Alert component must have typed text.');
    return null;
  }
  if (!Object.prototype.hasOwnProperty.call(typeCSS, type)) {
    console.warn('Alert component must have valid `type` as a property. Using default one');
    type = 'alert'; // assign default type
  }

  const [color, image] = typeCSS[type];

  return (
    <div style={{ color }}>
      <AwesomeLabel icon={image} label={children} />
    </div>
  );
};

export default Alert;
