import React, { useState } from "react";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import BillingSubmit from "./BillingSubmit";

const BillingAdmin = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleSelect = e => {
    setSelectedTab(e.selected);
  };

  return (
    <div className="portal-container">
      <div className="row" style={{ margin: "0" }}>
        <div className="col-12 main-content p-0">
          <div className="container-fluid navBar1">
            <TabStrip selected={selectedTab} onSelect={handleSelect}>
              <TabStripTab
                contentClassName="navBar2"
                title="Billing Submission"
              >
                <div className="tabText">
                  <BillingSubmit />
                </div>
              </TabStripTab>
              {/* <TabStripTab contentClassName="navBar2" title="Product Details">
								<div className="tabText">
									<ProductDetails />
								</div>
							</TabStripTab>
							<TabStripTab contentClassName="navBar2" title="Pre-Medications">
								<div className="tabText">
									<PreMedications />
								</div>
							</TabStripTab> */}
            </TabStrip>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingAdmin;
