import React, { useContext } from 'react';
import styled from 'styled-components';

// common components
import AwesomeLabel from '@/components/common-components/AwesomeLabel';

// partials
import PatientSurveyCheckButton from './PatientSurveyCheckButton';

// context
import { InfusionContext } from '@/context/InfusionContext';

const BannerWrapper = styled.div`
  height: 50px;
  font-size: 18px;
  margin-right: 10px;
  border: 1px solid #005282;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  &.complete {
    background-color: green;
    color: white;
  }

  &.pending,
  &.skipped {
    background-color: #ffdebb;
    color: #005282;
  }
`;

const PatientSurveyBanner = () => {
  const { infusion } = useContext(InfusionContext);

  if (!infusion?.isSurveyRequired) {
    return null;
  }

  // hide it, don't be such annoying thing
  if (infusion.isSurveyReviewed && infusion.isSurveyComplete) {
    return null;
  }

  const bannerSettings = {
    isComplete: {
      cssClass: 'complete',
      icon: 'badge-check',
      label: (
        <PatientSurveyCheckButton label="Patient survey has been completed by patient" />
      )
    },
    isRequired: {
      cssClass: 'pending',
      icon: 'square-exclamation',
      label: <PatientSurveyCheckButton label="Patient survey is due today" />
    },
    isSkipped: {
      cssClass: 'skipped',
      icon: 'forward',
      label: 'Patient survey has been skipped by provider'
    }
  };

  let settings = bannerSettings.isRequired;

  const isComplete = infusion.isSurveyComplete;
  const isSkipped = infusion.isSurveySkipped;

  if (isComplete) settings = bannerSettings.isComplete;
  if (isSkipped) settings = bannerSettings.isSkipped;

  return (
    <BannerWrapper className={settings.cssClass}>
      <AwesomeLabel icon={settings.icon} label={settings.label} />
    </BannerWrapper>
  );
};

// Display Names
BannerWrapper.displayName = 'BannerWrapper';

export default PatientSurveyBanner;
