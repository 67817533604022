import React, { useContext, useState } from 'react';
import * as moment from 'moment';

// kendo
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { Dialog } from '@progress/kendo-react-dialogs';

// components
import FormButton from '@/components/common-components/Form/FormButton';

// utils
import {
  DatePickerField,
  InputField,
  validateInput
} from '@/common/Validation';
import { Constants } from '@/constants';
import { convertToE164, convertToUS } from '@/common/PhoneNumberConverter';
import { formatDateToAWS } from '@/common/DateHelper';

// gql
import { connectToGraphqlAPI } from '@/provider';
import { updatePatientKeyInfo } from '@/graphql/mutations';

// context
import {
  UserRoleTypes,
  UserContext,
  PatientContext,
  NotifContext
} from '@/context';

const PatientInfoUpdate = () => {
  const { agent, canEdit } = useContext(UserContext);
  const { selectedPatientInfo, setSelectedPatientInfo } =
    useContext(PatientContext);
  const { showError, showSuccess } = useContext(NotifContext);

  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(false);

  const [selectedPatientIsActive, setSelectedPatientIsActive] = useState();

  const toggleForm = () => setShowForm(!showForm);

  const patientInfoForm = {
    firstName: {
      value: selectedPatientInfo?.patientFirstName || null,
      inputValidator: (value) => {
        return validateInput({
          firstName: { ...patientInfoForm.firstName, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        },
        {
          type: 'alpha',
          message: Constants.ErrorMessage.Alpha_Required
        },
        {
          type: 'maxLength',
          length: 40,
          message: Constants.ErrorMessage.maxLength_REQUIRED
        }
      ]
    },
    lastName: {
      value: selectedPatientInfo?.patientLastName || null,
      inputValidator: (value) => {
        return validateInput({
          lastName: { ...patientInfoForm.lastName, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.lastname_REQUIRED
        },
        {
          type: 'alpha',
          message: Constants.ErrorMessage.Alpha_Required
        }
      ]
    },
    dateOfBirth: {
      value: selectedPatientInfo?.dob
        ? new Date(
            moment(selectedPatientInfo?.dob).add(
              new Date().getTimezoneOffset(),
              'minutes'
            )
          )
        : new Date(moment().subtract(5, 'year')),
      inputValidator: (value) => {
        return validateInput({
          dateOfBirth: { ...patientInfoForm.dateOfBirth, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.dateOfBirth_REQUIRED
        },
        {
          type: 'dateRange',
          message: Constants.ErrorMessage.dateOneHundredAndTwentyYearsPast,
          minDate: moment().subtract(120, 'year'),
          maxDate: moment().add(50, 'year')
        }
      ]
    },
    phoneNumber: {
      value: selectedPatientInfo?.homePhoneNumber
        ? convertToUS(selectedPatientInfo.homePhoneNumber)
        : null,
      inputValidator: (value) => {
        return validateInput({
          phoneNumber: { ...patientInfoForm.phoneNumber, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.PhoneNumber_REQUIRED
        }
      ]
    }
  };

  const initialForm = () => {
    let initialObject = {};
    Object.keys(patientInfoForm).forEach((key) => {
      initialObject[key] = patientInfoForm[key].value;
    });
    return initialObject;
  };

  const handleSubmit = (dataItem) => {
    setLoading(true);

    const patientKeyInfo = {
      patientId: selectedPatientInfo.patientId,
      patientFirstName: dataItem.firstName,
      patientLastName: dataItem.lastName,
      dob: formatDateToAWS(dataItem.dateOfBirth),
      homePhoneNumber: dataItem.phoneNumber
        ? convertToE164(dataItem.phoneNumber)
        : null
    };

    updatePatientCall(patientKeyInfo);
  };

  const updatePatientCall = async (patientKeyInfo) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: updatePatientKeyInfo,
        variables: { agentId: agent.agentId, patientKeyInfo }
      });

      if (data.data?.updatePatientKeyInfo?.statusCode === '400') {
        showError(data.data.updatePatientKeyInfo.message);
      } else if (data.data?.updatePatientKeyInfo?.statusCode === '200') {
        showSuccess('Patient info updated sucessfully');

        // update info at the context level
        setSelectedPatientInfo({ ...selectedPatientInfo, ...patientKeyInfo });
      }

      setLoading(false);
      toggleForm();
    } catch (err) {
      console.error('PatientInfo::updatePatientCall err:', err);
      showError('Error: check updatePatientCall method');
      setLoading(false);
    }
  };

  // only admins can see this
  if (!canEdit(UserRoleTypes.PatientKeyInfo)) return null;

  return (
    <div className='pl-3 mt-3 mb-3'>
      <div className='row'>
        <span className='help-link' onClick={toggleForm}>
          Update Required Fields for Patient
        </span>
      </div>
      {showForm && (
        <Form
          onSubmit={handleSubmit}
          initialValues={initialForm()}
          render={() => (
            <Dialog title='Update patient info' onClose={toggleForm}>
              <FormElement className='k-form pl-3 pr-3 pt-1'>
                <div className='row mt-12'>
                  <div className='col-md-6'>
                    <Field
                      name='firstName'
                      label='Patient First Name'
                      component={InputField}
                      validator={patientInfoForm.firstName.inputValidator}
                    />
                  </div>
                  <div className='col-md-6'>
                    <Field
                      name='lastName'
                      label='Patient Last Name'
                      component={InputField}
                      validator={patientInfoForm.lastName.inputValidator}
                    />
                  </div>
                </div>

                <div className='row mt-12'>
                  <div className='col-md-1 mt-12'>DOB:</div>
                  <div className='col-md-5 mt-10'>
                    <Field
                      name='dateOfBirth'
                      label=''
                      component={DatePickerField}
                      validator={patientInfoForm.dateOfBirth.inputValidator}
                      min={
                        new Date(
                          patientInfoForm.dateOfBirth.validations[1].minDate
                        )
                      }
                      max={
                        new Date(
                          patientInfoForm.dateOfBirth.validations[1].maxDate
                        )
                      }
                    />
                  </div>
                  <div className='col-md-6'>
                    <Field
                      name='phoneNumber'
                      label='Primary Phone'
                      component={InputField}
                      validator={patientInfoForm.phoneNumber.inputValidator}
                    />
                  </div>
                </div>
                <hr />
                <div className='row pt-1'>
                  <div className='col-12'>
                    <FormButton
                      type='submit'
                      loading={loading}
                      label='Update'
                      className='k-button pageButton'
                      disabled={!canEdit(UserRoleTypes.PatientInfo)}
                    />
                  </div>
                </div>
              </FormElement>
            </Dialog>
          )}
        />
      )}
    </div>
  );
};

export default PatientInfoUpdate;
