import React, { useState, useContext, useEffect } from 'react';
import { Grid } from '@progress/kendo-react-grid';
import { GridColumn as Column } from '@progress/kendo-react-grid/dist/npm/GridColumn';
import { Button } from '@progress/kendo-react-buttons';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Form, Field } from '@progress/kendo-react-form';
import {
  InputField,
  validateInput,
  DropDownListField
} from '@/common/Validation';

import WindowDialog from '@/components/common-components/WindowDialog';

import { connectToGraphqlAPI } from '@/provider';
import { listInsurerAICs } from '@/graphql/queries';

import { addInsurer } from '@/graphql/mutations';
import { states } from '@/common/states';
import { MaskedPhoneInput } from '@/common/MaskInput';
import { Constants } from '@/constants';
import { convertToE164 } from '@/common/PhoneNumberConverter';
import { UserContext } from '@/context/UserContext';

const InsurerAdmin = () => {
  const { user } = useContext(UserContext);

  const [showAddInsurer, setShowAddInsurer] = useState(false);
  const [dialogOption, setDialogOption] = useState({});
  const [insurerGridData, setInsurerGridData] = useState([]);

  const insuranceTypes = ['Commercial', 'Medicaid', 'Medicare'];

  const listInsurerDataCall = async () => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: listInsurerAICs
      });

      if (
        data &&
        data.data &&
        data.data.listInsurerAICs &&
        data.data.listInsurerAICs.items
      ) {
        const filtered = data.data.listInsurerAICs.items
          .map((item) => ({
            ...item
          }))
          .sort((a, b) => (a.name > b.name ? 1 : -1));

        setInsurerGridData(filtered);
      } else {
        setDialogOption({
          title: 'Patient Insurance',
          message: 'No Insurance Companies Found',
          showDialog: true
        });
      }
    } catch (err) {
      console.log('PatientInsurance::listInsurerDataCall err:', err);
      setDialogOption({
        title: 'Patient Insurance',
        message: 'Error: listInsurerDataCall',
        showDialog: true
      });
    }
  };

  // MAIN INITIATOR
  useEffect(() => {
    listInsurerDataCall();
  }, []);

  const insurerInfoForm = {
    name: {
      value: null,
      inputValidator: (value) => {
        return validateInput({
          name: { ...insurerInfoForm.name, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        },
        {
          type: 'alpha',
          message: Constants.ErrorMessage.Alpha_Required
        },
        {
          type: 'maxLength',
          length: 40,
          message: Constants.ErrorMessage.Alpha_Required
        }
      ]
    },
    insurerType: {
      // search both abbreviation AND name
      value: null,
      inputValidator: (value) => {
        return validateInput({
          insurerType: { ...insurerInfoForm.insurerType, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        }
      ]
    },
    taxId: {
      value: null,
      inputValidator: (value) => {
        return validateInput({
          taxId: { ...insurerInfoForm.taxId, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        },
        {
          type: 'maxLength',
          length: 40,
          message: Constants.ErrorMessage.maxLength_REQUIRED
        }
      ]
    },
    mainPhoneNumber: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          mainPhoneNumber: { ...insurerInfoForm.mainPhoneNumber, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.PhoneNumber_REQUIRED
        }
      ]
    },

    streetName: {
      value: null,
      inputValidator: (value) => {
        return validateInput({
          streetName: { ...insurerInfoForm.streetName, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.Street_REQUIRED
        }
      ]
    },
    state: {
      // search both abbreviation AND name
      value: null,
      inputValidator: (value) => {
        return validateInput({ state: { ...insurerInfoForm.state, value } });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.State_REQUIRED
        }
      ]
    },
    city: {
      value: null,
      inputValidator: (value) => {
        return validateInput({ city: { ...insurerInfoForm.city, value } });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.City_REQUIRED
        },
        {
          type: 'alpha',
          message: Constants.ErrorMessage.Alpha_Required
        }
      ]
    },
    zip: {
      value: null,
      inputValidator: (value) => {
        return validateInput({ zip: { ...insurerInfoForm.zip, value } });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.Zip_REQUIRED
        },
        {
          type: 'minLength',
          length: 5,
          message: Constants.ErrorMessage.minLength_REQUIRED
        },
        {
          type: 'maxLength',
          length: 5,
          message: Constants.ErrorMessage.maxLength_REQUIRED
        }
      ]
    }
  };

  const initialForm = () => {
    const initialObject = {};
    Object.keys(insurerInfoForm).forEach((key) => {
      initialObject[key] = insurerInfoForm[key].value;
    });
    return initialObject;
  };

  const handleAddNewSubmit = (dataItem) => {
    const requestObject = {
      name: dataItem?.name ? dataItem?.name : '',
      insurerType: dataItem.insurerType ? dataItem.insurerType : '',
      // mainPhoneNumber: null,
      mainPhoneNumber: dataItem.mainPhoneNumber
        ? convertToE164(dataItem.mainPhoneNumber)
        : null, // note AWS valiates AWSPhone so things like "+10000000000" and "+11234567890" are rejected as invalid...
      taxId: dataItem?.taxId ? dataItem?.taxId : '',
      billingAddress: {
        streetName: dataItem?.streetName ? dataItem?.streetName : '',
        city: dataItem?.city ? dataItem?.city : '',
        county: '',
        state: dataItem.state?.abbreviation ? dataItem.state.abbreviation : '',
        zip: dataItem?.name ? dataItem?.name : ''
      }
    };
    addInsurerCall(requestObject);
    toggleAddNewInsurer();
  };

  const addInsurerCall = async (requestObject) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: addInsurer,
        variables: { insurer: requestObject, agentId: user.username }
      });
      if (data && data.data && data.data.createReferralOrder) {
        setDialogOption({
          title: 'Insurer',
          message: 'New Insurer saved sucessfully.',
          showDialog: true
        });
        //   //setStatusUpdate(!statusUpdate);
      }
      listInsurerDataCall(); // reload InsuranceCompany grid
    } catch (err) {
      console.log('dev addInsurerCall err', err);
      setDialogOption({
        title: 'Referral',
        message: err.errors[0].message, // 'Error',
        showDialog: true
      });
      if (err && err.errors && err.errors.length > 0 && err.errors[0].message) {
        //
      }
    }
  };

  const toggleAddNewInsurer = () => {
    // listAgentsCall(); // initialize
    setShowAddInsurer(!showAddInsurer);
  };

  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col ml-1 mr-1'>
          <div className='row'>
            <div className='col-md-2 mt-12'>
              <Button
                type='button'
                look='outline'
                icon='plus'
                onClick={toggleAddNewInsurer}
              >
                Add New Insurance Company
              </Button>
            </div>
            <div className='col-md-8 mt-16'>
              <b>Note:</b> All Insurer additions should be tested in TEST
              environment before going to Production.
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12 ml-1 mr-1 mt-3'>
              <div>
                <div className='a-grid__header'>
                  <div>Insurance Companies</div>
                </div>
                <Grid
                  className='a-grid'
                  // selectedField="selected"
                  data={insurerGridData}
                  // data={orderBy(patientAppointmentsFormating, sort)}
                  // sortable
                  // sort={sort}
                  // onSortChange={e => {
                  // setSort(e.sort);
                  // }}
                  fixedScroll
                >
                  <Column field='name' title='NAME' sortable />
                  <Column field='insurerType' title='TYPE' sortable />
                  <Column field='id' title='ID' />
                  <Column
                    field='status'
                    title='STATUS'
                    // sortable={true}
                    // cell={statusCell}
                  />
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <WindowDialog
          title='Add Insurer'
          width={600}
          height={615}
          initialTop={50}
          showDialog={showAddInsurer}
          onClose={toggleAddNewInsurer}
        >
          <Form
            onSubmit={handleAddNewSubmit}
            initialValues={initialForm()}
            render={(formRenderProps) => (
              <form
                onSubmit={formRenderProps.onSubmit}
                className='k-form pl-2 pr-2'
              >
                <div className='row'>
                  <div className='col-md-6'>
                    <Field
                      name='name'
                      label='Insurance Company Name'
                      component={InputField}
                      validator={insurerInfoForm.name.inputValidator}
                    />
                  </div>
                  <div className='col-md-6'>
                    <Field
                      component={DropDownListField}
                      data={insuranceTypes}
                      name='insurerType'
                      label='Insurer Type'
                      style={{ width: 250 }}
                      validator={insurerInfoForm.insurerType.inputValidator}
                    />
                  </div>
                </div>
                <div className='row mt-3'>
                  <div className='col-md-6'>
                    <Field
                      name='mainPhoneNumber'
                      label='Main Phone Number'
                      component={MaskedPhoneInput}
                      validator={insurerInfoForm.mainPhoneNumber.inputValidator}
                    />
                  </div>
                  <div className='col-md-6'>
                    <Field
                      name='taxId'
                      label='Tax ID'
                      component={InputField}
                      validator={insurerInfoForm.taxId.inputValidator}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-12'>
                    <br />
                    BILLING ADDRESS:
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-12'>
                    <Field
                      name='streetName'
                      label='Street Name'
                      component={InputField}
                      validator={insurerInfoForm.streetName.inputValidator}
                    />
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-6'>
                    <Field
                      name='city'
                      label='City'
                      component={InputField}
                      validator={insurerInfoForm.city.inputValidator}
                    />
                  </div>
                  <div className='col-md-6'>
                    <Field
                      name='state'
                      data={states}
                      textField='name'
                      valueField='abbreviation'
                      label='State'
                      component={DropDownList}
                      validator={insurerInfoForm.state.inputValidator}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    <Field
                      name='zip'
                      label='Zip'
                      component={InputField}
                      validator={insurerInfoForm.zip.inputValidator}
                    />
                  </div>
                </div>
                <hr />
                <div className='row mt-3'>
                  <div className='col-md-6'>
                    <button type='submit' className='k-button pageButton Blue'>
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            )}
          />
        </WindowDialog>
      </div>
    </div>
  );
};

export default InsurerAdmin;
