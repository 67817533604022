import React, { useContext, useState, useEffect } from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Form, Field } from '@progress/kendo-react-form';
import { TextArea } from '@progress/kendo-react-inputs';
import { connectToGraphqlAPI } from '@/provider';
import { updatePatientBucket } from '@/graphql/mutations';
import { getPatientBucketBulletin } from '@/graphql/queries';
import { NotifContext } from '@/context/NotifContext';
import { PatientContext } from '@/context/PatientContext';

const BulletinBoard = ({ patientId, toggleDialog }) => {
  const [loading, setLoading] = useState(true);
  const [bulletin, setBulletin] = useState('');

  const { showError, showSuccess } = useContext(NotifContext);
  const { selectedPatientInfo, setSelectedPatientInfo } = useContext(PatientContext);

  const updateBucketCall = async ({ bulletinBoard }) => {
    try {
      setLoading(true);

      const data = await connectToGraphqlAPI({
        graphqlQuery: updatePatientBucket,
        variables: {
          input: { patientId, bulletinBoard }
        }
      });

      if (data.data?.updatePatientBucket) {
        showSuccess('Patient Bulletin Board successfully updated');
        setSelectedPatientInfo({
          ...selectedPatientInfo,
          bulletinBoard
        });
      } else {
        showError('Error in Patient Bulletin update action. Please, check logs for details');
      }

      setLoading(false);
      toggleDialog();
    } catch (err) {
      console.log('BulletingBoard::updateBucketCall err:', err);
      setLoading(false);
    }
  };

  const getBulletinCall = async () => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getPatientBucketBulletin,
        variables: { patientId }
      });

      if (data.data?.getPatientBucket) {
        setBulletin(data.data.getPatientBucket.bulletinBoard);
      } else {
        showError('Error in getBulletinCall action. Please, check logs for details');
      }

      setLoading(false);
    } catch (err) {
      console.log('BulletingBoard::getBulletinCall err:', err);
      setLoading(false);
    }
  };
 useEffect(() => {
    if (patientId) {
      getBulletinCall();
    }
  }, [patientId, getBulletinCall]);
  const loaderClass = loading ? 'k-i-loading' : '';

  const initialValues = { bulletinBoard: bulletin };

  return (
    <Dialog
      title="Update Patient Bulletin Board"
      onClose={toggleDialog}
      width={700}
    >
      <Form
        key={JSON.stringify(initialValues)}
        initialValues={initialValues}
        onSubmit={updateBucketCall}
        render={formRenderProps => (
          <>
            <div className="row">
              <div className="col-md-12">
                <Field
                  component={TextArea}
                  name="bulletinBoard"
                  style={{ width: '100%', minHeight: '250px' }}
                  autoSize
                  disabled={loading}
                />
              </div>
            </div>
            <div className="row" style={{ marginTop: '1.0rem' }}>
              <div className="col-md-6">
                <button
                  type="submit"
                  className="k-button k-primary"
                  onClick={formRenderProps.onSubmit}
                  disabled={loading}
                >
                  <span
                    className={`k-icon k-iconWhite k-i-save mr-2 ${loaderClass}`}
                  />
                  Update
                </button>
              </div>
              <div className="col-md-6" style={{ textAlign: 'right' }}>
                <button className="k-button ml-15" onClick={toggleDialog}>
                  <span className="k-icon k-i-close mr-2" />
                  Close
                </button>
              </div>
            </div>
          </>
        )}
      />
    </Dialog>
  );
};

export default BulletinBoard;
