import React from 'react';

import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';

import { getDosageUOM } from '@/common/DoseHelper';
import { drugDosage } from '../infusionHelper';

import WindowDialog from '@/components/common-components/WindowDialog';
import IconLabel from '@/components/common-components/IconLabel';

import IVLabel from '../IVLabel';
import PatientWeightLog from '../PatientWeightLog';

const RevisitPrep = props => {
  const { infusionFormData } = props;
  const { dosage } = props;

  return (
    <div className="infusion-page">
      <div
        className="infusion-details col-md-11 mt-2 mb-3"
        style={{ border: '1px solid #afaaaa' }}
      >
        <div className="row">
          <div className="infusion-HeaderRow col-12 ml-0 pl-2 py-2 mr-0">
            <div className="row">
              <div className="col-md-2 headerText">PATIENT WEIGHT</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-3 mb-3">
            <PatientWeightLog patientInfo={props.patientInfo} />
          </div>
        </div>
      </div>
      <div className="row">
        <div
          className="infusion-details col-md-11"
          style={{ border: '1px solid #afaaaa' }}
        >
          <div className="row">
            <div className="infusion-HeaderRow col-12 ml-0 pl-3 py-3 mr-0">
              <div className="row">
                <div className="col-md-4 headerText">
                  MEDICATION: &nbsp;{' '}
                  {infusionFormData?.referralOrder?.orderName}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* DRUG SELECTION */}
      <div
        className="infusion-details col-md-11 mt-2 mb-3"
        style={{
          border: '1px solid #afaaaa',
          backgroundColor: '#ffffff'
        }}
      >
        <div className="row">
          <div className="infusion-SubHeaderRowGrey col-12 ml-0 pl-1 py-2 mr-0">
            <div className="row">
              <div className="col-md-4 headerText">DRUG SELECTION</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-11 mt-1 ml-3 mb-2 mr-3">
            <Grid className="infusion-grid" data={props.itemDrugs}>
              <Column field="drugQty" title="QTY" />
              <Column field="drugVial" title="VIAL" />
              <Column field="drugLot" title="LOT" />
              <Column field="drugExpDate" title="EXP" />
            </Grid>
          </div>
        </div>

        <div className="infusion-details col-md-11 ml-0 pl-3 py-2 mr-0">
          <div className="row">
            {drugDosage.map((dsg, i) => {
              if (dosage[dsg.key]) {
                const uom = getDosageUOM(dosage.UOM);
                return (
                  <div className="col-md-2 headerText" key={`dosage-uom-${i}`}>
                    {dosage[dsg.key]} {uom}
                  </div>
                );
              }
            })}
          </div>
          <div className="row">
            {drugDosage.map((drugDosage, i) => {
              if (dosage[drugDosage.key]) {
                return (
                  <div
                    className="col-2 infusion-details-field-name"
                    key={`drug-dosage-${i}`}
                  >
                    {drugDosage.title}
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
      {/* DILUENT SELECTION */}
      <div
        className="infusion-details col-md-11 mt-2 mb-3"
        style={{
          border: '1px solid #afaaaa',
          backgroundColor: '#ffffff'
        }}
      >
        <div className="row">
          <div className="infusion-SubHeaderRowGrey col-12 ml-0 pl-1 py-2 mr-0">
            <div className="row">
              <div className="col-md-4 headerText">DILUENT SELECTION</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-11 mt-1 ml-3 mb-2 mr-3">
            <Grid className="infusion-grid" data={props.itemDiluents}>
              <Column field="diluentQty" title="QTY" />
              <Column field="diluentChoice" title="DILUENT" />
              <Column field="diluentLot" title="LOT" />
              <Column field="diluentExpDate" title="EXP" />
            </Grid>
          </div>
        </div>
      </div>
      {/* RECONSTITUTED-IN SELECTION */}
      <div
        className="infusion-details col-md-11 mt-2 mb-3"
        style={{
          border: '1px solid #afaaaa',
          backgroundColor: '#ffffff'
        }}
      >
        <div className="row">
          <div className="infusion-SubHeaderRowGrey col-12 ml-0 pl-1 py-2 mr-0">
            <div className="row">
              <div className="col-md-4 headerText">
                RECONSTITUTED-IN SELECTION
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-11 mt-1 ml-3 mb-2 mr-3">
            <Grid className="infusion-grid" data={props.itemReconstituteds}>
              <Column field="reconstitutedQty" title="QTY" />
              <Column field="reconstitutedFluid" title="DILUENT" />
              <Column field="reconstitutedLot" title="LOT" />
              <Column field="reconstitutedExpDate" title="EXP" />
            </Grid>
          </div>
        </div>
      </div>
      <div>
        <div className="row mt-5 mb-5">
          <div className="col-md-3">
            <Button
              type="button"
              className="k-button"
              onClick={props.handlePrintIVLabel}
            >
              <IconLabel label="Print IV Label" icon="printer" />
            </Button>
          </div>
        </div>
      </div>
      <WindowDialog
        title="Print IV Label"
        style={{ backgroundColor: '#FFFFFF', minHeight: '300px' }}
        initialHeight={800}
        initialTop={1}
        width={600}
        showDialog={props.showPrintIVLabel}
        onClose={props.handlePrintIVLabel}
      >
        <IVLabel labelData={props.labelData} />
      </WindowDialog>
    </div>
  );
};

export default RevisitPrep;
