import React, { useContext } from 'react';

// common components
import AwesomeLink from '@/components/common-components/AwesomeLink';

// context
import { WorkItemContext } from '@/context';

const WorkItemLog = () => {
  const { setShowLog, getWorkItemDetails } = useContext(WorkItemContext);

  return (
    <>
      <AwesomeLink
        onClick={() => {
          getWorkItemDetails();
          setShowLog(true);
        }}
        icon='book'
        label='Show Work Item History'
        customClassName='k-iconWhite'
      />
    </>
  );
};

export default WorkItemLog;
