import React, { useContext } from 'react';

// context
import { WorkItemContext } from '@/context';

// components
import AwesomeLabel from '@/components/common-components/AwesomeLabel';

const WorkItemICD10 = () => {
  const { selectedWorkItem } = useContext(WorkItemContext);

  if (!selectedWorkItem) return null;

  const { attachedData } = selectedWorkItem;

  if (!!attachedData?.attachedData?.dxCode) {
    return (
      <div>
        <AwesomeLabel
          label={attachedData.attachedData.dxCode}
          icon='hashtag'
          title='DX code'
        />
      </div>
    );
  }

  return null;
};

export default WorkItemICD10;
