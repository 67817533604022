import React from 'react';
import styled from 'styled-components';
import optmlLogo from '@/assets/images/optml-infuse.png';

const FooterFlex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const Footer = () => {
  return (
    <>
      <FooterFlex>
        <span>Powered by</span>
        <img width={100} src={optmlLogo} alt='OptML Infuse logo' />
      </FooterFlex>
      <FooterFlex>
        <small>app version: {process.env.REACT_APP_VERSION}</small>
      </FooterFlex>
    </>
  );
};

export default Footer;
