import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';

import { Dialog } from '@progress/kendo-react-dialogs';
import { connectToGraphqlAPI } from '@/provider';
import { getSalesCommissionsByPrescriberId } from '@/graphql/queries';
import {
  createSalesCommission,
  deleteSalesCommission
} from '@/graphql/mutations';

import { AddSalesRep } from './AddSalesRep';
import { SalesRepList } from './SalesRepList';
import { toProperCase } from '@/common/Converters';

const showAddSalesRepError = () =>
  alert('An error occured while trying to assign the Sales Rep to the Prescriber');

const showDeleteSalesRepError = () =>
  alert('An error occured while trying to delete the Sales Rep for this Prescriber');

export const SalesRepDialog = ({
  showDialog,
  onClose,
  prescriber,
  salesReps
}) => {
  const [prescriberSalesReps, setPrescriberSalesReps] = useState([]);
  useEffect(async () => {
    if (!showDialog || !prescriber) {
      return;
    }
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getSalesCommissionsByPrescriberId,
        variables: { prescriberId: prescriber.npi }
      });
      const results =
        data?.data?.getSalesCommissionsByPrescriberId?.items || [];
      setPrescriberSalesReps(results
          .filter((item) => item?.userInfo?.firstName) // deleted sales reps will come as undefined
          .map((item) => ({
            id: item?.id,
            userId: item?.userInfo?.userId,
            role: item?.userInfo?.role,
            salesRep: `${item?.userInfo?.firstName} ${
              item?.userInfo?.lastName || ''
            }`
          })));
    } catch (err) {
      console.log('Error occured in calling get sales reps query', err);
    }
  }, [showDialog, prescriber]);

  if (!showDialog || !prescriber) {
    return null;
  }

  const onDeleteSalesRep = async (selectedRep) => {
    const input = {
      id: selectedRep.id
    };

    try {
      const result = await connectToGraphqlAPI({
        graphqlQuery: deleteSalesCommission,
        variables: { input }
      });
      if (result?.error) {
        console.error('deleteSalesCommissionCall err', result.err);
        showDeleteSalesRepError();
        return;
      }
      if (result?.data?.deleteSalesCommission) {
        setPrescriberSalesReps(prescriberSalesReps.filter((item) => item.id !== selectedRep.id));
      }
    } catch (err) {
      console.error('deleteSalesCommissionCall err', err);
      showDeleteSalesRepError();
    }
  };

  const addSalesRep = async (selectedSalesRepUserId) => {
    const alreadyExists = prescriberSalesReps.find((rep) => rep.userId === selectedSalesRepUserId);
    if (alreadyExists) {
      alert('This sales rep is already assigned to this prescriber');
      return;
    }
    const selectedRep = salesReps.find((rep) => rep.value === selectedSalesRepUserId);
    if (!selectedRep) {
      console.error('Could not find the selected sales rep');
      showAddSalesRepError();
      return;
    }
    const input = {
      prescriberId: prescriber.npi,
      userId: selectedRep.value,
      userRole: selectedRep.role
    };

    try {
      const result = await connectToGraphqlAPI({
        graphqlQuery: createSalesCommission,
        variables: { input }
      });
      if (result?.error) {
        console.error('createSalesCommissionCall err', result.err);
        showAddSalesRepError();
        return;
      }
      if (result?.data?.createSalesCommission) {
        setPrescriberSalesReps([
          ...prescriberSalesReps,
          {
            id: result?.data?.createSalesCommission?.id,
            userId: selectedRep.value,
            role: selectedRep.role,
            salesRep: selectedRep.text
          }
        ]);
      }
    } catch (err) {
      console.error('createSalesCommissionCall err', err);
      showAddSalesRepError();
    }
  };

  const prescriberDisplay = `${toProperCase(prescriber.prescriberFirstName)} ${toProperCase(prescriber.prescriberLastName)} - (NPI: ${
    prescriber.NPINumber
  })`;
  return (
    <>
      <Dialog
        title={`Manage Sales Reps For ${prescriberDisplay}`}
        width={1000}
        onClose={onClose}
      >
        <>
          <AddSalesRep
            salesReps={salesReps}
            prescriberInfo={prescriber}
            onAddSalesRep={addSalesRep}
          />
          <SalesRepList
            salesReps={prescriberSalesReps}
            onDeleteSalesRep={onDeleteSalesRep}
          />
        </>
      </Dialog>
    </>
  );
};

SalesRepDialog.propTypes = {
  showDialog: PropTypes.bool,
  onClose: PropTypes.func,
  prescriber: PropTypes.object,
  salesReps: PropTypes.array
};
