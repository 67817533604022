import React, { useContext, useState } from 'react';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Tooltip } from '@progress/kendo-react-tooltip';
import pluralize from 'pluralize';
import styled from 'styled-components';
import cx from 'clsx';
import { Storage } from 'aws-amplify';

// components
import Preloader from '@/components/common-components/Preloader';
import { ColumnMenu } from '@/components/common-components/columnMenu';
import Badge from '@/components/common-components/Badge';

// context
import { InboundContext } from '@/context/InboundContext';

// constants
import { taskStatus } from '@/constants/enum';

// styles
import styles from './InboundWorkGrid.module.scss';
import AwesomeLink from '@/components/common-components/AwesomeLink';

const ActionLink = styled.div`
  padding: 3px 0;
`;

const initialDataState = {};

const InboundWorkGrid = ({ resultState = false, setPdfPath, onCreateItem }) => {
  const [dataState, setDataState] = useState(initialDataState);

  const { isInboundLoading, isInboundDataLoaded } = useContext(InboundContext);

  const render = {
    descriptionCell: ({ dataItem }) => {
      const showBadge = dataItem.status === taskStatus.PENDING;

      return (
        <td>
          {dataItem.description}{' '}
          {showBadge && <Badge type='warning' text='UNDETERMINED' />}
        </td>
      );
    },
    faxViewCell: ({ dataItem }) => {
      const renderAssignLink = () => (
        <AwesomeLink
          onClick={async () => {
            const conf = { download: false };
            const s3ImageURL = await Storage.get(dataItem.url, conf);
            setPdfPath(s3ImageURL);
            onCreateItem(dataItem);
          }}
          icon='square-plus'
          label='Create Work Item'
        />
      );

      return (
        <td>
          <ActionLink>{renderAssignLink()}</ActionLink>
        </td>
      );
    },
    senderCell: ({ dataItem }) => {
      if (dataItem.prescriberInfo) {
        return (
          <td>
            <Tooltip anchorElement='target' position='right'>
              <span
                className='help-link'
                title='Office Address: 12345 Test Avenue, Boulder, CO'
              >
                {dataItem.prescriberInfo}
              </span>
            </Tooltip>
          </td>
        );
      }
      return <td>{dataItem.source}</td>;
    },
    pendingItemsCounter: () => {
      const pluralizedItems = pluralize(
        'pending item',
        resultState.total,
        true
      );
      return resultState.data.length > 0 ? `(${pluralizedItems})` : '';
    }
  };

  const getColumns = () => {
    const cols = [
      { field: 'id', title: 'ID' },
      { field: 'source', title: 'Sender', cell: render.senderCell },
      { field: 'date', title: 'Received At' },
      {
        field: 'description',
        title: 'Description',
        cell: render.descriptionCell
      },
      { field: '', title: 'Actions', cell: render.faxViewCell }
    ];
    return cols;
  };

  const columns = getColumns();

  return (
    <Preloader show={isInboundLoading}>
      {isInboundDataLoaded && (
        <>
          <div className='a-grid__header'>
            <div>Inbound Work {render.pendingItemsCounter()}</div>
          </div>
          <Grid
            className={cx(styles.dataGrid, 'queue_grid')}
            data={{ data: resultState.data }}
            onDataStateChange={(e) => setDataState(e.dataState)}
            sortable
            {...dataState}
            total={1} // total set to stop paging error in the browser. Remove if you want to use paging.
          >
            {columns.map(({ minWidth, ...rest }, idx) => (
              <Column
                key={idx}
                width={minWidth}
                columnMenu={ColumnMenu}
                {...rest}
              />
            ))}
          </Grid>
        </>
      )}
    </Preloader>
  );
};

export { InboundWorkGrid };
