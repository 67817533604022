import React, { useContext } from 'react';

//kendo
import { Field, Form } from '@progress/kendo-react-form';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Grid } from '@progress/kendo-react-grid';
import { GridColumn as Column } from '@progress/kendo-react-grid/dist/npm/GridColumn';
import { TextArea } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';

//components
import AttachReferralDocument from '@/components/Referral/AttachDocument/AttachReferralDocument';
import PrescriberAddressesCurrent from '@/components/Prescriber/PrescriberAddressesCurrent';
import DeleteButton from '@/components/common-components/Form/DeleteButton';
import FormButton from '@/components/common-components/Form/FormButton';
import AutoFilter from '@/common/AutoFilter';

// context
import { InfoCodeContext, ReferralContext, PrescriberContext } from '@/context';

// util
import {
  DatePickerField,
  InputField,
  validateInput
} from '@/common/Validation';
import { formatPrescriberAddress } from '@/components/Prescriber/prescriberHelper';
import { administrationForm } from './LabDrawValidation';
import { Constants } from '@/constants';

// styles
import styles from '../Referral.module.scss';

const LabDrawForm = ({
  handleSubmitOrder,
  detailFormValues,
  detailsFormRef,
  loading,
  handleAddAdministration,
  iPrimaryDX,
  codeDescriptionChange,
  selectedCodeDescription,
  addIPrimaryDX
}) => {
  const {
    referralOrderUrl,
    itemAdministrations,
    setItemAdministrations,
    referralHeaderNotes,
    setReferralHeaderNotes,
    orderFormData
  } = useContext(ReferralContext);
  const {
    listPrescriberOfficeAddresses,
    listCardPrescriberAddresses,
    listPatientHcpProfilesData,
    setListPrescriberOfficeAddresses,
    setListCardPrescriberAddresses
  } = useContext(PrescriberContext);
  const { dxCodes } = useContext(InfoCodeContext);

  const handleDeleteClick = (props) => {
    const clonedItems = [...itemAdministrations];
    clonedItems.splice(props.dataIndex, 1);
    setItemAdministrations(clonedItems);
  };
  const getOfficeAddresses = (event) => {
    const officeAddresses = event.value?.officeAddresses;
    setListPrescriberOfficeAddresses(
      officeAddresses.map((address) => ({
        value: address.id,
        text: formatPrescriberAddress(address),
        default: officeAddresses.length > 1 ? false : address.default
      }))
    );
    setListCardPrescriberAddresses(officeAddresses);
  };

  const customCellDeleteProductAdmins = (props) => {
    return (
      <td>
        <DeleteButton handleClick={() => handleDeleteClick(props)} />
      </td>
    );
  };

  const labDrawForm = {
    prescribingHCP: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          prescribingHCP: { ...labDrawForm?.prescribingHCP, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        }
      ]
    },
    selectedPrescriberOfficeAddress: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          selectedPrescriberOfficeAddress: {
            ...labDrawForm.selectedPrescriberOfficeAddress,
            value
          }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        }
      ]
    },
    orderDate: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          orderDate: { ...labDrawForm.orderDate, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        },
        {
          type: 'dateTodayFuture',
          message: Constants.ErrorMessage.dateFuture
        }
      ]
    },
    orderExpires: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          orderExpires: { ...labDrawForm.orderExpires, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        },
        {
          type: 'datePast',
          message: Constants.ErrorMessage.datePast
        }
      ]
    },
    maxNumTreatments: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          maxNumTreatments: { ...labDrawForm.maxNumTreatments, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        }
      ]
    },
    primaryDX: {
      value: orderFormData?.primaryDX || '',
      inputValidator: (value) => {
        return validateInput({
          primaryDX: { ...labDrawForm.primaryDX, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        }
      ]
    },
    'primaryDX-0': {
      value: orderFormData?.['primaryDX-0'],
      inputValidator: () => {
        return validateInput({
          'primaryDX-0': {
            ...labDrawForm['primaryDX-0'],
            value: labDrawForm['primaryDX-0']?.value
          }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        }
      ]
    },
    'primaryDX-1': {
      value: orderFormData?.['primaryDX-1'] || '',
      inputValidator: () => {
        return validateInput({
          'primaryDX-1': {
            ...labDrawForm?.['primaryDX-1'],
            value: labDrawForm['primaryDX-1'].value
          }
        });
      },
      validations: []
    },
    'primaryDX-2': {
      value: orderFormData?.['primaryDX-2'] || '',
      inputValidator: () => {
        return validateInput({
          'primaryDX-2': {
            ...labDrawForm?.['primaryDX-2'],
            value: labDrawForm['primaryDX-2'].value
          }
        });
      },
      validations: []
    }
  };

  return (
    <article>
      <Form
        onSubmit={handleSubmitOrder}
        initialValues={detailFormValues}
        ref={detailsFormRef}
        render={(formRenderProps) => (
          <form
            id='formOrder'
            onSubmit={formRenderProps.onSubmit}
            className='k-form pl-3 pr-3 pt-1'
          >
            <AttachReferralDocument isNewOrder />
            <hr />
            <div className='row'>
              <div className='col-10'>
                <div className='row'>
                  <div className='col-md-3'>
                    ORDER NAME: <b>LAB DRAW</b>
                  </div>
                </div>
                <div className='row mt-14'>
                  <div className='col-md-2'>PRESCRIBING HCP:</div>
                  <div className='col-md-4'>
                    <Field
                      name='prescribingHCP'
                      label=''
                      component={DropDownList}
                      data={listPatientHcpProfilesData}
                      textField='text'
                      valueField='value'
                      validator={labDrawForm.prescribingHCP.inputValidator}
                      onChange={(event) => {
                        getOfficeAddresses(event);
                      }}
                    />
                  </div>
                </div>
                <div className='row mt-14'>
                  <div className='col-md-3'>
                    PRESCRIBING HCP OFFICE ADDRESS:
                  </div>
                  <div className='col-md-8'>
                    <Field
                      name='selectedPrescriberOfficeAddress'
                      label=''
                      component={DropDownList}
                      data={listPrescriberOfficeAddresses}
                      textField='text'
                      valueField='value'
                      validator={
                        labDrawForm.selectedPrescriberOfficeAddress
                          .inputValidator
                      }
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col ml-3'>
                    <Field
                      name='OfficeAddress'
                      component={() => (
                        <PrescriberAddressesCurrent
                          addresses={listCardPrescriberAddresses}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className='row mt-14'>
                  <div className='col-md-3'>
                    <Field
                      name='orderDate'
                      label='Order Date'
                      component={DatePickerField}
                      onChange={(event) => {
                        const expDate = new Date(event.value);
                        expDate.setFullYear(expDate.getFullYear() + 1);
                        formRenderProps.onChange('orderExpires', {
                          value: expDate
                        });
                      }}
                      validator={labDrawForm.orderDate.inputValidator}
                    />
                  </div>
                  <div className='col-md-3'>
                    <Field
                      name='orderExpires'
                      label='Expiration Date'
                      component={DatePickerField}
                      validator={labDrawForm.orderExpires.inputValidator}
                    />
                  </div>
                  <div className='col-md-6'>
                    {iPrimaryDX.map((__, index) => (
                      <div key={`primaryDX-${index}`} className='row icd10-box'>
                        <div className='col-md-10'>
                          <Field
                            name={`primaryDX-${index}`}
                            label='ICD10 Code'
                            component={AutoFilter}
                            data={dxCodes}
                            textField='codeDescription'
                            valueField='code'
                            onChangeCallback={(value, name) => {
                              codeDescriptionChange(
                                value,
                                name,
                                formRenderProps
                              );
                            }}
                            validator={
                              labDrawForm[`primaryDX-${index}`]?.inputValidator
                            }
                            savedValue={
                              selectedCodeDescription[index]
                                ? selectedCodeDescription[index].codeDescription
                                : null
                            }
                          />
                        </div>
                        {index < 2 && iPrimaryDX.length - 1 === index && (
                          <div className='col-md-2 k-textbox-container'>
                            <Button
                              type='button'
                              look='flat'
                              icon='plus'
                              onClick={() => addIPrimaryDX(index)}
                            >
                              Add
                            </Button>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <hr />
          </form>
        )}
      />

      {/* ADMINISTRATION */}
      <Form
        onSubmit={handleAddAdministration}
        initialValues={detailFormValues}
        render={(formRenderProps) => (
          <form
            id='formAdministration'
            onSubmit={formRenderProps.onSubmit}
            className='k-form pl-3 pr-3 pt-1'
          >
            <div className='row mt-08'>
              <div className='col-md-2 mt-08'>ADMINISTRATION:</div>
              <div className='col-10'>
                <hr />
                <div className='row'>
                  <div className='col-md-1'>Route:</div>
                  <div className='col-md-2'>
                    <Field
                      name='route'
                      component={DropDownList}
                      textField='text'
                      valueField='value'
                      value={{ value: 'IV' }}
                      defaultValue={{ text: 'IV', value: 'IV' }}
                    />
                  </div>
                  <div className='col-md-2'># Treatments:</div>
                  <div className='col-md-1'>
                    <Field
                      name='numTreatments'
                      component={InputField}
                      validator={
                        administrationForm.numTreatments.inputValidator
                      }
                    />
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-2 mt-14'>
                    <button
                      type='submit'
                      className='k-button blue'
                      form='formAdministration'
                    >
                      ADD
                    </button>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-10 mt-14'>
                    <Grid className='infusion-grid' data={itemAdministrations}>
                      <Column
                        field='drugName'
                        title='PRODUCT NAME'
                        width={160}
                      />
                      <Column field='route' title='ROUTE' width={80} />
                      <Column
                        field='administer'
                        title='ADMINISTER'
                        width={200}
                      />
                      <Column
                        field='maxOfTreatments'
                        title='MAX #'
                        width={80}
                      />

                      <Column title='' cell={customCellDeleteProductAdmins} />
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      />

      <hr />

      <div className='row mt-14 ml-3'>
        <div className='col-md-2'>NOTES:</div>
        <div className='col-10'>
          <div className='row'>
            <TextArea
              value={referralHeaderNotes}
              id='referralHeaderNotes'
              autoSize
              rows={4}
              className={styles.referralNote}
              onChange={(e) => setReferralHeaderNotes(e.value)}
            />
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-2 mt-14 ml-3'>
          <FormButton
            type='submit'
            className='k-button pageButton blue'
            loading={loading}
            label='Create New Order'
            form='formOrder'
            disabled={!referralOrderUrl}
          />
        </div>
        <div className='col-2'>
          {!referralOrderUrl && (
            <div
              style={{
                color: 'red',
                fontSize: '8pt',
                marginTop: '1.8rem'
              }}
            >
              Alert: Please add RX Order.
            </div>
          )}
        </div>
      </div>
    </article>
  );
};

export default LabDrawForm;
