import React, { useContext, useState } from 'react';

// components
import { Dialog } from '@progress/kendo-react-dialogs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import AwesomeLabel from '@/components/common-components/AwesomeLabel';

// context
import { WorkItemContext } from '@/context/WorkItemContext';

// constants
import {
  biWorkItemCancelReasons,
  workItemCancelReasons,
  workItemTypes
} from '@/constants/enum';

// styles
import styles from './ItemActions.module.scss';

const CancelItemReason = ({ isVisible, onClose }) => {
  const [reason, setReason] = useState({ value: null });
  const { cancelWorkItem, selectedWorkItem } = useContext(WorkItemContext);
  const work = selectedWorkItem.work;
  const BI = workItemTypes.BENEFIT_INVESTIGATION;

  const onReasonChange = (event) => {
    setReason({ value: event.target.value });
  };

  const onCancelSubmit = async () => {
    const data = await cancelWorkItem(reason);
    if (data?.data?.updateWorkItemStatus?.success) {
      onClose();
    }
  };

  if (!isVisible) return null;

  return (
    <Dialog title='Cancel Work Item' onClose={onClose}>
      <div className={styles.modalBody}>
        <div>Select a reason for canceling:</div>
        <DropDownList
          data={work === BI ? biWorkItemCancelReasons : workItemCancelReasons}
          textField='text'
          dataItemKey='id'
          value={reason.value}
          onChange={onReasonChange}
          style={{
            width: '350px'
          }}
        />
      </div>
      <div className={styles.actions}>
        <button className={styles.actionButton} onClick={onClose}>
          <AwesomeLabel icon='xmark' label='Cancel' />
        </button>
        <button
          className={styles.actionButton}
          onClick={onCancelSubmit}
          disabled={!reason.value}
        >
          <AwesomeLabel icon='box-archive' label='Cancel Work Item' />
        </button>
      </div>
    </Dialog>
  );
};

export default CancelItemReason;
