import React from 'react';

const OfficeVisitBadge = ({ show = true }) => {
  if (!show) return null;

  return (
    <span className='badge badge-pill bg-warning text-dark'>OFFICE VISIT</span>
  );
};

export default OfficeVisitBadge;
