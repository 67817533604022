import React, { useState } from 'react';
// Kendo
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { Input } from '@progress/kendo-react-inputs';
// gql
import { connectToGraphqlAPI } from '@/provider';
import { releaseLocker } from '@/graphql/mutations';
// components
import { MessageDialog } from '@/components/common-components/MessageDialog';
// util
import { capitalizeFirstWord } from '@/common/Converters';

const ReleaseLock = () => {
  const [dialogOption, setDialogOption] = useState({});

  const releaseWorkAPICall = async (dataItem) => {
    const { patientId, agentId } = dataItem;
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: releaseLocker,
        variables: {
          agentId,
          lockerId: patientId
        }
      });
      if (data) {
        const dataMessage = data?.data?.releaseLocker?.details;
        const releaseMessage = dataMessage
          ? capitalizeFirstWord(dataMessage)
          : 'Patient is not locked';
        setDialogOption({
          title: 'Patient Release',
          message: releaseMessage,
          showDialog: true
        });
      }
    } catch (err) {
      console.error('ReleaseLock::releaseWorkAPICall err:', err);
    }
  };

  const handleSubmit = (dataItem) => {
    if (dataItem?.patientId) {
      releaseWorkAPICall(dataItem);
    }
  };
  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-4'></div>
        {!!dialogOption.showDialog && (
          <MessageDialog dialogOption={dialogOption} />
        )}
        <div className='col-4'>
          <Form
            onSubmit={handleSubmit}
            render={() => (
              <FormElement
                style={{
                  maxWidth: 650
                }}
              >
                <fieldset className={'k-form-fieldset'}>
                  <legend className={'k-form-legend'}>Enter Patient Id:</legend>
                  <div className='mb-1'>
                    <Field
                      name={'patientId'}
                      component={Input}
                      label={'Patient Id'}
                    />
                    <Field
                      name={'agentId'}
                      component={Input}
                      label={'Agent Id'}
                    />
                  </div>
                  <div className='mb-1'></div>
                </fieldset>
                <div className='k-form-buttons'>
                  <button type={'submit'} className='k-button'>
                    Submit
                  </button>
                </div>
              </FormElement>
            )}
          />
        </div>
      </div>
      <div className='col-4'></div>
    </div>
  );
};

export default ReleaseLock;
