import React from 'react';

const lightRed = 'rgb(235, 111, 128,.33)';
const lightGreen = 'rgb(150, 219, 148,.33)';
const lightYellow = 'rgb(255, 193, 94,.33)';

export const filterDays = (dayData) => {
  const overFifteen = dayData.filter((item) => item.days >= 15);
  const dayZero = dayData.filter((item) => item.days < 1);
  const dayOne = dayData.filter((item) => item.days === 1);
  const dayTwo = dayData.filter((item) => item.days === 2);
  const dayThree = dayData.filter((item) => item.days === 3);
  const dayFour = dayData.filter((item) => item.days === 4);
  const dayFive = dayData.filter((item) => item.days === 5);
  const daySix = dayData.filter((item) => item.days === 6);
  const daySeven = dayData.filter((item) => item.days === 7);
  const dayEight = dayData.filter((item) => item.days === 8);
  const dayNine = dayData.filter((item) => item.days === 9);
  const tenToFourteen = dayData.filter((item) => item.days >= 10 && item.days <= 14);

  return {
    fullData: dayData,
    dayZero,
    day0: dayZero.length,
    dayOne,
    day1: dayOne.length,
    dayTwo,
    day2: dayTwo.length,
    dayThree,
    day3: dayThree.length,
    dayFour,
    day4: dayFour.length,
    dayFive,
    day5: dayFive.length,
    daySix,
    day6: daySix.length,
    daySeven,
    day7: daySeven.length,
    dayEight,
    day8: dayEight.length,
    dayNine,
    day9: dayNine.length,
    tenToFourteen,
    day10: tenToFourteen.length,
    overFifteen,
    day15: overFifteen.length
  };
};

const daysToWords = (days) => {
  const daysData = Number(days);
  let day = 'Test';
  switch (daysData) {
    case 0:
      day = 'dayZero';
      break;
    case 1:
      day = 'dayOne';
      break;
    case 2:
      day = 'dayTwo';
      break;
    case 3:
      day = 'dayThree';
      break;
    case 4:
      day = 'dayFour';
      break;
    case 5:
      day = 'dayFive';
      break;
    case 6:
      day = 'daySix';
      break;
    case 7:
      day = 'dayNine';
      break;
    case 8:
      day = 'dayEight';
      break;
    case 9:
      day = 'dayNine';
      break;
    case 10:
      day = 'tenToFourteen';
      break;
    case 15:
      day = 'overFifteen';
      break;
    default:
      console.log('Switch did not fire');
  }

  return day;
};

export const getDayColumnBackGroundColor = (day) => {
  {
    const dayNumber = day;
    let styleSelection;
    if (dayNumber >= 9) {
      styleSelection = lightRed;
    } else if (dayNumber <= 5) {
      styleSelection = lightGreen;
    } else {
      styleSelection = lightYellow;
    }
    const style = {
      backgroundColor: styleSelection,
      fontWeight: 'bold'
    };
    return <td style={style}>{dayNumber}</td>;
  }
};

export const cellWithBackGround = (props) => {
  const path = props.field;
  const str = path;
  const part = str.slice(str.lastIndexOf('.') + 1);
  const dataStr = props.dataItem.workItems[part];
  const dayNumber = str.slice(str.lastIndexOf('y') + 1);

  let styleSelection = lightYellow;
  if (dayNumber > 8) {
    styleSelection = lightRed;
  } else if (dayNumber < 6) {
    styleSelection = lightGreen;
  }

  const style = {
    backgroundColor: styleSelection,
    fontWeight: 'bold',
    opacity: dataStr === 0 ? '30%' : 1
  };

  return <td style={style}>{dataStr}</td>;
};

export const viewSettings = [
  { value: 'Drug', text: 'Drug' },
  { value: 'Drug Category', text: 'Drug Category' },
  { value: 'Physician', text: 'Physician' },
  { value: 'Payer', text: 'Payer' },
  { value: 'Region', text: 'Region' },
  { value: 'Sales ID', text: 'Sales ID' },
  { value: 'State', text: 'State' }
];

// Until new api is created to send all queues at one time. The dataCount in DashboardLanding counts the number of values in the groupQueue
// The current dataCount === 6 (counting from 0). If queues are added or removed please adjust count in DashboardLanding. Currently on line 92.
export const groupQueues = [
  { value: 'BI', text: 'BI' },
  { value: 'INTAKE', text: 'Intake' },
  { value: 'PA', text: 'PA' },
  { value: 'SALES', text: 'Sales' },
  { value: 'SCHEDULE', text: 'Schedule' },
  { value: 'CLINICIAN', text: 'Pharmacist' }
];

export const defaultQueue = { value: 'ALL', text: 'All' };
