import React, { useState, useContext } from 'react';

// kendo
import { Grid } from '@progress/kendo-react-grid';
import { GridColumn as Column } from '@progress/kendo-react-grid/dist/npm/GridColumn';
import { Button } from '@progress/kendo-react-buttons';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Form, Field } from '@progress/kendo-react-form';

// components
import WindowOverlay from '@/components/common-components/WindowOverlay';
import { MessageDialog } from '@/components/common-components/MessageDialog';
import { MaskedPhoneInput } from '@/common/MaskInput';

// utils
import { InputField, validateInput } from '@/common/Validation';
import { states } from '@/common/states';
import { Constants } from '@/constants';
import { convertToE164 } from '@/common/PhoneNumberConverter';
import { sortArrayByField } from '@/common/Utility';

// gql
import { connectToGraphqlAPI } from '@/provider';
import { addPayer } from '@/graphql/mutations';

// context
import { UserContext, PayerContext } from '@/context';

const PayerAdmin = () => {
  const { user } = useContext(UserContext);
  const { payers, plans, getPlansByInsurerName, setPlans, getPayerById } =
    useContext(PayerContext);

  const [showAddPayer, setShowAddPayer] = useState(false);
  const [dialogOption, setDialogOption] = useState({});
  const [payerGridData, setPayerGridData] = useState([]);
  const [insurerName, setInsurerName] = useState();
  const [insurerId, setInsurerId] = useState();

  const insurerInfoForm = {
    insurerName: {
      value: insurerName,
      inputValidator: (value) => {
        return validateInput({
          insurerName: { ...insurerInfoForm.insurerName, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        }
      ]
    },
    insurerId: {
      // search both abbreviation AND name
      value: insurerId,
      inputValidator: (value) => {
        return validateInput({
          insurerType: { ...insurerInfoForm.insurerType, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        }
      ]
    },
    planName: {
      value: null,
      inputValidator: (value) => {
        return validateInput({
          planName: { ...insurerInfoForm.planName, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.planName_REQUIRED
        }
      ]
    },
    phoneNumber: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          phoneNumber: { ...insurerInfoForm.phoneNumber, value }
        });
      },
      validations: [
        // {
        // 	type: "required",
        // 	message: Constants.ErrorMessage.PhoneNumber_REQUIRED,
        // },
      ]
    },

    streetName: {
      value: null,
      inputValidator: (value) => {
        return validateInput({
          streetName: { ...insurerInfoForm.streetName, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.Address_REQUIRED
        }
      ]
    },
    state: {
      // search both abbreviation AND name
      value: null,
      inputValidator: (value) => {
        return validateInput({ state: { ...insurerInfoForm.state, value } });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.State_REQUIRED
        }
      ]
    },
    city: {
      value: null,
      inputValidator: (value) => {
        return validateInput({ city: { ...insurerInfoForm.city, value } });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.City_REQUIRED
        },
        {
          type: 'alpha',
          message: Constants.ErrorMessage.Alpha_Required
        }
      ]
    },
    zip: {
      value: null,
      inputValidator: (value) => {
        return validateInput({ zip: { ...insurerInfoForm.zip, value } });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.Zip_REQUIRED
        },
        {
          type: 'minLength',
          length: 5,
          message: Constants.ErrorMessage.minLength_REQUIRED
        },
        {
          type: 'maxLength',
          length: 5,
          message: Constants.ErrorMessage.maxLength_REQUIRED
        }
      ]
    }
  };

  const initialForm = () => {
    const initialObject = {};
    Object.keys(insurerInfoForm).forEach((key) => {
      initialObject[key] = insurerInfoForm[key].value;
    });
    return initialObject;
  };

  const handlePayerSearchSubmit = () => {
    const insuranceCompany = getPayerById(insurerId);
    const insurancePlans = getPlansByInsurerName(insuranceCompany.name);

    if (!!insurancePlans.length) {
      setPayerGridData(insurancePlans);
    } else {
      setDialogOption({
        title: 'No Payer Found ',
        message: 'No Payer Found',
        showDialog: true
      });
      setPayerGridData([]); // clear the grid display
    }
  };

  const handleAddNewSubmit = (dataItem) => {
    // planName: String!
    // insurerId: ID!
    // insurerName: String!
    // tradingPatnerId: String
    // providerPhone: AWSPhone
    // claimAddress: AddressInput
    // electronicPayerId: String
    // timelyFillingINN: Int
    // timelyFillingOON: Int
    // externalId: String

    const requestObject = {
      insurerId: dataItem.insurerId,
      insurerName: dataItem.insurerName,
      planName: dataItem.planName,
      // 	tradingPatnerId: String
      providerPhone: dataItem.phoneNumber
        ? convertToE164(dataItem.phoneNumber)
        : null, // note AWS valiates AWSPhone so things like "+10000000000" and "+11234567890" are rejected as invalid...
      claimAddress: {
        city: dataItem.city,
        state: dataItem.state.abbreviation,
        streetName: dataItem.streetName,
        zip: dataItem.zip
      },
      electronicPayerId: dataItem.electronicPayerId,
      timelyFillingINN: dataItem.timelyFillingINN,
      timelyFillingOON: dataItem.timelyFillingOON
    };
    addPayerCall(requestObject);
    toggleAddNewPayer();
  };

  const addPayerCall = async (requestObject) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: addPayer,
        variables: { input: requestObject, agentId: user.username }
      });

      if (!!data?.data?.addPayer?.payer?.id) {
        setDialogOption({
          title: 'Payor',
          message: 'Payor created sucessfully.',
          showDialog: true
        });

        // update plan list at the context level
        const clonedPlans = [...plans];
        clonedPlans.push(data.data.addPayer.payer);
        sortArrayByField(clonedPlans, 'planName');
        setPlans(clonedPlans);

        // refresh grid with updated data
        setPayerGridData(
          clonedPlans.filter((plan) => plan.insurerId === insurerId)
        );
      }
    } catch (err) {
      console.error('err: ', err);
      setDialogOption({
        title: 'Payor',
        message: 'Error',
        showDialog: true
      });
    }
  };

  const toggleAddNewPayer = () => {
    setShowAddPayer(!showAddPayer);
  };

  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col'>
          {dialogOption && dialogOption.showDialog && (
            <MessageDialog dialogOption={dialogOption} />
          )}
          <Form
            render={(formRenderProps) => (
              <form
                onSubmit={formRenderProps.onSubmit}
                className='k-form pl-3 pr-3 pt-1'
              >
                <div className='row'>
                  <div className='col-md-2 mt-16'>SEARCH PAYOR:</div>
                  <div className='col-md-3'>
                    <Field
                      component={DropDownList}
                      data={payers}
                      name='insurerName'
                      label='Select Insurance Company'
                      textField='name'
                      valueField='id'
                      onChange={(e) => {
                        setInsurerName(e.target.value.name);
                        setInsurerId(e.target.value.id);
                      }}
                    />
                  </div>
                  <div className='col-md-1 mt-12'>
                    <button
                      type='submit'
                      onClick={handlePayerSearchSubmit}
                      className='k-button blue'
                    >
                      Select
                    </button>
                  </div>
                  <div className='col-md-6 mt-16'>
                    <b>Note:</b> All Payer additions should be tested in TEST
                    environment before going to Production.
                  </div>
                </div>
              </form>
            )}
          />
        </div>
      </div>

      <hr />

      <div className='row'>
        <div className='col ml-1 mr-1'>
          <div className='row'>
            <div className='col-md-2 mt-12'>
              <Button
                type='button'
                look='outline'
                icon='plus'
                onClick={toggleAddNewPayer}
              >
                Add New Payer
              </Button>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12 ml-1 mr-1 mt-3'>
              <div>
                <div className='a-grid__header'>
                  <div>Payers</div>
                </div>
                <Grid className='a-grid' data={payerGridData} fixedScroll>
                  <Column field='insurerName' title='INSURER NAME' sortable />
                  <Column field='planName' title='PLAN NAME' sortable />
                  <Column field='id' title='PAYER ID' />
                  <Column field='status' title='STATUS' />
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {showAddPayer && (
          <WindowOverlay
            title='Add Payer'
            width={600}
            height={615}
            initialTop={50}
            onClose={toggleAddNewPayer}
          >
            <Form
              onSubmit={handleAddNewSubmit}
              initialValues={initialForm()}
              render={(formRenderProps) => (
                <form
                  onSubmit={formRenderProps.onSubmit}
                  className='k-form pl-2 pr-2'
                >
                  <div className='row'>
                    <div className='col-md-6'>
                      <Field
                        name='insurerName'
                        label='Insurance Company Name'
                        component={InputField}
                        disabled
                      />
                    </div>
                    <div className='col-md-6'>
                      <Field
                        component={InputField}
                        name='insurerId'
                        label='Insurer ID'
                        style={{ width: 250 }}
                        disabled
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-12'>
                      <Field
                        name='planName'
                        label='Plan Name'
                        component={InputField}
                        validator={insurerInfoForm.planName.inputValidator}
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <Field
                        name='phoneNumber'
                        label='Phone Number'
                        component={MaskedPhoneInput}
                        validator={insurerInfoForm.phoneNumber.inputValidator}
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-12'>
                      <br />
                      CLAIMS ADDRESS:
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-12'>
                      <Field
                        name='streetName'
                        label='Street Name'
                        component={InputField}
                        validator={insurerInfoForm.streetName.inputValidator}
                      />
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-md-6'>
                      <Field
                        name='city'
                        label='City'
                        component={InputField}
                        validator={insurerInfoForm.city.inputValidator}
                      />
                    </div>
                    <div className='col-md-6'>
                      <Field
                        name='state'
                        data={states}
                        textField='name'
                        valueField='abbreviation'
                        label='State'
                        component={DropDownList}
                        validator={insurerInfoForm.state.inputValidator}
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <Field
                        name='zip'
                        label='Zip'
                        component={InputField}
                        validator={insurerInfoForm.zip.inputValidator}
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <Field
                        name='electronicPayerId'
                        component={InputField}
                        label='Electronoic Payer ID'
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <Field
                        name='timelyFillingINN'
                        component={InputField}
                        label='Timely Filing for claims INN'
                      />
                    </div>
                    <div className='col-md-6'>
                      <Field
                        name='timelyFillingOON'
                        component={InputField}
                        label='Timely Filing for claims OON'
                      />
                    </div>
                  </div>

                  <hr />
                  <div className='row mt-3'>
                    <div className='col-md-6'>
                      <button
                        type='submit'
                        className='k-button pageButton Blue'
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              )}
            />
          </WindowOverlay>
        )}
      </div>
    </div>
  );
};

export default PayerAdmin;
