import React, { useEffect, useState, useContext } from 'react';
import { arrayMoveImmutable } from 'array-move';
import * as moment from 'moment';

import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Input, RadioGroup, Switch } from '@progress/kendo-react-inputs';
import {
  Card,
  CardTitle,
  CardBody,
  CardActions
} from '@progress/kendo-react-layout';
import { Form, Field } from '@progress/kendo-react-form';
import { TimePicker } from '@progress/kendo-react-dateinputs';
import { Error } from '@progress/kendo-react-labels';

// components
import DeleteButton from '@/components/common-components/Form/DeleteButton';
import { MessageDialog } from '@/components/common-components/MessageDialog';
import WindowDialog from '@/components/common-components/WindowDialog';
import AwesomeLabel from '@/components/common-components/AwesomeLabel';
import Badge from '@/components/common-components/Badge';

// common
import { InputField, validateFloat } from '@/common/Validation';
import { getPatientWeightFromReferralLB, lb2kg } from '@/common/Converters';
import {
  formatDateToAWS,
  formatDateToDefault,
  formatTimeToDefault,
  getNow
} from '@/common/DateHelper';
import { CellRender, RowRender } from '@/common/GridRenderer';

// context
import { NotifContext } from '@/context/NotifContext';

// gql
import { connectToGraphqlAPI } from '@/provider';
import { getNursingProcess } from '@/graphql/queries';

// constants
import { Constants } from '@/constants';

import {
  getAdminMaxTime,
  getAdminMinTime
} from '../Scheduling/getSchedulerTimeLimits';
import PatientWeightLog from './PatientWeightLog';
import PatientAllergiesLog from '../Patient/PatientAllergiesLog';
import PatientMedsLog from '../Patient/PatientMedsLog';
import PatientLogInfo from '../Patient/PatientLogInfo';

const Assessment = (props) => {
  const { showWarning } = useContext(NotifContext);

  const { infusion } = props;
  const { selectedPatientInfo } = props;
  const { nursingProcessId } = props;
  const [nursingProcess, setNursingProcess] = useState({});
  const { infusionFormData } = props;

  const [apiWeights, setApiWeights] = useState([]);
  const [itemWeights, setItemWeights] = useState([]);
  const [apiVitals, setApiVitals] = useState([]);
  const [itemVitals, setItemVitals] = useState([]);
  // const [apiAllergies, setApiAllergies] = useState([]);
  // const [itemAllergies, setItemAllergies] = useState([]);

  const [theQ, setTheQ] = useState();
  const [stepAllowed, setStepAllowed] = useState(false);
  const [dialogOption, setDialogOption] = useState({});
  const [showAssessments, setShowAssessments] = useState(false);

  const [showQuestionSet1, setShowQuestionSet1] = useState(false);
  const [showQuestionSet2, setShowQuestionSet2] = useState(false);
  const [showQuestionSet3, setShowQuestionSet3] = useState(false);
  const [showQuestionSet4, setShowQuestionSet4] = useState(false);
  const [showQuestionSet5, setShowQuestionSet5] = useState(false);
  const [showQuestionSet6, setShowQuestionSet6] = useState(false);
  const [showQuestionSet7, setShowQuestionSet7] = useState(false);
  const [showQuestionSet8, setShowQuestionSet8] = useState(false);
  const [showQuestionSet9, setShowQuestionSet9] = useState(false);
  const [showQuestionSet10, setShowQuestionSet10] = useState(false);
  const [showQuestionSet11, setShowQuestionSet11] = useState(false);
  const [showQuestionSet12, setShowQuestionSet12] = useState(false);
  const [question1Count, setQuestions1] = useState(0);
  const [question2Count, setQuestions2] = useState(0);
  const [question3Count, setQuestions3] = useState(0);
  const [question4Count, setQuestions4] = useState(0);
  const [question5Count, setQuestions5] = useState(0);
  const [question6Count, setQuestions6] = useState(0);
  const [question7Count, setQuestions7] = useState(0);
  const [question8Count, setQuestions8] = useState(0);
  const [question9Count, setQuestions9] = useState(0);
  const [question10Count, setQuestions10] = useState(0);
  const [question11Count, setQuestions11] = useState(0);
  const [question12Count, setQuestions12] = useState(0);

  const [isTimeRangeError, setIsTimeRangeError] = useState(false);

  const booleanChoices = [
    { label: 'Yes', value: true, className: 'patient-radio blue' },
    { label: 'No', value: false, className: 'patient-radio blue' }
  ];

  // MAIN INITIATOR
  useEffect(() => {
    getNursingProcessCall(nursingProcessId);
  }, []);

  useEffect(() => {
    sortQuestionData();
  }, [theQ]);

  const sortQuestionData = () => {
    if (theQ) {
      // Step 1: Turn theQ object into an array
      let theQArr = Object.keys(theQ).map((key) => [key, theQ[key]]);
      // Step 2: Sort the array to get questions in the correct group
      theQArr = theQArr.sort();
      // Step 3: filter out all of the groups into 12 sets of questions
      const set1 = theQArr.filter((x) => x[0].includes('question1_'));
      const set2 = theQArr.filter((x) => x[0].includes('question2_'));
      // Step 3.1: Use array-move to put questions in the correct location in the set
      let set3 = theQArr.filter((x) => x[0].includes('question3_'));
      set3 = arrayMoveImmutable(set3, 1, 11);
      set3 = arrayMoveImmutable(set3, 1, 11);
      set3 = arrayMoveImmutable(set3, 1, 11);
      const set4 = theQArr.filter((x) => x[0].includes('question4_'));
      const set5 = theQArr.filter((x) => x[0].includes('question5_'));
      let set6 = theQArr.filter((x) => x[0].includes('question6_'));
      set6 = arrayMoveImmutable(set6, 1, 9);
      const set7 = theQArr.filter((x) => x[0].includes('question7_'));
      const set8 = theQArr.filter((x) => x[0].includes('question8_'));
      const set9 = theQArr.filter((x) => x[0].includes('question9_'));
      const set10 = theQArr.filter((x) => x[0].includes('question10_'));
      const set11 = theQArr.filter((x) => x[0].includes('question11_'));
      const set12 = theQArr.filter((x) => x[0].includes('question12_'));
      // Step 4: Add keys for switch
      const questState = [
        [set1, 'setQuestions1'],
        [set2, 'setQuestions2'],
        [set3, 'setQuestions3'],
        [set4, 'setQuestions4'],
        [set5, 'setQuestions5'],
        [set6, 'setQuestions6'],
        [set7, 'setQuestions7'],
        [set8, 'setQuestions8'],
        [set9, 'setQuestions9'],
        [set10, 'setQuestions10'],
        [set11, 'setQuestions11'],
        [set12, 'setQuestions12']
      ];
      // Step 5: Filter out all the even questions that relate to the input field and set the question group state.
      questState.map((x) => {
        filterSet(x[0], x[1]);
      });
    }
  };

  const filterSet = (arr, state) => {
    // Step 5.1: Filter out all even questions
    let filtered = arr.filter((element, index) => {
      return index % 2 === 0;
    });
    filtered = filtered.reduce(
      (r, o) => r + +!Object.values(o).includes(null),
      0
    );
    // Step 5.2: Set the question state
    switch (state) {
      case 'setQuestions1':
        setQuestions1(filtered);
        break;
      case 'setQuestions2':
        setQuestions2(filtered);
        break;
      case 'setQuestions3':
        setQuestions3(filtered);
        break;
      case 'setQuestions4':
        setQuestions4(filtered);
        break;
      case 'setQuestions5':
        setQuestions5(filtered);
        break;
      case 'setQuestions6':
        setQuestions6(filtered);
        break;
      case 'setQuestions7':
        setQuestions7(filtered);
        break;
      case 'setQuestions8':
        setQuestions8(filtered);
        break;
      case 'setQuestions9':
        setQuestions9(filtered);
        break;
      case 'setQuestions10':
        setQuestions10(filtered);
        break;
      case 'setQuestions11':
        setQuestions11(filtered);
        break;
      case 'setQuestions12':
        setQuestions12(filtered);
        break;
    }
  };

  // LISTENERS
  useEffect(() => {
    handleLoadInfusion();
  }, [nursingProcess]);

  const getNursingProcessCall = async (id) => {
    let npid = id;
    if (!npid) {
      npid = '';
    }
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getNursingProcess,
        variables: { id: npid }
      });

      // STEP 1: data collection from existing record
      if (data && data.data && data.data.getNursingProcess) {
        setNursingProcess(data.data.getNursingProcess);
      } else {
        handleLoadInfusion();
      }
    } catch (err) {
      console.log('marty getNursingProcessCall err', err);
      alert('ERROR: getNursingProcessCall');
    }
  };

  const handleLoadInfusion = () => {
    if (nursingProcess && nursingProcess.stepReview) {
      if (nursingProcess.stepReview.orderApproved) {
        setStepAllowed(true);
      }
    }

    if (
      nursingProcess &&
      nursingProcess.stepAssessment &&
      nursingProcess.stepAssessment.patientWeights
    ) {
      try {
        const a = [];
        const i = [];
        nursingProcess.stepAssessment.patientWeights.map((item, idx) => {
          const isLastItem =
            idx === nursingProcess.stepAssessment.patientWeights.length - 1;

          if (isLastItem) {
            const apiWeight = {
              weight: item.weightLB,
              entered: formatDateToAWS(item.entered),
              unitOfWeight: 'LB'
            };
            a.push(apiWeight);
          }

          const itemWeight = {
            patientWeightLB: item.weightLB,
            calcPatientWeightKG: item.weight,
            lastKnownWeightLB: item.lastKnown,
            calcChangeFromLastKnown: item.changeFromLastKnown,
            origOrderWeightLB: item.lastKnown,
            dateEntered: formatDateToDefault(item.entered)
          };
          i.push(itemWeight);
        });
        setApiWeights([...a]);
        setItemWeights([...i]);
      } catch (err) {
        console.log('marty nursingProcess patientWeights err', err);
        setDialogOption({
          title: 'Infusion: Assessment',
          message: 'Error: nursingProcess patientWeights',
          showDialog: true
        });
      }
    }

    if (
      nursingProcess &&
      nursingProcess.stepAssessment &&
      nursingProcess.stepAssessment.vitals
    ) {
      try {
        const a = [];
        const i = [];
        nursingProcess.stepAssessment.vitals.map((item, index) => {
          const apiVital = {
            recordNumber: 1,
            enteredAt: moment(new Date(item.R)),
            temperature: item.temperature,
            bloodPressure: item.bloodPressure,
            heartRate: item.heartRate,
            R: item.R,
            SP02: item.SP02,
            initials: item.initials
          };
          a.push(apiVital);
          const itemVital = {
            temp: item.temperature,
            bp: item.bloodPressure,
            hr: item.heartRate,
            r: item.R,
            spo2: item.SP02,
            initials: item.initials,
            time: formatTimeToDefault(item.enteredAt),
            id: index
          };
          i.push(itemVital);
        });
        setApiVitals([...a]);
        setItemVitals([...i]);
      } catch (err) {
        console.log('marty nursingProcess vitals err', err);
        setDialogOption({
          title: 'Infusion: Assessment',
          message: 'Error: nursingProcess vitals',
          showDialog: true
        });
      }
    }

    // if (
    //   nursingProcess &&
    //   nursingProcess.stepAssessment &&
    //   nursingProcess.stepAssessment.allergies
    // ) {
    //   try {
    //     let a = [];
    //     let i = [];
    //     nursingProcess.stepAssessment.allergies.map(item => {
    //       let apiAllergy = {
    //         recordNumber: 1,
    //         entered: formatDateToAWS(item.entered),
    //         allergen: item.allergen,
    //         reaction: item.reaction
    //       };
    //       a.push(apiAllergy);
    //       let itemAllergy = {
    //         allergyDate: formatDateToDefault(item.entered),
    //         allergyDrugName: item.allergen,
    //         allergyReaction: item.reaction,
    //         allergyInitials: "" //item.initials,
    //       };
    //       i.push(itemAllergy);
    //     });
    //     setApiAllergies([...a]);
    //     setItemAllergies([...i]);
    //   } catch (err) {
    //     console.log("marty nursingProcess allergies err", err);
    //     setDialogOption({
    //       title: "Infusion: Assessment",
    //       message: "Error: nursingProcess allergies",
    //       showDialog: true
    //     });
    //   }
    // } else if (
    //   // attempt to pre-populate allergies from Order Review step
    //   infusion &&
    //   infusion.updateStepOrderReviewResponse &&
    //   infusion.updateStepOrderReviewResponse.initStepAssessment &&
    //   infusion.updateStepOrderReviewResponse.initStepAssessment.allergies &&
    //   infusion.updateStepOrderReviewResponse.initStepAssessment.allergies.length
    // ) {
    //   try {
    //     let a = [];
    //     let i = [];
    //     infusion.updateStepOrderReviewResponse.initStepAssessment.allergies.map(
    //       item => {
    //         let apiAllergy = {
    //           recordNumber: 1,
    //           entered: formatDateToAWS(item.entered),
    //           allergen: item.allergen,
    //           reaction: item.reaction
    //         };
    //         a.push(apiAllergy);
    //         let itemAllergy = {
    //           allergyDate: formatDateToDefault(item.entered),
    //           allergyDrugName: item.allergen,
    //           allergyReaction: item.reaction,
    //           allergyInitials: "" //item.initials,
    //         };
    //         i.push(itemAllergy);
    //       }
    //     );
    //     setApiAllergies([...a]);
    //     setItemAllergies([...i]);
    //   } catch (err) {
    //     console.log("ilia pre-populate allergies err", err);
    //     setDialogOption({
    //       title: "Infusion: Assessment",
    //       message: "Error: nursingProcess allergies",
    //       showDialog: true
    //     });
    //   }
    // }

    if (
      nursingProcess &&
      nursingProcess.stepAssessment &&
      nursingProcess.stepAssessment.questionnaire &&
      nursingProcess.stepAssessment.questionnaire !== '[]'
    ) {
      try {
        const thisQ = JSON.parse(nursingProcess.stepAssessment.questionnaire);
        setTheQ(thisQ);
        setShowAssessments(true);
      } catch (err) {
        console.log('marty nursingProcess questionnaire err', err);
        setDialogOption({
          title: 'Infusion: Assessment',
          message: 'Error: nursingProcess questionnaire',
          showDialog: true
        });
      }
    }
  };

  const toggleQuestionSet1 = () => {
    setShowQuestionSet1(!showQuestionSet1);
  };
  const toggleQuestionSet2 = () => {
    setShowQuestionSet2(!showQuestionSet2);
  };
  const toggleQuestionSet3 = () => {
    setShowQuestionSet3(!showQuestionSet3);
  };
  const toggleQuestionSet4 = () => {
    setShowQuestionSet4(!showQuestionSet4);
  };
  const toggleQuestionSet5 = () => {
    setShowQuestionSet5(!showQuestionSet5);
  };
  const toggleQuestionSet6 = () => {
    setShowQuestionSet6(!showQuestionSet6);
  };
  const toggleQuestionSet7 = () => {
    setShowQuestionSet7(!showQuestionSet7);
  };
  const toggleQuestionSet8 = () => {
    setShowQuestionSet8(!showQuestionSet8);
  };
  const toggleQuestionSet9 = () => {
    setShowQuestionSet9(!showQuestionSet9);
  };
  const toggleQuestionSet10 = () => {
    setShowQuestionSet10(!showQuestionSet10);
  };
  const toggleQuestionSet11 = () => {
    setShowQuestionSet11(!showQuestionSet11);
  };
  const toggleQuestionSet12 = () => {
    setShowQuestionSet12(!showQuestionSet12);
  };

  const questionCheck = async (state) => {
    // Step 1: Get a count of all radio inputs in the question set
    const inputs = await document.getElementById('assessmentForm').elements;
    let count = 0;
    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].type == 'radio' && inputs[i].checked) {
        count++;
      }
    }
    // Step 2: Set the radio button count and open the set of questions
    switch (state) {
      case 'setQuestions1':
        setQuestions1(count);
        toggleQuestionSet1();
        break;
      case 'setQuestions2':
        setQuestions2(count);
        toggleQuestionSet2();
        break;
      case 'setQuestions3':
        setQuestions3(count);
        toggleQuestionSet3();
        break;
      case 'setQuestions4':
        setQuestions4(count);
        toggleQuestionSet4();
        break;
      case 'setQuestions5':
        setQuestions5(count);
        toggleQuestionSet5();
        break;
      case 'setQuestions6':
        setQuestions6(count);
        toggleQuestionSet6();
        break;
      case 'setQuestions7':
        setQuestions7(count);
        toggleQuestionSet7();
        break;
      case 'setQuestions8':
        setQuestions8(count);
        toggleQuestionSet8();
        break;
      case 'setQuestions9':
        setQuestions9(count);
        toggleQuestionSet9();
        break;
      case 'setQuestions10':
        setQuestions10(count);
        toggleQuestionSet10();
        break;
      case 'setQuestions11':
        setQuestions11(count);
        toggleQuestionSet11();
        break;
      case 'setQuestions12':
        setQuestions12(count);
        toggleQuestionSet12();
        break;
    }
  };

  const renderAssessment = () => {
    return (
      <>
        <div className="row col-md-11 mt-5">
          <div className="col-md-3">
            <Card
              style={{
                border: '1px solid #005282',
                textAlign: 'center',
                margin: '5px',
                alignItems: 'center'
              }}
            >
              <CardBody>
                <CardTitle>CONSTITUTIONAL</CardTitle>
              </CardBody>
              <CardActions>
                <span
                  className="k-button k-flat k-primary"
                  onClick={toggleQuestionSet1}
                >
                  {`Questions: (${question1Count} of 3)`}
                </span>
              </CardActions>
            </Card>
          </div>
          <WindowDialog
            title="CONSTITUTIONAL"
            width={850}
            height={350}
            initialTop={0}
            showDialog={showQuestionSet1}
            onClose={toggleQuestionSet1}
          >
            <div className="row col-md-12 mb-2">
              <div className="col-md-3">
                <big>Unexplained Fatigue:</big>
              </div>
              <div className="col-md-3">
                <Field
                  component={RadioGroup}
                  name="question1_1"
                  data={booleanChoices}
                  layout="horizontal"
                />
              </div>
              <div className="col-md-6">
                <Field
                  component={InputField}
                  name="question1_2"
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className="row col-md-12 mb-2">
              <div className="col-md-3">
                <big>Fever, Chills, Sweats:</big>
              </div>
              <div className="col-md-3">
                <Field
                  component={RadioGroup}
                  name="question1_3"
                  data={booleanChoices}
                  layout="horizontal"
                />
              </div>
              <div className="col-md-6">
                <Field
                  component={InputField}
                  name="question1_4"
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className="row col-md-12 mb-2">
              <div className="col-md-3">
                <big>Other:</big>
              </div>
              <div className="col-md-3">
                <Field
                  component={RadioGroup}
                  name="question1_5"
                  data={booleanChoices}
                  layout="horizontal"
                />
              </div>
              <div className="col-md-6">
                <Field
                  component={InputField}
                  name="question1_6"
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className="row p-3">
              <div className="col-12" style={{ textAlign: 'center' }}>
                <button
                  type="submit"
                  onClick={() => questionCheck('setQuestions1')}
                  className="k-button pageButton"
                >
                  Update
                </button>
              </div>
            </div>
          </WindowDialog>
          <div className="col-md-3">
            <Card
              style={{
                border: '1px solid #005282',
                textAlign: 'center',
                margin: '5px',
                alignItems: 'center'
              }}
            >
              <CardBody>
                <CardTitle>CARDIOVASCULAR</CardTitle>
              </CardBody>
              <CardActions>
                <span
                  className="k-button k-flat k-primary"
                  onClick={toggleQuestionSet2}
                >
                  {`Questions: (${question2Count} of 4)`}
                </span>
              </CardActions>
            </Card>
          </div>
          <WindowDialog
            title="CARDIOVASCULAR"
            width={850}
            height={350}
            initialTop={0}
            showDialog={showQuestionSet2}
            onClose={toggleQuestionSet2}
          >
            <div className="row col-md-12 mb-2">
              <div className="col-md-3">
                <big>High blood pressure:</big>
              </div>
              <div className="col-md-3">
                <Field
                  component={RadioGroup}
                  name="question2_1"
                  data={booleanChoices}
                  layout="horizontal"
                />
              </div>
              <div className="col-md-6">
                <Field
                  component={InputField}
                  name="question2_2"
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className="row col-md-12 mb-2">
              <div className="col-md-3">
                <big>Chest pain over heart:</big>
              </div>
              <div className="col-md-3">
                <Field
                  component={RadioGroup}
                  name="question2_3"
                  data={booleanChoices}
                  layout="horizontal"
                />
              </div>
              <div className="col-md-6">
                <Field
                  component={InputField}
                  name="question2_4"
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className="row col-md-12 mb-2">
              <div className="col-md-3">
                <big>Previous cardiac issues:</big>
              </div>
              <div className="col-md-3">
                <Field
                  component={RadioGroup}
                  name="question2_5"
                  data={booleanChoices}
                  layout="horizontal"
                />
              </div>
              <div className="col-md-6">
                <Field
                  component={InputField}
                  name="question2_6"
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className="row col-md-12 mb-2">
              <div className="col-md-3">
                <big>Other:</big>
              </div>
              <div className="col-md-3">
                <Field
                  component={RadioGroup}
                  name="question2_7"
                  data={booleanChoices}
                  layout="horizontal"
                />
              </div>
              <div className="col-md-6">
                <Field
                  component={InputField}
                  name="question2_8"
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className="row p-3">
              <div className="col-12" style={{ textAlign: 'center' }}>
                <button
                  type="submit"
                  onClick={() => questionCheck('setQuestions2')}
                  className="k-button pageButton"
                >
                  Update
                </button>
              </div>
            </div>
          </WindowDialog>
          <div className="col-md-3">
            <Card
              style={{
                border: '1px solid #005282',
                textAlign: 'center',
                margin: '5px',
                alignItems: 'center'
              }}
            >
              <CardBody>
                <CardTitle>NEUROLOGICAL</CardTitle>
              </CardBody>
              <CardActions>
                <span
                  className="k-button k-flat k-primary"
                  onClick={toggleQuestionSet3}
                >
                  {`Questions: (${question3Count} of 6)`}
                </span>
              </CardActions>
            </Card>
          </div>
          <WindowDialog
            title="NEUROLOGICAL"
            width={850}
            height={400}
            initialTop={0}
            showDialog={showQuestionSet3}
            onClose={toggleQuestionSet3}
          >
            <div className="row col-md-12 mb-2">
              <div className="col-md-3">
                <big>Headaches:</big>
              </div>
              <div className="col-md-3">
                <Field
                  component={RadioGroup}
                  name="question3_1"
                  data={booleanChoices}
                  layout="horizontal"
                />
              </div>
              <div className="col-md-6">
                <Field
                  component={InputField}
                  name="question3_2"
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className="row col-md-12 mb-2">
              <div className="col-md-3">
                <big>Double Vision:</big>
              </div>
              <div className="col-md-3">
                <Field
                  component={RadioGroup}
                  name="question3_3"
                  data={booleanChoices}
                  layout="horizontal"
                />
              </div>
              <div className="col-md-6">
                <Field
                  component={InputField}
                  name="question3_4"
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className="row col-md-12 mb-2">
              <div className="col-md-3">
                <big>Seizure:</big>
              </div>
              <div className="col-md-3">
                <Field
                  component={RadioGroup}
                  name="question3_5"
                  data={booleanChoices}
                  layout="horizontal"
                />
              </div>
              <div className="col-md-6">
                <Field
                  component={InputField}
                  name="question3_6"
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className="row col-md-12 mb-2">
              <div className="col-md-3">
                <big>Numbness, Tingling, Tremors:</big>
              </div>
              <div className="col-md-3">
                <Field
                  component={RadioGroup}
                  name="question3_7"
                  data={booleanChoices}
                  layout="horizontal"
                />
              </div>
              <div className="col-md-6">
                <Field
                  component={InputField}
                  name="question3_8"
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className="row col-md-12 mb-2">
              <div className="col-md-3">
                <big>Memory loss:</big>
              </div>
              <div className="col-md-3">
                <Field
                  component={RadioGroup}
                  name="question3_9"
                  data={booleanChoices}
                  layout="horizontal"
                />
              </div>
              <div className="col-md-6">
                <Field
                  component={InputField}
                  name="question3_10"
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className="row col-md-12 mb-2">
              <div className="col-md-3">
                <big>Other:</big>
              </div>
              <div className="col-md-3">
                <Field
                  component={RadioGroup}
                  name="question3_11"
                  data={booleanChoices}
                  layout="horizontal"
                />
              </div>
              <div className="col-md-6">
                <Field
                  component={InputField}
                  name="question3_12"
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className="row p-3">
              <div className="col-12" style={{ textAlign: 'center' }}>
                <button
                  type="submit"
                  onClick={() => questionCheck('setQuestions3')}
                  className="k-button pageButton"
                >
                  Update
                </button>
              </div>
            </div>
          </WindowDialog>
          <div className="col-md-3">
            <Card
              style={{
                border: '1px solid #005282',
                textAlign: 'center',
                margin: '5px',
                alignItems: 'center'
              }}
            >
              <CardBody>
                <CardTitle>SKIN</CardTitle>
              </CardBody>
              <CardActions>
                <span
                  className="k-button k-flat k-primary"
                  onClick={toggleQuestionSet4}
                >
                  {`Questions: (${question4Count} of 2)`}
                </span>
              </CardActions>
            </Card>
          </div>
          <WindowDialog
            title="SKIN"
            width={850}
            height={250}
            initialTop={0}
            showDialog={showQuestionSet4}
            onClose={toggleQuestionSet4}
          >
            <div className="row col-md-12 mb-2">
              <div className="col-md-3">
                <big>Wounds:</big>
              </div>
              <div className="col-md-3">
                <Field
                  component={RadioGroup}
                  name="question4_1"
                  data={booleanChoices}
                  layout="horizontal"
                />
              </div>
              <div className="col-md-6">
                <Field
                  component={InputField}
                  name="question4_2"
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className="row col-md-12 mb-2">
              <div className="col-md-3">
                <big>Other:</big>
              </div>
              <div className="col-md-3">
                <Field
                  component={RadioGroup}
                  name="question4_3"
                  data={booleanChoices}
                  layout="horizontal"
                />
              </div>
              <div className="col-md-6">
                <Field
                  component={InputField}
                  name="question4_4"
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className="row p-3">
              <div className="col-12" style={{ textAlign: 'center' }}>
                <button
                  type="submit"
                  onClick={() => questionCheck('setQuestions4')}
                  className="k-button pageButton"
                >
                  Update
                </button>
              </div>
            </div>
          </WindowDialog>
        </div>
        <div className="row col-md-11">
          <div className="col-md-3">
            <Card
              style={{
                border: '1px solid #005282',
                textAlign: 'center',
                margin: '5px',
                alignItems: 'center'
              }}
            >
              <CardBody>
                <CardTitle>EYES/EARS/NOSE/THROAT</CardTitle>
              </CardBody>
              <CardActions>
                <span
                  className="k-button k-flat k-primary"
                  onClick={toggleQuestionSet5}
                >
                  {`Questions: (${question5Count} of 4)`}
                </span>
              </CardActions>
            </Card>
          </div>
          <WindowDialog
            title="EYES/EARS/NOSE/THROAT"
            width={850}
            height={350}
            initialTop={0}
            showDialog={showQuestionSet5}
            onClose={toggleQuestionSet5}
          >
            <div className="row col-md-12 mb-2">
              <div className="col-md-3">
                <big>Dizziness:</big>
              </div>
              <div className="col-md-3">
                <Field
                  component={RadioGroup}
                  name="question5_1"
                  data={booleanChoices}
                  layout="horizontal"
                />
              </div>
              <div className="col-md-6">
                <Field
                  component={InputField}
                  name="question5_2"
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className="row col-md-12 mb-2">
              <div className="col-md-3">
                <big>Sore Throat:</big>
              </div>
              <div className="col-md-3">
                <Field
                  component={RadioGroup}
                  name="question5_3"
                  data={booleanChoices}
                  layout="horizontal"
                />
              </div>
              <div className="col-md-6">
                <Field
                  component={InputField}
                  name="question5_4"
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className="row col-md-12 mb-2">
              <div className="col-md-3">
                <big>Vision Problems:</big>
              </div>
              <div className="col-md-3">
                <Field
                  component={RadioGroup}
                  name="question5_5"
                  data={booleanChoices}
                  layout="horizontal"
                />
              </div>
              <div className="col-md-6">
                <Field
                  component={InputField}
                  name="question5_6"
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className="row col-md-12 mb-2">
              <div className="col-md-3">
                <big>Other:</big>
              </div>
              <div className="col-md-3">
                <Field
                  component={RadioGroup}
                  name="question5_7"
                  data={booleanChoices}
                  layout="horizontal"
                />
              </div>
              <div className="col-md-6">
                <Field
                  component={InputField}
                  name="question5_8"
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className="row p-3">
              <div className="col-12" style={{ textAlign: 'center' }}>
                <button
                  type="submit"
                  onClick={() => questionCheck('setQuestions5')}
                  className="k-button pageButton"
                >
                  Update
                </button>
              </div>
            </div>
          </WindowDialog>
          <div className="col-md-3">
            <Card
              style={{
                border: '1px solid #005282',
                textAlign: 'center',
                margin: '5px',
                alignItems: 'center'
              }}
            >
              <CardBody>
                <CardTitle>RESPIRATORY</CardTitle>
              </CardBody>
              <CardActions>
                <span
                  className="k-button k-flat k-primary"
                  onClick={toggleQuestionSet6}
                >
                  {`Questions: (${question6Count} of 5)`}
                </span>
              </CardActions>
            </Card>
          </div>
          <WindowDialog
            title="RESPIRATORY"
            width={850}
            height={350}
            initialTop={0}
            showDialog={showQuestionSet6}
            onClose={toggleQuestionSet6}
          >
            <div className="row col-md-12 mb-2">
              <div className="col-md-3">
                <big>Cough:</big>
              </div>
              <div className="col-md-3">
                <Field
                  component={RadioGroup}
                  name="question6_1"
                  data={booleanChoices}
                  layout="horizontal"
                />
              </div>
              <div className="col-md-6">
                <Field
                  component={InputField}
                  name="question6_2"
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className="row col-md-12 mb-2">
              <div className="col-md-3">
                <big>Shortness of breath:</big>
              </div>
              <div className="col-md-3">
                <Field
                  component={RadioGroup}
                  name="question6_3"
                  data={booleanChoices}
                  layout="horizontal"
                />
              </div>
              <div className="col-md-6">
                <Field
                  component={InputField}
                  name="question6_4"
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className="row col-md-12 mb-2">
              <div className="col-md-3">
                <big>Wheezing:</big>
              </div>
              <div className="col-md-3">
                <Field
                  component={RadioGroup}
                  name="question6_5"
                  data={booleanChoices}
                  layout="horizontal"
                />
              </div>
              <div className="col-md-6">
                <Field
                  component={InputField}
                  name="question6_6"
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className="row col-md-12 mb-2">
              <div className="col-md-3">
                <big>Chest pain, lungs:</big>
              </div>
              <div className="col-md-3">
                <Field
                  component={RadioGroup}
                  name="question6_7"
                  data={booleanChoices}
                  layout="horizontal"
                />
              </div>
              <div className="col-md-6">
                <Field
                  component={InputField}
                  name="question6_8"
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className="row col-md-12 mb-2">
              <div className="col-md-3">
                <big>Other:</big>
              </div>
              <div className="col-md-3">
                <Field
                  component={RadioGroup}
                  name="question6_9"
                  data={booleanChoices}
                  layout="horizontal"
                />
              </div>
              <div className="col-md-6">
                <Field
                  component={InputField}
                  name="question6_10"
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className="row p-3">
              <div className="col-12" style={{ textAlign: 'center' }}>
                <button
                  type="submit"
                  onClick={() => questionCheck('setQuestions6')}
                  className="k-button pageButton"
                >
                  Update
                </button>
              </div>
            </div>
          </WindowDialog>
          <div className="col-md-3">
            <Card
              style={{
                border: '1px solid #005282',
                textAlign: 'center',
                margin: '5px',
                alignItems: 'center'
              }}
            >
              <CardBody>
                <CardTitle>GENITAL/URINARY</CardTitle>
              </CardBody>
              <CardActions>
                <span
                  className="k-button k-flat k-primary"
                  onClick={toggleQuestionSet7}
                >
                  {`Questions: (${question7Count} of 4)`}
                </span>
              </CardActions>
            </Card>
          </div>
          <WindowDialog
            title="GENITAL/URINARY"
            width={850}
            height={350}
            initialTop={0}
            showDialog={showQuestionSet7}
            onClose={toggleQuestionSet7}
          >
            <div className="row col-md-12 mb-2">
              <div className="col-md-3">
                <big>Pain with urination:</big>
              </div>
              <div className="col-md-3">
                <Field
                  component={RadioGroup}
                  name="question7_1"
                  data={booleanChoices}
                  layout="horizontal"
                />
              </div>
              <div className="col-md-6">
                <Field
                  component={InputField}
                  name="question7_2"
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className="row col-md-12 mb-2">
              <div className="col-md-3">
                <big>Problems with frequency:</big>
              </div>
              <div className="col-md-3">
                <Field
                  component={RadioGroup}
                  name="question7_3"
                  data={booleanChoices}
                  layout="horizontal"
                />
              </div>
              <div className="col-md-6">
                <Field
                  component={InputField}
                  name="question7_4"
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className="row col-md-12 mb-2">
              <div className="col-md-3">
                <big>Catheter in use:</big>
              </div>
              <div className="col-md-3">
                <Field
                  component={RadioGroup}
                  name="question7_5"
                  data={booleanChoices}
                  layout="horizontal"
                />
              </div>
              <div className="col-md-6">
                <Field
                  component={InputField}
                  name="question7_6"
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className="row col-md-12 mb-2">
              <div className="col-md-3">
                <big>Other:</big>
              </div>
              <div className="col-md-3">
                <Field
                  component={RadioGroup}
                  name="question7_7"
                  data={booleanChoices}
                  layout="horizontal"
                />
              </div>
              <div className="col-md-6">
                <Field
                  component={InputField}
                  name="question7_8"
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className="row p-3">
              <div className="col-12" style={{ textAlign: 'center' }}>
                <button
                  type="submit"
                  onClick={() => questionCheck('setQuestions7')}
                  className="k-button pageButton"
                >
                  Update
                </button>
              </div>
            </div>
          </WindowDialog>
          <div className="col-md-3">
            <Card
              style={{
                border: '1px solid #005282',
                textAlign: 'center',
                margin: '5px',
                alignItems: 'center'
              }}
            >
              <CardBody>
                <CardTitle>MUSCULOSKELETAL</CardTitle>
              </CardBody>
              <CardActions>
                <span
                  className="k-button k-flat k-primary"
                  onClick={toggleQuestionSet8}
                >
                  {`Questions: (${question8Count} of 3)`}
                </span>
              </CardActions>
            </Card>
          </div>
          {showQuestionSet8 && (
            <WindowDialog
              title="MUSCULOSKELETAL"
              width={850}
              height={350}
              initialTop={0}
              showDialog
              onClose={toggleQuestionSet8}
            >
              <div className="row col-md-12 mb-2">
                <div className="col-md-3">
                  <big>Joint Pain:</big>
                </div>
                <div className="col-md-3">
                  <Field
                    component={RadioGroup}
                    name="question8_1"
                    data={booleanChoices}
                    layout="horizontal"
                  />
                </div>
                <div className="col-md-6">
                  <Field
                    component={InputField}
                    name="question8_2"
                    style={{ width: 375 }}
                  />
                </div>
              </div>
              <div className="row col-md-12 mb-2">
                <div className="col-md-3">
                  <big>Muscle weakness:</big>
                </div>
                <div className="col-md-3">
                  <Field
                    component={RadioGroup}
                    name="question8_3"
                    data={booleanChoices}
                    layout="horizontal"
                  />
                </div>
                <div className="col-md-6">
                  <Field
                    component={InputField}
                    name="question8_4"
                    style={{ width: 375 }}
                  />
                </div>
              </div>
              <div className="row col-md-12 mb-2">
                <div className="col-md-3">
                  <big>Other:</big>
                </div>
                <div className="col-md-3">
                  <Field
                    component={RadioGroup}
                    name="question8_5"
                    data={booleanChoices}
                    layout="horizontal"
                  />
                </div>
                <div className="col-md-6">
                  <Field
                    component={InputField}
                    name="question8_6"
                    style={{ width: 375 }}
                  />
                </div>
              </div>
              <div className="row p-3">
                <div className="col-12" style={{ textAlign: 'center' }}>
                  <button
                    type="submit"
                    onClick={() => questionCheck('setQuestions8')}
                    className="k-button pageButton"
                  >
                    Update
                  </button>
                </div>
              </div>
            </WindowDialog>
          )}
        </div>
        <div className="row col-md-11">
          <div className="col-md-3">
            <Card
              style={{
                border: '1px solid #005282',
                textAlign: 'center',
                margin: '5px',
                alignItems: 'center'
              }}
            >
              <CardBody>
                <CardTitle>GASTROINTESTINAL</CardTitle>
              </CardBody>
              <CardActions>
                <span
                  className="k-button k-flat k-primary"
                  onClick={toggleQuestionSet9}
                >
                  {`Questions: (${question9Count} of 4)`}
                </span>
              </CardActions>
            </Card>
          </div>
          <WindowDialog
            title="GASTROINTESTINAL"
            width={850}
            height={350}
            initialTop={0}
            showDialog={showQuestionSet9}
            onClose={toggleQuestionSet9}
          >
            <div className="row col-md-12 mb-2">
              <div className="col-md-3">
                <big>Nausea or vomiting:</big>
              </div>
              <div className="col-md-3">
                <Field
                  component={RadioGroup}
                  name="question9_1"
                  data={booleanChoices}
                  layout="horizontal"
                />
              </div>
              <div className="col-md-6">
                <Field
                  component={InputField}
                  name="question9_2"
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className="row col-md-12 mb-2">
              <div className="col-md-3">
                <big>Diarrhea:</big>
              </div>
              <div className="col-md-3">
                <Field
                  component={RadioGroup}
                  name="question9_3"
                  data={booleanChoices}
                  layout="horizontal"
                />
              </div>
              <div className="col-md-6">
                <Field
                  component={InputField}
                  name="question9_4"
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className="row col-md-12 mb-2">
              <div className="col-md-3">
                <big>Constipation:</big>
              </div>
              <div className="col-md-3">
                <Field
                  component={RadioGroup}
                  name="question9_5"
                  data={booleanChoices}
                  layout="horizontal"
                />
              </div>
              <div className="col-md-6">
                <Field
                  component={InputField}
                  name="question9_6"
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className="row col-md-12 mb-2">
              <div className="col-md-3">
                <big>Other:</big>
              </div>
              <div className="col-md-3">
                <Field
                  component={RadioGroup}
                  name="question9_7"
                  data={booleanChoices}
                  layout="horizontal"
                />
              </div>
              <div className="col-md-6">
                <Field
                  component={InputField}
                  name="question9_8"
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className="row p-3">
              <div className="col-12" style={{ textAlign: 'center' }}>
                <button
                  type="submit"
                  onClick={() => questionCheck('setQuestions9')}
                  className="k-button pageButton"
                >
                  Update
                </button>
              </div>
            </div>
          </WindowDialog>
          <div className="col-md-3">
            <Card
              style={{
                border: '1px solid #005282',
                textAlign: 'center',
                margin: '5px',
                alignItems: 'center'
              }}
            >
              <CardBody>
                <CardTitle>PSYCHOLOGICAL</CardTitle>
              </CardBody>
              <CardActions>
                <span
                  className="k-button k-flat k-primary"
                  onClick={toggleQuestionSet10}
                >
                  {`Questions: (${question10Count} of 4)`}
                </span>
              </CardActions>
            </Card>
          </div>
          <WindowDialog
            title="PSYCHOLOGICAL"
            width={850}
            height={350}
            initialTop={0}
            showDialog={showQuestionSet10}
            onClose={toggleQuestionSet10}
          >
            <div className="row col-md-12 mb-2">
              <div className="col-md-3">
                <big>Depression or Anxiety:</big>
              </div>
              <div className="col-md-3">
                <Field
                  component={RadioGroup}
                  name="question10_1"
                  data={booleanChoices}
                  layout="horizontal"
                />
              </div>
              <div className="col-md-6">
                <Field
                  component={InputField}
                  name="question10_2"
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className="row col-md-12 mb-2">
              <div className="col-md-3">
                <big>Insomnia:</big>
              </div>
              <div className="col-md-3">
                <Field
                  component={RadioGroup}
                  name="question10_3"
                  data={booleanChoices}
                  layout="horizontal"
                />
              </div>
              <div className="col-md-6">
                <Field
                  component={InputField}
                  name="question10_4"
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className="row col-md-12 mb-2">
              <div className="col-md-3">
                <big>Poor appetite:</big>
              </div>
              <div className="col-md-3">
                <Field
                  component={RadioGroup}
                  name="question10_5"
                  data={booleanChoices}
                  layout="horizontal"
                />
              </div>
              <div className="col-md-6">
                <Field
                  component={InputField}
                  name="question10_6"
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className="row col-md-12 mb-2">
              <div className="col-md-3">
                <big>Other:</big>
              </div>
              <div className="col-md-3">
                <Field
                  component={RadioGroup}
                  name="question10_7"
                  data={booleanChoices}
                  layout="horizontal"
                />
              </div>
              <div className="col-md-6">
                <Field
                  component={InputField}
                  name="question10_8"
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className="row p-3">
              <div className="col-12" style={{ textAlign: 'center' }}>
                <button
                  type="submit"
                  onClick={() => questionCheck('setQuestions10')}
                  className="k-button pageButton"
                >
                  Update
                </button>
              </div>
            </div>
          </WindowDialog>
          <div className="col-md-3">
            <Card
              style={{
                border: '1px solid #005282',
                textAlign: 'center',
                margin: '5px',
                alignItems: 'center'
              }}
            >
              <CardBody>
                <CardTitle>FEMALE</CardTitle>
              </CardBody>
              <CardActions>
                <span
                  className="k-button k-flat k-primary"
                  onClick={toggleQuestionSet11}
                >
                  {`Questions: (${question11Count} of 4)`}
                </span>
              </CardActions>
            </Card>
          </div>
          <WindowDialog
            title="FEMALE"
            width={850}
            height={350}
            initialTop={0}
            showDialog={showQuestionSet11}
            onClose={toggleQuestionSet11}
          >
            <div className="row col-md-12 mb-2">
              <div className="col-md-3">
                <big>Currently pregnant:</big>
              </div>
              <div className="col-md-3">
                <Field
                  component={RadioGroup}
                  name="question11_1"
                  data={booleanChoices}
                  layout="horizontal"
                />
              </div>
              <div className="col-md-6">
                <Field
                  component={InputField}
                  name="question11_2"
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className="row col-md-12 mb-2">
              <div className="col-md-3">
                <big>Menopause:</big>
              </div>
              <div className="col-md-3">
                <Field
                  component={RadioGroup}
                  name="question11_3"
                  data={booleanChoices}
                  layout="horizontal"
                />
              </div>
              <div className="col-md-6">
                <Field
                  component={InputField}
                  name="question11_4"
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className="row col-md-12 mb-2">
              <div className="col-md-3">
                <big>Hysterectomy:</big>
              </div>
              <div className="col-md-3">
                <Field
                  component={RadioGroup}
                  name="question11_5"
                  data={booleanChoices}
                  layout="horizontal"
                />
              </div>
              <div className="col-md-6">
                <Field
                  component={InputField}
                  name="question11_6"
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className="row col-md-12 mb-2">
              <div className="col-md-3">
                <big>Other:</big>
              </div>
              <div className="col-md-3">
                <Field
                  component={RadioGroup}
                  name="question11_7"
                  data={booleanChoices}
                  layout="horizontal"
                />
              </div>
              <div className="col-md-6">
                <Field
                  component={InputField}
                  name="question11_8"
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className="row p-3">
              <div className="col-12" style={{ textAlign: 'center' }}>
                <button
                  type="submit"
                  onClick={() => questionCheck('setQuestions11')}
                  className="k-button pageButton"
                >
                  Update
                </button>
              </div>
            </div>
          </WindowDialog>
          <div className="col-md-3">
            <Card
              style={{
                border: '1px solid #005282',
                textAlign: 'center',
                margin: '5px',
                alignItems: 'center'
              }}
            >
              <CardBody>
                <CardTitle>TYSABRI</CardTitle>
              </CardBody>
              <CardActions>
                <span
                  className="k-button k-flat k-primary"
                  onClick={toggleQuestionSet12}
                >
                  {`Questions: (${question12Count} of 2)`}
                </span>
              </CardActions>
            </Card>
          </div>
          <WindowDialog
            title="TYSABRI"
            width={850}
            height={350}
            initialTop={0}
            showDialog={showQuestionSet12}
            onClose={toggleQuestionSet12}
          >
            <div className="row col-md-12 mb-3">
              <div className="col-md-12">
                <big>
                  The Tysabri TOUCH pre-Infusion checklist has been completed
                  with the patient?
                </big>
              </div>
            </div>
            <div className="row col-md-12 mb-3">
              <div className="col-md-3">
                <Field
                  component={RadioGroup}
                  name="question12_1"
                  data={booleanChoices}
                  layout="horizontal"
                />
              </div>
              <div className="col-md-9">
                <Field
                  component={InputField}
                  name="question12_2"
                  style={{ width: 550 }}
                />
              </div>
            </div>
            <div className="row col-md-12 mb-3">
              <div className="col-md-12">
                <big>
                  The TOUCH Biogen authorization checklist has been verified and
                  confirmed for this patient and treatment date?
                </big>
              </div>
            </div>
            <div className="row col-md-12 mb-2">
              <div className="col-md-3">
                <Field
                  component={RadioGroup}
                  name="question12_3"
                  data={booleanChoices}
                  layout="horizontal"
                />
              </div>
              <div className="col-md-9">
                <Field
                  component={InputField}
                  name="question12_4"
                  style={{ width: 550 }}
                />
              </div>
            </div>
            <div className="row p-3">
              <div className="col-12" style={{ textAlign: 'center' }}>
                <button
                  type="submit"
                  onClick={() => questionCheck('setQuestions12')}
                  className="k-button pageButton"
                >
                  Update
                </button>
              </div>
            </div>
          </WindowDialog>
        </div>
      </>
    );
  };

  const handleAddWeight = (dataItem) => {
    // do not allow to add more than 1 record
    if (itemWeights.length && itemWeights.length > 0) {
      setDialogOption({
        title: 'Infusion: Assessment',
        message:
          'Patient weight was already provided. If you want to change that value - please, remove another record and add new one.',
        showDialog: true
      });

      return;
    }

    const newWeight = parseFloat(dataItem.weight);
    const newWeightKG = lb2kg(newWeight);
    const previousWeight =
      selectedPatientInfo.patientProfile.patientInfo.patientWeightLB;
    const changeInWeight = Math.round(newWeight - previousWeight);

    const apiWeight = {
      weight: newWeight,
      entered: formatDateToAWS(),
      unitOfWeight: 'LB'
    };

    const itemWeight = {
      patientWeightLB: newWeight,
      calcPatientWeightKG: newWeightKG,
      lastKnownWeightLB: previousWeight,
      calcChangeFromLastKnown: changeInWeight,
      origOrderWeightLB: previousWeight,
      dateEntered: getNow()
    };

    try {
      // setApiWeights([...apiWeights, apiWeight]);
      setApiWeights([apiWeight]);
      // setItemWeights([...itemWeights, itemWeight]);
      setItemWeights([itemWeight]);
    } catch (err) {
      console.log('marty handleAddWeight err', err);
      setDialogOption({
        title: 'Infusion: Assessment',
        message: 'Error: handleAddWeight',
        showDialog: true
      });
    }
  };

  const handleAddVitals = (dataItem) => {
    if (validateTimeRange(dataItem.time)) {
      return;
    }
    const apiVital = {
      recordNumber: 1,
      enteredAt: moment(new Date(dataItem.time)),
      temperature: dataItem.temp === '' ? null : dataItem.temp,
      bloodPressure: dataItem.bp,
      heartRate: dataItem.hr === '' ? null : dataItem.hr,
      R: dataItem.r === '' ? null : dataItem.r,
      SP02: dataItem.spo2 === '' ? null : dataItem.spo2,
      initials: dataItem.initials
    };

    const itemVital = {
      temp: dataItem.temp === '' ? null : dataItem.temp,
      bp: dataItem.bp,
      hr: dataItem.hr === '' ? null : dataItem.hr,
      r: dataItem.r === '' ? null : dataItem.r,
      spo2: dataItem.spo2 === '' ? null : dataItem.spo2,
      initials: dataItem.initials,
      time: moment(new Date(dataItem.time)).format('hh:mm A'),
      id: itemVitals.length
    };

    try {
      setApiVitals([...apiVitals, apiVital]);
      setItemVitals([...itemVitals, itemVital]);
    } catch (err) {
      console.log('marty handleAddVitals err', err);
      setDialogOption({
        title: 'Infusion: Assessment',
        message: 'Error: handleAddVitals',
        showDialog: true
      });
    }
  };

  // const handleAddAllergies = dataItem => {
  //   const { allergyDate, allergyDrugName, allergyReaction } = dataItem;

  //   if (!allergyDate || !allergyDrugName || !allergyReaction) {
  //     return setDialogOption({
  //       title: "Infusion: Assessment",
  //       message: Constants.ErrorMessage.allFields_REQUIRED,
  //       showDialog: true
  //     });
  //   }

  //   const apiAllergy = {
  //     recordNumber: 1,
  //     entered: formatDateToAWS(dataItem.allergyDate),
  //     allergen: dataItem.allergyDrugName,
  //     reaction: dataItem.allergyReaction
  //   };

  //   const itemAllergy = {
  //     allergyDate: formatDateToDefault(dataItem.allergyDate),
  //     allergyDrugName: dataItem.allergyDrugName,
  //     allergyReaction: dataItem.allergyReaction,
  //     allergyInitials: dataItem.allergyInitials
  //   };

  //   try {
  //     setApiAllergies([...apiAllergies, apiAllergy]);
  //     setItemAllergies([...itemAllergies, itemAllergy]);
  //   } catch (err) {
  //     console.log("marty handleAddAllergies err", err);
  //     setDialogOption({
  //       title: "Infusion: Assessment",
  //       message: "Error: handleAddAllergies",
  //       showDialog: true
  //     });
  //   }
  // };

  // const handleRevisitSubmit = () => {
  //   const requestObject = {
  //     // STEP 2
  //     // input UpdateStepAssessmentInput {
  //     // updateStepAssessmentInput: {
  //     // nursingProcessId: ID!
  //     nursingProcessId: nursingProcess.id,
  //     // agentId: ID!
  //     agentId: nursingProcess.startedBy,
  //     // patientWeights: [PatientWeightRecordInput]!
  //     patientWeights: apiWeights, //itemWeights,
  //     // vitals: [VitalRecordInput]!
  //     vitals: apiVitals, //itemVitals,
  //     // allergies: [AllergyRecordInput]!
  //     // allergies: apiAllergies, //itemAllergies,
  //     // noAssessmentToday: Boolean
  //     noAssessmentToday: nursingProcess.stepAssessment.noAssessmentToday,
  //     // @NOTE: removed as part of updating step, now the notes live alone
  //     // notes: [String]
  //     // notes: nursingProcess.notes,
  //     // questionnaire: AWSJSON
  //     questionnaire: nursingProcess.stepAssessment.questionnaire
  //   };

  //   props.sendDataToParent(requestObject);
  // };

  const handleSubmit = (dataItem) => {
    let questionnaire = JSON.stringify([]);
    // if (dataItem.values?.assessmentsOn) {
    if (showAssessments) {
      questionnaire = setQuestionnaire(dataItem.values);
    }
    let isError = false;
    let errorMessage = Constants.ErrorMessage.allFields_REQUIRED;
    if (apiVitals.length > 0) {
      apiVitals.forEach((item) => {
        // if(!!validateEmptyFloat(item.temperature) || !!validateEmptyFloat(item.heartRate) || !!validateEmptyFloat(item.SP02) || !!validateEmptyFloat(item.R)) {
        //   isError = true;
        //   return false;
        // }
        if (!item.temperature) {
          item.temperature = 0;
        }
        if (!item.heartRate) {
          item.heartRate = 0;
        }
        if (!item.R) {
          item.R = 0;
        }
        if (!item.SP02) {
          item.SP02 = 0;
        }
        if (validateEmptyFloat(item.temperature)) {
          isError = true;
          errorMessage = Constants.ErrorMessage.temp_Value;
          return false;
        }
        if (validateEmptyFloat(item.heartRate)) {
          isError = true;
          errorMessage = Constants.ErrorMessage.heartrate_Value;
          return false;
        }
        if (validateEmptyFloat(item.SP02)) {
          isError = true;
          errorMessage = Constants.ErrorMessage.spo2_Value;
          return false;
        }
        if (validateEmptyFloat(item.R)) {
          isError = true;
          errorMessage = Constants.ErrorMessage.r_Value;
          return false;
        }
      });
    }

    if (isError) {
      setDialogOption({
        title: 'Vital: Error',
        message: errorMessage,
        showDialog: true
      });
      return;
    }

    const requestObject = {
      // STEP 2
      // input UpdateStepAssessmentInput {
      // updateStepAssessmentInput: {
      // nursingProcessId: ID!
      nursingProcessId:
        nursingProcessId ||
        infusion.updateStepOrderReviewInput.nursingProcessId ||
        '',
      // agentId: ID!
      agentId: infusion.stepCheckInInput.agentId, // agent.agentId, //user.username,
      // patientWeights: [PatientWeightRecordInput]!
      patientWeights: apiWeights, // itemWeights,
      // vitals: [VitalRecordInput]!
      vitals: apiVitals, // itemVitals,
      // allergies: [AllergyRecordInput]!
      // allergies: apiAllergies, //itemAllergies,
      // noAssessmentToday: Boolean
      noAssessmentToday: !dataItem.values?.assessmentsOn,
      // @NOTE: removed as part of updating step, now the notes live alone
      // notes: [String]
      // notes: restoreNotes(),
      // questionnaire: AWSJSON
      questionnaire
      // },
    };

    props.sendDataToParent(requestObject);
  };

  const handleDeleteClick = (props, object) => {
    if (props.dataIndex > -1) {
      if (object === 'weight') {
        // alert(`DELETE ${object}: ${props.dataIndex}`)
        if (props.dataIndex > -1) {
          const cloneApiWeights = [...apiWeights];
          cloneApiWeights.splice(props.dataIndex, 1);
          setApiWeights(cloneApiWeights);
          const cloneItemWeights = [...itemWeights];
          cloneItemWeights.splice(props.dataIndex, 1);
          setItemWeights(cloneItemWeights);
        }
      }
      if (object === 'vitals') {
        // alert(`DELETE ${object}: ${props.dataIndex}`)
        if (props.dataIndex > -1) {
          const cloneApiVitals = [...apiVitals];
          cloneApiVitals.splice(props.dataIndex, 1);
          setApiVitals(cloneApiVitals);
          const cloneItemVitals = [...itemVitals];
          cloneItemVitals.splice(props.dataIndex, 1);
          setItemVitals(cloneItemVitals);
        }
      }
      // if (object === "allergy") {
      //   //alert(`DELETE ${object}: ${props.dataIndex}`)
      //   if (props.dataIndex > -1) {
      //     const cloneApiAllergies = [...apiAllergies];
      //     cloneApiAllergies.splice(props.dataIndex, 1);
      //     setApiAllergies(cloneApiAllergies);
      //     const cloneItemAllergies = [...itemAllergies];
      //     cloneItemAllergies.splice(props.dataIndex, 1);
      //     setItemAllergies(cloneItemAllergies);
      //   }
      // }
    }
  };

  // const customCellDeleteWeight = props => {
  //   if (!itemWeights || itemWeights.length === 0) {
  //     return <td>&nbsp;</td>;
  //   }

  //   if (props.dataIndex !== 0) return <td>&nbsp;</td>;

  //   return (
  //     <td>
  //       <button
  //         type="button"
  //         className="k-button"
  //         onClick={() => handleDeleteClick(props, "weight")}
  //       >
  //         X
  //       </button>
  //     </td>
  //   );
  // };

  const customCellDeleteVitals = (props) => {
    return (
      <td>
        <DeleteButton handleClick={() => handleDeleteClick(props, 'vitals')} />
        {/* <button
          type="button"
          className="k-button"
          onClick={() => handleDeleteClick(props, "vitals")}
        >
          X
        </button> */}
      </td>
    );
  };

  // const customCellDeleteAllergy = props => {
  //   return (
  //     <td>
  //       <button
  //         type="button"
  //         className="k-button"
  //         onClick={() => handleDeleteClick(props, "allergy")}
  //       >
  //         X
  //       </button>
  //     </td>
  //   );
  // };

  const patientWeightLB = getPatientWeightFromReferralLB(infusionFormData.referralOrder);

  const validateEmptyFloat = (value) => {
    if (!value || value === '') return '';
    return validateFloat(value) ? '' : Constants.ErrorMessage.Numeric_Required;
  };

  const validateTimeRange = (value) => {
    // field is now required when vitals are entered
    if (!value || value === '') {
      setIsTimeRangeError(true);
      return true;
    }
    setIsTimeRangeError(!moment(value).isBetween(getAdminMinTime(), getAdminMaxTime()));
    return moment(value).isBetween(getAdminMinTime(), getAdminMaxTime())
      ? ''
      : true;
  };

  const [editField, setEditField] = React.useState(undefined);

  const enterEdit = (dataItem, field) => {
    const newData = itemVitals.map((item) => ({
      ...item,
      inEdit: item.id === dataItem.id ? field : undefined
    }));
    setItemVitals(newData);
    setEditField(field);
  };

  const exitEdit = () => {
    const newData = itemVitals.map((item) => ({ ...item, inEdit: undefined }));
    setItemVitals(newData);
    setEditField(undefined);
  };

  // const saveChanges = () => {
  //   itemVitals.splice(0, itemVitals.length, ...itemVitals);
  //   setEditField(undefined);
  //   setChanges(false);
  // };

  // const cancelChanges = () => {
  //   setItemVitals(itemVitals);
  //   setChanges(false);
  // };

  const itemChange = (event) => {
    const field = event.field || '';
    event.dataItem[field] = event.value;
    const newData = itemVitals.map((item) => {
      if (item.id === event.dataItem.id) {
        item[field] = event.value;
      }
      return item;
    });
    const copyApiVitals = [...apiVitals];
    const apiVitalRow = copyApiVitals.find((item, index) => index === event.dataItem.id);
    let isError = false;
    if (apiVitalRow) {
      if (field === 'temp') {
        isError = isNaN(event.value);
        // if(!isError) {
        apiVitalRow.temperature = event.value;
        if (isError) {
          setDialogOption({
            title: 'Vital: Error',
            message: Constants.ErrorMessage.temp_Value,
            showDialog: true
          });
        }
        // }
      } else if (field === 'time' && event.value.split(':').length > 1) {
        const now = new Date();
        apiVitalRow.enteredAt = moment(`${now.getMonth() + 1}/${now.getDate()}/${now.getFullYear()}`)
          .add(
            event.value.endsWith('AM')
              ? event.value.split(':')[0]
              : +event.value.split(':')[0] + 12,
            'hours'
          )
          .add(event.value.split(':')[1].slice(0, 2), 'minutes');
      } else if (field === 'bp') {
        apiVitalRow.bloodPressure = event.value;
      } else if (field === 'hr') {
        isError = isNaN(event.value);
        // if(!isError) {
        apiVitalRow.heartRate = event.value;
        if (isError) {
          setDialogOption({
            title: 'Vital: Error',
            message: Constants.ErrorMessage.heartrate_Value,
            showDialog: true
          });
        }
        // }
      } else if (field === 'r') {
        isError = isNaN(event.value);
        //  if(!isError) {
        apiVitalRow.R = event.value;
        if (isError) {
          setDialogOption({
            title: 'Vital: Error',
            message: Constants.ErrorMessage.r_Value,
            showDialog: true
          });
        }
        // }
      } else if (field === 'spo2') {
        isError = isNaN(event.value);
        // if(!isError) {
        apiVitalRow.SP02 = event.value;
        if (isError) {
          setDialogOption({
            title: 'Vital: Error',
            message: Constants.ErrorMessage.spo2_Value,
            showDialog: true
          });
        }
        //  }
      } else if (field === 'initials') {
        apiVitalRow.initials = event.value;
      }
    }
    // if(isError) {
    //   setDialogOption({
    //     title: "Vital: Error",
    //     message: Constants.ErrorMessage.allFields_REQUIRED,
    //     showDialog: true
    //   });
    // }
    setApiVitals([...copyApiVitals]);
    setItemVitals(newData);
    // setChanges(true);
  };
  const customCellRender = (td, props) => (
    <CellRender
      originalProps={props}
      td={td}
      enterEdit={enterEdit}
      editField={editField}
    />
  );

  const customRowRender = (tr, props) => (
    <RowRender
      originalProps={props}
      tr={tr}
      exitEdit={exitEdit}
      editField={editField}
    />
  );

  // const weightLbCell = () => {
  //   return <AwesomeLabel icon="weight-hanging" label="Weight (lbs)" />;
  // };

  // const weightKgCell = () => {
  //   return <AwesomeLabel icon="weight-hanging" label="Weight (kgs)" />;
  // };

  // const weightDateCell = () => {
  //   return <AwesomeLabel icon="calendar" label="Entered Date" />;
  // };

  const renderWeightForm = () => {
    if (props.infusionRestricted) {
      const hasBeenWeightEntered = itemWeights.length > 0;

      const renderWeightInfo = () => {
        if (!hasBeenWeightEntered) {
          return (
            <Badge
              text="Patient weight has not been updated during the infusion"
              type="warning"
            />
          );
        }

        const enteredWeight =
          itemWeights[itemWeights.length - 1].patientWeightLB;

        return (
          <>
            Patient Weight:{' '}
            <span style={{ color: 'red' }}>{enteredWeight}</span> lb
          </>
        );
      };

      return (
        <div className="row">
          <div className="col-md-12">
            <div>
              <h5>{renderWeightInfo()}</h5>
            </div>
          </div>
        </div>
      );
    }

    if (itemWeights.length === 0) {
      return (
        <>
          <div className="row">
            <div className="col-md-5">
              <h5>Current Weight (lb):</h5>
            </div>
            <div className="col-md-4">
              <Field
                name="weight"
                label=""
                component={InputField}
                placeholder={
                  selectedPatientInfo?.patientProfile?.patientInfo
                    ?.patientWeightLB
                }
                defaultValue={patientWeightLB}
              />
            </div>
            <div className="col-md-3">
              <button type="submit" className="k-button blue">
                ADD
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mt-12 mb-3">
              <Badge
                text="If patient weight changed since last check — please, provide the updated value here"
                type="warning"
              />
            </div>
          </div>
        </>
      );
    }

    const hasEnteredDrugs =
      nursingProcess.stepPreparation?.drugs &&
      nursingProcess.stepPreparation?.drugs.length > 0;

    return (
      <div className="row">
        <div className="col-md-12">
          <div>
            <h5>
              Entered Weight:{' '}
              <span style={{ color: 'red' }}>
                {itemWeights[itemWeights.length - 1].patientWeightLB}
              </span>{' '}
              lb
            </h5>
          </div>
          {!props.infusionCompleted && (
            <span
              className="remove-label"
              onClick={() => {
                if (hasEnteredDrugs) {
                  showWarning('Remove selected drugs on Prep step in order to update patient weight value');
                } else {
                  setItemWeights([]);
                  setApiWeights([]);
                }
              }}
            >
              <AwesomeLabel label="Remove" icon="xmark" />
            </span>
          )}
        </div>
      </div>
    );
  };

  // if (props.infusionRestricted) {
  //   return (
  //     <RevisitAssessment
  //       onSubmit={handleRevisitSubmit}
  //       itemWeights={itemWeights}
  //       handleAddVitals={handleAddVitals}
  //       customCellDeleteVitals={customCellDeleteVitals}
  //       itemVitals={itemVitals}
  //       // itemAllergies={itemAllergies}
  //       showAssessments={showAssessments}
  //       theQ={theQ}
  //     />
  //   );
  // }

  return (
    <div className="infusion-page">
      {dialogOption && dialogOption.showDialog && (
        <MessageDialog dialogOption={dialogOption} />
      )}

      {stepAllowed && (
        <>
          <Form
            onSubmit={handleAddWeight}
            render={(formRenderProps) => (
              <form
                onSubmit={formRenderProps.onSubmit}
                className="k-form pl-3 pr-3 pt-1"
              >
                {/* PATIENT WEIGHT */}
                <div
                  className="infusion-details col-md-11 mt-2 mb-3"
                  style={{ border: '1px solid #afaaaa' }}
                >
                  <div className="row">
                    <div className="infusion-HeaderRow col-12 ml-0 pl-2 py-2 mr-0">
                      <div className="row">
                        <div className="col-md-2 headerText">
                          PATIENT WEIGHT
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-5 ml-0 mt-16">
                      {renderWeightForm()}
                    </div>
                    <div className="col-md-7 mt-12 mb-3">
                      <h5>Patient Weight Log</h5>
                      <PatientWeightLog patientInfo={selectedPatientInfo} />
                    </div>
                  </div>
                </div>
              </form>
            )}
          />

          <Form
            onSubmit={handleAddVitals}
            render={(formRenderProps) => (
              <form
                onSubmit={formRenderProps.onSubmit}
                className="k-form pl-3 pr-3 pt-1"
              >
                {/* VITALS */}

                <div
                  className="infusion-details col-md-11 mt-2 mb-3"
                  style={{ border: '1px solid #afaaaa' }}
                >
                  <div className="row">
                    <div className="infusion-HeaderRow col-12 ml-0 pl-2 py-2 mr-0">
                      <div className="row">
                        <div className="col-md-2 headerText">VITALS</div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-2 ml-3">
                      Time:
                      <br />
                      <Field
                        name="time"
                        label=""
                        // validator={validateTimeRange}
                        component={TimePicker}
                      />
                      {isTimeRangeError && (
                        <Error>
                          {Constants.ErrorMessage.Admin_Timer_Range}
                        </Error>
                      )}
                    </div>
                    <div className="col-md-1">
                      <Field
                        name="temp"
                        label="Temp"
                        style={{ width: 75 }}
                        component={Input}
                        validator={validateEmptyFloat}
                      />
                    </div>
                    <div className="col-md-1">
                      <Field
                        name="bp"
                        label="BP"
                        style={{ width: 75 }}
                        component={Input}
                      />
                    </div>
                    <div className="col-md-1">
                      <Field
                        name="hr"
                        label="HR"
                        style={{ width: 75 }}
                        component={Input}
                        validator={validateEmptyFloat}
                      />
                    </div>
                    <div className="col-md-1">
                      <Field
                        name="r"
                        label="R"
                        style={{ width: 75 }}
                        component={Input}
                        validator={validateEmptyFloat}
                      />
                    </div>
                    <div className="col-md-1">
                      <Field
                        name="spo2"
                        label="SPO2"
                        style={{ width: 75 }}
                        component={Input}
                        validator={validateEmptyFloat}
                      />
                    </div>
                    <div className="col-md-1">
                      <Field
                        name="initials"
                        label="INITIALS"
                        style={{ width: 75 }}
                        component={Input}
                      />
                    </div>
                    <div className="col-md-2 mt-12">
                      <button type="submit" className="k-button blue">
                        ADD
                      </button>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 mt-12 mb-2">
                      <Grid
                        className="infusion-grid"
                        data={itemVitals}
                        onItemChange={itemChange}
                        cellRender={customCellRender}
                        rowRender={customRowRender}
                        editField="inEdit"
                      >
                        <Column field="time" title="TIME" width="140px" />
                        <Column field="temp" title="TEMP" width="140px" />
                        <Column field="bp" title="BP" width="140px" />
                        <Column field="hr" title="HR" width="140px" />
                        <Column field="r" title="R" width="140px" />
                        <Column field="spo2" title="SPO2" width="140px" />
                        <Column
                          field="initials"
                          title="INITIALS"
                          width="140px"
                        />
                        <Column
                          field="action"
                          title=" "
                          cell={customCellDeleteVitals}
                        />
                      </Grid>
                    </div>
                  </div>
                </div>
              </form>
            )}
          />

          {/** New Allergies and Current Meds - reference, readonly */}

          <div className="k-form pl-3 pr-3 pt-1">
            <div
              className="infusion-details col-md-11 mt-2 mb-3"
              style={{
                border: '1px solid #afaaaa',
                backgroundColor: '#ffffff'
              }}
            >
              <div className="row">
                <div className="infusion-SubHeaderRowGrey col-12 ml-0 pl-1 py-2 mr-0">
                  <div className="row">
                    <div className="col-md-6 headerText">ALLERGIES</div>
                    <div className="col-md-5 headerText">
                      CURRENT MEDICATIONS
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mt-3 mb-3">
                  <PatientAllergiesLog />
                </div>
                <div className="col-md-6 mt-3 mb-3">
                  <PatientMedsLog />
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-md-12">
                  <PatientLogInfo />
                </div>
              </div>
            </div>
          </div>

          <Form
            onSubmitClick={handleSubmit}
            initialValues={theQ}
            render={({ onSubmit }) => (
              <form
                id="assessmentForm"
                onSubmit={onSubmit}
                className="k-form pl-3 pr-3 pt-1"
              >
                {/* ASSESSMENTS SWITCH */}

                <div className="row mt-16">
                  <div className="col-md-12">
                    Assessments Today &nbsp;
                    <Field
                      component={Switch}
                      name="assessmentsOn"
                      defaultChecked={showAssessments}
                      onLabel="Yes"
                      offLabel="No"
                      onChange={(event) => setShowAssessments(event.value)}
                    />
                  </div>
                </div>
                {showAssessments && <>{renderAssessment()}</>}

                {/* SUBMIT FORM */}

                <div className="row mt-5 mb-5">
                  <div className="col-12">
                    <button type="submit" className="k-button pageButton">
                      Save
                    </button>
                  </div>
                </div>

                <div style={{ height: '200px' }}>{/* SPACER */}</div>
              </form>
            )}
          />
        </>
      )}
    </div>
  );
};

const setQuestionnaire = (dataItem) => {
  const questions =
    // [
    {
      // 	question: 1,
      // 	title: "CONSTITUTIONAL",
      question1_1: dataItem.question1_1
        ? dataItem.question1_1
        : dataItem.question1_1 === false
        ? false
        : null, // Boolean
      question1_2: dataItem.question1_2 ? dataItem.question1_2 : null, // String
      question1_3: dataItem.question1_3
        ? dataItem.question1_3
        : dataItem.question1_3 === false
        ? false
        : null, // Boolean
      question1_4: dataItem.question1_4 ? dataItem.question1_4 : null, // String
      question1_5: dataItem.question1_5
        ? dataItem.question1_5
        : dataItem.question1_5 === false
        ? false
        : null, // Boolean
      question1_6: dataItem.question1_6 ? dataItem.question1_6 : null, // String
      // },
      // {
      // 	question: 2,
      // 	title: "CARDIOVASCULAR",
      question2_1: dataItem.question2_1
        ? dataItem.question2_1
        : dataItem.question2_1 === false
        ? false
        : null, // Boolean
      question2_2: dataItem.question2_2 ? dataItem.question2_2 : null, // String
      question2_3: dataItem.question2_3
        ? dataItem.question2_3
        : dataItem.question2_3 === false
        ? false
        : null, // Boolean
      question2_4: dataItem.question2_4 ? dataItem.question2_4 : null, // String
      question2_5: dataItem.question2_5
        ? dataItem.question2_5
        : dataItem.question2_5 === false
        ? false
        : null, // Boolean
      question2_6: dataItem.question2_6 ? dataItem.question2_6 : null, // String
      question2_7: dataItem.question2_7
        ? dataItem.question2_7
        : dataItem.question2_7 === false
        ? false
        : null, // Boolean
      question2_8: dataItem.question2_8 ? dataItem.question2_8 : null, // String
      // },
      // {
      // 	question: 3,
      // 	title: "NEUROLOGICAL",
      question3_1: dataItem.question3_1
        ? dataItem.question3_1
        : dataItem.question3_1 === false
        ? false
        : null, // Boolean
      question3_2: dataItem.question3_2 ? dataItem.question3_2 : null, // String
      question3_3: dataItem.question3_3
        ? dataItem.question3_3
        : dataItem.question3_3 === false
        ? false
        : null, // Boolean
      question3_4: dataItem.question3_4 ? dataItem.question3_4 : null, // String
      question3_5: dataItem.question3_5
        ? dataItem.question3_5
        : dataItem.question3_5 === false
        ? false
        : null, // Boolean
      question3_6: dataItem.question3_6 ? dataItem.question3_6 : null, // String
      question3_7: dataItem.question3_7
        ? dataItem.question3_7
        : dataItem.question3_7 === false
        ? false
        : null, // Boolean
      question3_8: dataItem.question3_8 ? dataItem.question3_8 : null, // String
      question3_9: dataItem.question3_9
        ? dataItem.question3_9
        : dataItem.question3_9 === false
        ? false
        : null, // Boolean
      question3_10: dataItem.question3_10 ? dataItem.question3_10 : null, // String
      question3_11: dataItem.question3_11
        ? dataItem.question3_11
        : dataItem.question3_11 === false
        ? false
        : null, // Boolean
      question3_12: dataItem.question3_12 ? dataItem.question3_12 : null, // String
      // },
      // {
      // 	question: 4,
      // 	title: "SKIN",
      question4_1: dataItem.question4_1
        ? dataItem.question4_1
        : dataItem.question4_1 === false
        ? false
        : null, // Boolean
      question4_2: dataItem.question4_2 ? dataItem.question4_2 : null, // String
      question4_3: dataItem.question4_3
        ? dataItem.question4_3
        : dataItem.question4_3 === false
        ? false
        : null, // Boolean
      question4_4: dataItem.question4_4 ? dataItem.question4_4 : null, // String
      // },
      // {
      // 	question: 5,
      // 	title: "EYES/EARS/NOSE/THROAT",
      question5_1: dataItem.question5_1
        ? dataItem.question5_1
        : dataItem.question5_1 === false
        ? false
        : null, // Boolean
      question5_2: dataItem.question5_2 ? dataItem.question5_2 : null, // String
      question5_3: dataItem.question5_3
        ? dataItem.question5_3
        : dataItem.question5_3 === false
        ? false
        : null, // Boolean
      question5_4: dataItem.question5_4 ? dataItem.question5_4 : null, // String
      question5_5: dataItem.question5_5
        ? dataItem.question5_5
        : dataItem.question5_5 === false
        ? false
        : null, // Boolean
      question5_6: dataItem.question5_6 ? dataItem.question5_6 : null, // String
      question5_7: dataItem.question5_7
        ? dataItem.question5_7
        : dataItem.question5_7 === false
        ? false
        : null, // Boolean
      question5_8: dataItem.question5_8 ? dataItem.question5_8 : null, // String
      // },
      // {
      // 	question: 6,
      // 	title: "RESPIRATORY",
      question6_1: dataItem.question6_1
        ? dataItem.question6_1
        : dataItem.question6_1 === false
        ? false
        : null, // Boolean
      question6_2: dataItem.question6_2 ? dataItem.question6_2 : null, // String
      question6_3: dataItem.question6_3
        ? dataItem.question6_3
        : dataItem.question6_3 === false
        ? false
        : null, // Boolean
      question6_4: dataItem.question6_4 ? dataItem.question6_4 : null, // String
      question6_5: dataItem.question6_5
        ? dataItem.question6_5
        : dataItem.question6_5 === false
        ? false
        : null, // Boolean
      question6_6: dataItem.question6_6 ? dataItem.question6_6 : null, // String
      question6_7: dataItem.question6_7
        ? dataItem.question6_7
        : dataItem.question6_7 === false
        ? false
        : null, // Boolean
      question6_8: dataItem.question6_8 ? dataItem.question6_8 : null, // String
      question6_9: dataItem.question6_9
        ? dataItem.question6_9
        : dataItem.question6_9 === false
        ? false
        : null, // Boolean
      question6_10: dataItem.question6_10 ? dataItem.question6_10 : null, // String
      // },
      // {
      // 	question: 7,
      // 	title: "GENITAL/URINARY",
      question7_1: dataItem.question7_1
        ? dataItem.question7_1
        : dataItem.question7_1 === false
        ? false
        : null, // Boolean
      question7_2: dataItem.question7_2 ? dataItem.question7_2 : null, // String
      question7_3: dataItem.question7_3
        ? dataItem.question7_3
        : dataItem.question7_3 === false
        ? false
        : null, // Boolean
      question7_4: dataItem.question7_4 ? dataItem.question7_4 : null, // String
      question7_5: dataItem.question7_5
        ? dataItem.question7_5
        : dataItem.question7_5 === false
        ? false
        : null, // Boolean
      question7_6: dataItem.question7_6 ? dataItem.question7_6 : null, // String
      question7_7: dataItem.question7_7
        ? dataItem.question7_7
        : dataItem.question7_7 === false
        ? false
        : null, // Boolean
      question7_8: dataItem.question7_8 ? dataItem.question7_8 : null, // String
      // },
      // {
      // 	question: 8,
      // 	title: "MUSCULOSKELETAL",
      question8_1: dataItem.question8_1
        ? dataItem.question8_1
        : dataItem.question8_1 === false
        ? false
        : null, // Boolean
      question8_2: dataItem.question8_2 ? dataItem.question8_2 : null, // String
      question8_3: dataItem.question8_3
        ? dataItem.question8_3
        : dataItem.question8_3 === false
        ? false
        : null, // Boolean
      question8_4: dataItem.question8_4 ? dataItem.question8_4 : null, // String
      question8_5: dataItem.question8_5
        ? dataItem.question8_5
        : dataItem.question8_5 === false
        ? false
        : null, // Boolean
      question8_6: dataItem.question8_6 ? dataItem.question8_6 : null, // String
      // },
      // {
      // 	question: 9,
      // 	title: "GASTROINTESTINAL",
      question9_1: dataItem.question9_1
        ? dataItem.question9_1
        : dataItem.question9_1 === false
        ? false
        : null, // Boolean
      question9_2: dataItem.question9_2 ? dataItem.question9_2 : null, // String
      question9_3: dataItem.question9_3
        ? dataItem.question9_3
        : dataItem.question9_3 === false
        ? false
        : null, // Boolean
      question9_4: dataItem.question9_4 ? dataItem.question9_4 : null, // String
      question9_5: dataItem.question9_5
        ? dataItem.question9_5
        : dataItem.question9_5 === false
        ? false
        : null, // Boolean
      question9_6: dataItem.question9_6 ? dataItem.question9_6 : null, // String
      question9_7: dataItem.question9_7
        ? dataItem.question9_7
        : dataItem.question9_7 === false
        ? false
        : null, // Boolean
      question9_8: dataItem.question9_8 ? dataItem.question9_8 : null, // String
      // },
      // {
      // 	question: 10,
      // 	title: "PSYCHOLOGICAL",
      question10_1: dataItem.question10_1
        ? dataItem.question10_1
        : dataItem.question10_1 === false
        ? false
        : null, // Boolean
      question10_2: dataItem.question10_2 ? dataItem.question10_2 : null, // String
      question10_3: dataItem.question10_3
        ? dataItem.question10_3
        : dataItem.question10_3 === false
        ? false
        : null, // Boolean
      question10_4: dataItem.question10_4 ? dataItem.question10_4 : null, // String
      question10_5: dataItem.question10_5
        ? dataItem.question10_5
        : dataItem.question10_5 === false
        ? false
        : null, // Boolean
      question10_6: dataItem.question10_6 ? dataItem.question10_6 : null, // String
      question10_7: dataItem.question10_7
        ? dataItem.question10_7
        : dataItem.question10_7 === false
        ? false
        : null, // Boolean
      question10_8: dataItem.question10_8 ? dataItem.question10_8 : null, // String
      // },
      // {
      // 	question: 11,
      // 	title: "FEMALE",
      question11_1: dataItem.question11_1
        ? dataItem.question11_1
        : dataItem.question11_1 === false
        ? false
        : null, // Boolean
      question11_2: dataItem.question11_2 ? dataItem.question11_2 : null, // String
      question11_3: dataItem.question11_3
        ? dataItem.question11_3
        : dataItem.question11_3 === false
        ? false
        : null, // Boolean
      question11_4: dataItem.question11_4 ? dataItem.question11_4 : null, // String
      question11_5: dataItem.question11_5
        ? dataItem.question11_5
        : dataItem.question11_5 === false
        ? false
        : null, // Boolean
      question11_6: dataItem.question11_6 ? dataItem.question11_6 : null, // String
      question11_7: dataItem.question11_7
        ? dataItem.question11_7
        : dataItem.question11_7 === false
        ? false
        : null, // Boolean
      question11_8: dataItem.question11_8 ? dataItem.question11_8 : null, // String
      // },
      // {
      // 	question: 12,
      // 	title: "TYSABRI",
      question12_1: dataItem.question12_1
        ? dataItem.question12_1
        : dataItem.question12_1 === false
        ? false
        : null, // Boolean
      question12_2: dataItem.question12_2 ? dataItem.question12_2 : null, // String
      question12_3: dataItem.question12_3
        ? dataItem.question12_3
        : dataItem.question12_3 === false
        ? false
        : null, // Boolean
      question12_4: dataItem.question12_4 ? dataItem.question12_4 : null // String
    };

  // ]
  return JSON.stringify(questions);
};

export default Assessment;
