import {
  addDays,
  addMonths,
  durationInMonths
} from '@progress/kendo-date-math';
import { isDateFromPast } from './DateHelper';
import { getDosageUOM } from './DoseHelper';

export const APRETUDE_NDC_CODE = '49702-0264-23';
export const CABENUVA_1000_NDC_CODE = '49702-0253-15';
export const CABENUVA_1500_NDC_CODE = '49702-0240-15';

export const CABENUVA_DOSAGE_1000 = '400/600 mg';
export const CABENUVA_DOSAGE_1500 = '600/900 mg';

export const isCabenuvaProduct = (productId) =>
  [CABENUVA_1000_NDC_CODE, CABENUVA_1500_NDC_CODE].includes(productId);
export const isApretudeProduct = (productId) => APRETUDE_NDC_CODE === productId;

export const isViiVProduct = (productId) =>
  isCabenuvaProduct(productId) || isApretudeProduct(productId);

export const setViivMeta = (orderData) => {
  if (!orderData) return null;

  const isViiv = isViiVProduct(orderData.drugId);
  const isCabenuva = isCabenuvaProduct(orderData.drugId);
  const isApretude = isApretudeProduct(orderData.drugId);

  return { ...orderData, isViiv, isCabenuva, isApretude };
};

export const getTargetInjectionDates = (dosingWindow, targetDate) => {
  if (!dosingWindow || !targetDate) {
    console.warn('viivHelper::getTargetInjectionDates - values for dosing window and/or target injection date are missing');
    return null;
  }

  const exactDate = new Date(targetDate);
  const minDate = addDays(exactDate, -dosingWindow);
  const maxDate = addDays(exactDate, +dosingWindow);

  return { minDate, maxDate, exactDate };
};

export const getCabenuvaDosageLabel = (dose) => {
  return Number(dose) === 1500 ? CABENUVA_DOSAGE_1500 : CABENUVA_DOSAGE_1000;
};

export const getDosageFromEvent = (eventData) => {
  if (eventData?.adminStage?.drugName === 'Cabenuva') {
    return getCabenuvaDosageLabel(eventData.adminStage.dose);
  }

  return `${eventData?.adminStage?.calcDosage} ${getDosageUOM(eventData?.adminStage?.unitOfMeas)}`;
};
