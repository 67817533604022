import React from 'react';
import { Field } from '@progress/kendo-react-form';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { InputField } from '../../common/Validation';
import { CABENUVA_DOSAGE_1000, CABENUVA_DOSAGE_1500 } from '@/common/viivHelper';

const OrderAdministrationCabenuva = ({ treatValidator = {} }) => {
  return (
    <div className="row">
      <div className="col-md-2">
        Route: <b>IM</b>
      </div>
      <div className="col-md-1">Dosage:</div>
      <div className="col-md-2">
        <Field
          name="cabenuvaDosage"
          component={DropDownList}
          data={[CABENUVA_DOSAGE_1000, CABENUVA_DOSAGE_1500]}
        />
      </div>
      <div className="col-md-2"># Treatments:</div>
      <div className="col-md-1">
        <Field
          name="numTreatments"
          component={InputField}
          validator={treatValidator}
        />
      </div>
    </div>
  );
};

export default OrderAdministrationCabenuva;
