import React, { createContext, useState, useEffect } from 'react';
import { onAuthUIStateChange } from '@aws-amplify/ui-components';

// gql
import { connectToGraphqlAPI } from '@/provider';
import { getAgent, listUsers } from '@/graphql/queries';

// utils
import getUserMenuAccess from './getUserMenuAccess';
import {
  canUserEditCompletedInfusion,
  canUserUpdateInfusionPrescriber
} from '@/components/Infusion/infusionHelper';
import {
  getIsUserAdmin,
  getUserRoles,
  getHasAccess,
  getHasEditAccess,
  getIsUserSupervisor
} from '@/context/UserContext.helper';

// USER CONTEXT
export const UserContext = createContext();
export const UserConsumer = UserContext.Consumer;

const MAX_USERS = 999;

const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [agentUsers, setAgentUsers] = useState([]);
  const [agent, setAgent] = useState({});
  const [agentId, setAgentId] = useState();
  const [userRoles, setUserRoles] = useState({});
  const [userMenuAccess, setUserMenuAccess] = useState([]);
  const [isClinicalAdmin, setIsClinicalAdmin] = useState(false);
  const [isNursingAdmin, setIsNursingAdmin] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSupervisor, setIsSupervisor] = useState(false);

  const canAccess = (userRoleType) => getHasAccess(userRoles, userRoleType);
  const canEdit = (userRoleType) => getHasEditAccess(userRoles, userRoleType);
  const showInMenu = (menuPoint) => userMenuAccess.includes(menuPoint);

  // MAIN INITIATOR
  useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      if (authData) {
        const { username } = authData;
        setUser({ username });
        setAgentId(username);
      } else {
        console.error(
          'UserContextProvider::onAuthUIStateChange err: ',
          authData
        );
      }
    });
  }, []);

  useEffect(() => {
    if (user?.username?.length) {
      callGetAgent();
      callListAgents();
    }
  }, [user]);

  const callGetAgent = async () => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getAgent,
        variables: { agentId: user.username }
      });
      setAgent(data.data.getAgent);
      setUserRoles(getUserRoles(data));
      setUserMenuAccess(getUserMenuAccess(data));
      setIsAdmin(getIsUserAdmin(data));
      setIsSupervisor(getIsUserSupervisor(data));
      setIsClinicalAdmin(canUserEditCompletedInfusion(data));
      setIsNursingAdmin(canUserUpdateInfusionPrescriber(data));
    } catch (err) {
      console.error('UserContextProvider getAgent err: ', err);
      // set agent data if its still returned inspite of the error
      if (err?.data?.getAgent) {
        setAgent(err?.data?.getAgent);
        setUserRoles(getUserRoles(err));
      }
    }
  };

  const callListAgents = async () => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: listUsers,
        variables: {
          limit: MAX_USERS
        }
      });

      if (data?.data?.listUsers?.items?.length) {
        const rawUsers = data.data.listUsers.items.map((item) => ({
          defaultRole: item.defaultRole,
          text: item.userId,
          value: item.userId
        }));

        const assignToUsers = rawUsers.filter(
          (v, i, a) => a.findIndex((t) => t.text === v.text) === i
        );
        setAgentUsers(
          assignToUsers.sort((a, b) => a.text.localeCompare(b.text))
        );
      } else {
        console.warn('UserContext::callListAgents - no data');
      }
    } catch (err) {
      console.error('UserContext::callListAgents err: ', err);
    }
  };

  return (
    <UserContext.Provider
      value={{
        user,
        agent,
        agentId,
        agentUsers,
        userRoles,
        canAccess,
        canEdit,
        userMenuAccess,
        showInMenu,
        isClinicalAdmin,
        isAdmin,
        isSupervisor,
        isNursingAdmin
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
