import React from 'react';
import Badge from './Badge';

// utils
import { taskStatusesMapped } from '@/constants/enum';
import { getBadgeType, hasMapping } from './WorkStatusBadge.utils';

const WorkStatusBadge = ({ workStatus }) => {
  // render UNKNOWN badge as default
  if (!workStatus || !hasMapping(workStatus)) {
    console.error(
      'WorkStatusBadge component requires `workStatus` as a property. Used `unkown` as a default.'
    );
    return <Badge text='UNKNOWN' type='default' />;
  }

  const type = getBadgeType(workStatus);

  return <Badge text={taskStatusesMapped[workStatus]} type={type} />;
};

export default WorkStatusBadge;
