import { workItemStatusMap } from '@/components/Queue/FilterSystem/filterHelper';
import {
  followUpNameType,
  groupQueues,
  notSupportedWorkItemStatus,
  noteTypes,
  patientDocumentTypes,
  supportedWorkItemTypes,
  taskStatus,
  workItemStatuses,
  workItemTypes,
  eventStatus,
  SCHEDULE_EVENTS
} from '@/constants/enum';

// mapper to get patient doc type from ENUM based on value from server
// e.g. we recieve from server type "PriorAuthApproval",
// which has "Prior Auth Approval" title to be displayed on UI
export const getDocTypeTitle = (docTypeEnum) => {
  const enumType = patientDocumentTypes.find(
    (type) => type.value === docTypeEnum
  );

  if (enumType) return enumType.title;

  console.error(
    `Mappers::getDocTypeTitle - passed unsupported document type - '${enumType}'`
  );

  return null;
};
export const getNoteTypeTitle = (docTypeEnum) => {
  const enumType = noteTypes.find((type) => type.value === docTypeEnum);

  if (enumType) return enumType.text;

  console.error(
    `Mappers::getNoteTypeTitle - passed unsupported document type - '${enumType}'`
  );

  return null;
};

// mapper to get display text from workItem.status value returned from server
export const getWorkItemStatusText = (sStatus) => {
  const workItemStatus = workItemStatuses.find((type) => type.code === sStatus);

  if (workItemStatus) {
    return workItemStatus.text;
  }

  console.error(
    `Mappers::getWorkItemStatusText - passed unsupported status type - '${sStatus}'`
  );
  return sStatus;
};

export const isWorkTypeSupported = (workItemType) =>
  supportedWorkItemTypes.includes(workItemType);

export const isWorkStatusSupported = (workStatus) =>
  !notSupportedWorkItemStatus.includes(workStatus);

export const mapAttachedData = (data) => {
  return (
    data
      .map((item) => {
        if (item.attachedData) {
          const parsedData = JSON.parse(item.attachedData);
          item.attachedData = parsedData;
        }

        return item;
      })
      // filter out any work items from Inbound Fax Queue and work items not ready for production.
      .filter((item) => item.groupQueueId !== groupQueues.INBOUND)
  );
};
export const isWorkTypeSupportedForSnooze = (dataItem) =>
  /* snoozeWorkItemTypes.includes(dataItem.work) && */
  dataItem.workStatus !== taskStatus.SNOOZED &&
  dataItem.workStatus !== taskStatus.IN_PROGRESS;

export const mapAgentItems = (data) => {
  let agentItems = [];
  data.forEach((dataItem) => {
    const itemsInProgress = dataItem.workItems.filter(
      (workItem) =>
        workItem.workStatus === taskStatus.IN_PROGRESS ||
        (workItem.workStatus === taskStatus.PENDING &&
          isWorkTypeSupported(workItem.work) &&
          workItem.assignedTo === dataItem.assignedTo)
    );
    if (itemsInProgress.length > 0) {
      agentItems = [...agentItems, ...itemsInProgress];
    }
  });
  return mapAttachedData(agentItems);
};

export const patientItemStatusesToExclude = [
  taskStatus.ARCHIVED,
  taskStatus.CANCELED,
  taskStatus.COMPLETED
];
export const patientItemStatusesToExcludeForRetrieve = [
  taskStatus.ARCHIVED,
  taskStatus.CANCELED,
  taskStatus.COMPLETED,
  taskStatus.IN_PROGRESS
];

export const eventStatusForArchive = [
  eventStatus.CHECKED_IN,
  eventStatus.STARTED_INFUSION,
  eventStatus.STARTED_LAB_DRAW
];
// Intake
export const intakeDropDownWorkItemList = [
  workItemTypes.FOLLOW_UP,
  workItemTypes.OUTBOUND_CALL,
  workItemTypes.PRE_ORDER,
  workItemTypes.NEW_REFERRAL,
  workItemTypes.REFILL,
  workItemTypes.DISCONTINUATION_REVIEW,
  workItemTypes.PATIENT_REACTIVATION_REVIEW
];
export const intakeWorkItemFollowUplist = [
  workItemTypes.FOLLOW_UP,
  workItemTypes.CHART_NOTES,
  workItemTypes.MISCELLANEOUS,
  workItemTypes.UPDATE_LAB_TESTS,
  workItemTypes.EXTEND_LAB_TESTS
];
export const intakeWorkItemFollowUpLablist = [
  workItemTypes.UPDATE_LAB_TESTS,
  workItemTypes.EXTEND_LAB_TESTS
];
export const intakeWorkItemCalllist = [
  workItemTypes.OUTBOUND_CALL,
  workItemTypes.FINANCIAL_ASSIST_CALL,
  workItemTypes.WELCOME_CALL,
  workItemTypes.PENDING_APPT
];

// BI
export const biDropDownWorkItemList = [
  workItemTypes.FOLLOW_UP,
  workItemTypes.OUTBOUND_CALL,
  workItemTypes.NETWORK_CHECKING,
  workItemTypes.DISCONTINUATION_REVIEW,
  workItemTypes.BENEFIT_INVESTIGATION,
  workItemTypes.RE_VERIFICATION
];
export const biWorkItemFollowUplist = [workItemTypes.FOLLOW_UP];
export const biWorkItemCalllist = [workItemTypes.OUTBOUND_CALL];
export const biList = [workItemTypes.BENEFIT_INVESTIGATION];
export const biNetworkList = [
  workItemTypes.NETWORK_CHECKING,
  workItemTypes.BI_NETWORK
];
export const discList = [
  workItemTypes.DISCONTINUATION_REVIEW,
  workItemTypes.PLAN_OON
];

// PA
export const paDropDownWorkItemList = [
  workItemTypes.PRIOR_AUTHORIZATION,
  workItemTypes.FOLLOW_UP,
  workItemTypes.OUTBOUND_CALL,
  workItemTypes.PA_DENIED,
  workItemTypes.EXTEND_PA
];
export const paWorkItemFollowUplist = [workItemTypes.FOLLOW_UP];
export const paWorkItemCalllist = [workItemTypes.OUTBOUND_CALL];
export const palist = [
  workItemTypes.PRIOR_AUTHORIZATION,
  workItemTypes.PRIOR_AUTH_INFO,
  workItemTypes.PENDING_PA_APPROVAL
];
export const paExpiredlist = [
  workItemTypes.EXTEND_PA,
  workItemTypes.EXTEND_PA_VISITS,
  workItemTypes.EXTEND_PA_UNITS
];

// Sales
export const salesDropDownWorkItemList = [
  workItemTypes.FOLLOW_UP,
  workItemTypes.OUTBOUND_CALL
];
// SCHEDULE
export const scheduleDropDownWorkItemList = [
  workItemTypes.SCHEDULE,
  workItemTypes.EXTEND_SCHEDULE,
  workItemTypes.OUTBOUND_CALL
];
// CLINICIAN
export const clinicianDropDownWorkItemList = [
  workItemTypes.CLINICIAN_REVIEW,
  workItemTypes.FOLLOW_UP,
  workItemTypes.OUTBOUND_CALL
];
export const clinicianWorkItemFollowUplist = [workItemTypes.FOLLOW_UP];
export const clinicianWorkItemCalllist = [workItemTypes.OUTBOUND_CALL];

export const salesWorkItemFollowUplist = [workItemTypes.FOLLOW_UP];
export const salesWorkItemCalllist = [workItemTypes.OUTBOUND_CALL];

export const getDocumentTitleMapped = (schemaType) => {
  const unmappedObj = patientDocumentTypes.find(
    (type) => type.value === schemaType
  );

  if (!unmappedObj) {
    console.error(`Passed type "${schemaType}" has no mapping for the title`);

    return 'n/a';
  }

  return unmappedObj.title;
};

export const booleanChoices = [
  { label: 'Yes', value: true, className: 'patient-radio blue' },
  { label: 'No', value: false, className: 'patient-radio blue' }
];

export const getWorkItemDropDownList = (queue) => {
  switch (queue) {
    case 'INTAKE':
      return intakeDropDownWorkItemList;
    case 'BI':
      return biDropDownWorkItemList;
    case 'PA':
      return paDropDownWorkItemList;
    case 'SALES':
      return salesDropDownWorkItemList;
    case 'CLINICIAN':
      return clinicianDropDownWorkItemList;
    case 'SCHEDULE':
      return scheduleDropDownWorkItemList;
    default:
      return [];
  }
};

export const getWorkItemCallList = (queue) => {
  switch (queue) {
    case 'INTAKE':
      return intakeWorkItemCalllist;
    case 'BI':
      return biWorkItemCalllist;
    case 'PA':
      return paWorkItemCalllist;
    case 'SALES':
      return salesWorkItemCalllist;
    case 'CLINICIAN':
      return clinicianWorkItemCalllist;
    default:
      return [];
  }
};

export const getWorkItemFollowUpList = (queue) => {
  switch (queue) {
    case 'INTAKE':
      return intakeWorkItemFollowUplist;
    case 'BI':
      return biWorkItemFollowUplist;
    case 'PA':
      return paWorkItemFollowUplist;
    case 'SALES':
      return salesWorkItemFollowUplist;
    case 'CLINICIAN':
      return clinicianWorkItemFollowUplist;
    default:
      return [];
  }
};
export const getFollowUpDetailList = (type) => {
  switch (type) {
    case followUpNameType.CHART_NOTES_TEXT:
      return workItemTypes.CHART_NOTES;
    case workItemStatusMap.DISCONTINUATION_REVIEW:
      return discList;
    case followUpNameType.DOCUMENT_MISC_TEXT:
      return workItemTypes.MISCELLANEOUS;
    case followUpNameType.LABS_RECEIVED_TEXT:
      return intakeWorkItemFollowUpLablist;
    case followUpNameType.WELCOME_CALL_TEXT:
      return workItemTypes.WELCOME_CALL;
    default:
      return [];
  }
};

export const getWorkItemList = (array, list, conditions) => {
  return array.filter(
    (items) =>
      (list && list.includes(items.work)) ||
      (conditions && items?.attachedData?.followUpType === conditions)
  );
};

export const mapCalendarEventType = (eventType) => {
  switch (eventType) {
    case SCHEDULE_EVENTS.APPOINTMENT_OFFICE_VISIT:
      return 'Office Visit';
    case SCHEDULE_EVENTS.APPOINTMENT_TELEHEALTH:
      return 'Telehealth';
    case SCHEDULE_EVENTS.APPOINTMENT:
      return 'Drug Infusion';
    default:
      return eventType;
  }
};
