import React, { useContext, useState, useEffect } from 'react';
import { Storage } from 'aws-amplify';
import { Grid } from '@progress/kendo-react-grid';
import { GridColumn as Column } from '@progress/kendo-react-grid/dist/npm/GridColumn';
import { Dialog } from '@progress/kendo-react-dialogs';
import { process } from '@progress/kendo-data-query';

// components
import { ColumnMenu } from '@/components/common-components/columnMenu';
import DeleteButton from '@/components/common-components/Form/DeleteButton';

// context
import { NotifContext } from '@/context/NotifContext';
import { UserContext } from '@/context/UserContext';
import { PatientContext } from '@/context/PatientContext';

// helpers
import { getDocTypeTitle } from '@/common/Mappers';
import { formatDateTimeToDefault } from '@/common/DateHelper';

// gql
import { connectToGraphqlAPI } from '@/provider';
import { movePatientDoc } from '@/graphql/mutations';
import Alert from '../common-components/Alert';

const initialDataState = {};
const initialResultState = { data: [], total: 0 };

const PatientDocsModal = ({ showDialog, onClose, documentViewCallback }) => {
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [patientData, setPatientData] = useState({});
  const [isReferralType, setIsReferralType] = useState(false);
  const [dataState, setDataState] = useState(initialDataState);
  const [resultState, setResultState] = useState(initialResultState);

  const {
    selectedPatientInfo: { patientDocuments, patientId, ...restPatientInfo },
    setSelectedPatientInfo
  } = useContext(PatientContext);
  const { user } = useContext(UserContext);
  const { showError, showSuccess } = useContext(NotifContext);

  const onRemoveFromContext = (docPath) => {
    const filteredList = patientDocuments.filter(
      (doc) => doc.documentPath !== docPath
    );
    setSelectedPatientInfo({
      ...restPatientInfo,
      patientId,
      patientDocuments: filteredList
    });
  };

  const onRemoveDocumentCall = async (requestObject) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: movePatientDoc,
        variables: requestObject
      });

      if (data.data?.movePatientDoc?.statusCode === '200') {
        showSuccess(
          'Document was successfully removed from the patient bucket'
        );
        // update doc list on the context
        onRemoveFromContext(requestObject.patientDocument.documentPath);
      } else {
        showError(data.data.movePatientDoc.message);
      }
    } catch (err) {
      console.error('PatientHeader::removeDocumentCall data err:', err);
      showError("Error: can't remove the document from the patient bucket");
    }
  };

  const onDocumentRowHandle = async (data) => {
    const conf = { download: false };
    const s3ImageURL = await Storage.get(data.documentPath, conf);
    documentViewCallback(s3ImageURL);
  };

  const toggleShowDeleteDialog = () => {
    setDeleteDialog(!deleteDialog);
  };

  const deleteDoc = () => {
    onRemoveDocumentCall({
      agentId: user.username,
      fromPatientId: patientId,
      patientDocument: { documentPath: patientData.documentPath }
    });
    toggleShowDeleteDialog();
  };

  useEffect(() => {
    if (
      (patientData && patientData?.documentType === 'Referral') ||
      patientData?.documentType === 'RefillOrder'
    ) {
      setIsReferralType(true);
    } else {
      setIsReferralType(false);
    }
  }, [patientData]);

  useEffect(() => {
    setResultState(process(patientDocuments || [], dataState));
  }, [dataState, patientDocuments]);

  const render = {
    docTypeCell: ({ dataItem }) => (
      <td>{getDocTypeTitle(dataItem?.documentType)}</td>
    ),
    docDateCell: ({ dataItem }) => (
      <td>{formatDateTimeToDefault(dataItem?.date)}</td>
    ),
    hyperLinkCell: ({ dataItem }) => (
      <td>
        {dataItem.documentPath && (
          <span
            className='blue-link'
            onClick={() => onDocumentRowHandle(dataItem)}
          >
            {dataItem.documentPath}
          </span>
        )}
      </td>
    ),
    actionsCell: ({ dataItem }) => {
      if (dataItem.documentType === 'PatientRights') {
        return <td>&nbsp;</td>;
      }

      return (
        <td>
          <DeleteButton
            handleClick={() => {
              toggleShowDeleteDialog();
              setPatientData(dataItem);
            }}
          />
        </td>
      );
    }
  };

  if (!showDialog) return null;

  return (
    <>
      {showDialog && (
        <Dialog
          title='Patient Documents'
          width={1200}
          initialHeight={500}
          onClose={onClose}
        >
          <div className='row'>
            <div className='col-md-12 patient-document'>
              <Grid
                className='queue_grid'
                style={{ height: '400px' }}
                data={{ data: resultState.data }}
                editField='inEdit'
                selectedField='selected'
                onDataStateChange={(e) => setDataState(e.dataState)}
                sortable
                {...dataState}
              >
                <Column
                  field='documentType'
                  title='Document Type'
                  width='150px'
                  cell={render.docTypeCell}
                  columnMenu={ColumnMenu}
                />
                <Column
                  field='date'
                  title='Date'
                  width='175px'
                  cell={render.docDateCell}
                  columnMenu={ColumnMenu}
                />
                <Column
                  field='documentPath'
                  title='Document'
                  sortable={false}
                  cell={render.hyperLinkCell}
                />
                <Column title='' width='125px' cell={render.actionsCell} />
              </Grid>
            </div>
          </div>
        </Dialog>
      )}
      {deleteDialog && (
        <Dialog
          title='Document Delete'
          width={450}
          onClose={toggleShowDeleteDialog}
        >
          <div className='row'>
            <div className='col-md-12 patient-document'>
              <div className='row'>
                {isReferralType && (
                  <div className='col-md-12'>
                    <Alert type='alert'>
                      Warning: This referral document may be attached to an
                      existing referral and deleting it will cause document data
                      to be lost.
                    </Alert>
                  </div>
                )}
                <div className='col-md-12' style={{ fontStyle: 'italic' }}>
                  Are you sure you would like to delete this document?
                </div>
              </div>

              <div className='row mt-12'>
                <div className='col-md-10'>
                  <button
                    type='button'
                    onClick={deleteDoc}
                    className='k-button blue'
                  >
                    <i className='fa-solid fa-trash-check fa-sm icon-white'>
                      &nbsp;&nbsp;
                    </i>
                    Delete
                  </button>
                  &nbsp;
                  <button
                    type='button'
                    onClick={toggleShowDeleteDialog}
                    className='k-button blue'
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default PatientDocsModal;
