import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

// kendo
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';

// components
import Header from './InfusionHeader';
import Infusion from './Infusion';
import { Patient } from '../Patient';
import { Prescriber } from '../Prescriber';
import { Fax } from '../FAX';
import TreatmentHistory from './TreatmentHistory';

// utisl
import { InfusionStructure } from '@/common/InfusionStructure';
import { patientProfileTabs } from '@/common/NavHelper';

// gql
import { connectToGraphqlAPI } from '@/provider';
import {
  getPatientBucket,
  getPatientReferralOrders,
  getInfusionHeaderDetails,
  getNursingProcess,
  listProducts
} from '@/graphql/queries';
import { stepCheckIn } from '@/graphql/mutations';

// context
import { UserContext, PatientContext, NotifContext } from '@/context';

const InfusionPortal = () => {
  const history = useHistory();
  const location = useLocation();
  const { agent } = useContext(UserContext);
  const { showInfo, showError } = useContext(NotifContext);
  const {
    selectedPatientInfo,
    setSelectedPatientInfo,
    showPatientFaxDocument,
    setCompletedTreatment
  } = useContext(PatientContext);

  const [infusion, setInfusion] = useState({ ...InfusionStructure });
  const [currentStep, setCurrentStep] = useState(0);
  const [showHeader, setShowHeader] = useState(false);
  const [headerDetailsData, setHeaderDetailsData] = useState({});

  const [showInfusionStepper, setShowInfusionStepper] = useState(false);
  const [showInfusionForm, setShowInfusionForm] = useState(false);
  const [infusionFormData, setInfusionFormData] = useState();

  const [eventDetailsData, setEventDetailsData] = useState(
    location.state?.dataItem
  );
  const [eventId, setEventId] = useState();
  const [nursingProcessId, setNursingProcessId] = useState(
    location.state?.dataItem?.tNPID?.id
  );
  const [nursingProcess, setNursingProcess] = useState({});

  const [listProductsByName, setListProductsByName] = useState();
  const [listProductsByNameData, setListProductsByNameData] = useState([]);
  const [showInfusionAnything, setShowInfusionAnything] = useState(false);

  const { searchType = 'Patient' } = location.state || { searchType: null };

  let activeTab = 0;

  const [selectedTab, setSelectedTab] = useState(activeTab);

  const handleSelect = (e) => {
    setSelectedTab(e.selected);
  };

  // MAIN INITIATOR
  useEffect(() => {
    listProductsByNameCall();
  }, []);

  useEffect(() => {
    if (agent.agentId) {
      getNursingProcessCall(nursingProcessId);
    }
  }, [agent, nursingProcessId]);

  // LISTENERS
  useEffect(() => {
    if (location?.state?.searchType) {
      const isHistoryTab = location.state.searchType === 'treatmentHistory';
      const tab = isHistoryTab
        ? patientProfileTabs.TREATMENT_HISTORY
        : patientProfileTabs.OVERVIEW;
      setSelectedTab(tab);

      // generateFax
      if (isHistoryTab && location.state.generateFax === true) {
        setCompletedTreatment(location.state.treatmentId);
      } else {
        setCompletedTreatment(null);
      }
    }
  }, [location]);

  useEffect(() => {
    // redirect to Patient Portal instead
    if (selectedTab === patientProfileTabs.TREATMENT_HISTORY) {
      history.push('/patient-portal', {
        searchType: 'PATIENT',
        redirectPatienTreatHistory: true
      });
    }
  }, [selectedTab]);

  const getNursingProcessCall = async (id) => {
    try {
      if (!id) {
        if (
          location &&
          location.state &&
          location.state.dataItem &&
          location.state.dataItem.patientId
        ) {
          await Promise.allSettled([
            setEventId(location.state.dataItem.id),
            getPatientBucketCall(location.state.dataItem.patientId)
          ]);

          if (currentStep === 0) {
            const infusionNewData = infusion;
            infusionNewData.currentStep = 1;
            // eventId: ID!
            infusionNewData.stepCheckInInput.eventId =
              location.state.dataItem.id;
            // agentId: ID!
            infusionNewData.stepCheckInInput.agentId =
              location.state.dataItem.agentId;
            // patientId: ID!
            infusionNewData.stepCheckInInput.patientId =
              location.state.dataItem.patientId;
            // locationId: ID!
            infusionNewData.stepCheckInInput.locationId =
              location.state.dataItem.locationId;
            // chairId: ID!
            infusionNewData.stepCheckInInput.chairId =
              location.state.dataItem.chairId;
            // providerId: ID!
            infusionNewData.stepCheckInInput.providerId =
              location.state.dataItem.providerId;
            // referralId: ID!
            infusionNewData.stepCheckInInput.referralId =
              location.state.dataItem.referralId;
            // checkInPatient: Boolean!
            infusionNewData.stepCheckInInput.checkInPatient = true; //location.state.dataItem.checkInPatient
            // verifiedDoB: Boolean!
            infusionNewData.stepCheckInInput.verifiedDoB = true; //location.state.dataItem.verifiedDoB
            // notes: String
            // infusionNewData.stepCheckInInput.notes =
            //   location.state.dataItem.notes;
            setInfusion(infusionNewData);
            getPatientReferralOrdersCall(infusionNewData.stepCheckInInput);
            stepCheckInCall(infusionNewData.stepCheckInInput, 'new');
          }
        }
      } else if (id) {
        if (location?.state?.dataItem?.id) {
          id = location.state.dataItem.id;
        }
        const data = await connectToGraphqlAPI({
          graphqlQuery: getNursingProcess,
          variables: { id }
        });

        if (data && data.data && data.data.getNursingProcess) {
          await Promise.allSettled([
            setNursingProcess(data.data.getNursingProcess),
            setEventId(data.data.getNursingProcess.scheduleEventId),
            getPatientBucketCall(data.data.getNursingProcess.patientId)
          ]);
          // re-store narrative notes from storage if exists
          const nnotes =
            data.data.getNursingProcess.notes &&
            data.data.getNursingProcess.notes.length
              ? data.data.getNursingProcess.notes[0]
              : '';
          localStorage.setItem('narrativeNotes', JSON.stringify(nnotes));
          if (currentStep === 0) {
            const npData = data.data.getNursingProcess;
            const infusionNewData = { ...infusion };
            infusionNewData.currentStep = 1;
            // eventId: ID!
            infusionNewData.stepCheckInInput.eventId = npData.scheduleEventId;
            // agentId: ID!
            infusionNewData.stepCheckInInput.agentId = agent.agentId;
            // patientId: ID!
            infusionNewData.stepCheckInInput.patientId = npData.patientId;
            // locationId: ID!
            infusionNewData.stepCheckInInput.locationId = npData.locationId;
            // chairId: ID!
            infusionNewData.stepCheckInInput.chairId = npData.chairId;
            // providerId: ID!
            infusionNewData.stepCheckInInput.providerId = npData.providerId;
            // referralId: ID!
            infusionNewData.stepCheckInInput.referralId = npData.referralId;
            // checkInPatient: Boolean!
            infusionNewData.stepCheckInInput.checkInPatient = true; //npData.checkInPatient
            // verifiedDoB: Boolean!
            infusionNewData.stepCheckInInput.verifiedDoB = true; //npData.verifiedDoB
            // notes: String
            // infusionNewData.stepCheckInInput.notes = npData.notes;
            // from stepCheckIn
            infusionNewData.updateStepOrderReviewInput.nursingProcessId =
              npData.id;
            // pre-populate Review
            if (npData.stepReview) {
              infusionNewData.updateStepOrderReviewInput = {
                ...npData.stepReview
              };
              infusionNewData.updateStepOrderReviewInput.orderIsApproved =
                npData.stepReview.orderApproved;
            }
            // pre-populate Assessments
            if (npData.stepAssessment) {
              infusionNewData.updateStepAssessmentInput = {
                ...npData.stepAssessment
              };
              infusionNewData.updateStepAssessmentInput.nursingProcessId =
                npData.id;
            }
            // pre-populate Pre-Treatments
            if (npData.stepPreTreatment) {
              infusionNewData.updateStepPreTreatmentInput = {
                ...npData.stepPreTreatment
              };
              infusionNewData.updateStepPreTreatmentInput.nursingProcessId =
                npData.id;
            }
            // pre-populate Prep
            if (npData.stepPreparation) {
              infusionNewData.updateStepPreparationInput = {
                ...npData.stepPreparation
              };
              infusionNewData.updateStepPreparationInput.nursingProcessId =
                npData.id;
            }
            // pre-populate Admin
            if (npData.stepAdministration) {
              infusionNewData.updateStepAdministrationInput = {
                ...npData.stepAdministration
              };
              infusionNewData.updateStepAdministrationInput.nursingProcessId =
                npData.id;
            }

            setInfusion(infusionNewData);
            getPatientReferralOrdersCall(infusionNewData.stepCheckInInput);
          }
        }
      }
    } catch (err) {
      console.log(err);
      showError(err);
    }
  };

  const getPatientBucketCall = async (patientId) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getPatientBucket,
        variables: { patientId: patientId }
      });
      if (data && data.data && data.data.getPatientBucket) {
        setSelectedPatientInfo(data.data.getPatientBucket);
      }
    } catch (err) {
      console.log('marty getPatientBucketCall err', err);
      //alert("getPatientBucketCall err")
    }
  };

  const [adminNumber, setData] = useState('');

  const sendDataToParent = () => {
    getPatientBucketCall(selectedPatientInfo.patientId);
  };
  const childToParent = (childData) => {
    setData(childData);
  };

  const getInfusionHeaderDetailsCall = async (locationId, providerId) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getInfusionHeaderDetails,
        variables: {
          locationId: locationId,
          providerNPI: providerId
        }
      });

      if (
        data &&
        data.data &&
        data.data.getLocationAIC //&&
        //data.data.getProviderAIC
      ) {
        let headerDetails = {
          patientInfo: selectedPatientInfo,
          locationInfo: data.data.getLocationAIC,
          providerInfo: data.data.getProviderAIC
            ? data.data.getProviderAIC
            : null,
          referralInfo: infusionFormData,
          eventInfo: eventDetailsData,
          //nursingProcessInfo: infusion.updateStepOrderReviewInput.nursingProcessId,
          nursingProcessInfo: nursingProcessId
            ? nursingProcessId
            : infusion.updateStepOrderReviewInput.nursingProcessId
            ? infusion.updateStepOrderReviewInput.nursingProcessId
            : null
        };

        setHeaderDetailsData(headerDetails);
        setShowHeader(true);
      }
    } catch (err) {
      console.log('marty getInfusionHeaderDetailsCall err', err);
    }
  };

  const getPatientReferralOrdersCall = async (requestObject) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getPatientReferralOrders,
        variables: { patientId: requestObject.patientId }
      });
      if (
        data &&
        data.data &&
        data.data.getPatientBucket &&
        data.data.getPatientBucket.referral &&
        data.data.getPatientBucket.referral.drugReferrals &&
        data.data.getPatientBucket.referral.drugReferrals.length
      ) {
        const selectedReferral =
          data.data.getPatientBucket.referral.drugReferrals.find(
            (refOrder) => refOrder.referralId === requestObject.referralId
          );

        if (!selectedReferral) {
          showInfo(
            'This referral has been archived and is not available to revisit'
          );

          return;
        }

        const orderName = selectedReferral.referralOrder.orderName;

        // @TODO: check if still needed, seems redundant
        selectedReferral.text = orderName;
        selectedReferral.value = orderName;

        setListProductsByName(selectedReferral.drugName);
        setInfusionFormData({ ...selectedReferral });
        setShowInfusionStepper(true);
        setShowInfusionForm(true);
        // if (Array.isArray(thisReferral) && thisReferral.length > 0) {
        //   for (let i = 0; i < thisReferral.length; i++) {
        //     if (thisReferral[i]) {
        //       await Promise.allSettled([
        //         setListProductsByName(thisReferral[i].drugName),
        //         //listProductsByNameCall(thisReferral[i].drugName),
        //         setInfusionFormData({ ...thisReferral[i] }),
        //         setShowInfusionStepper(true),
        //         setShowInfusionForm(true)
        //       ]);
        //     }
        //   }
        // }
      } else
        showInfo(
          'This referral has been archived and is not available to revisit'
        );
    } catch (err) {
      console.log('marty getPatientReferralOrders data err', err);
    }
  };

  // const listProductsByNameCall_old = async () => {
  //   try {
  //     const data = await connectToGraphqlAPI({
  //       //graphqlQuery: listProductsByName,
  //       graphqlQuery: listProducts
  //       //variables: {input: productName}
  //     });

  //     if (
  //       data &&
  //       data.data &&
  //       data.data.listProducts &&
  //       data.data.listProducts.items
  //     ) {
  //       const theData = data.data.listProducts.items
  //         .map(item => ({
  //           ...item,
  //           text: `${item.productName} ${item.strength} ${item.unitOfMeas} (${item.route})`,
  //           value: item.productId
  //         }))
  //         //.filter(item => item.productName === props.listProductsByName)
  //         .sort((a, b) => (a.productName > b.productName ? 1 : -1));

  //       setListProductsByNameData(theData);
  //       setShowInfusionAnything(true);
  //     }
  //   } catch (err) {
  //     console.log("marty listProductsByNameCall err", err);
  //   }
  // };
  const listProductsByNameCall = async () => {
    try {
      const data = await connectToGraphqlAPI({
        //graphqlQuery: listProductsByName,
        graphqlQuery: listProducts
        //variables: {input: productName}
      });

      if (
        data &&
        data.data &&
        data.data.listProducts &&
        data.data.listProducts.items
      ) {
        ////////////////////////
        let list = data.data.listProducts.items;
        let sNextToken = data.data.listProducts.nextToken
          ? data.data.listProducts.nextToken
          : null;
        while (sNextToken != null) {
          try {
            const data = await connectToGraphqlAPI({
              graphqlQuery: listProducts,
              variables: { nextToken: sNextToken }
            });

            if (
              data &&
              data.data &&
              data.data.listProducts &&
              data.data.listProducts.items
            ) {
              sNextToken = data.data.listProducts.nextToken
                ? data.data.listProducts.nextToken
                : null;
              list = list.concat(data.data.listProducts.items);
            }
          } catch (err) {
            console.log('ILIA listProductsCall err', err);
            sNextToken = null; // set to stop iterating
          }
        }
        ///////////////////
        const theData = list
          .map((item) => ({
            ...item,
            text: `${item.productName} ${item.strength} ${item.unitOfMeas} (${item.route})`,
            value: item.productId
          }))
          .sort((a, b) => (a.productName > b.productName ? 1 : -1));

        setListProductsByNameData(theData);
        setShowInfusionAnything(true);
      }
    } catch (err) {
      console.log('marty listProductsByNameCall err', err);
    }
  };

  const stepCheckInCall = async (requestObject) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: stepCheckIn,
        variables: { input: requestObject }
      });
      if (
        data &&
        data.data &&
        data.data.stepCheckIn &&
        data.data.stepCheckIn.statusCode !== '400'
      ) {
        setCurrentStep(1);

        const infusionNewData = infusion;

        infusionNewData.stepCheckInResponse = data.data.stepCheckIn;
        infusionNewData.updateStepOrderReviewInput.nursingProcessId =
          data.data.stepCheckIn.nursingProcessId;
        infusionNewData.currentStep = 1;
        setInfusion(infusionNewData);
        setNursingProcessId(data.data.stepCheckIn.nursingProcessId);
      } else if (data.data.stepCheckIn.statusCode === '400') {
        showError(data.data.stepCheckIn.message);
        SendToInfusionQueue();
      }
    } catch (err) {
      console.log('marty stepCheckInCall err', err);
      alert('marty stepCheckInCall err');
    }
  };

  useEffect(() => {
    let timerid;

    if (timerid) {
      clearTimeout(timerid);
    }

    timerid = setTimeout(() => {
      if (infusion.updateStepOrderReviewInput.nursingProcessId) {
        getInfusionHeaderDetailsCall(
          infusion.stepCheckInInput.locationId,
          infusion.stepCheckInInput.providerId
        );
      }
    }, 1000);
  }, [selectedPatientInfo, infusionFormData]);

  const SendToInfusionQueue = () => {
    history.push('/infusion-queue');
  };

  return (
    <div className='container-fluid portal-container infusion-portal'>
      {showInfusionAnything && (
        <>
          <div className='row p-0'>
            {showHeader && (
              <Header
                agent={agent}
                headerDetailsData={headerDetailsData}
                notes={selectedPatientInfo}
                sendDataToParent={sendDataToParent}
                childToParent={childToParent}
              />
            )}
          </div>
          <div className='row'>
            <div className='col-12 p-0'>
              <div className='container-fluid p-0 navBar1'>
                <TabStrip selected={selectedTab} onSelect={handleSelect}>
                  <TabStripTab
                    contentClassName='navBar2'
                    title='INFUSION&nbsp;&nbsp;'
                  >
                    <div className='tabText'>
                      {showInfusionStepper && showInfusionForm && (
                        <Infusion
                          agent={agent}
                          headerDetailsData={headerDetailsData}
                          nursingProcess={nursingProcess}
                          nursingProcessId={nursingProcessId}
                          setNursingProcess={setNursingProcess}
                          infusion={infusion}
                          selectedPatientInfo={selectedPatientInfo}
                          currentStep={currentStep}
                          showInfusionStepper={showInfusionStepper}
                          showInfusionForm={showInfusionForm}
                          infusionFormData={infusionFormData}
                          listProductsByName={listProductsByName}
                          listProductsByNameData={listProductsByNameData}
                          sendDataToParent={sendDataToParent}
                          adminNumber={adminNumber}
                          //sendDataToHeader={sendDataToHeader}
                        />
                      )}
                    </div>
                  </TabStripTab>
                  <TabStripTab contentClassName='navBar2' title='PATIENT INFO'>
                    <div className='tabText'>
                      <Patient
                        agent={agent}
                        selectedPatientInfo={selectedPatientInfo}
                        sendDataToParent={sendDataToParent}
                      />
                    </div>
                  </TabStripTab>
                  <TabStripTab contentClassName='navBar2' title='PRESCRIBER'>
                    <div className='tabText'>
                      <Prescriber
                        agent={agent}
                        selectedPatientInfo={selectedPatientInfo}
                        sendDataToParent={sendDataToParent}
                      />
                    </div>
                  </TabStripTab>
                  {/* <TabStripTab contentClassName="navBar2" title="PATIENT NOTES" >
									<div className="tabText">
										<Notes 
											agent={agent}
											selectedPatientInfo={selectedPatientInfo}
											sendDataToParent={sendDataToParent} 
										/>
									</div>
								</TabStripTab> */}
                  <TabStripTab
                    contentClassName='navBar2'
                    title='TREATMENT HISTORY'
                  >
                    <div className='tabText'>
                      <TreatmentHistory
                        agent={agent}
                        selectedPatientInfo={selectedPatientInfo}
                        sendDataToParent={sendDataToParent}
                      />
                    </div>
                  </TabStripTab>
                  <TabStripTab contentClassName='navBar2' title='FAX'>
                    <div className='tabText'>
                      <Fax
                        agent={agent}
                        selectedPatientInfo={selectedPatientInfo}
                        sendDataToParent={sendDataToParent}
                      />
                    </div>
                  </TabStripTab>

                  {/* <TabStripTab contentClassName="navBar2" title={`Nursing Process ID: ${infusion.updateStepOrderReviewInput?.nursingProcessId}`} >
									<div className="tabText">
										
									</div>
								</TabStripTab> */}
                </TabStrip>
              </div>
            </div>
          </div>
          {selectedPatientInfo && selectedPatientInfo.documentURI && (
            <button
              type='submit'
              onClick={showPatientFaxDocument}
              className='k-button pageTab doc-fixed-right'
            >
              Patient Document
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default InfusionPortal;
