export const cardTypes = {
  NEW_REFERRAL: {
    type: 'NEW_REFERRAL',
    title: 'New Referral',
    rows: [
      {
        icon: 'user',
        title: 'Patient Name',
        field: 'patientName'
      },
      {
        icon: 'pills',
        title: 'Drug',
        field: 'drugName'
      },
      {
        icon: 'note',
        title: 'Description',
        value: 'Complete new referral form for the patient'
      }
    ]
  },
  REFILL: {
    type: 'REFILL',
    title: 'Order Refill',
    rows: [
      {
        icon: 'user',
        title: 'Patient Name',
        field: 'patientName'
      },
      {
        icon: 'pills',
        title: 'Drug',
        field: 'drugName'
      },
      {
        icon: 'note',
        title: 'Description',
        value: 'Complete order refill form for the patient'
      }
    ]
  },
  PATIENT_PROFILE: {
    type: 'PATIENT_PROFILE',
    title: 'Patient Profile',
    rows: [
      {
        icon: 'user',
        title: 'Patient Name',
        field: 'patientName'
      },
      {
        icon: 'note',
        title: 'Description',
        value: 'Please complete patient profile: patientInfo and insurance.'
      }
    ]
  },
  OUTBOUND_PATIENT_CALL: {
    type: 'OUTBOUND_PATIENT_CALL',
    title: 'Missing Patient Info',
    rows: [
      {
        icon: 'user',
        title: 'Patient Name',
        field: 'patientName'
      },
      {
        icon: 'pills',
        title: 'Drug',
        field: 'drugName'
      },
      {
        icon: 'note',
        title: 'Description',
        value: 'Complete missing patient info form for patient'
      }
    ]
  },
  EXTEND_LAB_TESTS: {
    type: 'EXTEND_LAB_TESTS',
    title: 'Expiring Labs',
    rows: [
      {
        icon: 'user',
        title: 'Patient Name',
        field: 'patientName'
      },
      {
        icon: 'pills',
        title: 'Drug',
        field: 'drugName'
      },
      {
        icon: 'note',
        title: 'Description',
        value: 'Complete lab test form for the patient expiring labs'
      }
    ]
  },
  EXTEND_PA: {
    type: 'EXTEND_PA',
    title: 'Expiring PA follow up task',
    rows: [
      {
        icon: 'user',
        title: 'Patient Name',
        field: 'patientName'
      },
      {
        icon: 'pills',
        title: 'Drug',
        field: 'drugName'
      },
      {
        icon: 'note',
        title: 'Description',
        value: 'Complete extend PA form for the patient'
      }
    ]
  },
  EXTEND_ADMINS: {
    type: 'EXTEND_ADMINS',
    title: 'Expiring Admins follow up task',
    rows: [
      {
        icon: 'user',
        title: 'Patient Name',
        field: 'patientName'
      },
      {
        icon: 'pills',
        title: 'Drug',
        field: 'drugName'
      },
      {
        icon: 'note',
        title: 'Description',
        value: 'Complete extend admins form for the patient'
      }
    ]
  },
  EXTEND_REFERRAL: {
    type: 'EXTEND_REFERRAL',
    title: 'Expiring Referral',
    rows: [
      {
        icon: 'user',
        title: 'Patient Name',
        field: 'patientName'
      },
      {
        icon: 'pills',
        title: 'Drug',
        field: 'drugName'
      },
      {
        icon: 'note',
        title: 'Description',
        value: 'Complete new referral form for the patient'
      }
    ]
  },
  PAYOR_NOT_CONTRACTED: {
    type: 'PAYOR_NOT_CONTRACTED',
    title: 'Payor Not in Network',
    rows: [
      {
        icon: 'user',
        title: 'Patient Name',
        field: 'patientName'
      },
      {
        icon: 'pills',
        title: 'Drug',
        field: 'drugName'
      },
      {
        icon: 'note',
        title: 'Description',
        value: 'Complete form payor not in network for the patient'
      }
    ]
  },
  OUTBOUND_DOCTOR_CALL: {
    type: 'OUTBOUND_DOCTOR_CALL',
    title: 'Missing MD Info',
    rows: [
      {
        icon: 'user',
        title: 'Patient Name',
        field: 'patientName'
      },
      {
        icon: 'pills',
        title: 'Drug',
        field: 'drugName'
      },
      {
        icon: 'note',
        title: 'Description',
        value: 'Complete MD information call'
      }
    ]
  },
  OUTBOUND_INSURANCE_CALL: {
    type: 'OUTBOUND_INSURANCE_CALL',
    title: 'Insurance Calls',
    rows: [
      {
        icon: 'user',
        title: 'Patient Name',
        field: 'patientName'
      },
      {
        icon: 'pills',
        title: 'Drug',
        field: 'drugName'
      },
      {
        icon: 'note',
        title: 'Description',
        value: 'Complete missing insurance call for the patient'
      }
    ]
  }
};
