// gql
import { getReferral } from '@/graphql/queries';
import { connectToGraphqlAPI } from '@/provider';
// Utils
import { productLabNames } from '../Referral/ReferralMaps';
import { SCHEDULE_EVENTS } from '@/constants/enum';

export const getIsLabDraw = (orderName) =>
  orderName === productLabNames.LAB_DRAW_NAME;

export const getIsTelehealthVisit = (eventType) =>
  eventType === SCHEDULE_EVENTS.APPOINTMENT_TELEHEALTH;

export const getIsOfficeVisit = (eventType) =>
  eventType === SCHEDULE_EVENTS.APPOINTMENT_OFFICE_VISIT;

export const getModalHeight = (isLabDraw) => (isLabDraw ? 420 : 570);

export const getProviderTitle = (type) => {
  const providerTitle = type.includes('LOCATION')
    ? 'Selected Provider'
    : 'Override Provider';
  return providerTitle;
};

export const getReferralCall = async (patientId, referralId) => {
  try {
    const referralData = await connectToGraphqlAPI({
      graphqlQuery: getReferral,
      variables: {
        patientId,
        referralId
      }
    });
    if (referralData?.data?.getReferral.referralDetail) {
      return referralData?.data?.getReferral;
    }
  } catch (err) {
    console.error('CalendarHelper::getReferralCall err:', err);
  }
};
