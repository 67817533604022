import zipcode_to_timezone from 'zipcode-to-timezone';

export const getTimeZoneForLocation = zipcode => {
  try {
    const zip = zipcode?.length > 5 ? zipcode.substring(0, 5) : zipcode;
    if (zip?.length === 5) {
      const tz = zipcode_to_timezone.lookup(zip);
      if (tz?.length) {
        return tz;
      }
    }
  } catch (error) {
    console.log('Error occured while getting the time zone. Defaulting to America/Los_Angeles`');
    return 'America/Los_Angeles';
  }
  console.log(`The timezone value for zipcode ${zipcode} could not be identified. Defaulting to America/Los_Angeles`);
  return 'America/Los_Angeles';
};
