import {
  DOC_TYPE_CHART_NOTES,
  DOC_TYPE_LAB_TESTS,
  DOC_TYPE_ORDER_REFILL,
  DOC_TYPE_OTHER,
  DOC_TYPE_PRIOR_AUTH_INFO,
  DOC_TYPE_REFERRAL
} from '@/constants/enum';

export const inboundDocTypes = [
  {
    label: 'New Referral',
    value: DOC_TYPE_REFERRAL
  },
  {
    label: 'Refill',
    value: DOC_TYPE_ORDER_REFILL
  },
  {
    label: 'Chart Notes',
    value: DOC_TYPE_CHART_NOTES
  },
  { label: 'Labs', value: DOC_TYPE_LAB_TESTS },
  {
    label: 'Documentation / Miscellaneous',
    value: DOC_TYPE_OTHER
  },
  {
    label: 'Prior Auth',
    value: DOC_TYPE_PRIOR_AUTH_INFO
  }
];

export const getFaxDocTypes = (patient) => {
  const types = [...inboundDocTypes];

  if (!!patient?.isNewPatient) {
    return types.filter((type) => type.value !== DOC_TYPE_ORDER_REFILL);
  }

  return types;
};
