import React, { useState, useContext, useRef } from 'react';
import styled from 'styled-components';
import { formatDateToAWSDateTime } from '@optml/alera-share';

// kendo
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { Card, CardBody, CardHeader } from '@progress/kendo-react-layout';
import { Button } from '@progress/kendo-react-buttons';
import { Label } from '@progress/kendo-react-labels';

// components
import AwesomeLabel from '@/components/common-components/AwesomeLabel';
import { FormRadioGroup } from '@/components/common-components/FormRadioGroup';
import WorkItemAudience from '@/components/Header/components/ActiveWorkItems/components/WorkItemAudience';
// context
import { WorkItemContext, UserContext, NotifContext } from '@/context';

// utils
import { DropDownListField, TextAreaFieldSmall } from '@/common/Validation';
import { callSelectionNotReached, callSelectionOpen } from './callsHelper';
import { booleanChoices } from '@/common/Mappers';
import { getDateTimePlusOneDay } from '@/common/DateHelper';
import { taskStatus } from '@/constants/enum';

const CallActions = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  height: 30px;
  align-content: center;
  justify-content: center;
`;

CallActions.displayName = 'CallActions';

const PortalCalls = () => {
  const {
    selectedWorkItem,
    returnWorkItem,
    releaseWorkItem,
    setRefreshQueueData,
    refreshQueueData,
    deletePendingWorkItem,
    reassignWorkItem,
    updatePhoneCallWorkItem,
    updateLastCompleted
  } = useContext(WorkItemContext);
  const { agentId } = useContext(UserContext);
  const { showError, showSuccess } = useContext(NotifContext);

  const [completedChoice, setCompletedChoice] = useState(); // removed default so it will come in undefined and not display drop down until radio button is selected.
  const [outcomeData, setOutcomeData] = useState(null);
  const [callOutcome, setCallOutcome] = useState(null);

  const textAreaRef = useRef();

  const handleReturnWork = async () => {
    // first - need to update call history
    const updateCallRes = await updatePhoneCallWorkItem({
      agentId,
      workItemId: selectedWorkItem.id,
      outcome: callOutcome,
      notes: textAreaRef?.current?.value,
      futureCallNeeded: true,
      futureCallDateTime: formatDateToAWSDateTime(getDateTimePlusOneDay())
    });

    const isUpdated = !!updateCallRes[0]?.success;

    if (!isUpdated) {
      showError(updateCallRes[0].details);
    } else {
      await returnWorkItem(
        selectedWorkItem,
        callOutcome,
        textAreaRef?.current?.value
      );
    }
  };

  const handleCloseWorkItem = async () => {
    // first - need to update call history
    const updateCallRes = await updatePhoneCallWorkItem({
      agentId,
      workItemId: selectedWorkItem.id,
      outcome: callOutcome,
      notes: textAreaRef?.current?.value,
      futureCallNeeded: false
    });

    const isUpdated = !!updateCallRes[0]?.success;

    if (!isUpdated) {
      showError(updateCallRes[0].details);
    } else {
      // second - release the work item
      const releaseWorkRes = await releaseWorkItem({
        agentId,
        workItemId: selectedWorkItem.id,
        workStatus: taskStatus.COMPLETED,
        reasonsForStatusChange: callOutcome,
        notes: textAreaRef?.current?.value
      });

      const isReleased = !!releaseWorkRes[0]?.success;

      if (isReleased) {
        deletePendingWorkItem(selectedWorkItem.id);
        showSuccess('Phone Call Work Completed');
        setRefreshQueueData(!refreshQueueData);

        // last completed WI logic
        updateLastCompleted(selectedWorkItem);
      } else {
        showError(releaseWorkRes[0].details);
      }
    }
  };

  const handleCompletedChoice = (selectedValue) => {
    setOutcomeData(selectedValue ? callSelectionOpen : callSelectionNotReached);
    setCompletedChoice(selectedValue);
  };

  const handleSendToSales = async () => {
    // first - need to update call history
    const updateCallRes = await updatePhoneCallWorkItem({
      agentId,
      workItemId: selectedWorkItem.id,
      outcome: callOutcome,
      notes: textAreaRef?.current?.value,
      futureCallNeeded: true,
      futureCallDateTime: formatDateToAWSDateTime(getDateTimePlusOneDay())
    });

    const isUpdated = !!updateCallRes[0]?.success;

    if (!isUpdated) {
      showError(updateCallRes[0].details);
    } else {
      // second - reassign the work item and move it to SALES Q
      const reassignWorkRes = await reassignWorkItem({
        currentAgentId: agentId,
        reassignedToAgentId: 'TBD',
        workItemId: selectedWorkItem.id,
        reassignedToQueueId: 'SALES'
      });

      const isReassigned = !!reassignWorkRes?.data?.reassignWork?.success;

      if (isReassigned) {
        deletePendingWorkItem(selectedWorkItem.id);
        showSuccess('Work Item Transferred to Sales Queue');
        setRefreshQueueData(!refreshQueueData);
      } else {
        showError(reassignWorkRes?.data?.reassignWork?.details);
      }
    }
  };

  return (
    <Card>
      <CardHeader>
        <div className='row'>
          <div className='col-md-12 smallPageTitle'>
            <AwesomeLabel
              label={` `}
              icon='clipboard-medical icon-blue'
              size='xl'
            />
            <WorkItemAudience callTitle /> Call
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <Form
          render={(formRenderProps) => (
            <FormElement>
              <div className='row'>
                <div className='col-12'>
                  <Field
                    label={<b>Fully Resolved:</b>}
                    name='callComplete'
                    data={booleanChoices}
                    layout='horizontal'
                    component={FormRadioGroup}
                    onChange={(event) => {
                      handleCompletedChoice(event.value);
                      formRenderProps.onChange('outcome', {
                        value: null
                      });
                      setCallOutcome(null);
                    }}
                  />
                </div>
              </div>

              <div className='row'>
                {completedChoice !== undefined && (
                  <div className='col mt-2'>
                    <Field
                      label='Outcome of Call:'
                      name='outcome'
                      component={DropDownListField}
                      data={outcomeData}
                      textField='title'
                      valueField='value'
                      onChange={(event) => setCallOutcome(event.value.title)}
                    />
                  </div>
                )}
              </div>
              <div className='row'>
                <div className='col-12 mt-10'>
                  <Label editorId='callNotes'>
                    <b>Call Notes:</b>
                  </Label>
                  <Field
                    component={TextAreaFieldSmall}
                    name='callNotes'
                    onChange={(e) => (textAreaRef.current = e)}
                  />
                </div>
              </div>

              <div className='row mt-3'>
                <CallActions>
                  {completedChoice !== undefined && <b>Outcome:</b>}
                  {completedChoice && (
                    <Button
                      className='k-button pageButton'
                      type='button'
                      onClick={handleCloseWorkItem}
                      disabled={!callOutcome}
                    >
                      <AwesomeLabel icon='phone-hangup' label='Close Call' />
                    </Button>
                  )}
                  {completedChoice === false && (
                    <Button
                      type='button'
                      className='k-button pageButton'
                      onClick={handleReturnWork}
                      disabled={!callOutcome}
                    >
                      <AwesomeLabel icon='phone-plus' label='Update Call' />
                    </Button>
                  )}
                  {completedChoice === false && (
                    <Button
                      type='button'
                      className='k-button pageButton'
                      onClick={handleSendToSales}
                      disabled={!callOutcome}
                    >
                      <AwesomeLabel
                        icon='phone-arrow-right'
                        label='Send to Sales'
                      />
                    </Button>
                  )}
                </CallActions>
              </div>
            </FormElement>
          )}
        />
      </CardBody>
    </Card>
  );
};

export default PortalCalls;
