import React, { useContext } from 'react';

// context
import { PatientContext } from '@/context/PatientContext';

// partials
import InfusionTreatmentHistory from '../../Infusion/TreatmentHistory';

const TreatmentHistory = () => {
  const { selectedPatientInfo } = useContext(PatientContext);
  return (
    <>
      <div className="row">
        <div className="col">
          <form className="k-form pl-3 pr-3 pt-1">
            <div className="row" style={{ height: '800px' }}>
              <InfusionTreatmentHistory
                selectedPatientInfo={selectedPatientInfo}
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default TreatmentHistory;
