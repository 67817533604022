import React, { useState, useContext, useRef } from 'react';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { Grid } from '@progress/kendo-react-grid';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { GridColumn as Column } from '@progress/kendo-react-grid/dist/npm/GridColumn';

import DeleteButton from '@/components/common-components/Form/DeleteButton';

// components
import { Constants } from '@/constants';
import {
  InputField,
  DatePickerFieldWithValidation,
  validateInput
} from '@/common/Validation';
import { formatDateToDefault } from '@/common/DateHelper';

// context
import { NotifContext } from '@/context/NotifContext';

const labTestOptions = [
  {
    name: 'ALT'
  },
  {
    name: 'AST'
  },
  {
    name: 'Bilirubin'
  },
  {
    name: 'BUN'
  },
  {
    name: 'Calcium Lab',
    expiration: '12/01/2099'
  },
  {
    name: 'Creatinine'
  },
  {
    name: 'Dexa Lab',
    expiration: '12/01/2099'
  },
  {
    name: 'Dexascan'
  },
  {
    name: 'EBV'
  },
  {
    name: 'Eosinophils'
  },
  {
    name: 'Ferritin'
  },
  {
    name: 'G6PD'
  },
  {
    name: 'GH'
  },
  {
    name: 'Hemoglobin'
  },
  {
    name: 'Hep B',
    expiration: '12/01/2099'
  },
  {
    name: 'HIV Status'
  },
  {
    name: 'HIV Viral Load'
  },
  {
    name: 'IGF-1'
  },
  {
    name: 'IgG'
  },
  {
    name: 'Iron'
  },
  {
    name: 'LVEF'
  },
  {
    name: 'Phosphorus'
  },
  {
    name: 'Platelets'
  },
  {
    name: 'Serum immunoglobulin'
  },
  {
    name: 'T3'
  },
  {
    name: 'TB',
    expiration: '12/01/2099'
  },
  {
    name: 'Total Cholesterol'
  },
  {
    name: 'TSH'
  },
  {
    name: 'Uric Acid'
  },
  {
    name: 'VIP'
  }
];

const ReferralLabTests = ({ labTests, addLabTest, removeLabTest }) => {
  const [selectedLabTest, setSelectedLabTest] = useState(null);
  const labTestFormRef = useRef(null);
  const { showWarning } = useContext(NotifContext);

  const showFormInvalidDialog = () => {
    showWarning(Constants.ErrorMessage.allFields_REQUIRED);
  };

  const onAddLabTest = () => {
    const { labTest, labTestDate, labExpiration, labTestValue } =
      labTestFormRef.current.values;
    if (!labTest || !labTestDate || !labExpiration) {
      return showFormInvalidDialog();
    }

    // handle main form state
    addLabTest({
      labTest,
      labTestDate,
      labTestValue,
      labExpiration
    });

    // handle local state
    setSelectedLabTest(null);
    onResetLabTestForm();
  };

  const onResetLabTestForm = () => {
    if (labTestFormRef?.current) {
      labTestFormRef.current.onChange('name', { value: null });
      labTestFormRef.current.onChange('labTest', { value: '' });
      labTestFormRef.current.onChange('labTestDate', { value: '' });
      labTestFormRef.current.onChange('labTestValue', { value: '' });
      labTestFormRef.current.onChange('labExpiration', { value: '' });
    }
  };

  const onChangeLabTest = (e) => {
    setSelectedLabTest(e.value);

    // sync form fields
    const { name, expiration } = e.value;
    const expirationDate = expiration ? new Date(expiration) : '';
    if (labTestFormRef?.current) {
      labTestFormRef.current.onChange('labTest', { value: name });
      labTestFormRef.current.onChange('labExpiration', {
        value: expirationDate
      });
    }
  };

  const labTestForm = {
    name: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          name: { ...labTestForm.name, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    },
    labTestDate: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          labTestDate: { ...labTestForm.labTestDate, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    },
    labExpiration: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          labExpiration: { ...labTestForm.labExpiration, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    },
    labTest: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          labTest: { ...labTestForm.labTest, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    },
    labTestValue: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          labTestValue: { ...labTestForm.labTestValue, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    }
  };

  const labTestFormValidator = (values) => {
    const errors = {};
    if (
      !values?.labTestDate ||
      new Date(values.labTestDate).getYear() < 0 ||
      new Date(values.labTestDate).getYear() > 199
    ) {
      errors.labTestDate = 'Please enter valid date.';
    }

    if (
      !values?.labExpiration ||
      new Date(values.labExpiration).getYear() < 0 ||
      new Date(values.labExpiration).getYear() > 199
    ) {
      errors.labExpiration = 'Please enter valid date.';
    }

    return errors;
  };

  const render = {
    labDateCell: ({ dataItem }) => {
      const val = dataItem.date ? formatDateToDefault(dataItem.date) : 'N/A';
      return <td>{val}</td>;
    },
    labExpDateCell: ({ dataItem }) => {
      const val = dataItem.labExpiration
        ? formatDateToDefault(dataItem.labExpiration)
        : 'N/A';
      return <td>{val}</td>;
    },
    labValueCell: ({ dataItem }) => {
      let val = '';
      if (dataItem?.results?.length) {
        const resJSON = JSON.parse(dataItem.results);
        if (resJSON.labTestValue) {
          val = resJSON.labTestValue;
        }
      }
      return <td>{val}</td>;
    },
    removeLabTestCell: ({ dataIndex }) => (
      <td>
        <DeleteButton handleClick={() => removeLabTest(dataIndex)} />
      </td>
    )
  };

  return (
    <div className="pl-3 pr-3 pt-1">
      <div
        className="infusion-details col-md-12 mt-2 mb-3"
        style={{ border: '1px solid #afaaaa' }}
      >
        <div className="row">
          <div className="infusion-HeaderRow col-12 ml-0 pl-2 py-2 mr-0">
            <div className="row">
              <div className="col-md-12 headerText">LABS / TESTS</div>
            </div>
          </div>
        </div>
        <div className="mt-2">
          <Form
            ref={labTestFormRef}
            validator={labTestFormValidator}
            render={(labFormRenderProps) => (
              <FormElement>
                <div className="row">
                  <div className="col-4">
                    <Field
                      name="labItem"
                      label="List of Labs / Tests"
                      component={DropDownList}
                      data={labTestOptions}
                      textField="name"
                      valueField="name"
                      onChange={onChangeLabTest}
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-3">
                    <Field
                      name="labTestDate"
                      label="Taken Date"
                      component={DatePickerFieldWithValidation}
                      validator={labTestForm.labTestDate.inputValidator}
                    />
                  </div>
                  <div className="col-md-3">
                    <Field
                      name="labTest"
                      label="Name"
                      component={InputField}
                      readOnly={selectedLabTest?.name}
                      validator={labTestForm.labTest.inputValidator}
                    />
                  </div>
                  <div className="col-md-2">
                    <Field
                      name="labTestValue"
                      label="Value"
                      component={InputField}
                      validator={labTestForm.labTestValue.inputValidator}
                    />
                  </div>
                  <div className="col-md-3">
                    <Field
                      name="labExpiration"
                      label="Expiration"
                      validator={labTestForm.labExpiration.inputValidator}
                      component={DatePickerFieldWithValidation}
                    />
                  </div>
                  <div className="col-md-1 mt-12">
                    <button
                      type="button"
                      className="k-button blue"
                      onClick={onAddLabTest}
                      disabled={!labFormRenderProps.valid}
                    >
                      ADD
                    </button>
                  </div>
                </div>
              </FormElement>
            )}
          />
        </div>
        <div className="row">
          <div className="col-md-12 mt-12 mb-2">
            <Grid className="infusion-grid" data={labTests}>
              <Column
                field="date"
                title="TEST DATE"
                cell={render.labDateCell}
              />
              <Column field="labTest" title="LAB TEST" />
              <Column
                field="labTestValue"
                title="VALUE"
                cell={render.labValueCell}
              />
              <Column
                field="labExpiration"
                title="LAB EXPIRATION"
                cell={render.labExpDateCell}
              />
              <Column title="" cell={render.removeLabTestCell} />
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferralLabTests;
