if (process.env.REACT_APP_ENV === 'stage') {
  module.exports = require('./config.stage');
} else if (process.env.REACT_APP_ENV === 'prod') {
  module.exports = require('./config.prod');
} else if (process.env.REACT_APP_ENV === 'prodx') {
  module.exports = require('./config.prodx');
} else if (process.env.REACT_APP_ENV === 'devx') {
  module.exports = require('./config.devx');
} else if (process.env.REACT_APP_ENV === 'devc') {
  module.exports = require('./config.devc');
} else if (process.env.REACT_APP_ENV === 'testa') {
  module.exports = require('./config.testa');
} else {
  module.exports = require('./config.dev');
}
