import React from 'react';
import { Window } from '@progress/kendo-react-dialogs';

const WindowOverlay = (props) => {
  return (
    <>
      <Window {...props}>{props.children}</Window>
      <div className="k-overlay" />
    </>
  );
};

export default WindowOverlay;
