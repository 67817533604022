import moment from 'moment';
import { validateInput } from '@/common/Validation';
import { Constants } from '@/constants';

const form = {
  drug: {
    value: {},
    inputValidator: (value) => {
      return validateInput({
        referralId: { ...form.drug, value }
      });
    },
    validations: [
      {
        type: 'required',
        message: Constants.ErrorMessage.is_REQUIRED
      }
    ]
  },
  qty: {
    value: '',
    inputValidator: (value) => {
      return validateInput({
        qty: { ...form.qty, value }
      });
    },
    validations: [
      {
        type: 'required',
        message: Constants.ErrorMessage.is_REQUIRED
      },
      {
        type: 'onlyNumeric',
        message: Constants.ErrorMessage.Numeric_Required
      }
    ]
  },
  vendor: {
    value: '',
    inputValidator: (value) => {
      return validateInput({
        referralId: { ...form.vendor, value }
      });
    },
    validations: [
      {
        type: 'required',
        message: Constants.ErrorMessage.is_REQUIRED
      }
    ]
  },
  lot: {
    value: '',
    inputValidator: (value) => {
      return validateInput({
        startDate: { ...form.lot, value }
      });
    },
    validations: [
      {
        type: 'required',
        message: Constants.ErrorMessage.is_REQUIRED
      }
    ]
  },
  recDate: {
    value: '',
    inputValidator: (value) => {
      return validateInput({
        recDate: { ...form.recDate, value }
      });
    },
    validations: [
      {
        type: 'required',
        message: Constants.ErrorMessage.is_REQUIRED
      },
      {
        type: 'datePast',
        message: Constants.ErrorMessage.datePast,
        minDate: moment(),
        maxDate: moment().add(50, 'year')
      }
    ]
  },
  expDate: {
    value: '',
    inputValidator: (value) => {
      return validateInput({
        expDate: { ...form.expDate, value }
      });
    },
    validations: [
      {
        type: 'required',
        message: Constants.ErrorMessage.is_REQUIRED
      },
      {
        type: 'datePast',
        message: Constants.ErrorMessage.datePast,
        minDate: moment(),
        maxDate: moment().add(50, 'year')
      }
    ]
  }
};

export default form;
