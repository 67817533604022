import React, { createContext } from 'react';
import LogRocket from 'logrocket';

export const LogContext = createContext();

const LogContextProvider = ({ children }) => {
  const logApiException = (err, props) => {
    LogRocket.captureMessage('API call exception', {
      extra: {
        errorMessage: err.errors[0]?.message,
        ...props
      }
    });
  };

  const logInfo = (message, props) => {
    LogRocket.captureMessage(message, {
      extra: {
        ...props
      }
    });
  };

  return (
    <LogContext.Provider
      value={{
        logApiException,
        logInfo
      }}
    >
      {children}
    </LogContext.Provider>
  );
};

export default LogContextProvider;
