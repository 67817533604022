import React, { useState } from 'react';

// components
import AwesomeLabel from '@/components/common-components/AwesomeLabel';
import CancelItemReason from './CancelItemReason';
// Styles
import styles from './ItemActions.module.scss';

const CancelItem = () => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <>
      <button
        className={styles.minButtonWidth}
        onClick={() => setIsVisible(true)}
      >
        <AwesomeLabel icon="xmark" label="Cancel Work Item" />
      </button>
      <CancelItemReason
        isVisible={isVisible}
        onClose={() => setIsVisible(false)}
      />
    </>
  );
};

export default CancelItem;
