import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// components
import Preloader from '@/components/common-components/Preloader';

// context
import { InfusionContext } from '@/context/InfusionContext';

// partials
import InfusionHeaderV2 from './InfusionHeaderV2';
import InfusionPortalV2 from './InfusionPortalV2';

const InfusionContainer = () => {
  const [loading, setLoading] = useState(true);

  const { loadInfusion } = useContext(InfusionContext);

  const { id } = useParams();

  useEffect(() => {
    setLoading(true);
    loadInfusion(id).then(() => setTimeout(() => setLoading(false), 500));
  }, [id]);

  return (
    <Preloader show={loading}>
      <InfusionHeaderV2 />
      <InfusionPortalV2 />
    </Preloader>
  );
};

export default InfusionContainer;
