/*
 * Gets the timezone specific date using ZonedData function
 */
// If all timezones are needed, use import '@progress/kendo-date-math/tz/all' which is 600kb
import '@progress/kendo-date-math/tz/all';
// todo: if we know what specific timezones we need, we can just import specific ones to decrease the application bundle size
// import "@progress/kendo-date-math/tz/Etc/UTC";
// import "@progress/kendo-date-math/tz/America/New_York";
// import "@progress/kendo-date-math/tz/America/Chicago";
// import "@progress/kendo-date-math/tz/America/Phoenix";
// import "@progress/kendo-date-math/tz/America/Los_Angeles";

import { ZonedDate } from '@progress/kendo-date-math';

export const getTimezoneSpecificDate = (utcDateString, timezone) => {
    const dateInBrowserTimezone = new Date(utcDateString);
    const tzDate = ZonedDate.fromLocalDate(dateInBrowserTimezone, timezone);
    return tzDate;
};

const pad = (val) => {
    if (val && val < 10) {
        return `0${val}`;
    }
    return val;
};
export const getTimezoneSpecificDateString = (utcDateString, timezone) => {
    if (!utcDateString?.length) {
        return null;
    }
    const tzDate = getTimezoneSpecificDate(utcDateString, timezone);
    return `${tzDate.getFullYear()}-${pad(tzDate.getMonth() + 1)}-${pad(tzDate.getDate())}`;
};

export const getAppointmentMinTime = (utcDateString, timezone) => {
    const date = utcDateString?.length ? getTimezoneSpecificDate(utcDateString, timezone) : new Date();
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 8, 0);
};

export const getAppointmentMaxTime = (utcDateString, timezone) => {
    const date = utcDateString?.length ? getTimezoneSpecificDate(utcDateString, timezone) : new Date();
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 18, 0);
};
