import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';

// common
import { infusionPortalTabs } from '@/common/NavHelper';

// context
import { UserContext, PatientContext } from '@/context';

// partials
import Infusion from './InfusionMainV2';
import { Patient } from '../Patient';
import { Prescriber } from '../Prescriber';
import { Fax } from '../FAX';
import TreatmentHistory from '../Infusion/TreatmentHistory';
import { AppointmentListData } from '../Appointments/AppointmentListData';

const InfusionPortalV2 = () => {
  const history = useHistory();
  const location = useLocation();
  const { agent } = useContext(UserContext);
  const { selectedPatientInfo, showPatientFaxDocument, setCompletedTreatment } =
    useContext(PatientContext);

  const [selectedTab, setSelectedTab] = useState(0);

  const handleSelect = (e) => {
    setSelectedTab(e.selected);
  };

  // LISTENERS
  useEffect(() => {
    if (location?.state?.searchType) {
      const isHistoryTab = location.state.searchType === 'treatmentHistory';
      const tab = isHistoryTab
        ? infusionPortalTabs.TREATMENT_HISTORY
        : infusionPortalTabs.OVERVIEW;
      setSelectedTab(tab);

      // generateFax
      if (isHistoryTab && location.state.generateFax === true) {
        setCompletedTreatment(location.state.treatmentId);
      } else {
        setCompletedTreatment(null);
      }
    }
  }, [location]);

  useEffect(() => {
    // redirect to Patient Portal instead
    if (selectedTab === infusionPortalTabs.TREATMENT_HISTORY) {
      history.push('/patient-portal', {
        searchType: 'PATIENT',
        redirectPatienTreatHistory: true
      });
    }
  }, [selectedTab]);

  return (
    <div className='container-fluid portal-container infusion-portal'>
      <div className='row'>
        <div className='col-12 p-0'>
          <div className='container-fluid p-0 navBar1'>
            <TabStrip selected={selectedTab} onSelect={handleSelect}>
              <TabStripTab
                contentClassName='navBar2'
                title='INFUSION&nbsp;&nbsp;'
              >
                <div className='tabText'>
                  <Infusion />
                </div>
              </TabStripTab>
              <TabStripTab contentClassName='navBar2' title='PATIENT INFO'>
                <div className='tabText'>
                  <Patient
                    agent={agent}
                    selectedPatientInfo={selectedPatientInfo}
                    sendDataToParent={() => {}}
                  />
                </div>
              </TabStripTab>
              <TabStripTab contentClassName='navBar2' title='PRESCRIBER'>
                <div className='tabText'>
                  <Prescriber
                    agent={agent}
                    selectedPatientInfo={selectedPatientInfo}
                    sendDataToParent={() => {}}
                  />
                </div>
              </TabStripTab>
              <TabStripTab contentClassName='navBar2' title='TREATMENT HISTORY'>
                <div className='tabText'>
                  <TreatmentHistory
                    agent={agent}
                    selectedPatientInfo={selectedPatientInfo}
                    sendDataToParent={() => {}}
                  />
                </div>
              </TabStripTab>
              <TabStripTab contentClassName='navBar2' title='FAX'>
                <div className='tabText'>
                  <Fax
                    agent={agent}
                    selectedPatientInfo={selectedPatientInfo}
                    sendDataToParent={() => {}}
                  />
                </div>
              </TabStripTab>
              <TabStripTab contentClassName='navBar2' title='APPOINTMENTS'>
                <div className='tabText'>
                  <AppointmentListData
                    selectedPatientInfo={selectedPatientInfo}
                    sendDataToParent={() => {}}
                  />
                </div>
              </TabStripTab>
            </TabStrip>
          </div>
        </div>
      </div>
      {selectedPatientInfo && selectedPatientInfo.documentURI && (
        <button
          type='submit'
          onClick={showPatientFaxDocument}
          className='k-button pageTab doc-fixed-right'
        >
          Patient Document
        </button>
      )}
    </div>
  );
};

export default InfusionPortalV2;
