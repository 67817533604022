import React from 'react';
import { filterInt } from '../../Infusion/infusionHelper';
import { last30daysOptionsPatient, skipReasons } from '../models/modelBlocks';
import * as dictionary from '../surveyDictionary';
import { dateDiffInDays } from '@/common/DateHelper';

export const calculateProviderScore = (results) => {
  let score = 0;

  Object.keys(results).forEach((property) => {
    // === Result filters ===
    // 1 — We can sum up numbers only
    // So, first we need to separate numbers
    const valueInt = filterInt(results[property]);
    const isNumber = !Number.isNaN(valueInt);

    // 2 — It requires to filter out all PHQ9 answers from the final calculations
    const isPHQ9Related = property.includes('PHQ9');

    // 3 - Q12: If 10 skip question 13 and assign a value of 10
    if (property === 'LoI-self-care' && valueInt === 10) {
      score += 10;
    }

    // 4 - Q1: No (assign 10 to question 3 and skip that question)
    if (property === 'PA-activity-level' && results[property] === false) {
      score += 10;
    }

    if (isNumber && !isPHQ9Related) {
      score += valueInt;
    }
  });

  return score;
};

// this is exact copy from `aic-mobile`
// if any changes neeeded - please, update in both repos
export const calculatePatientSurveyScore = (results) => {
  let score = 0;

  Object.keys(results).forEach((property) => {
    // === Result filters ===
    // 1 — We can sum up numbers only
    // So, first we need to separate numbers
    const valueInt = filterInt(results[property]);
    const isNumber = !Number.isNaN(valueInt);

    // 2 — It requires to filter out all PHQ9 answers from the final calculations
    const isPHQ9Related = property.includes('PHQ9');

    // 3 - Q12: If 10 skip question 13 and assign a value of 10
    // if (property === "LoI-self-care" && valueInt === 10) {
    //   score += 10;
    // }

    // 4 - Q1: No (assign 10 to question 3 and skip that question)
    // if (property === "PA-activity-level" && results[property] === false) {
    //   score += 10;
    // }

    if (isNumber && !isPHQ9Related) {
      score += valueInt;
    }
  });

  return score;
};

export const calculatePHQ9Score = (results) => {
  // need to separate non-PHQ9 from PHQ9
  if (Array.isArray(results) && results.some((res) => res.includes('PHQ9')))
    return null;

  let score = 0;

  Object.keys(results).forEach((property) => {
    // === Result filters ===
    // 1 — We can sum up numbers only
    // So, first we need to separate numbers
    const valueInt = filterInt(results[property]);
    const isNumber = !Number.isNaN(valueInt);

    // 2 — It requires to keep only PHQ9 answers for the final calculations
    const isPHQ9Related = property.includes('PHQ9');

    // 3 — Exclude PHQ9-Q10 from the PHQ9 total scoring
    const isExcludedQuestion = property === 'PHQ9-Q10';

    if (isNumber && isPHQ9Related && !isExcludedQuestion) {
      score += valueInt;
    }
  });

  return score;
};

export const buildDXString = (referralOrder) => {
  const orderDxs = [referralOrder.primaryDX, ...referralOrder.otherDX].filter(
    (dx) => dx.primaryDiagnosis !== null
  );
  const dxArray = [];

  orderDxs.forEach((dx) => {
    dxArray.push(dx);
  });

  return dxArray
    .map((dx) => `${dx.primaryDiagnosis} - ${dx.description}`)
    .join(', ');
};

export const getSurveySettingsQOL = (surveys) => {
  // by default we use 'AQCCA Provider - First Infusion (Administration)' type
  const settings = {
    surveyType: dictionary.QOL_PROVIDER_1ST_INFUSION,
    forceDisplay: true
  };

  // no surveys means we should use 1st infusion type
  if (!surveys || surveys.length === 0) {
    return settings;
  }

  // filter out non-QOL related surveys and skipped surveys (no score)
  const surveysQOL = surveys
    .filter((survey) =>
      [
        dictionary.QOL_PROVIDER_1ST_INFUSION,
        dictionary.QOL_PROVIDER_FOLLOWING
      ].includes(survey.typeOfSurvey)
    )
    .filter((surveyData) => {
      const { meta, score } = JSON.parse(surveyData.survey);
      if (!score) return false;
      return meta.isSurveySkipped !== true;
    });

  if (!surveysQOL.length) return settings;

  // last element in array - is the most recent survey
  const lastSurvey = surveysQOL.at(-1);

  // check survey reuqirements based on risk (prev results)
  const isRequired = isSurveyRequiredByRisk(lastSurvey);

  // using following type of QOL survey
  if (isRequired) {
    return {
      ...settings,
      surveyType: dictionary.QOL_PROVIDER_FOLLOWING,
      isFollowUp: true
    };
  }

  // otherwise we shouldn't display the survey at all
  return { forceDisplay: false };
};

export const flipPainScaleValue = (surveyValue) => {
  // Makes an opposite value for pain scale score, e.g.:
  // 10 => 1, 9 => 2, ..., 1 => 10
  // Base value equals to 11 - it's a sum for all scores (9+2 or 7+4)
  const baseValue = 11;

  return baseValue - surveyValue;
};

export const generateChartNotesQOL = (results) => {
  const chartNotes = [];
  const found = results?.score.find((element) => element.type === 'PHQ9');
  // Question #2
  // NOTE: this pain scale is a bit tricky, because we give 10 as score,
  // but, at the same time - it should be an opposite:
  // (no pain gives 10 as AQCCA score and 1 for the real pain scale level)
  // Same when you select answer with value 7 - it'll be translated to 4 in real scale level
  const flippedPainScore = flipPainScaleValue(results['general-pain-scale']);
  if (flippedPainScore > 5) {
    chartNotes.push(`Patient reports a ${flippedPainScore} on pain scale`);
  }
  // Question #4
  if (results['PA-hard-to-eat'] < 4) {
    chartNotes.push(
      'Patient reports significant difficulty with eating. Recommend weight monitoring. See current weight at this visit'
    );
  }
  // Question #6
  if (results['EWB-depression'] < 6) {
    chartNotes.push(
      `Signs/symptoms of depression reported/observed. PHQ-9 assessment score ${found?.score}`
    );
  }

  // Question #8
  if (results['cognition-literacy-level'] < 4) {
    chartNotes.push(
      'Observed patient has low health literacy. Recommend continuous education efforts'
    );
  }

  // Question #10
  if (results['cognition-remembering'] < 4) {
    chartNotes.push(
      'Patient reports significant forgetfulness. Provided patient with tools/resources to address'
    );
  }

  // Question #11
  if (results['cognition-problem-solving'] < 4) {
    chartNotes.push(
      'Patient experiencing difficulty problem solving health concerns. Provided tools/resources to address'
    );
  }

  // Questions #12-13
  if (results['LoI-self-care'] < 4 || results['LoI-caregiver-access'] < 4) {
    chartNotes.push(
      'Patient reports self care needs and lack of resources. Recommend assessing if would benefit from Case Management'
    );
  }

  // Question #19
  if (results['EDU-hospitalizations'] === true) {
    chartNotes.push('Patient reports recent Hospitalization/ER visit');
  }

  const notesLog = chartNotes.join('\n');
  return notesLog;
};

// NOTE: this is exact copy of the same method from `aic-mobile`
// please update in both repos when changes are required
export const generateTabletSurveyChartNotes = (results) => {
  const chartNotes = [];
  const found = results?.score.find((element) => element.type === 'PHQ9');

  // NOTE: this pain scale is a bit tricky, because we give 10 as score,
  // but, at the same time - it should be an opposite:
  // (no pain gives 10 as AQCCA score and 1 for the real pain scale level)
  // Same when you select answer with value 7 - it'll be translated to 4 in real scale level
  const flippedPainScore = flipPainScaleValue(results['general-pain-scale']);
  if (flippedPainScore > 5) {
    chartNotes.push(`Patient reports a ${flippedPainScore} on pain scale`);
  }

  if (results['PA-days-in-pain'] < 8) {
    const daysValue = last30daysOptionsPatient.find(
      (option) => option.value === results['PA-days-in-pain']
    );
    if (daysValue) {
      chartNotes.push(
        `Patient reports experiencing pain ${daysValue?.text} during the past month`
      );
    }
  }

  if (results['PA-disease-effects-days'] < 8) {
    const daysValue = last30daysOptionsPatient.find(
      (option) => option.value === results['PA-disease-effects-days']
    );
    if (daysValue) {
      chartNotes.push(
        `Patient reports not feeling well due to their condition ${daysValue?.text} during the past month`
      );
    }
  }

  if (results['PA-hard-to-eat'] < 4) {
    chartNotes.push(`Patient reports significant difficulty with eating. Recommend weight monitoring. 
  See current weight at this visit`);
  }

  // PHQ9 trigger
  if (results['EWB-depression'] < 6) {
    chartNotes.push(
      `Signs/symptoms of depression reported/observed. PHQ-9 assessment score ${found?.score}`
    );
  }

  if (results['cognition-literacy-level'] < 4) {
    chartNotes.push(
      'Observed patient has low health literacy. Recommend continuous education efforts'
    );
  }

  if (results['cognition-remembering'] < 4) {
    chartNotes.push(
      'Patient reports significant forgetfulness. Provided patient with tools/resources to address'
    );
  }

  if (results['cognition-problem-solving'] < 4) {
    chartNotes.push(
      'Patient experiencing difficulty problem solving health concerns. Provided tools/resources to address'
    );
  }

  if (results['LoI-self-care'] < 4 || results['LoI-caregiver-access'] < 4) {
    chartNotes.push(
      'Patient reports self care needs and lack of resources. Recommend assessing if would benefit from Case Management'
    );
  }

  if (results['risks-adherence'] < 6) {
    chartNotes.push(
      'Patient experiences challenges with adherene to therapy. Provided education and tools'
    );
  }

  if (results['risks-hospitalizations'] === true) {
    chartNotes.push('Patient reports recent Hospitalization/ER visit');
  }

  const notesLog = chartNotes.join('\n');
  return notesLog;
};

export const getScoreRating = (score, survey) => {
  const version = survey.version || 1;
  const type = survey.surveyType;

  // AQQCA Score Rating:
  if (score && score.type === 'AQCCA') {
    // =============================
    // Provider (Nurse) AQCCA survey
    // =============================
    const hasProviderType = [
      dictionary.QOL_PROVIDER_1ST_INFUSION,
      dictionary.QOL_PROVIDER_FOLLOWING
    ].includes(type);
    if (hasProviderType) {
      // for model version 1
      if (version === 1) {
        if (score.score < 40) return dictionary.QOL_RATING_VERY_POOR;
        if (score.score < 60) return dictionary.QOL_RATING_POOR;
        if (score.score < 81) return dictionary.QOL_RATING_AT_RISK;
        if (score.score < 121) return dictionary.QOL_RATING_GENERALLY_WELL;
        return dictionary.QOL_RATING_VERY_WELL;
      }

      // for model version 2
      if (version === 2) {
        if (score.score < 66) return dictionary.QOL_RATING_POOR;
        if (score.score < 81) return dictionary.QOL_RATING_AT_RISK;
        if (score.score < 97) return dictionary.QOL_RATING_AVERAGE;
        if (score.score < 109) return dictionary.QOL_RATING_GENERALLY_WELL;
        return dictionary.QOL_RATING_VERY_WELL;
      }
    } else {
      // currently we only have 2 types, if get more - add another `else if` statement
      // ===================================
      // Patient (tablet) AQCCA survey type:
      // ===================================
      // for model version 1
      if (version === 1) {
        // if (score.score < 40) return dictionary.QOL_RATING_VERY_POOR;
        if (score.score < 100) return dictionary.QOL_RATING_POOR;
        if (score.score < 115) return dictionary.QOL_RATING_AT_RISK;
        if (score.score < 135) return dictionary.QOL_RATING_AVERAGE;
        if (score.score < 150) return dictionary.QOL_RATING_GENERALLY_WELL;
        return dictionary.QOL_RATING_VERY_WELL;
      }
    }
  }

  // PHQ9 Score Rating:
  if (score && score.type === 'PHQ9') {
    if (score.score < 10) return dictionary.QOL_RATING_MILD;
    if (score.score < 15) return dictionary.QOL_RATING_MODERATE;
    if (score.score < 20) return dictionary.QOL_RATING_MODERATELY_SEVERE;
    return dictionary.QOL_RATING_SEVERE_DEPRESSION;
  }
};

export const renderWeightInHeader = (weightInfo) => {
  if (!weightInfo) return 'n/a';

  const { weightLB, weight } = weightInfo.at(-1);

  return `${weightLB}(lbs), ${weight}(kgs)`;
};

export const generateSurveyNotes = (notes) => {
  const noResNotes = [
    { type: 'info', description: 'We do not have any notes for this survey.' }
  ];
  if (!notes) return noResNotes;

  const notesArr = notes.split(/\r?\n/);
  if (notesArr.length === 0) return noResNotes;

  return notesArr.map((note) => {
    return {
      type: 'warning',
      description: note
    };
  });
};

export const calculateSurveyScores = (jsonResults, isTabletSurvey = false) => {
  if (!jsonResults) return null;

  const scoreCalc = isTabletSurvey
    ? calculatePatientSurveyScore
    : calculateProviderScore;
  const qolScore = scoreCalc(jsonResults);
  const phq9Score = calculatePHQ9Score(jsonResults);

  const score = [{ type: 'AQCCA', score: qolScore }];

  if (phq9Score) {
    score.push({ type: 'PHQ9', score: phq9Score });
  }

  return score;
};

export const getTabletSurveyScores = (jsonResults) => {
  if (!jsonResults) return null;

  const parsedSurvey = JSON.parse(jsonResults.survey);
  return parsedSurvey.score || 0;
};

export const compareScores = (currentScore, priorScore) => {
  if (!priorScore || priorScore.length === 0) return 0; // nothing to compare

  const scoreByType = priorScore.find(
    (pScore) => pScore.type === currentScore.type
  );

  if (scoreByType) {
    return currentScore.score - scoreByType.score;
  }
  console.error(`Prior score for type '${currentScore.type}' not found`);
  return 0;
};

export const renderScoreChange = (scoreDiff) => {
  if (scoreDiff === 0) return null;
  if (scoreDiff > 0) return <sup style={{ color: 'green' }}>+{scoreDiff}</sup>;

  return <sub style={{ color: 'red' }}>{scoreDiff}</sub>;
};

export const generateEducationLinks = (results) => {
  const links = [];

  if (results['cognition-problem-solving'] < 4) {
    links.push({
      title: 'Patient Self-Care Problem Solving Handout',
      fileName: dictionary.SELF_CARE_GUIDE
    });
  }

  if (results['cognition-remembering'] < 4) {
    links.push({
      title: 'Patient Memory Guide Handout',
      fileName: dictionary.MEMORY_TOOL_GUIDE
    });
  }

  return links;
};

// AQCCA Provider (Nurse) type of the survey only:
// check results and comparing dates based on risk
// returns a boolean, if true - survey is required
export const isSurveyRequiredByRisk = (surveyData) => {
  const { score, meta } = JSON.parse(surveyData.survey);
  const aqccaScore = score.find((scoreEntry) => scoreEntry.type === 'AQCCA');

  // require survey if prev result has not been found
  if (!aqccaScore) return true;

  const prevScore = aqccaScore.score;

  let daysByRisk = 60; // Generally well or Very well

  // define risk level based on model version
  if (meta.version === 2) {
    if (prevScore < 81) {
      daysByRisk = 30; // Poor or At Risk
    } else if (prevScore < 97) {
      daysByRisk = 45; // Average
    }
  } else {
    // model version 1 or default
    if (prevScore < 60) {
      daysByRisk = 30; // Poor or Very Poor
    } else if (prevScore < 81) {
      daysByRisk = 45; // At Risk
    }
  }

  // comparing dates now
  const surveyDate = new Date(surveyData.collectedAt);
  const today = new Date();
  const diffDays = dateDiffInDays(surveyDate, today);

  // display survey if last survey was long time ago, based on prev results risk value
  return diffDays >= daysByRisk;
};

// AQCCA Patient (tablet) type of the survey only:
// check results and comparing dates based on risk
// returns a boolean, if true - survey is required
export const isPatientSurveyRequiredByRisk = (surveyData) => {
  const { score, meta } = JSON.parse(surveyData.survey);
  const aqccaScore = score.find((scoreEntry) => scoreEntry.type === 'AQCCA');

  // require survey if prev result has not been found
  if (!aqccaScore) return true;

  const prevScore = aqccaScore.score;

  let daysByRisk = 60; // Generally well or Very well

  // define risk level based on model version
  if (meta.version === 1) {
    if (prevScore < 115) {
      daysByRisk = 30; // Poor or At Risk
    } else if (prevScore < 135) {
      daysByRisk = 45; // Average
    }
  } else {
    // move logic for version 1 if we update models at some point
  }

  // comparing dates now
  const surveyDate = new Date(surveyData.collectedAt);
  const today = new Date();
  const diffDays = dateDiffInDays(surveyDate, today);

  // display survey if last survey was long time ago, based on prev results risk value
  return diffDays >= daysByRisk;
};

export const generateSkipSurveyNotes = (surveyData) => {
  const reason = skipReasons[surveyData.reasonCode];
  return `Agent '${surveyData.agentId}' skipped the AQCCA survey. Reason: ${reason}`;
};

export const generateSurveyOptOutNote = (agentName, reasonCode) => {
  let note = `Agent '${agentName}' opted patient out from any following surveys`;

  if (reasonCode) {
    const reason = skipReasons[reasonCode];
    note += `. Reason: ${reason}`;
  }

  return note;
};
