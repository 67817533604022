export default [
  'PAP - Acaria Health (Aduhelm)',
  'PAP - Acaria Health (Tysabri Free Drug)',
  'PAP - Accredo Pharmacy (Entyvio Connect)',
  'AleraCare Pharmacy of AZ',
  'AleraCare Pharmacy of CA',
  'PAP - Amgen Safety Net Foundation',
  'PAP - AstraZenica',
  'Baxalta US Inc.',
  'Biocare',
  'PAP - Biogen Support Services',
  'Biologix Direct',
  'Cardinal Physician Office Oncology',
  'CuraScript SD',
  'PAP - Entyvio Connect Patient Assistance',
  'Eversana Life Science Services, LLC',
  'FFF Enterprises',
  'PAP - Gateway to Nucala',
  'PAP - Genentech Patient Foundation',
  'PAP - Home Scripts',
  'PAP - Horizon Therapeutics',
  'Johnson & Johnson DePuy Synthes',
  'PAP - Johnson and Johnson Foundation',
  'PAP - J&J Patient Assistance',
  'PAP - Lundbeck Foundation',
  'McKesson Pharmaceutical Distribution',
  'McKesson Plasma & Biologics',
  'McKesson Specialty Health',
  'PAP - Medvantix (Genenetech Patient Foundation)',
  'PAP - myAbbVie Assist',
  'PAP - Novartis',
  'PAP - Organon Access Program',
  'Patient Supplied Medication',
  'PAP - Pfizer Encompass',
  'PAP - Rx Crossroads Takeda',
  'Sanofi Genzyme',
  'PAP - Sonexus Health',
  'Takeda Pharmaceutical',
  'PAP - Tezspire Connect',
  'PAP - Vasco Specialty Pharmacy'
];
