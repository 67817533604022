import React, { useEffect, useState, useContext } from 'react';
import { arrayMoveImmutable } from 'array-move';
import * as moment from 'moment';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Input, RadioGroup, Switch } from '@progress/kendo-react-inputs';
import {
  Card,
  CardTitle,
  CardBody,
  CardActions
} from '@progress/kendo-react-layout';
import { Form, Field } from '@progress/kendo-react-form';
import { TimePicker } from '@progress/kendo-react-dateinputs';
import { Error } from '@progress/kendo-react-labels';

// components
import DeleteButton from '@/components/common-components/Form/DeleteButton';
import { MessageDialog } from '@/components/common-components/MessageDialog';
import WindowDialog from '@/components/common-components/WindowDialog';
import AwesomeLabel from '@/components/common-components/AwesomeLabel';
import Badge from '@/components/common-components/Badge';

// helpers
import { InputField, validateFloat } from '@/common/Validation';
import { getPatientWeightFromReferralLB, lb2kg } from '@/common/Converters';
import {
  formatDateToAWS,
  formatDateToDefault,
  formatTimeToDefault,
  getNow
} from '@/common/DateHelper';
import { CellRender, RowRender } from '@/common/GridRenderer';

// gql
import { connectToGraphqlAPI } from '@/provider';
import { updateStepAssessment } from '@/graphql/mutations';
import { getAssessmentWeights } from '@/graphql/queries';

// context
import {
  NotifContext,
  InfusionContext,
  UserContext,
  PatientContext
} from '@/context';

// constants
import { Constants } from '@/constants';

// partials
import PatientWeightLog from '../Infusion/PatientWeightLog';
import PatientAllergiesLog from '../Patient/PatientAllergiesLog';
import PatientMedsLog from '../Patient/PatientMedsLog';
import PatientLogInfo from '../Patient/PatientLogInfo';
import {
  getAdminMaxTime,
  getAdminMinTime
} from '../Scheduling/getSchedulerTimeLimits';
import {
  getStepStatusV2,
  infusionStepNumbersV2,
  infusionSteps,
  setQuestionnaire
} from '../Infusion/infusionHelper';

const AssessmentV2 = (props) => {
  const { showWarning, showError } = useContext(NotifContext);
  const { infusion, setInfusion, setCurrentStep, updateStepper } =
    useContext(InfusionContext);
  const { agent } = useContext(UserContext);
  const { setSelectedPatientInfo } = useContext(PatientContext);

  const { nursingProcess, patientBucket, currentReferral } = infusion;

  const nursingProcessId = nursingProcess?.id;

  const [apiWeights, setApiWeights] = useState([]);
  const [itemWeights, setItemWeights] = useState([]);
  const [apiVitals, setApiVitals] = useState([]);
  const [itemVitals, setItemVitals] = useState([]);

  const [theQ, setTheQ] = useState();
  const [stepAllowed, setStepAllowed] = useState(false);
  const [dialogOption, setDialogOption] = useState({});
  const [showAssessments, setShowAssessments] = useState(false);

  const [showQuestionSet1, setShowQuestionSet1] = useState(false);
  const [showQuestionSet2, setShowQuestionSet2] = useState(false);
  const [showQuestionSet3, setShowQuestionSet3] = useState(false);
  const [showQuestionSet4, setShowQuestionSet4] = useState(false);
  const [showQuestionSet5, setShowQuestionSet5] = useState(false);
  const [showQuestionSet6, setShowQuestionSet6] = useState(false);
  const [showQuestionSet7, setShowQuestionSet7] = useState(false);
  const [showQuestionSet8, setShowQuestionSet8] = useState(false);
  const [showQuestionSet9, setShowQuestionSet9] = useState(false);
  const [showQuestionSet10, setShowQuestionSet10] = useState(false);
  const [showQuestionSet11, setShowQuestionSet11] = useState(false);
  const [showQuestionSet12, setShowQuestionSet12] = useState(false);
  const [question1Count, setQuestions1] = useState(0);
  const [question2Count, setQuestions2] = useState(0);
  const [question3Count, setQuestions3] = useState(0);
  const [question4Count, setQuestions4] = useState(0);
  const [question5Count, setQuestions5] = useState(0);
  const [question6Count, setQuestions6] = useState(0);
  const [question7Count, setQuestions7] = useState(0);
  const [question8Count, setQuestions8] = useState(0);
  const [question9Count, setQuestions9] = useState(0);
  const [question10Count, setQuestions10] = useState(0);
  const [question11Count, setQuestions11] = useState(0);
  const [question12Count, setQuestions12] = useState(0);

  const [isTimeRangeError, setIsTimeRangeError] = useState(false);

  const booleanChoices = [
    { label: 'Yes', value: true, className: 'patient-radio blue' },
    { label: 'No', value: false, className: 'patient-radio blue' }
  ];

  useEffect(() => {
    sortQuestionData();
  }, [theQ]);

  const updateStepAssessmentCall = async (input) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: updateStepAssessment,
        variables: { input }
      });
      if (data?.data?.updateStepAssessment) {
        setCurrentStep(infusionStepNumbersV2.PRE_TREATMENT);

        const infusionData = { ...infusion };

        // @NOTE: since patient weight can be changed only once per day -
        // we'll need to use this callback in order to get updated list of weights
        const cbData = await connectToGraphqlAPI({
          graphqlQuery: getAssessmentWeights,
          variables: { id: infusionData.nursingProcess.id }
        });

        const patientWeights =
          cbData.data.getNursingProcess.stepAssessment.patientWeights || [];

        infusionData.nursingProcess.stepAssessment = {
          ...infusionData.nursingProcess.stepAssessment,
          executedBy: input.agentId,
          noAssessmentToday: input.noAssessmentToday,
          patientWeights,
          questionnaire: input.questionnaire,
          vitals: [...input.vitals]
        };

        // update infusion data in the context level
        setInfusion({ ...infusionData });

        // update stepper progress checkmark
        const stepIsValid = getStepStatusV2(infusionSteps.ASSESSMENT, input);
        updateStepper(stepIsValid);
      }
    } catch (err) {
      console.error('AssessmentV2::updateStepAssessmentCall err: ', err);
      showError(
        'Error: check logs for AssessmentV2::updateStepAssessmentCall module'
      );
    }
  };

  const sortQuestionData = () => {
    if (theQ) {
      // Step 1: Turn theQ object into an array
      let theQArr = Object.keys(theQ).map((key) => [key, theQ[key]]);
      // Step 2: Sort the array to get questions in the correct group
      theQArr = theQArr.sort();
      // Step 3: filter out all of the groups into 12 sets of questions
      const set1 = theQArr.filter((x) => x[0].includes('question1_'));
      const set2 = theQArr.filter((x) => x[0].includes('question2_'));
      // Step 3.1: Use array-move to put questions in the correct location in the set
      let set3 = theQArr.filter((x) => x[0].includes('question3_'));
      set3 = arrayMoveImmutable(set3, 1, 11);
      set3 = arrayMoveImmutable(set3, 1, 11);
      set3 = arrayMoveImmutable(set3, 1, 11);
      const set4 = theQArr.filter((x) => x[0].includes('question4_'));
      const set5 = theQArr.filter((x) => x[0].includes('question5_'));
      let set6 = theQArr.filter((x) => x[0].includes('question6_'));
      set6 = arrayMoveImmutable(set6, 1, 9);
      const set7 = theQArr.filter((x) => x[0].includes('question7_'));
      const set8 = theQArr.filter((x) => x[0].includes('question8_'));
      const set9 = theQArr.filter((x) => x[0].includes('question9_'));
      const set10 = theQArr.filter((x) => x[0].includes('question10_'));
      const set11 = theQArr.filter((x) => x[0].includes('question11_'));
      const set12 = theQArr.filter((x) => x[0].includes('question12_'));
      // Step 4: Add keys for switch
      const questState = [
        [set1, 'setQuestions1'],
        [set2, 'setQuestions2'],
        [set3, 'setQuestions3'],
        [set4, 'setQuestions4'],
        [set5, 'setQuestions5'],
        [set6, 'setQuestions6'],
        [set7, 'setQuestions7'],
        [set8, 'setQuestions8'],
        [set9, 'setQuestions9'],
        [set10, 'setQuestions10'],
        [set11, 'setQuestions11'],
        [set12, 'setQuestions12']
      ];
      // Step 5: Filter out all the even questions that relate to the input field and set the question group state.
      questState.forEach((x) => {
        filterSet(x[0], x[1]);
      });
    }
  };

  const filterSet = (arr, state) => {
    // Step 5.1: Filter out all even questions
    let filtered = arr.filter((element, index) => {
      return index % 2 === 0;
    });
    filtered = filtered.reduce(
      (r, o) => r + +!Object.values(o).includes(null),
      0
    );
    // Step 5.2: Set the question state
    switch (state) {
      case 'setQuestions1':
      default:
        setQuestions1(filtered);
        break;
      case 'setQuestions2':
        setQuestions2(filtered);
        break;
      case 'setQuestions3':
        setQuestions3(filtered);
        break;
      case 'setQuestions4':
        setQuestions4(filtered);
        break;
      case 'setQuestions5':
        setQuestions5(filtered);
        break;
      case 'setQuestions6':
        setQuestions6(filtered);
        break;
      case 'setQuestions7':
        setQuestions7(filtered);
        break;
      case 'setQuestions8':
        setQuestions8(filtered);
        break;
      case 'setQuestions9':
        setQuestions9(filtered);
        break;
      case 'setQuestions10':
        setQuestions10(filtered);
        break;
      case 'setQuestions11':
        setQuestions11(filtered);
        break;
      case 'setQuestions12':
        setQuestions12(filtered);
        break;
    }
  };

  // LISTENERS
  useEffect(() => {
    if (nursingProcessId) {
      handleLoadInfusion();
      setSelectedPatientInfo(patientBucket);
    }
  }, [nursingProcessId]);

  const handleLoadInfusion = () => {
    if (nursingProcess && nursingProcess.stepReview) {
      if (nursingProcess.stepReview.orderApproved) {
        setStepAllowed(true);
      }
    }

    if (nursingProcess?.stepAssessment?.patientWeights) {
      try {
        const a = [];
        const i = [];
        nursingProcess.stepAssessment.patientWeights.forEach((item, idx) => {
          const isLastItem =
            idx === nursingProcess.stepAssessment.patientWeights.length - 1;

          if (isLastItem) {
            const apiWeight = {
              weight: item.weightLB,
              entered: formatDateToAWS(item.entered),
              unitOfWeight: 'LB'
            };
            a.push(apiWeight);
          }

          const itemWeight = {
            patientWeightLB: item.weightLB,
            calcPatientWeightKG: item.weight,
            lastKnownWeightLB: item.lastKnown,
            calcChangeFromLastKnown: item.changeFromLastKnown,
            origOrderWeightLB: item.lastKnown,
            dateEntered: formatDateToDefault(item.entered)
          };
          i.push(itemWeight);
        });
        setApiWeights([...a]);
        setItemWeights([...i]);
      } catch (err) {
        console.error('AssessmentV2::nursingProcess patientWeights err: ', err);
        setDialogOption({
          title: 'Infusion: Assessment',
          message: 'Error: nursingProcess patientWeights',
          showDialog: true
        });
      }
    }

    if (nursingProcess?.stepAssessment?.vitals) {
      try {
        const newApiVitals = [];
        const newItemVitals = [];

        nursingProcess.stepAssessment.vitals.forEach((vital, idx) => {
          const apiVital = {
            ...vital,
            recordNumber: 1,
            enteredAt: moment(vital.enteredAt)
          };
          const itemVital = {
            id: idx,
            temp: vital.temperature,
            bp: vital.bloodPressure,
            hr: vital.heartRate,
            r: vital.R,
            spo2: vital.SP02,
            initials: vital.initials,
            time: formatTimeToDefault(vital.enteredAt)
          };
          newApiVitals.push(apiVital);
          newItemVitals.push(itemVital);
        });
        setApiVitals(newApiVitals);
        setItemVitals(newItemVitals);
      } catch (err) {
        console.error('AssessmentV2::nursingProcess vitals err: ', err);
        setDialogOption({
          title: 'Infusion: Assessment',
          message: 'Error: nursingProcess vitals',
          showDialog: true
        });
      }
    }

    if (
      nursingProcess &&
      nursingProcess.stepAssessment &&
      nursingProcess.stepAssessment.questionnaire &&
      nursingProcess.stepAssessment.questionnaire !== '[]'
    ) {
      try {
        const thisQ = JSON.parse(nursingProcess.stepAssessment.questionnaire);
        setTheQ(thisQ);
        setShowAssessments(true);
      } catch (err) {
        console.error('AssessmentV2::nursingProcess questionnaire err: ', err);
        setDialogOption({
          title: 'Infusion: Assessment',
          message: 'Error: nursingProcess questionnaire',
          showDialog: true
        });
      }
    }
  };

  const toggleQuestionSet1 = () => {
    setShowQuestionSet1(!showQuestionSet1);
  };
  const toggleQuestionSet2 = () => {
    setShowQuestionSet2(!showQuestionSet2);
  };
  const toggleQuestionSet3 = () => {
    setShowQuestionSet3(!showQuestionSet3);
  };
  const toggleQuestionSet4 = () => {
    setShowQuestionSet4(!showQuestionSet4);
  };
  const toggleQuestionSet5 = () => {
    setShowQuestionSet5(!showQuestionSet5);
  };
  const toggleQuestionSet6 = () => {
    setShowQuestionSet6(!showQuestionSet6);
  };
  const toggleQuestionSet7 = () => {
    setShowQuestionSet7(!showQuestionSet7);
  };
  const toggleQuestionSet8 = () => {
    setShowQuestionSet8(!showQuestionSet8);
  };
  const toggleQuestionSet9 = () => {
    setShowQuestionSet9(!showQuestionSet9);
  };
  const toggleQuestionSet10 = () => {
    setShowQuestionSet10(!showQuestionSet10);
  };
  const toggleQuestionSet11 = () => {
    setShowQuestionSet11(!showQuestionSet11);
  };
  const toggleQuestionSet12 = () => {
    setShowQuestionSet12(!showQuestionSet12);
  };

  const questionCheck = async (state) => {
    // Step 1: Get a count of all radio inputs in the question set
    const inputs = await document.getElementById('assessmentForm').elements;
    let count = 0;
    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].type === 'radio' && inputs[i].checked) {
        count += 1;
      }
    }
    // Step 2: Set the radio button count and open the set of questions
    switch (state) {
      case 'setQuestions1':
      default:
        setQuestions1(count);
        toggleQuestionSet1();
        break;
      case 'setQuestions2':
        setQuestions2(count);
        toggleQuestionSet2();
        break;
      case 'setQuestions3':
        setQuestions3(count);
        toggleQuestionSet3();
        break;
      case 'setQuestions4':
        setQuestions4(count);
        toggleQuestionSet4();
        break;
      case 'setQuestions5':
        setQuestions5(count);
        toggleQuestionSet5();
        break;
      case 'setQuestions6':
        setQuestions6(count);
        toggleQuestionSet6();
        break;
      case 'setQuestions7':
        setQuestions7(count);
        toggleQuestionSet7();
        break;
      case 'setQuestions8':
        setQuestions8(count);
        toggleQuestionSet8();
        break;
      case 'setQuestions9':
        setQuestions9(count);
        toggleQuestionSet9();
        break;
      case 'setQuestions10':
        setQuestions10(count);
        toggleQuestionSet10();
        break;
      case 'setQuestions11':
        setQuestions11(count);
        toggleQuestionSet11();
        break;
      case 'setQuestions12':
        setQuestions12(count);
        toggleQuestionSet12();
        break;
    }
  };

  const renderAssessment = () => {
    return (
      <>
        <div className='row col-md-11 mt-5'>
          <div className='col-md-3'>
            <Card
              style={{
                border: '1px solid #005282',
                textAlign: 'center',
                margin: '5px',
                alignItems: 'center'
              }}
            >
              <CardBody>
                <CardTitle>CONSTITUTIONAL</CardTitle>
              </CardBody>
              <CardActions>
                <span
                  className='k-button k-flat k-primary'
                  onClick={toggleQuestionSet1}
                >
                  {`Questions: (${question1Count} of 3)`}
                </span>
              </CardActions>
            </Card>
          </div>
          <WindowDialog
            title='CONSTITUTIONAL'
            width={850}
            height={350}
            initialTop={0}
            showDialog={showQuestionSet1}
            onClose={toggleQuestionSet1}
          >
            <div className='row col-md-12 mb-2'>
              <div className='col-md-3'>
                <big>Unexplained Fatigue:</big>
              </div>
              <div className='col-md-3'>
                <Field
                  component={RadioGroup}
                  name='question1_1'
                  data={booleanChoices}
                  layout='horizontal'
                />
              </div>
              <div className='col-md-6'>
                <Field
                  component={InputField}
                  name='question1_2'
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className='row col-md-12 mb-2'>
              <div className='col-md-3'>
                <big>Fever, Chills, Sweats:</big>
              </div>
              <div className='col-md-3'>
                <Field
                  component={RadioGroup}
                  name='question1_3'
                  data={booleanChoices}
                  layout='horizontal'
                />
              </div>
              <div className='col-md-6'>
                <Field
                  component={InputField}
                  name='question1_4'
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className='row col-md-12 mb-2'>
              <div className='col-md-3'>
                <big>Other:</big>
              </div>
              <div className='col-md-3'>
                <Field
                  component={RadioGroup}
                  name='question1_5'
                  data={booleanChoices}
                  layout='horizontal'
                />
              </div>
              <div className='col-md-6'>
                <Field
                  component={InputField}
                  name='question1_6'
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className='row p-3'>
              <div className='col-12' style={{ textAlign: 'center' }}>
                <button
                  type='submit'
                  onClick={() => questionCheck('setQuestions1')}
                  className='k-button pageButton'
                >
                  Update
                </button>
              </div>
            </div>
          </WindowDialog>
          <div className='col-md-3'>
            <Card
              style={{
                border: '1px solid #005282',
                textAlign: 'center',
                margin: '5px',
                alignItems: 'center'
              }}
            >
              <CardBody>
                <CardTitle>CARDIOVASCULAR</CardTitle>
              </CardBody>
              <CardActions>
                <span
                  className='k-button k-flat k-primary'
                  onClick={toggleQuestionSet2}
                >
                  {`Questions: (${question2Count} of 4)`}
                </span>
              </CardActions>
            </Card>
          </div>
          <WindowDialog
            title='CARDIOVASCULAR'
            width={850}
            height={350}
            initialTop={0}
            showDialog={showQuestionSet2}
            onClose={toggleQuestionSet2}
          >
            <div className='row col-md-12 mb-2'>
              <div className='col-md-3'>
                <big>High blood pressure:</big>
              </div>
              <div className='col-md-3'>
                <Field
                  component={RadioGroup}
                  name='question2_1'
                  data={booleanChoices}
                  layout='horizontal'
                />
              </div>
              <div className='col-md-6'>
                <Field
                  component={InputField}
                  name='question2_2'
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className='row col-md-12 mb-2'>
              <div className='col-md-3'>
                <big>Chest pain over heart:</big>
              </div>
              <div className='col-md-3'>
                <Field
                  component={RadioGroup}
                  name='question2_3'
                  data={booleanChoices}
                  layout='horizontal'
                />
              </div>
              <div className='col-md-6'>
                <Field
                  component={InputField}
                  name='question2_4'
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className='row col-md-12 mb-2'>
              <div className='col-md-3'>
                <big>Previous cardiac issues:</big>
              </div>
              <div className='col-md-3'>
                <Field
                  component={RadioGroup}
                  name='question2_5'
                  data={booleanChoices}
                  layout='horizontal'
                />
              </div>
              <div className='col-md-6'>
                <Field
                  component={InputField}
                  name='question2_6'
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className='row col-md-12 mb-2'>
              <div className='col-md-3'>
                <big>Other:</big>
              </div>
              <div className='col-md-3'>
                <Field
                  component={RadioGroup}
                  name='question2_7'
                  data={booleanChoices}
                  layout='horizontal'
                />
              </div>
              <div className='col-md-6'>
                <Field
                  component={InputField}
                  name='question2_8'
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className='row p-3'>
              <div className='col-12' style={{ textAlign: 'center' }}>
                <button
                  type='submit'
                  onClick={() => questionCheck('setQuestions2')}
                  className='k-button pageButton'
                >
                  Update
                </button>
              </div>
            </div>
          </WindowDialog>
          <div className='col-md-3'>
            <Card
              style={{
                border: '1px solid #005282',
                textAlign: 'center',
                margin: '5px',
                alignItems: 'center'
              }}
            >
              <CardBody>
                <CardTitle>NEUROLOGICAL</CardTitle>
              </CardBody>
              <CardActions>
                <span
                  className='k-button k-flat k-primary'
                  onClick={toggleQuestionSet3}
                >
                  {`Questions: (${question3Count} of 6)`}
                </span>
              </CardActions>
            </Card>
          </div>
          <WindowDialog
            title='NEUROLOGICAL'
            width={850}
            height={400}
            initialTop={0}
            showDialog={showQuestionSet3}
            onClose={toggleQuestionSet3}
          >
            <div className='row col-md-12 mb-2'>
              <div className='col-md-3'>
                <big>Headaches:</big>
              </div>
              <div className='col-md-3'>
                <Field
                  component={RadioGroup}
                  name='question3_1'
                  data={booleanChoices}
                  layout='horizontal'
                />
              </div>
              <div className='col-md-6'>
                <Field
                  component={InputField}
                  name='question3_2'
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className='row col-md-12 mb-2'>
              <div className='col-md-3'>
                <big>Double Vision:</big>
              </div>
              <div className='col-md-3'>
                <Field
                  component={RadioGroup}
                  name='question3_3'
                  data={booleanChoices}
                  layout='horizontal'
                />
              </div>
              <div className='col-md-6'>
                <Field
                  component={InputField}
                  name='question3_4'
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className='row col-md-12 mb-2'>
              <div className='col-md-3'>
                <big>Seizure:</big>
              </div>
              <div className='col-md-3'>
                <Field
                  component={RadioGroup}
                  name='question3_5'
                  data={booleanChoices}
                  layout='horizontal'
                />
              </div>
              <div className='col-md-6'>
                <Field
                  component={InputField}
                  name='question3_6'
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className='row col-md-12 mb-2'>
              <div className='col-md-3'>
                <big>Numbness, Tingling, Tremors:</big>
              </div>
              <div className='col-md-3'>
                <Field
                  component={RadioGroup}
                  name='question3_7'
                  data={booleanChoices}
                  layout='horizontal'
                />
              </div>
              <div className='col-md-6'>
                <Field
                  component={InputField}
                  name='question3_8'
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className='row col-md-12 mb-2'>
              <div className='col-md-3'>
                <big>Memory loss:</big>
              </div>
              <div className='col-md-3'>
                <Field
                  component={RadioGroup}
                  name='question3_9'
                  data={booleanChoices}
                  layout='horizontal'
                />
              </div>
              <div className='col-md-6'>
                <Field
                  component={InputField}
                  name='question3_10'
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className='row col-md-12 mb-2'>
              <div className='col-md-3'>
                <big>Other:</big>
              </div>
              <div className='col-md-3'>
                <Field
                  component={RadioGroup}
                  name='question3_11'
                  data={booleanChoices}
                  layout='horizontal'
                />
              </div>
              <div className='col-md-6'>
                <Field
                  component={InputField}
                  name='question3_12'
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className='row p-3'>
              <div className='col-12' style={{ textAlign: 'center' }}>
                <button
                  type='submit'
                  onClick={() => questionCheck('setQuestions3')}
                  className='k-button pageButton'
                >
                  Update
                </button>
              </div>
            </div>
          </WindowDialog>
          <div className='col-md-3'>
            <Card
              style={{
                border: '1px solid #005282',
                textAlign: 'center',
                margin: '5px',
                alignItems: 'center'
              }}
            >
              <CardBody>
                <CardTitle>SKIN</CardTitle>
              </CardBody>
              <CardActions>
                <span
                  className='k-button k-flat k-primary'
                  onClick={toggleQuestionSet4}
                >
                  {`Questions: (${question4Count} of 2)`}
                </span>
              </CardActions>
            </Card>
          </div>
          <WindowDialog
            title='SKIN'
            width={850}
            height={250}
            initialTop={0}
            showDialog={showQuestionSet4}
            onClose={toggleQuestionSet4}
          >
            <div className='row col-md-12 mb-2'>
              <div className='col-md-3'>
                <big>Wounds:</big>
              </div>
              <div className='col-md-3'>
                <Field
                  component={RadioGroup}
                  name='question4_1'
                  data={booleanChoices}
                  layout='horizontal'
                />
              </div>
              <div className='col-md-6'>
                <Field
                  component={InputField}
                  name='question4_2'
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className='row col-md-12 mb-2'>
              <div className='col-md-3'>
                <big>Other:</big>
              </div>
              <div className='col-md-3'>
                <Field
                  component={RadioGroup}
                  name='question4_3'
                  data={booleanChoices}
                  layout='horizontal'
                />
              </div>
              <div className='col-md-6'>
                <Field
                  component={InputField}
                  name='question4_4'
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className='row p-3'>
              <div className='col-12' style={{ textAlign: 'center' }}>
                <button
                  type='submit'
                  onClick={() => questionCheck('setQuestions4')}
                  className='k-button pageButton'
                >
                  Update
                </button>
              </div>
            </div>
          </WindowDialog>
        </div>
        <div className='row col-md-11'>
          <div className='col-md-3'>
            <Card
              style={{
                border: '1px solid #005282',
                textAlign: 'center',
                margin: '5px',
                alignItems: 'center'
              }}
            >
              <CardBody>
                <CardTitle>EYES/EARS/NOSE/THROAT</CardTitle>
              </CardBody>
              <CardActions>
                <span
                  className='k-button k-flat k-primary'
                  onClick={toggleQuestionSet5}
                >
                  {`Questions: (${question5Count} of 4)`}
                </span>
              </CardActions>
            </Card>
          </div>
          <WindowDialog
            title='EYES/EARS/NOSE/THROAT'
            width={850}
            height={350}
            initialTop={0}
            showDialog={showQuestionSet5}
            onClose={toggleQuestionSet5}
          >
            <div className='row col-md-12 mb-2'>
              <div className='col-md-3'>
                <big>Dizziness:</big>
              </div>
              <div className='col-md-3'>
                <Field
                  component={RadioGroup}
                  name='question5_1'
                  data={booleanChoices}
                  layout='horizontal'
                />
              </div>
              <div className='col-md-6'>
                <Field
                  component={InputField}
                  name='question5_2'
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className='row col-md-12 mb-2'>
              <div className='col-md-3'>
                <big>Sore Throat:</big>
              </div>
              <div className='col-md-3'>
                <Field
                  component={RadioGroup}
                  name='question5_3'
                  data={booleanChoices}
                  layout='horizontal'
                />
              </div>
              <div className='col-md-6'>
                <Field
                  component={InputField}
                  name='question5_4'
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className='row col-md-12 mb-2'>
              <div className='col-md-3'>
                <big>Vision Problems:</big>
              </div>
              <div className='col-md-3'>
                <Field
                  component={RadioGroup}
                  name='question5_5'
                  data={booleanChoices}
                  layout='horizontal'
                />
              </div>
              <div className='col-md-6'>
                <Field
                  component={InputField}
                  name='question5_6'
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className='row col-md-12 mb-2'>
              <div className='col-md-3'>
                <big>Other:</big>
              </div>
              <div className='col-md-3'>
                <Field
                  component={RadioGroup}
                  name='question5_7'
                  data={booleanChoices}
                  layout='horizontal'
                />
              </div>
              <div className='col-md-6'>
                <Field
                  component={InputField}
                  name='question5_8'
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className='row p-3'>
              <div className='col-12' style={{ textAlign: 'center' }}>
                <button
                  type='submit'
                  onClick={() => questionCheck('setQuestions5')}
                  className='k-button pageButton'
                >
                  Update
                </button>
              </div>
            </div>
          </WindowDialog>
          <div className='col-md-3'>
            <Card
              style={{
                border: '1px solid #005282',
                textAlign: 'center',
                margin: '5px',
                alignItems: 'center'
              }}
            >
              <CardBody>
                <CardTitle>RESPIRATORY</CardTitle>
              </CardBody>
              <CardActions>
                <span
                  className='k-button k-flat k-primary'
                  onClick={toggleQuestionSet6}
                >
                  {`Questions: (${question6Count} of 5)`}
                </span>
              </CardActions>
            </Card>
          </div>
          <WindowDialog
            title='RESPIRATORY'
            width={850}
            height={350}
            initialTop={0}
            showDialog={showQuestionSet6}
            onClose={toggleQuestionSet6}
          >
            <div className='row col-md-12 mb-2'>
              <div className='col-md-3'>
                <big>Cough:</big>
              </div>
              <div className='col-md-3'>
                <Field
                  component={RadioGroup}
                  name='question6_1'
                  data={booleanChoices}
                  layout='horizontal'
                />
              </div>
              <div className='col-md-6'>
                <Field
                  component={InputField}
                  name='question6_2'
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className='row col-md-12 mb-2'>
              <div className='col-md-3'>
                <big>Shortness of breath:</big>
              </div>
              <div className='col-md-3'>
                <Field
                  component={RadioGroup}
                  name='question6_3'
                  data={booleanChoices}
                  layout='horizontal'
                />
              </div>
              <div className='col-md-6'>
                <Field
                  component={InputField}
                  name='question6_4'
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className='row col-md-12 mb-2'>
              <div className='col-md-3'>
                <big>Wheezing:</big>
              </div>
              <div className='col-md-3'>
                <Field
                  component={RadioGroup}
                  name='question6_5'
                  data={booleanChoices}
                  layout='horizontal'
                />
              </div>
              <div className='col-md-6'>
                <Field
                  component={InputField}
                  name='question6_6'
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className='row col-md-12 mb-2'>
              <div className='col-md-3'>
                <big>Chest pain, lungs:</big>
              </div>
              <div className='col-md-3'>
                <Field
                  component={RadioGroup}
                  name='question6_7'
                  data={booleanChoices}
                  layout='horizontal'
                />
              </div>
              <div className='col-md-6'>
                <Field
                  component={InputField}
                  name='question6_8'
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className='row col-md-12 mb-2'>
              <div className='col-md-3'>
                <big>Other:</big>
              </div>
              <div className='col-md-3'>
                <Field
                  component={RadioGroup}
                  name='question6_9'
                  data={booleanChoices}
                  layout='horizontal'
                />
              </div>
              <div className='col-md-6'>
                <Field
                  component={InputField}
                  name='question6_10'
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className='row p-3'>
              <div className='col-12' style={{ textAlign: 'center' }}>
                <button
                  type='submit'
                  onClick={() => questionCheck('setQuestions6')}
                  className='k-button pageButton'
                >
                  Update
                </button>
              </div>
            </div>
          </WindowDialog>
          <div className='col-md-3'>
            <Card
              style={{
                border: '1px solid #005282',
                textAlign: 'center',
                margin: '5px',
                alignItems: 'center'
              }}
            >
              <CardBody>
                <CardTitle>GENITAL/URINARY</CardTitle>
              </CardBody>
              <CardActions>
                <span
                  className='k-button k-flat k-primary'
                  onClick={toggleQuestionSet7}
                >
                  {`Questions: (${question7Count} of 4)`}
                </span>
              </CardActions>
            </Card>
          </div>
          <WindowDialog
            title='GENITAL/URINARY'
            width={850}
            height={350}
            initialTop={0}
            showDialog={showQuestionSet7}
            onClose={toggleQuestionSet7}
          >
            <div className='row col-md-12 mb-2'>
              <div className='col-md-3'>
                <big>Pain with urination:</big>
              </div>
              <div className='col-md-3'>
                <Field
                  component={RadioGroup}
                  name='question7_1'
                  data={booleanChoices}
                  layout='horizontal'
                />
              </div>
              <div className='col-md-6'>
                <Field
                  component={InputField}
                  name='question7_2'
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className='row col-md-12 mb-2'>
              <div className='col-md-3'>
                <big>Problems with frequency:</big>
              </div>
              <div className='col-md-3'>
                <Field
                  component={RadioGroup}
                  name='question7_3'
                  data={booleanChoices}
                  layout='horizontal'
                />
              </div>
              <div className='col-md-6'>
                <Field
                  component={InputField}
                  name='question7_4'
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className='row col-md-12 mb-2'>
              <div className='col-md-3'>
                <big>Catheter in use:</big>
              </div>
              <div className='col-md-3'>
                <Field
                  component={RadioGroup}
                  name='question7_5'
                  data={booleanChoices}
                  layout='horizontal'
                />
              </div>
              <div className='col-md-6'>
                <Field
                  component={InputField}
                  name='question7_6'
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className='row col-md-12 mb-2'>
              <div className='col-md-3'>
                <big>Other:</big>
              </div>
              <div className='col-md-3'>
                <Field
                  component={RadioGroup}
                  name='question7_7'
                  data={booleanChoices}
                  layout='horizontal'
                />
              </div>
              <div className='col-md-6'>
                <Field
                  component={InputField}
                  name='question7_8'
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className='row p-3'>
              <div className='col-12' style={{ textAlign: 'center' }}>
                <button
                  type='submit'
                  onClick={() => questionCheck('setQuestions7')}
                  className='k-button pageButton'
                >
                  Update
                </button>
              </div>
            </div>
          </WindowDialog>
          <div className='col-md-3'>
            <Card
              style={{
                border: '1px solid #005282',
                textAlign: 'center',
                margin: '5px',
                alignItems: 'center'
              }}
            >
              <CardBody>
                <CardTitle>MUSCULOSKELETAL</CardTitle>
              </CardBody>
              <CardActions>
                <span
                  className='k-button k-flat k-primary'
                  onClick={toggleQuestionSet8}
                >
                  {`Questions: (${question8Count} of 3)`}
                </span>
              </CardActions>
            </Card>
          </div>
          <WindowDialog
            title='MUSCULOSKELETAL'
            width={850}
            height={350}
            initialTop={0}
            showDialog={showQuestionSet8}
            onClose={toggleQuestionSet8}
          >
            <div className='row col-md-12 mb-2'>
              <div className='col-md-3'>
                <big>Joint Pain:</big>
              </div>
              <div className='col-md-3'>
                <Field
                  component={RadioGroup}
                  name='question8_1'
                  data={booleanChoices}
                  layout='horizontal'
                />
              </div>
              <div className='col-md-6'>
                <Field
                  component={InputField}
                  name='question8_2'
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className='row col-md-12 mb-2'>
              <div className='col-md-3'>
                <big>Muscle weakness:</big>
              </div>
              <div className='col-md-3'>
                <Field
                  component={RadioGroup}
                  name='question8_3'
                  data={booleanChoices}
                  layout='horizontal'
                />
              </div>
              <div className='col-md-6'>
                <Field
                  component={InputField}
                  name='question8_4'
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className='row col-md-12 mb-2'>
              <div className='col-md-3'>
                <big>Other:</big>
              </div>
              <div className='col-md-3'>
                <Field
                  component={RadioGroup}
                  name='question8_5'
                  data={booleanChoices}
                  layout='horizontal'
                />
              </div>
              <div className='col-md-6'>
                <Field
                  component={InputField}
                  name='question8_6'
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className='row p-3'>
              <div className='col-12' style={{ textAlign: 'center' }}>
                <button
                  type='submit'
                  onClick={() => questionCheck('setQuestions8')}
                  className='k-button pageButton'
                >
                  Update
                </button>
              </div>
            </div>
          </WindowDialog>
        </div>
        <div className='row col-md-11'>
          <div className='col-md-3'>
            <Card
              style={{
                border: '1px solid #005282',
                textAlign: 'center',
                margin: '5px',
                alignItems: 'center'
              }}
            >
              <CardBody>
                <CardTitle>GASTROINTESTINAL</CardTitle>
              </CardBody>
              <CardActions>
                <span
                  className='k-button k-flat k-primary'
                  onClick={toggleQuestionSet9}
                >
                  {`Questions: (${question9Count} of 4)`}
                </span>
              </CardActions>
            </Card>
          </div>
          <WindowDialog
            title='GASTROINTESTINAL'
            width={850}
            height={350}
            initialTop={0}
            showDialog={showQuestionSet9}
            onClose={toggleQuestionSet9}
          >
            <div className='row col-md-12 mb-2'>
              <div className='col-md-3'>
                <big>Nausea or vomiting:</big>
              </div>
              <div className='col-md-3'>
                <Field
                  component={RadioGroup}
                  name='question9_1'
                  data={booleanChoices}
                  layout='horizontal'
                />
              </div>
              <div className='col-md-6'>
                <Field
                  component={InputField}
                  name='question9_2'
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className='row col-md-12 mb-2'>
              <div className='col-md-3'>
                <big>Diarrhea:</big>
              </div>
              <div className='col-md-3'>
                <Field
                  component={RadioGroup}
                  name='question9_3'
                  data={booleanChoices}
                  layout='horizontal'
                />
              </div>
              <div className='col-md-6'>
                <Field
                  component={InputField}
                  name='question9_4'
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className='row col-md-12 mb-2'>
              <div className='col-md-3'>
                <big>Constipation:</big>
              </div>
              <div className='col-md-3'>
                <Field
                  component={RadioGroup}
                  name='question9_5'
                  data={booleanChoices}
                  layout='horizontal'
                />
              </div>
              <div className='col-md-6'>
                <Field
                  component={InputField}
                  name='question9_6'
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className='row col-md-12 mb-2'>
              <div className='col-md-3'>
                <big>Other:</big>
              </div>
              <div className='col-md-3'>
                <Field
                  component={RadioGroup}
                  name='question9_7'
                  data={booleanChoices}
                  layout='horizontal'
                />
              </div>
              <div className='col-md-6'>
                <Field
                  component={InputField}
                  name='question9_8'
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className='row p-3'>
              <div className='col-12' style={{ textAlign: 'center' }}>
                <button
                  type='submit'
                  onClick={() => questionCheck('setQuestions9')}
                  className='k-button pageButton'
                >
                  Update
                </button>
              </div>
            </div>
          </WindowDialog>
          <div className='col-md-3'>
            <Card
              style={{
                border: '1px solid #005282',
                textAlign: 'center',
                margin: '5px',
                alignItems: 'center'
              }}
            >
              <CardBody>
                <CardTitle>PSYCHOLOGICAL</CardTitle>
              </CardBody>
              <CardActions>
                <span
                  className='k-button k-flat k-primary'
                  onClick={toggleQuestionSet10}
                >
                  {`Questions: (${question10Count} of 4)`}
                </span>
              </CardActions>
            </Card>
          </div>
          <WindowDialog
            title='PSYCHOLOGICAL'
            width={850}
            height={350}
            initialTop={0}
            showDialog={showQuestionSet10}
            onClose={toggleQuestionSet10}
          >
            <div className='row col-md-12 mb-2'>
              <div className='col-md-3'>
                <big>Depression or Anxiety:</big>
              </div>
              <div className='col-md-3'>
                <Field
                  component={RadioGroup}
                  name='question10_1'
                  data={booleanChoices}
                  layout='horizontal'
                />
              </div>
              <div className='col-md-6'>
                <Field
                  component={InputField}
                  name='question10_2'
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className='row col-md-12 mb-2'>
              <div className='col-md-3'>
                <big>Insomnia:</big>
              </div>
              <div className='col-md-3'>
                <Field
                  component={RadioGroup}
                  name='question10_3'
                  data={booleanChoices}
                  layout='horizontal'
                />
              </div>
              <div className='col-md-6'>
                <Field
                  component={InputField}
                  name='question10_4'
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className='row col-md-12 mb-2'>
              <div className='col-md-3'>
                <big>Poor appetite:</big>
              </div>
              <div className='col-md-3'>
                <Field
                  component={RadioGroup}
                  name='question10_5'
                  data={booleanChoices}
                  layout='horizontal'
                />
              </div>
              <div className='col-md-6'>
                <Field
                  component={InputField}
                  name='question10_6'
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className='row col-md-12 mb-2'>
              <div className='col-md-3'>
                <big>Other:</big>
              </div>
              <div className='col-md-3'>
                <Field
                  component={RadioGroup}
                  name='question10_7'
                  data={booleanChoices}
                  layout='horizontal'
                />
              </div>
              <div className='col-md-6'>
                <Field
                  component={InputField}
                  name='question10_8'
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className='row p-3'>
              <div className='col-12' style={{ textAlign: 'center' }}>
                <button
                  type='submit'
                  onClick={() => questionCheck('setQuestions10')}
                  className='k-button pageButton'
                >
                  Update
                </button>
              </div>
            </div>
          </WindowDialog>
          <div className='col-md-3'>
            <Card
              style={{
                border: '1px solid #005282',
                textAlign: 'center',
                margin: '5px',
                alignItems: 'center'
              }}
            >
              <CardBody>
                <CardTitle>FEMALE</CardTitle>
              </CardBody>
              <CardActions>
                <span
                  className='k-button k-flat k-primary'
                  onClick={toggleQuestionSet11}
                >
                  {`Questions: (${question11Count} of 4)`}
                </span>
              </CardActions>
            </Card>
          </div>
          <WindowDialog
            title='FEMALE'
            width={850}
            height={350}
            initialTop={0}
            showDialog={showQuestionSet11}
            onClose={toggleQuestionSet11}
          >
            <div className='row col-md-12 mb-2'>
              <div className='col-md-3'>
                <big>Currently pregnant:</big>
              </div>
              <div className='col-md-3'>
                <Field
                  component={RadioGroup}
                  name='question11_1'
                  data={booleanChoices}
                  layout='horizontal'
                />
              </div>
              <div className='col-md-6'>
                <Field
                  component={InputField}
                  name='question11_2'
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className='row col-md-12 mb-2'>
              <div className='col-md-3'>
                <big>Menopause:</big>
              </div>
              <div className='col-md-3'>
                <Field
                  component={RadioGroup}
                  name='question11_3'
                  data={booleanChoices}
                  layout='horizontal'
                />
              </div>
              <div className='col-md-6'>
                <Field
                  component={InputField}
                  name='question11_4'
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className='row col-md-12 mb-2'>
              <div className='col-md-3'>
                <big>Hysterectomy:</big>
              </div>
              <div className='col-md-3'>
                <Field
                  component={RadioGroup}
                  name='question11_5'
                  data={booleanChoices}
                  layout='horizontal'
                />
              </div>
              <div className='col-md-6'>
                <Field
                  component={InputField}
                  name='question11_6'
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className='row col-md-12 mb-2'>
              <div className='col-md-3'>
                <big>Other:</big>
              </div>
              <div className='col-md-3'>
                <Field
                  component={RadioGroup}
                  name='question11_7'
                  data={booleanChoices}
                  layout='horizontal'
                />
              </div>
              <div className='col-md-6'>
                <Field
                  component={InputField}
                  name='question11_8'
                  style={{ width: 375 }}
                />
              </div>
            </div>
            <div className='row p-3'>
              <div className='col-12' style={{ textAlign: 'center' }}>
                <button
                  type='submit'
                  onClick={() => questionCheck('setQuestions11')}
                  className='k-button pageButton'
                >
                  Update
                </button>
              </div>
            </div>
          </WindowDialog>
          <div className='col-md-3'>
            <Card
              style={{
                border: '1px solid #005282',
                textAlign: 'center',
                margin: '5px',
                alignItems: 'center'
              }}
            >
              <CardBody>
                <CardTitle>TYSABRI</CardTitle>
              </CardBody>
              <CardActions>
                <span
                  className='k-button k-flat k-primary'
                  onClick={toggleQuestionSet12}
                >
                  {`Questions: (${question12Count} of 2)`}
                </span>
              </CardActions>
            </Card>
          </div>
          <WindowDialog
            title='TYSABRI'
            width={850}
            height={350}
            initialTop={0}
            showDialog={showQuestionSet12}
            onClose={toggleQuestionSet12}
          >
            <div className='row col-md-12 mb-3'>
              <div className='col-md-12'>
                <big>
                  The Tysabri TOUCH pre-Infusion checklist has been completed
                  with the patient?
                </big>
              </div>
            </div>
            <div className='row col-md-12 mb-3'>
              <div className='col-md-3'>
                <Field
                  component={RadioGroup}
                  name='question12_1'
                  data={booleanChoices}
                  layout='horizontal'
                />
              </div>
              <div className='col-md-9'>
                <Field
                  component={InputField}
                  name='question12_2'
                  style={{ width: 550 }}
                />
              </div>
            </div>
            <div className='row col-md-12 mb-3'>
              <div className='col-md-12'>
                <big>
                  The TOUCH Biogen authorization checklist has been verified and
                  confirmed for this patient and treatment date?
                </big>
              </div>
            </div>
            <div className='row col-md-12 mb-2'>
              <div className='col-md-3'>
                <Field
                  component={RadioGroup}
                  name='question12_3'
                  data={booleanChoices}
                  layout='horizontal'
                />
              </div>
              <div className='col-md-9'>
                <Field
                  component={InputField}
                  name='question12_4'
                  style={{ width: 550 }}
                />
              </div>
            </div>
            <div className='row p-3'>
              <div className='col-12' style={{ textAlign: 'center' }}>
                <button
                  type='submit'
                  onClick={() => questionCheck('setQuestions12')}
                  className='k-button pageButton'
                >
                  Update
                </button>
              </div>
            </div>
          </WindowDialog>
        </div>
      </>
    );
  };

  const handleAddWeight = (dataItem) => {
    // do not allow to add more than 1 record
    if (itemWeights.length && itemWeights.length > 0) {
      setDialogOption({
        title: 'Infusion: Assessment',
        message:
          'Patient weight was already provided. If you want to change that value - please, remove another record and add new one.',
        showDialog: true
      });

      return;
    }

    const newWeight = parseFloat(dataItem.weight);
    const newWeightKG = lb2kg(newWeight);
    const previousWeight =
      patientBucket.patientProfile.patientInfo.patientWeightLB;
    const changeInWeight = Math.round(newWeight - previousWeight);

    const apiWeight = {
      weight: newWeight,
      entered: formatDateToAWS(),
      unitOfWeight: 'LB'
    };

    const itemWeight = {
      patientWeightLB: newWeight,
      calcPatientWeightKG: newWeightKG,
      lastKnownWeightLB: previousWeight,
      calcChangeFromLastKnown: changeInWeight,
      origOrderWeightLB: previousWeight,
      dateEntered: getNow()
    };

    try {
      // setApiWeights([...apiWeights, apiWeight]);
      setApiWeights([apiWeight]);
      // setItemWeights([...itemWeights, itemWeight]);
      setItemWeights([itemWeight]);
    } catch (err) {
      console.error('AssessmentV2::handleAddWeight err: ', err);
      setDialogOption({
        title: 'Infusion: Assessment',
        message: 'Error: handleAddWeight',
        showDialog: true
      });
    }
  };

  const handleAddVitals = (dataItem) => {
    if (validateTimeRange(dataItem.time)) {
      return;
    }
    const apiVital = {
      recordNumber: 1,
      enteredAt: moment(new Date(dataItem.time)),
      temperature: dataItem.temp === '' ? null : dataItem.temp,
      bloodPressure: dataItem.bp,
      heartRate: dataItem.hr === '' ? null : dataItem.hr,
      R: dataItem.r === '' ? null : dataItem.r,
      SP02: dataItem.spo2 === '' ? null : dataItem.spo2,
      initials: dataItem.initials
    };

    const itemVital = {
      temp: dataItem.temp === '' ? null : dataItem.temp,
      bp: dataItem.bp,
      hr: dataItem.hr === '' ? null : dataItem.hr,
      r: dataItem.r === '' ? null : dataItem.r,
      spo2: dataItem.spo2 === '' ? null : dataItem.spo2,
      initials: dataItem.initials,
      time: moment(new Date(dataItem.time)).format('hh:mm A'),
      id: itemVitals.length
    };

    try {
      setApiVitals([...apiVitals, apiVital]);
      setItemVitals([...itemVitals, itemVital]);
    } catch (err) {
      console.error('AssessmentV2::handleAddVitals err: ', err);
      setDialogOption({
        title: 'Infusion: Assessment',
        message: 'Error: handleAddVitals',
        showDialog: true
      });
    }
  };

  const handleSubmit = (dataItem) => {
    let questionnaire = JSON.stringify([]);
    if (showAssessments) {
      questionnaire = setQuestionnaire(dataItem.values);
    }
    let isError = false;
    let errorMessage = Constants.ErrorMessage.allFields_REQUIRED;
    if (apiVitals.length > 0) {
      apiVitals.forEach((item) => {
        if (!item.temperature) {
          item.temperature = 0;
        }
        if (!item.heartRate) {
          item.heartRate = 0;
        }
        if (!item.R) {
          item.R = 0;
        }
        if (!item.SP02) {
          item.SP02 = 0;
        }
        if (validateEmptyFloat(item.temperature)) {
          isError = true;
          errorMessage = Constants.ErrorMessage.temp_Value;
          return false;
        }
        if (validateEmptyFloat(item.heartRate)) {
          isError = true;
          errorMessage = Constants.ErrorMessage.heartrate_Value;
          return false;
        }
        if (validateEmptyFloat(item.SP02)) {
          isError = true;
          errorMessage = Constants.ErrorMessage.spo2_Value;
          return false;
        }
        if (validateEmptyFloat(item.R)) {
          isError = true;
          errorMessage = Constants.ErrorMessage.r_Value;
          return false;
        }
      });
    }

    if (isError) {
      setDialogOption({
        title: 'Vital: Error',
        message: errorMessage,
        showDialog: true
      });
      return;
    }

    const requestObject = {
      // STEP 2
      // input UpdateStepAssessmentInput {
      // updateStepAssessmentInput: {
      // nursingProcessId: ID!
      nursingProcessId,
      // agentId: ID!
      agentId: agent.agentId,
      // patientWeights: [PatientWeightRecordInput]!
      patientWeights: apiWeights,
      // vitals: [VitalRecordInput]!
      vitals: apiVitals, // itemVitals,
      // noAssessmentToday: Boolean
      noAssessmentToday: !dataItem.values?.assessmentsOn,
      // @NOTE: removed as part of updating step, now the notes live alone
      // notes: [String]
      // notes: restoreNotes(),
      // questionnaire: AWSJSON
      questionnaire
    };

    updateStepAssessmentCall(requestObject);
  };

  const handleDeleteClick = (vitalProps, object) => {
    if (vitalProps.dataIndex > -1) {
      if (object === 'weight') {
        // alert(`DELETE ${object}: ${props.dataIndex}`)
        if (vitalProps.dataIndex > -1) {
          const cloneApiWeights = [...apiWeights];
          cloneApiWeights.splice(vitalProps.dataIndex, 1);
          setApiWeights(cloneApiWeights);
          const cloneItemWeights = [...itemWeights];
          cloneItemWeights.splice(vitalProps.dataIndex, 1);
          setItemWeights(cloneItemWeights);
        }
      }
      if (object === 'vitals') {
        // alert(`DELETE ${object}: ${props.dataIndex}`)
        if (vitalProps.dataIndex > -1) {
          const cloneApiVitals = [...apiVitals];
          cloneApiVitals.splice(vitalProps.dataIndex, 1);
          setApiVitals(cloneApiVitals);
          const cloneItemVitals = [...itemVitals];
          cloneItemVitals.splice(vitalProps.dataIndex, 1);
          setItemVitals(cloneItemVitals);
        }
      }
    }
  };

  const customCellDeleteVitals = (vitalProps) => {
    return (
      <td>
        <DeleteButton
          handleClick={() => handleDeleteClick(vitalProps, 'vitals')}
        />
      </td>
    );
  };

  const patientWeightLB = getPatientWeightFromReferralLB(
    currentReferral?.referralOrder
  );

  const validateEmptyFloat = (value) => {
    if (!value || value === '') return '';
    return validateFloat(value) ? '' : Constants.ErrorMessage.Numeric_Required;
  };

  const validateTimeRange = (value) => {
    // field is now required when vitals are entered
    if (!value || value === '') {
      setIsTimeRangeError(true);
      return true;
    }
    setIsTimeRangeError(
      !moment(value).isBetween(getAdminMinTime(), getAdminMaxTime())
    );
    return moment(value).isBetween(getAdminMinTime(), getAdminMaxTime())
      ? ''
      : true;
  };

  const [editField, setEditField] = React.useState(undefined);

  const enterEdit = (dataItem, field) => {
    const newData = itemVitals.map((item) => ({
      ...item,
      inEdit: item.id === dataItem.id ? field : undefined
    }));
    setItemVitals(newData);
    setEditField(field);
  };

  const exitEdit = () => {
    const newData = itemVitals.map((item) => ({ ...item, inEdit: undefined }));
    setItemVitals(newData);
    setEditField(undefined);
  };

  const onVitalItemChange = (event) => {
    const field = event.field || '';
    event.dataItem[field] = event.value;
    const newData = itemVitals.map((item) => {
      if (item.id === event.dataItem.id) {
        item[field] = event.value;
      }
      return item;
    });

    const copyApiVitals = [...apiVitals];
    const apiVitalRow = copyApiVitals[event.dataItem.id];
    let isError = false;
    if (field === 'temp') {
      isError = Number.isNaN(event.value);
      // if(!isError) {
      apiVitalRow.temperature = event.value;
      if (isError) {
        setDialogOption({
          title: 'Vital: Error',
          message: Constants.ErrorMessage.temp_Value,
          showDialog: true
        });
      }
      // }
    } else if (field === 'time' && event.value.split(':').length > 1) {
      const [hours, mins] = event.value.slice(0, -2).split(':');
      const newEnteredAt = moment(apiVitalRow.enteredAt).set({
        hour: event.value.endsWith('AM') ? Number(hours) : Number(hours) + 12,
        minute: Number(mins)
      });
      apiVitalRow.enteredAt = newEnteredAt;
    } else if (field === 'bp') {
      apiVitalRow.bloodPressure = event.value;
    } else if (field === 'hr') {
      isError = Number.isNaN(event.value);
      // if(!isError) {
      apiVitalRow.heartRate = event.value;
      if (isError) {
        setDialogOption({
          title: 'Vital: Error',
          message: Constants.ErrorMessage.heartrate_Value,
          showDialog: true
        });
      }
      // }
    } else if (field === 'r') {
      isError = Number.isNaN(event.value);
      //  if(!isError) {
      apiVitalRow.R = event.value;
      if (isError) {
        setDialogOption({
          title: 'Vital: Error',
          message: Constants.ErrorMessage.r_Value,
          showDialog: true
        });
      }
      // }
    } else if (field === 'spo2') {
      isError = Number.isNaN(event.value);
      // if(!isError) {
      apiVitalRow.SP02 = event.value;
      if (isError) {
        setDialogOption({
          title: 'Vital: Error',
          message: Constants.ErrorMessage.spo2_Value,
          showDialog: true
        });
      }
      //  }
    } else if (field === 'initials') {
      apiVitalRow.initials = event.value;
    }
    setApiVitals([...copyApiVitals]);
    setItemVitals(newData);
  };

  const customCellRender = (td, cellProps) => (
    <CellRender
      originalProps={cellProps}
      td={td}
      enterEdit={enterEdit}
      editField={editField}
    />
  );

  const customRowRender = (tr, rowProps) => (
    <RowRender
      originalProps={rowProps}
      tr={tr}
      exitEdit={exitEdit}
      editField={editField}
    />
  );

  const renderWeightForm = () => {
    if (props.infusionRestricted) {
      const hasBeenWeightEntered = itemWeights.length > 0;

      const renderWeightInfo = () => {
        if (!hasBeenWeightEntered) {
          return (
            <Badge
              text='Patient weight has not been updated during the infusion'
              type='warning'
            />
          );
        }

        const enteredWeight =
          itemWeights[itemWeights.length - 1].patientWeightLB;

        return (
          <>
            Patient Weight:{' '}
            <span style={{ color: 'red' }}>{enteredWeight}</span> lb
          </>
        );
      };

      return (
        <div className='row'>
          <div className='col-md-12'>
            <div>
              <h5>{renderWeightInfo()}</h5>
            </div>
          </div>
        </div>
      );
    }

    if (itemWeights.length === 0) {
      return (
        <>
          <div className='row'>
            <div className='col-md-5'>
              <h5>Current Weight (lb):</h5>
            </div>
            <div className='col-md-4'>
              <Field
                name='weight'
                label=''
                component={InputField}
                placeholder={
                  patientBucket?.patientProfile?.patientInfo?.patientWeightLB
                }
                defaultValue={patientWeightLB}
              />
            </div>
            <div className='col-md-3'>
              <button type='submit' className='k-button blue'>
                ADD
              </button>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12 mt-12 mb-3'>
              <Badge
                text='If patient weight changed since last check — please, provide the updated value here'
                type='warning'
              />
            </div>
          </div>
        </>
      );
    }

    const hasEnteredDrugs =
      nursingProcess.stepPreparation?.drugs &&
      nursingProcess.stepPreparation?.drugs.length > 0;

    return (
      <div className='row'>
        <div className='col-md-12'>
          <div>
            <h5>
              Entered Weight:{' '}
              <span style={{ color: 'red' }}>
                {itemWeights[itemWeights.length - 1].patientWeightLB}
              </span>{' '}
              lb
            </h5>
          </div>
          {!props.infusionCompleted && (
            <span
              className='remove-label'
              onClick={() => {
                if (hasEnteredDrugs) {
                  showWarning(
                    'Remove selected drugs on Prep step in order to update patient weight value'
                  );
                } else {
                  setItemWeights([]);
                  setApiWeights([]);
                }
              }}
            >
              <AwesomeLabel label='Remove' icon='xmark' />
            </span>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className='infusion-page'>
      {dialogOption && dialogOption.showDialog && (
        <MessageDialog dialogOption={dialogOption} />
      )}

      {stepAllowed && (
        <>
          <Form
            onSubmit={handleAddWeight}
            render={(formRenderProps) => (
              <form
                onSubmit={formRenderProps.onSubmit}
                className='k-form pl-3 pr-3 pt-1'
              >
                {/* PATIENT WEIGHT */}

                <div
                  className='infusion-details col-md-11 mt-2 mb-3'
                  style={{ border: '1px solid #afaaaa' }}
                >
                  <div className='row'>
                    <div className='infusion-HeaderRow col-12 ml-0 pl-2 py-2 mr-0'>
                      <div className='row'>
                        <div className='col-md-2 headerText'>
                          PATIENT WEIGHT
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-5 ml-0 mt-16'>
                      {renderWeightForm()}
                    </div>
                    <div className='col-md-7 mt-12 mb-3'>
                      <h5>Patient Weight Log</h5>
                      <PatientWeightLog patientInfo={patientBucket} />
                    </div>
                  </div>
                </div>
              </form>
            )}
          />

          <Form
            onSubmit={handleAddVitals}
            render={(formRenderProps) => (
              <form
                onSubmit={formRenderProps.onSubmit}
                className='k-form pl-3 pr-3 pt-1'
              >
                {/* VITALS */}

                <div
                  className='infusion-details col-md-11 mt-2 mb-3'
                  style={{ border: '1px solid #afaaaa' }}
                >
                  <div className='row'>
                    <div className='infusion-HeaderRow col-12 ml-0 pl-2 py-2 mr-0'>
                      <div className='row'>
                        <div className='col-md-2 headerText'>VITALS</div>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-2 ml-3'>
                      Time:
                      <br />
                      <Field
                        name='time'
                        label=''
                        // validator={validateTimeRange}
                        component={TimePicker}
                      />
                      {isTimeRangeError && (
                        <Error>
                          {Constants.ErrorMessage.Admin_Timer_Range}
                        </Error>
                      )}
                    </div>
                    <div className='col-md-1'>
                      <Field
                        name='temp'
                        label='Temp'
                        style={{ width: 75 }}
                        component={Input}
                        validator={validateEmptyFloat}
                      />
                    </div>
                    <div className='col-md-1'>
                      <Field
                        name='bp'
                        label='BP'
                        style={{ width: 75 }}
                        component={Input}
                      />
                    </div>
                    <div className='col-md-1'>
                      <Field
                        name='hr'
                        label='HR'
                        style={{ width: 75 }}
                        component={Input}
                        validator={validateEmptyFloat}
                      />
                    </div>
                    <div className='col-md-1'>
                      <Field
                        name='r'
                        label='R'
                        style={{ width: 75 }}
                        component={Input}
                        validator={validateEmptyFloat}
                      />
                    </div>
                    <div className='col-md-1'>
                      <Field
                        name='spo2'
                        label='SPO2'
                        style={{ width: 75 }}
                        component={Input}
                        validator={validateEmptyFloat}
                      />
                    </div>
                    <div className='col-md-1'>
                      <Field
                        name='initials'
                        label='INITIALS'
                        style={{ width: 75 }}
                        component={Input}
                      />
                    </div>
                    <div className='col-md-2 mt-12'>
                      <button type='submit' className='k-button blue'>
                        ADD
                      </button>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-md-12 mt-12 mb-2'>
                      <Grid
                        className='infusion-grid'
                        data={itemVitals}
                        editField='inEdit'
                        rowRender={customRowRender}
                        cellRender={customCellRender}
                        onItemChange={onVitalItemChange}
                      >
                        <Column field='time' title='TIME' width='140px' />
                        <Column field='temp' title='TEMP' width='140px' />
                        <Column field='bp' title='BP' width='140px' />
                        <Column field='hr' title='HR' width='140px' />
                        <Column field='r' title='R' width='140px' />
                        <Column field='spo2' title='SPO2' width='140px' />
                        <Column
                          field='initials'
                          title='INITIALS'
                          width='140px'
                        />
                        <Column
                          field='action'
                          title=' '
                          cell={customCellDeleteVitals}
                        />
                      </Grid>
                    </div>
                  </div>
                </div>
              </form>
            )}
          />

          {/** New Allergies and Current Meds - reference, readonly */}

          <div className='k-form pl-3 pr-3 pt-1'>
            <div
              className='infusion-details col-md-11 mt-2 mb-3'
              style={{
                border: '1px solid #afaaaa',
                backgroundColor: '#ffffff'
              }}
            >
              <div className='row'>
                <div className='infusion-SubHeaderRowGrey col-12 ml-0 pl-1 py-2 mr-0'>
                  <div className='row'>
                    <div className='col-md-6 headerText'>ALLERGIES</div>
                    <div className='col-md-5 headerText'>
                      CURRENT MEDICATIONS
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-6 mt-3 mb-3'>
                  <PatientAllergiesLog />
                </div>
                <div className='col-md-6 mt-3 mb-3'>
                  <PatientMedsLog />
                </div>
              </div>
              <hr />
              <div className='row'>
                <div className='col-md-12'>
                  <PatientLogInfo />
                </div>
              </div>
            </div>
          </div>

          <Form
            onSubmitClick={handleSubmit}
            initialValues={theQ}
            render={({ onSubmit }) => (
              <form
                id='assessmentForm'
                onSubmit={onSubmit}
                className='k-form pl-3 pr-3 pt-1'
              >
                {/* ASSESSMENTS SWITCH */}

                <div className='row mt-16'>
                  <div className='col-md-12'>
                    Assessments Today &nbsp;
                    <Field
                      component={Switch}
                      name='assessmentsOn'
                      defaultChecked={showAssessments}
                      onLabel='Yes'
                      offLabel='No'
                      onChange={(event) => setShowAssessments(event.value)}
                    />
                  </div>
                </div>
                {showAssessments && <>{renderAssessment()}</>}

                {/* SUBMIT FORM */}

                <div className='row mt-5 mb-5'>
                  <div className='col-12'>
                    <button type='submit' className='k-button pageButton'>
                      Save
                    </button>
                  </div>
                </div>

                <div style={{ height: '200px' }}>{/* SPACER */}</div>
              </form>
            )}
          />
        </>
      )}
    </div>
  );
};

export default AssessmentV2;
