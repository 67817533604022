import React from "react";
import Badge from "./Badge";

const ExpireBadge = ({ checkdate, includeDIV }) => {
  if (!checkdate) {
    console.error("Badge component must have `checkdate` as a property.");
    return null;
  }

  const renderEscalationLabel = () => {
    let curDate = new Date();
   let compareDate = new Date(
    curDate.getFullYear(),
    curDate.getMonth(),
    curDate.getDate() + 30 
  );
  const labDate = new Date(checkdate)
  labDate.setDate(labDate.getDate() + 1)
  
     if(labDate < curDate){
      return   <Badge text="EXPIRED" />;
    } else if (labDate <= compareDate) {
      return  <Badge text="EXPIRES SOON" type="warning" />;
    }
    return null;
  };
  if (includeDIV)
  {
    return (
      <div>
        {checkdate} &nbsp;
        {renderEscalationLabel()}
      </div>
    );
  }
  else
  {
    return (
      <>
        {checkdate} &nbsp;
        {renderEscalationLabel()}
      </>
    );
  }
};

export default ExpireBadge;