import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Card, CardBody } from '@progress/kendo-react-layout';
import { process } from '@progress/kendo-data-query';
import cx from 'clsx';

// components
import Alert from '@/components/common-components/Alert';
import AwesomeLabel from '@/components/common-components/AwesomeLabel';
import { CreateWorkItemDialog, InboundWorkGrid } from './components';

// graphQL
import { getQueryClient } from '@/provider';
import { acquireWork } from '@/graphql/mutations';

// context
import { ProductContext } from '@/context/ProductContext';
import { NotifContext } from '@/context/NotifContext';
import { UserContext } from '@/context/UserContext';
import { LogContext } from '@/context/LogContext';
import { InboundContext } from '@/context/InboundContext';

// constants
import { WORK_ITEM_TYPES } from './InboundFaxQueue.constants';

// styles
import styles from './InboundFaxQueue.module.scss';

const initialDataState = {};
const initialResultState = { data: [], total: 0 };

const InboundFaxQueue = () => {
  const [currentWorkItemId, setCurrentWorkItemId] = useState(null);
  const [currentWorkItem, setCurrentWorkItem] = useState(null);
  const [dataState, setDataState] = useState(initialDataState);
  const [resultState, setResultState] = useState(initialResultState);
  const [workItemType, setWorkItemType] = useState(WORK_ITEM_TYPES[0]);
  const [pdfPath, setPdfPath] = useState();
  const [showFaxWorkflow, setShowFaxWorkflow] = useState(false);
  const [productList, setProductList] = useState([]);

  const toggleFaxWorkflow = () => setShowFaxWorkflow(!showFaxWorkflow);

  const { products } = useContext(ProductContext);
  const { showWarning } = useContext(NotifContext);
  const { agentId } = useContext(UserContext);
  const { logApiException } = useContext(LogContext);
  const {
    inboundWorkItems,
    fetchInboundWorkItems,
    deleteInboundWorkItem,
    setNeedToBeReleased
  } = useContext(InboundContext);

  const onAcquireWorkItem = async (workItemId) => {
    try {
      const data = await getQueryClient({
        query: acquireWork,
        path: 'acquireWork',
        payload: { input: { agentId, workItemId } }
      });
      if (data?.[0].success) {
        // show the modal
        toggleFaxWorkflow();

        // reset to default, since every item needs to be released
        setNeedToBeReleased(true);

        // remove it from the queue
        deleteInboundWorkItem(workItemId);
      } else {
        showWarning(data?.[0].details);
      }
    } catch (error) {
      console.error('CreateWorkItemDialog::acquireWork error: ', error);
      logApiException(error, {
        view: 'CreateWorkItemDialog',
        endpoint: 'acquireWork',
        id: workItemId,
        agentId
      });
    }
  };

  const onSearchItems = async () => {
    if (workItemType === 'Incoming Fax') {
      await fetchInboundWorkItems();
    }

    setDataState(initialDataState);
  };

  const onCreateWorkItem = (workItem) => {
    setCurrentWorkItemId(workItem.id);
    setCurrentWorkItem(workItem);
    onAcquireWorkItem(workItem.id);
  };

  useEffect(() => {
    if (products) {
      const filtered = products
        .map((item) => ({
          productId: item.productId,
          productOnSelector: item.productName,
          productName: item.productName,
          possibleUOMs: item.possibleUOMs
        }))
        .sort((a, b) => (a.productName > b.productName ? 1 : -1));
      const unique = Array.from(
        new Set(filtered.map((a) => a.productName))
      ).map((productName) => {
        return filtered.find((a) => a.productName === productName);
      });

      setProductList(unique);
    }
  }, [products]);

  useEffect(() => {
    setResultState(process(inboundWorkItems, dataState));
  }, [dataState, inboundWorkItems]);

  return (
    <>
      <Card>
        <CardBody>
          <div className='container-fluid'>
            <div className='row my-3'>
              <div className='col-12 col-lg-12 work-in-progress'>
                <h5>Inbound Fax Queue</h5>
                <Alert type='info'>
                  Select work item type from the list, then click Search
                </Alert>
                <div className={styles.filterContainer}>
                  <div className={styles.filters}>
                    <div>
                      <div>Work Item Type:</div>
                      <DropDownList
                        data={WORK_ITEM_TYPES}
                        value={workItemType}
                        onChange={(e) => setWorkItemType(e.target.value)}
                        style={{
                          width: '300px'
                        }}
                      />
                    </div>
                    <div>
                      <div>&nbsp;</div>
                      <div>
                        <button
                          className={cx(styles.searchBtn, 'k-button')}
                          onClick={onSearchItems}
                        >
                          <AwesomeLabel icon='search' label='Search' />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <InboundWorkGrid
                  resultState={resultState}
                  setPdfPath={setPdfPath}
                  onCreateItem={onCreateWorkItem}
                />
              </div>
            </div>
          </div>
        </CardBody>
      </Card>

      <CreateWorkItemDialog
        showDialog={showFaxWorkflow}
        toggleDialog={toggleFaxWorkflow}
        pdfPath={pdfPath}
        productList={productList}
        currentWorkItemId={currentWorkItemId}
        currentWorkItem={currentWorkItem}
      />
    </>
  );
};

export default withRouter(InboundFaxQueue);
