import StatusContextProvider from '@/context/StatusContext';
import UserContextProvider from '@/context/UserContext';
import NotifContextProvider from '@/context/NotifContext';
import PatientContextProvider from '@/context/PatientContext';
import LocationContextProvider from '@/context/LocationContext';
import SurveyContextProvider from '@/context/SurveyContext';
import ProductContextProvider from '@/context/ProductContext';
import InfusionContextProvider from '@/context/InfusionContext';
import FollowUpContextProvider from '@/context/FollowUpContext';
import WorkItemContextProvider from '@/context/WorkItemContext';
import LogContextProvider from '@/context/LogContext';
import ReferralContextProvider from '@/context/ReferralContext';
import QueueFilterContextProvider from '@/context/QueueFilterContext';
import PayerContextProvider from '@/context/PayerContext';
import ProviderContextProvider from '@/context/ProviderContext';
import PrescriberContextProvider from '@/context/PrescriberContext';
import SalesContextProvider from '@/context/SalesContext';
import InboundContextProvider from '@/context/InboundContext';
import InfoCodeContextProvider from '@/context/InfoCodeContext';
import TreatmentContextProvider from '@/context/TreatmentContext';
import CalendarContextProvider from '@/context/CalendarContext';

export default [
  NotifContextProvider,
  UserContextProvider,
  LogContextProvider,
  StatusContextProvider,
  PatientContextProvider,
  ProviderContextProvider,
  LocationContextProvider,
  SurveyContextProvider,
  InfusionContextProvider,
  ProductContextProvider,
  FollowUpContextProvider,
  WorkItemContextProvider,
  ReferralContextProvider,
  QueueFilterContextProvider,
  PayerContextProvider,
  PrescriberContextProvider,
  SalesContextProvider,
  InboundContextProvider,
  InfoCodeContextProvider,
  TreatmentContextProvider,
  CalendarContextProvider
];
