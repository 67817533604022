export const cleanseString = (input) => {
  let str;

  if (typeof input === 'string') {
    str = input;
  } else if (typeof input === 'number') {
    str = input.toString();
  } else {
    return ''; // Return empty string if input is neither string nor number
  }

  // Trim the string to remove spaces at the start and end
  // Then replace all characters that are not alphanumeric, dashes, or hash symbols with an empty string
  return str.trim().replace(/[^\w-#]/g, ''); // Only allow alphanumeric characters, dashes, and hash symbols
};
