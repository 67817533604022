export const themeColors = [
  'primary',
  'secondary',
  'tertiary',
  'info',
  'success',
  'error',
  'warning',
  'dark',
  'light',
  'inverse'
];
