import React, { createContext, useState } from 'react';
import { PropTypes } from 'prop-types';

import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import {
  Scheduler,
  DayView,
  WeekView,
  MonthView,
  AgendaView
} from '@progress/kendo-react-scheduler';
import { CustomViewSlot } from './custom-view-slot';

// If all timezones are needed, use import '@progress/kendo-date-math/tz/all' which is 600kb
import '@progress/kendo-date-math/tz/all';
// todo: if we know what specific timezones we need, we can just import specific ones to decrease the application bundle size
// import "@progress/kendo-date-math/tz/Etc/UTC";
// import "@progress/kendo-date-math/tz/America/New_York";
// import "@progress/kendo-date-math/tz/America/Chicago";
// import "@progress/kendo-date-math/tz/America/Phoenix";
// import "@progress/kendo-date-math/tz/America/Los_Angeles";

import { customModelFields, displayDate } from './events-utc.js';

import { CustomFooter } from '../Calendar/custom-footer';

export const SchedulerConfigContext = createContext({
  slotDuration: [60, (_) => {}],
  slotDivision: [2, (_) => {}]
});

export const DisplayScheduler = (props) => {
  const {
    locale,
    locationEvents,
    handleDataChange,
    view,
    handleViewChange,
    handleDateChange,
    EventEdit,
    EventItem,
    timezone,
    handleRemoveClick,
    orientation,
    history,
    chairData,
    date
  } = props;
  const [slotDuration, setSlotDuration] = useState(30);
  const [slotDivision, setSlotDivision] = useState(1);
  if (!chairData?.length) {
    return <div>No chair data available for this location</div>;
  }
  return (
    <LocalizationProvider language={locale.language}>
      <IntlProvider locale={locale.locale}>
        <SchedulerConfigContext.Provider
          value={{
            slotDuration: [slotDuration, setSlotDuration],
            slotDivision: [slotDivision, setSlotDivision]
          }}
        >
          <p />
          <Scheduler
            editable={{
              add: false,
              remove: false,
              drag: false,
              resize: false,
              edit: true
            }}
            height={720}
            history={history}
            data={locationEvents}
            view={view}
            viewSlot={CustomViewSlot}
            item={EventItem}
            editItem={EventEdit}
            defaultDate={date}
            timezone={timezone}
            group={{
              resources: ['Chair'],
              orientation
            }}
            resources={[
              {
                name: 'Chair',
                data: chairData,
                field: 'chairId',
                valueField: 'value',
                textField: 'text',
                colorField: 'color'
              }
            ]}
            footer={(footerProps) => <CustomFooter {...footerProps} />}
            modelFields={customModelFields}
            onDataChange={handleDataChange}
            onViewChange={handleViewChange}
            onDateChange={handleDateChange}
            onRemoveClick={handleRemoveClick}
            onRemoveItemChange={handleRemoveClick}
          >
            <DayView slotDivisions={slotDivision} slotDuration={slotDuration} />
            <WeekView
              slotDivisions={slotDivision}
              slotDuration={slotDuration}
            />
            <MonthView />
            <AgendaView />
          </Scheduler>
        </SchedulerConfigContext.Provider>
      </IntlProvider>
    </LocalizationProvider>
  );
};

DisplayScheduler.propTypes = {
  locale: PropTypes.object.isRequired,
  locationEvents: PropTypes.array.isRequired,
  view: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired,
  orientation: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  chairData: PropTypes.array.isRequired,
  EventEdit: PropTypes.func.isRequired,
  EventItem: PropTypes.func.isRequired,
  handleDataChange: PropTypes.func.isRequired,
  handleRemoveClick: PropTypes.func.isRequired,
  handleViewChange: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired
};
