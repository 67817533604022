import React, { useContext } from 'react';
import { SurveyContext } from '@/context/SurveyContext';

import {
  compareScores,
  getScoreRating,
  renderScoreChange
} from '../helpers/helperQOL';
import AwesomeLabel from '@/components/common-components/AwesomeLabel';
import { followUpActionTypes } from '../surveyDictionary';
import { formatDateTimeToDefault } from '@/common/DateHelper';
import { downloadFromBucket } from '@/common/DownloadHelper';

const SurveyFollowup = () => {
  const { surveyFollowup } = useContext(SurveyContext);

  const renderScores = () => {
    const scores = surveyFollowup.score;
    const priorScores = surveyFollowup.priorScore;

    // support for now up to 3 scores - display as columns
    const colSize = 12 / scores.length;

    return scores.map((score, i) => {
      const scoreDiff = compareScores(score, priorScores);
      return (
        <div key={`survey-scores-${i}`} className={`col-${colSize}`}>
          <h5>{score.type} Score:</h5>
          <h3>
            <b>{score.score}</b>
            {renderScoreChange(scoreDiff)}
          </h3>
          <p>
            <i>
              <b>{getScoreRating(score, surveyFollowup)}</b>
            </i>
          </p>
        </div>
      );
    });
  };

  const renderWorkItems = () => {
    if (surveyFollowup.workItems?.length > 0) {
      return surveyFollowup.workItems.map((workItem, i) => {
        const { color, icon } = followUpActionTypes[workItem.type];

        return (
          <div key={`follow-up-workitem-${i}`} style={{ color }}>
            <AwesomeLabel icon={icon} label={workItem.description} />
          </div>
        );
      });
    }

    return null;
  };

  const renderEduLinks = () => {
    if (surveyFollowup.education?.length > 0) {
      return surveyFollowup.education.map((guide, i) => {
        return (
          <div key={`follow-up-edulink-${i}`}>
            <i className="fa-solid fa-book" />
            &nbsp;
            <span
              className="help-link"
              onClick={() =>
                downloadFromBucket(`uploads/${guide.fileName}`, guide.title)
              }
            >
              {guide.title}
            </span>
          </div>
        );
      });
    }

    const { color, icon } = followUpActionTypes.info;

    return (
      <div style={{ color }}>
        <AwesomeLabel
          icon={icon}
          label="No resources available based on survey results"
        />
      </div>
    );
  };

  const renderDateMeta = () => {
    const takenAt = surveyFollowup.collectedAt || formatDateTimeToDefault();
    const takenBy = surveyFollowup.collectedBy || null;

    return (
      <div className="row">
        <div className="col-12">
          <AwesomeLabel label={`Survey taken: ${takenAt}`} icon="calendar" />
        </div>
        {takenBy ? (
          <div className="col-12">
            <AwesomeLabel label={`Agent name: ${takenBy}`} icon="user-nurse" />
          </div>
        ) : null}
      </div>
    );
  };

  const renderScoreComparison = () => {
    if (surveyFollowup.score?.length > 0) {
      return (
        <>
          <div
            className="row"
            style={{
              textAlign: 'center',
              backgroundColor: '#ced4da',
              borderRadius: 5,
              paddingTop: '1rem',
              marginBottom: '1rem'
            }}
          >
            {renderScores()}
          </div>
          {surveyFollowup.priorCollectedAt ? (
            <p className="gray-hint">
              Difference to the score shown against to the prior score from
              survey collected at <b>{surveyFollowup.priorCollectedAt}</b>
            </p>
          ) : null}
        </>
      );
    }

    return null;
  };

  if (surveyFollowup) {
    return (
      <div className="sv-page sv-body__page">
        <div className="row" style={{ paddingBottom: 15 }}>
          <div className="col-6">
            <h1>Survey Results</h1>
          </div>
          <div className="col-6">{renderDateMeta()}</div>
        </div>
        {renderScoreComparison()}
        <div className="row">
          <div className="col-6">
            <h5>Survey Notes</h5>
            {renderWorkItems()}
          </div>
          <div className="col-6">
            <h5>Educational Resources</h5>
            {renderEduLinks()}
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default SurveyFollowup;
