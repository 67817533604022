import React, { useState } from "react";
import { PropTypes } from "prop-types";

import { Button } from "@progress/kendo-react-buttons";

import { SalesRepDialog } from "./SalesRepDialog";

export const AddSalesRepButton = ({ prescriberInfo, salesReps }) => {
  const [showDialog, setShowDialog] = useState(false);
  return (
    <>
      <Button type="button" look="flat" className="k-button" title="Sales" onClick={() => setShowDialog(true)}>
          <i className="fa-solid fa-person fa-xl  icon-blue"></i>&nbsp;&nbsp;Sales
      </Button>
      <SalesRepDialog
        prescriber={prescriberInfo}
        showDialog={showDialog}
        salesReps={salesReps}
        onClose={() => setShowDialog(false)}
      />
    </>
  );
};

AddSalesRepButton.propTypes = {
  prescriberInfo: PropTypes.object,
  salesReps: PropTypes.array,
};
