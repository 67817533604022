import { filterBy } from "@progress/kendo-react-data-tools";
import { mapTree, extendDataItem } from "@progress/kendo-react-common";
import { states } from "@/common/states";

export const processTreeData = (data, state, fields) => {
  const {
    selectField,
    expandField,
    dataItemKey,
    subItemsField,
    itemExpandKey,
  } = fields;
  const { expanded, value, filter } = state;
  const filtering = Boolean(filter && filter.value);
  return mapTree(
    filtering ? filterBy(data, [filter], subItemsField) : data,
    subItemsField,
    (item) => {
      const props = {
        [expandField]: expanded.includes(item[itemExpandKey]),
        [selectField]: value && item[dataItemKey] === value[dataItemKey],
      };
      return filtering
        ? extendDataItem(item, subItemsField, props)
        : {
            ...item,
            ...props,
          };
    }
  );
};

export const groupAICLocationData = (array) => {
  /*
   * main level options gonna be STATES (full title, if available, if not - use AZ, MD, etc)
   * states sorted A-Z
   * second level gonna be location list sorted A-Z
   */

  const statesMap = states.reduce((prev, state) => {
    if (!prev[state.abbreviation]) {
      return {
        ...prev,
        [state.abbreviation]: state.name,
      };
    }
    return prev;
  }, {});

  // group and sort

  const sortedDataMap = array.reduce((prev, aic) => {
    if (!statesMap[aic.state]) return prev;

    if (!prev[aic.state]) {
      // add new state
      prev[aic.state] = {
        locationName: `${statesMap[aic.state]} (${aic.state.toUpperCase()})`,
        selectorText: `${statesMap[aic.state]} (${aic.state.toUpperCase()})`,
        items: [aic],
        expanded: false,
        state: aic.state,
      };
    } else {
      // find index
      prev[aic.state].items.push(aic);
    }

    return prev;
  }, {});

  // return sortedData value
  const sortedStates = Object.keys(sortedDataMap).sort((a, b) =>
    a.localeCompare(b, undefined, { sensitivity: "accent" })
  );

  const sortedLocationsByState = sortedStates.map((state) => {
    const sortedItems = sortedDataMap[state].items.sort((a, b) =>
      a.locationName.localeCompare(b.locationName, undefined, {
        sensitivity: "accent",
      })
    );
    return {
      ...sortedDataMap[state],
      items: sortedItems,
    };
  });

  return sortedLocationsByState;
};

export const expandedState = (item, itemExpandKey, expanded) => {
  const nextExpanded = expanded.slice();
  const state = item[itemExpandKey];
  const index = expanded.indexOf(state);
  index === -1 ? nextExpanded.push(state) : nextExpanded.splice(index, 1);
  return nextExpanded;
};
