import React, { useContext } from 'react';

// common components
import AwesomeLink from '@/components/common-components/AwesomeLink';

// context
import { WorkItemContext } from '@/context';

const CallHistoryLog = ({
  id,
  title = 'Show Call History',
  customClassName = ''
}) => {
  const { setShowCallHistoryLog, getWorkItemDetails } =
    useContext(WorkItemContext);

  return (
    <>
      <AwesomeLink
        onClick={() => {
          getWorkItemDetails(id);
          setShowCallHistoryLog(true);
        }}
        icon='book'
        label={title}
        customClassName={customClassName}
      />
    </>
  );
};

export default CallHistoryLog;
