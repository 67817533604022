import React, { useState, useContext } from 'react';

// components
import FormButton from '@/components/common-components/Form/FormButton';
import PreviewDocument from '@/components/common-components/PreviewDocument';
import AwesomeLabel from '@/components/common-components/AwesomeLabel';
import Preloader from '@/components/common-components/Preloader';

// Kendo
import { Dialog } from '@progress/kendo-react-dialogs';
import { Field, Form } from '@progress/kendo-react-form';
import { Checkbox, TextArea } from '@progress/kendo-react-inputs';
import { Input } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Button } from '@progress/kendo-react-buttons';

// styles
import styles from './LabDrawModal.module.scss';

// util
import { cancelReasons, accessPoints } from './LabDrawMapping';
import {
  formatDateTimeToDefault,
  formatDateToAWSDateTime
} from '@/common/DateHelper';
import { Constants } from '@/constants';
import { DropDownListField, validateInput } from '@/common/Validation';
import { authUser } from '@/common/aws-cognito';

// api
import { cancelTreatmentCall, closeLabDrawCall } from './api.LabDrawModal';

// context
import { NotifContext, UserContext } from '@/context';

const LabDrawModal = ({ handleShowFinshLabDraw, labDrawInfo }) => {
  const { showSuccess, showWarning } = useContext(NotifContext);
  const { agentId } = useContext(UserContext);
  const [showCancelLabDrawDialog, setshowCancelLabDrawDialog] = useState(false);
  const [labDrawConfirmed, setlabDrawConfirmed] = useState(false);
  const isLoading = labDrawInfo ? false : true;
  const toggleCancelLabDrawDialog = () => {
    setshowCancelLabDrawDialog(!showCancelLabDrawDialog);
  };
  const handleCancel = async (dataItem) => {
    const requestObject = {
      // id: ID!
      nursingProcessId: labDrawInfo.npid || labDrawInfo.nursingProcessId,
      agentId: labDrawInfo.agentId,
      // reason: String!
      reason: dataItem.reasonCancelled.value,
      // notes: String
      notes: `Nurse Cancelled Lab Draw. Reason: ${dataItem.reasonCancelled.value}`
    };

    const cancelled = await cancelTreatmentCall(requestObject);
    if (cancelled) {
      handleShowFinshLabDraw();
      toggleCancelLabDrawDialog();
      showSuccess('Lab Draw Cancelled Successfully');
    }
  };
  //Validation object
  const labDrawModalForm = {
    accessPoint: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          referralId: { ...labDrawModalForm.accessPoint, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    }
  };

  const handleFinishLabDraw = (event) => {
    if (!labDrawConfirmed) {
      showWarning('Please confirm that Lab Draw is complete.');
      return false;
    }

    // callback for successful password check
    const cbSuccess = () => {
      const currentTimeDate = new Date();
      const labDrawEndTime = formatDateToAWSDateTime(currentTimeDate);

      // we must use agentId from the auth function,
      // so the person who really closes the lab draw is the one who signed in
      const agentId = event.username;

      const requestObject = {
        nursingProcessId: labDrawInfo.npid || labDrawInfo.nursingProcessId,
        agentId,
        labDrawStartTime: labDrawInfo.labDrawStartTime,
        labDrawEndTime,
        accessPoint: event.accessPoint.value,
        departureTime: labDrawEndTime,
        closeTreatmentNote: event.appointmentNotes,
        signature: agentId
      };

      closeLabDrawCall(requestObject).then(() => {
        showSuccess('Lab Draw Completed Successfully');
        handleShowFinshLabDraw();
      });
    };

    // callback for failed password check
    const cbFailure = (message) => {
      showWarning(message);
    };

    // check user's password, then call the callback function
    authUser(event.username, event.signature, cbSuccess, cbFailure);
  };

  const patientInformation = `${labDrawInfo?.patientFirstName} ${labDrawInfo?.patientLastName}`;

  return (
    <>
      <Dialog
        id='dialogAppointmentCheckIn'
        title='Complete Lab Draw'
        height='auto'
        width={600}
        initialTop={350}
        showDialog
        onClose={toggleCancelLabDrawDialog}
      >
        <Preloader show={isLoading}>
          <Form
            id='formAppointmentCheckIn'
            onSubmit={handleFinishLabDraw}
            render={(formRenderProps) => (
              <form
                onSubmit={formRenderProps.onSubmit}
                className='k-form pl-3 pr-3 pt-3'
              >
                <div className='row'>
                  <div className='col-4'>
                    <h5>Patient Info</h5>
                  </div>
                  <div className='col-8'></div>
                </div>
                <hr className={styles.hrSpacing} />
                <div className='row'>
                  <div className='col-4'>
                    <p>
                      <b>
                        <AwesomeLabel label={patientInformation} icon='user' />
                      </b>
                    </p>
                  </div>
                  <div className='col-8'>
                    <p>
                      <AwesomeLabel
                        size='duotone'
                        icon='timer'
                        title='Start Time'
                        label={formatDateTimeToDefault(
                          labDrawInfo?.labDrawStartTime
                        )}
                      />
                    </p>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-4'>
                    <p>
                      <AwesomeLabel
                        size='duotone'
                        icon='person'
                        title='Nurse'
                        label={agentId}
                      />
                    </p>
                  </div>
                  <div className={styles.labelPadding}>
                    <p>
                      <AwesomeLabel
                        size='duotone'
                        icon='memo'
                        title='Referral'
                        label={labDrawInfo?.adminStage?.drugName}
                      />
                    </p>
                  </div>
                </div>

                <hr />
                <div className='row mt-2'>
                  <div className='col-12'>
                    <p>
                      <AwesomeLabel
                        label=' '
                        title='Lab Draw Notes'
                        icon='notes-medical'
                        size='duotone'
                      />
                    </p>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12'>
                    <Field
                      name='appointmentNotes'
                      rows={4}
                      autoSize
                      component={TextArea}
                      value={null}
                      defaultValue={labDrawInfo?.notes}
                    />
                  </div>
                </div>
                <div className='row mt-10'>
                  <div className='col-4 mt-1'>
                    <b>
                      <AwesomeLabel
                        label={'Access Point:'}
                        icon='bullseye-pointer'
                      />
                    </b>
                  </div>
                  <div className='col-8'>
                    <Field
                      name='accessPoint'
                      textField='text'
                      valueField='value'
                      component={DropDownListField}
                      data={accessPoints}
                      validator={labDrawModalForm.accessPoint.inputValidator}
                      required
                    />
                  </div>
                </div>
                <div className='row mt-10'>
                  <div className='col-4 mt-1'>
                    <b>
                      <AwesomeLabel label='Username:' icon='user' />
                    </b>
                  </div>
                  <div className='col-8'>
                    <Field
                      component={Input}
                      name='username'
                      label=''
                      required
                    />
                  </div>
                </div>
                <div className='row mt-10'>
                  <div className='col-4 mt-1'>
                    <b>
                      <AwesomeLabel label='Password:' icon='key' />
                    </b>
                  </div>
                  <div className='col-8'>
                    <Field
                      component={Input}
                      name='signature'
                      label=''
                      required
                      type='password'
                    />
                  </div>
                </div>
                <div className='row mt-14'>
                  <div className='col-12'>
                    <Field
                      name='confirmation'
                      label='I confirm that the Lab Draw is complete.'
                      required
                      component={Checkbox}
                      value={labDrawConfirmed}
                      onChange={(e) => setlabDrawConfirmed(e.value)}
                    />
                  </div>
                </div>
                <div className='row mt-14'>
                  <div className='col-4'>
                    <FormButton
                      type='submit'
                      className='btn blue'
                      label='Finish Lab Draw'
                    />
                  </div>

                  <div className='col-4'>
                    <Button
                      type='button'
                      className='btn blue'
                      onClick={toggleCancelLabDrawDialog}
                    >
                      Cancel Lab Draw
                    </Button>
                  </div>
                  <div className='col-4'>
                    <PreviewDocument
                      patientId={labDrawInfo?.patientId}
                      referralId={labDrawInfo?.referralId}
                    />
                  </div>
                </div>
              </form>
            )}
          />
        </Preloader>
      </Dialog>
      {showCancelLabDrawDialog && (
        <Dialog
          title='Cancel Lab Draw'
          width={550}
          initialTop={50}
          height='auto'
          onClose={toggleCancelLabDrawDialog}
        >
          <Form
            onSubmit={handleCancel}
            render={(formRenderProps) => (
              <form
                onSubmit={formRenderProps.onSubmit}
                className='k-form pl-3 pr-3 pt-1'
              >
                <div className='row'>
                  <div className='col-md-3 mt-2 pl-4'>Reason:</div>
                  <div className='col-8'>
                    <Field
                      data={cancelReasons}
                      component={DropDownList}
                      valueField='value'
                      textField='value'
                      className={styles.fieldWidth}
                      name='reasonCancelled'
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-12 mt-16'>
                    <button
                      type='submit'
                      className='k-button k-primary mr-3 p-1'
                    >
                      Submit Cancellation
                    </button>
                    <button
                      onClick={toggleCancelLabDrawDialog}
                      className='k-button pageButton'
                    >
                      Do Not Cancel
                    </button>
                  </div>
                </div>
              </form>
            )}
          />
        </Dialog>
      )}
    </>
  );
};

export default LabDrawModal;
