import React from 'react';

const TelehealthVisitBadge = ({ show = true }) => {
  if (!show) return null;

  return (
    <span className='badge badge-pill bg-info k-iconWhite'>
      TELEHEALTH VISIT
    </span>
  );
};

export default TelehealthVisitBadge;
