import { logRecords } from '@/constants/dict';

export const getLogTitle = (logRecord) => {
  const agent = logRecord.agentId;

  let title = logRecords.UPDATED_BY_AGENT;

  if (
    logRecord.reasonsForStatusChange &&
    logRecord.reasonsForStatusChange !== 'false'
  ) {
    title = logRecord.reasonsForStatusChange;
  }

  return title.replace('XXX', agent);
};
