import * as moment from 'moment';
import React from 'react';
import { Constants } from '@/constants';

export const GridDateTimeZoneFormatCell = (props) => {
  return (
    <td>
      {`${moment(new Date(props.dataItem.date)).format(
        Constants.DATE.DEFAULT_DATETIME
      )} ${new Date()
        .toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'long' })
        .substring(4)}`}
    </td>
  );
};

// check if passed object is empty - `{}` - has no attributes at all
export const isObjectEmpty = (obj) => Object.keys(obj).length === 0;

export const formatSSN = (value) => {
  if (!value) return null;

  value = value.slice(0, 11).replace(/-/g, '');
  if (value.length <= 3) {
    return value;
  }
  if (value.length > 3 && value.length <= 5) {
    return `${value.slice(0, 3)}-${value.slice(3)}`;
  }
  if (value.length > 5) {
    return `${value.slice(0, 3)}-${value.slice(3, 5)}-${value.slice(5)}`;
  }
};

export const maskSSN = (value) => {
  if (!value) return value;
  const masked = value.slice(0, 7).replace(/[0-9]/g, '*');
  const final = masked + value.slice(7);
  return final;
};

export const makeArrayUniqueAndSort = (origArray, uniqueField, sortField) => {
  const sortBy = sortField || uniqueField;
  const unique = [];
  for (const arrayItem of origArray) {
    const isDuplicate = unique.find(
      (obj) => obj[uniqueField] === arrayItem[uniqueField]
    );
    if (!isDuplicate) {
      unique.push(arrayItem);
    }
  }

  // Sort by the same field no matter of letter case
  return sortArrayByField(unique, sortBy);
};

// Sort by the same field no matter of letter case
export const sortArrayByField = (origArray, sortField) => {
  return origArray.sort((a, b) => {
    if (a[sortField].toLowerCase() < b[sortField].toLowerCase()) {
      return -1;
    } else if (a[sortField].toLowerCase() > b[sortField].toLowerCase()) {
      return 1;
    } else {
      return 0;
    }
  });
};

export const makeFlatArray = (origArray, fieldName) => {
  const flatArray = [];

  origArray.forEach((item) => flatArray.push(item[fieldName]));

  return flatArray;
};

export const getFileNameFromPath = (pathString) => {
  // Split the filePath by slashes to get an array of path segments
  const pathSegments = pathString.split('/');

  // Get the last segment, which represents the file name
  return pathSegments[pathSegments.length - 1];
};
