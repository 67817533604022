import React from 'react';

/* @NOTE: list of supported icons can be found here: https://fontawesome.com/icons

Supported size values:
  * 2xs
  * xs
  * sm
  * lg
  * xl
  * 2xl
*/
const AwesomeLabel = ({
  icon = '',
  label = '',
  title,
  size,
  iconStyle = 'solid',
  iconFirst = true,
  customClassName = ''
}) => {
  if (!label) {
    console.error('AwesomeLabel component must have label as a property.');
    return '<label>';
  }

  if (!icon) return label;

  const sizeStyle = size ? `fa-${size}` : '';

  const renderIcon = () => (
    <i
      className={`fa-${iconStyle} fa-${icon} ${sizeStyle} ${customClassName}`}
    />
  );

  const renderLabel = () => {
    if (title) {
      return (
        <>
          <b>{title}:</b> {label}
        </>
      );
    }

    return label;
  };

  if (iconFirst) {
    return (
      <>
        {renderIcon()}&nbsp;&nbsp;{renderLabel()}
      </>
    );
  }

  return (
    <>
      {renderLabel()}&nbsp;&nbsp;{renderIcon()}
    </>
  );
};
export default AwesomeLabel;
