import * as moment from 'moment';

// gql
import { connectToGraphqlAPI } from '@/provider';
import { getScheduleEventsByLocationId } from '@/graphql/queries';

// helpers
import { formatDateToAWSDateTime } from '@/common/DateHelper';

export const getScheduleEvents = async (locationId, specificDate, view) => {
  if (!locationId?.length) {
    alert('Cannot query getScheduleEventsByLocationIdCall. Location Id was not provided');
  } else if (!specificDate) {
    alert('Cannot query getScheduleEventsByLocationIdCall. selected date is missing');
  } else {
    try {
      let startDate;
      let endDate;
      switch (view) {
        case 'month':
          startDate = moment(specificDate).date(1).format('YYYY-MM-DD');
          endDate = moment(specificDate)
            .date(31)
            .add(1, 'days')
            .format('YYYY-MM-DD');
          break;
        case 'week':
        case 'agenda':
          startDate = moment(specificDate).day(0).format('YYYY-MM-DD');
          endDate = moment(specificDate)
            .day(6)
            .add(1, 'days')
            .format('YYYY-MM-DD');
          break;
        case 'day':
        default:
          startDate = moment(specificDate).format('YYYY-MM-DD');
          endDate = moment(specificDate).add(1, 'days').format('YYYY-MM-DD');
          endDate = formatDateToAWSDateTime(endDate);// Adds the time back to date at 0:00
          endDate = new Date(endDate); // Creates new date to use with setHours
          endDate = endDate.setHours(endDate.getHours() + 5); // Adds five hours to the endtime
      }

      const data = await connectToGraphqlAPI({
        graphqlQuery: getScheduleEventsByLocationId,
        variables: {
          locationId,
          period: {
            startDate: formatDateToAWSDateTime(startDate),
            endDate: formatDateToAWSDateTime(endDate)
          }
        }
      });
      if (data?.data?.getScheduleEventsByLocationId?.events) {
        return data.data.getScheduleEventsByLocationId.events
          .filter((event) => event?.status?.toUpperCase() !== 'CANCELLED')
          .map((item, index) => ({
            ...item,
            TaskID: index + 1,
            Title: item.chairId === 'c000' ? item.title : `${item.title}`,
            Description: item.notes,
            StartTimezone: null,
            Start: new Date(item.startTime),
            End: new Date(item.endTime),
            EndTimezone: null,
            RecurrenceRule: null,
            RecurrenceID: null,
            RecurrenceException: null,
            isAllDay: item.chairId === 'c000',
            chairId: item.chairId === 'c000' ? 'c001' : item.chairId,
            OwnerID: 1,
            PersonIDs: 1,
            RoomID: 1
          }));
      }
    } catch (err) {
      console.log('marty getScheduleEventsByLocationIdCall err', err);
      return null;
    }
  }
};
