import React, { useContext } from 'react';

// components
import WorkItemTimeoutModal from '@/components/WorkItemTimeoutModal/WorkItemTimeoutModal';
import LinkToPatientProfile from '@/components/common-components/LinkToPatientProfile';
import AwesomeLabel from '@/components/common-components/AwesomeLabel';

// partials
import PreviewDocument from '../PreviewDocument';
import WorkItemId from '../WorkItemId';
import WorkItemLog from '../WorkItemLog';
import WorkItemParent from '../WorkItemParent';

// context
import { WorkItemContext } from '@/context';

// utils
import { workItemStatusMap } from '@/components/Queue/FilterSystem/filterHelper';

// styles
import styles from '../CurrentWorkItem.module.scss';

const PayerOON = () => {
  const { selectedWorkItem } = useContext(WorkItemContext);
  const drugName =
    selectedWorkItem.attachedData?.attachedData?.productName ||
    selectedWorkItem?.drugName ||
    'n/a';
  const title = workItemStatusMap[selectedWorkItem?.work] || 'n/a';

  return (
    <>
      <div className='row'>
        <div className='col-6'>
          <h5>Complete: {title}</h5>
          <div>
            <LinkToPatientProfile />
          </div>
          <div>
            <AwesomeLabel label={drugName} icon='pills' title='Drug Name' />
          </div>
          <div>
            <WorkItemId />
          </div>
          <div className={styles.itemRow}>
            <WorkItemLog />
          </div>
        </div>

        <div className='col-1' />
        <div className='col-4'>
          <div className={styles.itemRow}>
            <WorkItemParent />
            <p>
              <b>Description:</b> {selectedWorkItem.description}
            </p>
          </div>

          <div className='col-1' />
        </div>
      </div>
      <div className='row'>
        <div className='col-9' />
        <div className='col-2'>
          <div className={styles.alignRight}>
            <PreviewDocument />
          </div>
        </div>
        <div className='col-1' />
      </div>
      <WorkItemTimeoutModal />
    </>
  );
};

export default PayerOON;
