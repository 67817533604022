import React, { useState, useCallback, useEffect, useMemo } from 'react';

// kendo
import { DropDownTree } from '@progress/kendo-react-dropdowns';

// utils
import {
  processTreeData,
  expandedState,
  reasons
} from './BulkAdjustmentSelector.utils';

const BulkAdjustmentSelector = ({
  selectField = 'selected',
  expandField = 'expanded',
  dataItemKey = 'id',
  textField = 'text',
  subItemsField = 'items',
  itemExpandKey = 'id',
  onSelectChange
}) => {
  const [expanded, setExpanded] = useState([reasons[0][dataItemKey]]);
  const [value, setValue] = useState(null);
  const onChange = (event) => {
    setValue(event.value);
    onSelectChange(event.value);
  };

  const fields = {
    selectField,
    expandField,
    dataItemKey,
    subItemsField,
    itemExpandKey
  };

  const treeData = useMemo(
    () =>
      processTreeData(
        reasons,
        {
          expanded,
          value
        },
        fields
      ),
    [expanded, value]
  );

  useEffect(() => {
    if (value && expanded.indexOf(value.state) < 0) {
      setExpanded(expandedState(value, itemExpandKey, expanded));
    }
  }, [value]);

  const onExpandChange = useCallback(
    (event) => setExpanded(expandedState(event.item, dataItemKey, expanded)),
    [expanded]
  );

  const Item = (props) => {
    return (
      <div
        onClick={(e) => handleCustomChange(e, props)}
        onDoubleClick={(e) => e.stopPropagation()}
      >
        {`${props.item.text}`}
      </div>
    );
  };

  const handleCustomChange = (e, props) => {
    if (props.item) {
      if (props.item.id) {
        onChange(props.item);
      } else {
        // clicked sub root item
        setExpanded(expandedState(props.item, itemExpandKey, expanded));
        e.stopPropagation();
        return;
      }
    }
  };

  return (
    <DropDownTree
      data={treeData}
      value={value}
      placeholder='Select a reason for adjustment'
      textField={textField}
      dataItemKey={dataItemKey}
      selectField={selectField}
      expandField={expandField}
      onChange={onChange}
      onExpandChange={onExpandChange}
      item={Item}
    />
  );
};

export default BulkAdjustmentSelector;
