export const CODE_TYPES = {
  ICD10: 'ICD10',
  BI: 'BI',
  PA: 'PA',
  INBOUND: 'INBOUND',
  INTAKE: 'INTAKE',
  SCHEDULE: 'SCHEDQ'
};

export const filterCodesByType = (list = [], type = CODE_TYPES.ICD10) =>
  list.filter((code) => code.codeType === type);

export const mapICD10Codes = (list = []) => {
  const filtered = filterCodesByType(list);

  return filtered.map((item) => {
    item.codeDescription = `${item.code} - ${item.description}`;
    return item;
  });
};
