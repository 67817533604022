import { groupQueues } from '@/constants/enum';

const phoneIcon = 'phone icon-blue';
const patient = 'user icon-blue';
const doctor = 'user-doctor-hair icon-blue';
const listIcon = 'check';
const hospital = 'hospital-user icon-blue';
const insurance = 'hospitals icon-blue';

export const GENERAL_FOLLOWUP_CALLS_ID = 1;
export const GENERAL_FOLLOWUP_TASKS_ID = 141;

export const generalFollowUpCategories = [
  {
    text: 'Tasks (Follow-Ups)',
    id: GENERAL_FOLLOWUP_TASKS_ID,
    items: [
      {
        parentId: GENERAL_FOLLOWUP_TASKS_ID,
        text: 'BI Follow-Up',
        icon: listIcon,
        queueType: groupQueues.BI
      },
      {
        parentId: GENERAL_FOLLOWUP_TASKS_ID,
        text: 'Billing Issue-Leads-Task',
        icon: hospital,
        forPatDoc: true,
        queueType: groupQueues.INTAKE
      },
      {
        parentId: GENERAL_FOLLOWUP_TASKS_ID,
        text: 'Billing Issue-Patient-Demos/Ins',
        icon: hospital,
        forPatDoc: true,
        queueType: groupQueues.INTAKE
      },
      {
        parentId: GENERAL_FOLLOWUP_TASKS_ID,
        text: 'Chart Notes Received',
        icon: listIcon,
        queueType: groupQueues.INTAKE
      },
      {
        parentId: GENERAL_FOLLOWUP_TASKS_ID,
        text: 'Discontinuation Review',
        icon: listIcon,
        queueType: groupQueues.INTAKE
      },
      {
        parentId: GENERAL_FOLLOWUP_TASKS_ID,
        text: 'Documents/Misc/Other',
        icon: listIcon,
        queueType: groupQueues.INTAKE
      },
      {
        parentId: GENERAL_FOLLOWUP_TASKS_ID,
        text: 'Expired PA',
        icon: listIcon,
        queueType: groupQueues.PA
      },
      {
        parentId: GENERAL_FOLLOWUP_TASKS_ID,
        text: 'General(Task)',
        icon: listIcon,
        queueType: groupQueues.INTAKE
      },
      {
        parentId: GENERAL_FOLLOWUP_TASKS_ID,
        text: 'Labs Received',
        icon: listIcon,
        queueType: [groupQueues.INTAKE, groupQueues.CLINICIAN]
      },
      {
        parentId: GENERAL_FOLLOWUP_TASKS_ID,
        text: 'Missing MD Info-No Response',
        icon: doctor,
        forDoctor: true,
        queueType: groupQueues.SALES
      },
      {
        parentId: GENERAL_FOLLOWUP_TASKS_ID,
        text: 'Need Clinical Evaluation',
        icon: hospital,
        queueType: groupQueues.CLINICIAN
      },
      {
        parentId: GENERAL_FOLLOWUP_TASKS_ID,
        text: 'P2P/MD Appeal',
        icon: doctor,
        forDoctor: true,
        queueType: groupQueues.SALES
      },
      {
        parentId: GENERAL_FOLLOWUP_TASKS_ID,
        text: 'PA Follow-Up',
        icon: listIcon,
        queueType: groupQueues.PA
      },
      {
        parentId: GENERAL_FOLLOWUP_TASKS_ID,
        text: 'Sales Escalation',
        icon: listIcon,
        queueType: groupQueues.INTAKE
      },
      {
        parentId: GENERAL_FOLLOWUP_TASKS_ID,
        text: 'Submit Appeal',
        icon: listIcon,
        queueType: groupQueues.PA
      },
      {
        parentId: GENERAL_FOLLOWUP_TASKS_ID,
        text: 'Submit Appeal',
        icon: hospital,
        forInsDoc: true,
        queueType: groupQueues.CLINICIAN
      }
    ]
  },
  {
    text: 'Calls',
    id: GENERAL_FOLLOWUP_CALLS_ID,
    items: [
      {
        text: 'Billing Issue-Leads-Call',
        parentId: GENERAL_FOLLOWUP_CALLS_ID,
        icon: hospital,
        forPatDoc: true,
        queueType: groupQueues.INTAKE
      },
      {
        text: 'Billing Issue-MD Outreach',
        parentId: GENERAL_FOLLOWUP_CALLS_ID,
        icon: doctor,
        forDoctor: true,
        queueType: groupQueues.INTAKE
      },
      {
        text: 'Billing Issue-Payer-PA/Retro',
        parentId: GENERAL_FOLLOWUP_CALLS_ID,
        icon: insurance,
        forInsurance: true,
        queueType: groupQueues.PA
      },
      {
        text: 'Billing Issue-Patient-Demos/Ins-Call',
        parentId: GENERAL_FOLLOWUP_CALLS_ID,
        icon: hospital,
        forPatDoc: true,
        queueType: groupQueues.INTAKE
      },
      {
        text: 'Cancel Patient',
        parentId: GENERAL_FOLLOWUP_CALLS_ID,
        icon: patient,
        forPatient: true,
        queueType: groupQueues.SCHEDULE
      },
      {
        text: 'Formulary Change',
        parentId: GENERAL_FOLLOWUP_CALLS_ID,
        icon: hospital,
        forPatDoc: true,
        queueType: groupQueues.CLINICIAN
      },
      {
        text: 'General-Call',
        parentId: GENERAL_FOLLOWUP_CALLS_ID,
        icon: phoneIcon,
        queueType: groupQueues.INTAKE
      },
      {
        text: 'Insurance Change-Waystar',
        parentId: GENERAL_FOLLOWUP_CALLS_ID,
        icon: phoneIcon,
        queueType: groupQueues.BI
      },
      {
        text: 'Lab Renewal',
        parentId: GENERAL_FOLLOWUP_CALLS_ID,
        icon: patient,
        forPatient: true,
        queueType: groupQueues.INTAKE
      },
      {
        text: 'Lab Results',
        parentId: GENERAL_FOLLOWUP_CALLS_ID,
        icon: hospital,
        forPatDoc: true,
        queueType: groupQueues.INTAKE
      },
      {
        text: 'Missing/Need Information-Doc/Misc',
        parentId: GENERAL_FOLLOWUP_CALLS_ID,
        icon: hospital,
        forPatDoc: true,
        queueType: groupQueues.CLINICIAN
      },
      {
        text: 'Missing/Need Information-Documentation',
        parentId: GENERAL_FOLLOWUP_CALLS_ID,
        icon: doctor,
        forDoctor: true,
        queueType: groupQueues.INTAKE
      },
      {
        text: 'Missing/Need Information-Labs',
        parentId: GENERAL_FOLLOWUP_CALLS_ID,
        icon: hospital,
        forPatDoc: true,
        queueType: groupQueues.CLINICIAN
      },
      {
        text: 'Missing/Need Information-Referral',
        parentId: GENERAL_FOLLOWUP_CALLS_ID,
        icon: hospital,
        forPatDoc: true,
        queueType: groupQueues.CLINICIAN
      },
      {
        text: 'Need New Insurance',
        parentId: GENERAL_FOLLOWUP_CALLS_ID,
        icon: hospital,
        forPatDoc: true,
        queueType: groupQueues.INTAKE
      },
      {
        text: 'Offer of Financial Assistance',
        parentId: GENERAL_FOLLOWUP_CALLS_ID,
        icon: patient,
        forPatient: true,
        queueType: groupQueues.INTAKE
      },
      {
        text: 'Onboarding',
        parentId: GENERAL_FOLLOWUP_CALLS_ID,
        icon: patient,
        forPatient: true,
        queueType: groupQueues.CLINICIAN
      },
      {
        text: 'PA Denied Call',
        parentId: GENERAL_FOLLOWUP_CALLS_ID,
        icon: patient,
        forPatient: true,
        queueType: groupQueues.PA
      },
      {
        text: 'Patient Counseling',
        parentId: GENERAL_FOLLOWUP_CALLS_ID,
        icon: patient,
        forPatient: true,
        queueType: groupQueues.CLINICIAN
      },
      {
        text: 'Referral Renewal',
        parentId: GENERAL_FOLLOWUP_CALLS_ID,
        icon: hospital,
        forPatDoc: true,
        queueType: groupQueues.INTAKE
      },
      {
        text: 'Referral Status',
        parentId: GENERAL_FOLLOWUP_CALLS_ID,
        icon: patient,
        forPatient: true,
        queueType: groupQueues.INTAKE
      },
      {
        text: 'Schedule Patient',
        parentId: GENERAL_FOLLOWUP_CALLS_ID,
        icon: patient,
        forPatient: true,
        queueType: groupQueues.SCHEDULE
      },
      {
        text: 'Submit Appeal',
        parentId: GENERAL_FOLLOWUP_CALLS_ID,
        icon: doctor,
        forDoctor: true,
        queueType: groupQueues.PA
      },
      {
        text: 'Unable to Schedule Patient',
        parentId: GENERAL_FOLLOWUP_CALLS_ID,
        icon: doctor,
        forDoctor: true,
        queueType: groupQueues.INTAKE
      },
      {
        text: 'Welcome Call',
        parentId: GENERAL_FOLLOWUP_CALLS_ID,
        icon: patient,
        forPatient: true,
        queueType: groupQueues.INTAKE
      }
    ]
  }
];

const itemNames = {
  LABS_RECEIVED: 'Labs Received',
  LAB_RENEWAL: 'Lab Renewal',
  LAB_RESULTS_PATIENT: 'Lab Results',
  MISSING_NEED_REFERRAL: 'Missing/Need Information-Referral',
  MISSING_NEED_LABS: 'Missing/Need Information-Labs',
  MISSING_NEED_DOC: 'Missing/Need Information-Doc/Misc'
};

const audienceMap = {
  FOR_PATIENT: 'forPatient',
  FOR_PAT_DOC: 'forPatDoc',
  FOR_DOCTOR: 'forDoctor',
  FOR_INS_DOC: 'forInsDoc',
  FOR_INSURANCE: 'forInsurance'
};

export const checkAudience = (typeValue) => {
  if (typeValue?.parentId === GENERAL_FOLLOWUP_TASKS_ID) return false;
  for (const audienceKey in audienceMap) {
    if (typeValue?.hasOwnProperty(audienceMap[audienceKey])) {
      return true;
    }
  }
  return false;
};

export const isCorrectSelection = (item) => {
  return !!item?.queueType;
};

export const getMainGeneralCategory = (parentId) => {
  if (parentId === GENERAL_FOLLOWUP_TASKS_ID) {
    return {
      text: 'Tasks (Follow-Ups)',
      id: GENERAL_FOLLOWUP_TASKS_ID
    };
  }
  if (parentId === GENERAL_FOLLOWUP_CALLS_ID) {
    return {
      text: 'Calls',
      id: GENERAL_FOLLOWUP_CALLS_ID
    };
  }
};

const [taskCategories, callCategories] = generalFollowUpCategories;

// Generate task list with items mapped to their respective queue types
export const taskList = taskCategories.items.flatMap((item) => {
  const queueTypes = Array.isArray(item.queueType)
    ? item.queueType
    : [item.queueType];
  return queueTypes.map((queueType) => ({
    text: item.text,
    value: item.text,
    queueType
  }));
});

// Generate call list with items mapped to their respective queue types
export const callList = callCategories.items.flatMap((item) => {
  const queueTypes = Array.isArray(item.queueType)
    ? item.queueType
    : [item.queueType];
  return queueTypes.map((queueType) => ({
    text: item.text,
    value: item.text,
    queueType
  }));
});

export const filterItemsByQueueType = (queueType, listType) => {
  return listType.filter((item) => item.queueType === queueType);
};

export const hasMultQueue = (category) => {
  for (const key in itemNames) {
    if (itemNames.hasOwnProperty(key)) {
      if (category.text === itemNames[key]) {
        return true;
      }
    }
  }
  return false;
};
