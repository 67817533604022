import { phq9Answer } from './modelBlocks';

export default {
  name: 'general-PHQ9',
  type: 'panel',
  title:
    'Over the past 2 weeks, how often have you been bothered by any of the following problems?',
  isRequired: true,
  visibleIf: '{EWB-depression} < 6',
  elements: [
    {
      ...phq9Answer,
      name: 'PHQ9-Q1',
      title: 'Little interest or pleasure in doing things'
    },
    {
      ...phq9Answer,
      name: 'PHQ9-Q2',
      title: 'Feeling down, depressed or hopeless'
    },
    {
      ...phq9Answer,
      name: 'PHQ9-Q3',
      title: 'Trouble falling asleep, staying asleep or sleeping too much'
    },
    {
      ...phq9Answer,
      name: 'PHQ9-Q4',
      title: 'Feeling tired or having little energy'
    },
    {
      ...phq9Answer,
      name: 'PHQ9-Q5',
      title: 'Poor appetite or overeating'
    },
    {
      ...phq9Answer,
      name: 'PHQ9-Q6',
      title:
        "Feeling bad about yourself or that you're a failure or have let yourself or your family down"
    },
    {
      ...phq9Answer,
      name: 'PHQ9-Q7',
      title:
        'Trouble concentrating on things such as reading the newspaper or watching television'
    },
    {
      ...phq9Answer,
      name: 'PHQ9-Q8',
      title:
        'Moving or speaking so slowly that other people could have noticed; or the opposite-being so fidgety or restless that you have been moving around a lot more than usual'
    },
    {
      ...phq9Answer,
      name: 'PHQ9-Q9',
      title:
        'Thoughts that you would be better off dead or of hurting yourself in some way'
    },
    {
      ...phq9Answer,
      name: 'PHQ9-Q10',
      title:
        'If you check off any problems listed above, how difficult have those problems made it for you to do your work, take care of things at home or get along with other people',
      choices: [
        { value: 0, text: 'Not difficult at all' },
        { value: 1, text: 'Somewhat difficult' },
        { value: 2, text: 'Very difficult' },
        { value: 3, text: 'Extremely difficult' }
      ]
    }
  ]
};
