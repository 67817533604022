import React, { useContext } from 'react';

// context
import { LocationContext, TreatmentContext } from '@/context';

//utils
import { formatDateToDefault } from '@/common/DateHelper';
import { accessPoints } from '@/components/Calendar/LabDrawModal/LabDrawMapping';

// assets
import svgLogo from '@/assets/images/alera-dark.svg';

const LogHeader = () => {
  const { snapshotInfusion } = useContext(TreatmentContext);
  const { getLocation } = useContext(LocationContext);
  const locationData = getLocation(snapshotInfusion?.locationId);

  return (
    <>
      <div className='row'>
        <div className='col-md-3'>
          <img src={svgLogo} alt='AleraCare' />
        </div>
        <div className='col-md-4'>
          <h4>
            {snapshotInfusion?.infusionDetail?.patientFirstName}&nbsp;
            {snapshotInfusion?.infusionDetail?.patientLastName}
          </h4>
        </div>
        <div className='col-md-5'>
          <h4>Order:&nbsp;{snapshotInfusion?.infusionDetail?.orderName}</h4>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-3'>&nbsp;</div>
        <div className='col-md-4'>
          <h4>
            Appointment Date:&nbsp;
            {snapshotInfusion?.appoinmentTime
              ? formatDateToDefault(snapshotInfusion?.appoinmentTime)
              : '-'}
          </h4>
        </div>
        <div className='col-md-5'>
          <h4>Location:&nbsp;{locationData?.selectorText || ''}</h4>
        </div>
      </div>
    </>
  );
};

export { LogHeader };

export function getAccessPointText(accessValue) {
  const matchingAccessPoint = accessPoints.find(
    (accessPoint) => accessPoint.value === accessValue
  );
  return matchingAccessPoint ? matchingAccessPoint.text : '';
}
