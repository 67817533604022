import { formatDateToAWS } from '@/common/DateHelper';

export const prepareWorkItemData = (workItem) => {
  const jsonData = JSON.parse(workItem.attachedData);

  return {
    status: workItem.workStatus,
    date: formatDateToAWS(workItem.createdAt),
    source: jsonData.source,
    description: 'inbound fax',
    url: jsonData.documentURI,
    id: workItem.id
  };
};

export const generateAttachedData = (formData, dxCode) => {
  const res = {};

  if ('product' in formData) {
    res.productName = formData.product.productName;
    res.productId = formData.product.productId;
  }

  if ('insuranceCompany' in formData) {
    res.insurerId = formData.insuranceCompany.id;
    res.insurerName = formData.insuranceCompany.name;
  }

  if ('insurancePlan' in formData) {
    res.insurancePlanId = formData.insurancePlan.id;
    res.insurancePlanIdExternal = formData.insurancePlan.externalId;
    res.insurancePlanName = formData.insurancePlan.planName;
  }

  if ('isNotContracted' in formData) {
    res.isNotContracted = formData.isNotContracted;
  }

  if (dxCode) {
    res.dxCode = dxCode;
  }

  return JSON.stringify(res);
};
