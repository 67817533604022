import React from "react";

import PatientAllergiesLog from "./PatientAllergiesLog";
import PatientAllergiesAdd from "./PatientAllergiesAdd";

const PatientAllergies = () => {
  return (
    <div className="k-form pl-3 pr-3 pt-1">
      <div className="row mt-2">
        <div className="col-md-8">
          <p className="icon-blue important-text">Known Allergies</p>
        </div>
        <div className="col-md-4">
          <p className="icon-blue important-text">Add New Allergy</p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-8">
          <PatientAllergiesLog editable={true} />
        </div>
        <div className="col-md-4">
          <PatientAllergiesAdd />
        </div>
      </div>
    </div>
  );
};

export default PatientAllergies;
