import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';

// kendo
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import { Button } from '@progress/kendo-react-buttons';
import { ExcelExport } from '@progress/kendo-react-excel-export';

// components
import { MessageDialog } from '@/components/common-components/MessageDialog';
import Preloader from '@/components/common-components/Preloader';
import Badge from '@/components/common-components/Badge';
import { ColumnMenu } from '@/components/common-components/columnMenu';

// gql
import { connectToGraphqlAPI } from '@/provider';
import { getWorkItemsByGroupQueue, getPatientBucket } from '@/graphql/queries';
import { acquireLocker } from '@/graphql/mutations';

// context
import { UserContext, PatientContext, WorkItemContext } from '@/context';

const WorkInProgress = (props) => {
  const { user } = useContext(UserContext);
  const { setSelectedWorkItem } = useContext(WorkItemContext);
  const { setSelectedPatientInfo, setSelectedLocker } =
    useContext(PatientContext);

  const [loading, setLoading] = useState(true);
  const [listWorkItems, setListWorkItems] = useState([]);
  const [dialogOption, setDialogOption] = useState({});

  const _exporter = React.createRef();
  const exportExcel = () => {
    if (_exporter.current) {
      _exporter.current.save();
    }
  };

  const customCell = ({ dataItem }) => {
    const renderEscalationLabel = () => {
      if (dataItem.workStatus === 'OVERDUE') {
        return <Badge text='ESCALATED' />;
      } else if (dataItem.work === 'EXTEND_CLINICIAN_REVIEW') {
        return <Badge type='warning' text='EXPIRING' />;
      }

      return null;
    };
    return (
      <td>
        {renderEscalationLabel()}
        {dataItem.description}
      </td>
    );
  };

  const columns = [
    {
      field: 'agentName',
      title: 'Agent Name'
    },
    {
      field: 'patientName',
      title: 'Patient Name'
    },
    {
      field: 'description',
      title: 'Description',
      cell: customCell
    },
    {
      field: 'drugName',
      title: 'Drug'
    },
    { field: 'locationName', title: 'Site' },
    {
      field: 'clinicianReviewStatus',
      title: 'Status'
    }
  ];

  const initialDataState = {};

  const [dataState, setDataState] = useState();
  const [resultState, setResultState] = React.useState(
    process(listWorkItems, initialDataState)
  );
  const onDataStateChange = React.useCallback(
    (e) => {
      setDataState(e.dataState);
      setResultState(process(listWorkItems, e.dataState));
    },
    [listWorkItems]
  );

  useEffect(() => {
    setDataState(initialDataState);
    setResultState(process(listWorkItems, initialDataState));
  }, [listWorkItems]);

  // MAIN INITIATOR
  useEffect(() => {
    if (user) {
      fetchWorkInProgressData();
    }
  }, [user]);

  const fetchWorkInProgressData = async () => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getWorkItemsByGroupQueue,
        variables: { groupQueueId: 'CLINICIAN' }
      });

      if (
        data &&
        data.data &&
        data.data.getWorkItemsByGroupQueue &&
        data.data.getWorkItemsByGroupQueue.length > 0
      ) {
        const listWorkItemsData = data.data.getWorkItemsByGroupQueue
          .map((item) => {
            item.patientName = `${item.patientLastName}, ${item.patientFirstName}`;
            item.agentName = `${item.agentLastName}, ${item.agentFirstName}`;
            return item;
          })
          .sort((a, b) => (b.startTime > a.startTime ? 1 : -1));
        setListWorkItems(listWorkItemsData);
        setLoading(false);
      }
    } catch (err) {
      console.log('WorkInProgress::fetchWorkInProgressData err:', err);
      setLoading(false);
    }
  };

  const onRowClickHandle = (e) => {
    setSelectedWorkItem(e.dataItem.workItems);
    if (e.dataItem.assignedTo) {
      acquireWorkApiCall(
        {
          agentId: user.username,
          patientId: e.dataItem.patientId
        },
        e.dataItem.patientId,
        e.dataItem.currentStage
      );
    }
  };

  const acquireWorkApiCall = async (requestObject, patientId, currentStage) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: acquireLocker,
        variables: { input: requestObject }
      });

      if (data && data.data && data.data.acquireLocker) {
        if (
          data.data.acquireLocker.success &&
          data.data.acquireLocker.lockerId
        ) {
          setSelectedLocker(data.data.acquireLocker.lockerId);
          getPatientBucketData(patientId, currentStage);
        } else {
          if (data.data.acquireLocker.details) {
            setDialogOption({
              title: 'Acquire Work: Error 1',
              message: data.data.acquireLocker.details,
              showDialog: true
            });
          } else {
            setDialogOption({
              title: 'Acquire Work: Error 2',
              message: 'Error acquireWorkApiCall with no {details} available',
              showDialog: true
            });
          }
        }
      } else {
        if (data.data.acquireLocker.details) {
          setDialogOption({
            title: 'Acquire Work: Error 3',
            message: data.data.acquireLocker.details,
            showDialog: true
          });
        }
      }
    } catch (err) {
      console.log('ClinicalWorkInProgress::acquireWorkApiCall err: ', err);
      setDialogOption({
        title: 'Work In Progress',
        message: 'Fatal Error',
        showDialog: true
      });
    }
  };

  const getPatientBucketData = async (patientId, currentStage) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getPatientBucket,
        variables: { patientId: patientId }
      });

      if (data && data.data && data.data.getPatientBucket) {
        setSelectedPatientInfo(data.data.getPatientBucket);
        props.history.push('/patient-portal', {
          searchType: currentStage,
          redirect2Referral: true
        });
      } else {
        setDialogOption({
          title: 'Work In Progress: Get Patient',
          message: 'No Patient Record Found',
          showDialog: true
        });
      }
    } catch (err) {
      console.log('WorkInProgress::getPatientBucketData err:', err);
    }
  };

  return (
    <>
      <div className='container-fluid'>
        {dialogOption.showDialog && (
          <MessageDialog dialogOption={dialogOption} />
        )}
        <div className='row my-3'>
          <div className='col-12 work-in-progress '>
            <div className='row'>
              <div className='col-11'></div>
              <div className='col-1 bright'>
                <Button
                  style={{ marginLeft: '-18px', color: '#005282' }}
                  look='flat'
                  onClick={exportExcel}
                >
                  <i className='fa-solid fa-file-excel '>&nbsp;&nbsp;</i>
                  Export to Excel
                </Button>
              </div>
            </div>
          </div>
          <div className='col-12 col-lg-12 work-in-progress'>
            <div className='a-grid__header'>
              <div>Clinician Queue</div>
            </div>
            <Preloader show={loading}>
              <ExcelExport data={resultState.data} ref={_exporter}>
                <Grid
                  className='nurse-queue_grid'
                  data={{ data: resultState.data }}
                  onDataStateChange={onDataStateChange}
                  sortable
                  onRowClick={(e) => {
                    onRowClickHandle(e);
                  }}
                  style={{ cursor: 'pointer' }}
                  {...dataState}
                >
                  {columns.map((column, index) => {
                    return (
                      <Column
                        field={column.field}
                        title={column.title}
                        key={index}
                        width={column.minWidth}
                        columnMenu={ColumnMenu}
                        cell={column.cell}
                      />
                    );
                  })}
                </Grid>
              </ExcelExport>
            </Preloader>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(WorkInProgress);
