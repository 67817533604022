// util
import { formatPrescriberAddress } from '../Prescriber/prescriberHelper';
import { productLabNames, referralType } from './ReferralMaps';
import { formatDateToAWS } from '@/common/DateHelper';

export const getOfficeAddresses = (event) => {
  const officeAddresses = event.value?.officeAddresses;

  officeAddresses.map((address) => ({
    value: address.id,
    text: formatPrescriberAddress(address),
    default: officeAddresses.length > 1 ? false : address.default
  }));
  return officeAddresses;
};

export const getItemAdmin = (admins) => {
  const itemAdmin = admins.some((workItem) => !workItem?.adminSequenceNumber)
    ? admins?.map((item, index) => {
        item.calcDosage = item.calcDosage || 0;
        item.adminSequenceNumber = index + 1;
        return item;
      })
    : admins;
  return itemAdmin;
};

export const getReferralType = (orderName) => {
  if (orderName === productLabNames.LAB_DRAW_NAME) {
    return referralType.LAB_DRAW;
  }
  return referralType.INFUSION;
};

// Referral Id format of lab draw: P-LAB-DRAW <order date>. The format of sample order date 2023-03-14
export const getReferralId = (dataItem, orderFormData) => {
  const { orderName } = dataItem;
  if (orderName === productLabNames.LAB_DRAW_NAME) {
    return `P-LAB-DRAW ${formatDateToAWS(dataItem.orderDate)}`;
  }
  return `${orderFormData.productId} ${formatDateToAWS(dataItem.orderDate)}`;
};

export const getDrugNamesAndReferralIds = (drugReferrals) => {
  const drugNames = drugReferrals?.map((referral) => referral?.drugName);
  const referralIds = drugReferrals?.map((referral) => referral?.referralId);
  const combinedReferralList = drugReferrals?.map((referral) => ({
    drugName: referral.drugName,
    referralId: referral.referralId
  }));
  return { drugNames, referralIds, combinedReferralList };
};
