import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Drawer from 'rc-drawer';

// kendo
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { DatePicker } from '@progress/kendo-react-dateinputs';

// components
import AwesomeLabel from '@/components/common-components/AwesomeLabel';
import FormButton from '@/components/common-components/Form/FormButton';
import AwesomeLink from '@/components/common-components/AwesomeLink';
import PatientDocument from '@/components/Patient/PatientDocument';
import Preloader from '@/components/common-components/Preloader';
import RequiredFormLabel from '@/components/common-components/RequiredFormLabel';
import Alert from '@/components/common-components/Alert';
import AutoFilter from '@/common/AutoFilter';

// context
import {
  WorkItemContext,
  PatientContext,
  UserContext,
  LogContext,
  ProductContext,
  InfoCodeContext,
  NotifContext
} from '@/context';

// utils
import { getS3path } from '@/common/exportHelper';
import {
  getReferralId,
  getReferralType
} from '@/components/Referral/ReferralHelpers';
import { formatDateToAWS } from '@/common/DateHelper';

// gql
import { createReferralOrder } from '@/graphql/mutations';
import { connectToGraphqlAPI } from '@/provider';

const DrawerContent = styled.div`
  padding: 15px;
`;

const DrawerActions = styled.span`
  font-size: 115%;
`;

const DataRow = styled.div`
  padding: 5px 0;
`;

const ConfirmAction = styled.div`
  text-align: right;
`;

const DropDownListWrapper = styled(DropDownList)`
  width: 100%;
  margin-bottom: 10px;
`;

const AutoFilterWrapper = styled(AutoFilter)`
  width: 100%;
  margin-bottom: 10px;
`;

const CreatePreOrderDrawer = () => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [docUrl, setDocUrl] = useState(null);
  const [prescriber, setPrescriber] = useState(null);
  const [prescriberAddress, setPrescriberAddress] = useState(null);
  const [orderDate, setOrderDate] = useState(null);
  const [expDate, setExpDate] = useState(null);
  const [product, setProduct] = useState(null);
  const [icd10Code, setIcd10Code] = useState(null);

  const {
    showPreOrderDrawer,
    setShowPreOrderDrawer,
    selectedWorkItem,
    releaseWorkItem,
    deletePendingWorkItem,
    updateLastCompleted
  } = useContext(WorkItemContext);
  const { hasPrescribers, patientPrescribers, selectedPatientInfo } =
    useContext(PatientContext);
  const { agentId } = useContext(UserContext);
  const { logApiException } = useContext(LogContext);
  const { productsUnique } = useContext(ProductContext);
  const { dxCodes } = useContext(InfoCodeContext);
  const { showError } = useContext(NotifContext);

  const generatePath = useCallback(async (path) => {
    if (path) {
      const s3Path = await getS3path(path);
      setDocUrl(s3Path);
    }
  });

  useEffect(() => {
    generatePath(selectedWorkItem.attachedData.documentPath);
  }, [selectedWorkItem.attachedData.documentPath]);

  if (!showPreOrderDrawer) return null;

  const { attachedData, documentPath, receivedAt } =
    selectedWorkItem.attachedData;
  const hasProduct = !!attachedData?.productName;
  const hasDxCode = !!attachedData?.dxCode;

  const onFormSubmit = async () => {
    if (!isDisabled) {
      setIsDisabled(true);

      const orderName = hasProduct
        ? attachedData.productName
        : product?.productOnSelector;
      const productId = hasProduct
        ? attachedData.productId
        : product?.productId;

      if (!orderName || !productId) {
        alert('Please select a product');
        setIsDisabled(false);
        return;
      }

      const dxCode = hasDxCode ? attachedData.dxCode : icd10Code;

      if (!dxCode) {
        alert('Please select a DX code');
        setIsDisabled(false);
        return;
      }

      const [code, description] = dxCode.split(' - ');
      if (!code || !description) {
        alert('Please enter valid DX code');
        setIsDisabled(false);
        return;
      }

      if (!prescriber) {
        alert('Please select prescriber');
        setIsDisabled(false);
        return;
      }

      if (!prescriberAddress) {
        alert('Please select prescriber office address');
        setIsDisabled(false);
        return;
      }

      if (!orderDate) {
        alert('Please provide order date');
        setIsDisabled(false);
        return;
      }

      if (!expDate) {
        alert('Please provide order expiration date');
        setIsDisabled(false);
        return;
      }

      // prepare request object
      const input = {
        agentId,
        patientId: selectedPatientInfo.patientId,
        drugReferral: {
          referralType: getReferralType(orderName),
          referralId: getReferralId({ orderName, orderDate }, { productId }),
          drugId: productId,
          drugName: orderName,
          isCompleted: false,
          originalReceivedDate: receivedAt,
          prescriberId: prescriber.id,
          prescriberOfficeAddressId: prescriberAddress?.id || 0,
          noOfTreatments: 0,
          rxOrderFile: documentPath,
          referralOrder: {
            orderName: orderName.toUpperCase(),
            orderDate: formatDateToAWS(orderDate),
            orderExpires: formatDateToAWS(expDate),
            primaryDX: {
              primaryDiagnosis: code,
              description: description,
              diagnosedBy: ''
            },
            otherDX: [],
            preMedications: [],
            administrations: []
          }
        }
      };

      // API call
      try {
        const data = await connectToGraphqlAPI({
          graphqlQuery: createReferralOrder,
          variables: { input }
        });
        if (data?.data?.createReferralOrder) {
          // complete work item
          const data = await releaseWorkItem({
            agentId,
            workItemId: selectedWorkItem.id,
            workStatus: 'COMPLETED'
          });
          if (data[0]?.success) {
            // close drawer
            setShowPreOrderDrawer(false);

            // update current items list (top banner)
            deletePendingWorkItem(selectedWorkItem.id);

            // last completed WI logic
            updateLastCompleted(selectedWorkItem);
          } else if (!data[0]?.success) {
            showError(data[0].details);
          }
        }
      } catch (err) {
        setIsDisabled(false);
        logApiException(err, {
          view: 'CreatePreOrderDrawer',
          endpoint: 'createReferralOrder',
          input
        });
        showError(err.errors[0].message);
      }
    }
  };

  const render = {
    preOrderForm: () => (
      <>
        {!hasProduct && (
          <div className='row'>
            <div className='col-md-12'>
              <DataRow>
                <RequiredFormLabel text='Product Name' />
              </DataRow>
              <DropDownListWrapper
                data={productsUnique}
                textField='productOnSelector'
                dataItemKey='productId'
                value={product}
                onChange={(e) => setProduct(e.target.value)}
              />
            </div>
          </div>
        )}
        {!hasDxCode && (
          <div className='row'>
            <div className='col-md-12'>
              <DataRow>
                <RequiredFormLabel text='ICD10 Code' />
              </DataRow>
              <AutoFilterWrapper
                data={dxCodes}
                textField='codeDescription'
                valueField='code'
                onChangeCallback={(value) => setIcd10Code(value)}
              />
            </div>
          </div>
        )}
        <div className='row'>
          <div className='col-md-12'>
            <DataRow>
              <RequiredFormLabel text='Prescribing HCP' />
            </DataRow>
            <DropDownListWrapper
              data={patientPrescribers}
              textField='fullName'
              dataItemKey='id'
              value={prescriber}
              onChange={(e) => setPrescriber(e.target.value)}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <DataRow>
              <RequiredFormLabel text='Prescriber office address' />
            </DataRow>
            <DropDownListWrapper
              data={prescriber?.officeAddresses}
              textField='addressTitle'
              dataItemKey='id'
              value={prescriberAddress}
              onChange={(e) => setPrescriberAddress(e.target.value)}
              disabled={!prescriber}
            />
          </div>
        </div>
        <DataRow>
          <div className='row'>
            <div className='col-md-6'>
              <RequiredFormLabel text='Order Date' />
              <DatePicker
                placeholder='mm/dd/yyyy'
                onChange={(e) => {
                  const orderDate = e.target.value;
                  setOrderDate(orderDate);

                  // set exp date as 1 year from order date
                  const expDate = new Date(orderDate);
                  expDate.setFullYear(expDate.getFullYear() + 1);
                  setExpDate(expDate);
                }}
                value={orderDate}
              />
            </div>
            <div className='col-md-6'>
              <RequiredFormLabel text='Expiration Date' />
              <DatePicker
                placeholder='mm/dd/yyyy'
                onChange={(e) => setExpDate(e.target.value)}
                value={expDate}
              />
            </div>
          </div>
        </DataRow>
      </>
    ),
    noPrescriberPlaceholder: () => (
      <Alert>
        Need to attach prescriber to patient before performing this task.
      </Alert>
    )
  };

  const patientHasPrescribers = hasPrescribers();

  return (
    <Drawer
      width={500}
      open={showPreOrderDrawer}
      onClose={() => {
        setShowPreOrderDrawer(false);
      }}
      push={{ distance: 700 }}
    >
      <DrawerContent>
        <h5>Create Pre-Order</h5>
        <p>
          Click "Back" (or just outside of this modal) to return to the main
          screen.
        </p>
        <hr />
        <DataRow>
          <AwesomeLabel
            icon='user'
            label={`${selectedWorkItem.patientFirstName} ${selectedWorkItem.patientLastName}`}
            title='Patient'
          />
        </DataRow>
        {hasProduct && (
          <DataRow>
            <AwesomeLabel
              icon='pills'
              label={attachedData.productName}
              title='Product'
            />
          </DataRow>
        )}
        {hasDxCode && (
          <DataRow>
            <AwesomeLabel
              icon='hashtag'
              label={attachedData.dxCode}
              title='DX Code'
            />
          </DataRow>
        )}
        <DataRow>
          <AwesomeLink
            onClick={() => {
              setShowPreview(true);
            }}
            icon='magnifying-glass'
            label='Original RX order'
          />
        </DataRow>
        <hr />
        <Preloader
          show={selectedPatientInfo.patientId !== selectedWorkItem.patientId}
        >
          {!!selectedPatientInfo.patientId &&
            !patientHasPrescribers &&
            render.noPrescriberPlaceholder()}
          {patientHasPrescribers && render.preOrderForm()}
          <hr />
          {patientHasPrescribers && (
            <div className='row'>
              <div className='col-6'>
                <DrawerActions
                  className='blue-label'
                  onClick={() => setShowPreOrderDrawer(false)}
                >
                  <AwesomeLabel icon='backward' label='Back' />
                </DrawerActions>
              </div>
              <ConfirmAction className='col-6'>
                <FormButton
                  className='k-button blue'
                  loading={isDisabled}
                  label='Create Pre-Order'
                  onClick={onFormSubmit}
                />
              </ConfirmAction>
            </div>
          )}
        </Preloader>
      </DrawerContent>
      <Drawer
        width={700}
        open={showPreview}
        onClose={() => {
          setShowPreview(false);
        }}
      >
        <DrawerContent>
          <h5>Doc Preview</h5>
          <PatientDocument file={docUrl} />
        </DrawerContent>
      </Drawer>
    </Drawer>
  );
};

export default CreatePreOrderDrawer;

DrawerContent.displayName = 'DrawerContent';
DrawerActions.displayName = 'DrawerActions';
DataRow.displayName = 'DataRow';
ConfirmAction.displayName = 'ConfirmAction';
DropDownListWrapper.displayName = 'DropDownListWrapper';
AutoFilterWrapper.displayName = 'AutoFilterWrapper';
