import React, { useEffect, useState } from 'react';

import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Input, Switch } from '@progress/kendo-react-inputs';
import { Button, ButtonGroup } from '@progress/kendo-react-buttons';
import { Form, Field } from '@progress/kendo-react-form';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import DeleteButton from '@/components/common-components/Form/DeleteButton';

import {
  DatePickerField,
  DropDownListField,
  InputField,
  TimePickerField,
  validateInput,
  validateNumeric
} from '@/common/Validation';
import PreMeds from '@/common/PreMeds';
import YesNoCell from '@/components/Grid/YesNoCell';
import { isObjectEmpty } from '@/components/Infusion/infusionHelper';
// import { restoreNotes } from "@/components/Infusion/infusionHelper";
// import RevisitPreTreatment from "@/components/Infusion/Revisit/RevisitPreTreatment";

import { MessageDialog } from '@/components/common-components/MessageDialog';

import { connectToGraphqlAPI } from '@/provider';
import { getNursingProcess } from '@/graphql/queries';

import { Constants } from '@/constants';

import * as moment from 'moment';
import {
  formatDateToAWSDateTime,
  formatDateToDefault,
  formatDateToAWS,
  formatTimeToDefault
} from '@/common/DateHelper';

const PreTreatment = (props) => {
  const infusion = props.infusion;
  const nursingProcessId = props.nursingProcessId;
  // const infusionFormData = props.infusionFormData;

  const [nursingProcess, setNursingProcess] = useState({});
  const [apiPreMeds, setApiPreMeds] = useState([]);
  const [itemPreMeds, setItemPreMeds] = useState([]);
  const [apiPIV1s, setApiPIV1s] = useState([]);
  const [itemPIV1s, setItemPIV1s] = useState([]);
  const [apiPICCs, setApiPICCs] = useState([]);
  const [itemPICCs, setItemPICCs] = useState([]);
  const [apiPORTs, setApiPORTs] = useState([]);
  const [itemPORTs, setItemPORTs] = useState([]);
  const [apiLineFlushes, setApiLineFlushes] = useState([]);
  const [itemLineFlushes, setItemLineFlushes] = useState([]);

  const [stepAllowed, setStepAllowed] = useState(false);
  const [dialogOption, setDialogOption] = useState({});

  const administeredLocations = ['Home', 'Site'];
  const piv1Types = ['IV Insertion', 'IV Discontinued'];
  const lineFlushTypes = [
    'D-5',
    'HCP 10 ML',
    'HCP 100 ML',
    'Heparin',
    'Saline'
  ];
  const [activeButtonG, setActiveButtonG] = useState('Piv1');

  const onButtonGroupToggle = (value) => {
    setActiveButtonG(value);
  };

  // MAIN INITIATOR
  useEffect(() => {
    getNursingProcessCall(nursingProcessId);
  }, []);

  useEffect(() => {
    handleLoadInfusion();
  }, [nursingProcess]);

  const getNursingProcessCall = async (id) => {
    let npid = id;
    if (!npid) {
      npid = '';
    }
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getNursingProcess,
        variables: { id: npid }
      });
      // STEP 1: data collection from existing record
      if (data && data.data && data.data.getNursingProcess) {
        setNursingProcess(data.data.getNursingProcess);
      } else {
        handleLoadInfusion();
      }
    } catch (err) {
      console.log('marty getNursingProcessCall err', err);
      alert('ERROR: getNursingProcessCall');
    }
  };

  const handleLoadInfusion = () => {
    if (nursingProcess && nursingProcess.stepReview) {
      if (nursingProcess.stepReview.orderApproved) {
        setStepAllowed(true);
      }
    }

    if (
      nursingProcess &&
      nursingProcess.stepPreTreatment &&
      nursingProcess.stepPreTreatment.preMedications
    ) {
      try {
        let a = [];
        let i = [];
        nursingProcess.stepPreTreatment.preMedications.map((item) => {
          let apiPreMed = {
            recordNumber: 1,
            time: moment(new Date(item.time)),
            medication: item.medication,
            dosing: item.dosing,
            administeredLocation: item.administeredLocation,
            dosage: item.dosage,
            unitOfDosage: item.unitOfDosage,
            lot: item.lot,
            expiration: item.expiration
              ? moment(new Date(item.expiration)).format('YYYY-MM-DD')
              : undefined,
            //expiration: item.expiration ? formatDateToDefault(item.expiration) : '',
            initials: item.initials
          };
          a.push(apiPreMed);
          let itemPreMed = {
            time: moment(new Date(item.time)).format('hh:mm A'),
            medication: item.medication,
            dose: item.dosing,
            administeredLocation: item.administeredLocation,
            dosage: item.dosage,
            lot: item.lot,
            expiration: item.expiration
              ? formatDateToDefault(item.expiration)
              : undefined,
            //  expiration: formatDateToDefault(item.expiration),
            initials: item.initials
          };
          i.push(itemPreMed);
        });
        setApiPreMeds([...a]);
        setItemPreMeds([...i]);
      } catch (err) {
        console.log('marty nursingProcess preMedications err', err);
        setDialogOption({
          title: 'Infusion: Assessment',
          message: 'Error: nursingProcess preMedications',
          showDialog: true
        });
      }
    }

    if (
      nursingProcess &&
      nursingProcess.stepPreTreatment &&
      nursingProcess.stepPreTreatment.piv
    ) {
      try {
        let a = [];
        let i = [];
        nursingProcess.stepPreTreatment.piv.map((item) => {
          let apiPIV1 = {
            recordNumber: 1,
            status: item.status,
            attempt: item.attempt,
            time: formatDateToAWSDateTime(item.time),
            catheter: item.catheter,
            vein: item.vein,
            location: item.location,
            entered: item.entered
          };

          if (item.ivInsertion) {
            apiPIV1.ivInsertion = formatDateToAWSDateTime(item.ivInsertion);
          }

          if (item.ivDiscontinuedTime) {
            apiPIV1.ivDiscontinuedTime = formatDateToAWSDateTime(
              item.ivDiscontinuedTime
            );
          }

          a.push(apiPIV1);

          let itemPIV1 = {
            piv1Status: item.status,
            piv1Attempt: item.attempt,
            piv1Catheter: item.catheter,
            piv1Vein: item.vein,
            piv1Location: item.location,
            piv1Entered: !!item.entered,
            piv1Initials: item.initials
          };

          if (item.ivInsertion) {
            itemPIV1.piv1Time = formatTimeToDefault(item.ivInsertion);
            itemPIV1.piv1Type = piv1Types[0];
          }

          if (item.ivDiscontinuedTime) {
            itemPIV1.piv1Time = formatTimeToDefault(item.ivDiscontinuedTime);
            itemPIV1.piv1Type = piv1Types[1];
          }

          i.push(itemPIV1);
        });
        setApiPIV1s([...a]);
        setItemPIV1s([...i]);
      } catch (err) {
        console.log('marty nursingProcess PIV1 err', err);
        setDialogOption({
          title: 'Infusion: Assessment',
          message: 'Error: nursingProcess PIV1',
          showDialog: true
        });
      }
    }

    if (
      nursingProcess &&
      nursingProcess.stepPreTreatment &&
      nursingProcess.stepPreTreatment.picc
    ) {
      try {
        let a = [];
        let i = [];
        nursingProcess.stepPreTreatment.picc.map((item) => {
          let apiPICC = {
            recordNumber: 1,
            portLocal: item.portLocal,
            lumens: item.lumens,
            datePlaced: moment(new Date(item.datePlaced)).format('YYYY-MM-DD'),
            armCircle: item.armCircle,
            unitOfArmCircle: item.unitOfArmCircle,
            externalLength: item.externalLength,
            unitOfExternalLength: item.unitOfExternalLength,
            bloodReturned: item.bloodReturned,
            flushed: item.flushed,
            dressingChangedDate: moment(
              new Date(item.dressingChangedDate)
            ).format('YYYY-MM-DD'),
            initials: item.initials,
            time: moment(new Date(item.time))
          };
          a.push(apiPICC);
          let itemPICC = {
            piccPortLocal: item.portLocal,
            piccNumLumens: item.lumens,
            piccDatePlaced: formatDateToDefault(item.datePlaced),
            piccArmCircumferenceCM: item.armCircle,
            piccExternalLengthCM: item.externalLength,
            piccBloodReturn: item.bloodReturned,
            piccFlushed: item.flushed,
            piccDressingChange: formatDateToDefault(item.dressingChangedDate),
            piccInitials: item.initials
          };
          i.push(itemPICC);
        });
        setApiPICCs([...a]);
        setItemPICCs([...i]);
      } catch (err) {
        console.log('marty nursingProcess PICC err', err);
        setDialogOption({
          title: 'Infusion: Assessment',
          message: 'Error: nursingProcess PICC',
          showDialog: true
        });
      }
    }

    if (
      nursingProcess &&
      nursingProcess.stepPreTreatment &&
      nursingProcess.stepPreTreatment.port
    ) {
      try {
        let a = [];
        let i = [];
        nursingProcess.stepPreTreatment.port.map((item) => {
          let apiPORT = {
            recordNumber: 1,
            portLocal: item.portLocal,
            needleLength: item.needleLength,
            unitOfNeedleLength: item.unitOfNeedleLength,
            needleSize: item.needleSize,
            accessDate: moment(new Date(item.accessDate)).format('YYYY-MM-DD'),
            bloodReturned: item.bloodReturned,
            flushed: item.flushed,
            deaccessedPort: moment(new Date(item.deaccessedPort)),
            details: item.details,
            recordTime: moment(new Date(item.recordTime)),
            initials: item.initials
          };
          a.push(apiPORT);
          let itemPORT = {
            portLocal: item.portLocal,
            portNeedleLength: item.needleLength,
            portNeedleSize: item.needleSize,
            portAccessDate: formatDateToDefault(item.accessDate),
            portBloodReturn: item.bloodReturned,
            portFlushed: item.flushed,
            portDeaccessed: formatDateToDefault(item.deaccessedPort),
            portDetails: item.details,
            portInitials: item.initials
          };
          i.push(itemPORT);
        });
        setApiPORTs([...a]);
        setItemPORTs([...i]);
      } catch (err) {
        console.log('marty nursingProcess PORT err', err);
        setDialogOption({
          title: 'Infusion: Assessment',
          message: 'Error: nursingProcess PORT',
          showDialog: true
        });
      }
    }

    if (
      nursingProcess &&
      nursingProcess.stepPreTreatment &&
      nursingProcess.stepPreTreatment.lineFlush
    ) {
      try {
        let a = [];
        let i = [];
        nursingProcess.stepPreTreatment.lineFlush.map((item) => {
          let apiLineFlush = {
            recordNumber: 1,
            ivAccess: item.ivAccess,
            flushType: item.flushType,
            executedBy: item.executedBy,
            flushTime: moment(new Date(item.flushTime))
          };
          a.push(apiLineFlush);
          let itemLineFlush = {
            lineFlushTime: moment(new Date(item.flushTime)).format('hh:mm A'),
            lineFlushIVAccess: item.ivAccess,
            lineFlushType: item.flushType,
            lineFlushInitials: item.executedBy
          };
          i.push(itemLineFlush);
        });
        setApiLineFlushes([...a]);
        setItemLineFlushes([...i]);
      } catch (err) {
        console.log('marty nursingProcess lineFlushes err', err);
        setDialogOption({
          title: 'Infusion: Assessment',
          message: 'Error: nursingProcess lineFlushes',
          showDialog: true
        });
      }
    }
  };

  const validateNumber = (value) =>
    validateNumeric(value) ? '' : Constants.ErrorMessage.Numeric_Required;

  const infusionForm = {
    isPreTreamentComplete: {
      value: nursingProcess.stepPreTreatment?.preTreatmentCompleted
        ? true
        : false,
      inputValidator: (value) => {
        return validateInput({
          isPreTreamentComplete: {
            ...infusionForm.isPreTreamentComplete,
            value
          }
        });
      },
      validations: []
    },
    piccNumLumens: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          piccNumLumens: { ...infusionForm.piccNumLumens, value }
        });
      },
      validations: [
        {
          type: 'onlyNumeric',
          message: Constants.ErrorMessage.Numeric_Required
        }
      ]
    },
    exp: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          exp: { ...infusionForm.exp, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    },
    lot: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          lot: { ...infusionForm.lot, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    },
    initials: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          initials: { ...infusionForm.initials, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    },
    lineFlushInitials: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          lineFlushInitials: { ...infusionForm.lineFlushInitials, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    },
    piccInitials: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          piccInitials: { ...infusionForm.piccInitials, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    },
    piv1Initials: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          piv1Initials: { ...infusionForm.piv1Initials, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    },
    lineFlushType: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          lineFlushType: { ...infusionForm.lineFlushType, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    },
    portInitials: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          portInitials: { ...infusionForm.portInitials, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    },
    administeredLocation: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          administeredLocation: { ...infusionForm.administeredLocation, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    },
    piccDressingChange: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          piccDressingChange: { ...infusionForm.piccDressingChange, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    },
    portAccessDate: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          portAccessDate: { ...infusionForm.portAccessDate, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    },
    piccDatePlaced: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          piccDatePlaced: { ...infusionForm.piccDatePlaced, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    },
    piccArmCircumferenceCM: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          piccArmCircumferenceCM: {
            ...infusionForm.piccArmCircumferenceCM,
            value
          }
        });
      },
      validations: [
        {
          type: 'onlyNumeric',
          message: Constants.ErrorMessage.Numeric_Required
        }
      ]
    },
    piccExternalLengthCM: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          piccExternalLengthCM: { ...infusionForm.piccExternalLengthCM, value }
        });
      },
      validations: [
        {
          type: 'onlyNumeric',
          message: Constants.ErrorMessage.Numeric_Required
        }
      ]
    },
    portNeedleLength: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          portNeedleLength: { ...infusionForm.portNeedleLength, value }
        });
      },
      validations: []
    },
    portNeedleSize: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          portNeedleSize: { ...infusionForm.portNeedleSize, value }
        });
      },
      validations: [
        {
          type: 'onlyNumeric',
          message: Constants.ErrorMessage.Numeric_Required
        }
      ]
    },
    portDeaccessed: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          portDeaccessed: { ...infusionForm.portDeaccessed, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    },
    lineFlushTime: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          lineFlushTime: { ...infusionForm.lineFlushTime, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    },
    piv1Time: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          piv1Time: { ...infusionForm.piv1Time, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    }
  };

  const handleAddPreMed = (dataItem) => {
    const apiPreMed = {
      recordNumber: 1,
      // time: moment(new Date()),
      medication: dataItem.listPreMeds.title,
      dosing: dataItem.dose,
      administeredLocation: dataItem.administeredLocation,
      dosage: 0, //dataItem.dosage,
      unitOfDosage: 'mg', //dataItem.unitOfDosage,
      lot: dataItem.lot,
      expiration: dataItem.exp
        ? moment(new Date(dataItem.exp)).format('YYYY-MM-DD')
        : undefined,
      initials: dataItem.initials,
      time: moment(new Date(dataItem.time))
    };

    const itemPreMed = {
      //   time: moment(new Date()).format("hh:mm A"),
      medication: dataItem.listPreMeds.title,
      dose: dataItem.dose,
      administeredLocation: dataItem.administeredLocation,
      dosage: 0,
      lot: dataItem.lot,
      expiration: dataItem.exp ? formatDateToDefault(dataItem.exp) : '',
      initials: dataItem.initials,
      time: moment(new Date(dataItem.time)).format('hh:mm A')
    };

    try {
      setApiPreMeds([...apiPreMeds, apiPreMed]);
      setItemPreMeds([...itemPreMeds, itemPreMed]);
    } catch (err) {
      console.log('marty handleAddPreMed err', err);
      setDialogOption({
        title: 'Infusion: PreTreatment',
        message: 'Error: handleAddPreMed',
        showDialog: true
      });
    }
  };

  const handleAddPIV1 = (dataItem) => {
    const isInsertion = dataItem.piv1Type === piv1Types[0];
    const ivTime = formatDateToAWSDateTime(dataItem.piv1Time);

    const apiPIV1 = {
      recordNumber: 1,
      status: '',
      attempt: dataItem.piv1Attempt,
      time: formatDateToAWSDateTime(),
      catheter: dataItem.piv1Catheter,
      vein: '',
      location: dataItem.piv1Location,
      entered: !!dataItem.piv1Entered,
      initials: dataItem?.piv1Initials
    };

    if (isInsertion) {
      apiPIV1.ivInsertion = ivTime;
    } else {
      apiPIV1.ivDiscontinuedTime = ivTime;
    }

    const itemPIV1 = {
      piv1Time: formatTimeToDefault(dataItem.piv1Time),
      piv1Attempt: dataItem.piv1Attempt,
      piv1Catheter: dataItem.piv1Catheter,
      piv1Location: dataItem.piv1Location,
      piv1Entered: dataItem.piv1Entered,
      piv1Initials: dataItem?.piv1Initials,
      piv1Type: dataItem.piv1Type
    };

    try {
      setApiPIV1s([...apiPIV1s, apiPIV1]);
      setItemPIV1s([...itemPIV1s, itemPIV1]);
    } catch (err) {
      console.log('marty handleAddPIV1 err', err);
      setDialogOption({
        title: 'Infusion: PreTreatment',
        message: 'Error: handleAddPIV1',
        showDialog: true
      });
    }
  };

  const handleAddPICC = (dataItem) => {
    const apiPICC = {
      recordNumber: 1,
      portLocal: dataItem.piccPortLocal,
      lumens: dataItem.piccNumLumens,
      datePlaced: moment(new Date(dataItem.piccDatePlaced)).format(
        'YYYY-MM-DD'
      ),
      armCircle: dataItem.piccArmCircumferenceCM,
      unitOfArmCircle: 'CM',
      externalLength: dataItem.piccExternalLengthCM,
      unitOfExternalLength: 'CM',
      bloodReturned: dataItem.piccBloodReturn,
      flushed: dataItem.piccFlushed,
      dressingChangedDate: moment(new Date(dataItem.piccDressingChange)).format(
        'YYYY-MM-DD'
      ),
      initials: dataItem.piccInitials,
      time: moment(new Date())
    };

    const itemPICC = {
      piccPortLocal: dataItem.piccPortLocal,
      piccNumLumens: dataItem.piccNumLumens,
      piccDatePlaced: formatDateToDefault(dataItem.piccDatePlaced),
      piccArmCircumferenceCM: dataItem.piccArmCircumferenceCM,
      piccExternalLengthCM: dataItem.piccExternalLengthCM,
      piccBloodReturn: dataItem.piccBloodReturn,
      piccFlushed: dataItem.piccFlushed,
      piccDressingChange: formatDateToDefault(dataItem.piccDressingChange),
      piccInitials: dataItem.piccInitials
    };

    try {
      setApiPICCs([...apiPICCs, apiPICC]);
      setItemPICCs([...itemPICCs, itemPICC]);
    } catch (err) {
      console.log('marty handleAddPICC err', err);
      setDialogOption({
        title: 'Infusion: PreTreatment',
        message: 'Error: handleAddPICC',
        showDialog: true
      });
    }
  };

  const handleAddPORT = (dataItem) => {
    const apiPORT = {
      recordNumber: 1,
      portLocal: dataItem.portLocal,
      needleLength: dataItem.portNeedleLength,
      unitOfNeedleLength: 'CM',
      needleSize: dataItem.portNeedleSize,
      accessDate: formatDateToAWS(dataItem.portAccessDate),
      bloodReturned: dataItem.portBloodReturn,
      flushed: dataItem.portFlushed,
      deaccessedPort: moment(new Date(dataItem.portDeaccessed)),
      details: dataItem.portDetails,
      recordTime: moment(new Date()),
      initials: dataItem.portInitials
    };

    const itemPORT = {
      portLocal: dataItem.portLocal,
      portNeedleLength: dataItem.portNeedleLength,
      portNeedleSize: dataItem.portNeedleSize,
      portAccess: dataItem.portAccess,
      portAccessDate: formatDateToDefault(dataItem.portAccessDate),
      portBloodReturn: dataItem.portBloodReturn,
      portFlushed: dataItem.portFlushed,
      portDeaccessed: formatDateToDefault(dataItem.portDeaccessed),
      portDetails: dataItem.portDetails,
      portInitials: dataItem.portInitials
    };

    try {
      setApiPORTs([...apiPORTs, apiPORT]);
      setItemPORTs([...itemPORTs, itemPORT]);
    } catch (err) {
      console.log('marty handleAddPORT err', err);
      setDialogOption({
        title: 'Infusion: PreTreatment',
        message: 'Error: handleAddPORT',
        showDialog: true
      });
    }
  };

  const handleAddLineFlush = (dataItem) => {
    const flushTime = new Date().setTime(dataItem.lineFlushTime);

    const apiLineFlush = {
      recordNumber: 1,
      ivAccess: dataItem.lineFlushIVAccess,
      flushType: dataItem.lineFlushType,
      executedBy: dataItem.lineFlushInitials,
      flushTime: formatDateToAWSDateTime(flushTime)
    };

    const itemLineFlush = {
      lineFlushTime: moment(dataItem.lineFlushTime).format('hh:mm A'),
      lineFlushIVAccess: dataItem.lineFlushIVAccess, //now qty
      lineFlushType: dataItem.lineFlushType,
      lineFlushInitials: dataItem.lineFlushInitials
    };

    try {
      setApiLineFlushes([...apiLineFlushes, apiLineFlush]);
      setItemLineFlushes([...itemLineFlushes, itemLineFlush]);
    } catch (err) {
      console.log('marty handleAddLineFlush err', err);
      setDialogOption({
        title: 'Infusion: PreTreatment',
        message: 'Error: handleAddLineFlush',
        showDialog: true
      });
    }
  };

  const handleSubmit = (dataItem) => {
    const isTreatmentCompleted = () => {
      if (dataItem && !isObjectEmpty(dataItem.values)) {
        return !!dataItem.values?.isPreTreamentComplete;
      }

      return !!nursingProcess.stepPreTreatment?.preTreatmentCompleted;
    };
    const preTreatmentCompleted = isTreatmentCompleted();
    const requestObject = {
      // STEP 3
      // input UpdateStepPreTreatmentInput {
      // updateStepPreTreatmentInput: {
      // nursingProcessId: ID!
      nursingProcessId:
        nursingProcessId ||
        infusion.updateStepOrderReviewInput.nursingProcessId ||
        '',
      // agentId: ID!
      agentId: infusion.stepCheckInInput.agentId,
      // @NOTE: removed as part of updating step, now the notes live alone
      // notes: [String]
      // notes: restoreNotes(),
      // preMedications: [PreMedicationRecordInput]
      preMedications: apiPreMeds,
      // piv: [PIVRecordInput]
      piv: apiPIV1s,
      // picc: [PICCRecordInput]
      picc: apiPICCs,
      // port: [PortRecordInput]
      port: apiPORTs,
      // lineFlush: [LineFlushRecordInput]
      lineFlush: apiLineFlushes,
      // executedBy: ID!
      executedBy: infusion.stepCheckInInput.agentId,
      // lastUpdatedTime: AWSDateTime
      lastUpdatedTime: formatDateToAWSDateTime(),
      // preTreatmentCompleted: Boolean
      preTreatmentCompleted
      // },
    };

    props.sendDataToParent(requestObject);
  };

  const handleRevisitSubmit = () => {
    const requestObject = {
      // STEP 3
      // input UpdateStepPreTreatmentInput {
      // updateStepPreTreatmentInput: {
      // nursingProcessId: ID!
      nursingProcessId: nursingProcess.id,
      // agentId: ID!
      agentId: nursingProcess.startedBy,
      // @NOTE: removed as part of updating step, now the notes live alone
      // notes: [String]
      // notes: nursingProcess.notes,
      // preMedications: [PreMedicationRecordInput]
      preMedications: apiPreMeds,
      // piv: [PIVRecordInput]
      piv: apiPIV1s,
      // picc: [PICCRecordInput]
      picc: apiPICCs,
      // port: [PortRecordInput]
      port: apiPORTs,
      // lineFlush: [LineFlushRecordInput]
      lineFlush: apiLineFlushes,
      // executedBy: ID!
      executedBy: nursingProcess.startedBy,
      // lastUpdatedTime: AWSDateTime
      lastUpdatedTime: formatDateToAWSDateTime(),
      // preTreatmentCompleted: Boolean
      preTreatmentCompleted: true
      // },
    };

    props.sendDataToParent(requestObject);
  };

  const handleDeleteClick = (props, object) => {
    if (props.dataIndex > -1) {
      if (object === 'premed') {
        if (props.dataIndex > -1) {
          const cloneApiPreMeds = [...apiPreMeds];
          cloneApiPreMeds.splice(props.dataIndex, 1);
          setApiPreMeds(cloneApiPreMeds);
          const cloneItemPreMeds = [...itemPreMeds];
          cloneItemPreMeds.splice(props.dataIndex, 1);
          setItemPreMeds(cloneItemPreMeds);
        }
      }
      if (object === 'piv1') {
        if (props.dataIndex > -1) {
          const cloneApiPIV1s = [...apiPIV1s];
          cloneApiPIV1s.splice(props.dataIndex, 1);
          setApiPIV1s(cloneApiPIV1s);
          const cloneItemPIV1s = [...itemPIV1s];
          cloneItemPIV1s.splice(props.dataIndex, 1);
          setItemPIV1s(cloneItemPIV1s);
        }
      }
      if (object === 'picc') {
        if (props.dataIndex > -1) {
          const cloneApiPICCs = [...apiPICCs];
          cloneApiPICCs.splice(props.dataIndex, 1);
          setApiPICCs(cloneApiPICCs);
          const cloneItemPICCs = [...itemPICCs];
          cloneItemPICCs.splice(props.dataIndex, 1);
          setItemPICCs(cloneItemPICCs);
        }
      }
      if (object === 'port') {
        if (props.dataIndex > -1) {
          const cloneApiPORTs = [...apiPORTs];
          cloneApiPORTs.splice(props.dataIndex, 1);
          setApiPORTs(cloneApiPORTs);
          const cloneItemPORTs = [...itemPORTs];
          cloneItemPORTs.splice(props.dataIndex, 1);
          setItemPORTs(cloneItemPORTs);
        }
      }
      if (object === 'lineflush') {
        if (props.dataIndex > -1) {
          const cloneApiLineFlushes = [...apiLineFlushes];
          cloneApiLineFlushes.splice(props.dataIndex, 1);
          setApiLineFlushes(cloneApiLineFlushes);
          const cloneItemLineFlushes = [...itemLineFlushes];
          cloneItemLineFlushes.splice(props.dataIndex, 1);
          setItemLineFlushes(cloneItemLineFlushes);
        }
      }
    }
  };

  const customCellDeletePreMed = (props) => {
    return (
      <td>
        <DeleteButton handleClick={() => handleDeleteClick(props, 'premed')} />
        {/* <button
          type="button"
          className="k-button"
          onClick={() => handleDeleteClick(props, "premed")}
        >
          X
        </button> */}
      </td>
    );
  };

  const pivEnteredCell = (props) => {
    return <YesNoCell boolVal={!!props.dataItem.piv1Entered} />;
  };

  const customCellDeletePIV1 = (props) => {
    return (
      <td>
        <DeleteButton handleClick={() => handleDeleteClick(props, 'piv1')} />
        {/* <button
          type="button"
          className="k-button"
          onClick={() => handleDeleteClick(props, "piv1")}
        >
          X
        </button> */}
      </td>
    );
  };

  const customCellDeletePICC = (props) => {
    return (
      <td>
        <DeleteButton handleClick={() => handleDeleteClick(props, 'picc')} />
        {/* <button
          type="button"
          className="k-button"
          onClick={() => handleDeleteClick(props, "picc")}
        >
          X
        </button> */}
      </td>
    );
  };

  const customCellDeletePORT = (props) => {
    return (
      <td>
        <DeleteButton handleClick={() => handleDeleteClick(props, 'port')} />
        {/* <button
          type="button"
          className="k-button"
          onClick={() => handleDeleteClick(props, "port")}
        >
          X
        </button> */}
      </td>
    );
  };

  const customCellDeleteLineFlush = (props) => {
    return (
      <td>
        <DeleteButton
          handleClick={() => handleDeleteClick(props, 'lineflush')}
        />
        {/* <button
          type="button"
          className="k-button"
          onClick={() => handleDeleteClick(props, "lineflush")}
        >
          X
        </button> */}
      </td>
    );
  };

  const renderGrid = () => {
    if (activeButtonG === 'Piv1') {
      return (
        <Form
          id='formAddPIV1'
          name='formAddPIV1'
          onSubmit={handleAddPIV1}
          render={(formRenderProps) => (
            <form
              onSubmit={formRenderProps.onSubmit}
              className={'k-form pl-3 pr-3 pt-1'}
            >
              <div>
                <div className='row mt-1'>
                  <div className='col-md-2'>
                    <Field
                      component={TimePickerField}
                      name='piv1Time'
                      label='Time'
                      validator={infusionForm.piv1Time.inputValidator}
                    />
                  </div>
                  <div className='col-md-2'>
                    <Field
                      component={DropDownListField}
                      data={piv1Types}
                      name='piv1Type'
                      label='Type'
                      validator={infusionForm.piv1Time.inputValidator}
                    />
                  </div>
                  <div className='col-md-1'>
                    <Field
                      component={Input}
                      name='piv1Attempt'
                      label='Attempt'
                    />
                  </div>
                  <div className='col-md-1'>
                    <Field
                      component={Input}
                      name='piv1Catheter'
                      label='Catheter'
                      validator={validateNumber}
                    />
                  </div>
                  <div className='col-md-2'>
                    <Field
                      component={Input}
                      name='piv1Location'
                      label='Location'
                    />
                  </div>
                  <div className='col-md-1'>
                    IV Entered
                    <br />
                    <Field
                      component={Switch}
                      name='piv1Entered'
                      label=''
                      onLabel='Yes'
                      offLabel='No'
                    />
                  </div>
                  <div className='col-md-1'>
                    <Field
                      component={InputField}
                      name='piv1Initials'
                      label='Initials'
                      validator={infusionForm.piv1Initials.inputValidator}
                    />
                  </div>
                  <div className='col-md-1 mt-12'>
                    <button type='submit' className='k-button blue'>
                      ADD
                    </button>
                  </div>
                </div>

                <Grid className='infusion-grid mt-1 mb-2' data={itemPIV1s}>
                  <Column field='piv1Time' title='TIME' />
                  <Column field='piv1Type' title='TYPE' />
                  <Column field='piv1Attempt' title='ATTEMPT' />
                  <Column field='piv1Catheter' title='CATHETER' />
                  <Column field='piv1Location' title='LOCATION' />
                  <Column
                    field='piv1Entered'
                    title='ENTERED'
                    cell={pivEnteredCell}
                  />
                  <Column field='piv1Initials' title='INITIALS' />
                  <Column
                    field='action'
                    title=' '
                    cell={customCellDeletePIV1}
                  />
                </Grid>
              </div>
            </form>
          )}
        />
      );
    } else if (activeButtonG === 'Picc' || activeButtonG === 'PORT') {
      return (
        <div>
          <div style={{ display: activeButtonG === 'Picc' ? 'block' : 'none' }}>
            <Form
              id={'formAddPICC'}
              name={'formAddPICC'}
              onSubmit={handleAddPICC}
              render={(formRenderProps) => (
                <form
                  onSubmit={formRenderProps.onSubmit}
                  className={'k-form pl-3 pr-3 pt-1'}
                >
                  <div>
                    <div className='row col-12 mt-1'>
                      <div className='col-md-2 ml-1'>
                        <Field
                          component={Input}
                          name={'piccPortLocal'}
                          label={'Location'}
                        />
                      </div>
                      <div className='col-md-2'>
                        <Field
                          component={InputField}
                          name={'piccNumLumens'}
                          label={'# Lumens'}
                          validator={infusionForm.piccNumLumens.inputValidator}
                        />
                      </div>
                      <div className='col-md-2'>
                        Date Placed
                        <br />
                        <Field
                          component={DatePickerField}
                          name={'piccDatePlaced'}
                          label={''}
                          validator={infusionForm.piccDatePlaced.inputValidator}
                        />
                      </div>
                      <div className='col-md-2'>
                        <Field
                          component={InputField}
                          name={'piccArmCircumferenceCM'}
                          label={'Arm Circumference (cm)'}
                          validator={
                            infusionForm.piccArmCircumferenceCM.inputValidator
                          }
                        />
                      </div>
                      <div className='col-md-3'>
                        <Field
                          component={InputField}
                          name={'piccExternalLengthCM'}
                          label={'External Length (cm)'}
                          validator={
                            infusionForm.piccExternalLengthCM.inputValidator
                          }
                        />
                      </div>
                    </div>
                    <div className='row col-12 mb-3'>
                      <div className='col-md-2 ml-1 mt-3'>
                        Blood Return
                        <br />
                        <Field
                          component={Switch}
                          name={'piccBloodReturn'}
                          label={''}
                          onLabel={'Yes'}
                          offLabel={'No'}
                        />
                      </div>
                      <div className='col-md-2  mt-3'>
                        Flushed w/o Resistance
                        <br />
                        <Field
                          component={Switch}
                          name={'piccFlushed'}
                          label={''}
                          onLabel={'Yes'}
                          offLabel={'No'}
                        />
                      </div>
                      <div className='col-md-2'>
                        Dressing Changed
                        <br />
                        <Field
                          component={DatePickerField}
                          name={'piccDressingChange'}
                          label={''}
                          validator={
                            infusionForm.piccDressingChange.inputValidator
                          }
                        />
                      </div>

                      <div className='col-md-2'>
                        <Field
                          component={InputField}
                          name={'piccInitials'}
                          label={'Initials'}
                          validator={infusionForm.piccInitials.inputValidator}
                        />
                      </div>
                      <div className='col-md-2 mt-3 text-right'>
                        <button type='submit' className='k-button blue'>
                          ADD
                        </button>
                      </div>
                    </div>

                    <Grid className='infusion-grid mt-1 mb-2' data={itemPICCs}>
                      <Column
                        field='piccPortLocal'
                        title='LOCATION'
                        width='100px'
                      />
                      <Column
                        field='piccNumLumens'
                        title='LUMENS'
                        width='100px'
                      />
                      <Column
                        field='piccDatePlaced'
                        title='DATE PLACED'
                        width='100px'
                      />
                      <Column
                        field='piccArmCircumferenceCM'
                        title='ARM CIRC'
                        width='100px'
                      />
                      <Column
                        field='piccExternalLengthCM'
                        title='EXT LENGTH'
                        width='100px'
                      />
                      <Column
                        field='piccBloodReturn'
                        title='BLOOD RETURN'
                        width='125px'
                      />
                      <Column
                        field='piccFlushed'
                        title='FLUSHED'
                        width='100px'
                      />
                      <Column
                        field='piccDressingChange'
                        title='DRESSING CHANGE'
                        width='200px'
                      />
                      <Column
                        field='piccInitials'
                        title='INITIALS'
                        width='100px'
                      />
                      <Column
                        field='action'
                        title=' '
                        cell={customCellDeletePICC}
                      />
                    </Grid>
                  </div>
                </form>
              )}
            />
          </div>
          <div style={{ display: activeButtonG === 'PORT' ? 'block' : 'none' }}>
            <Form
              id={'formAddPORT'}
              name={'formAddPORT'}
              onSubmit={handleAddPORT}
              render={(formRenderProps) => (
                <form
                  onSubmit={formRenderProps.onSubmit}
                  className={'k-form pl-3 pr-3 pt-1'}
                >
                  <div>
                    <div className='row col-12 mt-1'>
                      <div className='col-md-2 ml-1'>
                        <Field
                          component={Input}
                          name={'portLocal'}
                          label={'Local'}
                        />
                      </div>
                      <div className='col-md-2 ml-1'>
                        <Field
                          component={InputField}
                          name={'portNeedleLength'}
                          label={'Needle Length'}
                          validator={
                            infusionForm.portNeedleLength.inputValidator
                          }
                        />
                      </div>
                      <div className='col-md-2 ml-1'>
                        <Field
                          component={InputField}
                          name={'portNeedleSize'}
                          label={'Needle Size'}
                          validator={infusionForm.portNeedleSize.inputValidator}
                        />
                      </div>
                    </div>
                    <div className='row col-12'>
                      <div className='col-md-3 mt-12 ml-1'>
                        Date Accessed Port
                        <br />
                        <Field
                          component={DatePickerField}
                          name={'portAccessDate'}
                          label={''}
                          validator={infusionForm.portAccessDate.inputValidator}
                        />
                      </div>
                      <div className='col-md-2 mt-12 ml-1'>
                        Blood Return
                        <br />
                        <Field
                          component={Switch}
                          name={'portBloodReturn'}
                          label={''}
                          onLabel={'Yes'}
                          offLabel={'No'}
                        />
                      </div>
                      <div className='col-md-3 mt-12 ml-1'>
                        Flushed w/o Resistance
                        <br />
                        <Field
                          component={Switch}
                          name={'portFlushed'}
                          label={''}
                          onLabel={'Yes'}
                          offLabel={'No'}
                        />
                      </div>
                      <div className='col-md-3 mt-12 ml-1'>
                        Time Deaccessed Port
                        <br />
                        {/* Capture Time Stamp if checked */}
                        <Field
                          component={TimePickerField}
                          name={'portDeaccessed'}
                          label={''}
                          validator={infusionForm.portDeaccessed.inputValidator}
                        />
                      </div>
                    </div>
                    <div className='row col-12 mb-3'>
                      <div className='col-md-6 ml-1'>
                        <Field
                          component={Input}
                          name={'portDetails'}
                          label={'Details'}
                        />
                      </div>
                      <div className='col-md-1'>
                        <Field
                          component={InputField}
                          name={'portInitials'}
                          label={'Initials'}
                          validator={infusionForm.portInitials.inputValidator}
                        />
                      </div>
                      <div className='col-md-1 mt-3'>
                        <button type='submit' className='k-button blue'>
                          ADD
                        </button>
                      </div>
                    </div>

                    <Grid className='infusion-grid mt-1 mb-2' data={itemPORTs}>
                      <Column
                        field='portLocal'
                        title='PORT LOCAL'
                        width='100px'
                      />
                      <Column
                        field='portNeedleLength'
                        title='NEEDLE LENGTH'
                        width='100px'
                      />
                      <Column
                        field='portNeedleSize'
                        title='NEEDLE SIZE'
                        width='100px'
                      />
                      <Column field='portAccess' title='ACCESS' width='100px' />
                      <Column
                        field='portAccessDate'
                        title='ACCESS DATE'
                        width='125px'
                      />
                      <Column
                        field='portBloodReturn'
                        title='BLOOD RETURN'
                        width='100px'
                      />
                      <Column
                        field='portFlushed'
                        title='FLUSHED'
                        width='100px'
                      />
                      <Column
                        field='portDeaccessed'
                        title='DEACCESSED'
                        width='100px'
                      />
                      <Column
                        field='portDetails'
                        title='DETAILS'
                        width='200px'
                      />
                      <Column
                        field='portInitials'
                        title='INITIALS'
                        width='100px'
                      />
                      <Column
                        field='action'
                        title=' '
                        cell={customCellDeletePORT}
                        width='60px'
                      />
                    </Grid>
                  </div>
                </form>
              )}
            />
          </div>
        </div>
      );
    }
  };

  // if (props.infusionCompleted) {
  //   return (
  //     <RevisitPreTreatment
  //       onSubmit={handleRevisitSubmit}
  //       handleAddPreMed={handleAddPreMed}
  //       itemPreMeds={itemPreMeds}
  //       removePreMed={customCellDeletePreMed}
  //       itemPIV1s={itemPIV1s}
  //       itemPICCs={itemPICCs}
  //       itemPORTs={itemPORTs}
  //       itemLineFlushes={itemLineFlushes}
  //     />
  //   );
  // }

  return (
    <div className='infusion-page'>
      {dialogOption && dialogOption.showDialog && (
        <MessageDialog dialogOption={dialogOption} />
      )}

      {stepAllowed && (
        <>
          <Form
            onSubmit={handleAddPreMed}
            render={(formRenderProps) => (
              <form
                onSubmit={formRenderProps.onSubmit}
                className={'k-form pl-3 pr-3 pt-1'}
              >
                {/* PRE-MEDICATION */}
                <div
                  className='col-md-11 mt-2 mb-3'
                  style={{
                    border: '1px solid #afaaaa',
                    backgroundColor: '#ffffff'
                  }}
                >
                  <div className='row'>
                    <div className='infusion-HeaderRow col-12 ml-0 pl-2 py-2 mr-0'>
                      <div className='row'>
                        <div className='col-md-2 headerText'>
                          PRE MEDICATION
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-2'>
                      <Field
                        component={DropDownListField}
                        name={'listPreMeds'}
                        label={'Pre-Medications'}
                        data={PreMeds}
                        textField={'title'}
                        valueField={'drugName'}
                        validator={infusionForm.exp.inputValidator}
                      />
                    </div>
                    <div className='col-md-1'>
                      <Field
                        component={InputField}
                        name={'dose'}
                        label={'Dose'}
                        validator={infusionForm.exp.inputValidator}
                      />
                    </div>
                    <div className='col-md-2'>
                      <Field
                        component={DropDownListField}
                        data={administeredLocations}
                        name={'administeredLocation'}
                        label={'Admin Location'}
                        validator={infusionForm.exp.inputValidator}
                      />
                    </div>
                    <div className='col-md-2 '>
                      <Field
                        component={TimePickerField}
                        name='time'
                        label='Time'
                        validator={infusionForm.exp.inputValidator}
                      />
                    </div>
                    <div className='col-md-1'>
                      <Field
                        component={InputField}
                        name={'lot'}
                        label={'Lot'}
                        validator={
                          formRenderProps.valueGetter(
                            'administeredLocation'
                          ) === 'Site'
                            ? infusionForm.lot.inputValidator
                            : undefined
                        }
                      />
                    </div>
                    <div className='col-md-2 '>
                      <Field
                        component={DatePickerField}
                        name={'exp'}
                        label={'Exp Date'}
                        validator={
                          formRenderProps.valueGetter(
                            'administeredLocation'
                          ) === 'Site'
                            ? infusionForm.exp.inputValidator
                            : undefined
                        }
                      />
                    </div>
                    <div className='col-md-1'>
                      <Field
                        component={InputField}
                        name={'initials'}
                        label={'Initials'}
                        validator={infusionForm.initials.inputValidator}
                      />
                    </div>
                    <div className='col-md-1 py-3 mt-1'>
                      <button type='submit' className='k-button blue'>
                        ADD
                      </button>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-12 mt-1 mb-2'>
                      <Grid className='infusion-grid' data={itemPreMeds}>
                        <Column field='time' title='TIME' width='100px' />
                        <Column
                          field='medication'
                          title='PRE-MED'
                          width='240px'
                        />
                        <Column field='dose' title='DOSE' width='100px' />
                        <Column
                          field='administeredLocation'
                          title='ADMIN LOCATION'
                          width='150px'
                        />
                        <Column field='lot' title='LOT' width='200px' />
                        <Column field='expiration' title='EXP' width='120px' />
                        <Column
                          field='initials'
                          title='INITIALS'
                          width='100px'
                        />
                        <Column
                          field='action'
                          title=' '
                          cell={customCellDeletePreMed}
                        />
                      </Grid>
                    </div>
                  </div>
                </div>
              </form>
            )}
          />

          {/* TABLE FOR IV's */}

          <div className={'k-form pl-3 pr-3 pt-1'}>
            <div
              className='col-md-11 mt-2 mb-3 mr-0 ml-0 p-0'
              style={{
                border: '1px solid #afaaaa',
                backgroundColor: '#ffffff'
              }}
            >
              <div className='infusion-HeaderRow'>
                <div className='row'>
                  <div className='col-md-9'>
                    <ButtonGroup>
                      <Button
                        type='button'
                        className={
                          activeButtonG === 'Piv1'
                            ? 'gridButton active'
                            : 'gridButton'
                        }
                        togglable={activeButtonG === 'Piv1'}
                        onClick={() => onButtonGroupToggle('Piv1')}
                      >
                        PIV(1)
                      </Button>
                      <Button
                        type='button'
                        className={
                          activeButtonG === 'Picc'
                            ? 'gridButton active'
                            : 'gridButton'
                        }
                        togglable={activeButtonG === 'Picc'}
                        onClick={() => onButtonGroupToggle('Picc')}
                      >
                        PICC/CVC(0)
                      </Button>
                      <Button
                        type='button'
                        className={
                          activeButtonG === 'PORT'
                            ? 'gridButton active'
                            : 'gridButton'
                        }
                        togglable={activeButtonG === 'PORT'}
                        onClick={() => onButtonGroupToggle('PORT')}
                      >
                        PORT(0)
                      </Button>
                    </ButtonGroup>
                  </div>
                  <div className='col-md-3 text-right'></div>
                </div>
              </div>
              {renderGrid()}
            </div>
          </div>
          <Form
            onSubmit={handleAddLineFlush}
            render={(formRenderProps) => (
              <form
                onSubmit={formRenderProps.onSubmit}
                className={'k-form pl-3 pr-3 pt-1'}
              >
                {/* LINE FLUSH */}

                <div
                  className='col-md-11 mt-3 mb-3 ml-1'
                  style={{
                    border: '1px solid #afaaaa',
                    backgroundColor: '#ffffff'
                  }}
                >
                  <div className='row'>
                    <div className='infusion-HeaderRow col-12 ml-0 pl-2 py-2 mr-0'>
                      <div className='row'>
                        <div className='col-md-2 headerText'>LINE FLUSH</div>
                      </div>
                    </div>
                  </div>
                  <div className='row mt-2'>
                    <div className='col-md-2'>
                      <Field
                        component={DropDownList}
                        data={lineFlushTypes}
                        name='lineFlushType'
                        label='Type'
                        validator={infusionForm.lineFlushType.inputValidator}
                      />
                    </div>
                    <div className='col-md-1 ml-1'>
                      <Field
                        component={Input}
                        name='lineFlushIVAccess'
                        label='Qty'
                      />
                    </div>
                    <div className='col-md-2'>
                      Line Flush Time
                      <br />
                      <Field
                        component={TimePickerField}
                        name='lineFlushTime'
                        label=''
                        validator={infusionForm.lineFlushTime.inputValidator}
                      />
                    </div>
                    <div className='col-md-1'>
                      <Field
                        component={InputField}
                        name='lineFlushInitials'
                        label='Initials'
                        validator={
                          infusionForm.lineFlushInitials.inputValidator
                        }
                      />
                    </div>
                    <div className='col-md-1 mt-3 text-right'>
                      <button type='submit' className='k-button blue'>
                        ADD
                      </button>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-md-12 mt-1 mb-2'>
                      <Grid className='infusion-grid' data={itemLineFlushes}>
                        <Column
                          field='lineFlushType'
                          title='TYPE'
                          width='150px'
                        />
                        <Column
                          field='lineFlushIVAccess'
                          title='QTY'
                          width='150px'
                        />
                        <Column
                          field='lineFlushTime'
                          title='LINE FLUSH TIME'
                          width='150px'
                        />
                        <Column
                          field='lineFlushInitials'
                          title='INITIALS'
                          width='150px'
                        />
                        <Column
                          field='action'
                          title=' '
                          cell={customCellDeleteLineFlush}
                        />
                      </Grid>
                    </div>
                  </div>
                </div>
              </form>
            )}
          />

          <Form
            onSubmitClick={handleSubmit}
            render={(formRenderProps) => (
              <form
                onSubmit={formRenderProps.onSubmit}
                className={'k-form pl-3 pr-3 pt-1'}
              >
                <div className='row mt-3'>
                  <div className='col-md-3 ml-1'>
                    {/* can move forward with out Pre-treatment checkbox 
									BUT cannot close Treatement UNTIL BOX IS Checked */}
                    Pre-Treatment Complete: &nbsp;
                    <Field
                      name='isPreTreamentComplete'
                      onLabel='Yes'
                      offLabel='No'
                      component={Switch}
                      defaultChecked={infusionForm.isPreTreamentComplete.value}
                    />
                  </div>
                </div>
                <div className='row mt-5 mb-5 ml-1'>
                  <div className='col-12'>
                    <button type='submit' className='k-button pageButton'>
                      Save
                    </button>
                  </div>
                </div>

                <div style={{ height: '200px' }}>{/* SPACER */}</div>
              </form>
            )}
          />
        </>
      )}
    </div>
  );
};

export default PreTreatment;
