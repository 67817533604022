import React, { useCallback, useState, useContext, useEffect } from 'react';
import * as moment from 'moment';

// kendo
import { Input, RadioGroup, Checkbox } from '@progress/kendo-react-inputs';
import { getter } from '@progress/kendo-react-common';
import { Form, Field } from '@progress/kendo-react-form';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Grid, getSelectedState } from '@progress/kendo-react-grid';
import { GridColumn as Column } from '@progress/kendo-react-grid/dist/npm/GridColumn';
import { Button } from '@progress/kendo-react-buttons';

// components
import { MessageDialog } from '@/components/common-components/MessageDialog';
import RequiredFormLabel from '@/components/common-components/RequiredFormLabel';

// gql
import { connectToGraphqlAPI } from '@/provider';
import { getPatientInsuranceInfo } from '@/graphql/queries';
import { addUpdateInsuranceInfo } from '@/graphql/mutations';

// context
import {
  PatientContext,
  StatusContext,
  UserContext,
  UserRoleTypes,
  PayerContext
} from '@/context';

// utils
import { InputField, validateInput } from '@/common/Validation';
import { MaskedPhoneInput } from '@/common/MaskInput';
import { states } from '@/common/states';
import { formatDateToAWS, formatDateToAWSDateTime } from '@/common/DateHelper';
import { convertToE164 } from '@/common/PhoneNumberConverter';
import { sortByField } from '@/common/SortHelper';
import { Constants } from '@/constants';

const DATA_ITEM_KEY = 'id';
const SELECTED_FIELD = 'selected';
const idGetter = getter(DATA_ITEM_KEY);

const PayorSelectDialog = ({
  title,
  data,
  onClose,
  onAddPayer,
  selectedState,
  setSelectedState
}) => {
  const onSelectionChange = useCallback(
    (event) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState,
        dataItemKey: DATA_ITEM_KEY
      });
      setSelectedState(newSelectedState);
    },
    [selectedState]
  );

  const onRadioSelectionChange = (newId) => {
    setSelectedState({
      [newId]: true
    });
  };

  const CustomSelectedCell = (props) => {
    const { dataItem } = props;
    return (
      <td onClick={() => onRadioSelectionChange(dataItem.id)}>
        <input
          type='radio'
          checked={dataItem.selected}
          value={dataItem.id}
          onChange={() => onRadioSelectionChange(dataItem.id)}
        />
      </td>
    );
  };

  return (
    <Dialog title={title} width={700} onClose={onClose}>
      <Grid
        data={data.map((item) => ({
          ...item,
          [SELECTED_FIELD]: selectedState[idGetter(item)]
        }))}
        style={{
          height: '400px'
        }}
        dataItemKey={DATA_ITEM_KEY}
        selectedField={SELECTED_FIELD}
        selectable={{
          enabled: true,
          drag: false,
          cell: false,
          mode: {
            value: 'single',
            label: 'Single selection mode'
          }
        }}
        onSelectionChange={onSelectionChange}
        className='cell_clickable'
      >
        <Column
          field={SELECTED_FIELD}
          title='SELECT'
          width='50px'
          headerCell={() => null}
          cell={CustomSelectedCell}
        />

        <Column field='planName' title='PLAN NAME' className='cell_clickable' />
      </Grid>
      <div className='row mt-10  d-flex justify-content-center'>
        <div className='col-2 col-md-4 col-sm-6 my-3'>
          <Button className='btn blue w-100' onClick={onAddPayer}>
            OK
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

const PatientInsurance = (props) => {
  const { user, canEdit } = useContext(UserContext);
  const { selectedPatientInfo } = useContext(PatientContext);
  const { payers, getPlansByInsurerName, getPayerById } =
    useContext(PayerContext);

  const [dialogOption, setDialogOption] = useState({});
  const [primaryInsurance, setPrimaryInsurance] = useState(false);
  const [secondaryInsurance, setSecondaryInsurance] = useState(false);
  const [tertiaryInsurance, setTertiaryInsurance] = useState(false);
  const [showTertiaryInsurance, setShowTertiaryInsurance] = useState(false);
  const [insuranceCompanyId, setInsuranceCompanyId] = useState('');
  const [searchTableData, setSearchTableData] = useState([]);
  const [selectedSearchTableState, setSelectedSearchTableState] = useState({});

  const [showPrimaryPayorDialog, setShowPrimaryPayorDialog] = useState(false);
  const [showSecondaryPayorDialog, setShowSecondaryPayorDialog] =
    useState(false);
  const [showTertiaryPayorDialog, setShowTertiaryPayorDialog] = useState(false);

  const [isPatientInsuredState, setIsPatientInsuredState] = useState(false);
  const [insuranceFormData, setInsuranceFormData] = useState({});
  const [showInsuranceForm, setShowInsuranceForm] = useState(false);
  const [hasPrimaryPlan, setPrimaryPlanName] = useState(false);
  const [hasSecondaryPlan, setSecondaryPlanName] = useState(false);
  const [hasTertiaryPlan, setTertiaryPlanName] = useState(false);

  const [selectedPayerPrimary, setSelectedPayerPrimary] = useState({});
  const [selectedPayerSecondary, setSelectedPayerSecondary] = useState({});
  const [selectedPayerTertiary, setSelectedPayerTertiary] = useState({});
  const { statusUpdate, setStatusUpdate } = useContext(StatusContext);

  const isPatientInsuredOptions = [
    { label: 'Yes', value: true, className: 'patient-radio blue' },
    { label: 'No', value: false, className: 'patient-radio blue' }
  ];

  const treatmentCoveredBy = ['Medical', 'Pharmacy', 'Other'];

  const personRelationship = ['SELF', 'SPOUSE', 'CHILD', 'OTHER'];

  // MAIN INITIATOR
  useEffect(() => {
    getPatientInsuranceInfoCall(selectedPatientInfo.patientId);
  }, [statusUpdate, selectedPatientInfo]);

  useEffect(() => {
    if (insuranceFormData.isPatientInsured || isPatientInsuredState) {
      setShowInsuranceForm(true);
    } else {
      setShowInsuranceForm(false);
    }
  }, [insuranceFormData, selectedPatientInfo]);

  useEffect(() => {
    if (isPatientInsuredState) {
      setShowInsuranceForm(true);
    } else {
      setShowInsuranceForm(false);
    }
  }, [isPatientInsuredState, selectedPatientInfo]);

  const getPatientInsuranceInfoCall = async (patientId) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getPatientInsuranceInfo,
        variables: { patientId }
      });

      if (data?.data?.getPatientBucket?.patientProfile?.insuranceInfo) {
        const insuranceInfo =
          data?.data?.getPatientBucket?.patientProfile?.insuranceInfo;
        if (insuranceInfo?.isPatientInsured) {
          setIsPatientInsuredState(true);
        } else {
          setIsPatientInsuredState(false);
        }
        setInsuranceFormData(insuranceInfo);

        if (insuranceInfo?.primary?.insurerId) {
          setPrimaryInsurance(true);
        }
        if (insuranceInfo?.secondary?.insurerId) {
          setSecondaryInsurance(true);
          setShowTertiaryInsurance(true);
        }

        if (insuranceInfo?.tertiary && insuranceInfo?.tertiary?.insurerId) {
          setTertiaryInsurance(true);
        }

        // set selectedPayerPrimary here first, then can change later with selections
        const preSelectedPayerPrimary = {
          insurerId: '',
          insurerName: '',
          planName: '',
          payerId: ''
        };
        if (insuranceInfo.primary) {
          if (insuranceInfo.primary?.insurerId) {
            preSelectedPayerPrimary.insurerId = insuranceInfo.primary.insurerId;
          }
          if (insuranceInfo.primary?.insurerName) {
            preSelectedPayerPrimary.insurerName =
              insuranceInfo.primary.insurerName;
          }
          if (insuranceInfo.primary?.planName) {
            preSelectedPayerPrimary.planName = insuranceInfo.primary.planName;
          }
          if (insuranceInfo.primary?.payerId) {
            preSelectedPayerPrimary.payerId = insuranceInfo.primary.payerId;
          }
        }
        setSelectedPayerPrimary(preSelectedPayerPrimary);

        const preSelectedPayerSecondary = {
          insurerId: '',
          insurerName: '',
          planName: '',
          payerId: ''
        };
        if (insuranceInfo?.secondary) {
          if (insuranceInfo.secondary?.insurerId) {
            preSelectedPayerSecondary.insurerId =
              insuranceInfo.secondary.insurerId;
          }
          if (insuranceInfo.secondary?.insurerName) {
            preSelectedPayerSecondary.insurerName =
              insuranceInfo.secondary.insurerName;
          }
          if (insuranceInfo.secondary?.planName) {
            preSelectedPayerSecondary.planName =
              insuranceInfo.secondary.planName;
          }
          if (insuranceInfo.secondary?.payerId) {
            preSelectedPayerSecondary.payerId = insuranceInfo.secondary.payerId;
          }
        }
        setSelectedPayerSecondary(preSelectedPayerSecondary);

        const preSelectedPayerTertiary = {
          insurerId: '',
          insurerName: '',
          planName: '',
          payerId: ''
        };
        if (insuranceInfo?.tertiary) {
          if (insuranceInfo.tertiary?.insurerId) {
            preSelectedPayerTertiary.insurerId =
              insuranceInfo.tertiary.insurerId;
          }
          if (insuranceInfo.tertiary?.insurerName) {
            preSelectedPayerTertiary.insurerName =
              insuranceInfo.tertiary.insurerName;
          }
          if (insuranceInfo.tertiary?.planName) {
            preSelectedPayerTertiary.planName = insuranceInfo.tertiary.planName;
          }
          if (insuranceInfo.tertiary?.payerId) {
            preSelectedPayerTertiary.payerId = insuranceInfo.tertiary.payerId;
          }
        }
        setSelectedPayerTertiary(preSelectedPayerTertiary);
      } else {
        setIsPatientInsuredState(false);
        setInsuranceFormData([]);
        setSelectedPayerPrimary();
        setDialogOption({
          title: 'Patient Insurance',
          message: 'No Insurance Info Found',
          showDialog: true
        });
      }
    } catch (err) {
      console.error('marty getPatientInsuranceInfoCall err', err);
      setDialogOption({
        title: 'Patient Insurance',
        message: 'Error: getPatientInsuranceInfoCall',
        showDialog: true
      });
    }
  };

  const patientInsuranceForm = {
    isPatientInsured: {
      value: !!insuranceFormData.isPatientInsured,
      inputValidator: (value) => {
        return validateInput({
          isPatientInsured: { ...patientInsuranceForm.isPatientInsured, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        }
      ]
    },

    // PRIMARY INSURANCE

    // insurerId: String!
    primary_insurerId: {
      value: insuranceFormData.primary?.insurerId || ''
    },
    // insurerName: String
    primary_insurerName: {
      value: insuranceFormData.primary?.insurerName || ''
    },
    // binNumber: String
    primary_binNumber: {
      value: insuranceFormData.primary?.binNumber || ''
    },
    // pcnNumber: String
    primary_pcnNumber: {
      value: insuranceFormData.primary?.pcnNumber || ''
    },
    // coveredBy: String
    primary_coveredBy: {
      value: insuranceFormData.primary?.coveredBy || ''
    },
    // groupId: String
    primary_groupId: {
      value: insuranceFormData.primary?.groupId || '',
      inputValidator: (value) => {
        return validateInput({
          primary_groupId: {
            ...patientInsuranceForm.primary_groupId,
            value
          }
        });
      },
      validations: [
        {
          type: 'alphaNumeric',
          message: Constants.ErrorMessage.AlphaNumeric_Required
        }
      ]
    },
    // email: AWSPhone
    primary_email: {
      value: insuranceFormData.primary?.email || ''
    },
    // firstName: String
    primary_firstName: {
      value: insuranceFormData.primary?.firstName || ''
    },
    // lastName: String
    primary_lastName: {
      value: insuranceFormData.primary?.lastName || ''
    },

    // dateOfVerification: AWSDateTime
    primary_dateOfVerification: {
      value: insuranceFormData.primary?.dateOfVerification
        ? new Date(
            moment(insuranceFormData.primary.dateOfVerification).add(
              new Date().getTimezoneOffset(),
              'minutes'
            )
          )
        : ''
    },

    // verifiedInsurance: Boolean
    primary_verifiedInsurance: {
      value: insuranceFormData.primary?.verifiedInsurance
    },

    // insuranceExpireDate: AWSDate
    primary_insuranceExpireDate: {
      // value: moment(insuranceFormData.primary?.insuranceExpireDate).format(Constants.DATE.DEFAULT_AWS),
      // value: insuranceFormData.primary?.insuranceExpireDate || '',
      value: insuranceFormData.primary?.insuranceExpireDate
        ? new Date(
            moment(insuranceFormData.primary.insuranceExpireDate).add(
              new Date().getTimezoneOffset(),
              'minutes'
            )
          )
        : ''
    },
    // relationship: String
    primary_polHolderRel: {
      value: insuranceFormData.primary?.relationship || '',
      inputValidator: (value) => {
        return validateInput({
          primary_polHolderRel: {
            ...patientInsuranceForm.primary_polHolderRel,
            value
          }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        }
      ]
    },
    // planName: String
    primary_planName: {
      // value: selectedPatientInfo?.patientProfile?.insuranceInfo?.primary?.planName,
      value: insuranceFormData.primary?.planName || '',
      inputValidator: (value) => {
        return validateInput({
          primary_planName: { ...patientInsuranceForm.primary_planName, value }
        });
      },
      validations: []
    },
    // policyId: String
    primary_policyId: {
      value: insuranceFormData.primary?.policyId || '',
      inputValidator: (value) => {
        return validateInput({
          primary_policyId: { ...patientInsuranceForm.primary_policyId, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        },
        {
          type: 'alphaNumeric',
          message: Constants.ErrorMessage.AlphaNumeric_Required
        }
      ]
    },
    // state: String
    primary_state: {
      // value: insuranceFormData.primary?.state || "",
      // search both abbreviation AND name
      value:
        states.find(
          (st) =>
            st.abbreviation === insuranceFormData.primary?.state ||
            st.name === insuranceFormData.primary?.state
        ) || '',
      inputValidator: (value) => {
        return validateInput({
          primary_state: { ...patientInsuranceForm.primary_state, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        }
      ]
    },
    // customerServicePhone: AWSPhone
    primary_custServPhoneNumber: {
      value: insuranceFormData.primary?.customerServicePhone || '',
      inputValidator: (value) => {
        return validateInput({
          primary_custServPhoneNumber: {
            ...patientInsuranceForm.primary_custServPhoneNumber,
            value
          }
        });
      },
      validations: []
    },
    // rxPlanName: String
    primary_rxPlanName: {
      value: insuranceFormData.primary?.rxPlanName || ''
    },
    // pharmacyPhone: String
    primary_phone: {
      value: insuranceFormData.primary?.pharmacyPhone || ''
    },
    // rxGroupNumber: String
    primary_rxGroupNum: {
      value: insuranceFormData.primary?.rxGroupNumber || ''
    },
    // hasPharmacyBenefits: Boolean
    primary_chkPharmBenefits: {
      value: insuranceFormData.primary?.hasPharmacyBenefits || ''
    },
    // payerId: String!
    primary_payerId: {
      value: insuranceFormData.primary?.payerId || '',
      inputValidator: (value) => {
        return validateInput({
          primary_payerId: { ...patientInsuranceForm.primary_payerId, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        }
      ]
    },

    // SECONDARY INSURANCE

    // insurerId: String!
    secondary_insurerId: {
      value: insuranceFormData.secondary?.insurerId
    },
    // insurerName: String
    secondary_insurerName: {
      value: insuranceFormData.secondary?.insurerName
    },
    // binNumber: String
    secondary_binNumber: {
      value: insuranceFormData.secondary?.binNumber
    },
    // pcnNumber: String
    secondary_pcnNumber: {
      value: insuranceFormData.secondary?.pcnNumber
    },
    // coveredBy: String
    secondary_coveredBy: {
      value: insuranceFormData.secondary?.coveredBy
    },
    // groupId: String
    secondary_groupId: {
      value: insuranceFormData.secondary?.groupId || '',
      inputValidator: (value) => {
        return validateInput({
          secondary_groupId: {
            ...patientInsuranceForm.secondary_groupId,
            value
          }
        });
      },
      validations: [
        {
          type: 'alphaNumeric',
          message: Constants.ErrorMessage.AlphaNumeric_Required
        }
      ]
    },
    // email: AWSPhone
    secondary_email: {
      value: insuranceFormData.secondary?.email
    },
    // firstName: String
    secondary_firstName: {
      value: insuranceFormData.secondary?.firstName
    },
    // lastName: String
    secondary_lastName: {
      value: insuranceFormData.secondary?.lastName
    },

    // dateOfVerification: AWSDateTime
    secondary_dateOfVerification: {
      value: insuranceFormData.secondary?.dateOfVerification
        ? new Date(
            moment(insuranceFormData.secondary.dateOfVerification).add(
              new Date().getTimezoneOffset(),
              'minutes'
            )
          )
        : ''
    },

    // verifiedInsurance: Boolean
    secondary_verifiedInsurance: {
      value: insuranceFormData.secondary?.verifiedInsurance
    },

    // insuranceExpireDate: AWSDate
    secondary_insuranceExpireDate: {
      value: insuranceFormData.secondary?.insuranceExpireDate
        ? new Date(
            moment(insuranceFormData.secondary.insuranceExpireDate).add(
              new Date().getTimezoneOffset(),
              'minutes'
            )
          )
        : ''
    },
    // relationship: String
    secondary_polHolderRel: {
      value: insuranceFormData.secondary?.relationship,
      inputValidator: (value) => {
        return validateInput({
          secondary_polHolderRel: {
            ...patientInsuranceForm.secondary_polHolderRel,
            value
          }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        }
      ]
    },
    // planName: String
    secondary_planName: {
      // value: selectedPatientInfo?.patientProfile?.insuranceInfo?.secondary?.planName,
      value: insuranceFormData.secondary?.planName,
      inputValidator: (value) => {
        return validateInput({
          secondary_planName: {
            ...patientInsuranceForm.secondary_planName,
            value
          }
        });
      },
      validations: []
    },
    // policyId: String
    secondary_policyId: {
      value: insuranceFormData.secondary?.policyId,
      inputValidator: (value) => {
        return validateInput({
          secondary_policyId: {
            ...patientInsuranceForm.secondary_policyId,
            value
          }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        },
        {
          type: 'alphaNumeric',
          message: Constants.ErrorMessage.AlphaNumeric_Required
        }
      ]
    },
    // state: String
    secondary_state: {
      // value: insuranceFormData.secondary?.state,
      // search both abbreviation AND name
      value:
        states.find(
          (st) =>
            st.abbreviation === insuranceFormData.secondary?.state ||
            st.name === insuranceFormData.secondary?.state
        ) || '',
      inputValidator: (value) => {
        return validateInput({
          secondary_state: { ...patientInsuranceForm.secondary_state, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        }
      ]
    },
    // customerServicePhone: AWSPhone
    secondary_custServPhoneNumber: {
      value: insuranceFormData.secondary?.customerServicePhone || '',
      inputValidator: (value) => {
        return validateInput({
          secondary_custServPhoneNumber: {
            ...patientInsuranceForm.secondary_custServPhoneNumber,
            value
          }
        });
      },
      validations: []
    },
    // rxPlanName: String
    secondary_rxPlanName: {
      value: insuranceFormData.secondary?.rxPlanName
    },
    // pharmacyPhone: String
    secondary_phone: {
      value: insuranceFormData.secondary?.pharmacyPhone
    },
    // rxGroupNumber: String
    secondary_rxGroupNum: {
      value: insuranceFormData.secondary?.rxGroupNumber
    },
    // hasPharmacyBenefits: Boolean
    secondary_chkPharmBenefits: {
      value: insuranceFormData.secondary?.hasPharmacyBenefits
    },
    // payerId: String!
    secondary_payerId: {
      value: insuranceFormData.secondary?.payerId || '',
      inputValidator: (value) => {
        return validateInput({
          secondary_payerId: {
            ...patientInsuranceForm.secondary_payerId,
            value
          }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        }
      ]
    },

    // TERTIARY INSURANCE

    // insurerId: String!
    tertiary_insurerId: {
      value: insuranceFormData.tertiary?.insurerId
    },
    // insurerName: String
    tertiary_insurerName: {
      value: insuranceFormData.tertiary?.insurerName
    },
    // binNumber: String
    tertiary_binNumber: {
      value: insuranceFormData.tertiary?.binNumber
    },
    // pcnNumber: String
    tertiary_pcnNumber: {
      value: insuranceFormData.tertiary?.pcnNumber
    },
    // coveredBy: String
    tertiary_coveredBy: {
      value: insuranceFormData.tertiary?.coveredBy
    },
    // groupId: String
    tertiary_groupId: {
      value: insuranceFormData.tertiary?.groupId || '',
      inputValidator: (value) => {
        return validateInput({
          tertiary_groupId: {
            ...patientInsuranceForm.tertiary_groupId,
            value
          }
        });
      },
      validations: [
        {
          type: 'alphaNumeric',
          message: Constants.ErrorMessage.AlphaNumeric_Required
        }
      ]
    },
    // email: AWSPhone
    tertiary_email: {
      value: insuranceFormData.tertiary?.email
    },
    // firstName: String
    tertiary_firstName: {
      value: insuranceFormData.tertiary?.firstName
    },
    // lastName: String
    tertiary_lastName: {
      value: insuranceFormData.tertiary?.lastName
    },

    // dateOfVerification: AWSDateTime
    tertiary_dateOfVerification: {
      value: insuranceFormData.tertiary?.dateOfVerification
        ? new Date(
            moment(insuranceFormData.tertiary.dateOfVerification).add(
              new Date().getTimezoneOffset(),
              'minutes'
            )
          )
        : ''
    },

    // verifiedInsurance: Boolean
    tertiary_verifiedInsurance: {
      value: insuranceFormData.tertiary?.verifiedInsurance
    },

    // insuranceExpireDate: AWSDate
    tertiary_insuranceExpireDate: {
      value: insuranceFormData.tertiary?.insuranceExpireDate
        ? new Date(
            moment(insuranceFormData.tertiary.insuranceExpireDate).add(
              new Date().getTimezoneOffset(),
              'minutes'
            )
          )
        : ''
    },
    // relationship: String
    tertiary_polHolderRel: {
      value: insuranceFormData.tertiary?.relationship,
      inputValidator: (value) => {
        return validateInput({
          tertiary_polHolderRel: {
            ...patientInsuranceForm.tertiary_polHolderRel,
            value
          }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        }
      ]
    },
    // planName: String
    tertiary_planName: {
      // value: selectedPatientInfo?.patientProfile?.insuranceInfo?.tertiary?.planName,
      value: insuranceFormData.tertiary?.planName,
      inputValidator: (value) => {
        return validateInput({
          tertiary_planName: {
            ...patientInsuranceForm.tertiary_planName,
            value
          }
        });
      },
      validations: []
    },
    // policyId: String
    tertiary_policyId: {
      value: insuranceFormData.tertiary?.policyId,
      inputValidator: (value) => {
        return validateInput({
          tertiary_policyId: {
            ...patientInsuranceForm.tertiary_policyId,
            value
          }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        },
        {
          type: 'alphaNumeric',
          message: Constants.ErrorMessage.AlphaNumeric_Required
        }
      ]
    },
    // state: String
    tertiary_state: {
      // value: insuranceFormData.tertiary?.state,
      // search both abbreviation AND name
      value:
        states.find(
          (st) =>
            st.abbreviation === insuranceFormData.tertiary?.state ||
            st.name === insuranceFormData.tertiary?.state
        ) || '',
      inputValidator: (value) => {
        return validateInput({
          tertiary_state: { ...patientInsuranceForm.tertiary_state, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        }
      ]
    },

    tertiary_custServPhoneNumber: {
      value: insuranceFormData.tertiary?.customerServicePhone || '',
      inputValidator: (value) => {
        return validateInput({
          tertiary_custServPhoneNumber: {
            ...patientInsuranceForm.tertiary_custServPhoneNumber,
            value
          }
        });
      },
      validations: []
    },
    // customerServicePhone: AWSPhone
    // tertiary_custServPhoneNumber: {
    //   value: insuranceFormData.tertiary?.customerServicePhone
    // },
    // rxPlanName: String
    tertiary_rxPlanName: {
      value: insuranceFormData.tertiary?.rxPlanName
    },
    // pharmacyPhone: String
    tertiary_phone: {
      value: insuranceFormData.tertiary?.pharmacyPhone
    },
    // rxGroupNumber: String
    tertiary_rxGroupNum: {
      value: insuranceFormData.tertiary?.rxGroupNumber
    },
    // hasPharmacyBenefits: Boolean
    tertiary_chkPharmBenefits: {
      value: insuranceFormData.tertiary?.hasPharmacyBenefits
    },
    // payerId: String!
    tertiary_payerId: {
      value: insuranceFormData.tertiary?.payerId || '',
      inputValidator: (value) => {
        return validateInput({
          tertiary_payerId: { ...patientInsuranceForm.tertiary_payerId, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        }
      ]
    }
  };

  const initialForm = () => {
    const initialObject = {};

    Object.keys(patientInsuranceForm).forEach((key) => {
      initialObject[key] = patientInsuranceForm[key].value;
    });
    return initialObject;
  };

  const handleAddPayerPrimary = () => {
    const selectedPayerPrimary = searchTableData.filter(
      (item) => !!selectedSearchTableState[idGetter(item)]
    );
    if (selectedPayerPrimary.length > 0) {
      setSelectedPayerPrimary(selectedPayerPrimary[0]);
    }
    togglePrimarySearchDialog();
  };

  const handleAddPayerSecondary = () => {
    const selectedPayerSecondary = searchTableData.filter(
      (item) => !!selectedSearchTableState[idGetter(item)]
    );

    if (selectedPayerSecondary.length > 0) {
      setSelectedPayerSecondary(selectedPayerSecondary[0]);
    }
    toggleSecondarySearchDialog();
  };

  const handleAddPayerTertiary = () => {
    const selectedPayerTertiary = searchTableData.filter(
      (item) => !!selectedSearchTableState[idGetter(item)]
    );
    if (selectedPayerTertiary.length > 0) {
      setSelectedPayerTertiary(selectedPayerTertiary[0]);
    }
    setShowTertiaryPayorDialog();
  };

  const togglePrimarySearchDialog = () => {
    setShowPrimaryPayorDialog(!showPrimaryPayorDialog);
  };

  const toggleSecondarySearchDialog = () => {
    setShowSecondaryPayorDialog(!showSecondaryPayorDialog);
  };

  const toggleTertiarySearchDialog = () => {
    setShowTertiaryPayorDialog(!showTertiaryPayorDialog);
  };

  //   const searchRowItemChange = (event) => {
  //     const inEditID = event.dataItem.id;
  //     const data = searchTableData.map((item) =>
  //       item.id === inEditID ? { ...item, [event.field]: event.value } : item
  //     );
  //     setSearchTableData(data);
  //   };

  //   const searchSelectionChange = (event) => {
  //     const data = searchTableData.map((item) => {
  //       item.selected = false;
  //       if (event.dataItem.id === item.id) {
  //         item.selected = !event.dataItem.selected;
  //       }
  //       return item;
  //     });
  //     setSearchTableData(data);
  //   };

  const handleAddNewChangeClick = (insuranceFormName) => {
    const insuranceCompany = getPayerById(insuranceCompanyId);
    const insurancePlans = getPlansByInsurerName(insuranceCompany.name);

    if (!!insurancePlans.length) {
      setSearchTableData(
        insurancePlans
          .map((item, index) => ({
            ...item,
            id: index,
            selected: false,
            inEdit: false,
            select: ''
          }))
          .sort((a, b) => sortByField(a, b, 'planName'))
      );
      if (insuranceFormName === 'primary') {
        togglePrimarySearchDialog();
      } else if (insuranceFormName === 'secondary') {
        toggleSecondarySearchDialog();
      } else if (insuranceFormName === 'tertiary') {
        toggleTertiarySearchDialog();
      }
    } else {
      setDialogOption({
        title: 'Payor',
        message: 'No Insurance Company / Payor Found',
        showDialog: true
      });
    }
  };

  const handleSubmit = (dataItem) => {
    const requestObject = {
      // agentId: ID!
      agentId: user.username,
      // patientId: ID!
      patientId: selectedPatientInfo.patientId,
      // insuranceInfo: InsuranceInput!
      insuranceInfo: {
        // isPatientInsured: dataItem.isPatientInsured ? true : false,
        isPatientInsured: !!isPatientInsuredState,
        primary: {
          // insurerId: String!
          insurerId: selectedPayerPrimary.insurerId,
          // insurerName: String
          insurerName: selectedPayerPrimary.insurerName,
          // binNumber: String
          binNumber: dataItem.primary_binNumber,
          // pcnNumber: String
          pcnNumber: dataItem.primary_pcnNumber,
          // coveredBy: String
          coveredBy: dataItem.primary_coveredBy,
          // email: AWSPhone
          email: dataItem.primary_email ? dataItem.primary_email : null,
          // firstName: String
          firstName: dataItem.primary_firstName,
          // groupId: String
          groupId: dataItem.primary_groupId,
          // insuranceExpireDate: AWSDate
          insuranceExpireDate: dataItem.primary_insuranceExpireDate
            ? formatDateToAWS(dataItem.primary_insuranceExpireDate)
            : null,
          // lastName: String
          lastName: dataItem.primary_lastName,
          // planName: String
          planName: selectedPayerPrimary.planName, // dataItem.primary_planName,
          // policyId: String
          policyId: dataItem.primary_policyId,
          // state: String
          state: dataItem.primary_state?.abbreviation,
          // customerServicePhone: AWSPhone
          customerServicePhone: dataItem.primary_custServPhoneNumber
            ? convertToE164(dataItem.primary_custServPhoneNumber)
            : null,
          // relationship: String
          relationship: dataItem.primary_polHolderRel,
          // rxPlanName: String
          rxPlanName: dataItem.primary_rxPlanName,
          // pharmacyPhone: String
          pharmacyPhone: dataItem.primary_phone,
          // rxGroupNumber: String
          rxGroupNumber: dataItem.primary_rxGroupNum,
          // hasPharmacyBenefits: Boolean
          hasPharmacyBenefits: dataItem.primary_chkPharmBenefits,
          // payerId: String!
          payerId: selectedPayerPrimary.payerId, // dataItem.primary_payerId,
          // dateOfVerification: AWSDateTime
          dateOfVerification:
            dataItem.primary_verifiedInsurance === true
              ? dataItem.primary_dateOfVerification
                ? formatDateToAWSDateTime(dataItem.primary_dateOfVerification)
                : null
              : null,
          // verifiedInsurance: Boolean
          verifiedInsurance: dataItem.primary_verifiedInsurance
        }
      }
    };

    if (secondaryInsurance) {
      requestObject.insuranceInfo.secondary = {
        // insurerId: String!
        insurerId: selectedPayerSecondary.insurerId,
        // insurerName: String
        insurerName: selectedPayerSecondary.insurerName,
        // binNumber: String
        binNumber: dataItem.secondary_binNumber,
        // pcnNumber: String
        pcnNumber: dataItem.secondary_pcnNumber,
        // coveredBy: String
        coveredBy: dataItem.secondary_coveredBy,
        // email: AWSPhone
        email: dataItem.secondary_email,
        // firstName: String
        firstName: dataItem.secondary_firstName,
        // groupId: String
        groupId: dataItem.secondary_groupId,
        // insuranceExpireDate: AWSDate
        insuranceExpireDate: dataItem.secondary_insuranceExpireDate
          ? formatDateToAWS(dataItem.secondary_insuranceExpireDate)
          : null,
        // lastName: String
        lastName: dataItem.secondary_lastName,
        // planName: String
        planName: selectedPayerSecondary.planName, // dataItem.secondary_planName,
        // policyId: String
        policyId: dataItem.secondary_policyId,
        // state: String
        state: dataItem.secondary_state?.abbreviation,
        // customerServicePhone: AWSPhone
        customerServicePhone: dataItem.secondary_custServPhoneNumber
          ? convertToE164(dataItem.secondary_custServPhoneNumber)
          : null,
        // relationship: String
        relationship: dataItem.secondary_polHolderRel,
        // rxPlanName: String
        rxPlanName: dataItem.secondary_rxPlanName,
        // pharmacyPhone: String
        pharmacyPhone: dataItem.secondary_phone,
        // rxGroupNumber: String
        rxGroupNumber: dataItem.secondary_rxGroupNum,
        // hasPharmacyBenefits: Boolean
        hasPharmacyBenefits: dataItem.secondary_chkPharmBenefits,
        // payerId: String!
        payerId: selectedPayerSecondary.payerId, // dataItem.secondary_payerId
        // dateOfVerification: AWSDateTime
        dateOfVerification:
          dataItem.secondary_verifiedInsurance === true
            ? dataItem.secondary_dateOfVerification
              ? formatDateToAWSDateTime(dataItem.secondary_dateOfVerification)
              : null
            : null,
        // verifiedInsurance: Boolean
        verifiedInsurance: dataItem.secondary_verifiedInsurance
      };
    }

    if (tertiaryInsurance) {
      requestObject.insuranceInfo.tertiary = {
        // insurerId: String!
        insurerId: selectedPayerTertiary.insurerId,
        // insurerName: String
        insurerName: selectedPayerTertiary.insurerName,
        // binNumber: String
        binNumber: dataItem.tertiary_binNumber,
        // pcnNumber: String
        pcnNumber: dataItem.tertiary_pcnNumber,
        // coveredBy: String
        coveredBy: dataItem.tertiary_coveredBy,
        // email: AWSPhone
        email: dataItem.tertiary_email,
        // firstName: String
        firstName: dataItem.tertiary_firstName,
        // groupId: String
        groupId: dataItem.tertiary_groupId,
        // insuranceExpireDate: AWSDate
        insuranceExpireDate: dataItem.tertiary_insuranceExpireDate
          ? formatDateToAWS(dataItem.tertiary_insuranceExpireDate)
          : null,
        // lastName: String
        lastName: dataItem.tertiary_lastName,
        // planName: String
        planName: selectedPayerTertiary.planName, // dataItem.tertiary_planName,
        // policyId: String
        policyId: dataItem.tertiary_policyId,
        // state: String
        state: dataItem.tertiary_state?.abbreviation,
        // customerServicePhone: AWSPhone
        customerServicePhone: dataItem?.tertiary_custServPhoneNumber
          ? convertToE164(dataItem.tertiary_custServPhoneNumber)
          : null,
        // relationship: String
        relationship: dataItem.tertiary_polHolderRel,
        // rxPlanName: String
        rxPlanName: dataItem.tertiary_rxPlanName,
        // pharmacyPhone: String
        pharmacyPhone: dataItem.tertiary_phone,
        // rxGroupNumber: String
        rxGroupNumber: dataItem.tertiary_rxGroupNum,
        // hasPharmacyBenefits: Boolean
        hasPharmacyBenefits: dataItem.tertiary_chkPharmBenefits,
        // payerId: String!
        payerId: selectedPayerTertiary.payerId, // dataItem.tertiary_payerId,
        // dateOfVerification: AWSDateTime
        dateOfVerification:
          dataItem.tertiary_verifiedInsurance === true
            ? dataItem.tertiary_dateOfVerification
              ? formatDateToAWSDateTime(dataItem.tertiary_dateOfVerification)
              : null
            : null,
        // verifiedInsurance: Boolean
        verifiedInsurance: dataItem.tertiary_verifiedInsurance
      };
    }

    addUpdateInsuranceInfoData(requestObject);
  };

  const addUpdateInsuranceInfoData = async (requestObject) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: addUpdateInsuranceInfo,
        variables: { input: requestObject }
      });

      if (data?.data?.addUpdateInsuranceInfo) {
        setDialogOption({
          title: 'Patient Insurance',
          message: 'Insurance updated sucessfully.',
          showDialog: true
        });
        setStatusUpdate(!statusUpdate);
      }
      props.sendDataToParent({});
    } catch (err) {
      console.error('PatientInsurance::addUpdateInsuranceInfoData err:', err);
      setDialogOption({
        title: 'Patient Insurance',
        message: 'Error: addUpdateInsuranceInfoData',
        showDialog: true
      });
    }
  };

  const renderInsuranceForm = (insuranceFormName) => {
    const primaryPayor = () => {
      if (insuranceFormName === 'primary' && !selectedPayerPrimary?.planName) {
        setPrimaryPlanName(true);
        return (
          <span
            style={{ color: 'red', fontWeight: 'normal', fontSize: 'small' }}
          >
            Please add an insurance plan name by selecting the insurance company
            in the dropdown menu above.
          </span>
        );
      }
      if (insuranceFormName === 'primary' && selectedPayerPrimary?.planName) {
        setPrimaryPlanName(false);
        return selectedPayerPrimary?.planName;
      }
    };

    const secondaryPayor = () => {
      if (
        insuranceFormName === 'secondary' &&
        !selectedPayerSecondary?.planName
      ) {
        setSecondaryPlanName(true);
        return (
          <span
            style={{ color: 'red', fontWeight: 'normal', fontSize: 'small' }}
          >
            Please add an insurance plan name by selecting the insurance company
            in the dropdown menu above.
          </span>
        );
      }
      if (
        insuranceFormName === 'secondary' &&
        selectedPayerSecondary?.planName
      ) {
        setSecondaryPlanName(false);
        return selectedPayerSecondary?.planName;
      }
    };

    const tertiaryPayor = () => {
      if (
        insuranceFormName === 'tertiary' &&
        !selectedPayerTertiary?.planName
      ) {
        setTertiaryPlanName(true);
        return (
          <span
            style={{ color: 'red', fontWeight: 'normal', fontSize: 'small' }}
          >
            Please add an insurance plan name by selecting the insurance company
            in the dropdown menu above.
          </span>
        );
      }
      if (insuranceFormName === 'tertiary' && selectedPayerTertiary?.planName) {
        setTertiaryPlanName(false);
        return selectedPayerTertiary?.planName;
      }
    };

    return (
      <>
        <div className='row'>
          <div className='col-md-4'>
            <Field
              component={DropDownList}
              data={payers}
              name={`${insuranceFormName}_insurerName`}
              label='Select Insurance Company'
              textField='name'
              valueField='name'
              onChange={(e) => setInsuranceCompanyId(e.target.value.id)}
            />
          </div>
          <div className='col-md-2 mt-12'>
            <button
              type='button'
              onClick={() => handleAddNewChangeClick(insuranceFormName)}
              className='k-button pageButton'
              disabled={
                !canEdit(UserRoleTypes.Insurance) || !insuranceCompanyId
              }
            >
              Add New/Change
            </button>
          </div>
        </div>

        <hr />

        <div className='row mt-16'>
          <div className='col-md-3'>
            INSURANCE COMPANY:
            <br />
            <strong>
              {insuranceFormName === 'primary'
                ? selectedPayerPrimary?.insurerName
                : ''}
              {insuranceFormName === 'secondary'
                ? selectedPayerSecondary?.insurerName
                : ''}
              {insuranceFormName === 'tertiary'
                ? selectedPayerTertiary?.insurerName
                : ''}
            </strong>
            {/* <br/>
						<strong>{insuranceFormData?.primary?.insurerName}</strong> */}
          </div>
          <div className='col-md-4'>
            PLAN NAME:
            <br />
            <strong>
              {primaryPayor()}
              {secondaryPayor()}
              {tertiaryPayor()}
            </strong>
            {/* <br/>
						<strong>{insuranceFormData?.primary?.planName}</strong> */}
          </div>
          <div className='col-md-4'>
            PAYOR ID:
            <br />
            <strong>
              {insuranceFormName === 'primary'
                ? selectedPayerPrimary?.payerId
                : ''}
              {insuranceFormName === 'secondary'
                ? selectedPayerSecondary?.payerId
                : ''}
              {insuranceFormName === 'tertiary'
                ? selectedPayerTertiary?.payerId
                : ''}
            </strong>
          </div>
        </div>

        <div className='row mt-16'>
          <div className='col-md-3'>
            <Field
              name={`${insuranceFormName}_coveredBy`}
              data={treatmentCoveredBy}
              layout='horizontal'
              label='Treatment Covered By'
              component={DropDownList}
            />
          </div>
          <div className='col-md-2'>
            <Field
              name={`${insuranceFormName}_state`}
              data={states}
              textField='name'
              valueField='abbreviation'
              layout='horizontal'
              label={<RequiredFormLabel text='Insurance State' />}
              component={DropDownList}
              validator={
                patientInsuranceForm[`${insuranceFormName}_state`]
                  .inputValidator
              }
            />
          </div>
          <div className='col-md-3'>
            <Field
              name={`${insuranceFormName}_custServPhoneNumber`}
              component={MaskedPhoneInput}
              required
              label='Cust Service Phone'
            />
          </div>
        </div>

        <div className='row mt-08'>
          <div className='col-md-2'>
            <Field
              name={`${insuranceFormName}_groupId`}
              component={InputField}
              label='Group ID'
              validator={
                patientInsuranceForm[`${insuranceFormName}_groupId`]
                  .inputValidator
              }
            />
          </div>
          <div className='col-md-2'>
            <Field
              name={`${insuranceFormName}_policyId`}
              component={InputField}
              label={<RequiredFormLabel text='Policy ID' />}
              validator={
                patientInsuranceForm[`${insuranceFormName}_policyId`]
                  .inputValidator
              }
            />
          </div>
          <div className='col-md-3'>
            <Field
              name={`${insuranceFormName}_insuranceExpireDate`}
              component={DatePicker}
              label='Insurance Exp Date'
            />
          </div>
        </div>

        <div className='row mt-08'>
          <div className='col-md-3'>
            <Field
              name={`${insuranceFormName}_firstName`}
              component={InputField}
              label='Policy Holder First Name'
            />
          </div>
          <div className='col-md-3'>
            <Field
              name={`${insuranceFormName}_lastName`}
              component={InputField}
              label='Policy Holder Last Name'
            />
          </div>
          <div className='col-md-3'>
            <Field
              name={`${insuranceFormName}_polHolderRel`}
              data={personRelationship}
              layout='horizontal'
              label={
                <RequiredFormLabel text='Policy Holder Relationship to Patient' />
              }
              component={DropDownList}
              validator={
                patientInsuranceForm[`${insuranceFormName}_polHolderRel`]
                  .inputValidator
              }
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-md-3 mt-14'>PHARMACY BENEFITS:</div>
        </div>

        <div className='row'>
          <div className='col-md-4 mt-14'>
            <Field
              name={`${insuranceFormName}_chkPharmBenefits`}
              component={Checkbox}
              label='Patient Has Pharmacy Benefits'
              defaultValue={
                insuranceFormData[`${insuranceFormName}`]?.hasPharmacyBenefits
              }
            />
          </div>
        </div>

        <div className='row'>
          <div className='col-md-2'>
            <Field
              name={`${insuranceFormName}_rxPlanName`}
              component={Input}
              label='Rx Plan Name'
              defaultValue={
                insuranceFormData[`${insuranceFormName}`]?.rxPlanName
              }
            />
          </div>
          <div className='col-md-2'>
            <Field
              name={`${insuranceFormName}_phone`}
              component={MaskedPhoneInput}
              label='Pharmacy Phone'
              defaultValue={
                insuranceFormData[`${insuranceFormName}`]?.pharmacyPhone
              }
            />
          </div>
          <div className='col-md-2'>
            <Field
              name={`${insuranceFormName}_pcnNumber`}
              component={Input}
              label='PCN #'
              defaultValue={
                insuranceFormData[`${insuranceFormName}`]?.pcnNumber
              }
            />
          </div>
          <div className='col-md-2'>
            <Field
              name={`${insuranceFormName}_binNumber`}
              component={Input}
              label='BIN #'
              defaultValue={
                insuranceFormData[`${insuranceFormName}`]?.binNumber
              }
            />
          </div>
          <div className='col-md-2'>
            <Field
              name={`${insuranceFormName}_rxGroupNum`}
              component={Input}
              label='Rx Group #'
              defaultValue={
                insuranceFormData[`${insuranceFormName}`]?.rxGroupNumber
              }
            />
          </div>
        </div>
        <div className='row mt-12 mb-12'>
          <div className='col-md-2 mt-10'>
            <Field
              name={`${insuranceFormName}_verifiedInsurance`}
              component={Checkbox}
              label='Verified Insurance'
              defaultValue={
                insuranceFormData[`${insuranceFormName}`]?.verifiedInsurance
              }
            />
          </div>
          <div className='col-md-2'>
            <Field
              name={`${insuranceFormName}_dateOfVerification`}
              component={DatePicker}
              label='Verification Date'
            />
          </div>
          <div className='col-md-4 mt-14'>&nbsp;</div>
        </div>
      </>
    );
  };
  const secondarySelector = (event) => {
    setSecondaryInsurance(event);
    setSecondaryPlanName(event);
  };
  const tertiarySelector = (event) => {
    setTertiaryInsurance(event);
    setTertiaryPlanName(event);
  };
  return (
    <>
      <div className='row'>
        <div className='col'>
          {dialogOption && dialogOption.showDialog && (
            <MessageDialog dialogOption={dialogOption} />
          )}
          <div className='row'>
            <div className='col-md-4 pageTitle ml-3'>
              Patient Insurance Information
            </div>
          </div>

          {/* START OF MAIN FORM */}

          <Form
            initialValues={insuranceFormData}
            render={(formRenderProps) => (
              <form
                id='formPatientInsurance'
                onSubmit={formRenderProps.onSubmit}
                className='k-form pl-3 pr-3 pt-1'
              >
                <div className='row'>
                  <div className='col-md-2 mt-08'>IS PATIENT INSURED?:</div>
                  <div className='col-md-6 mt-08'>
                    <RadioGroup
                      name='isPatientInsured'
                      layout='horizontal'
                      data={isPatientInsuredOptions}
                      value={isPatientInsuredState}
                      onChange={(event) =>
                        setIsPatientInsuredState(event.value)
                      }
                    />
                  </div>
                </div>
              </form>
            )}
          />

          <hr />
          {/* form */}

          {/* MAIN FORM */}
          {showInsuranceForm && (
            <Form
              onSubmit={handleSubmit}
              initialValues={initialForm()}
              render={(formRenderProps) => (
                <form
                  onSubmit={formRenderProps.onSubmit}
                  className='k-form pl-3 pr-3 pt-1'
                >
                  <>
                    {/* Primary Insurance */}

                    <div className='row mt-16'>
                      <div className='col-md-4'>
                        <h4>Primary Insurance</h4>
                      </div>
                    </div>

                    {renderInsuranceForm('primary')}

                    <hr />

                    {/* Secondary Insurance */}

                    {primaryInsurance && (
                      <div className='row'>
                        <div className='col-md-12'>
                          <h4>Secondary Insurance</h4>
                          <Field
                            component={Checkbox}
                            value={secondaryInsurance}
                            name='hasSecondaryInsurance'
                            checked={secondaryInsurance}
                            onChange={(event) => secondarySelector(event.value)}
                          />
                        </div>
                      </div>
                    )}
                    {secondaryInsurance && (
                      <>
                        <div>{renderInsuranceForm('secondary')}</div>
                        <hr />
                      </>
                    )}

                    {/* Tertiary Insurance */}

                    {secondaryInsurance && showTertiaryInsurance && (
                      <div className='row'>
                        <div className='col-md-12'>
                          <h4>Tertiary Insurance</h4>
                          <Field
                            component={Checkbox}
                            value={tertiaryInsurance}
                            name='hasTertiaryInsurance'
                            checked={tertiaryInsurance}
                            onChange={(event) => tertiarySelector(event.value)}
                          />
                        </div>
                      </div>
                    )}

                    {tertiaryInsurance && (
                      <>
                        <div>{renderInsuranceForm('tertiary')}</div>
                        <hr />
                      </>
                    )}
                  </>

                  <div className='row p-3 mt-12'>
                    <div className='col-12'>
                      <button
                        type='submit'
                        className='k-button pageButton'
                        disabled={
                          !canEdit(UserRoleTypes.Insurance) ||
                          hasPrimaryPlan ||
                          hasSecondaryPlan ||
                          hasTertiaryPlan
                        }
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              )}
            />
          )}
        </div>
      </div>

      {showPrimaryPayorDialog && (
        <PayorSelectDialog
          title='Primary Payor Selection'
          onClose={togglePrimarySearchDialog}
          onAddPayer={handleAddPayerPrimary}
          data={searchTableData}
          selectedState={selectedSearchTableState}
          setSelectedState={setSelectedSearchTableState}
        />
      )}

      {showSecondaryPayorDialog && (
        <PayorSelectDialog
          title='Secondary Payor Selection'
          onClose={toggleSecondarySearchDialog}
          onAddPayer={handleAddPayerSecondary}
          data={searchTableData}
          selectedState={selectedSearchTableState}
          setSelectedState={setSelectedSearchTableState}
        />
      )}

      {showTertiaryPayorDialog && (
        <PayorSelectDialog
          title='Tertiary Payor Selection'
          onClose={toggleTertiarySearchDialog}
          onAddPayer={handleAddPayerTertiary}
          data={searchTableData}
          selectedState={selectedSearchTableState}
          setSelectedState={setSelectedSearchTableState}
        />
      )}
    </>
  );
};

export default PatientInsurance;
