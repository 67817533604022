import React, { useState, useContext } from 'react';
import cx from 'clsx';

// components
import { DrawerMenu, UserInfo } from '../Header/components';

// context
import { UserContext } from '@/context';

// styles
import styles from '../StatusBar/StatusBarHeader.module.scss';

const PatientPortalHeader = () => {
  const [menuExpanded, setMenuExpanded] = useState(false);

  const { user } = useContext(UserContext);
  const projectName = 'Patient Portal';
  const isPatientPortal = true;

  const onToggleDrawerMenu = () => {
    setMenuExpanded(!menuExpanded);
  };

  const render = {
    userInfo: (userData) => {
      if (isPatientPortal) {
        return <div className={styles.username}>{userData?.username}</div>;
      }
      return <UserInfo user={userData} />;
    }
  };

  return (
    <>
      <div className={styles.topbar}>
        <div
          className={cx({
            [styles.appHeader]: true,
            [styles.appPortalHeader]: isPatientPortal
          })}
        >
          <div className={styles.projectContainer}>
            <span className={styles.projectName}>{projectName}</span>
          </div>
          {render.userInfo(user)}
          <div className={styles.hamburgerIcon} onClick={onToggleDrawerMenu} />
        </div>
        <DrawerMenu
          expanded={menuExpanded}
          onClose={() => setMenuExpanded(false)}
        />
      </div>
    </>
  );
};

export { PatientPortalHeader };
