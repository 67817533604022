import { filterBy } from '@progress/kendo-react-data-tools';
import { mapTree, extendDataItem } from '@progress/kendo-react-common';

const categories = {
  SCRAP_INVENTORY: 'Scrap Inventory: No Return Needed',
  INVENTORY_RETURN: 'Return to Inventory'
};

export const reasons = [
  {
    text: categories.SCRAP_INVENTORY,
    id: 1,
    items: [
      {
        text: 'Adulterated',
        notes: 'Product appears to be tampered with',
        id: 2,
        mainCategory: categories.SCRAP_INVENTORY
      },
      {
        text: 'Cycle Count',
        notes: 'Adjusting Inventory count without a primary reason',
        id: 3,
        mainCategory: categories.SCRAP_INVENTORY
      },
      {
        text: 'Damaged',
        notes: 'Product was damaged and is unusable',
        id: 4,
        mainCategory: categories.SCRAP_INVENTORY
      },
      {
        text: 'Expired',
        notes: "Product has reached it's expiration date and is unusable",
        id: 5,
        mainCategory: categories.SCRAP_INVENTORY
      },
      {
        text: 'Not Physically Shipped',
        notes:
          'Identified inventory record in "Site Inventory", but product was not physically shipped',
        id: 6,
        mainCategory: categories.SCRAP_INVENTORY
      },
      {
        text: 'Recalled',
        notes: 'Product was recalled',
        id: 7,
        mainCategory: categories.SCRAP_INVENTORY
      },
      {
        text: 'Wasted',
        notes: 'Product was wasted while attempting to use it',
        id: 8,
        mainCategory: categories.SCRAP_INVENTORY
      }
    ]
  },
  {
    text: 'Return to Inventory',
    id: 9,
    items: [
      {
        text: 'Adulterated',
        notes: 'Product appears to be tampered with',
        id: 10,
        mainCategory: categories.INVENTORY_RETURN
      },
      {
        text: 'Damaged',
        notes: 'Product was damaged and is unusable',
        id: 11,
        mainCategory: categories.INVENTORY_RETURN
      },
      {
        text: 'Expired',
        notes: "Product has reached it's expiration date and is unusable",
        id: 12,
        mainCategory: categories.INVENTORY_RETURN
      },
      {
        text: 'Exess Inventory',
        notes: 'Returning excess inventory that is no longer needed',
        id: 13,
        mainCategory: categories.INVENTORY_RETURN
      },
      {
        text: 'Recalled',
        notes: 'Product was recalled',
        id: 14,
        mainCategory: categories.INVENTORY_RETURN
      },
      {
        text: 'Wasted',
        notes: 'Product was wasted while attempting to use it',
        id: 15,
        mainCategory: categories.INVENTORY_RETURN
      }
    ]
  }
];

export const processTreeData = (data, state, fields) => {
  const { selectField, expandField, dataItemKey, subItemsField } = fields;
  const { expanded, value, filter } = state;
  const filtering = Boolean(filter && filter.value);
  return mapTree(
    filtering ? filterBy(data, [filter], subItemsField) : data,
    subItemsField,
    (item) => {
      const props = {
        [expandField]: expanded.includes(item[dataItemKey]),
        [selectField]: value && item[dataItemKey] === value[dataItemKey]
      };
      return filtering
        ? extendDataItem(item, subItemsField, props)
        : {
            ...item,
            ...props
          };
    }
  );
};
export const expandedState = (item, dataItemKey, expanded) => {
  const nextExpanded = expanded.slice();
  const itemKey = item[dataItemKey];
  const index = expanded.indexOf(itemKey);
  index === -1 ? nextExpanded.push(itemKey) : nextExpanded.splice(index, 1);
  return nextExpanded;
};
