import React from 'react';
import styled from 'styled-components';

const TableRowValue = styled.span`
  padding-left: 3px;
`;

const TableRowPrint = ({ label = '', value = '' }) => {
  return (
    <td className='print'>
      <span className='title'>
        <b>{label}:</b>
      </span>
      <TableRowValue>{value}</TableRowValue>
    </td>
  );
};

export default TableRowPrint;

TableRowValue.displayName = 'TableRowValue';
