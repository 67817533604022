export const productTypes = {
  PRESCRIPTION: 'PRESCRIPTION',
  PREMED: 'PREMED',
  DILUENT: 'DILUENT',
  MIXIN: 'MIXIN'
};

export const getProductsByType = (products = [], type) => {
  return products.filter((item) => item.productType === type);
};
