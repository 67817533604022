export const parseSerialNumbers = (data) => {
  // push filtered and serialized data here
  const parsedData = [];

  // temporary storage for the current row
  let currentRow = {};

  // temporary storage for the serial numbers
  let serialNumbers = [];

  data.forEach((row, index) => {
    const shouldSkipCurrentRow =
      row['ShipNumber'] === 'Serial Number' || row['ShipItemQty'] === undefined;

    if (!shouldSkipCurrentRow) {
      // need to check if the following row is a serial number
      if (
        data[index + 1] &&
        data[index + 1]['ShipNumber'] === 'Serial Number'
      ) {
        // it'll be next row after the serial number placeholder
        let startIndex = index + 2;

        // populate the serial numbers array
        while (startIndex < data.length && !data[startIndex]['ShipItemQty']) {
          serialNumbers.push(data[startIndex]['ShipNumber']);
          startIndex++;
        }

        // now based on serial numbers we need to create a new row for each serial number using QTY = 1 for each row
        serialNumbers.forEach((serialNumber) => {
          // create a new row for each serial number
          const newRow = { ...row, SerialNumber: serialNumber, ShipItemQty: 1 };

          // add it to the parsed data
          parsedData.push(newRow);
        });

        // clear the serial numbers array
        serialNumbers = [];
      } else {
        // otherwise just set current as-is and push it to the parsed data
        currentRow = row;
        parsedData.push(row);
      }
    }
  });

  return parsedData;
};
