import React, { useContext } from 'react';
import styled from 'styled-components';

// Common Components
import AwesomeLabel from '@/components/common-components/AwesomeLabel';

// Partials
import PatientSurveyReviewButton from './PatientSurveyReviewButton';
import PatientSurveySkipButton from './PatientSurveySkipButton';

// Context
import { InfusionContext } from '@/context';

const ActionLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: darkred;
`;

const ActionLink = styled.div`
  padding-left: 15px;
  padding-bottom: 5px;

  &.complete {
    color: green;
  }

  &.link {
    cursor: pointer;
    color: #005282;
    text-decoration: underline;
    text-decoration-style: dotted;
    padding-left: 30px;
  }
`;

const ActionButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  cursor: pointer;
`;

const PatientSurveyActions = () => {
  const { infusion } = useContext(InfusionContext);

  if (!infusion.isSurveyRequired || infusion.isSurveySkipped) {
    return null;
  }

  return (
    <>
      <hr />
      <h5>Patient Survey Checklist:</h5>
      <ActionLinks>
        <ActionLink className={infusion.isSurveyComplete ? 'complete' : ''}>
          <AwesomeLabel
            icon={infusion.isSurveyComplete ? 'check' : 'xmark'}
            label="Survey complete by patient"
          />
        </ActionLink>
        <ActionLink className={infusion.isSurveyReviewed ? 'complete' : ''}>
          <AwesomeLabel
            icon={infusion.isSurveyReviewed ? 'check' : 'xmark'}
            label="Patient survey reviewed by nurse"
          />
        </ActionLink>
      </ActionLinks>
      <ActionButtons>
        <PatientSurveyReviewButton />
        <PatientSurveySkipButton />
      </ActionButtons>
    </>
  );
};

// Display Names
ActionLink.displayName = 'ActionLink';
ActionLinks.displayName = 'ActionLinks';
ActionButtons.displayName = 'ActionButtons';

export default PatientSurveyActions;
