import { cancelTreatment, closeLabDraw } from '@/graphql/mutations';
import { connectToGraphqlAPI } from '@/provider';

export const cancelTreatmentCall = async (requestObject) => {
  try {
    const data = await connectToGraphqlAPI({
      graphqlQuery: cancelTreatment,
      variables: { input: requestObject }
    });

    if (data.data.cancelTreatment.statusCode) {
      if (data.data.cancelTreatment.statusCode !== '400') {
        return data.data.cancelTreatment;
      }

      console.error('api.LabDraw::cancelTreatmentCall - Cancel action failed');

      return null;
    }
  } catch (err) {
    console.error('api.LabDraw::cancelTreatmentCall err: ', err);

    return null;
  }
};
export const closeLabDrawCall = async (requestObject) => {
  try {
    const data = await connectToGraphqlAPI({
      graphqlQuery: closeLabDraw,
      variables: { input: requestObject }
    });
    if (data.data.closeLabDraw.statusCode) {
      if (data.data.closeLabDraw.statusCode !== '400') {
        return data.data.closeLabDraw.nursingProcessId;
      }

      console.error('api.LabDraw::closeLabDrawCall - selected scheduled event check-in action failed');

      return null;
    }
  } catch (err) {
    console.error('api.LabDraw::closeLabDrawCall err: ', err);

    return null;
  }
};
