import React, { useEffect, useState } from 'react';
import { Avatar } from '@progress/kendo-react-layout';

// util
import { formatDateToDefault } from '@/common/DateHelper';
import { themeColors } from './w2w.utils';
import { W2wData } from './W2wData';
// GQL
//import { getNurseCalendarByLocationCall } from './W2wApiCall';

const ScheduleBanner = (props) => {
  const date = formatDateToDefault(props?.date);
  const view = props?.view;
  const currentLocation = props?.currentLocation?.locationName;
  // State
  const [posts, setPosts] = useState([]);

  // Effect for start
  useEffect(async () => {
    if (currentLocation && date) {
      //getNurseCalendarByLocationCall(currentLocation, date).then((response) => {
      W2wData(currentLocation, date).then((response) => {
        setPosts(response);
      });
    }
  }, [currentLocation, date]);

  return (
    <div className='container-fluid'>
      {view === 'day' && (
        <div>
          <div
            className='row mt-12'
            // styles set to prevent the margin jumping and the shadow box showing without data
            style={{
              padding: posts.length > 0 ? '8px 14px' : '24px 14px',
              marginBottom: '4px',
              boxShadow: posts.length > 0 ? '0 1px 2px #ccc' : 'none'
            }}
          >
            {posts.map((employee, index) => {
              const color =
                themeColors.length >= index ? themeColors[index] : 'primary';

              return (
                <div
                  className='k-hbox col-2'
                  key={index}
                  style={{ marginTop: posts.length > 6 ? '8px' : 'none' }}
                >
                  <Avatar
                    rounded='full'
                    type='text'
                    border
                    style={{
                      marginRight: 5,
                      borderRadius: '50%'
                    }}
                    themeColor={color}
                  >
                    {employee.avatar}
                  </Avatar>
                  <div>
                    <h2
                      style={{
                        fontSize: '1.0em',
                        fontWeight: 'normal',
                        margin: 0
                      }}
                    >
                      {employee.name}
                    </h2>
                    <p
                      style={{
                        margin: 0,
                        fontSize: '0.7em'
                      }}
                    >
                      {`${employee.time} - ${employee.DESCRIPTION}`}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default ScheduleBanner;
