import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

// kendo
import { Field, Form } from '@progress/kendo-react-form';
import { ComboBox, DropDownList } from '@progress/kendo-react-dropdowns';
import { filterBy } from '@progress/kendo-data-query';

// gql
import { connectToGraphqlAPI } from '@/provider';
import {
  getWorkItemsByGroupQueue,
  getCodesByCodeType
} from '@/graphql/queries';

// components
import FormButton from '@/components/common-components/Form/FormButton';
import { FormDatePicker } from '@/common/kendo-form-components';

// context
import {
  ProductContext,
  LogContext,
  NotifContext,
  QueueFilterContext,
  PayerContext,
  PrescriberContext,
  WorkItemContext,
  PatientContext,
  SalesContext
} from '@/context';

// utils
import { states } from '@/common/states';
import { getWorkItemCodeTitle, getPushObj } from './QueueFilters.utils';
import { cardTypes } from '@/components/Header/components/ActiveWorkItems/components/PendingWorkItems.constants';
import {
  biNetworkList,
  discList,
  getFollowUpDetailList,
  getWorkItemList,
  getWorkItemCallList,
  getWorkItemDropDownList,
  isWorkStatusSupported,
  isWorkTypeSupported,
  paExpiredlist,
  palist,
  getWorkItemFollowUpList
} from '@/common/Mappers';

import {
  programNames,
  viewSettingsObj,
  workItemStatusMap,
  workStatusObj
} from './filterHelper';
import {
  workItemTypes,
  taskStatus,
  taskAssignType,
  filterDropDownTypes,
  filterFieldNames,
  priorityNumbers,
  patientStatusList
} from '@/constants/enum';
import {
  callList,
  filterItemsByQueueType,
  taskList
} from '../GeneralFollowUp/generalFollowUp.helper';

const QueueFilters = ({ queueType }) => {
  const callTypes = filterItemsByQueueType(queueType, callList);
  const followUpTypes = filterItemsByQueueType(queueType, taskList);
  const { prescriptions, filteredDrugCategories } = useContext(ProductContext);
  const { salesTerritories } = useContext(SalesContext);
  const { addPendingWorkItem, setRefreshQueueData } =
    useContext(WorkItemContext);
  const { payers } = useContext(PayerContext);
  const { listPrescribersDataFiltered } = useContext(PrescriberContext);
  const { logApiException } = useContext(LogContext);
  const { isPatientLocked, getPatientBucketCall } = useContext(PatientContext);
  const {
    workItemQueueSelection,
    setWorkItemQueueSelection,
    workItemQueueSelectionValue,
    setWorkItemQueueSelectionValue,
    queueData,
    setQueueData,
    setIsLoading,
    isLoading,
    onSubscribeQueueData
  } = useContext(QueueFilterContext);
  const history = useHistory();
  const { showError, showWarning } = useContext(NotifContext);

  const [showPrescribersDropDown, setShowPrescribersDropDown] = useState();
  const [showFollowUpDropDown, setShowFollowUpDropDown] = useState();
  const [showCallsDropDown, setShowCallsDropDown] = useState();
  const [showPayersDropDown, setShowPayersDropDown] = useState();
  const [showStatesDropDown, setShowStatesDropDown] = useState();
  const [showPriorityDropDown, setShowPriorityDropDown] = useState();
  const [showPatientStatusDropDown, setShowPatientStatusDropDown] = useState();
  const [showProgramDropDown, setShowProgramDropDown] = useState();
  const [showSalesDropDown, setShowSalesDropDown] = useState();
  const [showProductCategoryDropDown, setShowProductCategoryDropDown] =
    useState();
  const [showProductDropDown, setShowProductDropDown] = useState();
  const [productsData, setProductsData] = useState([]);
  const [prescribersData, setPrescribersData] = useState(
    listPrescribersDataFiltered
  );
  const [payersData, setPayersData] = useState(payers);
  const [stateNames, setStateNames] = useState(states);
  const [workItemNames, setWorkItemNames] = useState([]);
  const [value, setValue] = useState(workItemQueueSelectionValue?.payer || '');
  const [filter, setFilter] = useState(
    workItemQueueSelectionValue?.payer || ''
  );

  const handleClearDropdowns = () => {
    setShowPrescribersDropDown(false);
    setShowPayersDropDown(false);
    setShowProductDropDown(false);
    setShowStatesDropDown(false);
    setShowPriorityDropDown(false);
    setShowPatientStatusDropDown(false);
    setShowProgramDropDown(false);
    setShowSalesDropDown(false);
    setWorkItemQueueSelection(null);
    setWorkItemQueueSelectionValue(null);
    setShowFollowUpDropDown(false);
    setShowCallsDropDown(false);
    setValue();
    setFilter();
    setShowProductCategoryDropDown(false);
  };

  // Handling combo box filtering
  const handleChange = (event) => {
    setValue(event.value?.name || event.value);
    setFilter(event.value?.name || event.value);
  };
  const filterChange = (event) => {
    const name = event?.target.props.name;
    if (name === filterFieldNames.Physician) {
      setPrescribersData(filterData(event.filter));
    }
    if (name === filterFieldNames.Payer) {
      setPayersData(filterData(event.filter, name));
      setFilter(event.filter.value);
    }
    if (name === filterFieldNames.ProductName) {
      setProductsData(filterData(event.filter));
    }
    if (name === filterFieldNames.State) {
      setStateNames(filterData(event.filter));
    }
  };

  const filterData = (filters, name) => {
    let dataItems;
    if (filters.field === filterFieldNames.ProductOnSelector) {
      dataItems = prescriptions.slice();
    }
    if (filters.field === filterFieldNames.PrescriberName) {
      dataItems = listPrescribersDataFiltered.slice();
    }
    if (name === filterFieldNames.Payer) {
      dataItems = payers;
    }
    if (filters.field === filterFieldNames.Name && !filterFieldNames.Payer) {
      // states uses 'name' as the textfield value
      dataItems = states.slice();
    }
    return filterBy(dataItems, filters);
  };

  /** **************************** */
  useEffect(() => {
    getCodesByCodeTypeCall(queueType);
    if (workItemQueueSelection?.queueType === queueType) {
      fetchWorkInProgressData(workItemQueueSelection);
      setShowCallsDropDown(
        !!workItemQueueSelection?.outboundCallSelected &&
          workItemQueueSelection.workItemSelection ===
            workItemTypes.OUTBOUND_CALL
      );
      setShowFollowUpDropDown(
        !!workItemQueueSelection?.followUpSelected &&
          workItemQueueSelection.workItemSelection === workItemTypes.FOLLOW_UP
      );
    } else {
      setQueueData([]);
      setWorkItemQueueSelection(null);
      setWorkItemQueueSelectionValue(null);
    }
  }, []);

  useEffect(() => {
    setProductsData(prescriptions);
  }, [prescriptions]);
  const getCodesByCodeTypeCall = async (codeType) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getCodesByCodeType,
        variables: {
          codeType
        }
      });
      const arrayData = data?.data?.getCodesByCodeType.items;
      const mappedNames = arrayData
        ?.map((item) => {
          return {
            ...item,
            value: item.codeName,
            text: getWorkItemCodeTitle(item.codeName)
          };
        })
        .filter((items) =>
          getWorkItemDropDownList(codeType).includes(items.codeName)
        )
        .sort((a, b) => {
          if (a.text < b.text) {
            return -1;
          }
          if (a.text > b.text) {
            return 1;
          }
          return 0;
        });
      setWorkItemNames(mappedNames);
    } catch (error) {
      console.error('QueueFilters::getCodesByCodeType error:', error);
      logApiException(error, {
        view: 'QueueFilters',
        endpoint: 'getCodesByCodeType'
      });
      showError('Error fetching data');
    }
  };

  const fetchWorkInProgressData = async ({
    workStatusSelection,
    workItemSelection,
    drugName,
    prescriberName,
    insurerName,
    fromDateRange,
    toDateRange,
    patientState,
    followUpSelected,
    outboundCallSelected,
    program,
    patientStatus,
    priority,
    productCategory,
    salesTerritoryId
  }) => {
    try {
      setIsLoading(true);
      const data = await connectToGraphqlAPI({
        graphqlQuery: getWorkItemsByGroupQueue,
        variables: {
          groupQueueId: queueType
        }
      });
      let arrayData = data?.data?.getWorkItemsByGroupQueue.map((item) => {
        const jsonData = JSON.parse(item?.attachedData);
        const resItemData = { ...item, attachedData: jsonData };

        if (!item.inProgram) {
          resItemData.program = 'general';
        }
        if (jsonData?.attachedData?.productName) {
          resItemData.drugName = jsonData.attachedData?.productName;
        }
        if (item.inProgram) {
          resItemData.program = jsonData?.programs?.[0]?.programId;
        }
        if (jsonData?.productCategory) {
          resItemData.productCategory = jsonData?.productCategory;
        }
        return resItemData;
      });
      const prescriberFirstName = prescriberName
        ?.split(' ')
        .slice(0, -1)
        .join(' ')
        .toLowerCase();
      const prescriberLastName = prescriberName
        ?.split(' ')
        .slice(-1)
        .join(' ')
        .toLowerCase();

      const conditions = {
        ...(drugName && { drugName }),
        ...(workItemSelection && { work: workItemSelection }),
        ...(workStatusSelection && { workStatus: workStatusSelection }),
        ...(prescriberName && { prescriberFirstName }),
        ...(prescriberName && { prescriberLastName }),
        ...(patientState && { patientState }),
        ...(insurerName && { insurerName: insurerName }),
        ...(followUpSelected && { followUpType: followUpSelected.text }),
        ...(outboundCallSelected && { callType: outboundCallSelected.text }),
        ...(program && { program }),
        ...(patientStatus && { patientStatus }),
        ...(priority && { priority: parseInt(priority) }),
        ...(productCategory && { productCategory }),
        ...(salesTerritoryId && {
          salesTerritoryId
        })
      };
      let results;
      /** ***************************************** */
      const followUplist = getFollowUpDetailList(conditions.followUpType);
      const callsList = getFollowUpDetailList(conditions.callType);
      switch (conditions.work) {
        case workItemTypes.DISCONTINUATION_REVIEW:
          arrayData = getWorkItemList(
            arrayData,
            discList,
            workItemStatusMap.DISCONTINUATION_REVIEW
          );
          delete conditions.work;
          break;
        case workItemTypes.NETWORK_CHECKING:
          arrayData = getWorkItemList(arrayData, biNetworkList);
          delete conditions.work;
          break;
        case workItemTypes.FOLLOW_UP:
          if (!conditions.followUpType) {
            arrayData = getWorkItemList(
              arrayData,
              getWorkItemFollowUpList(queueType)
            );
          } else {
            arrayData = getWorkItemList(
              arrayData,
              followUplist,
              conditions.followUpType
            );
          }
          delete conditions.work;
          delete conditions.followUpType;
          break;
        case workItemTypes.OUTBOUND_CALL:
          if (!conditions.callType) {
            arrayData = getWorkItemList(
              arrayData,
              getWorkItemCallList(queueType)
            );
          } else {
            arrayData = getWorkItemList(
              arrayData,
              callsList,
              conditions.callType
            );
          }
          delete conditions.work;
          delete conditions.callType;
          break;
        case workItemTypes.PRIOR_AUTHORIZATION:
          arrayData = getWorkItemList(arrayData, palist);
          delete conditions.work;
          break;
        case workItemTypes.EXTEND_PA:
          arrayData = getWorkItemList(arrayData, paExpiredlist);
          delete conditions.work;
      }

      if (conditions.workStatus === taskStatus.OPENED) {
        arrayData = arrayData.filter(
          (items) =>
            items.workStatus === taskStatus.OPENED ||
            items.workStatus === taskStatus.CREATED
        );
        delete conditions.workStatus;
      }

      // filter out all acrhived and completed items
      results = arrayData
        .filter((item) => {
          const filtered =
            item.workStatus !== taskStatus.ARCHIVED &&
            item.workStatus !== taskStatus.COMPLETED;
          return filtered;
        })
        // apply conditions as a filter
        .filter((item) => {
          return Object.keys(conditions).every((key) => {
            return conditions[key] === item[key];
          });
        })
        .sort((a, b) => {
          return new Date(a.targetTime) - new Date(b.targetTime);
        });

      if (fromDateRange && toDateRange) {
        const startDate = new Date(fromDateRange);
        const endDate = new Date(
          toDateRange.setHours(toDateRange.getHours() + 23)
        );
        results = results?.filter((a) => {
          const date = new Date(a.createdAt);
          return date >= startDate && date <= endDate;
        });
      }

      if (results.length === 0) {
        showError('No data found');
      }

      setQueueData(results);
      setIsLoading(false);
      setRefreshQueueData(false);
      onSubscribeQueueData();
    } catch (error) {
      console.error('getWorkItemsByGroupQueue: error:', error);
      logApiException(error, {
        view: 'QueueFilters',
        endpoint: 'getWorkItemsByGroupQueue'
      });
      setIsLoading(false);
      setRefreshQueueData(false);
    }
  };

  const startFetchProcess = async (selected) => {
    setWorkItemQueueSelectionValue(selected?.values);
    setQueueData([]);
    const queueSelection = selected?.values?.queue?.value;
    const workItemSelection = selected?.values?.workItem?.value;
    const workStatusSelection = selected?.values?.status?.value || '';
    const viewSettingsSelection = selected?.values?.viewSettings?.value;
    const drugName = selected?.values?.productName?.productName;
    const prescriberName = selected?.values?.physician?.prescriberName;
    const insurerName = selected?.values?.payer?.name;
    const program = selected?.values?.program?.value;
    const followUpSelected = selected?.values?.follow;
    const outboundCallSelected = selected?.values?.calls;
    const fromDateRange = selected?.values?.fromDateRange;
    const toDateRange = selected?.values?.toDateRange;
    const patientState = selected?.values?.patientState?.abbreviation;
    const patientStatus = selected?.values?.patientStatus?.value;
    const productCategory = selected?.values?.productCategory;
    const salesTerritoryId = selected?.values?.salesTerritoryId?.id;
    const priority = selected?.values?.priority;
    const requestObject = {
      queueSelection,
      workItemSelection,
      workStatusSelection,
      viewSettingsSelection,
      drugName,
      prescriberName,
      insurerName,
      fromDateRange,
      toDateRange,
      patientState,
      queueType,
      followUpSelected,
      outboundCallSelected,
      patientStatus,
      program,
      priority,
      productCategory,
      salesTerritoryId
    };

    setWorkItemQueueSelection(requestObject);
    fetchWorkInProgressData(requestObject);
  };

  const onRetrieveClick = async () => {
    let foundItem;
    let isLocked;
    for (const item of queueData) {
      if (!item.patientId) {
        console.warn(`No patient ID provided for work item (ID: ${item.id})`);
      } else if (
        item.assignedTo === taskAssignType.TBD &&
        isWorkTypeSupported(item.work) &&
        isWorkStatusSupported(item.workStatus)
      ) {
        isLocked = await isPatientLocked(item.patientId);
        if (!isLocked) {
          foundItem = item;
          break;
        }
      }
    }
    const firstAvailableWorkItem = foundItem;

    if (!firstAvailableWorkItem && !isLocked) {
      showWarning('There are no work items available');
      return null;
    }
    if (firstAvailableWorkItem) {
      const { work, patientId } = firstAvailableWorkItem;
      await getPatientBucketCall(patientId);
      const isAcquired = await addPendingWorkItem({
        ...firstAvailableWorkItem,
        ...cardTypes[work]
      });

      if (isAcquired) {
        history.push('/patient-portal', getPushObj(work));
      }
    }
  };

  const handleViewFilter = (e) => {
    const selection = e.value.value;
    if (selection === filterDropDownTypes.Drug) {
      setShowProductDropDown(!showProductDropDown);
    }
    if (selection === filterDropDownTypes.Physician) {
      setShowPrescribersDropDown(!showPrescribersDropDown);
    }
    if (selection === filterDropDownTypes.Payer) {
      setShowPayersDropDown(!showPayersDropDown);
    }
    if (selection === filterDropDownTypes.Priority) {
      setShowPriorityDropDown(!showPriorityDropDown);
    }
    if (selection === filterDropDownTypes.PatientStatus) {
      setShowPatientStatusDropDown(!showPatientStatusDropDown);
    }
    if (selection === filterDropDownTypes.State) {
      setShowStatesDropDown(!showStatesDropDown);
    }
    if (selection === filterDropDownTypes.Program) {
      setShowProgramDropDown(!showProgramDropDown);
    }
    if (selection === filterDropDownTypes.Sales) {
      setShowSalesDropDown(!showSalesDropDown);
    }
    if (selection === filterDropDownTypes.ProductCategory) {
      setShowProductCategoryDropDown(!showProductCategoryDropDown);
    }
    if (selection === workItemTypes.FOLLOW_UP) {
      setShowFollowUpDropDown(!showFollowUpDropDown);
      setShowCallsDropDown(false);
    }
    if (selection === workItemTypes.OUTBOUND_CALL) {
      setShowCallsDropDown(!showCallsDropDown);
      setShowFollowUpDropDown(false);
    }
  };

  return (
    <div className='container-fluid'>
      <div className='row mb-5'>
        <div className='col-12'>
          <Form
            onSubmitClick={startFetchProcess}
            initialValues={workItemQueueSelectionValue}
            key={JSON.stringify(workItemQueueSelectionValue)}
            render={(formRenderProps) => (
              <form
                onSubmit={formRenderProps.onSubmit}
                className='k-form pl-3 pr-3 pt-1'
              >
                <div className='row'>
                  <div className='col-2'>
                    <Field
                      name='viewSettings'
                      label='View'
                      component={DropDownList}
                      textField='text'
                      valueField='value'
                      data={viewSettingsObj}
                      onChange={(e) => handleViewFilter(e)}
                    />
                  </div>

                  <div className='col-2'>
                    <Field
                      name='workItem'
                      label='Work Items'
                      textField='text'
                      valueField='value'
                      component={DropDownList}
                      data={workItemNames}
                      onChange={(e) => handleViewFilter(e)}
                    />
                  </div>
                  <div className='col-2'>
                    <Field
                      name='status'
                      label='Status'
                      textField='text'
                      valueField='value'
                      component={DropDownList}
                      data={workStatusObj}
                    />
                  </div>
                  <div className='col-md-1' style={{ marginTop: '-16px' }}>
                    <Field
                      component={FormDatePicker}
                      name='fromDateRange'
                      label='From Date:'
                    />
                  </div>
                  <div className='col-md-1' style={{ marginTop: '-16px' }}>
                    <Field
                      component={FormDatePicker}
                      name='toDateRange'
                      label='To Date:'
                    />
                  </div>
                  <div className='col-4' style={{ marginTop: '19px' }}>
                    <FormButton
                      label='Show Work'
                      type='submit'
                      className='k-button pageButton blue'
                      loading={isLoading}
                    />
                    <FormButton
                      label='Clear'
                      type='button'
                      className='k-button clearButton blue'
                      style={{ marginLeft: '30px' }}
                      onClick={() => {
                        formRenderProps.onFormReset();
                        handleClearDropdowns();
                      }}
                    />

                    <FormButton
                      type='button'
                      label='Retrieve From Queue'
                      className='k-button  blue'
                      style={{ marginLeft: '30px' }}
                      onClick={(e) => onRetrieveClick(e)}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-2'>
                    {showProductDropDown && (
                      <Field
                        name='productName'
                        label='Product Name'
                        filterable
                        component={ComboBox}
                        data={productsData}
                        onFilterChange={(e) => filterChange(e)}
                        textField='productOnSelector'
                        valueField='productId'
                      />
                    )}
                    {showPrescribersDropDown && (
                      <Field
                        name='physician'
                        label='Physician Name'
                        filterable
                        component={ComboBox}
                        data={prescribersData}
                        onFilterChange={(e) => filterChange(e)}
                        textField='prescriberName'
                        valueField='prescriberName'
                      />
                    )}
                    {showPayersDropDown && (
                      <Field
                        label='Insurance Name'
                        name='payer'
                        textField='name'
                        dataItemKey='id'
                        component={ComboBox}
                        data={payersData}
                        value={value}
                        filterable
                        filter={filter}
                        onFilterChange={filterChange}
                        onChange={handleChange}
                      />
                    )}
                    {showStatesDropDown && (
                      <Field
                        name='patientState'
                        label='States'
                        filterable
                        component={ComboBox}
                        data={stateNames}
                        onFilterChange={(e) => filterChange(e)}
                        textField='name'
                        valueField='abbreviation'
                      />
                    )}
                    {showPatientStatusDropDown && (
                      <Field
                        name='patientStatus'
                        label='Patient Status'
                        filterable
                        component={ComboBox}
                        data={patientStatusList}
                        onFilterChange={(e) => filterChange(e)}
                        textField='text'
                        valueField='value'
                      />
                    )}
                    {showPriorityDropDown && (
                      <Field
                        name='priority'
                        label='Priority'
                        filterable
                        component={ComboBox}
                        data={priorityNumbers}
                        onFilterChange={(e) => filterChange(e)}
                      />
                    )}
                    {showProgramDropDown && (
                      <Field
                        name='program'
                        label='Program'
                        filterable
                        component={ComboBox}
                        data={programNames}
                        onFilterChange={(e) => filterChange(e)}
                        textField='text'
                        valueField='value'
                      />
                    )}
                    {showProductCategoryDropDown && (
                      <Field
                        name='productCategory'
                        label='Drug Category'
                        filterable
                        component={ComboBox}
                        data={filteredDrugCategories}
                        onFilterChange={(e) => filterChange(e)}
                      />
                    )}
                    {showSalesDropDown && (
                      <Field
                        name='salesTerritoryId'
                        label='Sales Territory'
                        filterable
                        component={ComboBox}
                        data={salesTerritories}
                        onFilterChange={(e) => filterChange(e)}
                        textField='text'
                        valueField='id'
                      />
                    )}
                  </div>
                  <div className='col-2'>
                    {showFollowUpDropDown && (
                      <Field
                        name='follow'
                        label='Follow Up Types'
                        filterable
                        component={ComboBox}
                        data={followUpTypes}
                        onFilterChange={(e) => filterChange(e)}
                        textField='text'
                        valueField='text'
                      />
                    )}
                    {showCallsDropDown && (
                      <Field
                        name='calls'
                        label='Call Types'
                        filterable
                        component={ComboBox}
                        data={callTypes}
                        onFilterChange={(e) => filterChange(e)}
                        textField='text'
                        valueField='text'
                      />
                    )}
                  </div>
                  <div className='col-2' />
                  <div className='col-2' />
                  <div className='col-2' />
                </div>
              </form>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default QueueFilters;
