import React from "react";
import { Loader } from "@progress/kendo-react-indicators";
import { Dialog } from "@progress/kendo-react-dialogs";

const FullPageLoader = ({
  show = true,
  size = "large",
  color = "primary",
  type = "infinite-spinner",
  ...props
}) => {
  if (!show) {
    return props.children;
  }

  return (
    <div>
      <Dialog className="full-page-loader">
        <Loader themeColor={color} type={type} size={size} />
      </Dialog>
    </div>
  );
};
export default FullPageLoader;
