import { QOL_PROVIDER_FOLLOWING } from '../../surveyDictionary';
import {
  generalPainLevel,
  // phq9Answer,
  common1to10Rate,
  commonYesNoField
} from '../modelBlocks';
import PHQ9Model from '../PHQ9Model';

export default {
  pages: [
    {
      elements: [
        // Category 1: Physical Assessment (PA)
        {
          type: 'panel',
          name: 'panel-PA',
          title: 'Category 1: Physical Assessment',
          elements: [
            // #1: Disease state status-Activity level
            {
              ...commonYesNoField,
              name: 'PA-activity-level',
              title:
                'Are you limited in any way in any activities because of any impairment or health problem?'
            },
            // #2: Disease state status-Pain level
            {
              name: 'general-pain-scale',
              type: 'radiogroup',
              title:
                'When you have pain how would you rate your pain on a scale of 1 through 10?',
              choices: generalPainLevel,
              isRequired: true
            },
            // #3: Disease state status-Presence of disease effects (not feeling well)
            {
              ...common1to10Rate,
              name: 'PA-general-feel',
              title: 'Because of your condition, how do you feel in general?',
              minRateDescription: 'Horrible',
              maxRateDescription: 'Very Well',
              visibleIf: '{PA-activity-level}=true'
            },
            // #4: Disease state status-Appetite
            {
              ...common1to10Rate,
              name: 'PA-hard-to-eat',
              title: 'How often do you find it difficult to eat?',
              minRateDescription: 'Always',
              maxRateDescription: 'Never'
            }
          ]
        }
      ]
    },
    {
      // Category 2: Emotional well-being
      elements: [
        {
          type: 'panel',
          name: 'panel-EWB',
          title: 'Category 2: Emotional well-being',
          elements: [
            // #5: Anxiety
            {
              ...common1to10Rate,
              name: 'EWB-anxiety',
              title:
                'How often are you experiencing stress, feeling anxious or worried?',
              minRateDescription: 'Always',
              maxRateDescription: 'Never'
            },
            // #6: Depression
            {
              ...common1to10Rate,
              name: 'EWB-depression',
              title:
                'How often are you experiencing feeling sad, blue or depressed and/or experiencing low energy/interest/pleasure in performing day to day tasks?',
              minRateDescription: 'Always',
              maxRateDescription: 'Never'
            },
            // ==================
            // PHQ9 form trigger
            // ==================
            { ...PHQ9Model },
            // #7: Sleep
            {
              ...common1to10Rate,
              name: 'EWB-sleep',
              title: 'How often do you have trouble sleeping?',
              minRateDescription: 'Always',
              maxRateDescription: 'Never'
            }
          ]
        }
      ]
    },
    // Category 3: Cognition
    {
      elements: [
        {
          type: 'panel',
          name: 'panel-cognition',
          title: 'Category 3: Cognition',
          elements: [
            // #8: Health literacy level
            {
              ...common1to10Rate,
              name: 'cognition-literacy-level',
              title:
              'Observe: Rate current capabilities compared to last visit (Health Literacy)',
              minRateDescription: 'Extremely difficult',
              maxRateDescription: 'Very easy'
            },
            // #9: Understanding of how to take meds
            {
              ...common1to10Rate,
              name: 'cognition-understanding',
              title:
                'Have you started taking any new medicines since your last time here?',
              minRateDescription: 'No understanding',
              maxRateDescription: 'Strong Understanding'
            },
            // #10: Remembering
            {
              ...common1to10Rate,
              name: 'cognition-remembering',
              title:
                'Observe: Rate current capabilities compared to last visit (Remembering) Can inquire: How easy is it for you to remember things?',
              minRateDescription: 'Extremely difficult',
              maxRateDescription: 'Very easy'
            },
            // #11: Ability to problem solve
            {
              ...common1to10Rate,
              name: 'cognition-problem-solving',
              title:
                'Observe: Rate current capabilities compared to last visit (Ability to problem solve) Can Inquire: When you have a problem how easy is it for you to solve it?',
              minRateDescription: 'Extremely difficult',
              maxRateDescription: 'Very easy'
            }
          ]
        }
      ]
    },
    // Category 4: Level of Independence
    {
      elements: [
        {
          type: 'panel',
          name: 'panel-personal-development',
          title: 'Category 4: Level of Independence',
          elements: [
            // #12: Self-care
            {
              ...common1to10Rate,
              name: 'LoI-self-care',
              title:
                'How easy is it for you to take care of all of your daily needs yourself?',
              minRateDescription: 'Extremely difficult',
              maxRateDescription: 'Very easy'
            },
            // #13: Access to Caregiver (if needed)
            {
              ...common1to10Rate,
              name: 'LoI-caregiver-access',
              title:
                "Do you have someone to help you when you can't do something for yourself?",
              minRateDescription: 'Never',
              maxRateDescription: 'Always',
              visibleIf: '{LoI-self-care} < 10'
            },
            // #14: Resources-Transportation
            {
              ...commonYesNoField,
              name: 'LoI-transportation',
              title:
                'Do you have someone to take you to your medical appointments when you need them?'
            },
            // #15: Resources-Food
            {
              ...commonYesNoField,
              name: 'LoI-food',
              title: 'Do you have access to the food you need daily?'
            }
          ]
        }
      ]
    },
    // Category 5: Patient Education
    {
      elements: [
        {
          type: 'panel',
          name: 'panel-patient-education',
          title: 'Category 5: Patient Education',
          elements: [
            // #16: Disease Education and Risks
            {
              ...common1to10Rate,
              name: 'EDU-risks',
              title: 'What concerns do you have about your condition since your last time here?',
              minRateDescription: 'No understanding',
              maxRateDescription: 'Full understanding'
            },
            // #17: Drug Education: Purpose, warnings, side effects, labs, follow-ups
            {
              ...common1to10Rate,
              name: 'EDU-drug-education',
              title:
                'How has this medicine been going for you since your last visit?',
              minRateDescription: 'No understanding',
              maxRateDescription: 'Full understanding'
            },
            // #18: Post-Infusion Management (infusion reactions and anaphylaxis)
            {
              ...common1to10Rate,
              name: 'EDU-post-infusion-management',
              title:
                'After your last infusion did you have any problems due to this medicine that required you to get help? (Doctor/ER/ Hospital)',
              minRateDescription: 'No understanding',
              maxRateDescription: 'Full understanding'
            },
            // #19: Hospitalizations and/or ER Visits
            {
              ...commonYesNoField,
              name: 'EDU-hospitalizations',
              title:
                'Have you had to be admitted to the Hospital or taken to the Emergency Room since your last visit?'
            }
          ]
        }
      ]
    }
  ],
  showPrevButton: false,
  completedHtml: 'Survey completed, saving data...',
  clearInvisibleValues: 'onHidden',
  questionsOnPageMode: 'singlePage',
  version: 1,
  surveyType: QOL_PROVIDER_FOLLOWING,
  surveyTitle: 'AQCCA Provider Survey - Following Administrations'
};
