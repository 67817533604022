import { Storage } from 'aws-amplify';

// Adds app standerd light blue color to every other line on exported file. Then exports the file.
export const getExportLineStyleSave = (component) => {
  const options = component.current.workbookOptions();
  const { rows } = options.sheets[0];
  let altIdx = 0;
  rows.forEach((row) => {
    if (row.type === 'data') {
      if (altIdx % 2 !== 0) {
        row.cells.forEach((cell) => {
          cell.background = '#F0F8FD';
        });
      }
      altIdx++;
    }
  });
  component.current.save(options);
};

export const getS3path = async (filePath) => {
  const conf = { download: false };
  const s3Path = await Storage.get(filePath, conf);
  return s3Path;
};
