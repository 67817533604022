import React from 'react';

const DrugWithBadge = ({ item }) => {
  const render = {
    viivBadge: () => {
      if (item.programId === 'viiv') {
        return (
          <span className='badge badge-pill badge-primary ml-01'>VIIV</span>
        );
      }
    },
    pdpBadge: () => {
      if (item.programId === 'PDP') {
        return <span className='badge badge-pill badge-secondary'>PDP</span>;
      }
    },
    categoryBadge: () => {
      if (!!item.category) {
        return (
          <span className='badge badge-pill badge-info'>{item.category}</span>
        );
      }
    }
  };

  return (
    <>
      <span>
        {item.productName} ({item.productId})
      </span>
      &nbsp;
      {render.categoryBadge()}
      &nbsp;
      {render.viivBadge()}
      {render.pdpBadge()}
    </>
  );
};

export default DrugWithBadge;
