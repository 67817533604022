import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

// kendo
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import { Button } from '@progress/kendo-react-buttons';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Field, Form } from '@progress/kendo-react-form';

// components
import Preloader from '@/components/common-components/Preloader';
import { ColumnMenu } from '@/components/common-components/columnMenu';
import Badge from '@/components/common-components/Badge';

// gql
import { connectToGraphqlAPI } from '@/provider';
import { getWorkItemsByGroupQueue, getPatientBucket } from '@/graphql/queries';
import { snoozeWorkItem } from '@/graphql/mutations.js';

// context
import { UserContext, PatientContext, NotifContext } from '@/context';

// utils
import {
  formatDateToDefault,
  formatDateToAWSDateTime
} from '@/common/DateHelper';
import {
  DatePickerField,
  isDateGreaterThanToday,
  validateInput
} from '@/common/Validation';
import { Constants } from '@/constants';

const initialDataState = {};

const PriorAuthQueue = () => {
  const [listWorkItems, setListWorkItems] = useState([]);
  const [visibleSnoozeDateDialog, setVisibleSnoozeDateDialog] = useState(false);
  const [currentSelRow, setCurrentSelRow] = useState({});
  const [loading, setLoading] = useState(true);
  const [dataState, setDataState] = useState();
  const [resultState, setResultState] = useState(
    process(listWorkItems, initialDataState)
  );
  const [, setDialogOption] = useState({});

  const { user } = useContext(UserContext);
  const { setSelectedPatientInfo } = useContext(PatientContext);
  const { showError } = useContext(NotifContext);

  const history = useHistory();

  const onDataStateChange = useCallback(
    (e) => {
      setDataState(e.dataState);
      setResultState(process(listWorkItems, e.dataState));
    },
    [listWorkItems]
  );

  const handleSnoozeItemClick = (dataObject) => {
    toggleSnoozeDateDialog();
    setCurrentSelRow(dataObject);
  };

  const toggleSnoozeDateDialog = () => {
    setVisibleSnoozeDateDialog(!visibleSnoozeDateDialog);
  };

  const handleSnoozeItemSubmit = async (dataItem) => {
    console.log('ILIA: form submission data', dataItem);

    try {
      await connectToGraphqlAPI({
        graphqlQuery: snoozeWorkItem,
        variables: {
          agentId: user.username,
          targetDate: formatDateToAWSDateTime(dataItem.snoozeUntil),
          workItemId: currentSelRow.id
        }
      }).then(() => {
        toggleSnoozeDateDialog();
        // getWorkItemsFromScheduleQueueCall();
        fetchWorkInProgressData();

        setDialogOption({
          title: 'Snoozing Prior Auth Item',
          message: 'Item Snooze Date was succesfully updated.',
          showDialog: true
        });
      });
    } catch (err) {
      console.log(err);
    }
  };

  // MAIN INITIATOR
  useEffect(() => {
    if (user) {
      fetchWorkInProgressData();
    }
  }, []);

  useEffect(() => {
    if (!loading) {
      setDataState(initialDataState);
      setResultState(process(listWorkItems, initialDataState));
    }
  }, [listWorkItems]);

  const fetchWorkInProgressData = async () => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getWorkItemsByGroupQueue,
        variables: { groupQueueId: 'PA' }
      });

      if (data?.data?.getWorkItemsByGroupQueue?.length > 0) {
        setLoading(false);

        const listWorkItemsData = data.data.getWorkItemsByGroupQueue
          .filter((item) => item.workStatus !== 'SNOOZED') // temporary manual filter of SNOOZED items... should be filtered at API
          .map((item) => {
            item.patientName = `${item.patientLastName}, ${item.patientFirstName}`;
            item.agentName = `${item.agentLastName}, ${item.agentFirstName}`;
            item.expDate = formatDateToDefault(item.targetTime);
            return item;
          })
          .sort((a, b) => (b.startTime > a.startTime ? 1 : -1));

        setListWorkItems(listWorkItemsData);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log('PriorAuthQueue::getWorkItemsByGroupQueue error:', err);
    }
  };

  const getPatientBucketData = async (patientId, currentStage) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getPatientBucket,
        variables: { patientId }
      });

      if (data && data.data && data.data.getPatientBucket) {
        setSelectedPatientInfo(data.data.getPatientBucket);
        history.push('/patient-portal', {
          searchType: currentStage,
          redirect2PA: true
        });
      } else {
        showError('No Patient Record Found');
      }
    } catch (err) {
      console.log('PriorAuthQueue::getPatientBucketData err:', err);
      showError('Error at patient bucket method. Check console for details');
    }
  };

  const onRowClickHandle = (e) => {
    if (e?.dataItem?.work) {
      return getPatientBucketData(e.dataItem.patientId, 'priorAuth');
    }

    console.error(
      "PriorAuthQueue error: work item doesn't have expected values"
    );
  };

  const snoozeForm = {
    snoozeUntil: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          snoozeUntil: { ...snoozeForm.snoozeUntil, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    }
  };

  const render = {
    patientNameCell: ({ dataItem: { inProgram, patientName } }) => (
      <td>
        {inProgram && <Badge type='info' text='STAT ViiV' />}
        {patientName}
      </td>
    ),
    actionCell: ({ dataItem }) => (
      <td>
        <div className='row'>
          <div className='col-md-4'>
            <Button
              type='button'
              title='Edit Quantity'
              onClick={() => {
                handleSnoozeItemClick(dataItem);
              }}
            >
              Snooze Item
            </Button>
          </div>
        </div>
      </td>
    )
  };

  const columns = [
    {
      field: 'agentName',
      title: 'Agent Name',
      columnMenu: ColumnMenu
    },
    {
      field: 'patientName',
      title: 'Patient Name',
      columnMenu: ColumnMenu,
      cell: render.patientNameCell
    },
    {
      field: 'work',
      title: 'Type',
      columnMenu: ColumnMenu
    },
    {
      field: 'description',
      title: 'Description',
      columnMenu: ColumnMenu
    },
    {
      field: 'expDate',
      title: 'Exp Date',
      columnMenu: ColumnMenu
    },
    {
      field: 'select',
      title: 'SELECT',
      cell: render.actionCell
    }
  ];

  return (
    <div className='container-fluid agent-work-queue'>
      {user?.username && (
        <div className='row my-3'>
          <div
            className='col-12 col-lg-12 work-in-progress'
            style={{ marginTop: '1.9rem' }}
          >
            <div className='a-grid__header'>
              <div>Prior Authorization Queue</div>
            </div>
            <Preloader show={loading}>
              <Grid
                className='nurse-queue_grid'
                style={{ cursor: 'pointer' }}
                data={{ data: resultState.data }}
                onRowClick={onRowClickHandle}
                onDataStateChange={onDataStateChange}
                sortable
                {...dataState}
              >
                {columns.map((colProps, idx) => (
                  <Column key={idx} {...colProps} />
                ))}
              </Grid>
            </Preloader>
          </div>
        </div>
      )}

      {visibleSnoozeDateDialog && (
        <Dialog
          title='Prior Auth Item'
          width={500}
          height={350}
          top={25}
          onClose={toggleSnoozeDateDialog}
          showDialog
        >
          <Form
            // initialValues={initialForm()}
            onSubmit={handleSnoozeItemSubmit}
            validator={isDateGreaterThanToday}
            render={(formRenderProps) => (
              <form
                onSubmit={formRenderProps.onSubmit}
                className='k-form pl-2 pr-3 pt-1'
              >
                <div className='row mt-2'>
                  <div className='col-7'>
                    <b>Patient Name:</b> {currentSelRow.patientName}
                  </div>
                  <div className='col-4'>
                    <b>ID:</b> {currentSelRow.patientId}
                  </div>
                </div>
                <div className='row mt-2'>
                  <div className='col-12'>
                    <Field
                      component={DatePickerField}
                      name='snoozeUntil'
                      label='Snooze Until'
                      validator={snoozeForm.snoozeUntil.inputValidator}
                    />
                  </div>
                </div>
                <div className='row p-3'>
                  <div className='col-12' style={{ textAlign: 'center' }}>
                    <button type='submit' className='k-button pageButton'>
                      Snooze
                    </button>
                  </div>
                </div>
              </form>
            )}
          />
        </Dialog>
      )}
    </div>
  );
};

export default PriorAuthQueue;
