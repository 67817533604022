import React, { useEffect, useState, useRef } from 'react';

const isProduction = process.env.NODE_ENV === 'production';
const seconds = isProduction ? 10 : 300;

const Fallback = () => {
const [num, setNum] = useState(seconds);
const intervalRef = useRef();

const decreaseNum = () => setNum((prev) => prev - 1);

useEffect(() => {
    intervalRef.current = setInterval(decreaseNum, 1000);
    return () => clearInterval(intervalRef.current);
  }, []);

  const refresh = () => {
    window.location.reload();
  };

  setTimeout(refresh, seconds * 1000);

  return (
    <div className="container">
      <div className="row">
        <div className="col-3" />
        <div className="col-6">
          <h4 style={{ textAlign: 'center', marginTop: '50px' }}>You have encountered an error</h4>
          <h4 style={{ textAlign: 'center' }}>and you will be redirected back to the home page in {num} seconds</h4>
        </div>
        <div className="col-3" />
      </div>
    </div>
  );
};

export default Fallback;
