import React, { useState, useContext, useCallback, useRef } from 'react';
import * as moment from 'moment';
import cx from 'clsx';

// kendo components
import { Field, Form } from '@progress/kendo-react-form';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import {
  Input,
  RadioGroup,
  Checkbox,
  TextArea
} from '@progress/kendo-react-inputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { DatePicker } from '@progress/kendo-react-dateinputs';

// app components
import Preloader from '@/components/common-components/Preloader';
import AwesomeLabel from '@/components/common-components/AwesomeLabel';
import AwesomeLink from '@/components/common-components/AwesomeLink';
import PatientDocument from '@/components/Patient/PatientDocument';
import WindowDialog from '@/components/common-components/WindowDialog';
import PatientInactiveBadge from '@/components/Patient/PatientInactiveBadge';

// partials
import CreateWorkItemDialogDrawer from './CreateWorkItemDialogDrawer';
import CreateWorkItemDialogOverlay from './CreateWorkItemDialogOverlay';

// graphQL
import { getQueryClient } from '@/provider';
import {
  getPatientBucket,
  getPatientBucketByLastNameInLowerCase,
  getPatientBucketByDob
} from '@/graphql/queries';
import {
  createNewPatientBucket,
  addUpdatePatientDocs
} from '@/graphql/mutations';

// context
import {
  UserContext,
  PayerContext,
  PatientContext,
  LogContext,
  InboundContext,
  WorkItemContext,
  InfoCodeContext,
  NotifContext
} from '@/context';
import { generateAttachedData } from '@/context/InboundContextHelper';

// common components
import { FormRadioGroup } from '@/common/kendo-form-components';
import AutoFilter from '@/common/AutoFilter';

// utils
import { validateInput } from '@/common/Validation';
import {
  formatDateToAWS,
  formatDateToAWSDateTime,
  formatDateToDefault
} from '@/common/DateHelper';
import { getFaxDocTypes } from './CreateWorkItemDialog.utils';
import { convertToE164 } from '@/common/PhoneNumberConverter';

// constants
import { Constants } from '@/constants';
import {
  DOC_TYPE_ORDER_REFILL,
  DOC_TYPE_REFERRAL,
  taskStatus
} from '@/constants/enum';

// styles
import styles from './CreateWorkItemDialog.module.scss';

const CreateWorkItemDialog = ({
  showDialog = false,
  toggleDialog,
  pdfPath,
  productList = [],
  currentWorkItemId,
  currentWorkItem
}) => {
  const [showUndeterminedConfirm, setShowUndeterminedConfirm] = useState(false);
  const [showJunkConfirm, setShowJunkConfirm] = useState(false);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [loadingPatients, setLoadingPatients] = useState(false);
  const [patientSearchResults, setPatientSearchResults] = useState();
  const [docType, setDocType] = useState();
  const [dxCode, setDxCode] = useState();
  const [searchBy, setSearchBy] = useState();
  const [patientMode, setPatientMode] = useState('search');
  const [insuranceOption, setInsuranceOption] = useState('undetermined');
  const [selectedPatient, setSelectedPatient] = useState();
  const [selectedPayer, setSelectedPayer] = useState();
  const [selectedPayerPlans, setSelectedPayerPlans] = useState([]);
  const [isPatientLoading, setIsPatientLoading] = useState(true);

  const { releaseWorkItem, updateWorkItem } = useContext(WorkItemContext);
  const { agentId, isAdmin } = useContext(UserContext);
  const { payers, getPlansByInsurerName, getPayerById } =
    useContext(PayerContext);
  const { addPatientNotes, getPatientWorkItemsCall } =
    useContext(PatientContext);
  const { dxCodes } = useContext(InfoCodeContext);
  const { logApiException } = useContext(LogContext);
  const {
    deleteInboundWorkItem,
    setShowInboundPreview,
    setInboundPreviewData,
    setNeedToBeReleased,
    needToBeReleased
  } = useContext(InboundContext);
  const { showError } = useContext(NotifContext);

  // Create a ref to store the current value
  const releaseFlagRef = useRef(needToBeReleased);
  const workItemIdRef = useRef(currentWorkItemId);
  const agentIdRef = useRef(agentId);

  // Update the ref whenever the value changes
  releaseFlagRef.current = needToBeReleased;
  workItemIdRef.current = currentWorkItemId;
  agentIdRef.current = agentId;

  const toggleJunk = () => setShowJunkConfirm(!showJunkConfirm);
  const toggleUndetermined = () =>
    setShowUndeterminedConfirm(!showUndeterminedConfirm);

  const generateSearchPatientsParams = (searchQuery) => {
    const { searchId, searchLastName, searchDob } = searchQuery;
    // uncomment this in case we need to EXCLUDE inactive patietns
    // const filter = { patientStatus: { ne: 'INACTIVE' } };
    const filter = {};
    let queryParams = null;
    if (searchId) {
      queryParams = {
        query: getPatientBucket,
        path: 'getPatientBucket',
        payload: {
          patientId: searchId
        }
      };
    } else if (searchLastName) {
      queryParams = {
        query: getPatientBucketByLastNameInLowerCase,
        path: 'getPatientBucketByLastNameInLowerCase',
        payload: {
          sPatientLastName: searchLastName.toLowerCase(),
          filter
        }
      };
    } else if (searchDob) {
      queryParams = {
        query: getPatientBucketByDob,
        path: 'getPatientBucketByDob',
        payload: {
          dob: moment(searchDob).format(Constants.DATE.DEFAULT_AWS),
          filter
        }
      };
    }
    return queryParams;
  };

  const onSubmitPatientSearch = async (searchQuery) => {
    setLoadingPatients(true);
    const queryParams = generateSearchPatientsParams(searchQuery);
    try {
      if (queryParams) {
        const patientsData = await getQueryClient(queryParams);
        const normPatients = patientsData
          .map((patient) => ({
            dob: patient.dob,
            firstName: patient.patientFirstName,
            lastName: patient.patientLastName,
            id: patient.patientId,
            patientStatus: patient.patientStatus
          }))
          .sort((a, b) => (b.firstName < a.firstName ? 1 : -1));
        setPatientSearchResults(normPatients);
      } else {
        setPatientSearchResults([]);
      }
    } catch (error) {
      setPatientSearchResults([]);

      console.error(
        'CreateWorkItemDialog::onSubmitPatientSearch error: ',
        error
      );
      logApiException(error, {
        view: 'CreateWorkItemDialog',
        endpoint: queryParams.path
      });
    }
    setLoadingPatients(false);
    setShowSearchResults(true);
  };

  const onLoadPatient = async (patientId) => {
    setIsPatientLoading(true);
    await getPatientWorkItemsCall(patientId);
    setIsPatientLoading(false);
  };

  const createNewPatientCall = async (inputData) => {
    try {
      const res = await getQueryClient({
        query: createNewPatientBucket,
        payload: {
          input: {
            agentId,
            dob: formatDateToAWS(inputData.dob),
            patientFirstName: inputData.firstName,
            patientLastName: inputData.lastName,
            homePhoneNumber: convertToE164(inputData.homePhoneNumber),
            patientDocuments: {
              documentType: docType,
              documentPath: currentWorkItem.url,
              date: formatDateToAWSDateTime(),
              receivedAt: currentWorkItem.createdAt,
              attachedData: inputData.attachedData
            }
          }
        },
        path: 'createNewPatientBucket'
      });
      if (!res?.length) {
        console.error(
          'CreateWorkItemDialog::createNewPatientBucket - no data returned from the server'
        );
        return null;
      }

      return res[0];
    } catch (error) {
      console.error(
        'CreateWorkItemDialog::createNewPatientBucket error: ',
        error
      );
      logApiException(error, {
        view: 'CreateWorkItemDialog',
        endpoint: 'createNewPatientBucket',
        agentId,
        patientLastName: inputData?.lastName,
        patientFirstName: inputData?.firstName
      });
    }
  };

  const uploadPatientDocCall = async (inputData) => {
    try {
      await getQueryClient({
        query: addUpdatePatientDocs,
        payload: {
          input: {
            agentId,
            createWorkItem: !!inputData.createWorkItem,
            patientId: inputData.id,
            patientDocuments: {
              documentType: docType,
              documentPath: currentWorkItem.url,
              date: formatDateToAWSDateTime(),
              receivedAt: currentWorkItem.createdAt,
              attachedData: inputData.attachedData
            }
          }
        },
        path: 'addUpdatePatientDocs'
      });
    } catch (error) {
      console.error(
        'CreateWorkItemDialog::addUpdatePatientDocs error: ',
        error
      );
      logApiException(error, {
        view: 'CreateWorkItemDialog',
        endpoint: 'addUpdatePatientDocs',
        agentId,
        patientId: inputData?.id
      });
    }
  };

  const clearData = () => {
    setDocType();
    setDxCode();
    setPatientMode('search');
    setInsuranceOption('undetermined');
    setSelectedPatient();
    setSelectedPayer();
    setSelectedPayerPlans([]);
    setSearchBy();
    setInboundPreviewData();
  };

  const onSubmitNewPatient = (formData) => {
    setSelectedPatient({
      firstName: formData.patientFirstName,
      lastName: formData.patientLastName,
      dob: formData.patientDob,
      homePhoneNumber: formData.patientPhoneNumber,
      isNewPatient: true
    });
  };

  const onFormSubmit = async (formData) => {
    setShowInboundPreview(true);
    setInboundPreviewData({ formData, selectedPatient, dxCode });
  };

  const onFormSubmitCallback = async (
    { formData },
    handleError,
    createWorkItem = false
  ) => {
    const attachedData = generateAttachedData(formData, dxCode);
    const requestInput = { ...selectedPatient, attachedData };
    let patientId = selectedPatient.id;

    // for existing patient we'll use different flow/endpoints
    if (selectedPatient.isNewPatient) {
      const newPatientInfo = await createNewPatientCall(requestInput);

      if (!newPatientInfo) {
        showError('New patient has not been created: bad input data');
        handleError();
        return;
      }
      patientId = newPatientInfo.patientId;
    } else {
      await uploadPatientDocCall({ ...requestInput, createWorkItem });
    }

    // add patient notes if provided
    if (formData.inboundNotes) {
      await addPatientNotes({
        agentId,
        patientId,
        type: 'INTAKE',
        notes: formData.inboundNotes
      });
    }

    await onReleaseWorkItem(taskStatus.COMPLETED);

    deleteInboundWorkItem(currentWorkItemId);

    // close modals
    setShowInboundPreview(false);
    toggleDialog();

    // clear data
    clearData();
  };

  const onMarkWorkItemAsJunk = async () => {
    try {
      if (currentWorkItemId) {
        const reqBody = {
          workItemId: currentWorkItemId,
          agentId,
          workStatus: taskStatus.CANCELED,
          reasonsForStatusChange: `Marked by agent ${agentId} as junk`
        };
        const data = await updateWorkItem(reqBody);

        if (data?.data?.updateWorkItemStatus?.success) {
          setShowJunkConfirm(false);
          setNeedToBeReleased(false);
          toggleDialog();
          deleteInboundWorkItem(currentWorkItemId);

          // clear data
          clearData();
        }
      }
    } catch (error) {
      console.error(
        'CreateWorkItemDialog::onMarkWorkItemAsJunk error: ',
        error
      );
      logApiException(error, {
        view: 'CreateWorkItemDialog',
        endpoint: 'deleteWorkItem|updateWorkItemStatus',
        id: currentWorkItemId
      });
    }
  };

  const onMarkWorkItemUndetermined = async () => {
    try {
      if (currentWorkItemId) {
        // we need to release so other agents can work on this one
        await onReleaseWorkItem(
          taskStatus.PENDING,
          'Marking work item as undetermined'
        );

        // closing modals / confirmations
        setShowUndeterminedConfirm(false);
        toggleDialog();

        // clear data
        clearData();

        // no need to release item on destroy
        setNeedToBeReleased(false);
      }
    } catch (error) {
      console.error(
        'CreateWorkItemDialog::onMarkWorkItemUndetermined error: ',
        error
      );
      logApiException(error, {
        view: 'CreateWorkItemDialog',
        endpoint: 'deleteWorkItem',
        id: currentWorkItemId
      });
    }
  };

  const onReleaseWorkItem = async (workStatus, reason) => {
    const workItemId = currentWorkItemId || workItemIdRef.current;
    if (!!workItemId && releaseFlagRef.current) {
      const input = { agentId: agentIdRef.current, workItemId, workStatus };

      if (reason) {
        input.reasonsForStatusChange = reason;
      }

      await releaseWorkItem(input);

      // no need to release item on destroy
      setNeedToBeReleased(false);
    } else {
      setNeedToBeReleased(true);
    }
  };

  const resetSearch = () => {
    setPatientSearchResults();
    setSelectedPatient();
    setPatientMode('search');
  };

  // useCallback to memoize the callback function
  const handleUnmount = useCallback(() => {
    if (releaseFlagRef.current) {
      // return (release) work item with setting status back to 'IN_PROGRESS'
      onReleaseWorkItem(
        taskStatus.IN_PROGRESS,
        'Releasing work from the inbound'
      );
    }
  }, []);

  const render = {
    patientNameCell: ({ dataItem }) => {
      return (
        <td>
          <AwesomeLabel
            icon='user'
            label={`${dataItem.firstName} ${dataItem.lastName}`}
          />
          <PatientInactiveBadge patientStatus={dataItem.patientStatus} />
        </td>
      );
    },
    selectPatientCell: ({ dataItem }) => {
      return (
        <td>
          <AwesomeLink
            onClick={() => {
              setSelectedPatient(dataItem);
              onLoadPatient(dataItem.id);
              setShowSearchResults(false);
            }}
            icon='hand-pointer'
            label='Select Patient'
          />
        </td>
      );
    },
    dobCell: ({ dataItem }) => {
      return <td>{formatDateToDefault(dataItem.dob)}</td>;
    }
  };

  const patientSearchForm = {
    searchPatientId: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          searchPatientId: {
            ...patientSearchForm.searchPatientId,
            value
          }
        });
      },
      validations: [
        {
          type: 'onlyNumeric',
          message: Constants.ErrorMessage.Numeric_Required
        }
      ]
    },
    searchPatientLastName: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          searchPatientLastName: {
            ...patientSearchForm.searchPatientLastName,
            value
          }
        });
      },
      validations: [
        {
          type: 'alpha',
          message: Constants.ErrorMessage.alpha_Required
        }
      ]
    },
    searchDateOfBirth: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          searchDateOfBirth: {
            ...patientSearchForm.searchDateOfBirth,
            value
          }
        });
      },
      validations: []
    }
  };

  const mainForm = {
    mainDocType: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          mainDocType: {
            ...mainForm.mainDocType,
            value
          }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        }
      ]
    },
    product: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          product: {
            ...mainForm.product,
            value
          }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        }
      ]
    },
    payer: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          payer: {
            ...mainForm.payer,
            value
          }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        }
      ]
    },
    plan: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          plan: {
            ...mainForm.plan,
            value
          }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        }
      ]
    }
  };

  const newPatientForm = {
    patientFirstName: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          patientFirstName: {
            ...newPatientForm.patientFirstName,
            value
          }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        },
        {
          type: 'alpha',
          message: Constants.ErrorMessage.Alpha_Required
        }
      ]
    },
    patientLastName: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          patientLastName: {
            ...newPatientForm.patientLastName,
            value
          }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        },
        {
          type: 'alpha',
          message: Constants.ErrorMessage.Alpha_Required
        }
      ]
    },
    patientDob: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          patientDob: {
            ...newPatientForm.patientDob,
            value
          }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        }
      ]
    },
    patientPhoneNumber: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          patientPhoneNumber: {
            ...newPatientForm.patientPhoneNumber,
            value
          }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        },
        {
          type: 'phonePattern',
          message: Constants.ErrorMessage.PhoneNumber_REQUIRED
        }
      ]
    }
  };

  const validateNewPatientForm = (values) => {
    const validationErrors = {};
    if (!values?.patientFirstName) {
      validationErrors.patientFirstName = Constants.ErrorMessage.is_REQUIRED;
    }

    if (!values?.patientLastName) {
      validationErrors.patientLastName = Constants.ErrorMessage.is_REQUIRED;
    }

    if (!values?.patientDob) {
      validationErrors.patientDob = Constants.ErrorMessage.is_REQUIRED;
    }

    if (!values?.patientPhoneNumber) {
      validationErrors.patientPhoneNumber = Constants.ErrorMessage.is_REQUIRED;
    }

    return validationErrors;
  };

  const validateMainForm = (values) => {
    const validationErrors = {};
    if (!values?.docType) {
      validationErrors.docType = Constants.ErrorMessage.is_REQUIRED;
    }

    // if not new order or refill - that's it for the validation
    if (!isNewOrRefill()) return validationErrors;

    if (!values?.product) {
      validationErrors.product = Constants.ErrorMessage.is_REQUIRED;
    }

    if (insuranceOption === 'select') {
      if (!values?.insuranceCompany) {
        validationErrors.payer = Constants.ErrorMessage.is_REQUIRED;
      }

      if (!values?.insurancePlan) {
        validationErrors.plan = Constants.ErrorMessage.is_REQUIRED;
      }
    }

    return validationErrors;
  };

  const isNewOrRefill = () =>
    [DOC_TYPE_REFERRAL, DOC_TYPE_ORDER_REFILL].includes(docType);

  const docTypes = getFaxDocTypes(selectedPatient);

  if (!showDialog) return null;

  return (
    <>
      {/* Create Work Item Dialog */}
      <CreateWorkItemDialogOverlay
        title='Create Work Item'
        onClose={() => {
          // close the modal
          toggleDialog();

          // clear data
          clearData();
        }}
        initialTop={50}
        initialHeight={1000}
        initialWidth={1200}
        onUnmount={handleUnmount}
      >
        <div className='row'>
          <div style={{ width: 650, paddingLeft: 15 }}>
            <PatientDocument file={pdfPath} />
          </div>
          <div className='col'>
            <div className='col-md-12' style={{ fontSize: 16 }}>
              <div className='row mt-3'>
                <div className='col-md-6'>
                  <AwesomeLink
                    onClick={toggleUndetermined}
                    icon='square-question'
                    label='Undetermined'
                  />
                </div>
                <div className='col-md-6' style={{ textAlign: 'right' }}>
                  {isAdmin && (
                    <AwesomeLink
                      onClick={toggleJunk}
                      icon='trash'
                      label='This is junk'
                      customClassName='remove-label'
                    />
                  )}
                </div>
              </div>
            </div>
            <hr />
            {selectedPatient?.lastName && (
              <>
                <div className='col-md-12' style={{ fontSize: 16 }}>
                  Selected Patient:{' '}
                  <b>
                    {selectedPatient.firstName} {selectedPatient.lastName}
                  </b>
                  , (DOB: {formatDateToDefault(selectedPatient.dob)})
                  <PatientInactiveBadge
                    patientStatus={selectedPatient.patientStatus}
                  />
                </div>
                <div className='col-md-12 pt-3'>
                  <AwesomeLink
                    onClick={resetSearch}
                    icon='user-magnifying-glass'
                    label='Make Another Search'
                    customClassName='remove-label'
                  />
                </div>
                <hr />
                <Form
                  onSubmit={onFormSubmit}
                  validator={validateMainForm}
                  render={(formRenderProps) => (
                    <form
                      onSubmit={formRenderProps.onSubmit}
                      className='k-form pl-3 pr-3 pt-1'
                    >
                      <div className='row'>
                        <div className='col-md-12'>
                          <div className='col-md-12'>
                            <h6>Determine Document Type:</h6>
                            <Field
                              name='docType'
                              data={docTypes}
                              value={docType}
                              onChange={(event) => setDocType(event.value)}
                              validator={mainForm.mainDocType.inputValidator}
                              component={FormRadioGroup}
                            />
                          </div>
                        </div>
                      </div>
                      {isNewOrRefill() && (
                        <>
                          <hr />
                          <div className='row'>
                            <div className='col-md-12'>
                              <div className='col-md-12'>
                                <h6>Select drug from the list:</h6>
                                <Field
                                  name='product'
                                  component={DropDownList}
                                  data={productList}
                                  textField='productOnSelector'
                                  valueField='productId'
                                  validator={mainForm.product.inputValidator}
                                />
                              </div>
                            </div>
                          </div>
                          <div className='col-md-12'>
                            <div className='row mt-1'>
                              <div className='col-md-12'>
                                <Field
                                  name='primaryDx'
                                  label='DX Code'
                                  placeholder='DX Code'
                                  component={AutoFilter}
                                  data={dxCodes}
                                  textField='codeDescription'
                                  valueField='code'
                                  onChangeCallback={(value) => setDxCode(value)}
                                />
                              </div>
                            </div>
                          </div>
                          <hr />
                          <div className='row'>
                            <div className='col-md-12'>
                              <div className='col-md-12'>
                                <h6>Insurance Plan:</h6>
                                <RadioGroup
                                  name='insuranceOption'
                                  layout='horizontal'
                                  data={[
                                    {
                                      label: 'Undetermined',
                                      value: 'undetermined'
                                    },
                                    {
                                      label: 'Select Insurance Company',
                                      value: 'select'
                                    }
                                  ]}
                                  value={insuranceOption}
                                  onChange={(event) =>
                                    setInsuranceOption(event.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          {insuranceOption === 'select' && (
                            <>
                              <div className='row pt-3'>
                                <div className='col-md-12'>
                                  <div className='col-md-12'>
                                    <h6>Select Insurance Company:</h6>
                                    <Field
                                      name='insuranceCompany'
                                      component={DropDownList}
                                      data={payers}
                                      textField='name'
                                      dataItemKey='id'
                                      onChange={(e) => {
                                        const { id } = e.target.value;
                                        setSelectedPayer(id);

                                        const insuranceCompany =
                                          getPayerById(id);
                                        const payerPlans =
                                          getPlansByInsurerName(
                                            insuranceCompany.name
                                          );

                                        setSelectedPayerPlans(payerPlans);
                                      }}
                                      validator={mainForm.payer.inputValidator}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className='row pt-3'>
                                <div className='col-md-12'>
                                  <div className='col-md-12'>
                                    <h6>Select Insurance Plan:</h6>
                                    <Field
                                      name='insurancePlan'
                                      component={DropDownList}
                                      data={selectedPayerPlans}
                                      textField='planName'
                                      dataItemKey='id'
                                      disabled={!selectedPayer}
                                      validator={mainForm.plan.inputValidator}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className='row pt-3'>
                                <div className='col-md-12'>
                                  <div className='col-md-12'>
                                    <Field
                                      name='isNotContracted'
                                      label='Currently not contracted'
                                      component={Checkbox}
                                    />
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}
                      <hr />
                      <div className='row p-3'>
                        <div className='col-md-12'>
                          <h6>Additional Notes:</h6>
                          <span>
                            These notes will be added to the patient notes
                          </span>
                          <Field
                            component={TextArea}
                            rows={6}
                            name='inboundNotes'
                            label='Notes'
                          />
                        </div>
                      </div>
                      <div className='row p-3'>
                        <div className={cx(styles.actions, 'col-12')}>
                          <button
                            type='submit'
                            className='k-button pageButton'
                            style={{ fontSize: 16 }}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                />
              </>
            )}

            {patientMode === 'search' && selectedPatient === undefined && (
              <Form
                onSubmit={onSubmitPatientSearch}
                render={(formRenderProps) => (
                  <form
                    onSubmit={formRenderProps.onSubmit}
                    className='k-form pl-3 pr-3 pt-1'
                  >
                    <div className='row pt-3'>
                      <div className='col-md-12'>
                        <div className='col-md-12'>
                          <h6>Search Patient By:</h6>
                          <RadioGroup
                            name='searchBy'
                            layout='horizontal'
                            data={[
                              { label: 'Last Name', value: 'lastName' },
                              { label: 'Patient ID', value: 'id' },
                              { label: 'DOB', value: 'dob' }
                            ]}
                            value={searchBy}
                            onChange={(event) => {
                              setSearchBy(event.value);
                              formRenderProps.onFormReset();
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    {searchBy === 'lastName' && (
                      <div className='col-md-12'>
                        <div className='row mt-1'>
                          <div className='col-md-12'>
                            <Field
                              component={Input}
                              name='searchLastName'
                              label='Patient Last Name'
                              validator={
                                patientSearchForm.searchPatientLastName
                                  .inputValidator
                              }
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {searchBy === 'id' && (
                      <div className='col-md-12'>
                        <div className='row mt-1'>
                          <div className='col-md-12'>
                            <Field
                              component={Input}
                              name='searchId'
                              label='Patient ID'
                              validator={
                                patientSearchForm.searchPatientId.inputValidator
                              }
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {searchBy === 'dob' && (
                      <div className='col-md-12'>
                        <div className='row'>
                          <div className='col-md-12 pt-4'>
                            <Field
                              name='searchDob'
                              component={DatePicker}
                              validator={
                                patientSearchForm.searchDateOfBirth
                                  .inputValidator
                              }
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <div className='row p-3'>
                      <div className='col-md-6'>
                        <button type='submit' className='k-button pageButton'>
                          Search
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              />
            )}
            {patientMode === 'new' && !selectedPatient && (
              <Form
                onSubmit={onSubmitNewPatient}
                validator={validateNewPatientForm}
                render={(formRenderProps) => (
                  <form
                    onSubmit={formRenderProps.onSubmit}
                    className='k-form pl-3 pr-3 pt-1'
                  >
                    <div className='row pt-3'>
                      <div className='col-md-12'>
                        <div className='col-md-12'>
                          <h6>Add New Patient:</h6>
                          <div className='row mt-3'>
                            <div className='col-md-6'>
                              <Field
                                component={Input}
                                name='patientFirstName'
                                label='Patient First Name'
                                validator={
                                  newPatientForm.patientFirstName.inputValidator
                                }
                              />
                            </div>
                            <div className='col-md-6'>
                              <Field
                                component={Input}
                                name='patientLastName'
                                label='Patient Last Name'
                                validator={
                                  newPatientForm.patientLastName.inputValidator
                                }
                              />
                            </div>
                          </div>
                          <div className='row mt-3'>
                            <div className='col-md-6 pt-4'>
                              <Field
                                name='patientDob'
                                component={DatePicker}
                                validator={
                                  newPatientForm.patientDob.inputValidator
                                }
                              />
                            </div>
                            <div className='col-md-6'>
                              <Field
                                component={Input}
                                name='patientPhoneNumber'
                                label='Patient Phone Number'
                                validator={
                                  newPatientForm.patientPhoneNumber
                                    .inputValidator
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='row p-3'>
                      <div className='col-md-6'>
                        <button
                          type='submit'
                          className='k-button pageButton'
                          disabled={!formRenderProps.allowSubmit}
                        >
                          Save Patient Info
                        </button>
                        <p>
                          <small>
                            <b>Note:</b> New patient profile will not be created
                            until you complete the work item
                          </small>
                        </p>
                      </div>
                      <div className='col-md-6' style={{ textAlign: 'right' }}>
                        <AwesomeLink
                          onClick={resetSearch}
                          icon='search'
                          label='Patient Search'
                          customClassName='remove-label'
                        />
                      </div>
                    </div>
                  </form>
                )}
              />
            )}
            <hr />
          </div>
        </div>
      </CreateWorkItemDialogOverlay>
      {/* Mark as Undetermined Dialog */}
      {showUndeterminedConfirm && (
        <Dialog
          title='Mark as Undetermined'
          onClose={toggleUndetermined}
          width={600}
        >
          <p className={styles.dialogBody}>
            This fax will be marked as PENDING and can be selected for the work
            later. Continue?
          </p>
          <DialogActionsBar layout='center'>
            <button
              className='k-button pageButton'
              onClick={onMarkWorkItemUndetermined}
            >
              <AwesomeLabel icon='check' label='Yes' />
            </button>
            <button
              className='k-button pageButton'
              onClick={toggleUndetermined}
            >
              <AwesomeLabel icon='ban' label='No' />
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
      {/* Mark as Junk Dialog */}
      {showJunkConfirm && (
        <Dialog title='Mark as Junk' onClose={toggleJunk} width={600}>
          <p className={styles.dialogBody}>
            This fax will be marked as junk and removed (canceled). Continue?
          </p>
          <DialogActionsBar layout='center'>
            <button
              className='k-button pageButton'
              onClick={onMarkWorkItemAsJunk}
            >
              <AwesomeLabel icon='check' label='Yes' />
            </button>
            <button className='k-button pageButton' onClick={toggleJunk}>
              <AwesomeLabel icon='ban' label='No' />
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
      {/* Patient Search Results Dialog */}
      <WindowDialog
        title='Patient Search Results'
        onClose={() => setShowSearchResults(false)}
        width={800}
        appendTo={document.body}
        showDialog={showSearchResults}
      >
        <Preloader show={loadingPatients}>
          <Grid data={patientSearchResults} fixedScroll>
            <Column field='id' title='Patient ID' width={150} />
            <Column title='Patient Name' cell={render.patientNameCell} />
            <Column field='dob' title='DOB' cell={render.dobCell} width={120} />
            <Column title='' cell={render.selectPatientCell} />
          </Grid>
          <DialogActionsBar layout='center' style={{ paddingTop: 10 }}>
            <div className='pt-3'>
              <AwesomeLink
                onClick={() => {
                  setPatientMode('search');
                  setShowSearchResults(false);
                }}
                icon='search'
                label='Patient Search'
              />
              <AwesomeLink
                onClick={() => {
                  setPatientMode('new');
                  setShowSearchResults(false);
                }}
                icon='user-plus'
                label='Add New Patient'
                customClassName='green pl-5'
              />
            </div>
          </DialogActionsBar>
        </Preloader>
      </WindowDialog>
      <CreateWorkItemDialogDrawer callback={onFormSubmitCallback} />
    </>
  );
};

export { CreateWorkItemDialog };
