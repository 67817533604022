import React, { useContext } from 'react';

// components
import AwesomeLabel from '@/components/common-components/AwesomeLabel';

// context
import { PatientContext, WorkItemContext } from '@/context';

// styles
import styles from './LinkToPatientProfile.module.scss';

// by default uses data from WorkItemContext
// if custom data sent over - using that one instead
const LinkToPatientProfile = ({ customData, customCssClass = 'link' }) => {
  const { selectedWorkItem } = useContext(WorkItemContext);
  const { goToProfile } = useContext(PatientContext);

  const patientId = customData
    ? customData.patientId
    : selectedWorkItem.patientId;
  const patientName = customData
    ? customData.patientName
    : `${selectedWorkItem.patientFirstName} ${selectedWorkItem.patientLastName}`;

  const renderPatientLink = () => {
    return (
      <span
        className={styles[customCssClass]}
        onClick={() => goToProfile(patientId)}
      >
        {patientName}
      </span>
    );
  };

  return (
    <AwesomeLabel
      label={renderPatientLink()}
      icon='user'
      title='Patient Name'
    />
  );
};

export { LinkToPatientProfile };
