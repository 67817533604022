import React, { useContext, useEffect, useState } from 'react';
// kendo
import { Form, Field } from '@progress/kendo-react-form';
import { Switch } from '@progress/kendo-react-inputs';
import { Dialog } from '@progress/kendo-react-dialogs';
// components
import FormButton from '@/components/common-components/Form/FormButton';
import Alert from '@/components/common-components/Alert';
//gql
import { connectToGraphqlAPI } from '@/provider';
import { checkReferralForDiscontinuation } from '@/graphql/queries';
import { archiveReferral } from '@/graphql/mutations';
// utils
import { Constants } from '@/constants';
import { validateInput, DropDownListField } from '@/common/Validation';
import { eventStatusForArchive } from '@/common/Mappers';
// context
import {
  PatientContext,
  StatusContext,
  NotifContext,
  ReferralContext
} from '@/context';

const reasonArchivingOrders = [
  {
    value: 'ENTRY_ERROR_DRUG_DOSE_ROUTE_UOM_TX',
    text: 'Entry Error - Drug, Dose, Route, UOM, Tx'
  },
  { value: 'ENTRY_ERROR_ICD_DATES_MD', text: 'Entry Error - ICD, Dates, MD' },
  {
    value: 'CR_ERROR_DRUG_DOSE_ROUTE_UOM_TX',
    text: 'CR Error - Drug, Dose, Route, UOM, Tx'
  },
  { value: 'CR_ERROR_ICD_DATES_MD', text: 'CR Error - ICD, Dates, MD' },
  { value: 'REFILL_RECEIVED', text: 'Refill Received' },
  { value: 'MD_CHANGED_ORDER', text: 'MD Changed Order' }
];

const ArchiveCurrentOrderModal = ({
  orderFormData,
  onClose,
  agentId,
  patientId,
  onChangeTab,
  onSelectArchiveOrder,
  removeReferralCallback,
  refList,
  referralId
}) => {
  const [referralFormData, setReferralFormData] = useState({});
  const [isReferralCopied, setIsReferralCopied] = useState(false);
  const { showError, showSuccess } = useContext(NotifContext);
  const [loading, setLoading] = useState(false);

  const { statusUpdate, setStatusUpdate } = useContext(StatusContext);
  const { setArchivedReason } = useContext(ReferralContext);
  const { getPatientAppointmentData } = useContext(PatientContext);

  const referralForm = {
    reasonForArchiving: {
      value: referralFormData?.reasonForArchiving || '',
      inputValidator: (value) => {
        return validateInput({
          reasonForArchiving: {
            ...referralForm.reasonForArchiving,
            value
          }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        }
      ]
    }
  };

  const archiveReferralCall = async (requestObject) => {
    setLoading(true);

    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: archiveReferral,
        variables: requestObject
      });

      if (data?.data?.archiveReferral) {
        showSuccess('Referral archived sucessfully.');
        setStatusUpdate(!statusUpdate);
        setLoading(false);
        return true;
      }
      return false;
    } catch (err) {
      showError(`CurrentOrder::archiveReferral err:${err}`);
      setLoading(false);
      return false;
    }
  };

  const checkAvailabilityCall = async (variables) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: checkReferralForDiscontinuation,
        variables
      });

      if (data?.data?.checkReferralForDiscontinuation?.statusCode === '200') {
        return true;
      }
      const errMsg =
        data.data.checkReferralForDiscontinuation.message || 'Unknown Reason';

      showError(`Order can not be archived: ${errMsg}`);
      return false;
    } catch (err) {
      showError(`CurrentOrder::checkAvailabilityCall err: ${err}`);
    }
  };

  useEffect(() => {
    if (orderFormData.referralId) {
      const formData = formatReferralFormData(orderFormData);
      setReferralFormData(formData);
    }
  }, [orderFormData]);

  const formatReferralFormData = (order) => {
    return {
      isReferralArchived: true,
      reasonForArchiving: order.reasonForArchiving || ''
    };
  };

  const handleArchiveOrder = async (dataItem) => {
    const appointmentData = await getPatientAppointmentData(patientId);
    const hasCheckedInPatient = appointmentData?.some(
      (appointment) =>
        appointment?.referralId === orderFormData?.referralId &&
        eventStatusForArchive.includes(appointment?.status)
    );
    if (hasCheckedInPatient) {
      showError(
        'Patient has checked in for this referral. Please cancel the appointment before archiving.'
      );

      return null;
    }
    // check if referral can be archived
    const canBeArchived = await checkAvailabilityCall({
      patientId,
      referralId: orderFormData.referralId
    });
    if (!canBeArchived) return null;

    const requestObject = {
      agentId,
      patientId,
      referralId: orderFormData.referralId,
      reasonForArchiving: dataItem.reasonForArchiving.value
    };
    const archived = await archiveReferralCall(requestObject);
    if (archived) {
      // update parent archived order info
      if (isReferralCopied && onSelectArchiveOrder) {
        onSelectArchiveOrder();
        onChangeTab(0);
        setArchivedReason(requestObject.reasonForArchiving);
      }
      // remove order from UI
      removeReferralCallback(refList, referralId, isReferralCopied);
      onClose();
    }
  };

  return (
    <div>
      <Dialog
        title='Archive Current Referral Order'
        width={500}
        height='auto'
        onClose={onClose}
      >
        <Form
          onSubmit={handleArchiveOrder}
          initialValues={referralFormData}
          key={JSON.stringify(referralFormData)}
          render={(formRenderProps) => {
            return (
              <form
                id='formOrder'
                onSubmit={formRenderProps.onSubmit}
                className='k-form pl-3 pr-3 mt-3'
              >
                <div className='row'>
                  <div className='col-md-12'>
                    <Alert type='warning'>
                      Please check to make sure there are no active appointments
                      before archiving
                    </Alert>
                  </div>
                  <div className='col-12 mt-06'>
                    Reasons to Archive: &nbsp;
                    <Field
                      name='reasonForArchiving'
                      component={DropDownListField}
                      data={reasonArchivingOrders}
                      validator={referralForm.reasonForArchiving.inputValidator}
                      textField='text'
                      dataItemKey='value'
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12 mt-08'>
                    Create a Duplicate Order: &nbsp;
                    <Field
                      component={Switch}
                      name='referralCopied'
                      onLabel='Yes'
                      offLabel='No'
                      defaultChecked={isReferralCopied}
                      onChange={(event) => setIsReferralCopied(event.value)}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12 mt-08'>
                    <FormButton
                      className='k-button blue'
                      type='submit'
                      disabled={
                        !formRenderProps.allowSubmit || !formRenderProps.valid
                      }
                      label={
                        isReferralCopied ? ' Archive & Create a New' : 'Archive'
                      }
                      loading={loading}
                    />
                  </div>
                </div>
              </form>
            );
          }}
        />
      </Dialog>
    </div>
  );
};

export default ArchiveCurrentOrderModal;
