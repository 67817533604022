import libphonenumber from 'google-libphonenumber';

const PNF = libphonenumber.PhoneNumberFormat;
const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

// (203) 192-1928 ===> +12031921928
export const convertToE164 = (thePhoneNumber) => {
  if (
    !thePhoneNumber ||
    thePhoneNumber === '' ||
    thePhoneNumber === '(___) ___-____'
  ) {
    // alert("BLANK")
    return null;
  }
  // alert(thePhoneNumber)

  const number = phoneUtil.parseAndKeepRawInput(thePhoneNumber, 'US');
  return phoneUtil.format(number, PNF.E164);
};

// +12031921928 ===> (203) 192-1928
export const convertToUS = (thePhoneNumber) => {
  if (
    !thePhoneNumber ||
    thePhoneNumber === '' ||
    thePhoneNumber === '(___) ___-____'
  ) {
    // alert("BLANK")
    return null;
  }
  // alert(thePhoneNumber)

  const number = phoneUtil.parseAndKeepRawInput(
    thePhoneNumber.slice(-10),
    'US'
  );
  return phoneUtil.formatInOriginalFormat(number, 'US');
};

// 123-456-7890 => (123) 456-7890  used for remapping incoming api phone number data for display in maskedEdit
export const reFormatUSPhoneNumber = (phoneNumberString) => {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return ''; // 08.15.22 aic-2892 changed from null which causing validation errors.
};
