import React, { useState, useContext } from 'react';
import { Storage } from 'aws-amplify';
import styled from 'styled-components';

// kendo
import { Button } from '@progress/kendo-react-buttons';

// context
import { WorkItemContext } from '@/context';

// components
import WindowDialog from '@/components/common-components/WindowDialog';
import PatientDocument from '@/components/Patient/PatientDocument';

// utils
import { BLUE, WHITE } from '@/constants/colors';
import AwesomeLabel from '@/components/common-components/AwesomeLabel';

// styled components
const PreviewBtn = styled(Button)`
  width: 170px !important;
  background-color: ${BLUE.button};
  color: ${WHITE.plain};
  font-weight: 500;
  background-image: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 15px;
`;

const PreviewDocumentWrapper = styled.div`
  padding-right: 12px;
  display: inline-block;
`;

const PreviewDocument = () => {
  const { selectedWorkItem } = useContext(WorkItemContext);
  const [selectedDocumentUrl, setSelectedDocumentUrl] = useState('');
  const [showPatientDocDialog, setShowPatientDocDialog] = useState(false);

  const onPreviewDocument = async (documentPath) => {
    const s3ImageURL = await Storage.get(documentPath, { download: false });
    setSelectedDocumentUrl(s3ImageURL);
    setShowPatientDocDialog(true);
  };

  return (
    <PreviewDocumentWrapper>
      <PreviewBtn
        onClick={() =>
          onPreviewDocument(selectedWorkItem?.attachedData?.documentPath)
        }
      >
        <AwesomeLabel icon='magnifying-glass' label='Preview Document' />
      </PreviewBtn>
      <WindowDialog
        appendTo={document.body}
        title='Patient Document'
        width={800}
        height={955}
        initialTop={0}
        initialLeft={300}
        showDialog={showPatientDocDialog}
        onClose={() => setShowPatientDocDialog(false)}
      >
        <PatientDocument file={selectedDocumentUrl} />
      </WindowDialog>
    </PreviewDocumentWrapper>
  );
};

PreviewBtn.displayName = 'PreviewBtn';
PreviewDocumentWrapper.displayName = 'PreviewDocumentWrapper';

export default PreviewDocument;
