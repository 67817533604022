import React, {useState, useEffect} from 'react'

import {TabStrip, TabStripTab} from '@progress/kendo-react-layout'

import OrderingQueue from './OrderingQueue'
import OrderProduct from './OrderProduct'
import ReceiveProduct from './ReceiveProduct'
import History from './History'


const Ordering = (props) => {
	
	const inventoryData = props.inventoryData

	const [selectedTab, setSelectedTab] = useState(0)

	const handleSelect = (e) => {
		setSelectedTab(e.selected)
	}	

	// CHOOSE/NAVIGATE TO SUB-TAB
	useEffect(() => {
		// const {searchType = 'HCP'} = props.location.state || {searchType : null}
		const searchType = props?.history?.location?.state?.searchType || null

		const tabs = ['OrderingQueue','OrderProduct','ReceiveProduct','History'];

		tabs.forEach((tab, index) => {
			if (tab === searchType) {
				setSelectedTab(index);
			}
		})

		// if (searchType === ) {
		// 	activeTab = 0
		// } else if (searchType === ) {
		// 	activeTab = 1
		// } else if (searchType === ) {
		// 	activeTab = 2
		// } else if (searchType === ) {
		// 	console.log('MCN: searchType: selectedTab:3')
		// 	activeTab = 3
		// }	
	}, [props])

	return (
		<div className="ordering-tab second-tabstrip-container">
			<TabStrip selected={selectedTab} onSelect={handleSelect}>
				<TabStripTab title="Ordering Queue">
					<OrderingQueue
						inventoryData={inventoryData}
						history={props.history} 
					/>
				</TabStripTab>
				<TabStripTab title="Order Product">
					<OrderProduct
						inventoryData={inventoryData}
						history={props.history} 
					/>
				</TabStripTab>
				<TabStripTab title="Receive Product">
					<ReceiveProduct
						inventoryData={inventoryData}
						history={props.history} 
					/>
				</TabStripTab>
				<TabStripTab title="Order History">
					<History
						inventoryData={inventoryData}
						history={props.history} 
					/>
				</TabStripTab>
			</TabStrip>
		</div>
	)
}

export default Ordering