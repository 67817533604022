export const regularVendors = [
  'AleraCare Pharmacy of AZ',
  'AleraCare Pharmacy of CA',
  'Amerisource Bergen',
  'Baxalta US Inc.',
  'Besse Medical',
  'Biocare',
  'Biologix Direct',
  'Cardinal Physician Office Oncology',
  'CuraScript SD',
  'Eversana Life Science Services, LLC',
  'FFF Enterprises',
  'Johnson & Johnson DePuy Synthes',
  'McKesson Pharmaceutical Distribution',
  'McKesson Plasma & Biologics',
  'McKesson Specialty Health',
  'Patient Supplied Medication',
  'Sanofi Genzyme',
  'Takeda Pharmaceutical',
  'Undetermined'
];

export const freeDrugVendors = [
  'Acaria Health (Aduhelm)',
  'Acaria Health (Tysabri Free Drug)',
  'Accredo Pharmacy (Entyvio Connect)',
  'AleraCare Pharmacy of AZ',
  'AleraCare Pharmacy of CA',
  'Amgen Safety Net Foundation',
  'AstraZenica',
  'Biogen Support Services',
  'Entyvio Connect Patient Assistance',
  'Gateway to Nucala',
  'Genentech Patient Foundation',
  'Home Scripts',
  'Horizon Therapeutics',
  'Johnson and Johnson Foundation',
  'J&J Patient Assistance',
  'Lundbeck Foundation',
  'Medvantix (Genenetech Patient Foundation)',
  'myAbbVie Assist',
  'Novartis',
  'Organon Access Program',
  'Pfizer Encompass',
  'Rx Crossroads Takeda',
  'Sonexus Health',
  'Tezspire Connect',
  'Undetermined',
  'Vasco Specialty Pharmacy'
];
