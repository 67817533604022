import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import UserPool from './UserPool';

export const authUser = (username, password, cbSuccess, cbFailure) => {
  const user = new CognitoUser({
    Username: username,
    Pool: UserPool
  });
  const authDetails = new AuthenticationDetails({
    Username: username,
    Password: password
  });

  // by default it uses 'USER_SRP_AUTH' which we don't support
  // check OPTML-1456 for details
  // though, in the production we must have SRP
  const flowType =
    process.env.REACT_APP_ENV === 'prod'
      ? 'USER_SRP_AUTH'
      : 'USER_PASSWORD_AUTH';
  user.setAuthenticationFlowType(flowType);

  user.authenticateUser(authDetails, {
    onSuccess: (data) => {
      if (cbSuccess) {
        cbSuccess(data);
      }
    },

    onFailure: (err) => {
      console.error('authUser action failed:', err);

      if (cbFailure) {
        cbFailure('Invalid username or password');
      }
    },

    newPasswordRequired: (data) => {
      console.warn('newPasswordRequired:', data);

      if (cbFailure) {
        cbFailure('Your password expired. Please, update your password.');
      }
    }
  });
};
