import React, { useState, useContext } from "react";

import { Field } from "@progress/kendo-react-form";
import { Grid } from "@progress/kendo-react-grid";
import { GridColumn as Column } from "@progress/kendo-react-grid/dist/npm/GridColumn";
import { DatePicker } from "@progress/kendo-react-dateinputs";

import { Constants } from "@/constants";

import { InputField } from "@/common/Validation";
import { formatDateToDefault } from "@/common/DateHelper";

import { NotifContext } from "@/context/NotifContext";
import DeleteButton from "@/components/common-components/Form/DeleteButton";

const ReferralAdverseEvents = ({
  adverseEvents,
  addAdverseEvent,
  removeAdverseEvent,
  formRenderProps
}) => {
  const { showWarning } = useContext(NotifContext);

  const adverseEventInitialValues = {
    advEventDate: "",
    advEventDrugName: "",
    advEventDetails: ""
  };
  const [adverseEvent, setAdverseEvent] = useState(adverseEventInitialValues);

  const showFormInvalidDialog = () => {
    showWarning(Constants.ErrorMessage.allFields_REQUIRED);
  };

  const resetAdverseEventForm = () => {
    formRenderProps.onChange("advEventDate", { value: "" });
    formRenderProps.onChange("advEventDrugName", { value: "" });
    formRenderProps.onChange("advEventDetails", { value: "" });
  };

  const dateCell = props => {
    const val = props.dataItem.date
      ? formatDateToDefault(props.dataItem.date)
      : "N/A";

    return <td>{val}</td>;
  };

  const handleAdd = () => {
    const { advEventDate, advEventDrugName, advEventDetails } = adverseEvent;

    if (!advEventDate || !advEventDrugName || !advEventDetails) {
      return showFormInvalidDialog();
    }

    // handle main form state
    addAdverseEvent(adverseEvent);

    // handle local state
    setAdverseEvent(adverseEvent);
    resetAdverseEventForm();
  };

  const removeActionCell = props => {
    return (
      <td>
        <DeleteButton handleClick={() => {
            resetAdverseEventForm();
            removeAdverseEvent(props.dataIndex);
          }} />
        {/* <button
          type="button"
          onClick={() => {
            resetAdverseEventForm();
            removeAdverseEvent(props.dataIndex);
          }}
        >
          Delete
        </button> */}
      </td>
    );
  };

  return (
    <div className="pl-3 pr-3 pt-1">
      <div
        className="infusion-details col-md-12 mt-2 mb-3"
        style={{ border: "1px solid #afaaaa" }}
      >
        <div className="row">
          <div className="infusion-HeaderRow col-12 ml-0 pl-2 py-2 mr-0">
            <div className="row">
              <div className="col-md-12 headerText">ADVERSE EVENTS</div>
            </div>
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-md-3">
            <Field
              name="advEventDate"
              label="Date"
              component={DatePicker}
              onChange={e => {
                setAdverseEvent(adv => ({
                  ...adv,
                  advEventDate: e.value
                }));
              }}
            />
          </div>
          <div className="col-md-3">
            <Field
              name="advEventDrugName"
              label="Drug Name"
              component={InputField}
              onChange={e => {
                setAdverseEvent(adv => ({
                  ...adv,
                  advEventDrugName: e.value
                }));
              }}
            />
          </div>
          <div className="col-md-4">
            <Field
              name="advEventDetails"
              label="Adverse Event"
              component={InputField}
              onChange={e => {
                setAdverseEvent(adv => ({
                  ...adv,
                  advEventDetails: e.value
                }));
              }}
            />
          </div>
          <div className="col-md-1 mt-12">
            <button type="button" className="k-button blue" onClick={handleAdd}>
              ADD
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 mt-12 mb-2">
            <Grid className="infusion-grid" data={adverseEvents}>
              <Column field="date" title="DATE" cell={dateCell} />
              <Column field="drugName" title="DRUG NAME" />
              <Column field="details" title="ADVERSE EVENT" />
              <Column title="" cell={removeActionCell} />
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferralAdverseEvents;
