import React, { useContext, useEffect, useState, useCallback, useMemo } from 'react';
import { Storage } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { DateRangePicker } from '@progress/kendo-react-dateinputs';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { process } from '@progress/kendo-data-query';
import styled from 'styled-components';
import * as moment from 'moment';
import { connectToGraphqlAPI } from '@/provider';
import { getPatientBucket, getHistoryRecordsByTime } from '@/graphql/queries';

import { UserContext } from '@/context/UserContext';
import { PatientContext } from '@/context/PatientContext';
import { NotifContext } from '@/context/NotifContext';

import { formatDateTimeToDefault } from '@/common/DateHelper';
import { ColumnMenu } from '@/components/common-components/columnMenu';
import { MessageDialog } from '@/components/common-components/MessageDialog';
import WindowDialog from '@/components/common-components/WindowDialog';
import Preloader from '@/components/common-components/Preloader';
import PatientDocument from '../Patient/PatientDocument';
import AwesomeLink from '../common-components/AwesomeLink';
import AwesomeLabel from '../common-components/AwesomeLabel';

const ActionLink = styled.div`
  padding: 3px 0;
`;

const FailedFaxQueue = () => {
  const history = useHistory();
  const { user } = useContext(UserContext);
  const { setSelectedPatientInfo } = useContext(PatientContext);
  const { showError } = useContext(NotifContext);

  const [loading, setLoading] = useState(true);
  const [faxItems, setFaxItems] = useState([]);

  const initialDataState = {};

  const [dataState, setDataState] = useState();
  const [resultState, setResultState] = useState(
    process(faxItems, initialDataState)
  );

  const [dialogOption, setDialogOption] = useState({});
  const [generatedPdfPath, setGeneratedPdfPath] = useState('');
  const [showPatientFaxDocumentDialog, setShowPatientFaxDocumentDialog] =
    useState(false);

  const _exporter = React.createRef();
  const exportExcel = () => {
    if (_exporter.current) {
      _exporter.current.save();
    }
  };

  const [dateRange, setDateRange] = React.useState({
    start: new Date(moment().subtract(1, 'month')),
    end: new Date()
  });

  const filterDateRange = useMemo(() => {
    if (!dateRange.start) return null;
    if (!dateRange.end) return null;
    if (isNaN(dateRange.start.getTime()) || dateRange.start.getTime() < 0) return null;
    if (isNaN(dateRange.end.getTime()) || dateRange.end.getTime() < 0) return null;
    if (dateRange.start.getTime() - dateRange.end.getTime() > 0) return null;

    return {
      startDate: moment(dateRange.start).utcOffset(0).startOf('day').toISOString(),
      endDate: moment(dateRange.end).utcOffset(0).endOf('day').toISOString(),
    }
  }, [dateRange]);
  const handleDateRangeChange = (event) => {
    setDateRange(event.value);
  };

  const onDataStateChange = useCallback(
    (e) => {
      setDataState(e.dataState);
      setResultState(process(faxItems, e.dataState));
    },
    [faxItems]
  );

  const handleOnDialogClose = () => {
    setShowPatientFaxDocumentDialog(false);
  };

  const showPatientFaxDocument = () => {
    setShowPatientFaxDocumentDialog(true);
  };

  const handlePatientFaxHistoryLinkClick = async (e, dataItem) => {
    if (dataItem?.patientId) {
      await getPatientBucketData(dataItem.patientId, 'benInv', true);
    }

    e.stopPropagation();
  };

  const handlePerviewFax = async ({ dataItem }) => {
    const conf = { download: false };
    const s3ImageURL = await Storage.get(dataItem.reference, conf);
    setGeneratedPdfPath(s3ImageURL);
    showPatientFaxDocument();
  };

  const actionsCell = ({ dataItem }) => {
    const renderPreviewLink = () => (
      <AwesomeLink
        onClick={() => handlePerviewFax({ dataItem })}
        icon='file-lines'
        label='Preview Fax'
      />
    );

    const renderPatientLink = () => (
      <AwesomeLink
        onClick={(e) => handlePatientFaxHistoryLinkClick(e, dataItem)}
        icon='file-user'
        label='Patient Fax History'
      />
    );

    return (
      <td>
        <ActionLink>{renderPreviewLink()}</ActionLink>
        <ActionLink>{renderPatientLink()}</ActionLink>
      </td>
    );
  };

  const dateCell = ({ dataItem }) => {
    return <td>{formatDateTimeToDefault(dataItem.eventTime)}</td>;
  };

  const columns = [
    { field: 'faxId', title: 'Fax ID' },
    { field: 'eventTime', title: 'Date', cell: dateCell },
    { field: 'causesForFailure', title: 'Reason' },
    { field: 'purpose', title: 'Subject' },
    { field: 'toEntity', title: 'Fax To' },
    { field: 'fromEntity', title: 'Fax Sender' },
    { field: '', title: 'Actions', cell: actionsCell }
  ];

  // MAIN INITIATOR
  useEffect(() => {
    if (user && filterDateRange) {
      getFailedFaxCall();
    }
  }, [user, filterDateRange]);

  useEffect(() => {
    if (!loading) {
      setDataState(initialDataState);
      setResultState(process(faxItems, initialDataState));
    }
  }, [faxItems]);

  const getPatientBucketData = async (
    patientId,
    currentStage,
    redirectToFaxHistory = false
  ) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getPatientBucket,
        variables: { patientId }
      });

      if (data && data.data && data.data.getPatientBucket) {
        setSelectedPatientInfo(data.data.getPatientBucket);
        if (redirectToFaxHistory) {
          history.push('/patient-portal', {
            searchType: 'FAX',
            redirectPatienFaxHistory: true
          });
        } else {
          history.push('/patient-portal', {
            searchType: currentStage,
            redirect2BI: true
          });
        }
      } else {
        showError('No Patient Record Found');
      }
    } catch (err) {
      console.log('FailedFaxQueue::getPatientBucketData err:', err);
      showError('Error at patient bucket method. Check console for details');
    }
  };

  const getFailedFaxCall = async () => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getHistoryRecordsByTime,
        variables: {
          status: 'FAILED',
          period: filterDateRange
        }
      });
      let faxItems = data.data?.getHistoryRecordsByTime;
      let sNextToken = data?.data?.getHistoryRecordsByTime?.nextToken;
      
      while (!!sNextToken) {
        try {
          const data = await connectToGraphqlAPI({
            graphqlQuery: getHistoryRecordsByTime,
            variables: {
              status: 'FAILED',
              period: filterDateRange,
              nextToken: sNextToken
            }
          });

          sNextToken = data?.data?.getHistoryRecordsByTime?.nextToken;
          if (data?.data?.getHistoryRecordsByTime?.length) {
            faxItems = faxItems.concat(
              data?.data?.getHistoryRecordsByTime
            );
          }
        } catch (err) {
          console.error('FaildFaxQueue::getHistoryRecordsByTime err:', err);
          sNextToken = null;
        }
      }
      setLoading(false);
      setFaxItems(faxItems);
    } catch (err) {
      console.error('FaildFaxQueue::getHistoryRecordsByTime err:', err);
      setLoading(false);
      setFaxItems([]);
      showError('Error: Failed to fetch failed fax queue.');
    }
  };

  const onRowClickHandle = (e) => {
    if (e?.dataItem?.patientId) {
      return getPatientBucketData(e.dataItem.patientId, 'benInv', true);
    }

    console.error(
      "FailedFaxQueue error: work item doesn't have expected values"
    );
  };

  return (
    <div>
      {dialogOption && dialogOption.showDialog && (
        <MessageDialog dialogOption={dialogOption} />
      )}
      <div className='container-fluid agent-work-queue'>
        {user?.username && (
          <div className='row my-3'>
            <div className='col-12 col-lg-12 work-in-progress'>
              <div className='row'>
                <div className='col-12 col-lg-12 pb-3'>
                  <DateRangePicker
                    value={dateRange}
                    onChange={handleDateRangeChange}
                  />
                  <Button
                    style={{ color: '#005282', float: 'right' }}
                    look='flat'
                    onClick={exportExcel}
                  >
                    <AwesomeLabel icon='file-excel' label='Export to Excel' />
                  </Button>
                </div>
              </div>

              <Preloader show={loading}>
                <ExcelExport data={resultState.data} ref={_exporter}>
                  <Grid
                    className='queue_grid'
                    onRowClick={onRowClickHandle}
                    style={{ cursor: 'pointer' }}
                    data={{ data: resultState.data }}
                    sortable
                    onDataStateChange={onDataStateChange}
                    {...dataState}
                  >
                    {columns.map((column, index) => {
                      return (
                        <Column
                          field={column.field}
                          title={column.title}
                          key={index}
                          width={column.minWidth}
                          columnMenu={ColumnMenu}
                          cell={column.cell}
                        />
                      );
                    })}
                  </Grid>
                </ExcelExport>
              </Preloader>
            </div>
          </div>
        )}
      </div>
      <WindowDialog
        title='Fax preview'
        style={{ backgroundColor: '#e9ecef', minHeight: '300px' }}
        initialHeight={800}
        initialTop={150}
        initialLeft={550}
        width={800}
        showDialog={showPatientFaxDocumentDialog}
        onClose={handleOnDialogClose}
      >
        <PatientDocument file={generatedPdfPath} />
      </WindowDialog>
    </div>
  );
};

export default FailedFaxQueue;
