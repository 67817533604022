import React, { useContext, useState } from 'react';

// components
import AwesomeLabel from '@/components/common-components/AwesomeLabel';
import ConfirmationModal from '@/components/common-components/ConfirmationModal';

// context
import { WorkItemContext } from '@/context/WorkItemContext';

// constants
import { returnReasons } from '@/constants/dict';

// styles
import styles from './ItemActions.module.scss';

const ReturnItem = () => {
  const { selectedWorkItem, returnWorkItem } = useContext(WorkItemContext);
  const [isVisible, setIsVisible] = useState(false);

  const onPositveSubmit = () => {
    returnWorkItem(selectedWorkItem, returnReasons.BY_AGENT);
    setIsVisible(false);
  };
  return (
    <>
      <button
        className={styles.minButtonWidth}
        onClick={() => setIsVisible(true)}
      >
        <AwesomeLabel icon='rotate-left' label='Return Work Item' />
      </button>
      <ConfirmationModal
        isVisible={isVisible}
        onClose={() => setIsVisible(false)}
        onReject={() => setIsVisible(false)}
        onConfirm={() => onPositveSubmit()}
      />
    </>
  );
};

export default ReturnItem;
