import React, { useEffect, useContext, useState } from 'react';

// kendo
import { Checkbox } from '@progress/kendo-react-inputs';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { DropDownList } from '@progress/kendo-react-dropdowns';

// components
import { FormRadioGroup } from '@/components/common-components/FormRadioGroup';
import Alert from '@/components/common-components/Alert';
import RequiredFormLabel from '@/components/common-components/RequiredFormLabel';
import AwesomeLabel from '@/components/common-components/AwesomeLabel';
import PatientInfoUpdate from './PatientInfoUpdate';
import PatientStatusChange from './PatientStatusChange';
import DisplayFutureStatus from './DisplayFutureStatus';

// utils
import {
  InputField,
  validateInput,
  DropDownListField,
  MaskedSSNInputField
} from '@/common/Validation';
import { formatSSN } from '@/common/Utility';
import { states } from '@/common/states';
import { Constants } from '@/constants';
import { convertToE164, convertToUS } from '@/common/PhoneNumberConverter';
import {
  formatDateToDefault,
  formatDateToAWSDateTime
} from '@/common/DateHelper';
import { generateSurveyOptOutNote } from '@/components/SurveyEngine/helpers/helperQOL';

// gql
import { connectToGraphqlAPI } from '@/provider';
import { getPatientBucket } from '@/graphql/queries';
import { addUpdateNotes, addUpdatePatientInfo } from '@/graphql/mutations';

// context
import {
  UserRoleTypes,
  StatusContext,
  UserContext,
  PatientContext,
  LogContext,
  NotifContext
} from '@/context';

const PatientInfoGeneral = () => {
  const { logApiException } = useContext(LogContext);
  const { showError, showSuccess } = useContext(NotifContext);
  const { user, canEdit } = useContext(UserContext);
  const { statusUpdate, setStatusUpdate } = useContext(StatusContext);
  const { selectedPatientInfo, setSelectedPatientInfo } =
    useContext(PatientContext);

  const [noEmail, setNoEmail] = useState(false);
  const [emailEntered, setEmailEntered] = useState(false);
  const [bestContactValue, setBestContactValue] = useState(
    selectedPatientInfo?.patientProfile?.patientInfo?.bestContact || ''
  );
  const [communicationValue, setCommunicationValue] = useState(
    selectedPatientInfo?.patientProfile?.patientInfo
      ?.preferredContactMethod?.[0] || 'TEXT'
  );
  const [showSecondaryAddress, setShowSecondaryAddress] = useState(false);

  const preferredLanguages = ['ENGLISH', 'SPANISH'];

  const gender = [
    { label: 'Female', value: 'FEMALE', className: 'patient-radio blue' },
    { label: 'Male', value: 'MALE', className: 'patient-radio blue' },
    { label: 'Other', value: 'OTHER', className: 'patient-radio blue' }
  ];

  const bestContact = [
    { label: 'Patient', value: 'PATIENT', className: 'patient-radio blue' },
    {
      label: 'Care Giver',
      value: 'CAREGIVER',
      className: 'patient-radio blue'
    },
    { label: 'Either', value: 'EITHER', className: 'patient-radio blue' }
  ];

  const preferences = [
    { label: 'Phone Call', value: 'PHONE', className: 'patient-radio blue' },
    {
      label: 'Email',
      value: 'EMAIL',
      className: 'patient-radio blue'
    },
    { label: 'Text', value: 'TEXT', className: 'patient-radio blue' }
  ];

  const booleanChoices = [
    { label: 'Yes', value: true, className: 'patient-radio blue' },
    { label: 'No', value: false, className: 'patient-radio blue' }
  ];

  const initialForm = () => {
    const initialObject = {};
    Object.keys(patientInfoForm).forEach((key) => {
      initialObject[key] = patientInfoForm[key].value;
    });

    return initialObject;
  };

  const validateSurveyOptOut = (patientInfo) => {
    const patientPreferences =
      patientInfo?.patientProfile?.patientInfo?.preference;

    if (!patientPreferences) return true;
    if (patientPreferences.surveys) return patientPreferences.surveys.preferred;

    return false;
  };

  const [formValues, setFormValues] = useState([]);
  const patientInfoForm = {
    CellPhoneNumber: {
      value: selectedPatientInfo?.patientProfile?.patientInfo?.cellphoneNumber
        ? convertToUS(
            selectedPatientInfo?.patientProfile?.patientInfo?.cellphoneNumber
          )
        : null,
      inputValidator: (value) => {
        return validateInput({
          CellPhoneNumber: { ...patientInfoForm.CellPhoneNumber, value }
        });
      },
      validations: [
        {
          type: 'phonePattern',
          message: Constants.ErrorMessage.PhoneNumber_FORMAT
        }
      ]
    },
    SSN: {
      value: selectedPatientInfo?.patientProfile?.patientInfo?.ssn || null,
      inputValidator: (value) => {
        return validateInput({
          SSN: {
            ...patientInfoForm.SSN,
            value: (value || '').replace(/-/g, '')
          }
        });
      },
      validations: [
        {
          type: 'minLength',
          length: 9,
          message: Constants.ErrorMessage.minLength_REQUIRED
        },
        {
          type: 'maxLength',
          length: 11,
          message: Constants.ErrorMessage.maxLength_REQUIRED
        }
      ]
    },
    gender: {
      value: selectedPatientInfo?.patientProfile?.patientInfo?.gender || null,
      inputValidator: (value) => {
        return validateInput({ gender: { ...patientInfoForm.gender, value } });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.Gender_REQUIRED
        }
      ]
    },
    patientWeight: {
      value:
        selectedPatientInfo.patientProfile?.patientInfo?.patientWeight || null,
      inputValidator: (value) => {
        return validateInput({
          patientWeight: { ...patientInfoForm.patientWeight, value }
        });
      },
      validations: [
        {
          type: 'onlyNumeric',
          message: Constants.ErrorMessage.Numeric_Required
        }
      ]
    },
    preferredLanguage: {
      value:
        selectedPatientInfo.patientProfile?.patientInfo?.preferredLanguage ||
        null,
      inputValidator: (value) => {
        return validateInput({
          preferredLanguage: { ...patientInfoForm.preferredLanguage, value }
        });
      },
      validations: []
    },
    Address: {
      value:
        selectedPatientInfo?.patientProfile?.patientInfo?.address?.streetName ||
        null,
      inputValidator: (value) => {
        return validateInput({
          Address: { ...patientInfoForm.Address, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.Address_REQUIRED
        }
      ]
    },
    State: {
      // search both abbreviation AND name
      value:
        states.find(
          (st) =>
            st.abbreviation ===
              selectedPatientInfo?.patientProfile?.patientInfo?.address
                ?.state ||
            st.name ===
              selectedPatientInfo?.patientProfile?.patientInfo?.address?.state
        ) || null,
      inputValidator: (value) => {
        return validateInput({ State: { ...patientInfoForm.State, value } });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.State_REQUIRED
        }
      ]
    },
    City: {
      value:
        selectedPatientInfo?.patientProfile?.patientInfo?.address?.city || null,
      inputValidator: (value) => {
        return validateInput({ City: { ...patientInfoForm.City, value } });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.City_REQUIRED
        },
        {
          type: 'alpha',
          message: Constants.ErrorMessage.Alpha_Required
        }
      ]
    },
    zip: {
      value:
        selectedPatientInfo?.patientProfile?.patientInfo?.address?.zip || null,
      inputValidator: (value) => {
        return validateInput({ Zip: { ...patientInfoForm.zip, value } });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.Zip_REQUIRED
        },
        {
          type: 'minLength',
          length: 5,
          message: Constants.ErrorMessage.minLength_REQUIRED
        },
        {
          type: 'maxLength',
          length: 5,
          message: Constants.ErrorMessage.maxLength_REQUIRED
        },
        {
          type: 'zipFormat',
          message: Constants.ErrorMessage.zipCode_FORMAT
        }
      ]
    },
    Email: {
      value: selectedPatientInfo?.patientProfile?.patientInfo?.email || null,
      inputValidator: (value) => {
        return validateInput({ Email: { ...patientInfoForm.Email, value } });
      },
      validations: [
        {
          type: 'emailPattern',
          message: Constants.ErrorMessage.EMAIL_REGX
        }
      ]
    },

    altContactFirstName: {
      value:
        selectedPatientInfo?.patientProfile?.patientInfo?.alternateContact
          ?.firstName || null,
      inputValidator: (value) => {
        return validateInput({
          altContactFirstName: { ...patientInfoForm.altContactFirstName, value }
        });
      },
      validations: [
        {
          type: 'alpha',
          message: Constants.ErrorMessage.Alpha_Required
        },
        {
          type: 'maxLength',
          length: 40,
          message: Constants.ErrorMessage.maxLength_REQUIRED
        }
      ]
    },
    altContactLastName: {
      value:
        selectedPatientInfo?.patientProfile?.patientInfo?.alternateContact
          ?.lastName || null,
      inputValidator: (value) => {
        return validateInput({
          altContactLastName: { ...patientInfoForm.altContactLastName, value }
        });
      },
      validations: [
        {
          type: 'alpha',
          message: Constants.ErrorMessage.Alpha_Required
        }
      ]
    },
    altContactRel: {
      value:
        selectedPatientInfo?.patientProfile?.patientInfo?.alternateContact
          ?.relationship,
      inputValidator: (value) => {
        return validateInput({
          altContactRel: { ...patientInfoForm.altContactRel, value }
        });
      },
      validations: []
    },
    altContactPhone: {
      value: selectedPatientInfo?.patientProfile?.patientInfo?.alternateContact
        ?.phone
        ? convertToUS(
            selectedPatientInfo?.patientProfile?.patientInfo?.alternateContact
              ?.phone
          )
        : '',
      inputValidator: (value) => {
        return validateInput({
          altContactPhone: { ...patientInfoForm.altContactPhone, value }
        });
      },
      validations: [
        {
          type: 'phonePattern',
          message: Constants.ErrorMessage.altContactPhone_REQUIRED
        }
      ]
    },

    hipaaContact: {
      value: !!selectedPatientInfo?.patientProfile?.patientInfo?.hipaaContact
    },

    bestContact: {
      value:
        selectedPatientInfo?.patientProfile?.patientInfo?.bestContact || null
    },
    AFTERNOON: {
      value:
        selectedPatientInfo?.patientProfile?.patientInfo?.bestTimeToContact?.includes(
          'AFTERNOON'
        ) || false
    },
    EVENING: {
      value:
        selectedPatientInfo?.patientProfile?.patientInfo?.bestTimeToContact?.includes(
          'EVENING'
        ) || false
    },
    ANYTIME: {
      value:
        selectedPatientInfo?.patientProfile?.patientInfo?.bestTimeToContact?.includes(
          'ANYTIME'
        ) || false
    },
    MORNING: {
      value:
        selectedPatientInfo?.patientProfile?.patientInfo?.bestTimeToContact?.includes(
          'MORNING'
        ) || false
    },
    EMAIL: {
      value:
        selectedPatientInfo?.patientProfile?.patientInfo?.preferredContactMethod?.includes(
          'EMAIL'
        ) || false
    },
    PHONE: {
      value:
        selectedPatientInfo?.patientProfile?.patientInfo?.preferredContactMethod?.includes(
          'PHONE'
        ) || false
    },
    TEXT: {
      value:
        selectedPatientInfo?.patientProfile?.patientInfo?.preferredContactMethod?.includes(
          'Text'
        ) || false
    },
    contactPatient: {
      value: selectedPatientInfo?.patientProfile?.patientInfo?.toContactPatient
        ? true
        : selectedPatientInfo?.patientProfile?.patientInfo?.toContactPatient ===
          false
        ? false
        : null
    },
    detailedMessage: {
      value: selectedPatientInfo?.patientProfile?.patientInfo?.toLeaveMessage
        ? true
        : selectedPatientInfo?.patientProfile?.patientInfo?.toLeaveMessage ===
          false
        ? false
        : null
    },
    surveyOptout: {
      value: validateSurveyOptOut(selectedPatientInfo)
    },
    // Second address fields
    secondaryAddress: {
      value:
        selectedPatientInfo?.patientProfile?.patientInfo?.secondaryAddress
          ?.streetName || null,
      inputValidator: (value) => {
        return validateInput({
          secondaryAddress: { ...patientInfoForm.secondaryAddress, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.Address_REQUIRED
        }
      ]
    },
    secondaryCity: {
      value:
        selectedPatientInfo?.patientProfile?.patientInfo?.secondaryAddress
          ?.city || null,
      inputValidator: (value) => {
        return validateInput({
          secondaryCity: { ...patientInfoForm.secondaryCity, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.City_REQUIRED
        },
        {
          type: 'alpha',
          message: Constants.ErrorMessage.Alpha_Required
        }
      ]
    },
    secondaryState: {
      value:
        states.find(
          (st) =>
            st.abbreviation ===
              (selectedPatientInfo?.patientProfile?.patientInfo
                ?.secondaryAddress?.state || '') ||
            st.name ===
              (selectedPatientInfo?.patientProfile?.patientInfo
                ?.secondaryAddress?.state || '')
        ) || null,
      inputValidator: (value) => {
        return validateInput({
          secondaryState: { ...patientInfoForm.secondaryState, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.State_REQUIRED
        }
      ]
    },
    secondaryZip: {
      value:
        selectedPatientInfo?.patientProfile?.patientInfo?.secondaryAddress
          ?.zip || null,
      inputValidator: (value) => {
        return validateInput({
          secondaryZip: { ...patientInfoForm.secondaryZip, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.Zip_REQUIRED
        },
        {
          type: 'minLength',
          length: 5,
          message: Constants.ErrorMessage.minLength_REQUIRED
        },
        {
          type: 'maxLength',
          length: 5,
          message: Constants.ErrorMessage.maxLength_REQUIRED
        },
        {
          type: 'zipFormat',
          message: Constants.ErrorMessage.zipCode_FORMAT
        }
      ]
    }
  };

  useEffect(() => {
    const hasData = patientInfoForm.secondaryAddress.value;

    if (hasData) {
      setShowSecondaryAddress(true);
    }
  }, [patientInfoForm.secondaryAddress.value]);

  const handleCheckboxChange = () => {
    setShowSecondaryAddress(!showSecondaryAddress);
  };

  useEffect(() => {
    const newForm = initialForm();
    setFormValues(newForm);
    if (communicationValue === 'EMAIL' && !newForm?.Email && !emailEntered) {
      setNoEmail(true);
    } else {
      setNoEmail(false);
    }
  }, [communicationValue, emailEntered]);

  const handleSubmit = async (dataItem) => {
    const preferredContactMethod = [];
    const bestTimeToContact = [];
    if (dataItem.communication) {
      preferredContactMethod.push(dataItem.communication);
    }
    if (dataItem.MORNING) {
      bestTimeToContact.push('MORNING');
    }
    if (dataItem.AFTERNOON) {
      bestTimeToContact.push('AFTERNOON');
    }
    if (dataItem.EVENING) {
      bestTimeToContact.push('EVENING');
    }
    if (dataItem.ANYTIME) {
      bestTimeToContact.push('ANYTIME');
    }

    const agentId = user.username;

    const requestObject = {
      // agentId: ID!
      agentId,
      // patientId: ID!
      patientId: selectedPatientInfo.patientId,

      // UN-EDITABLE FIELDS
      // patientFirstName: dataItem.firstName,
      // patientLastName: dataItem.lastName,
      // dob: moment(dataItem.dateOfBirth).format('YYYY-MM-DD'),
      // homePhoneNumber: dataItem.PhoneNumber,

      // patientInfo: PatientInfoInput
      patientInfo: {
        // patientWeight: Float
        // patientWeight: dataItem.patientWeight,
        // unitOfWeight: dataItem.unitOfWeight,
        patientWeightLB: dataItem.patientWeightLB,
        // gender: Gender
        gender: dataItem.gender ? dataItem.gender : null,
        // workPhone: AWSPhone
        // workPhone: dataItem.PhoneNumber,
        // cellphoneNumber: AWSPhone
        cellphoneNumber: dataItem.CellPhoneNumber
          ? convertToE164(dataItem.CellPhoneNumber)
          : null,
        // email: AWSEmail
        email: dataItem.Email ? dataItem.Email : null,
        // ssn: String
        // ssn: dataItem.SSN,
        // ssn: formatSSN(dataItem.SSN),
        ssn: formatSSN(dataItem.SSN),
        // preferredLanguage: Language
        preferredLanguage: dataItem.preferredLanguage,
        // toContactPatient: Boolean
        toContactPatient: dataItem.contactPatient,
        // toLeaveMessage: Boolean
        toLeaveMessage: dataItem.detailedMessage,
        // bestTimeToContact: [ContactTime]
        bestTimeToContact,
        // bestContact: ContactType
        bestContact: bestContactValue ? bestContactValue.toUpperCase() : null,
        // address: AddressInput
        address: {
          // streetName: String
          streetName: dataItem.Address,
          // city: String
          city: dataItem.City,
          // state: String
          // state: dataItem.State,
          state: dataItem.State?.abbreviation,
          // zip: String
          zip: dataItem.zip
          // county: String
          // county: dataItem.county
        },
        secondaryAddress: {
          streetName: dataItem.secondaryAddress,
          city: dataItem.secondaryCity,
          state: dataItem.secondaryState?.abbreviation,
          zip: dataItem.secondaryZip
        },
        // alternateContact: ContactDetailInput
        alternateContact: {
          // firstName: String
          firstName: dataItem.altContactFirstName,
          // lastName: String
          lastName: dataItem.altContactLastName,
          // relationship: String
          relationship: dataItem.altContactRel,
          // phone: AWSPhone
          phone: convertToE164(dataItem.altContactPhone)
          // preferredLanguage: Language
          // preferredLanguage: dataItem.altContactPreferredLanguage,
        },
        // preferredContactMethod: [ContactMethod]
        preferredContactMethod,
        // hipaaContact: Boolean
        hipaaContact: dataItem.hipaaContact,
        preference: {
          surveys: {
            preferred: dataItem.surveyOptout,
            updatedAt: formatDateToAWSDateTime(),
            reasons: '',
            updatedBy: agentId
          }
        }
      }
    };
    // generate patient notes only if the prev value has been changed
    // and the new value is set to "opt-out"
    if (
      dataItem.surveyOptout === false &&
      selectedPatientInfo.patientProfile?.patientInfo?.preference?.surveys
        ?.preferred !== dataItem.surveyOptout
    ) {
      // add new reason field
      requestObject.patientInfo.preference.surveys.reasons =
        generateSurveyOptOutNote(agentId);

      // add patient note
      await uploadOptOutNotes();
    }

    await addUpdatePatientInfoData(requestObject);
  };

  const addUpdatePatientInfoData = async (requestObject) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: addUpdatePatientInfo,
        variables: { input: requestObject }
      });

      if (data?.data?.addUpdatePatientInfo?.patientId) {
        getPatientBucketData(data.data.addUpdatePatientInfo.patientId);
        showSuccess('Patient saved sucessfully');
        setStatusUpdate(!statusUpdate);
      }
    } catch (err) {
      showError('Failed to update patient info');
      logApiException(err, {
        view: 'PatientInfoGeneral',
        endpoint: 'addUpdatePatientInfo',
        patientId: requestObject.patientId
      });
    }
  };

  const uploadOptOutNotes = async () => {
    const agentId = user.username;
    const note = generateSurveyOptOutNote(agentId);
    try {
      const requestObject = {
        patientId: selectedPatientInfo.patientId,
        agentId,
        notes: [
          {
            note,
            createdBy: agentId,
            date: formatDateToAWSDateTime(),
            type: 'PATIENT_COMMUNICATIONS',
            modifiedNote: false // send false to add new
          }
        ]
      };

      const data = await connectToGraphqlAPI({
        graphqlQuery: addUpdateNotes,
        variables: { input: requestObject }
      });

      if (!data.data?.addUpdateNotes?.notes) {
        showError(
          'Failed to save new Patient Notes (type - PATIENT_COMMUNICATIONS)'
        );
      }
    } catch (err) {
      showError('Failed to add patient notes');
      logApiException(err, {
        view: 'PatientInfoGeneral',
        endpoint: 'uploadOptOutNotes',
        patientId: selectedPatientInfo.patientId,
        agentId,
        note
      });
    }
  };

  const getPatientBucketData = async (patientId) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getPatientBucket,
        variables: { patientId }
      });

      if (data?.data?.getPatientBucket) {
        setSelectedPatientInfo(data.data.getPatientBucket);
        showSuccess('Patient re-loaded successfully');
      } else {
        showError('No Patient Record Found');
      }
    } catch (err) {
      showError('Failed to fetch patient data');
      logApiException(err, {
        view: 'PatientInfoGeneral',
        endpoint: 'getPatientBucket',
        patientId
      });
    }
  };

  const checkForEmail = (event) => {
    if (event.value.length > 0) {
      setEmailEntered(true);
    } else {
      setEmailEntered(false);
    }
  };

  const handleRadioChange = (event) => {
    setCommunicationValue(event.value);
  };
  return (
    <div className='k-form pl-3 pr-3 pt-1'>
      <PatientInfoUpdate />
      <Form
        onSubmit={handleSubmit}
        initialValues={formValues}
        key={JSON.stringify(formValues)}
        render={() => (
          <FormElement>
            <div className='row mt-3'>
              <div className='col-md-3 mt-2'>
                <AwesomeLabel
                  icon='user'
                  label={
                    <b>
                      {selectedPatientInfo?.patientFirstName}{' '}
                      {selectedPatientInfo?.patientLastName}
                    </b>
                  }
                  size='xl'
                  iconStyle='solid'
                  customClassName='icon-blue'
                />
              </div>
              <div className='col-md-3 mt-2'>
                <AwesomeLabel
                  icon='calendar'
                  title='DOB'
                  label={formatDateToDefault(selectedPatientInfo?.dob)}
                  size='xl'
                  iconStyle='solid'
                  customClassName='icon-blue'
                />
              </div>
              <div className='col-md-3 mt-2'>
                <AwesomeLabel
                  icon='phone'
                  title='Primary Phone'
                  label={convertToUS(selectedPatientInfo?.homePhoneNumber)}
                  size='xl'
                  iconStyle='solid'
                  customClassName='icon-blue'
                />
              </div>
            </div>
            <div className='row mt-10'>
              <PatientStatusChange />
            </div>
            <DisplayFutureStatus />
            <div className='row mt-10'>
              <div className='col-md-1 mt-12'>
                <RequiredFormLabel text='GENDER' />
              </div>
              <div className='col-md-4'>
                <Field
                  name='gender'
                  data={gender}
                  layout='horizontal'
                  component={FormRadioGroup}
                  validator={patientInfoForm.gender.inputValidator}
                />
              </div>
            </div>

            <div className='row mt-10'>
              <div className='col-md-4'>
                <Field
                  name='Address'
                  label={<RequiredFormLabel text='Address' />}
                  component={InputField}
                  validator={patientInfoForm.Address.inputValidator}
                />
              </div>
              <div className='col-md-2'>
                <Field
                  name='City'
                  label={<RequiredFormLabel text='City' />}
                  component={InputField}
                  validator={patientInfoForm.City.inputValidator}
                />
              </div>
            </div>
            <div className='row mt-10'>
              <div className='col-md-2'>
                <Field
                  name='State'
                  label={<RequiredFormLabel text='State' />}
                  component={DropDownListField}
                  data={states}
                  textField='name'
                  valueField='abbreviation'
                  validator={patientInfoForm.State.inputValidator}
                />
              </div>
              <div className='col-md-2'>
                <Field
                  name='zip'
                  label={<RequiredFormLabel text='ZIP' />}
                  component={InputField}
                  validator={patientInfoForm.zip.inputValidator}
                />
              </div>
              <div className='col-md-2'>
                <Field
                  name='SSN'
                  label='SSN'
                  component={MaskedSSNInputField}
                  validator={patientInfoForm.SSN.inputValidator}
                />
              </div>
            </div>
            <div>
              <div className='row mt-10'>
                <div className='col-md-4'>
                  <Checkbox
                    checked={showSecondaryAddress}
                    onChange={handleCheckboxChange}
                    label={'Add Secondary Address'}
                  />
                </div>
              </div>
            </div>

            {showSecondaryAddress && (
              <div>
                <hr />
                <div>
                  <div className='row mt-10'>
                    {/* secondaryAddress */}
                    <div className='col-md-4'>
                      <Field
                        name='secondaryAddress'
                        label={<RequiredFormLabel text='Secondary Address' />}
                        component={InputField}
                        validator={
                          showSecondaryAddress
                            ? patientInfoForm.secondaryAddress.inputValidator
                            : []
                        }
                      />
                    </div>
                    {/* secondaryCity */}
                    <div className='col-md-2'>
                      <Field
                        name='secondaryCity'
                        label={<RequiredFormLabel text='City' />}
                        component={InputField}
                        validator={
                          showSecondaryAddress
                            ? patientInfoForm.secondaryCity.inputValidator
                            : []
                        }
                      />
                    </div>
                  </div>
                  <div className='row mt-10'>
                    {/* secondaryState */}
                    <div className='col-md-2'>
                      <Field
                        name='secondaryState'
                        label={<RequiredFormLabel text='State' />}
                        component={DropDownListField}
                        data={states}
                        textField='name'
                        valueField='abbreviation'
                        validator={
                          showSecondaryAddress
                            ? patientInfoForm.secondaryState.inputValidator
                            : []
                        }
                      />
                    </div>
                    {/* secondaryZip */}
                    <div className='col-md-2'>
                      <Field
                        name='secondaryZip'
                        label={<RequiredFormLabel text='Zip' />}
                        component={InputField}
                        validator={
                          showSecondaryAddress
                            ? patientInfoForm.secondaryZip.inputValidator
                            : []
                        }
                      />
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            )}

            <div className='row mt-10'>
              <div className='col-md-2'>
                <Field
                  name='CellPhoneNumber'
                  label='Cell Phone'
                  component={InputField}
                  validator={patientInfoForm.CellPhoneNumber.inputValidator}
                />
              </div>
              {/* <div className="col-md-2">
                <Field
                  name="homePhoneNumber"
                  label="Home Phone"
                  component={InputField}
                  validator={patientInfoForm.CellPhoneNumber.inputValidator}
                />
              </div> */}
              <div className='col-md-3'>
                <Field
                  name='Email'
                  label='Email'
                  component={InputField}
                  validator={patientInfoForm.Email.inputValidator}
                  onChange={(event) => checkForEmail(event)}
                />
              </div>
              <div className='col-md-3'>
                <Field
                  name='preferredLanguage'
                  label='Preferred Language'
                  component={DropDownList}
                  data={preferredLanguages}
                />
              </div>
            </div>

            <div className='row mt-08'>
              <div className='col-md-2 mt-12'>BEST CONTACT:</div>
              <div className='col-md-6'>
                <Field
                  name='bestContact'
                  data={bestContact}
                  layout='horizontal'
                  component={FormRadioGroup}
                  defaultValue={bestContactValue}
                  onChange={(event) => setBestContactValue(event.value)}
                />
              </div>
            </div>

            {/* logic for selecting Caregiver or Either to show Alternate Contact Fields */}
            {bestContactValue === 'Caregiver' ||
            bestContactValue === 'Either' ? (
              <div className='row mt-12'>
                <div className='col-md-3'>
                  <Field
                    name='altContactFirstName'
                    label='Alt Contact First Name'
                    component={InputField}
                    validator={
                      patientInfoForm.altContactFirstName.inputValidator
                    }
                  />
                </div>
                <div className='col-md-3'>
                  <Field
                    name='altContactLastName'
                    label='Alt Contact Last Name'
                    component={InputField}
                    validator={
                      patientInfoForm.altContactLastName.inputValidator
                    }
                  />
                </div>
                <div className='col-md-3'>
                  <Field
                    name='altContactPhone'
                    label='Alt Contact Phone'
                    component={InputField}
                    validator={patientInfoForm.altContactPhone.inputValidator}
                  />
                </div>
                <div className='col-md-3'>
                  <Field
                    name='altContactRel'
                    label='Relation to Patient'
                    component={InputField}
                    validator={patientInfoForm.altContactRel.inputValidator}
                  />
                </div>
              </div>
            ) : (
              <div className='row mt-12'>
                <div className='col-md-3'>
                  <Field
                    name='altContactFirstName'
                    label='Alt Contact First Name'
                    component={InputField}
                    validator={
                      patientInfoForm.altContactFirstName.inputValidator
                    }
                  />
                </div>
                <div className='col-md-3'>
                  <Field
                    name='altContactLastName'
                    label='Alt Contact Last Name'
                    component={InputField}
                    validator={
                      patientInfoForm.altContactLastName.inputValidator
                    }
                  />
                </div>
                <div className='col-md-3'>
                  <Field
                    name='altContactPhone'
                    label='Alt Contact Phone'
                    component={InputField}
                    validator={patientInfoForm.altContactPhone.inputValidator}
                  />
                </div>
                <div className='col-md-3'>
                  <Field
                    name='altContactRel'
                    label='Relation to Patient'
                    component={InputField}
                    validator={patientInfoForm.altContactRel.inputValidator}
                  />
                </div>
              </div>
            )}

            <div className='row'>
              <div className='col-md-10'>
                <div className='row'>
                  <div className='col-md-8 mt-12'>
                    <Field
                      name='hipaaContact'
                      label='HIPAA Contact'
                      component={Checkbox}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-3 mt-12'>OK TO CONTACT PATIENT:</div>
                  <div className='col-md-9'>
                    <Field
                      name='contactPatient'
                      data={booleanChoices}
                      layout='horizontal'
                      component={FormRadioGroup}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-3 mt-12'>
                    OK TO LEAVE DETAILED MESSAGE:
                  </div>
                  <div className='col-md-9'>
                    <Field
                      name='detailedMessage'
                      data={booleanChoices}
                      layout='horizontal'
                      component={FormRadioGroup}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-3 mt-12'>
                    OK TO PARTICIPATE IN SURVEYS:
                  </div>
                  <div className='col-md-9'>
                    <Field
                      name='surveyOptout'
                      data={booleanChoices}
                      layout='horizontal'
                      component={FormRadioGroup}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-3 mt-12'>
                    COMMUNICATION PREFERENCES:
                  </div>
                  <div className='col-md-4' style={{ float: 'left' }}>
                    <Field
                      name='communication'
                      data={preferences}
                      layout='horizontal'
                      component={FormRadioGroup}
                      defaultValue={communicationValue}
                      onChange={(event) => handleRadioChange(event)}
                    />
                  </div>
                  <div className='col-md-5 mt-12' style={{ float: 'left' }}>
                    Reminders will go to Primary Phone
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-3 mt-12'>BEST TIME TO CONTACT:</div>
                  <div className='col-md-9 mt-12'>
                    <Field
                      name='MORNING'
                      label='Morning'
                      component={Checkbox}
                      className='mr-4'
                    />
                    <Field
                      name='AFTERNOON'
                      label='Afternoon'
                      component={Checkbox}
                      className='mr-4'
                    />
                    <Field
                      name='EVENING'
                      label='Evening'
                      component={Checkbox}
                      className='mr-4'
                    />
                    <Field
                      name='ANYTIME'
                      label='Anytime'
                      component={Checkbox}
                    />
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='row'>
                  <div className='col-md-7 offset-1 mt-18'>
                    <div className='card-block insurance-card'>
                      {selectedPatientInfo?.patientProfile?.patientInfo
                        ?.insuranceCardImages &&
                        selectedPatientInfo?.patientProfile?.patientInfo
                          ?.insuranceCardImages.length > 0 &&
                        selectedPatientInfo?.patientProfile?.patientInfo
                          ?.insuranceCardImages[0] && (
                          <div id='f1_container'>
                            <div id='f1_card' className='shadow'>
                              <div className='front face'>
                                <img
                                  src={
                                    selectedPatientInfo?.patientProfile
                                      ?.patientInfo?.insuranceCardImages[0]
                                  }
                                  alt='insurance-card-front'
                                />
                              </div>
                              <div className='back face center'>
                                <img
                                  src={
                                    selectedPatientInfo?.patientProfile
                                      ?.patientInfo?.insuranceCardImages[1]
                                  }
                                  alt='insurance-card-back'
                                />
                              </div>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='row p-3'>
              <div className='col-2'>
                <button
                  type='submit'
                  className='k-button pageButton'
                  disabled={!canEdit(UserRoleTypes.PatientInfo) || noEmail}
                >
                  Submit
                </button>
              </div>
              <div className='col-10'>
                {noEmail && (
                  <>
                    <div className=' ml-1 mt-1'>
                      <div
                        style={{ textAlign: 'left' /* fontWeight: "bold" */ }}
                      >
                        <Alert type='alert'>
                          Alert: If Communication Preference is set to Email the
                          Patient must have an Email listed.
                        </Alert>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </FormElement>
        )}
      />
    </div>
  );
};

export default PatientInfoGeneral;
