import React, { useContext, useEffect, useState } from 'react';

// kendo
import { Form, Field } from '@progress/kendo-react-form';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Dialog } from '@progress/kendo-react-dialogs';

// components
import FormButton from '@/components/common-components/Form/FormButton';

// utils
import {
  InputField,
  InputCustomField,
  validateInput
} from '@/common/Validation';
import { MaskedPhoneInput } from '@/common/MaskInput';
import { states } from '@/common/states';
import { Constants } from '@/constants';

// context
import { UserContext, UserRoleTypes } from '@/context';

const PrescriberAddressModal = ({
  onClose,
  address,
  index,
  onAddUpdateAddress
}) => {
  const { canEdit } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [touched, setTouched] = useState(false);
  const [mainFormData, setMainFormData] = useState({});

  const dialogTitle = !address
    ? "Add Prescriber's New Address"
    : "Update Prescriber's Address";

  useEffect(() => {
    const initialObject = {};
    if (address) {
      Object.keys(prescriberAddressForm).forEach((key) => {
        if (key === 'state' && !address[key]?.abbreviation) {
          initialObject[key] = address[key]
            ? states.find((st) => st?.abbreviation === address[key])
            : prescriberAddressForm[key].value;
        } else {
          initialObject[key] = address[key]
            ? address[key]
            : prescriberAddressForm[key].value;
        }
      });
    } else {
      Object.keys(prescriberAddressForm).forEach((key) => {
        initialObject[key] = prescriberAddressForm[key].value;
      });
    }
    setMainFormData(initialObject);
  }, [address]);

  const handleUpdateTitle = (formRenderProps, value) => {
    formRenderProps.onChange('addressTitle', {
      value
    });
  };

  const prescriberAddressForm = {
    streetName: {
      value: mainFormData?.streetName || '',
      inputValidator: (value) => {
        return validateInput({
          streetName: { ...prescriberAddressForm.streetName, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.prescFirstName_REQUIRED
        }
      ]
    },
    city: {
      value: mainFormData?.city || '',
      inputValidator: (value) => {
        return validateInput({
          city: { ...prescriberAddressForm.city, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.City_REQUIRED
        },
        {
          type: 'alpha',
          message: Constants.ErrorMessage.Alpha_Required
        },
        {
          type: 'noCommas',
          message: Constants.ErrorMessage.noCommas
        }
      ]
    },
    state: {
      value:
        states.find(
          (st) =>
            st.abbreviation === mainFormData?.state ||
            st.name === mainFormData?.state
        ) || null,
      inputValidator: (value) => {
        return validateInput({
          state: { ...prescriberAddressForm.state, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.State_REQUIRED
        }
      ]
    },
    zip: {
      value: mainFormData?.zip || '',
      inputValidator: (value) => {
        return validateInput({
          zip: { ...prescriberAddressForm.zip, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.Zip_REQUIRED
        },
        {
          type: 'onlyNumeric',
          message: Constants.ErrorMessage.Numeric_Required
        },
        {
          type: 'zipLength',
          message: Constants.ErrorMessage.zipLength_REQUIRED
        }
      ]
    },
    phoneNumber: {
      value: mainFormData?.phoneNumber,
      inputValidator: (value) => {
        return validateInput({
          phoneNumber: { ...prescriberAddressForm.phoneNumber, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: 'Phone number is required.'
        },
        {
          type: 'maskedMinlength',
          length: 14,
          message: Constants.ErrorMessage.PhoneNumber_REQUIRED
        }
      ]
    },
    faxNumber: {
      value: mainFormData?.faxNumber,
      inputValidator: (value) => {
        return validateInput({
          faxNumber: { ...prescriberAddressForm.faxNumber, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: 'Fax number is required.'
        },
        {
          type: 'maskedMinlength',
          length: 14,
          message: Constants.ErrorMessage.PhoneNumber_REQUIRED
        }
      ]
    },
    officeContactName: {
      value: mainFormData?.officeContactName || '',
      inputValidator: (value) => {
        return validateInput({
          officeContactName: {
            ...prescriberAddressForm.officeContactName,
            value
          }
        });
      }
    },
    addressTitle: {
      value: mainFormData?.addressTitle || '',
      inputValidator: (value) => {
        return validateInput({
          addressTitle: { ...prescriberAddressForm.addressTitle, value }
        });
      }
    }
  };

  const handleAddUpdateAddress = (dataItem) => {
    setTouched(false);

    const requestObject = {
      ...dataItem
    };
    // bind api call
    onAddUpdateAddress(index, requestObject);
    onClose();
  };

  return (
    <div>
      <Dialog title={dialogTitle} width={800} height={500} onClose={onClose}>
        <Form
          onSubmit={handleAddUpdateAddress}
          initialValues={mainFormData}
          key={JSON.stringify(mainFormData)}
          render={(formRenderProps) => {
            return (
              <form
                id='prescriberAddress'
                onSubmit={formRenderProps.onSubmit}
                className='k-form pl-3 pr-3 mt-3'
              >
                <div className='row'>
                  <div className='col-md-12 headerText'>
                    Enter Prescriber Address
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12 mt-08'>
                    <Field
                      name='streetName'
                      label='Office Address'
                      component={InputField}
                      validator={
                        prescriberAddressForm.streetName.inputValidator
                      }
                      onChange={(e) => {
                        handleUpdateTitle(
                          formRenderProps,
                          `${formRenderProps.valueGetter('city')} - ${
                            e.target.value
                          }`
                        );
                      }}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-5 col-sm-12 mt-08'>
                    <Field
                      name='city'
                      label='City'
                      component={InputCustomField}
                      validator={prescriberAddressForm.city.inputValidator}
                      onChange={(e) => {
                        handleUpdateTitle(
                          formRenderProps,
                          `${e.target.value} - ${formRenderProps.valueGetter(
                            'streetName'
                          )}`
                        );
                      }}
                    />
                  </div>
                  <div className='col-md-5 col-sm-12 mt-08'>
                    <Field
                      name='state'
                      label='State'
                      component={DropDownList}
                      data={states}
                      textField='name'
                      valueField='abbreviation'
                      layout='horizontal'
                      validator={prescriberAddressForm.state.inputValidator}
                    />
                  </div>
                  <div className='col-md-2 col-sm-12 mt-08'>
                    <Field
                      name='zip'
                      label='Zip'
                      component={InputField}
                      validator={prescriberAddressForm.zip.inputValidator}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6 col-sm-12 mt-08'>
                    <Field
                      name='phoneNumber'
                      label='Phone Number'
                      component={MaskedPhoneInput}
                      validator={
                        prescriberAddressForm.phoneNumber.inputValidator
                      }
                    />
                  </div>
                  <div className='col-md-6 col-sm-12 mt-08'>
                    <Field
                      name='faxNumber'
                      label='Fax Number'
                      component={MaskedPhoneInput}
                      validator={prescriberAddressForm.faxNumber.inputValidator}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12 mt-08'>
                    <Field
                      name='officeContactName'
                      label='Office Contact Name'
                      component={InputField}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12 mt-08'>
                    <Field
                      name='addressTitle'
                      label='Address Title'
                      component={InputField}
                      // onChange={(e) => {
                      //   formRenderProps.onChange('title', { value: e.value });
                      // }}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-6 mt-08'>
                    <FormButton
                      className='k-button blue'
                      type='submit'
                      disabled={
                        !formRenderProps.allowSubmit ||
                        !formRenderProps.valid ||
                        !canEdit(UserRoleTypes.PatientPrescribers)
                      }
                      label={!address ? ' Save' : 'Update'}
                      loading={loading}
                    />
                  </div>
                </div>
              </form>
            );
          }}
        />
      </Dialog>
    </div>
  );
};
export default PrescriberAddressModal;
