import DOMPurify from 'isomorphic-dompurify';
import {
  isDateFromPast,
  adjustUTC,
  isToday,
  dateDiffInDays,
  formatDateToDefault
} from '@/common/DateHelper.js';
import roles from '@/context/enumUserRoles';
import { setViivMeta } from '@/common/viivHelper';
import {
  QOL_PATIENT_TABLET_SURVEY,
  REASON_TO_SKIP_SURVEY
} from '@/components/SurveyEngine/surveyDictionary';
import { isPatientSurveyRequiredByRisk } from '@/components/SurveyEngine/helpers/helperQOL';

export const infusionSteps = {
  ORDER_REVIEW: 'Order Review',
  ASSESSMENT: 'Assessment',
  PRE_TREATMENT: 'Pre-Treatment',
  PREP: 'Prep',
  ADMIN: 'Admin',
  CLOSE_TREATMENT: 'Close Treatment'
};

const infusionStepInputs = {
  ORDER_REVIEW: 'updateStepOrderReviewInput',
  ASSESSMENT: 'updateStepAssessmentInput',
  PRE_TREATMENT: 'updateStepPreTreatmentInput',
  PREP: 'updateStepPreparationInput',
  ADMIN: 'updateStepAdministrationInput',
  CLOSE_TREATMENT: 'updateStepCloseTreatmentInput'
};

const infusionStepInputsV2 = {
  ORDER_REVIEW: 'stepReview',
  ASSESSMENT: 'stepAssessment',
  PRE_TREATMENT: 'stepPreTreatment',
  PREP: 'stepPreparation',
  ADMIN: 'stepAdministration',
  CLOSE_TREATMENT: 'stepCloseTreatment'
};

export const infusionStepNumbers = {
  ORDER_REVIEW: 1,
  ASSESSMENT: 2,
  PRE_TREATMENT: 3,
  PREP: 4,
  ADMIN: 5,
  CLOSE_TREATMENT: 6
};

export const infusionStepNumbersV2 = {
  ORDER_REVIEW: 0,
  ASSESSMENT: 1,
  PRE_TREATMENT: 2,
  PREP: 3,
  ADMIN: 4,
  CLOSE_TREATMENT: 5
};

export const isObjectEmpty = (obj) =>
  obj instanceof Object ? Object.keys(obj).length === 0 : true;

export const getStepStatus = (stepName, stepInput) => {
  if (!stepInput || isObjectEmpty(stepInput)) return undefined;

  switch (stepName) {
    case infusionSteps.ORDER_REVIEW:
      if (!stepInput.agentId && !stepInput.executedBy) return undefined;
      return !!stepInput.orderIsApproved && !!stepInput.patientConsentReceived;
    case infusionSteps.ASSESSMENT:
      if (stepInput.lastUpdatedTime) return true;
      if (!stepInput.agentId && !stepInput.executedBy) return undefined;
      return !!stepInput.agentId || !stepInput.executedBy;
    case infusionSteps.PRE_TREATMENT:
      return !!stepInput.preTreatmentCompleted;
    case infusionSteps.PREP:
      return !!stepInput.preparationComplete;
    case infusionSteps.ADMIN:
      return !!stepInput.administrationComplete;
    // case infusionSteps.CLOSE_TREATMENT:
    //   return !!stepInput.closeTreatmentNote;
    default:
      break;
  }
  return undefined;
};

export const getStepStatusV2 = (stepName, stepInput) => {
  if (!stepInput || isObjectEmpty(stepInput)) return undefined;

  switch (stepName) {
    case infusionSteps.ORDER_REVIEW:
      if (!stepInput.agentId && !stepInput.executedBy) return undefined;
      return !!stepInput.orderApproved && !!stepInput.patientConsentReceived;
    case infusionSteps.ASSESSMENT:
      if (stepInput.lastUpdatedTime) return true;
      if (!stepInput.agentId && !stepInput.executedBy) return undefined;
      return !!stepInput.agentId || !stepInput.executedBy;
    case infusionSteps.PRE_TREATMENT:
      return !!stepInput.preTreatmentCompleted;
    case infusionSteps.PREP:
      return !!stepInput.preparationComplete;
    case infusionSteps.ADMIN:
      return !!stepInput.administrationComplete;
    default:
      break;
  }
  return undefined;
};

export const initStepper = (infusionInput) => {
  const steps = [];

  Object.entries(infusionSteps).forEach(([key, label]) => {
    if (key === 'CLOSE_TREATMENT') {
      steps.push({
        label,
        isValid: infusionInput.infusionCompleted ? true : undefined
      });
    } else {
      steps.push({
        label,
        isValid: getStepStatus(
          infusionSteps[key],
          infusionInput[infusionStepInputs[key]]
        )
      });
    }
  });

  return steps;
};

export const initStepperV2 = (infusionInput) => {
  const steps = [];

  Object.entries(infusionSteps).forEach(([key, label]) => {
    if (key === 'CLOSE_TREATMENT') {
      steps.push({
        label,
        isValid: infusionInput.infusionCompleted ? true : undefined
      });
    } else {
      steps.push({
        label,
        isValid: getStepStatusV2(
          infusionSteps[key],
          infusionInput[infusionStepInputsV2[key]]
        )
      });
    }
  });

  return steps;
};

export const initCurrentStep = (infusionInput) => {
  let currentStep = infusionStepNumbers.ORDER_REVIEW;

  if (!infusionInput) return currentStep;

  const assesmentInput = infusionInput[infusionStepInputs.ASSESSMENT];
  const hasAssessment =
    !isObjectEmpty(assesmentInput) && !!assesmentInput.nursingProcessId;
  if (hasAssessment) currentStep = infusionStepNumbers.PRE_TREATMENT;

  const preTreatmentInput = infusionInput[infusionStepInputs.PRE_TREATMENT];
  const hasPreTreatment =
    !isObjectEmpty(preTreatmentInput) && !!preTreatmentInput.nursingProcessId;
  if (hasPreTreatment) currentStep = infusionStepNumbers.PREP;

  const prepInput = infusionInput[infusionStepInputs.PREP];
  const hasPrep = !isObjectEmpty(prepInput) && !!prepInput.nursingProcessId;
  if (hasPrep) currentStep = infusionStepNumbers.ADMIN;

  const adminInput = infusionInput[infusionStepInputs.ADMIN];
  const hasAdmin = !isObjectEmpty(adminInput) && !!adminInput.nursingProcessId;
  if (hasAdmin) currentStep = infusionStepNumbers.CLOSE_TREATMENT;

  return currentStep;
};

export const initCurrentStepV2 = (infusionInput) => {
  let currentStep = infusionStepNumbersV2.ORDER_REVIEW;

  if (!infusionInput) return currentStep;

  const assesmentInput = infusionInput[infusionStepInputsV2.ASSESSMENT];
  const hasAssessment =
    !isObjectEmpty(assesmentInput) && !!assesmentInput.executedBy;
  if (hasAssessment) currentStep = infusionStepNumbersV2.PRE_TREATMENT;

  const preTreatmentInput = infusionInput[infusionStepInputsV2.PRE_TREATMENT];
  const hasPreTreatment =
    !isObjectEmpty(preTreatmentInput) &&
    preTreatmentInput.preTreatmentCompleted;
  if (hasPreTreatment) currentStep = infusionStepNumbersV2.PREP;

  const prepInput = infusionInput[infusionStepInputsV2.PREP];
  const hasPrep = !isObjectEmpty(prepInput) && prepInput.preTreatmentCompleted;
  if (hasPrep) currentStep = infusionStepNumbersV2.ADMIN;

  const adminInput = infusionInput[infusionStepInputsV2.ADMIN];
  const hasAdmin =
    !isObjectEmpty(adminInput) && adminInput.administrationComplete;
  if (hasAdmin) currentStep = infusionStepNumbersV2.CLOSE_TREATMENT;

  return currentStep;
};

// temp solution/workaround, will be replaced with context/reducer later
export const restoreNotes = () => {
  const notesFromStorage = localStorage.getItem('narrativeNotes');

  return notesFromStorage ? JSON.parse(notesFromStorage) : '';
};

// check if infusion completed and some critical fields can not be edited
export const isInfusionRestricted = (infusionProps) => {
  const completedTimeUTC = infusionProps.nursingProcess?.endTime;

  if (completedTimeUTC) {
    const tz = infusionProps.headerDetailsData?.locationInfo?.timeZone;
    const locationTime = adjustUTC(completedTimeUTC, tz);

    return isDateFromPast(locationTime);
  }
  return false;
};

export const diluentValidators = (values) => {
  const errors = {};

  if (!values?.exp) {
    errors.exp = 'Required';
  }
  if (!values?.lotNumber) {
    errors.lotNumber = 'Required';
  }

  if (Object.keys(errors).length > 0) {
    return errors;
  }
};

export const formatDiluentProductName = (
  name = 'n/a',
  strength = 0,
  uom = 'n/a'
) => {
  return `${name} (Volume: ${strength} ${uom})`;
};

export const drugDosage = [
  { key: 'calcDosage', title: 'ORIGINAL ORDER DOSAGE' },
  { key: 'calculatedDosage', title: 'CURRENT CALCULATED DOSAGE' },
  { key: 'selectedQuantity', title: 'SELECTED QUANTITY' },
  { key: 'adjustedDosage', title: 'ADJUSTED DOSAGE' },
  { key: 'wastage', title: 'WASTAGE' },
  { key: 'remainingQty', title: 'REMAINING QUANTITY' }
];

// compares 2 strings ignoring the string CASE
// e.g. "Orencia" and "ORENCIA" basically is the same string
// or "nancie" and "NaNciE" also the same
// return Boolean true if they're equal
export const stingsAreEqual = (str1, str2) => {
  // convert alues to strings, sometimes they might be something else
  const s1 = String(str1);
  const s2 = String(str2);

  return s1.localeCompare(s2, undefined, { sensitivity: 'accent' }) === 0;
};

export const listPatientAllergies = (allergies) => {
  if (!allergies || !allergies.length) return 'No';

  return allergies.map((allergy) => allergy.allergen).join(', ');
};

export const listOrderPreMeds = (preMeds) => {
  if (!preMeds || !preMeds.length) return 'No';

  return preMeds.map((medication) => medication.drugName).join(', ');
};

// converts passed number to a fixed number
// e.g. 0.567895234235 to 0.568
// use `decimalLimit` attribute if you need more/less decimals after the float dot
export const parseNumber = (value, decimalLimit = 3) => {
  if (value) {
    const _val = +value;
    return +_val.toFixed(decimalLimit);
  }

  console.warn('parseNumber: attempt to parse non-existing value: ', value);
  return 0;
};

// integer parser, works with negative numbers too
// returns parsed string as a number
// returns NaN otherwise
export function filterInt(value) {
  if (/^[-+]?(\d+|Infinity)$/.test(value)) {
    return Number(value);
  }
  return NaN;
}

export const canUserEditCompletedInfusion = (getAgentResponse) => {
  const userRoles = getAgentResponse?.data?.getAgent?.roles;
  const allowedRoles = [roles.clinicalAdmin];

  return userRoles.some((role) => allowedRoles.includes(role));
};

export const canUserUpdateInfusionPrescriber = (getAgentResponse) => {
  const userRoles = getAgentResponse?.data?.getAgent?.roles;
  const allowedRoles = [roles.nursingAdmin];

  return userRoles.some((role) => allowedRoles.includes(role));
};

export const findMinVialStrength = (drugs) => {
  if (!drugs || !drugs.length) return 0;

  const volumeArr = drugs.map((drug) => drug.strength);

  return Math.min(...volumeArr);
};

export const findReferralInBucket = (patientBucket, referralId) => {
  if (!patientBucket || !patientBucket.referral) return null;

  // first - check active orders
  const drugReferral = patientBucket.referral.drugReferrals?.find(
    (dRef) => dRef.referralId === referralId
  );

  if (drugReferral) {
    return setViivMeta(drugReferral);
  }

  // now try to find the order among archived ones
  const archivedReferral = patientBucket.referral.archivedDrugReferrals?.find(
    (dRef) => dRef.referralId === referralId
  );

  return setViivMeta(archivedReferral);
};

export const setQuestionnaire = (dataItem) => {
  const questions =
    // [
    {
      // 	question: 1,
      // 	title: "CONSTITUTIONAL",
      question1_1: dataItem.question1_1
        ? dataItem.question1_1
        : dataItem.question1_1 === false
        ? false
        : null, // Boolean
      question1_2: dataItem.question1_2 ? dataItem.question1_2 : null, // String
      question1_3: dataItem.question1_3
        ? dataItem.question1_3
        : dataItem.question1_3 === false
        ? false
        : null, // Boolean
      question1_4: dataItem.question1_4 ? dataItem.question1_4 : null, // String
      question1_5: dataItem.question1_5
        ? dataItem.question1_5
        : dataItem.question1_5 === false
        ? false
        : null, // Boolean
      question1_6: dataItem.question1_6 ? dataItem.question1_6 : null, // String
      // },
      // {
      // 	question: 2,
      // 	title: "CARDIOVASCULAR",
      question2_1: dataItem.question2_1
        ? dataItem.question2_1
        : dataItem.question2_1 === false
        ? false
        : null, // Boolean
      question2_2: dataItem.question2_2 ? dataItem.question2_2 : null, // String
      question2_3: dataItem.question2_3
        ? dataItem.question2_3
        : dataItem.question2_3 === false
        ? false
        : null, // Boolean
      question2_4: dataItem.question2_4 ? dataItem.question2_4 : null, // String
      question2_5: dataItem.question2_5
        ? dataItem.question2_5
        : dataItem.question2_5 === false
        ? false
        : null, // Boolean
      question2_6: dataItem.question2_6 ? dataItem.question2_6 : null, // String
      question2_7: dataItem.question2_7
        ? dataItem.question2_7
        : dataItem.question2_7 === false
        ? false
        : null, // Boolean
      question2_8: dataItem.question2_8 ? dataItem.question2_8 : null, // String
      // },
      // {
      // 	question: 3,
      // 	title: "NEUROLOGICAL",
      question3_1: dataItem.question3_1
        ? dataItem.question3_1
        : dataItem.question3_1 === false
        ? false
        : null, // Boolean
      question3_2: dataItem.question3_2 ? dataItem.question3_2 : null, // String
      question3_3: dataItem.question3_3
        ? dataItem.question3_3
        : dataItem.question3_3 === false
        ? false
        : null, // Boolean
      question3_4: dataItem.question3_4 ? dataItem.question3_4 : null, // String
      question3_5: dataItem.question3_5
        ? dataItem.question3_5
        : dataItem.question3_5 === false
        ? false
        : null, // Boolean
      question3_6: dataItem.question3_6 ? dataItem.question3_6 : null, // String
      question3_7: dataItem.question3_7
        ? dataItem.question3_7
        : dataItem.question3_7 === false
        ? false
        : null, // Boolean
      question3_8: dataItem.question3_8 ? dataItem.question3_8 : null, // String
      question3_9: dataItem.question3_9
        ? dataItem.question3_9
        : dataItem.question3_9 === false
        ? false
        : null, // Boolean
      question3_10: dataItem.question3_10 ? dataItem.question3_10 : null, // String
      question3_11: dataItem.question3_11
        ? dataItem.question3_11
        : dataItem.question3_11 === false
        ? false
        : null, // Boolean
      question3_12: dataItem.question3_12 ? dataItem.question3_12 : null, // String
      // },
      // {
      // 	question: 4,
      // 	title: "SKIN",
      question4_1: dataItem.question4_1
        ? dataItem.question4_1
        : dataItem.question4_1 === false
        ? false
        : null, // Boolean
      question4_2: dataItem.question4_2 ? dataItem.question4_2 : null, // String
      question4_3: dataItem.question4_3
        ? dataItem.question4_3
        : dataItem.question4_3 === false
        ? false
        : null, // Boolean
      question4_4: dataItem.question4_4 ? dataItem.question4_4 : null, // String
      // },
      // {
      // 	question: 5,
      // 	title: "EYES/EARS/NOSE/THROAT",
      question5_1: dataItem.question5_1
        ? dataItem.question5_1
        : dataItem.question5_1 === false
        ? false
        : null, // Boolean
      question5_2: dataItem.question5_2 ? dataItem.question5_2 : null, // String
      question5_3: dataItem.question5_3
        ? dataItem.question5_3
        : dataItem.question5_3 === false
        ? false
        : null, // Boolean
      question5_4: dataItem.question5_4 ? dataItem.question5_4 : null, // String
      question5_5: dataItem.question5_5
        ? dataItem.question5_5
        : dataItem.question5_5 === false
        ? false
        : null, // Boolean
      question5_6: dataItem.question5_6 ? dataItem.question5_6 : null, // String
      question5_7: dataItem.question5_7
        ? dataItem.question5_7
        : dataItem.question5_7 === false
        ? false
        : null, // Boolean
      question5_8: dataItem.question5_8 ? dataItem.question5_8 : null, // String
      // },
      // {
      // 	question: 6,
      // 	title: "RESPIRATORY",
      question6_1: dataItem.question6_1
        ? dataItem.question6_1
        : dataItem.question6_1 === false
        ? false
        : null, // Boolean
      question6_2: dataItem.question6_2 ? dataItem.question6_2 : null, // String
      question6_3: dataItem.question6_3
        ? dataItem.question6_3
        : dataItem.question6_3 === false
        ? false
        : null, // Boolean
      question6_4: dataItem.question6_4 ? dataItem.question6_4 : null, // String
      question6_5: dataItem.question6_5
        ? dataItem.question6_5
        : dataItem.question6_5 === false
        ? false
        : null, // Boolean
      question6_6: dataItem.question6_6 ? dataItem.question6_6 : null, // String
      question6_7: dataItem.question6_7
        ? dataItem.question6_7
        : dataItem.question6_7 === false
        ? false
        : null, // Boolean
      question6_8: dataItem.question6_8 ? dataItem.question6_8 : null, // String
      question6_9: dataItem.question6_9
        ? dataItem.question6_9
        : dataItem.question6_9 === false
        ? false
        : null, // Boolean
      question6_10: dataItem.question6_10 ? dataItem.question6_10 : null, // String
      // },
      // {
      // 	question: 7,
      // 	title: "GENITAL/URINARY",
      question7_1: dataItem.question7_1
        ? dataItem.question7_1
        : dataItem.question7_1 === false
        ? false
        : null, // Boolean
      question7_2: dataItem.question7_2 ? dataItem.question7_2 : null, // String
      question7_3: dataItem.question7_3
        ? dataItem.question7_3
        : dataItem.question7_3 === false
        ? false
        : null, // Boolean
      question7_4: dataItem.question7_4 ? dataItem.question7_4 : null, // String
      question7_5: dataItem.question7_5
        ? dataItem.question7_5
        : dataItem.question7_5 === false
        ? false
        : null, // Boolean
      question7_6: dataItem.question7_6 ? dataItem.question7_6 : null, // String
      question7_7: dataItem.question7_7
        ? dataItem.question7_7
        : dataItem.question7_7 === false
        ? false
        : null, // Boolean
      question7_8: dataItem.question7_8 ? dataItem.question7_8 : null, // String
      // },
      // {
      // 	question: 8,
      // 	title: "MUSCULOSKELETAL",
      question8_1: dataItem.question8_1
        ? dataItem.question8_1
        : dataItem.question8_1 === false
        ? false
        : null, // Boolean
      question8_2: dataItem.question8_2 ? dataItem.question8_2 : null, // String
      question8_3: dataItem.question8_3
        ? dataItem.question8_3
        : dataItem.question8_3 === false
        ? false
        : null, // Boolean
      question8_4: dataItem.question8_4 ? dataItem.question8_4 : null, // String
      question8_5: dataItem.question8_5
        ? dataItem.question8_5
        : dataItem.question8_5 === false
        ? false
        : null, // Boolean
      question8_6: dataItem.question8_6 ? dataItem.question8_6 : null, // String
      // },
      // {
      // 	question: 9,
      // 	title: "GASTROINTESTINAL",
      question9_1: dataItem.question9_1
        ? dataItem.question9_1
        : dataItem.question9_1 === false
        ? false
        : null, // Boolean
      question9_2: dataItem.question9_2 ? dataItem.question9_2 : null, // String
      question9_3: dataItem.question9_3
        ? dataItem.question9_3
        : dataItem.question9_3 === false
        ? false
        : null, // Boolean
      question9_4: dataItem.question9_4 ? dataItem.question9_4 : null, // String
      question9_5: dataItem.question9_5
        ? dataItem.question9_5
        : dataItem.question9_5 === false
        ? false
        : null, // Boolean
      question9_6: dataItem.question9_6 ? dataItem.question9_6 : null, // String
      question9_7: dataItem.question9_7
        ? dataItem.question9_7
        : dataItem.question9_7 === false
        ? false
        : null, // Boolean
      question9_8: dataItem.question9_8 ? dataItem.question9_8 : null, // String
      // },
      // {
      // 	question: 10,
      // 	title: "PSYCHOLOGICAL",
      question10_1: dataItem.question10_1
        ? dataItem.question10_1
        : dataItem.question10_1 === false
        ? false
        : null, // Boolean
      question10_2: dataItem.question10_2 ? dataItem.question10_2 : null, // String
      question10_3: dataItem.question10_3
        ? dataItem.question10_3
        : dataItem.question10_3 === false
        ? false
        : null, // Boolean
      question10_4: dataItem.question10_4 ? dataItem.question10_4 : null, // String
      question10_5: dataItem.question10_5
        ? dataItem.question10_5
        : dataItem.question10_5 === false
        ? false
        : null, // Boolean
      question10_6: dataItem.question10_6 ? dataItem.question10_6 : null, // String
      question10_7: dataItem.question10_7
        ? dataItem.question10_7
        : dataItem.question10_7 === false
        ? false
        : null, // Boolean
      question10_8: dataItem.question10_8 ? dataItem.question10_8 : null, // String
      // },
      // {
      // 	question: 11,
      // 	title: "FEMALE",
      question11_1: dataItem.question11_1
        ? dataItem.question11_1
        : dataItem.question11_1 === false
        ? false
        : null, // Boolean
      question11_2: dataItem.question11_2 ? dataItem.question11_2 : null, // String
      question11_3: dataItem.question11_3
        ? dataItem.question11_3
        : dataItem.question11_3 === false
        ? false
        : null, // Boolean
      question11_4: dataItem.question11_4 ? dataItem.question11_4 : null, // String
      question11_5: dataItem.question11_5
        ? dataItem.question11_5
        : dataItem.question11_5 === false
        ? false
        : null, // Boolean
      question11_6: dataItem.question11_6 ? dataItem.question11_6 : null, // String
      question11_7: dataItem.question11_7
        ? dataItem.question11_7
        : dataItem.question11_7 === false
        ? false
        : null, // Boolean
      question11_8: dataItem.question11_8 ? dataItem.question11_8 : null, // String
      // },
      // {
      // 	question: 12,
      // 	title: "TYSABRI",
      question12_1: dataItem.question12_1
        ? dataItem.question12_1
        : dataItem.question12_1 === false
        ? false
        : null, // Boolean
      question12_2: dataItem.question12_2 ? dataItem.question12_2 : null, // String
      question12_3: dataItem.question12_3
        ? dataItem.question12_3
        : dataItem.question12_3 === false
        ? false
        : null, // Boolean
      question12_4: dataItem.question12_4 ? dataItem.question12_4 : null // String
    };

  // ]
  return JSON.stringify(questions);
};

// limit survey collection for the specific locations:
export const shouldDisplayQOL = (locationId) => {
  return false;
  // @NOTE: uncomment the following logic in case we need to restrict tablet survey to specific locations only
  //   const CHANDLER = '27';
  //   const ALBUQ = '21';
  //   const AHWATUKEE = '45';
  //   const BOISE = '25';
  //   const QUEEN_CREEK = '28';
  //   const FLAGSTAFF = '49';
  //   const PRESCOTT_VALLEY = '46';
  //   const KINGMAN = '44';
  //   const TUCSON = '33';
  //   return [
  //     CHANDLER,
  //     ALBUQ,
  //     AHWATUKEE,
  //     BOISE,
  //     QUEEN_CREEK,
  //     FLAGSTAFF,
  //     PRESCOTT_VALLEY,
  //     KINGMAN,
  //     TUCSON
  //   ].includes(locationId);
};

// get ALL patient surveys, filter patient tablet only and return filtered array back
// empty array will be returned as a default value
export const getTabletSurveysForInfusion = (patientSurveys) => {
  if (!patientSurveys || patientSurveys.length === 0) {
    return [];
  }

  // filter out non-tablet surveys and skipped surveys (no score)
  return patientSurveys
    .filter((survey) => QOL_PATIENT_TABLET_SURVEY === survey.typeOfSurvey)
    .filter((surveyData) => {
      const { meta, score } = JSON.parse(surveyData.survey);
      if (!score) return false;
      return meta.isSurveySkipped !== true;
    });
};

export const getTabletSurveysOnly = (patientSurveys) => {
  if (!patientSurveys || patientSurveys.length === 0) {
    return null;
  }

  // filter out non-tablet surveys and skipped surveys (no score)
  return patientSurveys
    .filter((survey) => QOL_PATIENT_TABLET_SURVEY === survey.typeOfSurvey)
    .filter((surveyData) => {
      const { meta, score } = JSON.parse(surveyData.survey);
      if (!score) return false;
      return meta.isSurveySkipped !== true;
    });
};

export const getAllSkippedSurveys = (patientSurveys) => {
  if (!patientSurveys || patientSurveys.length === 0) {
    return null;
  }

  return patientSurveys
    .filter((survey) => REASON_TO_SKIP_SURVEY === survey.typeOfSurvey)
    .filter((surveyData) => {
      const { meta, score } = JSON.parse(surveyData.survey);
      if (!score) return true;
      return meta.isSurveySkipped === true;
    });
};

export const findTabletSurveyByDate = (patientSurveys, infusionDate) => {
  const tabletSurveys = getTabletSurveysOnly(patientSurveys);
  if (!tabletSurveys) return null;

  return tabletSurveys.find((survey) => {
    const a = new Date(survey.collectedAt);
    const b = new Date(infusionDate);
    return dateDiffInDays(a, b) === 0;
  });
};

// get last patient survey done via tablet
// `null` will be returned if there's no survey with that type complete yet
export const getLastTabletSurvey = (patientSurveys) => {
  const tabletSurveys = getTabletSurveysOnly(patientSurveys);
  if (!tabletSurveys || tabletSurveys.length === 0) {
    return null;
  }

  return tabletSurveys.at(-1);
};

// get a patient survey prior to last one
// `null` will be returned if there's no survey with that type complete yet
export const getPriorTabletSurvey = (patientSurveys) => {
  const tabletSurveys = getTabletSurveysOnly(patientSurveys);
  if (!tabletSurveys || tabletSurveys.length === 0) {
    return null;
  }

  return tabletSurveys.at(-2);
};

// check if patient survey is required for the selected infusion
export const shouldRequirePatientSurvey = (infusion) => {
  // do not require if infusion complete
  if (infusion?.nursingProcess?.status === 'CLOSED') {
    return false;
  }

  // do not require if patient opted out from surveys
  if (
    infusion?.patientBucket?.patientProfile?.patientInfo?.preference?.surveys
      ?.preferred === false
  ) {
    return false;
  }

  // @NOTE: uncomment the following logic in case we need to restrict tablet survey to specific locations only
  // do not require if the infusion location is not listed
  //   const AVONDALE = '3';
  //   const SCOTTSDALE = '6';

  //   if (![AVONDALE, SCOTTSDALE].includes(infusion.location.id)) {
  //     return false;
  //   }

  /**
   * Multi-step check if patient survey required by risk or prev results
   */

  // last element in array - is the most recent survey
  const lastSurvey = getLastTabletSurvey(infusion.patientBucket.surveys);

  if (!lastSurvey) return true;

  // if last survey has completion date as today - that means it's already done
  // but it's still required on the infusion level
  const lastSurveyDate = new Date(lastSurvey.collectedAt);
  if (isToday(lastSurveyDate)) {
    return true;
  }

  // check survey reuqirements based on risk (prev results)
  return isPatientSurveyRequiredByRisk(lastSurvey);
};

export const hasPatientSurveyComplete = (infusion) => {
  const lastSurvey = getLastTabletSurvey(infusion.patientBucket.surveys);
  if (!lastSurvey) return false;

  const lastSurveyDate = new Date(lastSurvey.collectedAt);
  return isToday(lastSurveyDate);
};

export const hasPatientSurveySkipped = (infusion) => {
  const skippedSurveys = getAllSkippedSurveys(infusion.patientBucket.surveys);
  if (!skippedSurveys) return false;

  // we only need one that has same NPID
  return skippedSurveys.find((survey) => {
    const jsonResults = JSON.parse(survey.survey);
    return jsonResults.meta?.infusionId === infusion.nursingProcess?.id;
  });
};

export const hasPatientSurveyReviewed = (infusion) => {
  const lastSurvey = getLastTabletSurvey(infusion.patientBucket.surveys);
  if (!lastSurvey) return false;

  return lastSurvey.collectedBy !== 'aleracare-tablet';
};

export const generateLabelHTML = (labelData) => `
<h3>AleraCare California</h3>
<hr />
<p><b>${labelData.patientName}</b></p>
<p>DOB:&nbsp;${labelData.dob}</p>
<p>IV${labelData.patientId}</p>
<p>Prescriber:&nbsp;${labelData.prescriber}</p>
<p>Allergies:&nbsp;${labelData.patientAllergies}</p>
<hr />
<p><b>${labelData.productName} (${labelData.productCode})</b></p>
<p>Original Dosage:&nbsp;${labelData.origDosage}</p>
<p>Calculated Dosage:&nbsp;${labelData.calcDosage}</p>
<p>Adjusted Dosage:&nbsp;${labelData.adjustedDosage}</p>
<p>Prepped By:&nbsp;${labelData.agent}</p>
<p>Printed:&nbsp;${labelData.datePrinted}</p>
<hr />
<p><b>Federal Law Prohibits Dispensing Without a Prescription</b></p>
`;

export const printIvLabel = (labelData) => {
  const w = window.open();

  const labelHtml = generateLabelHTML(labelData);
  const htmlBody = DOMPurify.sanitize(labelHtml, {
    ALLOWED_TAGS: ['h3', 'hr', 'p', 'b']
  });

  const html = `
  <!DOCTYPE HTML>
  <html lang="en-us">
  <head><title>Print IV Label</title></head>
  <body>${htmlBody}</body>
  `;

  w.document.write(html);
  w.window.print();
  w.document.close();
};

export const getPatientPrescribers = (patientBucket) => {
  if (!patientBucket) {
    console.error('getPatientPrescribers - Patient bucket is required');
    return null;
  }

  if (patientBucket.hcpProfile?.items?.length) {
    return patientBucket.hcpProfile.items.map((item) => {
      return { ...item.prescriber, prescriberId: item.prescriberId };
    });
  }

  console.warn(
    `No prescribers assigned to patient (ID: ${patientBucket.patientId}) found`
  );

  return null;
};

export const findPrescriberById = (patientBucket, id) => {
  const prescribers = getPatientPrescribers(patientBucket);

  if (prescribers) {
    return prescribers.find((prescriber) => prescriber.prescriberId === id);
  }

  return null;
};

export const getEventDetailsForSurvey = (eventData) => {
  if (eventData) {
    return {
      adminSequenceNumber: eventData.adminSequenceNumber,
      chairId: eventData.chairId,
      locationId: eventData.locationId,
      locationName: eventData.locationName,
      orderName: eventData.orderName,
      providerId: eventData.providerId,
      referralId: eventData.referralId
    };
  }

  return null;
};

export const generateFaxMeta = (patientData, primaryInsurance) => {
  const name = `${patientData.patientFirstName} ${patientData.patientLastName}`;
  const dob = formatDateToDefault(patientData.dob);
  let metaString = `Patient Name: ${name}, DOB: ${dob}`;
  metaString += `, policy ID: ${
    !!primaryInsurance?.policyId ? primaryInsurance.policyId : 'N/A'
  }`;
  return metaString;
};
