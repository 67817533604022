export const onHoldReasons = [
  'Billing Issue',
  'Doesn’t want to move forward with treatments now',
  'Financial Concerns',
  'Future Labs',
  'Hospitalized',
  'Insurance',
  'MD appointment/discussion',
  'Medical/Clinical Condition or Procedure',
  'Out of country'
];
