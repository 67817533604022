import React, { useContext, useState } from 'react';
import { Field } from '@progress/kendo-react-form';
import { Grid } from '@progress/kendo-react-grid';
import { GridColumn as Column } from '@progress/kendo-react-grid/dist/npm/GridColumn';
import { DropDownList } from '@progress/kendo-react-dropdowns';

// components
import DeleteButton from '@/components/common-components/Form/DeleteButton';

// context
import { NotifContext } from '@/context/NotifContext';

// common
import { InputField } from '@/common/Validation';
import PreMeds from '@/common/PreMeds';

// utils
import { Constants } from '@/constants';
import { defaultUOMs } from '@/constants/uom';
import { routes } from '@/components/Referral/ReferralMaps';

const initPreMed = {
  drugName: '',
  route: '',
  dosage: '',
  uom: ''
};

const OrderPreMeds = ({
  preMeds,
  addPreMed,
  removePreMed,
  formRenderProps
}) => {
  const [preMed, setPreMed] = useState(initPreMed);

  const { showWarning } = useContext(NotifContext);

  const resetForm = () => {
    formRenderProps.onChange('pmDrugName', { value: '' });
    formRenderProps.onChange('pmRoute', { value: '' });
    formRenderProps.onChange('pmDosage', { value: '' });
    formRenderProps.onChange('pmUom', { value: '' });
  };

  const showFormInvalidDialog = () => {
    showWarning(Constants.ErrorMessage.allFields_REQUIRED);
  };

  const onAddPreMedication = () => {
    const { drugName, route, dosage, uom } = preMed;
    if (!drugName || !route || !dosage || !uom) {
      return showFormInvalidDialog();
    }

    const itemPreMed = {
      drugName,
      route,
      approvedDosage: dosage,
      unitOfMeas: uom,
      isPreMed: true
    };

    // add pre medication
    addPreMed(itemPreMed);

    // reset ui
    setPreMed(initPreMed);
    resetForm();
  };

  const onChangeField = (field, value) => {
    setPreMed((prevPreMed) => ({
      ...prevPreMed,
      [field]: value
    }));
  };

  const onChangePreMedication = (e) => {
    const { drugName, route, dose, uom } = e.value;

    // sync form fields
    formRenderProps.onChange('pmRoute', { value: route });
    formRenderProps.onChange('pmUom', { value: uom });
    formRenderProps.onChange('pmDosage', { value: dose });

    // update form state
    const newPreMed = { ...preMed, drugName, route, uom, dosage: dose };
    setPreMed(newPreMed);
  };

  const render = {
    actionCell: ({ dataIndex }) => (
      <td>
        <DeleteButton
          handleClick={(e) => {
            e.preventDefault();
            resetForm(); // need in order to update the form
            removePreMed(dataIndex);
          }}
        />
      </td>
    )
  };

  const columns = [
    { field: 'drugName', title: 'PRODUCT NAME' },
    { field: 'route', title: 'ROUTE', width: '80px' },
    { field: 'approvedDosage', title: 'DOSE', width: '80px' },
    { field: 'unitOfMeas', title: 'UOM', width: '60px' },
    { title: '', cell: render.actionCell }
  ];

  return (
    <>
      <div className='row'>
        <div className='col-4'>
          <Field
            name='pmDrugName'
            label='List of Pre-Medications'
            component={DropDownList}
            data={PreMeds}
            textField='title'
            valueField='drugName'
            onChange={onChangePreMedication}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-md-1 mt-16'>ROUTE:</div>
        <div className='col-md-2 mt-14'>
          <Field
            name='pmRoute'
            component={(props) => (
              <DropDownList
                {...props}
                opened={preMed.drugName ? false : props.opened}
              />
            )}
            data={routes.map((item) => item.value)}
            onChange={(e) => onChangeField('route', e.value)}
          />
        </div>
        <div className='col-md-1 mt-16'>Dosage:</div>
        <div className='col-md-1 mt-14'>
          <Field
            name='pmDosage'
            component={InputField}
            onChange={(e) => onChangeField('dosage', e.value)}
          />
        </div>
        <div className='col-md-1 mt-16'>UOM:</div>
        <div className='col-md-2 mt-14'>
          <Field
            name='pmUom'
            component={(props) => (
              <DropDownList
                {...props}
                opened={preMed.drugName ? false : props.opened}
              />
            )}
            data={defaultUOMs}
            onChange={(e) => onChangeField('uom', e.value)}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-md-2 mt-14'>
          <button
            type='button'
            className='k-button blue'
            onClick={onAddPreMedication}
          >
            ADD
          </button>
        </div>
      </div>
      <div className='row'>
        <div className='col mt-14'>
          <Grid data={preMeds} class='infusion-grid'>
            {columns.map(({ minWidth, ...rest }, idx) => (
              <Column key={idx} width={minWidth} {...rest} />
            ))}
          </Grid>
        </div>
      </div>
    </>
  );
};

export default OrderPreMeds;
