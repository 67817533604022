import { filterBy } from '@progress/kendo-react-data-tools';
import { mapTree, extendDataItem } from '@progress/kendo-react-common';
import {
  DOC_TYPE_LAB_DRAW,
  DOC_TYPE_ORDER_REFILL,
  DOC_TYPE_REFERRAL,
  DOC_TYPE_REFERRAL_ADDENDUM,
  patientDocumentTypes
} from '@/constants/enum';

export const processReferralTreeData = (data, state, fields) => {
  const { selectField, expandField, dataItemKey, subItemsField } = fields;
  const { expanded, value, filter } = state;
  const filtering = Boolean(filter && filter.value);

  const totalFilteredItems = patientDocumentTypes?.map((item) => {
    return {
      text: item.value,
      items: data
        .filter((type) => type?.documentType === item?.value)
        .map((itemType) => {
          const nameText = itemType.documentPath?.split('/');
          return {
            text: nameText?.[nameText?.length - 1],
            path: itemType?.documentPath,
            receivedAt: itemType?.receivedAt
          };
        })
    };
  });
  const filteredItems = totalFilteredItems?.filter((item) =>
      item.text === DOC_TYPE_LAB_DRAW ||
      item.text === DOC_TYPE_ORDER_REFILL ||
      item.text === DOC_TYPE_REFERRAL ||
      item.text === DOC_TYPE_REFERRAL_ADDENDUM);
  return mapTree(
    filtering
      ? filterBy(filteredItems, [filter], subItemsField)
      : filteredItems,
    subItemsField,
    (item) => {
      const props = {
        [expandField]: expanded.includes(item[dataItemKey]),
        [selectField]: value && item[dataItemKey] === value[dataItemKey]
      };

      return filtering
        ? extendDataItem(item, subItemsField, props)
        : {
            ...item,
            ...props
          };
    }
  );
};

export const expandedState = (item, dataItemKey, expanded) => {
  const nextExpanded = expanded.slice();
  const itemKey = item[dataItemKey];
  const index = expanded.indexOf(itemKey);
  index === -1 ? nextExpanded.push(itemKey) : nextExpanded.splice(index, 1);
  return nextExpanded;
};
