import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

// kendo
import {
  Card,
  CardBody,
  TabStrip,
  TabStripTab
} from '@progress/kendo-react-layout';

// components
import QueueFilters from '@/components/Queue/FilterSystem/QueueFilters';
import MainQueue from '@/components/Queue/MainQueue/MainQueue';

// context
import { QueueFilterContext } from '@/context';

// util
import { groupQueues } from '@/constants/enum';

const QueueCenterContainer = () => {
  const { type } = useParams();
  const history = useHistory();

  const { setWorkItemQueueSelection, setWorkItemQueueSelectionValue } =
    useContext(QueueFilterContext);

  const [selectedTab, setSelectedTab] = useState(0);

  const tabs = [
    groupQueues.INTAKE,
    groupQueues.BI,
    groupQueues.PA,
    groupQueues.SALES,
    groupQueues.SCHEDULE,
    groupQueues.CLINICIAN
  ];

  useEffect(() => {
    setSelectedTab(tabs.indexOf(type));
  }, [type, tabs]);

  const handleSelect = (e) => {
    setWorkItemQueueSelection(null);
    setWorkItemQueueSelectionValue(null);

    // redirect to new route
    history.push(`/work-queue/${tabs[e.selected]}`);
  };

  return (
    <Card>
      <CardBody>
        <div className='container-fluid'>
          <div className='row my-3'>
            <div className='col-12 col-lg-12 work-in-progress '>
              <h5>Queue Center</h5>
              <TabStrip
                selected={selectedTab}
                onSelect={(e) => handleSelect(e)}
              >
                {tabs.map((tab) => (
                  <TabStripTab title={tab} key={tab}>
                    <QueueFilters queueType={type} />
                    <MainQueue name={tab} />
                  </TabStripTab>
                ))}
              </TabStrip>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default QueueCenterContainer;
