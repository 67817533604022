/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const addUpdateAdverseEventInfo = /* GraphQL */ `
  mutation AddUpdateAdverseEventInfo($input: AddUpdateAdverseEventInfo!) {
    addUpdateAdverseEventInfo(input: $input) {
      agentId
      dob
      patientFirstName
      homePhoneNumber
      patientLastName
      patientAssistantProgram {
        annualIncome
        eligibleForCoPay
        householdSize
        incomeDoc
      }
      patientId
      patientDocuments {
        documentType
        documentPath
        date
        receivedAt
      }
      notes {
        date
        type
        note
      }
      aggregateAttribute
      createdAt
      updatedAt
    }
  }
`;

// [MM]
// checkings {
//   insuranceKey
//   groupId
//   billingTaxId
//   billingNPINumber
//   policyId
//   policy {
//     planType
//     planName
//     dedType
//     effectiveDate
//     termDate
//     coPay
//     coveragePercentage
//     deductibleInfo {
//       deductibleType
//       networkStatus
//       totalDeductibleAmount
//       metDeductible
//       totalOOPAmount
//       metOOP
//     }
//     oopMax {
//       deductibleType
//       networkStatus
//       totalDeductibleAmount
//       metDeductible
//       totalOOPAmount
//       metOOP
//     }
//     verificationMethod
//     networkStatus
//     outOfNetworkBenefits
//   }
//   paRequired
//   priorAuthorization {
//     submitMethod
//     paPhone
//     paSite
//     jCode
//     adminCode1
//     adminCode2
//     adminCode3
//   }
//   predeterminationNeeded
//   predetermination {
//     submitMethod
//     paPhone
//     paSite
//     jCode
//     adminCode1
//     adminCode2
//     adminCode3
//   }
//   callCompletionTime
//   claims {
//     claimAddress {
//       city
//       state
//       streetName
//       county
//       zip
//     }
//     timelyFilling
//     results
//   }
//   selectedGroupId
//   selectedLocationId
//   selectedProviderId
//   selectedBillingTaxId
//   selectedBillingNPI
//   billingTaxIdForOutOfNetwork
//   billingNPIForOutOfNetwork
//   billingOverrideReason
//   verifiedDate
//   callReferenceNumber
//   isCompleted
// }
// welcomeCalls {
//   callTime
//   agentId
//   answered
// }
// ----
export const addUpdateBenefitChecking = /* GraphQL */ `
  mutation AddUpdateBenefitChecking($input: AddUpdateBenefitInvestigation!) {
    addUpdateBenefitChecking(input: $input) {
      referralId
      welcomeCallCompleted
      callCompletionTime
    }
  }
`;

export const addUpdateCareGiverInfo = /* GraphQL */ `
  mutation AddUpdateCareGiverInfo($input: AddUpdateCareGiverInfo!) {
    addUpdateCareGiverInfo(input: $input) {
      agentId
      dob
      patientFirstName
      homePhoneNumber
      patientLastName
      patientAssistantProgram {
        annualIncome
        eligibleForCoPay
        householdSize
        incomeDoc
      }
      patientId
      patientDocuments {
        documentType
        documentPath
        date
        receivedAt
      }
      notes {
        date
        type
        note
      }
      aggregateAttribute
      createdAt
      updatedAt
    }
  }
`;

export const addUpdateClinicalAllergies = /* GraphQL */ `
  mutation AddUpdateClinicalAllergies($input: AddUpdateClinicalAllergies!) {
    addUpdateClinicalAllergies(input: $input) {
      agentId
      dob
      patientFirstName
      homePhoneNumber
      patientLastName
      patientAssistantProgram {
        annualIncome
        eligibleForCoPay
        householdSize
        incomeDoc
      }
      patientId
      patientDocuments {
        documentType
        documentPath
        date
        receivedAt
      }
      notes {
        date
        type
        note
      }
      aggregateAttribute
      createdAt
      updatedAt
    }
  }
`;

export const addUpdateClinicalDrugHistory = /* GraphQL */ `
  mutation AddUpdateClinicalDrugHistory($input: AddUpdateClinicalDrugHistory!) {
    addUpdateClinicalDrugHistory(input: $input) {
      agentId
      dob
      patientFirstName
      homePhoneNumber
      patientLastName
      patientAssistantProgram {
        annualIncome
        eligibleForCoPay
        householdSize
        incomeDoc
      }
      patientId
      patientDocuments {
        documentType
        documentPath
        date
        receivedAt
      }
      notes {
        date
        type
        note
      }
      aggregateAttribute
      createdAt
      updatedAt
    }
  }
`;

export const addUpdateClinicalInfo = /* GraphQL */ `
  mutation AddUpdateClinicalInfo($input: AddUpdateClinicalInfo!) {
    addUpdateClinicalInfo(input: $input) {
      agentId
      dob
      patientFirstName
      homePhoneNumber
      patientLastName
      patientAssistantProgram {
        annualIncome
        eligibleForCoPay
        householdSize
        incomeDoc
      }
      patientId
      patientDocuments {
        documentType
        documentPath
        date
        receivedAt
      }
      notes {
        date
        type
        note
      }
      aggregateAttribute
      createdAt
      updatedAt
    }
  }
`;

export const addUpdateConsentInfo = /* GraphQL */ `
  mutation AddUpdateConsentInfo($input: AddUpdateConsentInfo!) {
    addUpdateConsentInfo(input: $input) {
      agentId
      dob
      patientFirstName
      homePhoneNumber
      patientLastName
      patientAssistantProgram {
        annualIncome
        eligibleForCoPay
        householdSize
        incomeDoc
      }
      patientId
      patientDocuments {
        documentType
        documentPath
        date
        receivedAt
      }
      notes {
        date
        type
        note
      }
      aggregateAttribute
      createdAt
      updatedAt
    }
  }
`;

export const addUpdateDenialTracking = /* GraphQL */ `
  mutation AddUpdateDenialTracking($input: AddUpdateDenialTrackingInput!) {
    addUpdateDenialTracking(input: $input) {
      agentId
      dob
      patientFirstName
      homePhoneNumber
      patientLastName
      patientAssistantProgram {
        annualIncome
        eligibleForCoPay
        householdSize
        incomeDoc
      }
      patientId
      patientDocuments {
        documentType
        documentPath
        date
        receivedAt
      }
      notes {
        date
        type
        note
      }
      aggregateAttribute
      createdAt
      updatedAt
    }
  }
`;

export const addUpdateDiscontinuationInfo = /* GraphQL */ `
  mutation AddUpdateDiscontinuationInfo($input: AddUpdateDiscontinuationInfo!) {
    addUpdateDiscontinuationInfo(input: $input) {
      agentId
      dob
      patientFirstName
      homePhoneNumber
      patientLastName
      patientAssistantProgram {
        annualIncome
        eligibleForCoPay
        householdSize
        incomeDoc
      }
      patientId
      patientDocuments {
        documentType
        documentPath
        date
        receivedAt
      }
      notes {
        date
        type
        note
      }
      aggregateAttribute
      createdAt
      updatedAt
    }
  }
`;

export const addUpdateFaxInfo = /* GraphQL */ `
  mutation AddUpdateFaxInfo($input: AddUpdateFaxInfo!) {
    addUpdateFaxInfo(input: $input) {
      agentId
      dob
      patientFirstName
      homePhoneNumber
      patientLastName
      patientAssistantProgram {
        annualIncome
        eligibleForCoPay
        householdSize
        incomeDoc
      }
      patientId
      patientDocuments {
        documentType
        documentPath
        date
        receivedAt
      }
      notes {
        date
        type
        note
      }
      aggregateAttribute
      createdAt
      updatedAt
    }
  }
`;

export const addUpdateInsuranceInfo = /* GraphQL */ `
  mutation AddUpdateInsuranceInfo($input: AddUpdateInsuranceInfo!) {
    addUpdateInsuranceInfo(input: $input) {
      agentId
      patientId
      patientProfile {
        insuranceInfo {
          isPatientInsured
          primary {
            insurerId
            insurerName
            binNumber
            pcnNumber
            coveredBy
            email
            firstName
            lastName
            groupId
            insuranceExpireDate
            planName
            policyId
            state
            customerServicePhone
            relationship
            rxPlanName
            pharmacyPhone
            rxGroupNumber
            hasPharmacyBenefits
          }
          secondary {
            insurerId
            insurerName
            binNumber
            pcnNumber
            coveredBy
            email
            firstName
            lastName
            groupId
            insuranceExpireDate
            planName
            policyId
            state
            customerServicePhone
            relationship
            rxPlanName
            pharmacyPhone
            rxGroupNumber
            hasPharmacyBenefits
          }
          tertiary {
            insurerId
            insurerName
            binNumber
            pcnNumber
            coveredBy
            email
            firstName
            lastName
            groupId
            insuranceExpireDate
            planName
            policyId
            state
            customerServicePhone
            relationship
            rxPlanName
            pharmacyPhone
            rxGroupNumber
            hasPharmacyBenefits
          }
        }
      }
    }
  }
`;

export const addUpdateInvestigationStatus = /* GraphQL */ `
  mutation AddUpdateInvestigationStatus(
    $input: AddUpdateInvestigationStatusInput!
  ) {
    addUpdateInvestigationStatus(input: $input) {
      agentId
      dob
      patientFirstName
      homePhoneNumber
      patientLastName
      patientAssistantProgram {
        annualIncome
        eligibleForCoPay
        householdSize
        incomeDoc
      }
      patientId
      patientDocuments {
        documentType
        documentPath
        date
        receivedAt
      }
      notes {
        date
        type
        note
      }
      aggregateAttribute
      createdAt
      updatedAt
    }
  }
`;

export const addUpdatePatientInfo = /* GraphQL */ `
  mutation AddUpdatePatientInfo($input: AddUpdatePatientInfo!) {
    addUpdatePatientInfo(input: $input) {
      agentId
      patientId
    }
  }
`;

export const updatePrescriberInfo = /* GraphQL */ `
  mutation UpdatePrescriberInfo($input: UpdatePrescriberInfoInput!) {
    updatePrescriberInfo(input: $input) {
      medicalSpecialty
      officeContactFirstName
      officeContactLastName
      siteInstitutionName
      taxIDNumber
      officeEmail
      officePhoneNumber
      officeFaxNumber
      preferredPrescriberContactMethod
      prescriberFirstName
      prescriberMiddleName
      prescriberLastName
      NPINumber
      HINNumber
      officeContactName
      createdAt
      updatedAt
      needTreatmentRecords
      dontSendSystemGeneratedFaxes
      notes {
        date
        drugName
        labExpiration
        labTest
        note
        type
      }
      officeAddresses {
        city
        state
        streetName
        county
        zip
        id
        phoneNumber
        faxNumber
        default
        officeContactName
        addressTitle
      }
    }
  }
`;

export const addUpdatePrescriberNotes = /* GraphQL */ `
  mutation AddUpdatePrescriberNotes($input: AddUpdatePrescriberNotes!) {
    addUpdatePrescriberNotes(input: $input) {
      agentId
      dob
      patientFirstName
      homePhoneNumber
      patientLastName
      patientAssistantProgram {
        annualIncome
        eligibleForCoPay
        householdSize
        incomeDoc
      }
      patientId
      patientDocuments {
        documentType
        documentPath
        date
      }
      notes {
        date
        type
        note
      }
      aggregateAttribute
      createdAt
      updatedAt
    }
  }
`;

export const addUpdatePriorAuthAppeal = /* GraphQL */ `
  mutation AddUpdatePriorAuthAppeal($input: AddUpdatePriorAuthAppeal!) {
    addUpdatePriorAuthAppeal(input: $input) {
      agentId
      dob
      patientFirstName
      homePhoneNumber
      patientLastName
      patientAssistantProgram {
        annualIncome
        eligibleForCoPay
        householdSize
        incomeDoc
      }
      patientId
      patientDocuments {
        documentType
        documentPath
        date
      }
      notes {
        date
        type
        note
      }
      aggregateAttribute
      createdAt
      updatedAt
    }
  }
`;

export const addUpdatePriorAuthBridge = /* GraphQL */ `
  mutation AddUpdatePriorAuthBridge($input: AddUpdatePriorAuthBridge!) {
    addUpdatePriorAuthBridge(input: $input) {
      agentId
      dob
      patientFirstName
      homePhoneNumber
      patientLastName
      patientAssistantProgram {
        annualIncome
        eligibleForCoPay
        householdSize
        incomeDoc
      }
      patientId
      patientDocuments {
        documentType
        documentPath
        date
      }
      notes {
        date
        type
        note
      }
      aggregateAttribute
      createdAt
      updatedAt
    }
  }
`;

export const addUpdatePriorAuthInfo = /* GraphQL */ `
  mutation AddUpdatePriorAuthInfo($input: AddUpdatePriorAuthInfo!) {
    addUpdatePriorAuthInfo(input: $input) {
      agentId
      dob
      patientFirstName
      homePhoneNumber
      patientLastName
      patientAssistantProgram {
        annualIncome
        eligibleForCoPay
        householdSize
        incomeDoc
      }
      patientId
      patientDocuments {
        documentType
        documentPath
        date
      }
      notes {
        date
        type
        note
      }
      aggregateAttribute
      createdAt
      updatedAt
    }
  }
`;

export const addUpdateReferral = /* GraphQL */ `
  mutation AddUpdateReferral($input: AddUpdateReferral!) {
    addUpdateReferral(input: $input) {
      agentId
      dob
      patientFirstName
      homePhoneNumber
      patientLastName
      patientAssistantProgram {
        annualIncome
        eligibleForCoPay
        householdSize
        incomeDoc
      }
      patientId
      patientDocuments {
        documentType
        documentPath
        date
        receivedAt
      }
      notes {
        date
        type
        note
      }
      aggregateAttribute
      createdAt
      updatedAt
    }
  }
`;

export const createNewPatientBucket = /* GraphQL */ `
  mutation CreateNewPatientBucket($input: CreatePatientBucketInput!) {
    createNewPatientBucket(input: $input) {
      agentId
      dob
      patientFirstName
      homePhoneNumber
      patientLastName
      patientId
      patientDocuments {
        documentType
        documentPath
        date
        receivedAt
      }
      aggregateAttribute
      workItemId
    }
  }
`;

export const addUpdatePatientDocs = /* GraphQL */ `
  mutation AddUpdatePatientDocs($input: AddUpdatePatientDocs!) {
    addUpdatePatientDocs(input: $input) {
      agentId
      dob
      patientFirstName
      homePhoneNumber
      patientLastName
      patientAssistantProgram {
        annualIncome
        eligibleForCoPay
        householdSize
        incomeDoc
      }
      patientId
      patientDocuments {
        documentType
        documentPath
        date
        receivedAt
      }
      notes {
        date
        type
        note
      }
      aggregateAttribute
      createdAt
      updatedAt
    }
  }
`;

/** [MM] 
 * 
      hcpProfile {
        medicalSpecialty
        officeContactFirstName
        officeContactLastName
        siteInstitutionName
        taxIDNumber
        officeEmail
        officePhoneNumber
        officeFaxNumber
        preferredPrescriberContactMethod
        prescriberFirstName
        prescriberLastName
        NPINumber
        HINNumber
        officeContactName
        createdAt
        updatedAt
      }

  "mutation MyMutation {
  addUpdateHCPProfile(input: {
    agentId: ""tester01"", 
    patientId: ""523020197"", 
    hcpProfile: {NPINumber: ""1407841109""}
  }) {
    patientId
  }
}"
*/
export const addUpdateHcpProfile = /* GraphQL */ `
  mutation AddUpdateHcpProfile($input: AddUpdateHCPProfile!) {
    addUpdateHCPProfile(input: $input) {
      agentId
      patientId
      hcpProfile {
        prescriberFirstName
        prescriberLastName
        preferredPrescriberContactMethod
        NPINumber
        taxIDNumber
        medicalSpecialty
        siteInstitutionName
        officeContactFirstName
        officeContactLastName
        officeEmail
        officeContactName
        officePhoneNumber
        officeFaxNumber
        HINNumber
        officeAddresses {
          city
          state
          streetName
          county
          zip
          id
          phoneNumber
          faxNumber
          default
          officeContactName
          addressTitle
        }
        notes {
          date
          drugName
          labExpiration
          labTest
          note
          type
        }
      }
    }
  }
`;

export const addUpdateNotes = /* GraphQL */ `
  mutation AddUpdateNotes($input: AddUpdateNotes!) {
    addUpdateNotes(input: $input) {
      agentId
      dob
      patientFirstName
      patientLastName
      homePhoneNumber
      notes {
        date
        type
        note
        createdBy
      }
    }
  }
`;

// export const createWorkItem = /* GraphQL */ `
//   mutation CreateWorkItem($input: CreateWorkItemInput!) {
//     createWorkItem(input: $input) {
//       id
//     }
//   }
// `;

// Legacy code: createWorkItem, Remove after testing and follow up comment is approved.
export const createWorkItem = /* GraphQL */ `
  mutation CreateWorkItem(
    $input: CreateWorkItemInput!
    $condition: ModelWorkItemConditionInput
  ) {
    createWorkItem(input: $input, condition: $condition) {
      id
      caseId
      work
      workStatus
      createdBy
      assignedTo
      patientId
      description
      referralId
      endTime
      startTime
      targetTime
      processId
      currentStep
      followupDate
      attachedData
      priority
      groupQueueId
      workHistory {
        agentId
        startTime
        endTime
        lastWorkStatus
      }
      updatedBy
      startTS
      endTS
      targetTS
      createdAt
      updatedAt
    }
  }
`;
/////////////////////////////////////////////////////////////////////////
export const createWork = /* GraphQL */ `
  mutation CreateWork($input: WorkItemInput!) {
    createWork(input: $input) {
      success
      details
      workItem {
        id
        assignedTo
        attachedData
        caseId
        createdBy
        currentStep
        description
        endTime
        followupDate
        groupQueueId
        patientId
        priority
        processId
        referralId
        startTime
        targetTime
        work
        workHistory {
          agentId
          endTime
          lastWorkStatus
          startTime
        }
        workStatus
      }
    }
  }
`;

export const updateWorkItem = /* GraphQL */ `
  mutation UpdateWorkItem(
    $input: UpdateWorkItemInput!
    $condition: ModelWorkItemConditionInput
  ) {
    updateWorkItem(input: $input, condition: $condition) {
      id
      caseId
      work
      workStatus
      createdBy
      assignedTo
      patientId
      description
      referralId
      endTime
      startTime
      targetTime
      processId
      currentStep
      followupDate
      attachedData
      priority
      groupQueueId
      workHistory {
        agentId
        startTime
        endTime
        lastWorkStatus
      }
      updatedBy
      startTS
      endTS
      targetTS
      createdAt
      updatedAt
    }
  }
`;

///Add Product
export const createProduct = /* GraphQL */ `
  mutation createProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      createdAt
      dosing
      frequency
      isReconstituted
      jCode
      ndcOuterPackageCode
      ndcInnerPackageCode
      packageType
      packageVolume
      packageVolumeUOM
      premedications {
        administer
        approvedDosage
        drugName
        isPreMed
        maxOfTreatments
        route
        unitOfMeas
      }
      price
      productId
      productName
      route
      scheduledAllotment
      status
      strength
      typeOfDrug
      typeOfProduct
      unitOfMeas
      updatedAt
      vendor
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation updateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      dosing
      frequency
      isReconstituted
      jCode
      ndcOuterPackageCode
      ndcInnerPackageCode
      packageType
      packageVolume
      packageVolumeUOM
      premedications {
        administer
        approvedDosage
        drugName
        isPreMed
        maxOfTreatments
        route
        unitOfMeas
      }
      price
      productId
      productName
      route
      scheduledAllotment
      status
      strength
      typeOfDrug
      typeOfProduct
      unitOfMeas
      updatedAt
      vendor
      category
    }
  }
`;

// [MM]
// id: ID!
// caseId: ID!
// work: Task!
// workStatus: TaskStatus
// createdBy: String!
// assignedTo: String!
// patientId: String
// description: String
// referralId: String
// endTime: AWSDateTime
// startTime: AWSDateTime!
// targetTime: AWSDateTime
// processId: String
// currentStep: String
// followupDate: AWSDate
// attachedData: AWSJSON
// priority: Int
// groupQueueId: GroupQueue
// workHistory: [WorkRecord]
// createdAt: AWSDateTime!
// updatedAt: AWSDateTime!
export const acquireWork = /* GraphQL */ `
  mutation AcquireWork($input: RequestWorkInput!) {
    acquireWork(input: $input) {
      success
      details
      workItem {
        id
        assignedTo
        attachedData
        caseId
        createdBy
        currentStep
        description
        endTime
        followupDate
        groupQueueId
        patientId
        priority
        processId
        previousWorkStatus
        referralId
        startTime
        targetTime
        work
        workHistory {
          agentId
          callAttempted
          endTime
          lastWorkStatus
          startTime
        }
        workStatus
      }
    }
  }
`;

export const releaseWork = /* GraphQL */ `
  mutation ReleaseWork($input: ReleaseWorkInput!) {
    releaseWork(input: $input) {
      success
      details
      workItem {
        createdAt
        id
        workItemType
        workStatus
        attachedData
        previousWorkStatus
      }
    }
  }
`;

export const createPatientBucket = /* GraphQL */ `
  mutation CreatePatientBucket(
    $input: CreatePatientBucketInput!
    $condition: ModelPatientBucketConditionInput
  ) {
    createPatientBucket(input: $input, condition: $condition) {
      agentId
      patientId
      patientFirstName
      patientLastName
      homePhoneNumber
      dob
      patientProfile {
        patientInfo {
          address {
            city
            state
            streetName
            zip
          }
          bestContact
          bestTimeToContact
          cellphoneNumber
          email
          gender
          ssn
        }
      }
      patientId
      patientDocuments {
        documentType
        documentPath
        date
        receivedAt
      }
      aggregateAttribute
      workItemId
    }
  }
`;

export const updatePatientBucketNotes = /* GraphQL */ `
  mutation UpdatePatientBucketNotes(
    $input: UpdatePatientBucketInput!
    $condition: ModelPatientBucketConditionInput
  ) {
    updatePatientBucket(input: $input, condition: $condition) {
      patientId
      notes {
        date
        type
        note
        createdBy
      }
    }
  }
`;

export const updatePatientBucket = /* GraphQL */ `
  mutation UpdatePatientBucket(
    $input: UpdatePatientBucketInput!
    $condition: ModelPatientBucketConditionInput
  ) {
    updatePatientBucket(input: $input, condition: $condition) {
      agentId
      patientId
      patientFirstName
      patientLastName
      homePhoneNumber
      dob
      patientProfile {
        patientInfo {
          address {
            city
            state
            streetName
            zip
          }
          bestContact
          bestTimeToContact
          cellphoneNumber
          email
          gender
          ssn
        }
      }
      patientAssistantProgram {
        annualIncome
        eligibleForCoPay
        householdSize
        incomeDoc
      }
      patientDocuments {
        documentType
        documentPath
        date
        receivedAt
      }
      notes {
        date
        type
        note
      }
      aggregateAttribute
      createdAt
      updatedAt
    }
  }
`;

export const deletePatientBucket = /* GraphQL */ `
  mutation DeletePatientBucket(
    $input: DeletePatientBucketInput!
    $condition: ModelPatientBucketConditionInput
  ) {
    deletePatientBucket(input: $input, condition: $condition) {
      agentId
      patientId
      patientFirstName
      patientLastName
      homePhoneNumber
      dob
      patientProfile {
        patientInfo {
          address {
            city
            state
            streetName
            zip
          }
          bestContact
          bestTimeToContact
          cellphoneNumber
          email
          gender
          ssn
        }
      }
      patientAssistantProgram {
        annualIncome
        eligibleForCoPay
        householdSize
        incomeDoc
      }
      patientId
      patientDocuments {
        documentType
        documentPath
        date
        receivedAt
      }
      notes {
        date
        type
        note
      }
      aggregateAttribute
      createdAt
      updatedAt
    }
  }
`;

export const createCase = /* GraphQL */ `
  mutation CreateCase(
    $input: CreateCaseInput!
    $condition: ModelCaseConditionInput
  ) {
    createCase(input: $input, condition: $condition) {
      activities {
        activityName
        agentId
        description
        endDate
        stage
        startDate
        status
        processId
        currentStep
      }
      currentActivity {
        activityName
        agentId
        description
        endDate
        stage
        startDate
        status
        processId
        currentStep
      }
      caseId
      caseStatus
      currentAssignedAgentId
      description
      patientFirstName
      followUpDate
      patientLastName
      patientId
      documentURI
      agentLastName
      agentFirstName
      alertLevels {
        intake
        bi
        pa
        updateDate
        updatedBy
      }
      statusDetails {
        isIntakeCompleted
        isBICompleted
        isPACompleted
        updateDate
      }
      source
      isLocked
      lockedAt
      lockedBy
      createdAt
      updatedAt
    }
  }
`;

export const updateCase = /* GraphQL */ `
  mutation UpdateCase(
    $input: UpdateCaseInput!
    $condition: ModelCaseConditionInput
  ) {
    updateCase(input: $input, condition: $condition) {
      activities {
        activityName
        agentId
        description
        endDate
        stage
        startDate
        status
        processId
        currentStep
      }
      currentActivity {
        activityName
        agentId
        description
        endDate
        stage
        startDate
        status
        processId
        currentStep
      }
      caseId
      caseStatus
      currentAssignedAgentId
      description
      patientFirstName
      followUpDate
      patientLastName
      patientId
      documentURI
      agentLastName
      agentFirstName
      alertLevels {
        intake
        bi
        pa
        updateDate
        updatedBy
      }
      statusDetails {
        isIntakeCompleted
        isBICompleted
        isPACompleted
        updateDate
      }
      source
      isLocked
      lockedAt
      lockedBy
      createdAt
      updatedAt
    }
  }
`;

export const deleteCase = /* GraphQL */ `
  mutation DeleteCase(
    $input: DeleteCaseInput!
    $condition: ModelCaseConditionInput
  ) {
    deleteCase(input: $input, condition: $condition) {
      activities {
        activityName
        agentId
        description
        endDate
        stage
        startDate
        status
        processId
        currentStep
      }
      currentActivity {
        activityName
        agentId
        description
        endDate
        stage
        startDate
        status
        processId
        currentStep
      }
      caseId
      caseStatus
      currentAssignedAgentId
      description
      patientFirstName
      followUpDate
      patientLastName
      patientId
      documentURI
      agentLastName
      agentFirstName
      alertLevels {
        intake
        bi
        pa
        updateDate
        updatedBy
      }
      statusDetails {
        isIntakeCompleted
        isBICompleted
        isPACompleted
        updateDate
      }
      source
      isLocked
      lockedAt
      lockedBy
      createdAt
      updatedAt
    }
  }
`;

export const createCommunicationHistory = /* GraphQL */ `
  mutation CreateCommunicationHistory(
    $input: CreateCommunicationHistoryInput!
    $condition: ModelCommunicationHistoryConditionInput
  ) {
    createCommunicationHistory(input: $input, condition: $condition) {
      id
      patientId
      agentId
      caseId
      eventTime
      updateTime
      channel
      fromEntity
      toEntity
      description
      reference
      purpose
      direction
      status
      faxId
      createdAt
      updatedAt
    }
  }
`;

export const updateCommunicationHistory = /* GraphQL */ `
  mutation UpdateCommunicationHistory(
    $input: UpdateCommunicationHistoryInput!
    $condition: ModelCommunicationHistoryConditionInput
  ) {
    updateCommunicationHistory(input: $input, condition: $condition) {
      id
      patientId
      agentId
      caseId
      eventTime
      updateTime
      channel
      fromEntity
      toEntity
      description
      reference
      purpose
      direction
      status
      faxId
      createdAt
      updatedAt
    }
  }
`;

export const deleteCommunicationHistory = /* GraphQL */ `
  mutation DeleteCommunicationHistory(
    $input: DeleteCommunicationHistoryInput!
    $condition: ModelCommunicationHistoryConditionInput
  ) {
    deleteCommunicationHistory(input: $input, condition: $condition) {
      id
      patientId
      agentId
      caseId
      eventTime
      updateTime
      channel
      fromEntity
      toEntity
      description
      reference
      purpose
      direction
      status
      faxId
      createdAt
      updatedAt
    }
  }
`;

export const deletePatientToPrescriber = /* GraphQL */ `
  mutation DeletePatientToPrescriber(
    $input: DeletePatientToPrescriberInput!
    $condition: ModelPatientToPrescriberConditionInput
  ) {
    deletePatientToPrescriber(input: $input, condition: $condition) {
      patientId
      prescriberId
    }
  }
`;

export const createPatientToPrescriber = /* GraphQL */ `
  mutation CreatePatientToPrescriber(
    $input: CreatePatientToPrescriberInput!
    $condition: ModelPatientToPrescriberConditionInput
  ) {
    createPatientToPrescriber(input: $input, condition: $condition) {
      patientId
      prescriberId
    }
  }
`;

export const attachPrescribersToPatient = /* GraphQL */ `
  mutation AttachPrescribersToPatient($input: PrescribersToPatientRequest!) {
    attachPrescribersToPatient(input: $input) {
      patientId
      agentId
      prescribers
    }
  }
`;

export const detachPrescribersFromPatient = /* GraphQL */ `
  mutation DetachPrescribersFromPatient($input: PrescribersToPatientRequest!) {
    detachPrescribersFromPatient(input: $input) {
      patientId
      agentId
      prescribers
    }
  }
`;

export const addUpdatePriorAuthDenialTracking = /* GraphQL */ `
  mutation AddUpdatePriorAuthDenialTracking(
    $input: AddUpdatePriorAuthDenialTracking!
  ) {
    addUpdatePriorAuthDenialTracking(input: $input) {
      referralId
      insuranceKey
      denialReason
      mdoContacted
    }
  }
`;

export const addUpdatePriorAuthFreeDrug = /* GraphQL */ `
  mutation AddUpdatePriorAuthFreeDrug($input: AddUpdatePriorAuthFreeDrug!) {
    addUpdatePriorAuthFreeDrug(input: $input) {
      referralId
      insuranceKey
      lastOrderDate
      expectedDeliveryDate
      firstEnrollDate
      orderName
    }
  }
`;

// groupId
// locationId
// providerId
// inNetworkTIN
// inNetworkNPI
// outOfNetworkTIN
// outOfNetworkNPI
// insuranceCompanyName
// personSpokeWith
// callReference
// submittedDate
// followUpDate
// approvalInfo {
//   priorAuthNumber
//   serviceFrom
//   serviceTo
//   numberOfApprovedUnits
//   numberOfApprovedVisits
//   frequency
// }
// requestHistory {
//   billings: [BillingInfo]
//   insuranceCompanyName: String
//   personSpokeWith: String
//   callReference: String
//   jCodes: [ProcedureCode]
//   adminCodes: [ProcedureCode]
//   submittedDate: AWSDate
//   followUpDate: AWSDate
// }
export const addUpdatePriorAuthChecking = /* GraphQL */ `
  mutation AddUpdatePriorAuthChecking($input: AddUpdatePriorAuthChecking!) {
    addUpdatePriorAuthChecking(input: $input) {
      referralId
      insuranceKey
      stat
      authStatus
      isCompleted
    }
  }
`;

export const updatePayer = /* GraphQL */ `
  mutation UpdatePayer(
    $input: UpdatePayerInput!
    $condition: ModelPayerConditionInput
  ) {
    updatePayer(input: $input, condition: $condition) {
      id
      planName
      insurerId
      insurerName
      tradingPatnerId
      providerPhone
      claimAddress {
        city
        state
        streetName
        zip
      }
      electronicPayerId
      timelyFillingINN
      timelyFillingOON
      externalId
      createdAt
      updatedAt
    }
  }
`;

// export const createPayer = /* GraphQL */ `
//   mutation CreatePayer(
//     $input: CreatePayerInput!
//     $condition: ModelPayerConditionInput
//   ) {
//       createPayer(input: $input, condition: $condition) {
//       id
//       planName
//       insurerId
//       insurerName
//       tradingPatnerId
//       providerPhone
//       claimAddress {
//         city
//         state
//         streetName
//         zip
//       }
//       electronicPayerId
//       timelyFillingINN
//       timelyFillingOON
//       externalId
//       createdAt
//       updatedAt
//     }
//   }
// `;

export const addPayer = /* GraphQL */ `
  mutation AddPayer($input: CreatePayerInput!, $agentId: String!) {
    addPayer(payer: $input, agentId: $agentId) {
      message
      statusCode
      payer {
        timelyFillingOON
        insurerId
        insurerName
        id
        planName
        providerPhone
        timelyFillingINN
        tradingPatnerId
        electronicPayerId
        createdAt
        claimAddress {
          city
          county
          state
          streetName
          zip
        }
        externalId
      }
    }
  }
`;

export const generateFaxPdf = /* GraphQL */ `
  mutation GenerateFaxPdf($input: GenerateFaxPDFInput!) {
    generateFaxPDF(input: $input) {
      statusCode
      filePath
      error
    }
  }
`;

// [MM]
// referralId ???
// drugId
// drugName
// isCompleted
// agentId
// patientId
// drugReferral
export const createReferralOrder = /* GraphQL */ `
  mutation CreateReferralOrder($input: CreateReferralOrderInput!) {
    createReferralOrder(input: $input) {
      drugId
      drugName
      isCompleted
    }
  }
`;

// [MM]
// referralId
// drugId
// drugName
// isCompleted
// agentId
// patientId
// referral: ReferralInput!
// archivedDrugReferrals {
//   archivedDate: AWSDateTime
//   orderName: String
//   agentId: String
//   archivedReferral: DrugReferral
// }
export const updateReferralOrder = /* GraphQL */ `
  mutation UpdateReferralOrder($input: UpdateReferral!) {
    updateReferral(input: $input) {
      drugReferrals {
        referralType
        referralUUID
        referralId
        drugId
        drugName
        isCompleted
        specialPharmName
        specialPharmPhoneNumber
        clinical {
          orderPending
          orderDenied
          orderApproved
          expirationDateOfApproval
          clinicalNotes
        }
        referralOrder {
          referralOrderUUID
          orderName
          orderType
          orderingProvider
          orderDate
          orderExpires
          primaryDX {
            primaryDiagnosis
            description
            diagnosedBy
          }
          administrations {
            drugName
            maxOfTreatments
            route
            unitOfMeas
            approvedDosage
            calcDosage
            administer
            dosageFrequencyType
            dosageDayRange
            dosageEvery
            dosageDateTimeFrameEvery
            dosageFor
            dosageDateTimeFrameFor
          }
          preMedications {
            drugName
            maxOfTreatments
            route
            unitOfMeas
            approvedDosage
            administer
            isPreMed
          }
          notes
        }
      }
    }
  }
`;

// mutation MyMutation {
//   sendFax(input: {
//     agentId: "marty",
//     faxAttachment: "patientDocs/651626626/CertificateOfCompletion_HTML Essential Training.pdf",
//     faxType: DocumentRequest,
//     faxBody: "outboundFax/2021-5-29/651626626_+18772096059_1622297812150.pdf",
//     outboundFax: "+18772096059",
//     patientId: "651626626",
//     sendToPhone: "+17079617078",
//     subject: "HEY HEY HEY"
//   }) {
//     message
//     statusCode
//   }
// }
// }
export const sendFax = /* GraphQL */ `
  mutation SendFax($input: FaxInput!) {
    sendFax(input: $input) {
      statusCode
      message
    }
  }
`;
export const closeLabDraw = /* GraphQL */ `
  mutation closeLabDraw($input: CloseLabDrawInput!) {
    closeLabDraw(input: $input) {
      statusCode
      message
      nursingProcessId
    }
  }
`;

export const createScheduleEvent = /* GraphQL */ `
  mutation CreateScheduleEvent($input: CreateScheduleEventInput!) {
    createScheduleEvent(input: $input) {
      statusCode
      message
      events {
        id
        title
        status
        startTime
        endTime
        locationId
        chairId
        patientId
        referralId
        providerId
        agentId
        resources
        notes
        startTimeZone
        endTimeZone
        locationName
        patientLastName
        patientFirstName
        agentLastName
        agentFirstName
        drugName
      }
    }
  }
`;

export const createEvent = /* GraphQL */ `
  mutation CreateEvent($input: CreateEventInput!) {
    createEvent(input: $input) {
      id
      title
      createdBy
      updatedBy
      startTime
      endTime
      locationId
      chairId
      patientId
      referralId
      providerId
      agentId
      resources
      notes
      startTimeZone
      endTimeZone
      reason
      startTimestamp
      endTimestamp
      createdAt
      updatedAt
    }
  }
`;

export const updateScheduleEvent = /* GraphQL */ `
  mutation UpdateScheduleEvent($input: UpdateScheduleEventInput!) {
    updateScheduleEvent(input: $input) {
      statusCode
      message
      events {
        id
        title
        status
        startTime
        endTime
        locationId
        chairId
        patientId
        referralId
        providerId
        agentId
        resources
        notes
        startTimeZone
        endTimeZone
        locationName
        patientLastName
        patientFirstName
        agentLastName
        agentFirstName
        drugName
      }
    }
  }
`;
export const updatePendingEvent = /* GraphQL */ `
  mutation UpdatePendingEvent(
    $input: UpdatePendingEventInput!
    $agentId: String!
  ) {
    updatePendingEvent(agentId: $agentId, input: $input) {
      statusCode
      message
      events {
        id
        title
        status
        startTime
        endTime
        locationId
        chairId
        patientId
        referralId
        providerId
        agentId
        resources
        notes
        startTimeZone
        endTimeZone
        locationName
        patientLastName
        patientFirstName
        agentLastName
        agentFirstName
        drugName
      }
    }
  }
`;

export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent($input: UpdateEventInput!) {
    updateEvent(input: $input) {
      id
      title
      createdBy
      updatedBy
      startTime
      endTime
      locationId
      chairId
      patientId
      referralId
      providerId
      agentId
      resources
      notes
      startTimeZone
      endTimeZone
      reason
      startTimestamp
      endTimestamp
      createdAt
      updatedAt
    }
  }
`;

export const cancelScheduleEvent = /* GraphQL */ `
  mutation CancelScheduleEvent($input: CancelScheduleEventInput!) {
    cancelScheduleEvent(input: $input) {
      statusCode
      message
      events {
        id
        title
        status
        startTime
        endTime
        locationId
        chairId
        patientId
        referralId
        providerId
        agentId
        resources
        notes
        startTimeZone
        endTimeZone
        locationName
        patientLastName
        patientFirstName
        agentLastName
        agentFirstName
        drugName
      }
    }
  }
`;
export const cancelPendingEvent = /* GraphQL */ `
  mutation CancelPendingEvent(
    $agentId: String!
    $input: CancelScheduleEventInput!
  ) {
    cancelPendingEvent(agentId: $agentId, input: $input) {
      statusCode
      message
      events {
        id
        title
        status
        startTime
        endTime
        locationId
        chairId
        patientId
        referralId
        providerId
        agentId
        resources
        notes
        startTimeZone
        endTimeZone
        locationName
        patientLastName
        patientFirstName
        agentLastName
        agentFirstName
        drugName
      }
    }
  }
`;

export const cancelEvent = /* GraphQL */ `
  mutation CancelEvent($input: CancelEventInput!) {
    cancelEvent(input: $input) {
      id
      title
      createdBy
      updatedBy
      startTime
      endTime
      locationId
      chairId
      patientId
      referralId
      providerId
      agentId
      resources
      notes
      startTimeZone
      endTimeZone
      reason
      startTimestamp
      endTimestamp
      createdAt
      updatedAt
    }
  }
`;

// [MM] Infusion Steps
// STEP 0
// statusCode: String!
// message: String
// nursingProcessId: String
// nextStep: NursingStep
// initStepOrderReview: StepOrderReviewInitData
// initStepAssessment: StepAssessmentInitData
// initStepPreTreatment: StepPreTreatmentInitData
// initStepPreparation: StepPreparationInitData
export const stepCheckIn = /* GraphQL */ `
  mutation StepCheckIn($input: StepCheckInInput!) {
    stepCheckIn(input: $input) {
      statusCode
      message
      nursingProcessId
      nextStep
      initStepOrderReview {
        referralOrder {
          orderType
          orderingProvider
          orderDate
          orderExpires
          notes
          orderName
        }
        pathOfOrderPDF
      }
      initStepLabDraw {
        referralOrder {
          orderType
          orderingProvider
          orderDate
          orderExpires
          notes
          orderName
        }
        pathOfOrderPDF
      }
      initStepAssessment {
        patientWeights {
          recordNumber
          weightLB
          changeFromLastKnown
          lastKnown
          # entered
        }
        allergies {
          recordNumber
          allergen
          reaction
          # entered
        }
      }
      initStepPreTreatment {
        preMedications {
          recordNumber
          time
          medication
          dosing
          administeredLocation
          dosage
          unitOfDosage
          lot
          expiration
        }
      }
      initStepPreparation {
        drugId
        drugName
        referralOrder {
          orderType
          orderingProvider
          orderDate
          orderExpires
          notes
          orderName
        }
      }
    }
  }
`;

// [MM] Infusion Steps
// STEP 1
// statusCode: String!
// message: String
// nursingProcessId: String
// nextStep: NursingStep
// initStepOrderReview: StepOrderReviewInitData
// initStepAssessment: StepAssessmentInitData
// initStepPreTreatment: StepPreTreatmentInitData
// initStepPreparation: StepPreparationInitData
export const updateStepOrderReview = /* GraphQL */ `
  mutation UpdateStepOrderReview($input: UpdateStepOrderReviewInput!) {
    updateStepOrderReview(input: $input) {
      statusCode
      message
      nursingProcessId
      nextStep
      initStepOrderReview {
        referralOrder {
          orderType
          orderingProvider
          orderDate
          orderExpires
          notes
          orderName
        }
        pathOfOrderPDF
      }
      initStepAssessment {
        patientWeights {
          recordNumber
          weightLB
          changeFromLastKnown
          lastKnown
          # entered
        }
        allergies {
          recordNumber
          allergen
          reaction
          entered
        }
      }
      initStepPreTreatment {
        preMedications {
          recordNumber
          time
          medication
          dosing
          administeredLocation
          dosage
          unitOfDosage
          lot
          expiration
        }
      }
      initStepPreparation {
        drugId
        drugName
        referralOrder {
          orderType
          orderingProvider
          orderDate
          orderExpires
          notes
          orderName
        }
      }
    }
  }
`;

// [MM] Infusion Steps
// STEP 2
// statusCode: String!
// message: String
// nursingProcessId: String
// nextStep: NursingStep
// initStepOrderReview: StepOrderReviewInitData
// initStepAssessment: StepAssessmentInitData
// initStepPreTreatment: StepPreTreatmentInitData
// initStepPreparation: StepPreparationInitData
export const updateStepAssessment = /* GraphQL */ `
  mutation UpdateStepAssessment($input: UpdateStepAssessmentInput!) {
    updateStepAssessment(input: $input) {
      statusCode
      message
      nursingProcessId
      nextStep
      initStepOrderReview {
        referralOrder {
          orderType
          orderingProvider
          orderDate
          orderExpires
          notes
          orderName
        }
        pathOfOrderPDF
      }
      initStepAssessment {
        patientWeights {
          recordNumber
          weightLB
          changeFromLastKnown
          lastKnown
          # entered
        }
        allergies {
          recordNumber
          allergen
          reaction
          # entered
        }
      }
      initStepPreTreatment {
        preMedications {
          recordNumber
          time
          medication
          dosing
          administeredLocation
          dosage
          unitOfDosage
          lot
          expiration
        }
      }
      initStepPreparation {
        drugId
        drugName
        referralOrder {
          orderType
          orderingProvider
          orderDate
          orderExpires
          notes
          orderName
        }
      }
    }
  }
`;

// [MM] Infusion Steps
// STEP 3
// statusCode: String!
// message: String
// nursingProcessId: String
// nextStep: NursingStep
// initStepOrderReview: StepOrderReviewInitData
// initStepAssessment: StepAssessmentInitData
// initStepPreTreatment: StepPreTreatmentInitData
// initStepPreparation: StepPreparationInitData
export const updateStepPreTreatment = /* GraphQL */ `
  mutation UpdateStepPreTreatment($input: UpdateStepPreTreatmentInput!) {
    updateStepPreTreatment(input: $input) {
      statusCode
      message
      nursingProcessId
      nextStep
      initStepOrderReview {
        referralOrder {
          orderType
          orderingProvider
          orderDate
          orderExpires
          notes
          orderName
        }
        pathOfOrderPDF
      }
      initStepAssessment {
        patientWeights {
          recordNumber
          weightLB
          changeFromLastKnown
          lastKnown
          # entered
        }
        allergies {
          recordNumber
          allergen
          reaction
          # entered
        }
      }
      initStepPreTreatment {
        preMedications {
          recordNumber
          time
          medication
          dosing
          administeredLocation
          dosage
          unitOfDosage
          lot
          expiration
          initials
        }
      }
      # initStepPreparation {
      #   drugId
      #   drugName
      #   referralOrder {
      #     orderType
      #     orderingProvider
      #     orderDate
      #     orderExpires
      #     notes
      #     orderName
      #   }
      # }
    }
  }
`;

// [MM] Infusion Steps
// STEP 4
// statusCode: String!
// message: String
// nursingProcessId: String
// nextStep: NursingStep
// initStepOrderReview: StepOrderReviewInitData
// initStepAssessment: StepAssessmentInitData
// initStepPreTreatment: StepPreTreatmentInitData
// initStepPreparation: StepPreparationInitData
export const updateStepPreparation = /* GraphQL */ `
  mutation UpdateStepPreparation($input: UpdateStepPreparationInput!) {
    updateStepPreparation(input: $input) {
      statusCode
      message
      nursingProcessId
      nextStep
      initStepOrderReview {
        referralOrder {
          orderType
          orderingProvider
          orderDate
          orderExpires
          notes
          orderName
        }
        pathOfOrderPDF
      }
      initStepAssessment {
        patientWeights {
          recordNumber
          weightLB
          changeFromLastKnown
          lastKnown
          # entered
        }
        allergies {
          recordNumber
          allergen
          reaction
          # entered
        }
      }
      initStepPreTreatment {
        preMedications {
          recordNumber
          time
          medication
          dosing
          administeredLocation
          dosage
          unitOfDosage
          lot
          expiration
        }
      }
      initStepPreparation {
        drugId
        drugName
        referralOrder {
          orderType
          orderingProvider
          orderDate
          orderExpires
          notes
          orderName
        }
      }
    }
  }
`;

// [MM] Infusion Steps
// STEP 5
// statusCode: String!
// message: String
// nursingProcessId: String
// nextStep: NursingStep
// initStepOrderReview: StepOrderReviewInitData
// initStepAssessment: StepAssessmentInitData
// initStepPreTreatment: StepPreTreatmentInitData
// initStepPreparation: StepPreparationInitData
export const updateStepAdministration = /* GraphQL */ `
  mutation UpdateStepAdministration($input: UpdateStepAdministrationInput!) {
    updateStepAdministration(input: $input) {
      statusCode
      message
      nursingProcessId
      nextStep
      initStepOrderReview {
        referralOrder {
          orderType
          orderingProvider
          orderDate
          orderExpires
          notes
          orderName
        }
        pathOfOrderPDF
      }
      initStepAssessment {
        patientWeights {
          recordNumber
          weightLB
          changeFromLastKnown
          lastKnown
          # entered
        }
        allergies {
          recordNumber
          allergen
          reaction
          # entered
        }
      }
      initStepPreTreatment {
        preMedications {
          recordNumber
          time
          medication
          dosing
          administeredLocation
          dosage
          unitOfDosage
          lot
          expiration
        }
      }
      initStepPreparation {
        drugId
        drugName
        referralOrder {
          orderType
          orderingProvider
          orderDate
          orderExpires
          notes
          orderName
        }
      }
    }
  }
`;

// [MM] Infusion Steps
// STEP 6
// statusCode: String!
// message: String
// nursingProcessId: String
// nextStep: NursingStep
// initStepOrderReview: StepOrderReviewInitData
// initStepAssessment: StepAssessmentInitData
// initStepPreTreatment: StepPreTreatmentInitData
// initStepPreparation: StepPreparationInitData
export const updateStepCloseTreatment = /* GraphQL */ `
  mutation UpdateStepCloseTreatment($input: UpdateStepCloseTreatmentInput!) {
    updateStepCloseTreatment(input: $input) {
      statusCode
      message
      nursingProcessId
      nextStep
      initStepOrderReview {
        referralOrder {
          orderType
          orderingProvider
          orderDate
          orderExpires
          notes
          orderName
        }
        pathOfOrderPDF
      }
      initStepAssessment {
        patientWeights {
          recordNumber
          weightLB
          changeFromLastKnown
          lastKnown
          # entered
        }
        allergies {
          recordNumber
          allergen
          reaction
          # entered
        }
      }
      initStepPreTreatment {
        preMedications {
          recordNumber
          time
          medication
          dosing
          administeredLocation
          dosage
          unitOfDosage
          lot
          expiration
        }
      }
      initStepPreparation {
        drugId
        drugName
        referralOrder {
          orderType
          orderingProvider
          orderDate
          orderExpires
          notes
          orderName
        }
      }
    }
  }
`;

export const acquireLocker = /* GraphQL */ `
  mutation AcquireLocker($input: AcquireLocker!) {
    acquireLocker(input: $input) {
      success
      details
      lockerId
    }
  }
`;
export const releaseLocker = /* GraphQL */ `
  mutation ReleaseLocker($lockerId: String!, $agentId: String!) {
    releaseLocker(lockerId: $lockerId, agentId: $agentId) {
      success
      details
      lockerId
    }
  }
`;

export const createPrescriberInfo = /* GraphQL */ `
  mutation CreatePrescriberInfo($input: PrescriberInfoInput!) {
    createPrescriberInfo(input: $input) {
      statusCode
      message
    }
  }
`;

export const requestForOrder = /* GraphQL */ `
  mutation RequestForOrder(
    $agentId: String!
    $orderRequests: [OrderRequestInput!]!
  ) {
    requestForOrder(agentId: $agentId, orderRequests: $orderRequests) {
      # RequestForOrderResp
      statusCode
      message
      eventsForOrder
      orders {
        dose
        id
        initialQuantity
        locationId
        locationName
        orderDate
        orderQuantity
        # orderTimestamp
        orderStatus
        orderedBy
        productId
        productName
        unitOfMeasure
        # updatedAt
        # updatedBy
      }
    }
  }
`;

export const updateWorkItemStatus = /* GraphQL */ `
  mutation UpdateWorkItemStatus($input: UpdateWorkItemStatusInput!) {
    updateWorkItemStatus(input: $input) {
      success
      details
      workItem {
        id
        caseId
        work
        workStatus
        createdBy
        assignedTo
        patientId
        description
        referralId
        endTime
        startTime
        targetTime
        processId
        currentStep
        followupDate
        attachedData
        priority
        groupQueueId
        updatedBy
        createdAt
        updatedAt
      }
    }
  }
`;

export const saveOrderReport = /* GraphQL */ `
  mutation SaveOrderReport($agentId: String!, $orderReport: OrderReportInput!) {
    saveOrderReport(agentId: $agentId, orderReport: $orderReport) {
      message
      statusCode
    }
  }
`;

export const saveOrders = /* GraphQL */ `
  mutation SaveOrders(
    $agentId: String!
    $productOrders: [ProductOrderInput!]!
    $eventsForOrder: [String]
  ) {
    saveOrders(
      agentId: $agentId
      productOrders: $productOrders
      eventsForOrder: $eventsForOrder
    ) {
      message
      statusCode
    }
  }
`;

export const addProductsToInventory = /* GraphQL */ `
  mutation AddProductsToInventory(
    $agentId: String!
    $locationId: String
    $products: [ReceivedProductInput!]!
  ) {
    addProductsToInventory(
      agentId: $agentId
      locationId: $locationId
      products: $products
    ) {
      message
      statusCode
    }
  }
`;

export const reconcileInventory = /* GraphQL */ `
  mutation ReconcileInventory(
    $agentId: String!
    $reconcileRecords: [UpdateProductInventoryInput!]!
  ) {
    reconcileInventory(agentId: $agentId, reconcileRecords: $reconcileRecords) {
      message
      statusCode
    }
  }
`;

export const shippingProducts = /* GraphQL */ `
  mutation ShippingProducts(
    $agentId: String!
    $productShipment: ShippingProductInput!
  ) {
    shippingProducts(agentId: $agentId, productShipment: $productShipment) {
      message
      statusCode
    }
  }
`;

export const deleteWorkItem = /* GraphQL */ `
  mutation DeleteWorkItem($id: ID!) {
    deleteWorkItem(input: { id: $id }) {
      assignedTo
      attachedData
      caseId
      createdAt
      currentStep
      description
      endTS
      endTime
      followupDate
      groupQueueId
      id
      patientId
      priority
      processId
      referralId
      startTime
      targetTime
      updatedAt
      updatedBy
      work
      workStatus
      workHistory {
        agentId
        endTime
        lastWorkStatus
        startTime
      }
    }
  }
`;

export const cancelTreatment = /* GraphQL */ `
  mutation cancelTreatment($input: CancelTreatmentInput!) {
    cancelTreatment(input: $input) {
      statusCode
      message
    }
  }
`;

export const createSalesCommission = /* GraphQL */ `
  mutation CreateSalesCommission($input: CreateSalesCommissionInput!) {
    createSalesCommission(input: $input) {
      id
    }
  }
`;
export const createCodeInfo = /* GraphQL */ `
  mutation CreateCodeInfo($input: CreateCodeInfoInput!) {
    createCodeInfo(input: $input) {
      code
      description
    }
  }
`;
export const deleteSalesCommission = /* GraphQL */ `
  mutation DeleteSalesCommission($input: DeleteSalesCommissionInput!) {
    deleteSalesCommission(input: $input) {
      id
      prescriberId
    }
  }
`;

export const updateNarrativeNotes = /* GraphQL */ `
  mutation UpdateNarrativeNotes(
    $agentId: String!
    $notes: String!
    $nursingProcessId: String!
  ) {
    addUpdateNursingNotes(
      agentId: $agentId
      notes: $notes
      nursingProcessId: $nursingProcessId
    ) {
      message
      statusCode
    }
  }
`;
export const resendClaims = /* GraphQL */ `
  mutation resendClaims($agentId: String!, $npIds: [String!]!) {
    resendClaims(agentId: $agentId, npIds: $npIds) {
      message
      statusCode
    }
  }
`;

export const createInsurerAIC = /* GraphQL */ `
  mutation CreateInsurerAIC($input: CreateInsurerAICInput!) {
    createInsurerAIC(input: $input) {
      name
      insurerType
      mainPhoneNumber
      taxId
      updatedAt
      id
      createdAt
      billingAddress {
        streetName
        city
        county
        state
        zip
      }
    }
  }
`;

// reassignWork(input: {currentAgentId: "", reassignedToAgentId: "", reassignedToQueueId: INTAKE, workItemId: ""}) {
//   success
//   details
//   workItem {
//     assignedTo
//     id
//     patientId
//     work
//     workStatus
//   }
// }

export const reassignWork = /* GraphQL */ `
  mutation ReassignWork($input: ReassignWorkInput!) {
    reassignWork(input: $input) {
      success
      details
      workItem {
        assignedTo
        id
        patientId
        work
        workStatus
      }
    }
  }
`;

export const addInsurer = /* GraphQL */ `
  mutation AddInsurer($insurer: CreateInsurerInput!, $agentId: String!) {
    addInsurer(insurer: $insurer, agentId: $agentId) {
      statusCode
      message
      insurer {
        name
        insurerType
        mainPhoneNumber
        taxId
        updatedAt
        id
        createdAt
        billingAddress {
          streetName
          city
          county
          state
          zip
        }
      }
    }
  }
`;

export const movePatientDoc = /* GraphQL */ `
  mutation movePatientDoc(
    $agentId: String!
    $fromPatientId: String!
    $patientDocument: PatientDocumentInput!
    $toPatientId: String
  ) {
    movePatientDoc(
      agentId: $agentId
      fromPatientId: $fromPatientId
      patientDocument: $patientDocument
      toPatientId: $toPatientId
    ) {
      message
      statusCode
    }
  }
`;

export const updatePatientKeyInfo = /* GraphQL */ `
  mutation updatePatientKeyInfo(
    $agentId: String!
    $patientKeyInfo: PatientKeyInfoInput!
  ) {
    updatePatientKeyInfo(agentId: $agentId, patientKeyInfo: $patientKeyInfo) {
      message
      statusCode
    }
  }
`;

export const updatePatientStatus = /* GraphQL */ `
  mutation updatePatientStatus(
    $agentId: String!
    $patientId: String!
    $patientStatus: PatientStatus!
    $effectivePeriod: DateRangeInDaysInput
    $reasons: String
  ) {
    updatePatientStatus(
      agentId: $agentId
      patientId: $patientId
      patientStatus: $patientStatus
      effectivePeriod: $effectivePeriod
      reasons: $reasons
    ) {
      message
      statusCode
    }
  }
`;

export const snoozeWorkItem = /* GraphQL */ `
  mutation snoozeWorkItem(
    $agentId: String!
    $targetDate: AWSDateTime!
    $workItemId: String!
  ) {
    snoozeWorkItem(
      agentId: $agentId
      targetDate: $targetDate
      workItemId: $workItemId
    ) {
      message
      statusCode
    }
  }
`;

export const discontinueReferral = /* GraphQL */ `
  mutation DiscontinueReferral(
    $agentId: String!
    $patientId: String!
    $referralId: String!
    $survey: AWSJSON!
  ) {
    discontinueReferral(
      agentId: $agentId
      patientId: $patientId
      referralId: $referralId
      survey: $survey
    ) {
      message
      statusCode
    }
  }
`;

export const archiveReferral = /* GraphQL */ `
  mutation ArchiveReferral(
    $agentId: String!
    $patientId: String!
    $referralId: String!
    $reasonForArchiving: ReasonArchivingOrder!
  ) {
    archiveReferral(
      agentId: $agentId
      patientId: $patientId
      referralId: $referralId
      reasonForArchiving: $reasonForArchiving
    ) {
      message
      statusCode
    }
  }
`;

export const deactivatePatient = /* GraphQL */ `
  mutation deactivatePatient(
    $agentId: String!
    $patientId: String!
    $reasons: AWSJSON
  ) {
    deactivatePatient(
      agentId: $agentId
      patientId: $patientId
      reasons: $reasons
    ) {
      message
      statusCode
    }
  }
`;

export const addPatientSurvey = /* GraphQL */ `
  mutation addPatientSurvey(
    $agentId: String!
    $patientId: String!
    $patientSurvey: PatientSurveyInput!
  ) {
    addPatientSurvey(
      agentId: $agentId
      patientId: $patientId
      patientSurvey: $patientSurvey
    ) {
      message
      statusCode
    }
  }
`;

export const createPendingEvent = /* GraphQL */ `
  mutation createPendingEvent(
    $input: CreatePendingEventInput!
    $agentId: String!
  ) {
    createPendingEvent(agentId: $agentId, input: $input) {
      events {
        adminSequenceNumber
        agentFirstName
        agentId
        agentLastName
        chairId
        dob
        endTime
        endTimeZone
        eventType
        id
        locationId
        locationName
        notes
        nursingProcessId
        orderName
        patientFirstName
        patientId
        patientLastName
        patientLatestWeight
        providerFirstName
        providerId
        providerLastName
        reason
        referralId
        drugName
        resources
        startTime
        startTimeZone
        status
        title
      }
      message
      statusCode
    }
  }
`;

export const updateProviderIdInNursingProcess = /* GraphQL */ `
  mutation UpdateProviderIdInNursingProcessInput(
    $input: UpdateProviderIdInNursingProcessInput
  ) {
    updateProviderIdInNursingProcess(input: $input) {
      message
      nextStep
      nursingProcessId
      statusCode
      initStepAssessment {
        allergies {
          recordNumber
          allergen
          reaction
          entered
        }
        patientWeights {
          recordNumber
          weightLB
          changeFromLastKnown
          lastKnown
          entered
          weight
          unitOfWeight
        }
      }
      initStepOrderReview {
        pathOfOrderPDF
      }
      initStepPreparation {
        drugName
      }
      initStepPreTreatment {
        preMedications {
          administeredLocation
        }
      }
    }
  }
`;

export const updatePatientSurvey = /* GraphQL */ `
  mutation updatePatientSurvey(
    $agentId: String!
    $patientId: String!
    $patientSurvey: PatientSurveyInput!
  ) {
    updatePatientSurvey(
      agentId: $agentId
      patientId: $patientId
      patientSurvey: $patientSurvey
    ) {
      message
      statusCode
    }
  }
`;

export const disposeCall = /* GraphQL */ `
  mutation DisposeCall(
    $agentId: String!
    $workItemId: String!
    $outcome: String!
    $futureCallNeeded: Boolean!
    $futureCallDateTime: AWSDateTime
    $notes: String
  ) {
    disposeCall(
      agentId: $agentId
      workItemId: $workItemId
      outcome: $outcome
      futureCallNeeded: $futureCallNeeded
      futureCallDateTime: $futureCallDateTime
      notes: $notes
    ) {
      details
      success
      workItem {
        workStatus
      }
    }
  }
`;
