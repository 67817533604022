import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Storage } from 'aws-amplify';
// Kendo
import { DropDownTree } from '@progress/kendo-react-dropdowns';
import { Button } from '@progress/kendo-react-buttons';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Field } from '@progress/kendo-react-form';
import { DateTimePicker } from '@progress/kendo-react-dateinputs';
// components
import PatientDocument from '@/components/Patient/PatientDocument.jsx';
// util
import {
  expandedState,
  processReferralTreeData
} from './referral-tree-data-operations.js';
import {
  formatDateTimeToDefault,
  formatDateToAWSDateTime
} from '@/common/DateHelper.js';
// context
import { PatientContext } from '@/context/PatientContext';
import { ReferralContext } from '@/context/ReferralContext';
import { UserContext } from '@/context/UserContext.js';
// styles
import styles from './AttachReferralDocument.module.scss';
import {
  DOC_TYPE_LAB_DRAW,
  DOC_TYPE_ORDER_REFILL,
  DOC_TYPE_REFERRAL,
  DOC_TYPE_REFERRAL_ADDENDUM
} from '@/constants/enum.js';

const selectField = 'selected';
const expandField = 'expanded';
const dataItemKey = 'text';
const textField = 'text';
const subItemsField = 'items';
const fields = {
  selectField,
  expandField,
  dataItemKey,
  subItemsField
};

const AttachReferralDocument = (props) => {
  const rxOrderValue = props?.rxOrderValue;
  const isNewOrder = props?.isNewOrder;
  const orderData = props?.orderData;
  const firstDocumentDate = orderData?.originalReceivedDate;
  const nameText = rxOrderValue?.documentPath?.split('/');
  const { selectedPatientInfo, setSelectedTab } = useContext(PatientContext);
  const { isAdmin } = useContext(UserContext);
  const {
    referralOrderUrl,
    setReferralOrderUrl,
    setReferralOrderDate,
    originalOrderDate,
    setOriginalOrderDate
  } = useContext(ReferralContext);
  const [value, setValue] = useState();
  const [selectedDocumentUrl, setSelectedDocumentUrl] = useState(null);
  const [showDateModal, setShowDateModal] = useState(false);
  const [showDropDownTree, setShowDropDownTree] = useState(false);
  const [showPatientDocDialog, setShowPatientDocDialog] = useState(false);
  const [storageDate, setStorageDate] = useState(null); // storageDate is the time and date when the choosen document was added to storage.
  const [faxDate, setFaxDate] = useState(null); // faxDate is the time and date when the fax was received into the system.
  const history = useHistory();
  const [expanded, setExpanded] = useState(
    selectedPatientInfo?.patientDocuments?.length > 0
      ? [selectedPatientInfo?.patientDocuments?.[0][dataItemKey]]
      : [{}]
  );

  useEffect(() => {
    setValue();
    setFaxDate();
    setStorageDate();
    if (orderData?.rxOrderFile && nameText) {
      setReferralOrderUrl(orderData?.rxOrderFile);
      setValue({
        expanded: false,
        receivedAt: rxOrderValue?.receivedAt,
        selected: null,
        text: nameText[nameText.length - 1],
        path: rxOrderValue?.documentPath
      });
      setFaxDate(
        rxOrderValue?.receivedAt
          ? formatDateTimeToDefault(rxOrderValue?.receivedAt)
          : null
      );
      setReferralOrderDate(
        rxOrderValue?.receivedAt
          ? formatDateToAWSDateTime(rxOrderValue?.receivedAt)
          : null
      );
      setSelectedDocumentUrl(rxOrderValue?.documentPath);
    }
  }, [rxOrderValue?.documentPath, orderData]);

  useEffect(() => {
    if (isNewOrder) {
      setReferralOrderUrl();
      setReferralOrderDate();
    }
  }, []);

  const onChange = (event) => {
    setValue(event.value);
    setReferralOrderUrl(event?.value?.path);
    setFaxDate(
      event?.value?.receivedAt
        ? formatDateTimeToDefault(event?.value?.receivedAt)
        : null
    );
    setReferralOrderDate(
      event?.value?.receivedAt
        ? formatDateToAWSDateTime(event?.value?.receivedAt)
        : null
    );
  };
  const onExpandChange = React.useCallback(
    (event) => setExpanded(expandedState(event.item, dataItemKey, expanded)),
    [expanded]
  );
  useEffect(() => {
    if (value && expanded.indexOf(value?.text) < 0) {
      setExpanded(expandedState(value, dataItemKey, expanded));
    }
  }, [value]);

  const onDocumentClick = async (e) => {
    e.preventDefault();
    const conf = { download: false };
    const s3ImageURL = await Storage.get(referralOrderUrl, conf);
    setSelectedDocumentUrl(s3ImageURL);
    setShowPatientDocDialog(true);
  };

  // for each document type find type and add to list
  const treeData = React.useMemo(
    () =>
      selectedPatientInfo?.patientDocuments?.length > 0
        ? processReferralTreeData(
            selectedPatientInfo?.patientDocuments,
            {
              expanded,
              value
            },
            fields
          )
        : [],
    [expanded, value]
  );

  useEffect(() => {
    if (rxOrderValue?.documentPath || referralOrderUrl) {
      Storage.list(referralOrderUrl) // for listing ALL files without prefix, pass '' instead
        .then((result) => {
          return setStorageDate(
            formatDateTimeToDefault(result?.[0]?.lastModified)
          );
        })
        .catch((err) => console.log(err));
    }
    const hasCorrectDocument = (element) => element?.items?.length;
    setShowDropDownTree(treeData.some(hasCorrectDocument));
  }, [rxOrderValue?.documentPath, referralOrderUrl]);

  const toggleShowPatientDocDialog = () => {
    setShowPatientDocDialog(!showPatientDocDialog);
  };

  const sendToUpload = () => {
    history.push('/patient-portal', {
      searchType: 'Patient',
      redirectToUpload: true
    });
    setSelectedTab(0);
  };

  const onOrgDateUpdate = (e) => {
    setOriginalOrderDate(e.value);
  };

  const isCorrectSelection = (item) =>
    item?.text !== DOC_TYPE_LAB_DRAW &&
    item?.text !== DOC_TYPE_ORDER_REFILL &&
    item?.text !== DOC_TYPE_REFERRAL &&
    item?.text !== DOC_TYPE_REFERRAL_ADDENDUM;

  return (
    <>
      <div className='row'>
        {showDropDownTree || value ? (
          <>
            <div className='col-4'>
              <DropDownTree
                name='referralDocument'
                data={treeData}
                value={value}
                required={true}
                valid={isCorrectSelection(value)}
                onChange={onChange}
                placeholder='Add Referral Document:'
                textField={textField}
                dataItemKey={dataItemKey}
                selectField={selectField}
                expandField={expandField}
                onExpandChange={onExpandChange}
              />
            </div>
            {((orderData?.rxOrderFile && referralOrderUrl) ||
              (value && referralOrderUrl)) && (
              <div className='col-1'>
                <Button
                  icon='preview'
                  onClick={(e) => onDocumentClick(e)}
                  disabled={!value}
                >
                  Preview
                </Button>
              </div>
            )}
          </>
        ) : (
          <div className='col-3'>
            <span className='help-link' onClick={sendToUpload}>
              Upload Document
            </span>
          </div>
        )}
        {faxDate && value ? (
          <div className='col-3 mt-1'>CURRENT DOCUMENT: {faxDate}</div>
        ) : (
          showDropDownTree &&
          storageDate &&
          !isNewOrder && (
            <div className='col-3 mt-1'> ORIGINAL RX ORDER FILE</div>
          )
        )}
        {storageDate && value && showDropDownTree && !isNewOrder && (
          <div className='col-3 mt-1'>DOCUMENT ATTACHED: {storageDate}</div>
        )}

        <div>
          {showPatientDocDialog && (
            <Dialog
              title='Patient Document'
              height={1100}
              width={850}
              initialTop={100}
              showDialog
              onClose={toggleShowPatientDocDialog}
            >
              <PatientDocument file={selectedDocumentUrl} />
            </Dialog>
          )}
        </div>
        {!referralOrderUrl && (
          <div className='col-2 mt-1 ml-1'>
            <div className={styles.alert}>Alert: Please add RX Order.</div>
          </div>
        )}
      </div>
      {!isNewOrder && (
        <div className='row'>
          {firstDocumentDate && (
            <div className='col-5 mt-3'>
              {isAdmin ? (
                <span
                  className='help-link'
                  onClick={() => setShowDateModal(!showDateModal)}
                >
                  ORIGINAL RECEIVED DATE:{' '}
                  {formatDateTimeToDefault(
                    originalOrderDate || firstDocumentDate
                  )}
                </span>
              ) : (
                <span>
                  ORIGINAL RECEIVED DATE:{' '}
                  {formatDateTimeToDefault(
                    originalOrderDate || firstDocumentDate
                  )}
                </span>
              )}
            </div>
          )}
          {showDateModal && (
            <>
              <div className='col-3 mt-3'>UPDATE RECEIVED DATE:</div>
              <div className='col-3 mt-3'>
                <Field
                  component={DateTimePicker}
                  width='100%'
                  name='dateTime'
                  defaultValue={
                    originalOrderDate || new Date(firstDocumentDate)
                  }
                  required
                  onChange={onOrgDateUpdate}
                />
              </div>
            </>
          )}
        </div>
      )}
      <div />
    </>
  );
};

export default AttachReferralDocument;
