import React from 'react';

//Kendo
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { orderBy } from '@progress/kendo-data-query';

// component
import { ColumnMenu } from '@/components/common-components/columnMenu';
import BadgeInline from '@/components/common-components/BadgeInline';
import { DashboardDetails } from './DashboardDetails';

// util
import { cellWithBackGround } from './dashboardHelper';
import { getDashStatusTitle } from '../Queue/FilterSystem/QueueFilters.utils';

// style
import styles from './Dashboard.module.scss';

function WorkItemGrid(detailsData) {
  const [data, setData] = React.useState(detailsData.detailsData);

  const expandChange = (event) => {
    const newData = data.map((item) => {
      if (item.workItemStatus === event.dataItem.workItemStatus) {
        item.expanded = !event.dataItem.expanded;
      }
      return item;
    });
    setData(newData);
  };
  const workStatusTitleCell = ({ dataItem }) => {
    const counter = dataItem.workItems.fullData.length;
    const title = getDashStatusTitle(dataItem?.workItemStatus);

    if (counter > 0) {
      return (
        <td>
          <BadgeInline inlineText={title} type='info' text={counter} />
        </td>
      );
    }

    return <td>{title}</td>;
  };
  const footerCell = ({ field }) => {
    // Split the path string into an array of keys
    const keys = field.split('.');

    const sum = data.reduce((accumulator, currentValue) => {
      // Initialize a variable to store the result
      let result = currentValue;

      // Traverse the object using each key in the path
      for (const key of keys) {
        result = result[key];
      }
      return accumulator + result;
    }, 0);

    return <td className={sum === 0 ? styles.noValue : ''}>{sum}</td>;
  };
  const initialSort = [
    {
      field: 'createdAt',
      dir: 'dsc'
    }
  ];
  const [sort, setSort] = React.useState(initialSort);
  const getDayGridColumns = () => {
    const cols = [
      {
        field: 'workItemStatus',
        title: 'Status',
        cell: workStatusTitleCell,
        footerCell: () => <td>Total:</td>
      },
      {
        field: 'workItems.day0',
        title: 'First Day',
        cell: cellWithBackGround,
        footerCell
      },
      {
        field: 'workItems.day1',
        title: 'Day 1',
        cell: cellWithBackGround,
        footerCell
      },
      {
        field: 'workItems.day2',
        title: 'Day 2',
        cell: cellWithBackGround,
        footerCell
      },
      {
        field: 'workItems.day3',
        title: 'Day 3',
        cell: cellWithBackGround,
        footerCell
      },
      {
        field: 'workItems.day4',
        title: 'Day 4',
        cell: cellWithBackGround,
        footerCell
      },
      {
        field: 'workItems.day5',
        title: 'Day 5',
        cell: cellWithBackGround,
        footerCell
      },
      {
        field: 'workItems.day6',
        title: 'Day 6',
        cell: cellWithBackGround,
        footerCell
      },
      {
        field: 'workItems.day7',
        title: 'Day 7',
        cell: cellWithBackGround,
        footerCell
      },
      {
        field: 'workItems.day8',
        title: 'Day 8',
        cell: cellWithBackGround,
        footerCell
      },
      {
        field: 'workItems.day9',
        title: 'Day 9',
        cell: cellWithBackGround,
        footerCell
      },
      {
        field: 'workItems.day10',
        title: '10-14',
        cell: cellWithBackGround,
        footerCell
      },
      {
        field: 'workItems.day15',
        title: "15+ Day's",
        cell: cellWithBackGround,
        footerCell
      }
    ];
    return cols;
  };
  const dayColumns = getDayGridColumns();
  return (
    <Grid
      data={orderBy(data, sort)}
      sortable={true}
      sort={sort}
      onSortChange={(e) => {
        setSort(e.sort);
      }}
      expandField='expanded'
      onExpandChange={expandChange}
      detail={DashboardDetails}
      total={1} // total set to stop paging error in the browser. Remove if you want to use paging.
    >
      {dayColumns.map(({ minWidth, ...rest }, idx) => (
        <Column key={idx} width={minWidth} columnMenu={ColumnMenu} {...rest} />
      ))}
    </Grid>
  );
}

export default WorkItemGrid;
