export default {
  intake: 'INTAKE',
  bi: 'BI',
  pa: 'PA',
  nursing: 'NURSING',
  schedule: 'SCHEDULE',
  supervisor: 'SUPERVISOR',
  admin: 'ADMIN',
  clinician: 'CLINICIAN',
  clinicalAdmin: 'CLINICALADMIN',
  inventory: 'INVENTORY',
  billing: 'BILLING',
  sales: 'SALES',
  nursingAdmin: 'NURSINGADMIN'
};
