import React, { createContext, useState } from 'react';
import {
  getEventCall,
  getNursingProcessCall,
  getPatientBucketCall,
  getInfusionHeaderDetailsCall
} from '@/api/infusion/api.infusion';
import {
  findReferralInBucket,
  hasPatientSurveyComplete,
  hasPatientSurveyReviewed,
  hasPatientSurveySkipped,
  initCurrentStepV2,
  initStepperV2,
  shouldRequirePatientSurvey
} from '@/components/Infusion/infusionHelper';

export const InfusionContext = createContext();

const InfusionContextProvider = ({ children }) => {
  const [infusion, setInfusion] = useState(null);
  const [steps, setSteps] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [hasSurveyReviewed, setHasSurveyReviewed] = useState(false);

  const loadInfusion = async (id) => {
    try {
      const nursingProcess = await getNursingProcessCall(id);
      const patientBucket = await getPatientBucketCall(nursingProcess.patientId);
      const scheduleEvent = await getEventCall(nursingProcess.scheduleEventId);
      const currentReferral = findReferralInBucket(
        patientBucket,
        nursingProcess.referralId
      );
      const { location, provider } = await getInfusionHeaderDetailsCall(
        nursingProcess.locationId,
        nursingProcess.providerId
      );
      const infusionCompleted = nursingProcess.status === 'CLOSED';
      const infusionSteps = initStepperV2({
        ...nursingProcess,
        infusionCompleted
      });
      const currentInfusionStep = initCurrentStepV2(nursingProcess);

      const infusion = {
        nursingProcess,
        patientBucket,
        scheduleEvent,
        currentReferral,
        location,
        provider
      };

      const isSurveyComplete = hasPatientSurveyComplete(infusion);

      infusion.isSurveyRequired = shouldRequirePatientSurvey(infusion);
      infusion.isSurveyComplete = isSurveyComplete;
      infusion.isSurveyReviewed = isSurveyComplete
        ? hasPatientSurveyReviewed(infusion)
        : false;
      infusion.isSurveySkipped = hasPatientSurveySkipped(infusion);

      setInfusion(infusion);
      setSteps(infusionSteps);
      setCurrentStep(currentInfusionStep);
    } catch (err) {
      console.error('InfusionContextProvider::loadInfusion err: ', err);
    }
  };

  const onStepChange = (e) => {
    setCurrentStep(Math.round(e.value));
  };

  const updateStepper = (isValid) => {
    const updatedSteps = steps.map((step, index) => ({
      ...step,
      isValid: index === currentStep ? isValid : step.isValid
    }));

    setSteps(updatedSteps);
  };

  return (
    <InfusionContext.Provider
      value={{
        infusion,
        setInfusion,
        loadInfusion,
        steps,
        setSteps,
        currentStep,
        setCurrentStep,
        onStepChange,
        updateStepper,
        hasSurveyReviewed,
        setHasSurveyReviewed
      }}
    >
      {children}
    </InfusionContext.Provider>
  );
};

export default InfusionContextProvider;
