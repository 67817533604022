import React from 'react';
import DOMPurify from 'isomorphic-dompurify';

const Sanitize = ({ html = '', allowedTags = [], allowedAttributes = [] }) => {
    const clean = DOMPurify.sanitize(html, {
      ALLOWED_TAGS: allowedTags,
      ALLOWED_ATTR: allowedAttributes
    });
    return (
      <span
        className="sanitized-html"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: clean }}
      />
    );
};
export default Sanitize;
