import React from "react";

const EditButton = ({ handleClick, ...props }) => {
  return (
    <button
      type="button"
      className="k-button"
      onClick={handleClick}
      {...props}
    >
      <i className="fa-solid fa-pencil icon-blue"></i>
    </button>
  );
};

export default EditButton;
