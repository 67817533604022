import React, { useContext } from "react";
import {
  Notification,
  NotificationGroup
} from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";

import { NotifContext } from "@/context/NotifContext";

const Notifier = () => {
  const { queue } = useContext(NotifContext);

  return (
    <NotificationGroup
      style={{
        top: 0,
        left: "50%",
        transform: "translateX(-50%)",
      }}
    >
      {queue.map(notification => {
        const { id, type, message } = notification;

        return (
          <Fade key={id}>
            <Notification
              type={{
                style: type,
                icon: false
              }}
            >
              <span>{message}</span>
            </Notification>
          </Fade>
        );
      })}
    </NotificationGroup>
  );
};

export default Notifier;
