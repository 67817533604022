import React, { useEffect, useState, useCallback, useContext } from 'react';

import { Input, Switch } from '@progress/kendo-react-inputs';
import { TimePicker } from '@progress/kendo-react-dateinputs';
import { Error } from '@progress/kendo-react-labels';
import { Form, Field } from '@progress/kendo-react-form';

import * as moment from 'moment';
import { validateInput } from '@/common/Validation';
import { authUser } from '@/common/aws-cognito';

import { MessageDialog } from '@/components/common-components/MessageDialog';
import WindowDialog from '@/components/common-components/WindowDialog';
// import WarningBlock from "@/components/common-components/WarningBlock";

import { connectToGraphqlAPI } from '@/provider';
import { getNursingProcess, getLocationAic } from '@/graphql/queries';

import { Constants } from '@/constants';
import { NotifContext } from '@/context/NotifContext';
import { UserContext } from '@/context/UserContext';
import RevisitCloseTreatment from './Revisit/RevisitCloseTreatment';
import {
  adjustUTC,
  formatDateToAWSDateTime,
  formatTimeToDefault
} from '@/common/DateHelper';
import { getSameHoursAndMinsForTimezone } from '../Scheduling/getUTCTimeString';
import { getTimeZoneForLocation } from '../Scheduling/getTimezoneForLocation';
import Alert from '@/components/common-components/Alert';

const CloseTreatment = props => {
  const { infusion } = props;
  const { nursingProcessId } = props;

  const { showError } = useContext(NotifContext);
  const { agent } = useContext(UserContext);

  const [nursingProcess, setNursingProcess] = useState({});
  const [locationData, setLocationData] = useState();

  const [apiVitals, setApiVitals] = useState([]);
  // const [itemVitals, setItemVitals] = useState([]);

  const [stepAllowed, setStepAllowed] = useState(false);
  const [dialogOption, setDialogOption] = useState({});

  const [showCloseTreatmentDialog, setShowCloseTreatmentDialog] =
    useState(false);

  const [allStepsCompleted, setAllStepsCompleted] = useState(false);
  const [pendingSteps, setPendingSteps] = useState([]);

  const [hasNarrativeNoteComplete, setNarrativeNoteComplete] = useState(false);
  const [narrativeNoteTouched, setNarrativeNoteTouched] = useState(false);

  const [departureTime, setDepartureTime] = useState(null);
  const [departureTimeTouched, setDepartureTimeTouched] = useState(false);

  const toggleNarrativeCompletion = useCallback(
    e => {
      if (!narrativeNoteTouched) {
        setNarrativeNoteTouched(true);
      }

      setNarrativeNoteComplete(e.value);
    },
    [setNarrativeNoteComplete]
  );

  const onDepartureTimeUpdate = useCallback(
    e => {
      if (!departureTimeTouched) {
        setDepartureTimeTouched(true);
      }

      setDepartureTime(e.value);
    },
    [setNarrativeNoteTouched]
  );

  const getOriginaDepTime = () => {
    if (props.infusionCompleted) {
      return nursingProcess.id && locationData
        ? formatTimeToDefault(adjustUTC(
              nursingProcess.stepCloseTreatment.departureTime,
              locationData.timezone
            ))
        : '';
    }

    return '';
  };

  const originalDepartureTime = getOriginaDepTime();

  const addPendingStep = newStep =>
    setPendingSteps(curSteps =>
      [...curSteps, newStep].filter((v, i, a) => a.indexOf(v) === i));

  // MAIN INITIATOR
  useEffect(() => {
    getNursingProcessCall(nursingProcessId);
  }, []);

  useEffect(() => {
    handleLoadInfusion();
  }, [nursingProcess]);

  useEffect(() => {
    if (nursingProcessId && nursingProcess.locationId) {
      callGetLocation(nursingProcess.locationId);
    }
  }, [nursingProcess, nursingProcessId]);

  const callGetLocation = async locationId => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getLocationAic,
        variables: {
          id: locationId
        }
      });
      if (data?.data?.getLocationAIC) {
        const aicData = { ...data.data.getLocationAIC };
        const locData = {
          ...aicData,
          timezone: getTimeZoneForLocation(aicData.address.zip)
        };
        setLocationData(locData);
      }
    } catch (err) {
      console.error('callGetEvent err: ', err);
    }
  };

  const getNursingProcessCall = async id => {
    let npid = id;
    if (!npid) {
      npid = '';
    }
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getNursingProcess,
        variables: { id: npid }
      });
      if (data && data.data && data.data.getNursingProcess) {
        setNursingProcess(data.data.getNursingProcess);
      } else {
        handleLoadInfusion();
      }
    } catch (err) {
      console.log('CloseTreatment getNursingProcessCall err: ', err);
      alert('ERROR: getNursingProcessCall');
    }
  };

  const handleLoadInfusion = () => {
    if (nursingProcess && nursingProcess.stepReview) {
      if (nursingProcess.stepReview.orderApproved) {
        setStepAllowed(true);
      }
    }

    if (
      nursingProcess &&
      nursingProcess.stepReview &&
      nursingProcess.stepReview.orderApproved &&
      nursingProcess.stepAssessment &&
      nursingProcess.stepPreTreatment &&
      nursingProcess.stepPreTreatment.preTreatmentCompleted &&
      nursingProcess.stepPreparation &&
      nursingProcess.stepPreparation.preparationComplete &&
      nursingProcess.stepAdministration &&
      nursingProcess.stepAdministration.administrationComplete
    ) {
      setAllStepsCompleted(true);
    }

    if (nursingProcess && nursingProcess.id) {
      if (
        !nursingProcess.stepReview ||
        !nursingProcess.stepReview.orderApproved
      ) {
        addPendingStep('Order Review');
      }

      if (!nursingProcess.stepAssessment) {
        addPendingStep('Assessment');
      }

      if (
        !nursingProcess.stepPreTreatment ||
        !nursingProcess.stepPreTreatment.preTreatmentCompleted
      ) {
        addPendingStep('Pre-Treatment');
      }

      if (
        !nursingProcess.stepPreparation ||
        !nursingProcess.stepPreparation.preparationComplete
      ) {
        addPendingStep('Preparation');
      }

      if (
        !nursingProcess.stepAdministration ||
        !nursingProcess.stepAdministration.administrationComplete
      ) {
        addPendingStep('Administration');
      }
    }

    if (
      nursingProcess &&
      nursingProcess.stepCloseTreatment &&
      nursingProcess.stepCloseTreatment.departureVital
    ) {
      try {
        const a = [];
        // let i = [];
        nursingProcess.stepCloseTreatment.departureVital.map(item => {
          const apiVital = {
            recordNumber: item.recordNumber,
            enteredAt: moment(new Date(item.enteredAt)),
            temperature: item.temperature,
            bloodPressure: item.bloodPressure,
            heartRate: item.heartRate,
            R: item.R,
            SP02: item.SP02,
            initials: item.initials
          };
          a.push(apiVital);
          // let itemVital = {
          //   temp: item.temperature,
          //   bp: item.bloodPressure,
          //   hr: item.heartRate,
          //   r: item.R,
          //   spo2: item.SP02,
          //   initials: item.initials,
          //   time: moment(new Date(item.enteredAt)).format("hh:mm A")
          // };
          // i.push(itemVital);
        });
        setApiVitals([...a]);
        // setItemVitals([...i]);
      } catch (err) {
        console.log('marty nursingProcess vitals err', err);
        setDialogOption({
          title: 'Infusion: CloseTreatment',
          message: 'Error: nursingProcess vitals',
          showDialog: true
        });
      }
    }
  };

  const infusionForm = {
    departureTime: {
      value: null,
      inputValidator: value => {
        return validateInput({
          departureTime: { ...infusionForm.departureTime, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    },
    isNarrativeNoteComplete: {
      value: hasNarrativeNoteComplete,
      inputValidator: value => {
        return validateInput({
          isNarrativeNoteComplete: {
            ...infusionForm.isNarrativeNoteComplete,
            value
          }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    }
  };

  const toggleCloseTreatment = () => {
    if (!departureTime) {
      alert('Please set the Depatrure Time in order to close the treatment');
    } else if (!hasNarrativeNoteComplete) {
      alert('Please mark Narrative Note completed in order to close the treatment');
    } else {
      setShowCloseTreatmentDialog(!showCloseTreatmentDialog);
    }
  };

  const updatStepHandler = dataItem => {
    const requestObject = {
      // STEP 6
      // input UpdateStepCloseTreatmentInput {
      // updateStepCloseTreatmentInput: {
      // nursingProcessId: ID!
      nursingProcessId:
        nursingProcessId ||
        infusion.updateStepOrderReviewInput.nursingProcessId ||
        '',
      // agentId: ID!
      agentId: infusion.stepCheckInInput.agentId, // agent.agentId, //user.username,
      // @NOTE: removed as part of updating step, now the notes live alone
      // notes: [String]
      // notes: restoreNotes(),
      // departureVital: VitalRecordInput
      departureVital: apiVitals,
      // departureTime: AWSDateTime
      // departureTime: formatDateToAWSDateTime(dataItem.departureTime),
      departureTime: formatDateToAWSDateTime(getSameHoursAndMinsForTimezone(
          dataItem.departureTime,
          locationData.timezone
        )),
      // signature: String
      // AIC-3084: need to display in TH the person who actually closed the treatment, not started it
      signature: agent.agentId,
      // password: String
      // password: dataItem.password
    };

    props.sendDataToParent(requestObject);

    toggleCloseTreatment();
  };

  const handleSubmit = dataItem => {
    const cbSuccess = () => {
      return updatStepHandler(dataItem);
    };

    const cbFailure = errMessage => showError(errMessage);

    authUser(dataItem.signature, dataItem.password, cbSuccess, cbFailure);
  };

  const handleRevisitSubmit = () => {
    const requestObject = {
      nursingProcessId: nursingProcess.id,
      agentId: nursingProcess.startedBy,
      // notes: narrativeNoteRevisit,
      departureVital: apiVitals,
      departureTime: departureTime
        ? formatDateToAWSDateTime(getSameHoursAndMinsForTimezone(
              departureTime.value,
              locationData.timezone
            ))
        : nursingProcess.stepCloseTreatment.departureTime,
      closeTreatmentNote: nursingProcess.stepCloseTreatment.closeTreatmentNote,
      signature: nursingProcess.stepCloseTreatment.signature,
      password: nursingProcess.stepCloseTreatment.password
    };

    props.sendDataToParent(requestObject);
  };

  return (
    <div className="infusion-page">
      {dialogOption && dialogOption.showDialog && (
        <MessageDialog dialogOption={dialogOption} />
      )}
      {stepAllowed && (
        <>
          {props.infusionCompleted ? (
            <RevisitCloseTreatment
              updateStepHandler={handleRevisitSubmit}
              updateDepartureTimeHandler={setDepartureTime}
              originalTime={originalDepartureTime}
            />
          ) : (
            <Form
              onSubmit={handleSubmit}
              render={formRenderProps => (
                <form
                  onSubmit={formRenderProps.onSubmit}
                  className="k-form pl-3 pr-3 pt-1"
                >
                  <div className="row mt-3">
                    <div className="col-md-2">DEPARTURE TIME:</div>
                    <div className="col-md-2">
                      <Field
                        component={TimePicker}
                        name="departureTime"
                        onChange={onDepartureTimeUpdate}
                        validator={infusionForm.departureTime.inputValidator}
                        format="h:mm a"
                      />
                      {!departureTime && departureTimeTouched && (
                        <Error>{Constants.ErrorMessage.is_REQUIRED}</Error>
                      )}
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-2">Narrative Note Complete</div>
                    <div className="col-md-2">
                      <Field
                        component={Switch}
                        onLabel="Yes"
                        offLabel="No"
                        name="isNarrativeNoteComplete"
                        onChange={toggleNarrativeCompletion}
                        defaultChecked={hasNarrativeNoteComplete}
                        validator={
                          infusionForm.isNarrativeNoteComplete.inputValidator
                        }
                      />
                      {!hasNarrativeNoteComplete && narrativeNoteTouched && (
                        <Error>{Constants.ErrorMessage.is_REQUIRED}</Error>
                      )}
                    </div>
                  </div>

                  <div className="row mt-5 mb-5">
                    <div className="col-2">
                      <button
                        type="button"
                        onClick={toggleCloseTreatment}
                        className="k-button pageButton"
                        disabled={!allStepsCompleted}
                      >
                        Close Treatment
                      </button>
                    </div>
                  </div>
                  {!allStepsCompleted && (
                    <div className="row">
                      <div className="col-md-12">
                        Please make sure all steps in the infusion are
                        &quot;Complete&quot; before attempting to close
                        treatment.
                        <br />
                        <br />
                        Steps not completed:
                        <br />
                        <ul>
                          {pendingSteps.map((step, i) => (
                            <li key={i}>{step}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  )}
                  <WindowDialog
                    title="Close Treatment"
                    initialTop={0}
                    width={550}
                    height={450}
                    showDialog={showCloseTreatmentDialog}
                    onClose={toggleCloseTreatment}
                  >
                    <>
                      <div className="row py-1">
                        <div className="col-md-3 mt-16 ml-3">USER NAME:</div>
                        <div className="col-md-5">
                          <Field
                            component={Input}
                            name="signature"
                            label="User Name"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-3 mt-16 ml-3">PASSWORD:</div>
                        <div className="col-md-5">
                          <Field
                            component={Input}
                            name="password"
                            label="Password"
                            type="password"
                          />
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col">
                          <Alert>
                            Reminder: Please add Close Treatment Notes
                          </Alert>
                        </div>
                      </div>
                      <div className="row">
                        <div
                          className="col-md-12 mt-1 pl-0 py-3 mr-0"
                          style={{ textAlign: 'center' }}
                        >
                          <button
                            type="submit"
                            className="k-button pageButton"
                          >
                            Close Treatment
                          </button>
                        </div>
                      </div>
                    </>
                  </WindowDialog>
                </form>
              )}
            />
          )}
        </>
      )}
    </div>
  );
};

export default CloseTreatment;
