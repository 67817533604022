import React, { useState } from 'react';
import { Storage } from 'aws-amplify';
// kendo
import { Button } from '@progress/kendo-react-buttons';
import { Dialog } from '@progress/kendo-react-dialogs';
// components
import PatientDocument from '@/components/Patient/PatientDocument';
// gql
import { connectToGraphqlAPI } from '@/provider';
import { getReferral } from '@/graphql/queries';

const PreviewDocument = ({ patientId, referralId }) => {
  const [selectedDocumentUrl, setSelectedDocumentUrl] = useState('');
  const [showPatientDocDialog, setShowPatientDocDialog] = useState(false);

  const getReferralDocumentPath = async () => {
    try {
      const referralData = await connectToGraphqlAPI({
        graphqlQuery: getReferral,
        variables: {
          patientId,
          referralId
        }
      });
      if (referralData?.data?.getReferral.referralDetail) {
        return referralData?.data?.getReferral?.referralDetail?.rxOrderFile;
      }
    } catch {
      return null;
    }
  };

  const onPreviewDocument = async () => {
    const documentPath = await getReferralDocumentPath();
    const s3ImageURL = await Storage.get(documentPath, { download: false });
    setSelectedDocumentUrl(s3ImageURL);
    setShowPatientDocDialog(true);
  };
  return (
    <>
      <Button
        type='button'
        className='k-button pageButton'
        onClick={() => onPreviewDocument()}
      >
        RX Order File
      </Button>

      {showPatientDocDialog && (
        <Dialog
          appendTo={document.body}
          title='Patient Document'
          width={800}
          height={955}
          initialTop={0}
          initialLeft={300}
          onClose={() => setShowPatientDocDialog(false)}
        >
          <PatientDocument file={selectedDocumentUrl} />
        </Dialog>
      )}
    </>
  );
};

export default PreviewDocument;
