import React, { useState } from "react";

import { TimePicker } from "@progress/kendo-react-dateinputs";
import { Form, Field } from "@progress/kendo-react-form";

const RevisitCloseTreatment = ({
  originalTime,
  updateStepHandler,
  updateDepartureTimeHandler
}) => {
  const [dateTouched, setDateTouched] = useState(false);

  const onDateChange = e => {
    setDateTouched(true);
    updateDepartureTimeHandler(e);
  };

  return (
    <div className="infusion-page">
      <Form
        render={() => (
          <form className={"k-form pl-3 pr-3 pt-1"}>
            <div className="row mt-3">
              <div className="col-md-2">ORIGINAL DEPARTURE TIME:</div>
              <div className="col-md-2">{originalTime}</div>
            </div>
            <div className="row mt-3">
              <div className="col-md-2">DEPARTURE TIME:</div>
              <div className="col-md-2">
                <Field
                  component={TimePicker}
                  name="departureTime"
                  onChange={onDateChange}
                  format="h:mm a"
                />
              </div>
            </div>
            <div className="row mt-5 mb-5">
              <div className="col-2">
                <button
                  type="button"
                  onClick={updateStepHandler}
                  className="k-button pageButton"
                  disabled={!dateTouched}
                >
                  Update Treatment
                </button>
              </div>
            </div>
          </form>
        )}
      />
    </div>
  );
};

export default RevisitCloseTreatment;
