import React, { useContext } from 'react';

// context
import { InfusionContext } from '@/context/InfusionContext';

const PatientSurveyCheckButton = ({ label }) => {
  const { infusion, loadInfusion } = useContext(InfusionContext);

  return (
    <>
      {label}
      <button
        className="k-button k-primary ml-3"
        type="button"
        onClick={() => loadInfusion(infusion?.nursingProcess?.id)}
      >
        Check Status
      </button>
    </>
  );
};

export default PatientSurveyCheckButton;
