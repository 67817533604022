import React from 'react';
import AddProductForm from './AddProductForm';

// kendo
import { Dialog } from '@progress/kendo-react-dialogs';

const AddProductToInventory = (props) => {
  if (!props.show) return null;

  return (
    <Dialog
      title={`Add Product — ${props.location}`}
      width={600}
      height='auto'
      initialTop={50}
      showDialog
      onClose={props.onToggle}
    >
      <AddProductForm />
    </Dialog>
  );
};

export default AddProductToInventory;
