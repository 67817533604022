import React, { createContext, useContext, useEffect, useState } from 'react';
// context
import { PatientContext } from './PatientContext';
// utils
import { getDrugNamesAndReferralIds } from '@/components/Referral/ReferralHelpers';

export const ReferralContext = createContext();

const ReferralContextProvider = ({ children }) => {
  const { selectedPatientInfo } = useContext(PatientContext);
  const [referralOrderUrl, setReferralOrderUrl] = useState('');
  const [referralOrderDate, setReferralOrderDate] = useState('');
  const [originalOrderDate, setOriginalOrderDate] = useState('');
  const [archivedReason, setArchivedReason] = useState('');
  const [itemAdministrations, setItemAdministrations] = useState([]);
  const [adminOverEverySelection, setAdminOverEverySelection] = useState('');
  const [referralHeaderNotes, setReferralHeaderNotes] = useState('');
  const [orderFormData, setOrderFormData] = useState({ productName: 'TEST' });
  const [patientReferralDrugNames, setPatientReferralDrugNames] = useState([]);
  const [patientReferralIds, setPatientReferralIds] = useState([]);
  const [combinedReferralList, setCombinedReferralList] = useState([]);
  useEffect(() => {
    if (selectedPatientInfo?.referral?.drugReferrals) {
      const { drugNames, referralIds, combinedReferralList } =
        getDrugNamesAndReferralIds(selectedPatientInfo?.referral?.drugReferrals);
      setPatientReferralDrugNames(drugNames);
      setPatientReferralIds(referralIds);
      setCombinedReferralList(combinedReferralList);
    }
  }, [selectedPatientInfo?.referral?.drugReferrals]);

  return (
    <ReferralContext.Provider
      value={{
        setPatientReferralIds,
        patientReferralIds,
        setPatientReferralDrugNames,
        patientReferralDrugNames,
        setCombinedReferralList,
        combinedReferralList,
        referralOrderUrl,
        setReferralOrderUrl,
        referralOrderDate,
        setReferralOrderDate,
        originalOrderDate,
        setOriginalOrderDate,
        archivedReason,
        setArchivedReason,
        itemAdministrations,
        setItemAdministrations,
        adminOverEverySelection,
        setAdminOverEverySelection,
        referralHeaderNotes,
        setReferralHeaderNotes,
        orderFormData,
        setOrderFormData
      }}
    >
      {children}
    </ReferralContext.Provider>
  );
};

export default ReferralContextProvider;
