import React from 'react';

const badgeTypes = {
  alert: ['red', 'white'],
  warning: ['orange', 'black'],
  success: ['green', 'white'],
  info: ['dodgerblue', 'white'],
  snooze: ['purple', 'white'],
  default: ['#ccc', 'black']
};

const Badge = ({ type = 'alert', text = '', customCss = {} }) => {
  if (!text) {
    console.error('Badge component must have `text` as a property.');
    return null;
  }

  if (!Object.prototype.hasOwnProperty.call(badgeTypes, type)) {
    console.error('Badge component must have valid `type` as a property.');
    return null;
  }

  const [bg, color] = badgeTypes[type];
  const badgeStyle = { ...customCss, backgroundColor: bg, color };

  return (
    <span style={badgeStyle} className="aic-badge">
      {text}
    </span>
  );
};

export default Badge;
