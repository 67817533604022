import React, { createContext, useState } from "react";

export const FollowUpContext = createContext();

const FollowUpContextProvider = ({ children }) => {
  const [followUpEvent, setFollowUpEvent] = useState("");
  const [followUpSearch, setFollowUpSearch] = useState("");
  const [followUpReload, setFollowUpReload] = useState("");

  return (
    <FollowUpContext.Provider
      value={{
        setFollowUpEvent,
        followUpEvent,
        setFollowUpSearch,
        followUpSearch,
        followUpReload,
        setFollowUpReload,
      }}
    >
      {children}
    </FollowUpContext.Provider>
  );
};

export default FollowUpContextProvider;
