import React, { useState, useEffect, useContext } from 'react';
import pluralize from 'pluralize';
import cx from 'clsx';

// kendo
import {
  Grid,
  GridColumn as Column,
  GridToolbar
} from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';

// components
import { ColumnMenu } from '@/components/common-components/columnMenu';
import Preloader from '@/components/common-components/Preloader';
import WorkStatusBadge from '@/components/common-components/WorkStatusBadge';
import CallHistoryLog from '@/components/Header/components/ActiveWorkItems/components/CallHistoryLog';
import PatientWorkLogHistory from './PatientWorkLogHistory';

// context
import { PatientContext } from '@/context';

// utils
import { getWorkItemTitle } from '../Queue/FilterSystem/QueueFilters.utils';
import { callWorkTypes, taskStatus, workItemTypes } from '@/constants/enum';
import { compareDates } from '@/common/SortHelper';

// styles
import styles from './PatientWorkLog.module.scss';

const initialDataState = {};
const initialResultState = { data: [], total: 0 };

const PatientWorkLog = () => {
  // data grid local state
  const [isLoading, setIsLoading] = useState(true);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [dataState, setDataState] = useState(initialDataState);
  const [resultState, setResultState] = useState(initialResultState);
  const [patientWorkItems, setPatientWorkItems] = useState([]);

  // context
  const { getPatientWorkItemsCall, selectedPatientInfo } =
    useContext(PatientContext);

  useEffect(() => {
    let isLoading = true;

    async function fetchData() {
      setIsLoading(true);
      const data = await getPatientWorkItemsCall(
        selectedPatientInfo?.patientId
      );

      if (isLoading) {
        setIsLoading(false);
        if (data) {
          const filtered = data
            .filter((logRecord) =>
              [
                taskStatus.COMPLETED,
                taskStatus.ARCHIVED,
                taskStatus.CANCELED
              ].includes(logRecord.workStatus)
            )
            .sort((a, b) => compareDates(a, b, 'updatedAt')); // show most recent items on the top

          setPatientWorkItems(filtered);
        }
      }
    }

    fetchData();

    return () => {
      isLoading = false;
    };
  }, [
    getPatientWorkItemsCall,
    selectedPatientInfo.patientId,
    setPatientWorkItems
  ]);

  useEffect(() => {
    setResultState(process(patientWorkItems, dataState));
    if (patientWorkItems?.length > 0) {
      setIsDataLoaded(true);
    }
  }, [dataState, patientWorkItems]);

  const workCell = ({ dataItem }) => {
    const title = getWorkItemTitle(dataItem?.work);

    if (dataItem.work === workItemTypes.FOLLOW_UP && !!dataItem.attachedData) {
      const jsonData = JSON.parse(dataItem.attachedData);
      if (jsonData.followUpType) {
        return (
          <td>
            {title}
            <br />
            <small>
              <b>Follow-up type:</b> {jsonData.followUpType}
            </small>
          </td>
        );
      }
    }

    if (
      dataItem.work === workItemTypes.OUTBOUND_CALL &&
      !!dataItem.attachedData
    ) {
      const jsonData = JSON.parse(dataItem.attachedData);

      if (jsonData.followUpType || jsonData.category) {
        const hasType = !!jsonData.followUpType;
        const hasCategory = !!jsonData.category;

        return (
          <td>
            {title}
            <br />
            {hasType && (
              <small>
                <b>Follow-up type:</b> {jsonData.followUpType}
              </small>
            )}
            {hasCategory && (
              <small>
                <b>Category:</b> {jsonData.category}
              </small>
            )}
          </td>
        );
      }
    }

    return <td>{title}</td>;
  };

  const workStatusCell = ({ dataItem }) => {
    return (
      <td>
        <WorkStatusBadge workStatus={dataItem.workStatus} />
      </td>
    );
  };

  const historyCell = ({ dataItem }) => {
    return (
      <td>
        <PatientWorkLogHistory id={dataItem.id} />
        {dataItem.parentWorkItemId && (
          <div className={styles.nextLink}>
            <PatientWorkLogHistory
              id={dataItem.parentWorkItemId}
              title='Show Parent History'
            />
          </div>
        )}
        {callWorkTypes.includes(dataItem.work) && (
          <div className={styles.nextLink}>
            <CallHistoryLog id={dataItem.id} />
          </div>
        )}
      </td>
    );
  };

  const getColumns = () => {
    const cols = [
      { field: 'work', title: 'Work Type', cell: workCell },
      { field: 'workStatus', title: 'Status', cell: workStatusCell },
      { field: 'description', title: 'Description' },
      { title: 'History', cell: historyCell }
    ];
    return cols;
  };

  const columns = getColumns();
  const render = {
    pendingItemsCounter: () => {
      const pluralizedItems = pluralize('work item', resultState.total, true);
      return resultState.data.length > 0 ? `(${pluralizedItems})` : '';
    }
  };

  return (
    <>
      {isDataLoaded && (
        <Preloader show={isLoading}>
          <Grid
            className={cx(styles.dataGrid, 'queue_grid')}
            data={{ data: resultState.data }}
            onDataStateChange={(e) => setDataState(e.dataState)}
            sortable
            {...dataState}
            total={1} // total set to stop paging error in the browser. Remove if you want to use paging.
          >
            <GridToolbar>
              <div className='a-grid__header'>
                <div>Work Items Log {render.pendingItemsCounter()}</div>
              </div>
            </GridToolbar>
            {columns.map(({ minWidth, ...rest }, idx) => (
              <Column
                key={idx}
                width={minWidth}
                columnMenu={ColumnMenu}
                {...rest}
              />
            ))}
          </Grid>
        </Preloader>
      )}
    </>
  );
};

export default PatientWorkLog;
