import React from "react";
import useTimer from "easytimer-react-hook";

const renderResult = resVal => <span>{resVal}</span>;

const getStartValues = (startTime, finishTime) => {
  const chairStart = new Date(startTime);
  const chairFinish = finishTime ? new Date(finishTime) : new Date();

  // calc time spent since infusion started (UTC)
  const diffMs = chairFinish.getTime() - chairStart.getTime();
  const diffDays = Math.floor(diffMs / 86400000); // days
  const diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
  const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
  const diffSec = Math.round((((diffMs % 86400000) % 3600000) % 60000) / 1000); // seconds

  return {
    seconds: diffSec,
    minutes: diffMins,
    hours: diffHrs,
    days: diffDays
  };
};

const TimeInChair = ({ startTime, finishTime }) => {
  const startValues = getStartValues(startTime, finishTime);
  const [timer] = useTimer({
    startValues
  });

  // if Infusion completed
  if (finishTime) {
    return renderResult(
      `${startValues.hours}:${startValues.minutes}:${startValues.seconds}`
    );
  }

  timer.start({});

  return renderResult(timer.getTimeValues().toString());
};

export default TimeInChair;
