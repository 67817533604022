import { connectToGraphqlAPI } from '@/provider';
import {
    getAdminStageForScheduleEvent,
} from '@/graphql/queries';

export const getAdminSequenceNumber = async (patientId, referralId, appointmentDate, eventToUpdate) => {
    try {
        const data = await connectToGraphqlAPI({
            graphqlQuery: getAdminStageForScheduleEvent,
            variables: {
                patientId,
                referralId,
                appointmentDate,
                eventToUpdate
            }
        });

        if (data?.data?.getAdminStageForScheduleEvent) {
           return data.data.getAdminStageForScheduleEvent;
        }
    } catch (err) {
        console.error('schedulingQueries::getAdminStageForScheduleEvent data err: ', err);
        return null;
    }
};
