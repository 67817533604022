import React, { useState } from 'react';

// components
import AwesomeLabel from '@/components/common-components/AwesomeLabel';
import ArchiveItemReason from './ArchiveItemReason';
// Styles
import styles from './ItemActions.module.scss';

const ArchiveItem = () => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <>
      <button
        className={styles.minButtonWidth}
        onClick={() => setIsVisible(true)}
      >
        <AwesomeLabel icon="box-archive" label="Archive Work Item" />
      </button>
      <ArchiveItemReason
        isVisible={isVisible}
        onClose={() => setIsVisible(false)}
      />
    </>
  );
};

export default ArchiveItem;
