import React from 'react';

const DeleteButton = ({ handleClick, ...props }) => {
  return (
    <button
      type="button"
      className="k-button"
      onClick={handleClick}
      {...props}
    >
      <i className="fa-solid fa-trash icon-blue" />
    </button>
  );
};

export default DeleteButton;
