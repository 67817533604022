import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import * as moment from 'moment';

// kendo
import { Input } from '@progress/kendo-react-inputs';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Form, Field } from '@progress/kendo-react-form';

// components
import { MessageDialog } from '@/components/common-components/MessageDialog';
import FormButton from '@/components/common-components/Form/FormButton';

// utils
import { MaskedPhoneInput } from '@/common/MaskInput';
import { validateInput } from '@/common/Validation';
import { convertToE164 } from '@/common/PhoneNumberConverter';
import { Constants } from '@/constants';
import { activeUsersFilter } from '@/constants/filters';

// gql
import { connectToGraphqlAPI } from '@/provider';
import {
  getPatientBucket,
  getPatientBucketByLastNameInLowerCase,
  getPatientBucketByDob
} from '@/graphql/queries';
import { createNewPatientBucket, acquireLocker } from '@/graphql/mutations';

// context
import {
  UserContext,
  PatientContext,
  NotifContext,
  FollowUpContext,
  UserRoleTypes
} from '@/context';

const PatientCalendarSearch = (props) => {
  const { user, canEdit } = useContext(UserContext);
  const { setSelectedPatientInfo, setSelectedLocker } =
    useContext(PatientContext);

  const history = useHistory();

  const searchLayout = props.searchLayout ? props.searchLayout : 1;
  // const searchEndPoint = props.searchEndPoint ? props.searchEndPoint : "";
  const existingOnly = !!props.existingOnly;

  const [searchType, setSearchType] = useState('Patient');
  const [errorMessage, setErrorMessage] = useState('');
  const [visibleDialog, setVisibleDialog] = useState(false);
  const [patientBucket, setPatientBucket] = useState([]);
  const [dialogOption, setDialogOption] = useState({});
  const [isLoading, setLoading] = useState(false);
  const { showWarning } = useContext(NotifContext);
  const { followUpSearch, setFollowUpSearch } = useContext(FollowUpContext);

  const mainFormExistingPatient = {
    searchPatientId: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          searchPatientId: { ...mainFormExistingPatient.searchPatientId, value }
        });
      },
      validations: [
        // {
        // 	type: "required",
        // 	message: Constants.ErrorMessage.prescFirstName_REQUIRED,
        // },
        {
          type: 'onlyNumeric',
          message: Constants.ErrorMessage.Numeric_Required
        }
        // {
        //     type: "maxLength",
        //     length: 10,
        //     message: Constants.ErrorMessage.Alpha_Required,
        // },
      ]
    },
    searchPatientFirstName: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          searchPatientFirstName: {
            ...mainFormExistingPatient.searchPatientFirstName,
            value
          }
        });
      },
      validations: [
        // {
        // 	type: "required",
        // 	message: Constants.ErrorMessage.prescFirstName_REQUIRED,
        // },
        {
          type: 'alpha',
          message: Constants.ErrorMessage.alpha_Required
        }
        // {
        //     type: "maxLength",
        //     length: 10,
        //     message: Constants.ErrorMessage.Alpha_Required,
        // },
      ]
    },
    searchPatientLastName: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          searchPatientLastName: {
            ...mainFormExistingPatient.searchPatientLastName,
            value
          }
        });
      },
      validations: [
        // {
        // 	type: "required",
        // 	message: Constants.ErrorMessage.prescFirstName_REQUIRED,
        // },
        {
          type: 'alpha',
          message: Constants.ErrorMessage.alpha_Required
        }
        // {
        //     type: "maxLength",
        //     length: 10,
        //     message: Constants.ErrorMessage.Alpha_Required,
        // },
      ]
    },
    searchDateOfBirth: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          searchDateOfBirth: {
            ...mainFormExistingPatient.searchDateOfBirth,
            value
          }
        });
      },
      validations: [
        // {
        // 	type: "required",
        // 	message: Constants.ErrorMessage.prescFirstName_REQUIRED,
        // },
        // {
        // 	type: "alpha",
        // 	message: Constants.ErrorMessage.alpha_Required,
        // },
        // {
        //     type: "maxLength",
        //     length: 10,
        //     message: Constants.ErrorMessage.Alpha_Required,
        // },
      ]
    }
  };

  const mainFormNewPatient = {
    patientFirstName: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          patientFirstName: { ...mainFormNewPatient.patientFirstName, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        },
        {
          type: 'alpha',
          message: Constants.ErrorMessage.Alpha_Required
        }
        // {
        //     type: "maxLength",
        //     length: 10,
        //     message: Constants.ErrorMessage.Alpha_Required,
        // },
      ]
    },
    patientLastName: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          patientLastName: { ...mainFormNewPatient.patientLastName, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.lastname_REQUIRED
        },
        {
          type: 'alpha',
          message: Constants.ErrorMessage.Alpha_Required
        }
        // {
        //     type: "maxLength",
        //     length: 10,
        //     message: Constants.ErrorMessage.Alpha_Required,
        // },
      ]
    },
    dateOfBirth: {
      value: null, // new Date(),
      inputValidator: (value) => {
        return validateInput({
          dateOfBirth: { ...mainFormNewPatient.dateOfBirth, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.dateOfBirth_REQUIRED
        }
        // {
        // 	type: "alpha",
        // 	message: Constants.ErrorMessage.Alpha_Required,
        // },
        // {
        //     type: "maxLength",
        //     length: 10,
        //     message: Constants.ErrorMessage.Alpha_Required,
        // },
      ]
    },
    phoneNumber: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          phoneNumber: { ...mainFormNewPatient.phoneNumber, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.PhoneNumber_REQUIRED
        },
        {
          type: 'phonePattern',
          message: Constants.ErrorMessage.PhoneNumber_REQUIRED
        }
        // {
        //     type: "maxLength",
        //     length: 10,
        //     message: Constants.ErrorMessage.Alpha_Required,
        // },
      ]
    }
  };

  const handleSubmitExistingPatientSearch = (dataItem) => {
    setErrorMessage('');

    if (
      !dataItem.searchPatientId &&
      !dataItem.searchPatientLastName &&
      !dataItem.searchDateOfBirth
    ) {
      setErrorMessage('Please enter a valid patient name or ID');
    } else if (searchType === 'Patient' && dataItem) {
      if (dataItem.searchPatientId) {
        getPatientBucketByIdCall(dataItem.searchPatientId);
      } else if (dataItem.searchPatientLastName) {
        getPatientBucketByLastNameInLowerCaseCall(
          dataItem.searchPatientLastName
        );
      } else if (dataItem.searchPatientFirstName) {
        // getPatientBucketByFirstNameCall(dataItem.searchPatientFirstName)
      } else if (dataItem.searchDateOfBirth) {
        getPatientBucketByDobCall(
          moment(dataItem.searchDateOfBirth).format(Constants.DATE.DEFAULT_AWS)
        );
      }
    }
  };

  const handleSubmitNewPatient = (dataItem) => {
    const requestObject = {
      agentId: user.username,
      patientFirstName: dataItem.patientFirstName,
      patientLastName: dataItem.patientLastName,
      dob: moment(dataItem.dateOfBirth).format('YYYY-MM-DD'),
      homePhoneNumber: convertToE164(dataItem.phoneNumber)
    };
    createNewPatientBucketCall(requestObject);
    // setSelectedPatientInfo(null)
    // history.push("/patient-portal", { searchType })
  };

  const createNewPatientBucketCall = async (requestObject) => {
    try {
      setLoading(true);
      const data = await connectToGraphqlAPI({
        graphqlQuery: createNewPatientBucket,
        variables: { input: requestObject }
      });

      if (data && data.data && data.data.createNewPatientBucket) {
        setSelectedPatientInfo({
          ...data.data.createNewPatientBucket,
          homePhoneNumber: convertToE164(
            data.data.createNewPatientBucket?.homePhoneNumber
          )
        });
        acquireLockerApiCall({
          agentId: user.username,
          patientId: data.data.createNewPatientBucket.patientId
        });
        history.push('/patient-portal', { searchType });
      }
      setLoading(false);
    } catch (err) {
      console.log('marty createNewPatientBucketCall err', err);
      // alert("createNewPatientBucketCall error")
      setDialogOption({
        title: 'Work Queue: New Patient',
        message: 'Error: createNewPatientBucketCall', // err.errors[0].message, //'Error',
        showDialog: true
      });
      setLoading(false);
    }
  };

  const getPatientBucketByIdCall = async (patientId) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getPatientBucket,
        variables: { patientId }
      });

      if (data && data.data && data.data.getPatientBucket) {
        setPatientBucket([data.data.getPatientBucket]);
        togglePatientSearchDialog();
      } else {
        setDialogOption({
          title: 'Search Patient',
          message: 'No Patient Record Found',
          showDialog: true
        });
      }
    } catch (err) {
      console.log('marty getPatientBucketByIdCall err', err);
      setDialogOption({
        title: 'Search Patient',
        message: 'Error: getPatientBucketByIdCall', // err.errors[0].message,
        showDialog: true
      });
    }
  };

  const getPatientBucketByLastNameInLowerCaseCall = async (patientLastName) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getPatientBucketByLastNameInLowerCase,
        variables: {
          sPatientLastName: patientLastName.toLowerCase(),
          filter: activeUsersFilter
        }
      });
      if (data.data.getPatientBucketByLastNameInLowerCase.items.length === 0) {
        showWarning('No Patient Found By That Name');
      }
      if (
        data &&
        data.data &&
        data.data.getPatientBucketByLastNameInLowerCase &&
        data.data.getPatientBucketByLastNameInLowerCase.items &&
        data.data.getPatientBucketByLastNameInLowerCase.items.length > 0
      ) {
        let list = data.data.getPatientBucketByLastNameInLowerCase.items;
        let sNextToken = data.data.getPatientBucketByLastNameInLowerCase
          .nextToken
          ? data.data.getPatientBucketByLastNameInLowerCase.nextToken
          : null;
        while (sNextToken != null) {
          try {
            const data = await connectToGraphqlAPI({
              graphqlQuery: getPatientBucketByLastNameInLowerCase,
              variables: {
                nextToken: sNextToken,
                sPatientLastName: patientLastName.toLowerCase(),
                filter: activeUsersFilter
              }
            });

            if (
              data &&
              data.data &&
              data.data.getPatientBucketByLastNameInLowerCase &&
              data.data.getPatientBucketByLastNameInLowerCase.items
            ) {
              sNextToken = data.data.getPatientBucketByLastNameInLowerCase
                .nextToken
                ? data.data.getPatientBucketByLastNameInLowerCase.nextToken
                : null;
              list = list.concat(
                data.data.getPatientBucketByLastNameInLowerCase.items
              );
            }
          } catch (err) {
            console.log('NewOrder::listProductsCall err:', err);
            sNextToken = null; // set to stop iterating
          }
        }

        // sort by first name
        list = list.sort((a, b) =>
          a.patientFirstName > b.patientFirstName ? 1 : -1
        );

        setPatientBucket(list);
        togglePatientSearchDialog();
      }
    } catch (err) {
      console.log('marty getPatientBucketByLastNameInLowerCase err', err);
      setDialogOption({
        title: 'Search Patient',
        message: err.errors[0].message,
        showDialog: true
      });
    }
  };

  const getPatientBucketByDobCall = async (searchDateOfBirth) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getPatientBucketByDob,
        variables: { dob: searchDateOfBirth }
      });

      if (
        data &&
        data.data &&
        data.data.getPatientBucketByDob &&
        data.data.getPatientBucketByDob.items &&
        data.data.getPatientBucketByDob.items.length > 0
      ) {
        setPatientBucket(data.data.getPatientBucketByDob.items);
        togglePatientSearchDialog();
      } else {
        setDialogOption({
          title: 'Search Patient',
          message: 'No Patient Record Found',
          showDialog: true
        });
      }
    } catch (err) {
      console.log('marty getPatientBucketByDobCall err', err);
      setDialogOption({
        title: 'Search Patient',
        message: err.errors[0].message,
        showDialog: true
      });
    }
  };

  const togglePatientSearchDialog = () => {
    setVisibleDialog(!visibleDialog);
  };

  const acquireLockerApiCall = async (requestObject) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: acquireLocker,
        variables: { input: requestObject }
      });

      if (data && data.data && data.data.acquireLocker) {
        if (
          data.data.acquireLocker.success &&
          data.data.acquireLocker.lockerId
        ) {
          setSelectedLocker(data.data.acquireLocker.lockerId);
        } else if (data.data.acquireLocker.details) {
          setDialogOption({
            title: 'Acquire Work: Error 1',
            message: data.data.acquireLocker.details,
            showDialog: true
          });
        } else {
          setDialogOption({
            title: 'Acquire Work: Error 2',
            message: 'Error acquireLockerApiCall with no {details} available',
            showDialog: true
          });
        }
      } else if (data.data.acquireLocker.details) {
        setDialogOption({
          title: 'Acquire Work: Error 3',
          message: data.data.acquireLocker.details,
          showDialog: true
        });
      }
    } catch (err) {
      console.log('marty acquireWorkApiCall err', err);
      setDialogOption({
        title: 'Work In Progress',
        message: 'Fatal Error',
        showDialog: true
      });
    }
  };

  const onRowClickHandle = (e) => {
    if (e.dataItem.patientId) {
      // only lock patient if user role(s) allows to edit
      if (canEdit(UserRoleTypes.PatientInfo)) {
        acquireLockerApiCall({
          agentId: user.username,
          patientId: e.dataItem.patientId
        });
      }

      const storeData = { ...e.dataItem };
      if (e.dataItem.patientProfile?.clinicInfo?.clinicalNotes?.length > 0) {
        storeData.clinicalNotes =
          e.dataItem.patientProfile?.clinicInfo?.clinicalNotes;
      }
      setSelectedPatientInfo(storeData);
      setFollowUpSearch(followUpSearch);
      /* if (searchEndPoint) {
         history.push(searchEndPoint, { searchType });

      } */
      togglePatientSearchDialog();
    }
  };

  return (
    <>
      {dialogOption && dialogOption.showDialog && (
        <MessageDialog dialogOption={dialogOption} />
      )}

      {searchLayout === 1 && (
        <div className='row patient-search'>
          <>
            <div className={!existingOnly ? 'col-md-5' : 'col-md-12'}>
              <Form
                // initialValues={mainFormExistingPatient}
                onSubmit={handleSubmitExistingPatientSearch}
                render={(formRenderProps) => (
                  <form
                    onSubmit={formRenderProps.onSubmit}
                    className='k-form pl-3 pr-3 py-3'
                  >
                    <div className='row'>
                      <div className='col-md-12'>
                        <h5>Existing Patient</h5>
                      </div>
                    </div>
                    <div className='row'>
                      {/* <div className="col-md-6">
											Patient First Name:
											<Field
												name={'searchPatientFirstName'}
												component={Input}
												validator={mainFormExistingPatient.searchPatientFirstName.inputValidator}
											/>
										</div> */}
                      <div className='col-md-12'>
                        Patient Last Name:
                        <Field
                          name='searchPatientLastName'
                          component={Input}
                          validator={
                            mainFormExistingPatient.searchPatientLastName
                              .inputValidator
                          }
                        />
                      </div>
                    </div>
                    <div className='row mt-04'>
                      <div className='col-md-12'>
                        Patient ID:
                        <Field
                          name='searchPatientId'
                          component={Input}
                          validator={
                            mainFormExistingPatient.searchPatientId
                              .inputValidator
                          }
                        />
                      </div>
                      <div className='col-md-12 mt-04'>
                        Date of Birth:
                        <Field
                          name='searchDateOfBirth'
                          component={DatePicker}
                          validator={
                            mainFormExistingPatient.searchDateOfBirth
                              .inputValidator
                          }
                        />
                      </div>
                    </div>
                    <div className='row mt-08'>
                      <div className='col-md-12'>
                        <button
                          type='submit'
                          look='outline'
                          className='k-button mr-5 blue'
                        >
                          Search Patients
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              />
            </div>

            {!existingOnly && (
              <>
                <div className='col-md-1 align-self-center pr-1'>
                  <h5>OR</h5>
                </div>
                <div className='col-md-6'>
                  <Form
                    // initialValues={mainFormNewPatient}
                    onSubmit={handleSubmitNewPatient}
                    render={(formRenderProps) => (
                      <form
                        onSubmit={formRenderProps.onSubmit}
                        className='k-form pl-3 pr-3 py-3'
                      >
                        <div className='row'>
                          <div className='col-md-12'>
                            <h5>New Patient</h5>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-md-12'>
                            <div className='row'>
                              <div className='col-md-6'>
                                Patient First Name:
                                <Field
                                  name='patientFirstName'
                                  component={Input}
                                  validator={
                                    mainFormNewPatient.patientFirstName
                                      .inputValidator
                                  }
                                />
                              </div>
                              <div className='col-md-6'>
                                Patient Last Name:
                                <Field
                                  name='patientLastName'
                                  component={Input}
                                  validator={
                                    mainFormNewPatient.patientLastName
                                      .inputValidator
                                  }
                                />
                              </div>
                            </div>
                            <div className='row mt-04'>
                              <div className='col-md-6'>
                                Date of Birth:
                                <Field
                                  name='dateOfBirth'
                                  component={DatePicker}
                                  validator={
                                    mainFormNewPatient.dateOfBirth
                                      .inputValidator
                                  }
                                />
                              </div>
                              <div className='col-md-6'>
                                Primary Phone:
                                <Field
                                  name='phoneNumber'
                                  component={MaskedPhoneInput}
                                  // component={Input}
                                  validator={
                                    mainFormNewPatient.phoneNumber
                                      .inputValidator
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='row mt-08'>
                          <div className='col-md-2'>
                            <FormButton
                              type='submit'
                              className='k-button blue'
                              loading={isLoading}
                              label={`Add New ${searchType}`}
                            />
                          </div>
                        </div>
                      </form>
                    )}
                  />
                </div>
              </>
            )}
          </>
        </div>
      )}

      {searchLayout == 2 && (
        <Form
          // initialValues={mainFormExistingPatient}
          onSubmit={handleSubmitExistingPatientSearch}
          render={(formRenderProps) => (
            <form onSubmit={formRenderProps.onSubmit} className='k-form'>
              <div className='row'>
                <div className='col-md-5 mt-06'>
                  Patient Last Name:
                  <Field
                    name='searchPatientLastName'
                    component={Input}
                    validator={
                      mainFormExistingPatient.searchPatientLastName
                        .inputValidator
                    }
                  />
                </div>
                <div className='col-md-5 mt-06'>
                  Patient ID:
                  <Field
                    name='searchPatientId'
                    component={Input}
                    validator={
                      mainFormExistingPatient.searchPatientId.inputValidator
                    }
                  />
                </div>
                <div className='col-md-2 mt-18'>
                  <button
                    type='submit'
                    look='outline'
                    className='k-button mr-5 blue'
                  >
                    Search
                  </button>
                </div>
              </div>
            </form>
          )}
        />
      )}

      {visibleDialog && (
        <Dialog
          title='Patient Search Results'
          width={1000}
          onClose={togglePatientSearchDialog}
        >
          <Grid
            style={{ height: '300px' }}
            data={patientBucket}
            onRowClick={(e) => {
              onRowClickHandle(e);
            }}
          >
            <Column field='patientId' title='ID' width='150px' />
            <Column field='patientFirstName' title='First Name' width='250px' />
            <Column field='patientLastName' title='Last Name ' />
            <Column field='dob' title='Date of Birth' />
          </Grid>
        </Dialog>
      )}
    </>
  );
};

export default PatientCalendarSearch;
