import * as uoms from '@/constants/uom';

export const weightBasedUOMs = [uoms.G_PER_KG, uoms.MG_PER_KG, uoms.MCG_PER_KG];

export const isWeightBasedUOM = UOM => {
  return weightBasedUOMs.includes(UOM);
};

export const getDosageUOM = UOM => {
  if (UOM === uoms.MG_PER_KG) return uoms.MG;
  if (UOM === uoms.G_PER_KG) return uoms.GRAMM;
  if (UOM === uoms.MCG_PER_KG) return uoms.MCG;

  return UOM;
};

export const calcRemaining = dosageDetails => {
  const {
    wastage,
    adjustedDosage,
    selectedQuantity,
    calcDosage,
    calculatedDosage
  } = dosageDetails;

  if (wastage > 0) return 0;

  // define which we should use as base
  const dosageBase = Number(adjustedDosage || calculatedDosage || calcDosage);

  return dosageBase - selectedQuantity;
};

// Returns a conversion rate you need to apply to the final calculation
export const getConversionRate = (orderUOM, infusionUOM) => {
  let conversionRate = 1;

  if (!infusionUOM) return conversionRate;
  if (orderUOM === infusionUOM) return conversionRate;

  const orderHasMg = [uoms.MG_PER_KG, uoms.MG].includes(orderUOM);
  const orderHasGram = [uoms.G_PER_KG, uoms.GRAMM].includes(orderUOM);
  const orderHasMcg = [uoms.MCG_PER_KG, uoms.MCG].includes(orderUOM);

  if (infusionUOM === uoms.GRAMM && orderHasMg) {
    // g ---> mg
    conversionRate = 1000;
  } else if (infusionUOM === uoms.GRAMM && orderHasMcg) {
    // g ---> mcg
    conversionRate = 1000000;
  } else if (infusionUOM === uoms.MG && orderHasGram) {
    // mg ---> g
    conversionRate = 0.001;
  } else if (infusionUOM === uoms.MG && orderHasMcg) {
    // mg ---> mcg
    conversionRate = 1000;
  } else if (infusionUOM === uoms.MCG && orderHasGram) {
    // mcg ---> g
    conversionRate = 0.000001;
  } else if (infusionUOM === uoms.MCG && orderHasMg) {
    // mcg ---> mg
    conversionRate = 0.001;
  } else if (infusionUOM === uoms.LITER && infusionUOM === uoms.ML) {
    // l ---> ml
    conversionRate = 1000;
  } else if (infusionUOM === uoms.ML && infusionUOM === uoms.LITER) {
    // ml ---> l
    conversionRate = 0.001;
  }

  return conversionRate;
};
