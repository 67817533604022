import React from "react";
import { withRouter } from "react-router-dom";
import PatientManualSearch from "../Agent/PatientManualSearch";
import ClinicianWorkInProgress from "../Agent/ClinicianWorkInProgress";

const ClinicianQueue = () => {
  return (
    <div className="container-fluid">
      <div className="row mt-18">
        <div className="col-7 mt-14">&nbsp;</div>
        <div className="col-4">
          <PatientManualSearch
            searchLayout={2}
            searchEndPoint={"/patient-portal"}
            existingOnly={true}
          />
        </div>
      </div>
      <div className="row my-4">
        <div className="col-12 col-lg-12 work-in-progress">
          <ClinicianWorkInProgress />
        </div>
      </div>
    </div>
  );
};

export default withRouter(ClinicianQueue);
