export const apointmentStatusMap = {
  CANCELLED_INFUSION: 'Infusion Cancelled',
  COMPLETED_INFUSION: 'Infusion Completed',
  COMPLETED_LAB_DRAW: 'Lab Draw Completed',
  CANCELLED: 'Appointment Cancelled',
  SCHEDULED: 'Appointment Scheduled',
  RESCHEDULED: 'Appointment Rescheduled',
  STARTED_INFUSION: 'Infusion Started',
  STARTED_LAB_DRAW: 'Lab Draw Started',
  CHECKED_IN: 'Checked In',
  INVALID: 'Invalid',
  PENDING: 'Pending'
};

export const initialSort = [
  {
    field: 'oldStartTime',
    dir: 'dsc'
  }
];
