// ========== QOL --- Provider-level surveys ========== //
// QOL Program, Provider version, for the first time infusions
export const QOL_PROVIDER_1ST_INFUSION = 'QOL-Provider-1st-Infusion';
// QOL Program, Provider version, for the following administrations
export const QOL_PROVIDER_FOLLOWING = 'QOL-Provider-Following';

// ========== QOL --- Patient-level surveys ========== //
// QOL (AQCCA) Program, Patient version on tablet
export const QOL_PATIENT_TABLET_SURVEY = 'QOL-Patient-Tablet-Survey';
export const REASON_TO_SKIP_SURVEY = 'Reason-To-Skip-Survey';

// ========== QOL --- Rating translations --- ========== //
export const QOL_RATING_VERY_POOR = 'Very Poor';
export const QOL_RATING_POOR = 'Poor';
export const QOL_RATING_AT_RISK = 'At risk';
export const QOL_RATING_GENERALLY_WELL = 'Generally Well';
export const QOL_RATING_VERY_WELL = 'Very Well';
export const QOL_RATING_MILD = 'Mild';
export const QOL_RATING_AVERAGE = 'Average';
export const QOL_RATING_MODERATE = 'Moderate';
export const QOL_RATING_MODERATELY_SEVERE = 'Moderately severe';
export const QOL_RATING_SEVERE_DEPRESSION = 'Severe depression';

// ========== Discontinuation survey models ========== //
export const DISCONTINUATION_SURVEY = 'Discontinuation-Survey';

export const followUpActionTypes = {
  emergency: { color: 'darkred', icon: 'light-emergency-on' },
  warning: { color: '#827300', icon: 'triangle-exclamation' },
  info: { color: '#005282', icon: 'circle-info' }
};

export const renderDiscSurveyHeader = (version = 1) => `
  <h2>Discontinuation Survey</h2>
  <h4>You must complete this survey in order to discontinue the selected order.</h4>
  <span class='location-time-label'>Survey Revision Number: ${version}</span>
`;

export const generateDiscReasonCode = (surveyData) => {
  if (!surveyData['main-reason']) return null;

  let code = surveyData['main-reason'];

  if (surveyData['financial-reason']) {
    code += surveyData['financial-reason'];
  }

  if (surveyData['physician-reason']) {
    code += surveyData['physician-reason'];
  }

  if (surveyData['patient-reason']) {
    code += surveyData['patient-reason'];
  }

  if (surveyData['insurance-reason']) {
    code += surveyData['insurance-reason'];
  }

  if (surveyData['supplier-reason']) {
    code += surveyData['supplier-reason'];
  }

  return code;
};

// Documents and Guides for download
export const MEMORY_TOOL_GUIDE = 'Memory Tool Guide-Patient Handout.docx';
export const SELF_CARE_GUIDE = 'Self-Care Patient Handout.docx';
