import React from 'react';
import { useHistory } from 'react-router-dom';

// kendo
import {
  Card,
  CardTitle,
  CardBody,
  CardActions
} from '@progress/kendo-react-layout';

// components
import AwesomeLabel from '@/components/common-components/AwesomeLabel';

// styles
import styles from './Widgets.module.scss';

const InboundFaxWidget = () => {
  const history = useHistory();

  return (
    <Card className={styles.card}>
      <CardBody>
        <CardTitle>
          <AwesomeLabel icon='fax' label='Inbound Faxes' />
        </CardTitle>
        <p className={styles.description}>
          Create work items from the inbound faxes
        </p>
        {/* <p>
          The most oldest fax received:
          <br />
          <AwesomeLabel icon='clock' label='Unknown' />
        </p> */}
      </CardBody>
      <CardActions className={styles.actions}>
        <span
          className='k-button k-button-md k-rounded-md k-button-flat k-button-flat-primary'
          onClick={() => history.push('/new-inbound')}
        >
          <AwesomeLabel
            icon='arrow-right'
            label='To the Fax Queue'
            iconFirst={false}
          />
        </span>
      </CardActions>
    </Card>
  );
};

export default InboundFaxWidget;
