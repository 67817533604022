import React, { useContext } from 'react';
import { PatientContext } from '@/context/PatientContext';
import OverView from './Overview';

const OverViewPage = () => {
  const { selectedPatientInfo } = useContext(PatientContext);
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="k-form pl-3 pr-3 pt-1">
              <div className="row mt-3">
                <OverView selectedPatientInfo={selectedPatientInfo} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OverViewPage;
