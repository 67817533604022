const last30daysOptions = [
  { value: 10, text: 'No day (0 days)' },
  { value: 8, text: 'A few days (1-3 days)' },
  { value: 6, text: 'A couple of days (4-6 days)' },
  { value: 4, text: 'A few weeks (7-14 days)' },
  { value: 2, text: 'Most of the time (15-21 days)' },
  { value: 0, text: 'Always (all 30 days)' }
];

export const common30daysField = {
  type: 'radiogroup',
  choices: last30daysOptions,
  isRequired: true
};

export const last30daysOptionsPatient = [
  { value: 10, text: '0-3 days' },
  { value: 9, text: '4-6 days' },
  { value: 8, text: '7-9 days' },
  { value: 7, text: '10-12 days' },
  { value: 6, text: '13-15 days' },
  { value: 5, text: '16-18 days' },
  { value: 4, text: '19-21 days' },
  { value: 3, text: '22-24 days' },
  { value: 2, text: '25-27 days' },
  { value: 1, text: '28-30 days' }
];

export const common30daysFieldPatient = {
  type: 'radiogroup',
  choices: last30daysOptionsPatient,
  isRequired: true
};

export const generalPainLevel = [
  { value: 10, text: '1 - No Pain' },
  {
    value: 9,
    text: '2 - Notice pain, does not interfere with activities'
  },
  { value: 8, text: '3 - Sometimes distracts me' },
  { value: 7, text: '4 - Distracts me, can do usual activities' },
  { value: 6, text: '5 - Interrupts some activities' },
  { value: 5, text: '6 - Hard to ignore, avoid usual activities' },
  {
    value: 4,
    text: '7 - Focus of attention, prevents doing daily activities'
  },
  { value: 3, text: '8 - Awful, hard to do anything' },
  {
    value: 2,
    text: '9 - Can’t bear the pain, unable to do anything'
  },
  {
    value: 1,
    text: '10 - As bad as it could be, nothing else matters'
  }
];

const NOT_AT_ALL = 0;
const SEVERAL_DAYS = 1;
const MORE_THAN_HALF = 2;
const EVERY_DAY = 3;

export const phq9Options = [
  { value: NOT_AT_ALL, text: 'Not at all' },
  { value: SEVERAL_DAYS, text: 'Several Days' },
  { value: MORE_THAN_HALF, text: 'More than one-half of the days' },
  { value: EVERY_DAY, text: 'Nearly every day' }
];

export const phq9SkippingMapper = (phq9Value) => {
  if (phq9Value === NOT_AT_ALL) return 10;
  if (phq9Value === SEVERAL_DAYS) return 7;
  if (phq9Value === MORE_THAN_HALF) return 4;
  if (phq9Value === EVERY_DAY) return 1;

  return 0;
};

export const phq9Answer = {
  type: 'radiogroup',
  choices: phq9Options,
  isRequired: true
};

export const defaultNumRates = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const common1to10Rate = {
  type: 'rating',
  rateValues: defaultNumRates,
  isRequired: true
};

export const yesNoOptions = [
  { value: true, text: 'Yes' },
  { value: false, text: 'No' }
];

export const commonYesNoField = {
  type: 'radiogroup',
  choices: yesNoOptions,
  isRequired: true
};

const DISEASE_STATE = {
  visibleIf: '{survey-start-option} = 1',
  type: 'checkbox',
  name: 'checkPatEduDiseaseState',
  title:
    'Educate Patient on their disease state; If patient has been seen previously inquire as to any new health concerns since prior visit',
  isRequired: true,
  choices: [
    {
      value: 'completed',
      text: 'Completed'
    }
  ]
};

const DRUGS = {
  visibleIf: '{survey-start-option} = 1',
  type: 'checkbox',
  name: 'checkPatEduDrugs',
  title:
    'Educate patient on why they are taking the drug, drug warnings, drug side effects and follow-ups including labs; If patient seen previously-inquire about any new medication starts (chart if any)',
  isRequired: true,
  choices: [
    {
      value: 'completed',
      text: 'Completed'
    }
  ]
};

const MEDICAL_HELP = {
  visibleIf: '{survey-start-option} = 1',
  type: 'checkbox',
  name: 'checkPatEduMedicalHelp',
  title:
    'After your last infusion did you have any problems due to this medicine that required you to get help? (Doctor/ER/Hospital)',
  isRequired: true,
  choices: [
    {
      value: 'completed',
      text: 'Completed'
    }
  ]
};

const MEDICAL_HELP_FIRST_TIME = {
  visibleIf: '{survey-start-option} = 1',
  type: 'checkbox',
  name: 'checkPatEduMedicalHelp',
  title:
    'Inform patient about post infusion management - what to watch for and when to seek medical help',
  isRequired: true,
  choices: [
    {
      value: 'completed',
      text: 'Completed'
    }
  ]
};

const MEDICAL_FORM = {
  visibleIf: '{survey-start-option} = 1',
  type: 'checkbox',
  name: 'checkPatEduMedicalForm',
  title: 'Give the patient a medication information form.',
  isRequired: true,
  choices: [
    {
      value: 'completed',
      text: 'Completed'
    }
  ]
};

export const firstInfusionPrompts = {
  title: 'Patient Education',
  elements: [DISEASE_STATE, DRUGS, MEDICAL_HELP_FIRST_TIME, MEDICAL_FORM]
};

export const followingInfusionPrompts = {
  title: 'Patient Education',
  elements: [DISEASE_STATE, DRUGS, MEDICAL_HELP]
};

export const selfHarmWarning = {
  name: 'self-harm-warning',
  type: 'html',
  html: '<b style="color: red">If patient reports self-harm provide Suicide Prevention line (800-273-8255) and Phone physician</b>',
  visibleIf: '{EWB-depression} < 6'
};

export const memoryGuideWarning = {
  name: 'memory-guide-warning',
  type: 'html',
  html: '<b style="color: red">Provide Patient Memory Guide Handout</b>',
  visibleIf: '{cognition-remembering} < 4'
};

export const selfCareGuideWarning = {
  name: 'self-care-guide-warning',
  type: 'html',
  html: '<b style="color: red">Provide Patient Self-Care Problem Solving Handout</b>',
  visibleIf: '{cognition-problem-solving} < 4'
};

export const transportationWarning = {
  name: 'transportation-warning',
  type: 'html',
  html: '<b style="color: red">Inform patient to call insurance to ask about transportation benefit</b>',
  visibleIf: '{LoI-transportation} = false'
};

export const foodBenefitsWarning = {
  name: 'food-benefits-warning',
  type: 'html',
  html: '<b style="color: red">Inform patient to call insurance to ask about food benefits</b>',
  visibleIf: '{LoI-food} = false'
};

export const startOrSkipSurvey = {
  elements: [
    // Start or Skip selector
    {
      type: 'panel',
      name: 'panel-start',
      title: 'Start Survey',
      elements: [
        {
          name: 'survey-start-option',
          type: 'radiogroup',
          title: 'Choose one of the options to start:',
          choices: [
            { value: 1, text: 'Proceed and show me the questions' },
            { value: 0, text: 'Skip Survey and provide a reason' },
            { value: 2, text: 'Opt-out from any surveys for this patient' }
          ],
          isRequired: true
        }
      ]
    }
  ]
};

export const skipReasons = {
  1: 'Patient concerned with questions',
  2: 'Patient refused',
  3: 'Patient has Privacy concerns',
  4: 'Language barrier',
  5: 'Patient cognition concerns',
  6: 'Caregiver request',
  7: 'Patient Request',
  8: 'Patient Arrived Late',
  9: 'No Privacy in AIC',
  10: 'Workflow',
  11: 'Other'
};

export const optOutReasons = {
  1: 'Patient concerned with questions',
  2: 'Patient refused',
  3: 'Patient has Privacy concerns',
  4: 'Language barrier',
  5: 'Patient cognition concerns',
  6: 'Caregiver request',
  7: 'Other'
};

const generateReasonsList = (reasons) => {
  const skipList = [];

  for (const [value, text] of Object.entries(reasons)) {
    skipList.push({ value, text });
  }

  return skipList;
};

export const skippingSurveyReasons = {
  elements: [
    {
      visibleIf: '{survey-start-option} = 0',
      type: 'panel',
      name: 'panel-survey-skip',
      title: 'Reason for skipping survey',
      elements: [
        {
          name: 'survey-skipping-reason',
          type: 'radiogroup',
          title: 'Why do you or your patient want to skip the survey?',
          choices: generateReasonsList(skipReasons),
          isRequired: true
        }
      ]
    }
  ]
};

export const skippingSurveyReasonsInfusion = {
  elements: [
    {
      type: 'panel',
      name: 'panel-survey-skip',
      title: 'Reason for skipping survey',
      elements: [
        {
          name: 'survey-skipping-reason',
          type: 'radiogroup',
          title: 'Why do you or your patient want to skip the survey?',
          choices: generateReasonsList(skipReasons),
          isRequired: true
        }
      ]
    }
  ]
};

export const optOutSurveyReasons = {
  elements: [
    {
      visibleIf: '{survey-start-option} = 2',
      type: 'panel',
      name: 'panel-survey-opt-out',
      title: 'Reason for opt-out from any following surveys',
      elements: [
        {
          name: 'survey-opt-out-reason',
          type: 'radiogroup',
          title: 'Why do you or your patient want to opt-out?',
          choices: generateReasonsList(optOutReasons),
          isRequired: true
        }
      ]
    }
  ]
};
