import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import CurrentOrder from './CurrentOrder';
import OrderHistory from './OrderHistory';
// import OrderNotes from './OrderNotes'
// import AdverseEvent from './AdverseEvent'
import Clinical from './Clinical';
import NewOrder from './NewOrder';
// import Discontinuation from "./Discontinuation";

const Referral = () => {
  const history = useHistory();
  const [selectedTab, setSelectedTab] = useState(0);
  const [archivedOrder, setArchivedOrder] = useState(null);

  const handleSelect = (e) => {
    setSelectedTab(e.selected);
  };

  const handleSelectArchivedOrder = (orderData) => {
    setArchivedOrder(orderData);
  };

  useEffect(() => {
    if (history?.location?.state?.redirect2Referral) {
      setSelectedTab(2);
    } else if (history?.location?.state?.redirect2OrderHistory) {
      setSelectedTab(3);
    } else if (history?.location?.state?.redirect2CurrentOrders) {
      setSelectedTab(1);
    } else if (history?.location?.state?.redirect2NewOrder) {
      setSelectedTab(0);
    }
  }, [history]);

  return (
    <div className="patient-portal bottom-tabstrip-container">
      <TabStrip selected={selectedTab} onSelect={handleSelect}>
        <TabStripTab title="New Order">
          <NewOrder
            archivedOrder={archivedOrder}
            onSelectArchiveOrder={handleSelectArchivedOrder}
          />
        </TabStripTab>
        <TabStripTab title="Current Orders">
          <CurrentOrder
            onChangeTab={setSelectedTab}
            onSelectArchiveOrder={handleSelectArchivedOrder}
          />
        </TabStripTab>
        {/* <TabStripTab title="Order Notes">
                    <OrderNotes />
                </TabStripTab>
                <TabStripTab title="Adverse Event">
                    <AdverseEvent />
                </TabStripTab> */}
        <TabStripTab title="Clinical">
          <Clinical />
        </TabStripTab>
        {/* <TabStripTab title="Discontinue/Cancellation">
          <Discontinuation />
        </TabStripTab> */}
        <TabStripTab title="Order History">
          <OrderHistory />
        </TabStripTab>
      </TabStrip>
    </div>
  );
};

export default Referral;
