export const WORK_ITEM_PRIORITIES = [
  { label: '01-Standard', value: 1 },
  { label: '02-Standard', value: 2 },
  { label: '03-Standard', value: 3 },
  { label: '04-High', value: 4 },
  { label: '05-High', value: 5 },
  { label: '06-High', value: 6 },
  { label: '07-High', value: 7 },
  { label: '08-Critical', value: 8 },
  { label: '09-Critical', value: 9 },
  { label: '10-Critical', value: 10 }
];

export const WORK_ITEM_TYPES = ['Incoming Fax'];
