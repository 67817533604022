export const cancelReasons = [
  { value: 'Abnormal Vital Signs' },
  { value: 'Damaged Medication' },
  { value: 'Home Medication Contraindication' },
  { value: 'No Meds Administered' },
  { value: 'Order Not Approved' },
  { value: 'Patient Changed Mind' },
  { value: 'Unable to Obtain IV Access' }
];

export const accessPoints = [
  {
    text: 'Routine Blood Draw: No IV access started',
    value: 'NonRoutineBloodDraw'
  },
  {
    text: 'Non-Routine Blood Draw: IV access started',
    value: 'NormalVeinDraw'
  },
  {
    text: 'PICC Draw',
    value: 'PICCDraw'
  },
  {
    text: 'Port Flush',
    value: 'ProviderIrrigates'
  },
  {
    text: 'Port/Central Line Draw',
    value: 'PortCentralLineDraw'
  }
];
