import React, { useContext, useEffect, useState } from 'react';
import { Storage } from 'aws-amplify';

// kendo
import { TextArea } from '@progress/kendo-react-inputs';
import { Form, Field } from '@progress/kendo-react-form';
import { Grid } from '@progress/kendo-react-grid';
import { GridColumn as Column } from '@progress/kendo-react-grid/dist/npm/GridColumn';
import { Dialog } from '@progress/kendo-react-dialogs';

// common components
import { MessageDialog } from '@/components/common-components/MessageDialog';
import FormButton from '@/components/common-components/Form/FormButton';
import WarningBlock from '@/components/common-components/WarningBlock';
import { MaskedPhoneInput } from '@/common/MaskInput';
import PatientDocument from '@/components/Patient/PatientDocument';
import AwesomeLabel from '@/components/common-components/AwesomeLabel';

// partials

// utils
import { convertToE164 } from '@/common/PhoneNumberConverter';
import {
  DropDownListField,
  InputField,
  validateInput
} from '@/common/Validation';
import { faxTemplates } from './FaxTemplates';
import { GridDateTimeZoneFormatCell } from '@/common/Utility';
import { Constants } from '@/constants';

// gql
import { connectToGraphqlAPI } from '@/provider';
import { getPatientReferralOrders } from '@/graphql/queries';
import { generateFaxPdf, sendFax } from '@/graphql/mutations';

// context
import {
  UserContext,
  PatientContext,
  NotifContext,
  LogContext,
  UserRoleTypes
} from '@/context';

const emailCCList = 'marty.mcgee@optml.com';

const FaxInfo = () => {
  const { user, canEdit } = useContext(UserContext);
  const { selectedPatientInfo } = useContext(PatientContext);
  const { showError } = useContext(NotifContext);
  const { logApiException } = useContext(LogContext);

  const [listReferralOrdersData, setListReferralOrdersData] = useState([]);
  const [faxData, setFaxData] = useState(null);
  const [loadingState, setLoadingState] = useState(false);

  const [generatedPdfPath, setGeneratedPdfPath] = useState('');
  const [dialogOption, setDialogOption] = useState({});
  const [showPatientFaxDocumentDialog, setShowPatientFaxDocumentDialog] =
    useState(false);

  const froms = [
    {
      from: 'AleraCare - AZ',
      fromName: 'AleraCare Arizona',
      phone: '+18189845784',
      fax: '+18189745487',
      logo: '/logo_aleracare_fax_new.png'
    },
    {
      from: 'AleraCare - CA',
      fromName: 'AleraCare California',
      phone: '+18882098874',
      fax: '+18333294738',
      logo: '/logo_aleracare_fax_new.png'
    },
    {
      from: 'AleraCare - CO',
      fromName: 'AleraCare Colorado',
      phone: '+18882389523',
      fax: '+18885515096',
      logo: '/logo_aleracare_fax_new.png'
    },
    {
      from: 'AleraCare - ID',
      fromName: 'AleraCare Idaho',
      phone: '+18885109298',
      fax: '+18885896495',
      logo: '/logo_aleracare_fax_new.png'
    },
    {
      from: 'AleraCare - NM',
      fromName: 'AleraCare New Mexico',
      phone: '+18882357067',
      fax: '+18884798572',
      logo: '/logo_aleracare_fax_new.png'
    },
    {
      from: 'AleraCare - OR',
      fromName: 'AleraCare Oregon',
      phone: '+18885022292',
      fax: '+18885051381',
      logo: '/logo_aleracare_fax_new.png'
    },
    {
      from: 'AleraCare - UT',
      fromName: 'AleraCare Utah',
      phone: '+18883841262',
      fax: '+18883841273',
      logo: '/logo_aleracare_fax_new.png'
    },
    {
      from: 'AleraCare - WA',
      fromName: 'AleraCare Washington',
      phone: '+18885061858',
      fax: '+18885085161',
      logo: '/logo_aleracare_fax_new.png'
    },
    {
      from: 'Vasco Infusion',
      fromName: 'Vasco Infusion',
      phone: '+16023460204',
      fax: '+18776376691',
      logo: '/logo_alera_vasco_new.png'
    }
  ];

  // const assignFromCompany = ["AleraCare", "Vasco Infusion"];

  const [patientDocuments, setPatientDocuments] = useState(
    (selectedPatientInfo &&
      selectedPatientInfo.patientDocuments?.map((item, index) => {
        item.id = index;
        return item;
      })) ||
      []
  );

  // MAIN INITIATOR
  useEffect(() => {
    listReferralOrdersCall(selectedPatientInfo.patientId);
  }, []);

  const listReferralOrdersCall = async (requestObject) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getPatientReferralOrders,
        variables: { patientId: requestObject }
      });

      if (data?.data?.getPatientBucket?.referral?.drugReferrals?.length) {
        setListReferralOrdersData(
          data.data.getPatientBucket.referral.drugReferrals.map((item) => ({
            ...item,
            text: item.referralOrder.orderName,
            value: item.referralOrder.orderName
          }))
        );
      }
    } catch (err) {
      logApiException(err, {
        view: 'FaxInfo',
        endpoint: 'getPatientReferralOrders',
        patientId: requestObject
      });

      showError('Error: getPatientReferralOrders');
    }
  };

  const onDocumentRowHandle = async (documentPath) => {
    if (documentPath) {
      const conf = { download: false };
      const s3ImageURL = await Storage.get(documentPath, conf);
      setGeneratedPdfPath(s3ImageURL);
      showPatientFaxDocument();
    }
  };

  const hyperLinkCell = (props) => {
    return (
      <td>
        {props.dataItem.documentPath && (
          <span
            className='blue-link'
            onClick={() => onDocumentRowHandle(props.dataItem.documentPath)}
          >
            {props.dataItem.documentPath}
          </span>
        )}
      </td>
    );
  };

  const sendFaxCall = async (requestObject) => {
    // Prevent sending faxes from non-production env
    // if (process.env.REACT_APP_ENV !== 'prod') {
    //   showError('Faxes can not be sent in non-production environment');
    //   return;
    // }

    // Otherwise - please, send the fax
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: sendFax,
        variables: { input: requestObject }
      });

      if (data?.data?.sendFax?.statusCode) {
        if (data.data.sendFax.statusCode === '200') {
          setDialogOption({
            title: 'Fax',
            message: 'Fax Sent Successfully',
            showDialog: true
          });

          // close dialog is fax was sent
          setShowPatientFaxDocumentDialog(!showPatientFaxDocumentDialog);

          // reset form
          resetForm();
        } else {
          setDialogOption({
            title: 'Fax',
            message: 'Fax Sending Failed',
            showDialog: true
          });
        }
      }
    } catch (err) {
      console.log('marty sendFaxCall err', err);
      setDialogOption({
        title: 'Fax',
        message: 'Error: sendFaxCall',
        showDialog: true
      });
    }
  };

  const generateFaxPdfCall = async (
    requestObject,
    // previewOnly,
    faxAttachment
  ) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: generateFaxPdf,
        variables: { input: requestObject }
      });

      if (
        data &&
        data.data &&
        data.data.generateFaxPDF &&
        data.data.generateFaxPDF.statusCode === '200'
      ) {
        /* setDialogOption({
          title: "Generate Fax PDF",
          message: "Successful",
          showDialog: true
        }); */

        // setPatientDocuments([
        //   {
        //     documentName: 'documentName',
        //     documentType/faxTemplate: data.data.generateFaxPDF.filePath,
        //     filePath: data.data.generateFaxPDF.filePath,
        //     date: moment().format(Constants.DATE.SHORTDATE)
        //   },
        // ])

        // if (previewOnly) {
        onDocumentRowHandle(data.data.generateFaxPDF.filePath);
        setGeneratedPdfPath(data.data.generateFaxPDF.filePath);
        // }

        // STEP 2 -- send generated PDF along with fax info

        // prepare faxData to be sent
        // input FaxInput {
        const faxData = {
          // agentId: String!
          agentId: requestObject.agentId,
          // patientId: String!
          patientId: requestObject.patientId,
          // outboundFax: AWSPhone!
          outboundFax: requestObject.outboundFax,
          // from: String
          from: requestObject.from,
          // attention: String
          attention: requestObject.attention,
          // sendToPhone: AWSPhone
          sendToPhone: requestObject.sendToPhone,
          // faxNotes: String
          faxNotes: requestObject.faxNotes,
          // faxBody: String!
          faxBody: data.data.generateFaxPDF.filePath,
          // emailCCList: [AWSEmail]
          emailCCList,
          // subject: String!
          subject: requestObject.subject,
          // faxType: FaxTemplate!
          faxType: requestObject.faxType
        };

        if (faxAttachment) {
          // faxAttachment: [String!]!
          faxData.faxAttachment = [...faxAttachment];
        }

        setFaxData(faxData);
        setLoadingState(false);
        // if (!previewOnly) {
        //   // input FaxInput {
        //   const requestObject2 = {
        //     // agentId: String!
        //     agentId: requestObject.agentId,
        //     // patientId: String!
        //     patientId: requestObject.patientId,
        //     // outboundFax: AWSPhone!
        //     outboundFax: requestObject.outboundFax,
        //     // from: String
        //     from: requestObject.from,
        //     // attention: String
        //     attention: requestObject.attention,
        //     // sendToPhone: AWSPhone
        //     sendToPhone: requestObject.sendToPhone,
        //     // faxNotes: String
        //     faxNotes: requestObject.faxNotes,
        //     // faxBody: String!
        //     faxBody: data.data.generateFaxPDF.filePath,
        //     // faxAttachment: [String!]!
        //     faxAttachment: [faxAttachment],
        //     // emailCCList: [AWSEmail]
        //     emailCCList,
        //     // subject: String!
        //     subject: requestObject.subject,
        //     // faxType: FaxTemplate!
        //     faxType: requestObject.faxType
        //     //referralOrderId: requestObject.referralOrderId,
        //   };

        // console.log("marty handleSubmit requestObject2", requestObject2)
        // alert("PDF GENERATED. READY TO SEND FAX...")

        // PREVIEW AND SEND

        // sendFaxCall(requestObject2);
        // }
      } else {
        let msg =
          'Error: This template requires a referral order name to be selected.';
        if (data?.data?.generateFaxPDF?.statusCode === '500') {
          msg = `Error: This template generated a server side error: '${data?.data?.generateFaxPDF?.error}'`;
        }
        setDialogOption({
          title: 'Generate Fax PDF',
          message: msg,
          showDialog: true
        });
        setLoadingState(false);
      }
    } catch (err) {
      console.log('FaxInfo::generateFaxPdfCall err: ', err);
      setDialogOption({
        title: 'Generate Fax PDF',
        message: 'Error: generateFaxPdfCall',
        showDialog: true
      });
      setLoadingState(false);
    }
  };

  const selectionChange = (event, tableData, setTableData) => {
    const data = tableData.map((item) => {
      if (event.dataItem.id === item.id) {
        item.selected = !event.dataItem.selected;
      }
      return item;
    });
    setTableData(data);
  };

  const headerSelectionChange = (event, tableData, setTableData) => {
    const { checked } = event.syntheticEvent.target;
    const data = tableData.map((item) => {
      item.selected = checked;
      return item;
    });
    setTableData(data);
  };

  const faxInfoForm = {
    faxNo: {
      value: '',
      inputValidator: (value) => {
        return validateInput({ faxNo: { ...faxInfoForm.faxNo, value } });
      },
      validations: []
    },
    referral: {
      value: '',
      inputValidator: (value) => {
        return validateInput({ referral: { ...faxInfoForm.referral, value } });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    },
    faxTemplate: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          faxTemplate: { ...faxInfoForm.faxTemplate, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    },
    assignFromCompany: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          assignFromCompany: { ...faxInfoForm.assignFromCompany, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    },
    sendToName: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          sendToName: { ...faxInfoForm.sendToName, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    },
    sendToFaxNumber: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          sendToFaxNumber: { ...faxInfoForm.sendToFaxNumber, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    },
    ccEmail: {
      value: '',
      inputValidator: (value) => {
        return validateInput({ ccEmail: { ...faxInfoForm.ccEmail, value } });
      },
      validations: []
    }
  };

  const initialForm = () => {
    const initialObject = {};
    Object.keys(faxInfoForm).forEach((key) => {
      initialObject[key] = faxInfoForm[key].value;
    });
    return initialObject;
  };

  const showPatientFaxDocument = () => {
    setShowPatientFaxDocumentDialog(!showPatientFaxDocumentDialog);
  };

  const handleSubmit = (dataItem) => {
    setLoadingState(true);

    // STEP 1 -- generate PDF in S3 and get it's URL
    const selectedDocument = patientDocuments.filter(
      (item) => item.selected && item.documentPath
    );

    const faxAttachment =
      selectedDocument && selectedDocument.length > 0
        ? selectedDocument.map((document) => document.documentPath)
        : null;

    let additionalData = {
      logo: `${window.location.host}${dataItem.assignFromCompany.logo}`
    };
    additionalData = JSON.stringify(additionalData);

    // input GenerateFaxPDFInput {
    const requestObject = {
      // agentId: String!
      agentId: user.username, // "tester01",
      // patientId: String!
      patientId: selectedPatientInfo.patientId,
      // outboundFax: AWSPhone!
      outboundFax: convertToE164(dataItem.sendToFaxNumber), // "+17707460198", //"+17079617078", //"+18772096059",
      // from: String
      from: dataItem.assignFromCompany.fromName, // "AleraCare Arizona",
      // attention: String
      attention: dataItem.sendToName, // "Dr. Lee",
      // sendToPhone: AWSPhone
      sendToPhone: convertToE164(dataItem.sendToPhone), // "+17079801136",
      // faxNotes: String
      faxNotes: dataItem.faxNotes, // "HEY HEY HEY",
      // faxBody: String!
      // faxAttachment: [String!]!
      // emailCCList: [AWSEmail]
      // subject: String!
      subject: dataItem?.referral?.referralOrder?.orderName
        ? dataItem?.referral?.referralOrder?.orderName
        : 'No Referral', // "REMICADE + IB PRE-MEDS",
      // faxType: FaxTemplate!
      faxType: dataItem.faxTemplate.value,
      // referralOrderId: String
      referralOrderId: dataItem.referral.referralId, // "Remicade",
      // additionalData: AWSJSON
      additionalData
    };

    generateFaxPdfCall(requestObject, faxAttachment);
  };

  const handleTemplateChange = (e) => {
    document.getElementById('faxNotes').value = e.value.faxNotes;
  };

  const resetForm = () => {
    document.getElementById('resetButton').click();
  };

  return (
    <div>
      <div className='col'>
        {dialogOption && dialogOption.showDialog && (
          <MessageDialog dialogOption={dialogOption} />
        )}
        <Form
          onSubmit={handleSubmit}
          initialValues={initialForm()}
          render={(formRenderProps) => (
            <form
              onSubmit={formRenderProps.onSubmit}
              className='k-form pl-3 pr-3 pt-1'
            >
              <div className='row'>
                <div className='col-md-3 pageTitle'>Outbound Fax</div>
              </div>
              <div className='row mt-12'>
                <div className='col-md-3'>
                  {/* From Entity: */}
                  <AwesomeLabel
                    size='duotone'
                    label={' '}
                    icon='share-from-square icon-blue'
                    title='From Entity'
                  />
                  <br />
                  <Field
                    name='assignFromCompany'
                    component={DropDownListField}
                    // data={assignFromCompany}
                    data={froms}
                    valueField='from'
                    textField='fromName'
                    // defaultValue="AleraCare"
                    validator={faxInfoForm.assignFromCompany.inputValidator}
                    // onChange={handleFromEntityChange}
                  />
                </div>
                <div className='col-md-3'>
                  {/* Template: */}
                  <AwesomeLabel
                    size='duotone'
                    label={' '}
                    icon='scroll icon-blue'
                    title='Template'
                  />
                  <br />
                  <Field
                    name='faxTemplate'
                    component={DropDownListField}
                    data={faxTemplates}
                    textField='text'
                    valueField='value'
                    validator={faxInfoForm.faxTemplate.inputValidator}
                    onChange={handleTemplateChange}
                  />
                </div>
                <div className='col-md-3'>
                  {/* Referral Order Name: */}
                  <AwesomeLabel
                    size='duotone'
                    label={' '}
                    icon='file-prescription icon-blue'
                    title='Referral Order Name'
                  />
                  <br />
                  <Field
                    name='referral'
                    label=''
                    component={DropDownListField}
                    data={listReferralOrdersData}
                    textField='text'
                    valueField='value'
                    // onChange={(e) => handleSelectOrder(e)}
                    // onChange={handleReferralOrderChange}
                    /*  validator={faxInfoForm.referral.inputValidator} */
                  />
                </div>
              </div>
              <div className='row mt-12'>
                <div className='col-md-3'>
                  {/* To Fax Number: */}
                  <AwesomeLabel
                    size='duotone'
                    label={' '}
                    icon='fax icon-blue'
                    title='To Fax Number'
                  />
                  <br />
                  <Field
                    name='sendToFaxNumber'
                    component={MaskedPhoneInput}
                    validator={faxInfoForm.sendToFaxNumber.inputValidator}
                  />
                </div>
                <div className='col-md-3'>
                  {/* To Name: */}
                  <AwesomeLabel
                    size='duotone'
                    label={' '}
                    icon='user icon-blue'
                    title='To Name'
                  />
                  <br />
                  <Field
                    name='sendToName'
                    component={InputField}
                    validator={faxInfoForm.sendToFaxNumber.inputValidator}
                  />
                </div>
                <div className='col-md-3'>
                  {/* To Phone Number: */}
                  <AwesomeLabel
                    size='duotone'
                    label={' '}
                    icon='phone icon-blue'
                    title='To Phone Number'
                  />
                  <br />
                  <Field
                    name='sendToPhone'
                    component={MaskedPhoneInput}
                    // validator={faxInfoForm.ccEmail.inputValidator}
                  />
                </div>
                {/* <div className="col-md-3">
								To Email:<br/>
								<Field
									name={"ccEmail"}
									component={InputField}
									validator={faxInfoForm.ccEmail.inputValidator}
								/>
							</div> */}
              </div>
              <div className='row mt-12'>
                {/* <div className="col-md-3">
								From Fax Number:<br/>
								<Field
									name={"sendFromFaxNumber"}
									component={MaskedPhoneInput}
									validator={faxInfoForm.faxNo.inputValidator}
								/>
							</div> */}
                {/* <div className="col-md-9">
								Fax Subject:<br/>
								<Field
									name={"faxSubject"}
									component={Input}
								/>
							</div> */}
              </div>
              <div className='row mt-12'>
                <div className='col-md-10'>
                  {/* Fax Message: */}
                  <AwesomeLabel
                    size='duotone'
                    label={' '}
                    icon='message icon-blue'
                    title='Fax Message'
                  />
                  <Field
                    component={TextArea}
                    // defaultValue={faxMessage}
                    // defaultValue={faxInfoForm.faxNotes.value}
                    // value={faxMessage}
                    id='faxNotes'
                    name='faxNotes'
                    // onChange={(e) => setFaxMessage(e.value)}
                    style={{ width: '100%', minHeight: '200px' }}
                  />
                </div>
              </div>
              <div className='row mt-12'>
                <div className='col-md-2'>
                  {/* File Attachments: */}
                  <AwesomeLabel
                    size='duotone'
                    label={' '}
                    icon='paperclip icon-blue'
                    title='File Attachments'
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-md-10 mt-06'>
                  <Grid
                    editField='inEdit'
                    selectedField='selected'
                    style={{ height: '350px' }}
                    onSelectionChange={(e) =>
                      selectionChange(e, patientDocuments, setPatientDocuments)
                    }
                    onHeaderSelectionChange={(e) =>
                      headerSelectionChange(
                        e,
                        patientDocuments,
                        setPatientDocuments
                      )
                    }
                    data={patientDocuments}
                  >
                    <Column
                      field='documentType'
                      title='Document Type'
                      width='160px'
                    />
                    <Column
                      field='date'
                      title='Date'
                      width='300px'
                      cell={GridDateTimeZoneFormatCell}
                    />
                    <Column
                      field='documentPath'
                      cell={hyperLinkCell}
                      title='View Document'
                      sortable={false}
                      width='450px'
                    />
                    <Column
                      field='selected'
                      title='Attach to Fax'
                      width='100px'
                      sortable={false}
                    />
                  </Grid>
                </div>
              </div>

              {/* <div className="row mt-12 p-3">
                <div className="col-12">
                  Preview Only: &nbsp;&nbsp;
                  <Field
                    component={Switch}
                    onLabel={"Yes"}
                    offLabel={"No"}
                    name={"previewOnly"}
                  />
                </div>
              </div> */}
              <div className='row p-3'>
                <div className='col-12'>
                  {canEdit(UserRoleTypes.Fax) ? (
                    <FormButton
                      type='submit'
                      loading={loadingState}
                      className='k-button pageButton'
                      label='Preview Fax'
                    />
                  ) : (
                    <WarningBlock message='You do not have the necessary permissions to send a fax' />
                  )}

                  <button
                    className='k-button pageButton'
                    onClick={formRenderProps.onFormReset}
                    id='resetButton'
                    style={{ display: 'none' }}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </form>
          )}
        />
      </div>
      {showPatientFaxDocumentDialog && (
        <Dialog
          title='Fax preview'
          style={{
            backgroundColor: '#e9ecef',
            minHeight: '300px',
            zIndex: 200
          }}
          width={900}
          height={800}
          top={150}
          onClose={showPatientFaxDocument}
        >
          <button
            className='k-button pageButton'
            style={{ marginBottom: 10 }}
            onClick={() => sendFaxCall(faxData)}
          >
            Send Fax
          </button>

          <PatientDocument file={generatedPdfPath} />
        </Dialog>
      )}
    </div>
  );
};

export default FaxInfo;
