import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { FollowUpContext } from '../../context/FollowUpContext';
import PatientCalendarSearch from '../Calendar/PatientCalendarSearch';
import PatientCallWorkInProgress from './PatientCallWorkInProgress';

const PatientCallQueue = () => {
  const { followUpSearch } = useContext(FollowUpContext);
  return (
    <div className="container-fluid">
      <div className="row mt-18">
        <div className="col-7 mt-3" />
        <div className="col-5">
          {followUpSearch && (
            <PatientCalendarSearch
              searchLayout={2}
              existingOnly
            />
          )}
        </div>
      </div>
      <div className="row my-4">
        <div className="col-12 col-lg-12 work-in-progress">
          <PatientCallWorkInProgress />
        </div>
      </div>
    </div>
  );
};

export default withRouter(PatientCallQueue);
