/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useState } from 'react';
import * as moment from 'moment';

// kendo
import { Input, TextArea, Checkbox } from '@progress/kendo-react-inputs';
import { Form, Field } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Skeleton } from '@progress/kendo-react-indicators';
import {
  PanelBar,
  PanelBarItem,
  TabStrip,
  TabStripTab
} from '@progress/kendo-react-layout';

// components
import { FormRadioGroup } from '@/components/common-components/FormRadioGroup';
import Badge from '@/components/common-components/Badge';
import Preloader from '@/components/common-components/Preloader';
import Alert from '@/components/common-components/Alert';
import FormButton from '@/components/common-components/Form/FormButton';
import FullPageLoader from '@/components/common-components/FullPageLoader';
import AwesomeLabel from '@/components/common-components/AwesomeLabel';
import { FormNumericTextBox } from '@/common/kendo-form-components';
import RequiredFormLabel from '@/components/common-components/RequiredFormLabel';

// gql
import { connectToGraphqlAPI } from '@/provider';
import {
  getBenefitChecking,
  getPatientReferralOrders,
  getPatientInsuranceInfo,
  listGroupAICs,
  getLocationsAndProvidersByGroupId,
  getProductInfoByName
} from '@/graphql/queries';
import { addUpdateBenefitChecking } from '@/graphql/mutations';

// context
import {
  UserContext,
  UserRoleTypes,
  PatientContext,
  StatusContext,
  NotifContext
} from '@/context';

// common
import { states } from '@/common/states';
import { InputField, validateInput } from '@/common/Validation';

// constants
import { Constants } from '@/constants';

// helpers
import getDropdownValue from './getDropdownValue';
import { getDateValueToInitialize, getDateString } from './dateFieldHelpers';
import { cleanseString } from '@/common/String-Utils';

const CheckBenefits = () => {
  const [listReferralOrdersData, setListReferralOrdersData] = useState([]);
  const [listInsuranceInfoData, setListInsuranceInfoData] = useState([]);
  const [insuranceReferral, setInsuranceReferral] = useState();
  const [listGroupAICsData, setListGroupAICsData] = useState([]);
  const [listLocationAICsData, setListLocationAICsData] = useState([]);
  const [listProviderAICsData, setListProviderAICsData] = useState([]);
  const [mainFormData, setMainFormData] = useState({});
  const [showMainForm, setShowMainForm] = useState(false);
  const [certified, setCertified] = useState(true);
  const [loading, setLoading] = useState(true);
  const [dropLoading, setDropLoading] = useState(false);
  const [providerDisable, setProviderDisable] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [infoLoading, setInfoLoading] = useState(false);
  const [insuranceDisabled, setInsuranceDisabled] = useState(false);
  const [adminStop, setAdminStop] = useState(false);
  const [selectedInsuranceKeyPlanType, setSelectedInsuranceKeyPlanType] =
    useState('');
  const [selectedInsuranceKeyId, setSelectedInsuranceKeyId] = useState(0);
  const [selectedLocationNPI] = useState('');
  const [selectedBillingInfoGroup, setSelectedBillingInfoGroup] = useState();
  const [selectedBillingInfoLocation, setSelectedBillingInfoLocation] =
    useState();
  const [selected, setSelected] = useState(0);
  const [selectedProvider, setSelectedProvider] = useState();
  const [selectedGroupId, setSelectedGroupId] = useState();
  const [selectedLocationId, setSelectedLocationId] = useState();
  const [listInsuranceKey, setListInsuranceKey] = useState([
    { insuranceKey: 'Primary' }
  ]);
  const [tabData, setTabData] = useState([]);
  const [selectedProviderId, setSelectedProviderId] = useState();
  const [selectedProviderInfo, setSeclectedProviderInfo] = useState();
  const [selectedLocationInfo, setSelectedLocationInfo] = useState();
  const [jCodeData, setJcodeData] = useState();
  const [priorAuthReq, setPriorAuthReq] = useState();
  const [selectedNetworkStatuses, setSelectedNetworkStatuses] = useState();
  const [selectedOutOfNetworkBen, setSelectedOutOfNetworkBen] = useState();
  const [predeterminationNeededSelected, setPredeterminationNeededSelected] =
    useState();
  const [submittedInsurance, setSubmittedInsurance] = useState();

  const { statusUpdate, setStatusUpdate } = useContext(StatusContext);
  const { user, canEdit } = useContext(UserContext);
  const { selectedPatientInfo } = useContext(PatientContext);
  const { showSuccess, showError, showWarning } = useContext(NotifContext);

  const planType = ['MEDICAL', 'PHARMACY'];
  const dedTypes = ['Calendar Year', 'Plan Year'];
  const dedInfoDedTypes = ['SINGLE', 'FAMILY'];
  const contactMethods = [
    { label: 'Phone', value: 'PHONE', className: 'patient-radio blue' },
    { label: 'Fax', value: 'FAX', className: 'patient-radio blue' },
    { label: 'Payor Portal', value: 'PORTAL', className: 'patient-radio blue' }
  ];
  const networkStatuses = [
    { label: 'In', value: 'IN', className: 'patient-radio blue' },
    { label: 'Out', value: 'OUT', className: 'patient-radio blue' }
  ];
  const billingResultsNPIChoices = [
    {
      label: 'NPI from AIC Location',
      value: 'LOCATION',
      className: 'patient-radio blue'
    },
    {
      label: 'NPI from Provider',
      value: 'PROVIDER',
      className: 'patient-radio blue'
    }
  ];
  const outOfNetworkBen = [
    { label: 'Yes', value: true, className: 'patient-radio blue' },
    { label: 'No', value: false, className: 'patient-radio blue' }
  ];
  const dedNetworks = [
    { label: 'In Network', value: 'IN', className: 'patient-radio blue' },
    { label: 'Out of Network', value: 'OUT', className: 'patient-radio blue' }
  ];
  const isPriorAuthReqs = [
    { label: 'Yes', value: true, className: 'patient-radio blue' },
    { label: 'No', value: false, className: 'patient-radio blue' }
  ];
  const predeterminationNeededOptions = [
    {
      label: 'PRE-DETERMINATION NEEDED',
      value: true,
      className: 'patient-radio blue'
    },
    {
      label: 'PRE-DETERMINATION NOT NEEDED',
      value: false,
      className: 'patient-radio blue'
    }
  ];
  const priorAuthSubmitMethods = [
    { label: 'Phone', value: 'PHONE', className: 'patient-radio blue' },
    { label: 'Fax', value: 'FAX', className: 'patient-radio blue' },
    { label: 'Portal', value: 'PORTAL', className: 'patient-radio blue' }
  ];

  const getNetworkStatus = () => {
    const status = selectedNetworkStatuses === 'IN' || !selectedNetworkStatuses;
    return status;
  };

  const checkBenefitsForm = {
    billingInfoGroup: {
      value:
        mainFormData.benefitChecking?.benefitChecking?.checkings[
          selectedInsuranceKeyId
        ]?.selectedGroupId,
      inputValidator: (value) => {
        return validateInput({
          billingInfoGroup: { ...checkBenefitsForm.billingInfoGroup, value }
        });
      },
      validations: [
        { type: 'required', message: Constants.ErrorMessage.is_REQUIRED }
      ]
    },
    billingInfoLocation: {
      value:
        mainFormData.benefitChecking?.benefitChecking?.checkings[
          selectedInsuranceKeyId
        ]?.selectedLocationId,
      inputValidator: (value) => {
        return validateInput({
          billingInfoLocation: {
            ...checkBenefitsForm.billingInfoLocation,
            value
          }
        });
      },
      validations: [
        { type: 'required', message: Constants.ErrorMessage.is_REQUIRED }
      ]
    },
    provider: {
      value:
        mainFormData.benefitChecking?.benefitChecking?.checkings[
          selectedInsuranceKeyId
        ]?.selectedProviderId,
      inputValidator: (value) => {
        return validateInput({
          provider: { ...checkBenefitsForm.provider, value }
        });
      },
      validations: [
        { type: 'required', message: Constants.ErrorMessage.is_REQUIRED }
      ]
    },
    billingResultsNPIChoice: {
      value: mainFormData.overrideChoice,
      inputValidator: (value) => {
        return validateInput({
          billingResultsNPIChoice: {
            ...checkBenefitsForm.billingResultsNPIChoice,
            value
          }
        });
      },
      validations: []
    },
    overrideTINNPI: {
      value: !!(
        mainFormData.benefitChecking?.benefitChecking?.checkings[
          selectedInsuranceKeyId
        ]?.billingTaxIdForOutOfNetwork ||
        mainFormData.benefitChecking?.benefitChecking?.checkings[
          selectedInsuranceKeyId
        ]?.billingNPIForOutOfNetwork
      ),
      inputValidator: (value) => {
        return validateInput({
          overrideTINNPI: { ...checkBenefitsForm.overrideTINNPI, value }
        });
      },
      validations: []
    },
    overrideTaxId: {
      value:
        mainFormData.benefitChecking?.benefitChecking?.checkings[
          selectedInsuranceKeyId
        ]?.billingTaxIdForOutOfNetwork || null,
      inputValidator: (value) => {
        return validateInput({
          overrideTaxId: { ...checkBenefitsForm.overrideTaxId, value }
        });
      },
      validations: []
    },
    overrideNPI: {
      value:
        mainFormData.benefitChecking?.benefitChecking?.checkings[
          selectedInsuranceKeyId
        ]?.billingNPIForOutOfNetwork || null,
      inputValidator: (value) => {
        return validateInput({
          overrideNPI: { ...checkBenefitsForm.overrideNPI, value }
        });
      },
      validations: []
    },
    overrideReason: {
      value:
        mainFormData.benefitChecking?.benefitChecking?.checkings[
          selectedInsuranceKeyId
        ]?.billingOverrideReason || null,
      inputValidator: (value) => {
        return validateInput({
          overrideReason: { ...checkBenefitsForm.overrideReason, value }
        });
      },
      validations: []
    },
    dateVerifiedOrReverified: {
      value: getDateValueToInitialize(
        mainFormData.benefitChecking?.benefitChecking?.checkings[
          selectedInsuranceKeyId
        ]?.verifiedDate
      ),
      inputValidator: (value) => {
        return validateInput({
          dateVerifiedOrReverified: {
            ...checkBenefitsForm.dateVerifiedOrReverified,
            value
          }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.dateOfBirth_REQUIRED
        },
        {
          type: 'dateRange',
          message: Constants.ErrorMessage.dateOneHundredAndTwentyYearsPast,
          minDate: moment().subtract(120, 'year'),
          maxDate: moment().add(50, 'year')
        }
      ]
    },
    callReferenceNum: {
      value:
        mainFormData.benefitChecking?.benefitChecking?.checkings[
          selectedInsuranceKeyId
        ]?.callReferenceNumber || null,
      inputValidator: (value) => {
        return validateInput({
          callReferenceNum: { ...checkBenefitsForm.callReferenceNum, value }
        });
      },
      validations: [
        {
          type: 'noExtraSymbols',
          message: Constants.ErrorMessage.NO_EXTRA_SYMBOLS
        }
      ]
    },
    representativeName: {
      value:
        mainFormData.benefitChecking?.benefitChecking?.checkings[
          selectedInsuranceKeyId
        ]?.representativeName || null,
      inputValidator: (value) => {
        return validateInput({
          representativeName: {
            ...checkBenefitsForm.representativeName,
            value
          }
        });
      },
      validations: []
    },
    payerPhoneNumber: {
      value:
        mainFormData.benefitChecking?.benefitChecking?.checkings[
          selectedInsuranceKeyId
        ]?.policy?.payerPhoneNumber || null,
      inputValidator: (value) => {
        return validateInput({
          payerPhoneNumber: { ...checkBenefitsForm.payerPhoneNumber, value }
        });
      },
      validations: []
    },
    contactMethod: {
      value:
        mainFormData.benefitChecking?.benefitChecking?.checkings[
          selectedInsuranceKeyId
        ]?.policy?.verificationMethod || null, // "PHONE",
      inputValidator: (value) => {
        return validateInput({
          contactMethod: { ...checkBenefitsForm.contactMethod, value }
        });
      },
      validations: []
    },
    networkStatus: {
      value:
        mainFormData.benefitChecking?.benefitChecking?.checkings[
          selectedInsuranceKeyId
        ]?.policy?.networkStatus || null, // "IN",
      inputValidator: (value) => {
        return validateInput({
          networkStatus: { ...checkBenefitsForm.networkStatus, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        }
      ]
    },
    outOfNetworkBen: {
      value:
        mainFormData.benefitChecking?.benefitChecking?.checkings[
          selectedInsuranceKeyId
        ]?.policy?.outOfNetworkBenefits || false,
      inputValidator: (value) => {
        return validateInput({
          outOfNetworkBen: { ...checkBenefitsForm.outOfNetworkBen, value }
        });
      },
      validations: []
    },
    planType: {
      value:
        mainFormData.benefitChecking?.benefitChecking?.checkings[
          selectedInsuranceKeyId
        ]?.policy?.planType || null, // "MEDICAL",
      inputValidator: (value) => {
        return validateInput({
          planType: { ...checkBenefitsForm.planType, value }
        });
      },
      validations: []
    },
    deductibleType: {
      value:
        mainFormData.benefitChecking?.benefitChecking?.checkings[
          selectedInsuranceKeyId
        ]?.policy?.dedType || null, // "Calendar Year",
      inputValidator: (value) => {
        return validateInput({
          deductibleType: { ...checkBenefitsForm.deductibleType, value }
        });
      },
      validations: []
    },
    effDate: {
      value: getDateValueToInitialize(
        mainFormData.benefitChecking?.benefitChecking?.checkings[
          selectedInsuranceKeyId
        ]?.policy?.effectiveDate
      ),
      inputValidator: (value) => {
        const sSelInsPlanType = selectedInsuranceKeyPlanType;
        let sRetVal = ''; // init to approve values
        let bNeedValidate = false;
        switch (sSelInsPlanType) {
          case 'Primary':
            if (mainFormData.insuranceInfo.primary != null) {
              bNeedValidate = true;
            }
            break;
          case 'Secondary':
            if (mainFormData.insuranceInfo.secondary != null) {
              bNeedValidate = true;
            }
            break;
          case 'Tertiary':
            if (mainFormData.insuranceInfo.tertiary != null) {
              bNeedValidate = true;
            }
            break;
          default:
            break;
        }
        if (bNeedValidate === true) {
          sRetVal = validateInput({
            effDate: { ...checkBenefitsForm.effDate, value }
          });
        }
        return sRetVal;
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        },
        {
          type: 'dateRange',
          message: Constants.ErrorMessage.dateOneHundredAndTwentyYearsPast,
          minDate: moment().subtract(120, 'year'),
          maxDate: moment().add(50, 'year')
        }
      ]
    },
    termDate: {
      value: getDateValueToInitialize(
        mainFormData.benefitChecking?.benefitChecking?.checkings[
          selectedInsuranceKeyId
        ]?.policy?.termDate
      ),
      inputValidator: (value) => {
        return validateInput({
          termDate: { ...checkBenefitsForm.termDate, value }
        });
      },
      validations: [
        {
          type: 'dateRangeNull',
          message: Constants.ErrorMessage.dateOneHundredAndTwentyYearsPast,
          minDate: moment().subtract(120, 'year'),
          maxDate: moment().add(50, 'year')
        }
      ]
    },
    covPerc: {
      value:
        mainFormData.benefitChecking?.benefitChecking?.checkings[
          selectedInsuranceKeyId
        ]?.policy?.coveragePercentage || null, // "100",
      inputValidator: (value) => {
        return validateInput({
          covPerc: { ...checkBenefitsForm.covPerc, value }
        });
      },
      validations: []
    },
    coPay: {
      value:
        mainFormData.benefitChecking?.benefitChecking?.checkings[
          selectedInsuranceKeyId
        ]?.policy?.coPay || null, // "20",
      inputValidator: (value) => {
        return validateInput({ coPay: { ...checkBenefitsForm.coPay, value } });
      },
      validations: []
    },

    deductibleNotes: {
      value:
        mainFormData.benefitChecking?.benefitChecking?.checkings[
          selectedInsuranceKeyId
        ]?.policy?.deductibleNotes || null, // "20",
      inputValidator: (value) => {
        return validateInput({
          deductibleNotes: { ...checkBenefitsForm.deductibleNotes, value }
        });
      },
      validations: []
    },
    dedInfoDedType: {
      value: mainFormData.benefitChecking?.benefitChecking?.checkings[
        selectedInsuranceKeyId
      ]?.policy?.deductibleInfo
        ? mainFormData.benefitChecking?.benefitChecking?.checkings[
            selectedInsuranceKeyId
          ]?.policy?.deductibleInfo[0]?.deductibleType
        : '', // "SINGLE, DOUBLE, FAMILY",
      inputValidator: (value) => {
        return validateInput({
          dedInfoDedType: { ...checkBenefitsForm.dedInfoDedType, value }
        });
      },
      validations: []
    },
    dedInfoDedType2: {
      value: mainFormData.benefitChecking?.benefitChecking?.checkings[
        selectedInsuranceKeyId
      ]?.policy?.deductibleInfo[1]
        ? mainFormData.benefitChecking?.benefitChecking?.checkings[
            selectedInsuranceKeyId
          ]?.policy?.deductibleInfo[1]?.deductibleType
        : '', // "SINGLE, DOUBLE, FAMILY",
      inputValidator: (value) => {
        return validateInput({
          dedInfoDedType2: { ...checkBenefitsForm.dedInfoDedType2, value }
        });
      },
      validations: []
    },
    dedInfoNetworkStatus: {
      value: mainFormData.benefitChecking?.benefitChecking?.checkings[
        selectedInsuranceKeyId
      ]?.policy?.deductibleInfo
        ? mainFormData.benefitChecking?.benefitChecking?.checkings[
            selectedInsuranceKeyId
          ]?.policy?.deductibleInfo[0]?.networkStatus
        : '', // "IN, OUT",
      inputValidator: (value) => {
        return validateInput({
          dedInfoNetworkStatus: {
            ...checkBenefitsForm.dedInfoNetworkStatus,
            value
          }
        });
      },
      validations: []
    },
    dedInfoNetworkStatus2: {
      value: mainFormData.benefitChecking?.benefitChecking?.checkings[
        selectedInsuranceKeyId
      ]?.policy?.deductibleInfo[1]
        ? mainFormData.benefitChecking?.benefitChecking?.checkings[
            selectedInsuranceKeyId
          ]?.policy?.deductibleInfo[1]?.networkStatus
        : null, // "IN, OUT",
      inputValidator: (value) => {
        return validateInput({
          dedInfoNetworkStatus2: {
            ...checkBenefitsForm.dedInfoNetworkStatus2,
            value
          }
        });
      },
      validations: []
    },
    dedInfoTotalDeductibleAmount: {
      value: mainFormData.benefitChecking?.benefitChecking?.checkings[
        selectedInsuranceKeyId
      ]?.policy?.deductibleInfo
        ? mainFormData.benefitChecking?.benefitChecking?.checkings[
            selectedInsuranceKeyId
          ]?.policy?.deductibleInfo[0]?.totalDeductibleAmount
        : null,
      inputValidator: (value) => {
        return validateInput({
          dedInfoTotalDeductibleAmount: {
            ...checkBenefitsForm.dedInfoTotalDeductibleAmount,
            value
          }
        });
      },
      validations: []
    },
    dedInfoTotalDeductibleAmount2: {
      value: mainFormData.benefitChecking?.benefitChecking?.checkings[
        selectedInsuranceKeyId
      ]?.policy?.deductibleInfo[1]
        ? mainFormData.benefitChecking?.benefitChecking?.checkings[
            selectedInsuranceKeyId
          ]?.policy?.deductibleInfo[1]?.totalDeductibleAmount
        : '',
      inputValidator: (value) => {
        return validateInput({
          dedInfoTotalDeductibleAmount2: {
            ...checkBenefitsForm.dedInfoTotalDeductibleAmount2,
            value
          }
        });
      },
      validations: []
    },
    dedInfoMetDeductibleAmount: {
      value: mainFormData.benefitChecking?.benefitChecking?.checkings[
        selectedInsuranceKeyId
      ]?.policy?.deductibleInfo
        ? mainFormData.benefitChecking?.benefitChecking?.checkings[
            selectedInsuranceKeyId
          ]?.policy?.deductibleInfo[0]?.metDeductible.amount
        : '',
      inputValidator: (value) => {
        return validateInput({
          dedInfoMetDeductibleAmount: {
            ...checkBenefitsForm.dedInfoMetDeductibleAmount,
            value
          }
        });
      },
      validations: []
    },
    dedInfoMetDeductibleAmount2: {
      value: mainFormData.benefitChecking?.benefitChecking?.checkings[
        selectedInsuranceKeyId
      ]?.policy?.deductibleInfo[1]
        ? mainFormData.benefitChecking?.benefitChecking?.checkings[
            selectedInsuranceKeyId
          ]?.policy?.deductibleInfo[1]?.metDeductible.amount
        : '',
      inputValidator: (value) => {
        return validateInput({
          dedInfoMetDeductibleAmount2: {
            ...checkBenefitsForm.dedInfoMetDeductibleAmount2,
            value
          }
        });
      },
      validations: []
    },
    dedInfoMetDeductibleResetDate: {
      value: getDateValueToInitialize(
        mainFormData.benefitChecking?.benefitChecking?.checkings[
          selectedInsuranceKeyId
        ]?.policy?.deductibleInfo[0]?.metDeductible?.resetDate
      ),
      inputValidator: (value) => {
        return validateInput({
          dedInfoMetDeductibleResetDate: {
            ...checkBenefitsForm.dedInfoMetDeductibleResetDate,
            value
          }
        });
      },
      validations: []
    },
    dedInfoMetDeductibleResetDate2: {
      value: getDateValueToInitialize(
        mainFormData.benefitChecking?.benefitChecking?.checkings[
          selectedInsuranceKeyId
        ]?.policy?.deductibleInfo[1]?.metDeductible?.resetDate
      ),
      inputValidator: (value) => {
        return validateInput({
          dedInfoMetDeductibleResetDate2: {
            ...checkBenefitsForm.dedInfoMetDeductibleResetDate2,
            value
          }
        });
      },
      validations: []
    },

    oopMaxDedType: {
      value:
        mainFormData.benefitChecking?.benefitChecking?.checkings[
          selectedInsuranceKeyId
        ]?.policy?.oopMax[0]?.deductibleType, // "SINGLE, FAMILY",
      inputValidator: (value) => {
        return validateInput({
          oopMaxDedType: { ...checkBenefitsForm.oopMaxDedType, value }
        });
      },
      validations: []
    },
    oopMaxDedType2: {
      value:
        mainFormData.benefitChecking?.benefitChecking?.checkings[
          selectedInsuranceKeyId
        ]?.policy?.oopMax[1]?.deductibleType, // "SINGLE, FAMILY",
      inputValidator: (value) => {
        return validateInput({
          oopMaxDedType2: { ...checkBenefitsForm.oopMaxDedType2, value }
        });
      },
      validations: []
    },
    oopMaxNetworkStatus: {
      value:
        mainFormData.benefitChecking?.benefitChecking?.checkings[
          selectedInsuranceKeyId
        ]?.policy?.oopMax[0]?.networkStatus, // "IN, OUT",
      inputValidator: (value) => {
        return validateInput({
          oopMaxNetworkStatus: {
            ...checkBenefitsForm.oopMaxNetworkStatus,
            value
          }
        });
      },
      validations: []
    },
    oopMaxNetworkStatus2: {
      value:
        mainFormData.benefitChecking?.benefitChecking?.checkings[
          selectedInsuranceKeyId
        ]?.policy?.oopMax[1]?.networkStatus, // "IN, OUT",
      inputValidator: (value) => {
        return validateInput({
          oopMaxNetworkStatus2: {
            ...checkBenefitsForm.oopMaxNetworkStatus2,
            value
          }
        });
      },
      validations: []
    },
    oopMaxTotalOOPAmount: {
      value:
        mainFormData.benefitChecking?.benefitChecking?.checkings[
          selectedInsuranceKeyId
        ]?.policy?.oopMax[0]?.totalOOPAmount,
      inputValidator: (value) => {
        return validateInput({
          oopMaxTotalOOPAmount: {
            ...checkBenefitsForm.oopMaxTotalOOPAmount,
            value
          }
        });
      },
      validations: []
    },
    oopMaxTotalOOPAmount2: {
      value:
        mainFormData.benefitChecking?.benefitChecking?.checkings[
          selectedInsuranceKeyId
        ]?.policy?.oopMax[1]?.totalOOPAmount,
      inputValidator: (value) => {
        return validateInput({
          oopMaxTotalOOPAmount2: {
            ...checkBenefitsForm.oopMaxTotalOOPAmount2,
            value
          }
        });
      },
      validations: []
    },
    oopMaxMetOOPAmount: {
      value:
        mainFormData.benefitChecking?.benefitChecking?.checkings[
          selectedInsuranceKeyId
        ]?.policy?.oopMax[0]?.metOOP.amount,
      inputValidator: (value) => {
        return validateInput({
          oopMaxMetOOPAmount: {
            ...checkBenefitsForm.oopMaxMetOOPAmount,
            value
          }
        });
      },
      validations: []
    },
    oopMaxMetOOPAmount2: {
      value:
        mainFormData.benefitChecking?.benefitChecking?.checkings[
          selectedInsuranceKeyId
        ]?.policy?.oopMax[1]?.metOOP.amount,
      inputValidator: (value) => {
        return validateInput({
          oopMaxMetOOPAmount2: {
            ...checkBenefitsForm.oopMaxMetOOPAmount2,
            value
          }
        });
      },
      validations: []
    },
    oopMaxMetOOPResetDate: {
      value: getDateValueToInitialize(
        mainFormData?.benefitChecking?.benefitChecking?.checkings[
          selectedInsuranceKeyId
        ]?.policy?.oopMax[0]?.metOOP?.resetDate
      ),
      inputValidator: (value) => {
        return validateInput({
          oopMaxMetOOPResetDate: {
            ...checkBenefitsForm.oopMaxMetOOPResetDate,
            value
          }
        });
      },
      validations: []
    },
    oopMaxMetOOPResetDate2: {
      value: getDateValueToInitialize(
        mainFormData.benefitChecking?.benefitChecking?.checkings[
          selectedInsuranceKeyId
        ]?.policy?.oopMax[1]?.metOOP?.resetDate
      ),
      inputValidator: (value) => {
        return validateInput({
          oopMaxMetOOPResetDate2: {
            ...checkBenefitsForm.oopMaxMetOOPResetDate2,
            value
          }
        });
      },
      validations: []
    },

    isPriorAuthReq: {
      value:
        !!mainFormData.benefitChecking?.benefitChecking?.checkings[
          selectedInsuranceKeyId
        ]?.paRequired,
      inputValidator: (value) => {
        return validateInput({
          isPriorAuthReq: { ...checkBenefitsForm.isPriorAuthReq, value }
        });
      },
      validations: []
    },
    priorAuthSubmitMethod: {
      value:
        mainFormData.benefitChecking?.benefitChecking?.checkings[
          selectedInsuranceKeyId
        ]?.priorAuthorization.submitMethod, // "PHONE",
      inputValidator: (value) => {
        return validateInput({
          priorAuthSubmitMethod: {
            ...checkBenefitsForm.priorAuthSubmitMethod,
            value
          }
        });
      },
      validations: []
    },
    predeterminationNeededOption: {
      value:
        !!mainFormData.benefitChecking?.benefitChecking?.checkings[
          selectedInsuranceKeyId
        ]?.predeterminationNeeded,
      inputValidator: (value) => {
        return validateInput({
          predeterminationNeededOption: {
            ...checkBenefitsForm.predeterminationNeeded,
            value
          }
        });
      },
      validations: []
    },
    predeterminationSubmitMethod: {
      value:
        mainFormData.benefitChecking?.benefitChecking?.checkings[
          selectedInsuranceKeyId
        ]?.predetermination.submitMethod, // "PHONE",
      inputValidator: (value) => {
        return validateInput({
          priorAuthSubmitMethod: {
            ...checkBenefitsForm.predeterminationSubmitMethod,
            value
          }
        });
      },
      validations: []
    },
    priorAuthPhone: {
      value:
        mainFormData.benefitChecking?.benefitChecking?.checkings[
          selectedInsuranceKeyId
        ]?.priorAuthorization.paPhone, // "+14158889977",
      inputValidator: (value) => {
        return validateInput({
          priorAuthPhone: { ...checkBenefitsForm.priorAuthPhone, value }
        });
      },
      validations: []
    },
    portalLink: {
      value:
        mainFormData.benefitChecking?.benefitChecking?.checkings[
          selectedInsuranceKeyId
        ]?.priorAuthorization.paSite, // "https://optml.com",
      inputValidator: (value) => {
        return validateInput({
          portalLink: { ...checkBenefitsForm.portalLink, value }
        });
      },
      validations: []
    },
    jCode1: {
      value:
        mainFormData.benefitChecking?.benefitChecking?.checkings[
          selectedInsuranceKeyId
        ]?.priorAuthorization.jCode[0], // "777",
      inputValidator: (value) => {
        return validateInput({
          jCode1: { ...checkBenefitsForm.jCode1, value }
        });
      },
      validations: []
    },
    jCode2: {
      value:
        mainFormData.benefitChecking?.benefitChecking?.checkings[
          selectedInsuranceKeyId
        ]?.priorAuthorization.jCode[1], // "888",
      inputValidator: (value) => {
        return validateInput({
          jCode2: { ...checkBenefitsForm.jCode2, value }
        });
      },
      validations: []
    },
    jCode3: {
      value:
        mainFormData.benefitChecking?.benefitChecking?.checkings[
          selectedInsuranceKeyId
        ]?.priorAuthorization.jCode[2], // "999",
      inputValidator: (value) => {
        return validateInput({
          jCode3: { ...checkBenefitsForm.jCode3, value }
        });
      },
      validations: []
    },
    adminCode1: {
      value:
        mainFormData.benefitChecking?.benefitChecking?.checkings[
          selectedInsuranceKeyId
        ]?.priorAuthorization.adminCode1, // "777",
      inputValidator: (value) => {
        return validateInput({
          adminCode1: { ...checkBenefitsForm.adminCode1, value }
        });
      },
      validations: []
    },
    adminCode2: {
      value:
        mainFormData.benefitChecking?.benefitChecking?.checkings[
          selectedInsuranceKeyId
        ]?.priorAuthorization.adminCode2, // "888",
      inputValidator: (value) => {
        return validateInput({
          adminCode2: { ...checkBenefitsForm.adminCode2, value }
        });
      },
      validations: []
    },
    adminCode3: {
      value:
        mainFormData.benefitChecking?.benefitChecking?.checkings[
          selectedInsuranceKeyId
        ]?.priorAuthorization.adminCode3, // "999",
      inputValidator: (value) => {
        return validateInput({
          adminCode3: { ...checkBenefitsForm.adminCode3, value }
        });
      },
      validations: []
    },

    claimsAddr: {
      value:
        mainFormData.benefitChecking?.benefitChecking?.checkings[
          selectedInsuranceKeyId
        ]?.claims.claimAddress.streetName, // "777 Web Lane",
      inputValidator: (value) => {
        return validateInput({
          claimsAddr: { ...checkBenefitsForm.claimsAddr, value }
        });
      },
      validations: []
    },
    claimsAddr2: {
      value: '', // "SUITE 5000",
      inputValidator: (value) => {
        return validateInput({
          claimsAddr2: { ...checkBenefitsForm.claimsAddr2, value }
        });
      },
      validations: []
    },
    claimsCity: {
      value:
        mainFormData.benefitChecking?.benefitChecking?.checkings[
          selectedInsuranceKeyId
        ]?.claims.claimAddress.city, // "Phoenix",
      inputValidator: (value) => {
        return validateInput({
          claimsCity: { ...checkBenefitsForm.claimsCity, value }
        });
      },
      validations: []
    },
    claimsState: {
      // value: mainFormData.benefitChecking?.benefitChecking?.checkings[selectedInsuranceKeyId]?.claims.claimAddress.state, //"Arizona",
      // search both abbreviation AND name
      value:
        states.find(
          (st) =>
            st.abbreviation ===
              mainFormData.benefitChecking?.benefitChecking?.checkings[
                selectedInsuranceKeyId
              ]?.claims.claimAddress.state ||
            st.name ===
              mainFormData.benefitChecking?.benefitChecking?.checkings[
                selectedInsuranceKeyId
              ]?.claims.claimAddress.state
        ) || null,
      inputValidator: (value) => {
        return validateInput({
          claimsState: { ...checkBenefitsForm.claimsState, value }
        });
      },
      validations: []
    },
    claimsZip: {
      value:
        mainFormData.benefitChecking?.benefitChecking?.checkings[
          selectedInsuranceKeyId
        ]?.claims.claimAddress.zip, // "55555",
      inputValidator: (value) => {
        return validateInput({
          claimsZip: { ...checkBenefitsForm.claimsZip, value }
        });
      },
      validations: []
    },
    timelyFiling: {
      value:
        mainFormData.benefitChecking?.benefitChecking?.checkings[
          selectedInsuranceKeyId
        ]?.claims.timelyFilling || null,
      inputValidator: (value) => {
        return validateInput({
          timelyFiling: { ...checkBenefitsForm.timelyFiling, value }
        });
      },
      validations: [
        {
          type: 'onlyNumeric',
          message: Constants.ErrorMessage.Numeric_Required
        }
      ]
    },
    displayResults: {
      value:
        mainFormData.benefitChecking?.benefitChecking?.checkings[
          selectedInsuranceKeyId
        ]?.claims.results, // "yes please",
      inputValidator: (value) => {
        return validateInput({
          displayResults: { ...checkBenefitsForm.displayResults, value }
        });
      },
      validations: []
    },
    atRiskClaims: {
      value:
        mainFormData.benefitChecking?.benefitChecking?.checkings[
          selectedInsuranceKeyId
        ]?.claims.riskForClaims,
      inputValidator: (value) => {
        return validateInput({
          atRiskClaims: { ...checkBenefitsForm.atRiskClaims, value }
        });
      },
      validations: []
    },
    LOALetterOfAgreement: {
      value:
        mainFormData.benefitChecking?.benefitChecking?.checkings[
          selectedInsuranceKeyId
        ]?.policy?.loa
    },
    welcomeCallAttempt1: {
      value:
        mainFormData.benefitChecking?.benefitChecking?.welcomeCalls[0]
          ?.answered || false,
      inputValidator: (value) => {
        return validateInput({
          welcomeCallAttempt1: {
            ...checkBenefitsForm.welcomeCallAttempt1,
            value
          }
        });
      },
      validations: []
    },
    welcomeCallAttempt2: {
      value:
        mainFormData.benefitChecking?.benefitChecking?.welcomeCalls[1]
          ?.answered || false,
      inputValidator: (value) => {
        return validateInput({
          welcomeCallAttempt2: {
            ...checkBenefitsForm.welcomeCallAttempt2,
            value
          }
        });
      },
      validations: []
    },
    welcomeCallAttempt3: {
      value:
        mainFormData.benefitChecking?.benefitChecking?.welcomeCalls[2]
          ?.answered || false,
      inputValidator: (value) => {
        return validateInput({
          welcomeCallAttempt3: {
            ...checkBenefitsForm.welcomeCallAttempt3,
            value
          }
        });
      },
      validations: []
    },
    welcomeCallCompleted: {
      value:
        mainFormData.benefitChecking?.benefitChecking?.welcomeCallCompleted ||
        false, // true,
      inputValidator: (value) => {
        return validateInput({
          welcomeCallCompleted: {
            ...checkBenefitsForm.welcomeCallCompleted,
            value
          }
        });
      },
      validations: []
    },
    isBICompleted: {
      value:
        mainFormData.benefitChecking?.benefitChecking?.checkings[
          selectedInsuranceKeyId
        ]?.isCompleted || false, // true,
      inputValidator: (value) => {
        return validateInput({
          isBICompleted: { ...checkBenefitsForm.isBICompleted, value }
        });
      },
      validations: []
    }
  };

  const initialForm = () => {
    const initialObject = {};
    Object.keys(checkBenefitsForm).forEach((key) => {
      initialObject[key] = checkBenefitsForm[key].value;
    });
    return initialObject;
  };
  const handleLoadOrder = (dataObject) => {
    // Will always be index of 0.
    setSubmittedInsurance(
      dataObject?.checkings
        ? dataObject?.checkings[0]?.insuranceKey.toLowerCase()
        : 'Primary'
    );
    const selectedData = {
      referral: dataObject,
      insuranceKey: dataObject?.checkings
        ? dataObject?.checkings[0]?.insuranceKey
        : 'Primary'
    };
    handleGetBenefitCheckingSubmit(selectedData);
  };

  const handleSelect = (e) => {
    handleSelectedInsuranceKeyChange('Primary');
    setSelectedInsuranceKeyPlanType('Primary');
    setSelectedInsuranceKeyId(0);
    setMainFormData({});

    const idx = Number(e.selected);
    const order = listReferralOrdersData[idx];
    if (!tabData[idx]?.checkings) {
      setListLocationAICsData([]);
      setListProviderAICsData([]);
      setSeclectedProviderInfo();
      setSelectedLocationInfo();
    }
    if (listReferralOrdersData[idx]?.checkings) {
      const checkings = listReferralOrdersData[idx]?.checkings?.sort((a, b) => {
        const textA = a.insuranceKey.toUpperCase();
        const textB = b.insuranceKey.toUpperCase();
        return textA.localeCompare(textB);
      });
      setLoading(true);
      setDropLoading(true);
      setSelected(idx);
      handleLoadOrder(order);
      setListInsuranceKey(checkings);
      setInsuranceReferral(order);
    } else {
      setLoading(true);
      setDropLoading(true);
      setSelected(idx);
      handleLoadOrder(order);
      setListInsuranceKey([{ insuranceKey: 'Primary' }]);
      setInsuranceReferral(order);
    }
  };

  const renderInsuranceLinks = () => {
    return listInsuranceKey.map((item) => (
      <span
        className='help-link ml-4 mr-4'
        key={item.selectedGroup}
        onClick={() => handleLoadInsuranceOrder(item)}
      >
        <AwesomeLabel icon='clipboard-medical' label={item.insuranceKey} />
      </span>
    ));
  };

  // MAIN INITIATOR
  useEffect(() => {
    listReferralOrdersCall(selectedPatientInfo.patientId);
    listInsuranceInfoCall(selectedPatientInfo.patientId);
    listGroupAICsCall(selectedPatientInfo.patientId);
  }, [statusUpdate]);

  useEffect(() => {
    if (
      tabData.length !== 0 &&
      tabData[0]?.checkings &&
      listReferralOrdersData[0] &&
      listInsuranceInfoData
    ) {
      const keyData = tabData?.[selected]?.checkings.sort((a, b) => {
        const textA = a.insuranceKey.toUpperCase();
        const textB = b.insuranceKey.toUpperCase();
        return textA.localeCompare(textB);
      });
      setDropLoading(true);
      setSelectedInsuranceKeyPlanType(keyData[0].insuranceKey);
      setSubmittedInsurance(keyData[0].insuranceKey.toLowerCase());
      setInsuranceReferral(listReferralOrdersData[selected]);
      setListInsuranceKey(keyData);
      handleLoadInsuranceOrder({
        insuranceKey: 'Primary',
        dataItem: listReferralOrdersData[selected]
      });
    } else if (listReferralOrdersData[0]) {
      const startData = {
        referral: listReferralOrdersData[0],
        insuranceKey: 'Primary'
      };
      setDropLoading(true);
      setSelectedInsuranceKeyPlanType('Primary');
      setSubmittedInsurance('primary');
      setInsuranceReferral(listReferralOrdersData[0]);
      setListInsuranceKey([{ insuranceKey: 'Primary' }]);
      handleGetBenefitCheckingSubmit(startData);
    }
  }, [listReferralOrdersData]);

  useEffect(() => {
    if (listInsuranceKey?.[0]?.insuranceKey !== 'Primary') {
      setAdminStop(true);
      setInsuranceDisabled(true);
    } else {
      setAdminStop(false);
    }
  }, [listInsuranceKey]);

  const handleLoadInsuranceOrder = (dataItem) => {
    setInfoLoading(true);
    setButtonLoading(false);
    handleSelectedInsuranceKeyChange(dataItem?.insuranceKey);
    setSubmittedInsurance(dataItem?.insuranceKey.toLowerCase());
    const requestObject = {
      dataItem: {
        referral: insuranceReferral || dataItem?.dataItem
      },
      insuranceKey: dataItem?.insuranceKey,
      referralId: insuranceReferral
        ? insuranceReferral?.referralId
        : dataItem?.dataItem?.referralId,
      patientId: selectedPatientInfo?.patientId
    };
    setShowMainForm(false);
    getBenefitCheckingCall(requestObject);
  };

  const listReferralOrdersCall = async (requestObject) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getPatientReferralOrders,
        variables: { patientId: requestObject }
      });

      if (
        data &&
        data.data &&
        data.data.getPatientBucket &&
        data.data.getPatientBucket.referral &&
        data.data.getPatientBucket.referral.drugReferrals &&
        data.data.getPatientBucket.referral.drugReferrals.length
      ) {
        const drugReferralsData = [
          ...data?.data?.getPatientBucket?.referral?.drugReferrals
        ];
        if (
          data?.data?.getPatientBucket?.benefitInvestigation?.benefitCheckings
        ) {
          data.data.getPatientBucket.benefitInvestigation.benefitCheckings.forEach(
            (itemBI) => {
              const refIndex = drugReferralsData.findIndex(
                (referral) => referral.referralId === itemBI.referralId
              );
              if (refIndex > -1) {
                drugReferralsData[refIndex] = {
                  ...drugReferralsData[refIndex],
                  ...itemBI
                };
              }
            }
          );
        }
        setTabData(drugReferralsData);
        setListReferralOrdersData(drugReferralsData);
      }
    } catch (err) {
      console.log('CheckBenefits::getPatientReferralOrders data err:', err);
      showError(`BI: Check Benefits Error ${err}`);
    }
  };

  const listInsuranceInfoCall = async (requestObject) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getPatientInsuranceInfo,
        variables: { patientId: requestObject }
      });

      if (
        data &&
        data.data &&
        data.data.getPatientBucket &&
        data.data.getPatientBucket.patientProfile &&
        data.data.getPatientBucket.patientProfile.insuranceInfo // &&
      ) {
        setListInsuranceInfoData(
          data.data.getPatientBucket.patientProfile.insuranceInfo
        );
      }
    } catch (err) {
      console.log('CheckBenefits::getPatientInsuranceInfo data err:', err);
      showError(`BI: Check Benefits Error ${err?.errors[0]?.errorType}`);
    }
  };

  const listGroupAICsCall = async () => {
    try {
      const data = await connectToGraphqlAPI({ graphqlQuery: listGroupAICs });
      if (
        data &&
        data.data &&
        data.data.listGroupAICs &&
        data.data.listGroupAICs.items
      ) {
        const aicGroups = data.data.listGroupAICs.items
          .map((item) => ({
            ...item,
            text: `${item.name} (Tax ID: ${item.taxId})`,
            value: item.id
          }))
          .sort((a, b) => (a.name > b.name ? 1 : -1));

        setListGroupAICsData(aicGroups);
      }
    } catch (err) {
      console.log('CheckBenefits::listGroupAICsCall data err:', err);
      showError(`BI: Check Benefits Error ${err?.errors[0]?.errorType}`);
    }
  };

  useEffect(() => {
    setSelectedBillingInfoLocation();
  }, [listLocationAICsData]);

  const listLocationsAndProvidersCall = async (
    groupId,
    locationId,
    providerId
  ) => {
    try {
      if (!groupId) {
        setListLocationAICsData([]);
        return;
      }
      const insurerId = mainFormData?.benefitChecking?.insuranceInfo?.insurerId
        ? mainFormData?.benefitChecking?.insuranceInfo?.insurerId
        : listInsuranceInfoData?.primary?.insurerId || '';
      const data = await connectToGraphqlAPI({
        graphqlQuery: getLocationsAndProvidersByGroupId,
        variables: { groupId, insurerId }
      });

      if (
        data &&
        data.data &&
        data.data.getLocationsAndProvidersByGroupId &&
        data.data.getLocationsAndProvidersByGroupId.length
      ) {
        const groupLocations = data.data.getLocationsAndProvidersByGroupId
          .map((item) => ({
            ...item,
            text: `${item.locationName}, ${item.state} (NPI: ${item.locationNPI})`,
            value: item.id
          }))
          .sort((a, b) => (a.locationName > b.locationName ? 1 : -1));

        setListLocationAICsData(groupLocations);
        setDropLoading(false);
        let selectedLocation;
        // prepopulate selected Location for existing entries
        if (locationId) {
          setSelectedBillingInfoGroup(
            listGroupAICsData.find((group) => group.id === groupId)
          );
          selectedLocation = groupLocations.find(
            (location) => location.id === locationId
          );
          if (selectedLocation) {
            setSelectedBillingInfoLocation(selectedLocation);
          }
          setCertified(selectedLocation?.insured === true);
        }

        // prepopulate providers list for existing entries
        if (providerId && selectedLocation) {
          const providers = mapProviders(selectedLocation.providers);
          setListProviderAICsData(providers);
          const providerSelected = providers.find(
            (provider) => provider.providerNPI === providerId
          );
          if (providerSelected) {
            setSelectedProvider(providerSelected);
          }
        }
      } else {
        setListLocationAICsData([]);
      }
    } catch (err) {
      console.log(
        'CheckBenefits::listLocationsAndProvidersCall data err:',
        err
      );
      showError(
        `BI: Check Benefits Error ${err?.errors?.[selected]?.errorType}`
      );
    }
  };

  const handleBillingInfoGroupChange = (event) => {
    setSelectedBillingInfoGroup(event.value);
    setSelectedGroupId(event.value.id);

    // clear up related children
    setSelectedBillingInfoLocation(null);
    setSelectedLocationId(null);
    setSelectedProvider(null);
    setSelectedProviderId(null);

    // get list of locations and providers for selected group
    listLocationsAndProvidersCall(event.value.id);
  };

  const mapProviders = (providers) => {
    if (providers && providers.length) {
      return providers
        .map((provider) => ({
          ...provider,
          text: `${provider.firstName} ${provider.lastName}, ${provider.type} (NPI: ${provider.providerNPI})`,
          value: provider.providerNPI
        }))
        .sort((a, b) => (a.firstName > b.firstName ? 1 : -1));
    }
  };

  const handleBillingInfoLocationChange = (event) => {
    setSelectedBillingInfoLocation(event.value);
    setSelectedLocationId(event.value.id);
    setSelectedProviderId(null);

    const { providers } = event.value;

    if (!providers || !providers.length) {
      setListProviderAICsData(null);
      // eslint-disable-next-line no-alert
      alert('No providers found per selected location.');
    } else {
      setListProviderAICsData(mapProviders(providers));
    }
  };

  const handleProviderChange = (event) => {
    setSelectedProvider(event.value);
    setSelectedProviderId(event.value.value);
  };

  const handleGetBenefitCheckingSubmit = (dataItem) => {
    setLoading(true);

    const requestObject = {
      dataItem,
      insuranceKey: dataItem.insuranceKey,
      referralId: dataItem.referral.referralId,
      patientId: selectedPatientInfo.patientId
    };
    setShowMainForm(false);
    getBenefitCheckingCall(requestObject);
  };

  const handleSelectedInsuranceKeyChange = (dataItem) => {
    if (dataItem && dataItem === 'Primary') {
      setSelectedInsuranceKeyId(0);
      setInsuranceDisabled(false);
    } else if (dataItem && dataItem === 'Secondary') {
      setSelectedInsuranceKeyId(1);
      setInsuranceDisabled(true);
    } else if (dataItem && dataItem === 'Tertiary') {
      setSelectedInsuranceKeyId(2);
      setInsuranceDisabled(true);
    }
    setSelectedInsuranceKeyPlanType(dataItem);
  };

  const getBenefitCheckingCall = async (requestObject) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getBenefitChecking,
        variables: requestObject
      });

      if (data && data.data && data.data.getBenefitChecking) {
        // setSubmittedInsurance(selectedInsuranceKeyPlanType.toLowerCase());

        let overrideChoice = '';
        if (
          data.data.getBenefitChecking?.benefitChecking?.checkings[
            selectedInsuranceKeyId
          ]?.selectedBillingNPI ===
          data.data.getBenefitChecking?.benefitChecking?.checkings[
            selectedInsuranceKeyId
          ]?.selectedProviderId
        ) {
          overrideChoice = 'PROVIDER';
        } else {
          overrideChoice = 'LOCATION';
        }

        setMainFormData({
          selectedOrder: requestObject.dataItem.referral,
          insuranceInfo: listInsuranceInfoData,
          benefitChecking: data.data.getBenefitChecking,
          overrideChoice,
          canComplete: requestObject.dataItem.referral.clinical
            ? requestObject.dataItem.referral.clinical.orderApproved
            : false
        });

        setSelectedOutOfNetworkBen(
          data.data.getBenefitChecking.benefitChecking?.checkings[
            selectedInsuranceKeyId
          ]?.policy?.outOfNetworkBenefits
        );

        setSelectedNetworkStatuses(
          data.data.getBenefitChecking?.benefitChecking?.checkings[
            selectedInsuranceKeyId
          ]?.policy?.networkStatus
        );

        if (
          data?.data?.getBenefitChecking?.benefitChecking?.checkings[
            selectedInsuranceKeyId
          ]
        ) {
          const {
            selectedGroupId: groupId,
            selectedLocationId: locationId,
            selectedProviderId: providerId,
            selectedLocation: location,
            selectedProvider: provider
          } = data.data.getBenefitChecking.benefitChecking.checkings[0];

          setSelectedGroupId(groupId);
          setSelectedLocationId(locationId);
          setSelectedProviderId(providerId);
          setSeclectedProviderInfo(JSON.parse(provider));
          setSelectedLocationInfo(JSON.parse(location));

          // fetch locations based on group id
          listLocationsAndProvidersCall(groupId, locationId, providerId);
          setPriorAuthReq(
            data.data.getBenefitChecking.benefitChecking.checkings[
              selectedInsuranceKeyId
            ].paRequired
          );
          setPredeterminationNeededSelected(
            data.data.getBenefitChecking.benefitChecking.checkings[
              selectedInsuranceKeyId
            ].predeterminationNeeded
          );
        } else {
          setDropLoading(false);
          setPriorAuthReq(undefined);
        }
        setShowMainForm(true);
        setLoading(false);
        setButtonLoading(false);
        setInfoLoading(false);
      }
    } catch (err) {
      console.log('CheckBenefits::getBenefitCheckingCall err:', err);
      showError(
        `Error getBenefitCheckingCall. Please check to see if the patient has insurance of type ${selectedInsuranceKeyPlanType}!`
      );
    }
  };

  const handleSubmit = (dataItem) => {
    setButtonLoading(true);
    const callRefNumber = cleanseString(dataItem.callReferenceNum);
    const requestObject = {
      agentId: user.username,
      patientId: selectedPatientInfo.patientId,
      benefitCheckingByReferral: {
        referralId: mainFormData.selectedOrder.referralId,
        checking: {
          insuranceKey: selectedInsuranceKeyPlanType,
          groupId: mainFormData.insuranceInfo[submittedInsurance]?.groupId, // dataItem.billingInfoGroup.taxId??,
          billingTaxId: !selectedBillingInfoGroup
            ? null
            : selectedBillingInfoGroup.taxId,
          billingNPINumber:
            dataItem.billingResultsNPIChoice === 'LOCATION'
              ? selectedBillingInfoLocation &&
                selectedBillingInfoLocation.locationNPI
                ? selectedBillingInfoLocation.locationNPI
                : null
              : dataItem.billingResultsNPIChoice === 'PROVIDER'
              ? selectedProvider && selectedProvider.providerNPI
                ? selectedProvider.providerNPI
                : null
              : null,
          selectedGroupId,
          selectedLocationId,
          selectedProviderId,
          selectedBillingTaxId: !selectedBillingInfoGroup
            ? null
            : selectedBillingInfoGroup.taxId, // dataItem.billingInfoGroup?.taxId,
          selectedBillingNPI:
            dataItem.billingResultsNPIChoice === 'LOCATION'
              ? selectedBillingInfoLocation &&
                selectedBillingInfoLocation.locationNPI
                ? selectedBillingInfoLocation.locationNPI
                : null
              : dataItem.billingResultsNPIChoice === 'PROVIDER'
              ? selectedProvider && selectedProvider.providerNPI
                ? selectedProvider.providerNPI
                : null
              : null,
          billingTaxIdForOutOfNetwork: dataItem.overrideTaxId,
          billingNPIForOutOfNetwork: dataItem.overrideNPI,
          billingOverrideReason: dataItem.overrideReason,
          verifiedDate: getDateString(dataItem.dateVerifiedOrReverified),
          callReferenceNumber: callRefNumber,
          representativeName: dataItem.representativeName,
          policyId:
            mainFormData.insuranceInfo[submittedInsurance]?.policyId || null
              ? ''
              : mainFormData.insuranceInfo[submittedInsurance]?.policyId,
          policy: {
            planType: dataItem.planType, // "MEDICAL, PHARMACY",
            dedType: dataItem.deductibleType, // "Calendar Year", "Plan Year"
            effectiveDate: getDateString(dataItem.effDate),
            termDate: getDateString(dataItem.termDate),
            coPay: dataItem.coPay,
            loa: dataItem.LOALetterOfAgreement,
            coveragePercentage: dataItem.covPerc,
            deductibleInfo: [
              {
                deductibleType: getDropdownValue(dataItem?.dedInfoDedType), // "FAMILY, SINGLE",
                networkStatus: dataItem?.dedInfoNetworkStatus?.length
                  ? dataItem.dedInfoNetworkStatus
                  : null, // "IN, OUT",
                totalDeductibleAmount:
                  dataItem.dedInfoTotalDeductibleAmount || null,
                metDeductible: {
                  amount: dataItem.dedInfoMetDeductibleAmount || null,
                  resetDate: getDateString(
                    dataItem.dedInfoMetDeductibleResetDate
                  )
                },
                totalOOPAmount: null,
                metOOP: {
                  // amount: Float
                  amount: null,
                  resetDate: null
                }
              },
              {
                deductibleType: getDropdownValue(dataItem?.dedInfoDedType2), // "FAMILY, SINGLE",
                networkStatus: dataItem?.dedInfoNetworkStatus2?.length
                  ? dataItem.dedInfoNetworkStatus2
                  : null, // "IN, OUT",
                totalDeductibleAmount:
                  dataItem.dedInfoTotalDeductibleAmount2 || null,
                metDeductible: {
                  amount: dataItem.dedInfoMetDeductibleAmount2 || null,
                  resetDate: getDateString(
                    dataItem.dedInfoMetDeductibleResetDate2
                  )
                },
                totalOOPAmount: null,
                metOOP: {
                  amount: null,
                  resetDate: null
                }
              }
            ],
            oopMax: [
              {
                deductibleType: getDropdownValue(dataItem?.oopMaxDedType), // "FAMILY, SINGLE",
                networkStatus: dataItem?.oopMaxNetworkStatus?.length
                  ? dataItem.oopMaxNetworkStatus
                  : null, // "IN, OUT",
                totalDeductibleAmount: null,
                metDeductible: {
                  amount: null,
                  resetDate: null
                },
                totalOOPAmount: dataItem.oopMaxTotalOOPAmount || null,
                metOOP: {
                  amount: dataItem.oopMaxMetOOPAmount || null,
                  resetDate: getDateString(dataItem.oopMaxMetOOPResetDate)
                }
              },
              {
                deductibleType: getDropdownValue(dataItem?.oopMaxDedType2), // "FAMILY, SINGLE",
                networkStatus: dataItem?.oopMaxNetworkStatus2?.length
                  ? dataItem.oopMaxNetworkStatus2
                  : null, // "IN, OUT",
                totalDeductibleAmount: null,
                metDeductible: {
                  amount: null,
                  resetDate: null
                },
                totalOOPAmount: dataItem.oopMaxTotalOOPAmount2 || null,
                metOOP: {
                  amount: dataItem.oopMaxMetOOPAmount2 || null,
                  resetDate: getDateString(dataItem.oopMaxMetOOPResetDate2)
                }
              }
            ],
            verificationMethod: dataItem.contactMethod,
            payerPhoneNumber: dataItem.payerPhoneNumber,
            networkStatus: dataItem?.networkStatus
              ? dataItem.networkStatus
              : null,
            outOfNetworkBenefits: getNetworkStatus()
              ? false
              : dataItem.outOfNetworkBen,
            deductibleNotes: dataItem.deductibleNotes
          },
          paRequired: !!priorAuthReq,
          priorAuthorization: {
            submitMethod: dataItem.priorAuthSubmitMethod, // "PHONE, FAX, EMAIL, MAIL",
            paPhone: dataItem.priorAuthPhone,
            paSite: dataItem.portalLink,
            jCode: [jCodeData, jCodeData, jCodeData],
            adminCode1: dataItem.adminCode1,
            adminCode2: dataItem.adminCode2,
            adminCode3: dataItem.adminCode3
          },
          predeterminationNeeded: predeterminationNeededSelected,
          predetermination: {
            submitMethod: dataItem.predeterminationSubmitMethod, // "PHONE, FAX, EMAIL, MAIL",
            paPhone: dataItem.priorAuthPhone,
            paSite: dataItem.portalLink,
            jCode: [jCodeData, jCodeData, jCodeData],
            adminCode1: dataItem.adminCode1,
            adminCode2: dataItem.adminCode2,
            adminCode3: dataItem.adminCode3
          },
          callCompletionTime: moment(new Date()).format(),
          claims: {
            claimAddress: {
              city: dataItem.claimsCity,
              state: dataItem.claimsState?.abbreviation,
              streetName: dataItem.claimsAddr2
                ? `${dataItem.claimsAddr}, ${dataItem.claimsAddr2}`
                : dataItem.claimsAddr,
              county: null,
              zip: dataItem.claimsZip
            },
            timelyFilling: dataItem.timelyFiling,
            riskForClaims: dataItem.atRiskClaims,
            results: '' // dataItem.displayResults, // removed in JIRA AT-28
          },
          isCompleted: !!dataItem.isBICompleted
        },
        welcomeCalls: [
          {
            callTime: moment(new Date()).format(),
            agentId: user.username,
            answered: !!dataItem.welcomeCallAttempt1
          },
          {
            callTime: moment(new Date()).format(),
            agentId: user.username,
            answered: !!dataItem.welcomeCallAttempt2
          },
          {
            callTime: moment(new Date()).format(),
            agentId: user.username,
            answered: !!dataItem.welcomeCallAttempt3
          }
        ],
        welcomeCallCompleted: !!dataItem.welcomeCallCompleted,
        // callCompletionTime: AWSDateTime
        callCompletionTime: moment(new Date()).format()
      }
    };
    if (dataItem.billingResultsNPIChoice === 'PROVIDER' && !selectedProvider) {
      setProviderDisable(true);
      setButtonLoading(false);
    } else {
      const runAdd = () => {
        addUpdateBenefitCheckingData(requestObject);
        setProviderDisable(false);
      };
      if (
        listInsuranceKey[0]?.insuranceKey === 'Primary' &&
        selectedInsuranceKeyPlanType === 'Primary'
      ) {
        runAdd();
      } else if (
        listInsuranceKey[0]?.insuranceKey === 'Primary' &&
        selectedInsuranceKeyPlanType === 'Secondary'
      ) {
        runAdd();
      } else if (
        listInsuranceKey[1]?.insuranceKey === 'Secondary' &&
        selectedInsuranceKeyPlanType === 'Tertiary'
      ) {
        runAdd();
      } else {
        showWarning(
          'BI must be added in insurance order: Primary, Secondary and then Tertiary'
        );
      }
    }
  };

  const addUpdateBenefitCheckingData = async (dataItem) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: addUpdateBenefitChecking,
        variables: { input: dataItem }
      });

      if (data && data.data && data.data.addUpdateBenefitChecking) {
        showSuccess('BI: Check Benefits updated sucessfully.');
      }

      setStatusUpdate(!statusUpdate);
    } catch (err) {
      console.log('CheckBenefits::addUpdateBenefitCheckingData err:', err);
      showError(`BI: Check Benefits Error ${err?.errors[0]?.errorType}`);
    }
  };

  const defGroup = listGroupAICsData.find(
    (group) => group.id === selectedGroupId
  );
  const defLocation = listLocationAICsData.find(
    (location) => location.id === selectedLocationId
  );
  const defProvider = listProviderAICsData.find(
    (provider) => provider.providerNPI === selectedProviderId
  );
  const productName =
    mainFormData?.selectedOrder?.referralOrder?.administrations[0]?.drugName;

  const isSubmitDisabled = () => {
    const status =
      !canEdit(UserRoleTypes.BenefitsInvest) ||
      adminStop ||
      !defGroup ||
      !selectedGroupId;
    return status;
  };

  useEffect(() => {
    if (productName) {
      getProductInfoByNameCall(productName);
    }
  }, [productName]);

  const getProductInfoByNameCall = async () => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getProductInfoByName,
        variables: { productName }
      });
      setJcodeData(data?.data?.getProductInfoByName?.items[0]?.jCode);
    } catch (err) {
      console.log('err in retrieving getProductInfoByName', err);
      setJcodeData(''); // todo: show error dialog
    }
  };
  const renderBiAlert = (biTextType) => (
    <div className='ml-1'>
      <div style={{ textAlign: 'left' }}>
        <Alert>{biTextType}</Alert>
      </div>
    </div>
  );

  const renderBI = () => {
    return (
      <Preloader show={loading}>
        <div className='row check-notes'>
          <div className='col'>
            <div className='row mt-16'>
              <div className='col-4'>
                {adminStop && (
                  <>
                    <div className=' ml-1'>
                      <div style={{ textAlign: 'left' }}>
                        <Alert>Please Contact Admininstratior</Alert>
                      </div>
                    </div>
                  </>
                )}
                {checkBenefitsForm.isBICompleted.disabled && (
                  <>
                    <div className=' ml-1'>
                      <div style={{ textAlign: 'left' }}>
                        <Alert>Please Complete Clinical</Alert>
                      </div>
                    </div>
                  </>
                )}
                {!tabData[selected]?.checkings && (
                  <>
                    <div className=' ml-1'>
                      <div style={{ textAlign: 'left' }}>
                        <Alert>Please Complete Primary BI</Alert>
                      </div>
                    </div>
                  </>
                )}
                {!certified && (
                  <>
                    <div className=' ml-1'>
                      <div
                        style={{ textAlign: 'left' /* fontWeight: "bold" */ }}
                      >
                        <Alert type='warning'>
                          Warning: Cannot Certify Patient Insurance.
                        </Alert>
                      </div>
                    </div>
                  </>
                )}
                {listInsuranceKey.length < 2 &&
                  !adminStop &&
                  mainFormData?.insuranceInfo?.secondary &&
                  renderBiAlert('Please Complete Secondary BI.')}
                {listInsuranceKey.length > 1 &&
                  listInsuranceKey.length < 3 &&
                  !adminStop &&
                  mainFormData?.insuranceInfo?.tertiary &&
                  renderBiAlert('Please Complete Tertiary BI.')}
              </div>

              <div className='col-5'>
                {renderInsuranceLinks()}
                {listInsuranceKey.length < 2 && !adminStop && (
                  <Button
                    type='button'
                    look='flat'
                    icon='plus'
                    disabled={
                      !tabData[selected]?.checkings ||
                      !mainFormData?.insuranceInfo?.secondary
                    }
                    onClick={() =>
                      handleLoadInsuranceOrder({ insuranceKey: 'Secondary' })
                    }
                  >
                    {mainFormData?.insuranceInfo?.secondary
                      ? 'Add Secondary'
                      : 'Complete Secondary Insurance'}
                  </Button>
                )}
                {listInsuranceKey.length > 1 &&
                  listInsuranceKey.length < 3 &&
                  !adminStop && (
                    <Button
                      type='button'
                      look='flat'
                      icon='plus'
                      disabled={
                        !tabData[selected]?.checkings ||
                        !mainFormData?.insuranceInfo?.tertiary
                      }
                      onClick={() =>
                        handleLoadInsuranceOrder({ insuranceKey: 'Tertiary' })
                      }
                    >
                      {mainFormData?.insuranceInfo?.tertiary
                        ? 'Add Tertiary'
                        : 'Complete Tertiary Insurance'}
                    </Button>
                  )}
              </div>
              <div className='col-3' />
            </div>

            <hr />

            {/* MAIN FORM */}
            {submittedInsurance &&
              mainFormData?.insuranceInfo?.[submittedInsurance] && (
                <>
                  <div className='row mt-10 ml-1'>
                    <div className='col-md-2'>INSURANCE TYPE:</div>
                    <div className='col-md-3'>
                      <strong className='bright'>
                        {submittedInsurance.toUpperCase()}
                      </strong>
                    </div>
                    <div className='col-md-1'>POLICY ID:</div>
                    <div className='col-md-2'>
                      <strong>
                        {
                          mainFormData?.insuranceInfo[submittedInsurance]
                            ?.policyId
                        }
                      </strong>
                    </div>
                    <div className='col-md-1'>GROUP ID:</div>
                    <div className='col-md-2'>
                      <strong>
                        {
                          mainFormData?.insuranceInfo[submittedInsurance]
                            ?.groupId
                        }
                      </strong>
                    </div>
                  </div>
                  <div className='row mt-10 ml-1'>
                    <div className='col-md-2'>INSURANCE PLAN:</div>
                    <div className='col-md-3'>
                      <strong>
                        {
                          mainFormData?.insuranceInfo[submittedInsurance]
                            ?.insurerName
                        }
                      </strong>
                      &nbsp;--&nbsp;
                      <strong>
                        {
                          mainFormData?.insuranceInfo[submittedInsurance]
                            ?.planName
                        }
                      </strong>
                    </div>
                    <div className='col-md-1'>PHONE:</div>
                    <div className='col-md-2'>
                      <strong>
                        {
                          mainFormData?.insuranceInfo[submittedInsurance]
                            ?.customerServicePhone
                        }
                      </strong>
                    </div>
                    <div className='col-md-1'>STATE:</div>
                    <div className='col-md-2'>
                      <strong>
                        {mainFormData?.insuranceInfo[submittedInsurance]?.state}
                      </strong>
                    </div>
                  </div>
                  <div className='row mt-10 ml-1'>
                    <div className='col-md-2'>MEDICATION CODE (J-CODE):</div>
                    <div className='col-md-2'>
                      <strong>{jCodeData || '-'}</strong>
                    </div>
                  </div>
                  <hr />
                </>
              )}
            <FullPageLoader show={infoLoading}>
              {showMainForm && (
                <Form
                  initialValues={initialForm()}
                  onSubmit={handleSubmit}
                  render={(formRenderProps) => (
                    <form
                      onSubmit={formRenderProps.onSubmit}
                      className='k-form pl-3 pr-3 pt-1'
                    >
                      {/* BILLING INFORMATION */}
                      <>
                        <div className='row mt-10'>
                          <h4 className='col-md-4'>BILLING INFO</h4>
                        </div>
                        <div className='row mt-02'>
                          <div className='col-md-2 mt-12'>AIC GROUP:</div>
                          <div className='col-md-5'>
                            <DropDownList
                              label='Group Organization'
                              data={listGroupAICsData}
                              textField='text'
                              valueField='id'
                              value={defGroup}
                              onChange={handleBillingInfoGroupChange}
                              disabled={insuranceDisabled}
                            />
                          </div>
                        </div>

                        <div className='row mt-02'>
                          <div className='col-md-2 mt-12'>AIC LOCATION:</div>
                          {dropLoading ? (
                            <>
                              <div className='col-md-5'>
                                <Skeleton
                                  shape='text'
                                  style={{ width: '100%', height: 10 }}
                                />
                                <Skeleton
                                  shape='rectangle'
                                  style={{ width: '100%', height: 40 }}
                                />
                              </div>
                            </>
                          ) : (
                            <>
                              <div className='col-md-5'>
                                <DropDownList
                                  label='Group Location'
                                  data={listLocationAICsData}
                                  textField='text'
                                  valueField='value'
                                  value={defLocation}
                                  onChange={handleBillingInfoLocationChange}
                                  disabled={insuranceDisabled}
                                />
                              </div>
                            </>
                          )}
                        </div>
                        {selectedLocationInfo?.status === 'INACTIVE' &&
                          !dropLoading && (
                            <div className='row mt-02'>
                              <div className='col-md-2 mt-12' />
                              <>
                                <div className='col-md-5 ml-1'>
                                  <div>
                                    <Alert>{`AIC Location: ${selectedLocationInfo?.locationName} is Inactive. Please select another location.`}</Alert>
                                  </div>
                                </div>
                              </>
                              <div className='col-md-5' />
                            </div>
                          )}

                        <div className='row mt-02'>
                          {dropLoading ? (
                            <>
                              <div className='col-md-2 mt-12'>PROVIDER:</div>
                              <div className='col-md-5'>
                                <Skeleton
                                  shape='text'
                                  style={{ width: '100%', height: 10 }}
                                />
                                <Skeleton
                                  shape='rectangle'
                                  style={{ width: '100%', height: 40 }}
                                />
                              </div>
                            </>
                          ) : (
                            <>
                              <div className='col-md-2 mt-12'>PROVIDER:</div>
                              <div className='col-md-5'>
                                <DropDownList
                                  label='Group Provider'
                                  data={listProviderAICsData}
                                  textField='text'
                                  valueField='value'
                                  value={defProvider}
                                  onChange={handleProviderChange}
                                  disabled={insuranceDisabled}
                                />
                              </div>
                            </>
                          )}
                        </div>
                        {selectedProviderInfo?.status === 'INACTIVE' &&
                          !dropLoading && (
                            <div className='row mt-02'>
                              <div className='col-md-2 mt-12' />
                              <>
                                <div className='col-md-5 ml-1'>
                                  <div>
                                    <Alert>{`Provider: ${selectedProviderInfo?.firstName} ${selectedProviderInfo?.lastName} (NPI: ${selectedProviderInfo?.providerNPI}) is Inactive. Please select another provider.`}</Alert>
                                  </div>
                                </div>
                              </>
                              <div className='col-md-5' />
                            </div>
                          )}
                        <p />

                        <div className='row mt-12'>
                          <div className='col-md-2 mt-12'>
                            BILLING TIN/NPI:
                            <br />
                            {selectedLocationNPI}
                          </div>
                          <div className='col-md-6'>
                            <Field
                              name='billingResultsNPIChoice'
                              data={billingResultsNPIChoices}
                              layout='horizontal'
                              component={FormRadioGroup}
                              disabled={insuranceDisabled}
                              validator={
                                checkBenefitsForm.billingResultsNPIChoice
                                  .inputValidator
                              }
                            />
                          </div>
                        </div>

                        <div className='row mt-12'>
                          <div className='col-md-2' />
                          <div className='col-md-3'>
                            <Field
                              name='overrideTaxId'
                              label='Override TIN'
                              component={Input}
                              disabled={insuranceDisabled}
                              validator={
                                checkBenefitsForm.overrideTaxId.inputValidator
                              }
                            />
                          </div>
                          <div className='col-md-3'>
                            <Field
                              name='overrideNPI'
                              label='Override NPI'
                              disabled={insuranceDisabled}
                              component={Input}
                              validator={
                                checkBenefitsForm.overrideNPI.inputValidator
                              }
                            />
                          </div>
                        </div>
                        <div className='row mt-12'>
                          <div className='col-md-2' />
                          <div className='col-md-6'>
                            <Field
                              name='overrideReason'
                              component={Input}
                              label='Override Reason'
                              disabled={insuranceDisabled}
                              validator={
                                checkBenefitsForm.overrideReason.inputValidator
                              }
                            />
                          </div>
                        </div>

                        <div className='row mt-12'>
                          <div className='col-md-2' />
                          <div className='col-md-3'>
                            <Field
                              name='dateVerifiedOrReverified'
                              label={
                                <RequiredFormLabel text='Date Verified or Reverified' />
                              }
                              component={DatePicker}
                              validator={
                                checkBenefitsForm.dateVerifiedOrReverified
                                  .inputValidator
                              }
                            />
                          </div>
                          <div className='col-md-3'>
                            <Field
                              name='callReferenceNum'
                              label='Call Reference Number'
                              component={InputField}
                              validator={
                                checkBenefitsForm.callReferenceNum
                                  .inputValidator
                              }
                            />
                          </div>
                          <div className='col-md-3'>
                            <Field
                              name='representativeName'
                              label='Representative Name'
                              component={InputField}
                              validator={
                                checkBenefitsForm.representativeName
                                  .inputValidator
                              }
                            />
                          </div>
                        </div>
                        <hr />
                      </>
                      <>
                        <div className='row mt-10'>
                          <h4 className='col-md-4 ml-0'>PAYOR</h4>
                        </div>
                        <div className='row'>
                          <div className='col-md-2 float-md-right mt-16'>
                            PAYOR VERIFIED BY:
                          </div>
                          <div className='col-md-6 float-md-left mt-06'>
                            <Field
                              name='contactMethod'
                              data={contactMethods}
                              layout='horizontal'
                              component={FormRadioGroup}
                              validator={
                                checkBenefitsForm.contactMethod.inputValidator
                              }
                            />
                          </div>
                        </div>
                        <div className='row mt-12'>
                          <div className='col-md-2 float-md-right mt-16'>
                            PAYOR PHONE NUM:
                          </div>
                          <div className='col-md-3 float-md-left mt-06'>
                            <Field
                              name='payerPhoneNumber'
                              label='Payor Phone Number'
                              component={InputField}
                              validator={
                                checkBenefitsForm.payerPhoneNumber
                                  .inputValidator
                              }
                            />
                          </div>
                        </div>

                        <div className='row'>
                          <div className='col-md-2 mt-12'>
                            <RequiredFormLabel text='NETWORK STATUS' />
                          </div>
                          <div className='col-md-2 float-left'>
                            <Field
                              name='networkStatus'
                              data={networkStatuses}
                              layout='horizontal'
                              value={selectedNetworkStatuses}
                              component={FormRadioGroup}
                              onChange={(event) => {
                                setSelectedNetworkStatuses(event.value);
                              }}
                              validator={
                                checkBenefitsForm.networkStatus.inputValidator
                              }
                            />
                          </div>
                        </div>

                        <div className='row'>
                          <div className='col-md-2 mt-12'>
                            OUT OF NETWORK BENEFITS:
                          </div>
                          <div className='col-md-2 float-left'>
                            <Field
                              name='outOfNetworkBen'
                              data={outOfNetworkBen}
                              layout='horizontal'
                              component={FormRadioGroup}
                              value={selectedOutOfNetworkBen}
                              onChange={(event) =>
                                setSelectedOutOfNetworkBen(event.value)
                              }
                              disabled={getNetworkStatus()}
                              validator={
                                checkBenefitsForm.outOfNetworkBen.inputValidator
                              }
                            />
                          </div>
                        </div>

                        <div className='row mt-12'>
                          <div className='col-md-4'>
                            Plan Type:
                            <Field
                              name='planType'
                              defaultValue='Plan Type'
                              component={DropDownList}
                              data={planType}
                              validator={
                                checkBenefitsForm.planType.inputValidator
                              }
                            />
                          </div>
                          <div className='col-md-4'>
                            Deductible Type:
                            <Field
                              name='deductibleType'
                              defaultValue='Deductible Type'
                              component={DropDownList}
                              data={dedTypes}
                              validator={
                                checkBenefitsForm.deductibleType.inputValidator
                              }
                            />
                          </div>
                        </div>
                        <div className='row mt-12'>
                          <div className='col-md-2'>
                            <Field
                              name='effDate'
                              component={DatePicker}
                              label={
                                <RequiredFormLabel text='Effective Date' />
                              }
                              validator={
                                checkBenefitsForm.effDate.inputValidator
                              }
                            />
                          </div>
                          <div className='col-md-2'>
                            <Field
                              name='termDate'
                              component={DatePicker}
                              label='Term Date'
                              validator={
                                checkBenefitsForm.termDate.inputValidator
                              }
                            />
                          </div>
                          <div className='col-md-2'>
                            <Field
                              name='covPerc'
                              component={Input}
                              label='Coverage (%)'
                              validator={
                                checkBenefitsForm.covPerc.inputValidator
                              }
                            />
                          </div>
                          <div className='col-md-2'>
                            <Field
                              name='coPay'
                              component={Input}
                              label='Co-Pay'
                              validator={checkBenefitsForm.coPay.inputValidator}
                            />
                          </div>
                        </div>
                        <div className='row mt-12'>
                          <div className='col-md-8'>
                            Additional Information:
                            <Field
                              name='deductibleNotes'
                              component={TextArea}
                              rows={7}
                              validator={
                                checkBenefitsForm.deductibleNotes.inputValidator
                              }
                            />
                          </div>
                        </div>
                        <hr />
                      </>
                      {/* //////////////// */}
                      <PanelBar>
                        <PanelBarItem expanded title='DEDUCTIBLE / OOP'>
                          <div className='row mt-18'>
                            <div className='col-md-11 mt-12'>
                              DEDUCTIBLE INFORMATION
                            </div>
                          </div>

                          {/* Main deductible details */}
                          <div className='row'>
                            <div className='col-md-1' />
                            <div className='col-md-2 mt-08'>
                              <Field
                                name='dedInfoDedType'
                                defaultItem='Deductible for:'
                                component={DropDownList}
                                data={dedInfoDedTypes}
                                validator={
                                  checkBenefitsForm.dedInfoDedType
                                    .inputValidator
                                }
                              />
                            </div>
                            <div className='col-md-4'>
                              <Field
                                name='dedInfoNetworkStatus'
                                data={dedNetworks}
                                layout='horizontal'
                                component={FormRadioGroup}
                                validator={
                                  checkBenefitsForm.dedInfoNetworkStatus
                                    .inputValidator
                                }
                              />
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-md-1' />
                            <div className='col-md-2 mt-12'>DEDUCTIBLE:</div>
                            <div className='col-md-1 mt-12 text-md-left'>
                              <Field
                                name='dedInfoTotalDeductibleAmount'
                                component={Input}
                                validator={
                                  checkBenefitsForm.dedInfoTotalDeductibleAmount
                                    .inputValidator
                                }
                              />
                            </div>
                            <div className='col-md-2 mt-12 text-md-right'>
                              DEDUCTIBLE MET:
                            </div>
                            <div className='col-md-1 mt-12'>
                              <Field
                                name='dedInfoMetDeductibleAmount'
                                component={Input}
                                validator={
                                  checkBenefitsForm.dedInfoMetDeductibleAmount
                                    .inputValidator
                                }
                              />
                            </div>
                            <div className='col-md-3'>
                              <Field
                                name='dedInfoMetDeductibleResetDate'
                                label='Date Amount Reset OOP'
                                component={DatePicker}
                                validator={
                                  checkBenefitsForm
                                    .dedInfoMetDeductibleResetDate
                                    .inputValidator
                                }
                              />
                            </div>
                          </div>

                          {/* Additional Deductible block */}
                          <div className='row'>
                            <div className='col-md-1' />
                            <div className='col-md-2 mt-08'>
                              <Field
                                name='dedInfoDedType2'
                                defaultItem='Deductible for:'
                                component={DropDownList}
                                data={dedInfoDedTypes}
                                validator={
                                  checkBenefitsForm.dedInfoDedType2
                                    .inputValidator
                                }
                              />
                            </div>
                            <div className='col-md-4'>
                              <Field
                                name='dedInfoNetworkStatus2'
                                data={dedNetworks}
                                layout='horizontal'
                                component={FormRadioGroup}
                                validator={
                                  checkBenefitsForm.dedInfoNetworkStatus2
                                    .inputValidator
                                }
                              />
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-md-1' />
                            <div className='col-md-2 mt-12'>DEDUCTIBLE:</div>
                            <div className='col-md-1 mt-12 text-md-left'>
                              <Field
                                name='dedInfoTotalDeductibleAmount2'
                                component={Input}
                                validator={
                                  checkBenefitsForm
                                    .dedInfoTotalDeductibleAmount2
                                    .inputValidator
                                }
                              />
                            </div>
                            <div className='col-md-2 mt-12 text-md-right'>
                              DEDUCTIBLE MET:
                            </div>
                            <div className='col-md-1 mt-12'>
                              <Field
                                name='dedInfoMetDeductibleAmount2'
                                component={Input}
                                validator={
                                  checkBenefitsForm.dedInfoMetDeductibleAmount2
                                    .inputValidator
                                }
                              />
                            </div>
                            <div className='col-md-3'>
                              <Field
                                name='dedInfoMetDeductibleResetDate2'
                                label='Date Amount Reset OOP'
                                component={DatePicker}
                                validator={
                                  checkBenefitsForm
                                    .dedInfoMetDeductibleResetDate2
                                    .inputValidator
                                }
                              />
                            </div>
                          </div>

                          {/** OOP starts */}
                          <div className='row mt-18'>
                            <div className='col-md-11 mt-12'>
                              OUT OF POCKET (OOP) INFORMATION
                            </div>
                          </div>

                          {/** Main OOP block */}
                          <div className='row'>
                            <div className='col-md-1' />
                            <div className='col-md-2 mt-08'>
                              <Field
                                name='oopMaxDedType'
                                defaultItem='Deductible for:'
                                component={DropDownList}
                                data={dedInfoDedTypes}
                                validator={
                                  checkBenefitsForm.oopMaxDedType.inputValidator
                                }
                              />
                            </div>
                            <div className='col-md-4'>
                              <Field
                                name='oopMaxNetworkStatus'
                                data={dedNetworks}
                                layout='horizontal'
                                component={FormRadioGroup}
                                validator={
                                  checkBenefitsForm.oopMaxNetworkStatus
                                    .inputValidator
                                }
                              />
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-md-1' />
                            <div className='col-md-2 mt-12'>
                              OUT OF POCKET MAX:
                            </div>
                            <div className='col-md-1 mt-12 text-md-left'>
                              <Field
                                name='oopMaxTotalOOPAmount'
                                component={Input}
                                validator={
                                  checkBenefitsForm.oopMaxTotalOOPAmount
                                    .inputValidator
                                }
                              />
                            </div>
                            <div className='col-md-2 mt-12 text-md-right'>
                              OUT OF POCKET MAX MET:
                            </div>
                            <div className='col-md-1 mt-12'>
                              <Field
                                name='oopMaxMetOOPAmount'
                                component={Input}
                                validator={
                                  checkBenefitsForm.oopMaxMetOOPAmount
                                    .inputValidator
                                }
                              />
                            </div>
                            <div className='col-md-3'>
                              <Field
                                name='oopMaxMetOOPResetDate'
                                component={DatePicker}
                                label='Date Amount Reset OOP'
                                validator={
                                  checkBenefitsForm.oopMaxMetOOPResetDate
                                    .inputValidator
                                }
                              />
                            </div>
                          </div>

                          {/** Additional OOP block */}
                          <div className='row'>
                            <div className='col-md-1' />
                            <div className='col-md-2 mt-08'>
                              <Field
                                name='oopMaxDedType2'
                                defaultItem='Deductible for:'
                                component={DropDownList}
                                data={dedInfoDedTypes}
                                validator={
                                  checkBenefitsForm.oopMaxDedType2
                                    .inputValidator
                                }
                              />
                            </div>
                            <div className='col-md-4'>
                              <Field
                                name='oopMaxNetworkStatus2'
                                data={dedNetworks}
                                layout='horizontal'
                                component={FormRadioGroup}
                                validator={
                                  checkBenefitsForm.oopMaxNetworkStatus2
                                    .inputValidator
                                }
                              />
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-md-1' />
                            <div className='col-md-2 mt-12'>
                              OUT OF POCKET MAX:
                            </div>
                            <div className='col-md-1 mt-12 text-md-left'>
                              <Field
                                name='oopMaxTotalOOPAmount2'
                                component={Input}
                                validator={
                                  checkBenefitsForm.oopMaxTotalOOPAmount2
                                    .inputValidator
                                }
                              />
                            </div>
                            <div className='col-md-2 mt-12 text-md-right'>
                              OUT OF POCKET MAX MET:
                            </div>
                            <div className='col-md-1 mt-12'>
                              <Field
                                name='oopMaxMetOOPAmount2'
                                component={Input}
                                validator={
                                  checkBenefitsForm.oopMaxMetOOPAmount2
                                    .inputValidator
                                }
                              />
                            </div>
                            <div className='col-md-3'>
                              <Field
                                name='oopMaxMetOOPResetDate2'
                                component={DatePicker}
                                label='Date Amount Reset OOP'
                                validator={
                                  checkBenefitsForm.oopMaxMetOOPResetDate2
                                    .inputValidator
                                }
                              />
                            </div>
                          </div>
                        </PanelBarItem>

                        <PanelBarItem expanded title='PRIOR AUTHORIZATION'>
                          <div className='row'>
                            <div className='col-md-2 mt-12'>PA REQUIRED:</div>
                            <div className='col-md-2'>
                              <Field
                                name='isPriorAuthReq'
                                data={isPriorAuthReqs}
                                layout='horizontal'
                                component={FormRadioGroup}
                                value={priorAuthReq}
                                onChange={(event) =>
                                  setPriorAuthReq(event.value)
                                }
                                validator={
                                  checkBenefitsForm.isPriorAuthReq
                                    .inputValidator
                                }
                              />
                            </div>
                          </div>
                          {priorAuthReq === true || priorAuthReq === '' ? (
                            <div className='row'>
                              <div className='col-md-2' />
                              <div className='col-md-10'>
                                <div className='row'>
                                  <div className='col-md-2 mt-10'>
                                    HOW TO SUBMIT PA:
                                  </div>
                                  <div className='col-md-4'>
                                    <Field
                                      name='priorAuthSubmitMethod'
                                      data={priorAuthSubmitMethods}
                                      layout='horizontal'
                                      component={FormRadioGroup}
                                      validator={
                                        checkBenefitsForm.priorAuthSubmitMethod
                                          .inputValidator
                                      }
                                    />
                                  </div>
                                </div>
                                <div className='row'>
                                  <div className='col-md-2' />
                                  <div className='col-md-2  mt-10'>
                                    <Field
                                      name='priorAuthPhone'
                                      label='Prior Auth Phone'
                                      component={Input}
                                      validator={
                                        checkBenefitsForm.priorAuthPhone
                                          .inputValidator
                                      }
                                    />
                                  </div>
                                  <div className='col-md-4 mt-10'>
                                    <Field
                                      name='portalLink'
                                      label='Portal Link'
                                      component={Input}
                                      validator={
                                        checkBenefitsForm.portalLink
                                          .inputValidator
                                      }
                                    />
                                  </div>
                                </div>

                                <div className='row'>
                                  <div className='col-md-2' />
                                  <div className='col-md-2'>
                                    <Field
                                      name='adminCode1'
                                      label='Admin Code 1'
                                      component={Input}
                                      validator={
                                        checkBenefitsForm.adminCode1
                                          .inputValidator
                                      }
                                    />
                                  </div>
                                  <div className='col-md-2'>
                                    <Field
                                      name='adminCode2'
                                      label='Admin Code 2'
                                      component={Input}
                                      validator={
                                        checkBenefitsForm.adminCode2
                                          .inputValidator
                                      }
                                    />
                                  </div>
                                  <div className='col-md-2'>
                                    <Field
                                      name='adminCode3'
                                      label='Admin Code 3'
                                      component={Input}
                                      validator={
                                        checkBenefitsForm.adminCode3
                                          .inputValidator
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div>
                              <div className='row'>
                                <div className='col-md-10 offset-md-2'>
                                  <Field
                                    name='predeterminationNeededOption'
                                    data={predeterminationNeededOptions}
                                    layout='horizontal'
                                    component={FormRadioGroup}
                                    value={predeterminationNeededSelected}
                                    onChange={(e) =>
                                      setPredeterminationNeededSelected(e.value)
                                    }
                                    validator={
                                      checkBenefitsForm
                                        .predeterminationNeededOption
                                        .inputValidator
                                    }
                                  />
                                </div>
                              </div>
                              {predeterminationNeededSelected ? (
                                <div className='row'>
                                  <div className='col-md-2' />
                                  <div className='col-md-10'>
                                    <div className='row'>
                                      <div className='col-md-2 mt-12'>
                                        HOW TO SUBMIT PA
                                      </div>
                                      <div className='col-md-4'>
                                        <Field
                                          name='predeterminationSubmitMethod'
                                          data={priorAuthSubmitMethods}
                                          layout='horizontal'
                                          component={FormRadioGroup}
                                          validator={
                                            checkBenefitsForm
                                              .predeterminationSubmitMethod
                                              .inputValidator
                                          }
                                        />
                                      </div>
                                    </div>

                                    <div className='row'>
                                      <div className='col-md-2' />
                                      <div className='col-md-2 mt-10'>
                                        <Field
                                          name='priorAuthPhone'
                                          label='Prior Auth Phone'
                                          component={Input}
                                          validator={
                                            checkBenefitsForm.priorAuthPhone
                                              .inputValidator
                                          }
                                        />
                                      </div>
                                      <div className='col-md-4 mt-10'>
                                        <Field
                                          name='portalLink'
                                          label='Portal Link'
                                          component={Input}
                                          validator={
                                            checkBenefitsForm.portalLink
                                              .inputValidator
                                          }
                                        />
                                      </div>
                                    </div>

                                    <div className='row'>
                                      <div className='col-md-2' />
                                      <div className='col-md-2'>
                                        <Field
                                          name='adminCode1'
                                          label='Admin Code 1'
                                          component={Input}
                                          validator={
                                            checkBenefitsForm.adminCode1
                                              .inputValidator
                                          }
                                        />
                                      </div>
                                      <div className='col-md-2'>
                                        <Field
                                          name='adminCode2'
                                          label='Admin Code 2'
                                          component={Input}
                                          validator={
                                            checkBenefitsForm.adminCode2
                                              .inputValidator
                                          }
                                        />
                                      </div>
                                      <div className='col-md-2'>
                                        <Field
                                          name='adminCode3'
                                          label='Admin Code 3'
                                          component={Input}
                                          validator={
                                            checkBenefitsForm.adminCode3
                                              .inputValidator
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className='mt-4'>
                                  <div className='row'>
                                    <div className='col-md-3 offset-md-2'>
                                      Need to Complete Welcome Call
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </PanelBarItem>

                        <PanelBarItem expanded title='CLAIMS'>
                          <div className='row'>
                            <div className='col-md-4'>
                              <Field
                                name='claimsAddr'
                                label='Claims Address 1'
                                component={Input}
                                validator={
                                  checkBenefitsForm.claimsAddr.inputValidator
                                }
                              />
                            </div>
                            <div className='col-md-4'>
                              <Field
                                name='claimsAddr2'
                                label='Claims Address 2'
                                component={Input}
                                validator={
                                  checkBenefitsForm.claimsAddr2.inputValidator
                                }
                              />
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-md-2'>
                              <Field
                                name='claimsCity'
                                label='Claims City'
                                component={Input}
                                validator={
                                  checkBenefitsForm.claimsCity.inputValidator
                                }
                              />
                            </div>
                            <div className='col-md-2'>
                              <Field
                                name='claimsState'
                                data={states}
                                textField='name'
                                valueField='abbreviation'
                                label='Claims State'
                                component={DropDownList}
                                validator={
                                  checkBenefitsForm.claimsCity.inputValidator
                                }
                              />
                            </div>
                            <div className='col-md-2'>
                              <Field
                                name='claimsZip'
                                label='Claims Zip'
                                component={Input}
                                validator={
                                  checkBenefitsForm.claimsZip.inputValidator
                                }
                              />
                            </div>
                          </div>
                          <div className='row mt-10'>
                            <div className='col-md-4'>
                              <Field
                                name='timelyFiling'
                                label={'Timely Filing (enter # of Days):'}
                                component={FormNumericTextBox}
                                validator={
                                  checkBenefitsForm.timelyFiling.inputValidator
                                }
                              />
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-md-4'>
                              <Field
                                name='atRiskClaims'
                                label='Who is at risk for claims?'
                                component={Input}
                                validator={
                                  checkBenefitsForm.atRiskClaims.inputValidator
                                }
                              />
                            </div>
                          </div>
                        </PanelBarItem>

                        <PanelBarItem expanded title='WELCOME CALL'>
                          <div className='mt-4'>
                            <div className='row '>
                              <div className='col-md-3 offset-md-1'>
                                WELCOME CALL TO PATIENT
                              </div>
                              <div className='col-md-3 mt-12'>
                                <Field
                                  name='welcomeCallAttempt1'
                                  label='1st Attempt'
                                  component={Checkbox}
                                  validator={
                                    checkBenefitsForm.welcomeCallAttempt1
                                      .inputValidator
                                  }
                                />
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-3 offset-md-4 mt-12'>
                                <Field
                                  name='welcomeCallAttempt2'
                                  label='2nd Attempt'
                                  component={Checkbox}
                                  validator={
                                    checkBenefitsForm.welcomeCallAttempt2
                                      .inputValidator
                                  }
                                />
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-3 offset-md-4 mt-12'>
                                <Field
                                  name='welcomeCallAttempt3'
                                  label='3rd Attempt'
                                  component={Checkbox}
                                  validator={
                                    checkBenefitsForm.welcomeCallAttempt3
                                      .inputValidator
                                  }
                                />
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-3 offset-md-1 mt-12'>
                                <Field
                                  name='welcomeCallCompleted'
                                  label='WELCOME CALL COMPLETED'
                                  component={Checkbox}
                                  validator={
                                    checkBenefitsForm.welcomeCallCompleted
                                      .inputValidator
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </PanelBarItem>
                      </PanelBar>
                      <hr />
                      <div className='row'>
                        <div className='col-md-4 mt-12'>
                          <Field
                            name='isBICompleted'
                            label='BENEFITS INVESTIGATION IS COMPLETE'
                            component={Checkbox}
                            validator={
                              checkBenefitsForm.isBICompleted.inputValidator
                            }
                          />
                        </div>
                      </div>
                      <div className='row p-3 mt-12'>
                        <div className='col-3'>
                          <FormButton
                            type='submit'
                            className='k-button pageButton blue'
                            disabled={isSubmitDisabled()}
                            loading={buttonLoading}
                          >
                            Submit
                          </FormButton>
                        </div>
                        <div className='col-9'>
                          {providerDisable && (
                            <>
                              {renderBiAlert(
                                'Alert: Must select a Provider if NPI from Provider is selected.'
                              )}
                            </>
                          )}
                          {!selectedGroupId && (
                            <>
                              {renderBiAlert('Alert: Must select a AIC group.')}
                            </>
                          )}
                        </div>
                      </div>
                    </form>
                  )}
                />
              )}
            </FullPageLoader>
          </div>
        </div>
      </Preloader>
    );
  };

  return (
    <>
      <div className='row'>
        <div className='col'>
          <div className='k-form pl-3 pr-3 pt-1'>
            <div className='row'>
              <div className='col-md-3 pageTitle'>BI: Check Benefits</div>
            </div>
          </div>

          <div
            className='col-md-12 mt-3'
            id='tab-strip-gray'
            style={{ minHeight: '800px' }}
          >
            {listReferralOrdersData && listReferralOrdersData.length > 0 ? (
              <TabStrip
                selected={selected}
                onSelect={handleSelect}
                className='tsg-container'
              >
                {listReferralOrdersData.map((item) => (
                  <TabStripTab title={item.drugName} key={item.referralId}>
                    {renderBI()}
                  </TabStripTab>
                ))}
              </TabStrip>
            ) : (
              <div className=''>
                <Badge
                  text='No active prior benefit orders available'
                  type='warning'
                />
              </div>
            )}
          </div>

          <hr />
        </div>
      </div>
      {buttonLoading && <FullPageLoader />}
    </>
  );
};

export default CheckBenefits;
