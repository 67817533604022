import React, { useContext, useState, useMemo, useEffect } from 'react';
import { Form, Field } from '@progress/kendo-react-form';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Typography } from '@progress/kendo-react-common';
import Alert from '@/components/common-components/Alert';
import FormButton from '@/components/common-components/Form/FormButton';
import { DropDownListField, validateInput } from '@/common/Validation';
import { listProviderAICsCall } from './listProvidersCall';
import { Constants } from '@/constants';
import { InfusionContext } from '@/context/InfusionContext';
import { NotifContext } from '@/context/NotifContext';
import { LogContext } from '@/context/LogContext';

const InfusionUpdateProviderDialog = ({
  onClose,
  onUpdateNursingProvider,
  infusionDetails
}) => {
  const { infusion, setInfusion } = useContext(InfusionContext);
  const { showSuccess, showError } = useContext(NotifContext);
  const { logApiException } = useContext(LogContext);
  const isInfusionBilled = infusionDetails?.claimStatus === 'ACCEPTED';
  const [providers, setProviders] = useState([]);
  const [rawProviders, setRawProviders] = useState([]);
  const currentProvider = useMemo(() => {
    return providers.find((provider) => provider.value === infusionDetails.providerId);
  }, [providers]);
  const [infusionFormData, setInfusionFormData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchhProviders();
  }, []);

  const fetchhProviders = async () => {
    try {
      const { sorted: sortedProviders, raw: rawProviders } =
        await listProviderAICsCall(true);
      setProviders(sortedProviders);
      setRawProviders(rawProviders);
    } catch (e) {
      console.error(e);
      logApiException(e, {
        view: 'InfusionUpdateProviderDialog',
        endpoint: 'getProvidersByLocationId',
        locationId: infusionDetails?.locationId
      });
    }
  };

  useEffect(() => {
    setInfusionFormData({
      provider: currentProvider
    });
  }, [currentProvider]);

  const infusionForm = {
    provider: {
      value: infusionFormData?.provider || '',
      inputValidator: (value) => {
        return validateInput({
          provider: {
            ...infusionForm.provider,
            value
          }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        }
      ]
    }
  };

  const handleSubmit = async (dataItem) => {
    const requestObject = {
      providerId: dataItem.provider.value,
      nursingProcessId: infusionDetails.id
    };

    try {
      setLoading(true);
      const updated = await onUpdateNursingProvider(requestObject);
      setLoading(false);
      if (updated) {
        // update provider id in infusion context
        const currentRawProvider = rawProviders.find((provider) => provider.providerNPI === dataItem.provider.value);
        setInfusion({
          ...infusion,
          nursingProcess: {
            ...infusionDetails,
            providerId: dataItem.provider.value
          },
          provider: currentRawProvider
        });
        showSuccess('Infusion provider info was successfully updated');
        onClose();
      }
    } catch (error) {
      showError('Error: infusion provider has not been updated');
      setLoading(false);
    }
  };

  return (
    <Dialog title="Update Provider" width={500} height="auto" onClose={onClose}>
      <Form
        onSubmit={handleSubmit}
        initialValues={infusionFormData}
        key={JSON.stringify(infusionFormData)}
        render={(formRenderProps) => {
          return (
            <form
              id="formUpdateProvider"
              onSubmit={formRenderProps.onSubmit}
              className="k-form pl-3 pr-3 mt-3"
            >
              <div className="row">
                {isInfusionBilled && (
                  <div className="col-md-12">
                    <Alert type="warning">
                      Warning: This infusion has been billed; any Provider
                      updates need to be resubmitted
                    </Alert>
                  </div>
                )}
                <div className="col-md-12">
                  <Typography.p textTransform="capitalize" fontWeight="bold">
                    Current Provider: {currentProvider?.text}
                  </Typography.p>
                </div>
                <div className="col-md-12">
                  Providers: &nbsp;
                  <Field
                    name="provider"
                    component={DropDownListField}
                    data={providers}
                    validator={infusionForm.provider.inputValidator}
                    textField="text"
                    dataItemKey="value"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 mt-08">
                  <FormButton
                    className="k-button blue"
                    type="submit"
                    disabled={
                      !formRenderProps.allowSubmit || !formRenderProps.valid
                    }
                    label="Update Provider"
                    loading={loading}
                  />
                </div>
              </div>
            </form>
          );
        }}
      />
    </Dialog>
  );
};

export default InfusionUpdateProviderDialog;
