import React, { useEffect, useContext } from 'react';
import { PropTypes } from 'prop-types';
import { PatientBillingList } from './PatientBillingList';
import { PatientContext } from '@/context';

export const PatientBillingListData = ({ selectAction }) => {
  const { selectedPatientInfo, recentBillings, getPatientBillingHistory } =
    useContext(PatientContext);

  useEffect(() => {
    getPatientBillingHistory(selectedPatientInfo.patientId);
  }, []);

  if (!recentBillings) {
    return (
      <>
        <p style={{ margin: '20px' }}>Loading...</p>
      </>
    );
  }
  if (!recentBillings?.length) {
    return (
      <>
        <p style={{ margin: '20px' }}>
          No billing information available at this time for this patient.
        </p>
      </>
    );
  }
  return (
    <PatientBillingList
      patientBillings={recentBillings}
      selectAction={selectAction}
    />
  );
};

PatientBillingListData.propTypes = {
  selectAction: PropTypes.func
};
