import React, { useState, useEffect, useContext } from 'react';

import { formatDateTimeToDefault } from '@/common/DateHelper';
import { PatientContext } from '@/context/PatientContext';
import Preloader from '@/components/common-components/Preloader';
import { getSurveyModel } from '../SurveyEngine/models/surveyModelMapper';
import PatientSurveyLog from './PatientSurveyLog';

const PatientSurveys = () => {
  const { selectedPatientInfo } = useContext(PatientContext);
  const [surveyInfo, setSurveyInfo] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (selectedPatientInfo?.surveys) {
      setSurveyInfo(selectedPatientInfo?.surveys.map((item) => {
          const surveyModel = getSurveyModel(item.typeOfSurvey, item.version);

          return {
            ...item,
            collectedAt: formatDateTimeToDefault(item.collectedAt),
            surveyModel
          };
        }));
    } else {
      setLoading(false);
    }
  }, [selectedPatientInfo]);

  useEffect(() => {
    if (surveyInfo) {
      setLoading(false);
    }
  }, [surveyInfo]);

  return (
    <div className="k-form pl-3 pr-3 pt-1">
      <div className="row mt-2">
        <div className="col-md-12">
          <div className="a-grid__header">
            <div>PATIENT SURVEYS</div>
          </div>
          <Preloader show={loading}>
            <PatientSurveyLog surveyInfo={surveyInfo} />
          </Preloader>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12" />
      </div>
    </div>
  );
};

export default PatientSurveys;
