import React, { createContext, useEffect, useState } from 'react';
// gql
import { listProviderAICs } from '@/graphql/queries';
import { connectToGraphqlAPI } from '@/provider';

export const ProviderContext = createContext();
export const ProviderConsumer = ProviderContext.Consumer;

const ProviderContextProvider = ({ children, user }) => {
  const [providers, setProviders] = useState([]);
  const [rawProviders, setRawProviders] = useState([]);

  const listProviderAICsCall = async () => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: listProviderAICs
      });

      if (!data?.data?.listProviderAICs?.items?.length) {
        return null;
      }

      const sorted = data.data.listProviderAICs.items
        .map((item) => ({
          text: `${item.firstName} ${item.lastName} (NPI: ${item.providerNPI})`,
          value: item.providerNPI
        }))
        .sort((first, second) =>
          first.text.localeCompare(second.text, 'en', { sensitivity: 'base' }));

      setRawProviders(data.data.listProviderAICs.items);
      setProviders(sorted);
    } catch (err) {
      console.error('listProviderAICsCall data err: ', err);
      return null;
    }
  };

  useEffect(() => {
    // action fires only if user logged in
    if (user) {
      listProviderAICsCall();
    }
  }, [user]);

  return (
    <ProviderContext.Provider
      value={{
        providers,
        setProviders,
        rawProviders,
        setRawProviders
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export default ProviderContextProvider;
