import React, { useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import { Button } from '@progress/kendo-react-buttons';
import { ExcelExport } from '@progress/kendo-react-excel-export';

// components
import { MessageDialog } from '@/components/common-components/MessageDialog';
import Badge from '@/components/common-components/Badge';
import { ColumnMenu } from '@/components/common-components/columnMenu';
import CurrentPatient from '@/components/common-components/CurrentPatient';
import { RequestGridData } from '@/components/common-components/RequestGridData';
import AwesomeLabel from '@/components/common-components/AwesomeLabel';

// gql
import { connectToGraphqlAPI } from '@/provider';
import {
  getPatientBucket,
  getWorkItemsByTaskType
} from '@/graphql/queries';
import { acquireLocker } from '@/graphql/mutations';

// context
import { UserContext } from '@/context/UserContext';
import { PatientContext } from '@/context/PatientContext';
import { NotifContext } from '@/context/NotifContext';
import { FollowUpContext } from '@/context/FollowUpContext';
import { WorkItemContext } from '@/context/WorkItemContext';

// partials
import { getCategoryNameFromJSON } from '../Followup/followUpData.js';
import FollowUp from '../Followup/FollowUp';

const PatientCallWorkInProgress = (props) => {
  const { user } = useContext(UserContext);
  const { showError } = useContext(NotifContext);
  const { followUpSearch, setFollowUpSearch, setFollowUpEvent } =
    useContext(FollowUpContext);
  const { setSelectedWorkItem } = useContext(WorkItemContext);
  const { setSelectedPatientInfo, setSelectedLocker, selectedPatientInfo } =
    useContext(PatientContext);

  const [dialogOption, setDialogOption] = useState({});
  /// ////////////Function sets to handle data call with RequestGridData component and grid data
  const [followUpListLoadSwitch, setFollowUpListLoadSwitch] = useState();
  const [gridData, setGridData] = useState({
    data: [],
    total: 10
  });
  const [dataState, setDataState] = useState({
    sort: [{ field: 'followupDate', dir: 'asc' }],
    take: 20,
    skip: 0
  });

  const dataStateChange = (e) => {
    setDataState(e.dataState);
  };

  const dataReceived = (data) => {
    const listWorkItemsData = data.data.getWorkItemsByTaskType
      .map((item) => {
        item.patientName = `${item.patientLastName}, ${item.patientFirstName}`;
        item.attachedData = getCategoryNameFromJSON(item.attachedData);
        return item;
      })
      .sort((a, b) => (b.startTime > a.startTime ? 1 : -1));
    setGridData({ data: listWorkItemsData, total: listWorkItemsData.length });
  };

  const followUpListLoad = () => {
    if (!followUpListLoadSwitch) setFollowUpListLoadSwitch(!followUpListLoadSwitch);
  };
  /// /////////////////////////////////////////////////////

  /// Excel export
  const _exporter = React.createRef();
  const exportExcel = () => {
    if (_exporter.current) {
      _exporter.current.save();
    }
  };
  /// //////

  const customCell = ({ dataItem }) => {
    const renderEscalationLabel = () => {
      if (dataItem.workStatus === 'OVERDUE') {
        return <Badge text="ESCALATED" />;
      }
      if (dataItem.work === 'EXTEND_CLINICIAN_REVIEW') {
        return <Badge type="warning" text="EXPIRING" />;
      }

      return null;
    };
    return (
      <td>
        {renderEscalationLabel()}
        {dataItem.description}
      </td>
    );
  };

  const handlePatientDetailsClick = async (dataItem) => {
    try {
      await getPatientBucketData(dataItem.patientId);
      props.history.push('/patient-portal');
    } catch (err) {
      showError(err);
    }
  };

  const setFollowUpEventCall = (dataItem) => {
    const requestObject = {
      followupid: dataItem?.id,
      followupdate: dataItem?.followupDate,
      followupassignedto: dataItem?.assignedTo,
      followupnote: dataItem?.description,
      followupcategory: dataItem?.attachedData,
      followUpWorkType: dataItem?.work,
      followUpCreated: new Date(dataItem?.createdAt).setMilliseconds(0)
    };
    setFollowUpEvent(requestObject);
  };

  const editFollowUp = ({ dataItem }) => {
    return (
      <td>
        <div className="row">
          <div className="col-md-4">
            <Button
              look="flat"
              icon="edit"
              type="button"
              title="Edit"
              disabled={
                !(selectedPatientInfo?.patientId && followUpListLoadSwitch)
              }
              onClick={() => {
                getPatientBucketData(dataItem.patientId);
                setFollowUpEventCall(dataItem);
              }}
            >
              Edit
            </Button>
          </div>
        </div>
      </td>
    );
  };

  const onRowClickHandle = (e) => {
    setSelectedWorkItem(e.dataItem.workItems);
    if (e.dataItem.assignedTo) {
      acquireWorkApiCall(
        {
          agentId: user.username,
          patientId: e.dataItem.patientId
        },
        e.dataItem.patientId,
        e.dataItem.currentStage
      );
    }
  };

  const acquireWorkApiCall = async (requestObject, patientId, currentStage) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: acquireLocker,
        variables: { input: requestObject }
      });

      if (data && data.data && data.data.acquireLocker) {
        if (
          data.data.acquireLocker.success &&
          data.data.acquireLocker.lockerId
        ) {
          setSelectedLocker(data.data.acquireLocker.lockerId);
          getPatientBucketData(patientId, currentStage);
        } else if (data.data.acquireLocker.details) {
          setDialogOption({
            title: 'Acquire Work: Error 1',
            message: data.data.acquireLocker.details,
            showDialog: true
          });
        } else {
          setDialogOption({
            title: 'Acquire Work: Error 2',
            message: 'Error acquireWorkApiCall with no {details} available',
            showDialog: true
          });
        }
      } else if (data.data.acquireLocker.details) {
        setDialogOption({
          title: 'Acquire Work: Error 3',
          message: data.data.acquireLocker.details,
          showDialog: true
        });
      }
    } catch (err) {
      console.log('PatientCallWorkInProgress::acquireWorkApiCall err: ', err);
      showError('PatientCallWorkInProgress::acquireWorkApiCall err: ', err);
    }
  };

  const getPatientBucketData = async (patientId) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getPatientBucket,
        variables: { patientId }
      });

      if (data && data.data && data.data.getPatientBucket) {
        setSelectedPatientInfo(data.data.getPatientBucket);
      } else {
        setDialogOption({
          title: 'Work In Progress: Get Patient',
          message: 'No Patient Record Found',
          showDialog: true
        });
      }
    } catch (err) {
      console.log('PatientCallWorkInProgress::getPatientBucketData err:', err);
      showError('PatientCallWorkInProgress::getPatientBucketData err:', err);
    }
  };

  const render = {
    patientNameCell: ({ dataItem: { inProgram, patientName } }) => (
      <td>
        {inProgram && (
          <Badge type="info" text="STAT ViiV" />
        )}
        {patientName}
      </td>
    ),
    actionCell: ({ dataItem }) => {
      return (
        <td>
          <div className="row">
            <div className="col-md-4">
              <Button
                look="flat"
                icon="file-txt"
                type="button"
                title="Patient Details"
                onClick={() => handlePatientDetailsClick(dataItem)}
              >
                Info
              </Button>
            </div>
          </div>
        </td>
      );
    }
  };

  const columns = [
    {
      field: 'followupDate',
      title: 'Follow Up',
      columnMenu: ColumnMenu
    },
    {
      field: 'patientId',
      title: 'Patient ID',
      columnMenu: ColumnMenu
    },
    {
      field: 'patientName',
      title: 'Patient',
      columnMenu: ColumnMenu,
      cell: render.patientNameCell
    },
    {
      field: 'attachedData',
      title: 'Category',
      columnMenu: ColumnMenu
    },
    {
      field: 'description',
      title: 'Description',
      cell: customCell,
      columnMenu: ColumnMenu
    },
    {
      field: 'createdBy',
      title: 'Agent',
      columnMenu: ColumnMenu
    },
    {
      field: 'assignedTo',
      title: 'Assigned',
      columnMenu: ColumnMenu
    },
    {
      field: 'workStatus',
      title: 'Status',
      columnMenu: ColumnMenu
    },
    {
      field: 'select',
      title: 'Info',
      cell: render.actionCell,
      columnMenu: ColumnMenu
    },
    {
      field: 'edit',
      title: 'Edit',
      cell: editFollowUp,
      columnMenu: ColumnMenu
    }
  ];

  return (
    <>
      <div className="container-fluid">
        {dialogOption.showDialog && (
          <MessageDialog dialogOption={dialogOption} />
        )}
        <div className="row">
          {selectedPatientInfo?.patientId ? (
            <div className="col-3">
              <CurrentPatient />
            </div>
          ) : (
            <div className="col-3" />
          )}
          <div className="col-1"> </div>
          <div className="col-5" />
          <div className="col-3 bright">
            <Button
              style={{ color: '#005282', float: 'right' }}
              look="flat"
              onClick={exportExcel}
            >
              <AwesomeLabel icon="file-excel" label="Export to Excel" />
            </Button>
            <Button
              style={{ color: '#005282', float: 'right' }}
              look="flat"
              icon="search"
              onClick={() => setFollowUpSearch(!followUpSearch)}
            >
              Search
            </Button>
            {selectedPatientInfo?.patientId && (
              <>
                <FollowUp
                  returnStyle="followUpLayout"
                  followUpListLoad={followUpListLoad}
                />
              </>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-12 work-in-progress " />
          <div className="col-12 col-lg-12 work-in-progress">
            <div className="a-grid__header">
              <div>Patient Call Queue</div>
            </div>

            <ExcelExport data={gridData.data} ref={_exporter}>
              <Grid
                className="queue_grid call-grid"
                style={{ cursor: 'pointer' }}
                sortable
                pageable
                data={process(gridData.data, dataState)}
                onDataStateChange={dataStateChange}
                onRowClick={(e) => onRowClickHandle(e)}
                {...dataState}
              >
                {columns.map((colProps, idx) => (
                  <Column key={idx} {...colProps} />
                ))}
              </Grid>
            </ExcelExport>
            {/* See file RequestGridData for instructions */}
            <RequestGridData
              graphqlQuery={getWorkItemsByTaskType}
              variables={{ taskType: 'OUTBOUND_CALL' }}
              dataState={dataState}
              onDataReceived={dataReceived}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(PatientCallWorkInProgress);
