import * as React from "react";
import { SchedulerViewSlot } from "@progress/kendo-react-scheduler";
export const CustomViewSlot = (props) => {
  return (
    <SchedulerViewSlot
      {...props}
      expandable={false}
      style={{ ...props.style, minHeight: 60 }}
    />
  );
};