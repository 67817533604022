import React from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import AwesomeLabel from "@/components/common-components/AwesomeLabel";
import { formatDateToDefault } from "@/common/DateHelper";

const PatientWeightLog = props => {
  const headerLbCell = () => {
    return <AwesomeLabel icon="weight-hanging" label="Weight (lbs)" />;
  };

  const headerKgCell = () => {
    return <AwesomeLabel icon="weight-hanging" label="Weight (kgs)" />;
  };

  const headerDateCell = () => {
    return <AwesomeLabel icon="calendar" label="Entered Date" />;
  };

  const weightLbCell = dataItem => {
    return renderCellValue(dataItem, "weightLb");
  };

  const weightKgCell = dataItem => {
    return renderCellValue(dataItem, "weightKg");
  };

  const weightDateCell = dataItem => {
    return renderCellValue(dataItem, "weightDate");
  };

  const renderCellValue = (dataItem, itemField) => {
    const cellClass = dataItem.dataIndex === 0 ? "important-text remove-label f12px" : "";

    return (
      <td>
        <span className={cellClass}>
          {dataItem?.dataItem?.[itemField] || 0}
        </span>
      </td>
    );
  };

  const data =
    props.patientInfo?.patientProfile?.weightInfo &&
    props.patientInfo.patientProfile.weightInfo.length > 0
      ? props.patientInfo.patientProfile.weightInfo
          .map(weightRecord => {
            return {
              weightLb: weightRecord.weightLB,
              weightKg: weightRecord.weight,
              weightDate: formatDateToDefault(weightRecord.entered)
            };
          })
          .sort((a, b) => (b.recordNumber > a.recordNumber ? 1 : -1))
      : [];

  return (
    <Grid data={data} className="infusion-grid f10px">
      <Column
        field="weightLb"
        title="WEIGHT (lbs)"
        headerCell={headerLbCell}
        cell={weightLbCell}
      />
      <Column
        field="weightKg"
        title="WEIGHT (kgs)"
        headerCell={headerKgCell}
        cell={weightKgCell}
      />
      <Column
        field="weightDate"
        title="ENTERED DATE"
        headerCell={headerDateCell}
        cell={weightDateCell}
      />
    </Grid>
  );
};

export default PatientWeightLog;
