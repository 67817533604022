import React, { useContext, useEffect, useState, useCallback } from 'react';

// Kendo
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { process, orderBy } from '@progress/kendo-data-query';

// components
import Preloader from '@/components/common-components/Preloader';
import BadgeInline from '@/components/common-components/BadgeInline';
import WorkItemGrid from './WorkItemGrid';

//context
import { UserContext } from '@/context';

// utils
import { cellWithBackGround } from './dashboardHelper';
import { getWorkItemTitle } from '../Queue/FilterSystem/QueueFilters.utils';

// style
import styles from './Dashboard.module.scss';

const Dashboard = ({ gridData, headingTitle }) => {
  const { user } = useContext(UserContext);
  const initialDataState = {};
  const initialSort = [
    {
      field: 'workItemName',
      dir: 'asc'
    }
  ];
  const [sort, setSort] = useState(initialSort);
  const [loading, setLoading] = useState(true);
  const [listWorkItems, setListWorkItems] = useState([]);
  const [dataState, setDataState] = useState();
  const [resultState, setResultState] = useState(
    process(listWorkItems, initialDataState)
  );

  const onDataStateChange = useCallback(
    (e) => {
      setDataState(e.dataState);
      setResultState(process(listWorkItems, e.dataState));
    },
    [listWorkItems]
  );

  const DashboardDetails = (props) => {
    const detailsData = props?.dataItem?.status;
    return <WorkItemGrid detailsData={detailsData} />;
  };

  const expandChange = (event) => {
    const newData = resultState.data.map((item) => {
      if (item.workItemName === event.dataItem.workItemName) {
        item.expanded = !event.dataItem.expanded;
      }
      return item;
    });
    setResultState(process(newData, dataState));
  };

  const workCell = ({ dataItem }) => {
    const counter = dataItem.workItems.fullData.length;
    const title = getWorkItemTitle(dataItem?.workItemName);

    if (counter > 0) {
      return (
        <td>
          <BadgeInline inlineText={title} type='info' text={counter} />
        </td>
      );
    }
    return <td>{title}</td>;
  };

  const columns = [
    {
      field: 'workItemName',
      title: 'Work Item',
      minWidth: 220,
      cell: workCell,
      footerCell: () => <td>Total:</td>
    }
  ];

  // MAIN INITIATOR
  useEffect(() => {
    if (user) {
      fetchWorkInProgressData();
    }
  }, []);

  useEffect(() => {
    if (!loading) {
      setDataState(initialDataState);
      setResultState(process(listWorkItems, initialDataState));
    }
  }, [listWorkItems]);

  const fetchWorkInProgressData = async () => {
    setLoading(false);
    setListWorkItems(gridData);
  };

  const footerCell = ({ field }) => {
    // Split the path string into an array of keys
    const keys = field.split('.');

    const sum = resultState.data.reduce((accumulator, currentValue) => {
      // Initialize a variable to store the result
      let result = currentValue;

      // Traverse the object using each key in the path
      for (const key of keys) {
        result = result[key];
      }
      return accumulator + result;
    }, 0);

    return <td className={sum === 0 ? styles.noValue : ''}>{sum}</td>;
  };

  const getDayGridColumns = () => {
    const cols = [
      {
        field: 'workItems.day0',
        title: 'First Day',
        cell: cellWithBackGround,
        footerCell
      },
      {
        field: 'workItems.day1',
        title: 'Day 1',
        cell: cellWithBackGround,
        footerCell
      },
      {
        field: 'workItems.day2',
        title: 'Day 2',
        cell: cellWithBackGround,
        footerCell
      },
      {
        field: 'workItems.day3',
        title: 'Day 3',
        cell: cellWithBackGround,
        footerCell
      },
      {
        field: 'workItems.day4',
        title: 'Day 4',
        cell: cellWithBackGround,
        footerCell
      },
      {
        field: 'workItems.day5',
        title: 'Day 5',
        cell: cellWithBackGround,
        footerCell
      },
      {
        field: 'workItems.day6',
        title: 'Day 6',
        cell: cellWithBackGround,
        footerCell
      },
      {
        field: 'workItems.day7',
        title: 'Day 7',
        cell: cellWithBackGround,
        footerCell
      },
      {
        field: 'workItems.day8',
        title: 'Day 8',
        cell: cellWithBackGround,
        footerCell
      },
      {
        field: 'workItems.day9',
        title: 'Day 9',
        cell: cellWithBackGround,
        footerCell
      },
      {
        field: 'workItems.day10',
        title: '10-14',
        cell: cellWithBackGround,
        footerCell
      },
      {
        field: 'workItems.day15',
        title: "15+ Day's",
        cell: cellWithBackGround
      }
    ];
    return cols;
  };
  const dayColumns = getDayGridColumns();

  return (
    <div className='container-fluid agent-work-queue'>
      {user?.username && (
        <div className='row my-3'>
          <div className='col-12 col-lg-12 work-in-progress'>
            <div className='a-grid__header'>
              <div>{headingTitle}</div>
            </div>
            <Preloader show={loading}>
              <Grid
                className='queue_grid'
                style={{ cursor: 'pointer' }}
                data={orderBy(resultState.data, sort)}
                sortable
                sort={sort}
                onSortChange={(e) => {
                  setSort(e.sort);
                }}
                onDataStateChange={onDataStateChange}
                {...dataState}
                expandField='expanded'
                onExpandChange={expandChange}
                detail={DashboardDetails}
                total={1} // total set to stop paging error in the browser. Remove if you want to use paging.
              >
                {columns.map((column, index) => {
                  return (
                    <Column key={index} width={column.minWidth} {...column} />
                  );
                })}

                <Column title='Aging Days in House'>
                  {dayColumns.map((column, index) => {
                    return (
                      <Column key={index} width={column.minWidth} {...column} />
                    );
                  })}
                </Column>
              </Grid>
            </Preloader>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
