import React, { Fragment } from 'react';
import MarkdownPreview from '@uiw/react-markdown-preview';

// components
import Badge from '@/components/common-components/Badge';

// config
import releaseNotes from '@/releases';

// styles
import styles from './Releases.module.scss';

const Releases = () => {
  return (
    <div className={styles.root}>
      <div className={styles.releaseGrid}>
        {releaseNotes.map((releaseData, idx) => (
          <Fragment key={idx}>
            <h3>Version {releaseData.version}</h3>
            <p>
              Release date: <Badge type="success" text={releaseData.date} />
            </p>
            <MarkdownPreview
              className={styles.markdownPreview}
              source={releaseData.notes}
              wrapperElement={{
                'data-color-mode': 'light'
              }}
            />
            <hr />
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export { Releases };
