export const parseQuestionaire = (questionaire) => {
  if (!questionaire) {
    return null;
  }

  if (typeof questionaire === 'string') {
    return JSON.parse(questionaire);
  }

  return questionaire;
};

export const showAnswers = (answer1, answer2) => {
  let answer = 'N/A';
  if (typeof answer1 === 'boolean') {
    answer = answer1 ? 'Yes' : 'No';
  }
  return answer2 ? answer.concat(`: ${answer2}`) : answer;
};
