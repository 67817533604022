import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import * as moment from 'moment';

// kendo
import { Form, Field } from '@progress/kendo-react-form';
import { Grid } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { GridColumn as Column } from '@progress/kendo-react-grid/dist/npm/GridColumn';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { TextArea } from '@progress/kendo-react-inputs';

// components
import { FormRadioGroup } from '@/components/common-components/FormRadioGroup';
import FormButton from '@/components/common-components/Form/FormButton';
import DeleteButton from '@/components/common-components/Form/DeleteButton';
import OrderPreMeds from './OrderPreMeds';
import OrderAdministrationCabenuva from './OrderAdministrationCabenuva';
import OrderAdministrationApretude from './OrderAdministrationApretude';
import OralLeadIn from './OralLeadIn';
import AttachReferralDocument from './AttachDocument/AttachReferralDocument';
import PrescriberAddressesCurrent from '@/components/Prescriber/PrescriberAddressesCurrent';
import LabDrawForm from './LabDraw/LabDrawForm';
import AwesomeLabel from '@/components/common-components/AwesomeLabel';
import AutoFilter from '@/common/AutoFilter';

// gql
import { connectToGraphqlAPI } from '@/provider';
import { getPatientHcpProfiles } from '@/graphql/queries';
import { createReferralOrder } from '@/graphql/mutations';

// context
import {
  UserContext,
  PatientContext,
  NotifContext,
  StatusContext,
  ProductContext,
  LogContext,
  UserRoleTypes,
  ReferralContext,
  PrescriberContext,
  InfoCodeContext
} from '@/context';

// utils
import { isWeightBasedUOM } from '@/common/DoseHelper';
import {
  DatePickerField,
  InputField,
  validateInput
} from '@/common/Validation';
import { Constants } from '@/constants';
import { mapUOMs, defaultUOMs, MG } from '@/constants/uom';
import { formatDateToAWS, formatDateToAWSDateTime } from '@/common/DateHelper';
import {
  isApretudeProduct,
  isCabenuvaProduct,
  isViiVProduct
} from '@/common/viivHelper';
import { getAdministerInstructions } from './NewOrder.utils';
import { toProperCase } from '@/common/Converters';
import {
  formatPrescriberAddress,
  formatPrescriberAddressTitle
} from '@/components/Prescriber/prescriberHelper';
import {
  dosageDateTimeFrames,
  dosageDayRanges,
  dosageFrequencyTypes,
  drugTypes,
  labDrawProduct,
  orderTypes,
  productLabNames,
  referralType,
  routes
} from './ReferralMaps';
import { getReferralId, getReferralType } from './ReferralHelpers';

// styles
import styles from './Referral.module.scss';

const NewOrder = ({ archivedOrder, onSelectArchiveOrder }) => {
  const { user, canEdit } = useContext(UserContext);
  const { selectedPatientInfo } = useContext(PatientContext);
  const { dxCodes } = useContext(InfoCodeContext);
  const { statusUpdate, setStatusUpdate } = useContext(StatusContext);
  const {
    referralOrderUrl,
    referralOrderDate,
    archivedReason,
    setArchivedReason,
    itemAdministrations,
    setItemAdministrations,
    adminOverEverySelection,
    setAdminOverEverySelection,
    setReferralHeaderNotes,
    referralHeaderNotes,
    orderFormData,
    setOrderFormData,
    patientReferralIds,
    patientReferralDrugNames
  } = useContext(ReferralContext);
  const { showError, showSuccess, showWarning } = useContext(NotifContext);
  const { products } = useContext(ProductContext);
  const { logApiException } = useContext(LogContext);
  const {
    listPrescriberOfficeAddresses,
    listCardPrescriberAddresses,
    setListPrescriberOfficeAddresses,
    setListCardPrescriberAddresses,
    listPatientHcpProfilesData,
    setListPatientHcpProfilesData
  } = useContext(PrescriberContext);

  const [listProductsDataFiltered, setListProductsDataFiltered] = useState([]);

  const [showOrderForm, setShowOrderForm] = useState(false);
  const [showLabDrawForm, setShowLabDrawForm] = useState(false);
  const [loading, setLoading] = useState(false);

  const [supportedUOM, setSupportedUOM] = useState(defaultUOMs);

  const [rxOrderUrl, setData] = useState('');
  const [addToStorage, setParentData] = useState('');
  const [originalReferralOrderDate, setOriginalReferralOrderDate] = useState();

  const isCabenuva = isCabenuvaProduct(orderFormData?.productId);
  const isApretude = isApretudeProduct(orderFormData?.productId);
  const isVIIV = isViiVProduct(orderFormData?.productId);
  const [labTestResultData, setLabTestResultData] = useState([]);
  const [adverseEventsData, setAdverseEventsData] = useState([]);

  const detailsFormRef = useRef(null);

  useEffect(() => {
    if (!archivedOrder) setItemAdministrations([]);
    if (archivedOrder?.referralType === referralType.LAB_DRAW) {
      setShowOrderForm(false);
      setShowLabDrawForm(true);
    }
    if (archivedOrder && listProductsDataFiltered?.length > 0) {
      const selectedProduct = listProductsDataFiltered.find(
        (product) => product.productOnSelector === archivedOrder.drugName
      );
      handleAddProduct({ product: selectedProduct });
      if (archivedReason === 'WRONG_DRUG' || archivedReason === 'WRONG_DOSE') {
        setOriginalReferralOrderDate(archivedOrder.originalReceivedDate);
        setArchivedReason();
      } else {
        setOriginalReferralOrderDate();
        setArchivedReason();
      }
      // notes
      if (archivedOrder?.referralOrder?.notes) {
        setReferralHeaderNotes(archivedOrder?.referralOrder?.notes);
      }
      // premedication
      if (archivedOrder?.referralOrder?.preMedications) {
        setItemPreMeds(archivedOrder?.referralOrder?.preMedications);
      }
      // administrations
      if (archivedOrder?.referralOrder?.administrations) {
        setItemAdministrations(archivedOrder?.referralOrder?.administrations);
      }
      if (archivedOrder?.orderNotes?.labTests) {
        setLabTestResultData(archivedOrder.orderNotes.labTests);
      }
      if (archivedOrder?.adverseEvent) {
        setAdverseEventsData(archivedOrder.adverseEvent);
      }

      // selected office address id

      // primaryDX and otherDX
      if (archivedOrder?.referralOrder?.primaryDX) {
        const icd10code = dxCodes.find(
          (listCodeInfo) =>
            listCodeInfo.code ===
            archivedOrder.referralOrder.primaryDX.primaryDiagnosis
        );

        const codeDescriptions = [];
        const iPrimaryDX = [];

        if (icd10code) {
          codeDescriptions.push({
            ...icd10code,
            name: 'primaryDX-0'
          });

          setOrderFormData((orderFormData) => ({
            ...orderFormData,
            'primaryDX-0': icd10code
          }));
        }

        const offSet = codeDescriptions.length;
        if (archivedOrder?.referralOrder?.otherDX?.length > 0) {
          archivedOrder.referralOrder.otherDX.forEach((dx, index) => {
            const key = `primaryDX-${index + offSet}`;
            const data = dxCodes.find(
              (listCodeInfo) => listCodeInfo.code === dx.primaryDiagnosis
            );
            if (data) {
              codeDescriptions.push({
                ...data,
                name: key
              });
              setOrderFormData((orderFormData) => ({
                ...orderFormData,
                [key]: data
              }));
              iPrimaryDX.push(index + offSet);
            }
          });
        }
        setSelectedCodeDescription(codeDescriptions);

        if (iPrimaryDX.length < 3) {
          iPrimaryDX.push(0);
        }
        setIPrimaryDX(iPrimaryDX);
      }

      // prescriber HCP
      if (archivedOrder?.prescriberId) {
        const prescribingHCP = listPatientHcpProfilesData.find(
          (item) => item.value === archivedOrder?.prescriberId
        );
        setOrderFormData((orderFormData) => ({
          ...orderFormData,
          prescribingHCP
        }));

        setListPrescriberOfficeAddresses(
          prescribingHCP?.officeAddresses.map((address) => ({
            value: address.id,
            text: formatPrescriberAddress(address),
            default:
              prescribingHCP?.officeAddresses.length > 1
                ? false
                : address.default
          }))
        );
        setListCardPrescriberAddresses(prescribingHCP?.officeAddresses);
        // update form values
        if (detailsFormRef?.current?.onChange) {
          detailsFormRef.current.onChange('prescribingHCP', {
            value: prescribingHCP
          });

          const officeAddresses = prescribingHCP?.officeAddresses;
          setListPrescriberOfficeAddresses(
            officeAddresses.map((address) => ({
              value: address.id,
              text: formatPrescriberAddressTitle(address),
              default:
                prescribingHCP?.officeAddresses.length > 1
                  ? false
                  : address.default
            }))
          );
          setListCardPrescriberAddresses(officeAddresses);
        }
      }
    }
  }, [
    archivedOrder,
    listProductsDataFiltered,
    listPatientHcpProfilesData,
    detailsFormRef
  ]);

  useEffect(() => {
    if (products) {
      const filtered = products
        .map((item) => ({
          productId: item.productId,
          productOnSelector: item.productName,
          productName: item.productName,
          possibleUOMs: item.possibleUOMs
        }))
        .sort((a, b) => (a.productName > b.productName ? 1 : -1));
      const unique = Array.from(
        new Set(filtered.map((a) => a.productName))
      ).map((productName) => {
        return filtered.find((a) => a.productName === productName);
      });

      setListProductsDataFiltered(unique);
    }
  }, [products]);

  useEffect(() => {
    if (archivedOrder?.prescriberOfficeAddressId) {
      const defaultAddress = !detailsFormRef.current?.valueGetter(
        'selectedPrescriberOfficeAddress'
      )
        ? listPrescriberOfficeAddresses?.find(
            (address) =>
              address.value === archivedOrder?.prescriberOfficeAddressId
          )
        : listPrescriberOfficeAddresses?.find((address) => !!address.default) ||
          null;

      // populate address dropdown
      if (detailsFormRef?.current?.onChange) {
        detailsFormRef.current.onChange('selectedPrescriberOfficeAddress', {
          value: defaultAddress
        });
      }
    } else {
      const defaultAddress =
        listPrescriberOfficeAddresses?.find((address) => !!address.default) ||
        null;

      // populate address dropdown
      if (detailsFormRef?.current?.onChange) {
        detailsFormRef.current.onChange('selectedPrescriberOfficeAddress', {
          value: defaultAddress
        });
      }
    }
  }, [listPrescriberOfficeAddresses, archivedOrder]);

  const childToParent = (childdata) => {
    setData(childdata);
  };

  const parentToChild = (dataItem) => {
    setParentData(dataItem);
  };

  const [selectedUOMs, setselectedUOMs] = useState();

  const removePreMed = (itemIndex) => {
    const preMedList = [...itemPreMeds];
    preMedList.splice(itemIndex, 1);
    setItemPreMeds(preMedList);
  };

  const [itemPreMeds, setItemPreMeds] = useState([]);

  const weightMeasOptions = ['LB', 'KG'];
  const [patientWeight, setPatientWeight] = useState(0);
  const [unitOfWeight, setUnitOfWeight] = useState(weightMeasOptions[0]);

  const [selectedCodeDescription, setSelectedCodeDescription] = useState([]);
  /* primaryDX indices (indexes) */
  const [iPrimaryDX, setIPrimaryDX] = useState([0]);
  const addIPrimaryDX = (index) => {
    if (selectedCodeDescription.length > index) {
      setIPrimaryDX([...iPrimaryDX, index]);
    } else {
      const sequenceName = index === 0 ? 'second' : 'third';

      showError(
        `Error: Primary ICD10 Code required before adding ${sequenceName} code.`
      );
    }
  };

  const codeDescriptionChange = (value, name, formRenderProps) => {
    setOrderFormData((orderFormData) => ({ ...orderFormData, [name]: value }));
    const copySelectedCodeDescription = [...selectedCodeDescription];
    if (
      value &&
      dxCodes.find((listCodeInfo) => listCodeInfo.codeDescription === value)
    ) {
      const matchedName = copySelectedCodeDescription.find(
        (item) => item.name === name
      );
      if (matchedName) {
        matchedName.code =
          dxCodes.find((listCodeInfo) => listCodeInfo.codeDescription === value)
            ?.code || '';
        matchedName.description =
          dxCodes.find((listCodeInfo) => listCodeInfo.codeDescription === value)
            ?.description || '';
      } else {
        const obj = {
          ...dxCodes.find(
            (listCodeInfo) => listCodeInfo.codeDescription === value
          ),
          name
        };
        copySelectedCodeDescription.push(obj);
      }
      setSelectedCodeDescription(copySelectedCodeDescription);
      if (formRenderProps?.onChange) {
        formRenderProps.onChange(name, { value });
      }
    } else if (!value) {
      const index = copySelectedCodeDescription.findIndex(
        (item) => item.name === name
      );
      copySelectedCodeDescription.splice(index, 1);
      setSelectedCodeDescription(copySelectedCodeDescription);
      if (formRenderProps?.onChange) {
        formRenderProps.onChange(name, { value: null });
      }
    }
  };

  // MAIN INITIATOR
  useEffect(() => {
    getPatientHcpProfilesCall();
  }, []);

  const getPatientHcpProfilesCall = async () => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getPatientHcpProfiles,
        variables: { patientId: selectedPatientInfo.patientId }
      });

      if (data?.data?.getPatientBucket?.hcpProfile?.items?.length) {
        setListPatientHcpProfilesData(
          data.data.getPatientBucket.hcpProfile.items
            .filter((item) => item.prescriber)
            .map((item) => {
              if (item.prescriber) {
                return {
                  text: `${toProperCase(
                    item.prescriber.prescriberFirstName
                  )} ${toProperCase(item.prescriber.prescriberLastName)} (#${
                    item.prescriber.NPINumber
                  })`,
                  value: item.prescriber.NPINumber,
                  officeAddresses: item.prescriber?.officeAddresses
                };
              }

              return {
                text: '',
                value: 0
              };
            })
        );
      }
    } catch (err) {
      logApiException(err, {
        view: 'NewOrder',
        endpoint: 'getPatientHcpProfiles',
        patientId: selectedPatientInfo.patientId
      });
      showError('Loading HCP profiles failed');
    }
  };

  const handleAddProduct = (dataObject) => {
    //Clear old data first
    setListCardPrescriberAddresses();
    setOrderFormData({});
    setselectedUOMs();
    setReferralHeaderNotes();
    //////
    const productType =
      dataObject?.product?.productName || labDrawProduct.productName;
    const selectedProduct = dataObject?.product || labDrawProduct; // searchTableData.filter(item => item.selected)
    setOrderFormData((orderFormData) => ({
      ...orderFormData,
      product: selectedProduct,
      productId: selectedProduct.productId,
      productName: selectedProduct.productName
    }));
    // Check and see if there is a referral with the same drug name
    const isSameDrug = patientReferralDrugNames.includes(
      selectedProduct.productName
    );
    if (isSameDrug) {
      showWarning(
        'Warning: A referral with the same drug name already exists.'
      );
    }
    // map supported UOMs
    const mappedUoms = mapUOMs(selectedProduct?.possibleUOMs);
    setSupportedUOM(mappedUoms);
    if (productType === referralType.LAB_DRAW_TEXT) {
      setShowLabDrawForm(true);
    } else {
      setShowOrderForm(true);
    }
  };

  const resetOrderForm = () => {
    setOrderFormData();
    setSelectedCodeDescription([]);
    setIPrimaryDX([0]);
    setItemAdministrations([]);
    setItemPreMeds([]);
    setShowOrderForm(false);
    setShowLabDrawForm(false);
  };

  const handleAddAdministration = (dataItem) => {
    const isLabDraw = dataItem.orderName === productLabNames.LAB_DRAW_NAME;
    const administer = getAdministerInstructions(dataItem);

    const getDosage = (adminDose) => {
      if (isCabenuva) {
        return adminDose.cabenuvaDosage === '400/600 mg' ? 1000 : 1500;
      }

      if (isApretude) {
        return 600;
      }

      return adminDose.dosageOverride;
    };

    const uom = isVIIV ? MG : dataItem.unitOfMeas;
    const route = isLabDraw ? 'IV' : isVIIV ? 'IM' : dataItem.route;
    const dose = getDosage(dataItem);
    const needCalculation = isWeightBasedUOM(uom);
    const weightInKg =
      unitOfWeight === 'KG' ? patientWeight : Math.round(patientWeight / 2.2);
    const calcDosage = needCalculation ? weightInKg * dose : dose;

    const itemAdministration = {
      drugName: orderFormData.productName,
      route,
      maxOfTreatments: dataItem.numTreatments,
      approvedDosage: dose || +dose,
      dose,
      unitOfMeas: uom,
      calcDosage,
      administer,
      dosageFrequencyType: dataItem.dosageFrequencyType,
      dosageDayRange: dataItem.dosageDayRange,
      dosageEvery: dataItem.dosageEvery,
      dosageDateTimeFrameEvery: dataItem.dosageDateTimeFrameEvery,
      dosageFor: dataItem.dosageFor,
      dosageDateTimeFrameFor: dataItem.dosageDateTimeFrameFor
    };

    // @TODO: extend ViiV drugs with Oral-lead In values
    try {
      setItemAdministrations([...itemAdministrations, itemAdministration]);
    } catch (err) {
      console.error('NewOrder::handleAddAdministration err:', err);
      showError('Adding new administration failed');
    }
  };

  const preMedAdd = (medItem) => {
    setItemPreMeds([...itemPreMeds, medItem]);
  };

  const handleSubmitOrder = (dataItem) => {
    const orderName = dataItem.orderName;
    /* orderName: "LAB DRAW" */
    /* if (!itemAdministrations.length) {
      // eslint-disable-next-line no-alert
      alert('No drug administrations set. Please add these before saving.');
      return null;
    } */

    const otherDX = [];
    const primaryDX1 = selectedCodeDescription.find(
      (item) => item.name === 'primaryDX-1'
    );
    if (selectedCodeDescription.find((item) => item.name === 'primaryDX-1')) {
      otherDX.push({
        diagnosedBy: '',
        primaryDiagnosis: primaryDX1?.code || '', // dataItem["primaryDX-0"], // dataItem.primaryDX, // "ICD10"
        description: primaryDX1?.description || ''
      });
    }
    const primaryDX2 = selectedCodeDescription.find(
      (item) => item.name === 'primaryDX-2'
    );
    if (primaryDX2) {
      otherDX.push({
        primaryDiagnosis: primaryDX2?.code || '', // dataItem["primaryDX-0"], // dataItem.primaryDX, // "ICD10"
        description: primaryDX2?.description || '',
        diagnosedBy: ''
      });
    }

    const requestObject = {
      agentId: user.username,
      patientId: selectedPatientInfo.patientId,
      drugReferral: {
        referralType: getReferralType(orderName),
        referralId: getReferralId(dataItem, orderFormData),
        drugId: orderFormData.productId,
        drugName: orderFormData.productName,
        isCompleted: false,
        prescriberId: dataItem.prescribingHCP?.value
          ? dataItem.prescribingHCP.value
          : '',
        prescriberOfficeAddressId:
          dataItem.selectedPrescriberOfficeAddress?.value || 0,
        drugType: dataItem?.drugType?.value,
        noOfTreatments: 0,
        rxOrderFile: referralOrderUrl,
        originalReceivedDate: originalReferralOrderDate || referralOrderDate,
        ...(archivedOrder && {
          orderNotes: {
            allergies: [],
            labTests: labTestResultData.map((item) => ({
              date: formatDateToAWSDateTime(item.date),
              drugName: item.drugName,
              note: `${item.note}`,
              type: 'LAB_TESTS',
              labTest: item.labTest,
              labExpiration: formatDateToAWS(item.labExpiration),
              results: JSON.stringify({ labTestValue: item.labTestValue })
            }))
          },
          adverseEvent: adverseEventsData.map((item) => ({
            date: formatDateToAWS(item.date),
            drugName: item.drugName,
            details: item.details
          }))
        }),
        referralOrder: {
          orderName: dataItem.orderName
            ? dataItem.orderName
            : orderFormData.productName.toUpperCase(),
          orderType: dataItem?.orderType?.value,
          medicationType: dataItem?.drugType?.value,
          orderDate: formatDateToAWS(dataItem.orderDate),
          orderExpires: formatDateToAWS(dataItem.orderExpires),
          primaryDX: {
            primaryDiagnosis:
              selectedCodeDescription.find(
                (item) => item.name === 'primaryDX-0'
              )?.code || '',
            description:
              selectedCodeDescription.find(
                (item) => item.name === 'primaryDX-0'
              )?.description || '',
            diagnosedBy: ''
          },
          otherDX,
          patientWeight,
          unitOfWeight,
          administrations: itemAdministrations?.map((item, index) => {
            item.calcDosage = item.calcDosage || 0;
            item.adminSequenceNumber = index + 1;
            return item;
          }),
          preMedications: itemPreMeds,
          notes: referralHeaderNotes
        }
      }
    };

    if (dataItem.firstInjectionDate || dataItem.oralLeadInDate) {
      const oralLeadIn = {};

      if (dataItem.firstInjectionDate) {
        oralLeadIn.dateOfFirstInjection = formatDateToAWSDateTime(
          dataItem.firstInjectionDate
        );
      }

      if (dataItem.oralLeadInDate) {
        oralLeadIn.dateOfLastDose = formatDateToAWSDateTime(
          dataItem.oralLeadInDate
        );
      }

      requestObject.drugReferral.orderNotes = { oralLeadIn };
    }
    // Check that no patientReferralIds match requestObject.drugReferral.referralId
    const doesReferralIdExist = patientReferralIds?.includes(
      requestObject.drugReferral.referralId
    );
    if (!doesReferralIdExist) {
      createReferralOrderCall(requestObject);
    } else {
      showError(
        'Referral ID already exists. Please check for an existing order.'
      );
    }
  };

  const getOfficeAddresses = (event) => {
    const officeAddresses = event.value?.officeAddresses;
    setListPrescriberOfficeAddresses(
      officeAddresses.map((address) => ({
        value: address.id,
        text: formatPrescriberAddress(address),
        default: officeAddresses.length > 1 ? false : address.default
      }))
    );
    setListCardPrescriberAddresses(officeAddresses);
  };
  const handleDeleteClick = (props) => {
    const clonedItems = [...itemAdministrations];
    clonedItems.splice(props.dataIndex, 1);
    setItemAdministrations(clonedItems);
  };

  const customCellDeleteProductAdmins = (props) => {
    return (
      <td>
        <DeleteButton handleClick={() => handleDeleteClick(props)} />
      </td>
    );
  };
  const createReferralOrderCall = async (requestObject) => {
    setLoading(true);
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: createReferralOrder,
        variables: { input: requestObject }
      });
      if (data?.data?.createReferralOrder) {
        setLoading(false);
        showSuccess('New Order saved sucessfully');
        setStatusUpdate(!statusUpdate);
        onSelectArchiveOrder(null);
        resetOrderForm();
      }
    } catch (err) {
      setLoading(false);
      logApiException(err, {
        view: 'NewOrder',
        endpoint: 'createReferralOrder',
        input: requestObject
      });
      showError(err.errors[0].message);
    }
  };

  const productForm = {
    product: {
      value: orderFormData?.product || null,
      inputValidator: (value) => {
        return validateInput({
          product: { ...orderFormData?.product, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        }
      ]
    }
  };

  const newOrderForm = {
    orderId: {
      value: orderFormData?.orderId || '',
      inputValidator: (value) => {
        return validateInput({ orderId: { ...newOrderForm.orderId, value } });
      },
      validations: []
    },
    orderName: {
      value: orderFormData?.orderName || '',
      inputValidator: (value) => {
        return validateInput({
          orderName: { ...newOrderForm.orderName, value }
        });
      },
      validations: []
    },
    orderType: {
      value: orderFormData?.orderType || '',
      inputValidator: (value) => {
        return validateInput({
          orderType: { ...newOrderForm.orderType, value }
        });
      },
      validations: []
    },
    prescribingHCP: {
      value: orderFormData?.prescribingHCP || '',
      inputValidator: (value) => {
        return validateInput({
          prescribingHCP: { ...newOrderForm.prescribingHCP, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        }
      ]
    },
    selectedPrescriberOfficeAddress: {
      value: orderFormData?.selectedPrescriberOfficeAddress || '',
      inputValidator: (value) => {
        return validateInput({
          selectedPrescriberOfficeAddress: {
            ...newOrderForm.selectedPrescriberOfficeAddress,
            value
          }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        }
      ]
    },
    drugType: {
      value: orderFormData?.drugType || '',
      inputValidator: (value) => {
        return validateInput({ drugType: { ...newOrderForm.drugType, value } });
      },
      validations: []
    },
    orderDate: {
      value: orderFormData?.orderDate || null,
      inputValidator: (value) => {
        return validateInput({
          orderDate: { ...newOrderForm.orderDate, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        },
        {
          type: 'dateTodayFuture',
          message: Constants.ErrorMessage.dateFuture
        }
      ]
    },
    orderExpires: {
      value: orderFormData?.orderExpires || null,
      inputValidator: (value) => {
        return validateInput({
          orderExpires: { ...newOrderForm.orderExpires, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        },
        {
          type: 'datePast',
          message: Constants.ErrorMessage.datePast
        }
      ]
    },
    primaryDX: {
      value: orderFormData?.primaryDX || '',
      inputValidator: (value) => {
        return validateInput({
          primaryDX: { ...newOrderForm.primaryDX, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        }
      ]
    },
    'primaryDX-0': {
      value: orderFormData?.['primaryDX-0'] || '',
      inputValidator: () => {
        return validateInput({
          'primaryDX-0': {
            ...newOrderForm['primaryDX-0'],
            value: newOrderForm['primaryDX-0'].value
          }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        }
      ]
    },
    'primaryDX-1': {
      value: orderFormData?.['primaryDX-1'] || '',
      inputValidator: () => {
        return validateInput({
          'primaryDX-1': {
            ...newOrderForm?.['primaryDX-1'],
            value: newOrderForm['primaryDX-1'].value
          }
        });
      },
      validations: []
    },
    'primaryDX-2': {
      value: orderFormData?.['primaryDX-2'] || '',
      inputValidator: () => {
        return validateInput({
          'primaryDX-2': {
            ...newOrderForm?.['primaryDX-2'],
            value: newOrderForm['primaryDX-2'].value
          }
        });
      },
      validations: []
    },
    approvedDosage: {
      value: orderFormData?.approvedDosage || '',
      inputValidator: (value) => {
        return validateInput({
          approvedDosage: { ...newOrderForm.approvedDosage, value }
        });
      },
      validations: []
    },
    maxNumTreatments: {
      value: orderFormData?.maxNumTreatments || '',
      inputValidator: (value) => {
        return validateInput({
          maxNumTreatments: { ...newOrderForm.maxNumTreatments, value }
        });
      },
      validations: []
    },
    otherDosage: {
      value: orderFormData?.otherDosage || '',
      inputValidator: (value) => {
        return validateInput({
          otherDosage: { ...newOrderForm.otherDosage, value }
        });
      },
      validations: []
    }
  };

  // const validateEmptyFloat = value => {
  //   if (!value || value === "") return "";
  //   return (value) ? "" : Constants.ErrorMessage.Numeric_Required;
  // };

  const administrationForm = {
    dosageFrequencyType: {
      value: orderFormData?.dosageFrequencyType || '',
      inputValidator: (value) => {
        return validateInput({
          dosageFrequencyType: {
            ...administrationForm.dosageFrequencyType,
            value
          }
        });
      },
      validations: []
    },
    dosageDayRange: {
      value: orderFormData?.dosageDayRange || '',
      inputValidator: (value) => {
        return validateInput({
          dosageDayRange: { ...administrationForm.dosageDayRange, value }
        });
      },
      validations: []
    },
    dosageEvery: {
      value: orderFormData?.dosageEvery || '',
      inputValidator: (value) => {
        return validateInput({
          dosageEvery: { ...administrationForm.dosageEvery, value }
        });
      },
      validations: [
        {
          type: 'onlyNumeric',
          message: Constants.ErrorMessage.Numeric_Required
        }
      ]
    },
    dosageDateTimeFrameEvery: {
      value: orderFormData?.dosageDateTimeFrameEvery || '',
      inputValidator: (value) => {
        return validateInput({
          dosageDateTimeFrameEvery: {
            ...administrationForm.dosageDateTimeFrameEvery,
            value
          }
        });
      },
      validations: []
    },
    dosageFor: {
      value: orderFormData?.dosageFor || '',
      inputValidator: (value) => {
        return validateInput({
          dosageFor: { ...administrationForm.dosageFor, value }
        });
      },
      validations: [
        {
          type: 'onlyNumeric',
          message: Constants.ErrorMessage.Numeric_Required
        }
      ]
    },
    dosageDateTimeFrameFor: {
      value: orderFormData?.dosageDateTimeFrameFor || '',
      inputValidator: (value) => {
        return validateInput({
          dosageDateTimeFrameFor: {
            ...administrationForm.dosageDateTimeFrameFor,
            value
          }
        });
      },
      validations: []
    },
    route: {
      value: orderFormData?.route || '',
      inputValidator: (value) => {
        return validateInput({ route: { ...administrationForm.route, value } });
      },
      validations: []
    },
    dosageOverride: {
      value: orderFormData?.dosageOverride || '',
      inputValidator: (value) => {
        return validateInput({
          dosageOverride: { ...administrationForm.dosageOverride, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        }
      ]
    },
    unitOfMeas: {
      value: orderFormData?.unitOfMeas || '',
      inputValidator: (value) => {
        return validateInput({
          unitOfMeas: { ...administrationForm.unitOfMeas, value }
        });
      },
      validations: []
    },
    numTreatments: {
      value: orderFormData?.numTreatments || '',
      inputValidator: (value) => {
        return validateInput({
          numTreatments: { ...administrationForm.numTreatments, value }
        });
      },
      validations: [
        {
          type: 'onlyNumeric',
          message: Constants.ErrorMessage.Numeric_Required
        }
      ]
    },
    patientWeightLB: {
      value: orderFormData?.patientWeightLB || '',
      inputValidator: (value) => {
        return validateInput({
          patientWeightLB: { ...administrationForm.patientWeightLB, value }
        });
      },
      validations: []
    },
    patientWeightKG: {
      value: orderFormData?.patientWeightKG || '',
      inputValidator: (value) => {
        return validateInput({
          patientWeightKG: { ...administrationForm.patientWeightKG, value }
        });
      },
      validations: []
    },

    patientWeight: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          patientWeight: { ...administrationForm.patientWeight, value }
        });
      },
      validations: [
        {
          type: 'anyNumberGreaterThanZero',
          message: Constants.ErrorMessage.PosNumeric_Required
        }
      ]
    },

    dosageAmountPerKG: {
      value: orderFormData?.dosageAmountPerKG || '',
      inputValidator: (value) => {
        return validateInput({
          dosageAmountPerKG: { ...administrationForm.dosageAmountPerKG, value }
        });
      },
      validations: []
    }
  };

  const initialProductForm = () => {
    if (!archivedOrder) {
      return {
        product: null
      };
    }

    return {
      product: listProductsDataFiltered.find(
        (item) => item.productId === archivedOrder?.drugId
      )
    };
  };

  const formValues = initialProductForm();
  const detailFormValues = useMemo(() => {
    const initialObject = {};
    if (!archivedOrder) {
      Object.keys(newOrderForm).forEach((key) => {
        if (key === 'orderName') {
          initialObject[key] = orderFormData?.productName?.toUpperCase() || '';
        } else {
          initialObject[key] = newOrderForm[key].value;
        }
      });
    } else {
      Object.keys(newOrderForm).forEach((key) => {
        if (['orderName'].includes(key)) {
          initialObject[key] = archivedOrder?.referralOrder[key]
            ? archivedOrder?.referralOrder[key]
            : orderFormData?.productName?.toUpperCase() || '';
        } else if (['orderType'].includes(key)) {
          initialObject[key] = archivedOrder?.referralOrder[key]
            ? orderTypes.find(
                (orderType) =>
                  orderType.value === archivedOrder?.referralOrder[key]
              )
            : null;
        } else if (['orderDate', 'orderExpires'].includes(key)) {
          initialObject[key] = archivedOrder?.referralOrder[key]
            ? new Date(moment(archivedOrder?.referralOrder[key]))
            : null;
        } else if (['drugType'].includes(key)) {
          initialObject[key] = drugTypes.find(
            (drugType) => drugType.value === archivedOrder[key]
          );
        } else if (key === 'prescribingHCP') {
          const found = archivedOrder?.prescriberId
            ? listPatientHcpProfilesData.find(
                (item) => item.value == archivedOrder?.prescriberId
              )
            : null;
          initialObject[key] = found || null;
        } else if (key === 'primaryDX-0') {
          const data = archivedOrder?.referralOrder?.primaryDX?.primaryDiagnosis
            ? dxCodes.find(
                (listCodeInfo) =>
                  listCodeInfo.code ===
                  archivedOrder.referralOrder.primaryDX.primaryDiagnosis
              )
            : null;
          initialObject[key] = data || null;
        }
      });

      if (archivedOrder?.referralOrder?.otherDX?.length > 0) {
        archivedOrder.referralOrder.otherDX.forEach((dx, index) => {
          const key = `primaryDX-${index + 1}`;
          const data = dxCodes.find(
            (listCodeInfo) => listCodeInfo.code === dx.primaryDiagnosis
          );
          initialObject[key] = data || null;
        });
      }
    }
    return initialObject;
  }, [
    archivedOrder,
    listPatientHcpProfilesData,
    dxCodes,
    orderFormData?.productName
  ]);

  return (
    <div className='row'>
      <div className='col'>
        <Form
          onSubmit={handleAddProduct}
          initialValues={formValues}
          key={JSON.stringify(formValues)}
          render={(formRenderProps) => (
            <form
              id='formSearchProduct'
              onSubmit={formRenderProps.onSubmit}
              className='k-form pl-3 pr-3 pt-1'
            >
              <div className='row'>
                <div className='col-md-12 pageTitle'>Create New Order</div>
              </div>
              <div className='row'>
                <div className='col-10'>
                  <div className='row'>
                    <div className='col-md-3 mt-14'>New Medication:</div>
                    <div className='col-md-5'>
                      <Field
                        name='product'
                        label='Product Name'
                        component={DropDownList}
                        data={listProductsDataFiltered}
                        textField='productOnSelector'
                        valueField='productId'
                        validator={productForm.product.inputValidator}
                        disabled={showLabDrawForm}
                      />
                    </div>
                    <div className='col-md-2 mt-12'>
                      <button
                        disabled={
                          !canEdit(UserRoleTypes.NewOrder) || showLabDrawForm
                        }
                        type='submit'
                        className='k-button pageButton'
                        form='formSearchProduct'
                      >
                        <AwesomeLabel label='Create Referral' icon='memo' />
                      </button>
                    </div>
                  </div>
                </div>
                <div className='col-2 mt-12'>
                  <Button
                    type='button'
                    className='k-button labDrawButton blue'
                    disabled={showOrderForm}
                    onClick={() => {
                      setShowOrderForm(false);
                      handleAddProduct();
                    }}
                  >
                    <AwesomeLabel label='Create Lab Draw' icon='vial' />
                  </Button>
                </div>
              </div>
              <hr />
            </form>
          )}
        />
        {showOrderForm && (
          <article>
            <Form
              onSubmit={handleSubmitOrder}
              initialValues={detailFormValues}
              ref={detailsFormRef}
              render={(formRenderProps) => (
                <form
                  id='formOrder'
                  onSubmit={formRenderProps.onSubmit}
                  className='k-form pl-3 pr-3 pt-1'
                >
                  <AttachReferralDocument isNewOrder />
                  {/*  <hr /> */}
                  <div className='row mt-10'>
                    <div className='col-10'>
                      <div className='row'>
                        <div className='col-md-2'>ORDER NAME:</div>
                        <div className='col-md-4'>
                          <Field
                            name='orderId'
                            type='hidden'
                            component={InputField}
                            validator={newOrderForm.orderId.inputValidator}
                          />
                          <Field
                            name='orderName'
                            component={InputField}
                            placeholder={orderFormData?.productOnSelector?.toUpperCase()}
                            validator={newOrderForm.orderName.inputValidator}
                          />
                        </div>
                        <div className='col-md-2'>PRESCRIBING HCP:</div>
                        <div className='col-md-4'>
                          <Field
                            name='prescribingHCP'
                            label=''
                            component={DropDownList}
                            data={listPatientHcpProfilesData}
                            textField='text'
                            valueField='value'
                            validator={
                              newOrderForm.prescribingHCP.inputValidator
                            }
                            onChange={(event) => getOfficeAddresses(event)}
                          />
                        </div>
                      </div>

                      <div className='row mt-14'>
                        <div className='col-md-3'>
                          PRESCRIBING HCP OFFICE ADDRESS:
                        </div>
                        <div className='col-md-8'>
                          <Field
                            name='selectedPrescriberOfficeAddress'
                            label=''
                            component={DropDownList}
                            data={listPrescriberOfficeAddresses}
                            textField='text'
                            valueField='value'
                            validator={
                              newOrderForm.selectedPrescriberOfficeAddress
                                .inputValidator
                            }
                          />
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col ml-3'>
                          <Field
                            name='OfficeAddress'
                            component={() => (
                              <PrescriberAddressesCurrent
                                addresses={listCardPrescriberAddresses}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className='row mt-1'>
                        <div className='col-md-3'>
                          <Field
                            name='orderDate'
                            label='Order Date'
                            component={DatePickerField}
                            onChange={(event) => {
                              const expDate = new Date(event.value);
                              expDate.setFullYear(expDate.getFullYear() + 1);
                              // expDate.setDate(expDate.getDate() - 1);

                              formRenderProps.onChange('orderExpires', {
                                value: expDate
                              });
                            }}
                            validator={newOrderForm.orderDate.inputValidator}
                          />
                        </div>
                        <div className='col-md-3'>
                          <Field
                            name='orderExpires'
                            label='Expiration Date'
                            component={DatePickerField}
                            validator={newOrderForm.orderExpires.inputValidator}
                          />
                        </div>
                        <div className='col-md-6'>
                          {iPrimaryDX.map((__, index) => (
                            <div
                              key={`primaryDX-${index}`}
                              className='row icd10-box'
                            >
                              <div className='col-md-10'>
                                <Field
                                  name={`primaryDX-${index}`}
                                  label='ICD10 Code'
                                  component={AutoFilter}
                                  data={dxCodes}
                                  textField='codeDescription'
                                  valueField='code'
                                  onChangeCallback={(value, name) => {
                                    codeDescriptionChange(
                                      value,
                                      name,
                                      formRenderProps
                                    );
                                  }}
                                  validator={
                                    newOrderForm[`primaryDX-${index}`]
                                      .inputValidator
                                  }
                                  savedValue={
                                    selectedCodeDescription[index]
                                      ? selectedCodeDescription[index]
                                          .codeDescription
                                      : null
                                  }
                                />
                              </div>
                              {index < 2 && iPrimaryDX.length - 1 === index && (
                                <div className='col-md-2 k-textbox-container'>
                                  <Button
                                    type='button'
                                    look='flat'
                                    icon='plus'
                                    onClick={() => addIPrimaryDX(index)}
                                  >
                                    Add
                                  </Button>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className='red' />
                  <div className='row'>
                    <div className='col-md-2'>ORDER TYPE:</div>
                    <div className='col-md-3'>
                      <Field
                        name='orderType'
                        label=''
                        component={DropDownList}
                        data={orderTypes}
                        textField='text'
                        valueField='value'
                        validator={newOrderForm.orderType.inputValidator}
                      />
                    </div>
                    <div className='col-md-2'>DRUG TYPE:</div>
                    <div className='col-md-3'>
                      <Field
                        name='drugType'
                        label=''
                        component={DropDownList}
                        data={drugTypes}
                        textField='text'
                        valueField='value'
                        validator={newOrderForm.drugType.inputValidator}
                      />
                    </div>
                  </div>
                  <hr />

                  {isVIIV && (
                    <>
                      <OralLeadIn formRenderProps={formRenderProps} />
                      <hr />
                    </>
                  )}
                  <div className='row'>
                    <div className='col-md-2 mt-14'>PRE-MEDICATION:</div>
                    <div className='col-10'>
                      <OrderPreMeds
                        preMeds={itemPreMeds}
                        addPreMed={preMedAdd}
                        removePreMed={removePreMed}
                        formRenderProps={formRenderProps}
                      />
                    </div>
                  </div>
                  <hr />
                </form>
              )}
            />

            {/* ADMINISTRATION */}
            <Form
              onSubmit={handleAddAdministration}
              initialValues={detailFormValues}
              render={(formRenderProps) => (
                <form
                  id='formAdministration'
                  onSubmit={formRenderProps.onSubmit}
                  className='k-form pl-3 pr-3 pt-1'
                >
                  <div className='row mt-08'>
                    <div className='col-md-2 mt-08'>ADMINISTRATION:</div>
                    <div className='col-10'>
                      <div className='row'>
                        <div className='col-md-2'>
                          <Field
                            name='dosageFrequencyType'
                            component={FormRadioGroup}
                            layout='vertical'
                            data={dosageFrequencyTypes}
                            className='dosage-frequency-types'
                            style={{ lineHeight: '2.6em' }}
                            onChange={(event) =>
                              setAdminOverEverySelection(event.value)
                            }
                            validator={
                              administrationForm.dosageFrequencyType
                                .inputValidator
                            }
                          />
                        </div>
                        <div className='col-md-10'>
                          <div className='row'>
                            <div className='col-2 mt-12'>
                              <Field
                                name='dosageDayRange'
                                label=''
                                component={DropDownList}
                                data={dosageDayRanges.map((item) => item.value)}
                                validator={
                                  adminOverEverySelection === 'OVER' ||
                                  adminOverEverySelection === 'BOTH'
                                    ? administrationForm.dosageDayRange
                                        .inputValidator
                                    : null
                                }
                              />
                            </div>
                            <div className='col-md-10 mt-16'>
                              CONSECUTIVE DAYS
                            </div>
                          </div>
                          <hr />
                          <div className='row'>
                            <div className='col-md-2'>
                              <Field
                                name='dosageEvery'
                                component={InputField}
                                validator={
                                  adminOverEverySelection === 'EVERY' ||
                                  adminOverEverySelection === 'BOTH'
                                    ? administrationForm.dosageEvery
                                        .inputValidator
                                    : null
                                }
                              />
                            </div>
                            <div className='col-2'>
                              <Field
                                name='dosageDateTimeFrameEvery'
                                label=''
                                component={DropDownList}
                                data={dosageDateTimeFrames.map(
                                  (item) => item.value
                                )}
                                validator={
                                  adminOverEverySelection === 'EVERY' ||
                                  adminOverEverySelection === 'BOTH'
                                    ? administrationForm
                                        .dosageDateTimeFrameEvery.inputValidator
                                    : null
                                }
                              />
                            </div>
                            <div className='col-md-1'>FOR:</div>
                            <div className='col-md-2'>
                              <Field
                                name='dosageFor'
                                component={InputField}
                                validator={
                                  adminOverEverySelection === 'EVERY' ||
                                  adminOverEverySelection === 'BOTH'
                                    ? administrationForm.dosageFor
                                        .inputValidator
                                    : null
                                }
                              />
                            </div>
                            <div className='col-2'>
                              <Field
                                name='dosageDateTimeFrameFor'
                                label=''
                                component={DropDownList}
                                data={dosageDateTimeFrames.map(
                                  (item) => item.value
                                )}
                                validator={
                                  adminOverEverySelection === 'EVERY' ||
                                  adminOverEverySelection === 'BOTH'
                                    ? administrationForm.dosageDateTimeFrameFor
                                        .inputValidator
                                    : null
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <hr />

                      {/* non-ViiV administration form */}
                      {isVIIV || (
                        <div className='row'>
                          <div className='col-md-1'>Route:</div>
                          <div className='col-md-2'>
                            <Field
                              name='route'
                              component={DropDownList}
                              data={routes.map((item) => item.value)}
                              validator={
                                administrationForm.route.inputValidator
                              }
                            />
                          </div>
                          <div className='col-md-1'>Dosage:</div>
                          <div className='col-md-1'>
                            <Field
                              name='dosageOverride'
                              component={InputField}
                              validator={
                                administrationForm.dosageOverride.inputValidator
                              }
                            />
                          </div>
                          <div className='col-md-1'>UOM:</div>
                          <div className='col-md-2'>
                            <Field
                              name='unitOfMeas'
                              component={DropDownList}
                              data={supportedUOM}
                              value={selectedUOMs}
                              onChange={(event) => setselectedUOMs(event.value)}
                              validator={
                                administrationForm.unitOfMeas.inputValidator
                              }
                            />
                          </div>
                          <div className='col-md-2'># Treatments:</div>
                          <div className='col-md-1'>
                            <Field
                              name='numTreatments'
                              component={InputField}
                              validator={
                                administrationForm.numTreatments.inputValidator
                              }
                            />
                          </div>
                        </div>
                      )}
                      {/* ViiV - Cabenuva */}
                      {isCabenuva ? (
                        <OrderAdministrationCabenuva
                          treatValidator={
                            administrationForm.numTreatments.inputValidator
                          }
                        />
                      ) : null}
                      {/* ViiV - Apretude */}
                      {isApretude ? (
                        <OrderAdministrationApretude
                          treatValidator={
                            administrationForm.numTreatments.inputValidator
                          }
                        />
                      ) : null}

                      <hr />

                      {isWeightBasedUOM(selectedUOMs) && (
                        <div className='row'>
                          <div className='col-md-3 mt-12'>
                            <Field
                              name='patientWeight'
                              value={patientWeight}
                              component={InputField}
                              label='PATIENT WEIGHT'
                              onChange={(e) => setPatientWeight(e.target.value)}
                              // validator={validateEmptyFloat}
                              validator={
                                administrationForm.patientWeight.inputValidator
                              }
                            />
                          </div>
                          <div className='col-md-2 mt-12'>
                            <Field
                              name='weightMeas'
                              label='UOM'
                              component={DropDownList}
                              data={weightMeasOptions}
                              defaultValue={unitOfWeight}
                              onChange={(e) => setUnitOfWeight(e.target.value)}
                            />
                          </div>
                        </div>
                      )}

                      <div className='row'>
                        <div className='col-md-2 mt-14'>
                          <button
                            type='submit'
                            className='k-button blue'
                            form='formAdministration'
                          >
                            ADD
                          </button>
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col mt-14'>
                          <Grid
                            className='infusion-grid'
                            data={itemAdministrations}
                          >
                            <Column
                              field='drugName'
                              title='PRODUCT NAME'
                              width={160}
                            />
                            <Column field='route' title='ROUTE' width={80} />
                            <Column
                              field='administer'
                              title='ADMINISTER'
                              width={200}
                            />
                            <Column
                              field='maxOfTreatments'
                              title='MAX #'
                              width={80}
                            />

                            <Column
                              field='approvedDosage'
                              title='DOSE'
                              width={100}
                            />
                            <Column
                              field='unitOfMeas'
                              title='UOM'
                              width={150}
                            />
                            <Column
                              field='calcDosage'
                              title='CALC DOSE'
                              width={150}
                            />
                            <Column
                              title=''
                              cell={customCellDeleteProductAdmins}
                            />
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            />

            <hr />

            {/* {isVIIV ? <OralLeadIn /> : null} */}

            <hr />

            <div className='row mt-14 ml-3'>
              <div className='col-md-2'>NOTES:</div>
              <div className='col-10'>
                <div className='row'>
                  <TextArea
                    value={referralHeaderNotes}
                    id='referralHeaderNotes'
                    autoSize
                    rows={4}
                    className={styles.referralNote}
                    onChange={(e) => setReferralHeaderNotes(e.value)}
                  />
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-md-2 mt-14 ml-3'>
                <FormButton
                  type='submit'
                  className='k-button pageButton blue'
                  loading={loading}
                  label='Create New Order'
                  form='formOrder'
                  disabled={!referralOrderUrl}
                />
              </div>
              <div className='col-2'>
                {!referralOrderUrl && (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '8pt',
                      marginTop: '1.8rem'
                    }}
                  >
                    Alert: Please add RX Order.
                  </div>
                )}
              </div>
            </div>
          </article>
        )}
        {showLabDrawForm && (
          <LabDrawForm
            addIPrimaryDX={addIPrimaryDX}
            selectedCodeDescription={selectedCodeDescription}
            codeDescriptionChange={codeDescriptionChange}
            iPrimaryDX={iPrimaryDX}
            handleSubmitOrder={handleSubmitOrder}
            detailFormValues={detailFormValues}
            detailsFormRef={detailsFormRef}
            loading={loading}
            handleAddAdministration={handleAddAdministration}
          />
        )}
      </div>
    </div>
  );
};

export default NewOrder;
