import React, { useContext } from 'react';
// context
import { WorkItemContext } from '@/context';
// util
import { getAudience } from '@/common/WorkItemHelper';

const WorkItemAudience = ({ callTitle }) => {
  const { selectedWorkItem } = useContext(WorkItemContext);
  const audience = callTitle
    ? getAudience(selectedWorkItem)
    : `-${getAudience(selectedWorkItem)}`;
  return audience;
};

export default WorkItemAudience;
