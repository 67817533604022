import React from 'react';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import PatientInfoGeneral from './PatientInfoGeneral';
import PatientAllergies from './PatientAllergies';
import PatientMeds from './PatientMeds';
import PatientSurveys from './PatientSurveys';

const PatientInfo = () => {
  const [selectedTab, setSelectedTab] = React.useState(0);

  const handleSelect = (e) => {
    setSelectedTab(e.selected);
  };

  return (
    <div className="col-md-12 mt-3" id="tab-strip-gray">
      <TabStrip
        className="tsg-container"
        selected={selectedTab}
        onSelect={handleSelect}
      >
        <TabStripTab title="Patient Info">
          <div className="tabText">
            <PatientInfoGeneral />
          </div>
        </TabStripTab>
        <TabStripTab title="Allergies">
          <div className="tabText">
            <PatientAllergies />
          </div>
        </TabStripTab>
        <TabStripTab title="Medications">
          <div className="tabText">
            <PatientMeds />
          </div>
        </TabStripTab>
        <TabStripTab title="Surveys">
          <div className="tabText">
            <PatientSurveys />
          </div>
        </TabStripTab>
      </TabStrip>
    </div>
  );
};

export default PatientInfo;
