import { inventorySources } from '@/constants/dict';

export const getInventorySource = (infusion) => {
  if (!!infusion?.infusionDetail?.inventorySource) {
    return inventorySources[infusion.infusionDetail.inventorySource];
  }

  return 'N/A';
};

export const parsePrepDosage = (treatment) => {
  const na = 'N/A';

  if (!!treatment?.infusionDetail?.stepPreparation?.drugs?.length) {
    const {
      adjustedDosage,
      calcDosage,
      selectedQuantity,
      uom,
      wastage,
      strength
    } = treatment.infusionDetail.stepPreparation.drugs[0]; // strength - original ??

    return {
      adjustedDosage: adjustedDosage ? `${adjustedDosage} ${uom}` : na,
      calcDosage: calcDosage ? `${calcDosage} ${uom}` : na,
      selectedQuantity: selectedQuantity ? `${selectedQuantity} ${uom}` : na,
      wastage: wastage ? `${wastage} ${uom}` : na,
      strength: strength ? `${strength} ${uom}` : na
    };
  }

  return {
    adjustedDosage: na,
    calcDosage: na,
    selectedQuantity: na,
    wastage: na,
    strength: na
  };
};
