import React from 'react';
import Badge from '@/components/common-components/Badge';

const PatientInactiveBadge = ({ patientStatus }) => {
  if (patientStatus === 'INACTIVE') {
    return (
      <Badge text='INACTIVE' customCss={{ fontSize: '75%', marginLeft: 10 }} />
    );
  }

  return null;
};

export default PatientInactiveBadge;
