import * as React from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { ColumnMenu } from "./columnMenu";

export function GridRowSelection(props) {
  const { data } = props.data;

  const selectionChange = event => {
    if (props.updateTableData) {
      const mapper = data.map(item => {
        if (event.dataItem.eventId === item.eventId) {
          item.isSelected = !event.dataItem.isSelected;
        }
        return item;
      });
      props.updateTableData(mapper);
    }
  };

  const headerSelectionChange = event => {
    if (props.updateTableData) {
      const checked = event.syntheticEvent.target.checked;
      const mapper = data.map(item => {
        item.isSelected = checked;
        return item;
      });
      props.updateTableData(mapper);
    }
  };

  return (
    <div>
      {props.title && (
        <div className="a-grid__header">
          <div>{props.title}</div>
        </div>
      )}
      <Grid
        {...props}
        className="a-grid"
        editField="inEdit"
        selectedField="isSelected"
        onSelectionChange={e => selectionChange(e)}
        onHeaderSelectionChange={e => headerSelectionChange(e)}
        data={data}
      >
        {props.columns.map((column, index) => {
          return (
            <Column {...column} columnMenu={ColumnMenu} key={index} cell={column.customCell || null} />
          );
        })}
      </Grid>
    </div>
  );
}
