import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import Drawer from 'rc-drawer';

// components
import AwesomeLabel from '@/components/common-components/AwesomeLabel';
import Badge from '@/components/common-components/Badge';
import FormButton from '@/components/common-components/Form/FormButton';
import ConfirmationModal from '@/components/common-components/ConfirmationModal';

// context
import { InboundContext } from '@/context';

// utils
import { formatDateToDefault } from '@/common/DateHelper';
import { getDocumentTitleMapped } from '@/common/Mappers';
import { DOC_TYPE_ORDER_REFILL, DOC_TYPE_REFERRAL } from '@/constants/enum';

const DrawerContent = styled.div`
  padding: 15px;
`;

const DrawerActions = styled.span`
  font-size: 115%;
`;

const RenderList = styled.div`
  padding: 5px 0;
`;

const DrawerField = styled.div`
  padding: 5px 0;
`;

const ConfirmAction = styled.div`
  text-align: right;
`;

const CreateWorkItemDialogDrawer = ({ callback }) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [isConfirmDisabled, setIsConfirmDisabled] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const { showInboundPreview, setShowInboundPreview, inboundPreviewData } =
    useContext(InboundContext);

  const handleCallback = (createWorkItem = false) => {
    setIsConfirmDisabled(true);
    callback(
      inboundPreviewData,
      () => {
        setIsDisabled(false);
        setIsConfirmDisabled(false);
        setShowConfirm(false);
      },
      createWorkItem
    );
  };

  if (!showInboundPreview) return null;

  const render = {
    hasOrderType: (docType) =>
      [DOC_TYPE_ORDER_REFILL, DOC_TYPE_REFERRAL].includes(docType),
    field: (title, value) => (
      <DrawerField>
        <b>{title}: </b>
        {value}
      </DrawerField>
    ),
    notes: () => {
      const { formData } = inboundPreviewData;

      if (!formData.inboundNotes) return null;

      return (
        <>
          <hr />
          <div className='row'>
            <div className='col-12'>
              <h6>
                <AwesomeLabel
                  icon='file'
                  label='Notes to be added to Patient Notes:'
                />
              </h6>
              <DrawerField>{formData.inboundNotes}</DrawerField>
            </div>
          </div>
        </>
      );
    },
    productInfo: () => {
      const { formData, dxCode } = inboundPreviewData;
      const showOrderDetails = render.hasOrderType(formData.docType);

      const renderField = (fieldName, fieldTitle) => {
        if (formData.product?.hasOwnProperty(fieldName)) {
          return render.field(fieldTitle, formData.product[fieldName]);
        }
        return null;
      };

      return (
        <>
          <h6>
            <AwesomeLabel icon='file' label='Document Type:' />
          </h6>
          <Badge type='info' text={getDocumentTitleMapped(formData.docType)} />
          {showOrderDetails && (
            <RenderList>
              {dxCode ? render.field('DX Code', dxCode) : null}
              {renderField('productName', 'Product')}
            </RenderList>
          )}
        </>
      );
    },
    patientInfo: () => {
      const { selectedPatient } = inboundPreviewData;

      const renderField = (fieldName, fieldTitle, fieldType) => {
        if (selectedPatient.hasOwnProperty(fieldName)) {
          let value = selectedPatient[fieldName];

          if (fieldType === 'date') {
            value = formatDateToDefault(value);
          }
          return render.field(fieldTitle, value);
        }

        return null;
      };

      return (
        <>
          <h6>
            <AwesomeLabel
              icon='user'
              label={`${selectedPatient.firstName} ${selectedPatient.lastName}`}
            />
          </h6>
          {selectedPatient.isNewPatient ? (
            <Badge type='success' text='NEW PATIENT' />
          ) : (
            <Badge type='default' text='EXISTING PATIENT' />
          )}
          <RenderList>
            {renderField('dob', 'DOB', 'date')}
            {renderField('id', 'ID')}
            {renderField('homePhoneNumber', 'Home Phone Number')}
          </RenderList>
        </>
      );
    },
    insuranceInfo: () => {
      const { formData } = inboundPreviewData;

      if (!render.hasOrderType(formData.docType)) return null;
      if (!formData.insuranceCompany) return null;

      return (
        <>
          <hr />
          <div className='row'>
            <div className='col-12'>
              <h6>
                <AwesomeLabel icon='file' label='Insurance Info:' />
              </h6>

              <RenderList>
                {formData.insuranceCompany.name &&
                  render.field(
                    'Insurance Company',
                    formData.insuranceCompany.name
                  )}
                {formData?.insurancePlan?.planName &&
                  render.field(
                    'Insurance Plan',
                    formData.insurancePlan.planName
                  )}
              </RenderList>
              {formData.isNotContracted && (
                <Badge type='warning' text='Currently Not Contracted' />
              )}
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <Drawer
      width={450}
      open={showInboundPreview}
      onClose={() => {
        setShowInboundPreview(false);
      }}
    >
      <DrawerContent>
        <h5>Work Item Creation</h5>
        <p>
          Please check carefully all details you entered before creating new
          work item from inbound fax. Click "Back" (or just outside of this
          modal) if you need to make any changes.
        </p>
        <hr />
        <div className='row'>
          <div className='col-6'>{render.patientInfo()}</div>
          <div className='col-6'>{render.productInfo()}</div>
        </div>
        {render.notes()}
        {render.insuranceInfo()}
        <hr />
        <div className='row'>
          <div className='col-6'>
            <DrawerActions
              className='blue-label'
              onClick={() => setShowInboundPreview(false)}
            >
              <AwesomeLabel icon='backward' label='Back' />
            </DrawerActions>
          </div>
          <ConfirmAction className='col-6'>
            <FormButton
              className='k-button blue'
              loading={isDisabled}
              label={<AwesomeLabel icon='forward' label='Next' />}
              onClick={() => {
                if (!isDisabled) {
                  setIsDisabled(true);
                  setShowConfirm(true);
                }
              }}
            />
          </ConfirmAction>
        </div>
        <ConfirmationModal
          isVisible={showConfirm}
          dialogDescription='Create a work item for this fax?'
          dialogTitle='Work Item Creation'
          onClose={() => {
            // do not allow closing modal if there is a request in progress
            if (isConfirmDisabled) return;

            setIsDisabled(false);
            setShowConfirm(false);
          }}
          onReject={() => handleCallback()}
          onConfirm={() => handleCallback(true)}
          disabled={isConfirmDisabled}
        />
      </DrawerContent>
    </Drawer>
  );
};

export default CreateWorkItemDialogDrawer;

DrawerContent.displayName = 'DrawerContent';
DrawerActions.displayName = 'DrawerActions';
RenderList.displayName = 'RenderList';
DrawerField.displayName = 'DrawerField';
ConfirmAction.displayName = 'ConfirmAction';
