import React from "react";

import PatientMedsLog from "./PatientMedsLog";
import PatientMedsAdd from "./PatientMedsAdd";

const PatientMeds = () => {
  return (
    <div className="k-form pl-3 pr-3 pt-1">
      <div className="row mt-2">
        <div className="col-md-8">
          <p className="icon-blue important-text">Current Medications</p>
        </div>
        <div className="col-md-4">
          <p className="icon-blue important-text">Add New Med</p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-8">
          <PatientMedsLog editable={true} />
        </div>
        <div className="col-md-4">
          <PatientMedsAdd />
        </div>
      </div>
    </div>
  );
};

export default PatientMeds;
