import React, { useState } from 'react';

import { AutoComplete } from '@progress/kendo-react-dropdowns';
import { Error } from '@progress/kendo-react-labels';
import { filterBy } from '@progress/kendo-data-query';

const AutoFilter = (props) => {
  const [state, setState] = useState({
    data: props.data,
    value: props.savedValue || '',
  });

  const filterData = (value) => {
    const { data } = props;
    const filter = {
      value,
      field: props.textField,
      operator: 'contains',
      ignoreCase: true,
    };
    return value ? filterBy(data, filter) : data;
  };

  const onChange = (event) => {
    const { value } = event;

    setState({
      data: filterData(value),
      value,
    });

    if (props.onChangeCallback) {
      props.onChangeCallback(value, event.target.name);
    }
  };
  return (
    <div>
      <AutoComplete
        {...props}
        data={state.data}
        placeholder={props.placeholder}
        value={state.value}
        onChange={onChange}
      />
      {props.visited && props.validationMessage && (
        <Error>{props.validationMessage}</Error>
      )}
    </div>
  );
};

export default AutoFilter;
