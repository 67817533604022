import React, { useContext, useEffect } from 'react';
// context
import { PatientContext } from '@/context';
// components
import AwesomeLabel from '@/components/common-components/AwesomeLabel';
// utils
import { patientStatuses } from '@/constants/dict';
import { formatDateToDefault } from '@/common/DateHelper';
import { toProperCase } from '@/common/Converters';

const { ACTIVE } = patientStatuses;

const DisplayFutureStatus = () => {
  const { patientScheduledItems, selectedPatientInfo } =
    useContext(PatientContext);
  const properCaseStatus =
    toProperCase(selectedPatientInfo?.patientStatus) || ACTIVE;

  if (!patientScheduledItems || patientScheduledItems.length === 0) {
    return null;
  }

  const nextFutureStatus = patientScheduledItems[0];
  const parameterData = nextFutureStatus?.parameters;
  let parsedData;

  try {
    parsedData = JSON.parse(parameterData);
  } catch (e) {
    console.error('Error parsing parameterData:', e);
  }

  const startDate = new Date(
    formatDateToDefault(parsedData?.effectivePeriod?.startDate)
  ).toLocaleDateString();

  const endDate = parsedData?.effectivePeriod?.endDate
    ? ` - ${new Date(
        formatDateToDefault(parsedData?.effectivePeriod?.endDate)
      ).toLocaleDateString()}`
    : '';

  const futureStatus = toProperCase(parsedData?.patientStatus);

  const statusRowClass = properCaseStatus === ACTIVE ? 'row mt-10' : 'row';

  const futureLabel = `(Effective Date ${startDate}${endDate}) : ${futureStatus}`;

  return (
    <div className={statusRowClass}>
      <div className='col-md-12 mt-2'>
        <AwesomeLabel
          icon='flux-capacitor'
          title='Future Status'
          label={futureLabel}
          size='xl'
          iconStyle='solid'
          customClassName='icon-blue'
        />
      </div>
    </div>
  );
};

export default DisplayFutureStatus;
