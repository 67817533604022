import React, { useContext } from 'react';
import Drawer from 'rc-drawer';
import styled from 'styled-components';

// components
import AwesomeLabel from '@/components/common-components/AwesomeLabel';
import PatientStatusTransition from '@/components/common-components/PatientStatusTransition';
import PatientStatusBadge from '@/components/common-components/PatientStatusBadge';
// utils
import { formatDateToDefault, utc2local } from '@/common/DateHelper';
import { patientStatusCodes } from '@/constants/enum';

// context
import { PatientContext } from '@/context';

const DrawerContent = styled.div`
  padding: 15px;
`;

const LogRecord = styled.li`
  padding: 10px 0;
`;

const LogList = styled.ul`
  padding-inline-start: 20px;

  div {
    padding-top: 10px;
  }
`;

const PatientStatusLogDrawer = () => {
  const {
    selectedPatientInfo,
    setShowStatusLog,
    showStatusLog,
    patientScheduledItems
  } = useContext(PatientContext);

  if (!showStatusLog) return null;

  const patientStatusHistory = selectedPatientInfo.patientStatusHistory;

  const renderLogRecord = (logRecord, index) => {
    const elKey = `log-item-${index}`;
    const when = utc2local(logRecord.date);
    const showDates = [
      patientStatusCodes.SEASONAL,
      patientStatusCodes.ON_HOLD
    ].includes(logRecord.afterStatus);
    const effectiveFrom = showDates
      ? formatDateToDefault(logRecord.effectivePeriod?.startDate)
      : '';
    const effectiveUntil = showDates
      ? formatDateToDefault(logRecord.effectivePeriod?.endDate)
      : '';

    return (
      <LogRecord key={elKey}>
        <PatientStatusTransition logRecord={logRecord} />
        {showDates && (
          <div>
            <small>
              <AwesomeLabel
                icon='clock'
                title='Effective Period'
                label={`${effectiveFrom} - ${effectiveUntil}`}
              />
            </small>
          </div>
        )}
        <div>
          <small>
            <AwesomeLabel
              icon='calendar'
              label={
                <span>
                  Updated by <b>{logRecord.agentId}</b>, {when}
                </span>
              }
            />
          </small>
        </div>
        {!!logRecord.notes && (
          <div>
            <small>
              <AwesomeLabel
                icon='notes'
                title='Notes'
                label={logRecord.notes}
              />
            </small>
          </div>
        )}
      </LogRecord>
    );
  };
  const renderEventLogRecord = (logRecord, index) => {
    const parameterData = JSON.parse(logRecord.parameters);
    const { patientStatus, effectivePeriod, agentId, reasons } = parameterData;

    const elKey = `log-item-${index}`;
    const when = utc2local(logRecord.createdAt);
    const showStatusEvent = [
      patientStatusCodes.SEASONAL,
      patientStatusCodes.ON_HOLD
    ].includes(patientStatus);

    const hasReasons =
      reasons && reasons !== '' && patientStatus === patientStatusCodes.ON_HOLD;
    const effectiveFrom = formatDateToDefault(effectivePeriod?.startDate);
    const effectiveUntil = showStatusEvent
      ? formatDateToDefault(effectivePeriod?.endDate)
      : '';

    return (
      <LogRecord key={elKey}>
        <PatientStatusBadge status={patientStatus} />
        <div>
          <small>
            <AwesomeLabel
              icon='calendar'
              label={
                <span>
                  Updated by <b>{agentId}</b>, {when}
                </span>
              }
            />
          </small>
        </div>
        {showStatusEvent && (
          <div>
            <small>
              <AwesomeLabel
                icon='clock'
                title='Effective Period'
                label={`${effectiveFrom} - ${effectiveUntil}`}
              />
            </small>
          </div>
        )}
        {!showStatusEvent && (
          <div>
            <small>
              <AwesomeLabel
                icon='clock'
                title='Reverts to Active'
                label={`${effectiveFrom}`}
              />
            </small>
          </div>
        )}
        {!!hasReasons && (
          <div>
            <small>
              <AwesomeLabel icon='notes' title='Reasons' label={reasons} />
            </small>
          </div>
        )}
      </LogRecord>
    );
  };

  return (
    <Drawer
      open={showStatusLog}
      onClose={() => {
        setShowStatusLog(false);
      }}
    >
      <DrawerContent>
        <h5>
          <AwesomeLabel label={selectedPatientInfo.displayName} icon='user' />
        </h5>
        <hr />
        {patientScheduledItems?.length !== 0 && (
          <>
            <h5>Upcoming Status Events</h5>
            <LogList>
              {patientScheduledItems?.map((logRecord, i) =>
                renderEventLogRecord(logRecord, i)
              )}
            </LogList>
            <hr />
          </>
        )}

        <h5>Patient Status History</h5>
        <LogList>
          {patientStatusHistory
            ?.map((logRecord, i) => renderLogRecord(logRecord, i))
            .reverse()}
        </LogList>
        {!patientStatusHistory && <p>No status updates found.</p>}
      </DrawerContent>
    </Drawer>
  );
};

export default PatientStatusLogDrawer;

DrawerContent.displayName = 'DrawerContent';
LogRecord.displayName = 'LogRecord';
LogList.displayName = 'LogList';
