import React, { useEffect, useState } from 'react';
// kendo
import { Field, Form } from '@progress/kendo-react-form';
import { DropDownList } from '@progress/kendo-react-dropdowns';
// components
import Dashboard from './Dashboard';
import FormButton from '@/components/common-components/Form/FormButton';
// gql
import { connectToGraphqlAPI } from '@/provider';
import { getWorkItemsByGroupQueue } from '@/graphql/queries';
// util
import {
  calculateWorkItemAge,
  formatDateTimeToDefault
} from '@/common/DateHelper';
import {
  defaultQueue,
  filterDays,
  groupQueues,
  viewSettings
} from './dashboardHelper';
import { taskType } from '../Queue/FilterSystem/filterHelper';
import cx from 'clsx';
// style
import styles from './Dashboard.module.scss';

const DashboardLanding = () => {
  const [allData, setAllData] = useState();
  const [finalData, setFinalData] = useState();
  const [isLoading, setLoading] = useState(false);
  const [createButtonStatus, setCreateButtonStatus] = useState(false);
  let queueData = [];
  let dataCount = 0;

  useEffect(() => {
    startFetchProcess();
  }, []);

  const fetchWorkInProgressData = async (name, queueSelection) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getWorkItemsByGroupQueue,
        variables: {
          groupQueueId: name
        }
      });
      dataCount += 1;

      if (data?.data?.getWorkItemsByGroupQueue?.length > 0) {
        const arrayData = data.data.getWorkItemsByGroupQueue;
        queueData = queueData.concat(
          arrayData.map((item) => ({
            ...item,
            days: calculateWorkItemAge(item.createdAt),
            createdAt: formatDateTimeToDefault(item.createdAt)
          }))
        );
      }
      const createData = () => {
        let workNames = queueData
          .reduce((c, v) => c.concat(v), [])
          .map((o) => o.work);
        workNames = [...new Set(workNames)];
        let workItemStatus = queueData
          .reduce((c, v) => c.concat(v), [])
          .map((o) => o.workStatus);
        workItemStatus = [...new Set(workItemStatus)];

        const nameData = workNames.map((item) => {
          const mapData = queueData.filter(
            (workItem) => workItem.work === item
          );
          const finalDays = filterDays(mapData);
          const workItemMapped = workItemStatus.map((items) => {
            const mapItemData = mapData.filter((workItem) => {
              return workItem.workStatus === items;
            });
            const finalItemDays = filterDays(mapItemData);
            const obj = {
              selected: queueSelection,
              workItemStatus: items,
              workItems: finalItemDays
            };
            return obj;
          });
          const obj = {
            selected: queueSelection,
            workItemName: item,
            workItems: finalDays,
            status: workItemMapped
          };
          return obj;
        });
        setAllData(queueData);
        setFinalData(nameData);
        setLoading(false);
        queueData = [];
      };
      if (dataCount === 6 && data && queueSelection === 'ALL') {
        createData();
      } else if (queueSelection !== 'ALL' && data) {
        createData();
      }
    } catch (err) {
      console.error(
        'DashboardLanding::fetchWorkInProgressData::getWorkItemsByGroupQueue err:',
        err
      );
      setLoading(false);
    }
  };
  const startFetchProcess = async (selected) => {
    setCreateButtonStatus(true);
    setLoading(true);
    const queueSelection = selected?.values?.queue?.value || 'ALL';
    const workItemSelection = selected?.values?.workItem?.value;
    if (queueSelection === 'ALL') {
      groupQueues.forEach((name) => {
        if (name.value !== 'ALL') {
          fetchWorkInProgressData(
            name.value,
            queueSelection,
            workItemSelection
          );
        }
      });
    } else {
      fetchWorkInProgressData(
        queueSelection,
        queueSelection,
        workItemSelection
      );
    }
  };

  const handleClearDropdowns = () => {
    setAllData();
    setFinalData();
    setCreateButtonStatus(false);
  };

  return (
    <div className='container-fluid'>
      <Form
        onSubmitClick={startFetchProcess}
        render={(formRenderProps) => (
          <form
            onSubmit={formRenderProps.onSubmit}
            className='k-form pl-3 pr-3 pt-1'
          >
            <div className='row'>
              <div className='col-2'>
                <Field
                  name='viewSettings'
                  label='View'
                  component={DropDownList}
                  textField='text'
                  valueField='value'
                  data={viewSettings}
                  disabled
                />
              </div>
              <div className='col-2'>
                <Field
                  name='queue'
                  label='By Queue'
                  textField='text'
                  valueField='value'
                  component={DropDownList}
                  data={groupQueues}
                  defaultItem={defaultQueue}
                />
              </div>
              <div className='col-2'>
                <Field
                  name='workItem'
                  label='Work Items'
                  textField='text'
                  valueField='value'
                  component={DropDownList}
                  data={taskType}
                  disabled
                />
              </div>
              <div className='col-2'>
                <Field
                  name='preferredLanguage'
                  label='Priority'
                  component={DropDownList}
                  disabled
                />
              </div>
              <div className={cx(styles.buttonMarginTop, 'col-2')}>
                <FormButton
                  label='Create Grid'
                  type='submit'
                  className='k-button pageButton blue'
                  loading={isLoading}
                  disabled={createButtonStatus}
                />
              </div>
              <div className={cx(styles.buttonMarginTop, 'col-2')}>
                <FormButton
                  label='Clear'
                  type='button'
                  className={cx(
                    styles.buttonMarginLeft,
                    'k-button clearButton blue'
                  )}
                  onClick={() => {
                    formRenderProps.onFormReset();
                    handleClearDropdowns();
                  }}
                />
              </div>
            </div>
          </form>
        )}
      />
      <div className='row'>
        <div className='mb-5'>
          {finalData && (
            <Dashboard
              headingTitle={finalData?.[0]?.selected}
              gridData={finalData}
              allData={allData}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardLanding;
