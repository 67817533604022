import { connectToGraphqlAPI } from 'provider';
import { getPatientBucket, getNursingProcess, getEvent, getInfusionHeaderDetails } from '@/graphql/queries';
import { stepCheckIn } from '@/graphql/mutations';

export const getPatientBucketCall = async (patientId) => {
  try {
    const data = await connectToGraphqlAPI({
      graphqlQuery: getPatientBucket,
      variables: { patientId },
    });
    if (data?.data?.getPatientBucket) {
      return data.data.getPatientBucket;
    }

    console.error(`api.infusion::getPatientBucketCall - the patient bucket for ID '${patientId}' not found`);
  } catch (err) {
    console.error('api.infusion::getPatientBucketCall err: ', err);

    return null;
  }
};

export const getNursingProcessCall = async (id) => {
  try {
    const data = await connectToGraphqlAPI({
      graphqlQuery: getNursingProcess,
      variables: { id },
    });
    if (data?.data?.getNursingProcess) {
      return data.data.getNursingProcess;
    }

    console.error(`api.infusion::getNursingProcessCall - the nursing process for ID '${id}' not found`);
  } catch (err) {
    console.error('api.infusion::getNursingProcessCall err: ', err);

    return null;
  }
};

export const getEventCall = async (id) => {
  try {
    const data = await connectToGraphqlAPI({
      graphqlQuery: getEvent,
      variables: { id },
    });

    if (data?.data?.getEvent) {
      return data.data.getEvent;
    }

    console.error(`api.infusion::getEventCall - the schedule event with ID '${id}' not found`);
  } catch (err) {
    console.error('api.infusion::getEventCall err: ', err);

    return null;
  }
};

export const getInfusionHeaderDetailsCall = async (locationId, providerNPI) => {
  try {
    const data = await connectToGraphqlAPI({
      graphqlQuery: getInfusionHeaderDetails,
      variables: {
        locationId,
        providerNPI,
      },
    });

    const headerDetails = { location: {}, provider: {} };

    if (data?.data?.getLocationAIC) {
      headerDetails.location = { ...data.data.getLocationAIC };
    } else {
      console.error(`api.infusion::getInfusionHeaderDetailsCall - the location with ID '${locationId}' not found`);
    }

    if (data?.data?.getProviderAIC) {
      headerDetails.provider = { ...data.data.getProviderAIC };
    } else {
      console.error(`api.infusion::getInfusionHeaderDetailsCall - the provider with NPI '${providerNPI}' not found`);
    }

    return headerDetails;
  } catch (err) {
    console.error('api.infusion::getInfusionHeaderDetailsCall err: ', err);

    return null;
  }
};

// pure function - no extra logic, just simple API call
// will return newly generated NPID (if success)
// otherwise it returns `null`
export const stepCheckInCall = async (input) => {
  try {
    const data = await connectToGraphqlAPI({
      graphqlQuery: stepCheckIn,
      variables: { input },
    });

    if (data?.data?.stepCheckIn?.statusCode) {
      if (data.data.stepCheckIn.statusCode !== '400') {
        return data.data.stepCheckIn.nursingProcessId;
      }

      console.error('api.infusion::stepCheckInCall - selected scheduled event check-in action failed');

      return null;
    }
  } catch (err) {
    console.error('api.infusion::stepCheckInCall err: ', err);

    return null;
  }
};
