import React, { useState, useContext } from 'react';
import * as moment from 'moment';

// kendo
import { Form, Field } from '@progress/kendo-react-form';
import { TextArea } from '@progress/kendo-react-inputs';
import { Dialog } from '@progress/kendo-react-dialogs';

// components
import AICLocationDropdown from '@/components/common-components/AICLocationDropdown';
import { getUTCTimeString } from '@/components/Scheduling/getUTCTimeString';
import {
  getAppointmentMinTime,
  getAppointmentMaxTime
} from '@/components/Scheduling/getTimezoneSpecificDate';
import FormButton from '@/components/common-components/Form/FormButton';
import Alert from '@/components/common-components/Alert';

// context
import {
  LocationContext,
  CalendarContext,
  UserContext,
  PatientContext
} from '@/context';

// utils
import { DatePickerField, TimePickerField } from '@/common/Validation';
import { calculateToFifteen } from '@/common/DateHelper';
import {
  CHAIR_TELEHEALTH,
  endTimeNotFifteenPastStartTimeValidator,
  steps
} from './Calendar.utils';
import { SCHEDULE_EVENTS } from '@/constants/enum';

const TelehealthVisitModal = ({
  handleLocationTreeChange,
  handleDetailsClick,
  newAppointmentForm,
  pathsNotEqual,
  createScheduleEventCall,
  addUpdateNotesCall
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const { aicActiveLocationsOnly, currentLocation, getChairsByLocation } =
    useContext(LocationContext);
  const { showNewTelehealthModal, setShowNewTelehealthModal } =
    useContext(CalendarContext);
  const { agentId } = useContext(UserContext);
  const { selectedPatientInfo } = useContext(PatientContext);

  const locationChairs = getChairsByLocation(currentLocation?.id);
  const canDoTelehealth = locationChairs.includes(CHAIR_TELEHEALTH);

  const handleSubmit = async (dataItem) => {
    setIsLoading(true);

    const newStartTime = calculateToFifteen(dataItem.startTime);
    const newEndTime = calculateToFifteen(dataItem.endTime);

    const requestObject = {
      agentId,
      chairId: CHAIR_TELEHEALTH,
      createdBy: agentId,
      endTime: getUTCTimeString(
        dataItem.startDate,
        newEndTime,
        currentLocation.timeZone
      ),
      endTimeZone: 'UTC',
      eventType: SCHEDULE_EVENTS.APPOINTMENT_TELEHEALTH,
      locationId: dataItem.locationId?.id,
      notes: dataItem.newAppointmentNotes,
      patientId: selectedPatientInfo.patientId,
      startTime: getUTCTimeString(
        dataItem.startDate,
        newStartTime,
        currentLocation.timeZone
      ),
      startTimeZone: 'UTC',
      status: 'SCHEDULED',
      title: 'Telehealth Visit',
      updatedBy: agentId
    };

    createScheduleEventCall(requestObject);
    setShowNewTelehealthModal(false);

    if (!!dataItem.newAppointmentNotes) {
      const noteObject = {
        patientId: selectedPatientInfo.patientId,
        agentId,
        notes: [
          {
            date: moment(new Date()),
            note: dataItem.newAppointmentNotes,
            type: 'APPOINTMENT'
          }
        ]
      };

      addUpdateNotesCall(noteObject);
    }
  };

  return (
    <Dialog
      id='telehealthVisitModal'
      title='Telehealth Visit'
      width={800}
      height='auto'
      showDialog={showNewTelehealthModal}
      onClose={() => setShowNewTelehealthModal(false)}
    >
      <Form
        id='formNewTelehealth'
        validator={endTimeNotFifteenPastStartTimeValidator}
        onSubmit={handleSubmit}
        initialValues={{
          locationId: currentLocation
        }}
        key={JSON.stringify({
          locationId: currentLocation
        })}
        render={(formRenderProps) => (
          <form
            onSubmit={formRenderProps.onSubmit}
            className='k-form pl-3 pr-3 pt-1'
          >
            <div className='row'>
              <div className='col-md-2'>Patient:</div>
              <div className='col-md-4 p-0'>
                <b>
                  {selectedPatientInfo.patientFirstName}{' '}
                  {selectedPatientInfo.patientLastName}
                </b>
                &nbsp;(ID: {selectedPatientInfo.patientId})
              </div>
            </div>
            <div className='row mt-10'>
              <div className='col-md-2'>AIC Location</div>
              <div className='col-md-4 p-0'>
                <Field
                  name='locationId'
                  component={() => (
                    <AICLocationDropdown
                      data={aicActiveLocationsOnly}
                      value={currentLocation}
                      style={{ width: '220px' }}
                      onChange={(selected) =>
                        handleLocationTreeChange(selected, formRenderProps)
                      }
                    />
                  )}
                  validator={newAppointmentForm.locationId.inputValidator}
                  required
                />
              </div>
              {!canDoTelehealth && (
                <div className='col-md-6 pt-1'>
                  <Alert>
                    This location does not have telehealth capabilities.
                  </Alert>
                </div>
              )}
            </div>
            <div className='row mt-10'>
              <div className='col-md-2'>Date:</div>
              <div className='col-md-3 p-0'>
                <Field
                  name='startDate'
                  component={DatePickerField}
                  validator={newAppointmentForm.startDate.inputValidator}
                />
              </div>
              <div className='col-md-7'></div>
            </div>
            <div className='row mt-10'>
              <div className='col-md-2'>Start Time:</div>

              <div className='col-md-3 p-0'>
                <Field
                  name='startTime'
                  component={TimePickerField}
                  steps={steps}
                  min={getAppointmentMinTime()}
                  max={getAppointmentMaxTime()}
                  validator={newAppointmentForm.startTime.inputValidator}
                />
              </div>
              <div className='col-md-2'>End Time:</div>

              <div className='col-md-3 p-0'>
                <Field
                  name='endTime'
                  component={TimePickerField}
                  steps={steps}
                  min={getAppointmentMinTime()}
                  max={getAppointmentMaxTime()}
                  validator={newAppointmentForm.endTime.inputValidator}
                />
              </div>
            </div>
            <div className='row mt-10'>
              <div className='col-md-2'>Notes:</div>
              <div className='col-md-10 p-0'>
                <Field
                  name='newAppointmentNotes'
                  style={{ width: '100%', minHeight: '100px' }}
                  autoSize
                  component={TextArea}
                />
              </div>
            </div>

            <div className='row mt-10'>
              <div className='col my-3 d-flex justify-content-center'>
                <FormButton
                  type='submit'
                  className='btn blue'
                  loading={isLoading}
                  label='Book Appointment'
                  disabled={!canDoTelehealth}
                />
              </div>
              {pathsNotEqual && (
                <div className='col my-3 d-flex justify-content-center'>
                  <FormButton
                    type='button'
                    className='btn'
                    onClick={handleDetailsClick}
                    label='Patient Details'
                  />
                </div>
              )}
            </div>
          </form>
        )}
      />
    </Dialog>
  );
};

export default TelehealthVisitModal;
