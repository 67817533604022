import React, { useContext, useEffect, useRef, useState } from 'react';
// Kendo
import { Grid } from '@progress/kendo-react-grid';
import { GridColumn as Column } from '@progress/kendo-react-grid/dist/npm/GridColumn';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
// context
import { NotifContext, ProductContext, ReferralContext } from '@/context';
// components
import OrderAdministrationCabenuva from './OrderAdministrationCabenuva';
import OrderAdministrationApretude from './OrderAdministrationApretude';
import DeleteButton from '../common-components/Form/DeleteButton';
import { FormRadioGroup } from '../common-components/FormRadioGroup';
// utils
import { getAdministerInstructions } from './NewOrder.utils';
import { isWeightBasedUOM } from '@/common/DoseHelper';
import {
  dosageDateTimeFrames,
  dosageDayRanges,
  dosageFrequencyTypes,
  productLabNames,
  routes
} from './ReferralMaps';
import {
  isApretudeProduct,
  isCabenuvaProduct,
  isViiVProduct
} from '@/common/viivHelper';
import { MG, defaultUOMs, mapUOMs } from '@/constants/uom';
import { InputField, validateInput } from '@/common/Validation';
import { Constants } from '@/constants';

const ReferralAdmin = ({ orderFormData, showLabDrawForm }) => {
  const { showError } = useContext(NotifContext);
  const {
    itemAdministrations,
    setItemAdministrations,
    adminOverEverySelection,
    setAdminOverEverySelection
  } = useContext(ReferralContext);

  const { products } = useContext(ProductContext);

  const weightMeasOptions = ['LB', 'KG'];

  const [selectedUOMs, setselectedUOMs] = useState();
  const [patientWeight, setPatientWeight] = useState(0);
  const [unitOfWeight, setUnitOfWeight] = useState(weightMeasOptions[0]);
  const [supportedUOM, setSupportedUOM] = useState(defaultUOMs);
  const [selectedProduct, setSelectedProduct] = useState([]);

  const adminFormRef = useRef(null);

  const isCabenuva = isCabenuvaProduct(
    orderFormData?.productId || orderFormData?.drugId
  );
  const isApretude = isApretudeProduct(
    orderFormData?.productId || orderFormData?.drugId
  );
  const isVIIV = isViiVProduct(orderFormData?.drugId);

  useEffect(() => {
    if (products) {
      const currrentProduct = products.find(
        (a) => a.productName === orderFormData?.drugName
      );
      // map supported UOMs
      const mappedUoms = mapUOMs(currrentProduct?.possibleUOMs);
      setSupportedUOM(mappedUoms);
    }
  }, [products]);

  const handleDeleteClick = (props) => {
    const clonedItems = [...itemAdministrations];
    clonedItems.splice(props.dataIndex, 1);
    setItemAdministrations(clonedItems);
  };

  const customCellDeleteProductAdmins = (props) => {
    const isLastItem = props.dataIndex === itemAdministrations.length - 1;
    return (
      <td>
        {isLastItem && (
          <DeleteButton handleClick={() => handleDeleteClick(props)} />
        )}
      </td>
    );
  };

  const onAddAdmin = (administrations) => {
    setItemAdministrations([...itemAdministrations, administrations]);
  };

  const handleAddAdministration = () => {
    const dataItem = adminFormRef.current.values;
    const isLabDraw = dataItem.orderName === productLabNames.LAB_DRAW_NAME;
    const administer = getAdministerInstructions(dataItem);

    const getDosage = (adminDose) => {
      if (isCabenuva) {
        return adminDose.cabenuvaDosage === '400/600 mg' ? 1000 : 1500;
      }

      if (isApretude) {
        return 600;
      }

      return adminDose.dosageOverride;
    };

    const uom = isVIIV ? MG : dataItem.unitOfMeas;
    const route = isLabDraw ? 'IV' : isVIIV ? 'IM' : dataItem.route;
    const dose = getDosage(dataItem);
    const needCalculation = isWeightBasedUOM(uom);
    const weightInKg =
      unitOfWeight === 'KG' ? patientWeight : Math.round(patientWeight / 2.2);
    const calcDosage = needCalculation ? weightInKg * dose : dose;

    const itemAdministration = {
      drugName: orderFormData.drugName,
      route,
      maxOfTreatments: dataItem.numTreatments,
      approvedDosage: dose || +dose,
      dose,
      unitOfMeas: uom,
      calcDosage,
      administer,
      dosageFrequencyType: dataItem.dosageFrequencyType,
      dosageDayRange: dataItem.dosageDayRange,
      dosageEvery: dataItem.dosageEvery,
      dosageDateTimeFrameEvery: dataItem.dosageDateTimeFrameEvery,
      dosageFor: dataItem.dosageFor,
      dosageDateTimeFrameFor: dataItem.dosageDateTimeFrameFor
    };

    // @TODO: extend ViiV drugs with Oral-lead In values
    try {
      onAddAdmin(itemAdministration);
    } catch (err) {
      console.error('NewOrder::handleAddAdministration err:', err);
      showError('Adding new administration failed');
    }
  };
  const administrationForm = {
    dosageFrequencyType: {
      value: orderFormData?.dosageFrequencyType || '',
      inputValidator: (value) => {
        return validateInput({
          dosageFrequencyType: {
            ...administrationForm.dosageFrequencyType,
            value
          }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        }
      ]
    },
    patientWeight: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          patientWeight: { ...administrationForm.patientWeight, value }
        });
      },
      validations: [
        {
          type: 'anyNumberGreaterThanZero',
          message: Constants.ErrorMessage.PosNumeric_Required
        }
      ]
    },
    dosageDayRange: {
      value: orderFormData?.dosageDayRange || '',
      inputValidator: (value) => {
        return validateInput({
          dosageDayRange: { ...administrationForm.dosageDayRange, value }
        });
      },
      validations: []
    },
    dosageEvery: {
      value: orderFormData?.dosageEvery || '',
      inputValidator: (value) => {
        return validateInput({
          dosageEvery: { ...administrationForm.dosageEvery, value }
        });
      },
      validations: []
    },
    dosageDateTimeFrameEvery: {
      value: orderFormData?.dosageDateTimeFrameEvery || '',
      inputValidator: (value) => {
        return validateInput({
          dosageDateTimeFrameEvery: {
            ...administrationForm.dosageDateTimeFrameEvery,
            value
          }
        });
      },
      validations: []
    },
    dosageFor: {
      value: orderFormData?.dosageFor || '',
      inputValidator: (value) => {
        return validateInput({
          dosageFor: { ...administrationForm.dosageFor, value }
        });
      },
      validations: []
    },
    dosageDateTimeFrameFor: {
      value: orderFormData?.dosageDateTimeFrameFor || '',
      inputValidator: (value) => {
        return validateInput({
          dosageDateTimeFrameFor: {
            ...administrationForm.dosageDateTimeFrameFor,
            value
          }
        });
      },
      validations: []
    },
    route: {
      value: orderFormData?.route || '',
      inputValidator: (value) => {
        return validateInput({ route: { ...administrationForm.route, value } });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        }
      ]
    },
    dosageOverride: {
      value: orderFormData?.dosageOverride || '',
      inputValidator: (value) => {
        return validateInput({
          dosageOverride: { ...administrationForm.dosageOverride, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        }
      ]
    },
    unitOfMeas: {
      value: orderFormData?.unitOfMeas || '',
      inputValidator: (value) => {
        return validateInput({
          unitOfMeas: { ...administrationForm.unitOfMeas, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        }
      ]
    },
    numTreatments: {
      value: orderFormData?.numTreatments || '',
      inputValidator: (value) => {
        return validateInput({
          numTreatments: { ...administrationForm.numTreatments, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        }
      ]
    },
    patientWeightLB: {
      value: orderFormData?.patientWeightLB || '',
      inputValidator: (value) => {
        return validateInput({
          patientWeightLB: { ...administrationForm.patientWeightLB, value }
        });
      },
      validations: []
    },
    patientWeightKG: {
      value: orderFormData?.patientWeightKG || '',
      inputValidator: (value) => {
        return validateInput({
          patientWeightKG: { ...administrationForm.patientWeightKG, value }
        });
      },
      validations: []
    },
    dosageAmountPerKG: {
      value: orderFormData?.dosageAmountPerKG || '',
      inputValidator: (value) => {
        return validateInput({
          dosageAmountPerKG: { ...administrationForm.dosageAmountPerKG, value }
        });
      },
      validations: []
    }
  };
  return (
    <div>
      <Form
        ref={adminFormRef}
        render={(formRenderProps) => (
          <FormElement>
            <div className='row mt-08'>
              <div className='col-md-2 mt-08'>ADMINISTRATION:</div>
              <div className='col-10'>
                {!showLabDrawForm && (
                  <div className='row'>
                    <div className='col-md-2'>
                      <Field
                        name='dosageFrequencyType'
                        component={FormRadioGroup}
                        layout='vertical'
                        data={dosageFrequencyTypes}
                        className='dosage-frequency-types'
                        style={{ lineHeight: '2.6em' }}
                        onChange={(event) =>
                          setAdminOverEverySelection(event.value)
                        }
                        validator={
                          administrationForm.dosageFrequencyType.inputValidator
                        }
                      />
                    </div>
                    <div className='col-md-10'>
                      <div className='row'>
                        <div className='col-2 mt-12'>
                          <Field
                            name='dosageDayRange'
                            label=''
                            component={DropDownList}
                            data={dosageDayRanges.map((item) => item.value)}
                            validator={
                              adminOverEverySelection === 'OVER' ||
                              adminOverEverySelection === 'BOTH'
                                ? administrationForm.dosageDayRange
                                    .inputValidator
                                : null
                            }
                          />
                        </div>
                        <div className='col-md-10 mt-16'>CONSECUTIVE DAYS</div>
                      </div>
                      <hr />
                      <div className='row'>
                        <div className='col-md-2'>
                          <Field
                            name='dosageEvery'
                            component={InputField}
                            validator={
                              adminOverEverySelection === 'EVERY' ||
                              adminOverEverySelection === 'BOTH'
                                ? administrationForm.dosageEvery.inputValidator
                                : null
                            }
                          />
                        </div>
                        <div className='col-2'>
                          <Field
                            name='dosageDateTimeFrameEvery'
                            label=''
                            component={DropDownList}
                            data={dosageDateTimeFrames.map(
                              (item) => item.value
                            )}
                            validator={
                              adminOverEverySelection === 'EVERY' ||
                              adminOverEverySelection === 'BOTH'
                                ? administrationForm.dosageDateTimeFrameEvery
                                    .inputValidator
                                : null
                            }
                          />
                        </div>
                        <div className='col-md-1'>FOR:</div>
                        <div className='col-md-2'>
                          <Field
                            name='dosageFor'
                            component={InputField}
                            validator={
                              adminOverEverySelection === 'EVERY' ||
                              adminOverEverySelection === 'BOTH'
                                ? administrationForm.dosageFor.inputValidator
                                : null
                            }
                          />
                        </div>
                        <div className='col-2'>
                          <Field
                            name='dosageDateTimeFrameFor'
                            label=''
                            component={DropDownList}
                            data={dosageDateTimeFrames.map(
                              (item) => item.value
                            )}
                            validator={
                              adminOverEverySelection === 'EVERY' ||
                              adminOverEverySelection === 'BOTH'
                                ? administrationForm.dosageDateTimeFrameFor
                                    .inputValidator
                                : null
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* non-ViiV administration form */}
                {!isVIIV && !showLabDrawForm && (
                  <>
                    <hr />
                    <div className='row'>
                      <div className='col-md-1'>Route:</div>
                      <div className='col-md-2'>
                        <Field
                          name='route'
                          component={DropDownList}
                          data={routes.map((item) => item.value)}
                          validator={administrationForm.route.inputValidator}
                        />
                      </div>
                      <div className='col-md-1'>Dosage:</div>
                      <div className='col-md-1'>
                        <Field
                          name='dosageOverride'
                          component={InputField}
                          validator={
                            administrationForm.dosageOverride.inputValidator
                          }
                        />
                      </div>
                      <div className='col-md-1'>UOM:</div>
                      <div className='col-md-2'>
                        <Field
                          name='unitOfMeas'
                          component={DropDownList}
                          data={supportedUOM}
                          value={selectedUOMs}
                          onChange={(event) => setselectedUOMs(event.value)}
                          validator={
                            administrationForm.unitOfMeas.inputValidator
                          }
                        />
                      </div>
                      <div className='col-md-2'># Treatments:</div>
                      <div className='col-md-1'>
                        <Field
                          name='numTreatments'
                          component={InputField}
                          validator={
                            administrationForm.numTreatments.inputValidator
                          }
                        />
                      </div>
                    </div>
                  </>
                )}
                {/* ViiV - Cabenuva */}
                {isCabenuva ? (
                  <OrderAdministrationCabenuva
                    treatValidator={
                      administrationForm.numTreatments.inputValidator
                    }
                  />
                ) : null}
                {/* ViiV - Apretude */}
                {isApretude ? (
                  <OrderAdministrationApretude
                    treatValidator={
                      administrationForm.numTreatments.inputValidator
                    }
                  />
                ) : null}

                <hr />

                {isWeightBasedUOM(selectedUOMs) && (
                  <div className='row'>
                    <div className='col-md-3 mt-12'>
                      <Field
                        name='patientWeight'
                        value={patientWeight}
                        component={InputField}
                        label='PATIENT WEIGHT'
                        onChange={(e) => setPatientWeight(e.target.value)}
                        // validator={validateEmptyFloat}
                        validator={
                          administrationForm.patientWeight.inputValidator
                        }
                      />
                    </div>
                    <div className='col-md-2 mt-12'>
                      <Field
                        name='weightMeas'
                        label='UOM'
                        component={DropDownList}
                        data={weightMeasOptions}
                        defaultValue={unitOfWeight}
                        onChange={(e) => setUnitOfWeight(e.target.value)}
                      />
                    </div>
                  </div>
                )}

                {!showLabDrawForm && (
                  <div className='row'>
                    <div className='col-md-2 mt-14'>
                      <button
                        type='submit'
                        className='k-button blue'
                        form='formAdministration'
                        onClick={handleAddAdministration}
                      >
                        ADD
                      </button>
                    </div>
                  </div>
                )}

                <div className='row'>
                  <div className='col mt-14'>
                    <Grid className='infusion-grid' data={itemAdministrations}>
                      <Column
                        field='drugName'
                        title='PRODUCT NAME'
                        width={160}
                      />
                      <Column field='route' title='ROUTE' width={80} />
                      <Column
                        field='administer'
                        title='ADMINISTER'
                        width={200}
                      />
                      <Column
                        field='maxOfTreatments'
                        title='MAX #'
                        width={80}
                      />

                      <Column field='approvedDosage' title='DOSE' width={80} />
                      <Column field='unitOfMeas' title='UOM' width={80} />
                      <Column field='calcDosage' title='CALC DOSE' width={80} />
                      <Column
                        title=''
                        width={60}
                        cell={customCellDeleteProductAdmins}
                      />
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
          </FormElement>
        )}
      />
    </div>
  );
};

export default ReferralAdmin;
