import React, { useContext } from 'react';
import styled from 'styled-components';
import cx from 'clsx';

// kendo
import { Button } from '@progress/kendo-react-buttons';

// components
import Badge from '@/components/common-components/Badge';
import AwesomeLabel from '@/components/common-components/AwesomeLabel';

// context
import { UserContext, UserRoleTypes } from '@/context';

// util
import {
  formatPrescriberAddress,
  formatPrescriberAddressTitle
} from './prescriberHelper';

// styles
const AddressCard = styled.div`
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 10px;

  &.invalid {
    border-color: darkred;
  }
`;

const AddressCardEmpty = styled.div`
  background-color: #eee;
  border: 1px dashed #ccc;
  border-radius: 3px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  cursor: pointer;
`;

const AddressTitle = styled.h5`
  color: #005282;
  font-size: 16px;
`;

const AddressHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
`;

const PrescriberAddressCard = ({
  addressInfo,
  onClickAddUpdateAddress,
  isPrimary,
  index
}) => {
  const { canEdit } = useContext(UserContext);
  const handleEditClick = () => {
    onClickAddUpdateAddress(index);
  };

  const fullAddress = [formatPrescriberAddress(addressInfo)]
    .filter((item) => !!item)
    .join(', ');

  if (!addressInfo) {
    return (
      <AddressCardEmpty
        onClick={onClickAddUpdateAddress}
        className='col-lg-3 col-md-4 col-sm-12'
      >
        <AddressTitle>
          <AwesomeLabel icon='plus' label=' ' />
        </AddressTitle>
        <div>Add new address</div>
      </AddressCardEmpty>
    );
  }

  const addressTitle = formatPrescriberAddressTitle(addressInfo);
  const isInvalid = addressInfo.faxNumber === null;

  return (
    <AddressCard
      className={cx('col-lg-3 col-md-4 col-sm-12', {
        invalid: isInvalid
      })}
    >
      <AddressHeader>
        <div>{isPrimary && <Badge type='info' text='Primary' />}</div>
        <div>
          <Button
            disabled={!canEdit(UserRoleTypes.PatientPrescribers)}
            look='flat'
            icon='edit'
            onClick={() => {
              handleEditClick(addressInfo);
            }}
          >
            Edit
          </Button>
        </div>
      </AddressHeader>
      <AddressHeader>
        <AddressTitle>
          <AwesomeLabel icon='building' label={addressTitle} />
        </AddressTitle>
      </AddressHeader>

      <p>{fullAddress}</p>
      <p>
        <AwesomeLabel icon='phone' label={addressInfo.phoneNumber || 'n/a'} />
      </p>
      <AwesomeLabel
        icon='fax'
        label={
          isInvalid ? (
            <Badge type='alert' text='Please provide fax number' />
          ) : (
            addressInfo.faxNumber
          )
        }
      />
    </AddressCard>
  );
};

export default PrescriberAddressCard;
