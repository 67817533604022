import React, { useContext } from 'react';
import { StylesManager, Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import { Dialog } from '@progress/kendo-react-dialogs';
import { SurveyContext } from '@/context/SurveyContext';

import 'survey-core/modern.min.css';
import SurveyFollowup from './components/SurveyFollowup';
import SurveyCloseLink from './components/SurveyCloseLink';

StylesManager.applyTheme('modern');

const SurveyEngine = () => {
  const {
    activeSurvey,
    surveyResults,
    surveyHeader,
    surveyFollowup,
    surveyReviewMode
  } = useContext(SurveyContext);

  const sendResults = (sender) => {
    const results = JSON.stringify(sender.data);

    if (activeSurvey.onFinish) {
      activeSurvey.onFinish(results); // callback from survey context
    }
  };

  if (activeSurvey) {
    const survey = new Model(activeSurvey.model);

    survey.onComplete.add(sendResults);

    // pre-populate survey results and make the survey read-only
    // just display the collected results
    if (surveyResults) {
      const data = JSON.parse(surveyResults);
      survey.data = data;
      survey.mode = surveyReviewMode ? '' : 'display'; // set a survey to read-only mode
    }

    return (
      <Dialog width={960} style={{ paddingTop: 40 }}>
        <SurveyCloseLink />
        {surveyHeader && <Survey model={new Model(surveyHeader)} />}
        {surveyFollowup && <SurveyFollowup />}
        <Survey model={survey} />
      </Dialog>
    );
  }

  // Just preview survey results
  if (surveyFollowup) {
    return (
      <Dialog width={960} style={{ paddingTop: 40 }}>
        <SurveyCloseLink />
        <SurveyFollowup />
      </Dialog>
    );
  }

  return null;
};

export default SurveyEngine;
