import { renderDiscSurveyHeader } from '../../surveyDictionary';

export default {
  pages: [
    {
      // LEVEL 1 -- Main Survey Form
      elements: [
        {
          name: 'surveyStartPage',
          type: 'html',
          html: renderDiscSurveyHeader()
        },
        {
          name: 'therapy-started',
          title: 'Therapy started?',
          type: 'radiogroup',
          choices: [
            { value: 1, text: 'Yes' },
            { value: 0, text: 'No' }
          ],
          isRequired: true
        },
        {
          name: 'discontinuation-reason',
          title: 'Reason for Discontinuation?',
          type: 'radiogroup',
          choices: [
            { value: 1, text: 'Drug on limited distribution' },
            { value: 2, text: 'Patient can not afford CoPay' },
            { value: 3, text: 'Patient no longer on drug' },
            { value: 4, text: 'Patient prescribed a new drug' },
            { value: 5, text: 'Patient changed to oral medication' },
            { value: 6, text: 'Patient Reason' },
            { value: 7, text: 'Insurance Reason' },
            { value: 8, text: 'Physician Reason' }
          ],
          isRequired: true
        }
      ]
    },
    {
      // LEVEL 2 -- Questions based on discontinuation answer selected
      elements: [
        // Patient cant afford CoPay
        {
          name: 'patient-cant-afford-copay',
          title: 'Does manufacturer offer Copay assistance?',
          type: 'radiogroup',
          choices: [
            { value: 1, text: 'Patient does not qualify' },
            { value: 2, text: "Patient won't provide Financial info" },
            { value: 3, text: 'Program out of Funding' }
          ],
          isRequired: true,
          visibleIf: '{discontinuation-reason} == 2'
        },
        // Patient prescribed a new drug
        {
          name: 'what-is-the-new-drug',
          title: 'What is the new drug?',
          type: 'comment',
          isRequired: true,
          visibleIf: '{discontinuation-reason} == 4'
        },
        {
          name: 'why-did-patient-switch-to-new-drug',
          title: 'Why did patient switch to new drug?',
          type: 'radiogroup',
          choices: [
            { value: 1, text: 'Not working' },
            { value: 2, text: 'Insurance' },
            { value: 3, text: 'Cost' },
            { value: 4, text: "Don't know" }
          ],
          isRequired: true,
          visibleIf: '{discontinuation-reason} == 4'
        },
        // Patient reason
        {
          name: 'patient-reason',
          title: "What's the reason?",
          type: 'radiogroup',
          choices: [
            { value: 1, text: "Can't afford treatment" },
            { value: 2, text: "Doesn't want to start treatment" },
            { value: 3, text: 'Patient refused service' },
            { value: 4, text: 'Patient needs to visit physician' },
            { value: 5, text: 'Patient needs lab work' },
            { value: 6, text: 'Patient went back to their other home' },
            { value: 7, text: 'Utilizing other provider ie hospital' },
            { value: 8, text: 'Discontinued treatment' },
            { value: 9, text: 'Patient deceased' },
            { value: 10, text: 'Lost insurance' },
            { value: 11, text: 'Other' }
          ],
          isRequired: true,
          visibleIf: '{discontinuation-reason} == 6'
        },
        // Patient reason --- Can't afford treatment
        {
          name: 'cant-afford-treatment',
          title: "Why can't afford treatment?",
          type: 'radiogroup',
          choices: [
            {
              value: 1,
              text: "Doesn't qualify for manufacturer programs or 3rd party assistance"
            },
            {
              value: 2,
              text: 'Qualifies for manufacturer programs but cost too high'
            },
            {
              value: 3,
              text: 'Manufacturer programs or 3rd party assistance not available'
            }
          ],
          isRequired: true,
          visibleIf: '{patient-reason} == 1'
        },
        // Patient reason --- Patient went back to their other home
        {
          name: 'other-home-city',
          title: 'City patient went back to:',
          type: 'text',
          isRequired: true,
          visibleIf: '{patient-reason} == 6'
        },
        {
          name: 'other-home-state',
          title: 'State patient went back to:',
          type: 'text',
          isRequired: true,
          visibleIf: '{patient-reason} == 6'
        },
        // Insurance reason
        {
          name: 'insurance-reason',
          title: 'Select an insurance reason:',
          type: 'radiogroup',
          choices: [
            {
              value: 1,
              text: 'Prior Auth denied'
            },
            {
              value: 2,
              text: 'Out of network'
            },
            {
              value: 3,
              text: 'Mandated use of alternate provider ie SP'
            }
          ],
          isRequired: true,
          visibleIf: '{discontinuation-reason} == 7'
        },
        // Insurance reason --- Prior Auth denied
        {
          name: 'prior-auth-denied',
          title: 'Reason why Prior Auth denied:',
          type: 'radiogroup',
          choices: [
            {
              value: 1,
              text: 'Drug not covered'
            },
            {
              value: 2,
              text: 'Failed to meet clinical criteria'
            }
          ],
          isRequired: true,
          visibleIf: '{insurance-reason} == 1'
        },
        // Physician reason
        {
          name: 'physician-reason',
          title: 'Select a physician reason:',
          type: 'radiogroup',
          choices: [
            {
              value: 1,
              text: 'Sent to other provider'
            },
            {
              value: 2,
              text: 'Discontinued treatment'
            },
            {
              value: 3,
              text: 'Changed treatment'
            },
            {
              value: 4,
              text: "Doesn't want to perform tests / labs"
            },
            {
              value: 5,
              text: 'Upset with service'
            }
          ],
          isRequired: true,
          visibleIf: '{discontinuation-reason} == 8'
        },
        // Physician reason --- Discontinued treatment
        {
          name: 'discontinued-treatment',
          title: 'Discontinued treatment reason:',
          type: 'comment',
          isRequired: true,
          visibleIf: '{physician-reason} == 2'
        },
        // Physician reason --- Changed treatment
        {
          name: 'changed-treatment',
          title: 'Why did physician change treatment?',
          type: 'radiogroup',
          choices: [
            {
              value: 1,
              text: 'Not working'
            },
            {
              value: 2,
              text: 'New drug'
            },
            {
              value: 3,
              text: 'Side effects'
            },
            {
              value: 4,
              text: 'Other'
            }
          ],
          isRequired: true,
          visibleIf: '{physician-reason} == 3'
        },
        // Physician reason --- Upset with service
        {
          name: 'upset-with-service',
          title: 'Reason why upset with service:',
          type: 'comment',
          isRequired: true,
          visibleIf: '{physician-reason} == 5'
        }
      ]
    }
  ],
  showQuestionNumbers: 'off',
  showPrevButton: false,
  completedHtml: 'Survey completed, saving data...',
  goNextPageAutomatic: true,
  showPreviewBeforeComplete: 'showAllQuestions',
  clearInvisibleValues: 'onHidden',
  questionsOnPageMode: 'singlePage'
};
