export const INTAKE_QUEUE = 'Patient Work Queue';
export const DASHBOARD = 'Dashboard';
export const INFUSION_QUEUE = 'Infusion Queue';
export const SCHEDULING_QUEUE = 'Scheduling Queue';
export const NURSING_QUEUE = 'Nursing Queue';
export const CALENDAR = 'Calendar';
export const INVENTORY = 'Inventory';
export const DATA_ADMIN = '[Data Admin]';
export const BILLING_QUEUE = 'Billing Queue';
export const CLINICIAN_QUEUE = 'Clinician Queue';
export const BI_QUEUE = 'BI Queue';
export const PA_QUEUE = 'Prior Auth Queue';
export const PATIENT_CALL = 'Patient Call Queue';
export const RELEASES = 'Release Notes';
export const FAILED_FAX_QUEUE = 'Failed Fax Queue';
export const MAIN_QUEUE_NEW = 'Queue Center (NEW)';
export const INTAKE_QUEUE_NEW = 'Intake Queue (NEW)';
export const BI_QUEUE_NEW = 'BI Queue (NEW)';
export const PA_QUEUE_NEW = 'PA Queue (NEW)';
export const SALES_QUEUE_NEW = 'Sales Queue (NEW)';
export const INBOUND_FAX_QUEUE_NEW = 'Inbound Fax Queue (NEW)';
export const WIDGETS = 'Widgets (DEMO)';
export const LOG_OUT = 'Logout';

export const fullMenu = [
  INTAKE_QUEUE,
  INFUSION_QUEUE,
  SCHEDULING_QUEUE,
  NURSING_QUEUE,
  CALENDAR,
  INVENTORY,
  DATA_ADMIN,
  BILLING_QUEUE,
  CLINICIAN_QUEUE,
  BI_QUEUE,
  PA_QUEUE,
  PATIENT_CALL,
  RELEASES,
  FAILED_FAX_QUEUE,
  LOG_OUT,
  DASHBOARD,
  MAIN_QUEUE_NEW,
  INTAKE_QUEUE_NEW,
  BI_QUEUE_NEW,
  PA_QUEUE_NEW,
  SALES_QUEUE_NEW,
  INBOUND_FAX_QUEUE_NEW,
  WIDGETS,
  LOG_OUT
];
