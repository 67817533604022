import React, { useEffect, useState, useContext } from "react";
import { Grid } from "@progress/kendo-react-grid";
import { GridColumn as Column } from "@progress/kendo-react-grid/dist/npm/GridColumn";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { UserContext } from "@/context/UserContext";
import { connectToGraphqlAPI } from "@/provider";
import { orderBy } from "@progress/kendo-data-query";
import {
  getOrderReports,
  getOrderReportsByDateRange
} from "@/graphql/queries";
import {
  formatDateToDefault,
  formatDateToAWSDateTime,
  formatDateToAWS
} from "@/common/DateHelper";
import {
  validateInput,
  DatePickerField
} from "@/common/Validation";
import { Constants } from "@/constants";

const initialSort = [
  {
    field: "reportedAt",
    dir: "desc",
  },
];  

const History = () => {
  const { user } = useContext(UserContext);
  const [orderHistory, setOrderHistory] = useState([]);
  const [details, setDetails] = useState(null);
  const [reportId, setReportId] = useState(null);
  const [sort, setSort] = React.useState(initialSort);

  // MAIN INITIATOR
  useEffect(() => {
    fetchOrderHistory();
  }, []);

  const fetchOrderHistory = async () => {
    try {
      await connectToGraphqlAPI({
        graphqlQuery: getOrderReports,
        variables: {
          agentId: user.username
        }
      }).then(res => {
        const reports = res.data?.getOrderReportsByDateRange?.reports ?? [];
        setOrderHistory(reports);
      });
    } catch (err) {
      if (err && err.errors && err.errors.length > 0) {
        //
      }
    }
  };

  const handleRunReport = async props => {
    try {
      // clean up current details
      setDetails(null);

      await connectToGraphqlAPI({
        graphqlQuery: getOrderReportsByDateRange,
        variables: {
          agentId: user.username,
          startDate: formatDateToAWSDateTime(props.fromDateRange),
          endDate: formatDateToAWSDateTime(props.toDateRange)
        }
      }).then(res => {
        const reports = res.data?.getOrderReportsByDateRange?.reports ?? [];
        setOrderHistory(reports);
      });
    } catch (err) {
      if (err && err.errors && err.errors.length > 0) {
        //
      }
    }
  };

  ////validation
  const orderHistoryForm = {
    fromDateRange: {
      value: "",
      inputValidator: value => {
        return validateInput({
          referralId: { ...orderHistoryForm.fromDateRange, value }
        });
      },
      validations: [
        {
          type: "required",
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    },
    toDateRange: {
      value: "",
      inputValidator: value => {
        return validateInput({
          referralId: { ...orderHistoryForm.toDateRange, value }
        });
      },
      validations: [
        {
          type: "required",
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    },
  }

  const customCell = props => {
    const { reportId, orders } = props.dataItem;

    const details = orders.map(order => {
      return {
        ...order,
        updatedAt: formatDateToDefault(order.updatedAt)
      };
    });

    return (
      <td>
        <button
          type="button"
          primary="true"
          className="k-button mr-1 mt-3"
          onClick={() => {
            setDetails([...details]);
            setReportId(reportId);
          }}
        >
          View Details
        </button>
      </td>
    );
  };



  return (
    <div className="row">
      <div className="col-11 mt-2 ml-3">
        <Form
          onSubmit={handleRunReport}
          render={formRenderProps => (
            <form onSubmit={formRenderProps.onSubmit}>
              <div className="row col-md-11 mt-0 mb-3">
                {/* <div className="col-md-2">
                  LOCATION: &nbsp;
                  <Field
                    component={DropDownList}
                    data={locations}
                    name={"location"}
                    //label={'LOCATION'}
                  />
                </div> */}
                <div className="col-md-2 mt-0">
                  FROM:&nbsp;
                  <Field
                    component={DatePickerField}
                    name={"fromDateRange"}
                    label=""
                    validator={orderHistoryForm.fromDateRange.inputValidator}
                  />
                </div>
                <div className="col-md-2 mt-0">
                  TO:&nbsp;
                  <Field component={DatePickerField} name={"toDateRange"} label="" validator={orderHistoryForm.toDateRange.inputValidator} />
                </div>
                <div className="col-md-2 mt-12">
                  <button type="submit" className="k-button blue">
                    RUN REPORT
                  </button>
                </div>
              </div>
            </form>
          )}
        />
        <Grid 
         data={orderBy(orderHistory, sort)}
         sortable={true}
         sort={sort}
         onSortChange={(e) => {
             setSort(e.sort);
           }}
            style={{
              height: "400px"
          }}
  >
          <Column field="reportedAt" title="DATE REPORTED" width="150" />
          <Column field="reportId" title="REPORT ID"  width="700" />
          <Column field="action" title={" "} cell={customCell} />
        </Grid>
        {details && (
          <>
            <div className="row my-3">
              <div className="col-md-12 pageTitle">Order Report Details</div>
              <div className="col-md-12">Report Id: {reportId}</div>
            </div>
            <Grid data={details}>
              <Column field="id" title="ORDER ID" width="400" />
              <Column field="locationName" title="LOCATION" />
              <Column field="updatedAt" title="DATE REQUESTED" />
              <Column field="productName" title="PRODUCT" />
              <Column field="orderQuantity" title="QTY ORDERED" />
            </Grid>
          </>
        )}
      </div>
    </div>
  );
};

export default History;
