import React, { useContext, useEffect, useState } from 'react';

// gql
import { connectToGraphqlAPI } from '@/provider';
import { updatePatientStatus, deactivatePatient } from '@/graphql/mutations';
import { checkPatientForDeactivation } from '@/graphql/queries';

// context
import { UserContext, PatientContext, NotifContext } from '@/context';

// kendo
import { Dialog } from '@progress/kendo-react-dialogs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Card, CardBody } from '@progress/kendo-react-layout';
import { Button } from '@progress/kendo-react-buttons';

// components
import AwesomeLabel from '@/components/common-components/AwesomeLabel';
import AwesomeLink from '@/components/common-components/AwesomeLink';
import Alert from '@/components/common-components/Alert';

// utils
import {
  formatDateToAWS,
  formatDateToDefault,
  isToday
} from '@/common/DateHelper';
import { toProperCase } from '@/common/Converters';
import { onHoldReasons } from './PatientListData';
import { patientStatuses } from '@/constants/dict';
import { patientStatusCodes } from '@/constants/enum';

const { SEASONAL, ON_HOLD, INACTIVE, ACTIVE, EXPEDITED, STAT } =
  patientStatuses;

const PatientStatusChange = () => {
  const { agent, agentId } = useContext(UserContext);
  const {
    selectedPatientInfo,
    setSelectedPatientInfo,
    setShowStatusLog,
    getSystemScheduledItemsByPatientCall
  } = useContext(PatientContext);
  const { showError, showSuccess, showWarning } = useContext(NotifContext);

  const properCaseStatus = toProperCase(selectedPatientInfo?.patientStatus);
  const dateStatuses = [ON_HOLD, SEASONAL];
  const statusList = [ACTIVE, SEASONAL, ON_HOLD, INACTIVE, EXPEDITED, STAT];
  const today = new Date();
  const statusOptions =
    properCaseStatus === ACTIVE || !properCaseStatus ? statusList : [ACTIVE];
  const [isVisible, setIsVisible] = useState(false);
  const [holdReasons, setHoldReasons] = useState('');
  const [status, setStatus] = useState(properCaseStatus || ACTIVE);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showDates, setShowDates] = useState(
    dateStatuses.includes(properCaseStatus)
  );
  const [showCardDates, setShowCardDates] = useState(
    dateStatuses.includes(properCaseStatus)
  );
  useEffect(() => {
    if (dateStatuses.includes(properCaseStatus)) {
      setStartDate(
        new Date(
          formatDateToDefault(
            selectedPatientInfo?.patientStatusEffectivePeriod?.startDate ||
              today
          )
        )
      );
      setEndDate(
        new Date(
          formatDateToDefault(
            selectedPatientInfo?.patientStatusEffectivePeriod?.endDate || today
          )
        )
      );
    }

    if (properCaseStatus === ON_HOLD) {
      const patientStatusHistory = selectedPatientInfo?.patientStatusHistory;
      const lastIndex = patientStatusHistory
        ? patientStatusHistory.length - 1
        : -1;
      setHoldReasons(patientStatusHistory?.[lastIndex]?.notes);
    }
  }, []);

  const handleReasonChange = (event) => {
    setHoldReasons(event.target.value);
  };

  const handleStatusChange = (event) => {
    if (
      event?.target?.value === SEASONAL &&
      !selectedPatientInfo?.patientProfile?.patientInfo?.secondaryAddress
        ?.streetName
    ) {
      showWarning(
        'Warning: Secondary address has not been added for this patient'
      );
    }
    setStatus(event.target.value);
    setShowDates(dateStatuses.includes(event.target.value));
  };

  const clearDates = () => {
    if (status === INACTIVE || status === ACTIVE) {
      setStartDate(null);
      setEndDate(null);
    }
  };
  const showChangeStatus = () => {
    /// Added to handle historical data that does not have a start date or end date.
    if (
      !selectedPatientInfo?.patientStatusEffectivePeriod?.startDate &&
      (properCaseStatus === ON_HOLD || properCaseStatus === SEASONAL)
    ) {
      showWarning(
        'No start date found for this patient status. Defaulting to today'
      );
    }
    if (
      !selectedPatientInfo?.patientStatusEffectivePeriod?.endDate &&
      (properCaseStatus === ON_HOLD || properCaseStatus === SEASONAL)
    ) {
      showWarning(
        'No end date found for this patient status. Defaulting to today'
      );
    }
    toggleDialog();
  };

  const toggleDialog = () => {
    setIsVisible(!isVisible);
    if (dateStatuses.includes(properCaseStatus)) {
      setShowDates(true);
      setStatus(properCaseStatus);
    }
  };
  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const handleSubmitClick = () => {
    if (status !== INACTIVE) {
      UpdatePatientStatusCall();
    } else {
      UpdatePatientINACTIVE();
    }
  };

  // Allow user to keep status as Seasonal or On Hold and update start and end dates
  const isUpdateDisabled =
    (showDates && (!startDate || !endDate)) ||
    (status === properCaseStatus && !startDate && !endDate) ||
    (status === patientStatuses.ON_HOLD && !holdReasons);
  // Check if end date is at least one day ahead of start date
  const isEndDateValid = endDate && startDate && endDate > startDate;

  const isNotDateStatus =
    !isEndDateValid &&
    status !== patientStatuses.INACTIVE &&
    status !== patientStatuses.ACTIVE &&
    status !== patientStatuses.EXPEDITED &&
    status !== patientStatuses.STAT;

  const UpdatePatientStatusCall = async () => {
    try {
      const patientStatus =
        status === ON_HOLD ? patientStatusCodes.ON_HOLD : status.toUpperCase();

      const data = await connectToGraphqlAPI({
        graphqlQuery: updatePatientStatus,
        variables: {
          agentId: agent.agentId,
          patientId: selectedPatientInfo.patientId,
          patientStatus,
          ...(endDate &&
            status !== patientStatuses.ACTIVE && {
              effectivePeriod: {
                endDate: formatDateToAWS(endDate),
                startDate: formatDateToAWS(startDate)
              }
            }),
          ...(status === ON_HOLD && {
            reasons: holdReasons
          })
        }
      });
      if (data.data?.updatePatientStatus?.statusCode === '200') {
        // call is made to remove upcoming INACTIVE status and clear Future Status if needed
        // future event items are changed to itemStatus = 'INACTIVE' before the system will pick them up on the backend and remove them.
        await getSystemScheduledItemsByPatientCall();
        showSuccess('Patient Status Sucessfully Changed');
        toggleDialog();
        clearDates();

        // only statuses w/o future dates need to be updated
        const needStatusUpdate = !startDate || isToday(new Date(startDate));

        // update info at the context level
        setSelectedPatientInfo({
          ...selectedPatientInfo,
          patientStatus: needStatusUpdate
            ? patientStatus
            : selectedPatientInfo.patientStatus,
          patientStatusEffectivePeriod: {
            endDate: formatDateToAWS(endDate),
            startDate: formatDateToAWS(startDate)
          },
          patientStatusHistory: [
            ...(selectedPatientInfo.patientStatusHistory || []),
            {
              afterStatus: status.toUpperCase(),
              agentId: agentId,
              beforeStatus: selectedPatientInfo.patientStatus,
              date: today,
              ...(status === ON_HOLD ||
                (status === SEASONAL && {
                  effectivePeriod: {
                    endDate: formatDateToAWS(endDate),
                    startDate: formatDateToAWS(startDate)
                  }
                })),
              ...(status === ON_HOLD && {
                notes: holdReasons
              })
            }
          ]
        });

        setShowCardDates(dateStatuses.includes(status));
      } else if (data.data?.updatePatientStatus?.statusCode === '500') {
        // Added because the network status returns as 200 but there is an error with a 500 code in the response
        // if trying to update reason without changing thes status first
        showError(data.data?.updatePatientStatus?.message);
      }
    } catch (err) {
      console.error('PatientInfo::updatePatientStatusCall err:', err);
      showError('Error: check updatePatientStatusCall method');
    }
  };

  const UpdatePatientINACTIVE = async () => {
    try {
      // checkPatientForDeactivation(patientId: String!)
      const { data } = await connectToGraphqlAPI({
        graphqlQuery: checkPatientForDeactivation,
        variables: {
          patientId: selectedPatientInfo.patientId
        }
      });

      // if response is 200 then proceed, else display errors
      if (data?.checkPatientForDeactivation?.statusCode === '200') {
        const { data: patientData } = await connectToGraphqlAPI({
          graphqlQuery: deactivatePatient,
          variables: {
            agentId: agent.agentId,
            patientId: selectedPatientInfo.patientId
          }
        });

        if (patientData?.deactivatePatient?.statusCode === '200') {
          showSuccess('Patient Deactivated sucessfully');
          toggleDialog();
          clearDates();
          // update info at the context level
          setSelectedPatientInfo({
            ...selectedPatientInfo,
            patientStatus: 'INACTIVE'
          });
        } else {
          showError(patientData?.deactivatePatient?.message);
        }
      } else {
        showError(data?.checkPatientForDeactivation?.message);
      }
    } catch (err) {
      console.error('PatientInfo::UpdatePatientINACTIVE err:', err);
      showError('Error: check UpdatePatientINACTIVE method');
    }
  };
  const handleStatusClick = () => {
    setShowStatusLog(true);
  };

  return (
    <>
      <div className='col-md-3 mt-2'>
        <AwesomeLabel
          icon='chart-simple'
          title='Status'
          label={properCaseStatus || ACTIVE}
          size='xl'
          iconStyle='solid'
          customClassName='icon-blue'
        />
      </div>
      {showCardDates && (
        <>
          <div className='col-md-3 mt-2'>
            <AwesomeLabel
              icon='calendar-days'
              title='Start Date'
              label={startDate && startDate.toLocaleDateString()}
              size='xl'
              iconStyle='solid'
              customClassName='icon-blue'
            />
          </div>
          <div className='col-md-3 mt-2'>
            <AwesomeLabel
              icon='calendar'
              title='End Date'
              label={endDate && endDate.toLocaleDateString()}
              size='xl'
              iconStyle='solid'
              customClassName='icon-blue'
            />
          </div>
        </>
      )}
      {status === ACTIVE ? (
        <>
          <div className='col-md-3 mt-2'>
            <AwesomeLink
              icon='arrows-rotate'
              label='Change Patient Status'
              onClick={showChangeStatus}
            />
          </div>
          <div className='col-md-3 mt-2'>
            <AwesomeLink
              icon='user-magnifying-glass'
              label='Patient Status History'
              onClick={handleStatusClick}
            />
          </div>
        </>
      ) : (
        <div className='col-md-3 mt-2'>
          <AwesomeLink
            icon='arrows-rotate'
            label='Change Patient Status'
            onClick={showChangeStatus}
          />
          <br />
          <AwesomeLink
            icon='user-magnifying-glass'
            label='Patient Status History'
            onClick={handleStatusClick}
          />
        </div>
      )}
      {isVisible && (
        <Dialog
          title='Update Patient Status'
          width={500}
          height='auto'
          onClose={toggleDialog}
        >
          <Card>
            <CardBody>
              <div>
                <div className='col-12 mt-10'>
                  <AwesomeLabel
                    size='duotone'
                    label={' '}
                    icon='chart-simple'
                    title='Status'
                  />
                  <DropDownList
                    data={statusOptions}
                    value={status}
                    onChange={handleStatusChange}
                  />
                </div>
                {status === ON_HOLD && (
                  <div className='col-12 mt-10'>
                    <AwesomeLabel
                      size='duotone'
                      label={' '}
                      icon='chart-simple'
                      title='On Hold Reason'
                    />
                    <DropDownList
                      data={onHoldReasons}
                      value={holdReasons}
                      onChange={handleReasonChange}
                      required
                    />
                  </div>
                )}
                {showDates && (
                  <>
                    <div className='col-12 mt-10'>
                      <AwesomeLabel
                        size='duotone'
                        label={' '}
                        icon='calendar-days'
                        title='Start Date'
                      />
                      <DatePicker
                        value={startDate}
                        onChange={handleStartDateChange}
                        required={showDates}
                        min={today}
                      />
                    </div>
                    <div className='col-12 mt-10'>
                      <AwesomeLabel
                        size='duotone'
                        label={' '}
                        icon='calendar'
                        title='End Date'
                      />

                      <DatePicker
                        value={endDate}
                        onChange={handleEndDateChange}
                        required={showDates}
                        min={today}
                        valid={isEndDateValid}
                      />
                      {isEndDateValid === false && ( //isEndDateValid defaults to null so we need to check for false
                        <Alert>
                          End Date must be at least one day ahead of Start Date.
                        </Alert>
                      )}
                    </div>
                  </>
                )}

                <div className='col-12 mt-10'>
                  <Button
                    className='k-button pageButton'
                    type='Button'
                    onClick={handleSubmitClick}
                    disabled={isUpdateDisabled || isNotDateStatus}
                  >
                    Update
                  </Button>
                </div>
              </div>
            </CardBody>
          </Card>
        </Dialog>
      )}
    </>
  );
};

export default PatientStatusChange;
