import React from "react";
import { PropTypes } from "prop-types";
import { formatDateToDefault } from "@/common/DateHelper";
import { CodesInfo } from "./CodesInfo";

const getFormattedDate = (date) => {
  return date ? formatDateToDefault(date) : date;
};

/* const getVisitsPerUnit = (details) => {

  if (
    !details?.approvalInfo?.numberOfApprovedUnits ||
    !details?.approvalInfo?.numberOfApprovedVisits
  ) {
    return null;
  }
  return Math.round(
    details?.approvalInfo?.numberOfApprovedVisits /
    details?.approvalInfo?.numberOfApprovedUnits
  );
}; */

export const AuthorizationInfo = ({ details }) => {

  if (!details) {
    return null;
  }

  const { authStatus, callCompletionTime, denialReason, insuranceKey, isCompleted, mdoContacted, referralId, stat, welcomeCallCompleted } = details;
  const { isSiteSpecific, numberOfApprovedUnits, numberOfApprovedVisits, priorAuthNumber, serviceFrom, serviceTo } = details?.approvalInfo;
  const { callReference, followUpDate, insuranceCompanyName, personSpokeWith, phoneNumber, submittedDate } = details?.requestHistory;

  const followDate = getFormattedDate(followUpDate);
  const serviceFromDate = getFormattedDate(serviceFrom);
  const serviceToDate = getFormattedDate(serviceTo);
  const submitted = getFormattedDate(submittedDate);




  let statCheck = stat ? "Yes" : "No";
  let isSite = isSiteSpecific ? "Yes" : "No";


  const insuranceName = insuranceKey.toUpperCase();
  return (
    <>
    <div className="container-fluid" style={{ backgroundColor: "white" }}>
      <div className="row" style={{ backgroundColor: "#e2e1e1" }}>
        <div className="col-md-12 ml-3">
          <b>AUTHORIZATIONS-{insuranceName}</b>
        </div>
      </div>
      <div className="row mt-3 ml-3">
        
        <div className="col-md-4">
          <div className="row mb-1" >
            <b style={{ marginLeft: 14 }}>Authorizations:</b>
          </div>
          <div className="row" className="billing-spacing"><span className="billing-style">STAT:</span>&nbsp; {statCheck || "-"} </div>
          <div className="row" className="billing-spacing"><span className="billing-style">Insurance Company Name:</span>&nbsp;  {insuranceCompanyName || "-"}  </div>
          <div className="row" className="billing-spacing"><span className="billing-style">Person Spoke With:</span>&nbsp; {personSpokeWith || "-"}  </div>
          <div className="row" className="billing-spacing"><span className="billing-style">Insurance Phone Number:</span>&nbsp; {phoneNumber || "-"} </div>
          <div className="row" className="billing-spacing"><span className="billing-style">Call Reference #:</span>&nbsp; {callReference || "-"}  </div>
          <div className="row" className="billing-spacing"><span className="billing-style">Submited Date:</span>&nbsp; {submitted || "-"}  </div>
          <div className="row" className="billing-spacing"><span className="billing-style">Follow Up Date:</span>&nbsp; {followDate || "-"} </div>
        </div>
        <div className="col-md-4">
          <div className="row mb-1" >
            <b style={{ marginLeft: 14 }}>Admin Codes:</b>
          </div>
          


          {!details?.requestHistory?.adminCodes?.[0]?.code ? <p>Admin codes unavailable</p> :
            <ul style={{ listStyleType: 'none', margin: '0', padding: '0' }}>
              {!details?.requestHistory?.adminCodes?.length
                ? null
                : details?.requestHistory?.adminCodes.map(
                  (codes, index) => (
                    <CodesInfo key={index} codes={codes} />
                  )
                )}
            </ul>
          }
        </div>
        <div className="col-md-4">

          <div className="row mb-1">
            <b style={{ marginLeft: 14 }}>J Codes:</b>
          </div>
          {!details?.requestHistory?.jCodes?.[0]?.code ? <p>J Codes unavailable</p> :
            <ul style={{ listStyleType: 'none', margin: '0', padding: '0' }}>
              {!details?.requestHistory?.jCodes?.length
                ? null
                : details?.requestHistory?.jCodes.map(
                  (codes, index) => (
                    <CodesInfo key={index} codes={codes} />
                  )
                )}
            </ul>
          }

        </div>


      </div>
      <div className="line">
        <hr></hr>
      </div>
      <div className="row mt-3 ml-3">
        <div className="col-md-4" style={{marginBottom: 20}}>
          <div className="row mb-1">
            <b style={{ marginLeft: 14 }}>Prior Authorization Approval:</b>
          </div>
          <div className="row" className="billing-spacing"><span className="billing-style">Is PA Approved:</span>&nbsp; </div> {/*  */}
          <div className="row" className="billing-spacing"><span className="billing-style">Prior Auth Number:</span>&nbsp;{priorAuthNumber || "-"} </div>
          <div className="row" className="billing-spacing"><span className="billing-style">Service From Date:</span>&nbsp;{serviceFromDate || "-"}  </div>
          <div className="row" className="billing-spacing"><span className="billing-style">Service To Date:</span>&nbsp; {serviceToDate || "-"}  </div>
          <div className="row" className="billing-spacing"><span className="billing-style">Visits Approved:</span>&nbsp; {numberOfApprovedVisits || "-"} </div>
          <div className="row" className="billing-spacing"><span className="billing-style">Units Approved:</span>&nbsp; {numberOfApprovedUnits || "-"} </div>
        </div>
        <div className="col-md-4" style={{marginBottom: 20}}>

        </div>
        <div className="col-md-4" style={{marginBottom: 20}}>




        </div>


      </div>
      </div>
    </>
  );
};


AuthorizationInfo.propTypes = {
  details: PropTypes.object,
};
