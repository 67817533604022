import React, { useEffect, useContext, useMemo, useState } from 'react';

import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Form, Field } from '@progress/kendo-react-form';
import { Input, Switch } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
// import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Button } from '@progress/kendo-react-buttons';
import * as moment from 'moment';
import DeleteButton from '@/components/common-components/Form/DeleteButton';
import Alert from '@/components/common-components/Alert';

import {
  validateInput,
  validateNumeric,
  DatePickerField,
  InputField
} from '@/common/Validation';
import {
  formatDateTimeToDefault,
  formatDateToAWS,
  formatDateToDefault
} from '@/common/DateHelper';
import {
  isWeightBasedUOM,
  // getDosageUOM,
  calcRemaining,
  getConversionRate
} from '@/common/DoseHelper';
import {
  diluentValidators,
  formatDiluentProductName,
  drugDosage,
  stingsAreEqual,
  parseNumber,
  findMinVialStrength
} from './infusionHelper';

import { MessageDialog } from '@/components/common-components/MessageDialog';
import WindowDialog from '@/components/common-components/WindowDialog';
import RevisitPrep from '@/components/Infusion/Revisit/RevisitPrep';

import IVLabel from './IVLabel';
import PatientWeightLog from './PatientWeightLog';

import { Constants } from '@/constants';

import { connectToGraphqlAPI } from '@/provider';
import {
  getNursingProcess,
  getInventoryByLocation,
  listDiluentProducts,
  listReconstitutedProducts
} from '@/graphql/queries';

import { UserContext } from '@/context/UserContext';

const Prep = props => {
  const { infusion } = props;
  const { nursingProcessId } = props;
  const [nursingProcess, setNursingProcess] = useState({});
  const { headerDetailsData } = props;
  const { infusionFormData } = props;
  const { adminNumber } = props;

  // const [apiWeights, setApiWeights] = useState([]);
  const [itemWeights, setItemWeights] = useState([]);
  const [apiDrugs, setApiDrugs] = useState([]);
  const [itemDrugs, setItemDrugs] = useState([]);
  const [apiDiluents, setApiDiluents] = useState([]);
  const [itemDiluents, setItemDiluents] = useState([]);
  const [apiReconstituteds, setApiReconstituteds] = useState([]);
  const [itemReconstituteds, setItemReconstituteds] = useState([]);

  const [diluentList, setDiluentList] = useState([]);
  const [mixinList, setMixinList] = useState([]);

  const [stepAllowed, setStepAllowed] = useState(false);
  const [dialogOption, setDialogOption] = useState({});

  const [showPrintIVLabel, setShowPrintIVLabel] = useState(false);
  const [inventoryData, setInventoryData] = useState([]);
  // const [selectedDrugVial, setSelectedDrugVial] = useState({});
  // const [isInvertoryOverride, setIsInvertoryOverride] = useState(false);
  const [isEmptyInventory, setIsEmptyInvertory] = useState(false);
  const [isPreparationCompleteSwitch, setIsPreparationComplete] =
    useState(false);

  const [stepInitCompleted, setStepInitCompleted] = useState(false);

  // conversion rate related stuff:
  const [productUOM, setProductUOM] = useState(null);
  const [rateUOM, setRateUOM] = useState(null);

  // User Context
  const { isClinicalAdmin } = useContext(UserContext);

  const setDefaultDosageState = () => {
    const hasDrugsInStore = Boolean(nursingProcess &&
        nursingProcess.stepPreparation &&
        nursingProcess.stepPreparation.drugs &&
        nursingProcess.stepPreparation.drugs.length > 0);

    const hasPatientWeight = Boolean(nursingProcess &&
        nursingProcess.stepAssessment &&
        nursingProcess.stepAssessment.patientWeights &&
        nursingProcess.stepAssessment.patientWeights.length > 0);

    // last record in the array will always be CURRENT WEIGHT
    const patientWeight = hasPatientWeight
      ? nursingProcess.stepAssessment.patientWeights[
          nursingProcess.stepAssessment.patientWeights.length - 1
        ].weight
      : 0;

    const getAdminSeqData = () => {
      const data = { calcDosage: 0, unitOfMeas: '', dose: 0 };

      if (adminNumber) {
        const adminSeq = infusionFormData?.referralOrder?.administrations.find(admin => admin.adminSequenceNumber === adminNumber);

        if (adminSeq) {
          return adminSeq;
        } return data;
      }

      return data;
    };

    const adminSeqData = getAdminSeqData();
    const { calcDosage } = adminSeqData;
    const UOM = adminSeqData.unitOfMeas; // order level UOM
    const weightBasedDrug = isWeightBasedUOM(UOM);

    const adjustedDosage = hasDrugsInStore
      ? nursingProcess?.stepPreparation?.drugs[0]?.adjustedDosage || 0
      : 0;
    const selectedQuantity = hasDrugsInStore
      ? nursingProcess?.stepPreparation?.drugs[0]?.selectedQuantity || 0
      : 0;
    const wastage = hasDrugsInStore
      ? nursingProcess?.stepPreparation?.drugs[0]?.wastage || 0
      : 0;

    // for weight-based drugs only, keep for nurse's reference and never change manually
    const origDrugDose = adminSeqData.dose;

    const calculatedDosage =
      weightBasedDrug && hasPatientWeight ? patientWeight * origDrugDose : 0; // calcDosage?

    // new field - remaining qty
    const remainingQty = calcRemaining({
      wastage,
      adjustedDosage,
      selectedQuantity,
      calcDosage: origDrugDose,
      calculatedDosage
    });

    return {
      calcDosage,
      adjustedDosage,
      selectedQuantity,
      wastage,
      UOM,
      weightBasedDrug,
      calculatedDosage,
      remainingQty
    };
  };

  const [drugReady, setDrugReady] = useState(true);
  const [dosage, setDosage] = useState({});

  useEffect(() => {
    if (stepInitCompleted) {
      setDosage(setDefaultDosageState());
    }
  }, [stepInitCompleted, adminNumber]);

  useEffect(() => {
    if (productUOM) {
      const conversionRate = getConversionRate(dosage.UOM, productUOM);
      setRateUOM(conversionRate);
      setDosage({
        ...dosage,
        adjustedDosage: dosage.adjustedDosage,
        calcDosage: parseNumber(+dosage.calcDosage / conversionRate),
        calculatedDosage: parseNumber(+dosage.calculatedDosage / conversionRate),
        remainingQty:
          apiDrugs.length > 0
            ? dosage.remainingQty
            : parseNumber(+dosage.remainingQty / conversionRate),
        selecteQty: dosage.selecteQty,
        wastage: dosage.wastage
      });
    }
  }, [productUOM, dosage.UOM]);

  const labelData = {
    patientFirstName: headerDetailsData?.patientInfo?.patientFirstName
      ? headerDetailsData?.patientInfo?.patientFirstName
      : '',
    patientLastName: headerDetailsData?.patientInfo?.patientLastName
      ? headerDetailsData?.patientInfo?.patientLastName
      : '',
    dob: headerDetailsData?.patientInfo?.dob
      ? headerDetailsData?.patientInfo?.dob
      : '',
    patientId: headerDetailsData?.patientInfo?.patientId
      ? headerDetailsData?.patientInfo?.patientId
      : '',
    appointment: headerDetailsData?.eventInfo?.startTime
      ? formatDateTimeToDefault(headerDetailsData?.eventInfo?.startTime)
      : '',
    prescFirstName: headerDetailsData?.providerInfo?.firstName
      ? headerDetailsData?.providerInfo?.firstName
      : '',
    prescLastName: headerDetailsData?.providerInfo?.lastName
      ? headerDetailsData?.providerInfo?.lastName
      : '',
    productName: infusionFormData?.drugName ? infusionFormData?.drugName : '',
    // {infusionFormData?.referralOrder?.orderName}
    productCode: infusionFormData?.drugId ? infusionFormData?.drugId : '',
    dateTimeStamp: formatDateTimeToDefault(),
    // originalDosage:
    // infusionFormData?.referralOrder?.administrations[0]?.calcDosage,
    originalDosage: dosage.calcDosage,
    calculatedDosage: dosage.calculatedDosage,
    adjustedDosage: dosage.adjustedDosage,
    uom: infusionFormData?.referralOrder?.administrations[0]?.unitOfMeas
  };

  const validateNumber = value =>
    (validateNumeric(value) ? '' : Constants.ErrorMessage.Numeric_Required);

  const calcWastage = (
    selecteQty,
    orderDosage,
    adjustedDosage,
    calculatedDosage
  ) => {
    const dosage = Number(adjustedDosage || calculatedDosage || orderDosage);

    if (selecteQty > dosage) return selecteQty - dosage;

    return null;
  };

  const calcSelectedQuantity = drugs =>
    drugs.reduce(
      (accumulator, { strength, quantity }) =>
        // parseNumber(accumulator + strength * quantity * rateUOM),
        parseNumber(accumulator + strength * quantity),
      0 // 0 - initial qty
    );

  // MAIN INITIATOR
  useEffect(() => {
    getNursingProcessCall(nursingProcessId);
    getDulientProductsCall(); // setting up dulients from server
    getReconstitutedProductsCall(); // setting up reconstituted drugs from server
  }, []);

  useEffect(() => {
    handleLoadInfusion();

    if (nursingProcess.locationId) {
      listLocationInventoryCall(nursingProcess.locationId);
    }
  }, [nursingProcess]);

  const listLocationInventoryCall = async locationId => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getInventoryByLocation,
        variables: { locationId }
      });

      if (
        data &&
        data.data &&
        data.data.getInventoryByLocation &&
        data.data.getInventoryByLocation.items
      ) {
        /// /////////////////////
        let list = data.data.getInventoryByLocation.items;
        let sNextToken = data.data.getInventoryByLocation.nextToken
          ? data.data.getInventoryByLocation.nextToken
          : null;
        while (sNextToken != null) {
          try {
            const data = await connectToGraphqlAPI({
              graphqlQuery: getInventoryByLocation,
              variables: { locationId, nextToken: sNextToken }
            });

            if (
              data &&
              data.data &&
              data.data.getInventoryByLocation &&
              data.data.getInventoryByLocation.items
            ) {
              sNextToken = data.data.getInventoryByLocation.nextToken
                ? data.data.getInventoryByLocation.nextToken
                : null;
              list = list.concat(data.data.getInventoryByLocation.items);
            }
          } catch (err) {
            console.log('SiteInventory::listProductsCall err: ', err);
            sNextToken = null; // set to stop iterating
          }
        }
        /// ////////////////

        const onlySimilarDrugs = list
          .filter(
            // item => item.productId === infusionFormData.drugId
            // item => item.productName.includes(infusionFormData.drugName)
            item => stingsAreEqual(infusionFormData.drugName, item.productName))
          .filter(item => item.currentQuantity > 0); // <--- exclude everything that has 0 or negative QTY in the inventory

        if (onlySimilarDrugs.length > 0) {
          const mappedDrugs = onlySimilarDrugs
            .map(item => {
              item.optionText = `${item.productName} ${item.strengthPerVial} ${
                item.unitOfMeasure
              }, LOT# ${item.lotNumber} (${
                item.currentQuantity
              } in stock), EXP.Date ${formatDateToDefault(item.expirationDate)}`;
              return item;
            })
            .sort((a, b) => (a.productName > b.productName ? 1 : -1));

          setIsEmptyInvertory(false);
          setInventoryData(mappedDrugs);

          // define UOM at the product level
          setProductUOM(mappedDrugs[0].unitOfMeasure);
        } else {
          setIsEmptyInvertory(true);
        }

        // INVENTORY-BASED APPROACH:
        // setDiluentList(
        //   data.data.getInventoryByLocation.items
        //     .filter(item => dils.diluents.includes(item.productId))
        //     .map(item => {
        //       item.optionText = `${item.productName} ${item.strengthPerVial} ${
        //         item.unitOfMeasure
        //       }, LOT# ${item.lotNumber} (${
        //         item.currentQuantity
        //       } in stock), EXP.Date ${formatDateToDefault(
        //         item.expirationDate
        //       )}`;
        //       return item;
        //     })
        //     .sort((a, b) => (a.productName > b.productName ? 1 : -1))
        // );

        // INVENTORY-BASED APPROACH:
        // setMixinList(
        //   data.data.getInventoryByLocation.items
        //     // .filter(item => dils.mixins.includes(item.productId))
        //     .filter(item => item.productInfo?.isReconstituted)
        //     .map(item => {
        //       item.optionText = `${item.productName} ${item.strengthPerVial} ${
        //         item.unitOfMeasure
        //       }, LOT# ${item.lotNumber} (${
        //         item.currentQuantity
        //       } in stock), EXP.Date ${formatDateToDefault(
        //         item.expirationDate
        //       )}`;
        //       return item;
        //     })
        //     .sort((a, b) => (a.productName > b.productName ? 1 : -1))
        // );
      } else {
        alert('NO INVENTORY DATA AVAILABLE.');
      }
    } catch (err) {
      console.log('marty listProductInventorysCall err', err);
    }
  };

  const getDulientProductsCall = async () => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: listDiluentProducts
      });

      if (data.data?.listProducts?.items) {
        setDiluentList(data.data.listProducts.items
            .map(item => {
              item.optionText = formatDiluentProductName(
                item.productName,
                item.strength,
                item.unitOfMeas
              );
              return item;
            })
            .sort((a, b) => (a.productName > b.productName ? 1 : -1)));
      } else {
        setDiluentList([]);
      }
    } catch (err) {
      console.log('Prep getDulientProductsCall err', err);
    }
  };

  const getReconstitutedProductsCall = async () => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: listReconstitutedProducts
      });

      if (data.data?.listProducts?.items) {
        setMixinList(data.data.listProducts.items
            .map(item => {
              item.optionText = `${item.productName} (${item.productId})`;
              return item;
            })
            .sort((a, b) => (a.productName > b.productName ? 1 : -1)));
      } else {
        setMixinList([]);
      }
    } catch (err) {
      console.log('Prep getReconstitutedProductsCall err', err);
    }
  };

  // const onChangeDrugVial = ({ value }) => {
  //   setSelectedDrugVial(value);
  // };

  const getNursingProcessCall = async id => {
    let npid = id;
    if (!npid) {
      npid = '';
    }
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getNursingProcess,
        variables: { id: npid }
      });

      // STEP 1: data collection from existing record
      if (data && data.data && data.data.getNursingProcess) {
        setNursingProcess(data.data.getNursingProcess);
      } else {
        handleLoadInfusion();
      }

      setStepInitCompleted(true);
    } catch (err) {
      console.log('marty getNursingProcessCall err', err);
      alert('ERROR: getNursingProcessCall');
    }
  };

  const handleLoadInfusion = async () => {
    if (nursingProcess && nursingProcess.stepReview) {
      if (nursingProcess.stepReview.orderApproved) {
        setStepAllowed(true);
      }
    }

    if (
      nursingProcess &&
      nursingProcess.stepAssessment &&
      nursingProcess.stepAssessment.patientWeights
    ) {
      try {
        // let a = [];
        const i = [];
        nursingProcess.stepAssessment.patientWeights.map(item => {
          // let apiWeight = {
          //   recordNumber: 1,
          //   weightLB: item.weightLB, //newWeight,
          //   changeFromLastKnown: item.changeFromLastKnown, //changeInWeight,
          //   lastKnown: item.lastKnown, //previousWeight,
          //   entered: moment(new Date(item.entered)).format("YYYY-MM-DD")
          // };
          // a.push(apiWeight);
          const itemWeight = {
            patientWeightLB: item.weightLB, // newWeight,
            calcPatientWeightKG: Math.round(item.weightLB / 2.2), // newWeightKG,
            lastKnownWeightLB: item.lastKnown, // previousWeight,
            calcChangeFromLastKnown: item.changeFromLastKnown, // changeInWeight,
            origOrderWeightLB: Math.round(item.lastKnown + item.changeFromLastKnown), // [MM] need to start tracking weight at referral level too
            dateEntered: moment(new Date(item.entered)).format('MM/DD/YYYY')
          };
          i.push(itemWeight);
        });
        // setApiWeights([...a]);
        setItemWeights([...i]);
      } catch (err) {
        console.log('marty nursingProcess patientWeights err', err);
        setDialogOption({
          title: 'Infusion: Prep',
          message: 'Error: nursingProcess patientWeights',
          showDialog: true
        });
      }
    }

    if (
      nursingProcess &&
      nursingProcess.stepPreparation &&
      nursingProcess.stepPreparation.drugs
    ) {
      try {
        const a = [];
        const i = [];
        nursingProcess.stepPreparation.drugs.map(item => {
          const apiDrug = {
            recordNumber: item.recordNumber,
            ndc: item.ndc,
            vial: item.vial,
            strength: item.strength,
            uom: item.uom,
            quantity: item.quantity,
            route: item.route,
            lot: item.lot,
            expiration: formatDateToAWS(item.expiration),
            inventoryId: item.inventoryId
          };
          a.push(apiDrug);
          const itemDrug = {
            drugVial: `${item.vial} ${item.strength} ${item.uom}`,
            drugQty: item.quantity,
            drugLot: item.lot,
            drugExpDate: formatDateToDefault(item.expiration)
          };
          i.push(itemDrug);
        });
        setApiDrugs([...a]);
        setItemDrugs([...i]);

        // re-store dosage/wastage data here
        setDosage(setDefaultDosageState());
      } catch (err) {
        console.log('marty nursingProcess Drug err', err);
        setDialogOption({
          title: 'Infusion: Assessment',
          message: 'Error: nursingProcess Drug',
          showDialog: true
        });
      }
    }

    if (
      nursingProcess &&
      nursingProcess.stepPreparation &&
      nursingProcess.stepPreparation.diluent
    ) {
      try {
        const a = [];
        const i = [];
        nursingProcess.stepPreparation.diluent.map(item => {
          const apiDiluent = {
            recordNumber: item.recordNumber,
            diluent: item.diluent,
            quantity: item.quantity,
            lot: item.lot,
            expiration: formatDateToAWS(item.expiration),
            inventoryId: item.inventoryId
          };
          a.push(apiDiluent);
          const itemDiluent = {
            diluentChoice: item.diluent,
            diluentQty: item.quantity,
            diluentLot: item.lot,
            diluentExpDate: formatDateToDefault(item.expiration)
          };
          i.push(itemDiluent);
        });
        setApiDiluents([...a]);
        setItemDiluents([...i]);
      } catch (err) {
        console.log('marty nursingProcess Diluent err', err);
        setDialogOption({
          title: 'Infusion: Assessment',
          message: 'Error: nursingProcess Diluent',
          showDialog: true
        });
      }
    }

    if (
      nursingProcess &&
      nursingProcess.stepPreparation &&
      nursingProcess.stepPreparation.reconstitutedIn
    ) {
      try {
        const a = [];
        const i = [];
        nursingProcess.stepPreparation.reconstitutedIn.map(item => {
          const apiReconstituted = {
            recordNumber: item.recordNumber,
            fluid: item.fluid,
            quantity: item.quantity,
            lot: item.lot,
            expiration: formatDateToAWS(item.expiration),
            inventoryId: item.inventoryId
          };
          a.push(apiReconstituted);
          const itemReconstituted = {
            reconstitutedFluid: item.fluid,
            reconstitutedQty: item.quantity,
            reconstitutedLot: item.lot,
            reconstitutedExpDate: formatDateToDefault(item.expiration)
          };
          i.push(itemReconstituted);
        });
        setApiReconstituteds([...a]);
        setItemReconstituteds([...i]);
      } catch (err) {
        console.log('marty nursingProcess Reconstituted err', err);
        setDialogOption({
          title: 'Infusion: Assessment',
          message: 'Error: nursingProcess Reconstituted',
          showDialog: true
        });
      }
    }

    if (nursingProcess.stepPreparation?.preparationComplete) {
      setIsPreparationComplete(nursingProcess.stepPreparation.preparationComplete);
    }
  };

  const infusionForm = {
    drugVial: {
      // search both abbreviation AND name
      value: null,
      inputValidator: value => {
        return validateInput({ drugVial: { ...infusionForm.drugVial, value } });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.polIdNum_REQUIRED
        }
      ]
    },
    isPreparationComplete: {
      value: !!nursingProcess.stepPreparation?.preparationComplete,
      inputValidator: value => {
        return validateInput({
          isPreparationComplete: {
            ...infusionForm.isPreparationComplete,
            value
          }
        });
      },
      validations: [
        // {
        // 	type: "required",
        // 	message: Constants.ErrorMessage.FirstName_REQUIRED,
        // },
      ]
    },
    noMedsAdministered: {
      value: !!nursingProcess.stepPreparation?.noMedsAdministrated,
      inputValidator: value => {
        return validateInput({
          noMedsAdministered: { ...infusionForm.noMedsAdministered, value }
        });
      },
      validations: [
        // {
        // 	type: "required",
        // 	message: Constants.ErrorMessage.FirstName_REQUIRED,
        // },
      ]
    }
  };

  const handleAddMedication = dataItem => {
    const wastage = calcWastage(
      dosage.selectedQuantity,
      dosage.calcDosage,
      dataItem.adjustedDosage,
      dosage.calculatedDosage
    );

    setDosage({
      ...dosage,
      wastage,
      adjustedDosage: +dataItem.adjustedDosage,
      remainingQty: calcRemaining({
        ...dosage,
        wastage,
        adjustedDosage: dataItem.adjustedDosage
      })
    });
  };

  const togglePreparationComplete = () => {
    setIsPreparationComplete(!isPreparationCompleteSwitch);
  };

  const handleAddDrug = dataItem => {
    const quantity = dataItem?.drugQty;
    const vial = dataItem?.drugVial;
    setDrugReady(false);

    if (!quantity || !vial) return;
    if (isNaN(Number(quantity)) || Number(quantity) > vial.currentQuantity) {
      setDialogOption({
        title: 'Infusion: Prep',
        message: `Must not exceed amount that in stock. In stock - ${vial.currentQuantity}, but selected - ${quantity}.`,
        showDialog: true
      });
      return;
    }

    const apiDrug = {
      recordNumber: 1,
      ndc: vial.productId || '',
      vial: vial.productName || '',
      strength: vial?.strengthPerVial,
      uom: vial?.unitOfMeasure,
      quantity: dataItem?.drugQty,
      route: vial?.route,
      lot: dataItem?.drugLot || vial?.lotNumber,
      inventoryId: vial?.id,
      expiration: dataItem?.drugExpDate
        ? dataItem?.drugExpDate
        : vial?.expirationDate
    };

    const itemDrug = {
      // drugVial: vial ? vial.optionText : "",
      drugVial: vial
        ? `${vial.productName} ${vial.strengthPerVial} ${vial.unitOfMeasure}`
        : '',
      drugQty: dataItem.drugQty,
      drugLot: vial ? vial?.lotNumber : dataItem.drugLot,
      drugExpDate: dataItem?.drugVial?.expirationDate || ''
    };

    // if (isInvertoryOverride) {
    //   itemDrug.drugVial = dataItem.overrideDrugVial
    //     ? dataItem.overrideDrugVial.optionText
    //     : "";
    //   itemDrug.drugQty = dataItem.overrideDrugQty;
    //   itemDrug.drugLot = dataItem.overrideDrugVial ? dataItem.drugLot : "";
    //   itemDrug.drugExpDate = dataItem.drugExpDate
    //     ? moment(new Date(dataItem.drugExpDate)).format("YYYY/MM/DD")
    //     : "";

    //   apiDrug.ndc = dataItem.overrideDrugVial
    //     ? dataItem.overrideDrugVial.productId
    //     : "";
    //   apiDrug.vial = dataItem.overrideDrugVial
    //     ? dataItem.overrideDrugVial.optionText
    //     : "";

    //   apiDrug.strength = dataItem?.overrideDrugVial?.strengthPerVial;
    //   apiDrug.quantity = dataItem?.overrideDrugQty || dataItem?.drugQty;
    //   apiDrug.route = dataItem?.overrideDrugVial?.route;
    //   apiDrug.lot = dataItem?.drugLot ? dataItem?.drugLot : "";
    //   apiDrug.expiration = dataItem?.drugExpDate
    //     ? formatDateToAWS(dataItem?.drugExpDate)
    //     : "";
    //   apiDrug.uom = dataItem?.overrideDrugVial?.unitOfMeasure;
    // }

    if (apiDrug.quantity) {
      apiDrug.quantity = +apiDrug.quantity;
    }

    // CONVERSION RULES, e.g. MG to G, etc.

    // const conversionRate = getConversionRate(dosage.UOM, apiDrug.uom);

    // apiDrug.conversionRate = conversionRate;

    const concatAPIDrugs = [...apiDrugs, apiDrug];
    const selectedQuantity = calcSelectedQuantity(concatAPIDrugs);
    const wastage = calcWastage(
      selectedQuantity,
      dosage.calcDosage,
      dosage.adjustedDosage,
      dosage.calculatedDosage
    );

    const remainingQty = calcRemaining({
      ...dosage,
      selectedQuantity,
      wastage
    });

    setDosage({
      ...dosage,
      selectedQuantity,
      wastage,
      remainingQty
    });

    try {
      setApiDrugs([...apiDrugs, apiDrug]);
      setItemDrugs([...itemDrugs, itemDrug]);
    } catch (err) {
      console.log('marty handleAddDrug err', err);
      setDialogOption({
        title: 'Infusion: Prep',
        message: 'Error: handleAddDrug',
        showDialog: true
      });
    }
  };

  const handleAddDiluent = dataItem => {
    const { productName, strength, unitOfMeas } = dataItem.diluentChoice;

    const diluent = formatDiluentProductName(productName, strength, unitOfMeas);

    const apiDiluent = {
      recordNumber: 1,
      diluent,
      quantity: dataItem.diluentQty,
      lot: dataItem.lotNumber,
      expiration: formatDateToAWS(dataItem.exp)
    };

    const itemDiluent = {
      diluentChoice: diluent,
      diluentQty: dataItem.diluentQty,
      diluentLot: dataItem.lotNumber,
      diluentExpDate: formatDateToDefault(dataItem.exp)
    };

    try {
      setApiDiluents([...apiDiluents, apiDiluent]);
      setItemDiluents([...itemDiluents, itemDiluent]);
    } catch (err) {
      console.log('marty handleAddDiluent err', err);
      setDialogOption({
        title: 'Infusion: Prep',
        message: 'Error: handleAddDiluent',
        showDialog: true
      });
    }
  };

  const handleAddReconstituted = dataItem => {
    const { productName, strength, unitOfMeas } = dataItem.reconstitutedFluid;
    const fluid = formatDiluentProductName(productName, strength, unitOfMeas);

    const apiReconstituted = {
      recordNumber: 1,
      fluid,
      quantity: dataItem.reconstitutedQty,
      lot: dataItem.lotNumber,
      expiration: formatDateToAWS(dataItem.exp)
    };

    const itemReconstituted = {
      reconstitutedFluid: fluid,
      reconstitutedQty: dataItem.reconstitutedQty,
      reconstitutedLot: dataItem.lotNumber,
      reconstitutedExpDate: formatDateToDefault(dataItem.exp)
    };

    try {
      setApiReconstituteds([...apiReconstituteds, apiReconstituted]);
      setItemReconstituteds([...itemReconstituteds, itemReconstituted]);
    } catch (err) {
      console.log('Prep::handleAddReconstituted err', err);
      setDialogOption({
        title: 'Infusion: Prep',
        message: 'Error: handleAddReconstituted',
        showDialog: true
      });
    }
  };

  const handleSubmit = dataItem => {
    const requestObject = {
      // STEP 4
      // input UpdateStepPreparationInput {
      // updateStepPreparationInput: {
      // nursingProcessId: ID!
      nursingProcessId:
        nursingProcessId ||
        infusion.updateStepOrderReviewInput.nursingProcessId ||
        '',
      // agentId: ID!
      agentId: infusion.stepCheckInInput.agentId,
      // @NOTE: removed as part of updating step, now the notes live alone
      // notes: [String]
      // notes: restoreNotes(),
      // drugs: [DrugRecordInput]
      drugs: apiDrugs.map(item => {
        item.calcDosage = dosage.calculatedDosage;
        item.adjustedDosage = dosage.adjustedDosage;
        item.selectedQuantity = dosage.selectedQuantity;
        item.wastage = dosage.wastage;
        // need to prevent issues on Billing/R2 step of infusion submission:
        item.route = infusionFormData?.referralOrder?.administrations[0]?.route;

        // remove extra fields
        // delete item.conversionRate;

        return item;
      }),
      // diluent: [DiluentRecordInput]
      diluent: apiDiluents,
      // reconstitutedIn: [ReconstituteRecordInput]
      reconstitutedIn: apiReconstituteds,
      // preparationComplete: Boolean
      preparationComplete: props.infusionCompleted
        ? true
        : !!dataItem.values?.isPreparationComplete,
      // noMedsAdministrated: Boolean
      noMedsAdministrated: false // @NOTE this will be removed soon from Prer step
      // },
    };

    props.sendDataToParent(requestObject);
  };

  const handleDeleteClick = (props, object) => {
    if (props.dataIndex > -1) {
      if (object === 'drug') {
        if (props.dataIndex > -1) {
          const cloneApiDrugs = [...apiDrugs];
          cloneApiDrugs.splice(props.dataIndex, 1);
          setApiDrugs(cloneApiDrugs);
          const cloneItemDrugs = [...itemDrugs];
          cloneItemDrugs.splice(props.dataIndex, 1);
          setItemDrugs(cloneItemDrugs);

          // additionally need to update dosage details
          const selectedQuantity = calcSelectedQuantity(cloneApiDrugs);
          const wastage = calcWastage(
            selectedQuantity,
            dosage.calcDosage,
            dosage.adjustedDosage,
            dosage.calculatedDosage
          );
          const remainingQty = calcRemaining({
            ...dosage,
            selectedQuantity,
            wastage
          });

          setDosage({ ...dosage, selectedQuantity, wastage, remainingQty });
        }
      }
      if (object === 'diluent') {
        if (props.dataIndex > -1) {
          const cloneApiDiluents = [...apiDiluents];
          cloneApiDiluents.splice(props.dataIndex, 1);
          setApiDiluents(cloneApiDiluents);
          const cloneItemDiluents = [...itemDiluents];
          cloneItemDiluents.splice(props.dataIndex, 1);
          setItemDiluents(cloneItemDiluents);
        }
      }
      if (object === 'reconstituted') {
        if (props.dataIndex > -1) {
          const cloneApiReconstituteds = [...apiReconstituteds];
          cloneApiReconstituteds.splice(props.dataIndex, 1);
          setApiReconstituteds(cloneApiReconstituteds);
          const cloneItemReconstituteds = [...itemReconstituteds];
          cloneItemReconstituteds.splice(props.dataIndex, 1);
          setItemReconstituteds(cloneItemReconstituteds);
        }
      }
    }
  };

  const checkForDrug = () => {
    if (apiDrugs.length === 0) {
      setDrugReady(true);
    } else if (apiDrugs.length !== 0) {
      setDrugReady(false);
    }
  };

  useMemo(() => {
    checkForDrug();
  }, [apiDrugs]);

  const customCellDeleteDrug = props => {
    return (
      <td>
        <DeleteButton handleClick={() => handleDeleteClick(props, 'drug')} />
        {/* <button
          type="button"
          className="k-button"
          onClick={() => handleDeleteClick(props, "drug")}
        >
          X
        </button> */}
      </td>
    );
  };

  const customCellDeleteDiluent = props => {
    return (
      <td>
        <DeleteButton handleClick={() => handleDeleteClick(props, 'diluent')} />
        {/* <button
          type="button"
          className="k-button"
          onClick={() => handleDeleteClick(props, "diluent")}
        >
          X
        </button> */}
      </td>
    );
  };

  const customCellDeleteReconstituted = props => {
    return (
      <td>
        <DeleteButton
          handleClick={() => handleDeleteClick(props, 'reconstituted')}
        />
        {/* <button
          type="button"
          className="k-button"
          onClick={() => handleDeleteClick(props, "reconstituted")}
        >
          X
        </button> */}
      </td>
    );
  };

  const handlePrintIVLabel = () => {
    if (showPrintIVLabel) {
      setShowPrintIVLabel(false);
    }
    if (!showPrintIVLabel) {
      setShowPrintIVLabel(true);
    }
  };

  const printIvLabel = () => {
    const w = window.open();

    const htmlBody = `
      <h3>AleraCare California</h3>
      <hr />
      <p><b>${labelData.patientFirstName} ${labelData.patientLastName}</b></p>
      <p>DOB:&nbsp;${labelData.dob}</p>
      <p>IV${labelData.patientId}</p>
      <p>PROVIDER:&nbsp;${labelData.prescFirstName}&nbsp;${labelData.prescLastName}</p>
      <hr />
      <p><b>${labelData.productName}</b></p>
      <p>Meds:&nbsp;${labelData.productCode}</p>
      <p>Original Dosage:&nbsp;${labelData?.originalDosage}&nbsp;${labelData.uom}</p>
      <p>Calculated Dosage:&nbsp;${labelData.calculatedDosage}&nbsp;${labelData.uom}</p>
      <p>Adjusted Dosage:&nbsp;${labelData.adjustedDosage}&nbsp;${labelData.uom}</p>
      <p>Prepped By:</p>
      <p>Prepped Time:</p>
      <p>Use By:</p>
      <p><b>Federal Law Prohibits Dispensing Without a Prescription</b></p>
      <p>Printed:&nbsp;${labelData.dateTimeStamp}</p>
    `;

    const html = `
    <!DOCTYPE HTML>
      <html lang="en-us">
      <head><title>Print IV Label</title><style></style></head>
      <body>${htmlBody}</body>
    `;

    w.document.write(html);
    w.window.print();
    w.document.close();
  };

  const renderNoVials = () => {
    if (isEmptyInventory) {
      const drugName =
        infusionFormData?.referralOrder?.orderName || 'Unknown Drug Name';
      return (
        <small style={{ color: 'red', fontStyle: 'italic' }}>
          There&apos;s no medication for {drugName} in the inventory.
        </small>
      );
    }

    return null;
  };

  const prepareWeightInfo = () => {
    const bucketWeights =
      props.selectedPatientInfo?.patientProfile?.weightInfo || [];
    const npWeights = nursingProcess.stepAssessment?.patientWeights || [];

    const resWeight =
      npWeights.length > 0 ? [...npWeights] : [...bucketWeights];

    return {
      patientProfile: {
        weightInfo: [...resWeight]
      }
    };
  };

  const updatedPatientInfo = prepareWeightInfo();

  // only CLINICAL ADMINS can manage this section after 24 hrs
  if (props.infusionRestricted && !isClinicalAdmin) {
    return (
      <RevisitPrep
        handlePrintIVLabel={handlePrintIVLabel}
        showPrintIVLabel={showPrintIVLabel}
        // isInvertoryOverride={isInvertoryOverride}
        itemDrugs={itemDrugs}
        itemWeights={itemWeights}
        itemDiluents={itemDiluents}
        itemReconstituteds={itemReconstituteds}
        infusionFormData={infusionFormData}
        labelData={labelData}
        dosage={dosage}
        patientInfo={updatedPatientInfo}
      />
    );
  }

  // Medication Overage flag
  const hasOverage =
    dosage.wastage > 0 && dosage.wastage >= findMinVialStrength(apiDrugs);

  // Deactivate toggler for completed infusions
  const togglerProps = {
    disabled: !!props.infusionCompleted,
    defaultChecked: props.infusionCompleted
      ? true
      : infusionForm.isPreparationComplete.value
  };

  return (
    <div className="infusion-page">
      {dialogOption && dialogOption.showDialog && (
        <MessageDialog dialogOption={dialogOption} />
      )}

      {stepAllowed && (
        <>
          <div
            className="infusion-details col-md-11 mt-2 mb-3"
            style={{ border: '1px solid #afaaaa' }}
          >
            <div className="row">
              <div className="infusion-HeaderRow col-12 ml-0 pl-2 py-2 mr-0">
                <div className="row">
                  <div className="col-md-2 headerText">PATIENT WEIGHT</div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mt-3 mb-3">
                <PatientWeightLog patientInfo={updatedPatientInfo} />
              </div>
            </div>
          </div>

          <div
            className="infusion-details col-md-11"
            style={{ border: '1px solid #afaaaa' }}
          >
            <div className="row">
              <div className="infusion-HeaderRow col-12 ml-0 pl-3 py-3 mr-0">
                <div className="row">
                  <div className="col-md-2 headerText">
                    {' '}
                    MEDICATION: &nbsp;{' '}
                    {infusionFormData?.referralOrder?.orderName}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* DRUG SELECTION */}
          <div
            className="infusion-details col-md-11 mt-2 mb-3"
            style={{
              border: '1px solid #afaaaa',
              backgroundColor: '#ffffff'
            }}
          >
            <div className="row">
              <div className="infusion-SubHeaderRowGrey col-12 ml-0 pl-1 py-2 mr-0">
                <div className="row">
                  <div className="col-md-4 headerText">DRUG SELECTION</div>
                </div>
              </div>
            </div>
            <Form
              onSubmit={handleAddDrug}
              render={formRenderProps => (
                <form
                  onSubmit={formRenderProps.onSubmit}
                  className="k-form pt-1"
                >
                  <div className="row col-md-11 mt-3 mb-3">
                    <div className="col-md-8">
                      <Field
                        component={DropDownList}
                        data={inventoryData}
                        name="drugVial"
                        label="VIAL"
                        textField="optionText"
                        // onChange={event => onChangeDrugVial(event)}
                        validator={infusionForm.drugVial.inputValidator}
                      />
                      {renderNoVials()}
                    </div>
                    <div className="col-md-2">
                      <Field
                        component={Input}
                        name="drugQty"
                        label="QTY"
                        validator={validateNumber}
                      />
                    </div>
                    <div className="col-md-2 mt-12">
                      <button type="submit" className="k-button blue">
                        SELECT
                      </button>
                    </div>
                  </div>
                  {/* {isInvertoryOverride && (
                    <div className="row col-md-11 mt-3 mb-3">
                      <div className="col-md-3">
                        <Field
                          component={DropDownList}
                          data={inventoryData}
                          name={"overrideDrugVial"}
                          label={"VIAL"}
                          textField={"optionText"}
                        />
                      </div>
                      <div className="col-md-3">
                        <Field
                          component={Input}
                          name={"drugLot"}
                          label={"LOT"}
                        />
                      </div>
                      <div className="col-md-1 ">
                        {" "}
                        <Field
                          component={Input}
                          name={"drugStock"}
                          label={"STOCK"}
                        />
                      </div>
                      <div className="col-md-1">
                        <Field
                          component={Input}
                          name={"overrideDrugQty"}
                          label={"QTY"}
                        />
                      </div>
                      <div className="col-md-2 mt-0">
                        EXP DATE:
                        <br />
                        <Field
                          component={DatePicker}
                          name={"drugExpDate"}
                          label={""}
                        />
                      </div>
                    </div>
                  )} */}
                  <div className="row">
                    <div className="col-md-11 mt-1 ml-3 mb-2 mr-3">
                      <Grid className="infusion-grid" data={itemDrugs}>
                        <Column field="drugQty" title="QTY" />
                        <Column field="drugVial" title="VIAL" />
                        <Column field="drugLot" title="LOT" />
                        <Column field="drugExpDate" title="EXP" />
                        <Column
                          field="action"
                          title=" "
                          cell={customCellDeleteDrug}
                        />
                      </Grid>
                    </div>
                  </div>
                </form>
              )}
            />

            <Form
              onSubmit={handleAddMedication}
              render={formRenderProps => (
                <form
                  onSubmit={formRenderProps.onSubmit}
                  className="k-form pt-1"
                >
                  <div className="infusion-details col-md-11 ml-0 pl-3 py-2 mr-0">
                    {hasOverage && (
                      <div className="row mb-3">
                        <div className="col-md-6">
                          <Alert>Alert: Medication Overage!</Alert>
                        </div>
                      </div>
                    )}
                    <div className="row">
                      {drugDosage.map(dsg => {
                        if (dosage[dsg.key]) {
                          // const uom = getDosageUOM(dosage.UOM);
                          const uom = productUOM;
                          const style =
                            dsg.key === 'remainingQty' ? { color: 'red' } : {};
                          return (
                            <div className="col-md-2 headerText" style={style}>
                              {/* {parseNumber(dosage[dsg.key] / rateUOM)} {uom} */}
                              {parseNumber(dosage[dsg.key])} {uom}
                            </div>
                          );
                        }
                      })}
                    </div>
                    <div className="row">
                      {drugDosage.map(drugDosage => {
                        if (dosage[drugDosage.key]) {
                          return (
                            <div className="col-2 infusion-details-field-name">
                              {drugDosage.title}
                            </div>
                          );
                        }
                      })}
                    </div>
                    <div className="row mb-2">
                      <div className="col-md-3">
                        <Field
                          component={Input}
                          name="adjustedDosage"
                          label="Adjusted Dosage"
                        />
                      </div>
                      <div className="col-md-3 mt-12">
                        <button type="submit" className="k-button blue">
                          Adjust Dosage
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            />
          </div>
          {/* DILUENT SELECTION */}
          <Form
            validator={diluentValidators}
            onSubmit={handleAddDiluent}
            render={formRenderProps => (
              <form
                onSubmit={formRenderProps.onSubmit}
                className="k-form pt-1"
              >
                <div
                  className="infusion-details col-md-11 mt-2 mb-3"
                  style={{
                    border: '1px solid #afaaaa',
                    backgroundColor: '#ffffff'
                  }}
                >
                  <div className="row">
                    <div className="infusion-SubHeaderRowGrey col-12 ml-0 pl-1 py-2 mr-0">
                      <div className="row">
                        <div className="col-md-4 headerText">
                          DILUENT SELECTION
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row col-md-11 mt-3 mb-3">
                    <div className="col-md-4">
                      <Field
                        component={DropDownList}
                        // data={diluents}
                        data={diluentList}
                        name="diluentChoice"
                        label="DILUENT"
                        textField="optionText"
                      />
                    </div>
                    <div className="col-md-2">
                      <Field
                        component={InputField}
                        name="diluentQty"
                        label="QTY"
                        validator={validateNumber}
                      />
                    </div>
                    <div className="col-md-2">
                      <Field
                        component={InputField}
                        name="lotNumber"
                        label="LOT NUMBER"
                      />
                    </div>
                    <div className="col-md-2 ">
                      Expiration Date
                      <br />
                      <Field component={DatePickerField} name="exp" />
                    </div>
                    <div className="col-md-2 mt-12">
                      <button type="submit" className="k-button blue">
                        ADD
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-11 mt-1 ml-3 mb-2 mr-3">
                      <Grid className="infusion-grid" data={itemDiluents}>
                        <Column field="diluentQty" title="QTY" width="100px" />
                        <Column
                          field="diluentChoice"
                          title="DILUENT"
                          width="200px"
                        />
                        <Column field="diluentLot" title="LOT" width="200px" />
                        <Column
                          field="diluentExpDate"
                          title="EXP"
                          width="150px"
                        />
                        <Column
                          field="action"
                          title=" "
                          cell={customCellDeleteDiluent}
                        />
                      </Grid>
                    </div>
                  </div>
                </div>
              </form>
            )}
          />
          {/* RECONSTITUTED-IN SELECTION */}
          <Form
            validator={diluentValidators}
            onSubmit={handleAddReconstituted}
            render={formRenderProps => (
              <form
                onSubmit={formRenderProps.onSubmit}
                className="k-form pt-1"
              >
                <div
                  className="infusion-details col-md-11 mt-2 mb-3"
                  style={{
                    border: '1px solid #afaaaa',
                    backgroundColor: '#ffffff'
                  }}
                >
                  <div className="row">
                    <div className="infusion-SubHeaderRowGrey col-12 ml-0 pl-1 py-2 mr-0">
                      <div className="row">
                        <div className="col-md-4 headerText">
                          RECONSTITUTED-IN SELECTION
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row col-md-11 mt-3 mb-3">
                    <div className="col-md-4">
                      <Field
                        component={DropDownList}
                        // data={mixins}
                        data={mixinList}
                        name="reconstitutedFluid"
                        label="RECONSTITUTED FLUID"
                        textField="optionText"
                      />
                    </div>
                    <div className="col-md-2">
                      <Field
                        component={InputField}
                        name="reconstitutedQty"
                        label="QTY"
                        validator={validateNumber}
                      />
                    </div>
                    <div className="col-md-2">
                      <Field
                        component={InputField}
                        name="lotNumber"
                        label="LOT NUMBER"
                      />
                    </div>
                    <div className="col-md-2 ">
                      Expiration Date
                      <br />
                      <Field component={DatePickerField} name="exp" />
                    </div>
                    <div className="col-md-2 mt-12">
                      <button type="submit" className="k-button blue">
                        ADD
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-11 mt-1 ml-3 mb-2 mr-3">
                      <Grid className="infusion-grid" data={itemReconstituteds}>
                        <Column
                          field="reconstitutedQty"
                          title="QTY"
                          width="100px"
                        />
                        <Column
                          field="reconstitutedFluid"
                          title="DILUENT"
                          width="200px"
                        />
                        <Column
                          field="reconstitutedLot"
                          title="LOT"
                          width="200px"
                        />
                        <Column
                          field="reconstitutedExpDate"
                          title="EXP"
                          width="150px"
                        />
                        <Column
                          field="action"
                          title=" "
                          cell={customCellDeleteReconstituted}
                        />
                      </Grid>
                    </div>
                  </div>
                </div>
              </form>
            )}
          />
          {/* SUBMIT FORM */}
          <Form
            onSubmitClick={handleSubmit}
            render={({ onSubmit }) => (
              <form onSubmit={onSubmit} className="k-form pl-3 pr-3 pt-1">
                <div className="row col-md-12 mt-3 mb-3">
                  <div className="col-md-3">
                    Preparation Complete: &nbsp;
                    <Field
                      component={Switch}
                      onLabel="Yes"
                      offLabel="No"
                      name="isPreparationComplete"
                      // defaultChecked={infusionForm.isPreparationComplete.value}
                      value={isPreparationCompleteSwitch}
                      onChange={togglePreparationComplete}
                      {...togglerProps}
                    />
                  </div>
                </div>
                {drugReady && (
                  <div className="row mt-1 ml-3">
                    <Alert>ALERT: No medications have been selected</Alert>
                  </div>
                )}
                <div className="row mt-5 mb-5">
                  <div className="col-md-3">
                    <button type="submit" className="k-button pageButton">
                      Save
                    </button>
                  </div>
                  <div className="col-md-9">
                    <Button
                      type="button"
                      className="k-button"
                      onClick={printIvLabel}
                      disabled={!isPreparationCompleteSwitch}
                    >
                      <i className="fa-solid fa-print" />&nbsp;Print IV Label
                    </Button>
                    <div style={{ marginLeft: 25, display: 'inline-block' }}>
                      &nbsp;
                    </div>
                    <Button
                      type="button"
                      className="k-button"
                      onClick={handlePrintIVLabel}
                      disabled={!isPreparationCompleteSwitch}
                    >
                      <i className="fa-solid fa-file-pdf" />&nbsp;Download IV
                      Label
                    </Button>
                  </div>
                  <div className="col-md-3" />
                </div>
              </form>
            )}
          />
        </>
      )}
      <WindowDialog
        title="Print IV Label"
        style={{ backgroundColor: '#FFFFFF', minHeight: '300px' }}
        initialHeight={800}
        initialTop={1}
        width={600}
        showDialog={showPrintIVLabel}
        onClose={handlePrintIVLabel}
      >
        <IVLabel labelData={labelData} />
      </WindowDialog>
    </div>
  );
};

export default Prep;
