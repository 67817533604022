import React, { useContext, useEffect, useState } from 'react';
import * as moment from 'moment';

// kendo
import { Grid } from '@progress/kendo-react-grid';
import { GridColumn as Column } from '@progress/kendo-react-grid/dist/npm/GridColumn';

// components
import WindowDialog from '@/components/common-components/WindowDialog';

// utils
import { Constants } from '@/constants';

// gql
import { connectToGraphqlAPI } from '@/provider';
import { getBenefitCheckingHistory } from '@/graphql/queries';

// context
import { PatientContext } from '@/context';

const BenHistory = () => {
  const { selectedPatientInfo } = useContext(PatientContext);
  const [showBenefitDetails, setShowBenefitDetails] = useState(false);
  const [getBenefitCheckingHistoryData, setGetBenefitCheckingHistoryData] =
    useState({});
  const [benefitCheckingHistory, setBenefitCheckingHistory] = useState([]);

  // MAIN INITIATOR
  useEffect(() => {
    getBenefitCheckingHistoryCall({ patientId: selectedPatientInfo.patientId });
  }, []);

  const getBenefitCheckingHistoryCall = async (requestObject) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getBenefitCheckingHistory,
        variables: requestObject
      });

      if (
        data &&
        data.data &&
        data.data.getBenefitCheckingHistory &&
        data.data.getBenefitCheckingHistory.length > 0
      ) {
        if (!requestObject.recordId) {
          setBenefitCheckingHistory(
            data.data.getBenefitCheckingHistory.map((item) => {
              item.orderTimeStamp = moment(
                new Date(item.orderTimeStamp)
              ).format(Constants.DATE.DEFAULT_DATETIME);
              item.verificationDate = moment(
                new Date(item.verificationDate)
              ).format(Constants.DATE.DEFAULT);
              return item;
            })
          );
        } else {
          /* console.log(
            "BenHistory::getBenefitCheckingHistory[0]:",
            data.data.getBenefitCheckingHistory[0]
          ); */
          setGetBenefitCheckingHistoryData({
            ...data.data.getBenefitCheckingHistory[0]
          });
          setShowBenefitDetails(true);
        }
      }
    } catch (err) {
      console.log('BenHistory::getBenefitCheckingHistoryCall err', err);
    }
  };

  const customCell = (props) => {
    return (
      <td>
        <span
          className='blue-link'
          onClick={() =>
            getBenefitCheckingHistoryCall({
              patientId: selectedPatientInfo.patientId,
              recordId: props.dataItem.recordId
            })
          }
        >
          View Details
        </span>
      </td>
    );
  };

  return (
    <div className='col-md-11 ml 3'>
      <div className='row'>
        <div className='col-md-6 pageTitle ml-3 mb-1'>
          Benefits Investigation: History
        </div>
      </div>
      <div className='col'>
        <Grid data={benefitCheckingHistory}>
          <Column field='orderTimeStamp' title='Timestamp' width='175px' />
          <Column field='drugName' title='Drug Name' width='250px' />
          <Column field='insuranceType' title='Insurance Type' width='120px' />
          <Column field='insurancePlan' title='Insurance Plan' width='120px' />
          <Column
            field='verificationDate'
            title='Date Verified'
            width='150px'
          />
          <Column field='agentId' title='Agent' width='100px' />
          <Column field='action' title=' ' width='150px' cell={customCell} />
        </Grid>
      </div>
      {showBenefitDetails && (
        <WindowDialog
          title={`Benefit Details - ${getBenefitCheckingHistoryData?.drugName}`}
          style={{ backgroundColor: '#fff', minHeight: '300px' }}
          initialHeight={900}
          initialTop={1}
          initialLeft={1}
          width={1000}
          showDialog={showBenefitDetails}
          onClose={() => setShowBenefitDetails(false)}
        >
          <div className='row mt-10'>
            <div className='col-md-4'>
              ORDER NAME: ??
              <br />
              {getBenefitCheckingHistoryData?.drugName}
            </div>
            <div className='col-md-4'>
              PATIENT NAME:
              <br />
              {selectedPatientInfo?.patientFirstName}&nbsp;
              {selectedPatientInfo?.patientLastName}
            </div>
            <div className='col-md-4'>
              DOB:
              <br />
              {selectedPatientInfo?.dob}
            </div>
          </div>
          <div className='row mt-10'>
            <div className='col-md-4'>
              INSURANCE PLAN:
              <br />
              {benefitCheckingHistory?.insurancePlan}
            </div>
            <div className='col-md-4'>INSURANCE PHONE NO.:</div>
            <div className='col-md-4'>STATE:</div>
          </div>
          <div className='row mt-10'>
            <div className='col-md-4'>
              INSURANCE TYPE:
              <br />
              ??{benefitCheckingHistory?.insuranceType}
            </div>
            <div className='col-md-4'>
              POLICY ID: ??
              {
                getBenefitCheckingHistoryData?.updatedBenefitChecking?.checking
                  ?.policyId
              }
            </div>
            <div className='col-md-4'>
              GROUP ID: ??
              {
                getBenefitCheckingHistoryData?.updatedBenefitChecking?.checking
                  ?.groupId
              }
            </div>
          </div>
          <hr />
          <div className='row mt-10'>
            <h4 className='col-md-4'>BILLING INFO</h4>
          </div>
          <div className='row mt-10'>
            <div className='col-md-4'>
              AIC GROUP / TAX ID:{' '}
              {
                getBenefitCheckingHistoryData?.updatedBenefitChecking?.checking
                  ?.groupId
              }{' '}
              &nbsp; / &nbsp;{' '}
              {
                getBenefitCheckingHistoryData?.updatedBenefitChecking?.checking
                  ?.billingTaxId
              }
            </div>
            <div className='col-md-4'>
              AIC LOCATION / NPI :{' '}
              {
                getBenefitCheckingHistoryData?.updatedBenefitChecking?.checking
                  ?.billingInfoLocation?.billingNPINumber
              }
              &nbsp; / &nbsp;{' '}
              {
                getBenefitCheckingHistoryData?.updatedBenefitChecking?.checking
                  ?.billingInfoLocation?.billingNPINumber
              }
            </div>
            <div className='col-md-4'>
              PROVIDER / NPI: ??
              {
                getBenefitCheckingHistoryData?.updatedBenefitChecking?.checking
                  ?.selectedProviderId
              }
            </div>
          </div>
          <div className='row mt-10'>
            <div className='col-md-4'>Override: ?? y/n </div>
            <div className='col-md-4'>
              Override TIN/NPI:{' '}
              {
                getBenefitCheckingHistoryData?.updatedBenefitChecking?.checking
                  ?.billingTaxIdForOutOfNetwork
              }
              &nbsp; / &nbsp;{' '}
              {
                getBenefitCheckingHistoryData?.updatedBenefitChecking?.checking
                  ?.billingNPIForOutOfNetwork
              }
            </div>
          </div>
          <div className='row mt-10'>
            <div className='col-md-4'>
              OverRide Reason:{' '}
              {
                getBenefitCheckingHistoryData?.updatedBenefitChecking?.checking
                  ?.billingOverrideReason
              }
            </div>
          </div>
          <div className='row mt-10'>
            <div className='col-md-4'>
              Date Verified or Reverified:{' '}
              {
                getBenefitCheckingHistoryData?.updatedBenefitChecking?.checking
                  ?.callCompletionTime
              }
            </div>
            <div className='col-md-4'>
              Call Ref No.:{' '}
              {
                getBenefitCheckingHistoryData?.updatedBenefitChecking?.checking
                  ?.callReferenceNumber
              }
            </div>
            <div className='col-md-4'>
              Rep Name:{' '}
              {
                getBenefitCheckingHistoryData?.updatedBenefitChecking?.checking
                  ?.representativeName
              }{' '}
              ??
            </div>
          </div>
          <hr />
          <div className='row mt-10'>
            <h4 className='col-md-4 ml-0'>PAYOR</h4>
          </div>
          <div className='row mt-10'>
            <div className='col-md-4'>
              PAYOR VERIFIED BY:{' '}
              {
                getBenefitCheckingHistoryData?.updatedBenefitChecking?.checking
                  ?.policy?.verificationMethod
              }
            </div>
            <div className='col-md-4'>
              PAYOR PHONE NO.:{' '}
              {
                getBenefitCheckingHistoryData?.updatedBenefitChecking?.checking
                  ?.policy?.payerPhoneNumber
              }
            </div>
            <div className='col-md-4'>
              NETWORK STATUS:{' '}
              {
                getBenefitCheckingHistoryData?.updatedBenefitChecking?.checking
                  ?.policy?.networkStatus
              }
            </div>
          </div>

          <div className='row mt-10'>
            <div className='col-md-4'>
              OUT OF NETWORK BENEFITS: true/false ??{' '}
              {
                getBenefitCheckingHistoryData?.updatedBenefitChecking?.checking
                  ?.policy?.outOfNetworkBenefits
              }
            </div>
            <div className='col-md-4'>
              LOA:?? true/false{' '}
              {
                getBenefitCheckingHistoryData?.updatedBenefitChecking?.checking
                  ?.policy?.loa
              }
            </div>
            <div className='col-md-4'></div>
          </div>

          <div className='row mt-10'>
            <div className='col-md-4'>
              Plan Type:{' '}
              {
                getBenefitCheckingHistoryData?.updatedBenefitChecking?.checking
                  ?.policy?.planType
              }
            </div>
            <div className='col-md-4'>
              Deductible Type:{' '}
              {
                getBenefitCheckingHistoryData?.updatedBenefitChecking?.checking
                  ?.policy?.dedType
              }
            </div>
          </div>
          <div className='row mt-10'>
            <div className='col-md-3'>
              Effective Date:{' '}
              {
                getBenefitCheckingHistoryData?.updatedBenefitChecking?.checking
                  ?.policy?.effectiveDate
              }
            </div>
            <div className='col-md-3'>
              Term Date:{' '}
              {
                getBenefitCheckingHistoryData?.updatedBenefitChecking?.checking
                  ?.policy?.termDate
              }
            </div>
            <div className='col-md-3'>
              Coverage (%):{' '}
              {
                getBenefitCheckingHistoryData?.updatedBenefitChecking?.checking
                  ?.policy?.coveragePercentage
              }
            </div>
            <div className='col-md-3'>
              CoPay:{' '}
              {
                getBenefitCheckingHistoryData?.updatedBenefitChecking?.checking
                  ?.policy?.coPay
              }
            </div>
          </div>
          <div className='row mt-10 ml-1'>
            <div className='col-md-11'>
              Additional Information: ??
              {
                getBenefitCheckingHistoryData?.updatedBenefitChecking?.checking
                  ?.policy?.deductibleNotes
              }
            </div>
          </div>
          <hr />
          <div className='row mt-10'>
            <h4 className='col-md-4 ml-0'>DEDUCTIBLE / OOP </h4>
          </div>
          <div className='row mt-10 ml-1'>DEDUCTIBLE INFORMATION:</div>
          <div className='row mt-10 ml-2'>
            <div className='col-md-4'>
              Deductible for:?? single/family ??{' '}
              {
                getBenefitCheckingHistoryData?.updatedBenefitChecking?.checking
                  ?.policy?.deductibleInfo?.deductibleType
              }
            </div>
            <div className='col-md-4'>
              In/Out of Network: dedInfoNetworkStatus ??{' '}
              {
                getBenefitCheckingHistoryData?.updatedBenefitChecking?.checking
                  ?.policy?.deductibleInfo?.networkStatus
              }
            </div>
          </div>
          <div className='row mt-10 ml-2'>
            <div className='col-md-4'>
              DED AMT: ??{' '}
              {
                getBenefitCheckingHistoryData?.updatedBenefitChecking?.checking
                  ?.policy?.deductibleInfo?.totalDeductibleAmount
              }
            </div>
            <div className='col-md-4'>
              DED MET : ??{' '}
              {
                getBenefitCheckingHistoryData?.updatedBenefitChecking?.checking
                  ?.policy?.deductibleInfo?.metDeductible?.amount
              }
            </div>
            <div className='col-md-4'>
              DED RESET DATE: ??{' '}
              {
                getBenefitCheckingHistoryData?.updatedBenefitChecking?.checking
                  ?.policy?.deductibleInfo?.metDeductible?.resetDate
              }
            </div>
          </div>
          <div className='row mt-10 ml-1'>OOP INFORMATION:</div>
          <div className='row mt-10 ml-2'>
            <div className='col-md-4'>Deductible for:?? single/family</div>
            <div className='col-md-4'>
              In/Out of Network: dedInfoNetworkStatus ??
            </div>
          </div>
          <div className='row mt-10 ml-2'>
            <div className='col-md-4'>DEDUCTIBLE AMOUNT: ??</div>
            <div className='col-md-4'>
              DEDUCTIBLE MET : ?? dedInfoMetDeductibleAmount
            </div>
            <div className='col-md-4'>
              DED RESET DATE: ?? dedInfoMetDeductibleResetDate
            </div>
          </div>
          <hr />
          <div className='row mt-10'>
            <h4 className='col-md-4 ml-0'>PRIOR AUTHORIZATION </h4>
          </div>
          <div className='row mt-10 ml-1'>
            PA REQUIRED: Y status: true/false{' '}
            {
              getBenefitCheckingHistoryData?.updatedBenefitChecking?.checking
                ?.paRequired
            }
          </div>
          <div className='row mt-10'>
            <div className='col-md-11 offset-1'>
              <div className='row mt-10'>
                How to submit PA: ?? ph/fax/port{' '}
                {
                  getBenefitCheckingHistoryData?.updatedBenefitChecking
                    ?.checking?.priorAuthorization?.submitMethod
                }
              </div>
              <div className='row mt-10'>
                <div className='col-md-4'>
                  PA Phone No.: ??{' '}
                  {
                    getBenefitCheckingHistoryData?.updatedBenefitChecking
                      ?.checking?.priorAuthorization?.paPhone
                  }
                </div>
                <div className='col-md-4'>
                  Portal Link: ??{' '}
                  {
                    getBenefitCheckingHistoryData?.updatedBenefitChecking
                      ?.checking?.priorAuthorization?.paSite
                  }
                </div>
              </div>
              <div className='row mt-10'>
                <div className='col-md-4'>
                  J Code 1: ?? (* need to make a loop ){' '}
                  {
                    getBenefitCheckingHistoryData?.updatedBenefitChecking
                      ?.checking?.priorAuthorization?.jCode
                  }
                </div>
                <div className='col-md-4'>
                  J Code 2: ??{' '}
                  {
                    getBenefitCheckingHistoryData?.updatedBenefitChecking
                      ?.checking?.priorAuthorization?.jCode
                  }
                </div>
                <div className='col-md-4'>
                  J Code 3: ??{' '}
                  {
                    getBenefitCheckingHistoryData?.updatedBenefitChecking
                      ?.checking?.priorAuthorization?.jCode
                  }
                </div>
              </div>
              <div className='row mt-10'>
                <div className='col-md-4'>
                  Admin Code 1: ??{' '}
                  {
                    getBenefitCheckingHistoryData?.updatedBenefitChecking
                      ?.checking?.priorAuthorization?.adminCode1
                  }
                </div>
                <div className='col-md-4'>
                  Admin Code 2: ??{' '}
                  {
                    getBenefitCheckingHistoryData?.updatedBenefitChecking
                      ?.checking?.priorAuthorization?.adminCode2
                  }
                </div>
                <div className='col-md-4'>
                  Admin Code 3: ??{' '}
                  {
                    getBenefitCheckingHistoryData?.updatedBenefitChecking
                      ?.checking?.priorAuthorization?.adminCode3
                  }
                </div>
              </div>
            </div>
          </div>
          <div className='row mt-10 ml-1'>
            PA REQUIRED: N{' '}
            {
              getBenefitCheckingHistoryData?.updatedBenefitChecking?.checking
                ?.paRequired
            }{' '}
            <br />
            PREDETERMINATION NEEDED: True{' '}
            {
              getBenefitCheckingHistoryData?.updatedBenefitChecking?.checking
                ?.predeterminationNeeded
            }
          </div>

          <div className='row mt-10'>
            <div className='col-md-11 offset-1'>
              <div className='row mt-10'>
                How to submit PA: ?? ph/fax/port{' '}
                {
                  getBenefitCheckingHistoryData?.updatedBenefitChecking
                    ?.checking?.predetermination?.submitMethod
                }
              </div>
              <div className='row mt-10'>
                <div className='col-md-4'>
                  PA Phone No.: ??{' '}
                  {
                    getBenefitCheckingHistoryData?.updatedBenefitChecking
                      ?.checking?.predetermination?.paPhone
                  }
                </div>
                <div className='col-md-4'>
                  Portal Link: ??{' '}
                  {
                    getBenefitCheckingHistoryData?.updatedBenefitChecking
                      ?.checking?.predetermination?.jCode
                  }
                </div>
              </div>
              <div className='row mt-10'>
                <div className='col-md-4'>
                  J Code 1: ??{' '}
                  {
                    getBenefitCheckingHistoryData?.updatedBenefitChecking
                      ?.checking?.predetermination?.paSite
                  }
                </div>
                <div className='col-md-4'>J Code 2: ??</div>
                <div className='col-md-4'>J Code 3: ??</div>
              </div>
              <div className='row mt-10'>
                <div className='col-md-4'>
                  Admin Code 1: ??
                  {
                    getBenefitCheckingHistoryData?.updatedBenefitChecking
                      ?.checking?.predetermination?.adminCode1
                  }
                </div>
                <div className='col-md-4'>
                  Admin Code 2: ??
                  {
                    getBenefitCheckingHistoryData?.updatedBenefitChecking
                      ?.checking?.predetermination?.adminCode2
                  }
                </div>
                <div className='col-md-4'>
                  Admin Code 3: ??
                  {
                    getBenefitCheckingHistoryData?.updatedBenefitChecking
                      ?.checking?.predetermination?.adminCode3
                  }
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className='row mt-10'>
            <h4 className='col-md-4 ml-0'>CLAIMS </h4>
          </div>
          <div className='row mt-1 ml-1'>
            Address:
            <br />
            {
              getBenefitCheckingHistoryData?.updatedBenefitChecking?.checking
                ?.claimAddress?.streetname
            }{' '}
            <br />
            {
              getBenefitCheckingHistoryData?.updatedBenefitChecking?.checking
                ?.claimAddress?.city
            }{' '}
            &nbsp;{' '}
            {
              getBenefitCheckingHistoryData?.updatedBenefitChecking?.checking
                ?.claimAddress?.state
            }{' '}
            &nbsp;&nbsp;{' '}
            {
              getBenefitCheckingHistoryData?.updatedBenefitChecking?.checking
                ?.claimAddress?.zip
            }
          </div>
          <div className='row mt-10 ml-1'>
            Timely Filing: ??{' '}
            {
              getBenefitCheckingHistoryData?.updatedBenefitChecking?.checking
                ?.claimAddress?.timelyFilling
            }
          </div>
          <div className='row mt-10 ml-1'>Who is at risk for claims: ??</div>
          <hr />
          <div className='row mt-10'>
            <h4 className='col-md-4 ml-0'>WELCOME CALL </h4>
          </div>
          <div className='row mt-1'>
            <div className='col-md-11 offset-1'>
              <div className='row mt-10'>
                <div className='col-md-8'>
                  Attempts: <br />
                  ??
                  {
                    getBenefitCheckingHistoryData?.updatedBenefitChecking
                      ?.welcomeCalls?.agentId
                  }
                  <br />
                  {
                    getBenefitCheckingHistoryData?.updatedBenefitChecking
                      ?.welcomeCalls?.answered
                  }
                  <br />
                  {
                    getBenefitCheckingHistoryData?.updatedBenefitChecking
                      ?.welcomeCalls?.callTime
                  }
                </div>
              </div>
            </div>
          </div>
          <div className='row mt-12 ml-1'>
            WELCOME CALL COMPLETE: details: Date/Time:{' '}
            {
              getBenefitCheckingHistoryData?.updatedBenefitChecking
                ?.welcomeCalls?.callTime
            }
          </div>

          <div className='row mt-12 ml-1'>
            BI COMPLETE: details:{' '}
            {
              getBenefitCheckingHistoryData?.updatedBenefitChecking?.checking
                ?.isCompleted
            }
            <br />
          </div>
        </WindowDialog>
      )}
    </div>
  );
};

export default BenHistory;
