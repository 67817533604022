import { skippingSurveyReasonsInfusion } from './modelBlocks';
import { REASON_TO_SKIP_SURVEY } from '../surveyDictionary';

export default {
  pages: [{ ...skippingSurveyReasonsInfusion }],
  showPrevButton: false,
  completedHtml: 'Survey completed, saving data...',
  clearInvisibleValues: 'onHidden',
  questionsOnPageMode: 'singlePage',
  version: 1,
  surveyType: REASON_TO_SKIP_SURVEY,
  surveyTitle: 'Skip Survey'
};
