import React, { useState, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import cx from 'clsx';

// components
import { DrawerMenu, UserInfo } from './components';

// gql
import { connectToGraphqlAPI } from '@/provider';
import { releaseLocker } from '@/graphql/mutations';

// context
import { UserContext } from '@/context/UserContext';
import { PatientContext } from '@/context/PatientContext';
import { LogContext } from '@/context/LogContext';

// utils
import { pathToProject } from './Header.utils';

// styles
import styles from './Header.module.scss';

// assets
// import logo from '@/assets/images/ALERACARE_logo.png';
// import oldLogo from '@/assets/images/ALERACARE_logo_old.png';
// import svgLogo from '@/assets/images/aleracare.svg';
import svgLogo from '@/assets/images/alera-white.svg';

const Header = () => {
  const [menuExpanded, setMenuExpanded] = useState(false);

  const { user, agentId } = useContext(UserContext);
  const { selectedPatientInfo, selectedLocker } = useContext(PatientContext);
  const { logApiException } = useContext(LogContext);

  const history = useHistory();
  const location = useLocation();

  const { pathname } = location;
  const projectName = pathToProject(pathname);
  const isPatientPortal = pathname === '/patient-portal';

  const releaseWorkAPICall = async () => {
    try {
      if (selectedLocker && selectedLocker.length > 0) {
        await connectToGraphqlAPI({
          graphqlQuery: releaseLocker,
          variables: {
            agentId,
            lockerId: selectedLocker
          }
        });
      }
      history.push('/');
    } catch (err) {
      console.error('Header::releaseWorkAPICall releaseWork err:', err);

      logApiException(err, {
        view: 'Header',
        endpoint: 'releaseLocker',
        lockerId: selectedLocker,
        agentId
      });

      history.push('/');
    }
  };

  const onLogoClick = () => {
    setMenuExpanded(false);

    if (selectedPatientInfo?.patientId && isPatientPortal) {
      releaseWorkAPICall();
    } else {
      history.push('/');
    }
  };

  const onToggleDrawerMenu = () => {
    setMenuExpanded(!menuExpanded);
  };

  const render = {
    logo: () => (
      <img
        className={cx(
          styles.logo,
          isPatientPortal ? styles.portalLogo : styles.companyLogo
        )}
        // src={isPatientPortal ? oldLogo : logo}
        src={svgLogo}
        alt="logo"
        onClick={onLogoClick}
      />
    ),
    userInfo: (userData) => {
      if (isPatientPortal) {
        return <div className={styles.username}>{userData?.username}</div>;
      }
      return <UserInfo user={userData} />;
    }
  };

  if (isPatientPortal) return null;

  return (
    <>
      <div className={styles.root}>
        <div
          className={cx({
            [styles.appHeader]: true,
            [styles.appPortalHeader]: isPatientPortal
          })}
        >
          <div className={styles.logoContainer}>{render.logo()}</div>
          <div className={styles.projectContainer}>
            <span className={styles.projectName}>{projectName}</span>
          </div>
          {render.userInfo(user)}
          <div className={styles.hamburgerIcon} onClick={onToggleDrawerMenu} />
        </div>
        <DrawerMenu
          expanded={menuExpanded}
          onClose={() => setMenuExpanded(false)}
        />
      </div>
    </>
  );
};

export { Header };
