import React, { useState, useContext } from 'react';

import FormButton from '@/components/common-components/Form/FormButton';
import AwesomeLabel from '@/components/common-components/AwesomeLabel';

import { NotifContext } from '@/context/NotifContext';
import { SurveyContext } from '@/context/SurveyContext';

import { connectToGraphqlAPI } from '@/provider';
import { checkReferralForDiscontinuation } from '@/graphql/queries';
import { discontinueReferral } from '@/graphql/mutations';
import { getSurveyModel } from '../SurveyEngine/models/surveyModelMapper';
import {
  DISCONTINUATION_SURVEY,
  generateDiscReasonCode
} from '../SurveyEngine/surveyDictionary';

const DiscontinueOrder = ({
  patientId,
  referralId,
  agentId,
  refList,
  removeReferralCallback
}) => {
  const { showError, showSuccess } = useContext(NotifContext);
  const { setActiveSurvey } = useContext(SurveyContext);

  const [loading, setLoading] = useState(false);

  if (!patientId || !referralId || !agentId) {
    console.error('No patientId/referralId/agentId passed');
    return null;
  }

  const checkAvailabilityCall = async (variables) => {
    setLoading(true);

    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: checkReferralForDiscontinuation,
        variables
      });

      if (data?.data?.checkReferralForDiscontinuation?.statusCode === '200') {
        setLoading(false);

        // get the active survey model version for discontinuation
        const model = getSurveyModel(DISCONTINUATION_SURVEY);
        const version = model.version || 1;

        // display survey
        setActiveSurvey({
          model,
          onFinish: (surveyData) => {
            const jsonData = JSON.parse(surveyData);
            jsonData.version = version; // add version for future reference
            jsonData.reasonCode = generateDiscReasonCode(jsonData); // generate reason code for future handling
            const survey = JSON.stringify(jsonData);

            // pass survey results to the server now
            discontinueReferralCall({
              agentId,
              patientId,
              referralId,
              survey
            });
          }
        });
      } else {
        setLoading(false);

        const errMsg =
          data.data.checkReferralForDiscontinuation.message || 'Unknown Reason';
        showError(`Order discontinuation is not available: ${errMsg}`);
      }
    } catch (err) {
      console.error('DiscontinueOrder::checkAvailabilityCall err: ', err);

      setLoading(false);
      showError('Error: checkReferralForDiscontinuation');
    }
  };

  const discontinueReferralCall = async (variables) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: discontinueReferral,
        variables
      });

      if (data?.data?.discontinueReferral?.statusCode === '200') {
        showSuccess('Order has been discontinued successfully.');

        // hide survey from the screen
        setActiveSurvey(null);

        // remove order from UI
        removeReferralCallback(refList, referralId);
      } else {
        showError(data.data.discontinueReferral.message);
      }
    } catch (err) {
      console.error('DiscontinueOrder::discontinueReferralCall err: ', err);
      showError('Error: discontinueReferral');
    }
  };

  return (
    <FormButton
      onClick={() => checkAvailabilityCall({ patientId, referralId })}
      className="k-button red withPadding"
      label={<AwesomeLabel icon="xmark" label="Discontinue" />}
      loading={loading}
    />
  );
};

export default DiscontinueOrder;
