export const viewSettingsObj = [
  { value: 'Drug', text: 'Drug' },
  { value: 'Drug Category', text: 'Drug Category' },
  { value: 'Payer', text: 'Insurance Company' },
  { value: 'Patient Status', text: 'Patient Status' },
  { value: 'Physician', text: 'Physician' },
  { value: 'Priority', text: 'Priority' },
  { value: 'Program', text: 'Program' },
  { value: 'Sales', text: 'Sales Territory' },
  { value: 'State', text: 'State' }
];
export const programNames = [
  { value: 'viiv', text: 'ViiV' },
  { value: 'pdp', text: 'PDP' },
  { value: 'general', text: 'General' }
];

export const workStatusObj = [
  { value: 'OPENED', text: 'OPEN' },
  { value: 'IN_PROGRESS', text: 'IN PROCESS' },
  { value: 'PENDING', text: 'PENDING' },
  { value: 'OVERDUE', text: 'OVERDUE' },
  { value: 'REASSIGNED', text: 'REASSIGNED' },
  { value: 'SNOOZED', text: 'WAITING' }
];
export const groupQueues = [
  { value: 'ADMIN', text: 'Admin' },
  { value: 'BI', text: 'BI' },
  { value: 'BILLING', text: 'Billing' },
  { value: 'CLINICIAN', text: 'Clinician' },
  { value: 'INBOUND', text: 'Inbound' },
  { value: 'INTAKE', text: 'Intake' },
  { value: 'INVENTORY', text: 'Inventory' },
  { value: 'NURSING', text: 'Nursing' },
  { value: 'PA', text: 'PA' },
  { value: 'SALES', text: 'Sales' },
  { value: 'SCHEDULE', text: 'Schedule' },
  { value: 'SUPERVISOR', text: 'Supervisor' },
  { value: 'UNASSIGNED', text: 'Unassigned' }
];

export const taskType = [
  { value: 'ADMINISTRATION', text: 'Administration' },
  { value: 'BENEFIT_INVESTIGATION', text: 'BI' },
  { value: 'CLINICIAN_REVIEW', text: 'Clinical Review' },
  { value: 'ENROLLMENT', text: 'Enrollment' },
  { value: 'EXTEND_ADMINS', text: 'Extend Admins' },
  { value: 'EXTEND_CLINICIAN_REVIEW', text: 'Extend Clinical Review' },
  { value: 'EXTEND_REFERRAL', text: 'Extend Referral' },
  { value: 'EXTEND_SCHEDULE', text: 'Extend Schedule' },
  { value: 'EXTEND_PA', text: 'Extend PA' },
  { value: 'EXTEND_PA_VISITS', text: 'Extend PA Visits' },
  { value: 'EXTEND_INSURANCE', text: 'Extend Insurance' },
  { value: 'EXTEND_LAB_TESTS', text: 'Expiring Labs' },
  { value: 'FOLLOW_UP', text: 'Follow Up' },
  { value: 'INTAKE', text: 'Intake' },
  { value: 'INVENTORY', text: 'Inventory' },
  { value: 'NURSING', text: 'Nursing' },
  { value: 'OUTBOUND_CALL', text: 'Outbound Call' },
  { value: 'PA_DENIED', text: 'Submit Appeal' },
  { value: 'PAP', text: 'PAP' },
  { value: 'PATIENT_PROFILE', text: 'Patient Profile' },
  { value: 'PRIOR_AUTHORIZATION', text: 'PA Required' },
  { value: 'PROCESSING_FAX', text: 'Process Fax' },
  { value: 'RE_VERIFICATION', text: 'Reverification' },
  { value: 'SCHEDULE', text: 'Schedule' },
  { value: 'SEND_EMAIL', text: 'Send Email' },
  { value: 'SEND_FAX', text: 'Send Fax' }
];

export const coreList = [
  { value: 'CORE', text: 'Core' },
  { value: 'CORE_1', text: 'Core 1' },
  { value: 'CORE_2', text: 'Core 2' },
  { value: 'CORE_3', text: 'Core 3' },
  { value: 'CORE_6', text: 'Core 6' },
  { value: 'RARE', text: 'Rare' },
  { value: 'RARE_1', text: 'Rare 1' },
  { value: 'RARE_2', text: 'Rare 2' },
  { value: 'RARE_3', text: 'Rare 3' },
  { value: 'RARE_6', text: 'Rare 6' },
  { value: 'RARE_12', text: 'Rare 12' },
  { value: 'OTHER', text: 'Other' },
  { value: 'OTHER_1', text: 'Other 1' },
  { value: 'OTHER_2', text: 'Other 2' },
  { value: 'OTHER_3', text: 'Other 3' },
  { value: 'OTHER_6', text: 'Other 6' }
];

export const apointmentStatusMap = {
  CANCELLED_INFUSION: 'Infusion Cancelled',
  COMPLETED_INFUSION: 'Infusion Completed',
  CANCELLED: 'Appointment Cancelled',
  SCHEDULED: 'Appointment Scheduled',
  RESCHEDULED: 'Appointment Rescheduled',
  STARTED_INFUSION: 'Infusion Started',
  CHECKED_IN: 'Checked In',
  INVALID: 'Invalid',
  PENDING: 'Pending'
};

export const workItemStatusMap = {
  NEW_REFERRAL: 'New Referral',
  REFERRAL: 'Referral',
  OUTBOUND_CALL: 'Outbound Call',
  SEND_EMAIL: 'Send Email',
  SEND_FAX: 'Send Fax',
  ADMINISTRATION: 'Administration',
  SCHEDULE: 'Schedule',
  BENEFIT_INVESTIGATION: 'BI',
  ENROLLMENT: 'Enrollment',
  NURSING: 'Nursing',
  INTAKE: 'Intake',
  PAP: 'PAP',
  PATIENT_REACTIVATION_REVIEW: 'Patient Reactivation Review',
  PATIENT_PROFILE: 'Patient Profile',
  RE_VERIFICATION: 'Reverification',
  INVENTORY: 'Inventory',
  PRIOR_AUTHORIZATION: 'PA Required',
  PRIOR_AUTH_INFO: 'PA Info',
  PROCESSING_FAX: 'Process Fax',
  FOLLOW_UP: 'Follow Up',
  CLINICIAN_REVIEW: 'Clinical Review',
  EXTEND_REFERRAL: 'Extend Referral',
  EXTEND_LAB_TESTS: 'Expiring Labs',
  EXTEND_INSURANCE: 'Extend Insurance',
  EXTEND_PA: 'Extend PA',
  EXTEND_PA_VISITS: 'Extend PA Visits',
  EXTEND_ADMINS: 'Extend Admins',
  EXTEND_SCHEDULE: 'Extend Schedule',
  EXTEND_CLINICIAN_REVIEW: 'Extend Clinical Review',
  UPDATE_LAB_TESTS: 'Update Lab Tests',
  MISCELLANEOUS: 'Doc/Misc',
  REFILL: 'Refill',
  PLAN_OON: 'Plan Out Of Network',
  OUTBOUND_TEXT: 'Outbound',
  DISCONTINUATION_REVIEW: 'Discontinuation Review',
  CHART_NOTES: 'Chart Notes',
  PENDING_APPT: 'Pending Appointment',
  PRE_ORDER: 'Pre-Order',
  NETWORK_CHECKING: 'Network Checking',
  BI_NETWORK: 'Network Checking',
  LABS: 'Labs',
  FINANCIAL_ASSIST_CALL: 'Financial Assist Call',
  WELCOME_CALL: 'Welcome Call',
  PENDING_PA_APPROVAL: 'Pending PA Approval',
  PA_DENIED: 'Submit Appeal'
};

export const workItemCodeStatusMap = {
  PLAN_OON: 'Out of Network',
  CHART_NOTES: 'Chart Notes',
  PRIOR_AUTH_INFO: 'Prior Auth Info',
  EXTEND_REFERRAL: 'Expiring Referral',
  EXTEND_SCHEDULE: 'Extend Schedule',
  SCHEDULE: 'Schedule',
  REFILL: 'Refill Referral',
  EXTEND_PA: 'PA Expiring',
  EXTEND_PA_UNITS: 'PA Units Expiring',
  MISCELLANEOUS: 'Doc/Misc',
  LABS: 'Labs',
  FOLLOW_UP: 'Follow Up',
  NEW_REFERRAL: 'New Referral',
  EXTEND_PA_VISITS: 'PA Visits Expiring',
  PRIOR_AUTHORIZATION: 'PA',
  EXTEND_ADMINS: 'Admins Needed',
  OUTBOUND_CALL: 'Outbound Call',
  UPDATE_LAB_TESTS: 'Update Lab Tests',
  PENDING_APPT: 'Pending Appointment',
  EXTEND_LAB_TESTS: 'Expiring Labs',
  BENEFIT_INVESTIGATION: 'BI',
  PRE_ORDER: 'Pre-Order',
  DISCONTINUATION_REVIEW: 'Disc Review',
  NETWORK_CHECKING: 'Network Checking',
  PA_DENIED: 'Submit Appeal',
  RE_VERIFICATION: 'Reverification',
  CLINICIAN_REVIEW: 'Clinical Review',
  PATIENT_REACTIVATION_REVIEW: 'Patient Reactivation Review'
};
export const intakeWorkItemCodeStatusMap = {
  PAYOR_NOT_CONTRACTED: 'Payor Not in Network',
  NEW_REFERRAL: 'New Referral',
  CHART_NOTES: 'Chart Notes',
  REFILL: 'Refill Referral',
  PATIENT_PROFILE: 'Follow Up Tasks',
  PRIOR_AUTHORIZATION: 'PA Required',
  EXTEND_LAB_TESTS: 'Expiring Labs',
  EXTEND_PA: 'Follow Up Tasks',
  EXTEND_ADMINS: 'Follow Up Tasks',
  FOLLOW_UP: 'Follow Up',
  EXTEND_REFERRAL: 'Expiring Referral',
  OUTBOUND_PATIENT_CALL: 'Patient Calls',
  OUTBOUND_DOCTOR_CALL: 'MD Calls',
  OUTBOUND_INSURANCE_CALL: 'Insurance Calls',
  PENDING_APPT: 'Pending Appointment',
  LABS: 'Labs',
  RE_VERIFICATION: 'Reverification'
};

// TODO: once Q work done - check if we still need this
// it might be redundant and we just can re-use `taskStatusesMapped` from enum
export const workStatusMap = {
  CREATED: 'OPEN',
  OPENED: 'OPEN',
  IN_PROGRESS: 'IN PROCESS',
  PENDING: 'PENDING',
  COMPLETED: 'CLOSED',
  ARCHIVED: 'CANCEL',
  CANCELED: 'CANCEL',
  OVERDUE: 'OVERDUE',
  REASSIGNED: 'REASSIGNED'
};
export const dashboardWorkStatusMap = {
  CREATED: 'CREATED',
  OPENED: 'OPEN',
  IN_PROGRESS: 'IN PROCESS',
  PENDING: 'PENDING',
  COMPLETED: 'CLOSED',
  ARCHIVED: 'CANCEL',
  CANCELED: 'CANCEL',
  OVERDUE: 'OVERDUE',
  REASSIGNED: 'REASSIGNED'
};

export const workItemPriorityMap = {
  1: '01-Standard',
  2: '02-Standard',
  3: '03-Standard',
  4: '04-High',
  5: '05-High',
  6: '06-High',
  7: '07-High',
  8: '08-Critical',
  9: '09-Critical',
  10: '10-Critical'
};
