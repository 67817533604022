import React from 'react';
import FishbowlAlert from './FishbowlAlert';

const FishbowlPreviewTable = ({ alertType, tableData }) => {
  if (!tableData || tableData.length === 0) return null;

  return (
    <>
      <FishbowlAlert alertType={alertType} itemsCount={tableData.length} />
      <table width='100%' cellPadding='7' border='0' className='mt-1'>
        <thead>
          <tr>
            {[
              'NDC Code',
              'Product Name',
              'Vendor',
              'QTY',
              'Exp Date',
              'Serial Number'
            ].map((column, index) => {
              return <th key={index}>{column}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {tableData.map((value, index) => {
            return (
              <tr key={index}>
                <td>{value.ndcNumber}</td>
                <td>{value.productNameDetailed}</td>
                <td>{value.vendor}</td>
                <td>{value.quantity}</td>
                <td>{value.expirationDate}</td>
                <td>{value.serial}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default FishbowlPreviewTable;
