/* eslint-disable react/no-danger */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import cx from 'clsx';
import { Badge, BadgeContainer } from '@progress/kendo-react-indicators';
import * as moment from 'moment';

// components
import { MessageDialog } from '@/components/common-components/MessageDialog';
import BulletinBoard from '@/components/Patient/BulletinBoard';
import ViivBanner from '@/components/Patient/PatientViivBanner';
import PdpBanner from '@/components/Patient/PatientPdpBanner';
import { UserInfo } from '../Header/components';
import GeneralFollowUp from '../Queue/GeneralFollowUp/GeneralFollowUp';
import FollowUp from '../Followup/FollowUp';

// gql
import { connectToGraphqlAPI } from '@/provider';
import { releaseLocker } from '@/graphql/mutations';
import {
  getCaseByPatientId,
  getPatientBucket,
  getScheduleEventsByPatient,
  listLocationAICs,
  getPatientReferralOrders
} from '@/graphql/queries';

// common
import { formatDateToAWS } from '@/common/DateHelper';

// context
import {
  StatusContext,
  UserContext,
  PatientContext,
  WorkItemContext,
  LocationContext
} from '@/context';

// partials
import { getTimeZoneForLocation } from '../Scheduling/getTimezoneForLocation';

// assets
// import logo from '@/assets/images/ALERACARE_logo.png';
// import oldLogo from '@/assets/images/ALERACARE_logo_old.png';
import svgLogo from '@/assets/images/alera-white.svg';

// styles
import styles from '../../styles/StatusBar.module.scss';

const StatusBar = (props) => {
  const { statusUpdate } = useContext(StatusContext);
  const {
    selectedLocker,
    setSelectedPatientInfo,
    getSystemScheduledItemsByPatientCall,
    getPatientAppointmentData
  } = useContext(PatientContext);
  const { workItemData, overDueWorkItemsData } = useContext(WorkItemContext);
  const { getTimeByLocation, getDateByLocation } = useContext(LocationContext);

  const { selectedPatientInfo } = props;
  const [selectedPatientInfoAll, setSelectedPatientInfoAll] = useState({});

  const [dialogOption, setDialogOption] = useState({});

  // show/hide
  const [statusBar, setStatusBar] = useState({});
  const [showStatusBar, setShowStatusBar] = useState(false);

  const [showStatusBarPatient, setShowStatusBarPatient] = useState(false);
  const [expandStatusBarPatient, setExpandStatusBarPatient] = useState(false);

  const [showStatusBarHCP, setShowStatusBarHCP] = useState(false);
  const [expandStatusBarHCP, setExpandStatusBarHCP] = useState(false);

  const [showStatusBarReferral, setShowStatusBarReferral] = useState(false);
  const [expandStatusBarReferral, setExpandStatusBarReferral] = useState(false);

  const [showStatusBarClinical, setShowStatusBarClinical] = useState(false);
  const [expandStatusBarClinical, setExpandStatusBarClinical] = useState(false);

  const [showStatusBarBI, setShowStatusBarBI] = useState(false);
  const [expandStatusBarBI, setExpandStatusBarBI] = useState(false);

  const [showStatusBarPA, setShowStatusBarPA] = useState(false);
  const [expandStatusBarPA, setExpandStatusBarPA] = useState(false);

  // portal header
  const [menuExpanded, setMenuExpanded] = useState(false);

  const { user, agent } = useContext(UserContext);

  const history = useHistory();
  const { pathname } = history.location;

  const isPatientPortal = true;

  // rules + color/status
  const [statusPatientProfileCompleted, setStatusPatientProfileCompleted] =
    useState('error');
  const [
    messagePatientInsuranceCompleted,
    setMessagePatientInsuranceCompleted
  ] = useState('');

  const [messagePatientInsured, setMessagePatientInsured] = useState('');

  const [statusHCPCompleted, setStatusHCPCompleted] = useState('error');
  const [messageHCPCompleted, setMessageHCPCompleted] = useState('');

  const [statusReferralCompleted, setStatusReferralCompleted] =
    useState('error');
  const [detailsReferral, setDetailsReferral] = useState([]);

  const [statusClinicalCompleted, setStatusClinicalCompleted] =
    useState('error');
  const [detailsClinical, setDetailsClinical] = useState('');

  const [statusBICompleted, setStatusBICompleted] = useState('error');
  const [detailsBI, setDetailsBI] = useState('');

  const [statusPACompleted, setStatusPACompleted] = useState('error');
  const [detailsPA, setDetailsPA] = useState('');

  const [showStatusBarWorkItems, setShowStatusBarWorkItems] =
    useState('success'); // always show green "+"
  const [expandStatusBarWorkItems, setExpandStatusBarWorkItems] =
    useState(false);

  const [showStatusBarScheduledAppts, setShowStatusBarScheduledAppts] =
    useState('success'); // always show green "+"
  const [expandStatusBarScheduledAppts, setExpandStatusBarScheduledAppts] =
    useState(false);

  const [scheduledItems, setScheduledItems] = useState([]);
  const [showNotes, setShowNotes] = React.useState(false);

  const toggleNotesDialog = () => {
    setShowNotes(!showNotes);
  };

  const checkForOverDue = (overDueItemsLength, workItemsLength) => {
    if (overDueItemsLength > 0) {
      setShowStatusBarWorkItems('error');
    } else if (workItemsLength > 0) {
      setShowStatusBarWorkItems('warning');
    } else {
      setShowStatusBarWorkItems('success');
    }
  };

  useEffect(() => {
    if (overDueWorkItemsData && workItemData) {
      checkForOverDue(overDueWorkItemsData.length, workItemData.length);
    }
  }, [overDueWorkItemsData, workItemData]);

  useEffect(() => {
    if (selectedPatientInfo?.patientId) {
      getSystemScheduledItemsByPatientCall(selectedPatientInfo.patientId);
    }
  }, []);

  const CheckScheduledItemsInit = (expanding) => {
    if (expanding === true && scheduledItems.length === 0) {
      InitScheduledItemsListsCall();
    }
  };
  const GetTimeZoneForLocationId = (Locations, locationId) => {
    let sRetVal = '';

    if (Locations.length > 0) {
      sRetVal = Locations[0].zip; // initialize to first value in list
      const loc = Locations.filter((itm) => itm.locationId === locationId); // just take first since should only be one... otherwise all zips the same and doesnt matter which one
      if (loc && loc.length > 0) {
        sRetVal = loc[0].zip; // just take first since should only be one... otherwise all zips the same and doesnt matter which one
      }
    }
    return getTimeZoneForLocation(sRetVal);
  };
  const InitScheduledItemsListsCall = async () => {
    try {
      const sRangeStartDate = formatDateToAWS(); // go from today
      // const sRangeStartDate = '2021-11-01'; // temp value
      let Locations = [];
      let totalMaxCounts = 0;
      let totalScheduleCounts = 0;
      let totalCompletedCounts = 0;
      // get locations
      const retDataLocations = await connectToGraphqlAPI({
        graphqlQuery: listLocationAICs
      });

      if (retDataLocations.data.listLocationAICs.items?.length > 0) {
        Locations = retDataLocations.data.listLocationAICs.items
          .map((itm) => ({
            locationId: itm.id,
            zip: itm.address.zip
          }))
          .filter((item) => item.status === 'ACTIVE');
      }

      // here we want to query all the referrals from the getPatientBucket so we can count maxtreatments in
      /// ////////////  get MaxTreatments count
      const retDataRefs = await connectToGraphqlAPI({
        graphqlQuery: getPatientReferralOrders,
        variables: {
          patientId: selectedPatientInfo.patientId
        }
      });
      if (
        retDataRefs?.data?.getPatientBucket?.referral?.drugReferrals?.length
      ) {
        // go through referrals and sum administrations[].maxOfTreatments
        let maxcount = 0;
        // let items = [];
        retDataRefs.data.getPatientBucket.referral.drugReferrals.forEach(
          (itm) => {
            maxcount = 0;
            if (itm.referralOrder.administrations) {
              // make sure administrations structure is not null
              itm.referralOrder.administrations.forEach((admn) => {
                maxcount += admn.maxOfTreatments;
              });
            }
            // items.push({
            //   referralId: itm.referralId,
            //   totalMaxTreatment: maxcount
            //   });
            totalMaxCounts += maxcount; // cumulate into total
          }
        );
      }

      /// /////////////

      const retData = await getPatientAppointmentData(
        selectedPatientInfo.patientId
      );
      if (retData) {
        const eventDateFiltered = retData.filter(
          ({ startTime }) => startTime >= sRangeStartDate
        );

        totalScheduleCounts = 0;
        totalCompletedCounts = 0;

        eventDateFiltered.forEach(({ status }) => {
          if (['CANCELLED', 'CANCELLED_INFUSION'].indexOf(status) < 0) {
            totalScheduleCounts += 1;
            if (status === 'COMPLETED_INFUSION') {
              totalCompletedCounts += 1;
            }
          }
        });

        const displayScheduleItems = eventDateFiltered
          .filter(
            ({ status }) =>
              ['CANCELLED', 'CANCELLED_INFUSION', 'COMPLETED_INFUSION'].indexOf(
                status
              ) < 0
          )
          .map(({ id, startTime, endTime, locationId }) => ({
            id,
            startTime: getTimeByLocation(locationId, startTime),
            endTime: getTimeByLocation(locationId, endTime),
            locationId,
            displayDate: getDateByLocation(locationId, startTime),
            timeZone: GetTimeZoneForLocationId(Locations, locationId)
          }));
        setScheduledItems(displayScheduleItems);

        if (totalScheduleCounts === 0) {
          // then no appts scheduled
          setShowStatusBarScheduledAppts('error');
        } else if (totalScheduleCounts === totalMaxCounts) {
          // then have scheduled or completed all appts
          setShowStatusBarScheduledAppts('success');
        } else if (totalCompletedCounts === totalScheduleCounts) {
          // then have more appts to schedule
          // if the only scheduled appts are completed, then RED to indicate schedule more appt
          setShowStatusBarScheduledAppts('error');
        } else {
          // has at least one appt that is SCHEDULED and not completed
          setShowStatusBarScheduledAppts('warning');
        }
      }
    } catch (err) {
      console.log('StatusBar::InitScheduledItemsListsCall err:', err);
      setDialogOption({
        title: 'Get Scheduled Items ',
        message: 'Error: InitScheduledItemsListsCall', // err.errors[0].message, //'Error',
        showDialog: true
      });
    }
  };

  const WorkItem = ({ work, description }) => {
    return (
      <div style={{ marginLeft: '0.0rem', marginTop: '0.4rem' }}>
        {`- ${work}: ${description}`}
      </div>
    );
  };

  const ScheduledItem = ({ startTime, displayDate }) => {
    return (
      <div
        className={styles.categoryItem}
        style={{ marginLeft: '-1.0rem', marginTop: '0.4rem' }}
      >
        {`* ${displayDate} @ ${startTime}`}
      </div>
    );
  };

  // MAIN INITIATOR
  useEffect(() => {
    getPatientBucketAllData();
    // Removed getStatusBarData call because it is called through refreshStatusBar on initialization.
    // Cleanup function added to fix console error. A return function in a useEffect hook fires when the component dismounts.
    return () => {
      setStatusBar({});
    };
  }, []);

  // LISTENERS
  useEffect(() => {
    refreshStatusBar();
  }, [statusUpdate]);

  useEffect(() => {
    setShowStatusBar(true);
    setShowStatusBarPatient(true);
    setShowStatusBarHCP(true);
    setShowStatusBarReferral(true);
    setShowStatusBarClinical(true);
    setShowStatusBarBI(true);
    setShowStatusBarPA(true);
  }, [statusBar]);

  const refreshStatusBar = () => {
    setShowStatusBar(false);
    getPatientBucketAllData();
    getStatusBarData();
  };

  const getStatusBarData = async () => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getCaseByPatientId,
        variables: { patientId: selectedPatientInfo.patientId }
      });

      if (data && data.data && data.data.getCaseByPatientId) {
        setStatusBar(data.data.getCaseByPatientId);
      }
    } catch (err) {
      console.log('StatusBar::getStatusBarData err:', err);
      setDialogOption({
        title: 'Status Bar',
        message: 'Error: getStatusBarData',
        showDialog: true
      });
    }
  };

  const getPatientBucketAllData = async () => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getPatientBucket,
        variables: { patientId: selectedPatientInfo.patientId }
      });

      if (data && data.data && data.data.getPatientBucket) {
        setSelectedPatientInfoAll(data.data.getPatientBucket);
        setSelectedPatientInfo(data.data.getPatientBucket);
      }
    } catch (err) {
      console.log('StatusBar::getPatientBucketAllData err:', err);
      setDialogOption({
        title: 'Patient Info',
        message: 'Error: getPatientBucketAllData', // err.errors[0].message, //'Error',
        showDialog: true
      });
    }
  };

  useEffect(() => {
    InitScheduledItemsListsCall();
  }, [selectedPatientInfoAll]);

  useEffect(() => {
    if (workItemData) {
      runBusinessRules(workItemData);
    }
  }, [workItemData]);

  const runBusinessRules = async (lstWorkItems) => {
    /** ************************************
     * ************************************
     * INTAKE
     * ************************************
     ************************************* */

    let sIntakeStatus = 'error';
    const wiINTAKEs = lstWorkItems.filter(
      (itm) => itm.work === 'INTAKE' && itm.workStatus === 'OVERDUE'
    );
    if (wiINTAKEs.length === 0) {
      // no overdue INTAKE items
      // we show warning ONLY IF patientProfile completed, else show red as if not started
      sIntakeStatus = 'warning';
    }
    // let sBIPANotStartedOrOverDue = false;
    // let wiBIs = lstWorkItems.filter(
    //   itm => itm.work === "BENEFIT_INVESTIGATION"
    // );
    // let wiBIOverdue = wiBIs.filter(itm => itm.workStatus === "OVERDUE");
    // if (wiBIs.length === 0 || wiBIOverdue.length > 0) {
    //   // not started of overdue INTAKE items
    //   sBIPANotStartedOrOverDue = true;
    // }
    let sBIOverDue = false;
    const wiBIs = lstWorkItems.filter(
      (itm) => itm.work === 'BENEFIT_INVESTIGATION'
    );
    const wiBIOverdue = wiBIs.filter((itm) => itm.workStatus === 'OVERDUE');
    if (wiBIOverdue.length > 0) {
      // overdue BI items
      sBIOverDue = true;
    }

    // Check If Insured
    if (selectedPatientInfoAll.patientProfile) {
      if (
        !selectedPatientInfoAll.patientProfile?.insuranceInfo?.isPatientInsured
      ) {
        setStatusPatientProfileCompleted(sIntakeStatus);
        setMessagePatientInsured('Not Insured');
      } else {
        setStatusPatientProfileCompleted('success');
        setMessagePatientInsuranceCompleted('Insurance Complete');
        setMessagePatientInsured('Insurance Set');
      }
    }

    /** ************************************
     * ************************************
     * HCP / PRESCRIBER
     * ************************************
     ************************************* */

    // Check If HCP Entered
    const hcpItems =
      selectedPatientInfoAll.hcpProfile?.items?.filter(
        (item) => item.prescriber
      ) || [];

    if (!hcpItems.length) {
      setStatusHCPCompleted(sIntakeStatus);
      setMessageHCPCompleted('No HCP or Prescriber not started');
    } else {
      setStatusHCPCompleted('success');
      const fullName = hcpItems
        .map(
          (item) =>
            `${item.prescriber.prescriberFirstName} ${item.prescriber.prescriberLastName}`
        )
        .join('<br />');
      setMessageHCPCompleted(fullName);
    }

    /** ************************************
     * ************************************
     * REFERRAL
     * ************************************
     ************************************* */

    // Check If Referral Complete
    // setStatusReferralCompleted(sIntakeStatus);
    if (selectedPatientInfoAll?.referral?.drugReferrals) {
      let allReferralsCompleted = true;
      let myReferrals = [];
      for (
        let i = 0;
        i < selectedPatientInfoAll.referral.drugReferrals.length;
        i++
      ) {
        if (!selectedPatientInfoAll.referral.drugReferrals[i].isCompleted) {
          allReferralsCompleted = false;
        }

        myReferrals = [
          ...myReferrals,
          <>
            <hr className={styles.hr} />
            <div
              className={styles.categoryItem}
              style={{ marginLeft: '1.6rem' }}
            >
              {
                selectedPatientInfoAll.referral.drugReferrals[i].referralOrder
                  .orderName
              }
            </div>
            <div
              className={styles.categoryItem}
              style={{ marginLeft: '1.6rem' }}
            >
              Status:{' '}
              {selectedPatientInfoAll.referral.drugReferrals[i].isCompleted
                ? 'Approved'
                : 'Needs Approval'}
            </div>
          </>
        ];
      }

      setDetailsReferral(myReferrals);

      if (allReferralsCompleted) {
        setStatusReferralCompleted('success');
      } else {
        setStatusReferralCompleted(sIntakeStatus);
      }
    } else {
      setStatusReferralCompleted(sIntakeStatus);
    }

    /** ************************************
     * ************************************
     * BENEFITS INVESTIGATION / BI
     * ************************************
     ************************************* */

    let countPARequired = 0;
    if (selectedPatientInfoAll?.referral?.drugReferrals) {
      let allBIsCompleted = true;
      let isBIsStarted = false;
      let myBIs = [];
      // let sVerifiedDate = "";

      // 10.25.2021 wat this condition nested INSIDE the original if so that the EMPTY structures process correctly and show GREEN on the screen when EMPTY
      // AIC-1338
      if (
        selectedPatientInfoAll?.benefitInvestigation?.benefitCheckings?.length
      ) {
        isBIsStarted = true;
        for (
          let i = 0;
          i < selectedPatientInfoAll.referral.drugReferrals.length;
          i++
        ) {
          for (
            let j = 0;
            j <
            selectedPatientInfoAll.benefitInvestigation.benefitCheckings.length;
            j++
          ) {
            // make sure this benefits checking matches the ReferralID for the i-index iteration.
            // because there are benefitcheckings in the list that belong to archived referrals and should NOT be counted
            if (
              selectedPatientInfoAll.referral.drugReferrals[i].referralId ===
              selectedPatientInfoAll.benefitInvestigation.benefitCheckings[j]
                .referralId
            ) {
              if (
                // note the BI should always have at least 1 "checking" (primary)
                selectedPatientInfoAll.benefitInvestigation.benefitCheckings[j]
                  .checkings.length
              ) {
                for (
                  let k = 0;
                  k <
                  selectedPatientInfoAll.benefitInvestigation.benefitCheckings[
                    j
                  ].checkings.length;
                  k++
                ) {
                  if (
                    !selectedPatientInfoAll.benefitInvestigation
                      .benefitCheckings[j].checkings[k].isCompleted
                  ) {
                    allBIsCompleted = false;
                  }
                  if (
                    selectedPatientInfoAll.benefitInvestigation
                      .benefitCheckings[j].checkings[k].paRequired
                  ) {
                    countPARequired += 1;
                  }
                  if (
                    selectedPatientInfoAll.benefitInvestigation
                      .benefitCheckings[j].referralId ===
                    selectedPatientInfoAll.referral.drugReferrals[i].referralId
                  ) {
                    myBIs = [
                      ...myBIs,
                      <>
                        <hr className={styles.hr} />
                        <div
                          className={styles.categoryItem}
                          style={{ marginLeft: '1.6rem' }}
                        >
                          {`${selectedPatientInfoAll.referral.drugReferrals[i].referralOrder.orderName}(${selectedPatientInfoAll.benefitInvestigation.benefitCheckings[j].checkings[k].insuranceKey})`}
                        </div>
                        <div
                          className={styles.categoryItem}
                          style={{ marginLeft: '1.6rem' }}
                        >
                          Status: &nbsp;
                          {selectedPatientInfoAll.benefitInvestigation
                            .benefitCheckings[j].checkings[k].isCompleted
                            ? 'Completed'
                            : selectedPatientInfoAll.referral.drugReferrals[i]
                                .clinical?.orderDenied
                            ? 'Denied'
                            : 'Not Completed'}
                        </div>
                        <div
                          className={styles.categoryItem}
                          style={{ marginLeft: '1.6rem' }}
                        >
                          Last Verified: &nbsp;
                          {
                            selectedPatientInfoAll.benefitInvestigation
                              .benefitCheckings[j].checkings[k].verifiedDate
                          }
                        </div>
                      </>
                    ];
                  }
                }
              }
            }
          }
        }
      }

      setDetailsBI(myBIs);

      if (allBIsCompleted) {
        if (isBIsStarted) {
          setStatusBICompleted('success');
        } else {
          setStatusBICompleted('error');
        }
      } else if (isBIsStarted && sBIOverDue === false) {
        setStatusBICompleted('warning');
      } else {
        setStatusBICompleted('error'); // MAY be overdue
      }
    }

    /** ************************************
     * ************************************
     * CLINICAL
     * ************************************
     ************************************* */

    if (selectedPatientInfoAll?.referral?.drugReferrals) {
      let allClinicalsCompleted = true;
      let allReferralsCompleted = true;
      let myClinicals = [];
      for (
        let i = 0;
        i < selectedPatientInfoAll.referral.drugReferrals.length;
        i++
      ) {
        // if (selectedPatientInfoAll.referral.drugReferrals[i].clinical) {
        if (
          !selectedPatientInfoAll.referral.drugReferrals[i].clinical ||
          !selectedPatientInfoAll.referral.drugReferrals[i].clinical
            .orderApproved
          // new status of pending so just check if approved == false
          // selectedPatientInfoAll.referral.drugReferrals[i].clinical.orderDenied
        ) {
          allClinicalsCompleted = false;
        }
        // 01.06.22 track whether all parent referrals are complete...
        if (!selectedPatientInfoAll.referral.drugReferrals[i].isCompleted) {
          allReferralsCompleted = false;
        }

        myClinicals = [
          ...myClinicals,
          <>
            <hr className={styles.hr} />
            <div
              className={styles.categoryItem}
              style={{ marginLeft: '1.6rem' }}
            >
              {
                selectedPatientInfoAll.referral.drugReferrals[i].referralOrder
                  .orderName
              }
            </div>
            <div
              className={styles.categoryItem}
              style={{ marginLeft: '1.6rem' }}
            >
              Status: &nbsp;
              {selectedPatientInfoAll.referral.drugReferrals[i].clinical
                ?.orderApproved
                ? 'Approved'
                : selectedPatientInfoAll.referral.drugReferrals[i].clinical
                    ?.orderDenied
                ? 'Denied'
                : 'Needs Approval'}
            </div>
          </>
        ];
      }

      setDetailsClinical(myClinicals);

      if (allClinicalsCompleted) {
        setStatusClinicalCompleted('success');
      } else if (allReferralsCompleted === true) {
        // 01.06.22 if REFERRALS are Complete, elevate color to RED
        setStatusClinicalCompleted('error');
      } else {
        setStatusClinicalCompleted('warning');
      }
    }

    /** ************************************
     * ************************************
     * PRIOR AUTH / PA
     * ************************************
     ************************************* */

    if (selectedPatientInfoAll?.referral?.drugReferrals) {
      let myPAs = [];
      let sStatusTag = 'success'; // start with GREEN

      selectedPatientInfoAll.referral.drugReferrals.forEach((drugReferral) => {
        const benefitsEnvs =
          selectedPatientInfoAll.benefitInvestigation.benefitCheckings.filter(
            ({ referralId }) => referralId === drugReferral.referralId
          );

        benefitsEnvs.forEach((benefitsEnv) => {
          benefitsEnv.checkings.forEach((beChecking) => {
            if (beChecking.paRequired) {
              const priorAuths =
                selectedPatientInfoAll.priorAuthorization.priorAuthCheckings?.filter(
                  ({ referralId, insuranceKey }) =>
                    referralId === benefitsEnv.referralId &&
                    insuranceKey === beChecking.insuranceKey
                );

              if (priorAuths && priorAuths.length > 0) {
                if (!priorAuths[0]?.isCompleted) {
                  if (sBIOverDue) {
                    sStatusTag = 'error';
                  } else if (sStatusTag === 'success') {
                    sStatusTag = 'warning';
                  }
                }
                myPAs = [
                  ...myPAs,
                  <>
                    <hr className={styles.hr} />
                    <div
                      className={styles.categoryItem}
                      style={{ marginLeft: '1.6rem' }}
                    >
                      {`${drugReferral.referralOrder.orderName} (${priorAuths[0]?.insuranceKey})`}
                    </div>
                    <div
                      className={styles.categoryItem}
                      style={{ marginLeft: '1.6rem' }}
                    >
                      Status: &nbsp;
                      {priorAuths[0]?.isCompleted
                        ? 'Completed'
                        : drugReferral.clinical?.orderDenied
                        ? 'Denied'
                        : 'Not Completed'}
                    </div>
                  </>
                ];
              } else {
                myPAs = [
                  ...myPAs,
                  <>
                    <hr className={styles.hr} />
                    <div
                      className={styles.categoryItem}
                      style={{ marginLeft: '1.6rem' }}
                    >
                      {drugReferral.referralOrder.orderName}
                    </div>
                    <div
                      className={styles.categoryItem}
                      style={{ marginLeft: '1.6rem' }}
                    >
                      Status: &nbsp; Not Started
                    </div>
                  </>
                ];
                /// set Button Status RED
                sStatusTag = 'error'; // force to RED
              }
            }
          });
        });
      });

      setDetailsPA(myPAs);
      setStatusPACompleted(sStatusTag);
    }
  };

  const releaseWorkAPICall = async () => {
    try {
      if (selectedLocker && selectedLocker.length > 0) {
        await connectToGraphqlAPI({
          graphqlQuery: releaseLocker,
          variables: {
            agentId: agent.agentId,
            lockerId: selectedLocker
          }
        });
      }
      history.push('/');
    } catch (err) {
      console.log('Header::releaseWorkAPICall releaseWork err:', err);
      history.push('/');
    }
  };

  const onLogoClick = () => {
    setMenuExpanded(false);

    if (selectedPatientInfo?.patientId && isPatientPortal) {
      releaseWorkAPICall();
    } else {
      history.push('/');
    }
  };

  const onToggleDrawerMenu = () => {
    setMenuExpanded(!menuExpanded);
  };

  const render = {
    logo: () => (
      <img
        className={cx(isPatientPortal ? styles.portalLogo : styles.companyLogo)}
        src={svgLogo}
        alt='logo'
        onClick={onLogoClick}
      />
    ),
    userInfo: (userData) => {
      if (isPatientPortal) {
        return <div className={styles.username}>{userData?.username}</div>;
      }
      return <UserInfo user={userData} />;
    }
  };

  return (
    <>
      <div>
        {dialogOption && dialogOption.showDialog && (
          <MessageDialog dialogOption={dialogOption} />
        )}

        {showStatusBar && (
          <div className={styles.sidebar_content}>
            <div className={styles.logoContainer}>{render.logo()}</div>
            <div className={styles.notesContainer}>
              <span onClick={toggleNotesDialog} className='k-link ml-15'>
                <span className='k-icon k-iconWhite k-i-edit mr-2 mb-1' />
                Notes
              </span>
            </div>
            <ViivBanner />
            <PdpBanner />

            {/* ------------------------------------------------------------------------- */}

            {/* Patient Profile */}
            <div>
              {showStatusBarPatient && (
                <>
                  <div className='intake mt-12'>
                    <div className={styles.category}>
                      <BadgeContainer>
                        <Badge
                          themeColor={statusPatientProfileCompleted}
                          size='small'
                          align={{ horizontal: 'start' }}
                          cutoutBorder={false}
                        >
                          <span
                            className={cx(
                              'k-icon',
                              { 'k-i-minus': expandStatusBarPatient },
                              { 'k-i-plus': !expandStatusBarPatient }
                            )}
                            onClick={() =>
                              setExpandStatusBarPatient(!expandStatusBarPatient)
                            }
                          />
                        </Badge>
                        <span
                          className={styles.category}
                          style={{ marginLeft: '1.6rem' }}
                        >
                          PATIENT PROFILE
                        </span>
                      </BadgeContainer>
                      {expandStatusBarPatient && (
                        <>
                          <div
                            className={styles.categoryItem}
                            style={{ marginLeft: '1.6rem' }}
                          >
                            {/* Display Not insured or Insurance info Status */}
                            {messagePatientInsuranceCompleted}
                          </div>
                          <div
                            className={styles.categoryItem}
                            style={{ marginLeft: '1.6rem' }}
                          >
                            {/* Display Not insured or Insurance info Status */}
                            {messagePatientInsured}
                          </div>
                          <div
                            className={styles.categoryItem}
                            style={{ marginLeft: '1.6rem' }}
                          >
                            Exp Date:{' '}
                            {selectedPatientInfo.patientProfile?.insuranceInfo
                              ?.primary?.insuranceExpireDate == null
                              ? ''
                              : selectedPatientInfo.patientProfile
                                  ?.insuranceInfo?.primary?.insuranceExpireDate}
                          </div>
                        </>
                      )}
                    </div>

                    <hr className={styles.hr} />
                  </div>
                </>
              )}

              {/* ------------------------------------------------------------------------- */}

              {/* HCP Section */}

              {showStatusBarHCP && (
                <>
                  <div className='HCP'>
                    <div className={styles.category}>
                      <BadgeContainer>
                        <Badge
                          themeColor={statusHCPCompleted}
                          size='small'
                          align={{ horizontal: 'start' }}
                          cutoutBorder={false}
                        >
                          <span
                            className={cx(
                              'k-icon',
                              { 'k-i-minus': expandStatusBarHCP },
                              { 'k-i-plus': !expandStatusBarHCP }
                            )}
                            onClick={() =>
                              setExpandStatusBarHCP(!expandStatusBarHCP)
                            }
                          />
                        </Badge>
                        <span
                          className={styles.category}
                          style={{ marginLeft: '1.6rem' }}
                        >
                          PRESCRIBER
                        </span>
                      </BadgeContainer>
                      {expandStatusBarHCP && (
                        <>
                          <div
                            className={styles.categoryItem}
                            style={{
                              marginLeft: '1.6rem',
                              textTransform: 'capitalize'
                            }}
                            dangerouslySetInnerHTML={{
                              __html: messageHCPCompleted
                            }}
                          />
                        </>
                      )}
                    </div>
                    <hr className={styles.hr} />
                  </div>
                </>
              )}

              {/* REFERRAL Section */}

              {showStatusBarReferral && (
                <>
                  <div className='REFERRAL'>
                    <div className={styles.category}>
                      <BadgeContainer>
                        <Badge
                          themeColor={statusReferralCompleted}
                          size='small'
                          align={{ horizontal: 'start' }}
                          cutoutBorder={false}
                        >
                          <span
                            className={cx(
                              'k-icon',
                              { 'k-i-minus': expandStatusBarReferral },
                              { 'k-i-plus': !expandStatusBarReferral }
                            )}
                            onClick={() =>
                              setExpandStatusBarReferral(
                                !expandStatusBarReferral
                              )
                            }
                          />
                        </Badge>
                        <span
                          className={styles.category}
                          style={{ marginLeft: '1.6rem' }}
                        >
                          REFERRAL
                        </span>
                      </BadgeContainer>
                      {expandStatusBarReferral && <>{detailsReferral}</>}
                    </div>
                    <hr className={styles.hr} />
                  </div>
                </>
              )}

              {/* ------------------------------------------------------------------------- */}

              {/* CLINICAL Section */}

              {showStatusBarClinical && (
                <>
                  <div className='CLINICAL'>
                    <div className={styles.category}>
                      <BadgeContainer>
                        <Badge
                          themeColor={statusClinicalCompleted}
                          size='small'
                          align={{ horizontal: 'start' }}
                          cutoutBorder={false}
                        >
                          <span
                            className={cx(
                              'k-icon',
                              { 'k-i-minus': expandStatusBarClinical },
                              { 'k-i-plus': !expandStatusBarClinical }
                            )}
                            onClick={() =>
                              setExpandStatusBarClinical(
                                !expandStatusBarClinical
                              )
                            }
                          />
                        </Badge>
                        <span
                          className={styles.category}
                          style={{ marginLeft: '1.6rem' }}
                        >
                          CLINICAL
                        </span>
                      </BadgeContainer>
                      {expandStatusBarClinical && <>{detailsClinical}</>}
                    </div>
                    <hr className={styles.hr} />
                  </div>
                </>
              )}

              {/* ------------------------------------------------------------------------- */}

              {/*  BI Section  */}

              {showStatusBarBI && (
                <>
                  <div className='BENINV'>
                    <div className={styles.category}>
                      <BadgeContainer>
                        <Badge
                          themeColor={statusBICompleted}
                          size='small'
                          align={{ horizontal: 'start' }}
                          cutoutBorder={false}
                        >
                          <span
                            className={cx(
                              'k-icon',
                              { 'k-i-minus': expandStatusBarBI },
                              { 'k-i-plus': !expandStatusBarBI }
                            )}
                            onClick={() =>
                              setExpandStatusBarBI(!expandStatusBarBI)
                            }
                          />
                        </Badge>
                        <span
                          className={styles.category}
                          style={{ marginLeft: '1.6rem' }}
                        >
                          BENEFITS INV
                        </span>
                      </BadgeContainer>
                      {expandStatusBarBI && <>{detailsBI}</>}
                    </div>
                    <hr className={styles.hr} />
                  </div>
                </>
              )}

              {/* ------------------------------------------------------------------------- */}

              {/* PRIOR AUTH Section */}
              {showStatusBarPA && (
                <>
                  <div className='PRIOR AUTH'>
                    <div className={styles.category}>
                      <BadgeContainer>
                        <Badge
                          themeColor={statusPACompleted}
                          size='small'
                          align={{ horizontal: 'start' }}
                          cutoutBorder={false}
                        >
                          <span
                            className={cx(
                              'k-icon',
                              { 'k-i-minus': expandStatusBarPA },
                              { 'k-i-plus': !expandStatusBarPA }
                            )}
                            onClick={() =>
                              setExpandStatusBarPA(!expandStatusBarPA)
                            }
                          />
                        </Badge>
                        <span
                          className={styles.category}
                          style={{ marginLeft: '1.6rem' }}
                        >
                          PRIOR AUTH
                        </span>
                      </BadgeContainer>
                      {expandStatusBarPA && <>{detailsPA}</>}
                    </div>
                    <hr className={styles.hr} />
                  </div>
                </>
              )}

              {/* ------------------------------------------------------------------------- */}

              {/* FOLLOW UP Section */}
              <div className='followup'>
                <FollowUp />
                <GeneralFollowUp />
                <hr className={styles.hr} />
                <div className={styles.category}>
                  <BadgeContainer>
                    <Badge
                      themeColor={showStatusBarScheduledAppts}
                      size='small'
                      align={{ horizontal: 'start' }}
                      cutoutBorder={false}
                    >
                      <span
                        className={cx(
                          'k-icon',
                          { 'k-i-minus': expandStatusBarScheduledAppts },
                          { 'k-i-plus': !expandStatusBarScheduledAppts }
                        )}
                        onClick={() => {
                          CheckScheduledItemsInit(
                            !expandStatusBarScheduledAppts
                          );
                          setExpandStatusBarScheduledAppts(
                            !expandStatusBarScheduledAppts
                          );
                        }}
                      />
                    </Badge>
                    <span
                      className={styles.category}
                      style={{ marginLeft: '1.6rem' }}
                    >
                      SCHEDULED APPTS:
                    </span>
                  </BadgeContainer>
                  {expandStatusBarScheduledAppts && (
                    <>
                      {scheduledItems.map((itm) => (
                        <div className={styles.categoryItem} key={itm.id}>
                          <ScheduledItem
                            key={itm.id}
                            id={itm.id}
                            timeZone={itm.timeZone}
                            startTime={itm.startTime}
                            startTimeZone={itm.startTimeZone}
                            endTime={itm.endTime}
                            endTimeZone={itm.endTimeZone}
                            locationId={itm.locationId}
                            providerId={itm.providerId}
                            title={itm.title}
                            displayDate={itm.displayDate}
                          />
                        </div>
                      ))}
                    </>
                  )}
                </div>

                <hr className={styles.hr} />

                <div className={styles.category}>
                  <BadgeContainer>
                    <Badge
                      themeColor={showStatusBarWorkItems}
                      size='small'
                      align={{ horizontal: 'start' }}
                      cutoutBorder={false}
                    >
                      <span
                        className={cx(
                          'k-icon',
                          { 'k-i-minus': expandStatusBarWorkItems },
                          { 'k-i-plus': !expandStatusBarWorkItems }
                        )}
                        onClick={() => {
                          // CheckWorkItemsInit(!expandStatusBarWorkItems);
                          setExpandStatusBarWorkItems(
                            !expandStatusBarWorkItems
                          );
                        }}
                      />
                    </Badge>
                    <span
                      className={styles.category}
                      style={{ marginLeft: '1.6rem' }}
                    >
                      WORK ITEMS:
                    </span>
                  </BadgeContainer>
                  {expandStatusBarWorkItems && workItemData && (
                    <>
                      {workItemData.map((itm) => (
                        <div className={styles.categoryItem} key={itm.id}>
                          <WorkItem
                            key={itm.id}
                            id={itm.id}
                            description={itm.description}
                            work={itm.work}
                          />
                        </div>
                      ))}
                    </>
                  )}
                </div>
                <div className={styles.category}>LAST UPDATE</div>
                <div
                  className={styles.categoryItem}
                  style={{ marginLeft: '0.0rem' }}
                >
                  {moment(selectedPatientInfoAll.updatedAt).format(
                    'MM/DD/YYYY @ hh:mm A'
                  )}
                </div>
              </div>
              <hr className={styles.hr} />
              <div className={styles.category}>
                LAST WORKED AGENT NAME:{' '}
                {statusBar?.caseDetail?.agentFirstName && (
                  <span>{`${statusBar?.caseDetail?.agentFirstName} ${statusBar?.caseDetail?.agentLastName}`}</span>
                )}
              </div>
              <hr className={styles.hr} />
              <div style={{ textAlign: 'center' }}>
                <span onClick={refreshStatusBar} className='k-link mr-15'>
                  <span className='k-icon k-iconWhite k-i-refresh mr-2 mb-1' />
                  Update Status
                </span>
              </div>
            </div>
          </div>
        )}
      </div>

      {showNotes && (
        <BulletinBoard
          toggleDialog={toggleNotesDialog}
          patientId={selectedPatientInfoAll.patientId}
        />
      )}
    </>
  );
};

export default StatusBar;
