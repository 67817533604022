import React, { useContext } from 'react';
import Drawer from 'rc-drawer';
import styled from 'styled-components';

// components
import Preloader from '@/components/common-components/Preloader';
import WorkStatusTransition from '@/components/common-components/WorkStatusTransition';
import AwesomeLabel from '@/components/common-components/AwesomeLabel';
import DrawerHeaderItem from './DrawerHeaderItem';

// utils
import { utc2local } from '@/common/DateHelper';
import { getLogTitle } from './WorkItemLog.utils';
import { workItemStatusMap } from '@/components/Queue/FilterSystem/filterHelper';
import { getWorkItemMetadata } from '@/common/WorkItemHelper';
import { formatDateTimeToDefault } from '@optml/alera-share';
import { taskStatus } from '@/constants/enum';

// context
import { WorkItemContext, PatientContext } from '@/context';

const DrawerContent = styled.div`
  padding: 15px;
`;

const LogRecord = styled.li`
  padding: 10px 0;
`;

const LogList = styled.ul`
  padding-inline-start: 20px;

  div {
    padding-top: 10px;
  }
`;

const WorkItemLogDrawer = () => {
  const {
    isLogLoading,
    showLog,
    setShowLog,
    workItemLog,
    selectedWorkItem4Log
  } = useContext(WorkItemContext);
  const { selectedPatientInfo } = useContext(PatientContext);

  if (!showLog) return null;

  const { drugName } = getWorkItemMetadata(
    selectedWorkItem4Log,
    selectedPatientInfo
  );

  const renderLogRecord = (logRecord, index) => {
    const elKey = `log-item-${index}`;
    const when = utc2local(logRecord.recordTime);
    const hasNotes = logRecord.notes && logRecord.notes !== '';

    return (
      <LogRecord key={elKey}>
        <WorkStatusTransition logRecord={logRecord} />
        <div>{getLogTitle(logRecord)}</div>
        <div>
          <small>
            <AwesomeLabel icon='calendar' label={when} />
          </small>
        </div>
        {hasNotes && (
          <div>
            <small>
              <AwesomeLabel
                icon='notes'
                label={
                  <>
                    <b>Notes: </b>
                    {logRecord.notes}
                  </>
                }
              />
            </small>
          </div>
        )}
      </LogRecord>
    );
  };

  const renderDescription = () => {
    if (!!selectedWorkItem4Log?.description) {
      return (
        <DrawerHeaderItem
          icon='envelope-open-text'
          label='Description'
          value={selectedWorkItem4Log.description}
        />
      );
    }

    return null;
  };

  const renderDrugName = () => {
    if (!!drugName) {
      return (
        <DrawerHeaderItem icon='pills' label='Drug Name' value={drugName} />
      );
    }

    return null;
  };

  const renderItemType = () => (
    <DrawerHeaderItem
      icon='briefcase'
      label='Work Item Type'
      value={workItemStatusMap[selectedWorkItem4Log.work]}
    />
  );

  const renderDates = () => (
    <div>
      <DrawerHeaderItem
        icon='timer'
        label='Created Date'
        value={formatDateTimeToDefault(selectedWorkItem4Log.createdAt)}
      />
      <DrawerHeaderItem
        icon='timer'
        label='Closed Date'
        value={formatDateTimeToDefault(selectedWorkItem4Log.updatedAt)}
      />
    </div>
  );

  const renderCompletedBy = () => {
    let agentName = 'SYSTEM';

    if (!!workItemLog?.length) {
      const completeItem = workItemLog.find(
        (item) => item.afterWorkStatus === taskStatus.COMPLETED
      );

      if (!!completeItem) agentName = completeItem.agentId;
    }

    return (
      <DrawerHeaderItem icon='user' label='Completed By' value={agentName} />
    );
  };

  const renderCreatedBy = () => (
    <DrawerHeaderItem
      icon='user'
      label='Created By'
      value={selectedWorkItem4Log.createdBy}
    />
  );

  return (
    <Drawer
      open={showLog}
      onClose={() => {
        setShowLog(false);
      }}
    >
      <DrawerContent>
        <h5>Work Item Info</h5>
        {renderItemType()}
        {renderDescription()}
        {renderDrugName()}
        {renderDates()}
        {renderCreatedBy()}
        {renderCompletedBy()}
        <hr />
        <h5>Work Item History</h5>
        <Preloader show={isLogLoading}>
          <LogList>
            {workItemLog?.map((logRecord, i) => renderLogRecord(logRecord, i))}
          </LogList>
          {workItemLog?.length === 0 && <p>No history records found.</p>}
        </Preloader>
      </DrawerContent>
    </Drawer>
  );
};

export { WorkItemLogDrawer };
