export const dosageFrequencyTypes = [
  { label: 'OVER', value: 'OVER' },
  { label: 'EVERY', value: 'EVERY' },
  { label: 'OVER/EVERY', value: 'BOTH' }
];

export const dosageDayRanges = [
  { value: 1 },
  { value: 2 },
  { value: 3 },
  { value: 4 },
  { value: 5 }
];

export const dosageDateTimeFrames = [
  { value: 'day' },
  { value: 'week' },
  { value: 'month' },
  { value: 'year' }
];

export const referralType = {
  LAB_DRAW: 'LAB_DRAW',
  LAB_DRAW_TEXT: 'Lab Draw',
  INFUSION: 'INFUSION',
  INFUSION_TEXT: 'Infusion',
  FIGURE_STICK: 'FIGURE_STICK',
  FIGURE_STICK_TEXT: 'Figure Stick'
};
export const productLabNames = {
  LAB_DRAW_NAME: 'LAB DRAW'
};
export const orderTypes = [
  { text: 'New Order', value: 'NEW_ORDER' },
  { text: 'Existing Order', value: 'EXISTING_ORDER' },
  { text: 'Transfer Order', value: 'TRANSFER_ORDER' }
];

export const drugTypes = [
  { text: 'Primary', value: 'PRIMARY' },
  { text: 'Secondary', value: 'SECONDARY' }
];

export const routes = [
  { text: 'IM', value: 'IM' },
  { text: 'IV', value: 'IV' },
  { text: 'IVP', value: 'IVP' },
  { text: 'PO', value: 'PO' },
  { text: 'SUBQ', value: 'SUBQ' }
];

export const routesFlat = routes.map((item) => item.value);

export const labDrawProduct = {
  possibleUOMs: 'mg,g',
  productId: '36415',
  productName: 'Lab Draw',
  productOnSelector: 'Lab Draw'
};
