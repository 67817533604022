import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import cx from 'clsx';

// components
import { TextCountdown } from './TextCountdown';

// context
import { WorkItemContext } from '@/context/WorkItemContext';

// constants
import {
  IDLE_LIMIT_TIME,
  COUNTDOWN_DURATION
} from './WorkItemTimeoutModal.constants';
import { returnReasons } from '@/constants/dict';

// styles
import styles from './WorkItemTimeoutModal.module.scss';

let idleTimer = null;
let countdownTimer = null;

const WorkItemTimeoutModal = () => {
  const [showIdleDialog, setShowIdleDialog] = useState(false);
  const [countdown, setCountdown] = useState(COUNTDOWN_DURATION);
  const { selectedWorkItem, deletePendingWorkItem, returnWorkItem } =
    useContext(WorkItemContext);

  const showSessionModal = () => {
    setShowIdleDialog(true);

    let remainingSeconds = COUNTDOWN_DURATION;
    countdownTimer = setInterval(async () => {
      remainingSeconds -= 1;
      if (remainingSeconds === 0) {
        // refresh timer and dialog
        clearInterval(countdownTimer);
        setShowIdleDialog(false);

        // return work item to the Q
        returnWorkItem(selectedWorkItem, returnReasons.BY_TIMEOUT);
      }
      setCountdown(remainingSeconds);
    }, 1000);
  };

  const onContinueSession = () => {
    resetTimer();
    clearInterval(countdownTimer);
    setShowIdleDialog(false);
    setCountdown(COUNTDOWN_DURATION);
  };

  const resetTimer = useCallback(() => {
    if (selectedWorkItem) {
      clearTimeout(idleTimer);
      idleTimer = setTimeout(showSessionModal, IDLE_LIMIT_TIME);
    }
  }, [selectedWorkItem, showSessionModal]);

  useEffect(() => {
    resetTimer();
  }, [selectedWorkItem]);

  if (showIdleDialog) {
    return (
      <Dialog
        // className={styles.idleLogoffDialog}
        title="Your Work Item is Expiring"
        onClose={() => setShowIdleDialog(false)}
      >
        <p className={styles.sessionDesc}>
          Your current work item is expiring, do you still need more time to
          work on it?
        </p>
        <p className={styles.sessionDesc}>
          Otherwise, it will be returned back to the original Working Queue.
        </p>
        <div className={styles.actions}>
          <Button
            className={cx('btn blue', styles.btnContinue)}
            onClick={onContinueSession}
          >
            Keep this work item
          </Button>
          <Button
            className="btn"
            onClick={() => {
              deletePendingWorkItem(selectedWorkItem.id);
              returnWorkItem(selectedWorkItem, returnReasons.BY_AGENT);
              setShowIdleDialog(false);
            }}
          >
            Return It Now
          </Button>
        </div>
        <TextCountdown timeValue={countdown} />
      </Dialog>
    );
  }
  return null;
};

export default WorkItemTimeoutModal;
