import React from 'react';
import { yesNoBoolean } from '@/common/Converters';

const YesNoCell = ({ boolVal }) => {
  if (typeof boolVal === 'boolean') {
    return <td>{yesNoBoolean(boolVal)}</td>;
  }

  return <td>&nbsp;</td>;
};

export default YesNoCell;
