import { API, graphqlOperation } from 'aws-amplify';

export const connectToGraphqlAPI = ({ graphqlQuery, variables = {} }) => {
  const gqlEndpoint = API?._graphqlApi?._options?.endpoints?.[0];
  if (gqlEndpoint) {
    API._graphqlApi._options.aws_appsync_graphqlEndpoint = gqlEndpoint.endpoint;
    return API.graphql(graphqlOperation(graphqlQuery, variables));
  }
  return undefined;
};

export const getQueryClient = async ({ query, payload, path }) => {
  try {
    let result = [];
    do {
      const { data } = await connectToGraphqlAPI({
        graphqlQuery: query,
        variables: payload
      });
      result = result.concat(data[path]?.items ? data[path].items : data[path]);
      payload.nextToken = data[path]?.nextToken;
    } while (payload.nextToken);

    return result;
  } catch (err) {
    console.error(err);
    return [];
  }
};
