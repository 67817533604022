import React, { createContext, useState, useEffect } from 'react';
import { connectToGraphqlAPI } from '@/provider';
import { getProductList } from '@/graphql/queries';
import { getProductsByType, productTypes } from './ProductContextHelper';

export const ProductContext = createContext();

const ProductContextProvider = ({ children, user }) => {
  // include all product types (PRESCRIPTION, PREMED, DILUENT), non-unique and INACTIVE drugs
  const [productsRaw, setProductsRaw] = useState([]);

  // Filtered out inactive
  const [products, setProducts] = useState([]);

  // include all product types, but unique (no dups) and ACTIVE only
  const [productsUnique, setProductsUnique] = useState([]);

  // include only products with type 'PRESCRIPTION'
  const [prescriptions, setPrescriptions] = useState([]);

  // include only products with type 'PREMED'
  const [premeds, setPremeds] = useState([]);

  // include only products with type 'DILUENT'
  const [diluents, setDiluents] = useState([]);

  // include only products with flag `isReconstituted === true`
  const [mixins, setMixins] = useState([]);

  // "Core", "Rare", etc.
  const [drugCategories, setDrugCategories] = useState([]);
  // filtered and sorted drug categories
  const [filteredDrugCategories, setFilteredDrugCategories] = useState([]);

  // TODO: get rid of it
  const [listProductsDataFiltered, setListProductsDataFiltered] = useState([]);

  const fetchProducts = async () => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getProductList
      });

      if (data?.data?.listProducts?.items) {
        const rawProducts = data.data.listProducts.items;
        const filtered = rawProducts
          .filter((item) => item.status !== 'INACTIVE')
          .map((item) => ({
            productId: item.productId,
            productOnSelector: item.productName,
            productName: item.productName,
            possibleUOMs: item.possibleUOMs,
            productType: item.typeOfProduct,
            selectorText: `${item.productName} ${item.strength} ${item.unitOfMeas} (${item.route})`,
            productForInventory: `${item.productName} (${item.strength} ${item.unitOfMeas}) - ${item.packageType}, ${item.packageVolume} ${item.packageVolumeUOM}`,
            ...item
          }))
          .sort((a, b) => (a.productName > b.productName ? 1 : -1));

        const unique = Array.from(new Set(filtered.map((a) => a.productName))).map((productName) => {
          return filtered.find((a) => a.productName === productName);
        }); // left code in case Ilia wants to revisit

        setProductsRaw(rawProducts); // ALL products unfiltered
        setProducts(filtered); // Filtered out inactive
        setProductsUnique(unique); // Unique products (no dup names)

        const prescriptions = getProductsByType(
          filtered,
          productTypes.PRESCRIPTION
        );
        setPrescriptions(prescriptions);

        const premeds = getProductsByType(filtered, productTypes.PREMED);
        setPremeds(premeds);

        const diluents = getProductsByType(filtered, productTypes.DILUENT);
        setDiluents(diluents);

        const mixins = filtered.filter((item) => item.isReconstituted === true);
        setMixins(mixins);

        const drugCategories = [
          ...new Set(rawProducts.map((item) =>
              (item.category ? `"${item.category}"` : null)))
        ];
        setDrugCategories(drugCategories);
        const filteredCategories = drugCategories
          .filter((category) => category !== null)
          .map((category) => category.replace(/['"]+/g, ''))
          .sort((a, b) => {
            const nameA = a.replace(/(\d+)/g, ''); // Extract the text part
            const nameB = b.replace(/(\d+)/g, ''); // Extract the text part
            if (nameA === nameB) {
              const numA = parseInt(a.match(/\d+/g)) || 0; // Extract the numeric part
              const numB = parseInt(b.match(/\d+/g)) || 0; // Extract the numeric part
              return numA - numB;
            }
            return nameA.localeCompare(nameB);
          });
        setFilteredDrugCategories(filteredCategories);
      }
    } catch (err) {
      console.error('Failed to get product list');
    }
  };

  useEffect(() => {
    // action fires only if user logged in
    if (user) {
      fetchProducts();
    }
  }, [user]);

  const getProductById = (id) => {
    const product = products.find((item) => item.productId === id);

    if (product) {
      return product;
    }

    return null;
  };

  return (
    <ProductContext.Provider
      value={{
        products,
        productsRaw,
        getProductById,
        listProductsDataFiltered,
        setListProductsDataFiltered,
        prescriptions,
        premeds,
        diluents,
        mixins,
        productsUnique,
        setProductsUnique,
        drugCategories,
        filteredDrugCategories,
        setFilteredDrugCategories
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};

export default ProductContextProvider;
