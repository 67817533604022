import React, { useEffect, useMemo, useState, useContext } from 'react';

import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Form, Field } from '@progress/kendo-react-form';
import { Input, Switch } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { TimePicker } from '@progress/kendo-react-dateinputs';
import { Error } from '@progress/kendo-react-labels';
import * as moment from 'moment';
import DeleteButton from '@/components/common-components/Form/DeleteButton';

import { validateInput, validateFloat } from '@/common/Validation';
import { Constants } from '@/constants';

import { MessageDialog } from '@/components/common-components/MessageDialog';
import {
  getStepStatusV2,
  infusionStepNumbersV2,
  infusionSteps,
  isObjectEmpty
} from '../Infusion/infusionHelper';
import RevisitAdmin from '../Infusion/Revisit/RevisitAdmin';
import Alert from '@/components/common-components/Alert';

import {
  formatDateToAWSDateTime,
  formatTimeToDefault
} from '@/common/DateHelper';
import {
  getAdminMaxTime,
  getAdminMinTime
} from '../Scheduling/getSchedulerTimeLimits';
import { CellRender, RowRender } from '@/common/GridRenderer';

import { InfusionContext } from '@/context/InfusionContext';
import { UserContext } from '@/context/UserContext';
import { connectToGraphqlAPI } from '@/provider';
import { updateStepAdministration } from '@/graphql/mutations';

const AdminV2 = (props) => {
  const { agent } = useContext(UserContext);
  const { infusion, setInfusion, setCurrentStep, updateStepper } =
    useContext(InfusionContext);
  const { nursingProcess } = infusion;
  const nursingProcessId = nursingProcess?.id;

  const [apiIVs, setApiIVs] = useState([]);
  const [itemIVs, setItemIVs] = useState([]);
  const [apiIMs, setApiIMs] = useState([]);
  const [itemIMs, setItemIMs] = useState([]);
  const [apiOtherIVs, setApiOtherIVs] = useState([]);
  const [itemOtherIVs, setItemOtherIVs] = useState([]);
  const [apiVitals, setApiVitals] = useState([]);
  const [itemVitals, setItemVitals] = useState([]);

  const [stepAllowed, setStepAllowed] = useState(false);
  const [dialogOption, setDialogOption] = useState({});
  const [isStopped, setIsStopped] = useState(false);
  const [isIv, setIsIv] = useState(false);
  const [isImAdded, setIsImAdded] = useState(false);
  const [adminReady, setAdminReady] = useState(true);
  const [prepHasDrugs, setPrepHasDrugs] = useState(false);

  const event = ['Start', 'Stop', 'Rate Change', 'Note'];
  const [eventIV, setEventIV] = useState('');
  const [timeIV, setTimeIV] = useState('');
  const showRateIV = eventIV !== 'Stop';
  const [editField, setEditField] = useState(undefined);
  const [isVitalsTimeRangeError, setIsVitalsTimeRangeError] = useState(false);
  const [isIMTimeRangeError, setIsIMTimeRangeError] = useState(false);
  const [isIVTimeRangeError, setIsIVTimeRangeError] = useState(false);

  useEffect(() => {
    handleLoadInfusion();
  }, [nursingProcess]);

  const ivim = () => {
    if (isStopped === false) {
      if (isIv === true || isImAdded === true) {
        setAdminReady(false);
      }
    } else if (isStopped === true) {
      setAdminReady(true);
    }
  };

  useMemo(() => {
    ivim();
  }, [isIv, isImAdded, isStopped]);

  const updateStepAdministrationCall = async (input) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: updateStepAdministration,
        variables: { input }
      });
      if (data && data.data && data.data.updateStepAdministration) {
        setCurrentStep(infusionStepNumbersV2.CLOSE_TREATMENT);

        const infusionData = { ...infusion };
        infusionData.nursingProcess.stepAdministration = {
          ...infusionData.nursingProcess.stepAdministration,
          imDrugs: [...input.imDrugs],
          ivDrugs: [...input.ivDrugs],
          otherIVDrugs: [...input.otherIVDrugs],
          vitals: [...input.vitals],
          administrationComplete: input.administrationComplete
        };

        // update infusion data in the context level
        setInfusion({ ...infusionData });

        // update stepper progress checkmark
        const stepIsValid = getStepStatusV2(infusionSteps.ADMIN, input);
        updateStepper(stepIsValid);
      }
    } catch (err) {
      console.error('AdminV2::updateStepAdministrationCall err: ', err);
    }
  };

  const handleLoadInfusion = () => {
    if (nursingProcess && nursingProcess.stepReview) {
      if (nursingProcess.stepReview.orderApproved) {
        setStepAllowed(true);
      }
    }

    // Logic to prevent inventory deduction on server before any drugs actually selected
    if (nursingProcess.stepPreparation?.drugs) {
      setPrepHasDrugs(nursingProcess.stepPreparation.drugs.length > 0);
    } else setPrepHasDrugs(false);

    if (
      nursingProcess &&
      nursingProcess.stepAdministration &&
      nursingProcess.stepAdministration.ivDrugs
    ) {
      try {
        const a = [];
        const i = [];
        let startGroupId = 0;
        nursingProcess.stepAdministration.ivDrugs.map((item) => {
          if (item.event === 'Start') {
            ++startGroupId;
          }
          const apiIV = {
            recordNumber: item.recordNumber,
            time: item.time,
            event: item.event,
            rate: item.rate === '' ? null : item.rate,
            unitOfRate: item.unitOfRate,
            visualNotes: item.visualNotes,
            initials: item.initials,
            totalInfusionInSec: item.totalInfusionInSec
          };
          a.push(apiIV);

          const min = Math.floor(item.totalInfusionInSec / 60);
          let h = Math.floor(min / 60);
          let m = min % 60;
          h = h < 10 ? `0${h}` : h;
          m = m < 10 ? `0${m}` : m;
          const modifiedTotalInfusion = item.totalInfusionInSec
            ? `${h} hour ${m} min`
            : '';

          const itemIV = {
            recordNumber: item.recordNumber,
            time: formatTimeToDefault(item.time),
            event: item.event,
            rate: item.rate === '' ? null : item.rate,
            unitOfRate: item.unitOfRate,
            visualNotes: item.visualNotes,
            initials: item.initials,
            totalInfusionInSec: item.totalInfusionInSec,
            totalInfusion: modifiedTotalInfusion,
            groupId: startGroupId
          };
          i.push(itemIV);
        });
        setApiIVs([...a]);
        setItemIVs([...i]);
        setIsStopped(
          [...i].filter((x) => x.event === 'Start').length !==
            [...i].filter((x) => x.event === 'Stop').length
        );
        // setIsStopped(!([...i].some(x => x.event === 'Start') && ![...i].some(x => x.event === 'Stop')));
        if ([...i].length > 0) {
          setIsIv(true);
        }
      } catch (err) {
        console.error('AdminV2::nursingProcess IVs err: ', err);
        setDialogOption({
          title: 'Infusion: Admin',
          message: 'Error: nursingProcess IVs',
          showDialog: true
        });
      }
    }

    if (
      nursingProcess &&
      nursingProcess.stepAdministration &&
      nursingProcess.stepAdministration.imDrugs
    ) {
      try {
        const a = [];
        const i = [];
        nursingProcess.stepAdministration.imDrugs.map((item) => {
          const apiIM = {
            recordNumber: item.recordNumber,
            time: item.time,
            location: item.location,
            amount: item.amount,
            unitOfAmount: item.unitOfAmount,
            visualNotes: item.visualNotes,
            temperature: item.temperature,
            S: item.S,
            D: item.D,
            hr: item.hr,
            R: item.R,
            SPO2: item.SPO2,
            initials: item.initials
          };
          a.push(apiIM);
          const itemIM = {
            recordNumber: item.recordNumber,
            time: formatTimeToDefault(item.time),
            location: item.location,
            amountMG: item.amount,
            visualNotes: item.visualNotes,
            temp: item.temperature,
            s: item.S,
            d: item.D,
            hr: item.hr,
            r: item.R,
            spo2: item.SPO2,
            initials: item.initials
          };
          i.push(itemIM);
        });
        setApiIMs([...a]);
        setItemIMs([...i]);
        if ([...i].length > 0) {
          setIsImAdded(true);
        }
      } catch (err) {
        console.error('AdminV2::nursingProcess IMs err: ', err);
        setDialogOption({
          title: 'Infusion: Assessment',
          message: 'Error: nursingProcess IMs',
          showDialog: true
        });
      }
    }

    if (
      nursingProcess &&
      nursingProcess.stepAdministration &&
      nursingProcess.stepAdministration.otherIVDrugs
    ) {
      try {
        const a = [];
        const i = [];
        nursingProcess.stepAdministration.otherIVDrugs.map((item) => {
          const apiOtherIV = {
            recordNumber: item.recordNumber,
            time: item.time,
            event: item.event,
            rate: item.rate,
            unitOfRate: item.unitOfRate,
            visualNotes: item.visualNotes,
            initials: item.initials,
            totalInfusionInSec: item.totalInfusionInSec
          };
          a.push(apiOtherIV);
          const itemOtherIV = {
            recordNumber: item.recordNumber,
            time: formatTimeToDefault(item.time),
            event: item.event,
            rate: item.rate,
            unitOfRate: item.unitOfRate,
            visualNotes: item.visualNotes,
            initials: item.initials,
            totalInfusionInSec: item.totalInfusionInSec
          };
          i.push(itemOtherIV);
        });
        setApiOtherIVs([...a]);
        setItemOtherIVs([...i]);
      } catch (err) {
        console.error('AdminV2::nursingProcess OtherIV err: ', err);
        setDialogOption({
          title: 'Infusion: Assessment',
          message: 'Error: nursingProcess OtherIV',
          showDialog: true
        });
      }
    }

    if (
      nursingProcess &&
      nursingProcess.stepAdministration &&
      nursingProcess.stepAdministration.vitals
    ) {
      try {
        const a = [];
        const i = [];
        nursingProcess.stepAdministration.vitals.map((item, index) => {
          const apiVital = {
            recordNumber: item.recordNumber,
            enteredAt: formatDateToAWSDateTime(item.enteredAt),
            temperature: item.temperature,
            bloodPressure: item.bloodPressure,
            heartRate: item.heartRate,
            R: item.R,
            SP02: item.SP02,
            initials: item.initials
          };
          a.push(apiVital);
          const itemVital = {
            temp: item.temperature,
            bp: item.bloodPressure,
            hr: item.heartRate,
            r: item.R,
            spo2: item.SP02,
            initials: item.initials,
            time: formatTimeToDefault(item.enteredAt),
            id: index
          };
          i.push(itemVital);
        });
        setApiVitals([...a]);
        setItemVitals([...i]);
      } catch (err) {
        console.error('AdminV2::nursingProcess vitals err: ', err);
        setDialogOption({
          title: 'Infusion: Administration',
          message: 'Error: nursingProcess vitals',
          showDialog: true
        });
      }
    }
  };

  const infusionForm = {
    isAdministrationComplete: {
      value: !!nursingProcess.stepAdministration?.administrationComplete,
      inputValidator: (value) => {
        return validateInput({
          isAdministrationComplete: {
            ...infusionForm.isAdministrationComplete,
            value
          }
        });
      },
      validations: []
    }
  };

  const validateIVTimeRange = (value) => {
    // field is now required when vitals are entered
    if (!value || value === '') {
      setIsIVTimeRangeError(true);
      return true;
    }
    setIsIVTimeRangeError(
      !moment(value).isBetween(getAdminMinTime(), getAdminMaxTime())
    );
    return moment(value).isBetween(getAdminMinTime(), getAdminMaxTime())
      ? ''
      : true;
  };
  const handleAddIV = (dataItem) => {
    validateIVTimeRange(dataItem.time);
    const copyItemIVs = JSON.parse(JSON.stringify(itemIVs));
    const lastStartIndex = copyItemIVs
      .reverse()
      .findIndex((item) => item.event === 'Start');
    if (itemIVs.length === 0 && dataItem.event !== 'Start') {
      setDialogOption({
        title: 'Infusion: Admin',
        message: 'Error: First event should be started',
        showDialog: true
      });
      return false;
    }
    if (
      itemIVs.length > 0 &&
      itemIVs[itemIVs.length - 1].event === 'Start' &&
      dataItem.event === 'Start'
    ) {
      setDialogOption({
        title: 'Infusion: Admin',
        message: 'Error: Please select stop rate change or note',
        showDialog: true
      });
      return false;
    }
    if (
      itemIVs.length > 0 &&
      itemIVs[itemIVs.length - 1].event === 'Stop' &&
      dataItem.event !== 'Start'
    ) {
      setDialogOption({
        title: 'Infusion: Admin',
        message: 'Error: Please start the event',
        showDialog: true
      });
      return false;
    }
    if (
      itemIVs.length > 0 &&
      !copyItemIVs
        .slice(0, lastStartIndex)
        .find((item) => item.event === 'Stop') &&
      dataItem.event === 'Start'
    ) {
      setDialogOption({
        title: 'Infusion: Admin',
        message: 'Error: Please select stop to start again',
        showDialog: true
      });
      return false;
    }
    if (
      itemIVs.length > 0 &&
      moment(
        `${new Date().toDateString()} ${itemIVs[itemIVs.length - 1].time}`
      ).isSameOrAfter(
        moment(
          `${new Date().toDateString()} ${formatTimeToDefault(dataItem.time)}`
        )
      )
    ) {
      setDialogOption({
        title: 'Infusion: Admin',
        message: 'Error: Time must be greater than last selected',
        showDialog: true
      });
      return false;
    }

    const apiIV = {
      recordNumber: 1,
      time: dataItem.time,
      event: dataItem.event,
      unitOfRate: '',
      visualNotes: dataItem.visualNotes,
      initials: dataItem.initials,
      totalInfusionInSec: 0
    };

    const itemIV = {
      recordNumber: 1,
      time: formatTimeToDefault(dataItem.time),
      event: dataItem.event,
      unitOfRate: '',
      visualNotes: dataItem.visualNotes,
      initials: dataItem.initials,
      totalInfusionInSec: 0,
      groupId: 1
    };

    if (showRateIV) {
      apiIV.rate = dataItem.rate === '' ? null : dataItem.rate;
      itemIV.rate = dataItem.rate === '' ? null : dataItem.rate;
    }

    if (dataItem.event === 'Start') {
      const startIndex = itemIVs.map((item) => item.event).lastIndexOf('Start');
      itemIV.groupId = startIndex === -1 ? 1 : itemIVs[startIndex].groupId + 1;
    }

    if (dataItem.event !== 'Start') {
      const startIndex = itemIVs.map((item) => item.event).lastIndexOf('Start');
      const stopIndex = itemIVs.map((item) => item.event).lastIndexOf('Stop');
      if (startIndex > -1 && itemIVs[startIndex].time) {
        let lastTotalNumberOfSec = 0;
        if (stopIndex > -1) {
          lastTotalNumberOfSec = itemIVs[stopIndex].totalInfusionInSec;
        }

        const sec = Math.abs(
          moment(`${new Date().toDateString()} ${itemIV.time}`).diff(
            moment(`${new Date().toDateString()} ${itemIVs[startIndex].time}`),
            'seconds'
          )
        );
        const min = Math.floor((sec + lastTotalNumberOfSec) / 60);
        let h = Math.floor(min / 60);
        let m = min % 60;
        h = h < 10 ? `0${h}` : h;
        m = m < 10 ? `0${m}` : m;

        itemIV.totalInfusion = `${h} hour ${m} min`;
        itemIV.totalInfusionInSec = sec + lastTotalNumberOfSec;
        apiIV.totalInfusionInSec = sec + lastTotalNumberOfSec;
        itemIV.groupId = itemIVs[startIndex].groupId;
      }
    }

    try {
      setApiIVs([...apiIVs, apiIV]);
      setItemIVs([...itemIVs, itemIV]);
      setIsStopped(
        [...itemIVs, itemIV].filter((x) => x.event === 'Start').length !==
          [...itemIVs, itemIV].filter((x) => x.event === 'Stop').length
      );
      if ([...itemIVs, itemIV].length > 0) {
        setIsIv(true);
      }
    } catch (err) {
      console.error('AdminV2::handleAddIV err: ', err);
      setDialogOption({
        title: 'Infusion: Admin',
        message: 'Error: handleAddIV',
        showDialog: true
      });
    }
  };

  const validateIMTimeRange = (value) => {
    // field is now required when vitals are entered
    if (!value || value === '') {
      setIsIMTimeRangeError(true);
      return true;
    }
    setIsIMTimeRangeError(
      !moment(value).isBetween(getAdminMinTime(), getAdminMaxTime())
    );
    return moment(value).isBetween(getAdminMinTime(), getAdminMaxTime())
      ? ''
      : true;
  };

  const handleAddIM = (dataItem) => {
    validateIMTimeRange(dataItem.time);
    const apiIM = {
      recordNumber: 1,
      time: dataItem.time,
      location: dataItem.location,
      amount: dataItem.amountMG === '' ? null : dataItem.amountMG,
      unitOfAmount: 'mg', // dataItem.unitOfAmount,
      visualNotes: dataItem.visualNotes,
      temperature: dataItem.temp,
      S: dataItem.s,
      D: dataItem.d,
      hr: dataItem.hr,
      R: dataItem.r,
      SPO2: dataItem.spo2,
      initials: dataItem.initials
    };

    const itemIM = {
      recordNumber: 1,
      time: formatTimeToDefault(dataItem.time),
      location: dataItem.location,
      amountMG: dataItem.amountMG === '' ? null : dataItem.amountMG,
      visualNotes: dataItem.visualNotes,
      temp: dataItem.temp,
      s: dataItem.s,
      d: dataItem.d,
      hr: dataItem.hr,
      r: dataItem.r,
      spo2: dataItem.spo2,
      initials: dataItem.initials
    };

    try {
      setApiIMs([...apiIMs, apiIM]);
      setItemIMs([...itemIMs, itemIM]);

      if ([...itemIMs, itemIM].length > 0) {
        setIsImAdded(true);
      } else {
        setIsImAdded(false);
      }
    } catch (err) {
      console.error('AdminV2::handleAddIM err: ', err);
      setDialogOption({
        title: 'Infusion: Admin',
        message: 'Error: handleAddIM',
        showDialog: true
      });
    }
  };

  const validateVitalsTimeRange = (value) => {
    // field is now required when vitals are entered
    if (!value || value === '') {
      setIsVitalsTimeRangeError(true);
      return true;
    }
    setIsVitalsTimeRangeError(
      !moment(value).isBetween(getAdminMinTime(), getAdminMaxTime())
    );
    return moment(value).isBetween(getAdminMinTime(), getAdminMaxTime())
      ? ''
      : true;
  };

  const handleAddVitals = (dataItem) => {
    validateVitalsTimeRange(dataItem.time);
    const apiVital = {
      recordNumber: 1,
      enteredAt: formatDateToAWSDateTime(dataItem.time),
      temperature: dataItem.temp === '' ? null : dataItem.temp,
      bloodPressure: dataItem.bp,
      heartRate: dataItem.hr === '' ? null : dataItem.hr,
      R: dataItem.r === '' ? null : dataItem.r,
      SP02: dataItem.spo2 === '' ? null : dataItem.spo2,
      initials: dataItem.initials
    };

    const itemVital = {
      temp: dataItem.temp === '' ? null : dataItem.temp,
      bp: dataItem.bp,
      hr: dataItem.hr === '' ? null : dataItem.hr,
      r: dataItem.r === '' ? null : dataItem.r,
      spo2: dataItem.spo2 === '' ? null : dataItem.spo2,
      initials: dataItem.initials,
      time: formatTimeToDefault(dataItem.time),
      id: itemVitals.length
    };

    try {
      setApiVitals([...apiVitals, apiVital]);
      setItemVitals([...itemVitals, itemVital]);
    } catch (err) {
      console.error('AdminV2::handleAddVitals err: ', err);
      setDialogOption({
        title: 'Infusion: Administration',
        message: 'Error: handleAddVitals',
        showDialog: true
      });
    }
  };

  const handleSubmit = (dataItem) => {
    const isAdminCompleted = () => {
      if (dataItem && !isObjectEmpty(dataItem.values)) {
        return !!dataItem.values?.isAdministrationComplete;
      }

      return !!nursingProcess.stepAdministration?.administrationComplete;
    };
    const administrationComplete = isAdminCompleted();

    let isError = false;
    let errorMessage = Constants.ErrorMessage.allFields_REQUIRED;
    if (apiVitals.length > 0) {
      apiVitals.forEach((item) => {
        if (!item.temperature) {
          item.temperature = 0;
        }
        if (!item.heartRate) {
          item.heartRate = 0;
        }
        if (!item.R) {
          item.R = 0;
        }
        if (!item.SP02) {
          item.SP02 = 0;
        }
        if (validateEmptyFloat(item.temperature)) {
          isError = true;
          errorMessage = Constants.ErrorMessage.temp_Value;
          return false;
        }
        if (validateEmptyFloat(item.heartRate)) {
          isError = true;
          errorMessage = Constants.ErrorMessage.heartrate_Value;
          return false;
        }
        if (validateEmptyFloat(item.SP02)) {
          isError = true;
          errorMessage = Constants.ErrorMessage.spo2_Value;
          return false;
        }
        if (validateEmptyFloat(item.R)) {
          isError = true;
          errorMessage = Constants.ErrorMessage.r_Value;
          return false;
        }
      });
    }

    if (isError) {
      setDialogOption({
        title: 'Vital: Error',
        message: errorMessage,
        showDialog: true
      });
      return;
    }

    const requestObject = {
      nursingProcessId,
      agentId: agent.agentId,
      ivDrugs: apiIVs,
      imDrugs: apiIMs,
      otherIVDrugs: apiOtherIVs,
      vitals: apiVitals,
      administrationComplete
    };

    updateStepAdministrationCall(requestObject);
  };

  const handleRevisitSubmit = () => {
    const requestObject = {
      nursingProcessId,
      agentId: agent.agentId,
      ivDrugs: apiIVs,
      imDrugs: apiIMs,
      otherIVDrugs: apiOtherIVs,
      vitals: apiVitals,
      administrationComplete: true
    };

    updateStepAdministrationCall(requestObject);
  };

  const handleDeleteClick = (props, object) => {
    if (props.dataIndex > -1) {
      if (object === 'vitals') {
        if (props.dataIndex > -1) {
          const cloneApiVitals = [...apiVitals];
          cloneApiVitals.splice(props.dataIndex, 1);
          setApiVitals(cloneApiVitals);
          const cloneItemVitals = [...itemVitals];
          cloneItemVitals.splice(props.dataIndex, 1);
          setItemVitals(cloneItemVitals);
        }
      }
      if (object === 'iv') {
        if (props.dataIndex > -1) {
          if (
            props.dataItem.event === 'Start' &&
            itemIVs.filter((item) => item.groupId === props.dataItem.groupId)
              .length > 1
          ) {
            setDialogOption({
              title: 'Infusion: Admin',
              message: 'Error: Cannot delete start before a stop',
              showDialog: true
            });
            return false;
          }
          const cloneApiIVs = [...apiIVs];
          cloneApiIVs.splice(props.dataIndex, 1);
          setApiIVs(cloneApiIVs);
          const cloneItemIVs = [...itemIVs];
          cloneItemIVs.splice(props.dataIndex, 1);
          setItemIVs(cloneItemIVs);
          setIsStopped(
            cloneItemIVs.filter((x) => x.event === 'Start').length !==
              cloneItemIVs.filter((x) => x.event === 'Stop').length
          );
          //  setIsStopped(!(cloneItemIVs.some(x => x.event === 'Start') && !cloneItemIVs.some(x => x.event === 'Stop')));
          if (cloneItemIVs.length === 0) {
            setIsIv(false);
          }
        }
      }
      if (object === 'im') {
        if (props.dataIndex > -1) {
          const cloneApiIMs = [...apiIMs];
          cloneApiIMs.splice(props.dataIndex, 1);
          setApiIMs(cloneApiIMs);
          const cloneItemIMs = [...itemIMs];
          cloneItemIMs.splice(props.dataIndex, 1);
          setItemIMs(cloneItemIMs);
          if (cloneItemIMs.length > 0) {
            setIsImAdded(true);
            setAdminReady(false);
          } else {
            setAdminReady(true);
            setIsImAdded(false);
          }
        }
      }
      if (object === 'otheriv') {
        if (props.dataIndex > -1) {
          const cloneApiOtherIVs = [...apiOtherIVs];
          cloneApiOtherIVs.splice(props.dataIndex, 1);
          setApiOtherIVs(cloneApiOtherIVs);
          const cloneItemOtherIVs = [...itemOtherIVs];
          cloneItemOtherIVs.splice(props.dataIndex, 1);
          setItemOtherIVs(cloneItemOtherIVs);
        }
      }
    }
  };

  const customCellDeleteVitals = (props) => {
    return (
      <td>
        <DeleteButton handleClick={() => handleDeleteClick(props, 'vitals')} />
      </td>
    );
  };

  const customCellDeleteIV = (props) => {
    return (
      <td>
        {itemIVs[itemIVs.length - 1]?.groupId === props?.dataItem?.groupId ? (
          <DeleteButton handleClick={() => handleDeleteClick(props, 'iv')} />
        ) : (
          <div>&nbsp;</div>
        )}
      </td>
    );
  };

  const validateEmptyFloat = (value) => {
    if (!value || value === '') return '';
    return validateFloat(value) ? '' : Constants.ErrorMessage.Numeric_Required;
  };

  const validateTimeRange = (value) => {
    if (!value || value === '') return '';

    return moment(value).isBetween(getAdminMinTime(), getAdminMaxTime())
      ? ''
      : true;
  };

  const customCellDeleteIM = (props) => {
    return (
      <td>
        <DeleteButton handleClick={() => handleDeleteClick(props, 'im')} />
      </td>
    );
  };

  if (props.infusionRestricted) {
    return (
      <RevisitAdmin
        handleSubmit={handleRevisitSubmit}
        handleAddVitals={handleAddVitals}
        customCellDeleteVitals={customCellDeleteVitals}
        itemIVs={itemIVs}
        itemIMs={itemIMs}
        itemVitals={itemVitals}
      />
    );
  }

  const enterEdit = (dataItem, field) => {
    const newData = itemVitals.map((item) => ({
      ...item,
      inEdit: item.id === dataItem.id ? field : undefined
    }));
    setItemVitals(newData);
    setEditField(field);
  };

  const exitEdit = () => {
    const newData = itemVitals.map((item) => ({ ...item, inEdit: undefined }));
    setItemVitals(newData);
    setEditField(undefined);
  };

  const itemChange = (event) => {
    const field = event.field || '';
    event.dataItem[field] = event.value;
    const newData = itemVitals.map((item) => {
      if (item.id === event.dataItem.id) {
        item[field] = event.value;
      }
      return item;
    });
    const copyApiVitals = [...apiVitals];
    const apiVitalRow = copyApiVitals.find(
      (item, index) => index === event.dataItem.id
    );
    let isError = false;
    if (apiVitalRow) {
      if (field === 'temp') {
        isError = isNaN(event.value);
        apiVitalRow.temperature = event.value;
        if (isError) {
          setDialogOption({
            title: 'Vital: Error',
            message: Constants.ErrorMessage.temp_Value,
            showDialog: true
          });
        }
      } else if (field === 'time' && event.value.split(':').length > 1) {
        const now = new Date();
        apiVitalRow.enteredAt = moment(
          `${now.getMonth() + 1}/${now.getDate()}/${now.getFullYear()}`
        )
          .add(
            event.value.endsWith('AM')
              ? event.value.split(':')[0]
              : +event.value.split(':')[0] + 12,
            'hours'
          )
          .add(event.value.split(':')[1].slice(0, 2), 'minutes');
      } else if (field === 'bp') {
        apiVitalRow.bloodPressure = event.value;
      } else if (field === 'hr') {
        isError = isNaN(event.value); //! !validateEmptyFloat(event.value);
        apiVitalRow.heartRate = event.value;
        if (isError) {
          setDialogOption({
            title: 'Vital: Error',
            message: Constants.ErrorMessage.heartrate_Value,
            showDialog: true
          });
        }
      } else if (field === 'r') {
        isError = isNaN(event.value); //! !validateEmptyFloat(event.value);
        apiVitalRow.R = event.value;
        if (isError) {
          setDialogOption({
            title: 'Vital: Error',
            message: Constants.ErrorMessage.r_Value,
            showDialog: true
          });
        }
      } else if (field === 'spo2') {
        isError = isNaN(event.value); //! !validateEmptyFloat(event.value);
        apiVitalRow.SP02 = event.value;
        if (isError) {
          setDialogOption({
            title: 'Vital: Error',
            message: Constants.ErrorMessage.spo2_Value,
            showDialog: true
          });
        }
      } else if (field === 'initials') {
        apiVitalRow.initials = event.value;
      }
    }
    setApiVitals([...copyApiVitals]);
    setItemVitals(newData);
  };
  const customCellRender = (td, props) => (
    <CellRender
      originalProps={props}
      td={td}
      enterEdit={enterEdit}
      editField={editField}
    />
  );

  const customRowRender = (tr, props) => (
    <RowRender
      originalProps={props}
      tr={tr}
      exitEdit={exitEdit}
      editField={editField}
    />
  );

  return (
    <div className='infusion-page'>
      {dialogOption && dialogOption.showDialog && (
        <MessageDialog dialogOption={dialogOption} />
      )}

      {stepAllowed && (
        <>
          {/* VITALS */}
          <Form
            onSubmit={handleAddVitals}
            render={(formRenderProps) => (
              <form
                onSubmit={formRenderProps.onSubmit}
                className='k-form pl-3 pr-3 pt-1'
              >
                <div
                  className='infusion-details col-md-11 mt-2 mb-3'
                  style={{ border: '1px solid #afaaaa' }}
                >
                  <div className='row'>
                    <div className='infusion-HeaderRow col-12 ml-0 pl-2 py-2 mr-0'>
                      <div className='row'>
                        <div className='col-md-2 headerText'>
                          <strong>VITALS</strong>
                        </div>
                        <div className='col-md-2 headerText'>&nbsp;</div>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-2 ml-3'>
                      Time:
                      <br />
                      <Field name='time' label='' component={TimePicker} />
                      {isVitalsTimeRangeError && (
                        <Error>
                          {Constants.ErrorMessage.Admin_Timer_Range}
                        </Error>
                      )}
                    </div>
                    <div className='col-md-1'>
                      <Field
                        name='temp'
                        label='Temp'
                        style={{ width: 75 }}
                        component={Input}
                        validator={validateEmptyFloat}
                      />
                    </div>
                    <div className='col-md-1'>
                      <Field
                        name='bp'
                        label='BP'
                        style={{ width: 75 }}
                        component={Input}
                      />
                    </div>
                    <div className='col-md-1'>
                      <Field
                        name='hr'
                        label='HR'
                        style={{ width: 75 }}
                        component={Input}
                        validator={validateEmptyFloat}
                      />
                    </div>
                    <div className='col-md-1'>
                      <Field
                        name='r'
                        label='R'
                        style={{ width: 75 }}
                        component={Input}
                        validator={validateEmptyFloat}
                      />
                    </div>
                    <div className='col-md-1'>
                      <Field
                        name='spo2'
                        label='SPO2'
                        style={{ width: 75 }}
                        component={Input}
                        validator={validateEmptyFloat}
                      />
                    </div>
                    <div className='col-md-1'>
                      <Field
                        name='initials'
                        label='INITIALS'
                        style={{ width: 75 }}
                        component={Input}
                      />
                    </div>
                    <div className='col-md-2 mt-12'>
                      <button type='submit' className='k-button blue'>
                        ADD
                      </button>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-md-12 mt-12 mb-2'>
                      <Grid
                        className='infusion-grid'
                        data={itemVitals}
                        onItemChange={itemChange}
                        cellRender={customCellRender}
                        rowRender={customRowRender}
                        editField='inEdit'
                      >
                        <Column field='time' title='TIME' />
                        <Column field='temp' title='TEMP' />
                        <Column field='bp' title='BP' />
                        <Column field='hr' title='HR' />
                        <Column field='r' title='R' />
                        <Column field='spo2' title='SPO2' />
                        <Column field='initials' title='INITIALS' />
                        <Column
                          field='action'
                          title=' '
                          cell={customCellDeleteVitals}
                        />
                      </Grid>
                    </div>
                  </div>
                </div>
              </form>
            )}
          />

          {/* IV / SUBQ */}

          <Form
            onSubmit={handleAddIV}
            render={(formRenderProps) => (
              <form
                onSubmit={formRenderProps.onSubmit}
                className='k-form pl-3 pr-3 pt-1'
              >
                <div
                  className='infusion-details col-md-11 mt-2 mb-3'
                  style={{ border: '1px solid #afaaaa' }}
                >
                  <div className='row'>
                    <div className='infusion-HeaderRow col-12 ml-0 pl-2 py-2 mr-0'>
                      <div className='row'>
                        <div className='col-md-8 headerText'>
                          <strong>IV/IVP</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row col-md-12 mt-1 mb-3'>
                    <div className='col-md-2 mt-12'>
                      <Field
                        component={TimePicker}
                        name='time'
                        value={timeIV}
                        onChange={(event) => {
                          setTimeIV(event.target.value);
                          validateTimeRange(event.target.value);
                        }}
                      />
                      {isIVTimeRangeError && (
                        <Error>
                          {Constants.ErrorMessage.Admin_Timer_Range}
                        </Error>
                      )}
                    </div>
                    <div className='col-md-3'>
                      <Field
                        component={DropDownList}
                        data={event}
                        name='event'
                        label='Event'
                        onChange={(e) => setEventIV(e.value)}
                      />
                    </div>
                    {showRateIV && (
                      <div className='col-md-1'>
                        <Field
                          component={Input}
                          name='rate'
                          label='Rate'
                          validator={validateEmptyFloat}
                        />
                      </div>
                    )}
                    <div className='col-md-4'>
                      <Field
                        component={Input}
                        name='visualNotes'
                        label='Visual Notes'
                      />
                    </div>
                    <div className='col-md-1'>
                      <Field
                        component={Input}
                        name='initials'
                        label='Initials'
                      />
                    </div>
                    <div className='col-md-1 mt-12'>
                      <button type='submit' className='k-button blue'>
                        ADD
                      </button>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-12 mt-1 mb-2'>
                      <Grid className='infusion-grid' data={itemIVs}>
                        <Column field='time' title='TIME' />
                        <Column field='event' title='EVENT' />
                        <Column field='rate' title='RATE' />
                        <Column field='visualNotes' title='VISUAL NOTES' />
                        <Column field='totalInfusion' title='TOTAL INFUSION' />
                        <Column field='initials' title='INITIALS' />
                        <Column
                          field='action'
                          title=' '
                          cell={customCellDeleteIV}
                        />
                      </Grid>
                    </div>
                  </div>
                </div>
              </form>
            )}
          />

          {/* IM or IQ */}

          <Form
            onSubmit={handleAddIM}
            render={(formRenderProps) => (
              <form
                onSubmit={formRenderProps.onSubmit}
                className='k-form pl-3 pr-3 pt-1'
              >
                <div
                  className='infusion-details col-md-11 mt-2 mb-3'
                  style={{ border: '1px solid #afaaaa' }}
                >
                  <div className='row'>
                    <div className='infusion-HeaderRow col-12 ml-0 pl-2 py-2 mr-0'>
                      <div className='row'>
                        <div className='col-md-8 headerText'>
                          <strong>IM / SUBQ</strong>
                          {/* : [Drug Name + Calc Dosage] */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row col-md-12 mt-1'>
                    <div className='col-md-2 mt-12'>
                      <Field component={TimePicker} name='time' />
                      {isIMTimeRangeError && (
                        <Error>
                          {Constants.ErrorMessage.Admin_Timer_Range}
                        </Error>
                      )}
                    </div>
                    <div className='col-md-2'>
                      <Field
                        component={Input}
                        name='location'
                        label='Location'
                      />
                    </div>
                    <div className='col-md-2'>
                      <Field
                        component={Input}
                        name='amountMG'
                        label='Amount (mg)'
                        validator={validateEmptyFloat}
                      />
                    </div>
                    <div className='col-md-4'>
                      <Field
                        component={Input}
                        name='visualNotes'
                        label='Visual Notes'
                      />
                    </div>
                    <div className='col-md-1'>
                      <Field
                        component={Input}
                        name='initials'
                        label='Initials'
                      />
                    </div>
                    <div className='col-md-1 mt-12'>
                      <button type='submit' className='k-button blue'>
                        ADD
                      </button>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-12 mt-3 mb-2'>
                      <Grid className='infusion-grid' data={itemIMs}>
                        <Column field='time' title='TIME' />
                        <Column field='location' title='LOCATION' />
                        <Column field='amountMG' title='AMOUNT (MG)' />
                        <Column field='visualNotes' title='VISUAL NOTES' />
                        <Column field='initials' title='INITIALS' />
                        <Column
                          field='action'
                          title=' '
                          cell={customCellDeleteIM}
                        />
                      </Grid>
                    </div>
                  </div>
                </div>
              </form>
            )}
          />

          {/* SUBMIT FORM */}

          <Form
            onSubmitClick={handleSubmit}
            render={(formRenderProps) => (
              <form
                onSubmit={formRenderProps.onSubmit}
                className='k-form pl-3 pr-3 pt-1'
              >
                <div className='row col-md-12 mt-3 mb-3'>
                  <div className='col-md-3'>
                    Administration Complete &nbsp;
                    <Field
                      component={Switch}
                      onLabel='Yes'
                      offLabel='No'
                      name='isAdministrationComplete'
                      disabled={adminReady || !prepHasDrugs}
                      defaultChecked={
                        infusionForm.isAdministrationComplete.value
                      }
                    />
                  </div>
                </div>
                {isStopped && (
                  <Alert>
                    ALERT: Add STOP to the current IV/IVP in order to mark
                    complete
                  </Alert>
                )}
                {adminReady && (
                  <Alert>
                    ALERT: Must complete an IV/IVP or IM/SUBQ before continuing
                    or Cancel Infusion with No Meds Administered
                  </Alert>
                )}
                {!prepHasDrugs && (
                  <Alert>
                    ALERT: No medication selected in step Preparation
                  </Alert>
                )}
                {isVitalsTimeRangeError && (
                  <Alert>Alert: VITALS time is not within Business Hours</Alert>
                )}
                {isIVTimeRangeError && (
                  <Alert>Alert: IV/IVP time is not within Business Hours</Alert>
                )}
                {isIMTimeRangeError && (
                  <Alert>
                    Alert: IM/SUBQ time is not within Business Hours
                  </Alert>
                )}
                <div className='row mt-5 ml-2 mb-5'>
                  <div className='col-md-2'>
                    <button type='submit' className='k-button pageButton'>
                      Save
                    </button>
                  </div>
                </div>
              </form>
            )}
          />
        </>
      )}
    </div>
  );
};

export default AdminV2;
