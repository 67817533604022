import React from 'react';
import { Button } from '@progress/kendo-react-buttons';

const FormButton = ({ label = 'Submit', loading = false, ...props }) => {
  const labelClass = loading ? 'k-i-loading' : '';

  return (
    <Button
      {...props}
      className={props.className}
      onClick={props.onClick}
      disabled={loading || props.disabled}
    >
      <span className={labelClass}>{label}</span>
    </Button>
  );
};
export default FormButton;
