import React from 'react';

const AppointmentDetails = ({ dataItem }) => {
  return (
    <>
      <div>
        <strong>Admin Seq No.:</strong>{' '}
        {dataItem?.adminStage?.adminSequenceNumber}
      </div>
      <div>
        <strong>Admin:</strong> {dataItem?.adminStage?.administer}
      </div>
      <div>
        <strong>UOM:</strong> {dataItem?.adminStage?.unitOfMeas}
      </div>
      <div>
        <strong>Calc Dose:</strong> {dataItem?.adminStage?.calcDosage}
      </div>
    </>
  );
};

export { AppointmentDetails };
