import React from 'react';

const RowFieldOrder = ({ label = '', value = '' }) => {
  return (
    <div className='col-md-3 headerText'>
      <b>{label}:</b>
      <br />
      {value}
    </div>
  );
};

export default RowFieldOrder;
