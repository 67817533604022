import React, { useEffect, useState, useContext, useCallback } from 'react';

// kendo
import { Grid, GridToolbar } from '@progress/kendo-react-grid';
import { GridColumn as Column } from '@progress/kendo-react-grid/dist/npm/GridColumn';
import { Button } from '@progress/kendo-react-buttons';
import { Form, Field } from '@progress/kendo-react-form';
import { Input } from '@progress/kendo-react-inputs';
import { process } from '@progress/kendo-data-query';
import { Dialog } from '@progress/kendo-react-dialogs';
import {
  ExcelExport,
  ExcelExportColumn,
  ExcelExportColumnGroup
} from '@progress/kendo-react-excel-export';

// components
import FormButton from '@/components/common-components/Form/FormButton';
import { MaskedNdcInput } from '@/common/MaskInput';
import { ColumnMenu } from '@/components/common-components/columnMenu';
import AwesomeLabel from '@/components/common-components/AwesomeLabel';
import Badge from '@/components/common-components/Badge';
import WindowOverlay from '@/components/common-components/WindowOverlay';

// utils
import {
  InputField,
  validateInput,
  DropDownListField,
  SwitchInput
} from '@/common/Validation';
import { Constants } from '@/constants';
// import vendorData from '@/components/Inventory/vendors'; // INFO: replaced this one with the one from common to exclude PAP vendors
import { routesFlat } from '@/components/Referral/ReferralMaps';
import { regularVendors } from '@/common/Vendors';

// gql
import { connectToGraphqlAPI } from '@/provider';
import { listProducts } from '@/graphql/queries';
import { createProduct, updateProduct } from '@/graphql/mutations';

// context
import { NotifContext, ProductContext } from '@/context';

const ProductAdmin = () => {
  const { showError, showSuccess } = useContext(NotifContext);

  const [showAddProduct, setShowAddProduct] = useState(false);
  const [showUpdateProduct, setShowUpdateProduct] = useState(false);
  const onToggle = () => setShowAddProduct(!showAddProduct);
  const onToggleUpdate = () => setShowUpdateProduct(!showUpdateProduct);
  const [reload, setReload] = useState('');
  const [listProductsByNameData, setListProductsByNameData] = useState([]);
  const [currentSelRow, setCurrentSelRow] = useState({});

  const initialDataState = {};
  const [dataState, setDataState] = useState();
  const [resultState, setResultState] = useState(
    process(listProductsByNameData, initialDataState)
  );

  // State for loading
  const [loading, setLoading] = useState(false);

  const onDataStateChange = useCallback(
    (e) => {
      setDataState(e.dataState); // store for use
      setResultState(process(listProductsByNameData, e.dataState));
    },
    [listProductsByNameData]
  );

  // MAIN INITIATOR
  useEffect(() => {
    listProductsByNameCall();
  }, [reload]);

  useEffect(() => {
    setDataState(initialDataState);
    setResultState(process(listProductsByNameData, initialDataState));
  }, [listProductsByNameData]);

  const packageData = [
    'vial',
    'syringe',
    'tablet',
    'bottle',
    'carton',
    'bag',
    'capsule'
  ];
  //   const routeData = ['IV', 'IVP', 'SUBQ', 'IM', 'PO'];
  const drugTypeData = ['Theraputic', 'Chemo'];
  const uomData = [
    'ml',
    'mcg',
    'mg',
    'kg',
    'g_per_kg',
    'mg_per_kg',
    'g',
    'each',
    'pct',
    'units',
    'mcg_per_kg'
  ];
  const productTypeData = ['PRESCRIPTION', 'PREMED', 'DILUENT', 'MIXIN'];

  const { drugCategories } = useContext(ProductContext);
  const filteredCategories = drugCategories
    .filter((category) => category !== null)
    .map((category) => category.replace(/['"]+/g, ''))
    .sort((category, c) => category.localeCompare(c));

  /// ///////////////////////////////////////////
  /// ///////////////////////////////////////////
  /// ///////////////////////////////////////////

  const selectAction = (dataItem) => {
    return (
      <td>
        {
          <div className='row'>
            <div className='col-md-4'>
              <Button
                look='flat'
                icon='edit'
                onClick={() => {
                  handleEditClick(dataItem.dataItem);
                  // handleEditQtyClick(dataItem.dataItem);
                }}
              >
                Edit
              </Button>
            </div>
          </div>
        }
      </td>
    );
  };

  // Call to get grid data
  const listProductsByNameCall = async () => {
    try {
      const data = await connectToGraphqlAPI({
        // graphqlQuery: listProductsByName,
        graphqlQuery: listProducts
        // variables: {input: productName}
      });

      if (
        data &&
        data.data &&
        data.data.listProducts &&
        data.data.listProducts.items
      ) {
        /// /////////////////////
        let list = data.data.listProducts.items;
        let sNextToken = data.data.listProducts.nextToken
          ? data.data.listProducts.nextToken
          : null;
        while (sNextToken != null) {
          try {
            const data = await connectToGraphqlAPI({
              graphqlQuery: listProducts,
              variables: { nextToken: sNextToken }
            });
            if (
              data &&
              data.data &&
              data.data.listProducts &&
              data.data.listProducts.items
            ) {
              sNextToken = data.data.listProducts.nextToken
                ? data.data.listProducts.nextToken
                : null;
              list = list.concat(data.data.listProducts.items);
            }
          } catch (err) {
            console.log('ProductAdmin::listProductsCall err: ', err);
            sNextToken = null; // set to stop iterating
          }
        }
        /// ////////////////
        const theData = list
          .map((item) => ({
            ...item,
            text: `${item.productName} ${item.strength} ${item.unitOfMeas} (${item.route})`,
            value: item.productId,
            formattedPrice: `$${parseFloat(item.price).toFixed(2)}`
          }))
          .sort((a, b) => (a.productName > b.productName ? 1 : -1));

        setListProductsByNameData(theData);
      }
    } catch (err) {
      console.log('ProductAdmin::listProductsByNameCall err: ', err);
    }
  };

  /// ///////////////////////////////////////////
  /// ///////////////////////////////////////////
  /// ///////////////////////////////////////////
  /// //Create product request object/////

  //
  const handleSubmitNewProduct = (dataItem) => {
    const {
      productName,
      ndcInnerPackageCode,
      ndcOuterPackageCode,
      vendor,
      dosing,
      isReconstituted,
      jcode,
      packageType,
      price,
      route,
      scheduledAllotment,
      uom,
      volume,
      volumeUom,
      strength,
      typeOfDrug,
      typeOfProduct,
      drugCategory
    } = dataItem;

    const requestObject = {
      productId:
        ndcInnerPackageCode === '_____-____-__'
          ? ndcOuterPackageCode
          : ndcInnerPackageCode || ndcOuterPackageCode,
      productName,
      vendor,
      price,
      strength,
      dosing,
      route,
      unitOfMeas: uom,
      scheduledAllotment,
      typeOfProduct,
      packageVolume: volume,
      packageVolumeUOM: volumeUom,
      packageType,
      typeOfDrug,
      isReconstituted: isReconstituted || false,
      jCode: jcode,
      ndcInnerPackageCode:
        ndcInnerPackageCode === '_____-____-__'
          ? null
          : ndcInnerPackageCode || null,
      ndcOuterPackageCode,
      category: drugCategory
    };
    createNewProductCall(requestObject);
    showSuccess('New Product was created');
  };
  /// ///////////////////////////////////////////
  /// ///////////////////////////////////////////
  /// ///////////////////////////////////////////
  /// //Update product request object/////

  //
  const handleSubmitUpdateProduct = (dataItem) => {
    const {
      productName,
      ndcInnerPackageCode,
      ndcOuterPackageCode,
      vendor,
      dosing,
      isReconstituted,
      jcode,
      packageType,
      price,
      route,
      scheduledAllotment,
      uom,
      volume,
      volumeUom,
      strength,
      typeOfDrug,
      typeOfProduct,
      productId,
      category
    } = dataItem;

    const requestObject = {
      productId: currentSelRow.productId,
      // productName: productName,
      vendor,
      price,
      // strength: strength,
      // dosing: dosing,
      // route: route,
      // unitOfMeas: uom,
      // scheduledAllotment: scheduledAllotment,
      // typeOfProduct: typeOfProduct,
      packageVolume: volume,
      packageVolumeUOM: volumeUom,
      packageType,
      // typeOfDrug: typeOfDrug,
      // isReconstituted: isReconstituted ? isReconstituted : false,
      jCode: jcode,
      // ndcInnerPackageCode: ndcInnerPackageCode === "_____-____-__" ? null : ndcInnerPackageCode,
      // ndcOuterPackageCode: ndcOuterPackageCode,
      category
    };
    updateProductCall(requestObject);
  };

  const updateProductCall = async (requestObject) => {
    try {
      setLoading(true);

      const data = await connectToGraphqlAPI({
        graphqlQuery: updateProduct,
        variables: { input: requestObject }
      });
      if (data) {
        showSuccess('Product was updated');
        onToggleUpdate();
        setReload(data);
        setLoading(false);
      }
    } catch (err) {
      console.error('ProductAdmin::updateProductCall err: ', err);
      showError('Error updating product. Check console for details');
      setLoading(false);
    }
  };

  /// ///////////////////////////////////////////
  /// ///////////////////////////////////////////
  /// ///////////////////////////////////////////
  // create product call

  const createNewProductCall = async (requestObject) => {
    try {
      setLoading(true);

      const data = await connectToGraphqlAPI({
        graphqlQuery: createProduct,
        variables: { input: requestObject }
      });
      if (data) {
        showSuccess('New Product was created');
        onToggle();
        setReload(data);
        setLoading(false);
      }
    } catch (err) {
      console.error('ProductAdmin::createNewProductCall err: ', err);
      showError('Error creating new product. Check console for details');
      setLoading(false);
    }
  };

  const handleEditClick = (dataObject) => {
    onToggleUpdate();
    setCurrentSelRow(dataObject);
  };

  /// Validation
  const createProductForm = {
    productName: {
      value: currentSelRow?.productName ? currentSelRow?.productName : '',
      // value: "123",
      inputValidator: (value) => {
        return validateInput({
          referralId: { ...createProductForm.productName, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    },
    vendor: {
      value: currentSelRow?.vendor ? currentSelRow?.vendor : '',
      // value: "123",
      inputValidator: (value) => {
        return validateInput({
          referralId: { ...createProductForm.vendor, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    },
    ndcOuterPackageCode: {
      value: currentSelRow?.ndcOuterPackageCode
        ? currentSelRow?.ndcOuterPackageCode
        : '',
      inputValidator: (value) => {
        return validateInput({
          referralId: { ...createProductForm.ndcOuterPackageCode, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    },
    ndcInnerPackageCode: {
      value: currentSelRow?.ndcInnerPackageCode
        ? currentSelRow?.ndcInnerPackageCode
        : '',
      inputValidator: (value) => {
        return validateInput({
          referralId: { ...createProductForm.ndcInnerPackageCode, value }
        });
      },
      validations: [
        {
          type: 'onlyFloat',
          message: Constants.ErrorMessage.Numeric_Required
        }
      ]
    },
    route: {
      value: currentSelRow?.route ? currentSelRow?.route : '',
      inputValidator: (value) => {
        return validateInput({
          referralId: { ...createProductForm.route, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    },
    strength: {
      value: currentSelRow?.strength ? currentSelRow?.strength : '',
      inputValidator: (value) => {
        return validateInput({
          referralId: { ...createProductForm.strength, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        },
        {
          type: 'onlyFloat',
          message: Constants.ErrorMessage.Numeric_Required
        }
      ]
    },
    dosing: {
      value: currentSelRow?.dosing ? currentSelRow?.dosing : '',
      inputValidator: (value) => {
        return validateInput({
          referralId: { ...createProductForm.dosing, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    },
    jcode: {
      value: currentSelRow?.jCode ? currentSelRow?.jCode : '',
      inputValidator: (value) => {
        return validateInput({
          referralId: { ...createProductForm.jCode, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    },
    volume: {
      value: currentSelRow?.packageVolume ? currentSelRow?.packageVolume : '',
      inputValidator: (value) => {
        return validateInput({
          referralId: { ...createProductForm.volume, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    },
    packageType: {
      value: currentSelRow?.packageType ? currentSelRow?.packageType : '',
      inputValidator: (value) => {
        return validateInput({
          referralId: { ...createProductForm.packageType, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    },
    price: {
      value: currentSelRow?.price ? currentSelRow?.price : 0,
      inputValidator: (value) => {
        return validateInput({
          referralId: { ...createProductForm.price, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    },
    uom: {
      value: currentSelRow?.unitOfMeas ? currentSelRow?.unitOfMeas : '',
      inputValidator: (value) => {
        return validateInput({
          referralId: { ...createProductForm.uom, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    },
    typeOfDrug: {
      value: currentSelRow?.typeOfDrug ? currentSelRow?.typeOfDrug : '',
      inputValidator: (value) => {
        return validateInput({
          referralId: { ...createProductForm.typeOfDrug, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    },
    typeOfProduct: {
      value: currentSelRow?.typeOfProduct ? currentSelRow?.typeOfProduct : '',
      inputValidator: (value) => {
        return validateInput({
          referralId: { ...createProductForm.typeOfProduct, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    },
    isReconstituted: {
      value: currentSelRow?.isReconstituted
        ? currentSelRow?.isReconstituted
        : '',
      inputValidator: (value) => {
        return validateInput({
          referralId: { ...createProductForm.isReconstituted, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    },
    scheduledAllotment: {
      value: currentSelRow?.scheduledAllotment
        ? currentSelRow?.scheduledAllotment
        : '',
      inputValidator: (value) => {
        return validateInput({
          referralId: { ...createProductForm.scheduledAllotment, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    },
    volumeUom: {
      value: currentSelRow?.packageVolumeUOM
        ? currentSelRow?.packageVolumeUOM
        : '',
      inputValidator: (value) => {
        return validateInput({
          referralId: { ...createProductForm.volumeUom, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    },
    category: {
      value: currentSelRow?.category ? currentSelRow?.category : '',
      inputValidator: (value) => {
        return validateInput({
          referralId: { ...createProductForm.category, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    }
  };
  // End validation
  /// ///////////////////////////////////////////
  /// ///////////////////////////////////////////
  /// ///////////////////////////////////////////
  // Expando

  // Row expanding pieces
  const ProductDetails = (props) => {
    const ProductDetailItems = props.dataItem;

    return (
      <>
        {/* Note: many of these will not display data until after AIC-2410 is merged due to changes in queries.js and mutation.js */}
        <div>
          <strong>Outer Package Code:</strong>{' '}
          {ProductDetailItems?.ndcOuterPackageCode}{' '}
        </div>
        <div>
          <strong>Inner Package Code:</strong>{' '}
          {ProductDetailItems?.ndcInnerPackageCode}{' '}
        </div>
        <div>
          <strong>Route:</strong> {ProductDetailItems?.route}{' '}
        </div>
        <div>
          <strong>J-Code:</strong> {ProductDetailItems?.jCode}{' '}
        </div>
        <div>
          <strong>Type of Drug:</strong> {ProductDetailItems?.typeOfDrug}{' '}
        </div>
        <div>
          <strong>Type of Product:</strong> {ProductDetailItems?.typeOfProduct}{' '}
        </div>
        <div>
          <strong>Dosing:</strong> {ProductDetailItems?.dosing}{' '}
        </div>
        <div>
          <strong>Package Type:</strong> {ProductDetailItems?.packageType}{' '}
        </div>
        <div>
          <strong>Package Volume:</strong> {ProductDetailItems?.packageVolume}{' '}
        </div>
        <div>
          <strong>Package Volume UOM:</strong>{' '}
          {ProductDetailItems?.packageVolumeUOM}{' '}
        </div>
        <div>
          <strong>Scheduled Allotment:</strong>{' '}
          {ProductDetailItems?.scheduledAllotment}{' '}
        </div>
        <div>
          <strong>Is Reconstituted:</strong>{' '}
          {ProductDetailItems?.isReconstituted}{' '}
        </div>
      </>
    );
  };

  const expandChange = (event) => {
    const newData = resultState.data.map((item) => {
      if (item.productId === event.dataItem.productId) {
        // "value" field is productId
        item.expanded = !event.dataItem.expanded;
      }
      return item;
    });
    setResultState(process(newData, dataState));
  };

  const initialForm = () => {
    const initialObject = {};
    Object.keys(createProductForm).forEach((key) => {
      initialObject[key] = createProductForm[key].value;
    });
    return initialObject;
  };

  /// /Export
  const _export = React.useRef(null);

  const exportExport = () => {
    if (_export.current !== null) {
      _export.current.save(listProductsByNameData);
    }
  };

  const statusCell = ({ dataItem }) => {
    const { status } = dataItem;
    const settings = {
      active: { type: 'success', text: 'ACTIVE' },
      inactive: { type: 'warning', text: 'INACTIVE' }
    };
    const badgeSettings =
      status === 'INACTIVE' ? settings.inactive : settings.active;

    return (
      <td>
        <Badge {...badgeSettings} />
      </td>
    );
  };

  // Start return
  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col ml-1 mr-1'>
          <div className='row'>
            <div className='col-md-2'>
              <Button type='button' look='flat' onClick={onToggle}>
                <AwesomeLabel label='Add New Product' icon='box-check' />
              </Button>
            </div>
            {/* <div className="col-md-8 mt-2">
              NOTE: Need to make sure Capitalization of Product Name is
              accurate. Also recommend to test all NEW products thoroughly
              before going to production.
            </div> */}
          </div>
          <div className='row'>
            <div className='col-md-12 ml-1 mr-1 mt-3'>
              <div>
                {/* <div className="a-grid__header">
                  <div>Products</div>
                </div> */}
                <ExcelExport data={listProductsByNameData} ref={_export}>
                  <ExcelExportColumnGroup
                    title='Products'
                    headerCellOptions={{
                      textAlign: 'center'
                    }}
                  >
                    <ExcelExportColumn
                      field='text'
                      title='Product'
                      width={180}
                      columnMenu={ColumnMenu}
                    />
                    <ExcelExportColumn
                      field='productId'
                      title='Product ID'
                      width={125}
                      columnMenu={ColumnMenu}
                    />
                    <ExcelExportColumn
                      field='productName'
                      title='Product Name'
                      width={180}
                      columnMenu={ColumnMenu}
                    />
                    <ExcelExportColumn
                      field='strength'
                      title='Strength'
                      width={50}
                      columnMenu={ColumnMenu}
                    />
                    <ExcelExportColumn
                      field='unitOfMeas'
                      title='UOM'
                      width={40}
                      columnMenu={ColumnMenu}
                    />
                    <ExcelExportColumn
                      field='status'
                      title='Status'
                      width={75}
                      columnMenu={ColumnMenu}
                    />
                    <ExcelExportColumn
                      field='category'
                      title='Category'
                      width={125}
                      columnMenu={ColumnMenu}
                    />
                    <ExcelExportColumn
                      field='vendor'
                      title='Vendor'
                      width={180}
                      columnMenu={ColumnMenu}
                    />
                    <ExcelExportColumn
                      field='formattedPrice'
                      title='Price'
                      width={75}
                      columnMenu={ColumnMenu}
                    />
                    <ExcelExportColumn
                      field='dosing'
                      title='Dosing'
                      width={50}
                      columnMenu={ColumnMenu}
                    />
                    <ExcelExportColumn
                      field='packageType'
                      title='Package Type'
                      width={100}
                      columnMenu={ColumnMenu}
                    />
                    <ExcelExportColumn
                      field='packageVolume'
                      title='Package Volume'
                      width={50}
                      columnMenu={ColumnMenu}
                    />
                    <ExcelExportColumn
                      field='packageVolumeUOM'
                      title='Package Volume UOM'
                      width={40}
                      columnMenu={ColumnMenu}
                    />
                    <ExcelExportColumn
                      field='scheduledAllotment'
                      title='Scheduled Allotment'
                      width={200}
                      columnMenu={ColumnMenu}
                    />
                    ///
                    <ExcelExportColumn
                      field='ndcOuterPackageCode'
                      title='Outer Package Code'
                      width={125}
                      columnMenu={ColumnMenu}
                    />
                    <ExcelExportColumn
                      field='ndcInnerPackageCode'
                      title='Inner Package Code'
                      width={125}
                      columnMenu={ColumnMenu}
                    />
                    <ExcelExportColumn
                      field='route'
                      title='Route'
                      width={50}
                      columnMenu={ColumnMenu}
                    />
                    <ExcelExportColumn
                      field='jCode'
                      title='J-Code'
                      width={75}
                      columnMenu={ColumnMenu}
                    />
                    <ExcelExportColumn
                      field='typeOfDrug'
                      title='Type of Drug'
                      width={125}
                      columnMenu={ColumnMenu}
                    />
                    <ExcelExportColumn
                      field='typeOfProduct'
                      title='Type of Product'
                      width={125}
                      columnMenu={ColumnMenu}
                    />
                    <ExcelExportColumn
                      field='isReconstituted'
                      title='Is Reconstituted'
                      width={100}
                      columnMenu={ColumnMenu}
                    />
                  </ExcelExportColumnGroup>
                </ExcelExport>
                <Grid
                  className='nurse-queue_grid'
                  // data={listProductsByNameData}
                  data={{ data: resultState.data }}
                  onDataStateChange={onDataStateChange}
                  {...dataState}
                  sortable
                  expandField='expanded'
                  onExpandChange={expandChange}
                  detail={ProductDetails}
                >
                  <GridToolbar>
                    <div className='float-left mt-06'>
                      <strong>PRODUCT LIST</strong>
                    </div>
                    <div className='float-right'>
                      <button
                        title='Export Excel'
                        className='k-button'
                        onClick={exportExport}
                      >
                        <AwesomeLabel
                          label='Excel Export'
                          icon='file-export icon-blue'
                        />
                      </button>
                    </div>
                  </GridToolbar>
                  <Column
                    field='text'
                    title='Product'
                    width={180}
                    columnMenu={ColumnMenu}
                  />
                  <Column
                    field='productId'
                    title='Product ID'
                    width={125}
                    columnMenu={ColumnMenu}
                  />
                  <Column
                    field='productName'
                    title='Product Name'
                    width={180}
                    columnMenu={ColumnMenu}
                  />
                  <Column
                    field='strength'
                    title='Strength'
                    width={100}
                    columnMenu={ColumnMenu}
                  />
                  <Column
                    field='unitOfMeas'
                    title='UOM'
                    width={100}
                    columnMenu={ColumnMenu}
                  />
                  <Column
                    field='status'
                    title='Status'
                    width={125}
                    columnMenu={ColumnMenu}
                    cell={statusCell}
                  />
                  <Column
                    field='category'
                    title='Category'
                    width={125}
                    columnMenu={ColumnMenu}
                  />
                  <Column
                    field='vendor'
                    title='Vendor'
                    width={200}
                    columnMenu={ColumnMenu}
                  />
                  <Column
                    field='formattedPrice'
                    title='Price Each'
                    width={125}
                    columnMenu={ColumnMenu}
                  />
                  <Column field='select' title='SELECT' cell={selectAction} />
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {showAddProduct && (
          <WindowOverlay
            title='Add Product'
            width={600}
            height={615}
            initialTop={50}
            showDialog={showAddProduct}
            onClose={onToggle}
          >
            <Form
              // initialValues={initialForm()}
              onSubmit={handleSubmitNewProduct}
              render={(formRenderProps) => (
                <form
                  onSubmit={formRenderProps.onSubmit}
                  className='k-form pl-2 pr-2'
                >
                  <div className='row'>
                    <div className='col-md-6'>
                      <Field
                        name='productName'
                        label='Product Name'
                        component={InputField}
                        validator={createProductForm.productName.inputValidator}
                      />
                    </div>
                    <div className='col-md-6'>
                      <Field
                        component={DropDownListField}
                        label='Vendor'
                        data={regularVendors}
                        name='vendor'
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <Field
                        name='ndcOuterPackageCode'
                        label='Outer NDC Package Code'
                        component={MaskedNdcInput}
                        validator={
                          createProductForm.ndcOuterPackageCode.inputValidator
                        }
                      />
                    </div>
                    <div className='col-md-6'>
                      <Field
                        name='ndcInnerPackageCode'
                        label='Inner NDC Package Code'
                        component={MaskedNdcInput}
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-2'>
                      <Field
                        component={DropDownListField}
                        label='Route'
                        data={routesFlat}
                        name='route'
                        validator={createProductForm.route.inputValidator}
                      />
                    </div>
                    <div className='col-md-2'>
                      <Field
                        name='strength'
                        label='Strength'
                        style={{ width: 70 }}
                        component={InputField}
                        validator={createProductForm.strength.inputValidator}
                      />
                    </div>
                    <div className='col-md-2'>
                      <Field
                        name='dosing'
                        label='Dosing'
                        style={{ width: 70 }}
                        component={InputField}
                      />
                    </div>
                    <div className='col-md-2'>
                      <Field
                        name='jcode'
                        label='J Code'
                        style={{ width: 70 }}
                        component={InputField}
                      />
                    </div>
                  </div>
                  <div className='row mt-3'>
                    <div className='col-md-3'>
                      <Field
                        name='volume'
                        label='Package Volume'
                        component={InputField}
                        validator={createProductForm.volume.inputValidator}
                      />
                    </div>

                    <div className='col-md-3'>
                      <Field
                        component={DropDownListField}
                        label='Volume UOM'
                        data={uomData}
                        name='volumeUom'
                        validator={createProductForm.volumeUom.inputValidator}
                      />
                    </div>
                    <div className='col-md-3'>
                      <Field
                        component={DropDownListField}
                        label='Package Type'
                        data={packageData}
                        name='packageType'
                        validator={createProductForm.packageType.inputValidator}
                      />
                    </div>
                    <div className='col-md-3'>
                      <Field
                        name='price'
                        label='Price'
                        style={{ width: 70 }}
                        component={InputField}
                      />
                    </div>
                  </div>

                  <div className='row mt-3'>
                    <div className='col-md-3'>
                      <Field
                        component={DropDownListField}
                        label='UOM'
                        data={uomData}
                        name='uom'
                        validator={createProductForm.uom.inputValidator}
                      />
                    </div>
                    <div className='col-md-3'>
                      <Field
                        component={DropDownListField}
                        label='Type of Drug'
                        data={drugTypeData}
                        name='typeOfDrug'
                      />
                    </div>
                    <div className='col-md-3'>
                      <Field
                        component={DropDownListField}
                        label='Type of Product'
                        data={productTypeData}
                        name='typeOfProduct'
                        validator={
                          createProductForm.typeOfProduct.inputValidator
                        }
                      />
                    </div>
                    <div className='col-md-3'>
                      <span
                        style={{
                          fontSize: 12,
                          fontWeight: 'bold'
                        }}
                      >
                        Is Reconstituted
                      </span>
                      <Field
                        name='isReconstituted'
                        id='termsinput'
                        component={SwitchInput}
                      />
                    </div>
                  </div>
                  <div className='row mt-3'>
                    <div className='col-md-12'>
                      <Field
                        name='scheduledAllotment'
                        label='Scheduled Allotment'
                        component={InputField}
                      />
                    </div>
                  </div>
                  <div className='row mt-3'>
                    <div className='col-md-12'>
                      <Field
                        component={DropDownListField}
                        label='Drug Category'
                        data={filteredCategories}
                        name='drugCategory'
                        validator={createProductForm.category.inputValidator}
                      />
                    </div>
                  </div>
                  <hr />
                  <div className='row mt-3'>
                    <div className='col-md-6'>
                      <FormButton
                        type='submit'
                        className='k-button pageButton Blue'
                        loading={loading}
                      >
                        Submit
                      </FormButton>
                    </div>
                  </div>
                </form>
              )}
            />
          </WindowOverlay>
        )}

        {/* Update Product */}
        {showUpdateProduct && (
          <Dialog
            title='Update Product'
            width={600}
            height={615}
            initialTop={50}
            showDialog
            onClose={onToggleUpdate}
          >
            <Form
              initialValues={initialForm()}
              onSubmit={handleSubmitUpdateProduct}
              render={(formRenderProps) => (
                <form
                  onSubmit={formRenderProps.onSubmit}
                  className='k-form pl-2 pr-2'
                >
                  <div className='row'>
                    <div className='col-md-6'>
                      <Field
                        name='productName'
                        label='Product Name'
                        component={InputField}
                        validator={createProductForm.productName.inputValidator}
                        disabled
                      />
                    </div>
                    <div className='col-md-6'>
                      <Field
                        component={DropDownListField}
                        label='Vendor'
                        data={regularVendors}
                        name='vendor'
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <Field
                        name='ndcOuterPackageCode'
                        label='Outer NDC Package Code'
                        component={MaskedNdcInput}
                        disabled
                      />
                    </div>
                    <div className='col-md-6'>
                      <Field
                        name='ndcInnerPackageCode'
                        label='Inner NDC Package Code'
                        component={MaskedNdcInput}
                        disabled
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-2'>
                      <Field
                        disabled
                        component={DropDownListField}
                        label='Route'
                        data={routesFlat}
                        name='route'
                        validator={createProductForm.route.inputValidator}
                      />
                    </div>
                    <div className='col-md-2'>
                      <Field
                        disabled
                        name='strength'
                        label='Strength'
                        style={{ width: 70 }}
                        component={Input}
                        validator={createProductForm.strength.inputValidator}
                      />
                    </div>
                    <div className='col-md-2'>
                      <Field
                        disabled
                        name='dosing'
                        label='Dosing'
                        style={{ width: 70 }}
                        component={Input}
                      />
                    </div>
                    <div className='col-md-2'>
                      <Field
                        disabled={false}
                        name='jcode'
                        label='J Code'
                        style={{ width: 70 }}
                        component={Input}
                        // readOnly= {true}
                      />
                    </div>
                  </div>
                  <div className='row mt-3'>
                    <div className='col-md-3'>
                      <Field
                        disabled={false}
                        name='volume'
                        label='Package Volume'
                        component={Input}
                      />
                    </div>

                    <div className='col-md-3'>
                      <Field
                        disabled={false}
                        component={DropDownListField}
                        label='Volume UOM'
                        data={uomData}
                        name='volumeUom'
                      />
                    </div>
                    <div className='col-md-3'>
                      <Field
                        disabled={false}
                        component={DropDownListField}
                        label='Package Type'
                        data={packageData}
                        name='packageType'
                      />
                    </div>
                    <div className='col-md-3'>
                      <Field
                        name='price'
                        label='Price'
                        style={{ width: 70 }}
                        component={Input}
                      />
                    </div>
                  </div>

                  <div className='row mt-3'>
                    <div className='col-md-3'>
                      <Field
                        disabled
                        component={DropDownListField}
                        label='UOM'
                        data={uomData}
                        name='uom'
                      />
                    </div>
                    <div className='col-md-3'>
                      <Field
                        disabled
                        component={DropDownListField}
                        label='Type of Drug'
                        data={drugTypeData}
                        name='typeOfDrug'
                      />
                    </div>
                    <div className='col-md-3'>
                      <Field
                        disabled
                        component={DropDownListField}
                        label='Type of Product'
                        data={productTypeData}
                        name='typeOfProduct'
                      />
                    </div>
                    <div className='col-md-3'>
                      <span
                        style={{
                          fontSize: 12,
                          // fontWeight: "bold", // set UNbold for now since control is disabled
                          color: '#C8C8C8' // set color to grey for now since control is disabled
                        }}
                      >
                        Is Reconstituted
                      </span>
                      <Field
                        readOnly
                        disabled
                        name='isReconstituted'
                        id='termsinput'
                        component={SwitchInput}
                      />
                    </div>
                  </div>
                  <div className='row mt-3'>
                    <div className='col-md-12'>
                      <Field
                        disabled
                        name='scheduledAllotment'
                        label='Scheduled Allotment'
                        component={InputField}
                      />
                    </div>
                  </div>
                  <div className='row mt-3'>
                    <div className='col-md-12'>
                      <Field
                        name='category'
                        label='Drug Category'
                        data={filteredCategories}
                        component={DropDownListField}
                        validator={createProductForm.category.inputValidator}
                      />
                    </div>
                  </div>
                  <hr />
                  <div className='row mt-3'>
                    <div className='col-md-6'>
                      <FormButton
                        type='submit'
                        className='k-button pageButton Blue'
                        loading={loading}
                      >
                        Submit
                      </FormButton>
                    </div>
                  </div>
                </form>
              )}
            />
          </Dialog>
        )}
      </div>
    </div>
  );
};

export default ProductAdmin;
