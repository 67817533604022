import { validateInput } from '@/common/Validation';
import { Constants } from '@/constants';

export const administrationForm = {
  numTreatments: {
    value: '',
    inputValidator: (value) => {
      return validateInput({
        numTreatments: { ...administrationForm.numTreatments, value }
      });
    },
    validations: [
      {
        type: 'required',
        message: Constants.ErrorMessage.is_REQUIRED
      },
      {
        type: 'onlyNumeric',
        message: Constants.ErrorMessage.Numeric_Required
      }
    ]
  }
};
